import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ThreatTierDetails from "brm/threat-model/details/ThreatTierDetails";
import errorUtils from "utils/error-utils";
import Title from "components/elements/StyledTitle";
import { threatTierApi } from "services/brm/global-threat-service";
import {
  DetailsContainer,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";

function ThreatTierFullDetails() {
  const { id } = useParams();
  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function getData() {
      setRefresh(false);
      try {
        const res = await threatTierApi.getThreatTierByIDWithHttpInfo(id);
        if (res.response.status !== 200 || res.response.status !== 201) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
        }
        setSelectedElement(res.data);
      } catch (err) {
        console.error(err);
      }
    }
    if (refresh) {
      getData();
    }
  }, [refresh, id]);

  return (
    <>
      <Title id="ThreatTierFullDetails_title">{selectedElement.name}</Title>

      {selectedElement.id && (
        <DetailsContainerParent showSummaryTable={false}>
          <DetailsContainer>
            <ThreatTierDetails selectedElement={selectedElement} setRefresh={setRefresh} refresh={refresh} />
          </DetailsContainer>
        </DetailsContainerParent>
      )}
    </>
  );
}

export default ThreatTierFullDetails;
