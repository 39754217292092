import { useQuery } from "@tanstack/react-query";
import * as Brm from "services/brm";
import { COMMON } from "constants/brm";
import * as KeyFactory from "./data-type-query-key-factory";
import { BrmError } from "../../../errors/BrmError";
import { DetailTableType } from "../../../types/DetailTableType";

/**
 * custom-hook to get all activities
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {object} config - configuration for the react-query
 */

interface IElementAllDataProps {
  elementId: string;
  elementName: string;
  projectId: string;
  options: {};
}

interface IAllDataMap {
  [index: string]: (id?: string) => any;
}

interface IGetElementAllData {
  elementId: string;
  elementType: string;
}

export const getElementAllData = async ({ elementId, elementType }: IGetElementAllData) => {
  const allDataMap: IAllDataMap = {
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeAllData(id),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeData(id),
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityAllData(id),
  };

  if (allDataMap[elementType]) {
    const data = await allDataMap[elementType](elementId);
    if (!Array.isArray(data)) {
      return data?.id === COMMON.nullUuid ? [] : [data];
    }
    return data;
  }

  throw new BrmError(`Unsupported element type: ${elementType}`);
};

export const useElementAllData = ({ elementId, elementName, projectId, options = {} }: IElementAllDataProps) => {
  return useQuery({
    ...options,
    queryKey: KeyFactory.dataTypeKeys.allData(elementId, elementName, projectId),
    queryFn: () => getElementAllData({ elementId, elementType: elementName }),
  });
};
