import PropTypes from "prop-types";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { useControlCatalog } from "features/brm";
import * as S from "../styles/wizard.styles";

export const Baseline = ({ baselineId, options, handleSubmit, baselineName, elementNameInUrl }) => {
  const { controlCatalogId } = useControlCatalog();

  const handleSubmission = () => {
    const opt = options["baseline-name"];
    opt.selection = [baselineName];
    opt.elementNameInUrl = elementNameInUrl;
    options["baseline-id"].selection = [baselineId];
    options["catalog-id"].selection = [controlCatalogId];
    handleSubmit(options);
  };

  return (
    <S.DefaultWizard>
      <h5 className="wizardTitle" id="baseline_title">
        Baseline Wizard
      </h5>
      <DialogButtonDivStyled>
        <DialogButtonStyled id="baseline_submit" onClick={handleSubmission}>
          Submit
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </S.DefaultWizard>
  );
};

Baseline.propTypes = {
  baselineId: PropTypes.string,
  handleSubmit: PropTypes.func,
  options: PropTypes.any,
  baselineName: PropTypes.string,
  elementNameInUrl: PropTypes.string,
};
