import PropTypes from "prop-types";
import { useState, useMemo, useRef, useCallback, useEffect } from "react";
import toast from "react-hot-toast";
// Components
import systemModals from "components/modals/Modals";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import ButtonCell from "components/EditComponents/ButtonCell";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { AddButton, DeleteButton } from "features/brm";
// Constants
import { MEANS, OPPORTUNITIES, TYPE, ATTACK_KIND } from "constants/brm";
// Styles
import * as S from "brm/styles/details-table.styles";
import { useKnowledgebase } from "features/knowledgebase";
import { BrmDetailTable } from "../BrmTables";
import RuleAddForm from "./forms/KbRuleAddForm";
import { useGetKbRules, useRuleUpdate } from "./api/rules-table-queries";
import {
  createColumnActions,
  createColumnMapped,
  createColumnMappedNoEditRuleset,
} from "../services/column/columnFactory";

const modifiedMeans = MEANS.map((e) => ({ id: e.value, name: e.label }));
const modifiedOpp = OPPORTUNITIES.map((e) => ({ id: e.value, name: e.label }));

const RuleTable = ({ kbId, selectedElement, setIsRulesetClicked, selectedRuleset }) => {
  const [addRuleModalIsOpen, setAddRuleModalIsOpen] = useState(false);
  const [selectedRule, setSelectedRule] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const updatedMeans = useRef("");
  const updatedOpportunity = useRef("");

  const { data: rulesAndUEsData, isError: isRulesAndUEsDataError } = useGetKbRules({ elementId: kbId });

  const rulesData = useMemo(() => {
    if (rulesAndUEsData && rulesAndUEsData.rules?.length) {
      return rulesAndUEsData.rules?.filter((rule) => rule.atkkind === ATTACK_KIND.cyber || ATTACK_KIND.physical);
    }
    return [];
  }, [rulesAndUEsData]);

  const ueData = useMemo(() => {
    if (rulesAndUEsData && rulesAndUEsData.ue?.length) {
      return rulesAndUEsData.ue;
    }
    return [];
  }, [rulesAndUEsData]);

  const { mutate: setRuleUpdate } = useRuleUpdate(kbId);

  const { isGlobalKb, isReadOnlyKb } = useKnowledgebase({ kbId });

  const createButton = useCallback(
    (cellProps) => {
      if (!isGlobalKb && !isReadOnlyKb) {
        return (
          <ButtonCell
            selectedRowId={selectedRule.id}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={() => {
              setEditMode(false);
              if (updatedOpportunity.current !== "" && updatedMeans.current !== "") {
                const newRules = rulesData.map((e) => {
                  if (e.id === selectedRule.id) {
                    return { ...e, means: updatedMeans.current, opportunity: updatedOpportunity.current };
                  }
                  return e;
                });
                setRuleUpdate(
                  { kbId, json: { ue: ueData, rules: newRules } },
                  {
                    onSettled: () => {
                      updatedMeans.current = "";
                      updatedOpportunity.current = "";
                    },
                  }
                );
              } else if (updatedMeans.current !== "") {
                const newRules = rulesData.map((e) => {
                  if (e.id === selectedRule.id) {
                    return { ...e, means: updatedMeans.current };
                  }
                  return e;
                });
                setRuleUpdate(
                  { kbId, json: { ue: ueData, rules: newRules } },
                  {
                    onSettled: () => {
                      updatedMeans.current = "";
                    },
                  }
                );
              } else if (updatedOpportunity.current !== "") {
                const newRules = rulesData.map((e) => {
                  if (e.id === selectedRule.id) {
                    return { ...e, opportunity: updatedOpportunity.current };
                  }
                  return e;
                });
                setRuleUpdate(
                  { kbId, json: { ue: ueData, rules: newRules } },
                  {
                    onSettled: () => {
                      updatedOpportunity.current = "";
                    },
                  }
                );
              }
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }

      return null;
    },
    [isGlobalKb, isReadOnlyKb, selectedRule.id, editMode, rulesData, ueData, setRuleUpdate, kbId]
  );

  const columns = useMemo(
    () => [
      createColumnMappedNoEditRuleset("trevcat"),
      createColumnMappedNoEditRuleset("trevpat"),
      createColumnMappedNoEditRuleset("atkkind"),
      createColumnMappedNoEditRuleset("atktactic"),
      createColumnMappedNoEditRuleset("sacat"),
      createColumnMappedNoEditRuleset("atkcat"),
      createColumnMappedNoEditRuleset("tag"),
      createColumnMapped("rulesetmeans", selectedRule.id, modifiedMeans, editMode, updatedMeans),
      createColumnMapped("rulesetopportunity", selectedRule.id, modifiedOpp, editMode, updatedOpportunity),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [createButton, editMode, selectedRule.id]
  );

  useEffect(() => {
    function multiDeleteApiCalls() {
      const newJson = rulesData.filter((e) => !selectedRows.some((each) => e.id === each.id));
      setRuleUpdate(
        { kbId, json: { rules: newJson } },
        {
          onSettled: () => {
            setConfirmDelete(false);
            setDeleteModalIsOpen(false);
            setSelectedRows([]);
          },
          onSuccess: () => {
            toast.success("Rules updated successfully");
          },
          onError: () => {
            toast.error("Error occurred while deleting rules");
          },
        }
      );
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, kbId, rulesData, selectedElement.id, selectedRows, setRuleUpdate]);

  if (isRulesAndUEsDataError) {
    return <ErrorBanner msg="Error while loading rules" />;
  }

  if (rulesData) {
    return (
      <>
        <S.RuleTitleContainer>
          <S.RuleTitle id="RuleTable_title">{selectedRuleset.name} Ruleset</S.RuleTitle>
          <S.DetailsTableButtonsContainer id="RuleTable_buttons">
            <S.RuleButton type="button" onClick={() => setIsRulesetClicked(false)} id="RuleTable_back">
              Back to rulesets
            </S.RuleButton>
            {/* {kbId !== COMMON.defaultUuid && !selectedElement.isGlobal && ( */}
            {!isGlobalKb && !isReadOnlyKb ? (
              <>
                <AddButton md onClick={() => setAddRuleModalIsOpen(true)} id="RuleTable_add" />
                {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
              </>
            ) : null}
          </S.DetailsTableButtonsContainer>
        </S.RuleTitleContainer>
        <S.DetailsContainer id="RuleTable_detailsPanel">
          {addRuleModalIsOpen &&
            systemModals.addModal(
              "RuleTable_DetailsPanel",
              addRuleModalIsOpen,
              setAddRuleModalIsOpen,
              <RuleAddForm setModalIsOpen={setAddRuleModalIsOpen} selectedElement={kbId} />,
              TYPE.rule
            )}
          {deleteModalIsOpen &&
            systemModals.deleteModal(
              "RuleTable_DetailsPanel",
              deleteModalIsOpen,
              setDeleteModalIsOpen,
              <GenericDeleteConfirmation
                elementName={TYPE.rule}
                setDeleteModalIsOpen={setDeleteModalIsOpen}
                selectedRows={selectedRows}
                setConfirmDelete={setConfirmDelete}
              />,
              TYPE.rule
            )}
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={rulesData}
              columns={columns}
              setSelectedRows={(newSelectedRows) =>
                setSelectedRows(newSelectedRows.toString() !== selectedRows.toString() ? newSelectedRows : selectedRows)
              }
              setSelectedElement={setSelectedRule}
              customProps={{ id: "RuleTable_detailsTable" }}
              showRowSelect={!isGlobalKb && !isReadOnlyKb}
            />
          </S.DetailsTableContainer>
        </S.DetailsContainer>
      </>
    );
  }

  return <Loading />;
};

RuleTable.propTypes = {
  kbId: PropTypes.string,
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isGlobal: PropTypes.bool,
  }),
  setIsRulesetClicked: PropTypes.func,
  selectedRuleset: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default RuleTable;
