/* eslint-disable import/prefer-default-export */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { nodeKeys } from "./node-query-key-factory";

/**
 * Returns a list of all nodes within a given project
 *
 * @param {string} projectId - uuid of project
 * @returns list of all nodes within the given project
 */
export const getNodes = ({ projectId }: { projectId: string }): Promise<any> => {
  return Brm.nodeApi.findNode(projectId);
};

interface IUseNodesProps {
  projectId: string;
  options?: QueryOption<typeof getNodes>;
}

/**
 * Custom-hook to retrieve all nodes for a project with additional risk data.
 *
 * @param {string} projectId - uuid of project
 * @param {string} variantId - uuid of project variant
 * @param {object} options - react-query configuration object
 * @returns react-query for findNode
 */
export const useNodes = ({ projectId, options = {} }: IUseNodesProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: nodeKeys.project(projectId),
    queryFn: () => getNodes({ projectId }),
  });
};
