import styled from "styled-components";

export const DefaultContentLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
`;
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
