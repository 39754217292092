import { endpointUrl, getFetchParams } from "services/api/graphql/graphql-config";
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from "@tanstack/react-query";
import { fetcher } from "services/api/graphql/brm-fetcher";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AccessPoint = {
  __typename?: "AccessPoint";
  category?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Activity = {
  __typename?: "Activity";
  attackers: Array<Maybe<Attacker>>;
  attacks: Array<Maybe<Attack>>;
  category?: Maybe<Scalars["String"]>;
  dataFlows: Array<Maybe<DataFlow>>;
  id: Scalars["String"];
  isBoundary?: Maybe<Scalars["Boolean"]>;
  isFinal?: Maybe<Scalars["Boolean"]>;
  isInitial?: Maybe<Scalars["Boolean"]>;
  isInternal?: Maybe<Scalars["Boolean"]>;
  missions: Array<Maybe<Mission>>;
  name: Scalars["String"];
  node?: Maybe<Node>;
};

export type AllocatedControl = {
  __typename?: "AllocatedControl";
  anobject: SystemObject;
  asset: Array<Asset>;
  calibratedStrength?: Maybe<Scalars["String"]>;
  controltype: ControlType;
  id: Scalars["ID"];
  isCompliant?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  strength?: Maybe<Scalars["String"]>;
  vulnerabilities: Array<Maybe<Vulnerability>>;
  vulnerabilitycategory?: Maybe<Scalars["String"]>;
};

export type Asset = {
  __typename?: "Asset";
  anobject: Array<Scalars["String"]>;
  categorizedentity?: Maybe<Scalars["String"]>;
  category?: Maybe<AssetCategory>;
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAssumed?: Maybe<Scalars["Boolean"]>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
  project: Scalars["String"];
  risk: Array<Risk>;
  subject?: Maybe<Scalars["String"]>;
  vulnerabilities: Array<Vulnerability>;
};

export type AssetCategory = {
  __typename?: "AssetCategory";
  asset: Array<Asset>;
  name: Scalars["String"];
  requestedVariant?: Maybe<Scalars["String"]>;
  riskDistribution?: Maybe<RiskDistribution>;
};

export type Attack = {
  __typename?: "Attack";
  attacker?: Maybe<Scalars["String"]>;
  attackercategory?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  kind?: Maybe<Scalars["String"]>;
  likelihood?: Maybe<CalibrationValue>;
  mechanism?: Maybe<Scalars["String"]>;
  mitigatedLikelihood?: Maybe<CalibrationValue>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
  path?: Maybe<Scalars["String"]>;
  project: Scalars["String"];
  requiredMeans?: Maybe<Scalars["String"]>;
  requiredOpportunity?: Maybe<Scalars["String"]>;
  satype?: Maybe<Scalars["String"]>;
  surface?: Maybe<Scalars["String"]>;
  systemElements: Array<Maybe<SystemElement>>;
  systemasset?: Maybe<Scalars["String"]>;
  tactic?: Maybe<Scalars["String"]>;
  tag?: Maybe<Scalars["String"]>;
  target?: Maybe<SystemElement>;
  trev?: Maybe<ThreatEvent>;
  trevcat?: Maybe<Scalars["String"]>;
  trevpattern?: Maybe<Scalars["String"]>;
  ttp?: Maybe<Scalars["String"]>;
  vulnerabilities: Array<Vulnerability>;
};

export type AttackSurface = {
  __typename?: "AttackSurface";
  attackVectors: Array<Maybe<AttackVector>>;
  attackers: Array<Maybe<Attacker>>;
  category?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
};

export type AttackVector = {
  __typename?: "AttackVector";
  category?: Maybe<Scalars["String"]>;
  exchange?: Maybe<Exchange>;
  id: Scalars["String"];
  name: Scalars["String"];
  node?: Maybe<Node>;
};

export type Attacker = {
  __typename?: "Attacker";
  accessPoints: Array<Maybe<AccessPoint>>;
  attackVectors: Array<Maybe<AttackVector>>;
  category?: Maybe<Scalars["String"]>;
  entryPoints: Array<Maybe<EntryPoint>>;
  id: Scalars["String"];
  name: Scalars["String"];
  surfaces: Array<Maybe<AttackSurface>>;
  systemAssets: Array<Maybe<SystemAsset>>;
  systemElements: Array<Maybe<SystemElement>>;
  targets: Array<Maybe<SystemObject>>;
  vulnerabilities: Array<Maybe<Vulnerability>>;
};

export type Bus = {
  __typename?: "Bus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CalibrationValue = {
  __typename?: "CalibrationValue";
  calcStringValue?: Maybe<Scalars["String"]>;
  calcValue?: Maybe<Scalars["Float"]>;
  calibratedStringValue?: Maybe<Scalars["String"]>;
  calibratedValue?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
  variant?: Maybe<Scalars["String"]>;
};

export type Capability = {
  __typename?: "Capability";
  attackers: Array<Maybe<Attacker>>;
  attacks: Array<Maybe<Attack>>;
  id: Scalars["String"];
  isInternal?: Maybe<Scalars["Boolean"]>;
  missions: Array<Maybe<Mission>>;
  name: Scalars["String"];
  resources: Array<Maybe<Resource>>;
};

export type CategorizedEntity = {
  __typename?: "CategorizedEntity";
  categorization: Array<ImpactStatement>;
  classification?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  subject?: Maybe<SystemElement>;
};

export type Channel = {
  __typename?: "Channel";
  category?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isBoundary?: Maybe<Scalars["Boolean"]>;
  isInternal?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  noun?: Maybe<Scalars["String"]>;
};

export enum CompletionStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  Started = "STARTED",
  Unknown = "UNKNOWN",
}

export type ControlCatalog = {
  __typename?: "ControlCatalog";
  id: Scalars["ID"];
  importedAt?: Maybe<Scalars["String"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  isGlobal?: Maybe<Scalars["Boolean"]>;
  isImported?: Maybe<Scalars["Boolean"]>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  organization?: Maybe<Organization>;
  standard?: Maybe<Scalars["String"]>;
};

export type ControlFamily = {
  __typename?: "ControlFamily";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ControlType = {
  __typename?: "ControlType";
  catalog?: Maybe<ControlCatalog>;
  cost?: Maybe<Scalars["String"]>;
  family?: Maybe<ControlFamily>;
  id: Scalars["ID"];
  name: Scalars["String"];
  strength?: Maybe<Scalars["String"]>;
};

export type DataFlow = {
  __typename?: "DataFlow";
  attackers: Array<Maybe<Attacker>>;
  attacks: Array<Maybe<Attack>>;
  category?: Maybe<Scalars["String"]>;
  dataTypes: Array<Maybe<DataType>>;
  id: Scalars["String"];
  isBoundary?: Maybe<Scalars["Boolean"]>;
  isInternal?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  nodes: Array<Maybe<Node>>;
  note?: Maybe<Scalars["String"]>;
};

export type DataType = {
  __typename?: "DataType";
  activities: Array<Maybe<Activity>>;
  attackers: Array<Maybe<Attacker>>;
  attacks: Array<Maybe<Attack>>;
  capabilities: Array<Maybe<Capability>>;
  categorizedEntity?: Maybe<CategorizedEntity>;
  category?: Maybe<Scalars["String"]>;
  channels: Array<Maybe<Channel>>;
  createdAt?: Maybe<Scalars["String"]>;
  dataFlows: Array<Maybe<DataFlow>>;
  exchanges: Array<Maybe<Exchange>>;
  id: Scalars["ID"];
  isInput?: Maybe<Scalars["Boolean"]>;
  isInternal?: Maybe<Scalars["Boolean"]>;
  isOutput?: Maybe<Scalars["Boolean"]>;
  isProcessed?: Maybe<Scalars["Boolean"]>;
  isStored?: Maybe<Scalars["Boolean"]>;
  missions: Array<Maybe<Mission>>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  nodes: Array<Maybe<Node>>;
  note?: Maybe<Scalars["String"]>;
  persons: Array<Maybe<Person>>;
  systemAssets: Array<SystemAsset>;
};

export type Embedded = {
  __typename?: "Embedded";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type EntryPoint = {
  __typename?: "EntryPoint";
  category?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Exchange = {
  __typename?: "Exchange";
  attackers: Array<Maybe<Attacker>>;
  attacks: Array<Maybe<Attack>>;
  capabilities: Array<Maybe<Capability>>;
  category?: Maybe<Scalars["String"]>;
  channels: Array<Maybe<Channel>>;
  consumer?: Maybe<Node>;
  controls: Array<AllocatedControl>;
  createdAt?: Maybe<Scalars["String"]>;
  data?: Maybe<DataType>;
  dataFlows: Array<Maybe<DataFlow>>;
  findings: Array<Finding>;
  id: Scalars["ID"];
  isBoundary?: Maybe<Scalars["Boolean"]>;
  isInput?: Maybe<Scalars["Boolean"]>;
  isInternal?: Maybe<Scalars["Boolean"]>;
  missions: Array<Maybe<Mission>>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
  producer?: Maybe<Node>;
  riskDistributionPercent?: Maybe<Scalars["Float"]>;
  riskDistributionRank?: Maybe<Scalars["Int"]>;
  vulnerabilities: Array<Vulnerability>;
};

export type Finding = {
  __typename?: "Finding";
  category?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  cwe?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lineNumber?: Maybe<Scalars["Int"]>;
  modifiedAt?: Maybe<Scalars["String"]>;
  performer?: Maybe<SystemElement>;
  project: Project;
  resource?: Maybe<Scalars["String"]>;
  sfp?: Maybe<Scalars["Int"]>;
  tool?: Maybe<Scalars["String"]>;
  weakness?: Maybe<Scalars["String"]>;
};

export type ImpactStatement = {
  __typename?: "ImpactStatement";
  id: Scalars["ID"];
  level?: Maybe<Scalars["String"]>;
  objective?: Maybe<Embedded>;
};

export type KnowledgeBase = {
  __typename?: "KnowledgeBase";
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isGlobal?: Maybe<Scalars["Boolean"]>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  organization?: Maybe<Organization>;
  version?: Maybe<Scalars["String"]>;
};

export type Link = {
  __typename?: "Link";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Mission = {
  __typename?: "Mission";
  activities: Array<Maybe<Activity>>;
  attackers: Array<Maybe<Attacker>>;
  attacks: Array<Maybe<Attack>>;
  dataTypes: Array<Maybe<DataType>>;
  exchanges: Array<Maybe<Exchange>>;
  id: Scalars["String"];
  isInternal?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  nodes: Array<Maybe<Node>>;
  persons: Array<Maybe<Person>>;
  resources: Array<Maybe<Resource>>;
};

export type Mutation = {
  __typename?: "Mutation";
  addCategorizedEntityCategorization?: Maybe<Scalars["Boolean"]>;
  calcRisk?: Maybe<Scalars["ID"]>;
  cloneVariant?: Maybe<Scalars["Boolean"]>;
  deleteNodes?: Maybe<Scalars["ID"]>;
  importSctm?: Maybe<Scalars["ID"]>;
  mitigateAndCalcVulnerability?: Maybe<Scalars["ID"]>;
  moveNode?: Maybe<Scalars["ID"]>;
  removeCategorizedEntityCategorization?: Maybe<Scalars["Boolean"]>;
  setAllAllocatedControlsCalibratedStrength?: Maybe<Scalars["Boolean"]>;
  setAllAllocatedControlsIsCompliant?: Maybe<Scalars["Boolean"]>;
  setAttackLikelihood?: Maybe<Scalars["Boolean"]>;
  setUndesiredEventImpact?: Maybe<Scalars["Boolean"]>;
  setUserAssignedRole?: Maybe<Scalars["Boolean"]>;
};

export type MutationAddCategorizedEntityCategorizationArgs = {
  id: Scalars["ID"];
  level: Scalars["String"];
  objective: Scalars["String"];
};

export type MutationCalcRiskArgs = {
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type MutationCloneVariantArgs = {
  project: Scalars["ID"];
  variant: Scalars["ID"];
};

export type MutationDeleteNodesArgs = {
  exchanges?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  nodes?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  project?: InputMaybe<Scalars["ID"]>;
};

export type MutationImportSctmArgs = {
  body?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type MutationMitigateAndCalcVulnerabilityArgs = {
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
  vulns?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type MutationMoveNodeArgs = {
  id: Scalars["ID"];
  isInternal: Scalars["Boolean"];
  parent: Scalars["ID"];
};

export type MutationRemoveCategorizedEntityCategorizationArgs = {
  categorization: Scalars["ID"];
  id: Scalars["ID"];
};

export type MutationSetAllAllocatedControlsCalibratedStrengthArgs = {
  calibratedStrength: Scalars["String"];
  ids: Array<InputMaybe<Scalars["ID"]>>;
  project: Scalars["ID"];
  variant: Scalars["ID"];
};

export type MutationSetAllAllocatedControlsIsCompliantArgs = {
  ids: Array<InputMaybe<Scalars["ID"]>>;
  isCompliant: Scalars["Boolean"];
  project: Scalars["ID"];
  variant: Scalars["ID"];
};

export type MutationSetAttackLikelihoodArgs = {
  calibratedStringValue: Scalars["String"];
  ids: Array<InputMaybe<Scalars["ID"]>>;
  project: Scalars["ID"];
  variant: Scalars["ID"];
};

export type MutationSetUndesiredEventImpactArgs = {
  calibratedStringValue: Scalars["String"];
  ids: Array<InputMaybe<Scalars["ID"]>>;
  project: Scalars["ID"];
  variant: Scalars["ID"];
};

export type MutationSetUserAssignedRoleArgs = {
  assignedRole?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  userId: Scalars["ID"];
};

export type Node = {
  __typename?: "Node";
  attackers: Array<Maybe<Attacker>>;
  attacks: Array<Maybe<Attack>>;
  category: Scalars["String"];
  controlFamilyCount?: Maybe<Scalars["Int"]>;
  controls: Array<AllocatedControl>;
  controlsCount?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["String"]>;
  dataFlows?: Maybe<Array<Maybe<DataFlow>>>;
  findingCount?: Maybe<Scalars["Int"]>;
  findings: Array<Finding>;
  id: Scalars["ID"];
  inData?: Maybe<Array<Maybe<DataType>>>;
  isBoundary: Scalars["Boolean"];
  isInternal: Scalars["Boolean"];
  missions?: Maybe<Array<Maybe<Mission>>>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
  outData?: Maybe<Array<Maybe<DataType>>>;
  parent?: Maybe<Node>;
  parentId?: Maybe<Scalars["ID"]>;
  resources?: Maybe<Array<Maybe<Resource>>>;
  riskDistributionPercent?: Maybe<Scalars["Float"]>;
  riskDistributionRank?: Maybe<Scalars["Int"]>;
  structuralcat: Scalars["String"];
  vulnerabilities: Array<Vulnerability>;
  vulnerabilitiesCount?: Maybe<Scalars["Int"]>;
};

export type Organization = {
  __typename?: "Organization";
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
};

export type Person = {
  __typename?: "Person";
  attackers: Array<Maybe<Attacker>>;
  attacks: Array<Maybe<Attack>>;
  capabilities: Array<Maybe<Capability>>;
  category?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isInternal?: Maybe<Scalars["Boolean"]>;
  missions: Array<Maybe<Mission>>;
  name: Scalars["String"];
  resources: Array<Maybe<Resource>>;
};

export type Project = {
  __typename?: "Project";
  controlCatalog?: Maybe<ControlCatalog>;
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isListed?: Maybe<Scalars["Boolean"]>;
  isShared?: Maybe<Scalars["Boolean"]>;
  kb?: Maybe<KnowledgeBase>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  organization?: Maybe<Organization>;
  setting: Array<Setting>;
  standardBaseline?: Maybe<StandardBaseline>;
  status?: Maybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  activity?: Maybe<Activity>;
  allocatedControl?: Maybe<AllocatedControl>;
  allocatedControls: Array<Maybe<AllocatedControl>>;
  asset?: Maybe<Asset>;
  assetcat?: Maybe<AssetCategory>;
  assetcats: Array<AssetCategory>;
  attack?: Maybe<Attack>;
  attackSurface?: Maybe<AttackSurface>;
  attacker?: Maybe<Attacker>;
  calcRiskStatus?: Maybe<CompletionStatus>;
  capability?: Maybe<Capability>;
  categorizedEntity?: Maybe<CategorizedEntity>;
  dataFlow?: Maybe<DataFlow>;
  dataType?: Maybe<DataType>;
  datatypeCount?: Maybe<Scalars["Int"]>;
  deleteNodesStatus?: Maybe<CompletionStatus>;
  exchange?: Maybe<Exchange>;
  exchangeCount?: Maybe<Scalars["Int"]>;
  exchanges: Array<Exchange>;
  exportFindings?: Maybe<Scalars["String"]>;
  exportSystemFacts?: Maybe<Scalars["String"]>;
  importSctmStatus?: Maybe<CompletionStatus>;
  listDerivedActivitiesForAccessPoint?: Maybe<Array<Maybe<Activity>>>;
  listDerivedActivitiesForAttackVector?: Maybe<Array<Maybe<Activity>>>;
  listDerivedActivitiesForEntryPoint?: Maybe<Array<Maybe<Activity>>>;
  mission?: Maybe<Mission>;
  mitigateAndCalcControlCount?: Maybe<Scalars["Int"]>;
  node?: Maybe<Node>;
  nodeCount?: Maybe<Scalars["Int"]>;
  nodes: Array<Node>;
  person?: Maybe<Person>;
  projectFindAllByUser?: Maybe<Array<Maybe<Project>>>;
  projectFindAllByUserWithKb?: Maybe<Array<Maybe<Project>>>;
  resource?: Maybe<Resource>;
  risk?: Maybe<Risk>;
  risks: Array<Risk>;
  systemAsset?: Maybe<SystemAsset>;
  systemAssetType?: Maybe<SystemAssetType>;
  systemElement?: Maybe<SystemElement>;
  systemElementsByIds: Array<SystemElement>;
  target?: Maybe<SystemElement>;
  threatGroups?: Maybe<Array<Maybe<ThreatGroup>>>;
  trev?: Maybe<ThreatEvent>;
  ue?: Maybe<UndesiredEvent>;
  undesiredEvent?: Maybe<UndesiredEvent>;
  vulnerabilities: Array<Maybe<Vulnerability>>;
  vulnerability?: Maybe<Vulnerability>;
};

export type QueryActivityArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
};

export type QueryAllocatedControlArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QueryAllocatedControlsArgs = {
  sctm: Scalars["ID"];
};

export type QueryAssetArgs = {
  asset: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QueryAssetcatArgs = {
  assetcat: Scalars["String"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QueryAssetcatsArgs = {
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QueryAttackArgs = {
  attack: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QueryAttackSurfaceArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
};

export type QueryAttackerArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QueryCalcRiskStatusArgs = {
  run: Scalars["ID"];
};

export type QueryCapabilityArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
};

export type QueryCategorizedEntityArgs = {
  dataTypeId: Scalars["ID"];
  project: Scalars["ID"];
};

export type QueryDataFlowArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
};

export type QueryDataTypeArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
};

export type QueryDatatypeCountArgs = {
  project: Scalars["ID"];
};

export type QueryDeleteNodesStatusArgs = {
  run: Scalars["ID"];
};

export type QueryExchangeArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant: Scalars["ID"];
};

export type QueryExchangeCountArgs = {
  project: Scalars["ID"];
};

export type QueryExchangesArgs = {
  category?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  project: Scalars["ID"];
  tag?: InputMaybe<Scalars["String"]>;
  variant: Scalars["ID"];
};

export type QueryExportFindingsArgs = {
  projectId: Scalars["ID"];
};

export type QueryExportSystemFactsArgs = {
  project: Scalars["ID"];
};

export type QueryImportSctmStatusArgs = {
  run: Scalars["ID"];
};

export type QueryListDerivedActivitiesForAccessPointArgs = {
  accessPointId: Scalars["ID"];
  project: Scalars["ID"];
};

export type QueryListDerivedActivitiesForAttackVectorArgs = {
  attackVectorId: Scalars["ID"];
  project: Scalars["ID"];
};

export type QueryListDerivedActivitiesForEntryPointArgs = {
  entryPointId: Scalars["ID"];
  project: Scalars["ID"];
};

export type QueryMissionArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
};

export type QueryMitigateAndCalcControlCountArgs = {
  run: Scalars["ID"];
};

export type QueryNodeArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant: Scalars["ID"];
};

export type QueryNodeCountArgs = {
  project: Scalars["ID"];
};

export type QueryNodesArgs = {
  category?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  project: Scalars["ID"];
  structuralcat?: InputMaybe<Scalars["String"]>;
  tag?: InputMaybe<Scalars["String"]>;
  variant: Scalars["ID"];
};

export type QueryPersonArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
};

export type QueryProjectFindAllByUserArgs = {
  userId: Scalars["ID"];
};

export type QueryProjectFindAllByUserWithKbArgs = {
  userId: Scalars["ID"];
};

export type QueryResourceArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
};

export type QueryRiskArgs = {
  risk: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QueryRisksArgs = {
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QuerySystemAssetArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QuerySystemAssetTypeArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant: Scalars["ID"];
};

export type QuerySystemElementArgs = {
  id: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QuerySystemElementsByIdsArgs = {
  ids: Array<InputMaybe<Scalars["ID"]>>;
  project: Scalars["ID"];
};

export type QueryTargetArgs = {
  target: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QueryTrevArgs = {
  trev: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QueryUeArgs = {
  ue: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QueryUndesiredEventArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QueryVulnerabilitiesArgs = {
  category?: InputMaybe<Scalars["String"]>;
  object?: InputMaybe<Scalars["ID"]>;
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type QueryVulnerabilityArgs = {
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
};

export type Resource = {
  __typename?: "Resource";
  attackers: Array<Maybe<Attacker>>;
  attacks: Array<Maybe<Attack>>;
  capabilities: Array<Maybe<Capability>>;
  id: Scalars["String"];
  isInternal?: Maybe<Scalars["Boolean"]>;
  missions: Array<Maybe<Mission>>;
  name: Scalars["String"];
  nodes: Array<Maybe<Node>>;
  persons: Array<Maybe<Person>>;
};

export type Risk = {
  __typename?: "Risk";
  asset?: Maybe<Asset>;
  averageLikelihood?: Maybe<CalibrationValue>;
  category?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  impact?: Maybe<CalibrationValue>;
  likelihood?: Maybe<CalibrationValue>;
  mitigatedAverageLikelihood?: Maybe<CalibrationValue>;
  mitigatedLikelihood?: Maybe<CalibrationValue>;
  mitigatedRiskLevel?: Maybe<Scalars["String"]>;
  mitigatedScore?: Maybe<CalibrationValue>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
  objective?: Maybe<Scalars["String"]>;
  objectiveObject?: Maybe<Embedded>;
  percent?: Maybe<CalibrationValue>;
  project?: Maybe<Scalars["String"]>;
  rank?: Maybe<CalibrationValue>;
  riskLevel?: Maybe<Scalars["String"]>;
  score?: Maybe<CalibrationValue>;
  ue: Array<UndesiredEvent>;
  vulnerabilities: Array<Maybe<Vulnerability>>;
};

export type RiskDistribution = {
  __typename?: "RiskDistribution";
  percent?: Maybe<Scalars["Float"]>;
  rank?: Maybe<Scalars["Int"]>;
};

export type Setting = {
  __typename?: "Setting";
  id: Scalars["ID"];
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  value: Scalars["String"];
};

export type StandardBaseline = {
  __typename?: "StandardBaseline";
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
};

export type SystemAsset = {
  __typename?: "SystemAsset";
  all_controls: Array<Maybe<AllocatedControl>>;
  attackers: Array<Maybe<Attacker>>;
  category?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  owner?: Maybe<SystemObject>;
  project: Project;
  satype?: Maybe<SystemAssetType>;
  vulnerabilities: Array<Vulnerability>;
};

export type SystemAssetType = {
  __typename?: "SystemAssetType";
  all_controls: Array<Maybe<AllocatedControl>>;
  attackers: Array<Maybe<Attacker>>;
  attacks: Array<Maybe<Attack>>;
  category?: Maybe<Scalars["String"]>;
  cpe?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  risks: Array<Risk>;
  trevs: Array<ThreatEvent>;
  ues: Array<UndesiredEvent>;
  vulnerabilities: Array<Vulnerability>;
};

export type SystemElement = {
  __typename?: "SystemElement";
  all_attackers: Array<Attacker>;
  all_attacks: Array<Attack>;
  all_controls: Array<AllocatedControl>;
  all_systemAssets: Array<SystemAsset>;
  all_trev: Array<ThreatEvent>;
  all_ue: Array<UndesiredEvent>;
  all_vulnerabilities: Array<Vulnerability>;
  attack: Array<Attack>;
  attacker: Array<Attacker>;
  category?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isInternal?: Maybe<Scalars["Boolean"]>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
  noun?: Maybe<Scalars["String"]>;
  project: Scalars["String"];
  systemAssets: Array<SystemAsset>;
  trev: Array<ThreatEvent>;
  ue: Array<UndesiredEvent>;
  vulnerabilities: Array<Vulnerability>;
};

export type SystemObject = {
  __typename?: "SystemObject";
  id: Scalars["ID"];
  isBoundary?: Maybe<Scalars["Boolean"]>;
  isInternal?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  noun?: Maybe<Scalars["String"]>;
};

export type ThreatCatalog = {
  __typename?: "ThreatCatalog";
  id: Scalars["String"];
  importedAt?: Maybe<Scalars["String"]>;
  isGlobal?: Maybe<Scalars["Boolean"]>;
  isImported?: Maybe<Scalars["Boolean"]>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  organization?: Maybe<Organization>;
};

export type ThreatEvent = {
  __typename?: "ThreatEvent";
  attack: Array<Attack>;
  category?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  likelihood?: Maybe<CalibrationValue>;
  mitigatedLikelihood?: Maybe<CalibrationValue>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
  pattern?: Maybe<Scalars["String"]>;
  project: Scalars["String"];
  subject?: Maybe<SystemElement>;
  systemasset: Array<Scalars["String"]>;
  target?: Maybe<SystemElement>;
  ue: Array<UndesiredEvent>;
  vulnerabilities: Array<Vulnerability>;
};

export type ThreatGroup = {
  __typename?: "ThreatGroup";
  catalog: ThreatCatalog;
  id: Scalars["String"];
  isNationState?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  tier?: Maybe<Scalars["String"]>;
};

export type UndesiredEvent = {
  __typename?: "UndesiredEvent";
  anobject?: Maybe<Embedded>;
  asset?: Maybe<Asset>;
  attacks: Array<Attack>;
  averageLikelihood?: Maybe<CalibrationValue>;
  category?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  criticality?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  impact?: Maybe<CalibrationValue>;
  likelihood?: Maybe<CalibrationValue>;
  mitigatedAverageLikelihood?: Maybe<CalibrationValue>;
  mitigatedLikelihood?: Maybe<CalibrationValue>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
  objective?: Maybe<Embedded>;
  project: Scalars["String"];
  rfactor?: Maybe<CalibrationValue>;
  risk: Array<Risk>;
  severity?: Maybe<Scalars["String"]>;
  systemasset: Array<Scalars["String"]>;
  target?: Maybe<SystemElement>;
  trev: Array<ThreatEvent>;
  vulnerabilities: Array<Vulnerability>;
};

export type Variant = {
  __typename?: "Variant";
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isCompliance?: Maybe<Scalars["Boolean"]>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type Vulnerability = {
  __typename?: "Vulnerability";
  anobject?: Maybe<SystemObject>;
  asset: Array<Asset>;
  category?: Maybe<Scalars["String"]>;
  controlCount?: Maybe<Scalars["Int"]>;
  findingCount?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  level?: Maybe<CalibrationValue>;
  name: Scalars["String"];
  rank?: Maybe<Scalars["Int"]>;
  ues: Array<UndesiredEvent>;
};

export type GetVulnerabilityAssetQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetVulnerabilityAssetQuery = {
  __typename?: "Query";
  vulnerability?: {
    __typename?: "Vulnerability";
    name: string;
    asset: Array<{
      __typename?: "Asset";
      id: string;
      name: string;
      category?: { __typename?: "AssetCategory"; name: string } | null;
    }>;
  } | null;
};

export type GetTargetAttacksQueryVariables = Exact<{
  target: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetTargetAttacksQuery = {
  __typename?: "Query";
  target?: {
    __typename?: "SystemElement";
    name: string;
    all_attacks: Array<{
      __typename?: "Attack";
      id: string;
      name: string;
      systemasset?: string | null;
      kind?: string | null;
      tactic?: string | null;
      mechanism?: string | null;
      target?: { __typename?: "SystemElement"; name: string } | null;
      trev?: { __typename?: "ThreatEvent"; id: string; name: string } | null;
    }>;
  } | null;
};

export type GetTargetAttackersQueryVariables = Exact<{
  target: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetTargetAttackersQuery = {
  __typename?: "Query";
  target?: {
    __typename?: "SystemElement";
    name: string;
    all_attackers: Array<{ __typename?: "Attacker"; id: string; name: string; category?: string | null }>;
  } | null;
};

export type GetSystemAssetTypeRiskQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetSystemAssetTypeRiskQuery = {
  __typename?: "Query";
  systemAssetType?: {
    __typename?: "SystemAssetType";
    name: string;
    risks: Array<{
      __typename?: "Risk";
      id: string;
      name: string;
      category?: string | null;
      rank?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
        value?: number | null;
        calibratedValue?: number | null;
        calcValue?: number | null;
      } | null;
      objectiveObject?: { __typename?: "Embedded"; id: string; name?: string | null } | null;
      score?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
        value?: number | null;
        calibratedValue?: number | null;
        calcValue?: number | null;
      } | null;
      mitigatedScore?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
        value?: number | null;
        calibratedValue?: number | null;
        calcValue?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetTargetSystemAssetsQueryVariables = Exact<{
  target: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetTargetSystemAssetsQuery = {
  __typename?: "Query";
  target?: {
    __typename?: "SystemElement";
    name: string;
    systemAssets: Array<{
      __typename?: "SystemAsset";
      id: string;
      name: string;
      category?: string | null;
      satype?: {
        __typename?: "SystemAssetType";
        id: string;
        name: string;
        category?: string | null;
        cpe?: string | null;
      } | null;
      owner?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    }>;
  } | null;
};

export type GetRiskTrevQueryVariables = Exact<{
  risk: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetRiskTrevQuery = {
  __typename?: "Query";
  risk?: {
    __typename?: "Risk";
    name: string;
    ue: Array<{
      __typename?: "UndesiredEvent";
      id: string;
      trev: Array<{
        __typename?: "ThreatEvent";
        id: string;
        name: string;
        note?: string | null;
        project: string;
        category?: string | null;
        pattern?: string | null;
        systemasset: Array<string>;
        createdAt?: string | null;
        modifiedAt?: string | null;
        likelihood?: {
          __typename?: "CalibrationValue";
          calcStringValue?: string | null;
          calibratedStringValue?: string | null;
        } | null;
        mitigatedLikelihood?: {
          __typename?: "CalibrationValue";
          calcStringValue?: string | null;
          calibratedStringValue?: string | null;
        } | null;
        subject?: { __typename?: "SystemElement"; id: string; name: string; noun?: string | null } | null;
        target?: { __typename?: "SystemElement"; id: string; noun?: string | null; name: string } | null;
      }>;
    }>;
  } | null;
};

export type GetTrevQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetTrevQuery = {
  __typename?: "Query";
  systemAssetType?: {
    __typename?: "SystemAssetType";
    trevs: Array<{
      __typename?: "ThreatEvent";
      id: string;
      name: string;
      project: string;
      category?: string | null;
      pattern?: string | null;
      createdAt?: string | null;
      modifiedAt?: string | null;
      subject?: { __typename?: "SystemElement"; id: string; name: string; noun?: string | null } | null;
      target?: { __typename?: "SystemElement"; id: string; noun?: string | null; name: string } | null;
    }>;
  } | null;
};

export type GetVulnerabilityUEsQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetVulnerabilityUEsQuery = {
  __typename?: "Query";
  vulnerability?: {
    __typename?: "Vulnerability";
    name: string;
    ues: Array<{
      __typename?: "UndesiredEvent";
      id: string;
      name: string;
      category?: string | null;
      impact?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      objective?: { __typename?: "Embedded"; id: string; name?: string | null } | null;
      anobject?: { __typename?: "Embedded"; id: string; name?: string | null } | null;
    }>;
  } | null;
};

export type GetSystemAssetTypeUEsQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetSystemAssetTypeUEsQuery = {
  __typename?: "Query";
  systemAssetType?: {
    __typename?: "SystemAssetType";
    name: string;
    ues: Array<{
      __typename?: "UndesiredEvent";
      id: string;
      name: string;
      category?: string | null;
      impact?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      objective?: { __typename?: "Embedded"; id: string; name?: string | null } | null;
      anobject?: { __typename?: "Embedded"; id: string; name?: string | null } | null;
    }>;
  } | null;
};

export type SetUserAssignedRoleMutationVariables = Exact<{
  userId: Scalars["ID"];
  assignedRole: Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>;
}>;

export type SetUserAssignedRoleMutation = { __typename?: "Mutation"; setUserAssignedRole?: boolean | null };

export type AutoMitigateMutationVariables = Exact<{
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
  vulns?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type AutoMitigateMutation = { __typename?: "Mutation"; mitigateAndCalcVulnerability?: string | null };

export type AutoMitigateCountQueryVariables = Exact<{
  run: Scalars["ID"];
}>;

export type AutoMitigateCountQuery = { __typename?: "Query"; mitigateAndCalcControlCount?: number | null };

export type CalcRiskMutationVariables = Exact<{
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type CalcRiskMutation = { __typename?: "Mutation"; calcRisk?: string | null };

export type CalcRiskStatusQueryVariables = Exact<{
  run: Scalars["ID"];
}>;

export type CalcRiskStatusQuery = { __typename?: "Query"; calcRiskStatus?: CompletionStatus | null };

export type AssetCatsQueryVariables = Exact<{
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type AssetCatsQuery = {
  __typename?: "Query";
  assetcats: Array<{
    __typename?: "AssetCategory";
    name: string;
    riskDistribution?: { __typename?: "RiskDistribution"; rank?: number | null; percent?: number | null } | null;
  }>;
};

export type SetUndesiredEventImpactMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>;
  project: Scalars["ID"];
  variant: Scalars["ID"];
  calibratedStringValue: Scalars["String"];
}>;

export type SetUndesiredEventImpactMutation = { __typename?: "Mutation"; setUndesiredEventImpact?: boolean | null };

export type SetAttackLikelihoodMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>;
  project: Scalars["ID"];
  variant: Scalars["ID"];
  calibratedStringValue: Scalars["String"];
}>;

export type SetAttackLikelihoodMutation = { __typename?: "Mutation"; setAttackLikelihood?: boolean | null };

export type GetProjectCatalogConfigurationQueryVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type GetProjectCatalogConfigurationQuery = {
  __typename?: "Query";
  projectFindAllByUser?: Array<{
    __typename?: "Project";
    id: string;
    name: string;
    isListed?: boolean | null;
    createdAt?: string | null;
    modifiedAt?: string | null;
    status?: string | null;
    isShared?: boolean | null;
    organization?: {
      __typename?: "Organization";
      id: string;
      name: string;
      createdAt?: string | null;
      modifiedAt?: string | null;
      status?: string | null;
    } | null;
    setting: Array<{ __typename?: "Setting"; name: string; value: string }>;
    controlCatalog?: {
      __typename?: "ControlCatalog";
      id: string;
      name: string;
      standard?: string | null;
      isDefault?: boolean | null;
      isGlobal?: boolean | null;
      isImported?: boolean | null;
      importedAt?: string | null;
      modifiedAt?: string | null;
    } | null;
    standardBaseline?: { __typename?: "StandardBaseline"; id: string; name?: string | null } | null;
  } | null> | null;
};

export type GetKbProjectConfigurationQueryVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type GetKbProjectConfigurationQuery = {
  __typename?: "Query";
  projectFindAllByUserWithKb?: Array<{
    __typename?: "Project";
    id: string;
    name: string;
    createdAt?: string | null;
    modifiedAt?: string | null;
    isListed?: boolean | null;
    status?: string | null;
    isShared?: boolean | null;
    kb?: {
      __typename?: "KnowledgeBase";
      id: string;
      name: string;
      isGlobal?: boolean | null;
      createdAt?: string | null;
      modifiedAt?: string | null;
      version?: string | null;
      organization?: {
        __typename?: "Organization";
        id: string;
        name: string;
        createdAt?: string | null;
        modifiedAt?: string | null;
        status?: string | null;
      } | null;
    } | null;
    organization?: {
      __typename?: "Organization";
      id: string;
      name: string;
      createdAt?: string | null;
      modifiedAt?: string | null;
      status?: string | null;
    } | null;
    setting: Array<{ __typename?: "Setting"; name: string; value: string }>;
  } | null> | null;
};

export type SetAllAllocatedControlsIsCompliantMutationVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  ids: Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>;
  isCompliant: Scalars["Boolean"];
}>;

export type SetAllAllocatedControlsIsCompliantMutation = {
  __typename?: "Mutation";
  setAllAllocatedControlsIsCompliant?: boolean | null;
};

export type SetAllAllocatedControlsCalibratedStrengthMutationVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  ids: Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>;
  calibratedStrength: Scalars["String"];
}>;

export type SetAllAllocatedControlsCalibratedStrengthMutation = {
  __typename?: "Mutation";
  setAllAllocatedControlsCalibratedStrength?: boolean | null;
};

export type GetTargetAllocatedControlsQueryVariables = Exact<{
  target: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetTargetAllocatedControlsQuery = {
  __typename?: "Query";
  target?: {
    __typename?: "SystemElement";
    name: string;
    all_controls: Array<{
      __typename?: "AllocatedControl";
      id: string;
      name: string;
      vulnerabilitycategory?: string | null;
      strength?: string | null;
      controltype: {
        __typename?: "ControlType";
        id: string;
        name: string;
        family?: { __typename?: "ControlFamily"; id: string; name: string } | null;
      };
      anobject: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null };
    }>;
  } | null;
};

export type GetSystemAssetAllocatedControlsQueryVariables = Exact<{
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetSystemAssetAllocatedControlsQuery = {
  __typename?: "Query";
  systemAsset?: {
    __typename?: "SystemAsset";
    name: string;
    all_controls: Array<{
      __typename?: "AllocatedControl";
      id: string;
      name: string;
      vulnerabilitycategory?: string | null;
      strength?: string | null;
      controltype: {
        __typename?: "ControlType";
        id: string;
        name: string;
        family?: { __typename?: "ControlFamily"; id: string; name: string } | null;
      };
      anobject: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null };
    } | null>;
  } | null;
};

export type GetSystemAssetTypeAllocatedControlsQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetSystemAssetTypeAllocatedControlsQuery = {
  __typename?: "Query";
  systemAssetType?: {
    __typename?: "SystemAssetType";
    name: string;
    all_controls: Array<{
      __typename?: "AllocatedControl";
      id: string;
      name: string;
      vulnerabilitycategory?: string | null;
      strength?: string | null;
      controltype: {
        __typename?: "ControlType";
        id: string;
        name: string;
        family?: { __typename?: "ControlFamily"; id: string; name: string } | null;
      };
      anobject: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null };
    } | null>;
  } | null;
};

export type CloneVariantMutationVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
}>;

export type CloneVariantMutation = { __typename?: "Mutation"; cloneVariant?: boolean | null };

export type GetAllVulnerabilitiesQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
}>;

export type GetAllVulnerabilitiesQuery = {
  __typename?: "Query";
  vulnerabilities: Array<{
    __typename?: "Vulnerability";
    id: string;
    name: string;
    category?: string | null;
    rank?: number | null;
    findingCount?: number | null;
    controlCount?: number | null;
    level?: { __typename?: "CalibrationValue"; calcStringValue?: string | null } | null;
    anobject?: { __typename?: "SystemObject"; id: string; noun?: string | null; name: string } | null;
  } | null>;
};

export type GetActivityMissionsQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetActivityMissionsQuery = {
  __typename?: "Query";
  activity?: {
    __typename?: "Activity";
    id: string;
    name: string;
    missions: Array<{ __typename?: "Mission"; id: string; name: string } | null>;
  } | null;
};

export type GetActivityDataflowsQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetActivityDataflowsQuery = {
  __typename?: "Query";
  activity?: {
    __typename?: "Activity";
    id: string;
    name: string;
    dataFlows: Array<{ __typename?: "DataFlow"; id: string; name: string; category?: string | null } | null>;
  } | null;
};

export type GetCapabilityMissionsQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetCapabilityMissionsQuery = {
  __typename?: "Query";
  capability?: {
    __typename?: "Capability";
    id: string;
    name: string;
    missions: Array<{ __typename?: "Mission"; id: string; name: string; isInternal?: boolean | null } | null>;
  } | null;
};

export type GetCapabilityResourcesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetCapabilityResourcesQuery = {
  __typename?: "Query";
  capability?: {
    __typename?: "Capability";
    id: string;
    name: string;
    resources: Array<{ __typename?: "Resource"; id: string; name: string } | null>;
  } | null;
};

export type GetDataTypeActivitiesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetDataTypeActivitiesQuery = {
  __typename?: "Query";
  dataType?: {
    __typename?: "DataType";
    id: string;
    name: string;
    activities: Array<{
      __typename?: "Activity";
      id: string;
      name: string;
      category?: string | null;
      isInternal?: boolean | null;
      isBoundary?: boolean | null;
      isInitial?: boolean | null;
      isFinal?: boolean | null;
      node?: { __typename?: "Node"; id: string; name: string } | null;
    } | null>;
  } | null;
};

export type GetDataTypeCapabilitiesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetDataTypeCapabilitiesQuery = {
  __typename?: "Query";
  dataType?: {
    __typename?: "DataType";
    id: string;
    name: string;
    capabilities: Array<{ __typename?: "Capability"; id: string; name: string; isInternal?: boolean | null } | null>;
  } | null;
};

export type GetDataTypeDataflowsQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetDataTypeDataflowsQuery = {
  __typename?: "Query";
  dataType?: {
    __typename?: "DataType";
    id: string;
    name: string;
    dataFlows: Array<{ __typename?: "DataFlow"; id: string; name: string; category?: string | null } | null>;
  } | null;
};

export type GetDataTypeExchangesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetDataTypeExchangesQuery = {
  __typename?: "Query";
  dataType?: {
    __typename?: "DataType";
    id: string;
    name: string;
    exchanges: Array<{ __typename?: "Exchange"; id: string; name: string; category?: string | null } | null>;
  } | null;
};

export type GetDataTypeMissionsQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetDataTypeMissionsQuery = {
  __typename?: "Query";
  dataType?: {
    __typename?: "DataType";
    id: string;
    name: string;
    missions: Array<{ __typename?: "Mission"; id: string; name: string; isInternal?: boolean | null } | null>;
  } | null;
};

export type GetDataTypeNodesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetDataTypeNodesQuery = {
  __typename?: "Query";
  dataType?: {
    __typename?: "DataType";
    id: string;
    name: string;
    nodes: Array<{
      __typename?: "Node";
      id: string;
      name: string;
      category: string;
      structuralcat: string;
      isInternal: boolean;
    } | null>;
  } | null;
};

export type GetDataTypePersonsQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetDataTypePersonsQuery = {
  __typename?: "Query";
  dataType?: {
    __typename?: "DataType";
    id: string;
    name: string;
    persons: Array<{
      __typename?: "Person";
      id: string;
      name: string;
      category?: string | null;
      isInternal?: boolean | null;
    } | null>;
  } | null;
};

export type GetDataflowNodesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetDataflowNodesQuery = {
  __typename?: "Query";
  dataFlow?: {
    __typename?: "DataFlow";
    id: string;
    name: string;
    nodes: Array<{
      __typename?: "Node";
      id: string;
      name: string;
      category: string;
      structuralcat: string;
      isInternal: boolean;
    } | null>;
  } | null;
};

export type GetDataflowDataTypesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetDataflowDataTypesQuery = {
  __typename?: "Query";
  dataFlow?: {
    __typename?: "DataFlow";
    id: string;
    name: string;
    dataTypes: Array<{
      __typename?: "DataType";
      id: string;
      name: string;
      category?: string | null;
      isInternal?: boolean | null;
      isInput?: boolean | null;
      isOutput?: boolean | null;
      isProcessed?: boolean | null;
      isStored?: boolean | null;
    } | null>;
  } | null;
};

export type GetExchangeCapabilitiesQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetExchangeCapabilitiesQuery = {
  __typename?: "Query";
  exchange?: {
    __typename?: "Exchange";
    id: string;
    name: string;
    capabilities: Array<{ __typename?: "Capability"; id: string; name: string; isInternal?: boolean | null } | null>;
  } | null;
};

export type GetExchangeDataflowsQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetExchangeDataflowsQuery = {
  __typename?: "Query";
  exchange?: {
    __typename?: "Exchange";
    id: string;
    name: string;
    dataFlows: Array<{ __typename?: "DataFlow"; id: string; name: string; category?: string | null } | null>;
  } | null;
};

export type GetExchangeMissionsQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetExchangeMissionsQuery = {
  __typename?: "Query";
  exchange?: {
    __typename?: "Exchange";
    id: string;
    name: string;
    missions: Array<{ __typename?: "Mission"; id: string; name: string; isInternal?: boolean | null } | null>;
  } | null;
};

export type GetExchangesWithClassificationsQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
}>;

export type GetExchangesWithClassificationsQuery = {
  __typename?: "Query";
  exchanges: Array<{
    __typename?: "Exchange";
    id: string;
    name: string;
    isBoundary?: boolean | null;
    producer?: { __typename?: "Node"; id: string; name: string } | null;
    consumer?: { __typename?: "Node"; id: string; name: string } | null;
    data?: {
      __typename?: "DataType";
      id: string;
      name: string;
      categorizedEntity?: { __typename?: "CategorizedEntity"; id: string } | null;
    } | null;
  }>;
};

export type GetAssetVulnerabilityQueryVariables = Exact<{
  asset: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetAssetVulnerabilityQuery = {
  __typename?: "Query";
  asset?: {
    __typename?: "Asset";
    name: string;
    vulnerabilities: Array<{
      __typename?: "Vulnerability";
      id: string;
      name: string;
      rank?: number | null;
      category?: string | null;
      level?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      anobject?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    }>;
  } | null;
};

export type GetUndesiredEventVulnerabilityQueryVariables = Exact<{
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetUndesiredEventVulnerabilityQuery = {
  __typename?: "Query";
  undesiredEvent?: {
    __typename?: "UndesiredEvent";
    name: string;
    vulnerabilities: Array<{
      __typename?: "Vulnerability";
      id: string;
      name: string;
      rank?: number | null;
      category?: string | null;
      findingCount?: number | null;
      controlCount?: number | null;
      level?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      anobject?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    }>;
  } | null;
};

export type GetThreatEventVulnerabilityQueryVariables = Exact<{
  trev: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetThreatEventVulnerabilityQuery = {
  __typename?: "Query";
  trev?: {
    __typename?: "ThreatEvent";
    name: string;
    vulnerabilities: Array<{
      __typename?: "Vulnerability";
      id: string;
      name: string;
      category?: string | null;
      rank?: number | null;
      findingCount?: number | null;
      controlCount?: number | null;
      level?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      anobject?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    }>;
  } | null;
};

export type GetSystemAssetVulnerabilityQueryVariables = Exact<{
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetSystemAssetVulnerabilityQuery = {
  __typename?: "Query";
  systemAsset?: {
    __typename?: "SystemAsset";
    name: string;
    vulnerabilities: Array<{
      __typename?: "Vulnerability";
      id: string;
      name: string;
      rank?: number | null;
      category?: string | null;
      findingCount?: number | null;
      controlCount?: number | null;
      level?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      anobject?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    }>;
  } | null;
};

export type GetSystemAssetTypeVulnerabilityQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetSystemAssetTypeVulnerabilityQuery = {
  __typename?: "Query";
  systemAssetType?: {
    __typename?: "SystemAssetType";
    name: string;
    vulnerabilities: Array<{
      __typename?: "Vulnerability";
      id: string;
      name: string;
      category?: string | null;
      rank?: number | null;
      findingCount?: number | null;
      controlCount?: number | null;
      level?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      anobject?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    }>;
  } | null;
};

export type GetTargetVulnerabilityQueryVariables = Exact<{
  target: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetTargetVulnerabilityQuery = {
  __typename?: "Query";
  target?: {
    __typename?: "SystemElement";
    id: string;
    name: string;
    all_vulnerabilities: Array<{
      __typename?: "Vulnerability";
      id: string;
      name: string;
      category?: string | null;
      rank?: number | null;
      findingCount?: number | null;
      controlCount?: number | null;
      level?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      anobject?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    }>;
  } | null;
};

export type GetRiskVulnerabilitiesQueryVariables = Exact<{
  risk: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetRiskVulnerabilitiesQuery = {
  __typename?: "Query";
  risk?: {
    __typename?: "Risk";
    id: string;
    name: string;
    vulnerabilities: Array<{
      __typename?: "Vulnerability";
      id: string;
      name: string;
      rank?: number | null;
      category?: string | null;
      findingCount?: number | null;
      controlCount?: number | null;
      level?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      anobject?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    } | null>;
  } | null;
};

export type GetExchangeVulnerabilitiesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
  variant: Scalars["ID"];
}>;

export type GetExchangeVulnerabilitiesQuery = {
  __typename?: "Query";
  exchange?: {
    __typename?: "Exchange";
    id: string;
    name: string;
    vulnerabilities: Array<{
      __typename?: "Vulnerability";
      id: string;
      name: string;
      rank?: number | null;
      category?: string | null;
      findingCount?: number | null;
      controlCount?: number | null;
      level?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      anobject?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    }>;
  } | null;
};

export type GetNodeVulnerabilitiesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
  variant: Scalars["ID"];
}>;

export type GetNodeVulnerabilitiesQuery = {
  __typename?: "Query";
  node?: {
    __typename?: "Node";
    id: string;
    name: string;
    vulnerabilities: Array<{
      __typename?: "Vulnerability";
      id: string;
      name: string;
      rank?: number | null;
      category?: string | null;
      findingCount?: number | null;
      controlCount?: number | null;
      level?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      anobject?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    }>;
  } | null;
};

export type GetAttackVulnerabilitiesQueryVariables = Exact<{
  attack: Scalars["ID"];
  variant: Scalars["ID"];
}>;

export type GetAttackVulnerabilitiesQuery = {
  __typename?: "Query";
  attack?: {
    __typename?: "Attack";
    id: string;
    name: string;
    vulnerabilities: Array<{
      __typename?: "Vulnerability";
      id: string;
      name: string;
      rank?: number | null;
      category?: string | null;
      findingCount?: number | null;
      controlCount?: number | null;
      level?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      anobject?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    }>;
  } | null;
};

export type GetAttackerVulnerabilitiesQueryVariables = Exact<{
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetAttackerVulnerabilitiesQuery = {
  __typename?: "Query";
  attacker?: {
    __typename?: "Attacker";
    id: string;
    name: string;
    vulnerabilities: Array<{
      __typename?: "Vulnerability";
      id: string;
      name: string;
      rank?: number | null;
      category?: string | null;
      findingCount?: number | null;
      controlCount?: number | null;
      level?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      anobject?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    } | null>;
  } | null;
};

export type GetAllocatedControlVulnerabilitiesQueryVariables = Exact<{
  id: Scalars["ID"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetAllocatedControlVulnerabilitiesQuery = {
  __typename?: "Query";
  allocatedControl?: {
    __typename?: "AllocatedControl";
    id: string;
    name: string;
    vulnerabilities: Array<{
      __typename?: "Vulnerability";
      id: string;
      name: string;
      rank?: number | null;
      category?: string | null;
      findingCount?: number | null;
      controlCount?: number | null;
      level?: {
        __typename?: "CalibrationValue";
        calcStringValue?: string | null;
        calibratedStringValue?: string | null;
      } | null;
      anobject?: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null } | null;
    } | null>;
  } | null;
};

export type GetMissionResourcesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetMissionResourcesQuery = {
  __typename?: "Query";
  mission?: {
    __typename?: "Mission";
    id: string;
    name: string;
    resources: Array<{ __typename?: "Resource"; id: string; name: string } | null>;
  } | null;
};

export type GetMissionActivitiesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetMissionActivitiesQuery = {
  __typename?: "Query";
  mission?: {
    __typename?: "Mission";
    id: string;
    name: string;
    activities: Array<{
      __typename?: "Activity";
      id: string;
      name: string;
      category?: string | null;
      isInternal?: boolean | null;
      isBoundary?: boolean | null;
      isInitial?: boolean | null;
      isFinal?: boolean | null;
      node?: { __typename?: "Node"; id: string; name: string } | null;
    } | null>;
  } | null;
};

export type GetMissionExchangesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetMissionExchangesQuery = {
  __typename?: "Query";
  mission?: {
    __typename?: "Mission";
    id: string;
    name: string;
    exchanges: Array<{ __typename?: "Exchange"; id: string; name: string; category?: string | null } | null>;
  } | null;
};

export type GetMissionNodesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetMissionNodesQuery = {
  __typename?: "Query";
  mission?: {
    __typename?: "Mission";
    id: string;
    name: string;
    nodes: Array<{
      __typename?: "Node";
      id: string;
      name: string;
      category: string;
      structuralcat: string;
      isInternal: boolean;
    } | null>;
  } | null;
};

export type GetMissionPersonsQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetMissionPersonsQuery = {
  __typename?: "Query";
  mission?: {
    __typename?: "Mission";
    id: string;
    name: string;
    persons: Array<{
      __typename?: "Person";
      id: string;
      name: string;
      category?: string | null;
      isInternal?: boolean | null;
    } | null>;
  } | null;
};

export type GetMissionDataTypesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetMissionDataTypesQuery = {
  __typename?: "Query";
  mission?: {
    __typename?: "Mission";
    id: string;
    name: string;
    dataTypes: Array<{
      __typename?: "DataType";
      id: string;
      name: string;
      category?: string | null;
      isInternal?: boolean | null;
      isInput?: boolean | null;
      isOutput?: boolean | null;
      isProcessed?: boolean | null;
      isStored?: boolean | null;
    } | null>;
  } | null;
};

export type GetNodeMissionQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetNodeMissionQuery = {
  __typename?: "Query";
  node?: {
    __typename?: "Node";
    id: string;
    name: string;
    missions?: Array<{ __typename?: "Mission"; id: string; name: string; isInternal?: boolean | null } | null> | null;
  } | null;
};

export type GetNodeDataflowsQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetNodeDataflowsQuery = {
  __typename?: "Query";
  node?: {
    __typename?: "Node";
    id: string;
    name: string;
    dataFlows?: Array<{ __typename?: "DataFlow"; id: string; name: string; category?: string | null } | null> | null;
  } | null;
};

export type GetNodeResourcesQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetNodeResourcesQuery = {
  __typename?: "Query";
  node?: {
    __typename?: "Node";
    id: string;
    name: string;
    resources?: Array<{ __typename?: "Resource"; id: string; name: string } | null> | null;
  } | null;
};

export type GetNodeParentsQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
}>;

export type GetNodeParentsQuery = {
  __typename?: "Query";
  nodes: Array<{ __typename?: "Node"; id: string; name: string; structuralcat: string; isInternal: boolean }>;
};

export type GetNodeInDataQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetNodeInDataQuery = {
  __typename?: "Query";
  node?: {
    __typename?: "Node";
    id: string;
    name: string;
    inData?: Array<{
      __typename?: "DataType";
      id: string;
      name: string;
      category?: string | null;
      isInternal?: boolean | null;
      isInput?: boolean | null;
      isOutput?: boolean | null;
      isProcessed?: boolean | null;
      isStored?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetNodeOutDataQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetNodeOutDataQuery = {
  __typename?: "Query";
  node?: {
    __typename?: "Node";
    id: string;
    name: string;
    outData?: Array<{
      __typename?: "DataType";
      id: string;
      name: string;
      category?: string | null;
      isInternal?: boolean | null;
      isInput?: boolean | null;
      isOutput?: boolean | null;
      isProcessed?: boolean | null;
      isStored?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetAllNodesQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
}>;

export type GetAllNodesQuery = {
  __typename?: "Query";
  nodes: Array<{
    __typename?: "Node";
    id: string;
    name: string;
    structuralcat: string;
    category: string;
    isBoundary: boolean;
    isInternal: boolean;
    riskDistributionRank?: number | null;
    riskDistributionPercent?: number | null;
    findingCount?: number | null;
    controlCount?: number | null;
    parent?: {
      __typename?: "Node";
      id: string;
      name: string;
      structuralcat: string;
      category: string;
      isBoundary: boolean;
      isInternal: boolean;
      riskDistributionRank?: number | null;
      riskDistributionPercent?: number | null;
      findingCount?: number | null;
      controlCount?: number | null;
      parent?: { __typename?: "Node"; id: string } | null;
    } | null;
  }>;
};

export type GetPersonCapabilitiesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetPersonCapabilitiesQuery = {
  __typename?: "Query";
  person?: {
    __typename?: "Person";
    id: string;
    name: string;
    capabilities: Array<{ __typename?: "Capability"; id: string; name: string; isInternal?: boolean | null } | null>;
  } | null;
};

export type GetPersonMissionsQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetPersonMissionsQuery = {
  __typename?: "Query";
  person?: {
    __typename?: "Person";
    id: string;
    name: string;
    missions: Array<{ __typename?: "Mission"; id: string; name: string; isInternal?: boolean | null } | null>;
  } | null;
};

export type GetPersonResourcesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetPersonResourcesQuery = {
  __typename?: "Query";
  person?: {
    __typename?: "Person";
    id: string;
    name: string;
    resources: Array<{ __typename?: "Resource"; id: string; name: string } | null>;
  } | null;
};

export type GetResourceMissionsQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetResourceMissionsQuery = {
  __typename?: "Query";
  resource?: {
    __typename?: "Resource";
    id: string;
    name: string;
    missions: Array<{ __typename?: "Mission"; id: string; name: string; isInternal?: boolean | null } | null>;
  } | null;
};

export type GetResourceCapabilitiesQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetResourceCapabilitiesQuery = {
  __typename?: "Query";
  resource?: {
    __typename?: "Resource";
    id: string;
    name: string;
    capabilities: Array<{ __typename?: "Capability"; id: string; name: string; isInternal?: boolean | null } | null>;
  } | null;
};

export type GetResourcePersonsQueryVariables = Exact<{
  project: Scalars["ID"];
  id: Scalars["ID"];
}>;

export type GetResourcePersonsQuery = {
  __typename?: "Query";
  resource?: {
    __typename?: "Resource";
    id: string;
    name: string;
    persons: Array<{
      __typename?: "Person";
      id: string;
      name: string;
      category?: string | null;
      isInternal?: boolean | null;
    } | null>;
  } | null;
};

export type GetSctmAllocatedControlsQueryVariables = Exact<{
  sctm: Scalars["ID"];
}>;

export type GetSctmAllocatedControlsQuery = {
  __typename?: "Query";
  allocatedControls: Array<{
    __typename?: "AllocatedControl";
    id: string;
    name: string;
    calibratedStrength?: string | null;
    strength?: string | null;
    vulnerabilitycategory?: string | null;
    isCompliant?: boolean | null;
    anobject: { __typename?: "SystemObject"; id: string; name: string; noun?: string | null };
    controltype: {
      __typename?: "ControlType";
      id: string;
      name: string;
      cost?: string | null;
      family?: { __typename?: "ControlFamily"; id: string; name: string } | null;
      catalog?: { __typename?: "ControlCatalog"; id: string; name: string } | null;
    };
  } | null>;
};

export type GetSystemElementNamesQueryVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"];
  project: Scalars["ID"];
}>;

export type GetSystemElementNamesQuery = {
  __typename?: "Query";
  systemElementsByIds: Array<{ __typename?: "SystemElement"; id: string; name: string; noun?: string | null }>;
};

export type GetAttackerTargetQueryVariables = Exact<{
  id: Scalars["ID"];
  project: Scalars["ID"];
}>;

export type GetAttackerTargetQuery = {
  __typename?: "Query";
  attacker?: {
    __typename?: "Attacker";
    id: string;
    name: string;
    targets: Array<{ __typename?: "SystemObject"; id: string; name: string } | null>;
  } | null;
};

export type GetThreatGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetThreatGroupsQuery = {
  __typename?: "Query";
  threatGroups?: Array<{
    __typename?: "ThreatGroup";
    id: string;
    name: string;
    isNationState?: boolean | null;
    tier?: string | null;
    catalog: {
      __typename?: "ThreatCatalog";
      id: string;
      name?: string | null;
      organization?: { __typename?: "Organization"; id: string } | null;
    };
  } | null> | null;
};

export type GetAssetCatTreeQueryVariables = Exact<{
  assetcat: Scalars["String"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetAssetCatTreeQuery = {
  __typename?: "Query";
  assetcat?: {
    __typename?: "AssetCategory";
    name: string;
    riskDistribution?: { __typename?: "RiskDistribution"; rank?: number | null; percent?: number | null } | null;
  } | null;
};

export type GetAssetCategoryTreeQueryVariables = Exact<{
  assetcat: Scalars["String"];
  project: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetAssetCategoryTreeQuery = {
  __typename?: "Query";
  assetcat?: {
    __typename?: "AssetCategory";
    name: string;
    asset: Array<{
      __typename?: "Asset";
      id: string;
      name: string;
      category?: { __typename?: "AssetCategory"; name: string } | null;
      risk: Array<{
        __typename?: "Risk";
        id: string;
        name: string;
        category?: string | null;
        rank?: { __typename?: "CalibrationValue"; value?: number | null } | null;
        ue: Array<{
          __typename?: "UndesiredEvent";
          id: string;
          name: string;
          category?: string | null;
          target?: { __typename?: "SystemElement"; id: string; name: string; noun?: string | null } | null;
          trev: Array<{
            __typename?: "ThreatEvent";
            id: string;
            name: string;
            category?: string | null;
            attack: Array<{ __typename?: "Attack"; id: string; name: string; tactic?: string | null }>;
          }>;
        }>;
      }>;
    }>;
  } | null;
};

export type GetAssetTreeQueryVariables = Exact<{
  asset: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetAssetTreeQuery = {
  __typename?: "Query";
  asset?: {
    __typename?: "Asset";
    id: string;
    name: string;
    category?: { __typename?: "AssetCategory"; name: string } | null;
    risk: Array<{
      __typename?: "Risk";
      id: string;
      name: string;
      category?: string | null;
      rank?: { __typename?: "CalibrationValue"; value?: number | null } | null;
      ue: Array<{
        __typename?: "UndesiredEvent";
        id: string;
        name: string;
        category?: string | null;
        target?: { __typename?: "SystemElement"; id: string; name: string; noun?: string | null } | null;
        trev: Array<{
          __typename?: "ThreatEvent";
          id: string;
          name: string;
          category?: string | null;
          attack: Array<{ __typename?: "Attack"; id: string; name: string; tactic?: string | null }>;
        }>;
      }>;
    }>;
  } | null;
};

export type GetRiskTreeQueryVariables = Exact<{
  risk: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetRiskTreeQuery = {
  __typename?: "Query";
  risk?: {
    __typename?: "Risk";
    id: string;
    name: string;
    category?: string | null;
    rank?: { __typename?: "CalibrationValue"; value?: number | null } | null;
    ue: Array<{
      __typename?: "UndesiredEvent";
      id: string;
      name: string;
      category?: string | null;
      target?: { __typename?: "SystemElement"; id: string; name: string; noun?: string | null } | null;
      trev: Array<{
        __typename?: "ThreatEvent";
        id: string;
        name: string;
        category?: string | null;
        attack: Array<{ __typename?: "Attack"; id: string; name: string; tactic?: string | null }>;
      }>;
    }>;
    asset?: {
      __typename?: "Asset";
      id: string;
      name: string;
      category?: { __typename?: "AssetCategory"; name: string } | null;
    } | null;
  } | null;
};

export type GetUndesiredEventTreeQueryVariables = Exact<{
  ue: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetUndesiredEventTreeQuery = {
  __typename?: "Query";
  ue?: {
    __typename?: "UndesiredEvent";
    id: string;
    name: string;
    category?: string | null;
    target?: { __typename?: "SystemElement"; id: string; name: string; noun?: string | null } | null;
    trev: Array<{
      __typename?: "ThreatEvent";
      id: string;
      name: string;
      category?: string | null;
      attack: Array<{ __typename?: "Attack"; id: string; name: string; tactic?: string | null }>;
    }>;
    risk: Array<{
      __typename?: "Risk";
      id: string;
      name: string;
      category?: string | null;
      rank?: { __typename?: "CalibrationValue"; value?: number | null } | null;
      asset?: {
        __typename?: "Asset";
        id: string;
        name: string;
        category?: { __typename?: "AssetCategory"; name: string } | null;
      } | null;
    }>;
  } | null;
};

export type GetTargetTreeQueryVariables = Exact<{
  target: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetTargetTreeQuery = {
  __typename?: "Query";
  target?: {
    __typename?: "SystemElement";
    id: string;
    name: string;
    noun?: string | null;
    trev: Array<{
      __typename?: "ThreatEvent";
      id: string;
      name: string;
      category?: string | null;
      attack: Array<{ __typename?: "Attack"; id: string; name: string; tactic?: string | null }>;
    }>;
    ue: Array<{
      __typename?: "UndesiredEvent";
      id: string;
      name: string;
      category?: string | null;
      target?: { __typename?: "SystemElement"; id: string; name: string; noun?: string | null } | null;
      risk: Array<{
        __typename?: "Risk";
        id: string;
        name: string;
        category?: string | null;
        rank?: { __typename?: "CalibrationValue"; value?: number | null } | null;
        asset?: {
          __typename?: "Asset";
          id: string;
          name: string;
          category?: { __typename?: "AssetCategory"; name: string } | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type GetThreatEventTreeQueryVariables = Exact<{
  trev: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetThreatEventTreeQuery = {
  __typename?: "Query";
  trev?: {
    __typename?: "ThreatEvent";
    id: string;
    name: string;
    category?: string | null;
    target?: { __typename?: "SystemElement"; id: string; name: string; noun?: string | null } | null;
    ue: Array<{
      __typename?: "UndesiredEvent";
      id: string;
      name: string;
      category?: string | null;
      risk: Array<{
        __typename?: "Risk";
        id: string;
        name: string;
        category?: string | null;
        rank?: { __typename?: "CalibrationValue"; value?: number | null } | null;
        asset?: {
          __typename?: "Asset";
          id: string;
          name: string;
          category?: { __typename?: "AssetCategory"; name: string } | null;
        } | null;
      }>;
    }>;
    attack: Array<{ __typename?: "Attack"; id: string; name: string; tactic?: string | null }>;
  } | null;
};

export type GetAttackTreeQueryVariables = Exact<{
  attack: Scalars["ID"];
  variant?: InputMaybe<Scalars["ID"]>;
}>;

export type GetAttackTreeQuery = {
  __typename?: "Query";
  attack?: {
    __typename?: "Attack";
    id: string;
    name: string;
    tactic?: string | null;
    trev?: {
      __typename?: "ThreatEvent";
      id: string;
      name: string;
      category?: string | null;
      target?: { __typename?: "SystemElement"; id: string; name: string; noun?: string | null } | null;
      ue: Array<{
        __typename?: "UndesiredEvent";
        id: string;
        name: string;
        category?: string | null;
        target?: { __typename?: "SystemElement"; id: string; name: string; noun?: string | null } | null;
        risk: Array<{
          __typename?: "Risk";
          id: string;
          name: string;
          category?: string | null;
          rank?: { __typename?: "CalibrationValue"; value?: number | null } | null;
          asset?: {
            __typename?: "Asset";
            id: string;
            name: string;
            category?: { __typename?: "AssetCategory"; name: string } | null;
          } | null;
        }>;
      }>;
    } | null;
  } | null;
};

export type SystemDiagramDataQueryVariables = Exact<{
  project: Scalars["ID"];
  variant: Scalars["ID"];
  withRisk: Scalars["Boolean"];
}>;

export type SystemDiagramDataQuery = {
  __typename?: "Query";
  nodes: Array<{
    __typename?: "Node";
    id: string;
    name: string;
    isInternal: boolean;
    category: string;
    structuralcat: string;
    riskDistributionRank?: number | null;
    riskDistributionPercent?: number | null;
    controlFamilyCount?: number | null;
    controlsCount?: number | null;
    findingCount?: number | null;
    vulnerabilitiesCount?: number | null;
    parent?: { __typename?: "Node"; id: string } | null;
  }>;
  exchanges: Array<{
    __typename?: "Exchange";
    id: string;
    name: string;
    category?: string | null;
    isInternal?: boolean | null;
    riskDistributionRank?: number | null;
    riskDistributionPercent?: number | null;
    data?: {
      __typename?: "DataType";
      categorizedEntity?: { __typename?: "CategorizedEntity"; id: string; classification?: string | null } | null;
    } | null;
    producer?: { __typename?: "Node"; id: string; name: string } | null;
    consumer?: { __typename?: "Node"; id: string; name: string } | null;
  }>;
};

export type DeleteItemsMutationVariables = Exact<{
  project: Scalars["ID"];
  nodes: Array<Scalars["ID"]> | Scalars["ID"];
  exchanges: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeleteItemsMutation = { __typename?: "Mutation"; deleteNodes?: string | null };

export type GetDeleteJobStatusQueryVariables = Exact<{
  run: Scalars["ID"];
}>;

export type GetDeleteJobStatusQuery = { __typename?: "Query"; deleteNodesStatus?: CompletionStatus | null };

export type SystemDiagramNodeFragment = {
  __typename?: "Node";
  id: string;
  name: string;
  isInternal: boolean;
  category: string;
  structuralcat: string;
  riskDistributionRank?: number | null;
  riskDistributionPercent?: number | null;
  controlFamilyCount?: number | null;
  controlsCount?: number | null;
  findingCount?: number | null;
  vulnerabilitiesCount?: number | null;
  parent?: { __typename?: "Node"; id: string } | null;
};

export type SystemDiagramExchangeFragment = {
  __typename?: "Exchange";
  id: string;
  name: string;
  category?: string | null;
  isInternal?: boolean | null;
  riskDistributionRank?: number | null;
  riskDistributionPercent?: number | null;
  data?: {
    __typename?: "DataType";
    categorizedEntity?: { __typename?: "CategorizedEntity"; id: string; classification?: string | null } | null;
  } | null;
  producer?: { __typename?: "Node"; id: string; name: string } | null;
  consumer?: { __typename?: "Node"; id: string; name: string } | null;
};

export type ImportSctmMutationVariables = Exact<{
  id: Scalars["ID"];
  body?: InputMaybe<Scalars["String"]>;
}>;

export type ImportSctmMutation = { __typename?: "Mutation"; importSctm?: string | null };

export type ImportSctmStatusQueryVariables = Exact<{
  run: Scalars["ID"];
}>;

export type ImportSctmStatusQuery = { __typename?: "Query"; importSctmStatus?: CompletionStatus | null };

export const SystemDiagramNodeFragmentDoc = `
    fragment SystemDiagramNode on Node {
  id
  name
  isInternal
  parent {
    id
  }
  category
  structuralcat
  riskDistributionRank @include(if: $withRisk)
  riskDistributionPercent @include(if: $withRisk)
  controlFamilyCount @include(if: $withRisk)
  controlsCount @include(if: $withRisk)
  findingCount @include(if: $withRisk)
  vulnerabilitiesCount @include(if: $withRisk)
}
    `;
export const SystemDiagramExchangeFragmentDoc = `
    fragment SystemDiagramExchange on Exchange {
  id
  name
  category
  isInternal
  riskDistributionRank @include(if: $withRisk)
  riskDistributionPercent @include(if: $withRisk)
  data {
    categorizedEntity {
      id
      classification
    }
  }
  producer {
    id
    name
  }
  consumer {
    id
    name
  }
}
    `;
export const GetVulnerabilityAssetDocument = `
    query getVulnerabilityAsset($project: ID!, $id: ID!, $variant: ID) {
  vulnerability(project: $project, id: $id, variant: $variant) {
    name
    asset {
      id
      name
      category {
        name
      }
    }
  }
}
    `;
export const useGetVulnerabilityAssetQuery = <TData = GetVulnerabilityAssetQuery, TError = unknown>(
  variables: GetVulnerabilityAssetQueryVariables,
  options?: UseQueryOptions<GetVulnerabilityAssetQuery, TError, TData>
) =>
  useQuery<GetVulnerabilityAssetQuery, TError, TData>(
    ["getVulnerabilityAsset", variables],
    fetcher<GetVulnerabilityAssetQuery, GetVulnerabilityAssetQueryVariables>(GetVulnerabilityAssetDocument, variables),
    options
  );

useGetVulnerabilityAssetQuery.getKey = (variables: GetVulnerabilityAssetQueryVariables) => [
  "getVulnerabilityAsset",
  variables,
];
export const GetTargetAttacksDocument = `
    query getTargetAttacks($target: ID!, $variant: ID) {
  target(target: $target, variant: $variant) {
    name
    all_attacks {
      id
      name
      target {
        name
      }
      systemasset
      trev {
        id
        name
      }
      kind
      tactic
      mechanism
    }
  }
}
    `;
export const useGetTargetAttacksQuery = <TData = GetTargetAttacksQuery, TError = unknown>(
  variables: GetTargetAttacksQueryVariables,
  options?: UseQueryOptions<GetTargetAttacksQuery, TError, TData>
) =>
  useQuery<GetTargetAttacksQuery, TError, TData>(
    ["getTargetAttacks", variables],
    fetcher<GetTargetAttacksQuery, GetTargetAttacksQueryVariables>(GetTargetAttacksDocument, variables),
    options
  );

useGetTargetAttacksQuery.getKey = (variables: GetTargetAttacksQueryVariables) => ["getTargetAttacks", variables];
export const GetTargetAttackersDocument = `
    query getTargetAttackers($target: ID!, $variant: ID) {
  target(target: $target, variant: $variant) {
    name
    all_attackers {
      id
      name
      category
    }
  }
}
    `;
export const useGetTargetAttackersQuery = <TData = GetTargetAttackersQuery, TError = unknown>(
  variables: GetTargetAttackersQueryVariables,
  options?: UseQueryOptions<GetTargetAttackersQuery, TError, TData>
) =>
  useQuery<GetTargetAttackersQuery, TError, TData>(
    ["getTargetAttackers", variables],
    fetcher<GetTargetAttackersQuery, GetTargetAttackersQueryVariables>(GetTargetAttackersDocument, variables),
    options
  );

useGetTargetAttackersQuery.getKey = (variables: GetTargetAttackersQueryVariables) => ["getTargetAttackers", variables];
export const GetSystemAssetTypeRiskDocument = `
    query getSystemAssetTypeRisk($project: ID!, $variant: ID!, $id: ID!) {
  systemAssetType(project: $project, variant: $variant, id: $id) {
    name
    risks {
      id
      name
      category
      rank {
        calcStringValue
        calibratedStringValue
        value
        calibratedValue
        calcValue
      }
      objectiveObject {
        id
        name
      }
      score {
        calcStringValue
        calibratedStringValue
        value
        calibratedValue
        calcValue
      }
      mitigatedScore {
        calcStringValue
        calibratedStringValue
        value
        calibratedValue
        calcValue
      }
    }
  }
}
    `;
export const useGetSystemAssetTypeRiskQuery = <TData = GetSystemAssetTypeRiskQuery, TError = unknown>(
  variables: GetSystemAssetTypeRiskQueryVariables,
  options?: UseQueryOptions<GetSystemAssetTypeRiskQuery, TError, TData>
) =>
  useQuery<GetSystemAssetTypeRiskQuery, TError, TData>(
    ["getSystemAssetTypeRisk", variables],
    fetcher<GetSystemAssetTypeRiskQuery, GetSystemAssetTypeRiskQueryVariables>(
      GetSystemAssetTypeRiskDocument,
      variables
    ),
    options
  );

useGetSystemAssetTypeRiskQuery.getKey = (variables: GetSystemAssetTypeRiskQueryVariables) => [
  "getSystemAssetTypeRisk",
  variables,
];
export const GetTargetSystemAssetsDocument = `
    query getTargetSystemAssets($target: ID!, $variant: ID) {
  target(target: $target, variant: $variant) {
    name
    systemAssets {
      id
      name
      category
      satype {
        id
        name
        category
        cpe
      }
      owner {
        id
        name
        noun
      }
    }
  }
}
    `;
export const useGetTargetSystemAssetsQuery = <TData = GetTargetSystemAssetsQuery, TError = unknown>(
  variables: GetTargetSystemAssetsQueryVariables,
  options?: UseQueryOptions<GetTargetSystemAssetsQuery, TError, TData>
) =>
  useQuery<GetTargetSystemAssetsQuery, TError, TData>(
    ["getTargetSystemAssets", variables],
    fetcher<GetTargetSystemAssetsQuery, GetTargetSystemAssetsQueryVariables>(GetTargetSystemAssetsDocument, variables),
    options
  );

useGetTargetSystemAssetsQuery.getKey = (variables: GetTargetSystemAssetsQueryVariables) => [
  "getTargetSystemAssets",
  variables,
];
export const GetRiskTrevDocument = `
    query getRiskTrev($risk: ID!, $variant: ID) {
  risk(risk: $risk, variant: $variant) {
    name
    ue {
      id
      trev {
        id
        name
        note
        project
        category
        pattern
        likelihood {
          calcStringValue
          calibratedStringValue
        }
        mitigatedLikelihood {
          calcStringValue
          calibratedStringValue
        }
        subject {
          id
          name
          noun
        }
        target {
          id
          noun
          name
        }
        systemasset
        createdAt
        modifiedAt
      }
    }
  }
}
    `;
export const useGetRiskTrevQuery = <TData = GetRiskTrevQuery, TError = unknown>(
  variables: GetRiskTrevQueryVariables,
  options?: UseQueryOptions<GetRiskTrevQuery, TError, TData>
) =>
  useQuery<GetRiskTrevQuery, TError, TData>(
    ["getRiskTrev", variables],
    fetcher<GetRiskTrevQuery, GetRiskTrevQueryVariables>(GetRiskTrevDocument, variables),
    options
  );

useGetRiskTrevQuery.getKey = (variables: GetRiskTrevQueryVariables) => ["getRiskTrev", variables];
export const GetTrevDocument = `
    query getTrev($project: ID!, $variant: ID!, $id: ID!) {
  systemAssetType(project: $project, variant: $variant, id: $id) {
    trevs {
      id
      name
      project
      category
      pattern
      subject {
        id
        name
        noun
      }
      target {
        id
        noun
        name
      }
      createdAt
      modifiedAt
    }
  }
}
    `;
export const useGetTrevQuery = <TData = GetTrevQuery, TError = unknown>(
  variables: GetTrevQueryVariables,
  options?: UseQueryOptions<GetTrevQuery, TError, TData>
) =>
  useQuery<GetTrevQuery, TError, TData>(
    ["getTrev", variables],
    fetcher<GetTrevQuery, GetTrevQueryVariables>(GetTrevDocument, variables),
    options
  );

useGetTrevQuery.getKey = (variables: GetTrevQueryVariables) => ["getTrev", variables];
export const GetVulnerabilityUEsDocument = `
    query getVulnerabilityUEs($project: ID!, $id: ID!, $variant: ID) {
  vulnerability(project: $project, id: $id, variant: $variant) {
    name
    ues {
      id
      name
      category
      impact {
        calcStringValue
        calibratedStringValue
      }
      objective {
        id
        name
      }
      anobject {
        id
        name
      }
    }
  }
}
    `;
export const useGetVulnerabilityUEsQuery = <TData = GetVulnerabilityUEsQuery, TError = unknown>(
  variables: GetVulnerabilityUEsQueryVariables,
  options?: UseQueryOptions<GetVulnerabilityUEsQuery, TError, TData>
) =>
  useQuery<GetVulnerabilityUEsQuery, TError, TData>(
    ["getVulnerabilityUEs", variables],
    fetcher<GetVulnerabilityUEsQuery, GetVulnerabilityUEsQueryVariables>(GetVulnerabilityUEsDocument, variables),
    options
  );

useGetVulnerabilityUEsQuery.getKey = (variables: GetVulnerabilityUEsQueryVariables) => [
  "getVulnerabilityUEs",
  variables,
];
export const GetSystemAssetTypeUEsDocument = `
    query getSystemAssetTypeUEs($project: ID!, $variant: ID!, $id: ID!) {
  systemAssetType(project: $project, variant: $variant, id: $id) {
    name
    ues {
      id
      name
      category
      impact {
        calcStringValue
        calibratedStringValue
      }
      objective {
        id
        name
      }
      anobject {
        id
        name
      }
    }
  }
}
    `;
export const useGetSystemAssetTypeUEsQuery = <TData = GetSystemAssetTypeUEsQuery, TError = unknown>(
  variables: GetSystemAssetTypeUEsQueryVariables,
  options?: UseQueryOptions<GetSystemAssetTypeUEsQuery, TError, TData>
) =>
  useQuery<GetSystemAssetTypeUEsQuery, TError, TData>(
    ["getSystemAssetTypeUEs", variables],
    fetcher<GetSystemAssetTypeUEsQuery, GetSystemAssetTypeUEsQueryVariables>(GetSystemAssetTypeUEsDocument, variables),
    options
  );

useGetSystemAssetTypeUEsQuery.getKey = (variables: GetSystemAssetTypeUEsQueryVariables) => [
  "getSystemAssetTypeUEs",
  variables,
];
export const SetUserAssignedRoleDocument = `
    mutation setUserAssignedRole($userId: ID!, $assignedRole: [String]!) {
  setUserAssignedRole(userId: $userId, assignedRole: $assignedRole)
}
    `;
export const useSetUserAssignedRoleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SetUserAssignedRoleMutation, TError, SetUserAssignedRoleMutationVariables, TContext>
) =>
  useMutation<SetUserAssignedRoleMutation, TError, SetUserAssignedRoleMutationVariables, TContext>(
    ["setUserAssignedRole"],
    (variables?: SetUserAssignedRoleMutationVariables) =>
      fetcher<SetUserAssignedRoleMutation, SetUserAssignedRoleMutationVariables>(
        SetUserAssignedRoleDocument,
        variables
      )(),
    options
  );
export const AutoMitigateDocument = `
    mutation autoMitigate($project: ID!, $variant: ID, $vulns: [String]) {
  mitigateAndCalcVulnerability(
    project: $project
    variant: $variant
    vulns: $vulns
  )
}
    `;
export const useAutoMitigateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AutoMitigateMutation, TError, AutoMitigateMutationVariables, TContext>
) =>
  useMutation<AutoMitigateMutation, TError, AutoMitigateMutationVariables, TContext>(
    ["autoMitigate"],
    (variables?: AutoMitigateMutationVariables) =>
      fetcher<AutoMitigateMutation, AutoMitigateMutationVariables>(AutoMitigateDocument, variables)(),
    options
  );
export const AutoMitigateCountDocument = `
    query autoMitigateCount($run: ID!) {
  mitigateAndCalcControlCount(run: $run)
}
    `;
export const useAutoMitigateCountQuery = <TData = AutoMitigateCountQuery, TError = unknown>(
  variables: AutoMitigateCountQueryVariables,
  options?: UseQueryOptions<AutoMitigateCountQuery, TError, TData>
) =>
  useQuery<AutoMitigateCountQuery, TError, TData>(
    ["autoMitigateCount", variables],
    fetcher<AutoMitigateCountQuery, AutoMitigateCountQueryVariables>(AutoMitigateCountDocument, variables),
    options
  );

useAutoMitigateCountQuery.getKey = (variables: AutoMitigateCountQueryVariables) => ["autoMitigateCount", variables];
export const CalcRiskDocument = `
    mutation calcRisk($project: ID!, $variant: ID) {
  calcRisk(project: $project, variant: $variant)
}
    `;
export const useCalcRiskMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CalcRiskMutation, TError, CalcRiskMutationVariables, TContext>
) =>
  useMutation<CalcRiskMutation, TError, CalcRiskMutationVariables, TContext>(
    ["calcRisk"],
    (variables?: CalcRiskMutationVariables) =>
      fetcher<CalcRiskMutation, CalcRiskMutationVariables>(CalcRiskDocument, variables)(),
    options
  );
export const CalcRiskStatusDocument = `
    query calcRiskStatus($run: ID!) {
  calcRiskStatus(run: $run)
}
    `;
export const useCalcRiskStatusQuery = <TData = CalcRiskStatusQuery, TError = unknown>(
  variables: CalcRiskStatusQueryVariables,
  options?: UseQueryOptions<CalcRiskStatusQuery, TError, TData>
) =>
  useQuery<CalcRiskStatusQuery, TError, TData>(
    ["calcRiskStatus", variables],
    fetcher<CalcRiskStatusQuery, CalcRiskStatusQueryVariables>(CalcRiskStatusDocument, variables),
    options
  );

useCalcRiskStatusQuery.getKey = (variables: CalcRiskStatusQueryVariables) => ["calcRiskStatus", variables];
export const AssetCatsDocument = `
    query assetCats($project: ID!, $variant: ID) {
  assetcats(project: $project, variant: $variant) {
    name
    riskDistribution {
      rank
      percent
    }
  }
}
    `;
export const useAssetCatsQuery = <TData = AssetCatsQuery, TError = unknown>(
  variables: AssetCatsQueryVariables,
  options?: UseQueryOptions<AssetCatsQuery, TError, TData>
) =>
  useQuery<AssetCatsQuery, TError, TData>(
    ["assetCats", variables],
    fetcher<AssetCatsQuery, AssetCatsQueryVariables>(AssetCatsDocument, variables),
    options
  );

useAssetCatsQuery.getKey = (variables: AssetCatsQueryVariables) => ["assetCats", variables];
export const SetUndesiredEventImpactDocument = `
    mutation setUndesiredEventImpact($ids: [ID]!, $project: ID!, $variant: ID!, $calibratedStringValue: String!) {
  setUndesiredEventImpact(
    ids: $ids
    project: $project
    variant: $variant
    calibratedStringValue: $calibratedStringValue
  )
}
    `;
export const useSetUndesiredEventImpactMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SetUndesiredEventImpactMutation,
    TError,
    SetUndesiredEventImpactMutationVariables,
    TContext
  >
) =>
  useMutation<SetUndesiredEventImpactMutation, TError, SetUndesiredEventImpactMutationVariables, TContext>(
    ["setUndesiredEventImpact"],
    (variables?: SetUndesiredEventImpactMutationVariables) =>
      fetcher<SetUndesiredEventImpactMutation, SetUndesiredEventImpactMutationVariables>(
        SetUndesiredEventImpactDocument,
        variables
      )(),
    options
  );
export const SetAttackLikelihoodDocument = `
    mutation setAttackLikelihood($ids: [ID]!, $project: ID!, $variant: ID!, $calibratedStringValue: String!) {
  setAttackLikelihood(
    ids: $ids
    project: $project
    variant: $variant
    calibratedStringValue: $calibratedStringValue
  )
}
    `;
export const useSetAttackLikelihoodMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SetAttackLikelihoodMutation, TError, SetAttackLikelihoodMutationVariables, TContext>
) =>
  useMutation<SetAttackLikelihoodMutation, TError, SetAttackLikelihoodMutationVariables, TContext>(
    ["setAttackLikelihood"],
    (variables?: SetAttackLikelihoodMutationVariables) =>
      fetcher<SetAttackLikelihoodMutation, SetAttackLikelihoodMutationVariables>(
        SetAttackLikelihoodDocument,
        variables
      )(),
    options
  );
export const GetProjectCatalogConfigurationDocument = `
    query getProjectCatalogConfiguration($userId: ID!) {
  projectFindAllByUser(userId: $userId) {
    id
    name
    isListed
    createdAt
    modifiedAt
    status
    isShared
    organization {
      id
      name
      createdAt
      modifiedAt
      status
    }
    setting {
      name
      value
    }
    controlCatalog {
      id
      name
      standard
      isDefault
      isGlobal
      isImported
      importedAt
      modifiedAt
    }
    standardBaseline {
      id
      name
    }
  }
}
    `;
export const useGetProjectCatalogConfigurationQuery = <TData = GetProjectCatalogConfigurationQuery, TError = unknown>(
  variables: GetProjectCatalogConfigurationQueryVariables,
  options?: UseQueryOptions<GetProjectCatalogConfigurationQuery, TError, TData>
) =>
  useQuery<GetProjectCatalogConfigurationQuery, TError, TData>(
    ["getProjectCatalogConfiguration", variables],
    fetcher<GetProjectCatalogConfigurationQuery, GetProjectCatalogConfigurationQueryVariables>(
      GetProjectCatalogConfigurationDocument,
      variables
    ),
    options
  );

useGetProjectCatalogConfigurationQuery.getKey = (variables: GetProjectCatalogConfigurationQueryVariables) => [
  "getProjectCatalogConfiguration",
  variables,
];
export const GetKbProjectConfigurationDocument = `
    query getKbProjectConfiguration($userId: ID!) {
  projectFindAllByUserWithKb(userId: $userId) {
    id
    name
    createdAt
    modifiedAt
    isListed
    status
    isShared
    kb {
      id
      name
      isGlobal
      createdAt
      modifiedAt
      organization {
        id
        name
        createdAt
        modifiedAt
        status
      }
      version
    }
    organization {
      id
      name
      createdAt
      modifiedAt
      status
    }
    setting {
      name
      value
    }
    setting {
      name
      value
    }
  }
}
    `;
export const useGetKbProjectConfigurationQuery = <TData = GetKbProjectConfigurationQuery, TError = unknown>(
  variables: GetKbProjectConfigurationQueryVariables,
  options?: UseQueryOptions<GetKbProjectConfigurationQuery, TError, TData>
) =>
  useQuery<GetKbProjectConfigurationQuery, TError, TData>(
    ["getKbProjectConfiguration", variables],
    fetcher<GetKbProjectConfigurationQuery, GetKbProjectConfigurationQueryVariables>(
      GetKbProjectConfigurationDocument,
      variables
    ),
    options
  );

useGetKbProjectConfigurationQuery.getKey = (variables: GetKbProjectConfigurationQueryVariables) => [
  "getKbProjectConfiguration",
  variables,
];
export const SetAllAllocatedControlsIsCompliantDocument = `
    mutation setAllAllocatedControlsIsCompliant($project: ID!, $variant: ID!, $ids: [ID]!, $isCompliant: Boolean!) {
  setAllAllocatedControlsIsCompliant(
    project: $project
    variant: $variant
    ids: $ids
    isCompliant: $isCompliant
  )
}
    `;
export const useSetAllAllocatedControlsIsCompliantMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SetAllAllocatedControlsIsCompliantMutation,
    TError,
    SetAllAllocatedControlsIsCompliantMutationVariables,
    TContext
  >
) =>
  useMutation<
    SetAllAllocatedControlsIsCompliantMutation,
    TError,
    SetAllAllocatedControlsIsCompliantMutationVariables,
    TContext
  >(
    ["setAllAllocatedControlsIsCompliant"],
    (variables?: SetAllAllocatedControlsIsCompliantMutationVariables) =>
      fetcher<SetAllAllocatedControlsIsCompliantMutation, SetAllAllocatedControlsIsCompliantMutationVariables>(
        SetAllAllocatedControlsIsCompliantDocument,
        variables
      )(),
    options
  );
export const SetAllAllocatedControlsCalibratedStrengthDocument = `
    mutation setAllAllocatedControlsCalibratedStrength($project: ID!, $variant: ID!, $ids: [ID]!, $calibratedStrength: String!) {
  setAllAllocatedControlsCalibratedStrength(
    project: $project
    variant: $variant
    ids: $ids
    calibratedStrength: $calibratedStrength
  )
}
    `;
export const useSetAllAllocatedControlsCalibratedStrengthMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SetAllAllocatedControlsCalibratedStrengthMutation,
    TError,
    SetAllAllocatedControlsCalibratedStrengthMutationVariables,
    TContext
  >
) =>
  useMutation<
    SetAllAllocatedControlsCalibratedStrengthMutation,
    TError,
    SetAllAllocatedControlsCalibratedStrengthMutationVariables,
    TContext
  >(
    ["setAllAllocatedControlsCalibratedStrength"],
    (variables?: SetAllAllocatedControlsCalibratedStrengthMutationVariables) =>
      fetcher<
        SetAllAllocatedControlsCalibratedStrengthMutation,
        SetAllAllocatedControlsCalibratedStrengthMutationVariables
      >(SetAllAllocatedControlsCalibratedStrengthDocument, variables)(),
    options
  );
export const GetTargetAllocatedControlsDocument = `
    query getTargetAllocatedControls($target: ID!, $variant: ID) {
  target(target: $target, variant: $variant) {
    name
    all_controls {
      id
      name
      vulnerabilitycategory
      strength
      controltype {
        id
        name
        family {
          id
          name
        }
      }
      anobject {
        id
        name
        noun
      }
    }
  }
}
    `;
export const useGetTargetAllocatedControlsQuery = <TData = GetTargetAllocatedControlsQuery, TError = unknown>(
  variables: GetTargetAllocatedControlsQueryVariables,
  options?: UseQueryOptions<GetTargetAllocatedControlsQuery, TError, TData>
) =>
  useQuery<GetTargetAllocatedControlsQuery, TError, TData>(
    ["getTargetAllocatedControls", variables],
    fetcher<GetTargetAllocatedControlsQuery, GetTargetAllocatedControlsQueryVariables>(
      GetTargetAllocatedControlsDocument,
      variables
    ),
    options
  );

useGetTargetAllocatedControlsQuery.getKey = (variables: GetTargetAllocatedControlsQueryVariables) => [
  "getTargetAllocatedControls",
  variables,
];
export const GetSystemAssetAllocatedControlsDocument = `
    query getSystemAssetAllocatedControls($id: ID!, $project: ID!, $variant: ID) {
  systemAsset(id: $id, project: $project, variant: $variant) {
    name
    all_controls {
      id
      name
      vulnerabilitycategory
      strength
      controltype {
        id
        name
        family {
          id
          name
        }
      }
      anobject {
        id
        name
        noun
      }
    }
  }
}
    `;
export const useGetSystemAssetAllocatedControlsQuery = <TData = GetSystemAssetAllocatedControlsQuery, TError = unknown>(
  variables: GetSystemAssetAllocatedControlsQueryVariables,
  options?: UseQueryOptions<GetSystemAssetAllocatedControlsQuery, TError, TData>
) =>
  useQuery<GetSystemAssetAllocatedControlsQuery, TError, TData>(
    ["getSystemAssetAllocatedControls", variables],
    fetcher<GetSystemAssetAllocatedControlsQuery, GetSystemAssetAllocatedControlsQueryVariables>(
      GetSystemAssetAllocatedControlsDocument,
      variables
    ),
    options
  );

useGetSystemAssetAllocatedControlsQuery.getKey = (variables: GetSystemAssetAllocatedControlsQueryVariables) => [
  "getSystemAssetAllocatedControls",
  variables,
];
export const GetSystemAssetTypeAllocatedControlsDocument = `
    query getSystemAssetTypeAllocatedControls($project: ID!, $variant: ID!, $id: ID!) {
  systemAssetType(project: $project, variant: $variant, id: $id) {
    name
    all_controls {
      id
      name
      vulnerabilitycategory
      strength
      controltype {
        id
        name
        family {
          id
          name
        }
      }
      anobject {
        id
        name
        noun
      }
    }
  }
}
    `;
export const useGetSystemAssetTypeAllocatedControlsQuery = <
  TData = GetSystemAssetTypeAllocatedControlsQuery,
  TError = unknown
>(
  variables: GetSystemAssetTypeAllocatedControlsQueryVariables,
  options?: UseQueryOptions<GetSystemAssetTypeAllocatedControlsQuery, TError, TData>
) =>
  useQuery<GetSystemAssetTypeAllocatedControlsQuery, TError, TData>(
    ["getSystemAssetTypeAllocatedControls", variables],
    fetcher<GetSystemAssetTypeAllocatedControlsQuery, GetSystemAssetTypeAllocatedControlsQueryVariables>(
      GetSystemAssetTypeAllocatedControlsDocument,
      variables
    ),
    options
  );

useGetSystemAssetTypeAllocatedControlsQuery.getKey = (variables: GetSystemAssetTypeAllocatedControlsQueryVariables) => [
  "getSystemAssetTypeAllocatedControls",
  variables,
];
export const CloneVariantDocument = `
    mutation cloneVariant($project: ID!, $variant: ID!) {
  cloneVariant(project: $project, variant: $variant)
}
    `;
export const useCloneVariantMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CloneVariantMutation, TError, CloneVariantMutationVariables, TContext>
) =>
  useMutation<CloneVariantMutation, TError, CloneVariantMutationVariables, TContext>(
    ["cloneVariant"],
    (variables?: CloneVariantMutationVariables) =>
      fetcher<CloneVariantMutation, CloneVariantMutationVariables>(CloneVariantDocument, variables)(),
    options
  );
export const GetAllVulnerabilitiesDocument = `
    query getAllVulnerabilities($project: ID!, $variant: ID!) {
  vulnerabilities(project: $project, variant: $variant) {
    id
    name
    category
    level {
      calcStringValue
    }
    anobject {
      id
      noun
      name
    }
    rank
    findingCount
    controlCount
  }
}
    `;
export const useGetAllVulnerabilitiesQuery = <TData = GetAllVulnerabilitiesQuery, TError = unknown>(
  variables: GetAllVulnerabilitiesQueryVariables,
  options?: UseQueryOptions<GetAllVulnerabilitiesQuery, TError, TData>
) =>
  useQuery<GetAllVulnerabilitiesQuery, TError, TData>(
    ["getAllVulnerabilities", variables],
    fetcher<GetAllVulnerabilitiesQuery, GetAllVulnerabilitiesQueryVariables>(GetAllVulnerabilitiesDocument, variables),
    options
  );

useGetAllVulnerabilitiesQuery.getKey = (variables: GetAllVulnerabilitiesQueryVariables) => [
  "getAllVulnerabilities",
  variables,
];
export const GetActivityMissionsDocument = `
    query getActivityMissions($project: ID!, $id: ID!) {
  activity(project: $project, id: $id) {
    id
    name
    missions {
      id
      name
    }
  }
}
    `;
export const useGetActivityMissionsQuery = <TData = GetActivityMissionsQuery, TError = unknown>(
  variables: GetActivityMissionsQueryVariables,
  options?: UseQueryOptions<GetActivityMissionsQuery, TError, TData>
) =>
  useQuery<GetActivityMissionsQuery, TError, TData>(
    ["getActivityMissions", variables],
    fetcher<GetActivityMissionsQuery, GetActivityMissionsQueryVariables>(GetActivityMissionsDocument, variables),
    options
  );

useGetActivityMissionsQuery.getKey = (variables: GetActivityMissionsQueryVariables) => [
  "getActivityMissions",
  variables,
];
export const GetActivityDataflowsDocument = `
    query getActivityDataflows($project: ID!, $id: ID!) {
  activity(project: $project, id: $id) {
    id
    name
    dataFlows {
      id
      name
      category
    }
  }
}
    `;
export const useGetActivityDataflowsQuery = <TData = GetActivityDataflowsQuery, TError = unknown>(
  variables: GetActivityDataflowsQueryVariables,
  options?: UseQueryOptions<GetActivityDataflowsQuery, TError, TData>
) =>
  useQuery<GetActivityDataflowsQuery, TError, TData>(
    ["getActivityDataflows", variables],
    fetcher<GetActivityDataflowsQuery, GetActivityDataflowsQueryVariables>(GetActivityDataflowsDocument, variables),
    options
  );

useGetActivityDataflowsQuery.getKey = (variables: GetActivityDataflowsQueryVariables) => [
  "getActivityDataflows",
  variables,
];
export const GetCapabilityMissionsDocument = `
    query getCapabilityMissions($project: ID!, $id: ID!) {
  capability(project: $project, id: $id) {
    id
    name
    missions {
      id
      name
      isInternal
    }
  }
}
    `;
export const useGetCapabilityMissionsQuery = <TData = GetCapabilityMissionsQuery, TError = unknown>(
  variables: GetCapabilityMissionsQueryVariables,
  options?: UseQueryOptions<GetCapabilityMissionsQuery, TError, TData>
) =>
  useQuery<GetCapabilityMissionsQuery, TError, TData>(
    ["getCapabilityMissions", variables],
    fetcher<GetCapabilityMissionsQuery, GetCapabilityMissionsQueryVariables>(GetCapabilityMissionsDocument, variables),
    options
  );

useGetCapabilityMissionsQuery.getKey = (variables: GetCapabilityMissionsQueryVariables) => [
  "getCapabilityMissions",
  variables,
];
export const GetCapabilityResourcesDocument = `
    query getCapabilityResources($project: ID!, $id: ID!) {
  capability(project: $project, id: $id) {
    id
    name
    resources {
      id
      name
    }
  }
}
    `;
export const useGetCapabilityResourcesQuery = <TData = GetCapabilityResourcesQuery, TError = unknown>(
  variables: GetCapabilityResourcesQueryVariables,
  options?: UseQueryOptions<GetCapabilityResourcesQuery, TError, TData>
) =>
  useQuery<GetCapabilityResourcesQuery, TError, TData>(
    ["getCapabilityResources", variables],
    fetcher<GetCapabilityResourcesQuery, GetCapabilityResourcesQueryVariables>(
      GetCapabilityResourcesDocument,
      variables
    ),
    options
  );

useGetCapabilityResourcesQuery.getKey = (variables: GetCapabilityResourcesQueryVariables) => [
  "getCapabilityResources",
  variables,
];
export const GetDataTypeActivitiesDocument = `
    query getDataTypeActivities($project: ID!, $id: ID!) {
  dataType(project: $project, id: $id) {
    id
    name
    activities {
      id
      name
      category
      isInternal
      isBoundary
      isInitial
      isFinal
      node {
        id
        name
      }
    }
  }
}
    `;
export const useGetDataTypeActivitiesQuery = <TData = GetDataTypeActivitiesQuery, TError = unknown>(
  variables: GetDataTypeActivitiesQueryVariables,
  options?: UseQueryOptions<GetDataTypeActivitiesQuery, TError, TData>
) =>
  useQuery<GetDataTypeActivitiesQuery, TError, TData>(
    ["getDataTypeActivities", variables],
    fetcher<GetDataTypeActivitiesQuery, GetDataTypeActivitiesQueryVariables>(GetDataTypeActivitiesDocument, variables),
    options
  );

useGetDataTypeActivitiesQuery.getKey = (variables: GetDataTypeActivitiesQueryVariables) => [
  "getDataTypeActivities",
  variables,
];
export const GetDataTypeCapabilitiesDocument = `
    query getDataTypeCapabilities($project: ID!, $id: ID!) {
  dataType(project: $project, id: $id) {
    id
    name
    capabilities {
      id
      name
      isInternal
    }
  }
}
    `;
export const useGetDataTypeCapabilitiesQuery = <TData = GetDataTypeCapabilitiesQuery, TError = unknown>(
  variables: GetDataTypeCapabilitiesQueryVariables,
  options?: UseQueryOptions<GetDataTypeCapabilitiesQuery, TError, TData>
) =>
  useQuery<GetDataTypeCapabilitiesQuery, TError, TData>(
    ["getDataTypeCapabilities", variables],
    fetcher<GetDataTypeCapabilitiesQuery, GetDataTypeCapabilitiesQueryVariables>(
      GetDataTypeCapabilitiesDocument,
      variables
    ),
    options
  );

useGetDataTypeCapabilitiesQuery.getKey = (variables: GetDataTypeCapabilitiesQueryVariables) => [
  "getDataTypeCapabilities",
  variables,
];
export const GetDataTypeDataflowsDocument = `
    query getDataTypeDataflows($project: ID!, $id: ID!) {
  dataType(project: $project, id: $id) {
    id
    name
    dataFlows {
      id
      name
      category
    }
  }
}
    `;
export const useGetDataTypeDataflowsQuery = <TData = GetDataTypeDataflowsQuery, TError = unknown>(
  variables: GetDataTypeDataflowsQueryVariables,
  options?: UseQueryOptions<GetDataTypeDataflowsQuery, TError, TData>
) =>
  useQuery<GetDataTypeDataflowsQuery, TError, TData>(
    ["getDataTypeDataflows", variables],
    fetcher<GetDataTypeDataflowsQuery, GetDataTypeDataflowsQueryVariables>(GetDataTypeDataflowsDocument, variables),
    options
  );

useGetDataTypeDataflowsQuery.getKey = (variables: GetDataTypeDataflowsQueryVariables) => [
  "getDataTypeDataflows",
  variables,
];
export const GetDataTypeExchangesDocument = `
    query getDataTypeExchanges($project: ID!, $id: ID!) {
  dataType(project: $project, id: $id) {
    id
    name
    exchanges {
      id
      name
      category
    }
  }
}
    `;
export const useGetDataTypeExchangesQuery = <TData = GetDataTypeExchangesQuery, TError = unknown>(
  variables: GetDataTypeExchangesQueryVariables,
  options?: UseQueryOptions<GetDataTypeExchangesQuery, TError, TData>
) =>
  useQuery<GetDataTypeExchangesQuery, TError, TData>(
    ["getDataTypeExchanges", variables],
    fetcher<GetDataTypeExchangesQuery, GetDataTypeExchangesQueryVariables>(GetDataTypeExchangesDocument, variables),
    options
  );

useGetDataTypeExchangesQuery.getKey = (variables: GetDataTypeExchangesQueryVariables) => [
  "getDataTypeExchanges",
  variables,
];
export const GetDataTypeMissionsDocument = `
    query getDataTypeMissions($project: ID!, $id: ID!) {
  dataType(project: $project, id: $id) {
    id
    name
    missions {
      id
      name
      isInternal
    }
  }
}
    `;
export const useGetDataTypeMissionsQuery = <TData = GetDataTypeMissionsQuery, TError = unknown>(
  variables: GetDataTypeMissionsQueryVariables,
  options?: UseQueryOptions<GetDataTypeMissionsQuery, TError, TData>
) =>
  useQuery<GetDataTypeMissionsQuery, TError, TData>(
    ["getDataTypeMissions", variables],
    fetcher<GetDataTypeMissionsQuery, GetDataTypeMissionsQueryVariables>(GetDataTypeMissionsDocument, variables),
    options
  );

useGetDataTypeMissionsQuery.getKey = (variables: GetDataTypeMissionsQueryVariables) => [
  "getDataTypeMissions",
  variables,
];
export const GetDataTypeNodesDocument = `
    query getDataTypeNodes($project: ID!, $id: ID!) {
  dataType(project: $project, id: $id) {
    id
    name
    nodes {
      id
      name
      category
      structuralcat
      isInternal
    }
  }
}
    `;
export const useGetDataTypeNodesQuery = <TData = GetDataTypeNodesQuery, TError = unknown>(
  variables: GetDataTypeNodesQueryVariables,
  options?: UseQueryOptions<GetDataTypeNodesQuery, TError, TData>
) =>
  useQuery<GetDataTypeNodesQuery, TError, TData>(
    ["getDataTypeNodes", variables],
    fetcher<GetDataTypeNodesQuery, GetDataTypeNodesQueryVariables>(GetDataTypeNodesDocument, variables),
    options
  );

useGetDataTypeNodesQuery.getKey = (variables: GetDataTypeNodesQueryVariables) => ["getDataTypeNodes", variables];
export const GetDataTypePersonsDocument = `
    query getDataTypePersons($project: ID!, $id: ID!) {
  dataType(project: $project, id: $id) {
    id
    name
    persons {
      id
      name
      category
      isInternal
    }
  }
}
    `;
export const useGetDataTypePersonsQuery = <TData = GetDataTypePersonsQuery, TError = unknown>(
  variables: GetDataTypePersonsQueryVariables,
  options?: UseQueryOptions<GetDataTypePersonsQuery, TError, TData>
) =>
  useQuery<GetDataTypePersonsQuery, TError, TData>(
    ["getDataTypePersons", variables],
    fetcher<GetDataTypePersonsQuery, GetDataTypePersonsQueryVariables>(GetDataTypePersonsDocument, variables),
    options
  );

useGetDataTypePersonsQuery.getKey = (variables: GetDataTypePersonsQueryVariables) => ["getDataTypePersons", variables];
export const GetDataflowNodesDocument = `
    query getDataflowNodes($project: ID!, $id: ID!) {
  dataFlow(project: $project, id: $id) {
    id
    name
    nodes {
      id
      name
      category
      structuralcat
      isInternal
    }
  }
}
    `;
export const useGetDataflowNodesQuery = <TData = GetDataflowNodesQuery, TError = unknown>(
  variables: GetDataflowNodesQueryVariables,
  options?: UseQueryOptions<GetDataflowNodesQuery, TError, TData>
) =>
  useQuery<GetDataflowNodesQuery, TError, TData>(
    ["getDataflowNodes", variables],
    fetcher<GetDataflowNodesQuery, GetDataflowNodesQueryVariables>(GetDataflowNodesDocument, variables),
    options
  );

useGetDataflowNodesQuery.getKey = (variables: GetDataflowNodesQueryVariables) => ["getDataflowNodes", variables];
export const GetDataflowDataTypesDocument = `
    query getDataflowDataTypes($project: ID!, $id: ID!) {
  dataFlow(project: $project, id: $id) {
    id
    name
    dataTypes {
      id
      name
      category
      isInternal
      isInput
      isOutput
      isProcessed
      isStored
    }
  }
}
    `;
export const useGetDataflowDataTypesQuery = <TData = GetDataflowDataTypesQuery, TError = unknown>(
  variables: GetDataflowDataTypesQueryVariables,
  options?: UseQueryOptions<GetDataflowDataTypesQuery, TError, TData>
) =>
  useQuery<GetDataflowDataTypesQuery, TError, TData>(
    ["getDataflowDataTypes", variables],
    fetcher<GetDataflowDataTypesQuery, GetDataflowDataTypesQueryVariables>(GetDataflowDataTypesDocument, variables),
    options
  );

useGetDataflowDataTypesQuery.getKey = (variables: GetDataflowDataTypesQueryVariables) => [
  "getDataflowDataTypes",
  variables,
];
export const GetExchangeCapabilitiesDocument = `
    query getExchangeCapabilities($project: ID!, $variant: ID!, $id: ID!) {
  exchange(project: $project, variant: $variant, id: $id) {
    id
    name
    capabilities {
      id
      name
      isInternal
    }
  }
}
    `;
export const useGetExchangeCapabilitiesQuery = <TData = GetExchangeCapabilitiesQuery, TError = unknown>(
  variables: GetExchangeCapabilitiesQueryVariables,
  options?: UseQueryOptions<GetExchangeCapabilitiesQuery, TError, TData>
) =>
  useQuery<GetExchangeCapabilitiesQuery, TError, TData>(
    ["getExchangeCapabilities", variables],
    fetcher<GetExchangeCapabilitiesQuery, GetExchangeCapabilitiesQueryVariables>(
      GetExchangeCapabilitiesDocument,
      variables
    ),
    options
  );

useGetExchangeCapabilitiesQuery.getKey = (variables: GetExchangeCapabilitiesQueryVariables) => [
  "getExchangeCapabilities",
  variables,
];
export const GetExchangeDataflowsDocument = `
    query getExchangeDataflows($project: ID!, $variant: ID!, $id: ID!) {
  exchange(project: $project, variant: $variant, id: $id) {
    id
    name
    dataFlows {
      id
      name
      category
    }
  }
}
    `;
export const useGetExchangeDataflowsQuery = <TData = GetExchangeDataflowsQuery, TError = unknown>(
  variables: GetExchangeDataflowsQueryVariables,
  options?: UseQueryOptions<GetExchangeDataflowsQuery, TError, TData>
) =>
  useQuery<GetExchangeDataflowsQuery, TError, TData>(
    ["getExchangeDataflows", variables],
    fetcher<GetExchangeDataflowsQuery, GetExchangeDataflowsQueryVariables>(GetExchangeDataflowsDocument, variables),
    options
  );

useGetExchangeDataflowsQuery.getKey = (variables: GetExchangeDataflowsQueryVariables) => [
  "getExchangeDataflows",
  variables,
];
export const GetExchangeMissionsDocument = `
    query getExchangeMissions($project: ID!, $variant: ID!, $id: ID!) {
  exchange(project: $project, variant: $variant, id: $id) {
    id
    name
    missions {
      id
      name
      isInternal
    }
  }
}
    `;
export const useGetExchangeMissionsQuery = <TData = GetExchangeMissionsQuery, TError = unknown>(
  variables: GetExchangeMissionsQueryVariables,
  options?: UseQueryOptions<GetExchangeMissionsQuery, TError, TData>
) =>
  useQuery<GetExchangeMissionsQuery, TError, TData>(
    ["getExchangeMissions", variables],
    fetcher<GetExchangeMissionsQuery, GetExchangeMissionsQueryVariables>(GetExchangeMissionsDocument, variables),
    options
  );

useGetExchangeMissionsQuery.getKey = (variables: GetExchangeMissionsQueryVariables) => [
  "getExchangeMissions",
  variables,
];
export const GetExchangesWithClassificationsDocument = `
    query getExchangesWithClassifications($project: ID!, $variant: ID!) {
  exchanges(project: $project, variant: $variant) {
    id
    name
    producer {
      id
      name
    }
    consumer {
      id
      name
    }
    isBoundary
    data {
      id
      name
      categorizedEntity {
        id
      }
    }
  }
}
    `;
export const useGetExchangesWithClassificationsQuery = <TData = GetExchangesWithClassificationsQuery, TError = unknown>(
  variables: GetExchangesWithClassificationsQueryVariables,
  options?: UseQueryOptions<GetExchangesWithClassificationsQuery, TError, TData>
) =>
  useQuery<GetExchangesWithClassificationsQuery, TError, TData>(
    ["getExchangesWithClassifications", variables],
    fetcher<GetExchangesWithClassificationsQuery, GetExchangesWithClassificationsQueryVariables>(
      GetExchangesWithClassificationsDocument,
      variables
    ),
    options
  );

useGetExchangesWithClassificationsQuery.getKey = (variables: GetExchangesWithClassificationsQueryVariables) => [
  "getExchangesWithClassifications",
  variables,
];
export const GetAssetVulnerabilityDocument = `
    query getAssetVulnerability($asset: ID!, $variant: ID) {
  asset(asset: $asset, variant: $variant) {
    name
    vulnerabilities {
      id
      name
      rank
      category
      level {
        calcStringValue
        calibratedStringValue
      }
      anobject {
        id
        name
        noun
      }
    }
  }
}
    `;
export const useGetAssetVulnerabilityQuery = <TData = GetAssetVulnerabilityQuery, TError = unknown>(
  variables: GetAssetVulnerabilityQueryVariables,
  options?: UseQueryOptions<GetAssetVulnerabilityQuery, TError, TData>
) =>
  useQuery<GetAssetVulnerabilityQuery, TError, TData>(
    ["getAssetVulnerability", variables],
    fetcher<GetAssetVulnerabilityQuery, GetAssetVulnerabilityQueryVariables>(GetAssetVulnerabilityDocument, variables),
    options
  );

useGetAssetVulnerabilityQuery.getKey = (variables: GetAssetVulnerabilityQueryVariables) => [
  "getAssetVulnerability",
  variables,
];
export const GetUndesiredEventVulnerabilityDocument = `
    query getUndesiredEventVulnerability($id: ID!, $project: ID!, $variant: ID) {
  undesiredEvent(id: $id, project: $project, variant: $variant) {
    name
    vulnerabilities {
      id
      name
      rank
      category
      level {
        calcStringValue
        calibratedStringValue
      }
      anobject {
        id
        name
        noun
      }
      findingCount
      controlCount
    }
  }
}
    `;
export const useGetUndesiredEventVulnerabilityQuery = <TData = GetUndesiredEventVulnerabilityQuery, TError = unknown>(
  variables: GetUndesiredEventVulnerabilityQueryVariables,
  options?: UseQueryOptions<GetUndesiredEventVulnerabilityQuery, TError, TData>
) =>
  useQuery<GetUndesiredEventVulnerabilityQuery, TError, TData>(
    ["getUndesiredEventVulnerability", variables],
    fetcher<GetUndesiredEventVulnerabilityQuery, GetUndesiredEventVulnerabilityQueryVariables>(
      GetUndesiredEventVulnerabilityDocument,
      variables
    ),
    options
  );

useGetUndesiredEventVulnerabilityQuery.getKey = (variables: GetUndesiredEventVulnerabilityQueryVariables) => [
  "getUndesiredEventVulnerability",
  variables,
];
export const GetThreatEventVulnerabilityDocument = `
    query getThreatEventVulnerability($trev: ID!, $variant: ID) {
  trev(trev: $trev, variant: $variant) {
    name
    vulnerabilities {
      id
      name
      category
      rank
      level {
        calcStringValue
        calibratedStringValue
      }
      anobject {
        id
        name
        noun
      }
      findingCount
      controlCount
    }
  }
}
    `;
export const useGetThreatEventVulnerabilityQuery = <TData = GetThreatEventVulnerabilityQuery, TError = unknown>(
  variables: GetThreatEventVulnerabilityQueryVariables,
  options?: UseQueryOptions<GetThreatEventVulnerabilityQuery, TError, TData>
) =>
  useQuery<GetThreatEventVulnerabilityQuery, TError, TData>(
    ["getThreatEventVulnerability", variables],
    fetcher<GetThreatEventVulnerabilityQuery, GetThreatEventVulnerabilityQueryVariables>(
      GetThreatEventVulnerabilityDocument,
      variables
    ),
    options
  );

useGetThreatEventVulnerabilityQuery.getKey = (variables: GetThreatEventVulnerabilityQueryVariables) => [
  "getThreatEventVulnerability",
  variables,
];
export const GetSystemAssetVulnerabilityDocument = `
    query getSystemAssetVulnerability($id: ID!, $project: ID!, $variant: ID) {
  systemAsset(id: $id, project: $project, variant: $variant) {
    name
    vulnerabilities {
      id
      name
      rank
      category
      level {
        calcStringValue
        calibratedStringValue
      }
      anobject {
        id
        name
        noun
      }
      findingCount
      controlCount
    }
  }
}
    `;
export const useGetSystemAssetVulnerabilityQuery = <TData = GetSystemAssetVulnerabilityQuery, TError = unknown>(
  variables: GetSystemAssetVulnerabilityQueryVariables,
  options?: UseQueryOptions<GetSystemAssetVulnerabilityQuery, TError, TData>
) =>
  useQuery<GetSystemAssetVulnerabilityQuery, TError, TData>(
    ["getSystemAssetVulnerability", variables],
    fetcher<GetSystemAssetVulnerabilityQuery, GetSystemAssetVulnerabilityQueryVariables>(
      GetSystemAssetVulnerabilityDocument,
      variables
    ),
    options
  );

useGetSystemAssetVulnerabilityQuery.getKey = (variables: GetSystemAssetVulnerabilityQueryVariables) => [
  "getSystemAssetVulnerability",
  variables,
];
export const GetSystemAssetTypeVulnerabilityDocument = `
    query getSystemAssetTypeVulnerability($project: ID!, $variant: ID!, $id: ID!) {
  systemAssetType(project: $project, variant: $variant, id: $id) {
    name
    vulnerabilities {
      id
      name
      category
      rank
      level {
        calcStringValue
        calibratedStringValue
      }
      anobject {
        id
        name
        noun
      }
      findingCount
      controlCount
    }
  }
}
    `;
export const useGetSystemAssetTypeVulnerabilityQuery = <TData = GetSystemAssetTypeVulnerabilityQuery, TError = unknown>(
  variables: GetSystemAssetTypeVulnerabilityQueryVariables,
  options?: UseQueryOptions<GetSystemAssetTypeVulnerabilityQuery, TError, TData>
) =>
  useQuery<GetSystemAssetTypeVulnerabilityQuery, TError, TData>(
    ["getSystemAssetTypeVulnerability", variables],
    fetcher<GetSystemAssetTypeVulnerabilityQuery, GetSystemAssetTypeVulnerabilityQueryVariables>(
      GetSystemAssetTypeVulnerabilityDocument,
      variables
    ),
    options
  );

useGetSystemAssetTypeVulnerabilityQuery.getKey = (variables: GetSystemAssetTypeVulnerabilityQueryVariables) => [
  "getSystemAssetTypeVulnerability",
  variables,
];
export const GetTargetVulnerabilityDocument = `
    query getTargetVulnerability($target: ID!, $variant: ID) {
  target(target: $target, variant: $variant) {
    id
    name
    all_vulnerabilities {
      id
      name
      category
      rank
      level {
        calcStringValue
        calibratedStringValue
      }
      anobject {
        id
        name
        noun
      }
      findingCount
      controlCount
    }
  }
}
    `;
export const useGetTargetVulnerabilityQuery = <TData = GetTargetVulnerabilityQuery, TError = unknown>(
  variables: GetTargetVulnerabilityQueryVariables,
  options?: UseQueryOptions<GetTargetVulnerabilityQuery, TError, TData>
) =>
  useQuery<GetTargetVulnerabilityQuery, TError, TData>(
    ["getTargetVulnerability", variables],
    fetcher<GetTargetVulnerabilityQuery, GetTargetVulnerabilityQueryVariables>(
      GetTargetVulnerabilityDocument,
      variables
    ),
    options
  );

useGetTargetVulnerabilityQuery.getKey = (variables: GetTargetVulnerabilityQueryVariables) => [
  "getTargetVulnerability",
  variables,
];
export const GetRiskVulnerabilitiesDocument = `
    query getRiskVulnerabilities($risk: ID!, $variant: ID) {
  risk(risk: $risk, variant: $variant) {
    id
    name
    vulnerabilities {
      id
      name
      rank
      category
      level {
        calcStringValue
        calibratedStringValue
      }
      anobject {
        id
        name
        noun
      }
      findingCount
      controlCount
    }
  }
}
    `;
export const useGetRiskVulnerabilitiesQuery = <TData = GetRiskVulnerabilitiesQuery, TError = unknown>(
  variables: GetRiskVulnerabilitiesQueryVariables,
  options?: UseQueryOptions<GetRiskVulnerabilitiesQuery, TError, TData>
) =>
  useQuery<GetRiskVulnerabilitiesQuery, TError, TData>(
    ["getRiskVulnerabilities", variables],
    fetcher<GetRiskVulnerabilitiesQuery, GetRiskVulnerabilitiesQueryVariables>(
      GetRiskVulnerabilitiesDocument,
      variables
    ),
    options
  );

useGetRiskVulnerabilitiesQuery.getKey = (variables: GetRiskVulnerabilitiesQueryVariables) => [
  "getRiskVulnerabilities",
  variables,
];
export const GetExchangeVulnerabilitiesDocument = `
    query getExchangeVulnerabilities($project: ID!, $id: ID!, $variant: ID!) {
  exchange(project: $project, id: $id, variant: $variant) {
    id
    name
    vulnerabilities {
      id
      name
      rank
      category
      level {
        calcStringValue
        calibratedStringValue
      }
      anobject {
        id
        name
        noun
      }
      findingCount
      controlCount
    }
  }
}
    `;
export const useGetExchangeVulnerabilitiesQuery = <TData = GetExchangeVulnerabilitiesQuery, TError = unknown>(
  variables: GetExchangeVulnerabilitiesQueryVariables,
  options?: UseQueryOptions<GetExchangeVulnerabilitiesQuery, TError, TData>
) =>
  useQuery<GetExchangeVulnerabilitiesQuery, TError, TData>(
    ["getExchangeVulnerabilities", variables],
    fetcher<GetExchangeVulnerabilitiesQuery, GetExchangeVulnerabilitiesQueryVariables>(
      GetExchangeVulnerabilitiesDocument,
      variables
    ),
    options
  );

useGetExchangeVulnerabilitiesQuery.getKey = (variables: GetExchangeVulnerabilitiesQueryVariables) => [
  "getExchangeVulnerabilities",
  variables,
];
export const GetNodeVulnerabilitiesDocument = `
    query getNodeVulnerabilities($project: ID!, $id: ID!, $variant: ID!) {
  node(project: $project, id: $id, variant: $variant) {
    id
    name
    vulnerabilities {
      id
      name
      rank
      category
      level {
        calcStringValue
        calibratedStringValue
      }
      anobject {
        id
        name
        noun
      }
      findingCount
      controlCount
    }
  }
}
    `;
export const useGetNodeVulnerabilitiesQuery = <TData = GetNodeVulnerabilitiesQuery, TError = unknown>(
  variables: GetNodeVulnerabilitiesQueryVariables,
  options?: UseQueryOptions<GetNodeVulnerabilitiesQuery, TError, TData>
) =>
  useQuery<GetNodeVulnerabilitiesQuery, TError, TData>(
    ["getNodeVulnerabilities", variables],
    fetcher<GetNodeVulnerabilitiesQuery, GetNodeVulnerabilitiesQueryVariables>(
      GetNodeVulnerabilitiesDocument,
      variables
    ),
    options
  );

useGetNodeVulnerabilitiesQuery.getKey = (variables: GetNodeVulnerabilitiesQueryVariables) => [
  "getNodeVulnerabilities",
  variables,
];
export const GetAttackVulnerabilitiesDocument = `
    query getAttackVulnerabilities($attack: ID!, $variant: ID!) {
  attack(attack: $attack, variant: $variant) {
    id
    name
    vulnerabilities {
      id
      name
      rank
      category
      level {
        calcStringValue
        calibratedStringValue
      }
      anobject {
        id
        name
        noun
      }
      findingCount
      controlCount
    }
  }
}
    `;
export const useGetAttackVulnerabilitiesQuery = <TData = GetAttackVulnerabilitiesQuery, TError = unknown>(
  variables: GetAttackVulnerabilitiesQueryVariables,
  options?: UseQueryOptions<GetAttackVulnerabilitiesQuery, TError, TData>
) =>
  useQuery<GetAttackVulnerabilitiesQuery, TError, TData>(
    ["getAttackVulnerabilities", variables],
    fetcher<GetAttackVulnerabilitiesQuery, GetAttackVulnerabilitiesQueryVariables>(
      GetAttackVulnerabilitiesDocument,
      variables
    ),
    options
  );

useGetAttackVulnerabilitiesQuery.getKey = (variables: GetAttackVulnerabilitiesQueryVariables) => [
  "getAttackVulnerabilities",
  variables,
];
export const GetAttackerVulnerabilitiesDocument = `
    query getAttackerVulnerabilities($id: ID!, $project: ID!, $variant: ID) {
  attacker(id: $id, project: $project, variant: $variant) {
    id
    name
    vulnerabilities {
      id
      name
      rank
      category
      level {
        calcStringValue
        calibratedStringValue
      }
      anobject {
        id
        name
        noun
      }
      findingCount
      controlCount
    }
  }
}
    `;
export const useGetAttackerVulnerabilitiesQuery = <TData = GetAttackerVulnerabilitiesQuery, TError = unknown>(
  variables: GetAttackerVulnerabilitiesQueryVariables,
  options?: UseQueryOptions<GetAttackerVulnerabilitiesQuery, TError, TData>
) =>
  useQuery<GetAttackerVulnerabilitiesQuery, TError, TData>(
    ["getAttackerVulnerabilities", variables],
    fetcher<GetAttackerVulnerabilitiesQuery, GetAttackerVulnerabilitiesQueryVariables>(
      GetAttackerVulnerabilitiesDocument,
      variables
    ),
    options
  );

useGetAttackerVulnerabilitiesQuery.getKey = (variables: GetAttackerVulnerabilitiesQueryVariables) => [
  "getAttackerVulnerabilities",
  variables,
];
export const GetAllocatedControlVulnerabilitiesDocument = `
    query getAllocatedControlVulnerabilities($id: ID!, $project: ID!, $variant: ID) {
  allocatedControl(id: $id, project: $project, variant: $variant) {
    id
    name
    vulnerabilities {
      id
      name
      rank
      category
      level {
        calcStringValue
        calibratedStringValue
      }
      anobject {
        id
        name
        noun
      }
      findingCount
      controlCount
    }
  }
}
    `;
export const useGetAllocatedControlVulnerabilitiesQuery = <
  TData = GetAllocatedControlVulnerabilitiesQuery,
  TError = unknown
>(
  variables: GetAllocatedControlVulnerabilitiesQueryVariables,
  options?: UseQueryOptions<GetAllocatedControlVulnerabilitiesQuery, TError, TData>
) =>
  useQuery<GetAllocatedControlVulnerabilitiesQuery, TError, TData>(
    ["getAllocatedControlVulnerabilities", variables],
    fetcher<GetAllocatedControlVulnerabilitiesQuery, GetAllocatedControlVulnerabilitiesQueryVariables>(
      GetAllocatedControlVulnerabilitiesDocument,
      variables
    ),
    options
  );

useGetAllocatedControlVulnerabilitiesQuery.getKey = (variables: GetAllocatedControlVulnerabilitiesQueryVariables) => [
  "getAllocatedControlVulnerabilities",
  variables,
];
export const GetMissionResourcesDocument = `
    query getMissionResources($project: ID!, $id: ID!) {
  mission(project: $project, id: $id) {
    id
    name
    resources {
      id
      name
    }
  }
}
    `;
export const useGetMissionResourcesQuery = <TData = GetMissionResourcesQuery, TError = unknown>(
  variables: GetMissionResourcesQueryVariables,
  options?: UseQueryOptions<GetMissionResourcesQuery, TError, TData>
) =>
  useQuery<GetMissionResourcesQuery, TError, TData>(
    ["getMissionResources", variables],
    fetcher<GetMissionResourcesQuery, GetMissionResourcesQueryVariables>(GetMissionResourcesDocument, variables),
    options
  );

useGetMissionResourcesQuery.getKey = (variables: GetMissionResourcesQueryVariables) => [
  "getMissionResources",
  variables,
];
export const GetMissionActivitiesDocument = `
    query getMissionActivities($project: ID!, $id: ID!) {
  mission(project: $project, id: $id) {
    id
    name
    activities {
      id
      name
      category
      isInternal
      isBoundary
      isInitial
      isFinal
      node {
        id
        name
      }
    }
  }
}
    `;
export const useGetMissionActivitiesQuery = <TData = GetMissionActivitiesQuery, TError = unknown>(
  variables: GetMissionActivitiesQueryVariables,
  options?: UseQueryOptions<GetMissionActivitiesQuery, TError, TData>
) =>
  useQuery<GetMissionActivitiesQuery, TError, TData>(
    ["getMissionActivities", variables],
    fetcher<GetMissionActivitiesQuery, GetMissionActivitiesQueryVariables>(GetMissionActivitiesDocument, variables),
    options
  );

useGetMissionActivitiesQuery.getKey = (variables: GetMissionActivitiesQueryVariables) => [
  "getMissionActivities",
  variables,
];
export const GetMissionExchangesDocument = `
    query getMissionExchanges($project: ID!, $id: ID!) {
  mission(project: $project, id: $id) {
    id
    name
    exchanges {
      id
      name
      category
    }
  }
}
    `;
export const useGetMissionExchangesQuery = <TData = GetMissionExchangesQuery, TError = unknown>(
  variables: GetMissionExchangesQueryVariables,
  options?: UseQueryOptions<GetMissionExchangesQuery, TError, TData>
) =>
  useQuery<GetMissionExchangesQuery, TError, TData>(
    ["getMissionExchanges", variables],
    fetcher<GetMissionExchangesQuery, GetMissionExchangesQueryVariables>(GetMissionExchangesDocument, variables),
    options
  );

useGetMissionExchangesQuery.getKey = (variables: GetMissionExchangesQueryVariables) => [
  "getMissionExchanges",
  variables,
];
export const GetMissionNodesDocument = `
    query getMissionNodes($project: ID!, $id: ID!) {
  mission(project: $project, id: $id) {
    id
    name
    nodes {
      id
      name
      category
      structuralcat
      isInternal
    }
  }
}
    `;
export const useGetMissionNodesQuery = <TData = GetMissionNodesQuery, TError = unknown>(
  variables: GetMissionNodesQueryVariables,
  options?: UseQueryOptions<GetMissionNodesQuery, TError, TData>
) =>
  useQuery<GetMissionNodesQuery, TError, TData>(
    ["getMissionNodes", variables],
    fetcher<GetMissionNodesQuery, GetMissionNodesQueryVariables>(GetMissionNodesDocument, variables),
    options
  );

useGetMissionNodesQuery.getKey = (variables: GetMissionNodesQueryVariables) => ["getMissionNodes", variables];
export const GetMissionPersonsDocument = `
    query getMissionPersons($project: ID!, $id: ID!) {
  mission(project: $project, id: $id) {
    id
    name
    persons {
      id
      name
      category
      isInternal
    }
  }
}
    `;
export const useGetMissionPersonsQuery = <TData = GetMissionPersonsQuery, TError = unknown>(
  variables: GetMissionPersonsQueryVariables,
  options?: UseQueryOptions<GetMissionPersonsQuery, TError, TData>
) =>
  useQuery<GetMissionPersonsQuery, TError, TData>(
    ["getMissionPersons", variables],
    fetcher<GetMissionPersonsQuery, GetMissionPersonsQueryVariables>(GetMissionPersonsDocument, variables),
    options
  );

useGetMissionPersonsQuery.getKey = (variables: GetMissionPersonsQueryVariables) => ["getMissionPersons", variables];
export const GetMissionDataTypesDocument = `
    query getMissionDataTypes($project: ID!, $id: ID!) {
  mission(project: $project, id: $id) {
    id
    name
    dataTypes {
      id
      name
      category
      isInternal
      isInput
      isOutput
      isProcessed
      isStored
    }
  }
}
    `;
export const useGetMissionDataTypesQuery = <TData = GetMissionDataTypesQuery, TError = unknown>(
  variables: GetMissionDataTypesQueryVariables,
  options?: UseQueryOptions<GetMissionDataTypesQuery, TError, TData>
) =>
  useQuery<GetMissionDataTypesQuery, TError, TData>(
    ["getMissionDataTypes", variables],
    fetcher<GetMissionDataTypesQuery, GetMissionDataTypesQueryVariables>(GetMissionDataTypesDocument, variables),
    options
  );

useGetMissionDataTypesQuery.getKey = (variables: GetMissionDataTypesQueryVariables) => [
  "getMissionDataTypes",
  variables,
];
export const GetNodeMissionDocument = `
    query getNodeMission($project: ID!, $variant: ID!, $id: ID!) {
  node(project: $project, variant: $variant, id: $id) {
    id
    name
    missions {
      id
      name
      isInternal
    }
  }
}
    `;
export const useGetNodeMissionQuery = <TData = GetNodeMissionQuery, TError = unknown>(
  variables: GetNodeMissionQueryVariables,
  options?: UseQueryOptions<GetNodeMissionQuery, TError, TData>
) =>
  useQuery<GetNodeMissionQuery, TError, TData>(
    ["getNodeMission", variables],
    fetcher<GetNodeMissionQuery, GetNodeMissionQueryVariables>(GetNodeMissionDocument, variables),
    options
  );

useGetNodeMissionQuery.getKey = (variables: GetNodeMissionQueryVariables) => ["getNodeMission", variables];
export const GetNodeDataflowsDocument = `
    query getNodeDataflows($project: ID!, $variant: ID!, $id: ID!) {
  node(project: $project, variant: $variant, id: $id) {
    id
    name
    dataFlows {
      id
      name
      category
    }
  }
}
    `;
export const useGetNodeDataflowsQuery = <TData = GetNodeDataflowsQuery, TError = unknown>(
  variables: GetNodeDataflowsQueryVariables,
  options?: UseQueryOptions<GetNodeDataflowsQuery, TError, TData>
) =>
  useQuery<GetNodeDataflowsQuery, TError, TData>(
    ["getNodeDataflows", variables],
    fetcher<GetNodeDataflowsQuery, GetNodeDataflowsQueryVariables>(GetNodeDataflowsDocument, variables),
    options
  );

useGetNodeDataflowsQuery.getKey = (variables: GetNodeDataflowsQueryVariables) => ["getNodeDataflows", variables];
export const GetNodeResourcesDocument = `
    query getNodeResources($project: ID!, $variant: ID!, $id: ID!) {
  node(project: $project, variant: $variant, id: $id) {
    id
    name
    resources {
      id
      name
    }
  }
}
    `;
export const useGetNodeResourcesQuery = <TData = GetNodeResourcesQuery, TError = unknown>(
  variables: GetNodeResourcesQueryVariables,
  options?: UseQueryOptions<GetNodeResourcesQuery, TError, TData>
) =>
  useQuery<GetNodeResourcesQuery, TError, TData>(
    ["getNodeResources", variables],
    fetcher<GetNodeResourcesQuery, GetNodeResourcesQueryVariables>(GetNodeResourcesDocument, variables),
    options
  );

useGetNodeResourcesQuery.getKey = (variables: GetNodeResourcesQueryVariables) => ["getNodeResources", variables];
export const GetNodeParentsDocument = `
    query getNodeParents($project: ID!, $variant: ID!) {
  nodes(project: $project, variant: $variant) {
    id
    name
    structuralcat
    isInternal
  }
}
    `;
export const useGetNodeParentsQuery = <TData = GetNodeParentsQuery, TError = unknown>(
  variables: GetNodeParentsQueryVariables,
  options?: UseQueryOptions<GetNodeParentsQuery, TError, TData>
) =>
  useQuery<GetNodeParentsQuery, TError, TData>(
    ["getNodeParents", variables],
    fetcher<GetNodeParentsQuery, GetNodeParentsQueryVariables>(GetNodeParentsDocument, variables),
    options
  );

useGetNodeParentsQuery.getKey = (variables: GetNodeParentsQueryVariables) => ["getNodeParents", variables];
export const GetNodeInDataDocument = `
    query getNodeInData($project: ID!, $variant: ID!, $id: ID!) {
  node(project: $project, variant: $variant, id: $id) {
    id
    name
    inData {
      id
      name
      category
      isInternal
      isInput
      isOutput
      isProcessed
      isStored
    }
  }
}
    `;
export const useGetNodeInDataQuery = <TData = GetNodeInDataQuery, TError = unknown>(
  variables: GetNodeInDataQueryVariables,
  options?: UseQueryOptions<GetNodeInDataQuery, TError, TData>
) =>
  useQuery<GetNodeInDataQuery, TError, TData>(
    ["getNodeInData", variables],
    fetcher<GetNodeInDataQuery, GetNodeInDataQueryVariables>(GetNodeInDataDocument, variables),
    options
  );

useGetNodeInDataQuery.getKey = (variables: GetNodeInDataQueryVariables) => ["getNodeInData", variables];
export const GetNodeOutDataDocument = `
    query getNodeOutData($project: ID!, $variant: ID!, $id: ID!) {
  node(project: $project, variant: $variant, id: $id) {
    id
    name
    outData {
      id
      name
      category
      isInternal
      isInput
      isOutput
      isProcessed
      isStored
    }
  }
}
    `;
export const useGetNodeOutDataQuery = <TData = GetNodeOutDataQuery, TError = unknown>(
  variables: GetNodeOutDataQueryVariables,
  options?: UseQueryOptions<GetNodeOutDataQuery, TError, TData>
) =>
  useQuery<GetNodeOutDataQuery, TError, TData>(
    ["getNodeOutData", variables],
    fetcher<GetNodeOutDataQuery, GetNodeOutDataQueryVariables>(GetNodeOutDataDocument, variables),
    options
  );

useGetNodeOutDataQuery.getKey = (variables: GetNodeOutDataQueryVariables) => ["getNodeOutData", variables];
export const GetAllNodesDocument = `
    query getAllNodes($project: ID!, $variant: ID!) {
  nodes(project: $project, variant: $variant) {
    id
    name
    structuralcat
    category
    isBoundary
    isInternal
    parent {
      id
      name
      structuralcat
      category
      isBoundary
      isInternal
      parent {
        id
      }
      riskDistributionRank
      riskDistributionPercent
      findingCount
      controlCount: controlsCount
    }
    riskDistributionRank
    riskDistributionPercent
    findingCount
    controlCount: controlsCount
  }
}
    `;
export const useGetAllNodesQuery = <TData = GetAllNodesQuery, TError = unknown>(
  variables: GetAllNodesQueryVariables,
  options?: UseQueryOptions<GetAllNodesQuery, TError, TData>
) =>
  useQuery<GetAllNodesQuery, TError, TData>(
    ["getAllNodes", variables],
    fetcher<GetAllNodesQuery, GetAllNodesQueryVariables>(GetAllNodesDocument, variables),
    options
  );

useGetAllNodesQuery.getKey = (variables: GetAllNodesQueryVariables) => ["getAllNodes", variables];
export const GetPersonCapabilitiesDocument = `
    query getPersonCapabilities($project: ID!, $id: ID!) {
  person(project: $project, id: $id) {
    id
    name
    capabilities {
      id
      name
      isInternal
    }
  }
}
    `;
export const useGetPersonCapabilitiesQuery = <TData = GetPersonCapabilitiesQuery, TError = unknown>(
  variables: GetPersonCapabilitiesQueryVariables,
  options?: UseQueryOptions<GetPersonCapabilitiesQuery, TError, TData>
) =>
  useQuery<GetPersonCapabilitiesQuery, TError, TData>(
    ["getPersonCapabilities", variables],
    fetcher<GetPersonCapabilitiesQuery, GetPersonCapabilitiesQueryVariables>(GetPersonCapabilitiesDocument, variables),
    options
  );

useGetPersonCapabilitiesQuery.getKey = (variables: GetPersonCapabilitiesQueryVariables) => [
  "getPersonCapabilities",
  variables,
];
export const GetPersonMissionsDocument = `
    query getPersonMissions($project: ID!, $id: ID!) {
  person(project: $project, id: $id) {
    id
    name
    missions {
      id
      name
      isInternal
    }
  }
}
    `;
export const useGetPersonMissionsQuery = <TData = GetPersonMissionsQuery, TError = unknown>(
  variables: GetPersonMissionsQueryVariables,
  options?: UseQueryOptions<GetPersonMissionsQuery, TError, TData>
) =>
  useQuery<GetPersonMissionsQuery, TError, TData>(
    ["getPersonMissions", variables],
    fetcher<GetPersonMissionsQuery, GetPersonMissionsQueryVariables>(GetPersonMissionsDocument, variables),
    options
  );

useGetPersonMissionsQuery.getKey = (variables: GetPersonMissionsQueryVariables) => ["getPersonMissions", variables];
export const GetPersonResourcesDocument = `
    query getPersonResources($project: ID!, $id: ID!) {
  person(project: $project, id: $id) {
    id
    name
    resources {
      id
      name
    }
  }
}
    `;
export const useGetPersonResourcesQuery = <TData = GetPersonResourcesQuery, TError = unknown>(
  variables: GetPersonResourcesQueryVariables,
  options?: UseQueryOptions<GetPersonResourcesQuery, TError, TData>
) =>
  useQuery<GetPersonResourcesQuery, TError, TData>(
    ["getPersonResources", variables],
    fetcher<GetPersonResourcesQuery, GetPersonResourcesQueryVariables>(GetPersonResourcesDocument, variables),
    options
  );

useGetPersonResourcesQuery.getKey = (variables: GetPersonResourcesQueryVariables) => ["getPersonResources", variables];
export const GetResourceMissionsDocument = `
    query getResourceMissions($project: ID!, $id: ID!) {
  resource(project: $project, id: $id) {
    id
    name
    missions {
      id
      name
      isInternal
    }
  }
}
    `;
export const useGetResourceMissionsQuery = <TData = GetResourceMissionsQuery, TError = unknown>(
  variables: GetResourceMissionsQueryVariables,
  options?: UseQueryOptions<GetResourceMissionsQuery, TError, TData>
) =>
  useQuery<GetResourceMissionsQuery, TError, TData>(
    ["getResourceMissions", variables],
    fetcher<GetResourceMissionsQuery, GetResourceMissionsQueryVariables>(GetResourceMissionsDocument, variables),
    options
  );

useGetResourceMissionsQuery.getKey = (variables: GetResourceMissionsQueryVariables) => [
  "getResourceMissions",
  variables,
];
export const GetResourceCapabilitiesDocument = `
    query getResourceCapabilities($project: ID!, $id: ID!) {
  resource(project: $project, id: $id) {
    id
    name
    capabilities {
      id
      name
      isInternal
    }
  }
}
    `;
export const useGetResourceCapabilitiesQuery = <TData = GetResourceCapabilitiesQuery, TError = unknown>(
  variables: GetResourceCapabilitiesQueryVariables,
  options?: UseQueryOptions<GetResourceCapabilitiesQuery, TError, TData>
) =>
  useQuery<GetResourceCapabilitiesQuery, TError, TData>(
    ["getResourceCapabilities", variables],
    fetcher<GetResourceCapabilitiesQuery, GetResourceCapabilitiesQueryVariables>(
      GetResourceCapabilitiesDocument,
      variables
    ),
    options
  );

useGetResourceCapabilitiesQuery.getKey = (variables: GetResourceCapabilitiesQueryVariables) => [
  "getResourceCapabilities",
  variables,
];
export const GetResourcePersonsDocument = `
    query getResourcePersons($project: ID!, $id: ID!) {
  resource(project: $project, id: $id) {
    id
    name
    persons {
      id
      name
      category
      isInternal
    }
  }
}
    `;
export const useGetResourcePersonsQuery = <TData = GetResourcePersonsQuery, TError = unknown>(
  variables: GetResourcePersonsQueryVariables,
  options?: UseQueryOptions<GetResourcePersonsQuery, TError, TData>
) =>
  useQuery<GetResourcePersonsQuery, TError, TData>(
    ["getResourcePersons", variables],
    fetcher<GetResourcePersonsQuery, GetResourcePersonsQueryVariables>(GetResourcePersonsDocument, variables),
    options
  );

useGetResourcePersonsQuery.getKey = (variables: GetResourcePersonsQueryVariables) => ["getResourcePersons", variables];
export const GetSctmAllocatedControlsDocument = `
    query getSCTMAllocatedControls($sctm: ID!) {
  allocatedControls(sctm: $sctm) {
    id
    name
    calibratedStrength
    strength
    vulnerabilitycategory
    isCompliant
    anobject {
      id
      name
      noun
    }
    controltype {
      id
      name
      cost
      family {
        id
        name
      }
      catalog {
        id
        name
      }
    }
  }
}
    `;
export const useGetSctmAllocatedControlsQuery = <TData = GetSctmAllocatedControlsQuery, TError = unknown>(
  variables: GetSctmAllocatedControlsQueryVariables,
  options?: UseQueryOptions<GetSctmAllocatedControlsQuery, TError, TData>
) =>
  useQuery<GetSctmAllocatedControlsQuery, TError, TData>(
    ["getSCTMAllocatedControls", variables],
    fetcher<GetSctmAllocatedControlsQuery, GetSctmAllocatedControlsQueryVariables>(
      GetSctmAllocatedControlsDocument,
      variables
    ),
    options
  );

useGetSctmAllocatedControlsQuery.getKey = (variables: GetSctmAllocatedControlsQueryVariables) => [
  "getSCTMAllocatedControls",
  variables,
];
export const GetSystemElementNamesDocument = `
    query getSystemElementNames($ids: [ID!]!, $project: ID!) {
  systemElementsByIds(ids: $ids, project: $project) {
    id
    name
    noun
  }
}
    `;
export const useGetSystemElementNamesQuery = <TData = GetSystemElementNamesQuery, TError = unknown>(
  variables: GetSystemElementNamesQueryVariables,
  options?: UseQueryOptions<GetSystemElementNamesQuery, TError, TData>
) =>
  useQuery<GetSystemElementNamesQuery, TError, TData>(
    ["getSystemElementNames", variables],
    fetcher<GetSystemElementNamesQuery, GetSystemElementNamesQueryVariables>(GetSystemElementNamesDocument, variables),
    options
  );

useGetSystemElementNamesQuery.getKey = (variables: GetSystemElementNamesQueryVariables) => [
  "getSystemElementNames",
  variables,
];
export const GetAttackerTargetDocument = `
    query getAttackerTarget($id: ID!, $project: ID!) {
  attacker(id: $id, project: $project) {
    id
    name
    targets {
      id
      name
    }
  }
}
    `;
export const useGetAttackerTargetQuery = <TData = GetAttackerTargetQuery, TError = unknown>(
  variables: GetAttackerTargetQueryVariables,
  options?: UseQueryOptions<GetAttackerTargetQuery, TError, TData>
) =>
  useQuery<GetAttackerTargetQuery, TError, TData>(
    ["getAttackerTarget", variables],
    fetcher<GetAttackerTargetQuery, GetAttackerTargetQueryVariables>(GetAttackerTargetDocument, variables),
    options
  );

useGetAttackerTargetQuery.getKey = (variables: GetAttackerTargetQueryVariables) => ["getAttackerTarget", variables];
export const GetThreatGroupsDocument = `
    query getThreatGroups {
  threatGroups {
    id
    name
    catalog {
      id
      name
      organization {
        id
      }
    }
    isNationState
    tier
  }
}
    `;
export const useGetThreatGroupsQuery = <TData = GetThreatGroupsQuery, TError = unknown>(
  variables?: GetThreatGroupsQueryVariables,
  options?: UseQueryOptions<GetThreatGroupsQuery, TError, TData>
) =>
  useQuery<GetThreatGroupsQuery, TError, TData>(
    variables === undefined ? ["getThreatGroups"] : ["getThreatGroups", variables],
    fetcher<GetThreatGroupsQuery, GetThreatGroupsQueryVariables>(GetThreatGroupsDocument, variables),
    options
  );

useGetThreatGroupsQuery.getKey = (variables?: GetThreatGroupsQueryVariables) =>
  variables === undefined ? ["getThreatGroups"] : ["getThreatGroups", variables];
export const GetAssetCatTreeDocument = `
    query getAssetCatTree($assetcat: String!, $project: ID!, $variant: ID) {
  assetcat(assetcat: $assetcat, project: $project, variant: $variant) {
    name
    riskDistribution {
      rank
      percent
    }
  }
}
    `;
export const useGetAssetCatTreeQuery = <TData = GetAssetCatTreeQuery, TError = unknown>(
  variables: GetAssetCatTreeQueryVariables,
  options?: UseQueryOptions<GetAssetCatTreeQuery, TError, TData>
) =>
  useQuery<GetAssetCatTreeQuery, TError, TData>(
    ["getAssetCatTree", variables],
    fetcher<GetAssetCatTreeQuery, GetAssetCatTreeQueryVariables>(GetAssetCatTreeDocument, variables),
    options
  );

useGetAssetCatTreeQuery.getKey = (variables: GetAssetCatTreeQueryVariables) => ["getAssetCatTree", variables];
export const GetAssetCategoryTreeDocument = `
    query getAssetCategoryTree($assetcat: String!, $project: ID!, $variant: ID) {
  assetcat(assetcat: $assetcat, project: $project, variant: $variant) {
    name
    asset {
      id
      name
      category {
        name
      }
      risk {
        id
        name
        category
        rank {
          value
        }
        ue {
          id
          name
          category
          target {
            id
            name
            noun
          }
          trev {
            id
            name
            category
            attack {
              id
              name
              tactic
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetAssetCategoryTreeQuery = <TData = GetAssetCategoryTreeQuery, TError = unknown>(
  variables: GetAssetCategoryTreeQueryVariables,
  options?: UseQueryOptions<GetAssetCategoryTreeQuery, TError, TData>
) =>
  useQuery<GetAssetCategoryTreeQuery, TError, TData>(
    ["getAssetCategoryTree", variables],
    fetcher<GetAssetCategoryTreeQuery, GetAssetCategoryTreeQueryVariables>(GetAssetCategoryTreeDocument, variables),
    options
  );

useGetAssetCategoryTreeQuery.getKey = (variables: GetAssetCategoryTreeQueryVariables) => [
  "getAssetCategoryTree",
  variables,
];
export const GetAssetTreeDocument = `
    query getAssetTree($asset: ID!, $variant: ID) {
  asset(asset: $asset, variant: $variant) {
    id
    name
    category {
      name
    }
    risk {
      id
      name
      category
      rank {
        value
      }
      ue {
        id
        name
        category
        target {
          id
          name
          noun
        }
        trev {
          id
          name
          category
          attack {
            id
            name
            tactic
          }
        }
      }
    }
  }
}
    `;
export const useGetAssetTreeQuery = <TData = GetAssetTreeQuery, TError = unknown>(
  variables: GetAssetTreeQueryVariables,
  options?: UseQueryOptions<GetAssetTreeQuery, TError, TData>
) =>
  useQuery<GetAssetTreeQuery, TError, TData>(
    ["getAssetTree", variables],
    fetcher<GetAssetTreeQuery, GetAssetTreeQueryVariables>(GetAssetTreeDocument, variables),
    options
  );

useGetAssetTreeQuery.getKey = (variables: GetAssetTreeQueryVariables) => ["getAssetTree", variables];
export const GetRiskTreeDocument = `
    query getRiskTree($risk: ID!, $variant: ID) {
  risk(risk: $risk, variant: $variant) {
    id
    name
    category
    rank {
      value
    }
    ue {
      id
      name
      category
      target {
        id
        name
        noun
      }
      trev {
        id
        name
        category
        attack {
          id
          name
          tactic
        }
      }
    }
    asset {
      id
      name
      category {
        name
      }
    }
  }
}
    `;
export const useGetRiskTreeQuery = <TData = GetRiskTreeQuery, TError = unknown>(
  variables: GetRiskTreeQueryVariables,
  options?: UseQueryOptions<GetRiskTreeQuery, TError, TData>
) =>
  useQuery<GetRiskTreeQuery, TError, TData>(
    ["getRiskTree", variables],
    fetcher<GetRiskTreeQuery, GetRiskTreeQueryVariables>(GetRiskTreeDocument, variables),
    options
  );

useGetRiskTreeQuery.getKey = (variables: GetRiskTreeQueryVariables) => ["getRiskTree", variables];
export const GetUndesiredEventTreeDocument = `
    query getUndesiredEventTree($ue: ID!, $variant: ID) {
  ue(ue: $ue, variant: $variant) {
    id
    name
    category
    target {
      id
      name
      noun
    }
    trev {
      id
      name
      category
      attack {
        id
        name
        tactic
      }
    }
    risk {
      id
      name
      category
      rank {
        value
      }
      asset {
        id
        name
        category {
          name
        }
      }
    }
  }
}
    `;
export const useGetUndesiredEventTreeQuery = <TData = GetUndesiredEventTreeQuery, TError = unknown>(
  variables: GetUndesiredEventTreeQueryVariables,
  options?: UseQueryOptions<GetUndesiredEventTreeQuery, TError, TData>
) =>
  useQuery<GetUndesiredEventTreeQuery, TError, TData>(
    ["getUndesiredEventTree", variables],
    fetcher<GetUndesiredEventTreeQuery, GetUndesiredEventTreeQueryVariables>(GetUndesiredEventTreeDocument, variables),
    options
  );

useGetUndesiredEventTreeQuery.getKey = (variables: GetUndesiredEventTreeQueryVariables) => [
  "getUndesiredEventTree",
  variables,
];
export const GetTargetTreeDocument = `
    query getTargetTree($target: ID!, $variant: ID) {
  target(target: $target, variant: $variant) {
    id
    name
    noun
    trev {
      id
      name
      category
      attack {
        id
        name
        tactic
      }
    }
    ue {
      id
      name
      category
      target {
        id
        name
        noun
      }
      risk {
        id
        name
        category
        rank {
          value
        }
        asset {
          id
          name
          category {
            name
          }
        }
      }
    }
  }
}
    `;
export const useGetTargetTreeQuery = <TData = GetTargetTreeQuery, TError = unknown>(
  variables: GetTargetTreeQueryVariables,
  options?: UseQueryOptions<GetTargetTreeQuery, TError, TData>
) =>
  useQuery<GetTargetTreeQuery, TError, TData>(
    ["getTargetTree", variables],
    fetcher<GetTargetTreeQuery, GetTargetTreeQueryVariables>(GetTargetTreeDocument, variables),
    options
  );

useGetTargetTreeQuery.getKey = (variables: GetTargetTreeQueryVariables) => ["getTargetTree", variables];
export const GetThreatEventTreeDocument = `
    query getThreatEventTree($trev: ID!, $variant: ID) {
  trev(trev: $trev, variant: $variant) {
    id
    name
    category
    target {
      id
      name
      noun
    }
    ue {
      id
      name
      category
      risk {
        id
        name
        category
        rank {
          value
        }
        asset {
          id
          name
          category {
            name
          }
        }
      }
    }
    attack {
      id
      name
      tactic
    }
  }
}
    `;
export const useGetThreatEventTreeQuery = <TData = GetThreatEventTreeQuery, TError = unknown>(
  variables: GetThreatEventTreeQueryVariables,
  options?: UseQueryOptions<GetThreatEventTreeQuery, TError, TData>
) =>
  useQuery<GetThreatEventTreeQuery, TError, TData>(
    ["getThreatEventTree", variables],
    fetcher<GetThreatEventTreeQuery, GetThreatEventTreeQueryVariables>(GetThreatEventTreeDocument, variables),
    options
  );

useGetThreatEventTreeQuery.getKey = (variables: GetThreatEventTreeQueryVariables) => ["getThreatEventTree", variables];
export const GetAttackTreeDocument = `
    query getAttackTree($attack: ID!, $variant: ID) {
  attack(attack: $attack, variant: $variant) {
    id
    name
    tactic
    trev {
      id
      name
      category
      target {
        id
        name
        noun
      }
      ue {
        id
        name
        category
        target {
          id
          name
          noun
        }
        risk {
          id
          name
          category
          rank {
            value
          }
          asset {
            id
            name
            category {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetAttackTreeQuery = <TData = GetAttackTreeQuery, TError = unknown>(
  variables: GetAttackTreeQueryVariables,
  options?: UseQueryOptions<GetAttackTreeQuery, TError, TData>
) =>
  useQuery<GetAttackTreeQuery, TError, TData>(
    ["getAttackTree", variables],
    fetcher<GetAttackTreeQuery, GetAttackTreeQueryVariables>(GetAttackTreeDocument, variables),
    options
  );

useGetAttackTreeQuery.getKey = (variables: GetAttackTreeQueryVariables) => ["getAttackTree", variables];
export const SystemDiagramDataDocument = `
    query SystemDiagramData($project: ID!, $variant: ID!, $withRisk: Boolean!) {
  nodes(project: $project, variant: $variant) {
    ...SystemDiagramNode
  }
  exchanges(project: $project, variant: $variant) {
    ...SystemDiagramExchange
  }
}
    ${SystemDiagramNodeFragmentDoc}
${SystemDiagramExchangeFragmentDoc}`;
export const useSystemDiagramDataQuery = <TData = SystemDiagramDataQuery, TError = unknown>(
  variables: SystemDiagramDataQueryVariables,
  options?: UseQueryOptions<SystemDiagramDataQuery, TError, TData>
) =>
  useQuery<SystemDiagramDataQuery, TError, TData>(
    ["SystemDiagramData", variables],
    fetcher<SystemDiagramDataQuery, SystemDiagramDataQueryVariables>(SystemDiagramDataDocument, variables),
    options
  );

useSystemDiagramDataQuery.getKey = (variables: SystemDiagramDataQueryVariables) => ["SystemDiagramData", variables];
export const DeleteItemsDocument = `
    mutation deleteItems($project: ID!, $nodes: [ID!]!, $exchanges: [ID!]!) {
  deleteNodes(project: $project, nodes: $nodes, exchanges: $exchanges)
}
    `;
export const useDeleteItemsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DeleteItemsMutation, TError, DeleteItemsMutationVariables, TContext>
) =>
  useMutation<DeleteItemsMutation, TError, DeleteItemsMutationVariables, TContext>(
    ["deleteItems"],
    (variables?: DeleteItemsMutationVariables) =>
      fetcher<DeleteItemsMutation, DeleteItemsMutationVariables>(DeleteItemsDocument, variables)(),
    options
  );
export const GetDeleteJobStatusDocument = `
    query getDeleteJobStatus($run: ID!) {
  deleteNodesStatus(run: $run)
}
    `;
export const useGetDeleteJobStatusQuery = <TData = GetDeleteJobStatusQuery, TError = unknown>(
  variables: GetDeleteJobStatusQueryVariables,
  options?: UseQueryOptions<GetDeleteJobStatusQuery, TError, TData>
) =>
  useQuery<GetDeleteJobStatusQuery, TError, TData>(
    ["getDeleteJobStatus", variables],
    fetcher<GetDeleteJobStatusQuery, GetDeleteJobStatusQueryVariables>(GetDeleteJobStatusDocument, variables),
    options
  );

useGetDeleteJobStatusQuery.getKey = (variables: GetDeleteJobStatusQueryVariables) => ["getDeleteJobStatus", variables];
export const ImportSctmDocument = `
    mutation importSctm($id: ID!, $body: String) {
  importSctm(id: $id, body: $body)
}
    `;
export const useImportSctmMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ImportSctmMutation, TError, ImportSctmMutationVariables, TContext>
) =>
  useMutation<ImportSctmMutation, TError, ImportSctmMutationVariables, TContext>(
    ["importSctm"],
    (variables?: ImportSctmMutationVariables) =>
      fetcher<ImportSctmMutation, ImportSctmMutationVariables>(ImportSctmDocument, variables)(),
    options
  );
export const ImportSctmStatusDocument = `
    query importSctmStatus($run: ID!) {
  importSctmStatus(run: $run)
}
    `;
export const useImportSctmStatusQuery = <TData = ImportSctmStatusQuery, TError = unknown>(
  variables: ImportSctmStatusQueryVariables,
  options?: UseQueryOptions<ImportSctmStatusQuery, TError, TData>
) =>
  useQuery<ImportSctmStatusQuery, TError, TData>(
    ["importSctmStatus", variables],
    fetcher<ImportSctmStatusQuery, ImportSctmStatusQueryVariables>(ImportSctmStatusDocument, variables),
    options
  );

useImportSctmStatusQuery.getKey = (variables: ImportSctmStatusQueryVariables) => ["importSctmStatus", variables];
