import { useEffect, useState, useCallback } from "react";
import { queryClient } from "libs/react-query";
import toast from "react-hot-toast";
import PropTypes from "prop-types";

// Components
import { ActionButton, ErrorSpan } from "components/elements";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import ProgressBar from "react-bootstrap/ProgressBar";

// Styles
// import FormStyledError from "components/forms/FormStyledError";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
// Recoil
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { userRoleState } from "atoms/atoms-admin";
import { runIdState, isSubmissionPendingState, runIdCanceledSubmissionState } from "atoms/atoms-importer";

import { isRiskAnalyst, isSystemEngineer } from "services/user";
import { COMMON, IMPORT_STATUS } from "constants/brm";
import localforage from "localforage";
// Hooks
import { ImportApi, ImportContext, useVariant } from "features/brm";
import Form from "react-bootstrap/Form";
import * as ModalStyles from "components/modals/Modal.styles";
import { isBrmApiError } from "utils/type-guard";
import { useWizardUpload, useWizardImportSubmission } from "../../api";

// Local
import { Dodaf } from "../Dodaf";
import { SCTM } from "../Sctm";
import { Blv as BLV } from "../Blv";
import { Baseline as BaselineWizard } from "../Baseline";
import * as S from "../styles/wizard.styles";

const POLL_INTERVAL = 1000;

export const Wizard = ({
  projectId,
  setUploadModalIsOpen,
  sctmId,
  context,
  baselineName,
  elementNameInUrl,
  closeTrigger,
}) => {
  // When context = ImportContext.baseline => projectId = baselineId.
  const [responseFromUpload, setResponseFromUpload] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [postError, setPostError] = useState("");
  const [disableUpload, setDisableUpload] = useState(false);
  const [importerType, setImporterType] = useState("");
  const [progress, setProgress] = useState(0);
  const userRole = useRecoilValue(userRoleState);
  const runId = useRecoilValue(runIdState);
  const resetRunId = useResetRecoilState(runIdState);
  const [localRunId, setLocalRunId] = useState(COMMON.nullUuid);
  const setIsSubmissionPending = useSetRecoilState(isSubmissionPendingState);
  const setRunIdCanceledSubmission = useSetRecoilState(runIdCanceledSubmissionState);
  const [pendingSubmissionId, setPendingSubmissionId] = useState("");
  const {
    handlers: { setCurrentVariant, getSystemVariant },
  } = useVariant();

  // Queries
  const { mutate: fileSubmission, isError: isFileSubmissionError, error: Error } = useWizardImportSubmission();
  const { mutate: cancelIimport } = ImportApi.useCancelImport();

  const onProgress = useCallback((event) => {
    setProgress(event.percent ? Math.trunc(event.percent) : 0);
  }, []);

  const {
    mutate: fileUpload,
    isError: isFileUploadError,
    error: fileUploadError,
  } = useWizardUpload(setResponseFromUpload, setShowUploadProgress, setImporterType, onProgress);

  ImportApi.useSubmissionStatus({
    projectId,
    context,
    runId: localRunId,
    options: {
      enabled: context === ImportContext.baseline && localRunId !== COMMON.nullUuid,
      refetchInterval: localRunId !== COMMON.nullUuid ? POLL_INTERVAL : false,
      onSuccess: (pollData) => {
        if (!pollData) {
          return;
        }
        if (pollData.status === IMPORT_STATUS.pass) {
          queryClient.invalidateQueries(["getElementControlTypes", projectId, elementNameInUrl]);
          setLocalRunId(COMMON.nullUuid);
          setUploadModalIsOpen(false);
          toast.success("Baseline controls submitted successfully");
        }
        if (pollData.status === IMPORT_STATUS.fail) {
          setLocalRunId(COMMON.nullUuid);
          setUploadModalIsOpen(false);
          toast.error("An error ocurred submitting baseline controls");
        }
      },
      onError: () => {
        setLocalRunId(COMMON.nullUuid);
        setUploadModalIsOpen(false);
        toast.error("An error ocurred loading baseline controls");
      },
    },
  });

  const handleWizardCancel = useCallback(() => {
    cancelIimport({ projectId, runId: pendingSubmissionId });
    setRunIdCanceledSubmission(pendingSubmissionId);
    setIsSubmissionPending(false);
    setUploadModalIsOpen(false);
  }, [
    cancelIimport,
    pendingSubmissionId,
    projectId,
    setRunIdCanceledSubmission,
    setIsSubmissionPending,
    setUploadModalIsOpen,
  ]);

  const handleUploadCancel = useCallback(() => {
    setUploadModalIsOpen(false);
  }, [setUploadModalIsOpen]);

  useEffect(() => {
    if (closeTrigger) {
      handleUploadCancel();
      handleWizardCancel(false);
    }
  }, [closeTrigger, handleUploadCancel, handleWizardCancel]);

  const handleFile = (e) => {
    const files = [];
    Array.from(e.target.files).forEach((file) => {
      files.push(file);
    });
    if (files.length > 0) {
      setSelectedFiles(files);
      setPostError("");
    }
  };
  /**
   * File Upload
   */
  const handleFileUpload = () => {
    if (selectedFiles.length !== 0) {
      setDisableUpload(true);
      setShowUploadProgress(true);
      fileUpload(
        { projectId, selectedFiles, context },
        {
          onSuccess: (data) => {
            setPendingSubmissionId(data.id);
            setIsSubmissionPending(true);
          },
        }
      );
    } else setPostError("Please select file(s) to upload");
  };
  /**
   * File Submission
   */
  const handleSubmit = (selectedOptions) => {
    // console.log("selectedOptions", selectedOptions);
    if (responseFromUpload) {
      const result = responseFromUpload;
      if (selectedOptions) {
        result.options = selectedOptions;
      }
      if (!postError) {
        if (runId) {
          resetRunId();
        }
        fileSubmission(
          { projectId, result, context },
          {
            onSuccess: (data) => {
              if (context === ImportContext.baseline) {
                setLocalRunId(data.id);
              } else {
                setUploadModalIsOpen(false);
              }
              if (isSystemEngineer && context === ImportContext.system) {
                setCurrentVariant(getSystemVariant());
              }
              // just uploaded a new model easiest thing is to clean the entire cache
              // TODO just clear the caches that are required
              queryClient.clear();
            },
          }
        );
        localforage.removeItem(`sd_layout_${projectId}`);
      }
    } else {
      setPostError("No response from uploaded files");
    }
  };

  const Default = () => (
    <S.DefaultWizard>
      <h5 className="wizardTitle" id={`${responseFromUpload.type}_title`}>
        {responseFromUpload.type} Wizard
      </h5>
      <br />
      {responseFromUpload.type} upload successful.
      <br />
      No additional import configuration required. Proceed with import.
      <DialogButtonDivStyled>
        <DialogButtonStyled
          id={`${responseFromUpload.type}_submit`}
          onClick={() => handleSubmit(responseFromUpload.options)}
        >
          Import
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </S.DefaultWizard>
  );

  /**
   *
   * @returns Wizard based on the import type
   */
  function getImporterWizard() {
    if (importerType) {
      switch (importerType) {
        case "UPDM/UAF/SYSML":
          if (!isSystemEngineer(userRole)) {
            return <div>Invalid File</div>;
          }
          return (
            <Dodaf options={responseFromUpload.options} onCancel={handleWizardCancel} handleSubmit={handleSubmit} />
          );
        case "SCTM":
          if (!isRiskAnalyst(userRole)) {
            return <div>Invalid File</div>;
          }
          return (
            <SCTM
              options={responseFromUpload.options}
              handleSubmit={handleSubmit}
              setUploadModalIsOpen={setUploadModalIsOpen}
              sctmId={sctmId || null}
              submissionId={pendingSubmissionId}
            />
          );
        case "BLV":
          if (!isSystemEngineer(userRole)) {
            return <div>Invalid File</div>;
          }
          return <BLV options={responseFromUpload.options} handleSubmit={handleSubmit} />;

        case "Baseline":
          return (
            <BaselineWizard
              baselineId={projectId}
              options={responseFromUpload.options}
              handleSubmit={handleSubmit}
              baselineName={baselineName}
              elementNameInUrl={elementNameInUrl}
            />
          );

        default:
          return <Default />;
      }
    } else return null;
  }

  useEffect(() => {
    if (isFileUploadError) {
      if (context === ImportContext.bor) {
        setPostError(
          `*The file that was uploaded does not contain Blade OneReport(BOR) Software Vulnerability findings.
          Please check with your BOR team that your file was generated by BOR and try again.`
        );
      } else if (isBrmApiError(fileUploadError)) {
        if (fileUploadError.status === 413) {
          setPostError("Uploaded file is too large.  Try again with a file smaller than 500M.");
        } else {
          setPostError(
            `*Invalid File upload.  Error: ${fileUploadError.statusText} , STATUS : ${fileUploadError.status}`
          );
        }
      } else if (fileUploadError?.error?.timeout) {
        setPostError(fileUploadError.error.message);
      } else {
        setPostError(`${fileUploadError}`);
      }
      setShowUploadProgress(false);
    }
    if (isFileSubmissionError) {
      setPostError(`*Submission error : ${Error?.statusText}`);
    }
  }, [isFileSubmissionError, Error, isFileUploadError, fileUploadError, context]);

  return (
    <div id="Wizard">
      <S.WizardUpload id="Wizard_import">
        <ModalStyles.Body>
          <Form>
            <Form.Label>Select file(s) to import</Form.Label>
            <Form.Group className="mb-3">
              <Form.Control
                accept=".json,.doc,.docx,.xml,.csv,.mdxml,.xlsx,.DOCX,.mdzip,.zip,.txt"
                type="file"
                onChange={(e) => handleFile(e)}
                disabled={disableUpload}
                multiple
              />
            </Form.Group>
          </Form>
          {postError ? <ErrorSpan>ERROR: {postError}</ErrorSpan> : ""}
          {showUploadProgress ? (
            <>
              <span>Upload Progress: {`${progress}%`}</span>
              <ProgressBar now={progress} />
            </>
          ) : null}
          <S.WizardResponsePanel id="Wizard_importResponse">
            {responseFromUpload && <>{getImporterWizard()}</>}{" "}
          </S.WizardResponsePanel>
          {isSystemEngineer(userRole) && importerType !== "UPDM/UAF/SYSML" ? (
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
                <AiOutlineExclamationCircle size="80px" fill="#6c757d" />
                <div>
                  Prior to importing the System Model, ensure your project is configured to use the relevant
                  knowledgebase, otherwise the default knowledgebase will be assigned. Configuration is done via the
                  following role and task:
                  <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                    <ul>
                      <li>Cybersecurity Expert - Configure Knowledgebase</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </ModalStyles.Body>
        {!responseFromUpload && (
          <ModalStyles.Footer>
            <ActionButton onClick={handleUploadCancel}>Close</ActionButton>
            <ActionButton onClick={handleFileUpload} disabled={disableUpload}>
              Upload
            </ActionButton>
          </ModalStyles.Footer>
        )}
      </S.WizardUpload>
    </div>
  );
};

Wizard.propTypes = {
  projectId: PropTypes.any,
  setUploadModalIsOpen: PropTypes.func,
  sctmId: PropTypes.string,
  context: PropTypes.string,
  baselineName: PropTypes.string,
  elementNameInUrl: PropTypes.string,
  closeTrigger: PropTypes.bool,
};
