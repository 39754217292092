export const Faq = [
  {
    Role: "admin",
    Page: "home",
    Question: "Why does the UI screen in the help video sometimes looks different?",
    Answer:
      "Some of the help videos may have been created using a different version but the video always captures the required set of UI elements to achieve the goal of the help video.",
  },
  {
    Role: "admin",
    Page: "projects",
    Question: "How do I add an element?",
    Answer:
      "To add an element click the Add icon, located in the top right corner. A form opens in a dialog window. Complete the form, which may contain some required some optional fields. Click the Add button at the bottom of the form and the element has now been added.",
  },
  {
    Role: "admin",
    Page: "projects",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "admin",
    Page: "projects",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "admin",
    Page: "projects",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "admin",
    Page: "projects",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "admin",
    Page: "projects",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "admin",
    Page: "projects",
    Question: "What happens if a project is set to Suspended?",
    Answer:
      "A Super Admin can suspend projects related to any organization while an Admin can only suspend projects for their assigned organization. When a project is in the suspended state no users can actively work on the project. If a project is set to the Suspended state while a user is actively working on the project a dialog will appear noting that something has happened, and the user can no longer work on the project. Super Admin's and Admin's should notify project users before setting a project to the Suspended state.",
  },
  {
    Role: "admin",
    Page: "projectOverview",
    Question: "Can a user from another organization have full or read-only access to projects in my organization?",
    Answer:
      "Yes, an organization's Admin can link a user from another organization, provided that the user has been previously attached by a Super Admin to your organization.",
  },
  {
    Role: "admin",
    Page: "projectOverview",
    Question:
      "Can an Admin restore a project from a backup file in one organization to a project in a different organization?",
    Answer:
      "Yes, it is possible to restore a project from a backup file to a project in a different organization. However, it's important to ensure that the catalogs and baselines used in the restored project settings and variants settings are accessible within the organization you are restoring to. This ensures compatibility and functionality of the restored project in its new environment.",
  },
  {
    Role: "admin",
    Page: "projectOverview",
    Question: "Can an Admin restore a project from a backup file in one machine to a project on a different machine?",
    Answer:
      "Yes, an Admin can restore a project from a backup file to a project on a different machine. However, if the catalogs and baselines used in the restored project's settings and variant settings are not accessible on the new machine, BRM will automatically default to NIST 800-53 Rev 5 controls with the CNSSI-Low-High-Moderate baseline.",
  },
  {
    Role: "admin",
    Page: "projectOverview",
    Question: "Can I view details about a user attached to my organization?",
    Answer:
      "No you cannot view details about a user from another organization that has been attached to your organization and assigned to a project within your organization. You can remove the user from your project by selecting the checkbox in front of their username and clicking the Delete button.",
  },
  {
    Role: "admin",
    Page: "projectOverview",
    Question: "How can I get more information on a specific area or section of a page or dialog window?",
    Answer:
      "Some areas or sections of a page have a lowercase i with a circle around it that when selected opens a tool-tip pop that provides specific information related to that section or area.",
  },
  {
    Role: "admin",
    Page: "projectOverview",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "admin",
    Page: "projectOverview",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "admin",
    Page: "projectOverview",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "admin",
    Page: "projectOverview",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "admin",
    Page: "projectOverview",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "admin",
    Page: "projectOverview",
    Question: "What information is in the Configuration tab?",
    Answer:
      "The Configuration tab displays the following read-only project configuration settings; Generate physical attacks - Determines whether physical attacks are part of the generated risk model (Yes) or not (No) when calculating risks; Vulnerability Computation Mode - Determines how vulnerability findings participate in risk calculation: when imported, vulnerability findings are associated with certain vulnerability conditions and change the corresponding vulnerability level. There are two modes: consider allocated controls and increase vulnerability level by one, if vulnerability findings are associated with a vulnerability condition (Default) or set vulnerability level to 'very high' and override any existing controls, if vulnerability findings are associated with a vulnerability condition (Aggressive); Non-Compliant Controls - Determines how 'compliant' column is processed during the import SCTM as allocated controls: if non-compliant security controls are added (Include) or skipped (Exclude); Baseline Mode - Determines if security controls baseline is a maximum set of controls (Ceiling), or a minimum set of controls (Floor), in which case the imported baseline is augmented with a more comprehensive set of controls.",
  },
  {
    Role: "admin",
    Page: "organizations",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "admin",
    Page: "organizations",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "admin",
    Page: "organizations",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "admin",
    Page: "organizations",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "admin",
    Page: "organizationOverview",
    Question: "How can I get more information on a specific area or section of a page or dialog window?",
    Answer:
      "Some areas or sections of a page have a lowercase i with a circle around it that when selected opens a tool-tip pop that provides specific information related to that section or area.",
  },
  {
    Role: "admin",
    Page: "organizationOverview",
    Question: "What information is in the Configuration tab?",
    Answer:
      "The Configuration tab displays the following read-only project configuration settings; Generate physical attacks - Determines whether physical attacks are part of the generated risk model (Yes) or not (No) when calculating risks; Vulnerability Computation Mode - Determines how vulnerability findings participate in risk calculation: when imported, vulnerability findings are associated with certain vulnerability conditions and change the corresponding vulnerability level. There are two modes: consider allocated controls and increase vulnerability level by one, if vulnerability findings are associated with a vulnerability condition (Default) or set vulnerability level to 'very high' and override any existing controls, if vulnerability findings are associated with a vulnerability condition (Aggressive); Non-Compliant Controls - Determines how 'compliant' column is processed during the import SCTM as allocated controls: if non-compliant security controls are added (Include) or skipped (Exclude); Baseline Mode - Determines if security controls baseline is a maximum set of controls (Ceiling), or a minimum set of controls (Floor), in which case the imported baseline is augmented with a more comprehensive set of controls.",
  },
  {
    Role: "admin",
    Page: "organizationOverview",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "admin",
    Page: "organizationOverview",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "admin",
    Page: "organizationOverview",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "admin",
    Page: "users",
    Question: "Can a user be assigned multiple roles?",
    Answer:
      "Yes, a Super Admin or Admin can assign multiple roles to a user. The Super Admin role is the only role that can assign an Admin to an organization.",
  },
  {
    Role: "admin",
    Page: "users",
    Question: "Can multiple users work on the same project?",
    Answer:
      "Yes, two or more users assigned the role of System Engineer, or Risk Analyst, or Threat Analyst can work on the same project so long as they are assigned to the project by a Super Admin or Admin.",
  },
  {
    Role: "admin",
    Page: "users",
    Question: "Can I view details or delete a user attached to my organization?",
    Answer:
      "No you cannot view details or delete a user from another organization that has been attached to your organization. You need to contact the Super Admin to remove a user attached to your organization.",
  },
  {
    Role: "admin",
    Page: "users",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "admin",
    Page: "users",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "admin",
    Page: "users",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "admin",
    Page: "users",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "admin",
    Page: "users",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "admin",
    Page: "userOverview",
    Question: "What does Last Active Role mean?",
    Answer: "Last Active Role is the last role that a user had selected prior to logging out of their BRM session.",
  },
  {
    Role: "admin",
    Page: "userOverview",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "admin",
    Page: "userOverview",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "admin",
    Page: "userOverview",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "admin",
    Page: "userOverview",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "admin",
    Page: "userOverview",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "superAdmin",
    Page: "home",
    Question: "Why does the UI screen in the help video sometimes looks different?",
    Answer:
      "Some of the help videos may have been created using a different version but the video always captures the required set of UI elements to achieve the goal of the help video.",
  },
  {
    Role: "superAdmin",
    Page: "projects",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "superAdmin",
    Page: "projects",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "superAdmin",
    Page: "projects",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "superAdmin",
    Page: "projects",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "superAdmin",
    Page: "projects",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "superAdmin",
    Page: "projects",
    Question: "What happens if a project is set to Suspended?",
    Answer:
      "A Super Admin can suspend projects related to any organization while an Admin can only suspend projects for their assigned organization. When a project is in the suspended state no users can actively work on the project. If a project is set to the Suspended state while a user is actively working on the project a dialog will appear noting that something has happened, and the user can no longer work on the project. Super Admin's and Admin's should notify project users before setting a project to the Suspended state.",
  },
  {
    Role: "superAdmin",
    Page: "projectOverview",
    Question: "What information is in the Configuration tab?",
    Answer:
      "The Configuration tab displays the following project configurations that can be set at the project level; Generate physical attacks - Determines whether physical attacks are part of the generated risk model (Yes) or not (No) when calculating risks; Vulnerability Computation Mode - Determines how vulnerability findings participate in risk calculation: when imported, vulnerability findings are associated with certain vulnerability conditions and change the corresponding vulnerability level. There are two modes: consider allocated controls and increase vulnerability level by one, if vulnerability findings are associated with a vulnerability condition (Default) or set vulnerability level to 'very high' and override any existing controls, if vulnerability findings are associated with a vulnerability condition (Aggressive); Non-Compliant Controls - Determines how 'compliant' column is processed during the import SCTM as allocated controls: if non-compliant security controls are added (Include) or skipped (Exclude); Baseline Mode - Determines if security controls baseline is a maximum set of controls (Ceiling), or a minimum set of controls (Floor), in which case the imported baseline is augmented with a more comprehensive set of controls.",
  },
  {
    Role: "superAdmin",
    Page: "projectOverview",
    Question: "How do I add an element?",
    Answer:
      "To add an element click the Add icon, located in the top right corner. A form opens in a dialog window. Complete the form, which may contain some required some optional fields. Click the Add button at the bottom of the form and the element has now been added.",
  },
  {
    Role: "superAdmin",
    Page: "projectOverview",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "superAdmin",
    Page: "projectOverview",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "superAdmin",
    Page: "projectOverview",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "superAdmin",
    Page: "projectOverview",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "superAdmin",
    Page: "projectOverview",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "superAdmin",
    Page: "organizations",
    Question: "What is an Organization?",
    Answer:
      "A specific real-world assemblage of people and other resources organized for an on-going purpose. An Organization owns projects and has assigned users. Organizations can “partner” with other organizations. For example, Organization “B” is “partner” to Organization “A” this means that “A” can share its projects with “B” and that users from “B” can work on any shared projects from “A”.",
  },
  {
    Role: "superAdmin",
    Page: "organizations",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "superAdmin",
    Page: "organizations",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "superAdmin",
    Page: "organizations",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "superAdmin",
    Page: "organizations",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "superAdmin",
    Page: "organizations",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "superAdmin",
    Page: "organizationOverview",
    Question: "How can I get more information on a specific area or section of a page or dialog window?",
    Answer:
      "Some areas or sections of a page have a lowercase i with a circle around it that when selected opens a tool-tip pop that provides specific information related to that section or area.",
  },
  {
    Role: "superAdmin",
    Page: "organizationOverview",
    Question: "What information is in the Configuration tab?",
    Answer:
      "The Configuration tab displays the following project configurations that can be set at the organization level; Generate physical attacks - Determines whether physical attacks are part of the generated risk model (Yes) or not (No) when calculating risks; Vulnerability Computation Mode - Determines how vulnerability findings participate in risk calculation: when imported, vulnerability findings are associated with certain vulnerability conditions and change the corresponding vulnerability level. There are two modes: consider allocated controls and increase vulnerability level by one, if vulnerability findings are associated with a vulnerability condition (Default) or set vulnerability level to 'very high' and override any existing controls, if vulnerability findings are associated with a vulnerability condition (Aggressive); Non-Compliant Controls - Determines how 'compliant' column is processed during the import SCTM as allocated controls: if non-compliant security controls are added (Include) or skipped (Exclude); Baseline Mode - Determines if security controls baseline is a maximum set of controls (Ceiling), or a minimum set of controls (Floor), in which case the imported baseline is augmented with a more comprehensive set of controls.",
  },
  {
    Role: "superAdmin",
    Page: "organizationOverview",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "superAdmin",
    Page: "organizationOverview",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "superAdmin",
    Page: "organizationOverview",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "superAdmin",
    Page: "organizationOverview",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "superAdmin",
    Page: "organizationOverview",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "superAdmin",
    Page: "installation",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "superAdmin",
    Page: "installation",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "superAdmin",
    Page: "installation",
    Question: "What duration is captured in the audit records?",
    Answer:
      "Audit records capture the last 30 days from the time of running the Export Audit Records command. For example, if an audit record was exported on June 30th it captures all audit records from May 31st to June 30th.",
  },
  {
    Role: "superAdmin",
    Page: "installation",
    Question: "How can I check the status of a borrowed license?",
    Answer:
      "You can check the status of a borrowed license by clicking on the License Status icon (key). This will open the License Status dialog, where you can see information about the number of licenses borrowed from your machine. When a borrowed license expires, the session is returned to the session pool on your machine.",
  },
  {
    Role: "superAdmin",
    Page: "installation",
    Question: "Is a license manager included in BRM?",
    Answer:
      " BRM manages the validity of licenses internally, so there is no need for an external license management server. However, after installing BRM, you need to send the HOSTID (found in the Summary table on the Installation page) to KDM Analytics to receive a valid license file.",
  },
  {
    Role: "superAdmin",
    Page: "installationOverview",
    Question: "How can I get more information on a specific area or section of a page or dialog window?",
    Answer:
      "Some areas or sections of a page have a lowercase i with a circle around it that when selected opens a tool-tip pop that provides specific information related to that section or area.",
  },
  {
    Role: "superAdmin",
    Page: "installationOverview",
    Question: "What information is in the Configuration tab?",
    Answer:
      "The Configuration tab displays the following project configurations that can be set at the installation level; Generate physical attacks - Determines whether physical attacks are part of the generated risk model (Yes) or not (No) when calculating risks; Vulnerability Computation Mode - Determines how vulnerability findings participate in risk calculation: when imported, vulnerability findings are associated with certain vulnerability conditions and change the corresponding vulnerability level. There are two modes: consider allocated controls and increase vulnerability level by one, if vulnerability findings are associated with a vulnerability condition (Default) or set vulnerability level to 'very high' and override any existing controls, if vulnerability findings are associated with a vulnerability condition (Aggressive); Non-Compliant Controls - Determines how 'compliant' column is processed during the import SCTM as allocated controls: if non-compliant security controls are added (Include) or skipped (Exclude); Baseline Mode - Determines if security controls baseline is a maximum set of controls (Ceiling), or a minimum set of controls (Floor), in which case the imported baseline is augmented with a more comprehensive set of controls.",
  },
  {
    Role: "superAdmin",
    Page: "installationOverview",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "superAdmin",
    Page: "installationOverview",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "superAdmin",
    Page: "installationOverview",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "superAdmin",
    Page: "users",
    Question: "Can a user be assigned multiple roles?",
    Answer:
      "Yes, a Super Admin or Admin can assign multiple roles to a user. The Super Admin role is the only role that can assign an Admin to an organization.",
  },
  {
    Role: "superAdmin",
    Page: "users",
    Question: "Can multiple users work on the same project?",
    Answer:
      "Yes, two or more users assigned the role of System Engineer, or Risk Analyst, or Threat Analyst can work on the same project so long as they are assigned to the project by a Super Admin or Admin..",
  },
  {
    Role: "superAdmin",
    Page: "users",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "superAdmin",
    Page: "users",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "superAdmin",
    Page: "users",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "superAdmin",
    Page: "users",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "superAdmin",
    Page: "users",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "superAdmin",
    Page: "userOverview",
    Question: "What does Last Active Role mean?",
    Answer: "Last Active Role is the last role that a user had selected prior to logging out of their BRM session.",
  },
  {
    Role: "superAdmin",
    Page: "userOverview",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "superAdmin",
    Page: "userOverview",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "superAdmin",
    Page: "userOverview",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "superAdmin",
    Page: "userOverview",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "superAdmin",
    Page: "userOverview",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "controlCoordinator",
    Page: "home",
    Question: "Why does the UI screen in the help video sometimes looks different?",
    Answer:
      "Some of the help videos may have been created using a different version but the video always captures the required set of UI elements to achieve the goal of the help video.",
  },
  {
    Role: "controlCoordinator",
    Page: "controlCatalogs",
    Question: "What is a Control Catalog?",
    Answer:
      "Control Catalogs are authored by an organization that defines or governs a set of controls, such as NIST with Special Publication (SP) 800-53 or Communications Security Establishment ITSG-33. In BRM a catalog may also be authored when organizations need to define a control not found in an existing control catalog.",
  },
  {
    Role: "controlCoordinator",
    Page: "controlCatalogs",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "controlCoordinator",
    Page: "controlCatalogs",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "controlCoordinator",
    Page: "controlCatalogsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "controlCoordinator",
    Page: "controlCatalogsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "controlCoordinator",
    Page: "controlFamily",
    Question: "What are Control Families?",
    Answer:
      "Control Families are classifications or groupings of controls as defined in a catalog such as NIST 800-53 Rev. 4 and NIST 800-53 Rev. 5.",
  },
  {
    Role: "controlCoordinator",
    Page: "controlFamily",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "controlCoordinator",
    Page: "controlFamily",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "controlCoordinator",
    Page: "controlFamilyFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "controlCoordinator",
    Page: "controlFamilyFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "controlCoordinator",
    Page: "controlType",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "controlCoordinator",
    Page: "controlType",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list alpha-numerically by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option.  Click 'x' to clear the text field.",
  },
  {
    Role: "controlCoordinator",
    Page: "standardBaselines",
    Question: "What is a Standard Baseline?",
    Answer:
      "A Standard Baseline is a group of controls, from a standard such as NIST 800-53, assembled to address the protection needs of a group, organization, or community of interest",
  },
  {
    Role: "controlCoordinator",
    Page: "standardBaselines",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "controlCoordinator",
    Page: "standardBaselines",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "controlCoordinator",
    Page: "standardBaselines",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "controlCoordinator",
    Page: "standardBaselineFullDetails",
    Question: "What does it mean to merge baselines?",
    Answer:
      "For any cloned or custom control catalog, you can add control types from another baseline.  Merging is basically adding new control types from another baseline into your chosen baseline, while leaving the other baseline alone.  Control types are not duplicated in the process.",
  },
  {
    Role: "controlCoordinator",
    Page: "standardBaselineFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "controlCoordinator",
    Page: "standardBaselineFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "controlCoordinator",
    Page: "projectCatalogConfig",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "controlCoordinator",
    Page: "projectCatalogConfig",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "controlCoordinator",
    Page: "controlTypeFullDetails",
    Question: "What does a control strength value set to '0' mean?",
    Answer: "A control with a control strength value set to '0' means that the control has no affect on the risk.",
  },
  {
    Role: "controlCoordinator",
    Page: "controlType",
    Question: "What does a control strength value set to '0' mean?",
    Answer: "A control with a control strength value set to '0' means that the control has no affect on the risk.",
  },
  {
    Role: "riskAnalyst",
    Page: "home",
    Question: "Why does the UI screen in the help video sometimes looks different?",
    Answer:
      "Some of the help videos may have been created using a different version but the video always captures the required set of UI elements to achieve the goal of the help video.",
  },
  {
    Role: "riskAnalyst",
    Page: "allocatedControls",
    Question: "What is an Allocated Control?",
    Answer:
      "Allocated Control is practices, procedures, or mechanisms that would deter the threat, reduce vulnerability, limit the impact of an undesired event, detect undesired events, and facilitate recovery. They are defined by standard control catalogs such as, NIST 800-53 Rev. 4 and NIST 800-53 Rev. 5.",
  },
  {
    Role: "riskAnalyst",
    Page: "allocatedControls",
    Question: "What does a control strength value set to '0' mean?",
    Answer: "A control with a control strength value set to '0' means that the control has no affect on the risk.",
  },
  {
    Role: "cybersecurityExpert",
    Page: "home",
    Question: "Why does the UI screen in the help video sometimes looks different?",
    Answer:
      "Some of the help videos may have been created using a different version but the video always captures the required set of UI elements to achieve the goal of the help video.",
  },
  {
    Role: "cybersecurityExpert",
    Page: "kbProjects",
    Question: "What is Vulnerability Computation?",
    Answer:
      "Vulnerability Computation Determines how vulnerability findings participate in risk calculation: when imported, vulnerability findings are associated with certain vulnerability conditions and change the corresponding vulnerability level.  There are two modes: consider allocated controls and increase vulnerability level by one, if vulnerability findings are associated with a vulnerability condition (Default) or set vulnerability level to 'very high' and override any existing controls, if vulnerability findings are associated with a vulnerability condition (Aggressive).",
  },
  {
    Role: "cybersecurityExpert",
    Page: "knowledgebases",
    Question: "What is the KDMA Knowledgebase?",
    Answer:
      "KDMA Knowledge Base (KB) is the large set of ontologies and rules guiding the construction of the risk model.",
  },
  {
    Role: "cybersecurityExpert",
    Page: "knowledgebases",
    Question: "When I generate a knowledgebase difference report what is Rfactor in the UE difference file?",
    Answer:
      "Some elements depend on other elements, like Capability and Mission depend on Data Types. We allow the score for the dependent elements to be less than any of the scores of the elements that they depend on (for example datatype). That's reflected in the Robustness factor or Rfactor which is 0 to 1 which is 0 percent to 100 percent.",
  },
  {
    Role: "cybersecurityExpert",
    Page: "knowledgebases",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "cybersecurityExpert",
    Page: "knowledgebases",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "cybersecurityExpert",
    Page: "knowledgebasesDetails",
    Question: "What is Means and Opportunity?",
    Answer:
      "Attacks are characterized by Means Level and Opportunity Level, which taken together, express the attack likelihood. Means is the range of capability that a vulnerability can be exploited, from M1 - Very Low to M5 - Very High. Opportunity is the range of opportunity an adversary has to initiate an attack to exploit a vulnerability from O1 - Possible to O5 - Confirmed.",
  },
  {
    Role: "cybersecurityExpert",
    Page: "knowledgebasesDetails",
    Question: "What is Severity and Criticality?",
    Answer:
      "Severity is the range of concern that an exploited vulnerability has on an undesired event from, S1 - Negligible to S5- Very High. Criticality is the range of degradation to a mission resulting from a loss of system, subsystem, function, or capability, from C1 - No Mission Degradation to C5 - Total Mission Failure.",
  },
  {
    Role: "cybersecurityExpert",
    Page: "knowledgebasesDetails",
    Question: "How does the Rfactor impact risk?",
    Answer:
      "The Rfactor (Robustness factor) is a coefficient ranging from 0 to 1 used to reduce the likelihood in tier2 and tier3 risks. Note that the Rfactor cannot be 0. When the Rfactor is 1, the likelihood is the unchanged high watermark likelihood from the attacks. When rfactor is less then 1, the likelihood is reduced to reflect the situation when a Tier2,3 risk is less than the high watermark likelihood of the attack on tier1 assets. Tier1 risks are directly to tier1 assets, such as datatypes (e.g. loss of Last Known Position information). Tier2 risks are related to capabilities. Capabilities depend on tier1 assets such as datatypes.  But if the architecture is more robust, failure of one dependent asset does not necessarily mean failure of the capability. So we can make the risk to capability less. Tier3 risks are to missions, which depends on capabilities, which in turn depends on tier1 assets. Failure of one capability does not necessarily mean failure of the mission because the capability may have redundancy built in.",
  },
  {
    Role: "cybersecurityExpert",
    Page: "knowledgebasesDetails",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "cybersecurityExpert",
    Page: "knowledgebasesDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "cybersecurityExpert",
    Page: "knowledgebasesDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "projects",
    Question: "Why is my project greyed out?",
    Answer:
      "Any project that is currently being edited by the project's System Engineer appears as grayed out. What this means for the Risk Analyst is that you can not perform any tasks on the project until the System Engineer has published their changes. Contact your System Engineer to request access to the project.",
  },
  {
    Role: "riskAnalyst",
    Page: "projects",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "projects",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "projectOverview",
    Question: "What is a System CWE Guidance Report?",
    Answer:
      "CWE System Guidance Report can be used to understand which CWE's exist in the system based on system vulnerabilities. The exported report can be used inform the Blade OneReport (BOR) team of the relevant CWE's to the system under analysis and they can therefore use source code analysis (SCA) tools that report on these CWE's when running BOR. The report can be exported as a *.csv or *.json file.",
  },
  {
    Role: "riskAnalyst",
    Page: "projectOverview",
    Question: "What is a CWE Report?",
    Answer:
      "Common Weakness Enumeration (CWE) report provides mapping information; CWE to vulnerability category to Software Fault Pattern (SFP). The report can be exported as a *.csv or *.json file.",
  },
  {
    Role: "riskAnalyst",
    Page: "projectOverview",
    Question: "What is an SFP?",
    Answer:
      "A Software Fault Pattern (SFP) is a set of vulnerability (or finding) attributes that provides a technical description of a pattern in software where weaknesses can exist, and are exploitable as vulnerabilities.",
  },
  {
    Role: "riskAnalyst",
    Page: "projectOverview",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "projectOverview",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "projectOverview",
    Question: "Publishing changes to System Model when user has both System Engineer and Risk Analyst role",
    Answer:
      "The System Model changes are automatically published when a user, with both roles, changes from System Engineer to Risk Analyst role.",
  },
  {
    Role: "riskAnalyst",
    Page: "systems",
    Question: "What is a System?",
    Answer:
      "System is a functionally, physically, and/or behaviorally related group of regularly interacting or interdependent elements.",
  },
  {
    Role: "riskAnalyst",
    Page: "systems",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "systemsDetails",
    Question: "What is the Fact Analysis Inventory Report?",
    Answer:
      "Fact Analysis Inventory Report provides a complete list and number of system facts (elements) related to the imported system model such as Nodes, Exchanges, Missions,",
  },
  {
    Role: "riskAnalyst",
    Page: "systemsDetails",
    Question: "What is the Fact Analysis Report?",
    Answer:
      "Fact Analysis report provides analysis feedback as to the consistency and suitability of imported model’s system facts for the purpose of risk assessment. Addressing the results will improve the model and accuracy of the risk profile.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemsDetails",
    Question: "What information is in the Configuration tab?",
    Answer:
      "The Configuration tab displays the following read-only project configuration settings; Generate Physical Attacks - Determines whether physical attacks are part of the generated risk model (Yes) or not (No) when calculating risks; Vulnerability Computation Mode - Determines how vulnerability findings participate in risk calculation: when imported, vulnerability findings are associated with certain vulnerability conditions and change the corresponding vulnerability level. There are two modes: consider allocated controls and increase vulnerability level by one, if vulnerability findings are associated with a vulnerability condition (Default) or set vulnerability level to 'very high' and override any existing controls, if vulnerability findings are associated with a vulnerability condition (Aggressive); Non-Compliant Controls - Determines how 'compliant' column is processed during the import SCTM as allocated controls: if non-compliant security controls are added (Include) or skipped (Exclude); Baseline Mode - Determines if security controls baseline is a maximum set of controls (Ceiling), or a minimum set of controls (Floor), in which case the imported baseline is augmented with a more comprehensive set of controls.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "systemsDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "accessPoints",
    Question: "What is an Access Point?",
    Answer:
      "Access Points (AP) are external points that might enable access to an internal entry point in a system to cause damage.",
  },
  {
    Role: "riskAnalyst",
    Page: "accessPoints",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "accessPoints",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "accessPoints",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "accessPointsDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "accessPointsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "accessPointsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "accessPointCategories",
    Question: "What are Access Point Categories?",
    Answer:
      "Access Point Categories are a collection of Access Points (AP) of similar category and they have their own category but are aligned with an Attack Surface (AS).",
  },
  {
    Role: "riskAnalyst",
    Page: "accessPointCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "boundariesActivities",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackSurfaces",
    Question: "What is an Attack Surface?",
    Answer:
      "An Attack Surface is systematic framework for identification of the source of the attack (for example, internal, external, maintenance, supply chain, ...). The Attack Surface (AS) has a collection of Entry Points (EP) of similar category and they have their own category but are aligned with an Attacker Category. Since Attack Vectors (AV) are linked to EPs the AV belongs to an AS. The more complex an AS is or the greater the number of AS the greater potential for attacks and risk.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackSurfaces",
    Question: "What is an Attack Vector?",
    Answer:
      "An Attack Vector (AV) is a method or pathway used by an attacker to access or penetrate the target system. An AV is created for all exchanges that are on the system boundary and are connected to activities that use this exchange. AVs only exist for external attack surfaces.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackSurfaces",
    Question: "How do I add an element?",
    Answer:
      "To add an element click the Add icon, located in the top right corner. A form opens in a dialog window. Complete the form, which may contain some required some optional fields. Click the Add button at the bottom of the form and the element has now been added.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackSurfaces",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackSurfaces",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackSurfaces",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackSurfaces",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "attackSurfacesDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackSurfacesDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackSurfacesDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "attackSurfaceCategories",
    Question: "What are Attack Surface Categories?",
    Answer:
      "Attack Surface Categories are a collection of Attack Surfaces (AS) of similar category and they have their own category but are aligned with an Access Point Category.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackSurfaceCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackVectors",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackVectors",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackVectors",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "attackVectorsDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackVectorsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackVectorsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "attackVectorsDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "attackVectorCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "entryPoints",
    Question: "What are Entry Points?",
    Answer:
      "Entry Points (EP) are internal points of failure that might be enabled by a corresponding Access Point (AP), through an exchange, to cause damage to the system. A single exchange can be on one or more EPs or APs.",
  },
  {
    Role: "riskAnalyst",
    Page: "entryPoints",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "entryPoints",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "entryPoints",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "entryPointsDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "entryPointsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "entryPointsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "entryPointsDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "entryPointCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "boundaries",
    Question: "What is a Boundary Node?",
    Answer:
      "Boundary Node is a node that is internal to the system and inside the boundary (scope of the risk analysis).",
  },
  {
    Role: "riskAnalyst",
    Page: "boundaries",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "categorizedEntities",
    Question: "How do I add an element?",
    Answer:
      "To add an element click the Add icon, located in the top right corner. A form opens in a dialog window. Complete the form, which may contain some required some optional fields. Click the Add button at the bottom of the form and the element has now been added.",
  },
  {
    Role: "riskAnalyst",
    Page: "categorizedEntities",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "riskAnalyst",
    Page: "categorizedEntities",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "categorizedEntities",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "categorizedEntities",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "categorizedEntities",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "categorizedEntitiesOverview",
    Question: "How do I add an element?",
    Answer:
      "To add an element click the Add icon, located in the top right corner. A form opens in a dialog window. Complete the form, which may contain some required some optional fields. Click the Add button at the bottom of the form and the element has now been added.",
  },
  {
    Role: "riskAnalyst",
    Page: "categorizedEntitiesOverview",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "riskAnalyst",
    Page: "categorizedEntitiesOverview",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "categorizedEntitiesOverview",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "categorizedEntitiesOverview",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "categorizedEntitiesOverview",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "classifiedCategories",
    Question: "What are Classification Categories?",
    Answer:
      "Classification Categories identify the sensitivity, For Official Use Only (FOUO), Secret (S), Top Secret (TS), and Unclassified (U), of the data related to a datatype.",
  },
  {
    Role: "riskAnalyst",
    Page: "classifiedCategories",
    Question:
      "I used Controlled Unclassified Information (CUI) classification in my AAR document, but why don't I see it in BRM?",
    Answer:
      "When you import your AAR document into a BRM project, any CUI classifications are automatically converted to For Official Use Only (FOUO). If you export the project back to an AAR document, all FOUO classifications will be converted to CUI.",
  },
  {
    Role: "riskAnalyst",
    Page: "classifiedCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "securityObjectives",
    Question: "What are Security Objectives?",
    Answer:
      "Security Objectives are the standards used to measure the impact (Very High, High, Moderate, Low, Very Low)as it relates to the Availability, Confidentiality, Integrity, Performance, Privacy, and Safety of a target, such as datatype or persons.",
  },
  {
    Role: "riskAnalyst",
    Page: "securityObjectives",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "securityObjectives",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "securityObjectivesOverview",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "securityObjectivesOverview",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "securityObjectivesOverview",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssets",
    Question: "What is a System Asset?",
    Answer:
      "System Assets (SA) are defined as assets that support activities. These are tangible things that are attacked and that fail such as hardware, software, networks, and people. Each node could have multiple SAs so each SA is uniquely owned by its node.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssets",
    Question: "How do I add an element?",
    Answer:
      "To add an element click the Add icon, located in the top right corner. A form opens in a dialog window. Complete the form, which may contain some required some optional fields. Click the Add button at the bottom of the form and the element has now been added.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssets",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssets",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssets",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssets",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssets",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetFullDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetTypes",
    Question: "What is a System Asset Type?",
    Answer:
      "System Asset Types (SAT) are one or more of system assets belonging to a specific system asset category. SAT can be linked to a CPE, so that CVEs can be associated with a node, and (potentially) used in risk calculation.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetTypes",
    Question: "How do I add an element?",
    Answer:
      "To add an element click the Add icon, located in the top right corner. A form opens in a dialog window. Complete the form, which may contain some required some optional fields. Click the Add button at the bottom of the form and the element has now been added.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetTypes",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetTypes",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetTypes",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetTypes",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetTypeFullDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetTypeFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetTypeFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "riskAnalyst",
    Page: "systemAssetTypeFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "nodes",
    Question: "What is a Node?",
    Answer:
      "Node is an entity that is human, automated, or any aggregation of human and/or automated that performs an activity and provides a capability. Nodes can be entry points of an attack surface and therefore susceptible to attacks.",
  },
  {
    Role: "riskAnalyst",
    Page: "nodes",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "nodes",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "nodes",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "nodeFullDetails",
    Question:
      "Why are the number of listed Attack Surfaces for a node different then the number displayed in the Context Diagram?",
    Answer:
      "Only Attack Surfaces with viable attacks are listed against each node, while the Context Diagram displays all Attack Surfaces including viable and non-viable attacks.",
  },
  {
    Role: "riskAnalyst",
    Page: "nodeFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "nodeFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "nodeFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "nodeFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "exchanges",
    Question: "What is an Exchange?",
    Answer:
      "Exchange is the act of exchanging information between two distinct nodes and the characteristics of the act, including the information element that needs to be exchanged and the characteristics of the exchange.",
  },
  {
    Role: "riskAnalyst",
    Page: "exchanges",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "exchanges",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "exchanges",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "exchangeFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "exchangeFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "exchangeFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "exchangeFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "datatypes",
    Question: "What is a Datatype?",
    Answer:
      "Datatype is a particular kind of data item, as defined by the values it can take, or the operations that can be performed on it. All exchanges require a datatype and a datatype can be related to one or more exchanges.",
  },
  {
    Role: "riskAnalyst",
    Page: "datatypes",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "datatypes",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "datatypes",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "datatypeFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "datatypeFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "datatypeFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "datatypeFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "activities",
    Question: "What are Activities?",
    Answer:
      "Activities are actions performed in conducting the business of an enterprise. It is a general term that does not imply a placement in a hierarchy (e.g., it could be a process, or a task as defined in other documents, and it could be at any level of the hierarchy of the Operational Activity Model). It is used to portray operational actions not hardware/software system functions. One or more activities can be related to a capability and nodes implement capabilities.",
  },
  {
    Role: "riskAnalyst",
    Page: "activities",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "activities",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "activities",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "activityFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "activityFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "activityFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "activityFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "dataflows",
    Question: "What is a Dataflow?",
    Answer:
      "Data Flow is an ordered set of operational exchanges with start node(s), intermediate node(s) and end node(s) and captures a flow of data related to activities or systems.",
  },
  {
    Role: "riskAnalyst",
    Page: "dataflows",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "dataflows",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "dataflows",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "dataflowFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "dataflowFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "dataflowFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "dataflowFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "persons",
    Question: "What is a Person?",
    Answer:
      "Person is defined by the role or roles that is relevant to an architecture from a risk analysis perspective.",
  },
  {
    Role: "riskAnalyst",
    Page: "persons",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "persons",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "persons",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "personFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "personFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "personFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "personFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "resources",
    Question: "What is a resource?",
    Answer:
      "Resource represents physical things like money, fuel or even intangible things like morale. Resource is placed in one or more nodes, and can be attacked.",
  },
  {
    Role: "riskAnalyst",
    Page: "resources",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "resources",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "resources",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "resourceFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "resourceFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "resourceFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "resourceFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "capabilities",
    Question: "What are Capabilities",
    Answer: "Capabilities are one or more sequences of activities provided by a node.",
  },
  {
    Role: "riskAnalyst",
    Page: "capabilities",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "capabilities",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "capabilities",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "capabilityFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "capabilityFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "capabilityFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "capabilityFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "missions",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "missions",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "missions",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "missionFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "missionFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "missionFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "missionFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "elementCategories",
    Question: "What are Element Categories?",
    Answer:
      "Element Categories are a collection of elements of similar category. An element can only belong to one category.",
  },
  {
    Role: "riskAnalyst",
    Page: "elementCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "structuralCategories",
    Question: "What are Structural Categories?",
    Answer:
      "Structural Categories identify an element's type based the structure of the system or objects related to the system.",
  },
  {
    Role: "riskAnalyst",
    Page: "structuralCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "assets",
    Question: "What is an Asset?",
    Answer:
      "Asset is a resource of a system either having tangible value such as hardware or people or an intangible value such as process or information.",
  },
  {
    Role: "riskAnalyst",
    Page: "assets",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "assets",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "assets",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "assetFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "assetFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "assetFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "assetFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "assetCategories",
    Question: "What are Asset Categories?",
    Answer:
      "Asset Categories are groups of assets with similar characteristics. The Asset Categories create a hierarchy of assets.",
  },
  {
    Role: "riskAnalyst",
    Page: "assetCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "undesiredEvents",
    Question: "What is an Undesired Event?",
    Answer:
      "Undesired events are the elements of the risk analysis framework that focus on the systematic identification of threats based on their outcome and impact.",
  },
  {
    Role: "riskAnalyst",
    Page: "undesiredEvents",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "undesiredEvents",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "undesiredEvents",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "undesiredEventFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "undesiredEventFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "undesiredEventFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "undesiredEventFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "harms",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "risks",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "risks",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "risks",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "risks",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "risks",
    Question: "What is Likelihood Level?",
    Answer:
      "Likelihood is the range of probability that one or more threat events will occur - Improbable, Remote, Occasional, Probable, and Near Certainty.",
  },
  {
    Role: "riskAnalyst",
    Page: "risks",
    Question: "What is Impact Level?",
    Answer:
      "Impact Level is a range of a undesired event from a risk analysis perspective – Negligible, Minor, Moderate, Major, and Catastrophic.",
  },
  {
    Role: "riskAnalyst",
    Page: "risks",
    Question: "What is Risk Level?",
    Answer:
      "Risk Level is the range of risk values assigned to a risk based on the impact and likelihood of a set of attacks and their related undesired event.",
  },
  {
    Role: "riskAnalyst",
    Page: "risks",
    Question: "What is the Risk Matrix?",
    Answer:
      "The Risk matrix shows the distribution of risks, based on a calculated risk likelihood and impact level and the related risk level. Each cell in the Risk Matrix has a number. The number relates to the number of risks found for that specific risk level. Risk levels are color code - Very Low (green), Low (light green), Moderate (Yellow), High (Orange), and Very High (Red).",
  },
  {
    Role: "riskAnalyst",
    Page: "risks",
    Question: "How is risk rank computed?",
    Answer:
      "Risk rank considers first the Risk Level, then the mitigated score. Risk level is a value between 1 and 25, corresponding to the cells in the 5x5 risk matrix. This is a finer ordering than the 5 levels of risk (Very High to High to Very Low). All cells in the 5x5 matrix are ordered. Any Very High cells (red color) is considered higher than any of the High cells (orange color). However, the Very High cells are ordered between themselves, so the L5-I5 cell is higher than the L4-I5 cell. So, any risk with L5-I5 will be ranked higher than any risk with L4-I5. Risks with the same level are further ordered by the mitigated risk score. Risk with a lower score can be ranked higher, because level is calculated as 'high water mark' and score is calculated as average. So, a risk can be well-mitigated except for one attack, and this will make the mitigated score low, but the one unmitigated attack will keep the risk level high (until it is mitigated).",
  },
  {
    Role: "riskAnalyst",
    Page: "riskFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "riskFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "riskFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "riskFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "riskFullDetails",
    Question: "What is Risk Level?",
    Answer:
      "Risk Level is the range of risk values assigned to a risk based on the impact and likelihood of a set of attacks and their related undesired event.",
  },
  {
    Role: "riskAnalyst",
    Page: "riskFullDetails",
    Question: "What is the difference between Auto and User-Guided Mitigation?",
    Answer:
      "Auto mitigation automatically applies controls from the Control Baseline to mitigate vulnerabilities for all high water mark attacks. With User-Guided mitigation, you are in control as to what vulnerabilities you want to mitigate in order to achieve the risk profile.",
  },
  {
    Role: "riskAnalyst",
    Page: "riskFullDetails",
    Question: "What is an Attack?",
    Answer:
      "Attacks is a detailed chronological and functional description of an actual or hypothetical threat intended to facilitate risk analysis by creating a confirmed relationship between an asset of value and a threat agent being motivated toward that asset and having the capability to exploit a vulnerability found in the same asset. For example, it could be a local or remote availability attack on system software.",
  },
  {
    Role: "riskAnalyst",
    Page: "riskFullDetails",
    Question: "What is a Threat Event?",
    Answer: "Threat Event is an event that has the capacity for causing undesirable consequences or impact.",
  },
  {
    Role: "riskAnalyst",
    Page: "riskFullDetails",
    Question: "What does vulnerability sorted by rank mean?",
    Answer:
      "The vulnerability rank is the global rank of the Vulnerability Conditions within this variant of the project, based on importance to the risk score for the contributors affected by this risk. This rank is also displayed in the User-Guided and Auto Vulnerability Mitigation dialog windows.",
  },
  {
    Role: "riskAnalyst",
    Page: "riskFullDetails",
    Question: "Why is there no vulnerability rank displayed in my RAR?",
    Answer:
      "The vulnerability rank is not displayed because the risk needs to be updated for any variant you want to display Vulnerability Ranking values. Click the Calculate Risks button to update the risk and display the vulnerability rank. This includes any existing projects not created in 5.3.2.",
  },
  {
    Role: "riskAnalyst",
    Page: "riskFullDetails",
    Question: "How do I further mitigate my risk that shows a threat and no additional countermeasures in my RAR?",
    Answer:
      "This indicates that no further countermeasures are available in the current baseline to mitigate this risk. To address this, you need to use the 'User-Guided Mitigation' to select applicable countermeasures from the Catalog.",
  },
  {
    Role: "riskAnalyst",
    Page: "riskFullDetails",
    Question: "How can I get more information on a specific area or section of a page or dialog window?",
    Answer:
      "Some areas or sections of a page have a lowercase i with a circle around it that when selected opens a tool-tip pop that provides specific information related to that section or area.",
  },
  {
    Role: "riskAnalyst",
    Page: "riskCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "threatEvents",
    Question: "What is a Threat Event?",
    Answer: "Threat Event is an event that has the capacity for causing undesirable consequences or impact.",
  },
  {
    Role: "riskAnalyst",
    Page: "threatEvents",
    Question: "What is Likelihood Level?",
    Answer:
      "Likelihood is the range of probability that one or more threat events will occur - Improbable, Remote, Occasional, Probable, and Near Certainty.",
  },
  {
    Role: "riskAnalyst",
    Page: "threatEvents",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "threatEvents",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "threatEvents",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "threatEventFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "threatEventFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "threatEventFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "threatEventFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "threatEventCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "threatEventPatterns",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attacks",
    Question: "What is an Attack?",
    Answer:
      "Attacks is a detailed chronological and functional description of an actual or hypothetical threat intended to facilitate risk analysis by creating a confirmed relationship between an asset of value and a threat agent being motivated toward that asset and having the capability to exploit a vulnerability found in the same asset. For example, it could be a local or remote availability attack on system software.",
  },
  {
    Role: "riskAnalyst",
    Page: "attacks",
    Question: "What is an Attack Mechanism?",
    Answer:
      "Attack Mechanism is a system or strategy by which an asset is attacked. Common Attack Pattern Enumeration and Classification (CAPEC) attack patterns are based on mechanisms that are employed when exploiting a vulnerability.",
  },
  {
    Role: "riskAnalyst",
    Page: "attacks",
    Question: "What is an Attack Tactic?",
    Answer:
      "Attack Tactic is a categorization of injury describing a specific kind of compromise to assets as an outcome of an attack, especially resulting from deliberate attackers but also covering accidental attacks and natural hazards.",
  },
  {
    Role: "riskAnalyst",
    Page: "attacks",
    Question: "What is an Attack Kinds?",
    Answer:
      "Attack Kind is a systematic framework for identification of the general means of an attack such as Cyber, EMF, Physical, social, and so on.",
  },
  {
    Role: "riskAnalyst",
    Page: "attacks",
    Question: "What is Likelihood Level?",
    Answer:
      "Likelihood is the range of probability that one or more threat events will occur - Improbable, Remote, Occasional, Probable, and Near Certainty.",
  },
  {
    Role: "riskAnalyst",
    Page: "attacks",
    Question: "What is a Threat Event?",
    Answer: "Threat Event is an event that has the capacity for causing undesirable consequences or impact.",
  },
  {
    Role: "riskAnalyst",
    Page: "attacks",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attacks",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "attacks",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "attackFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "attackKinds",
    Question: "What is an Attack Kinds?",
    Answer:
      "Attack Kind is a systematic framework for identification of the general means of an attack such as Cyber, EMF, Physical, social, and so on.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackKinds",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackMechanisms",
    Question: "What is an Attack Mechanism?",
    Answer:
      "Attack Mechanism is a system or strategy by which an asset is attacked. Common Attack Pattern Enumeration and Classification (CAPEC) attack patterns are based on mechanisms that are employed when exploiting a vulnerability.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackMechanisms",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackTactics",
    Question: "What is an Attack Tactic?",
    Answer:
      "Attack Tactic is a categorization of injury describing a specific kind of compromise to assets as an outcome of an attack, especially resulting from deliberate attackers but also covering accidental attacks and natural hazards.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackTactics",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "vulnerabilities",
    Question: "What is a Vulnerability Condition?",
    Answer: "A Vulnerability Condition allows exploiting this system in unauthorised and possibly even malicious ways.",
  },
  {
    Role: "riskAnalyst",
    Page: "vulnerabilities",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "vulnerabilities",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "vulnerabilities",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "vulnerabilityFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "vulnerabilityFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "vulnerabilityFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "vulnerabilityFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "vulnerabilityCategories",
    Question: "What is a Vulnerability Category?",
    Answer:
      "A Vulnerability Category is a classification associated with a Vulnerability Condition that more precisely indicates the affect of the vulnerability. The category name can be captured as an abbreviation. For example, “iaaa” is “Inadequate audit and accountability”.",
  },
  {
    Role: "riskAnalyst",
    Page: "vulnerabilityCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "softwareVulnerabilities",
    Question: "What are Vulnerability Findings?",
    Answer:
      "Vulnerability finding is a normalized software vulnerability finding, generated by Blade OneReport (BOR), related to a project node. Imported Vulnerability Findings are used in the calculation of residual risk.",
  },
  {
    Role: "riskAnalyst",
    Page: "softwareVulnerabilities",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackers",
    Question: "What is an Attacker?",
    Answer:
      "Attacker is the point of origin of an attack, where an attack is an indication that an unwanted incident may result in harm to a system or organization and its assets. For example, an attack could be a supplier acting without malice (bug) or malicious supplier (backdoor, time bomb).",
  },
  {
    Role: "riskAnalyst",
    Page: "attackers",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackers",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackers",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackersDetails",
    Question: "What is Means and Opportunity?",
    Answer:
      "Attacks are characterized by Means Level and Opportunity Level, which taken together, express the attack likelihood. Means is the range of capability that a vulnerability can be exploited, from M1 - Very Low to M5 - Very High. Opportunity is the range of opportunity an adversary has to initiate an attack to exploit a vulnerability from O1 - Possible to O5 - Confirmed.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackersDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackersDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackersDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "attackersDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "attackerCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "allocatedControls",
    Question: "What is Compliance Risk?",
    Answer:
      "Compliant risk is the risk after one or more security controls used to mitigate the vulnerabilities of a risk is set to being compliant with a security control listed in a catalog such as NIST 800-53.",
  },
  {
    Role: "riskAnalyst",
    Page: "allocatedControls",
    Question: "What is Mitigated Risk?",
    Answer:
      "Mitigated risk is the risk after one or more security controls are used to mitigate the vulnerabilities of a risk.",
  },
  {
    Role: "riskAnalyst",
    Page: "allocatedControls",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "allocatedControls",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "allocatedControlsDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "allocatedControlsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "allocatedControlsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "allocatedControlsDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "allocatedControlsDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "riskAnalyst",
    Page: "sctmsDetails",
    Question: "What is a Security Control Traceability Matrix (SCTM)?",
    Answer:
      "Security Control Traceability Matrix (SCTM) is a matrix of control types (as rows) with targets or system nodes and exchanges (as columns); control types usually correspond to some standard baseline, but may miss some control types from standard baseline and/or include ones that are not in standard baseline.",
  },
  {
    Role: "riskAnalyst",
    Page: "sctmsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "sctmsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "sctmsDetails",
    Question: "Where can I find sample files?",
    Answer:
      "Click the 'Download Samples' link on any of the Quick Start pages. The downloaded zip file, once extracted, contains a Samples folder. This folder contains several subfolders that contain sample files for example, the AAR, CSV, DoDAF, and SysML folders all contain system model files that can be imported by System Engineers. The SRTM folder has control files that can be imported by Risk Analysts. There are other folders that contain release related documents such as Release Notes.",
  },
  {
    Role: "riskAnalyst",
    Page: "sctmsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "sctmsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "sctmsDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "variants",
    Question: "What is a Variant?",
    Answer:
      "Variant is a policy, practice, control (including a safeguard) or process that would lessen the impact of the threat event.",
  },
  {
    Role: "riskAnalyst",
    Page: "variants",
    Question: "What is Compliance Risk?",
    Answer:
      "Compliance risk is the risk after one or more security controls used to mitigate the vulnerabilities of a risk is set to being compliant with a security control listed in a catalog such as NIST 800-53.",
  },
  {
    Role: "riskAnalyst",
    Page: "variants",
    Question: "What is Mitigated Risk?",
    Answer:
      "Mitigated risk is the risk after one or more security controls are used to mitigate the vulnerabilities of a risk.",
  },
  {
    Role: "riskAnalyst",
    Page: "variants",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "variants",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "variantsDetails",
    Question: "Can I change the control catalog for a variant?",
    Answer:
      "You can only change the control catalog for the variant if there are no controls currently assigned to the variant. You can still change the baseline if need be.",
  },
  {
    Role: "riskAnalyst",
    Page: "variantsDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "riskAnalyst",
    Page: "variantsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "variantsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "riskAnalyst",
    Page: "variantsDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "riskAnalyst",
    Page: "variantsDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "systems",
    Question: "What is a System?",
    Answer:
      "System is a functionally, physically, and/or behaviorally related group of regularly interacting or interdependent elements.",
  },
  {
    Role: "systemsEngineer",
    Page: "systems",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "systemsEngineer",
    Page: "systems",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "systems",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "systems",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemsDetails",
    Question: "What is the Fact Analysis Inventory Report?",
    Answer:
      "Fact Analysis Inventory Report provides a complete list and number of system facts (elements) related to the imported system model such as Nodes, Exchanges, Missions.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemsDetails",
    Question: "What is the Fact Analysis  Report?",
    Answer:
      "Fact Analysis report provides analysis feedback as to the consistency and suitability of imported model’s system facts for the purpose of risk assessment. Addressing the results will improve the model and accuracy of the risk profile.",
  },
  {
    Role: "systemsEngineer",
    Page: "accessPoints",
    Question: "What is an Access Point?",
    Answer:
      "Access Points (AP) are external points that might enable access to an internal entry point in a system to cause damage.",
  },
  {
    Role: "systemsEngineer",
    Page: "accessPoints",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "impactCalibration",
    Question: "What is Impact?",
    Answer: "Impact is the range of concern that an exploited vulnerability has on an undesired event.",
  },
  {
    Role: "riskAnalyst",
    Page: "impactCalibration",
    Question: "What is an Impact Level?",
    Answer:
      "Impact Level is a range of a threat event from a risk analysis perspective – Negligible, Minor, Moderate, Major, and Catastrophic.",
  },
  {
    Role: "riskAnalyst",
    Page: "impactCalibration",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "impactCalibration",
    Question: "How does calibration impact my risk?",
    Answer:
      "Generated risks are based on common impact, and various attacks that can cause this impact. The mechanics of constructing the risk model, setting the likelihoods and impacts and calculating risks is completely automated. Therefore, changing the impact of the corresponding undesired event or changing the likelihood of one or more attacks adjusts the generated risk. After making the adjustments you will need to run 'Calculate Risks' to recalculate risk based on the adjustments that were made.",
  },
  {
    Role: "riskAnalyst",
    Page: "impactCalibration",
    Question: "How do I incrementally increase or decrease the impact level?",
    Answer:
      "To incrementally increase the impact level, select one or more undesired events from the list table and click the 'Increase Level' button. To incrementally decrease the impact level, select one or more undesired events from the list table and click the 'Decrease Level' button.",
  },
  {
    Role: "riskAnalyst",
    Page: "impactCalibration",
    Question: "How do I clear calibration(s)?",
    Answer:
      "To clear the calibrated impact, select one or more undesired events from the list table and click the 'Clear Calibration' button.",
  },
  {
    Role: "riskAnalyst",
    Page: "likelihoodCalibration",
    Question: "What is Likelihood Level?",
    Answer:
      "Likelihood is the range of probability that one or more threat events will occur - Improbable, Remote, Occasional, Probable, and Near Certainty.",
  },
  {
    Role: "riskAnalyst",
    Page: "likelihoodCalibration",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "likelihoodCalibration",
    Question: "How does calibration impact my risk?",
    Answer:
      "Generated risks are based on common impact, and various attacks that can cause this impact. The mechanics of constructing the risk model, setting the likelihoods and impacts and calculating risks is completely automated. Therefore, changing the impact of the corresponding undesired event or changing the likelihood of one or more attacks adjusts the generated risk. After making the adjustments you will need to run 'Calculate Risks' to recalculate risk based on the adjustments that were made.",
  },
  {
    Role: "riskAnalyst",
    Page: "likelihoodCalibration",
    Question: "How do I incrementally increase or decrease the likelihood level?",
    Answer:
      "To incrementally increase the likelihood level, select one or more attacks from the list table and click the 'Increase Level' button. To incrementally decrease the likelihood level, select one or more attacks from the list table and click the 'Decrease Level' button.",
  },
  {
    Role: "riskAnalyst",
    Page: "likelihoodCalibration",
    Question: "How do I clear calibration(s)?",
    Answer:
      "To clear the calibrated likelihood, select one or more attacks from the list table and click the 'Clear Calibration' button.",
  },
  {
    Role: "riskAnalyst",
    Page: "reports",
    Question: "What is the Fact Analysis Inventory Report?",
    Answer:
      "Fact Analysis Inventory Report provides a complete list and number of system facts (elements) related to the imported system model such as Nodes, Exchanges, Missions,",
  },
  {
    Role: "riskAnalyst",
    Page: "reports",
    Question: "What is the Fact Analysis Report?",
    Answer:
      "Fact Analysis report provides analysis feedback as to the consistency and suitability of imported model’s system facts for the purpose of risk assessment. Addressing the results will improve the model and accuracy of the risk profile.",
  },
  {
    Role: "riskAnalyst",
    Page: "reports",
    Question: "What is a System CWE Guidance Report?",
    Answer:
      "CWE System Guidance Report can be used to understand which CWE's exist in the system based on system vulnerabilities. The exported report can be used to inform the Blade OneReport (BOR) team of the relevant CWE's to the system under analysis and they can therefore use source code analysis (SCA) tools that report on these CWE's when running BOR.",
  },
  {
    Role: "riskAnalyst",
    Page: "reports",
    Question: "What is a CWE Report?",
    Answer:
      "Common Weakness Enumeration (CWE) report provides mapping information; CWE to vulnerability category to Software Fault Pattern (SFP).",
  },
  {
    Role: "riskAnalyst",
    Page: "reports",
    Question: "What is a Threat Risk Analysis Report?",
    Answer:
      "Threat Risk Analysis (TRA) Report describes the results of performing a security assessment on a system. The purpose of the assessment is to identify the operational risks to the system (in particular, those resulting from cyber-attacks), the corresponding vulnerabilities, assess the criticality of the components and recommend mitigations.",
  },
  {
    Role: "riskAnalyst",
    Page: "reports",
    Question: "What is a Risk Assessment Report?",
    Answer:
      "Risk Assessment Report (RAR) provides information regarding a specific risk instance, including suggested countermeasures (mitigation) needed for a low residual risk, and the related attack tree. Generating the RAR report for risk instances is helpful in determining which mitigation controls should be implemented to effectively reduce residual risk level.",
  },
  {
    Role: "riskAnalyst",
    Page: "reports",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "riskAnalyst",
    Page: "reports",
    Question: "Why does N/A appear in the Actions column of the reports table?",
    Answer:
      "When N/A appears in the Actions column of the reports table, it indicates that no actions can be taken on the report; however, by clicking on the name of the report, you can navigate to the location of the report within BRM.",
  },
  {
    Role: "riskAnalyst",
    Page: "contextDiagram",
    Question: "Why is there no image displayed in my Context Diagram?",
    Answer:
      "There is no image displayed in the Context Diagram because an image has not been uploaded. Only System Engineers can upload images.",
  },
  {
    Role: "riskAnalyst",
    Page: "attackGraph",
    Question: "What is an Attack Path?",
    Answer:
      "Attack Path is the identification of one or more vulnerabilities that can be exploited by attackers to gain access to specific assets and move between them in a system, thus, forming an exploitable path between the assets. The Attack Graph can be filtered to focus on specific attach paths within the system.",
  },
  {
    Role: "riskAnalyst",
    Page: "metadata",
    Question: "What is Metadata?",
    Answer: "Metadata is a set of data that describes and gives information about a project.",
  },
  {
    Role: "systemsEngineer",
    Page: "home",
    Question: "Why does the UI screen in the help video sometimes looks different?",
    Answer:
      "Some of the help videos may have been created using a different version but the video always captures the required set of UI elements to achieve the goal of the help video.",
  },
  {
    Role: "systemsEngineer",
    Page: "reports",
    Question: "What is the Fact Analysis Inventory Report?",
    Answer:
      "Fact Analysis Inventory Report provides a complete list and number of system facts (elements) related to the imported system model such as Nodes, Exchanges, Missions,",
  },
  {
    Role: "systemsEngineer",
    Page: "reports",
    Question: "What is the Fact Analysis Report?",
    Answer:
      "Fact Analysis report provides analysis feedback as to the consistency and suitability of imported model’s system facts for the purpose of risk assessment. Addressing the results will improve the model and accuracy of the risk profile.",
  },
  {
    Role: "systemsEngineer",
    Page: "reports",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "reports",
    Question: "Why does N/A appear in the Actions column of the reports table?",
    Answer:
      "When N/A appears in the Actions column of the reports table, it indicates that no actions can be taken on the report; however, by clicking on the name of the report, you can navigate to the location of the report within BRM.",
  },
  {
    Role: "systemsEngineer",
    Page: "projects",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "projects",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node. ",
  },
  {
    Role: "systemsEngineer",
    Page: "projectOverview",
    Question: "How do I address validation errors and warnings after importing an AAR doc?",
    Answer:
      " BRM runs validation of the AAR document and provides feedback in the form of an HTML page that contains errors and warnings. The areas of concern are highlighted and the Validation Errors(x) Warnings(x) column provides a description of the error or warning such as any missing tables or data, integrity checks, syntax errors, etc. Reported warnings do not have to be addressed and you can continue with your risk assessment however if any errors are reported BRM stops the importing process, and you must address the errors in the AAR source document followed by a re-import of the document for validation. ",
  },
  {
    Role: "systemsEngineer",
    Page: "projectOverview",
    Question: "Where can I find sample files?",
    Answer:
      "Click the 'Download Samples' link on any of the Quick Start pages. The downloaded zip file, once extracted, contains a Samples folder. This folder contains several subfolders that contain sample files for example, the AAR, CSV, DoDAF, and SysML folders all contain system model files that can be imported by System Engineers. The SRTM folder has control files that can be imported by Risk Analysts. There are other folders that contain release related documents such as Release Notes.",
  },
  {
    Role: "systemsEngineer",
    Page: "projectOverview",
    Question: "Can BRM handle multiple tables being imported from one or more AAR documents?",
    Answer:
      "Yes BRM can handle multiple tables. For example, if AAR document  ‘A' had an EIE table and AAR document 'B’ had an EIE upon importing those two AAR documents the EIE tables would be merged into one.",
  },
  {
    Role: "systemsEngineer",
    Page: "projectOverview",
    Question: "What are the requirements of a DoDAF or SysML model needed to fully take advantage of BRM capabilities?",
    Answer:
      "BRM takes full advantage of whatever is in your model, be it UAF, UPDM, SysML. The only requirement for BRM is that a model contains at least one node. BRM consumes all views (whatever is available in the model), but from that point on, it works with the model and not views. What this means, for example, is BRM looks at node by node, identifies its owners, connectors, associations, activities … it identifies all activities related to the node, hidden exchanges (that come from activities), it does sequencing between activities to discover dataflows. In other words BRM focuses on the entire system model available to it (does not matter how little is defined) to understand how a system can be attacked (cause and effect story).",
  },
  {
    Role: "systemsEngineer",
    Page: "projectOverview",
    Question: "Can I import an exported AAR document?",
    Answer:
      "Yes. Keep in mind that if the origin of the exported AAR document is not a model generated from an AAR document (such as SysML, UAF, UPDM), upon import of this document, AAR modelling rules will be applied that may result in import errors, or system data differences resulting in a different risk profile.",
  },
  {
    Role: "systemsEngineer",
    Page: "projectOverview",
    Question: "How many AAR documents can I import at one time on a single project?",
    Answer:
      "You can import up to 12 AAR documents at one time on a single project.  They must all be imported at the same time.",
  },
  {
    Role: "systemsEngineer",
    Page: "projectOverview",
    Question: "Is there a file size limit for files I upload?",
    Answer:
      "Yes. The maximum combined file size that can be uploaded is 500MB. If you try anything larger, it is immediately blocked when you press 'Upload', with the error message - 'ERROR: Uploaded file is too large. Try again with a file smaller than 500MB'.",
  },
  {
    Role: "systemsEngineer",
    Page: "projectOverview",
    Question: "Is there a timeout for uploading a file?",
    Answer:
      "Yes. The maximum allowable time is ten minutes. When you press the 'Upload' button, a file that fails to upload after 10 minutes fails with the message - 'ERROR: Timeout of 600s exceeded'.",
  },
  {
    Role: "systemsEngineer",
    Page: "accessPoints",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "accessPoints",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "accessPointsDetails",
    Question: "How do I add an element?",
    Answer:
      "To add an element click the Add icon, located in the top right corner. A form opens in a dialog window. Complete the form, which may contain some required some optional fields. Click the Add button at the bottom of the form and the element has now been added.",
  },
  {
    Role: "systemsEngineer",
    Page: "accessPointsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "accessPointsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "accessPointsDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "accessPointCategories",
    Question: "What are Access Point Categories?",
    Answer:
      "Access Point Categories are a collection of Access Points (AP) of similar category and they have their own category but are aligned with an Attack Surface (AS).",
  },
  {
    Role: "systemsEngineer",
    Page: "accessPointCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "boundariesActivities",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "boundariesActivities",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackSurfaces",
    Question: "What is an Attack Surface?",
    Answer:
      "An Attack Surface is systematic framework for identification of the source of the attack (for example, internal, external, maintenance, supply chain, ...). The Attack Surface (AS) has a collection of Entry Points (EP) of similar category and they have their own category but are aligned with an Attacker Category. Since Attack Vectors (AV) are linked to EPs the AV belongs to an AS. The more complex an AS is or the greater the number of AS the greater potential for attacks and risk.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackSurfaces",
    Question: "What is an Attack Vector?",
    Answer:
      "An Attack Vector (AV) is a method or pathway used by an attacker to access or penetrate the target system. An AV is created for all exchanges that are on the system boundary and are connected to activities that use this exchange. AVs only exist for external attack surfaces.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackSurfaces",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackSurfaces",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackSurfaces",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackSurfaces",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackSurfacesDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackSurfacesDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackSurfacesDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "attackSurfaceCategories",
    Question: "What are Attack Surface Categories?",
    Answer:
      "Attack Surface Categories are a collection of Attack Surfaces (AS) of similar category and they have their own category but are aligned with an Access Point Category.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackSurfaceCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackVectors",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackVectors",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackVectors",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackVectorsDetails",
    Question: "How do I add an element?",
    Answer:
      "To add an element click the Add icon, located in the top right corner. A form opens in a dialog window. Complete the form, which may contain some required some optional fields. Click the Add button at the bottom of the form and the element has now been added.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackVectorsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackVectorsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "attackVectorsDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "attackVectorCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "entryPoints",
    Question: "What are Entry Points?",
    Answer:
      "Entry Points (EP) are internal points of failure that might be enabled by a corresponding Access Point (AP), through an exchange, to cause damage to the system. A single exchange can be on one or more EPs or APs.",
  },
  {
    Role: "systemsEngineer",
    Page: "entryPoints",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "entryPoints",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "entryPoints",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "entryPointsDetails",
    Question: "How do I add an element?",
    Answer:
      "To add an element click the Add icon, located in the top right corner. A form opens in a dialog window. Complete the form, which may contain some required some optional fields. Click the Add button at the bottom of the form and the element has now been added.",
  },
  {
    Role: "systemsEngineer",
    Page: "entryPointsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "entryPointsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "entryPointsDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "entryPointCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "boundaries",
    Question: "What is a Boundary Node?",
    Answer:
      "Boundary Node is a node that is internal to the system and inside the boundary (scope of the risk analysis).",
  },
  {
    Role: "systemsEngineer",
    Page: "boundaries",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "boundaries",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "classifiedCategories",
    Question: "What are Classification Categories?",
    Answer:
      "Classification Categories identify the sensitivity, For Official Use Only (FOUO), Secret (S), Top Secret (TS), and Unclassified (U), of the data related to a datatype.",
  },
  {
    Role: "systemsEngineer",
    Page: "classifiedCategories",
    Question:
      "I used Controlled Unclassified Information (CUI) classification in my AAR document, but why don't I see it in BRM?",
    Answer:
      "When you import your AAR document into a BRM project, any CUI classifications are automatically converted to For Official Use Only (FOUO). If you export the project back to an AAR document, all FOUO classifications will be converted to CUI.",
  },
  {
    Role: "systemsEngineer",
    Page: "classifiedCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "categorizedEntities",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on,  to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "categorizedEntities",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "categorizedEntities",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "categorizedEntities",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "categorizedEntitiesOverview",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "categorizedEntitiesOverview",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "categorizedEntitiesOverview",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "categorizedEntitiesOverview",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "securityObjectives",
    Question: "What are Security Objectives?",
    Answer:
      "Security Objectives are the standards used to measure the impact (Very High, High, Moderate, Low, Very Low)as it relates to the Availability, Confidentiality, Integrity, Performance, Privacy, and Safety of a target, such as datatype or persons.",
  },
  {
    Role: "systemsEngineer",
    Page: "securityObjectives",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "securityObjectives",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "securityObjectives",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "securityObjectivesOverview",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "securityObjectivesOverview",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssets",
    Question: "What is a System Asset?",
    Answer:
      "System Assets (SA) are defined as assets that support activities. These are tangible things that are attacked and that fail such as hardware, software, networks, and people. Each node could have multiple SAs so each SA is uniquely owned by its node.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssets",
    Question: "How do I add an element?",
    Answer:
      "To add an element click the Add icon, located in the top right corner. A form opens in a dialog window. Complete the form, which may contain some required some optional fields. Click the Add button at the bottom of the form and the element has now been added.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssets",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssets",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssets",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssets",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssets",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetTypes",
    Question: "Is there a way to distinguish the types of OS deployed?",
    Answer:
      "BRM distinguishes between nodes and system assets. For example a node could be, a mission computer LRU. Each node has multiple system assets, such as hardware (can be more than one unit), software (we distinguish Custom SW and Operating System, can be multiple units), UI, etc. BRM also distinguishes between a System Asset (SA) and a System Asset Type (SAT).  Each SA is uniquely owned by its node and each SA has a SAT.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetTypes",
    Question: "What is a System Asset Type?",
    Answer:
      "System Asset Types (SAT) are one or more of system assets belonging to a specific system asset category. SAT can be linked to a CPE, so that CVEs can be associated with a node, and (potentially) used in risk calculation.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetTypes",
    Question: "How do I add an element?",
    Answer:
      "To add an element click the Add icon, located in the top right corner. A form opens in a dialog window. Complete the form, which may contain some required some optional fields. Click the Add button at the bottom of the form and the element has now been added.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetTypes",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetTypes",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetTypes",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetTypes",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetTypeFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetTypeFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemAssetTypeFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "nodes",
    Question: "What is a Node?",
    Answer:
      "Node is an entity that is human, automated, or any aggregation of human and/or automated that performs an activity and provides a capability. Nodes can be entry points of an attack surface and therefore susceptible to attacks. ",
  },
  {
    Role: "systemsEngineer",
    Page: "nodes",
    Question: "What is Boundary?",
    Answer:
      "Defines the scope of the risk analysis as it pertains to a node. A node can be directly 'inside' or within the scope of risk analysis. A node can also be 'outside' or not within the scope of risk analysis but can contribute via operational exchanges between it and the 'inside' nodes.",
  },
  {
    Role: "systemsEngineer",
    Page: "nodes",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "nodes",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "systemsEngineer",
    Page: "nodes",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "nodes",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "nodes",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "nodeFullDetails",
    Question:
      "Why are the number of listed Attack Surfaces for a node different then the number displayed in the Context Diagram?",
    Answer:
      "Only Attack Surfaces with viable attacks are listed against each node, while the Context Diagram displays all Attack Surfaces including viable and non-viable attacks.",
  },
  {
    Role: "systemsEngineer",
    Page: "nodeFullDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "systemsEngineer",
    Page: "nodeFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "nodeFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "nodeFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "nodeFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "exchanges",
    Question: "What is an Exchange?",
    Answer:
      "Exchange is the act of exchanging information between two distinct nodes and the characteristics of the act, including the information element that needs to be exchanged and the characteristics of the exchange.",
  },
  {
    Role: "systemsEngineer",
    Page: "exchanges",
    Question: "What is Boundary?",
    Answer:
      "Defines the scope of the risk analysis as it pertains to a node. A node can be directly 'inside' or within the scope of risk analysis. A node can also be 'outside' or not within the scope of risk analysis but can contribute via operational exchanges between it and the 'inside' nodes.",
  },
  {
    Role: "systemsEngineer",
    Page: "exchanges",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "exchanges",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "systemsEngineer",
    Page: "exchanges",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "exchanges",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "exchanges",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "exchangeFullDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "systemsEngineer",
    Page: "exchangeFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "exchangeFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "exchangeFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "exchangeFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "datatypes",
    Question: "What is a Datatype?",
    Answer:
      "Datatype is a particular kind of data item, as defined by the values it can take, or the operations that can be performed on it. All exchanges require a datatype and a datatype can be related to one or more exchanges.",
  },
  {
    Role: "systemsEngineer",
    Page: "datatypes",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "datatypes",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "systemsEngineer",
    Page: "datatypes",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "datatypes",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "datatypes",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "datatypeFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "datatypeFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "datatypeFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "datatypeFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "activities",
    Question: "What are Activities?",
    Answer:
      "Activities are actions performed in conducting the business of an enterprise. It is a general term that does not imply a placement in a hierarchy (e.g., it could be a process, or a task as defined in other documents, and it could be at any level of the hierarchy of the Operational Activity Model). It is used to portray operational actions not hardware/software system functions. One or more activities can be related to a capability and nodes implement capabilities.",
  },
  {
    Role: "systemsEngineer",
    Page: "activities",
    Question: "What is Boundary?",
    Answer:
      "Defines the scope of the risk analysis as it pertains to a node. A node can be directly 'inside' or within the scope of risk analysis. A node can also be 'outside' or not within the scope of risk analysis but can contribute via operational exchanges between it and the 'inside' nodes.",
  },
  {
    Role: "systemsEngineer",
    Page: "activities",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "activities",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "systemsEngineer",
    Page: "activities",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "activities",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "activities",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "activityFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "activityFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "activityFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "activityFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "dataflows",
    Question: "What is a Dataflow?",
    Answer:
      "Data Flow is an ordered set of operational exchanges with start node(s), intermediate node(s) and end node(s) and captures a flow of data related to activities or systems.",
  },
  {
    Role: "systemsEngineer",
    Page: "dataflows",
    Question: "What is Boundary?",
    Answer:
      "Defines the scope of the risk analysis as it pertains to a node. A node can be directly 'inside' or within the scope of risk analysis. A node can also be 'outside' or not within the scope of risk analysis but can contribute via operational exchanges between it and the 'inside' nodes.",
  },
  {
    Role: "systemsEngineer",
    Page: "dataflows",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "dataflows",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "systemsEngineer",
    Page: "dataflows",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "dataflows",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "dataflows",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "dataflowFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "dataflowFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "dataflowFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "dataflowFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "persons",
    Question: "What is a Person?",
    Answer:
      "Person is defined by the role or roles that is relevant to an architecture from a risk analysis perspective.",
  },
  {
    Role: "systemsEngineer",
    Page: "persons",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "persons",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "systemsEngineer",
    Page: "persons",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "persons",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "persons",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "personFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "personFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "personFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "personFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "resources",
    Question: "What is a resource?",
    Answer:
      "Resource represents physical things like money, fuel or even intangible things like morale. Resource is placed in one or more nodes, and can be attacked.",
  },
  {
    Role: "systemsEngineer",
    Page: "resources",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "resources",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "systemsEngineer",
    Page: "resources",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "resources",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "resources",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "resourceFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "resourceFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "resourceFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "resourceFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "capabilities",
    Question: "What are Capabilities?",
    Answer: "Capabilities are one or more sequences of activities provided by a node.",
  },
  {
    Role: "systemsEngineer",
    Page: "capabilities",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "capabilities",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "systemsEngineer",
    Page: "capabilities",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "capabilities",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "capabilities",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "capabilityFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "capabilityFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "capabilityFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "capabilityFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "missions",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "systemsEngineer",
    Page: "missions",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "systemsEngineer",
    Page: "missions",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "missions",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "systemsEngineer",
    Page: "missions",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "missionFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "missionFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "systemsEngineer",
    Page: "missionFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "systemsEngineer",
    Page: "missionFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "systemsEngineer",
    Page: "elementCategories",
    Question: "What are Element Categories?",
    Answer:
      "Element Categories are a collection of elements of similar category. An element can only belong to one category.",
  },
  {
    Role: "systemsEngineer",
    Page: "elementCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "structuralCategories",
    Question: "What are Structural Categories?",
    Answer:
      "Structural Categories identify an element's type based the structure of the system or objects related to the system.",
  },
  {
    Role: "systemsEngineer",
    Page: "structuralCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemDiagram",
    Question: "How come I don't see my newly added node in the System Diagram?",
    Answer:
      "A newly added node is not connected, so it is considered an 'orphan'. Select the Show/Hide icon in the toolbar to toggle orphans. A node added to the System Diagram has no connections to other nodes. These 'orphan' nodes by default are hidden. Clicking the Hide Orphan button in the toolbar toggles to 'show' or 'hide' them from being displayed in the diagram.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemDiagram",
    Question: "How do I add an icon to the System Diagram when the Inside Boundary appears small?",
    Answer: "You can click on any edge and drag and release to make the Inside Boundary larger.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemDiagram",
    Question:
      "How do I move an node in the System Diagram inside or outside of a container (Component, Subsystem, Segment)?",
    Answer:
      "Click on the node you want to move to select it. Press the 'Shift' key, drag the node inside or outside the container, and then release.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemDiagram",
    Question: "Why are the nodes in my System Diagram layout overlapping?",
    Answer:
      "This can happen if you have an empty System Diagram open and then import a system model file. Clicking on any of the layout buttons in the toolbar will correctly layout the System Diagram.",
  },
  {
    Role: "systemsEngineer",
    Page: "systemDiagram",
    Question: "Why is the risk rank different in the System Diagram than in the UI tables?",
    Answer:
      "The risk rank in the Properties panel for a Node or Exchange is the rank relative to all targets; Nodes and Exchanges. Whereas in the UI tables the rank is relative to the target type; so just Nodes or Exchanges.",
  },
  {
    Role: "systemsEngineer",
    Page: "contextDiagram",
    Question: "Why is there no image displayed in my Context Diagram?",
    Answer: "There is no image displayed in the Context Diagram because an image has not been uploaded.",
  },
  {
    Role: "systemsEngineer",
    Page: "metadata",
    Question: "What is Metadata?",
    Answer: "Metadata is a set of data that describes and gives information about a project.",
  },
  {
    Role: "threatAnalyst",
    Page: "resources",
    Question: "What is a resource?",
    Answer:
      "Resource represents physical things like money, fuel or even intangible things like morale. Resource is placed in one or more nodes, and can be attacked.",
  },
  {
    Role: "threatAnalyst",
    Page: "systems",
    Question: "What is a System?",
    Answer:
      "System is a functionally, physically, and/or behaviorally related group of regularly interacting or interdependent elements.",
  },
  {
    Role: "threatAnalyst",
    Page: "systems",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "systems",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "threatAnalyst",
    Page: "systems",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatCatalogs",
    Question: "What file type can I import into a Threat Catalog?",
    Answer:
      "You can import a *.csv but it must be in the same format as presented in an threat catalog that was exported as a csv file. URL field and note fields can be empty. You can also import a *.json file but the file must comply with the schema defined for a threat catalog such as the MITRE ATT&CK v9.0 threat catalog",
  },
  {
    Role: "threatAnalyst",
    Page: "threatCatalogs",
    Question: "What is a Threat Catalog?",
    Answer:
      "Threat Catalog is a list of threats that are regarded as common information security threats. An example of a Threat Catalog is MITRE ATT&CK.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatCatalogs",
    Question: "What is a Threat Analyst?",
    Answer:
      "Threat Analyst is responsible for managing the Threat Model and managing global threats mappings (Threat Catalogs) to projects. Threat analyst should be selecting threats based on a general description of the system, its environment and its mission.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatCatalogs",
    Question: "What is ATT&CK?",
    Answer:
      "Adversarial Tactics, Techniques & Common Knowledge (ATT&CK) is a globally accessible knowledge base of adversary tactics and techniques based on real-world observations. The ATT&CK knowledge base is used as a foundation for the development of specific threat models and methodologies in the private sector, in government, and in the cybersecurity product and service community.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatCatalogs",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatCatalogs",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatCatalogs",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatCatalogs",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatCatalogsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatCatalogsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatGroups",
    Question: "How do I delete an element?",
    Answer:
      "Each item listed in the table has a checkbox that allows you to select a specific item, such as a user, project, role, and so on, to delete. There is also a checkbox in the table header that allows you to select all items in the list for deletion. Click in the checkbox to the items you want to delete and then click the Delete icon which looks like a trash can. The item is now deleted from the list.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatGroups",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatGroups",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatGroups",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatGroupDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatGroupDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "threatGroupDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "threatAnalyst",
    Page: "threatGroupDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "threatAnalyst",
    Page: "threatTiers",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "accessPoints",
    Question: "What is an Access Point?",
    Answer:
      "Access Points (AP) are external points that might enable access to an internal entry point in a system to cause damage.",
  },
  {
    Role: "threatAnalyst",
    Page: "accessPoints",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "accessPoints",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "threatAnalyst",
    Page: "accessPoints",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "accessPointsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "accessPointsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "accessPointsDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "threatAnalyst",
    Page: "accessPointCategories",
    Question: "What are Access Point Categories?",
    Answer:
      "Access Point Categories are a collection of Access Points (AP) of similar category and they have their own category but are aligned with an Attack Surface (AS).",
  },
  {
    Role: "threatAnalyst",
    Page: "accessPointCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "boundariesActivities",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "boundariesActivities",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "threatAnalyst",
    Page: "attackSurfaces",
    Question: "What is an Attack Surface?",
    Answer:
      "An Attack Surface is systematic framework for identification of the source of the attack (for example, internal, external, maintenance, supply chain, ...). The Attack Surface (AS) has a collection of Entry Points (EP) of similar category and they have their own category but are aligned with an Attacker Category. Since Attack Vectors (AV) are linked to EPs the AV belongs to an AS. The more complex an AS is or the greater the number of AS the greater potential for attacks and risk.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackSurfaces",
    Question: "What is an Attack Vector?",
    Answer:
      "An Attack Vector (AV) is a method or pathway used by an attacker to access or penetrate the target system. An AV is created for all exchanges that are on the system boundary and are connected to activities that use this exchange. AVs only exist for external attack surfaces.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackSurfaces",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackSurfaces",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackSurfaces",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackSurfacesDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackSurfacesDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackSurfacesDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "threatAnalyst",
    Page: "attackSurfaceCategories",
    Question: "What are Attack Surface Categories?",
    Answer:
      "Attack Surface Categories are a collection of Attack Surfaces (AS) of similar category and they have their own category but are aligned with an Access Point Category.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackSurfaceCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackVectors",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackVectors",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackVectors",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackVectorsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackVectorsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackVectorsDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "threatAnalyst",
    Page: "attackVectorCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "entryPoints",
    Question: "What are Entry Points?",
    Answer:
      "Entry Points (EP) are internal points of failure that might be enabled by a corresponding Access Point (AP), through an exchange, to cause damage to the system. A single exchange can be on one or more EPs or APs.",
  },
  {
    Role: "threatAnalyst",
    Page: "entryPoints",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "entryPoints",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "threatAnalyst",
    Page: "entryPoints",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "entryPointsDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "entryPointsDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "entryPointsDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "threatAnalyst",
    Page: "entryPointCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "boundaries",
    Question: "What is a Boundary Node?",
    Answer:
      "Boundary Node is a node that is internal to the system and inside the boundary (scope of the risk analysis).",
  },
  {
    Role: "threatAnalyst",
    Page: "boundaries",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "boundaries",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssets",
    Question: "What is a System Asset?",
    Answer:
      "System Assets (SA) are defined as assets that support activities. These are tangible things that are attacked and that fail such as hardware, software, networks, and people. Each node could have multiple SAs so each SA is uniquely owned by its node.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssets",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssets",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssets",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssets",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetFullDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetTypes",
    Question: "What is a System Asset Type?",
    Answer:
      "System Asset Types (SAT) are one or more of system assets belonging to a specific system asset category. SAT can be linked to a CPE, so that CVEs can be associated with a node, and (potentially) used in risk calculation.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetTypes",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetTypes",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetTypes",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetTypeFullDetails",
    Question: "How do I edit an element?",
    Answer:
      "When you see the edit icon, which looks like a pencil, in the Actions column of a table this means that one or more columns can be edited. Columns become editable either by selecting options from a pull down or typing characters in a text field. Once you have made your changes click the Accept icon, which looks like a check mark, located in the Actions column. At anytime you can cancel the edits by clicking the Cancel icon, which looks like a red 'X'.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetTypeFullDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetTypeFullDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetTypeFullDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "threatAnalyst",
    Page: "systemAssetTypeFullDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "threatAnalyst",
    Page: "attackers",
    Question: "What is an Attacker?",
    Answer:
      "Attacker is the point of origin of an attack, where an attack is an indication that an unwanted incident may result in harm to a system or organization and its assets. For example, an attack could be a supplier acting without malice (bug) or malicious supplier (backdoor, time bomb).",
  },
  {
    Role: "threatAnalyst",
    Page: "attackers",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackers",
    Question: "How do I view element notes?",
    Answer:
      "Click on the row of an element listed in a table to select it then click the Notes icon in the right sidebar panel. The Notes panel slides open to display a header with the name of the element and any existing notes related to the element. To add more notes, click under the header and start typing on the text box. When you are done click off the text box.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackers",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackersDetails",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackersDetails",
    Question: "How do I view element details?",
    Answer:
      "Each element that appears as a hyperlink (blue) allows you to navigate to a page that provides more details about that element. For example, if you have the Nodes page open, there is a table listing the nodes relate to the current project, if you were to click on a node a new page opens providing more information about that node.",
  },
  {
    Role: "threatAnalyst",
    Page: "attackersDetails",
    Question: "How do I export a list report?",
    Answer:
      "To export a list report click the Export icon located at the top right of the table. A dialog appears in which the export format (default CSV) and range can be selected. Click the Export button in the dialog and the list report is saved in your downloads directory",
  },
  {
    Role: "threatAnalyst",
    Page: "attackersDetails",
    Question: "What does No data found... mean?",
    Answer:
      "When No data found... appears in a list table, it indicates that currently there is no related data in this element list table for the selected element instance. It is possible that data becomes available upon future actions (e.g., adding data manually or via import).",
  },
  {
    Role: "threatAnalyst",
    Page: "attackerCategories",
    Question: "How do I scope a list?",
    Answer:
      "A table list can be scoped by applying a sort and/or filter by column headings. Click on any of the column headings to sort the list by category, level or alpha-numerically ascending and descending by column. To filter the list: 1) click in any column Search... text field and type one or more characters, and/or 2) click the dropdown selection menu in the column heading and choose an option. Click 'x' to clear the text field.",
  },
  {
    Role: "threatAnalyst",
    Page: "home",
    Question: "Why does the UI screen in the help video sometimes looks different?",
    Answer:
      "Some of the help videos may have been created using a different version but the video always captures the required set of UI elements to achieve the goal of the help video.",
  },
  {
    Role: "threatAnalyst",
    Page: "metadata",
    Question: "What is Metadata?",
    Answer: "Metadata is a set of data that describes and gives information about a project.",
  },
];
