import { useQuery } from "@tanstack/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "../access/access-query-key-factory";
import { DetailTableType } from "../../../types/DetailTableType";
import { IElementProps, IElementMap } from "./types/elementInterface";
import { responseAsArray } from "./utils/responseAsArray";

/**
 * custom-hook to get all entrypoints of an element
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {object} config - configuration for the react-query
 */

export const getElementEntryPoints = async (elementId: string, elementType: string) => {
  const entryPointsMap: IElementMap = {
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityAllEntrypoint(id),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeAllEntrypoint(id),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeAllEntrypoint(id),
    [DetailTableType.ATTACK_SURFACES.key]: (id) => Brm.attackSurfaceApi.getAttackSurfaceAllEntrypoint(id),
    [DetailTableType.ATTACKERS.key]: (id) => Brm.attackerApi.getAttackerAllEntrypoint(id),
    [DetailTableType.ATTACK_VECTORS.key]: (id) => Brm.attackVectorApi.getAttackVectorAllEntrypoint(id),
    [DetailTableType.ACCESS_POINTS.key]: (id) => Brm.accessPointApi.getAccessPointAllEntrypoint(id),
  };

  return responseAsArray({ map: entryPointsMap, elementId, elementType });
};

export const useElementEntryPoints = ({ elementId, elementName, projectId, options = {} }: IElementProps) => {
  const elementEntryPointsQuery = useQuery({
    ...options,
    queryKey: KeyFactory.entryPointKeys.details(elementId, elementName, projectId),
    queryFn: () => getElementEntryPoints(elementId, elementName),
  });

  return elementEntryPointsQuery;
};
