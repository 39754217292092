import { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
// State
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
// Constants
import { TYPE } from "constants/brm";
// Services
import { SystemApi, useRoles } from "features/brm";
import * as BrmGql from "generated/graphql";
// Local
import { AccordionStyles } from "features/diagram-common";
import { LoadingSpinner } from "components/elements";
import * as AgQuery from "../../api";
import AddNodeGroup from "./AddNodeGroup";
import * as S from "./AddNodeSection.styles";
// Helpers
import getSvg from "./SvgExports/svg-exporter";

const toNonZeroRisks = (data) => {
  return AgQuery.getDataWithNonZeroRisks(data);
};

const AddNodeSection = ({ section }) => {
  const { isRiskAnalyst } = useRoles();
  // Svgs
  const assetCategorySvg = useRef(getSvg(TYPE.assetCat, null));
  const assetSvg = useRef(getSvg(TYPE.asset, null));
  const nodeSvg = useRef(getSvg(TYPE.node, null));
  const exchangeSvg = useRef(getSvg(TYPE.exchange, null));
  const riskSvg = useRef(getSvg(TYPE.risk, null));
  const attackSvg = useRef(getSvg(TYPE.attack, null));
  const trevSvg = useRef(getSvg(TYPE.trev, null));
  const ueSvg = useRef(getSvg(TYPE.ue, null));
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  // Queries
  const {
    data: assetCategories,
    isLoading: isLoadingAssetCategories,
    refetch: fetchAssetCategories,
  } = BrmGql.useAssetCatsQuery(
    { project: projectId, variant: variantId },
    { enabled: false, refetchOnWindowFocus: false }
  );
  const {
    data: nodes,
    isLoading: isLoadingNodes,
    refetch: fetchNodes,
  } = SystemApi.useNodesWithRisk({
    projectId,
    variantId,
    options: {
      select: toNonZeroRisks,
      enabled: isRiskAnalyst,
    },
  });
  const {
    data: exchanges,
    isLoading: isLoadingExchanges,
    refetch: fetchExchanges,
  } = SystemApi.useExchangesWithRisk({
    projectId,
    variantId,
    options: {
      select: toNonZeroRisks,
      enabled: isRiskAnalyst,
    },
  });
  const {
    data: risksByCategory,
    isLoading: isLoadingRisks,
    refetch: fetchRisksByCategory,
  } = AgQuery.useRisksByCategory(projectId, variantId, {
    enabled: false,
  });
  const {
    data: attacksByTactic,
    isLoading: isLoadingAttacks,
    refetch: fetchAttacksByTactic,
  } = AgQuery.useAttacksByTactic(projectId, variantId, {
    enabled: false,
  });
  const {
    data: threatEventsByCategory,
    isLoading: isLoadingThreatEvents,
    refetch: fetchThreatEventByCategory,
  } = AgQuery.useThreatEventsByCategory(projectId, variantId, {
    enabled: false,
  });
  const {
    data: undesiredEventsByCategory,
    isLoading: isLoadingUndesiredEvents,
    refetch: fetchUndesiredEventByCategory,
  } = AgQuery.useUndesiredEventsByCategory(projectId, variantId, {
    enabled: false,
  });
  const {
    data: assetsByCategory,
    isLoading: isLoadingAssets,
    refetch: fetchAssetByCategory,
  } = AgQuery.useAssetsByCategory(projectId, variantId, {
    enabled: false,
  });

  // const loader = <Loader type="ThreeDots" color="#005ca6" height={20} width={20} />;
  let content = null;
  const [assetCategoriestoDisplay, setAssetCategoriestoDisplay] = useState(null);

  useEffect(() => {
    if (assetCategories) {
      const assetCatsWithRisk = assetCategories.assetcats.map((assetCat) => {
        const { rank } = assetCat.riskDistribution;
        const updatedAssetCat = { id: assetCat.name, name: assetCat.name };
        updatedAssetCat.rank = { value: rank };

        return updatedAssetCat;
      });

      setAssetCategoriestoDisplay(assetCatsWithRisk);
    }
  }, [assetCategories]);

  useEffect(() => {
    // Fetch risks by default to support having risk section open
    if (!risksByCategory) {
      fetchRisksByCategory();
    }
  }, [risksByCategory, fetchRisksByCategory]);

  function sectionOnClickHandler(id) {
    if (id === TYPE.assetCat && !assetCategories) {
      fetchAssetCategories();
    }
    if (id === TYPE.asset && !assetsByCategory) {
      fetchAssetByCategory();
    }
    if (id === TYPE.ue && !undesiredEventsByCategory) {
      fetchUndesiredEventByCategory();
    }
    if (id === TYPE.trev && !threatEventsByCategory) {
      fetchThreatEventByCategory();
    }
    if (id === TYPE.attack && !attacksByTactic) {
      fetchAttacksByTactic();
    }
    if (id === TYPE.risk && !risksByCategory) {
      fetchRisksByCategory();
    }
    if (id === "Targets" && (!nodes || !exchanges)) {
      fetchNodes();
      fetchExchanges();
    }
  }

  function getContent(body, svg) {
    const x = body.map((item) => {
      let instanceType = section.name;
      let svgString = svg;
      if (section.name === "Targets") {
        instanceType = item.title;
        svgString = item.svg;
      }
      if (item.title === "Disclosure") {
        svgString = getSvg(TYPE.risk, "Disclosure");
      }
      return <AddNodeGroup key={item.title} data={item} type={instanceType} svg={svgString} />;
    });
    return x;
  }

  switch (section.name) {
    case TYPE.assetCat: {
      if (isLoadingAssetCategories) {
        content = <LoadingSpinner message="Loading Asset Categories..." />;
      }
      if (assetCategoriestoDisplay) {
        const riskAssetCategories = assetCategoriestoDisplay.filter((assetCat) => assetCat.rank.value > 0);
        const body = riskAssetCategories.map((assetCat) => ({ title: assetCat.name, instances: [assetCat] }));
        content = getContent(body, assetCategorySvg.current);
      }
      break;
    }
    case TYPE.asset: {
      if (isLoadingAssets) {
        content = <LoadingSpinner message="Loading Assets..." />;
      }
      if (assetsByCategory) {
        content = getContent(assetsByCategory, assetSvg.current);
      }
      break;
    }
    case TYPE.ue: {
      if (isLoadingUndesiredEvents) {
        content = <LoadingSpinner message="Loading Undesired Events..." />;
      }
      if (undesiredEventsByCategory) {
        content = getContent(undesiredEventsByCategory, ueSvg.current);
      }
      break;
    }
    case TYPE.trev: {
      if (isLoadingThreatEvents) {
        content = <LoadingSpinner message="Loading Threat Events..." />;
      }
      if (threatEventsByCategory) {
        content = getContent(threatEventsByCategory, trevSvg.current);
      }
      break;
    }
    case TYPE.attack: {
      if (isLoadingAttacks) {
        content = <LoadingSpinner message="Loading Attacks..." />;
      }

      if (attacksByTactic) {
        content = getContent(attacksByTactic, attackSvg.current);
      }
      break;
    }
    case TYPE.risk: {
      if (isLoadingRisks) {
        content = <LoadingSpinner message="Loading Risks..." />;
      }
      if (risksByCategory) {
        content = getContent(risksByCategory, riskSvg.current);
      }
      break;
    }
    case "Targets": {
      if (isLoadingNodes || isLoadingExchanges) {
        content = <LoadingSpinner message="Loading Targets..." />;
      }
      if (nodes && exchanges) {
        const body = [
          { id: "Nodes", title: TYPE.node, instances: nodes, svg: nodeSvg.current },
          { id: "Exchanges", title: TYPE.exchange, instances: exchanges, svg: exchangeSvg.current },
        ];
        content = getContent(body, null);
      }
      break;
    }
    default:
      break;
  }

  return (
    <S.Container>
      <Accordion key={section.name} defaultActiveKey={section.name === TYPE.risk ? section.name : ""}>
        <Accordion.Item eventKey={section.name}>
          <AccordionStyles.Header onClick={() => sectionOnClickHandler(section.name)}>
            {section.name}
          </AccordionStyles.Header>
          {/* <Accordion.Button
              id={section.name}
              key={section.name}
              as={Card.Header}
              eventKey={section.name}
              onClick={(e) => sectionOnClickHandler(e)}
              style={{ cursor: "pointer" }}
            >
              <svg id="svgelem" width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                <rect x="3" y="3" rx="3" ry="3" width="12" height="12" style={{ fill: `${section.color}` }} />
              </svg> */}

          {/* </Accordion.Button> */}

          {/* <Accordion.Collapse key={`${section.name}_content`} eventKey={section.name}> */}
          <AccordionStyles.SkinnyBody>{content}</AccordionStyles.SkinnyBody>
          {/* </Accordion.Collapse> */}
        </Accordion.Item>
      </Accordion>
    </S.Container>
  );
};

AddNodeSection.propTypes = {
  section: PropTypes.object,
};

export default AddNodeSection;
