import { COMMON } from "constants/brm";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { riskApi } from "services/brm";
// import * as KeyFactory from "./rar-query-key-factory";
import { ReportsApi } from "features/brm"; // When this file gets moved to src\features\brm\api\reports\rar replace ReportsApi by KeyFactory
import { getErrorMessage } from "utils/error-message-utils";

const getRarData = async ({ riskId, sysElId, variantId }) => {
  const opts = {
    component: sysElId,
    variant: variantId,
  };
  // console.log("about to query rar....");
  try {
    const data = await riskApi.getRiskRar(riskId, opts);
    if (data?.result) {
      const rarData = JSON.parse(data.result);
      if (Array.isArray(rarData.risks) && rarData.risks.length) {
        return rarData.risks[0];
      }
      throw Error("RAR data contained no risks");
    }
  } catch (err) {
    throw Error(getErrorMessage(err));
  }
  return null;
};

export const useRarData = ({ riskId, sysElId = "", variantId = "", config = {} }) => {
  return useQuery({
    ...config,
    queryKey: ReportsApi.rarKeys.rar(riskId, sysElId, variantId),
    queryFn: () => getRarData({ riskId, sysElId, variantId }),
  });
};

export const useGetSysObjects = (riskId) => {
  const sysElQuery = useQuery(["getRiskSysObjects", riskId], () => riskApi.getRiskAllObject(riskId), {
    enabled: !!riskId,
    cacheTime: 0,
  });

  return {
    ...sysElQuery,
    data: useMemo(() => sysElQuery.data?.concat([{ name: "ALL", id: COMMON.nullUuid }]), [sysElQuery]),
  };
};
