import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { dataTypeApi } from "services/brm/system-service";
import handleApi from "services/brm/api-util";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import ButtonCell from "components/EditComponents/ButtonCell";
import { ElementFullDetails, SystemApi } from "features/brm";
import { TYPE } from "constants/brm";

import {
  createColumnBooleanMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";
import { RoutePath } from "routes/route-paths";
import DatatypeDetails from "../details/DatatypeDetails";

function DatatypeFullDetails() {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();

  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);

  const [selectedElement, setSelectedElement] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedCategory = useRef("");
  const updatedIsInternal = useRef("");
  const updatedIsInput = useRef("");
  const updatedIsOutput = useRef("");
  const updatedIsStored = useRef("");
  const updatedIsProcessed = useRef("");

  const queryClient = useQueryClient();
  const { data: elementCats } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: datatype } = SystemApi.useDataType({
    dataTypeId: id,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedRowId}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedIsInternal.current !== "") {
              await handleApi(
                dataTypeApi.setDataTypeIsInternalWithHttpInfo(selectedRowId, {
                  body: updatedIsInternal.current,
                })
              );
              updatedIsInternal.current = "";
            }

            if (updatedIsInput.current !== "") {
              await handleApi(
                dataTypeApi.setDataTypeIsInputWithHttpInfo(selectedRowId, {
                  body: updatedIsInput.current,
                })
              );
              updatedIsInput.current = "";
            }

            if (updatedIsOutput.current !== "") {
              await handleApi(
                dataTypeApi.setDataTypeIsOutputWithHttpInfo(selectedRowId, {
                  body: updatedIsOutput.current,
                })
              );
              updatedIsOutput.current = "";
            }

            if (updatedIsStored.current !== "") {
              await handleApi(
                dataTypeApi.setDataTypeIsStoredWithHttpInfo(selectedRowId, {
                  body: updatedIsStored.current,
                })
              );
              updatedIsStored.current = "";
            }

            if (updatedIsProcessed.current !== "") {
              await handleApi(
                dataTypeApi.setDataTypeIsProcessedWithHttpInfo(selectedRowId, {
                  body: updatedIsProcessed.current,
                })
              );
              updatedIsProcessed.current = "";
            }

            if (updatedCategory.current !== "") {
              await handleApi(
                dataTypeApi.setDataTypeCategoryWithHttpInfo(selectedRowId, {
                  body: updatedCategory.current,
                })
              );
              updatedCategory.current = "";
            }
            queryClient.invalidateQueries(SystemApi.dataTypeKeys.project(projectId));
            queryClient.invalidateQueries(SystemApi.dataTypeKeys.dataType(selectedRowId));
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [selectedRowId, editMode, queryClient, projectId]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnCategoryMapped("category", selectedRowId, elementCats, editMode, updatedCategory),
        createColumnBooleanMapped("isInternal", selectedRowId, editMode, updatedIsInternal),
        createColumnBooleanMapped("isInput", selectedRowId, editMode, updatedIsInput),
        createColumnBooleanMapped("isOutput", selectedRowId, editMode, updatedIsOutput),
        createColumnBooleanMapped("isStored", selectedRowId, editMode, updatedIsStored),
        createColumnBooleanMapped("isProcessed", selectedRowId, editMode, updatedIsProcessed),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }

    return [
      createColumnCategoryMapped("category", selectedRowId, elementCats, false, null),
      createColumnBooleanMapped("isInternal", selectedRowId, false, null),
      createColumnBooleanMapped("isInput", selectedRowId, false, null),
      createColumnBooleanMapped("isOutput", selectedRowId, false, null),
      createColumnBooleanMapped("isStored", selectedRowId, false, null),
      createColumnBooleanMapped("isProcessed", selectedRowId, false, null),
    ];
  }, [isSystemEngineer, selectedRowId, elementCats, editMode, createButton]);

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(RoutePath.Datatypes);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    if (datatype) {
      setSelectedElement(datatype);
    }
  }, [datatype]);

  if (elementCats && datatype) {
    const detailsComp = <DatatypeDetails selectedElement={datatype} />;

    return (
      <ElementFullDetails
        showBackLink
        data={[datatype]}
        columns={columns}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        setSelectedRowId={setSelectedRowId}
        detailsComp={detailsComp}
        compName={TYPE.datatype}
      />
    );
  }
  return <Loading />;
}

export default DatatypeFullDetails;
