import PropTypes from "prop-types";
import CancelIcon from "assets/icons/cancel.svg";
import InLineEditButtonCancel from "components/EditComponents/InLineEditButtonCancelStyled";
import ImgSized from "components/elements/ImgSized";

const CancelButton = ({ onClick }) => (
  <>
    <InLineEditButtonCancel id="CancelButton_button" onClick={onClick}>
      <ImgSized id="CancelButton_cancelIcon" sm src={CancelIcon} alt="cancel" />
    </InLineEditButtonCancel>
  </>
);

CancelButton.propTypes = {
  onClick: PropTypes.func,
};

export default CancelButton;
