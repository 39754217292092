import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./export-query-key-factory";
import { ExportContext, ExportContextValues } from "../../constants";

interface IGetExportStatus {
  projectId: string;
  context: ExportContextValues;
  runId: string;
}
interface ExporterFunctionMap {
  [key: string]: any;
}
export const getExportStatus = async ({ projectId, context, runId }: IGetExportStatus) => {
  const exporter: ExporterFunctionMap = {
    [ExportContext.savepoint]: () => Brm.savePointExportApi.exportStatusSavepoint(projectId, runId),
    [ExportContext.rar]: () => Brm.rarExportApi.exportStatusRar(projectId, runId),
    [ExportContext.sctm]: () => Brm.sctmExportApi.exportStatusSctm(projectId, runId),
    [ExportContext.tra]: () => Brm.traExportApi.exportStatusTra(projectId, runId),
    [ExportContext.system]: () => Brm.systemExportApi.exportStatusSystem(projectId, runId),
  };

  if (!Object.keys(exporter).some((k) => k === context)) {
    throw new Error(`Unsupported export context: ${context}`);
  }

  return exporter[context]() || null;
};

export interface IUseExportStatus {
  projectId: string;
  context: ExportContextValues;
  runId: string;
  options?: QueryOption<typeof getExportStatus>;
}

export const useExportStatus = ({ projectId, runId, context, options }: IUseExportStatus) => {
  // When context = ImportContext.baseline => projectId = baselineId.
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exportKeys.status(projectId, context, runId),
    queryFn: () => getExportStatus({ projectId, context, runId }),
  });
};
