import * as React from "react";
import USER_ROLES from "services/brm/user-roles";
import { IUseHelpContent, IContentObj } from "features/help/types";
import { Faq as faqList } from "features/help/constants";

interface IFaqList {
  Role: string;
  Page: string;
  Question: string;
  Answer: string;
}

interface IFaq {
  [role: string]: {
    [page: string]: IContentObj[];
  };
}

// Removes duplicate FAQs by role
function uniqFaqByRole(faqArrWithDuplicates: IContentObj[]) {
  const seen = new Set();
  return faqArrWithDuplicates.filter((faqObj) => {
    const key = faqObj.role.concat(faqObj.name);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
}

const createFaqObj = (list: IFaqList[] = []) => {
  const rolesObj: { [key: string]: string } = Object.values(USER_ROLES.properties).reduce(
    (obj, role) => ({ ...obj, [role.key]: role.name }),
    {}
  );
  let faqObj: IFaq = {};
  list.forEach((item) => {
    const itemRole = item.Role;
    if (!faqObj) {
      faqObj = {};
    }
    if (!faqObj[item.Role]) {
      faqObj[item.Role] = {};
    }
    if (!faqObj[item.Role][item.Page]) {
      faqObj[item.Role][item.Page] = [];
    }
    faqObj[item.Role][item.Page].push({
      type: "FAQ",
      role: rolesObj[itemRole],
      name: item.Question,
      response: item.Answer,
    });
  });

  return faqObj;
};

const faqObj: IFaq = createFaqObj(faqList);

export const useFaqContent = ({ userRoles, contexts }: IUseHelpContent) => {
  const [contentsData, setContentsData] = React.useState<IContentObj[]>([]);

  const allFaqsContentsData: any[] = React.useMemo(() => {
    return (
      Object.values(faqObj)
        .map((contentsByRole: any) => Object.values(contentsByRole).flat())
        .flat() || []
    );
  }, []);

  const contentsByRole = React.useMemo(() => {
    if (userRoles.length && contexts.length) {
      return userRoles
        .map((role) => {
          return contexts
            .map((contentKey) =>
              faqObj[role] && faqObj[role][contentKey] && Array.isArray(faqObj[role][contentKey])
                ? faqObj[role][contentKey]
                : []
            )
            .flat();
        })
        .flat();
    }
    if (userRoles.length && !contexts.length) {
      return userRoles
        .map((role) => {
          return faqObj && role && faqObj[role] ? Object.values(faqObj[role]).flat() : [];
        })
        .flat();
    }
    if (!userRoles.length && contexts.length) {
      return Object.values(faqObj)
        .map((contentRole) => {
          return contexts.map((contentKey) => contentRole[contentKey] || []).flat();
        })
        .flat();
    }
    return null;
  }, [contexts, userRoles]);

  React.useEffect(() => {
    if (contentsByRole) {
      setContentsData(uniqFaqByRole(contentsByRole));
    } else {
      setContentsData(uniqFaqByRole(allFaqsContentsData));
    }
  }, [allFaqsContentsData, contexts, contentsByRole, userRoles]);

  return contentsData;
};
