import * as React from "react";

// Styles
import FormStyledError from "components/forms/FormStyledError";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";

// Constants
import { TYPE } from "constants/brm";

// Local
import { ImportAuditLogsSelector, ImportAuditEditSelector } from "../WizardSelector";
import { AUDIT_FORMAT, AUDIT_TYPE } from "../../constants";
import { IExportAuditReport } from "../../types";
import S from "./ExportWizard.styles";

interface IExportAuditWizard {
  onReportExport: IExportAuditReport;
  toggle: (value: boolean) => void;
  elementName: string;
}

export const ExportAuditWizard = ({ onReportExport, toggle, elementName }: IExportAuditWizard) => {
  const [selectedFormat, setSelectedFormat] = React.useState(AUDIT_FORMAT.csv);
  const [selectedType, setSelectedType] = React.useState(AUDIT_TYPE.session);
  const [postError, setPostError] = React.useState("");
  const [disableExport, setDisableExport] = React.useState(false);

  const handleFileExport = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (postError) {
      setPostError("");
    }
    if (selectedFormat) {
      setDisableExport(true);
      const opts = {
        format: selectedFormat,
        type: selectedType,
      };
      onReportExport(opts);
      toggle(false);
    } else setPostError("Please select an option to export");
  };

  let selector;

  if (elementName === TYPE.installation) {
    selector = (
      <ImportAuditLogsSelector
        selectedFormat={selectedFormat}
        setSelectedFormat={setSelectedFormat}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />
    );
  } else if (elementName === TYPE.project) {
    selector = (
      <ImportAuditEditSelector
        selectedFormat={selectedFormat}
        setSelectedFormat={setSelectedFormat}
        setSelectedType={setSelectedType}
      />
    );
  }

  return (
    <div id="Wizard">
      <S.WizardExport id="Wizard_import">
        <form onSubmit={handleFileExport} action="" id="WizardForm_form">
          {selector}
          <FormStyledError id="WizardForm_Error">{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => toggle(false)}>Cancel</DialogButtonStyled>
            <DialogButtonStyled type="submit" id="Wizard_export" disabled={disableExport}>
              {disableExport ? "Exporting..." : "Export"}
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </S.WizardExport>
    </div>
  );
};
