import PropTypes from "prop-types";
import { useEffect, useState, useMemo, useRef, useCallback } from "react";
// Utils
import errorUtils from "utils/error-utils";
// State
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
// Services
import handleApi from "services/brm/api-util";
import { moduleApi } from "services/brm/component-service";
// Components
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";
// Styles
import CustomTable from "components/elements/CustomTableDivStyled";
// Hooks
import useRefreshTableList from "hooks/useRefreshTableList";
// Constants
import { TYPE } from "constants/brm";

import {
  createColumnName,
  createColumnActions,
  createColumnImportedAt,
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";

export const ModuleTable = ({ setSelectedRows, refresh, setRefresh }) => {
  const projectId = useRecoilValue(projectIdState);
  const [moduleData, setModuleData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const { isSystemEngineer } = useRoles();
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedName.current !== "") {
              await handleApi(
                moduleApi.setModuleNameWithHttpInfo(selectedInstance.id, {
                  body: updatedName.current,
                })
              );
              setSelectedInstance({
                id: selectedInstance.id,
                name: updatedName.current,
                type: selectedInstance.type,
              });
              updatedName.current = "";
            }

            setRefresh(true);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [editMode, setRefresh, selectedInstance.id, selectedInstance.type, setSelectedInstance]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "modules"),
        createColumnMappedNoEdit("importsource"),
        createColumnMappedNoEdit("sourcetype"),
        createColumnBooleanNoEditMapped("isImported"),
        createColumnImportedAt(),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }

    return [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "modules"),
      createColumnMappedNoEdit("importsource"),
      createColumnMappedNoEdit("sourcetype"),
      createColumnBooleanNoEditMapped("isImported"),
      createColumnImportedAt(),
    ];
  }, [isSystemEngineer, selectedInstance.id, setSelectedInstance, editMode, createButton]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    async function getData() {
      setRefresh(false);
      // get list of instances
      try {
        const res = await moduleApi.findModuleWithHttpInfo(projectId);
        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setModuleData(res.data);
        }
      } catch (err) {
        console.error("err in get modules : ", err);
      }
    }
    if (refresh) {
      getData();
    }
  }, [refresh, projectId, setRefresh]);

  return (
    <>
      <CustomTable>
        <BrmMainTable
          data={moduleData}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "ModuleTable_table" }}
          elementName={TYPE.module}
        />
      </CustomTable>
    </>
  );
};

ModuleTable.propTypes = {
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func,
  setSelectedRows: PropTypes.func.isRequired,
};
