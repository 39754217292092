import { useState, useEffect } from "react";
import * as ReactQuery from "@tanstack/react-query";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import { userRoles } from "services/brm/admin-service";
import { AddButton, DeleteButton, SystemApi } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { TYPE, CRUD_EVENT } from "constants/brm";
import systemModals from "components/modals/Modals";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { useRoles } from "features/brm/hooks/useRoles";
import PersonTable from "./tables/PersonTable";
import PersonAddForm from "./forms/PersonAddForm";
import Event from "./crudEvent";

function Person() {
  const queryClient = ReactQuery.useQueryClient();
  const { isSystemEngineer } = useRoles();
  const sysEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const { mutateAsync: deletePerson } = SystemApi.useDeletePerson();

  const addPerson = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      await Promise.all(selectedRows.map((each) => deletePerson({ personId: each.id })));
      setDeleteModalIsOpen(false);
      setSelectedInstance({});
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deletePerson, queryClient, selectedRows, setSelectedInstance]);

  const personHeader = (
    <Title id="Person_title">
      Persons
      <Buttons id="Person_buttons">
        <Event constant={CRUD_EVENT.person} setRefresh={setRefresh} />
        {isSystemEngineer ? (
          <>
            {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
            <AddButton onClick={addPerson} />
          </>
        ) : null}
      </Buttons>
    </Title>
  );

  const personMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "Person_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.person}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.person
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "Person_page",
          modalIsOpen,
          setModalIsOpen,
          <PersonAddForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} />,
          TYPE.person
        )}

      <PersonTable
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
        accessibleRole={sysEngineerRole}
      />
    </>
  );

  return <ContentLayout pageId="Person_page" header={personHeader} main={personMain} />;
}

export default Person;
