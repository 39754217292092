import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./project-query-key-factory";

interface IProjectNameProps {
  projectId: string | null;
  newName: string;
}

/**
 * Rename a project
 *
 * @param param0 upload file data
 * @returns
 */
export const setProjectName = ({ projectId, newName }: IProjectNameProps) => {
  return projectId === null
    ? Promise.reject(new Error("Invalid projectId id"))
    : Brm.projectApi.setProjectName(projectId, { body: newName });
};

interface ISetNameProps {
  options?: MutationOption<typeof setProjectName>;
}

/**
 * Custom hook to rename a project.
 *
 * @returns react-query Mutation.
 */
export const useSetProjectName = ({ options }: ISetNameProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, err, variables) => {
      queryClient.invalidateQueries(KeyFactory.projectKeys.all);
      queryClient.invalidateQueries(KeyFactory.projectKeys.project(variables.projectId));
    },
    ...options,
    mutationFn: setProjectName,
  });
};
