import { DetailTableType } from "features/brm";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { attackerApi, baselineApi, projectApi, threatGroupApi, ttpApi, userApi } from "services/brm";
import getReactSelectArray from "utils/react-select-array";
import getUniqueArray from "./helpers/GetUniqueArray";

export const useAddThreatGroupTtp = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, selectedId }) => threatGroupApi.addThreatGroupTtpWithHttpInfo(id, { body: selectedId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["elementTtps"]);
    },
  });
};

export const useAddAttackerTtp = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, selectedId }) => attackerApi.addAttackerTtpWithHttpInfo(id, { body: selectedId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["elementTtps"]);
    },
  });
};

export const doFetchThreatGroups = async (projectId, id) => {
  const threatGroupttp = await threatGroupApi.getThreatGroupTtp(id);
  const ttps = await ttpApi.findTTP(projectId);
  return getReactSelectArray(getUniqueArray(ttps, threatGroupttp));
};

export const useThreatGroupTtps = (projectId, id, elementNameInUrl) => {
  const { data } = useQuery(["threatGroups", projectId], () => doFetchThreatGroups(projectId, id), {
    enabled: elementNameInUrl === DetailTableType.THREAT_GROUPS.key,
  });
  return data;
};

const doFetchAttackerTtps = async (projectId, id) => {
  const attackerttp = await attackerApi.getAttackerTtp(id);
  const ttps = await ttpApi.findTTP(projectId);
  return getReactSelectArray(getUniqueArray(ttps, attackerttp));
};

export const useAttackerTtps = (projectId, id, elementNameInUrl) => {
  const { data } = useQuery(["attackers", projectId], () => doFetchAttackerTtps(projectId, id), {
    enabled: elementNameInUrl === DetailTableType.ATTACKERS.key,
  });
  return data;
};

export const useAddRoles = () => {
  return useMutation(({ userId, role }) =>
    userApi.addUserAssignedRole(userId, {
      body: role,
    })
  );
};

export const useAddUser = (selectedElementId) => {
  const queryClient = useQueryClient();
  return useMutation(({ projectId, userId }) => projectApi.addProjectWorker(projectId, { body: userId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getProjectUsers", selectedElementId]);
    },
  });
};

export const useAddControlTypes = (elementId, elementName) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ controlId }) => {
      if (elementName === DetailTableType.STANDARD_BASELINE.key) {
        const data = await baselineApi.addStandardBaselineControltype(elementId, { body: controlId });
        return data;
      }
      return null;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getElementControlTypes", elementId, elementName]);
      },
    }
  );
};
