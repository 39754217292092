import { isProjectBusyState, projectStatusState } from "atoms/atoms-admin";
import { isSubmissionPendingState } from "atoms/atoms-importer";
import { STATUS } from "constants/brm";
import { AdminApi } from "features/brm";
import { useRoles } from "features/brm/hooks/useRoles";
import { useSetRecoilState, useRecoilValue } from "recoil";

interface IProjectStatusForImport {
  projectId: string | null;
  submissionData: any;
}

export const useProjectGetStatusForImport = ({ projectId, submissionData }: IProjectStatusForImport) => {
  const { isSystemEngineer } = useRoles();
  const setIsBusy = useSetRecoilState(isProjectBusyState);
  const setProjectStatus = useSetRecoilState(projectStatusState);
  const isSubmissionPending = useRecoilValue(isSubmissionPendingState);

  return AdminApi.useProjectStatus({
    projectId,
    options: {
      enabled: !!projectId && !!submissionData && isSystemEngineer,
      onSuccess: (data) => {
        setProjectStatus(data.result);
        if (data.result !== STATUS.active) {
          setIsBusy(true);
        } else setIsBusy(false);
      },
      refetchInterval: isSubmissionPending ? 3000 : false,
    },
  });
};
