import PropTypes from "prop-types";
import * as React from "react";
import { useLocation } from "react-router-dom";

// State
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedInstanceState } from "atoms/atoms-content";
import { leftSidebarOpenState } from "atoms/atoms-layout";

import { RoutePath } from "routes/route-paths";

// Components
import Content from "brm/layout/Content";
import { useRoles } from "features/brm/hooks/useRoles";

// Style/Layout
import { contentKeyState, userRoleState } from "atoms";
import { LicenseApi } from "features/brm";
import { LicenseAlert, PageSpinner } from "components/elements";
import L from "./MainPage.styles";

const MainPage = ({ contentKey }) => {
  const setSelectedInstance = useSetRecoilState(selectedInstanceState);
  const setDrawerVisible = useSetRecoilState(leftSidebarOpenState);
  const setContentKey = useSetRecoilState(contentKeyState);
  const location = useLocation();
  const { isAdmin, isSuperAdmin, isRiskAnalyst } = useRoles();
  const { data: licenseStatus, isLoading } = LicenseApi.useLicenseState();
  const userRole = useRecoilValue(userRoleState);

  React.useEffect(() => {
    setContentKey(contentKey);
  }, [contentKey, setContentKey]);

  React.useEffect(() => {
    if (
      ((isAdmin || isSuperAdmin) && location.pathname !== RoutePath.Home) ||
      (isRiskAnalyst && location.pathname === RoutePath.Projects)
    ) {
      setDrawerVisible(true);
    } else if (location.pathname === RoutePath.Home) {
      setDrawerVisible(false);
    }
  }, [userRole, isAdmin, isSuperAdmin, isRiskAnalyst, setDrawerVisible, location]);

  React.useEffect(() => {
    setSelectedInstance({});
  }, [location, setSelectedInstance]);

  if (isLoading) {
    return <PageSpinner text="Loading..." />;
  }

  if (userRole && !isSuperAdmin && !licenseStatus?.valid) {
    return <LicenseAlert errorMsg={licenseStatus?.vendorString?.$ERROR_MSG} />;
  }

  return (
    <L.Contents>
      <L.ContentContainer>
        <Content contentKey={contentKey} />
      </L.ContentContainer>
    </L.Contents>
  );
};

MainPage.propTypes = {
  contentKey: PropTypes.any,
};

export default MainPage;
