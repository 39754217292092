import styled from "styled-components";
import Button from "react-bootstrap/Button";

export const ActionButton = styled(Button)`
  background-color: #005ca6;
  border: 1px solid #005ca6;

  & :hover {
    background-color: #0065ff;
    border: 1px solid #0065ff;
  }

  & :disabled {
    border-color: none;
  }
`;
