import { AdminApi, useRoles } from "features/brm";
import { Setting } from "features/admin/types/setting-types";

interface IUseProjectConfigurationPanelApi {
  projectId: string;
}

export const useProjectConfigurationPanelApi = ({ projectId }: IUseProjectConfigurationPanelApi) => {
  const { isAdmin, isRiskAnalyst, isSuperAdmin } = useRoles();
  const { data: projectSettings } = AdminApi.useGetProjectProperty({
    projectId,
    property: "settings",
    options: { enabled: isAdmin || isRiskAnalyst || isSuperAdmin },
  });
  const { mutate: setProjectSettingProperty } = AdminApi.useSetProjectSetting();

  const setProjectSetting = (setting: Setting) => {
    setProjectSettingProperty({ projectId, setting: setting.name as AdminApi.ProjectSetting, value: setting.value });
  };

  return {
    projectSettings,
    setProjectSetting,
  };
};
