import * as SystemApi from "features/brm/api/system";

import { DataTypeCreateDto, CategorizedEntityCreateDto } from "@kdmanalytics/brm-system";

// import { useQueryClient } from "@tanstack/react-query";
import * as React from "react";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useRoles } from "hooks";
import { COMMON } from "constants/brm";

interface IImpactStatement {
  level: string;
  objective: string;
}

export interface IDatatypeObject {
  id: string;
  name: string;
  note: string;
  category: string;
  isInternal: boolean;
  isInput?: boolean;
  isOutput?: boolean;
  isProcessed?: boolean;
  isStored?: boolean;
  classificationCategory: string;
  categorization: IImpactStatement[];
}

interface ICreateDatatype {
  projectId: string;
  dto: IDatatypeObject;
  onSuccess?: () => void;
}

/**
 * Hook to create Datatypes.  When creating a datatype
 * we want to also by default create a Categorized Entity for that datatype
 * so we create both using this hook
 */
export const useCreateDatatype = () => {
  const { isSystemEngineer } = useRoles();
  const { mutate: createDataType } = SystemApi.useCreateDataType();
  const { mutateAsync: setDatatypeProperty } = SystemApi.useSetDatatypeProperty();
  const { mutateAsync: setCategorizedEntityClassification } = SystemApi.useSetCategorizedEntityClassification();
  const activeDatatypeId = React.useRef("");
  const activeClassification = React.useRef("");
  const queryClient = useQueryClient();

  const { data: categorizedEntity } = SystemApi.useDataTypeCategorizedEntity({
    dataTypeId: activeDatatypeId.current,
    options: {
      enabled: activeDatatypeId.current !== "" && isSystemEngineer,
    },
  });

  const { mutateAsync: createCategorizedEntity } = SystemApi.useCreateCategorizedEntity();

  const createDatatype = ({ projectId, dto, onSuccess }: ICreateDatatype) => {
    const createDto = DataTypeCreateDto.constructFromObject({
      name: dto.name,
      note: dto.note,
      category: dto.category,
      isInternal: dto.isInternal,
    });

    try {
      // Add new datatype
      createDataType(
        { projectId, dataTypeCreateDto: createDto },
        {
          onSuccess: async (datatypeId) => {
            // Optional properties
            if (dto.isInput) {
              await setDatatypeProperty({ datatypeId, property: "isInput", value: dto.isInput });
            }
            if (dto.isOutput) {
              await setDatatypeProperty({ datatypeId, property: "isOutput", value: dto.isOutput });
            }
            if (dto.isProcessed) {
              await setDatatypeProperty({ datatypeId, property: "isProcessed", value: dto.isProcessed });
            }
            if (dto.isStored) {
              await setDatatypeProperty({ datatypeId, property: "IsStored", value: dto.isStored });
            }

            // We avoid creation classifiedEntity if there is no classification as per Nick's request
            if (!dto.isInternal && dto.classificationCategory !== "-") {
              const catEntparams = {
                name: `${dto.name} ce`,
                note: "",
                subject: datatypeId,
                classification: "-",
                categorization: [],
              };
              const categorizedEntityCreateDto = CategorizedEntityCreateDto.constructFromObject(catEntparams);
              try {
                await createCategorizedEntity({ projectId, categorizedEntityCreateDto });
              } catch (err: any) {
                toast.error(`Creating categorized entity caused an error: ${err.status}`);
              }
            }

            activeClassification.current = dto.classificationCategory;
            activeDatatypeId.current = datatypeId;

            if (onSuccess) {
              onSuccess();
            }
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  if (activeClassification.current && categorizedEntity && categorizedEntity.id !== COMMON.nullUuid) {
    setCategorizedEntityClassification({
      categorizedEntityId: categorizedEntity.id,
      classificationId: activeClassification.current,
    });
    activeClassification.current = "";
    activeDatatypeId.current = "";
    queryClient.invalidateQueries({ queryKey: ["classifiedDataType"] });
    queryClient.invalidateQueries({ queryKey: SystemApi.dataTypeKeys.all });
  }

  return { createDatatype };
};
