/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { ErrorSpan } from "components/elements";
import { AdminApi, useProject, KnowledgebaseApi } from "features/brm";
import { IOption } from "types";
import ReactSelect from "react-select";
import { getErrorMessage } from "utils/error-message-utils";
import { useConfigureProjectContext } from "./ConfigureProjectContext";
import * as CommonStyle from "./styles";
import { StandardStepButtons } from "./StandardStepButtons";

interface IKnowledgebaseInput {
  kb: IOption;
}

interface IKnowledgebase {
  id: string;
  name: string;
}

export const KnowledgebaseStepForm = () => {
  const { next, configureData, setConfigureData } = useConfigureProjectContext();

  const [, { projectId }] = useProject();
  const { data: projKb } = AdminApi.useProjectKb({ projectId, options: { enabled: !!projectId } });
  const { data: kbData, isError: isKbError, error: kbError } = KnowledgebaseApi.useAllKbs();

  const kbOptions = React.useMemo(
    () => kbData?.map((kb: IKnowledgebase): IOption => ({ value: kb.id, label: kb.name })) || [],
    [kbData]
  );

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    reset,
  } = useForm<IKnowledgebaseInput>({
    mode: "onChange",
  });

  React.useEffect(() => {
    if (projKb && kbOptions) {
      const options = configureData?.knowledgebase?.value
        ? kbOptions.find((kb: IOption) => kb.value === configureData?.knowledgebase?.value)
        : kbOptions.find((kb: IOption) => kb.value === projKb.id);

      reset({
        kb: options,
      });
    }
  }, [configureData?.knowledgebase?.value, kbOptions, projKb, reset]);

  const onSubmit = (data: IKnowledgebaseInput) => {
    setConfigureData({ ...configureData, knowledgebase: data.kb });
    next();
  };

  if (isKbError) {
    return <>{`Unable to retrieve available Knowledgebases:${getErrorMessage(kbError)}`}</>;
  }

  return (
    <CommonStyle.StepForm onSubmit={handleSubmit(onSubmit)} id="createProject">
      <fieldset>
        <legend>Knowledgebase</legend>
        <div style={{ padding: "5px 0 10px 0" }}>
          A default knowledgebase for your organization has been pre-selected. If you require a more appropriate
          knowledgebase please select it from the available knowledgebases in dropdown below
        </div>
        <Form.Group className="mb-3" controlId="formProjectUsers">
          <Form.Label>Select Knowledgebase</Form.Label>
          <Controller
            name="kb"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <ReactSelect {...field} options={kbOptions} placeholder="Select Knowledgebase..." />}
          />
          {errors.kb && errors.kb.type === "required" && <ErrorSpan>This field is required</ErrorSpan>}
        </Form.Group>
      </fieldset>
      <StandardStepButtons isBackVisible={false} isNextEnabled={isValid} />
    </CommonStyle.StepForm>
  );
};
