import { useQuery } from "@tanstack/react-query";
import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { useRoles } from "features/brm/hooks/useRoles";
import * as KeyFactory from "./node-query-key-factory";
import { BrmError } from "../../../errors/BrmError";
import { DetailTableType } from "../../../types/DetailTableType";

export const getElementNodes = async ({
  elementId,
  elementName,
  dataTypeNodesData,
  dataFlowNodesData,
  missionNodesData,
}: IGetElementNodesProps) => {
  const nodesMap: INodesMap = {
    [DetailTableType.DATATYPES.key]: () => dataTypeNodesData?.dataType?.nodes,
    [DetailTableType.DATAFLOWS.key]: () => dataFlowNodesData?.dataFlow?.nodes,
    [DetailTableType.MISSIONS.key]: () => missionNodesData?.mission?.nodes,
    [DetailTableType.PERSONS.key]: (id) => Brm.personApi.getPersonNode(id),
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.getCapabilityNode(id),
    [DetailTableType.ATTACK_SURFACE_CATEGORIES.key]: (id) =>
      Brm.attackSurfaceCatApi.getAttackSurfaceCategoryAllAccessableNode(id),
    [DetailTableType.ENTRY_POINT_CATEGORIES.key]: (id) => Brm.entryPointCatApi.getEntryPointCategoryAllNode(id),
    [DetailTableType.ACCESS_POINT_CATEGORIES.key]: (id) => Brm.accessPointCatApi.getAccessPointCategoryAllNode(id),
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityNode(id),
    [DetailTableType.RESOURCES.key]: (id) => Brm.resourceApi.getResourceNode(id),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeParent(id),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeParent(id),
    [DetailTableType.ACCESS_POINTS.key]: (id) => Brm.accessPointApi.getAccessPointNode(id),
    [DetailTableType.ATTACK_SURFACES.key]: (id) => Brm.attackSurfaceApi.getAttackSurfaceAllAccessableNode(id),
    [DetailTableType.ENTRY_POINTS.key]: (id) => Brm.entryPointApi.getEntryPointNode(id),
    [DetailTableType.ATTACK_VECTORS.key]: (id) => Brm.attackVectorApi.getAttackVectorNode(id),
  };

  if (nodesMap[elementName]) {
    const data = (await nodesMap[elementName](elementId)) || [];
    return Array.isArray(data) ? data : [data];
  }

  throw new BrmError(`Unsupported element type: ${elementName}`);
};

interface IElementNodesProps {
  elementId: string;
  elementName: string;
  projectId: string;
  options: QueryOption<typeof getElementNodes>;
}

interface IGetElementNodesProps {
  elementId: string;
  elementName: string;
  dataTypeNodesData: BrmGql.GetDataTypeNodesQuery | undefined;
  dataFlowNodesData: BrmGql.GetDataflowNodesQuery | undefined;
  missionNodesData: BrmGql.GetMissionNodesQuery | undefined;
}

interface INodesMap {
  [index: string]: (id?: string) => any;
}

/**
 * custom-hook to retreive all nodes
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {string} variantId - uuid of the variant
 * @param {object} options - configuration for the react-query
 */
export const useElementNodes = ({ elementId, elementName, projectId, options = {} }: IElementNodesProps) => {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();

  const dataTypeNodesQuery = BrmGql.useGetDataTypeNodesQuery(
    { project: projectId, id: elementId },
    {
      enabled:
        !!elementId &&
        !!projectId &&
        elementName === DetailTableType.DATATYPES.key &&
        (isSystemEngineer || isRiskAnalyst),
    }
  );

  const dataFlowNodesQuery = BrmGql.useGetDataflowNodesQuery(
    { project: projectId, id: elementId },
    {
      enabled:
        !!elementId &&
        !!projectId &&
        elementName === DetailTableType.DATAFLOWS.key &&
        (isSystemEngineer || isRiskAnalyst),
    }
  );

  const missionNodesQuery = BrmGql.useGetMissionNodesQuery(
    { project: projectId, id: elementId },
    {
      enabled:
        !!elementId &&
        !!projectId &&
        elementName === DetailTableType.MISSIONS.key &&
        (isSystemEngineer || isRiskAnalyst),
    }
  );

  const nodesQuery = useQuery({
    ...options,
    queryKey: KeyFactory.nodeKeys.element(
      elementId,
      elementName,
      dataTypeNodesQuery.data,
      dataFlowNodesQuery.data,
      missionNodesQuery.data
    ),
    queryFn: () =>
      getElementNodes({
        elementId,
        elementName,
        dataTypeNodesData: dataTypeNodesQuery.data,
        dataFlowNodesData: dataFlowNodesQuery.data,
        missionNodesData: missionNodesQuery.data,
      }),
  });

  if (dataTypeNodesQuery.isError) {
    return dataTypeNodesQuery;
  }

  if (dataFlowNodesQuery.isError) {
    return dataFlowNodesQuery;
  }

  if (missionNodesQuery.isError) {
    return missionNodesQuery;
  }

  return nodesQuery;
};
