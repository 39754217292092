import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

export const getSecurityObjectives = ({ projectId }) => {
  return Brm.securityObjectiveApi.findSecurityObjective(projectId);
};

/**
 * React-query hook for getting all SecurityObjectives
 *
 * @param {string} projectId - uuid of the project
 * @returns react-query for getSecurityObjectives
 */
export const useSecurityObjectives = ({ projectId, config = {} }) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["securityObjectives", projectId],
    queryFn: () => getSecurityObjectives({ projectId }),
  });
};
