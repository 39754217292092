import * as Recoil from "recoil";
import { userRoleState, userAssignedRolesState } from "atoms/atoms-admin";
import userRoles from "services/brm/user-roles";
import { useEffect } from "react";
import { useRoles } from "hooks";
import * as AdminApi from "../../api/admin";

interface IProp {
  key: string;
  name: string;
  rank: number;
}

interface IUserRoles {
  [key: string]: any;
}
const typedUser: IUserRoles = userRoles;

const UNUSED_ROLES: Array<IProp> = [
  typedUser.properties[typedUser.EVIDENCE_SERVER_COORDINATOR] as IProp,
  typedUser.properties[typedUser.SW_DEVELOPER] as IProp,
  typedUser.properties[typedUser.BOR_PROJECT_COORDINATOR] as IProp,
  typedUser.properties[typedUser.TECH_ADMIN] as IProp,
  typedUser.properties[typedUser.BUILD_ENGINEER] as IProp,
  typedUser.properties[typedUser.AUTH_OFFICER] as IProp,
  typedUser.properties[typedUser.RMF_COORDINATOR] as IProp,
  typedUser.properties[typedUser.EXECUTIVE] as IProp,
  typedUser.properties[typedUser.SCA] as IProp,
];

export const useSwitchUser = () => {
  const { userId, userRole } = useRoles();
  const setCurrentUserRole = Recoil.useSetRecoilState(userRoleState);
  const setAssignedRoles = Recoil.useSetRecoilState(userAssignedRolesState);

  const { data: userAssignedRoles, refetch: refreshAssignedRoles } = AdminApi.useUser({
    userId,
    options: {
      select: (data) => {
        return data?.assignedRole ? data.assignedRole.filter((i: any) => !UNUSED_ROLES.some((j) => j.key === i)) : [];
      },
    },
  });

  useEffect(() => {
    if (userRole && userAssignedRoles) {
      setCurrentUserRole(userRole);
      setAssignedRoles(userAssignedRoles);
    }
  }, [setAssignedRoles, setCurrentUserRole, userAssignedRoles, userId, userRole]);

  return { refreshAssignedRoles };
};
