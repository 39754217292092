import Button from "react-bootstrap/Button";

export const FileListItem = ({ file, onRemoveClicked }: { file: File; onRemoveClicked: (f: File) => void }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <span style={{ display: "flex", alignItems: "center" }}>{file.name}</span>
      <Button size="sm" variant="link" onClick={() => onRemoveClicked(file)}>
        REMOVE
      </Button>
    </div>
  );
};
