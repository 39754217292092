import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import * as Brm from "services/brm";

interface ICreateProjectProps {
  organizationId: string;
  projectCreateDto: any;
}

/**
 * Create a project
 *
 * @returns
 */
export const createProject = ({ organizationId, projectCreateDto }: ICreateProjectProps) => {
  return projectCreateDto === null
    ? Promise.reject(new Error("Invalid projectDto dto"))
    : Brm.projectApi.createProject(organizationId, { projectCreateDto });
};

interface ICreateProjectQueryProps {
  options?: MutationOption<typeof createProject>;
}

/**
 * Create a project
 *
 * @returns react-query Mutation.
 */
export const useCreateProject = ({ options }: ICreateProjectQueryProps = {}) => {
  return ReactQuery.useMutation({
    // Removed since createProject is an async operation these don't really do anything..
    // onSuccess: () => {
    //   queryClient.invalidateQueries(KeyFactory.projectKeys.all);
    //   queryClient.invalidateQueries(UserKeyFactory.userKeys.all);
    // },
    ...options,
    mutationFn: createProject,
  });
};
