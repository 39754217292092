/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { useMultiStepForm } from "hooks/useMultStepForm";
import { ActionButton } from "components/elements";
import * as S from "./RarManualMitigateForm.styles";
import { VulnerabilitySelectionStep } from "./VulnerabilitySelectionStep";
import { ControlTypeSelectionStep } from "./ControlTypeSelectionStep";
import { ManualMitigationSummaryStep } from "./ManualMitigationSummaryStep";
import { IVulnerabilityData } from "./types";
import { useRarManualMitigation } from "./useRarManualMitigation";
import { toSelectedControlTypes, toSelectedVulnerabilities } from "./selection-to-type";

interface IManualMitigationFormProps {
  onClose: () => void;
  rarVulnerabilities: IVulnerabilityData[];
  importSctm: ({ id, body }: { id: string; body: string }) => void;
}

export const RarManualMitigateForm = ({ onClose, rarVulnerabilities, importSctm }: IManualMitigationFormProps) => {
  const {
    mitigationWizardState,
    updateMitigationWizardState,
    // vulnerabilitySelection,
    // setVulnerabilitySelection,
    // selectedControlTypeRef,
    hasSelectedVulnerabilities,
    hasSelectedControlTypes,
    // updateSelectedControlTypes,
    performManualMitigation,
  } = useRarManualMitigation({
    rarVulnerabilities,
    onClose,
    importSctm,
  });

  const { currentStep, isFirstStep, isLastStep, currentStepIndex, prev, next } = useMultiStepForm([
    <VulnerabilitySelectionStep
      key="VulnerabilitySelectionForm"
      wizardState={mitigationWizardState}
      updateWizardState={updateMitigationWizardState}
      // setVulnerabilitySelection={setVulnerabilitySelection}
      // selectedVulnerabilities={vulnerabilitySelection}
    />,
    <ControlTypeSelectionStep
      key="ControlTypeSelectionForm"
      wizardState={mitigationWizardState}
      updateWizardState={updateMitigationWizardState}
    />,
    <ManualMitigationSummaryStep key="ManualMitigationSummary" {...mitigationWizardState} />,
  ]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isFirstStep) {
      // updateData({ selectedVulnerabilities: selectedVulnerabilityRef.current });
      const vuls = toSelectedVulnerabilities(mitigationWizardState);
      updateMitigationWizardState({ vulnerabilities: vuls });
      // updateData({ vulnerabilities: selectedVulnerabilityRef.current.map((v) => v.value) });
    } else if (currentStepIndex === 1) {
      const ctrls = toSelectedControlTypes(mitigationWizardState);
      updateMitigationWizardState({ controlTypes: ctrls });
    } else if (isLastStep) {
      performManualMitigation();

      // setFormIsComplete(true);
      onClose();
      return;
    }
    next();
  };

  const isNextEnabled = () => {
    return (isFirstStep && hasSelectedVulnerabilities) || hasSelectedControlTypes;
  };

  return (
    <S.Container>
      <S.Form id="manual mitigation" onSubmit={onSubmit}>
        <S.StepContainer>{currentStep}</S.StepContainer>
        <S.ButtonContainer>
          <ActionButton type="button" onClick={onClose}>
            Cancel
          </ActionButton>
          {!isFirstStep && (
            <ActionButton type="button" onClick={prev}>
              Prev
            </ActionButton>
          )}
          <ActionButton type="submit" disabled={!isNextEnabled()} form="manual mitigation">
            {isLastStep ? "Mitigate" : "Next"}
          </ActionButton>
        </S.ButtonContainer>
      </S.Form>
    </S.Container>
  );
};
