import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * Returns control types for the given baseline
 *
 * @param {string} baselineId - uuid of the baseline
 */
export const getBaselineControlTypes = ({ baselineId }) => {
  return Brm.baselineApi.getStandardBaselineControltype(baselineId);
};

/**
 * React-query hook for getting control types of a baseline
 */
export const useBaselineControlTypes = ({ baselineId, config = {} }) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["baselineControlTypes", baselineId],
    queryFn: () => getBaselineControlTypes({ baselineId }),
  });
};
