import { useState } from "react";
// Styles
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

// Local
import AttackKindTable from "./tables/AttackKindTable";

function AttackKind() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const attackKindHeader = <Title id="AttackKind_title">Attack Kinds</Title>;

  const attackKindMain = (
    <AttackKindTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
  );

  return <ContentLayout pageId="AttackKind_page" header={attackKindHeader} main={attackKindMain} />;
}

export default AttackKind;
