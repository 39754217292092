import { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

// Components
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { DeleteButton, AddButton, ControlFamilyDropdownSelection } from "features/brm";
// Services
import { controlTypeApi } from "services/brm/global-control-service";
// State
import { controlFamilyIdState } from "atoms/atoms-global-control";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useSetRecoilState } from "recoil";
// Constants
import { TYPE } from "constants/brm";
// Styles
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";

import ContentLayout from "brm/layout/Content/ContentLayout";

// Local
import { LoadingSpinner } from "components/elements";
import { useControlCatalog } from "../../hooks";
import { AddControlTypeForm, ControlTypeTable } from "../../components";

export function ControlTypes() {
  const setSelectedInstance = useSetRecoilState(selectedInstanceState);
  const controlFamilyId = useRecoilValue(controlFamilyIdState);

  const [selectedRows, setSelectedRows] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const queryClient = useQueryClient();
  const { controlCatalog, isReadOnlyCatalog } = useControlCatalog();

  const addControlType = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      await Promise.all(selectedRows.map((each: any) => controlTypeApi.deleteControlTypeWithHttpInfo(each.id)));
      queryClient.invalidateQueries(["controlTypes"]);
      setDeleteModalIsOpen(false);
      setSelectedInstance({});
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, queryClient, selectedRows, setSelectedInstance]);

  if (controlCatalog) {
    const header = (
      <Title>
        Control Types
        {controlFamilyId && (
          <Buttons>
            {!isReadOnlyCatalog && selectedRows.length > 0 ? (
              <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />
            ) : null}
            {isReadOnlyCatalog ? null : <AddButton onClick={addControlType} />}
          </Buttons>
        )}
      </Title>
    );

    const main = (
      <>
        {controlCatalog.id ? (
          <ControlFamilyDropdownSelection />
        ) : (
          <>Please select a control catalog, in order to view control types</>
        )}
        {deleteModalIsOpen &&
          systemModals.deleteModal(
            "ControlType_page",
            deleteModalIsOpen,
            setDeleteModalIsOpen,
            <GenericDeleteConfirmation
              elementName={TYPE.controlType}
              setDeleteModalIsOpen={setDeleteModalIsOpen}
              selectedRows={selectedRows}
              setConfirmDelete={setConfirmDelete}
            />,
            TYPE.controlType
          )}

        {modalIsOpen &&
          systemModals.addModal(
            "ControlType_page",
            modalIsOpen,
            setModalIsOpen,
            <AddControlTypeForm setModalIsOpen={setModalIsOpen} />,
            TYPE.controlType
          )}

        <ControlTypeTable setSelectedRows={setSelectedRows} />
      </>
    );

    return <ContentLayout pageId="ControlType_page" header={header} main={main} />;
  }
  return <LoadingSpinner />;
}
