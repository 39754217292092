import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { COMMON } from "constants/brm";
import * as KeyFactory from "./variant-query-key-factory";

interface IGetUnmitigatedScoreProps {
  projectId: string;
}

/**
 *
 * @param {string} projectId - uuid of the project
 */
export const getUnmitigatedScore = ({ projectId }: IGetUnmitigatedScoreProps): Promise<any> => {
  return Brm.variantApi.getVariantScore(COMMON.nullUuid, projectId);
};

interface IUseUnmitigatedScoreProps {
  projectId: string;
  options?: QueryOption<typeof getUnmitigatedScore>;
}

/**
 * React-query hook for getting unmitigated score
 *
 * @param {string} projectId - uuid of the project
 * @param {object} options - react-query configuration object
 * @returns react-query for getUnmitigatedScore
 */
export const useUnmitigatedScore = ({ projectId, options = {} }: IUseUnmitigatedScoreProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.variantsKeys.all,
    queryFn: () => getUnmitigatedScore({ projectId }),
  });
};
