import { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
// Components
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { AddButton, DeleteButton, SystemApi } from "features/brm";

import ContentLayout from "brm/layout/Content/ContentLayout";
// Styles
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
// State
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";

// Constants
import { TYPE } from "constants/brm";
// Local
import { useRoles } from "features/brm/hooks/useRoles";
import toast from "react-hot-toast";
import SystemAssetTypeTable from "./tables/SystemAssetTypeTable";
import SystemAssetTypeAddForm from "./forms/SystemAssetTypeAddForm";

function SystemAssetType() {
  const projectId = useRecoilValue(projectIdState);
  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  const queryClient = useQueryClient(projectIdState);
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { mutateAsync: deleteSystemAssetType } = SystemApi.useDeleteSystemAssetType();

  useEffect(() => {
    async function multiDeleteApiCalls() {
      try {
        await Promise.all(selectedRows.map((each) => deleteSystemAssetType({ systemAssetTypeId: each.id })));

        setConfirmDelete(false);
        setDeleteModalIsOpen(false);
        setSelectedInstance({});
      } catch (err) {
        toast.error(`Error occured while deleting system asset type ${err?.message}`);
      }
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteSystemAssetType, projectId, queryClient, selectedRows, setSelectedInstance]);

  const addSystemAssetType = () => {
    setModalIsOpen(true);
  };

  const systemAssetTypeHeader = (
    <Title id="SystemAssetType_title">
      System Asset Types
      <Buttons id="SystemAssetType_buttons">
        {isRiskAnalyst || isSystemEngineer ? (
          <>
            {selectedRows?.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
            <AddButton onClick={addSystemAssetType} />
          </>
        ) : (
          <br />
        )}
      </Buttons>
    </Title>
  );

  const systemAssetTypeMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "SystemAsset_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.systemAssetType}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.systemAssetType
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "SystemAsset_page",
          modalIsOpen,
          setModalIsOpen,
          <SystemAssetTypeAddForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} />,
          TYPE.systemAssetType
        )}
      <SystemAssetTypeTable
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );

  return <ContentLayout pageId="SystemAssetType_page" header={systemAssetTypeHeader} main={systemAssetTypeMain} />;
}

export default SystemAssetType;
