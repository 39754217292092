import { useState, useMemo, useRef, useCallback } from "react";
// State
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
// Styles
import CustomTable from "components/elements/CustomTableDivStyled";
// Components
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";
// Hooks
import { SystemApi } from "features/brm";
import { useRoles } from "features/brm/hooks/useRoles";
// Constants
import { TYPE, COMMON } from "constants/brm";
// Table Columns
import {
  createColumnName,
  createColumnMapped,
  createColumnCategoryMapped,
  createColumnMappedNoEdit,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import * as ACQueries from "brm/system-model/access/access-queries";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";

const EntryPointTable = ({ setSelectedRows }: { setSelectedRows: any }) => {
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedCategory = useRef("");
  const updatedSurface = useRef("");

  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const { data: entryPointCategories } = ACQueries.useEntryPointCategories(projectId);
  const { data: entryPoints, isError: entryPointsError } = SystemApi.useEntryPoints({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer) },
  });
  const { data: attackSurfaces, isError: attackSurfacesError } = SystemApi.useAttackSurfaces({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer) },
  });

  const { mutate: setEntryPointName } = SystemApi.useSetEntryPointName({
    projectId,
    options: {
      onSettled: () => {
        updatedCategory.current = "";
      },
    },
  });

  const { mutate: setEntryPointCategory } = SystemApi.useSetEntryPointCategory({
    projectId,
    options: {
      onSettled: () => {
        updatedCategory.current = "";
      },
    },
  });

  const { mutate: setEntryPointSurface } = SystemApi.useSetEntryPointSurface({
    projectId,
    options: {
      onSettled: () => {
        updatedSurface.current = "";
      },
    },
  });

  const createButton = useCallback(
    (cellProps: { cell: { row: { original: { id: any } } } }) => {
      if (isSystemEngineer || isRiskAnalyst) {
        return (
          <ButtonCell
            selectedRowId={selectedInstance.id}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedName.current !== "") {
                setEntryPointName(
                  { entryPointId: selectedInstance.id, name: updatedName.current },
                  {
                    onSuccess: () => {
                      setSelectedInstance({
                        id: selectedInstance.id,
                        name: updatedName.current,
                        type: selectedInstance.type,
                      });
                    },
                  }
                );
              }

              if (updatedCategory.current !== "") {
                setEntryPointCategory({ entryPointId: selectedInstance.id, category: updatedCategory.current });
              }

              if (updatedSurface.current !== "") {
                setEntryPointSurface({ entryPointId: selectedInstance.id, surface: updatedSurface.current });
              }
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return COMMON.na;
    },
    [
      isSystemEngineer,
      isRiskAnalyst,
      selectedInstance.id,
      selectedInstance.type,
      editMode,
      setEntryPointName,
      setEntryPointCategory,
      setEntryPointSurface,
      setSelectedInstance,
    ]
  );

  const columns = useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "entryPoint"),
      createColumnCategoryMapped("category", selectedInstance.id, entryPointCategories, editMode, updatedCategory),
      createColumnMappedNoEdit("node"),
      createColumnMappedNoEdit("exchange"),
      createColumnMapped("surface", selectedInstance.id, attackSurfaces, editMode, updatedSurface),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [selectedInstance.id, setSelectedInstance, editMode, entryPointCategories, attackSurfaces, createButton]
  );

  if (entryPointsError) {
    return <ErrorBanner msg="Error while loading Entry Points data." />;
  }

  if (attackSurfacesError) {
    return <ErrorBanner msg="Error while loading Attack Surfaces data." />;
  }

  if (entryPoints) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={entryPoints}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "EntryPointTable_table" }}
            elementName={TYPE.entryPoint}
            showRowSelect={false}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

export default EntryPointTable;
