import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "brm/tables/details/api/element-query-key-factory";

interface IAddExchangeCharacteristicProps {
  exchangeId: string;
  characteristicCreateDto: any;
}

/**
 * Sets tag value of selected caractheristic
 *
 * @param {string} exchangeId - uuid of the Exchange
 * @param {ImpactStatementCreateDto} characteristicCreateDto - Characteristic
 */
export const addExchangeCharacteristic = ({ exchangeId, characteristicCreateDto }: IAddExchangeCharacteristicProps) => {
  return Brm.exchangeApi.addExchangeCharacteristic(exchangeId, { characteristicCreateDto });
};

interface IuseAddExchangeCharacteristicProps {
  elementId: string;
  elementName: string;
  options?: MutationOption<typeof addExchangeCharacteristic>;
}

/**
 * Custom hook to add a new characteristic or modify the tag value of an existing one
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useAddExchangeCharacteristic = ({
  elementId,
  elementName,
  options = {},
}: IuseAddExchangeCharacteristicProps) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: addExchangeCharacteristic,
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.elementKeys.characteristic(elementId, elementName));
    },
  });
};
