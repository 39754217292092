import { useQuery } from "@tanstack/react-query";
import { DetailTableType, BrmError } from "features/brm";
import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./element-query-key-factory";

const getElementPersons = (
  elementId: string,
  elementType: string,
  resourcePersonsData: BrmGql.GetResourcePersonsQuery | undefined,
  missionPersonsData: BrmGql.GetMissionPersonsQuery | undefined,
  dataTypesPersonData: BrmGql.GetDataTypePersonsQuery | undefined
) => {
  const personMap: IPersonsMap = {
    [DetailTableType.RESOURCES.key]: () => resourcePersonsData?.resource?.persons,
    [DetailTableType.MISSIONS.key]: () => missionPersonsData?.mission?.persons,
    [DetailTableType.DATATYPES.key]: () => dataTypesPersonData?.dataType?.persons,
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.getCapabilityPerson(id),
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityPerson(id),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodePerson(id),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangePerson(id),
    [DetailTableType.DATAFLOWS.key]: (id) => Brm.dataflowApi.getDataFlowPerson(id),
  };

  if (personMap[elementType]) {
    return personMap[elementType](elementId) || [];
  }
  throw new BrmError(`Unsupported element type: ${elementType}`);
};

interface IElementPersonsProps {
  elementId: string;
  elementName: string;
  projectId: string;
  config: QueryOption<typeof getElementPersons>;
}

interface IPersonsMap {
  [index: string]: (id?: string) => any;
}

/**
 * custom-hook to retreive all persons
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {string} variantId - uuid of the variant
 * @param {object} config - configuration for the react-query
 */
export const useElementPersons = ({ elementId, elementName, projectId, config = {} }: IElementPersonsProps) => {
  const resourcePersonsQuery = BrmGql.useGetResourcePersonsQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.RESOURCES.key }
  );

  const missionPersonsQuery = BrmGql.useGetMissionPersonsQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.MISSIONS.key }
  );

  const dataTypesPersonQuery = BrmGql.useGetDataTypePersonsQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.DATATYPES.key }
  );

  const elementPersonsQuery = useQuery({
    ...config,
    queryKey: KeyFactory.elementKeys.persons(
      elementId,
      elementName,
      resourcePersonsQuery.data,
      missionPersonsQuery.data,
      dataTypesPersonQuery.data
    ),
    queryFn: () =>
      getElementPersons(
        elementId,
        elementName,
        resourcePersonsQuery.data,
        missionPersonsQuery.data,
        dataTypesPersonQuery.data
      ),
  });

  if (resourcePersonsQuery.isError) {
    return resourcePersonsQuery;
  }

  if (missionPersonsQuery.isError) {
    return missionPersonsQuery;
  }

  if (dataTypesPersonQuery.isError) {
    return dataTypesPersonQuery;
  }

  return elementPersonsQuery;
};
