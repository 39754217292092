import { useState, useRef, useEffect, Suspense, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { controlReportState } from "atoms/atoms-report";
// import { currentControlCatalogState } from "atoms/atoms-global-control";
import { userIdState, projectIdState } from "atoms/atoms-admin";
import PropTypes from "prop-types";
import styled from "styled-components";
// import TableButtonsDiv from "components/elements/DetailsTableButtonsDivStyled";
// import DetailsTable from "styles/DetailsTableDivStyled";
// import DetailsContainer from "styles/DetailsTableContainerStyled";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { handleNoteInTooltip, formattedTimeStamp /* , dloadJSON */ } from "utils/report-utils";
import { /* REPORT, REPORT_SUFFIX, */ CONTROL_DATA } from "constants/brm";
import { useModal } from "hooks";

import { LoadingSpinner /* , DownloadButton */ } from "components/elements";
import useSioService from "hooks/useSioService";

import { createColumnMappedNoEditRpt, createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
import * as S from "brm/styles/details-table.styles";

const CntrlRptTable = ({ selectedElement, tableTitle }) => {
  // Global state
  const [controlReport, setControlReport] = useRecoilState(controlReportState);
  // const currentControlCatalog = useRecoilValue(currentControlCatalogState);
  const userId = useRecoilValue(userIdState);
  const projectId = useRecoilValue(projectIdState);

  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  // Local State
  const socket = useRef(null);
  const [sio, isConnected] = useSioService(projectId);
  socket.current = sio;

  // Leave in row selector so user can still highlight rows in the report while viewing
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [inProgress, setInProgress] = useState(true); // default true because last progress displays are final
  const [generalError, setGeneralError] = useState(false);
  const [errMsg, setErrMsg] = useState(``);
  const [done1, setDone1] = useState(true);

  const analysisResults = useRef([]);
  const progMsg1 = useRef("");

  const columns = useMemo(
    () => [
      createColumnMappedNoEdit("controlId"),
      createColumnMappedNoEditRpt("controlName", handleNoteInTooltip),
      createColumnMappedNoEditRpt("targetName", handleNoteInTooltip),
      createColumnMappedNoEdit("targetType"),
    ],
    []
  );

  useEffect(() => {
    if (controlReport.summary) {
      const progMsgs = controlReport.summary.split(", ");
      [progMsg1.current] = progMsgs;
    }
  }, [controlReport.summary]);

  /**
   * Download report
   */
  /* const handleDownloadClick = () => {
    const fileName = selectedElement.name;
    const jsonData = {
      metaData: {
        reportType: REPORT.control,
        variant: fileName,
        baseline: selectedElement.baseline.name,
        catalog: currentControlCatalog.name,
        summary: controlReport.summary,
        reportGenerationDate: controlReport.timestamp,
        numOfRows: controlReport.count,
      },
      rows: controlReport.data,
    };
    dloadJSON(jsonData, `${fileName + REPORT_SUFFIX.control}.json`);
  }; */

  /**
   * Analysis Results Handlers
   *
   * Each element type receives its own _prog msg and the _done msg is global and sent only
   * once all of the _progs have been sent.
   */
  useEffect(() => {
    function doWork() {
      // Start the new report
      progMsg1.current = "Getting baseline and allocated controls...";
      setDone1(false);
      socket.current.sendReq(CONTROL_DATA, {
        userId,
        projectId,
        variant: selectedElement,
      });
      socket.current.waitProg(CONTROL_DATA, (data) => {
        // console.log(`waitProg ${CONTROL_DATA} - data: `, data);
        progMsg1.current = data.msg;
      });
      socket.current.waitResp(CONTROL_DATA, (data) => {
        // console.log(`waitResp ${CONTROL_DATA} - data: `, data);

        // Update the table with the latest results received from backend analysis
        analysisResults.current = data.msg;

        progMsg1.current = "";
        setDone1(true);
        setInProgress(false);
        // Capture the final report
        setControlReport({
          data: analysisResults.current.map((each) => ({
            ...each,
            targetType: each.targetName,
            targetName: each.targetType,
          })),
          summary: "",
          timestamp: formattedTimeStamp(),
          count: analysisResults.current.length.toLocaleString(),
        });
      });
      socket.current.waitErr(CONTROL_DATA, (data) => {
        // console.log(`waitErr ${CONTROL_DATA} - data.msg: `, data.msg);

        // For now, indicate error message in first item message area
        progMsg1.current = data.msg;
        setGeneralError(true);
      });
    }
    if (isConnected) {
      setGeneralError(false);
      doWork();
    }
  }, [isConnected, setGeneralError, setErrMsg, setControlReport, userId, projectId, selectedElement]);

  return (
    <S.DetailsContainer id="CntrlRptTable_detailsPanel">
      <S.DetailButtonTopCorner>
        <LeftMsgArea>
          <>
            {inProgress ? (
              <ProgressArea>
                {generalError ? (
                  <Prog>
                    <ProgMsg>{errMsg}</ProgMsg>
                    <DoneMsg />
                  </Prog>
                ) : (
                  <>
                    <Prog>
                      <ProgMsg>{progMsg1.current}</ProgMsg>
                      <DoneMsg>{done1 ? "" : <LoadingSpinner message="" />}</DoneMsg>
                    </Prog>
                  </>
                )}
              </ProgressArea>
            ) : (
              <>
                {generalError && (
                  <Prog>
                    <ProgMsg>{errMsg}</ProgMsg>
                    <DoneMsg />
                  </Prog>
                )}
              </>
            )}
          </>
          <Updated>Last updated: {controlReport.timestamp}</Updated>
        </LeftMsgArea>
        <ExportButton onClick={() => toggle()} disableExport={disableExport} />
      </S.DetailButtonTopCorner>
      <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />

      <S.ActionContainer>
        <S.DetailsTableContainer>
          <Suspense fallback="Loading...">
            <BrmDetailTable
              data={controlReport.data}
              columns={columns}
              setSelectedRowIndex={setSelectedRowIndex}
              selectedRowIndex={selectedRowIndex}
              showRowSelect={false}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </Suspense>
        </S.DetailsTableContainer>
        {/* <S.DetailsTableButtonsContainer>
          // TODO: Merge the DownloadButton's functionality (JSON format) into the ExportButton. BRMCD-7922
          <DownloadButton onClick={handleDownloadClick} />
        </S.DetailsTableButtonsContainer> */}
      </S.ActionContainer>
    </S.DetailsContainer>
  );
};

CntrlRptTable.propTypes = {
  selectedElement: PropTypes.object,
  tableTitle: PropTypes.string,
};

const LeftMsgArea = styled.div`
  display: grid;
`;

const ProgressArea = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 1em;
`;

const Prog = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.1em;
`;

const ProgMsg = styled.div`
  justify-self: end;
`;

const DoneMsg = styled.div`
  justify-self: start;
`;

const Updated = styled.div`
  justify-self: end;
`;

export default CntrlRptTable;
