import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./installation-query-key-factory";

type InstallationProperty = "projectSettings" | "sapAvailability";

/**
 * Gets the Installation's property
 */
export const getInstallationProperty = ({
  installationId,
  property,
}: {
  installationId: string | null;
  property: InstallationProperty;
}) => {
  if (installationId === null) {
    return Promise.reject(new Error("Invalid InstallationId"));
  }
  switch (property) {
    case "sapAvailability":
      return Brm.installationApi.getsapstatusInstallation(installationId);
    case "projectSettings":
      return Brm.installationApi.getInstallationProjectSetting(installationId);
    default:
      return Promise.reject(new Error("Unsupported property"));
  }
};

type FetcherFunction = typeof getInstallationProperty;
interface IInstallationsProps {
  installationId: string | null;
  property: InstallationProperty;
  options?: QueryOption<FetcherFunction>;
}

/**
 * Custom hook for getting Installations projects
 *
 * @param {object} options - react-query options
 */
export const useGetInstallationProperty = ({ installationId, property, options = {} }: IInstallationsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.installationKeys.property(installationId, property),
    queryFn: () => getInstallationProperty({ installationId, property }),
  });
};
