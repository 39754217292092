import * as React from "react";
import { type Orientation } from "./types";

export interface IStepperContextType {
  orientation?: Orientation;
  activeStep?: number;
}

export const StepperContext = React.createContext<IStepperContextType | null>(null);

export function useStepperContext(): IStepperContextType {
  const context = React.useContext(StepperContext);
  if (!context) {
    throw new Error("useStepperContext must be used within StepperContext.provider");
  }
  return context;
}
