import { useState } from "react";
// Styles
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

// Local
import AttackMechanismTable from "./tables/AttackMechanismTable";

function AttackMechanism() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const attackMechanismHeader = <Title id="AttackMechanism_title">Attack Mechanisms</Title>;

  const attackMechanismMain = (
    <AttackMechanismTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
  );

  return <ContentLayout pageId="AttackMechanism_page" header={attackMechanismHeader} main={attackMechanismMain} />;
}

export default AttackMechanism;
