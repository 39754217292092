import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * Add a mission capability
 * @param {string} projectId uuid of the project
 * @param {capabilityId} capabilityId a MissionCreateDto object
 * @returns
 */
export const addMissionCapability = ({ missionId, capabilityId }) => {
  return Brm.missionApi.addMissionCapability(missionId, { body: capabilityId });
};

/**
 * Custom hook to a add mission capability node
 *
 * @param {object} config - react-query configuration object
 * @returns react-query-data for addMissionCapability
 */
export const useAddMissionCapability = ({ config = {} } = {}) => {
  return ReactQuery.useMutation({
    ...config,
    mutationFn: addMissionCapability,
  });
};
