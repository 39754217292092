import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./dataflow-query-key-factory";

interface IDeleteDataFlow {
  dataFlowId: string;
}

/**
 * Deletes a dataflow with the given uuid
 * @param {string} dataflowId uuid of the node
 * @returns
 */
export const deleteDataFlow = ({ dataFlowId }: IDeleteDataFlow) => {
  return Brm.dataflowApi.deleteDataFlow(dataFlowId);
};

export interface IUseDeleteDataFlow {
  options?: MutationOption<typeof deleteDataFlow>;
}

/**
 * Custom hook to delete an existing dataFlow
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteDataFlow = ({ options }: IUseDeleteDataFlow = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.dataflowKeys.all);
    },
    ...options,
    mutationFn: deleteDataFlow,
  });
};
