import { COMMON } from "constants/brm";
import PropTypes from "prop-types";
import ButtonAsLink from "components/modals/ButtonAsLinkStyled";

const AttackMechanismNotesModal = ({ mechanism, setModalIsOpen, setMechanism }) => {
  return (
    <p id="AttackMechanismModal">
      {mechanism ? (
        <ButtonAsLink
          type="button"
          onClick={() => {
            setModalIsOpen(true);
            setMechanism(mechanism);
          }}
        >
          {mechanism}
        </ButtonAsLink>
      ) : (
        COMMON.hyphen
      )}
    </p>
  );
};

AttackMechanismNotesModal.propTypes = {
  mechanism: PropTypes.string.isRequired,
  setModalIsOpen: PropTypes.func.isRequired,
  setMechanism: PropTypes.func.isRequired,
};

export default AttackMechanismNotesModal;
