import { queryClient } from "libs/react-query";
import PropTypes from "prop-types";
import toast from "react-hot-toast";

// Components
import systemModals from "components/modals/Modals";
// State
import { variantIdState } from "atoms/atoms-component";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
// import { riskUpdatedState } from "atoms/atoms-risk";

import * as BrmGql from "generated/graphql";
import { RiskApi } from "features/brm";
// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import * as S from "../styles/modal-styles";

export const ClearLikelihoodLevelModal = ({ modalIsOpen, setModalIsOpen, selectedRows }) => {
  const variantId = useRecoilValue(variantIdState);
  const projectId = useRecoilValue(projectIdState);
  // const riskUpdated = useRecoilValue(riskUpdatedState);
  const { mutate: setAttackLikelihood } = BrmGql.useSetAttackLikelihoodMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(RiskApi.attackKeys.likelihoodCalibrationAttacks(projectId, variantId));
    },
    onError: () => {
      toast.error("Error ocurred upon clearing calibrated likelihood", {
        duration: 4000,
      });
      setModalIsOpen(false);
    },
  });

  async function setLevelClick() {
    const attackIds = selectedRows.map((each) => each.id);
    setAttackLikelihood(
      { ids: attackIds, project: projectId, variant: variantId, calibratedStringValue: "unset" },
      {
        onSuccess: () => {
          toast.success("Calibrated likelihood Level cleared successfully", { duration: 4000 });
          setModalIsOpen(false);
        },
        enabled: !!projectId && !!variantId && !!attackIds,
      }
    );
  }

  const handleCancel = () => {
    setModalIsOpen(false);
  };

  const body = (
    <>
      <S.BodyDiv> Are you sure you want to clear the calibrated Likelihood levels of the selected Attack(s)?</S.BodyDiv>

      <DialogButtonDivStyled>
        <DialogButtonStyled id="LikelihoodLevelModal_cancelButton" onClick={handleCancel}>
          Cancel
        </DialogButtonStyled>
        <DialogButtonStyled id="LikelihoodLevelModal_confirmButton" onClick={() => setLevelClick()}>
          Confirm
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </>
  );

  return (
    <div id="ClearLikelihoodLevelModal_modal">
      {systemModals.customModal(
        "ClearLikelihoodLevelModal_modal",
        modalIsOpen,
        setModalIsOpen,
        body,
        "Clear Caliberated Likelihood Level"
      )}
    </div>
  );
};

ClearLikelihoodLevelModal.propTypes = {
  modalIsOpen: PropTypes.any,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
  setModalIsOpen: PropTypes.func,
};
