import { useContext } from "react";
import * as Recoil from "recoil";
import { CONTEXT_SIDEBAR_CONTENT, contextSidebarContentState, DrawerContentType, useProjectStatus } from "features/brm";

// Images tmp
import { ReactComponent as AddNodesIcon } from "assets/icons/studio91/attack/add-nodes.svg";

import { ThemeContext } from "styled-components";
// Components
import { BasicButton as Button } from "components/elements";

// Constants

export const RightSidebarAttackGraphButtons = ({ onClick }: { onClick: (type: DrawerContentType) => void }) => {
  const { isReadable } = useProjectStatus();
  const theme = useContext(ThemeContext);
  const setContentType = Recoil.useSetRecoilState(contextSidebarContentState);

  const {
    colors: { buttonPrimary: buttonFill },
    sizes: { standaloneIconWidth: iconWidth, standaloneIconWidth: iconHeight },
  } = theme;

  const handleButtonClick = (contentType: DrawerContentType) => {
    onClick(contentType);
    setContentType(contentType);
  };

  return (
    <>
      <Button onClick={() => handleButtonClick(CONTEXT_SIDEBAR_CONTENT.attackGraphAddNodes)} disabled={!isReadable}>
        <span title="Drag and Drop from List">
          <AddNodesIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
        </span>
      </Button>
    </>
  );
};
