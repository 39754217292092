import * as YFiles from "yfiles";
import { detectiOSVersion, detectSafariVersion } from "../../utils/Workarounds";

export const getCenterInPage = (element: HTMLElement): { x: number; y: number } => {
  let left = element.clientWidth / 2.0;
  let top = element.clientHeight / 2.0;
  while (element.offsetParent) {
    left += element.offsetLeft;
    top += element.offsetTop;
    // eslint-disable-next-line no-param-reassign
    element = element.offsetParent as HTMLElement;
  }
  return { x: left, y: top };
};

export const addOpeningEventListeners = (
  graphComponent: YFiles.GraphComponent,
  openingCallback: ({ x, y }: { x: number; y: number }) => void
): void => {
  const componentDiv = graphComponent.div;
  const contextMenuListener = (evt: MouseEvent) => {
    evt.preventDefault();
    const me = evt;
    if ((evt as any).mozInputSource === 1 && me.button === 0) {
      // This event was triggered by the context menu key in Firefox.
      // Thus, the coordinates of the event point to the lower left corner of the element and should be corrected.
      openingCallback(getCenterInPage(componentDiv));
    } else if (me.pageX === 0 && me.pageY === 0) {
      // Most likely, this event was triggered by the context menu key in IE.
      // Thus, the coordinates are meaningless and should be corrected.
      openingCallback(getCenterInPage(componentDiv));
    } else {
      openingCallback({ x: me.pageX, y: me.pageY });
    }
  };
  // Listen for the contextmenu event
  // Note: On Linux based systems (e.g. Ubuntu), the contextmenu event is fired on mouse down
  // which triggers the ContextMenuInputMode before the ClickInputMode. Therefore handling the
  // event, will prevent the ItemRightClicked event from firing.
  // For more information, see https://docs.yworks.com/yfileshtml/#/kb/article/780/
  componentDiv.addEventListener("contextmenu", contextMenuListener, false);

  if (detectSafariVersion() > 0 || detectiOSVersion() > 0) {
    // Additionally add a long press listener especially for iOS, since it does not fire the contextmenu event.
    let contextMenuTimer: ReturnType<typeof setTimeout> | undefined;
    graphComponent.addTouchDownListener((_sender, args) => {
      contextMenuTimer = setTimeout(() => {
        openingCallback(graphComponent.toPageFromView(graphComponent.toViewCoordinates(args.location)));
      }, 500);
    });
    graphComponent.addTouchUpListener(() => {
      clearTimeout(contextMenuTimer!);
    });
  }

  // Listen to the context menu key to make it work in Chrome
  componentDiv.addEventListener("keyup", (evt) => {
    if (evt.keyCode === 93) {
      evt.preventDefault();
      openingCallback(getCenterInPage(componentDiv));
    }
  });
};
