import { useEffect, useState, useMemo } from "react";

import errorUtils from "utils/error-utils";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import { boundaryApi } from "services/brm/access-service";
import PropTypes from "prop-types";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";

import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";
import {
  createColumnBooleanNoEditMapped,
  createColumnCategoryMapped,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";

const BoundaryTable = ({ setSelectedRows, refresh, setRefresh }) => {
  const [boundaryData, setBoundaryData] = useState([]);
  const projectId = useRecoilValue(projectIdState);

  const columns = useMemo(
    () => [
      createColumnMappedNoEdit("name"),
      createColumnCategoryMapped("category"),
      createColumnCategoryMapped("structcat"),
      createColumnBooleanNoEditMapped("isBoundary"),
      createColumnBooleanNoEditMapped("isInternal"),
    ],
    []
  );

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    async function getData() {
      setRefresh(false);

      try {
        const { data, response } = await boundaryApi.findBoundaryNodesWithHttpInfo(projectId);
        if (response.status !== 200 && response.status !== 201) {
          errorUtils.checkErrorStatus(response.status, response.statusText);
        }
        setBoundaryData(data);
      } catch (error) {
        console.error("Boundary GET: ", error);
      }
    }
    if (refresh) {
      getData();
    }
  }, [refresh, setRefresh, projectId, setBoundaryData]);

  return (
    <>
      <CustomTable>
        <BrmMainTable
          data={boundaryData}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "BoundaryTable_table" }}
          showRowSelect={false}
          elementName={TYPE.node}
        />
      </CustomTable>
    </>
  );
};

BoundaryTable.propTypes = {
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func,
  setSelectedRows: PropTypes.func.isRequired,
};

export default BoundaryTable;
