import styled from "styled-components";

export const chevronStyle = {
  height: "13px",
  width: "13px",
  marginRight: "5px",
  marginBottom: "2px",
};

export const Dropdown = styled.div`
  display: block;
  position: absolute;
  line-height: 21px;
  text-align: left;
  z-index: 999;
  left: 0;
  min-width: 100px;
  width: max-content;
  background: #f2f2f2;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
`;

export const Button = styled.button`
  color: #000000;
  text-decoration: none;
  text-align: left;
  outline: none;
  border: none;
  background: none;
  pointer-events: auto;
  max-height: 20px;
`;

export const MenuItem = styled.p`
  padding: 8px 15px;
  color: #000000;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fonts.defaultSize};
`;

export const MenuItemDisabled = styled.p`
  padding: 8px 15px;
  color: #aaaaaa;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fonts.defaultSize};
`;

export const Container = styled.div`
  background: white;

  & :hover {
    background: #deebff;
    color: black;
  }
`;

export const DisabledContainer = styled.div`
  background: white;
  cursor: default;
`;
