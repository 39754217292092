import styled from "styled-components/macro";

export const Root = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 20px;
  min-height: 40px;
  box-sizing: border-box;
  margin: 0px;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
`;

export const AvatarInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  line-height: 1.22;
  text-align: left;
`;
export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

export const AvatarAndNameContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const NameAndEmailContainer = styled.div``;

export const NameContainer = styled.div`
  line-height: 20px;
`;

export const EmailContainer = styled.small`
  font-size: 0.7857142857142857em;
  font-style: inherit;
  line-height: 1.45px;
  color: #6b778c;
`;
