/* eslint-disable no-redeclare, @typescript-eslint/no-unused-vars */
import * as React from "react";
import * as Log from "loglevel";
import { useForm } from "react-hook-form";
import { ActionButton, ErrorSpan } from "components/elements";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useProject } from "features/brm";
import Spinner from "react-bootstrap/Spinner";
import { FaCheckCircle } from "react-icons/fa";
import { CgUnavailable } from "react-icons/cg";
import { MdError } from "react-icons/md";
import { getErrorMessage } from "utils/error-message-utils";
import ListGroup from "react-bootstrap/ListGroup";
import { useCancelContext } from "features/admin/stores/CancelContext/CancelContext";
import { IConfigureData, IStepInfo, StepStatus, ConfigureStep } from "./types";
import * as CommonStyle from "./styles";
import { useConfigureProjectContext } from "./ConfigureProjectContext";
import { useConfigureProject } from "./useConfigureProject";
// import { useAdminOrganization } from "./useAdminOranization";
const log = Log.getLogger("ProjectConfigureLogger");

const LABEL_COL_SPAN = 4;
const INPUT_COL_SPAN = 8;

interface IConfigureProject {
  foo: string;
}

interface IControlCatalog {
  id: string;
  name: string;
}
type StepMap = Map<ConfigureStep, IStepInfo>;

function stepInfoFactory(step: ConfigureStep, label: string, status = StepStatus.NotStarted, isVisible = true) {
  return [
    step,
    {
      id: step,
      label,
      status,
      isVisible,
    },
  ] as [ConfigureStep, IStepInfo];
}

function createStepMap(): StepMap {
  const stepMap = new Map<ConfigureStep, IStepInfo>([
    stepInfoFactory(ConfigureStep.SetProjectKnowledgebase, "Setting Project Knowledgebase"),
    stepInfoFactory(ConfigureStep.CloneControlCatalog, "Cloning Control Catalog", StepStatus.NotStarted, false),
    stepInfoFactory(ConfigureStep.SetProjectCatalog, "Set Project Control Catalog"),
    stepInfoFactory(ConfigureStep.SetVariantCatalog, "Set Variant Control Catalog"),
    stepInfoFactory(ConfigureStep.CreateBaseline, "Creating Baseline in Control Catalog"),
    stepInfoFactory(ConfigureStep.ImportBaseline, "Import Baseline"),
    stepInfoFactory(ConfigureStep.SetProjectBaseline, "Setting Project Baseline"),
    stepInfoFactory(ConfigureStep.SetVariantBaseline, "Setting Variant Baseline"),
    stepInfoFactory(ConfigureStep.ImportModel, "Importing System Architecture Model"),
    stepInfoFactory(ConfigureStep.ImportSctm, "Importing SCTM"),
    stepInfoFactory(ConfigureStep.ImportFindings, "Import Vulnerability Findings"),
    stepInfoFactory(ConfigureStep.CalculateInitialRisk, "Calculating Initial Risk"),
    stepInfoFactory(ConfigureStep.CalculateVariantRisk, "Calculating Variant Risk"),
  ]);
  return stepMap;
}

const skipStep = (stepMap: StepMap, id: ConfigureStep) => {
  const step = stepMap.get(id);
  if (step) {
    step.status = StepStatus.Skipped;
  }
};

const initSteps = (data: IConfigureData) => {
  const stepMap = createStepMap();
  if (!data.sctmFile) {
    skipStep(stepMap, ConfigureStep.ImportBaseline);
  }

  if (!data.modelFiles) {
    skipStep(stepMap, ConfigureStep.ImportModel);
    skipStep(stepMap, ConfigureStep.CalculateInitialRisk);
    skipStep(stepMap, ConfigureStep.ImportBaseline);
    skipStep(stepMap, ConfigureStep.ImportFindings);
    skipStep(stepMap, ConfigureStep.CalculateVariantRisk);
  }
  return stepMap;
};

export const ConfigureProjectStepForm = () => {
  const { setIsCancelRequested } = useCancelContext();
  const [projectState, { setCurrentProject }] = useProject();
  const { handleSubmit } = useForm<IConfigureProject>();
  const { prev, close, configureData, setConfigureData } = useConfigureProjectContext();
  const [steps, setSteps] = React.useState(() => initSteps(configureData));
  // const [isConfiguring, setIsConfiguring] = React.useState(false);
  const [isConfigureComplete, setIsConfigureComplete] = React.useState(false);
  // const [isCancelModalVisible, setIsCancelModalVisible] = React.useState(false);
  // const { organizationId } = useAdminOrganization();

  const updateSteps = (updatedStep: IStepInfo) => {
    // console.log("updating step:", updatedStep);
    setSteps(new Map(steps.set(updatedStep.id, updatedStep)));
  };

  // log.debug("ConfigureData", configureData);

  // const { data: defaultControlCatalog } = GlobalControlApi.useControlCatalogs({
  //   options: {
  //     select: (data) => data.find((cc: IControlCatalog) => cc.name === DEFAULT_CATALOG_NAME.name_5),
  //   },
  // });
  // const { data: orgDefaultCatalog } = AdminApi.useGetOrganizationProperty({
  //   organizationId,
  //   property: "defaultControlCatalog",
  //   options: { enabled: !!organizationId },
  // });

  const { configureProject } = useConfigureProject({
    isCancelled: configureData.isConfigureCancelled,
    onStepUpdate: updateSteps,
    onComplete: () => {
      setIsConfigureComplete(true);
      setConfigureData({ ...configureData, isComplete: true });
      setCurrentProject(null);
    },
    onError: () => {
      setIsConfigureComplete(true);
      setCurrentProject(null);
    },
    onCancelled: () => {
      close();
      setCurrentProject(null);
    },
  });

  // const onCancel = () => {
  //   if (isConfiguring) {
  //     close();
  //   } else {
  //     close();
  //   }
  // };

  const onSubmit = React.useCallback(() => {
    setConfigureData({ ...configureData, isConfigureInProgress: true });
    configureProject(configureData, steps);
    // console.log("submitting...");
    // next();
  }, [configureData, configureProject, setConfigureData, steps]);

  const renderStepIconStatus = (step: IStepInfo) => {
    const iconSize = 18;
    switch (step.status) {
      // case "Not Started":
      //   return <CgUnavailable size={iconSize} />;
      case "In Progress":
        return <Spinner animation="border" role="status" size="sm" />;
      case "Complete":
        return <FaCheckCircle color="green" size={iconSize} />;
      // case "Aborted":
      //   return <CgUnavailable size={iconSize}/>;
      case "Error":
        return <MdError size={iconSize} fill="#ED4337" />;
      // case "Skipped":
      //   return <CgUnavailable size={iconSize}/>;
      default:
        return <CgUnavailable size={iconSize} />;
    }
  };

  const stepProgress = (
    <CommonStyle.StepDiv>
      <CommonStyle.Col>
        <h5 style={{ paddingBottom: "5px" }}>Configuring Project: {projectState?.name}</h5>
        {configureData.isConfigureInProgress && !isConfigureComplete ? (
          <span style={{ paddingBottom: "5px" }}>Please wait this may take a few minutes...</span>
        ) : null}
        <CommonStyle.FileListGroupContainer>
          <ListGroup>
            {Array.from(steps.values())
              .filter((s) => s.isVisible)
              .map((step: IStepInfo) => {
                return (
                  <ListGroup.Item key={step.id} style={{ padding: "5px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <span style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        {renderStepIconStatus(step)} {step.label}{" "}
                      </span>
                      <span>{step.status.toUpperCase()}</span>
                    </div>
                    <ErrorSpan>{step.error ? getErrorMessage(step.error) : null}</ErrorSpan>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </CommonStyle.FileListGroupContainer>
      </CommonStyle.Col>
      <CommonStyle.ButtonContainer>
        <ActionButton onClick={() => setIsCancelRequested(true)} disabled={isConfigureComplete}>
          Cancel
        </ActionButton>
        <ActionButton onClick={close} disabled={!isConfigureComplete}>
          Close
        </ActionButton>
      </CommonStyle.ButtonContainer>
    </CommonStyle.StepDiv>
  );

  // console.log("configureData", configureData);

  const ccAndBaselineText = (() => {
    if (configureData.isBaselineIncludedInSctm && configureData.sctmFile) {
      return "Baseline is provided by SCTM file";
    }
    if (configureData.baselineName) {
      return `${configureData.catalogName}: ${configureData.baselineName}`;
    }
    return "N/A";
  })();

  const sctmFile = configureData.sctmFile ? configureData.sctmFile.item(0) : undefined;

  // console.log("configureData", configureData);
  // console.log("sctmFile", sctmFile);

  const configReview = (
    <CommonStyle.StepForm onSubmit={handleSubmit(onSubmit)} id="createProject">
      <CommonStyle.ScrollingDiv>
        <legend>Review Project Configuration Summary</legend>
        <div style={{ paddingBottom: "15px" }}>
          If the information below is accurate, click the &apos;Configure&apos; button to finish setting up your
          project.
        </div>
        <CommonStyle.ScrollingDiv>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={LABEL_COL_SPAN}>
              Project
            </Form.Label>
            <Col sm={INPUT_COL_SPAN}>
              <Form.Control type="text" readOnly defaultValue={projectState?.name} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={LABEL_COL_SPAN}>
              Knowledgebase
            </Form.Label>
            <Col sm={INPUT_COL_SPAN}>
              <Form.Control type="text" readOnly defaultValue={configureData.knowledgebase?.label} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={LABEL_COL_SPAN}>
              System Architecture Model
            </Form.Label>
            <Col sm={INPUT_COL_SPAN}>
              {/* <Form.Control type="text" readOnly disabled defaultValue={modelFileNames} /> */}
              <ListGroup>
                {configureData.modelFiles ? (
                  [...configureData.modelFiles].map((mf, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ListGroup.Item key={`${mf.name}_${index}`}>{mf.name}</ListGroup.Item>
                  ))
                ) : (
                  <ListGroup.Item>N/A</ListGroup.Item>
                )}
              </ListGroup>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={LABEL_COL_SPAN}>
              SCTM
            </Form.Label>
            <Col sm={INPUT_COL_SPAN}>
              <Form.Control type="text" readOnly defaultValue={sctmFile?.name || "N/A"} />
            </Col>
          </Form.Group>
          {/* <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={LABEL_COL_SPAN}>
              Non-Compliant Controls
            </Form.Label>
            <Col sm={INPUT_COL_SPAN}>
              <Form.Control
                type="text"
                readOnly
                defaultValue={configureData.excludeNonCompliantControls ? "Exclude" : "Include"}
              />
            </Col>
          </Form.Group> */}
          {/* <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={LABEL_COL_SPAN}>
              Baseline Mode
            </Form.Label>
            <Col sm={INPUT_COL_SPAN}>
              <Form.Control
                type="text"
                readOnly
                defaultValue={configureData.baselineMode === "ceiling" ? "Ceiling" : "Floor"}
              />
            </Col>
          </Form.Group> */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={LABEL_COL_SPAN}>
              Control Catalog and Baseline
            </Form.Label>
            <Col sm={INPUT_COL_SPAN}>
              <Form.Control type="text" readOnly defaultValue={ccAndBaselineText} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column lg={LABEL_COL_SPAN}>
              Vulnerability Findings
            </Form.Label>
            <Col sm={INPUT_COL_SPAN}>
              <ListGroup>
                {configureData.findingsFiles ? (
                  [...configureData.findingsFiles].map((vf, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ListGroup.Item key={`${vf.name}_${index}`}>{vf.name}</ListGroup.Item>
                  ))
                ) : (
                  <ListGroup.Item>N/A</ListGroup.Item>
                )}
              </ListGroup>
            </Col>
          </Form.Group>
        </CommonStyle.ScrollingDiv>
      </CommonStyle.ScrollingDiv>
      <CommonStyle.ButtonContainer>
        {configureData.isConfigureInProgress ? null : (
          <>
            <ActionButton onClick={() => setIsCancelRequested(true)}>Cancel</ActionButton>
            <ActionButton onClick={prev}>Back</ActionButton>
            <ActionButton type="submit">Configure</ActionButton>
          </>
        )}
      </CommonStyle.ButtonContainer>
    </CommonStyle.StepForm>
  );

  return <>{configureData.isConfigureInProgress ? stepProgress : configReview}</>;
};
