import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import * as Brm from "services/brm";

interface IExportVariantRisk {
  variantId: string;
}

const exportVariantRisk = ({ variantId }: IExportVariantRisk) => {
  return Brm.variantApi.exportRiskVariant(variantId);
};

interface IUseExportVariantRisk {
  options?: MutationOption<typeof exportVariantRisk>;
}

export const useExportVariantRisk = ({ options }: IUseExportVariantRisk = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: exportVariantRisk,
  });
};
