/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import Form from "react-bootstrap/Form";
import { ErrorSpan, LoadingSpinner } from "components/elements";
import { GlobalControlApi, useProject, AdminApi } from "features/brm";
// import Button from "react-bootstrap/Button";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Popover from "react-bootstrap/Popover";
// import { MdInfoOutline } from "react-icons/md";
import { COMMON } from "constants/brm";
import { useConfigureProjectContext } from "./ConfigureProjectContext";
import { StandardStepButtons } from "./StandardStepButtons";
import * as CommonStyle from "./styles";
import { FileList } from "./FileList";
import { UiOption } from "./types";

interface INamedElement {
  id: string;
  name: string;
}

interface IOption {
  label: string;
  value: string;
}

interface ISetting {
  id: string;
  name: string;
  value: string;
}

interface ISecurityArchitecture {
  uiOption: UiOption;
  sctmFile?: FileList;
  isBaselineIncluded: boolean;
  controlCatalogOption?: IOption;
  baseline?: IOption;
  // excludeNonCompliantControls: boolean;
  baselineMode?: IOption;
  complianceMode?: IOption;
  ceilingBaseline?: IOption;
}

const baselineModeOptions: IOption[] = [
  { label: "Floor", value: "floor" },
  { label: "Ceiling", value: "ceiling" },
];

const complianceModeOptions: IOption[] = [
  { label: "Include", value: "include" },
  { label: "Exclude", value: "exclude" },
];

export const SecurityArchitectureStepForm = () => {
  const [currentProject] = useProject();
  const { data: projectSettings } = AdminApi.useGetProjectProperty({
    projectId: currentProject?.id || "",
    property: "settings",
  });

  const compliantControlModeSetting: ISetting = projectSettings?.find((ps: any) => ps.name === "compliantControlMode");
  const baselineModeSetting: ISetting = projectSettings?.find((ps: any) => ps.name === "baselineMode");

  // console.log("baselineModeSetting", baselineModeSetting);
  // console.log("compliantControlModeSetting", compliantControlModeSetting);

  const sctmFileRef = React.useRef<HTMLInputElement>();
  const { next, configureData, setConfigureData } = useConfigureProjectContext();

  // console.log("configureData", configureData);

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm<ISecurityArchitecture>({
    defaultValues: {
      uiOption: configureData.uiSecurityOption ? configureData.uiSecurityOption : "uploadSctm",
      isBaselineIncluded: true,
      // excludeNonCompliantControls: compliantControlModeSetting?.value || "exclude",
      sctmFile: configureData.sctmFile,
      controlCatalogOption: configureData.catalog
        ? { label: configureData.catalogName, value: configureData.catalog }
        : undefined,
      baseline: configureData.baseline
        ? { label: configureData.baselineName, value: configureData.baseline }
        : undefined,
      baselineMode: configureData.baselineMode
        ? { label: "baselineMode", value: configureData.baselineMode }
        : { label: "baselineMode", value: undefined },
      ceilingBaseline: configureData.ceilingBaseline ? configureData.ceilingBaseline : undefined,
      complianceMode:
        configureData.excludeNonCompliantControls || configureData.excludeNonCompliantControls === undefined
          ? complianceModeOptions[1]
          : complianceModeOptions[0],
    },
  });

  React.useEffect(() => {
    /**
     * Some settings require async calls to figure out proper defaults so we use this effect
     * to reset the defaults.
     */
    const setDefaults = () => {
      let controlMode;
      if (configureData.excludeNonCompliantControls === undefined) {
        controlMode =
          compliantControlModeSetting.value === "exclude" ? complianceModeOptions[1] : complianceModeOptions[0];
      } else if (configureData.excludeNonCompliantControls) {
        controlMode = configureData.excludeNonCompliantControls ? complianceModeOptions[1] : complianceModeOptions[0];
      }

      let baselineM;
      if (configureData.baselineMode === undefined) {
        baselineM = baselineModeSetting.value === "floor" ? baselineModeOptions[0] : baselineModeOptions[1];
      } else if (configureData.baselineMode) {
        baselineM = configureData.baselineMode === "floor" ? baselineModeOptions[0] : baselineModeOptions[1];
      }

      const defaults = {
        uiOption: configureData.uiSecurityOption ? configureData.uiSecurityOption : "uploadSctm",
        isBaselineIncluded: true,
        excludeNonCompliantControls: compliantControlModeSetting?.value === "exclude",
        sctmFile: configureData.sctmFile,
        controlCatalogOption: configureData.catalog
          ? { label: configureData.catalogName, value: configureData.catalog }
          : undefined,
        baseline: configureData.baseline
          ? { label: configureData.baselineName, value: configureData.baseline }
          : undefined,
        baselineMode: baselineM,
        ceilingBaseline: configureData.ceilingBaseline ? configureData.ceilingBaseline : undefined,
        complianceMode: controlMode,
      };
      reset(defaults);
    };
    if (projectSettings) {
      setDefaults();
    }
  }, [reset, projectSettings, configureData, compliantControlModeSetting, baselineModeSetting]);

  const { data: controlCatalogOptions, isError: isCntrlCatalogsError } = GlobalControlApi.useControlCatalogs({
    options: {
      select: (data) => data.map((d: INamedElement) => ({ value: d.id, label: d.name })),
    },
  });

  const controlCatalogOption = watch("controlCatalogOption");
  const isBaselineIncluded = watch("isBaselineIncluded");
  const baseline = watch("baseline");
  const sctmFile = watch("sctmFile");
  const uiOption = watch("uiOption");

  const catalogId = controlCatalogOption?.value || "";
  const { data: baselineOption, isError: isBaselinesError } = GlobalControlApi.useCatalogBaselines({
    catalogId,
    options: {
      enabled: catalogId !== "",
      select: (data) => data.map((d: INamedElement) => ({ value: d.id, label: d.name })),
    },
  });

  const nistRev5Id = COMMON.defaultUuid;

  const { data: ceilingBaselineOptions } = GlobalControlApi.useCatalogBaselines({
    catalogId: nistRev5Id,
    options: {
      enabled: nistRev5Id !== "",
      select: (data) => data.map((d: INamedElement) => ({ value: d.id, label: d.name })),
    },
  });

  const setConfigureDataHook = (data: ISecurityArchitecture) => {
    const selectedSctmFile = data.sctmFile?.length && data.uiOption === "uploadSctm" ? data.sctmFile : undefined;
    let selectedBaseline;
    let selectedCatalog;

    if ((data.uiOption === "uploadSctm" && data.sctmFile && data.isBaselineIncluded) || data.uiOption === "none") {
      selectedBaseline = undefined;
      selectedCatalog = undefined;
    } else if (
      data.uiOption === "existingBaseline" ||
      (data.uiOption === "uploadSctm" && data.sctmFile && !data.isBaselineIncluded)
    ) {
      selectedBaseline = data.baseline;
      selectedCatalog = data.controlCatalogOption;
    }

    const isIncluded = selectedSctmFile ? data.isBaselineIncluded : undefined;
    const baselineOptions = baselineOption && !isIncluded ? baselineOption : ceilingBaselineOptions;

    const defaultCeilingBaseline = baselineOptions.find((o: IOption) => o.label === "CNSSI High-High-High");

    setConfigureData({
      ...configureData,
      uiSecurityOption: data.uiOption,
      isBaselineIncludedInSctm: isIncluded,
      excludeNonCompliantControls: data.complianceMode?.value === "exclude",
      sctmFile: data.uiOption === "uploadSctm" ? selectedSctmFile : undefined,
      baseline: selectedBaseline?.value,
      baselineName: selectedBaseline?.label,
      catalog: selectedCatalog?.value,
      catalogName: selectedCatalog?.label,
      baselineMode: data.baselineMode?.value,
      // ceilingBaseline: data.ceilingBaseline,
      ceilingBaseline: defaultCeilingBaseline,
    });
  };

  const onSubmit = (data: ISecurityArchitecture) => {
    setConfigureDataHook(data);

    next();
  };

  const removeSelectedFile = (file: File) => {
    if (!sctmFile) {
      return;
    }
    const newList = [...sctmFile].filter((f) => f.name !== file.name);
    if (newList.length) {
      const list = new DataTransfer();
      newList.forEach((newFile) => list.items.add(newFile));
      setValue("sctmFile", list.files);
    } else {
      setValue("sctmFile", undefined);
      if (sctmFileRef.current) {
        sctmFileRef.current.value = "";
      }
    }
  };

  if (isBaselinesError || isCntrlCatalogsError) {
    return <>Error retreiving available catalogs and baselines</>;
  }

  const fileArray = sctmFile ? [...sctmFile] : [];
  const fileListLabel = `Currently selected Security Architecture (SCTM) file`;

  const isNextEnabled = () => {
    return (
      (uiOption === "uploadSctm" && isBaselineIncluded && sctmFile !== undefined) ||
      (uiOption === "uploadSctm" && !isBaselineIncluded && sctmFile !== undefined && baseline !== undefined) ||
      uiOption === "none" ||
      (uiOption === "existingBaseline" && baseline !== undefined)
    );
  };

  // const popover = (
  //   <Popover id="popover-basic">
  //     <Popover.Header as="h3">Security Architecture Hints</Popover.Header>
  //     <Popover.Body>
  //       <CommonStyle.Title>Control Compliance Mode </CommonStyle.Title>
  //       <div>
  //         by default, the projects control compliance is set to Exclude, which means that the security controls that are
  //         not set as compliant are not included in the control baseline. Selecting the Include option will set non
  //         compliant controls to be included in the control baseline.
  //       </div>
  //       <CommonStyle.Title>Baseline Mode </CommonStyle.Title>
  //       <div>
  //         by default, the projects baseline mode is set to Ceiling, which means the security controls baseline will have
  //         a maximum set of controls. Selecting the Floor option will set the security controls baseline to have a
  //         minimum set of controls.
  //       </div>
  //     </Popover.Body>
  //   </Popover>
  // );

  if (ceilingBaselineOptions) {
    return (
      <CommonStyle.StepForm onSubmit={handleSubmit(onSubmit)} id="selectSecurityArchitecture">
        <CommonStyle.SecurityArchFieldset>
          <legend>Security Architecture Configuration</legend>
          {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "5px" }}>
            <legend>Security Architecture Configuration</legend>
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
              <Button title="User Guided Mitigation Hints" size="sm" variant="link">
                <MdInfoOutline size="24" />
              </Button>
            </OverlayTrigger>
          </div> */}
          {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "5px" }}>
            <CommonStyle.OptionHeader>Select baseline mode:</CommonStyle.OptionHeader>
            <Controller
              name="baselineMode"
              control={control}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              render={({ field: { onChange, ref, ...rest } }) => (
                <ReactSelect
                  {...rest}
                  options={baselineModeOptions}
                  value={baselineModeOptions.find((o) => o.value === baselineMode?.value)}
                  onChange={(e) => {
                    if (e) {
                      // setValue("baselineMode", undefined);
                      onChange(e);
                    }
                  }}
                />
              )}
            />
          </div> */}

          <CommonStyle.OptionHeader>Select an option to configure your Security Architecture:</CommonStyle.OptionHeader>
          <div style={{ padding: "0px 0px 0px 15px" }}>
            <Form.Group className="mb-3" controlId="isFileProvided">
              <Form.Check
                type="radio"
                id="baseline-switch-upload"
                label="Upload a Security Control Traceability Matrix (SCTM) file"
                {...register("uiOption")}
                value="uploadSctm"
              />
              <Form.Check
                type="radio"
                id="baseline-switch-select"
                label="Select an available control catalog and baseline"
                {...register("uiOption")}
                value="existingBaseline"
              />
              <Form.Check
                type="radio"
                id="baseline-switch-default"
                label="Use default control catalog and baseline"
                {...register("uiOption")}
                value="none"
              />
            </Form.Group>
          </div>
          {uiOption === "uploadSctm" ? (
            <>
              <Form.Group className="mb-3" controlId="sctmFile">
                <CommonStyle.UploadLabelContainer>
                  <span>Select SCTM file for upload:</span>
                  <CommonStyle.UploadLabel htmlFor="sctmFile">Choose file</CommonStyle.UploadLabel>
                </CommonStyle.UploadLabelContainer>
                <Controller
                  name="sctmFile"
                  control={control}
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  render={({ field: { onChange, value, ref, ...rest } }) => (
                    <Form.Control
                      type="file"
                      accept=".xlsx"
                      style={{ display: "none" }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event?.target?.files !== null && event?.target?.files.length > 0) {
                          onChange(event.target.files);
                          handleSubmit(onSubmit);
                        }
                      }}
                      ref={(e: HTMLInputElement) => {
                        ref(e);
                        sctmFileRef.current = e;
                      }}
                      {...rest}
                    />
                  )}
                />
                {errors.sctmFile && errors.sctmFile.type === "validate" && (
                  <ErrorSpan>Unsupported SCTM file type</ErrorSpan>
                )}
                <FileList fileArray={fileArray} onRemoveClicked={removeSelectedFile} label={fileListLabel} />
              </Form.Group>
              {/* {baselineMode?.value === "floor" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "5px",
                  }}
                >
                  <CommonStyle.OptionHeader>Select ceiling baseline:</CommonStyle.OptionHeader>
                  <Controller
                    name="ceilingBaseline"
                    control={control}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    render={({ field: { onChange, ref, ...rest } }) => (
                      <ReactSelect
                        {...rest}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        options={ceilingBaselineOptions}
                        value={ceilingBaselineOptions.find((o: IOption) => o.value === ceilingBaseline?.value)}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: "300px",
                          }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        onChange={(e) => {
                          if (e) {
                            // setValue("baselineMode", undefined);
                            onChange(e);
                          }
                        }}
                      />
                    )}
                  />
                </div>
              ) : null} */}
              {/* 
            <Form.Group className="mb-3" controlId="sctmFile">
              <CommonStyle.UploadLabelContainer>
                <span>Select ceiling baseline file for upload:</span>
                <CommonStyle.UploadLabel htmlFor="sctmFile">Choose file</CommonStyle.UploadLabel>
              </CommonStyle.UploadLabelContainer>
              <Controller
                name="ceilingBaseline"
                control={control}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                render={({ field: { onChange, value, ref, ...rest } }) => (
                  <Form.Control
                    type="file"
                    accept=".xlsx"
                    style={{ display: "none" }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event?.target?.files !== null && event?.target?.files.length > 0) {
                        onChange(event.target.files);
                        handleSubmit(onSubmit);
                      }
                    }}
                    ref={(e: HTMLInputElement) => {
                      ref(e);
                      sctmFileRef.current = e;
                    }}
                    {...rest}
                  />
                )}
              />
              {errors.sctmFile && errors.sctmFile.type === "validate" && (
                <ErrorSpan>Unsupported baseline file type</ErrorSpan>
              )}
              <FileList fileArray={fileArray} onRemoveClicked={removeSelectedFile} label={celingListLabel} />
            </Form.Group> */}
              {/* <div
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "5px" }}
              >
                <CommonStyle.OptionHeader>Non-Compliant Controls:</CommonStyle.OptionHeader>
                <Controller
                  name="complianceMode"
                  control={control}
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  render={({ field: { onChange, ref, ...rest } }) => (
                    <ReactSelect
                      {...rest}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      options={complianceModeOptions}
                      value={complianceModeOptions.find((o) => o.value === complianceMode?.value)}
                      onChange={(e) => {
                        if (e) {
                          // setValue("baselineMode", undefined);
                          onChange(e);
                        }
                      }}
                    />
                  )}
                />
              </div> */}

              {/* <Form.Group className="mb-3" controlId="nonCompliantControlsSwitch">
              <Form.Check
                {...register("excludeNonCompliantControls")}
                type="switch"
                id="exclude-non-compliant-controls"
                label="Exclude non-compliant controls"
              />
            </Form.Group> */}
              <Form.Group className="mb-3" controlId="sctmBaselineSwitch">
                <Form.Check
                  {...register("isBaselineIncluded")}
                  type="switch"
                  id="baseline-switch"
                  label="Baseline included in SCTM file"
                />
              </Form.Group>
            </>
          ) : null}
          {(!isBaselineIncluded && uiOption === "uploadSctm") || uiOption === "existingBaseline" ? (
            <>
              <Form.Group className="mb-3" controlId="formControlCatalog">
                <Form.Label>Select Control Catalog</Form.Label>
                <Controller
                  name="controlCatalogOption"
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <ReactSelect
                      {...rest}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      options={controlCatalogOptions}
                      placeholder="Select Control Catalog..."
                      onChange={(e) => {
                        setValue("baseline", undefined);
                        onChange(e);
                      }}
                      isClearable
                    />
                  )}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBaseline">
                <Form.Label>Select Baseline</Form.Label>
                <Controller
                  name="baseline"
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      options={baselineOption}
                      placeholder="Select Baseline..."
                      isClearable
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  )}
                />
              </Form.Group>
            </>
          ) : null}
          {uiOption === "none" ? (
            <CommonStyle.OptionHeader>
              The project will be configured using the default control catalog and baseline. The baseline can be changed
              after the configuration wizard completes.
            </CommonStyle.OptionHeader>
          ) : null}
        </CommonStyle.SecurityArchFieldset>
        <StandardStepButtons
          isBackVisible
          isNextEnabled={isNextEnabled()}
          onBack={handleSubmit(setConfigureDataHook)}
        />
      </CommonStyle.StepForm>
    );
  }

  return <LoadingSpinner />;
};
