import * as React from "react";
import * as Recoil from "recoil";
import { Outlet, useLocation } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { ErrorBoundary } from "react-error-boundary";

import { selectedInstanceState } from "atoms/atoms-content";
import { leftSidebarOpenState } from "atoms/atoms-layout";
import { PageSpinner } from "components/elements/PageSpinner";
import { Header } from "features/header";
import { Footer } from "features/footer";
import { ErrorFallback } from "components/error";
import LeftSidebar from "brm/navigation/LeftSidebar";
import { RightSidebar } from "components/layout/RightSidebar";
import {
  useProjectStatusNotification,
  useVariantRiskStatusNotification,
  LicenseApi,
  useResetLikelihoodFilters,
} from "features/brm";
import { CenteredSpinner } from "components/elements";
import * as S from "./MainLayout.styles";
import { LicenseError } from "./LicenseError";

export const MainLayout = () => {
  const {
    error: licError,
    isError: isLicError,
    isLoading: isLicLoading,
  } = LicenseApi.useLicenseCheck({ options: { retryDelay: 250, retry: 2 } });

  useProjectStatusNotification();
  useVariantRiskStatusNotification();
  useResetLikelihoodFilters();

  const [isDrawerVisible, setDrawerVisible] = Recoil.useRecoilState(leftSidebarOpenState);
  const location = useLocation();
  const setSelectedInstance = Recoil.useSetRecoilState<any>(selectedInstanceState);

  const toggleDrawerVisible = () => {
    setDrawerVisible((prev) => !prev);
  };

  React.useEffect(() => {
    setSelectedInstance({});
  }, [location, setSelectedInstance]);

  const HeaderErrorFallback = ({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) => {
    return (
      <Alert variant="danger">
        <Alert.Heading>
          Something went wrong: {error.message}.
          <Button size="sm" variant="link" onClick={resetErrorBoundary}>
            Try Again
          </Button>
        </Alert.Heading>
      </Alert>
    );
  };

  // only care about license violations... we let missing licenses aka 460 through.
  if (isLicError && (licError as any).status === 461) {
    return <LicenseError licenseError={(licError as any).error.response.text} />;
  }

  if (isLicLoading) {
    return <CenteredSpinner text="Validating user session..." />;
  }

  return (
    <S.AppContainer>
      <S.LayoutContainer>
        <React.Suspense
          fallback={
            <div>
              <PageSpinner text="Loading BRM Data..." />
            </div>
          }
        >
          <S.TopNavigation>
            <ErrorBoundary FallbackComponent={HeaderErrorFallback} onReset={() => {}}>
              <Header />
            </ErrorBoundary>
          </S.TopNavigation>
          <S.MainLayoutContainer>
            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
              <LeftSidebar show={isDrawerVisible} toggleDrawerVisibility={toggleDrawerVisible} />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
              <S.MainLayoutContentContainer>
                <Outlet />
              </S.MainLayoutContentContainer>
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
              <RightSidebar />
            </ErrorBoundary>
          </S.MainLayoutContainer>

          <S.Footer>
            <Footer />
          </S.Footer>
        </React.Suspense>
      </S.LayoutContainer>
    </S.AppContainer>
  );
};
