import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./attacker-query-key-factory";

interface IAttacker {
  category: any;
  group: any;
  person: any;
  satype: any;
}

// ? Should get attacker do all the extra queries?
export const getAttacker = async ({ attackerId }: { attackerId: string }) => {
  let attackerToDisplay: Partial<IAttacker> = {};
  const data = await Brm.attackerApi.getAttackerByID(attackerId);
  attackerToDisplay = data;
  const category = await Brm.attackerApi.getAttackerCategory(attackerId);
  const group = await Brm.attackerApi.getAttackerGroup(attackerId);
  const person = await Brm.attackerApi.getAttackerPerson(attackerId);
  const saType = await Brm.attackerApi.getAttackerSatype(attackerId);
  attackerToDisplay.category = category.result;
  attackerToDisplay.group = group;
  attackerToDisplay.person = person;
  attackerToDisplay.satype = saType;
  return [attackerToDisplay];
};

interface IUseAttacker {
  attackerId: string;
  options?: QueryOption<typeof getAttacker>;
}

/**
 * React-query hook for getting all details of Attacker
 *
 * @param {string} attackerId - uuid of the attacker
 * @returns react-query for getAttackers
 */
export const useAttacker = ({ attackerId, options = {} }: IUseAttacker) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.attackerKeys.attacker(attackerId),
    queryFn: () => getAttacker({ attackerId }),
  });
};
