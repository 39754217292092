import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./threat-tier-query-key-factory";

export const getThreatTiers = () => {
  return Brm.threatTierApi.findThreatTier();
};

interface IUseThreatTiers {
  options?: QueryOption<typeof getThreatTiers>;
}

/**
 * React-query hook for getting all Threat tiers
 *
 * @param
 * @returns react-query for getThreatTiers
 */
export const useThreatTiers = ({ options }: IUseThreatTiers = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatTierKeys.all,
    queryFn: () => getThreatTiers(),
  });
};
