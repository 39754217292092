import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./risk-query-key-factory";

interface IGetRiskCategories {
  projectId: string;
}

export const getRiskCategories = ({ projectId }: IGetRiskCategories) => {
  return Brm.riskCategoryApi.findRiskCategory(projectId);
};

interface IUseRisks {
  projectId: string;
  options?: QueryOption<typeof getRiskCategories>;
}

export const useRiskCategories = ({ projectId, options }: IUseRisks) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.riskKeys.categories(projectId),
    queryFn: () => getRiskCategories({ projectId }),
  });
};
