import { useState, useMemo, useRef, useCallback } from "react";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
// Utils
import {
  createColumnName,
  createColumnMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
// Services
import { userRoles } from "services/brm/admin-service";
import { SystemApi, useRoles } from "features/brm";
// State
import { userRoleState, projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
// Styles
import CustomTable from "components/elements/CustomTableDivStyled";
// Components
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";
// Hooks
import useRefreshTableList from "hooks/useRefreshTableList";
import { useCanRename } from "features/system-model";
// Constants
import { TYPE, COMMON } from "constants/brm";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";

const ROLE_SYSTEM_ENGINEER = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
const ROLE_RISK_ANALYST = userRoles.properties[userRoles.RISK_ANALYST].key;

const SystemAssetTable = ({ setSelectedRows, setRefresh, setExportTableData }) => {
  const { isRiskAnalyst, isSystemEngineer, isThreatAnalyst } = useRoles();
  const { canRename } = useCanRename();
  const userRole = useRecoilValue(userRoleState); // current userRole
  const projectId = useRecoilValue(projectIdState); // current project Id
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedCategory = useRef("");
  const updatedSaType = useRef("");
  const updatedOwner = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data, error } = SystemApi.useSystemAssets({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer || isThreatAnalyst) },
  });
  const { data: ownersList, isError: isOwnersListError } = SystemApi.useNodes({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer || isThreatAnalyst) },
  });
  const { data: saTypeList, isError: isSaTypeListError } = SystemApi.useSystemAssetsTypes({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer || isThreatAnalyst) },
  });
  const { data: categoryList, isError: isCategoryListError } = SystemApi.useSystemAssetCategories({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer || isThreatAnalyst) },
  });
  const { mutate: setSystemAssetName } = SystemApi.useSetSystemAssetName({ projectId });
  const { mutate: setSystemAssetCategory } = SystemApi.useSetSystemAssetCategory();
  const { mutate: setSystemAssetType } = SystemApi.useSetSystemAssetSystemAssetType();
  const { mutate: setSystemAssetOwner } = SystemApi.useSetSystemAssetOwner();

  const createButton = useCallback(
    (cellProps) => {
      if (userRole === ROLE_SYSTEM_ENGINEER || userRole === ROLE_RISK_ANALYST) {
        return (
          <ButtonCell
            selectedRowId={selectedInstance.id}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedName.current !== "") {
                if (!canRename({ updatedName, elementsList: data })) return;
                setSystemAssetName(
                  {
                    id: selectedInstance.id,
                    name: updatedName.current,
                  },
                  {
                    onSettled: () => {
                      setSelectedInstance({
                        id: selectedInstance.id,
                        name: updatedName.current,
                        type: selectedInstance.type,
                      });
                      updatedName.current = "";
                    },
                    onError: (err) => {
                      toast.error(`Setting system asset name caused an error: ${err}`);
                    },
                  }
                );
              }

              if (updatedCategory.current !== "") {
                setSystemAssetCategory(
                  {
                    saId: selectedInstance.id,
                    saCat: updatedCategory.current,
                  },
                  {
                    onSettled: () => {
                      updatedCategory.current = "";
                    },
                    onError: (err) => {
                      toast.error(`Setting system asset category caused an error: ${err}`);
                    },
                  }
                );
              }

              if (updatedSaType.current !== "") {
                setSystemAssetType(
                  {
                    saId: selectedInstance.id,
                    saType: updatedSaType.current,
                  },
                  {
                    onSettled: () => {
                      updatedSaType.current = "";
                    },
                    onError: (err) => {
                      toast.error(`Setting system asset type caused an error: ${err}`);
                    },
                  }
                );
              }

              if (updatedOwner.current !== "") {
                setSystemAssetOwner(
                  {
                    saId: selectedInstance.id,
                    owner: updatedOwner.current,
                  },
                  {
                    onSettled: () => {
                      updatedOwner.current = "";
                    },
                    onError: (err) => {
                      toast.error(`Setting system asset owner caused an error: ${err}`);
                    },
                  }
                );
              }
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return COMMON.na;
    },

    [
      userRole,
      selectedInstance.id,
      selectedInstance.type,
      editMode,
      canRename,
      data,
      setSystemAssetName,
      setSelectedInstance,
      setSystemAssetCategory,
      setSystemAssetType,
      setSystemAssetOwner,
    ]
  );

  const columns = useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "systemAsset"),
      createColumnCategoryMapped("category", selectedInstance.id, categoryList, editMode, updatedCategory),
      createColumnMapped("satype", selectedInstance.id, saTypeList, editMode, updatedSaType),
      createColumnMapped("owner", selectedInstance.id, ownersList, editMode, updatedOwner),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [selectedInstance.id, setSelectedInstance, editMode, categoryList, saTypeList, ownersList, createButton]
  );

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  if (error || isOwnersListError || isSaTypeListError || isCategoryListError) {
    return <ErrorBanner msg="Error while loading system asset data" />;
  }

  if (data) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={data || []}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "SystemAssetTable_table" }}
            elementName={TYPE.systemAsset}
            setExportTableData={setExportTableData}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

SystemAssetTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  setExportTableData: PropTypes.func,
};

export default SystemAssetTable;
