import { useState, useMemo, useRef, useCallback } from "react";
import toast from "react-hot-toast";
import PropTypes from "prop-types";

import {
  createColumnName,
  createColumnMappedStringEditValidation,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
// Services
import { SystemApi, useRoles } from "features/brm";
import { userRoles } from "services/brm/admin-service";
import { useCanRename } from "features/system-model";
// State
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
// Styles
import CustomTable from "components/elements/CustomTableDivStyled";
// Components
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";
// Constants
import { TYPE, COMMON } from "constants/brm";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";

const ROLE_SYSTEM_ENGINEER = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
const ROLE_RISK_ANALYST = userRoles.properties[userRoles.RISK_ANALYST].key;

const SystemAssetTypeTable = ({ setSelectedRows }) => {
  const { isRiskAnalyst, isSystemEngineer, isThreatAnalyst, userRole } = useRoles();
  const { canRename } = useCanRename();
  const projectId = useRecoilValue(projectIdState); // current project Id
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedCpe = useRef("");
  const updatedCategory = useRef("");

  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data, error } = SystemApi.useSystemAssetsTypes({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer || isThreatAnalyst) },
  });
  const { data: categoryList, isError: isCategoryListError } = SystemApi.useSystemAssetCategories({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer || isThreatAnalyst) },
  });
  const { mutate: setSystemAssetTypeName } = SystemApi.useSetSystemAssetTypeName({ projectId });
  const { mutate: setSystemAssetTypeCpe } = SystemApi.useSetSystemAssetTypeCpe({ projectId });
  const { mutate: setSystemAssetTypeCategory } = SystemApi.useSetSystemAssetTypeCategory();

  const createButton = useCallback(
    (cellProps) => {
      if (userRole === ROLE_SYSTEM_ENGINEER || userRole === ROLE_RISK_ANALYST) {
        const element = cellProps.cell.row.original;
        return (
          <ButtonCell
            selectedRowId={selectedInstance.id}
            elementId={element.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedName.current !== "") {
                if (!canRename({ updatedName, elementsList: data })) return;
                setSystemAssetTypeName(
                  {
                    saTypeId: selectedInstance.id,
                    name: updatedName.current,
                  },
                  {
                    onSettled: () => {
                      setSelectedInstance({
                        id: selectedInstance.id,
                        name: updatedName.current,
                        type: selectedInstance.type,
                      });
                      updatedName.current = "";
                    },
                    onError: (err) => {
                      toast.error(`Setting system asset type name caused an error: ${err}`);
                    },
                  }
                );
              }

              if (updatedCpe.current !== "" || (updatedCpe.current === "" && element.cpe !== "")) {
                await setSystemAssetTypeCpe(
                  {
                    saTypeId: selectedInstance.id,
                    cpeId: updatedCpe.current,
                  },
                  {
                    onSettled: () => {
                      updatedCpe.current = "";
                    },
                    onError: (err) => {
                      toast.error(`Setting system asset type CPE caused an error: ${err}`);
                    },
                  }
                );
              }

              if (updatedCategory.current !== "") {
                setSystemAssetTypeCategory(
                  {
                    saTypeId: selectedInstance.id,
                    saTypeCat: updatedCategory.current,
                  },
                  {
                    onSettled: () => {
                      updatedCategory.current = "";
                    },
                    onError: (err) => {
                      toast.error(`Setting system asset type category caused an error: ${err}`);
                    },
                  }
                );
              }
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return COMMON.na;
    },

    [
      userRole,
      selectedInstance.id,
      selectedInstance.type,
      editMode,
      canRename,
      data,
      setSystemAssetTypeName,
      setSelectedInstance,
      setSystemAssetTypeCpe,
      setSystemAssetTypeCategory,
    ]
  );

  const columns = useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "systemAssetType"),
      createColumnCategoryMapped("category", selectedInstance.id, categoryList, editMode, updatedCategory),
      createColumnMappedStringEditValidation("cpe", selectedInstance.id, editMode, updatedCpe, "systemAssetType"),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [categoryList, createButton, editMode, selectedInstance.id, setSelectedInstance]
  );

  if (error || isCategoryListError) {
    return <ErrorBanner msg="Error while loading system asset type data" />;
  }

  if (data) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={data}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "SystemAssetTypeTable_table" }}
            elementName={TYPE.systemAssetType}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

SystemAssetTypeTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

export default SystemAssetTypeTable;
