import { IRule, IDiff, IKnowledgebase, IUndesiredEvent, IKbDiff } from "./types";

const ruleMatch = (orig: IRule, other: IRule) => {
  return (
    orig.tag === other.tag &&
    orig.sacat === other.sacat &&
    orig.atkcat === other.atkcat &&
    orig.atktactic === other.atktactic &&
    orig.atkkind === other.atkkind &&
    orig.trevcat === other.trevcat &&
    orig.trevpat === other.trevpat
  );
};

const ueMatch = (orig: IUndesiredEvent, other: IUndesiredEvent) => {
  return orig.targetcat === other.targetcat && orig.objective === other.objective;
};

const moMatch = (orig: IRule, other: IRule) => {
  return orig.means === other.means && orig.opportunity === other.opportunity;
};

// criticality, severity, rfactor
const csrMatch = (orig: IUndesiredEvent, other: IUndesiredEvent) => {
  return orig.criticality === other.criticality && orig.severity === other.severity && orig.rfactor === other.rfactor;
};

type KeyMatchFunc<T> = (arg0: T, arg1: T) => boolean;
type MatchFunc<T> = (arg0: T, arg1: T) => boolean;

export const useKbDiff = () => {
  const doDiff = <T>(kbA: T[], kbB: T[], rowMatch: KeyMatchFunc<T>, recordMatch: MatchFunc<T>) => {
    const result: IDiff<T> = {
      changed: [],
      added: [],
      removed: [],
    };

    if (Array.isArray(kbA) && Array.isArray(kbB)) {
      kbA.forEach((ra: T) => {
        const matchIndex = kbB.findIndex((rb: T) => rowMatch(ra, rb));
        if (matchIndex === -1) {
          // removed
          result.removed.push(ra);
        } else {
          const matchedRule = kbB[matchIndex];
          if (!recordMatch(ra, matchedRule)) {
            // changed
            result.changed.push({ orig: ra, other: matchedRule });
          }
        }
      });

      // added
      kbB.forEach((rb: T) => {
        const matchIndex = kbA.findIndex((ra: T) => rowMatch(rb, ra));
        if (matchIndex === -1) {
          result.added.push(rb);
        }
      });
    }
    return result;
  };

  const calculateDiff = (kbA: IKnowledgebase, kbB: IKnowledgebase) => {
    const ruleDiff = doDiff(kbA.rules, kbB.rules, ruleMatch, moMatch);
    const ueDiff = doDiff(kbA.ue, kbB.ue, ueMatch, csrMatch);

    const result: IKbDiff = {
      rules: { ...ruleDiff },
      ue: { ...ueDiff },
    };

    return {
      result,
      isEmpty: !(
        result.rules.added.length ||
        result.rules.changed.length ||
        result.rules.changed.length ||
        result.ue.added.length ||
        result.ue.changed.length ||
        result.ue.removed.length
      ),
    };
  };

  return { calculateDiff };
};
