import * as React from "react";

import { useNavigate, createSearchParams, generatePath } from "react-router-dom";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import toast from "react-hot-toast";
import { queryClient } from "libs/react-query";
import { Col, Container, Row } from "react-bootstrap";

// Components
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import ButtonCell from "components/EditComponents/ButtonCell";
import { LicenseCheckButton, LoadingSpinner, UploadButton } from "components/elements";
import { LicenseWizard } from "features/importer";
import { ExportAuditModal, useExportFile } from "features/exporter";
import {
  createColumnName,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnActions,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";
import { AdminApi, AuditApi, LicenseApi, ExportButton, DetailsContainerSingleTable } from "features/brm";
import { FormStyledError } from "components/forms";

// Constants
import { TYPE } from "constants/brm";

// Hooks
import { useModal, useRoles } from "hooks/";
import { RoutePath } from "routes/route-paths";
// import * as log from "loglevel";
import { useSap } from "features/admin/hooks";
import { BorrowRequestModal, BorrowRecordList } from "../../modals";
import { BorrowRequestButton } from "../../license";

import { LicenseStatus } from "./InstallationTable.styles";
import { EXPORT_FILE } from "../../../constants";

export const InstallationTable = () => {
  const navigate = useNavigate();
  const { isSuperAdmin } = useRoles();
  const [editMode, setEditMode] = React.useState(false);
  const [uploadModalIsOpen, setUploadModalIsOpen] = React.useState(false);
  const [licenseStatusModalIsOpen, setLicenseStatusModalIsOpen] = React.useState(false);
  const updatedName = React.useRef("");
  const updatedStatus = React.useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState<any>(selectedInstanceState);

  const { data: licenseStatus } = LicenseApi.useLicenseState();

  const { downloadFile } = useExportFile();

  const [disableExport, setDisableExport] = React.useState(false);
  const { isVisible: isAuditModalOpen, toggle: setAuditModalOpen } = useModal();
  const { mutate: exportAuditLogEvents } = AuditApi.useExportAuditLogEvents({});

  const [disableBorrowRequest, setDisableBorrowRequest] = React.useState(false);
  const { isVisible: isBorrowRequestModalOpen, toggle: setBorrowRequestModalOpen } = useModal();
  const { mutate: createBorrowFile } = LicenseApi.useCreateBorrowFile({});

  const { mutate: setInstallationName } = AdminApi.useSetInstallationName();
  const { mutate: setInstallationStatus } = AdminApi.useSetInstallationStatus();
  const { data: installationData } = AdminApi.useInstallations({ options: { enabled: isSuperAdmin, cacheTime: 0 } });

  const { isSapAvailable, isEnabled } = useSap();
  const { mutate: createGlobalSetting } = AdminApi.useCreateGlobalSetting();

  const data = React.useMemo(() => {
    let Data = null;
    if (Array.isArray(installationData) && installationData.length) {
      [Data] = installationData;
      return [{ ...Data, hostId: licenseStatus.vendorString.$HOSTID }];
    }
    return Data;
  }, [installationData, licenseStatus.vendorString.$HOSTID]);

  const createButton = React.useCallback(
    (cellProps) => (
      <>
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);
            // send request to update name
            if (updatedName.current !== "") {
              setInstallationName({ installationId: selectedInstance.id, newName: updatedName.current });
              setSelectedInstance({
                id: selectedInstance.id,
                name: updatedName.current,
                type: selectedInstance.type,
              });
              updatedName.current = "";
            }

            // send request to update status
            if (updatedStatus.current && updatedStatus.current !== "Select status") {
              setInstallationStatus({ installationId: selectedInstance.id, newStatus: updatedStatus.current });
              updatedStatus.current = "";
            }
            queryClient.invalidateQueries(AdminApi.installationKeys.installation(selectedInstance.id));
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
        {!editMode && (
          <>
            <UploadButton onClick={() => setUploadModalIsOpen(true)} uploadTitle="License import" />
            <LicenseCheckButton onClick={() => setLicenseStatusModalIsOpen(true)} />
            <ExportButton
              buttonTitle="Export Audit Records"
              onClick={() => setAuditModalOpen()}
              disableExport={disableExport}
            />
            <BorrowRequestButton
              buttonTitle="Request License Borrow"
              onClick={() => setBorrowRequestModalOpen()}
              disableBorrow={disableBorrowRequest}
            />
            {Array.isArray(installationData) && installationData.length ? (
              <>
                <button
                  style={{
                    background: "#005CA6",
                    color: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "4px 4px",
                    height: "32px",
                  }}
                  type="button"
                  onClick={() =>
                    navigate({
                      pathname: generatePath(RoutePath.Installation, { id: installationData[0].id }),
                      search: createSearchParams({ tab: "config" }).toString(),
                    })
                  }
                >
                  Configuration
                </button>
                {isSapAvailable ? (
                  <button
                    style={{
                      background: "#005CA6",
                      color: "#fff",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "4px 4px",
                      height: "32px",
                    }}
                    type="button"
                    onClick={() => {
                      const settingDto = isEnabled
                        ? {
                            name: "sap",
                            value: "disabled",
                          }
                        : {
                            name: "sap",
                            value: "enabled",
                          };
                      createGlobalSetting({ globalSettingCreateDto: settingDto });
                      // setIsEnabled(!isEnabled);
                    }}
                  >
                    {!isEnabled ? "Enable SAP support" : "Disable SAP Support"}
                  </button>
                ) : null}
              </>
            ) : null}
          </>
        )}
      </>
    ),
    [
      selectedInstance,
      editMode,
      disableExport,
      disableBorrowRequest,
      installationData,
      isSapAvailable,
      isEnabled,
      setInstallationName,
      setSelectedInstance,
      setInstallationStatus,
      setAuditModalOpen,
      setBorrowRequestModalOpen,
      navigate,
      createGlobalSetting,
    ]
  );

  const handleAuditReportDownload = React.useCallback(
    ({ format, type }) => {
      setDisableExport(true);
      exportAuditLogEvents(
        { format, type },
        {
          onSuccess: (auditData) => {
            setDisableExport(false);
            downloadFile({ data: auditData, format, name: `AUDIT-${type}` });
          },
          onError: () => {
            setDisableExport(false);
            toast.error("Error occurred while generating audit report");
          },
        }
      );
    },
    [downloadFile, exportAuditLogEvents]
  );

  const handleBorrowLicenseRequest = React.useCallback(
    ({ hostId, duration }) => {
      setDisableBorrowRequest(true);
      createBorrowFile(
        { hostId, duration },
        {
          onSuccess: (borrowData) => {
            setDisableBorrowRequest(false);
            toast.success("License borrowed successfully");
            downloadFile({ data: borrowData, format: EXPORT_FILE.format, name: EXPORT_FILE.name });
          },
          onError: () => {
            setDisableBorrowRequest(false);
            toast.error("Error occurred while generating borrow license");
          },
        }
      );
    },
    [createBorrowFile, downloadFile]
  );

  const pathFilter = React.useCallback(
    (path: string) => {
      if (Array.isArray(data) && data.length) {
        return path && data[0].hostId ? path : undefined;
      }
      return undefined;
    },
    [data]
  );

  const columns = React.useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "installation", undefined, {
        pathFilter,
      }),
      createColumnMappedNoEdit("hostId"),
      createColumnMappedNoEdit("status"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [selectedInstance.id, setSelectedInstance, editMode, pathFilter, createButton]
  );

  if (data) {
    return (
      <div id="InstallationTable_table">
        {uploadModalIsOpen &&
          systemModals.customModal(
            "InstallationTable_import",
            uploadModalIsOpen,
            setUploadModalIsOpen,
            <LicenseWizard setUploadModalIsOpen={setUploadModalIsOpen} />,
            "Import License"
          )}

        {licenseStatus &&
          licenseStatusModalIsOpen &&
          systemModals.customModal(
            "InstallationTable_status",
            licenseStatusModalIsOpen,
            setLicenseStatusModalIsOpen,
            <LicenseStatus>
              <Container>
                <Row>
                  <Col>License validity :</Col>
                  <Col> {licenseStatus?.valid ? " valid " : " invalid "}</Col>
                </Row>

                <Row>
                  <Col>Issue date : </Col>
                  <Col>{licenseStatus?.issueDate || "-"}</Col>
                </Row>

                <Row>
                  <Col>Start date : </Col>
                  <Col>{licenseStatus?.startDate || "-"}</Col>
                </Row>

                <Row>
                  <Col>Expiration date : </Col>
                  <Col> {licenseStatus?.expirationDate || "-"}</Col>
                </Row>

                <Row>
                  <Col>Days : </Col>
                  <Col>{licenseStatus?.days || "-"}</Col>
                </Row>

                <br />
                {licenseStatus?.vendorString?.product && (
                  <Row>
                    <Col>Product : </Col>
                    <Col>{licenseStatus.vendorString.product}</Col>
                  </Row>
                )}

                {licenseStatus?.vendorString?.profile && (
                  <Row>
                    <Col>Profile : </Col>
                    <Col>{licenseStatus.vendorString.profile}</Col>
                  </Row>
                )}

                {licenseStatus?.vendorString?.session_max && (
                  <Row>
                    <Col>Max sessions : </Col>
                    <Col>{licenseStatus.vendorString.session_max}</Col>
                  </Row>
                )}

                {licenseStatus?.vendorString?.session_per_user_max && (
                  <Row>
                    <Col>Max sessions per user : </Col>
                    <Col>{licenseStatus.vendorString.session_per_user_max}</Col>
                  </Row>
                )}

                {licenseStatus?.vendorString?.user_max && (
                  <Row>
                    <Col>Max users : </Col>
                    <Col>
                      {licenseStatus.vendorString.user_max < 0 ? "unlimited" : licenseStatus.vendorString.user_max}
                    </Col>
                  </Row>
                )}

                {licenseStatus?.borrows && <BorrowRecordList borrows={licenseStatus.borrows} />}
              </Container>

              {licenseStatus?.vendorString?.$ERROR_MSG && (
                <FormStyledError>*Error : {licenseStatus.vendorString.$ERROR_MSG}</FormStyledError>
              )}
            </LicenseStatus>,
            "License Status"
          )}
        <ExportAuditModal
          modalName="AuditLogsReport"
          elementName={TYPE.installation}
          onReportExport={handleAuditReportDownload}
          isVisible={isAuditModalOpen}
          toggle={setAuditModalOpen}
        />
        <BorrowRequestModal
          onBorrowRequest={handleBorrowLicenseRequest}
          isVisible={isBorrowRequestModalOpen}
          toggle={setBorrowRequestModalOpen}
        />
        <DetailsContainerSingleTable>
          <BrmSingleElementTable
            data={data}
            columns={columns}
            setSelectedElement={setSelectedInstance}
            setSelectedRowId={() => {}}
            customProps={{ id: `installationTable_mainTable` }}
            elementName={TYPE.installation}
          />
        </DetailsContainerSingleTable>
      </div>
    );
  }

  return <LoadingSpinner />;
};
