// import * as ReactQuery from "react-query";
// import * as Brm from "services/brm";
// import { QueryOption } from "libs/react-query";
// import * as KeyFactory from "./import-query-key-factory";

// interface IGetImportAsset {
//   projectId: string | null;
//   context: any;
// }

// /**
//  *
//  * Gets import history of a project
//  * @param {string} projectId - uuid of project
//  */
// export const getImportAsset = ({ projectId, context }: IGetImportAsset) => {
//   return projectId === null
//     ? Promise.reject(new Error("Invalid projectId"))
//     : Brm.genericImportApi.importAsset(context, projectId);
// };

// interface IImportAssetProps {
//   projectId: string | null;
//   context: any;
//   options?: QueryOption<typeof getImportAsset>;
// }

// /**
//  * Custom hook for getting import history of project
//  *
//  * @param {string} projectId - uuid of user
//  * @param {string} context - import context
//  *
//  * @param {object} options - react-query options
//  */
// export const useProjectStatus = ({ projectId, context, options = {} }: IImportAssetProps) => {
//   return ReactQuery.useQuery({
//     ...options,
//     queryKey: KeyFactory.importKeys.context(projectId, context),
//     queryFn: () => getImportAsset({ projectId, context }),
//   });
// };

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { QueryOption } from "libs/react-query";
import { ImportContext, ImportContextValues } from "../../constants";
import * as KeyFactory from "./import-query-key-factory";

interface IGetImportAsset {
  projectId: string;
  context: ImportContextValues;
  runId: string;
}

interface ImporterFunctionMap {
  [key: string]: any;
}

export const getImportAssets = async ({ projectId, context, runId }: IGetImportAsset) => {
  const importer: ImporterFunctionMap = {
    [ImportContext.bor]: () => Brm.borImportApi.importAssetsBOR(projectId, runId),
    [ImportContext.baseline]: () => Brm.baselineImportApi.importAssetsBaseline(projectId, runId),
    [ImportContext.savepoint]: () => Brm.savePointImportApi.importAssetsSavepoint(projectId, runId),
    [ImportContext.sctm]: () => Brm.sctmImportApi.importAssetsSctm(projectId, runId),
    [ImportContext.tra]: () => Brm.traImportApi.importAssetsTra(projectId, runId),
    [ImportContext.system]: () => Brm.systemImportApi.importAssetsSystem(projectId, runId),
  };

  if (!Object.keys(importer).some((k) => k === context)) {
    throw new Error(`Unsupported import context: ${context}`);
  }

  return importer[context]();
};

export interface IUseImportAssets {
  projectId: string;
  context: ImportContextValues;
  runId: string;
  options?: QueryOption<typeof getImportAssets>;
}

export const useImportAssets = ({ projectId, context, runId, options }: IUseImportAssets) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.importKeys.assets(projectId, context, runId),
    queryFn: () => getImportAssets({ projectId, context, runId }),
  });
};
