import styled from "styled-components/macro";
import { Popover } from "react-bootstrap";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const VulnerabilityListLabel = styled.div`
  font-weight: 550;
  align-self: end;
`;

export const VulnerabilityListContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  /* border-radius: 5px;
  border: 1px #cccccc solid; */
  overflow: auto;
`;

export const VulnerabilityLabel = styled.label`
  padding: 3px 10px;
`;

export const OrderedList = styled.ol`
  padding-left: 20px;
`;

export const List = styled.ul`
  padding-left: 15px;
  list-style: none;
`;

export const ListItem = styled.li`
  padding-left: 5px;
`;

export const Title = styled.h6`
  padding: 5px 0;
  font-weight: 600;
`;

// export const VulnerabilityCheckedInput = styled.input`
//   margin-top: 5px;
//   margin-right: 5px;
//   border-radius: 5px;
//   width: 16px;
//   height: 16px;
//   vertical-align: top;
// `;

export const ShadowPopover = styled(Popover)`
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
  border: none;
  font-size: 1rem;
  max-width: 400px;
`;
