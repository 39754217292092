// Local
import { AUDIT_FORMAT, AUDIT_TYPE } from "../../constants";
import S from "../ExportWizard/ExportWizard.styles";

interface IImportAuditTypeSelector {
  selectedFormat: string;
  setSelectedFormat: (value: string) => void;
  setSelectedType: (value: string) => void;
}

export const ImportAuditEditSelector = ({
  selectedFormat,
  setSelectedFormat,
  setSelectedType,
}: IImportAuditTypeSelector) => {
  setSelectedType(AUDIT_TYPE.system);
  return (
    <div>
      <label>Export as :</label>
      <S.ExportMenu>
        <S.List>
          <li>
            <input
              type="radio"
              value="CSV"
              checked={selectedFormat === AUDIT_FORMAT.csv}
              onChange={(e) => setSelectedFormat(e.target.value)}
            />
            <S.ExportLabel>.csv</S.ExportLabel>
          </li>
          <li>
            <input
              type="radio"
              value="JSON"
              checked={selectedFormat === AUDIT_FORMAT.json}
              onChange={(e) => setSelectedFormat(e.target.value)}
            />
            <S.ExportLabel>.json</S.ExportLabel>
          </li>
        </S.List>
      </S.ExportMenu>
    </div>
  );
};
