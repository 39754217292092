import { useQuery } from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "brm/tables/details/api/element-query-key-factory";
import { responseAsArray } from "./utils/responseAsArray";
import { DetailTableType } from "../../../types/DetailTableType";
import { IElementMap } from "./types/elementInterface";

interface IGetElementCharacteristicProps {
  elementId: string;
  elementName: string;
}

export const getElementCharacteristic = async ({ elementId, elementName }: IGetElementCharacteristicProps) => {
  const characteristicsMap: IElementMap = {
    [DetailTableType.MISSIONS.key]: (id) => Brm.missionApi.getMissionCharacteristic(id),
    [DetailTableType.PERSON.key]: (id) => Brm.personApi.getPersonCharacteristic(id),
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.getCapabilityCharacteristic(id),
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityCharacteristic(id),
    [DetailTableType.DATATYPES.key]: (id) => Brm.dataTypeApi.getDataTypeCharacteristic(id),
    [DetailTableType.DATAFLOWS.key]: (id) => Brm.dataflowApi.getDataFlowCharacteristic(id),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeCharacteristic(id),
    [DetailTableType.BUSES.key]: (id) => Brm.busApi.getBusCharacteristic(id),
    [DetailTableType.LINKS.key]: (id) => Brm.linkApi.getLinkCharacteristic(id),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeCharacteristic(id),
    [DetailTableType.RESOURCES.key]: (id) => Brm.resourceApi.getResourceCharacteristic(id),
  };

  return responseAsArray({ map: characteristicsMap, elementId, elementType: elementName });
};

interface IUseElementCharacteristicsProps {
  elementId: string;
  elementName: string;
  options: QueryOption<typeof getElementCharacteristic>;
}

/**
 * Custom hook to retrieve characteristics of an element
 *
 * @param {string} elementId - uuid of any selected element
 * @param {string} elementName - type/category of selected element
 * @param {object} options - react-query configuration object
 * @returns list of characteristics for any given selected element
 */
export const useElementCharacteristic = ({ elementId, elementName, options = {} }: IUseElementCharacteristicsProps) => {
  return useQuery({
    ...options,
    queryKey: KeyFactory.elementKeys.characteristic(elementId, elementName),
    queryFn: () => getElementCharacteristic({ elementId, elementName }),
  });
};
