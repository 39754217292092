// import { useState, useRef, useEffect, useMemo } from "react";
import * as React from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { handleNoteInTooltip, formattedTimeStamp, dloadJSON } from "utils/report-utils";
import { createColumnMappedNoEditRpt, createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
import { REPORT } from "constants/brm";

// Styles
import * as S from "brm/styles/details-table.styles";
import { useKbCwes, useKbSfps, useKbSubcats, ExportContext, ExportButton } from "features/brm";
import { LoadingSpinner } from "components/elements";
import { ExportTableModal, useExportTable, EXPORT_TARGET } from "features/exporter";
import { useModal } from "hooks";

interface ICweReportTableProps {
  tableTitle: string;
}
interface ICallbackOpts {
  target: string;
  type: string;
}

interface IReportItem {
  cweId: string;
  subcatId: string;
  cweName: string;
  sfpId: string;
  note: string;
}

const CweRptTable = ({ tableTitle }: ICweReportTableProps) => {
  const { lookupCwes } = useKbCwes();
  const { lookupSfps } = useKbSfps();
  const { lookupSubcats } = useKbSubcats();

  const [cwesData, setCwesData] = React.useState<IReportItem[]>([]);
  const [, setSelectedData] = React.useState([]);
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  React.useEffect(() => {
    if (lookupCwes && lookupSfps && lookupSubcats) {
      const items = Object.keys(lookupCwes).map((property) => {
        return {
          cweId: property,
          subcatId: lookupCwes[property].subcatId,
          cweName: lookupCwes[property].cweName,
          sfpId: lookupCwes[property].sfpId,
          note: lookupCwes[property].note,
        };
      });
      setCwesData(items);
    }
  }, [lookupCwes, lookupSfps, lookupSubcats]);

  const handleCweNameInTooltip = React.useCallback(
    (cell: any) => {
      // lookup the cwe name and display in tooltip for this column
      if (cell.value && lookupCwes) {
        const { cweName } = lookupCwes[cell.value];

        return (
          <div>
            <span title={cweName}>{cell.value}</span>
          </div>
        );
      }
      return (
        <div>
          <span title={cell.value}>{cell.value}</span>
        </div>
      );
    },
    [lookupCwes]
  );

  const handleSubcatExplanationInTooltip = React.useCallback(
    (cell) => {
      // lookup the subcat description and display in tooltip for this column
      if (cell.value && lookupSubcats) {
        const subcat = lookupSubcats[cell.value];
        const helpStr = subcat ? `${subcat.category} - ${subcat.subcatName}` : "";

        return (
          <div>
            <span title={helpStr}>{cell.value}</span>
          </div>
        );
      }
      return (
        <div>
          <span title={cell.value}>{cell.value}</span>
        </div>
      );
    },
    [lookupSubcats]
  );

  const cweReport = React.useMemo(() => {
    if (cwesData) {
      // const vulResults = nodeVulResults.map((result) => result.data);
      // const finalResults = createVulData(vulResults);
      return {
        data: cwesData,
        timestamp: formattedTimeStamp(),
        count: cwesData.length.toLocaleString(),
      };
    }
    return undefined;
  }, [cwesData]);

  const handleSfpExplanationInTooltip = React.useCallback(
    (cell) => {
      // lookup the sfp description and display in tooltip for this column
      if (cell.value && lookupSfps) {
        const sfp = lookupSfps[cell.value];
        const helpStr = sfp ? `${sfp.sfpCluster} - ${sfp.sfpName}` : "";

        return (
          <div>
            <span title={helpStr}>{cell.value}</span>
          </div>
        );
      }
      return (
        <div>
          <span title={cell.value}>{cell.value}</span>
        </div>
      );
    },
    [lookupSfps]
  );

  /**
   * Download report
   */
  const handleDownloadClick = React.useCallback(
    (opts: ICallbackOpts) => {
      if (opts.target === EXPORT_TARGET.json) {
        if (!cweReport) {
          return;
        }
        const fileName = "CWE";
        const jsonData = {
          metaData: {
            reportType: REPORT.cwe,
            project: fileName,
            reportGenerationDate: cweReport.timestamp,
            numOfRows: cweReport.count,
          },
          rows: cweReport.data,
        };
        dloadJSON(jsonData, `BRMExport__${tableTitle}_${opts.type}.json`);
      } else if (opts.target === EXPORT_TARGET.csv) {
        handleTableExport(opts);
      }
    },
    [cweReport, tableTitle, handleTableExport]
  );

  const columns = React.useMemo(
    () => [
      createColumnMappedNoEditRpt("cweId", handleCweNameInTooltip),
      createColumnMappedNoEditRpt("subcatId", handleSubcatExplanationInTooltip),
      createColumnMappedNoEdit("cweName"),
      createColumnMappedNoEditRpt("sfpId", handleSfpExplanationInTooltip),
      createColumnMappedNoEditRpt("note", handleNoteInTooltip),
    ],
    [handleCweNameInTooltip, handleSfpExplanationInTooltip, handleSubcatExplanationInTooltip]
  );

  if (cwesData) {
    /**
     * RENDER COMPONENT
     */
    return (
      <>
        <S.DetailsContainer>
          <S.DetailButtonTopCorner>
            <S.DetailsTableButtonsContainer>
              <ExportButton onClick={() => toggle()} disableExport={disableExport} />
            </S.DetailsTableButtonsContainer>
          </S.DetailButtonTopCorner>
          <ExportTableModal
            onTableExport={handleDownloadClick}
            isVisible={isVisible}
            toggle={toggle}
            context={ExportContext.listWithJson}
          />
          <S.DetailsTableContainer id="CweRptTable_table">
            <BrmDetailTable
              data={cwesData}
              columns={columns}
              setSelectedElement={setSelectedData}
              customProps={{ id: "CweRptTable_detailsTable" }}
              showRowSelect={false}
              tableTitle={tableTitle}
              setExportTableData={(currentData) =>
                setExportTableData((prevData) =>
                  prevData?.toString() !== currentData.toString() ? currentData : prevData
                )
              }
            />
          </S.DetailsTableContainer>
        </S.DetailsContainer>
      </>
    );
  }

  return <LoadingSpinner message="Loading CWE data" />;
};

export default CweRptTable;
