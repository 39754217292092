import styled from "styled-components";

export const HomeHelptext = styled.div`
  padding: 15px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fonts.defaultSize};
`;

export const DocContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const DocHeading = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: red;
`;

export const Subtask = styled.ul`
  padding-left: 16px;
`;

export const SeparatorWrapper = styled.div`
  display: flex;
  padding: 0 10px;
`;
