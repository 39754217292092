import { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import Title from "components/elements/StyledTitle";
import { LoadingSpinner } from "components/elements";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { createColumnMapped, createColumnBooleanMapped } from "brm/tables/services/column/columnFactory";
import * as AssetsApi from "brm/risk-model/assets/api";
import { TYPE } from "constants/brm";
import { RoutePath } from "routes/route-paths";
import { useRoles } from "features/brm";
import AssetDetails from "../details/AssetDetails";

const listUrl = RoutePath.Assets;

function AssetFullDetails() {
  const { isRiskAnalyst } = useRoles();
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [showSummaryTable, setShowSummaryTable] = useState(true);

  const { data: assetById, isLoading, isError } = AssetsApi.useGetAssetById(id, { enabled: isRiskAnalyst });

  let content = null;

  const columns = useMemo(
    () => [
      createColumnMapped("category", selectedRowId, null, false, null),
      createColumnBooleanMapped("isAssumed", selectedRowId, false, null),
    ],
    [selectedRowId]
  );

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(listUrl);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    if (assetById) {
      setSelectedElement(assetById);
      setData([assetById]);
    }
  }, [assetById]);

  if (isError) {
    content = <>An error occurred while retrieving Asset data.</>;
  }

  if (isLoading) {
    content = <LoadingSpinner />;
  }

  if (assetById) {
    return (
      <>
        <Title id="AssetFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "AssetFullDetails_table" }}
              elementName={TYPE.asset}
            />
          </DetailsContainerSingleTable>
        ) : (
          <></>
        )}

        <DetailsContainerParent showSummaryTable={showSummaryTable}>
          <DetailsContainer id="Asset_detailsPanel">
            {selectedElement.id && (
              <AssetDetails selectedElement={selectedElement} setRefresh={setRefresh} refresh={refresh} />
            )}
          </DetailsContainer>
        </DetailsContainerParent>
      </>
    );
  }
  return content;
}

export default AssetFullDetails;
