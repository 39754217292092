export const entryPointKeys = {
  all: ["entryPoint"] as const,
  entryPoint: (entryPointId: string) => [...entryPointKeys.all, entryPointId] as const,
  project: (projectId: string) => [...entryPointKeys.all, projectId] as const,
  details: (elementName: string, elementId: string, projectId: string) =>
    [...entryPointKeys.all, elementId, elementName, projectId] as const,
};

export const attackSurfaceKeys = {
  all: ["attackSurface"] as const,
  project: (projectId: string) => [...attackSurfaceKeys.all, projectId] as const,
  details: (elementName: string, elementId: string, projectId: string) =>
    [...attackSurfaceKeys.all, elementId, elementName, projectId] as const,
};

export const accessPointKeys = {
  all: ["accessPoint"] as const,
  project: (projectId: string) => [...accessPointKeys.all, projectId] as const,
  entryPoints: (accessPointId: string) => [...accessPointKeys.all, accessPointId] as const,
  details: (elementName: string, elementId: string, projectId: string) =>
    [...accessPointKeys.all, elementId, elementName, projectId] as const,
};

export const attackVectorsKeys = {
  all: ["attackVector"] as const,
  project: (projectId: string) => [...attackVectorsKeys.all, projectId] as const,
  entryPoints: (attackVectorId: string) => [...attackVectorsKeys.all, attackVectorId] as const,
  details: (elementName: string, elementId: string, projectId: string) =>
    [...attackVectorsKeys.all, elementId, elementName, projectId] as const,
};
