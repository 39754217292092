/* eslint-disable react/jsx-props-no-spreading */
import Modal from "react-bootstrap/Modal";
import { ActionButton } from "components/elements/ActionButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { useForm, SubmitHandler } from "react-hook-form";
import useToggle from "hooks/useToggle";
import { useResetUserPassword } from "features/brm/api/admin/user/resetUserPassword";
import * as S from "./ResetPasswordModal.styles";

interface IInputs {
  newPassword: string;
}

interface IPasswordModalProps {
  onHide: () => void;
  user: any;
  show: boolean;
}

const MIN_PASSWORD_LENGTH = 16;

export const ResetPasswordModal = ({ onHide, user, ...rest }: IPasswordModalProps) => {
  const [isPasswordVisible, togglePasswordVisible] = useToggle(false);

  const { mutate: resetPassword } = useResetUserPassword();

  const {
    register,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<IInputs>({ mode: "onChange" });

  const onSubmit: SubmitHandler<IInputs> = (data) => {
    resetPassword({ userId: user.id, newPassword: data.newPassword });
    reset();
    onHide();
  };

  const validatePasswordUpperCase = (v: string) => {
    return v.toLowerCase() !== v;
  };

  const validatePasswordLowerCase = (v: string) => {
    return v.toUpperCase() !== v;
  };

  const validatePasswordNumber = (v: string) => {
    return /\d/.test(v);
  };

  const validatePasswordSpecialCharacter = (v: string) => {
    return /[!@#$%^&*)(+=._-]/.test(v);
  };

  const validateIsNotUsername = (v: string, username: string) => {
    return v !== username;
  };

  const validateIsNotEmail = (v: string, email: string) => {
    return v !== email;
  };

  return (
    <Modal {...rest} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Password Reset </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <S.ResetPasswordRoot>
          <S.ResetPasswordContainer>
            <S.PasswordGroup>
              <S.ResetPasswordTitle> Change {user.username}&apos;s password</S.ResetPasswordTitle>
              <S.FormRoot>
                <Form>
                  <Form.Group className="mb-3" controlId="formCurrentPassword">
                    <S.FieldLabel>New Password</S.FieldLabel>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        <FaLock />
                      </InputGroup.Text>
                      <Form.Control
                        type={!isPasswordVisible ? "password" : "text"}
                        placeholder="Enter New Password"
                        {...register("newPassword", {
                          required: true,
                          minLength: {
                            value: MIN_PASSWORD_LENGTH,
                            message: `Minimum password length is ${MIN_PASSWORD_LENGTH}`,
                          },
                          validate: {
                            containsUpper: (v) => validatePasswordUpperCase(v),
                            containsLower: (v) => validatePasswordLowerCase(v),
                            containsNumber: (v) => validatePasswordNumber(v),
                            containsSpecialChar: (v) => validatePasswordSpecialCharacter(v),
                            isNotUsername: (v) => validateIsNotUsername(v, user.username),
                            isNotEmail: (v) => validateIsNotEmail(v, user.email),
                          },
                        })}
                      />
                      <InputGroup.Text id="basic-addon2" onClick={() => togglePasswordVisible()}>
                        {!isPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Group>
                      <Form.Text id="passwordErrorBlock">
                        {errors.newPassword && errors.newPassword.message && (
                          <S.PasswordErrorSpan>{errors.newPassword.message}</S.PasswordErrorSpan>
                        )}
                        {errors.newPassword && errors.newPassword.type === "containsUpper" && (
                          <S.PasswordErrorSpan role="alert">
                            This password does not contain an uppercase letter
                          </S.PasswordErrorSpan>
                        )}
                        {errors.newPassword && errors.newPassword.type === "containsLower" && (
                          <S.PasswordErrorSpan role="alert">
                            This password does not contain a lowercase letter
                          </S.PasswordErrorSpan>
                        )}
                        {errors.newPassword && errors.newPassword.type === "containsNumber" && (
                          <S.PasswordErrorSpan role="alert">
                            This password does not contain a number
                          </S.PasswordErrorSpan>
                        )}
                        {errors.newPassword && errors.newPassword.type === "containsSpecialChar" && (
                          <S.PasswordErrorSpan role="alert">
                            This password does not contain a special character
                          </S.PasswordErrorSpan>
                        )}
                        {errors.newPassword && errors.newPassword.type === "isNotUsername" && (
                          <S.PasswordErrorSpan role="alert">
                            This password matches the user&apos;s username
                          </S.PasswordErrorSpan>
                        )}
                        {errors.newPassword && errors.newPassword.type === "isNotEmail" && (
                          <S.PasswordErrorSpan>This password matches the user&apos;s email address</S.PasswordErrorSpan>
                        )}
                      </Form.Text>
                    </Form.Group>
                    <Form.Text id="passwordHelpBlock" muted>
                      New password must be at least {MIN_PASSWORD_LENGTH} characters long contain both uppercase and
                      lowercase letters, special characters and numbers. The new password must not be the same as the
                      user&apos;s username or email address.
                    </Form.Text>
                  </Form.Group>
                </Form>
              </S.FormRoot>
            </S.PasswordGroup>
          </S.ResetPasswordContainer>
        </S.ResetPasswordRoot>
      </Modal.Body>
      <Modal.Footer>
        <ActionButton
          onClick={() => {
            reset();
            onHide();
          }}
        >
          Close
        </ActionButton>
        <ActionButton onClick={handleSubmit(onSubmit)} disabled={!isDirty || !isValid}>
          Reset Password
        </ActionButton>
      </Modal.Footer>
    </Modal>
  );
};
