import * as React from "react";
import * as Recoil from "recoil";
import ButtonCell from "components/EditComponents/ButtonCell";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import { userIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { TYPE, STATUS, COMMON } from "constants/brm";

import {
  createColumnUserName,
  createColumnMappedStringEdit,
  createColumnMapped,
  createColumnOrganization,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";
import { AdminApi } from "features/brm";
import { LoadingSpinner as Loading } from "components/elements";
import { userRoles } from "services/brm/admin-service";
import { useRelatedOrgUsers } from "../../../api/useRelatedOrgUsers";

interface IUserTableProps {
  setSelectedRows: (rows: any) => void;
}
const SUPER_ADMIN = userRoles.properties[1].key;
const statusList = [
  { id: STATUS.active, name: STATUS.active },
  { id: STATUS.suspended, name: STATUS.suspended },
];

export const UserTable = ({ setSelectedRows }: IUserTableProps) => {
  const userId = Recoil.useRecoilValue(userIdState); // current userID
  const [selectedInstance] = Recoil.useRecoilState<any>(selectedInstanceState);
  const selectedInstanceId = selectedInstance?.id || "";

  const { isAdmin, isSuperAdmin } = useRoles();

  const [editMode, setEditMode] = React.useState(false);
  const updatedStatus = React.useRef("");
  const updatedUserName = React.useRef("");
  const updatedLastName = React.useRef("");
  const updatedFirstName = React.useRef("");

  const { data: allUsers } = AdminApi.useUsers({
    options: {
      enabled: isSuperAdmin,
      select: React.useCallback((data) => data.filter((user: any) => user.status !== STATUS.deleted), []),
    },
  }); // Get list of all users irrespective of organization
  const { data: relatedOrgUsers } = useRelatedOrgUsers({
    userId,
    options: { enabled: isAdmin },
  });
  const { data: orgId } = AdminApi.useUserPrimaryOrganization({
    userId,
    options: {
      enabled: Boolean(userId),
      select: (data) => data.id,
    },
  });
  const { data: attachedUsers } = AdminApi.useOrganizationAttachedUser({
    organizationId: orgId,
    options: { enabled: Boolean(orgId) && isAdmin },
  });

  const { mutateAsync: setUserStatus } = AdminApi.useSetUserStatus();
  const { mutateAsync: setUserFirstname } = AdminApi.useSetUserFirstname();
  const { mutateAsync: setUserLastname } = AdminApi.useSetUserLastname();

  const createButton = React.useCallback(
    (cellProps) => {
      const isSA = cellProps.cell.row.original.assignedRole.includes(SUPER_ADMIN);
      if (isSA && isAdmin) {
        return COMMON.na;
      }

      if (
        (isSuperAdmin && cellProps.cell.row.original.status !== STATUS.deleted) ||
        (isAdmin &&
          cellProps.cell.row.original.status !== STATUS.deleted &&
          cellProps.cell.row.original.organization.id === orgId)
      ) {
        return (
          <ButtonCell
            selectedRowId={selectedInstanceId}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);
              // send request to update status
              if (updatedStatus.current && updatedStatus.current !== "Select status") {
                // only perform update if updatedStatus is not an empty string
                await setUserStatus({ userId: selectedInstanceId, newStatus: updatedStatus.current });
                updatedStatus.current = "";
              }

              if (updatedFirstName.current !== "") {
                await setUserFirstname({ userId: selectedInstanceId, newFirstname: updatedFirstName.current });
                updatedFirstName.current = "";
              }
              // await new Promise((r) => setTimeout(r, 1000));
              if (updatedLastName.current !== "") {
                await setUserLastname({ userId: selectedInstanceId, newLastname: updatedLastName.current });
                updatedLastName.current = "";
              }
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return COMMON.na;
    },
    [isAdmin, isSuperAdmin, orgId, selectedInstanceId, editMode, setUserStatus, setUserFirstname, setUserLastname]
  );

  const hideCheckBoxCondition = React.useCallback(
    (row) => isAdmin && row.original.organization.id !== orgId,
    [isAdmin, orgId]
  );

  const pathfilter = React.useCallback(
    (path, value) => {
      return isAdmin && value.organization.id !== orgId ? null : path;
    },
    [isAdmin, orgId]
  );

  const columns = React.useMemo(
    () => [
      createColumnUserName(selectedInstanceId, null, false, updatedUserName, "users", pathfilter),
      createColumnMappedStringEdit("firstname", selectedInstanceId, editMode, updatedFirstName),
      createColumnMappedStringEdit("lastname", selectedInstanceId, editMode, updatedLastName),
      createColumnMapped(
        "status",
        selectedInstanceId,
        statusList,
        selectedInstanceId === userId ? false : editMode,
        updatedStatus
      ),
      createColumnOrganization("organization.name"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [selectedInstanceId, pathfilter, editMode, userId, createButton]
  );

  const userData = React.useMemo(() => {
    if (allUsers || relatedOrgUsers) {
      if (isAdmin && relatedOrgUsers && attachedUsers) {
        return relatedOrgUsers.concat(attachedUsers);
      }
      return isAdmin ? relatedOrgUsers : allUsers;
    }
    return undefined;
  }, [allUsers, isAdmin, relatedOrgUsers, attachedUsers]);

  if (userData) {
    return (
      <>
        <CustomTable id="UserTable_table">
          <BrmMainTable
            data={userData}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "UserTable_table" }}
            elementName={TYPE.user}
            showRowSelect={isSuperAdmin || isAdmin}
            hideCheckBoxCondition={hideCheckBoxCondition}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};
