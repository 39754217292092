import styled from "styled-components";
import { Popover } from "react-bootstrap";

export const List = styled.ul`
  padding-left: 20px;
  list-style: none;
`;
export const ListItem = styled.li`
  padding-left: 5px;
`;

export const Title = styled.h6`
  padding: 5px 0;
  font-weight: 600;
`;

export const Container = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`;

export const ShadowPopover = styled(Popover)`
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
  border: none;
  font-size: 1rem;
  max-width: 400px;
`;
