import PropTypes from "prop-types";
import * as Recoil from "recoil";
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { nodeApi } from "services/brm/system-service";
import handleApi from "services/brm/api-util";
// Features
import {
  SystemApi,
  ElementFullDetails,
  systemModelStructureDirtyState,
  useMoveNode,
  UrlSearchParams,
} from "features/brm";

// Messenger
import messenger from "services/messenger";
// State
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
// Components
import ButtonCell from "components/EditComponents/ButtonCell";
import { LoadingSpinner as Loading } from "components/elements";
// Constants
import { TYPE } from "constants/brm";

import {
  createColumnMapped,
  createColumnCategoryMapped,
  createColumnBooleanMapped,
  createColumnBooleanNoEditMapped,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";
import * as ReactQuery from "@tanstack/react-query";
import { RoutePath } from "routes/route-paths";
import { stringToBoolean } from "utils/boolean-checker";
import NodeDetails from "../details/NodeDetails";

function NodeFullDetails({
  onCloseClick,
  nodeId = null,
  defaultTabIndex = 0,
  defaultSubTabIndex = 0,
  hideSummaryTable = true,
}) {
  const projectId = useRecoilValue(projectIdState);
  const setSystemModelStructureDirty = Recoil.useSetRecoilState(systemModelStructureDirtyState);

  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const { id: pathId } = useParams();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get(UrlSearchParams.Tab) ? Number(searchParams.get(UrlSearchParams.Tab)) : defaultTabIndex;

  const [activeId, setActiveId] = useState();
  const navigate = useNavigate();

  const queryClient = ReactQuery.useQueryClient();
  const { data: possibleParents } = SystemApi.useNodeParents({
    projectId,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: node } = SystemApi.useNode({
    nodeId: activeId,
    options: { enabled: !!activeId && (isRiskAnalyst || isSystemEngineer) },
  });
  const { data: elementCats } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: structCats } = SystemApi.useGetNodeStructuralCategories({
    projectId,
  });
  const { data: parent } = SystemApi.useNodeParent({
    childId: activeId,
    options: { enabled: !!activeId && (isRiskAnalyst || isSystemEngineer) },
  });

  const { moveNode } = useMoveNode();
  const localProjectId = useRef(projectId);

  const [selectedElement, setSelectedElement] = useState({});
  const [, setRefresh] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedIsInternal = useRef("");
  const updatedCategory = useRef("");
  const updatedStructCat = useRef("");
  const updatedParent = useRef("");

  useEffect(() => {
    if (node) {
      setSelectedElement(node);
    }
  }, [node]);

  useEffect(() => {
    setActiveId(pathId || nodeId);
    setRefresh(true);
  }, [nodeId, pathId]);

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedRowId}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedIsInternal.current !== "") {
              const isInternal = stringToBoolean(updatedIsInternal.current);

              moveNode(
                { nodeId: selectedRowId, parentId: null, isInternal },
                {
                  onSuccess: () => {
                    messenger.dispatch("nodeParentChangedById", {
                      nodeDataId: selectedRowId,
                      isInternal,
                      parentId: null,
                    });
                  },
                  markSystemModelDirty: true,
                }
              );
              updatedIsInternal.current = "";
            }

            if (updatedStructCat.current !== "") {
              await handleApi(
                nodeApi.setNodeStructuralcatWithHttpInfo(selectedRowId, {
                  body: updatedStructCat.current,
                })
              );
              updatedStructCat.current = "";
            }

            if (updatedCategory.current !== "") {
              await handleApi(
                nodeApi.setNodeCategoryWithHttpInfo(selectedRowId, {
                  body: updatedCategory.current,
                })
              );
              updatedCategory.current = "";
            }

            if (updatedParent.current !== "") {
              if (updatedParent.current === "-") {
                await handleApi(nodeApi.setNodeParentWithHttpInfo(selectedRowId, { body: null }));
              } else {
                await handleApi(nodeApi.setNodeParentWithHttpInfo(selectedRowId, { body: updatedParent.current }));
              }
              setSystemModelStructureDirty(true);
              updatedParent.current = "";
            }

            setRefresh(true);
            queryClient.invalidateQueries(["nodes"]);
            queryClient.invalidateQueries(["node", activeId]);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [selectedRowId, editMode, queryClient, activeId, moveNode, setSystemModelStructureDirty]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnCategoryMapped("category", selectedRowId, elementCats, editMode, updatedCategory),
        createColumnBooleanMapped("isInternal", selectedRowId, editMode, updatedIsInternal),
        createColumnBooleanNoEditMapped("isBoundary"),
        createColumnCategoryMapped("structcat", selectedRowId, structCats, editMode, updatedStructCat),
        createColumnMapped("parent", selectedRowId, possibleParents, editMode, updatedParent),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnCategoryMapped("category", selectedRowId, elementCats, false, null),
      createColumnBooleanMapped("isInternal", selectedRowId, false, null),
      createColumnBooleanNoEditMapped("isBoundary"),
      createColumnCategoryMapped("structcat", selectedRowId, structCats, false, null),
      createColumnMapped("parent", selectedRowId, possibleParents, false, null),
    ];
  }, [isSystemEngineer, selectedRowId, elementCats, structCats, possibleParents, editMode, createButton]);

  useEffect(() => {
    if (pathId && projectId !== localProjectId.current) {
      navigate(RoutePath.Nodes);
    }
  }, [projectId, pathId, navigate]);

  if (node && parent && selectedElement) {
    node.parent = parent;

    const nodeDetails = (
      <NodeDetails selectedElement={node} defaultTabIndex={tab} defaultSubTabIndex={defaultSubTabIndex} />
    );

    return (
      <ElementFullDetails
        onCloseClick={onCloseClick}
        showBackLink={!onCloseClick}
        data={[node]}
        columns={columns}
        selectedElement={node}
        setSelectedElement={setSelectedElement}
        setSelectedRowId={setSelectedRowId}
        detailsComp={nodeDetails}
        compName={TYPE.node}
        hideSummaryTable={hideSummaryTable}
      />
    );
  }

  return <Loading />;
}

NodeFullDetails.propTypes = {
  nodeId: PropTypes.any,
  onCloseClick: PropTypes.any,
  defaultTabIndex: PropTypes.number,
  defaultSubTabIndex: PropTypes.number,
  hideSummaryTable: PropTypes.bool,
};

export default NodeFullDetails;
