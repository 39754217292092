import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

interface ICreateControlType {
  controlFamilyId: string;
  controlTypeCreateDto: any;
}

/**
 * Function to create a new control type
 *
 * @param {ControlTypeCreateDto} controlTypeCreateDto creation dto
 * @returns id of created control type
 */
export const createControlType = ({ controlFamilyId, controlTypeCreateDto }: ICreateControlType) => {
  return Brm.controlTypeApi.createControlType(controlFamilyId, { controlTypeCreateDto });
};

interface IUseCreateControlType {
  options?: MutationOption<typeof createControlType>;
}

/**
 * Custom hook to create a new control type.
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for useCreateControlType
 */
export const useCreateControlType = ({ options }: IUseCreateControlType = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.controlTypeKeys.all);
    },
    ...options,
    mutationFn: createControlType,
  });
};
