import * as React from "react";
import * as Recoil from "recoil";

import toast from "react-hot-toast";
// State
import { riskUpdatedState } from "atoms/atoms-risk";
import { leftSidebarOpenState } from "atoms/atoms-layout";
import {
  SystemApi,
  MitigationApi,
  currentProjectState,
  currentVariantState,
  isCurrentProjectGeneratingState,
  isCurrentProjectImportingState,
  calculateRiskToastIdState,
} from "features/brm";
import { queryClient } from "libs/react-query";
import { useCalculateRisk } from "features/risk";
// Styles
import S from "./CalculateRiskButton.styles";

const TOAST_DURATION = 4000;

export const CalculateRiskButton = () => {
  // Global State
  const [toastId, setToastId] = Recoil.useRecoilState(calculateRiskToastIdState);
  const isCurrentProjectGenerating = Recoil.useRecoilValue(isCurrentProjectGeneratingState);
  const isCurrentProjectImporting = Recoil.useRecoilValue(isCurrentProjectImportingState);
  const currentProject = Recoil.useRecoilValue(currentProjectState);
  const currentVariant = Recoil.useRecoilValue(currentVariantState);
  const isDrawerVisible = Recoil.useRecoilValue(leftSidebarOpenState);
  const setRiskUpdated = Recoil.useSetRecoilState(riskUpdatedState);
  const setDrawerVisible = Recoil.useSetRecoilState(leftSidebarOpenState);

  // Local State
  const [disabled, setDisabled] = React.useState(false);
  const [succ, setSucc] = React.useState(false);

  const doSuccess = React.useCallback(() => {
    queryClient.invalidateQueries(SystemApi.nodeKeys.all);
    queryClient.invalidateQueries(SystemApi.exchangeKeys.all);
    queryClient.invalidateQueries(MitigationApi.variantsKeys.all);
    queryClient.invalidateQueries(["SystemDiagramData"]); // TODO should be a constant somewhere..
    queryClient.invalidateQueries(["risks"]); // TODO check if still valid?
    queryClient.invalidateQueries(["getVariants"]); // TODO check if still valid?
    queryClient.invalidateQueries(["getVariantsFullDetails"]); // TODO check if still valid?
    queryClient.invalidateQueries(["getUndesiredEvents"]); // TODO check if still valid?

    toast.remove();

    if (!isDrawerVisible) {
      setDrawerVisible(true);
    }
    setRiskUpdated(true);
    setDisabled(false);
    setSucc(true);
  }, [isDrawerVisible, setDrawerVisible, setRiskUpdated]);

  const { calculateRisk } = useCalculateRisk({
    onSuccess: () => {
      setToastId(null);
      doSuccess();
    },
    onError: () => {
      console.error("Error occurred during risk calculation.");
      if (isCurrentProjectGenerating && toastId) {
        toast.remove(toastId);
        setToastId(null);
      }
      toast.error(<span id="riskCalculationToast">An error occurred while calculating risk.</span>, {
        duration: TOAST_DURATION,
      });
      setToastId(null);
    },
  });

  React.useEffect(() => {
    if (succ) {
      toast.success(<span id="riskCalculationToast1">Risk Calculation Complete</span>, {
        duration: TOAST_DURATION,
      });
      setSucc(false);
    }
  }, [succ]);

  React.useEffect(() => {
    setDisabled(isCurrentProjectImporting);
  }, [isCurrentProjectImporting]);

  React.useEffect(() => {
    if (isCurrentProjectGenerating && !toastId) {
      const loadingToastId = toast.loading("Calculating risk...");
      // const loadingToastId = toast.custom((t) => {
      //   t.type = "loading";
      //   t.duration = undefined;
      //   return <DismissableToast toastObj={t} message="Calculating risk..." />;
      // });
      setDisabled(true);
      setToastId(loadingToastId);
    } else if (!isCurrentProjectGenerating && toastId) {
      setDisabled(false);
      toast.remove(toastId);
      setToastId(null);
    }
    return () => {};
  }, [isCurrentProjectGenerating, setToastId, toastId]);

  const handleClick = async () => {
    setDisabled(true);
    calculateRisk({ project: currentProject!.id, variant: currentVariant?.id || "" });
  };

  return (
    <S.RiskButton disabled={disabled} onClick={handleClick} id="CalcRiskButton_button">
      Calculate Risks
    </S.RiskButton>
  );
};
