import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./user-query-key-factory";

interface IDeleteUserProps {
  userId: string | null;
}

/**
 * Delete User
 *
 * @param param0 user to delete
 * @returns
 */
export const deleteUser = ({ userId }: IDeleteUserProps) => {
  return userId === null ? Promise.reject(new Error("Invalid userId id")) : Brm.userApi.deleteUser(userId);
};

interface ISetNameProps {
  options?: MutationOption<typeof deleteUser>;
}

/**
 * Custom hook to Delete User
 *
 * @returns react-query Mutation.
 */
export const useDeleteUser = ({ options }: ISetNameProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.userKeys.user(variables.userId));
      queryClient.invalidateQueries(KeyFactory.userKeys.all);
    },
    ...options,
    mutationFn: deleteUser,
  });
};
