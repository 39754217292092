import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./dataflow-query-key-factory";

/**
 * Returns a dataflow
 *
 * @param {string} dataFlowId - uuid of dataflow
 * @returns a dataflow
 */
export const getDataflowExchanges = ({ dataflowId }: { dataflowId: string }) => {
  return Brm.dataflowApi.getDataFlowExchange(dataflowId);
};

interface IUseDataflowExchanges {
  dataflowId: string;
  options?: QueryOption<typeof getDataflowExchanges>;
}

/**
 * Return the DataflowDto .
 *
 * @param {string} dataflowId - uuid of dataflow
 * @param {object} options - react-query configuration object
 * @returns consumer for this exchange
 */
export const useDataflowExchanges = ({ dataflowId, options }: IUseDataflowExchanges) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.dataflowKeys.exchanges(dataflowId),
    queryFn: () => getDataflowExchanges({ dataflowId }),
  });
};
