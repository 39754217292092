import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

export const getSecurityObjective = async ({ soId }) => {
  const data = await Brm.securityObjectiveApi.getSecurityObjectiveByID(soId);
  return [data];
};

/**
 * React-query hook for getting all details of SecurityObjective by id
 *
 * @param {string} soId - uuid of the security objective
 * @returns react-query for getSecurityObjective
 */
export const useSecurityObjective = ({ soId, setSelectedElement, options = {} }) => {
  return ReactQuery.useQuery(["securityObjective", soId], () => getSecurityObjective({ soId }), {
    ...options,
    onSuccess: (data) => {
      setSelectedElement(data[0]);
    },
  });
};
