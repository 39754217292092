import { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { /* AddButton, */ DeleteButton } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { categorizedEntityApi } from "services/brm/categorization-service";
import { TYPE } from "constants/brm";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { useRoles } from "features/brm/hooks/useRoles";
import CategorizedEntityTable from "./tables/CategorizedEntityTable";
// import CategorizedEntityAddForm from "./forms/CategorizedEntityAddForm";

function CategorizedEntity() {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const queryClient = useQueryClient();
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  useEffect(() => {
    async function multiDeleteApiCalls() {
      Promise.all(selectedRows.map((each) => categorizedEntityApi.deleteCategorizedEntityWithHttpInfo(each.id)))
        .then(() => {
          queryClient.invalidateQueries();
          setConfirmDelete(false);
          setDeleteModalIsOpen(false);
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, projectId, queryClient, selectedRows, setSelectedInstance]);

  // const addCategorizedEntity = () => {
  //   setModalIsOpen(true);
  // };

  const catEntityHeader = (
    <Title id="CategorizationEntityList_title">
      Categorized Entities
      <Buttons id="CategorizationEntityList_buttons">
        {isRiskAnalyst || isSystemEngineer ? (
          <>
            {selectedRows?.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
            {/* <AddButton onClick={addCategorizedEntity} /> */}
          </>
        ) : (
          <br />
        )}
      </Buttons>
    </Title>
  );

  const catEntityMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "CategorizedEntityList_Panel",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.categorizedEntity}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.categorizedEntity
        )}

      {/* {modalIsOpen &&
        systemModals.addModal(
          "CategorizedEntityList_Panel",
          modalIsOpen,
          setModalIsOpen,
          <CategorizedEntityAddForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} refresh={refresh} />,
          TYPE.categorizedEntity
        )} */}

      <CategorizedEntityTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="CategorizedEntityList_Panel" header={catEntityHeader} main={catEntityMain} />;
}

export default CategorizedEntity;
