import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./attacker-query-key-factory";

export interface ISetAttackerGroup {
  attackerId: string;
  group: string;
}

/**
 * sets group of the selected attacker
 * @param {string} attackerId - uuid of the attacker
 * @param {string} group - uuid of threat group
 *
 */
export const setAttackerGroup = ({ attackerId, group }: ISetAttackerGroup) => {
  return Brm.attackerApi.setAttackerGroup(attackerId, { body: group });
};

export interface IUseSetAttackerGroup {
  options?: MutationOption<typeof setAttackerGroup>;
}

/**
 * Custom hook to change the group of a existing Attacker
 * @param {object} options hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerGroup = ({ options }: IUseSetAttackerGroup = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.attackerKeys.all);
    },
    ...options,
    mutationFn: setAttackerGroup,
  });
};
