import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./threat-event-query-key-factory";

interface IGethreatEventCategories {
  projectId: string;
}

export const gethreatEventCategories = ({ projectId }: IGethreatEventCategories) => {
  return Brm.threatEventCategoryApi.findThreatEventCategory(projectId);
};

interface IThreatEventCategories {
  projectId: string;
  options?: QueryOption<typeof gethreatEventCategories>;
}

export const useThreatEventCategories = ({ projectId, options }: IThreatEventCategories) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatEventKeys.categories(projectId),
    queryFn: () => gethreatEventCategories({ projectId }),
  });
};
