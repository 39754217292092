import * as React from "react";
import { contentTypeState, CONTENT_TYPE } from "atoms/atoms-content";
import { useSetRecoilState } from "recoil";
import { Breadcrumb } from "../../elements/Breadcrumb";
import * as S from "./DiagramContentLayout.styles";

type CKey = typeof CONTENT_TYPE[keyof typeof CONTENT_TYPE];

interface IDefaultContentLayoutProps {
  contentKey: string;
  children: React.ReactNode;
  type: CKey;
}

export const DiagramContentLayout = ({ contentKey, type, children }: IDefaultContentLayoutProps) => {
  const setContentType = useSetRecoilState(contentTypeState);

  React.useEffect(() => {
    setContentType(type);
  }, [setContentType, type]);

  return (
    <S.DiagramLayoutContainer>
      <S.CrumbContainer>
        <Breadcrumb contentKey={contentKey} />
      </S.CrumbContainer>
      <S.DiagramContainer>{children}</S.DiagramContainer>
    </S.DiagramLayoutContainer>
  );
};
