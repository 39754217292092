import * as React from "react";
// Utils
import errorUtils from "utils/error-utils";
// State
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
// Services
import { ModuleCreateDto } from "@kdmanalytics/brm-component";
import { moduleApi } from "services/brm/component-service";
// Components
import TextField from "components/forms/TextField";
// Constants
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";

interface IAddModuleFormProps {
  setModalIsOpen: (value: boolean) => void;
  setRefresh: (value: boolean) => void;
}

export const AddModuleForm = ({ setModalIsOpen, setRefresh }: IAddModuleFormProps) => {
  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = React.useState("");
  const [formData, setFormData] = React.useState();
  const [isTextValid, setIsTextValid] = React.useState(true);

  // validate that all fields in the form have been completed
  function validate(frmData: any) {
    let error = true;
    if (
      frmData.name.value === "" ||
      // formData.importSource.value === "" ||
      frmData.sourceType.value === ""
    ) {
      error = false;
    }
    return error;
  }

  function onFileUpload(e: any) {
    setFormData(e.target.files[0]);
  }

  async function handleSubmit(e: any) {
    e.preventDefault();

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value,
        importSource: formData,
        sourceType: e.target.elements.sourceType.value,
      };
      const dto = (ModuleCreateDto as any).constructFromObject(params);
      const opts = { moduleCreateDto: dto };
      try {
        const res = await moduleApi.createModuleWithHttpInfo(projectId, opts);

        errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
        setModalIsOpen(false);
        setRefresh(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  return (
    <div>
      <form onSubmit={handleSubmit} action="">
        <FormStyled>
          <div className="form-style">
            <TextField
              label={{ id: "ModuleAddForm_name", name: FORM_LABEL.nameMandatory }}
              input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
              setIsTextValid={setIsTextValid}
            />
            <TextField
              label={{ id: "ModuleAddForm_note", name: FORM_LABEL.note }}
              input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
              setIsTextValid={setIsTextValid}
            />

            <label>{FORM_LABEL.sourceTypeMandatory}</label>
            <select name="sourceType" id="sourceType">
              <option>--- Select Source Type ---</option>
              <option value="xml">XML</option>
              <option value="mdxml">MDXML</option>
              <option value="docx">DOCX</option>
              <option value="json">JSON</option>
            </select>
            <label>Input Source</label>
            <input type="file" name="inputSource" placeholder="inputSource" onChange={(e) => onFileUpload(e)} />
          </div>
        </FormStyled>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
          <DialogButtonStyled type="submit">Add</DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};
