import { SystemAssetCategoryApi, SystemAssetTypeApi, SystemAssetApi } from "@kdmanalytics/brm-system";
// import { updateToken, BEARER_AUTH } from "services/brm/service-util";
import BrmApiClient from "services/brm/BrmApiClient";

// const auth = BrmApiClient.instance.authentications[BEARER_AUTH];
export const systemAssetCategoryApi = new SystemAssetCategoryApi(BrmApiClient.instance);
export const systemAssetTypeApi = new SystemAssetTypeApi(BrmApiClient.instance);
export const systemAssetApi = new SystemAssetApi(BrmApiClient.instance);

const createSystemAssetCategory = async (projectId, createSACatDto) => {
  // await updateToken(auth);
  const opts = { systemAssetCategoryCreateDto: createSACatDto };
  return systemAssetCategoryApi.createSystemAssetCategoryWithHttpInfo(projectId, opts);
};

const getSystemAssetCategories = async (project) => {
  // await updateToken(auth);
  return systemAssetCategoryApi.findSystemAssetCategoryWithHttpInfo(project);
};

const getSystemAssetCategoryById = async (saCatId) => {
  // await updateToken(auth);
  return systemAssetCategoryApi.getSystemAssetCategoryByIDWithHttpInfo(saCatId);
};

const createSystemAssetType = async (projectId, createSATypeDto) => {
  // await updateToken(auth);
  const opts = { systemAssetTypeCreateDto: createSATypeDto };
  return systemAssetTypeApi.createSystemAssetTypeWithHttpInfo(projectId, opts);
};

const getSystemAssetTypes = async (project) => {
  // await updateToken(auth);
  return systemAssetTypeApi.findSystemAssetTypeWithHttpInfo(project);
};

const getSystemAssetTypeById = async (saTypeId) => {
  // await updateToken(auth);
  return systemAssetTypeApi.getSystemAssetTypeByIDWithHttpInfo(saTypeId);
};

const getSystemAssetTypeAttacks = async (satId) => {
  // await updateToken(auth);
  return systemAssetTypeApi.getSystemAssetTypeAllAttackWithHttpInfo(satId);
};

const getSystemAssetTypeAttackers = async (satId) => {
  // await updateToken(auth);
  return systemAssetTypeApi.getSystemAssetTypeAllAttackerWithHttpInfo(satId);
};

const createSystemAsset = async (projectId, createSADto) => {
  // await updateToken(auth);
  const opts = { systemAssetCreateDto: createSADto };
  return systemAssetApi.createSystemAssetWithHttpInfo(projectId, opts);
};

const getSystemAssets = async (project) => {
  // await updateToken(auth);
  return systemAssetApi.findSystemAssetWithHttpInfo(project);
};

const getSystemAssetById = async (saId) => {
  // await updateToken(auth);
  return systemAssetApi.getSystemAssetByIDWithHttpInfo(saId);
};

const getSystemAssetsByCategory = async (project, cat) => {
  // await updateToken(auth);
  const opts = { cat };
  return systemAssetApi.findSystemAssetWithHttpInfo(project, opts);
};

const getSystemAssetsByType = async (project, type) => {
  // await updateToken(auth);
  const opts = { satype: type };
  return systemAssetApi.findSystemAssetWithHttpInfo(project, opts);
};

const getSystemAssetCategory = async (saId) => {
  // await updateToken(auth);
  return systemAssetApi.getSystemAssetCategoryWithHttpInfo(saId);
};

const getSystemAssetOwner = async (saId) => {
  // await updateToken(auth);
  return systemAssetApi.getSystemAssetOwnerWithHttpInfo(saId);
};

const getSystemAssetSaType = async (saId) => {
  // await updateToken(auth);
  return systemAssetApi.getSystemAssetSatypeWithHttpInfo(saId);
};

const getSystemAssetTrevs = async (saId) => {
  // await updateToken(auth);
  return systemAssetApi.getSystemAssetAllTrevWithHttpInfo(saId);
};

const getSystemAssetDependentUes = async (saId) => {
  // await updateToken(auth);
  return systemAssetApi.getSystemAssetAllDependentUeWithHttpInfo(saId);
};

const getSystemAssetDependentRisks = async (saId) => {
  // await updateToken(auth);
  return systemAssetApi.getSystemAssetAllDependentRiskWithHttpInfo(saId);
};

const getSystemAssetDependentAssets = async (saId) => {
  // await updateToken(auth);
  return systemAssetApi.getSystemAssetAllDependentAssetWithHttpInfo(saId);
};

const getSystemAssetAttackers = async (saId) => {
  // await updateToken(auth);
  return systemAssetApi.getSystemAssetAllAttackerWithHttpInfo(saId);
};

const getSystemAssetAttacks = async (saId) => {
  // await updateToken(auth);
  return systemAssetApi.getSystemAssetAllAttackWithHttpInfo(saId);
};

const SystemAssetService = {
  createSystemAssetCategory,
  getSystemAssetCategories,
  getSystemAssetCategoryById,
  createSystemAssetType,
  getSystemAssetTypes,
  getSystemAssetTypeById,
  createSystemAsset,
  getSystemAssets,
  getSystemAssetById,
  getSystemAssetsByCategory,
  getSystemAssetsByType,
  getSystemAssetTypeAttacks,
  getSystemAssetTypeAttackers,
  getSystemAssetCategory,
  getSystemAssetSaType,
  getSystemAssetOwner,
  getSystemAssetTrevs,
  getSystemAssetDependentAssets,
  getSystemAssetDependentRisks,
  getSystemAssetDependentUes,
  getSystemAssetAttackers,
  getSystemAssetAttacks,

  systemAssetCategoryApi,
  systemAssetTypeApi,
  systemAssetApi,
};
export default SystemAssetService;
