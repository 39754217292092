import * as React from "react";

/**
 * Multi Step hook encapsulate the basic multi step form logic
 * @param steps
 * @returns
 */
export const useMultiStepForm = (steps: React.ReactElement[]) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
  const [isStepComplete, setStepComplete] = React.useState(false);

  const next = () => {
    setCurrentStepIndex((i) => {
      if (i >= steps.length - 1) {
        return i;
      }
      return i + 1;
    });
  };

  const prev = () => {
    setCurrentStepIndex((i) => {
      if (i <= 0) {
        return i;
      }
      return i - 1;
    });
  };

  const goTo = (index: number) => {
    setCurrentStepIndex(index);
  };

  return {
    goTo,
    next,
    prev,
    setStepComplete,
    currentStepIndex,
    currentStep: steps[currentStepIndex],
    steps,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    isStepComplete,
  };
};
