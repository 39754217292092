import toast from "react-hot-toast";
import { useState, useMemo, useRef, useCallback } from "react";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable, RetainStringColumnFilter } from "brm/tables/BrmTables";
import { TYPE } from "constants/brm";
import { SystemApi } from "features/brm";
import { useCanRename } from "features/system-model";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { useQueryClient } from "@tanstack/react-query";
import {
  createColumnName,
  createColumnBooleanMapped,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";

interface IResourceProps {
  onRowSelect: (...args: any[]) => void;
}

const ResourceTable = ({ onRowSelect }: IResourceProps) => {
  const { canRename } = useCanRename();
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedCategory = useRef("");
  const updatedIsInternal = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState<any>(selectedInstanceState);

  const queryClient = useQueryClient();
  const { data: resourcesData, isError: isResourceDataError } = SystemApi.useResources({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });

  const { mutate: setResourceName } = SystemApi.useSetResourceName({
    options: {
      onSettled: () => {
        setSelectedInstance({
          id: selectedInstance.id,
          name: updatedName.current,
          type: selectedInstance.type,
        });
        updatedName.current = "";
      },
      onError: (err) => {
        toast.error(`Setting resource name caused an error: ${err}`);
      },
    },
  });

  const { mutate: setResourceCategory } = SystemApi.useSetResourceCategory({
    options: {
      onSettled: () => {
        updatedCategory.current = "";
      },
      onError: (err) => {
        toast.error(`Setting resource category caused an error: ${err}`);
      },
    },
  });
  const { mutate: setResourceIsInternal } = SystemApi.useSetResourceIsInternal({
    options: {
      onSettled: () => {
        updatedIsInternal.current = "";
      },
      onError: (err) => {
        toast.error(`Setting resource internal caused an error: ${err}`);
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedName.current !== "") {
              if (!canRename({ updatedName, elementsList: resourcesData })) return;
              setResourceName({ resourceId: selectedInstance.id, name: updatedName.current });
            }

            if (updatedIsInternal.current !== "") {
              setResourceIsInternal({ resourceId: selectedInstance.id, isInternal: updatedIsInternal.current });
            }

            if (updatedCategory.current !== "") {
              setResourceCategory({ resourceId: selectedInstance.id, category: updatedCategory.current });
            }
            queryClient.invalidateQueries(SystemApi.resourceKeys.all);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [
      selectedInstance.id,
      editMode,
      queryClient,
      canRename,
      resourcesData,
      setResourceName,
      setResourceIsInternal,
      setResourceCategory,
    ]
  );

  const checkNameUniqueness = useCallback(
    (newName) => {
      const trimedNamed = newName.trim();
      return isSystemEngineer && Array.isArray(resourcesData)
        ? !resourcesData.map((resource) => resource.name.toLowerCase()).includes(trimedNamed.toLowerCase())
        : false;
    },
    [isSystemEngineer, resourcesData]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnName(
          selectedInstance.id,
          setSelectedInstance,
          editMode,
          updatedName,
          "resource",
          checkNameUniqueness,
          {
            Filter: RetainStringColumnFilter,
          }
        ),
        createColumnBooleanMapped("isInternal", selectedInstance.id, editMode, updatedIsInternal),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }

    return [
      createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "resource"),
      createColumnBooleanMapped("isInternal", selectedInstance.id, false, updatedIsInternal),
    ];
  }, [isSystemEngineer, selectedInstance.id, setSelectedInstance, editMode, checkNameUniqueness, createButton]);

  if (isResourceDataError) {
    return <ErrorBanner msg="Error while loading Resources" />;
  }

  if (resourcesData) {
    return (
      <CustomTable>
        <BrmMainTable
          data={resourcesData}
          columns={columns}
          setSelectedRows={onRowSelect}
          customProps={{ id: "ResourceTable_table" }}
          elementName={TYPE.resource}
          showRowSelect={isSystemEngineer}
        />
      </CustomTable>
    );
  }

  return <Loading />;
};

export default ResourceTable;
