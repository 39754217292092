import { atom, selector } from "recoil";
import { controlCatalogApi } from "services/brm/global-control-service";
import _ from "lodash";

export async function getCurrentCatalog(id) {
  const res = await controlCatalogApi.getControlCatalogByIDWithHttpInfo(id);
  return res.data;
}

/**
 * Selected control catalog atom
 */
export const controlCatalogIdState = atom({
  key: "controlCatalogId",
  default: null,
});

export const controlCatalogAddedState = atom({
  key: "controlCatalogAdded",
  default: false,
});

export const selectedControlCatalogIdState = atom({
  key: "selectedControlCatalogIdState",
  default: "",
});

/**
 * Selected control family atom
 */
export const controlFamilyIdState = atom({
  key: "controlFamilyId",
  default: null,
});

/**
 * Current catalog
 * NOTE: <React.Suspense> MUST wrap the component this selector is used in (since its aysnc)
 */
export const currentControlCatalogState = selector({
  key: "currentControlCatalogState",
  get: async ({ get }) => {
    let id = null;
    try {
      id = get(controlCatalogIdState);
      if (id) {
        const res = await controlCatalogApi.getControlCatalogByIDWithHttpInfo(id);
        if (_.isEmpty(res)) {
          console.error(`Failed to get control catalog for current id: '${id}'.  Error in res: `, res);
          return {};
        }
        return res.data;
      }
      return {};
    } catch (err) {
      console.error(`Failed to get control catalog for current id: '${id}'.  Error: `, err);
    }
    return {};
  },
});
