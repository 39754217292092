import * as YFiles from "yfiles";
import { DiagramType, LayoutType } from "features/diagram-constants";
import { TYPE } from "constants/brm";
import { createLayoutWorker } from "worker";

const layoutWorker = createLayoutWorker();

const createOrganicLayoutData = () => {
  return new YFiles.OrganicLayoutData();
};

const createCircularLayoutData = () => {
  return new YFiles.CircularLayoutData();
};

const createRadialLayoutData = () => {
  const layoutData = new YFiles.RadialLayoutData();
  layoutData.centerNodes = YFiles.DpKeyItemCollection.from((node) => node.tag.type === TYPE.assetCat);
  return layoutData;
};

const createHierarchicalLayoutData = () => {
  return new YFiles.HierarchicLayoutData();
};

const createOrthogonalLayoutData = () => {
  return new YFiles.OrthogonalLayoutData();
};

export const createLayoutData = (type: LayoutType) => {
  const layoutDatas: { [key: string]: () => YFiles.LayoutData } = {
    [LayoutType.ORGANIC.name]: createOrganicLayoutData,
    [LayoutType.HIERARCHICAL.name]: createHierarchicalLayoutData,
    [LayoutType.ORTHOGONAL.name]: createOrthogonalLayoutData,
    [LayoutType.CIRCULAR.name]: createCircularLayoutData,
    [LayoutType.RADIAL.name]: createRadialLayoutData,
  };
  return layoutDatas[type.name]();
};

const createOrganicLayoutOptions = () => {
  return {
    deterministic: true,
    nodeEdgeOverlapAvoided: true,
    compactnessFactor: 0.95,
    preferredEdgeLength: 80,
  };
};

const createCircularLayoutOptions = () => {
  return {
    considerNodeLabels: false,
    labelingEnabled: true,
    layoutStyle: YFiles.CircularLayoutStyle.BCC_COMPACT,
  };
};

const createRadialLayoutOptions = () => {
  return {
    centerNodesPolicy: YFiles.CenterNodesPolicy.CUSTOM,
    layerSpacing: 0,
    labelingEnabled: true,
    maximumChildSectorAngle: 360,
  };
};

const createHierarchicalLayoutOptions = () => {
  return {
    recursiveGroupLayering: true,
    considerNodeLabels: true,
    layoutMode: YFiles.LayoutMode.INCREMENTAL,
  };
};

const createOrthogonalLayoutOptions = () => {
  return { considerNodeLabels: true };
};

export const createLayoutOptions = (type: LayoutType) => {
  const layoutOptions: { [key: string]: () => any } = {
    [LayoutType.ORGANIC.name]: createOrganicLayoutOptions,
    [LayoutType.HIERARCHICAL.name]: createHierarchicalLayoutOptions,
    [LayoutType.ORTHOGONAL.name]: createOrthogonalLayoutOptions,
    [LayoutType.CIRCULAR.name]: createCircularLayoutOptions,
    [LayoutType.RADIAL.name]: createRadialLayoutOptions,
  };
  return layoutOptions[type.name]();
};

export const executeLayout = async (
  graphComponent: YFiles.GraphComponent,
  layoutType: LayoutType,
  animated: boolean = true
) => {
  const webWorkerMessageHandler = (data: unknown): Promise<any> => {
    return new Promise((resolve) => {
      layoutWorker.onmessage = (e: any) => resolve(e.data);
      layoutWorker.postMessage(data);
    });
  };

  const layoutOptions = createLayoutOptions(layoutType);
  const layoutData = createLayoutData(layoutType);

  const properties = {
    diagram: DiagramType.ATTACK.name,
    type: layoutType.name,
    ...layoutOptions,
  };

  const executor = new YFiles.LayoutExecutorAsync({
    messageHandler: webWorkerMessageHandler,
    graphComponent,
    duration: YFiles.TimeSpan.fromMilliseconds(500),
    layoutData: layoutData as YFiles.LayoutData,
    layoutDescriptor: { name: "UserDefined", properties },
    animateViewport: animated,
    easedAnimation: animated,
  });

  return executor.start();
};
