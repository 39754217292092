// Utils
import { keyToRank } from "services/user";

/* This is a utility file that can be passed into the sort() method to sort an array by name */

const reSplitAlphaNumeric = /([0-9]+)/gm;

export const alphanumeric = (valueA, valueB) => {
  if (valueA && !valueB) {
    return 1;
  }
  if (!valueA && valueB) {
    return -1;
  }
  if (!valueA && !valueB) {
    return 0;
  }

  // Force to strings (or "" for unsupported types)
  let a = valueA.toString(valueA).toLowerCase();
  let b = valueB.toString(valueB).toLowerCase();

  // Split on number groups, but keep the delimiter
  // Then remove falsey split values
  a = valueA.split(reSplitAlphaNumeric).filter(Boolean);
  b = valueB.split(reSplitAlphaNumeric).filter(Boolean);

  // While
  while (a.length && b.length) {
    const aa = a.shift();
    const bb = b.shift();

    const an = parseInt(aa, 10);
    const bn = parseInt(bb, 10);

    const combo = [an, bn].sort();

    // Both are string
    if (Number.isNaN(combo[0])) {
      if (aa > bb) {
        return 1;
      }
      if (bb > aa) {
        return -1;
      }
      // eslint-disable-next-line no-continue
      continue;
    }

    // One is a string, one is a number
    if (Number.isNaN(combo[1])) {
      return Number.isNaN(an) ? -1 : 1;
    }

    // Both are numbers
    if (an > bn) {
      return 1;
    }
    if (bn > an) {
      return -1;
    }
  }

  return a.length - b.length;
};

export const sortByLabelAlphanumeric = (a, b) => {
  const valueA = a.label ? a.label.toUpperCase() : ""; // ignore upper and lowercase
  const valueB = b.label ? b.label.toUpperCase() : ""; // ignore upper and lowercase
  return alphanumeric(valueA, valueB);
};

export const sortByLabel = (a, b) => {
  const nameA = a.label ? a.label.toUpperCase() : ""; // ignore upper and lowercase
  const nameB = b.label ? b.label.toUpperCase() : ""; // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

export const sortUpperCase = (a, b) => {
  const A = a.toUpperCase();
  const B = b.toUpperCase();
  if (A < B) {
    return -1;
  }
  if (A > B) {
    return 1;
  }
  // names must be equal
  return 0;
};

export const sortByName = (a, b) => {
  const nameA = a.name ? a.name.toUpperCase() : a.username.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name ? b.name.toUpperCase() : b.username.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

export const sortByRiskRank = (a, b) => {
  const rankA = a?.rank?.value;
  const rankB = b?.rank?.value;
  if (rankA < rankB) {
    return -1;
  }
  if (rankA > rankB) {
    return 1;
  }
  // names must be equal
  return 0;
};

export const sortByRiskDistributionRank = (a, b) => {
  const rankA = a?.riskDistributionRank;
  const rankB = b?.riskDistributionRank;
  if (rankA < rankB) {
    return -1;
  }
  if (rankA > rankB) {
    return 1;
  }
  // names must be equal
  return 0;
};

export const sortByRanks = (a, b) => {
  const rankA = keyToRank[a?.value || ""] || 0;
  const rankB = keyToRank[b?.value || ""] || 0;

  if (rankA < rankB) {
    return -1;
  }
  if (rankA > rankB) {
    return 1;
  }
  // names must be equal
  return 0;
};
