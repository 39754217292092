import { useState, useEffect } from "react";
// Utils
import errorUtils from "utils/error-utils";
// State
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
// Components
import SelectBox from "components/forms/DynamicLevelSelectBox";
import TextField from "components/forms/TextField";
// Constants
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
// Services
import { classificationCategoryApi, securityObjectiveApi } from "services/brm/categorization-service";
import { SecurityObjectiveCreateDto } from "@kdmanalytics/brm-system";

import PropTypes from "prop-types";

const AddSecurityObjectiveForm = ({ setModalIsOpen, setRefresh, refresh }) => {
  const projectId = useRecoilValue(projectIdState);
  // Dropdowns for forms
  const [postError, setPostError] = useState("");
  const [, setLettersList] = useState([]);
  const [levelsList] = useState([
    { id: 0, name: { level: "Very High", letter: "VH" } },
    { id: 1, name: { level: "High", letter: "H" } },
    { id: 2, name: { level: "Moderate", letter: "M" } },
    { id: 3, name: { level: "Low", letter: "L" } },
    { id: 4, name: { level: "Very Low", letter: "VL" } },
  ]);
  const [isTextValid, setIsTextValid] = useState(true);

  useEffect(() => {
    async function getData() {
      classificationCategoryApi
        .findClassificationCategoryWithHttpInfo(projectId)
        .then((res) => {
          // errorUtils.checkErrorStatus(res.status, res.statusText)
          setLettersList(res.data);
        })
        .catch(async (error) => {
          console.error("error in getAllClassification : ", error);
        });
    }
    getData();
  }, [projectId]);

  // validate that all fields in the form have been completed
  function validate(formData) {
    let error = true;
    if (formData.name.value === "" || formData.levels.value === "") {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      // the new instance from user - form entry
      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value || "",
        levels: JSON.parse(e.target.elements.levels.value).level,
        letters: JSON.parse(e.target.elements.levels.value).letter,
      };
      const dto = SecurityObjectiveCreateDto.constructFromObject(params);
      try {
        const { response } = await securityObjectiveApi.createSecurityObjectiveWithHttpInfo(projectId, {
          securityObjectiveCreateDto: dto,
        });

        errorUtils.checkErrorStatus(response.status, response.statusText);
        setModalIsOpen(false);
        setRefresh(() => !refresh);
      } catch (err) {
        console.error("error in createSO : ", err);
      }
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  return (
    <div>
      <form onSubmit={handleSubmit} action="">
        <FormStyled>
          <div className="form-style">
            <TextField
              label={{ id: "SecObjectiveAddForm_Name", name: FORM_LABEL.nameMandatory }}
              input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
              setIsTextValid={setIsTextValid}
            />
            <TextField
              label={{ id: "SecObjectiveAddForm_Note", name: FORM_LABEL.note }}
              input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
              setIsTextValid={setIsTextValid}
            />

            <label id="SecObjectiveAddForm_Level">{FORM_LABEL.levelMandatory}</label>
            <SelectBox arrayOfData={levelsList} item="levels" />
          </div>
        </FormStyled>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
          <DialogButtonStyled type="submit">Add</DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};

AddSecurityObjectiveForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
};

export default AddSecurityObjectiveForm;
