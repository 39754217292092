import { MouseEvent } from "react";
// import { AiOutlineFileZip } from "react-icons/ai";
import AarFileExport from "assets/icons/aar-file-export.svg";
import { InlineButton } from "features/brm";

interface IExportAarButtonProps {
  onClick: (event: MouseEvent) => void;
  disabled?: boolean;
}

export const ExportAarButton = ({ onClick, disabled = false }: IExportAarButtonProps) => {
  return (
    <InlineButton
      title="Export AAR File"
      data-testid="ExportAarFileButton_button"
      onClick={onClick}
      disabled={disabled}
    >
      <img src={AarFileExport} alt="AAR File export" style={{ width: "20px" }} />
    </InlineButton>
  );
};
