import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

export interface ISetSystemAssetName {
  id: string;
  name: string;
}

export const setSystemAssetName = ({ id, name }: ISetSystemAssetName) => {
  return Brm.systemAssetApi.setSystemAssetName(id, { body: name });
};

export interface IUseSetSystemAssetName {
  projectId: string;
  options?: MutationOption<typeof setSystemAssetName>;
}

/**
 * Custom hook to change the name of a existing system asset
 * @returns react-query ReactQuery.useMutation
 */
export const useSetSystemAssetName = ({ projectId, options }: IUseSetSystemAssetName) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.systemAssetKeys.project(projectId));
    },
    ...options,
    mutationFn: setSystemAssetName,
  });
};
