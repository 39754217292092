import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import errorUtils from "utils/error-utils";
import { activityApi, nodeApi } from "services/brm/system-service";
import { DeleteButton, AddButton, DetailTableType, BrmError } from "features/brm";
import systemModals from "components/modals/Modals";
import { BrmDetailTable } from "brm/tables/BrmTables";
import {
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnNameDetails,
} from "brm/tables/services/column/columnFactory";
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";
import multiSelectDelete from "./utils/DetailTablesUtils";
import StoredDataAddForm from "./forms/StoredDataAddForm";

// Styles
const StoredTable = ({ elementNameInUrl, selectedElement, showAdd, showDelete, tableTitle }) => {
  const [refresh, setRefresh] = useState(false);
  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Datatype.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnBooleanNoEditMapped("isInternal"),
      createColumnBooleanNoEditMapped("isInput"),
      createColumnBooleanNoEditMapped("isOutput"),
      createColumnBooleanNoEditMapped("isProcessed"),
    ],
    []
  );

  const [storedData, setStoredData] = useState([]);
  const [, setSelectedStored] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  function getDeleteRequest(dataId) {
    switch (elementNameInUrl) {
      case DetailTableType.ACTIVITIES.key: {
        return activityApi.removeActivityStoredDataWithHttpInfo(selectedElement.id, dataId);
      }
      case DetailTableType.NODES.key: {
        return nodeApi.removeNodeStoredDataWithHttpInfo(selectedElement.id, dataId);
      }
      default: {
        return undefined;
      }
    }
  }

  useEffect(() => {
    async function getData() {
      function getElementRequest() {
        switch (elementNameInUrl) {
          case DetailTableType.ACTIVITIES.key: {
            return activityApi.getActivityStoredDataWithHttpInfo(selectedElement.id);
          }
          case DetailTableType.NODES.key: {
            return nodeApi.getNodeStoredDataWithHttpInfo(selectedElement.id);
          }
          default: {
            break;
          }
        }
        throw new BrmError(`Unsupported element type${elementNameInUrl}`);
      }

      try {
        const res = await getElementRequest();
        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setStoredData(res.data);
          setRefresh(false);
        }
      } catch (err) {
        console.error("Error in get Stored data ", err);
      }
    }
    getData();
  }, [refresh, elementNameInUrl, selectedElement]);

  const addStored = () => {
    setModalIsOpen(true);
  };

  return (
    <S.DetailsContainer id="StoredTable_detailsPanel">
      {/* modal for adding instance */}
      {modalIsOpen &&
        systemModals.addModal(
          "StoredTable_detailsPanel",
          modalIsOpen,
          setModalIsOpen,
          <StoredDataAddForm
            setModalIsOpen={setModalIsOpen}
            setRefresh={setRefresh}
            elementNameInUrl={elementNameInUrl}
            selectedElement={selectedElement}
          />,
          "Stored"
        )}
      <S.ActionContainer>
        <S.DetailsTableContainer>
          <BrmDetailTable
            data={storedData}
            columns={columns}
            setSelectedElement={setSelectedStored}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "StoredTable_detailsTable" }}
            showRowSelect={showDelete}
            tableTitle={tableTitle}
          />
        </S.DetailsTableContainer>
        <S.DetailsTableButtonsContainer>
          {showDelete && (
            <DeleteButton md onClick={() => multiSelectDelete(selectedRows, getDeleteRequest, setRefresh)} />
          )}
          {showAdd && <AddButton md onClick={addStored} />}
        </S.DetailsTableButtonsContainer>
      </S.ActionContainer>
    </S.DetailsContainer>
  );
};

StoredTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  showAdd: PropTypes.any,
  showDelete: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default StoredTable;
