import { useQuery } from "@tanstack/react-query";
import { DetailTableType, BrmError } from "features/brm";
import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./element-query-key-factory";

const getElementResources = (
  elementId: string,
  elementType: string,
  nodesResourcesData: BrmGql.GetNodeResourcesQuery | undefined,
  personResourcesData: BrmGql.GetPersonResourcesQuery | undefined,
  capabilityResourcesData: BrmGql.GetCapabilityResourcesQuery | undefined,
  missionResourcesData: BrmGql.GetMissionResourcesQuery | undefined
) => {
  const resourcesMap: IResourceMap = {
    [DetailTableType.NODES.key]: () => nodesResourcesData?.node?.resources,
    [DetailTableType.PERSONS.key]: () => personResourcesData?.person?.resources,
    [DetailTableType.MISSIONS.key]: () => missionResourcesData?.mission?.resources,
    [DetailTableType.CAPABILITIES.key]: () => capabilityResourcesData?.capability?.resources,
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityResource(id),
  };

  if (resourcesMap[elementType]) {
    return resourcesMap[elementType](elementId) || [];
  }
  throw new BrmError(`Unsupported element type: ${elementType}`);
};
interface IElementResourcesProps {
  elementId: string;
  elementName: string;
  projectId: string;
  variantId: string;
  config: QueryOption<typeof getElementResources>;
}

interface IResourceMap {
  [index: string]: (id?: string) => any;
}

/**
 * custom-hook to get all Resources
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {object} config - configuration for the react-query
 * @returns react-query for resources
 */
export const useElementResources = ({
  elementId,
  elementName,
  projectId,
  variantId,
  config = {},
}: IElementResourcesProps) => {
  const nodesResourcesQuery = BrmGql.useGetNodeResourcesQuery(
    { project: projectId, variant: variantId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.NODES.key }
  );

  const personResourcesQuery = BrmGql.useGetPersonResourcesQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.PERSON.key }
  );

  const capabilityResourcesQuery = BrmGql.useGetCapabilityResourcesQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.CAPABILITIES.key }
  );

  const missionResourcesQuery = BrmGql.useGetMissionResourcesQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.MISSIONS.key }
  );

  const resourceQuery = useQuery({
    ...config,
    queryKey: KeyFactory.elementKeys.resources(
      elementId,
      elementName,
      nodesResourcesQuery.data,
      personResourcesQuery.data,
      capabilityResourcesQuery.data,
      missionResourcesQuery.data
    ),
    queryFn: () =>
      getElementResources(
        elementId,
        elementName,
        nodesResourcesQuery.data,
        personResourcesQuery.data,
        missionResourcesQuery.data,
        capabilityResourcesQuery.data
      ),
  });

  if (nodesResourcesQuery.isError) {
    return nodesResourcesQuery;
  }

  if (personResourcesQuery.isError) {
    return personResourcesQuery;
  }

  if (capabilityResourcesQuery.isError) {
    return capabilityResourcesQuery;
  }

  if (missionResourcesQuery.isError) {
    return missionResourcesQuery;
  }

  return resourceQuery;
};
