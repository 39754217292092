import styled from "styled-components/macro";

const projectExplorerStyles = {
  Menu: styled.div`
    background-color: #ffffff;
    padding-top: ${({ theme }) => theme.sizes.defaultPadding};
  `,
  Element: styled.span`
    font-size: ${({ theme }) => theme.sizes.defaultFontSize};
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.sidebarnavtextcolor};
  `,
  Item: styled.div`
    font-size: ${({ theme }) => theme.sizes.defaultFontSize};
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.sidebarnavtextcolor};
    &:hover {
      opacity: 0.5;
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.mediumContrastBackground};
    }
    margin-bottom: 4px;
    padding-left: 5px;
  `,
  SingleItem: styled.div`
    font-size: ${({ theme }) => theme.sizes.defaultFontSize};
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.sidebarnavtextcolor};
    &:hover {
      opacity: 0.5;
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.mediumContrastBackground};
    }
    padding-left: 15px;
    margin-bottom: 4px;
  `,
  PackageLink: styled.div`
    font-size: ${({ theme }) => theme.sizes.defaultFontSize};
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.sidebarnavtextcolor};
    cursor: pointer;
    &:hover {
      opacity: 0.5;
      background-color: ${({ theme }) => theme.colors.mediumContrastBackground};
    }
    padding-left: 15px;
    margin: 4px;
  `,
  SubMenu: styled.div`
    font-size: ${({ theme }) => theme.sizes.defaultFontSize};
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.sidebarnavtextcolor};
    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
      opacity: 0.5;
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.mediumContrastBackground};
    }

    margin-bottom: 5px;
    padding-left: 50px;
  `,
  SubMenuAccordionLevelTwo: styled.div`
    font-size: ${({ theme }) => theme.sizes.defaultFontSize};
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.sidebarnavtextcolor};
    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
      opacity: 0.5;
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.mediumContrastBackground};
    }

    margin-bottom: 5px;
    padding-left: 35px;
  `,
  MenuItemContainer: styled.div`
    font-size: ${({ theme }) => theme.sizes.defaultFontSize};
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.sidebarnavtextcolor};
    padding-top: 2px;
    padding-bottom: 1px;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.mediumContrastBackground};
    }
  `,
  MenuEntry: styled.div`
    display: flex;
    flex-direction: row;
    height: 16px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding-left: 15px;
  `,
};
export default projectExplorerStyles;
