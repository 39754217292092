import { useMemo, useRef, useCallback, useEffect, useState } from "react";

import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { SystemApi, useRoles } from "features/brm";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";

import { createColumnNameNoNav } from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";

const ClassificationCategoryTable = ({ setSelectedRows, setRefresh }) => {
  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const [classCatData, setClassCatData] = useState([]);
  const updatedName = useRef("");
  const [selectedInstance] = useRecoilState(selectedInstanceState);
  const { data, error } = SystemApi.useClassificationCategories({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer) },
  });

  const getNameCell = useCallback(() => {
    return createColumnNameNoNav(selectedInstance.id, false, updatedName);
  }, [selectedInstance.id]);

  const columns = useMemo(() => [getNameCell()], [getNameCell]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);
  useEffect(() => {
    if (data) {
      setClassCatData(data.map((e) => ({ name: e })));
    }
  }, [data]);

  if (error) {
    return <ErrorBanner msg="Error while loading classification categories" />;
  }

  if (data) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={classCatData}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "ClassificationCategoryTable_table" }}
            elementName={TYPE.classificationCat}
            showRowSelect={false}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

ClassificationCategoryTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default ClassificationCategoryTable;
