import * as React from "react";

interface ICancelContext {
  isCancelRequested: boolean;
  setIsCancelRequested: (v: boolean) => void;
}

export const CancelContext = React.createContext<ICancelContext | null>(null);

export const useCancelContext = () => {
  const context = React.useContext(CancelContext);

  if (!context) {
    throw new Error("CancelContext has to be used within <CancelContext.Provider>");
  }
  return context;
};
