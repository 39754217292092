import { useState } from "react";
import PropTypes from "prop-types";
import { isProjectNameValid, isProjectUrlValid } from "features/brm/utils";

import S from "./TextField.styles";

const TextField = ({ label, input, setIsTextValid, isTextValid }) => {
  const [invalidMsg, setInvalidMsg] = useState("");
  const inputId = input.id || input.name;

  const getTextOnChange = () => {
    const inputString = document.getElementById(inputId).value;

    if ((isTextValid ? isTextValid(inputString) : !isProjectNameValid(inputString)) && inputId !== "sourceURL") {
      // input string include something other than alphanumeric chars, dash or underscore; invalid
      setInvalidMsg("Invalid character(s)");
      setIsTextValid(false);
    } else if (inputId === "sourceURL" && !isProjectUrlValid(inputString)) {
      setInvalidMsg("Invalid URL");
      setIsTextValid(false);
    } else {
      setInvalidMsg("");
      setIsTextValid(true);
    }
  };

  return (
    <>
      <S.Label id={label.id} htmlFor={inputId}>
        {label.name}
        <S.Msg title="Valid characters are alphanumeric, dash (-), or underscore (_)">{invalidMsg}</S.Msg>

        <S.Input
          onChange={getTextOnChange}
          className="inputField"
          type="text"
          name={input.name}
          id={inputId}
          placeholder={input.placeholder}
          disabled={input.disabled}
        />
      </S.Label>
    </>
  );
};

TextField.propTypes = {
  label: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    htmlFor: PropTypes.string,
  }),
  input: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  setIsTextValid: PropTypes.func,
  isTextValid: PropTypes.func,
};
export default TextField;
