import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

export interface ISetSystemAssetSystemAssetType {
  saId: string;
  saType: string;
}

export const setSystemAssetSystemAssetType = ({ saId, saType }: ISetSystemAssetSystemAssetType) => {
  return Brm.systemAssetApi.setSystemAssetSatype(saId, { body: saType });
};

export interface IUseSetSystemAssetType {
  options?: MutationOption<typeof setSystemAssetSystemAssetType>;
}

/**
 * Custom hook to change the category of a existing system asset
 * @returns react-query ReactQuery.useMutation
 */
// eslint-disable-next-line import/prefer-default-export
export const useSetSystemAssetSystemAssetType = ({ options }: IUseSetSystemAssetType = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.systemAssetKeys.all);
    },
    ...options,
    mutationFn: setSystemAssetSystemAssetType,
  });
};
