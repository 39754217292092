import { LoadingSpinner } from "components/elements";
import * as React from "react";
import { Location } from "react-router-dom";

import UserService from "services/brm/user-service";

export const Logout = ({ previousLoc }: { previousLoc: Location }) => {
  React.useEffect(() => {
    sessionStorage.clear();
    const host = `${window.location.protocol}//${window.location.host}`;
    UserService.doLogout({ redirectUri: host });
  }, [previousLoc]);

  return <LoadingSpinner message="Logging out" />;
};
