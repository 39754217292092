import styled from "styled-components";

const TalbeButtonsDivStyled = styled.div`
  float: right;
  padding-right: 10px;
  padding-bottom: 4px;
  font-size: 14px;
`;

export default TalbeButtonsDivStyled;
