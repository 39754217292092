import { useSearchParams } from "react-router-dom";
import { UrlSearchParams } from "features/brm";
import { LoadingSpinner } from "components/elements";
import { DemoContent as videoList } from "features/help/constants";
import HtmlHelpViewer from "components/elements/HtmlHelpViewer";
import { Wrapper } from "./Demo.styles";

export const Demo = () => {
  const [searchParams] = useSearchParams();
  const name = searchParams.get(UrlSearchParams.name);
  const { filePath } = videoList.filter((videoObj) => videoObj.name === name)[0];

  if (name && filePath) {
    return (
      <Wrapper>
        <b>{name}</b>
        <div>
          <HtmlHelpViewer fileUrl={filePath} />
        </div>
      </Wrapper>
    );
  }
  return <LoadingSpinner message="Loading demo video" />;
};
