import * as React from "react";
import * as Recoil from "recoil";
import toast from "react-hot-toast";

// Atoms
import { selectedInstanceState } from "atoms";
import { selectedControlCatalogIdState } from "atoms/atoms-global-control";

// Constants
import { COMMON } from "constants/brm";

// Components
import InLineSelectBox from "components/EditComponents/InLineSelectBox2";

// API
import { useRoles } from "hooks";
import { useVariant } from "features/brm/hooks";
import { useProjectCatalog } from "../../../api/admin/project";
import { useGetVariantProperty } from "../../../api/mitigation/variant";
import { useCatalogBaselines } from "../../../api/global-control";

interface IEditCellBaselineDropdownProps {
  selectedRowId: string;
  field: string;
  elementId: string;
  id: string;
  editMode: Boolean;
  setter: (value: string) => void;
}

export const EditCellBaselineDropdown = ({
  selectedRowId,
  field,
  elementId,
  id,
  editMode,
  setter,
}: IEditCellBaselineDropdownProps) => {
  const { isRiskAnalyst, isControlsCoordinator } = useRoles();
  const { variantId } = useVariant();
  const selectedInstance = Recoil.useRecoilValue(selectedInstanceState);
  const [selectedControlCatalogId, setSelectedControlCatalogId] = Recoil.useRecoilState(selectedControlCatalogIdState);

  const instanceId = selectedInstance?.id || "";

  const { data: projectCatalog } = useProjectCatalog({
    projectId: instanceId,
    options: { enabled: !!instanceId && !variantId && isControlsCoordinator },
  });

  const { data: variantCatalog } = useGetVariantProperty({
    variantId: instanceId,
    property: "catalog",
    options: {
      enabled: !!instanceId && !!variantId && (isControlsCoordinator || isRiskAnalyst),
    },
  });

  const { data: baselines, isError: isBaselinesError } = useCatalogBaselines({
    catalogId: selectedControlCatalogId,
    options: { enabled: !!selectedControlCatalogId && (isControlsCoordinator || isRiskAnalyst) },
  });

  // !FIXME ....state syncing...
  React.useEffect(() => {
    if (projectCatalog) {
      setSelectedControlCatalogId(projectCatalog.id);
    }
    if (variantCatalog) {
      setSelectedControlCatalogId(variantCatalog.id);
    }
  }, [projectCatalog, setSelectedControlCatalogId, variantCatalog]);

  React.useEffect(() => {
    if (isBaselinesError) {
      toast.error(<span>Error setting baseline to project</span>, {
        duration: 4000,
      });
    }
  }, [isBaselinesError]);

  const updateValues = (value: string) => {
    setter(value);
  };

  if (editMode && selectedRowId === elementId) {
    return <InLineSelectBox arrayOfData={baselines} item={id} updateValues={updateValues} value={field} />;
  }
  if (field === null || field === "" || field === undefined || isBaselinesError) {
    return <>{COMMON.hyphen}</>;
  }
  return <>{field}</>;
};
