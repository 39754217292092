import styled from "styled-components";

const FormStyledError = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  max-width: 400px;
  margin: 10px auto;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.errorText};
`;

export default FormStyledError;
