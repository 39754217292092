import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./sap-person-query-key-factory";

/**
 * Gets the sap person
 */
export const getSapPerson = ({ personId }: { personId: string }) => {
  return Brm.sapPersonApi.getSapPersonByID(personId);
};

type FunctionType = typeof getSapPerson;
interface IPersonsProps {
  personId: string;
  options?: QueryOption<FunctionType>;
}

/**
 * Custom hook for getting a sap Person
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useGetSapPerson = ({ personId, options = {} }: IPersonsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.sapPersonKeys.find(personId),
    queryFn: () => getSapPerson({ personId }),
  });
};
