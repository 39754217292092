import { Classification } from "constants/brm";
import { Select } from "./styles/tables.styles";

const SelectCategorizedEntityColumnFilter = ({ column: { setFilter } }: any) => (
  <Select
    id="custom-select"
    onChange={(e) => {
      setFilter(e.target.value || undefined);
    }}
  >
    <option value="">All</option>
    <option value={Classification.TOP_SECRET.letter}>{Classification.TOP_SECRET.letter}</option>
    <option value={Classification.SECRET.letter}>{Classification.SECRET.letter}</option>
    <option value={Classification.FOUO.letter}>{Classification.FOUO.letter}</option>
    <option value={Classification.UNCLASSIFIED.letter}>{Classification.UNCLASSIFIED.letter}</option>
    <option value={Classification.NOT_CLASSIFIED.letter}>{Classification.NOT_CLASSIFIED.letter}</option>
  </Select>
);

export default SelectCategorizedEntityColumnFilter;
