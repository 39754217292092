import styled from "styled-components/macro";

export const SCTMWizard = styled.div`
  .wizardTitle {
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
`;
export const Column = styled.div`
  flex: 50%;
`;

export const SelectMargin = styled.div`
  margin: 5px 0px;
`;

export const SCTMStep1 = styled.div`
  margin-top: 5px;
`;

export const SCTMInput = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 2px;
`;

export const SCTMSelect = styled.div`
  padding-left: ${(props) => props.theme.padding};
  margin-left: 5px;
  flex: 50%;
`;

export const SCTMNodeOptions = styled.div`
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 15px;
  justify-content: center;

  .row: {
    display: flex;

    flex-wrap: no-wrap;
    gap: 12px;
    justify-content: center;
    width: absolute;
  }

  .column: {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: flex-start;
  }
`;
export const SCTMHeadings = styled.h6`
  margin-bottom: 7px;
  margin-top: 7px;
  font-weight: 600;
`;
export const SCTMOptions = styled.div`
  padding-left: 15px;
`;

export const SelectionButton = styled.button`
  background-color: ${({ theme }) => theme.primarybuttonbackgroundcolor};
  border-radius: 3px;
  color: white;
  font-family: ${({ theme }) => theme.fonts.primary};
  border: 1px black;
  padding: 5px;
  margin: 2px 8px;
  opacity: ${(prop) => (prop.disabled ? 0.3 : 1)};
  &:hover {
    opacity: ${(prop) => (prop.disabled ? 0.3 : 0.8)};
    cursor: ${(prop) => (prop.disabled ? "auto" : "pointer")};
    background-color: #45a049;
  }
`;
