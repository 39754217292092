import { atom } from "recoil";

export const rightSidebarOpenState = atom<boolean>({
  key: "rightSidebarOpenState",
  default: false,
});

export const leftSidebarOpenState = atom<boolean>({
  key: "leftSidebarOpenState",
  default: false,
});
