import { COMMON } from "constants/brm";
import { ProjectSettingsView } from "../ProjectSettingsView";
import * as PS from "../styles/project-settings-styles";
import { useInstallationConfigurationPanelApi } from "./useInstallationConfigurationPanelApi";

export const InstallationConfigurationPanel = () => {
  const { projectSettings, setProjectSetting } = useInstallationConfigurationPanelApi({
    installationId: COMMON.defaultUuid,
  });

  return (
    <>
      <PS.SettingsHeading>Default Project Configuration Settings</PS.SettingsHeading>
      <ProjectSettingsView projectSettings={projectSettings} isReadOnly={false} onSettingChange={setProjectSetting} />
    </>
  );
};
