import styled from "styled-components";

export const RelativeContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

// eslint-disable-next-line import/prefer-default-export
export const FilterBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: "45px";
`;
