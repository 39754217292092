import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import getReactSelectArray from "utils/react-select-array";
import SelectAll from "components/forms/SelectAllReactSelect";
import { FORM_LABEL } from "constants/brm";
import { queryClient } from "libs/react-query";
import toast from "react-hot-toast";
import { SystemApi } from "features/brm";
import { ErrorBanner } from "components/elements";
import getUniqueArray from "./helpers/GetUniqueArray";
// import customDetailStyle from "./styles/BrmReactSelectDetailsStyles";

// This component seems to be deprecated

const AddActivityForm = ({ setModalIsOpen, elementNameInUrl, selectedElement }) => {
  const [postError, setPostError] = useState("");
  const [activityOptions, setActivityOptions] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const projectId = useRecoilValue(projectIdState);

  const { data: elementActivitiesData, isError: isElementActivitiesDataError } = SystemApi.useElementActivities({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    config: {
      enabled: !!selectedElement.id,
    },
  });

  const { data: activitydata, isError: isActivityError } = SystemApi.useActivities({
    projectId,
    options: {
      enabled: !!projectId,
    },
  });

  const { mutateAsync: addActivity } = SystemApi.useAddElementActivities();

  useEffect(() => {
    async function getData() {
      const uniqueArray = getUniqueArray(activitydata, elementActivitiesData);
      setActivityOptions(getReactSelectArray(uniqueArray));
    }
    if (projectId && elementActivitiesData && activitydata) {
      getData();
    }
  }, [activitydata, elementActivitiesData, projectId]);

  async function handleSubmit(e) {
    e.preventDefault();
    if (selectedActivities.length > 0) {
      try {
        await Promise.all(
          selectedActivities.map((each) =>
            addActivity({
              elementId: selectedElement.id,
              elementName: elementNameInUrl,
              activityId: each.value,
            })
          )
        );
        setModalIsOpen(false);
        queryClient.invalidateQueries(SystemApi.activitiesKeys.details);
      } catch (err) {
        toast.error(<p>Error adding activities</p>, {});
      }
    } else {
      setPostError("*Please select an element to add prior to submitting.");
    }
  }

  const handleSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedActivities(items);
  };

  if (isActivityError || isElementActivitiesDataError) {
    return <ErrorBanner msg="Error getting activities" />;
  }

  return (
    <div id="AddActivityForm">
      <form onSubmit={handleSubmit} action="" id="AddActivityForm_form">
        <FormStyled>
          <div className="form-style" id="AddActivityForm_formContent">
            <label id="AddActivityForm_label">{FORM_LABEL.activity}</label>
            <SelectAll
              // styles={customDetailStyle}
              isMulti
              options={activityOptions}
              defaultValue={selectedActivities}
              value={selectedActivities}
              onChange={handleSelectChange}
              allowSelectAll
              allOption={{
                label: "Select all",
                value: "*",
              }}
              elementId="ActivityAddForm_activity"
            />
          </div>
        </FormStyled>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="ActivityAddForm_cancelButton">
            Cancel
          </DialogButtonStyled>
          <DialogButtonStyled type="submit" id="ActivityAddForm_addButton">
            Add
          </DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};

AddActivityForm.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setModalIsOpen: PropTypes.func,
};

export default AddActivityForm;
