import BrmLogoPng from "assets/icons/BladeRiskManagerLogo.png";

import BrmLogo from "../Logo";
import S from "./Help.styles";

const Help = ({ isHelpCenter }: { isHelpCenter: boolean }) => {
  return (
    <S.Header id="Header">
      <S.PrimaryContainer>
        <BrmLogo src={BrmLogoPng} alt="BRM Logo" id="Navbar_logo" />
        {isHelpCenter && <S.TitleSpan>Help Center</S.TitleSpan>}
      </S.PrimaryContainer>
    </S.Header>
  );
};
export default Help;
