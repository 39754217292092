import { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState, useRecoilState } from "recoil";
import { controlGuidanceReportState, controlReportState } from "atoms/atoms-report";

import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import { REPORT } from "constants/brm";
import { isRiskAnalyst } from "services/user";
import { userRoleState } from "atoms/atoms-admin";
import { DetailTableType } from "features/brm";
import DynamicSubTabSelector from "components/elements/DynamicSubTabSelector";
import { DetailsTableMap } from "brm/tables/details/DetailsTableMap";
import { defaultReportTabState, isReportsTabDefaultState } from "atoms";
import AllocatedControlTable from "brm/tables/details/AllocatedControlTable";

const reportsMenu = [
  DetailTableType.CNTRL_GUINDANCE_RPT,
  DetailTableType.CNTRL_RPT,
  DetailTableType.CSA_KPP_RPT,
  DetailTableType.CSF_RPT,
  DetailTableType.VARIANT_DIFF_RPT,
];

export const VariantTab = ({ selectedElement, setIsControlCatalogEditingEnabled }) => {
  // Global state
  const resetControlGuidanceReport = useResetRecoilState(controlGuidanceReportState);
  const resetControlReport = useResetRecoilState(controlReportState);
  const [selectedReportContext, setSelectedReportContext] = useState(DetailTableType.CNTRL_GUINDANCE_RPT.key);
  const userRole = useRecoilValue(userRoleState);
  const [curr, setCurr] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [isReportstabDefault, setIsReportsTabDefault] = useRecoilState(isReportsTabDefaultState);
  const [defaultReportsTab, setDefaultReportsTab] = useRecoilState(defaultReportTabState);

  const onSelect = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (isReportstabDefault) {
      setTabIndex(1);
      setSelectedReportContext(defaultReportsTab);
    }
    return () => {
      setIsReportsTabDefault(false);
      setDefaultReportsTab("");
    };
  }, [defaultReportsTab, isReportstabDefault, setDefaultReportsTab, setIsReportsTabDefault]);

  /**
   * Clear out the recoil reports since only keeping 'current' instance report in recoil
   */
  useEffect(() => {
    resetControlGuidanceReport();
    resetControlReport();
  }, [resetControlGuidanceReport, resetControlReport]);

  const elementNameInUrl = DetailTableType.VARIANTS.key;
  return (
    <Tabs selectedIndex={tabIndex} onSelect={onSelect}>
      <TabList>
        {isRiskAnalyst(userRole) && <Tab>Allocated Controls</Tab>}
        <Tab onClick={() => setCurr(REPORT.reports)}>
          <DynamicSubTabSelector
            title={REPORT.reports}
            menu={reportsMenu}
            setSelectedContext={setSelectedReportContext}
            curr={curr}
          />
        </Tab>
      </TabList>

      {isRiskAnalyst(userRole) && (
        <TabPanel>
          <AllocatedControlTable
            elementNameInUrl={elementNameInUrl}
            selectedElement={selectedElement}
            setIsControlCatalogEditingEnabled={setIsControlCatalogEditingEnabled}
          />
        </TabPanel>
      )}

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedReportContext}
        />
      </TabPanel>
    </Tabs>
  );
};
VariantTab.propTypes = {
  selectedElement: PropTypes.object,
  setIsControlCatalogEditingEnabled: PropTypes.func,
};
