import { useState } from "react";
// Styles
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

// Local
import AttackVectorTable from "./tables/AttackVectorTable";

function AttackVector() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const attackVectorHeader = <Title id="AttackVector_title">Attack Vectors</Title>;

  const attackVectorMain = (
    <>
      <AttackVectorTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="AttackVector_page" header={attackVectorHeader} main={attackVectorMain} />;
}

export default AttackVector;
