import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import * as BrmGql from "generated/graphql";
// Services
// import { useRecoilValue } from "recoil";
// import { projectIdState } from "atoms/atoms-admin";
// import { baselineApi, sctmApi, variantApi } from "services/brm";
import { MitigationApi, GlobalControlApi } from "features/brm";

// async function getDiffInfo(elementId, projectId, variantId) {
//   const result = [];
//   try {
//     if (elementId && variantId) {
//       let info1 = {};
//       let info2 = {};

//       const variant1Info = await variantApi.getVariantByID(elementId);
//       info1 = variant1Info;
//       const baseline1 = await baselineApi.getStandardBaselineName(variant1Info.baseline);
//       const controlsCount = await sctmApi.getSCTMAllocatedcontrol(variant1Info.sctm);
//       const sctmName = await sctmApi.getSCTMName(variant1Info.sctm);
//       info1.baseline = baseline1.result;
//       info1.controlsCount = controlsCount.length;
//       info1.sctm = sctmName.result;

//       const variant2Info = await variantApi.getVariantByID(variantId);
//       info2 = variant2Info;
//       const baseline2 = await baselineApi.getStandardBaselineName(variant2Info.baseline);
//       const controlsCount2 = await sctmApi.getSCTMAllocatedcontrol(variant2Info.sctm);
//       const sctmName2 = await sctmApi.getSCTMName(variant2Info.sctm);
//       info2.baseline = baseline2.result;
//       info2.controlsCount = controlsCount2.length;
//       info2.sctm = sctmName2.result;

//       result.push(info1);
//       result.push(info2);
//     }
//   } catch (err) {
//     console.error("err in get var info : ", err);
//   }
//   return result;
// }

const useGetVariantData = ({ variantId }) => {
  // console.log("variantId", variantId);

  const { data: variant1 } = MitigationApi.useVariant({ variantId, options: { enabled: !!variantId } });
  const { data: controlCatalog1 } = GlobalControlApi.useControlCatalog({ catalogId: variant1?.catalog || "" });
  const { data: baseline1 } = MitigationApi.useStandardBaselineName({
    baselineId: variant1?.baseline || "",
    options: { enabled: !!variant1 },
  });
  // // TODO is there another way of getting the number of sctm allocated controls?
  // const { data: allocatedControls1 } = MitigationApi.useSctmAllocatedControls({
  //   sctmId: variant1?.sctm || "",
  //   options: { enabled: !!variant1 },
  // });

  const { data: allocatedControls1 } = BrmGql.useGetSctmAllocatedControlsQuery(
    { sctm: variant1?.sctm || "" },
    {
      enabled: !!variant1,
      select: (data) => data.allocatedControls,
    }
  );

  const { data: sctmName1 } = MitigationApi.useSctmName({
    sctmId: variant1?.sctm || "",
    options: { enabled: !!variant1 },
  });

  if (
    variantId === undefined ||
    variant1 === undefined ||
    controlCatalog1 === undefined ||
    baseline1 === undefined ||
    allocatedControls1 === undefined ||
    sctmName1 === undefined
  ) {
    return undefined;
  }

  const info1 = { ...variant1 };
  info1.controlCatalog = controlCatalog1;
  info1.baseline = baseline1?.result;
  info1.controlsCount = allocatedControls1?.length;
  info1.sctm = sctmName1?.result;
  return info1;
};

export const useGetVariantDiff = (variant1Id, variant2Id) => {
  // const projectId = useRecoilValue(projectIdState);

  const info1 = useGetVariantData({ variantId: variant1Id });
  const info2 = useGetVariantData({ variantId: variant2Id });

  // const { data: variant1 } = MitigationApi.useVariant({ variantId: variant1Id });
  // const { data: baseline1 } = MitigationApi.useStandardBaselineName({
  //   baselineId: variant1?.baseline || "",
  //   options: { enabled: !!variant1 },
  // });
  // const { data: allocatedControls1 } = MitigationApi.useSctmAllocatedControls({
  //   sctmId: variant1?.sctm || "",
  //   options: { enabled: !!variant1 },
  // });
  // const { data: sctmName1 } = MitigationApi.useSctmName({
  //   sctmId: variant1?.sctm || "",
  //   options: { enabled: !!variant1 },
  // });

  // const info1 = { ...variant1 };
  // info1.baseline = baseline1?.result;
  // info1.controlsCount = allocatedControls1?.length;
  // info1.sctm = sctmName1?.result;

  // const { data: variant2 } = MitigationApi.useVariant({ variantId: variant2Id });
  // const { data: baseline2 } = MitigationApi.useStandardBaselineName({
  //   baselineId: variant2?.baseline || "",
  //   options: { enabled: !!variant2 },
  // });
  // const { data: allocatedControls2 } = MitigationApi.useSctmAllocatedControls({
  //   sctmId: variant2?.sctm || "",
  //   options: { enabled: !!variant2 },
  // });
  // const { data: sctmName2 } = MitigationApi.useSctmName({
  //   sctmId: variant2?.sctm || "",
  //   options: { enabled: !!variant2 },
  // });

  // const info2 = { ...variant2 };
  // info2.baseline = baseline2?.result;
  // info2.controlsCount = allocatedControls2?.length;
  // info2.sctm = sctmName2?.result;
  // // return useQuery(variantsKeys.diff(variant1Id, variant2Id), () => getDiffInfo(variant1Id, projectId, variant2Id), {
  // //   enabled: !!variant1Id,
  // //   cacheTime: 0,
  // // });

  return useMemo(() => {
    const result = [];
    if (info1 && info2) {
      result.push(info1);
      result.push(info2);
    }
    return result;
  }, [info1, info2]);
};

const compareData = (d1 = [], d2 = []) => {
  const onlyInA = d1.filter((o1) => d2.filter((o2) => o1.name === o2.name).length === 0);
  const onlyInB = d2.filter((o1) => d1.filter((o2) => o1.name === o2.name).length === 0);

  const modifiedA = onlyInA.map((e) => ({
    ...e,
    strength: e.calibratedStrength && e.calibratedStrength !== "-" ? e.calibratedStrength : e.strength,
    cost: e.controltype.cost,
    var1: true,
    var2: false,
  }));
  const modifiedB = onlyInB.map((e) => ({
    ...e,
    strength: e.calibratedStrength && e.calibratedStrength !== "-" ? e.calibratedStrength : e.strength,
    cost: e.controltype.cost,
    var1: false,
    var2: true,
  }));

  return modifiedA.concat(modifiedB);
};

export const useGetDifferenceData = (data1, data2) => {
  return useQuery(["getDiff", data1, data2], () => compareData(data1, data2), {
    enabled: !!data1 && !!data2,
    cacheTime: 0,
  });
};

export const useGetVariantControls = (variantId) => {
  const { data: sctm } = MitigationApi.useVariantSctm({ variantId, options: { enabled: !!variantId } });
  return MitigationApi.useSctmAllocatedControls({
    sctmId: sctm?.id || "",
    options: {
      enabled: !!sctm?.id,
    },
  });
};
