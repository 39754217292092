import { IKbDiff, IRule, IChange, IUndesiredEvent, IKnowledgebaseObj } from "./types";

// function toRuleRow(row: IRule, action: string) {
//   return `"${row.trevcat}","${row.trevpat}","${row.atkkind}","${row.atktactic}","${row.sacat}","${row.atkcat}","${row.tag}","${action},"${row.means}","${row.opportunity}"`;
// }

function toRuleRow(row: IRule) {
  return `"${row.trevcat}","${row.trevpat}","${row.atkkind}","${row.atktactic}","${row.sacat}","${row.atkcat}","${row.tag}"`;
}

function toChangeRuleRow(row: IChange<IRule>) {
  return `${toRuleRow(row.orig)},,"Changed",,"${row.orig.means}","${row.orig.opportunity}",,"${row.other.means}","${
    row.other.opportunity
  }"`;
}

function toAddRuleRow(row: IRule) {
  return `${toRuleRow(row)},,"Add",,"-","-",,"${row.means}","${row.opportunity}"`;
}
function toRemoveRuleRow(row: IRule) {
  return `${toRuleRow(row)},,"Delete",,"${row.means}","${row.opportunity}",,"-","-"`;
}

function toUeRow(row: IUndesiredEvent) {
  return `"${row.targetcat}","${row.objective}"`;
}

function toAddUeRow(row: IUndesiredEvent) {
  return `${toUeRow(row)},,"Add",,-,-,-,,"${row.criticality}","${row.severity}","${row.rfactor}"`;
}
function toRemoveUeRow(row: IUndesiredEvent) {
  return `${toUeRow(row)},,"Delete",,"${row.criticality}","${row.severity}","${row.rfactor}",,-,-,-`;
}
function toChangeUeRow(row: IChange<IUndesiredEvent>) {
  return `${toUeRow(row.orig)},,"Changed",,"${row.orig.criticality}","${row.orig.severity}","${row.orig.rfactor}",,"${
    row.other.criticality
  }","${row.other.severity}","${row.other.rfactor}"`;
}

export const useKbDiffToCsv = () => {
  const convertKbDiffToCsv = (kbA: IKnowledgebaseObj, kbB: IKnowledgebaseObj, diffCsv: IKbDiff) => {
    let csv: string[] = [];
    csv = [
      ...csv,
      `,,,,,,,,,,${kbA.name},${kbA.organization.name},,${kbB.name},${kbB.organization.name}`,
      "Trev Category,Trev Pattern,Attack Kind,Attack Tactic,System Asset Category,Attacker Category,Tag,,Action,,Means,Opportunity,,Means,Opportunity",
    ];

    if (diffCsv.rules.added.length) {
      csv = [...csv, ...diffCsv.rules.added.map((a) => toAddRuleRow(a))];
    }
    if (diffCsv.rules.changed.length) {
      csv = [...csv, ...diffCsv.rules.changed.map((a) => toChangeRuleRow(a))];
    }
    if (diffCsv.rules.removed.length) {
      csv = [...csv, ...diffCsv.rules.removed.map((a) => toRemoveRuleRow(a))];
    }

    let ueCsv: string[] = [];
    ueCsv = [
      ...ueCsv,
      `,,,,,${kbA.name},${kbA.organization.name},,,${kbB.name},${kbB.organization.name}`,
      "Target Category,Objective,,Action,,Criticallity,Severity,R Factor,,Criticality,Severity,R Factor",
    ];

    if (diffCsv.ue.added.length) {
      ueCsv = [...ueCsv, ...diffCsv.ue.added.map((a) => toAddUeRow(a))];
    }
    if (diffCsv.ue.changed.length) {
      ueCsv = [...ueCsv, ...diffCsv.ue.changed.map((a) => toChangeUeRow(a))];
    }
    if (diffCsv.ue.removed.length) {
      ueCsv = [...ueCsv, ...diffCsv.ue.removed.map((a) => toRemoveUeRow(a))];
    }

    // const ruleCsv = csv.join("\n");
    // const UECSV = ;
    return { ruleCsv: csv.join("\n"), ueCsv: ueCsv.join("\n") };
  };

  return { convertKbDiffToCsv };
};
