import { io } from "socket.io-client";
import _ from "lodash";
import async from "async";
import { REPORT, REPORT_STATUS, SIONSP } from "constants/brm";

export function getWshost() {
  let wshost = `ws://${process.env.REACT_APP_WEBSOCKET_HOST}:${process.env.REACT_APP_WEBSOCKET_PORT}`;
  if (window.location.hostname !== "localhost") {
    wshost = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }`;
  }
  return wshost;
}

export function getServicesSocketNsp(auth = {}) {
  const wshost = getWshost();
  // Setup socket with specific name space for system model services
  let localSocket;
  if (_.isEmpty(auth)) {
    localSocket = io(wshost + SIONSP.services, {
      transports: [`websocket`], // no fall back to polling
      path: REPORT.servicePath,
    });
  } else {
    // console.log("getServicesSocketNsp", `${wshost}${SIONSP.services}`);
    localSocket = io(wshost + SIONSP.services, {
      transports: [`websocket`], // no fall back to polling
      path: REPORT.servicePath,
      auth,
    });
  }

  return localSocket;
}

export const secureSocketService = (socket) =>
  new Promise((resolve, reject) => {
    async.race(
      [
        function foo(asyncCb) {
          setTimeout(() => {
            // console.log(`secureSocketService timer done`);
            asyncCb({ code: `sio1`, message: `authorization timeout`, error: {} });
          }, 2000); // Maximum time to authorize is 2s
        },
        function bar(asyncCb) {
          async.parallel(
            {
              connect(asyncCb2) {
                socket.on(`connect`, () => {
                  // console.log(`secureSocketConnect on connect emit authenticate`);
                  socket.emit(`authentication`);
                  asyncCb2(null, REPORT_STATUS.done);
                });
              },
              authorized(asyncCb2) {
                socket.on(`authenticated`, () => {
                  // console.log(`secureSocketConnect - authenticated`);
                  asyncCb2(null, REPORT_STATUS.done);
                });
                socket.on(`unauthorized`, (error) => {
                  socket.disconnect();
                  // console.error(`secureSocketConnect - Unauthorized userId/projectId combination. Error: `, error);
                  asyncCb2({
                    code: `sio2`,
                    message: `secureSocketConnect - Unauthorized userId/projectId combination error`,
                    error,
                  });
                });
              },
            },
            (error) => {
              if (error) {
                // console.error(`secureSocketConnect - parallel error: `, error);
                socket.disconnect();
                asyncCb(error);
              } else {
                asyncCb(null, { authorized: true });
              }
            }
          );
        },
      ],
      (error, result) => {
        if (error) {
          // console.error(`race finish error: `, error);
          reject(error);
        } else {
          // console.log(`race finish good - result.authorized: `, result.authorized);
          resolve(result);
        }
      }
    );
  });
