import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  gap: 4px;
  /* width: 100%; */
  height: 29px;
  background-color: ${({ theme }) => theme.colors.lowContrastBackground};
`;

export const FilterLabel = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
`;
