import PropTypes from "prop-types";
import { useState, useMemo, useEffect } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import { useModal } from "hooks";
import { useRoles } from "features/brm/hooks/useRoles";
import * as BrmGql from "generated/graphql";
import * as S from "brm/styles/details-table.styles";
// Services
import { variantIdState } from "atoms/atoms-component";
import { DetailTableType, ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { RoutePath } from "routes/route-paths";
import { useElementTrevs } from "./api";

const TREVTable = ({ elementNameInUrl, selectedElement, showDelete = false, tableTitle }) => {
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const [data, setData] = useState([]);
  const { isRiskAnalyst } = useRoles();
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  const columns = useMemo(() => {
    if (isRiskAnalyst) {
      return [
        createColumnNameDetails(RoutePath.ThreatEvent.replace(":id", "")),
        createColumnMappedNoEdit("category"),
        createColumnMappedNoEdit("pattern"),
        createColumnMappedNoEdit("subject"),
        createColumnMappedNoEdit("target"),
      ];
    }
    return [
      createColumnMappedNoEdit("name"),
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("pattern"),
      createColumnMappedNoEdit("subject"),
      createColumnMappedNoEdit("target"),
    ];
  }, [isRiskAnalyst]);

  const [, setSelectedTrev] = useState([]);
  const {
    data: trevData,
    isError: isTervDataError,
    error: trevDataError,
  } = useElementTrevs({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    config: {
      enabled:
        !!selectedElement.id &&
        elementNameInUrl !== DetailTableType.RISKS.key &&
        elementNameInUrl !== DetailTableType.SYSTEM_ASSET_TYPES.key,
    },
  });

  const {
    data: riskTrevData,
    isError: isRiskTrevDataError,
    error: riskTrevDataError,
  } = BrmGql.useGetRiskTrevQuery(
    { risk: selectedElement.id, variant: variantId },
    { enabled: !!selectedElement.id && elementNameInUrl === DetailTableType.RISKS.key, refetchOnWindowFocus: false }
  );

  const {
    data: systemAssetTypeTrevData,
    isError: isSystemAssetTypeTrevDataError,
    error: systemAssetTypeTrevDataError,
  } = BrmGql.useGetTrevQuery(
    { project: projectId, variant: variantId, id: selectedElement.id },
    {
      enabled: !!selectedElement.id && elementNameInUrl === DetailTableType.SYSTEM_ASSET_TYPES.key,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (trevData) {
      setData(trevData);
    }
    if (riskTrevData) {
      const res = riskTrevData?.risk.ue.map((e) => e.trev).flat();
      setData(res || []);
    }
    if (systemAssetTypeTrevData) {
      const res = systemAssetTypeTrevData?.systemAssetType.trevs;
      setData(res || []);
    }
  }, [riskTrevData, trevData, systemAssetTypeTrevData]);

  if (isRiskTrevDataError) {
    return <ErrorBanner msg={riskTrevDataError?.message} />;
  }

  if (isTervDataError) {
    return <ErrorBanner msg={trevDataError?.message} />;
  }

  if (isSystemAssetTypeTrevDataError) {
    return <ErrorBanner msg={systemAssetTypeTrevDataError?.message} />;
  }

  if (data) {
    return (
      <S.DetailsContainer id="TREVTable_detailsPanel">
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedTrev}
              customProps={{ id: "TREVTable_detailsTable" }}
              showRowSelect={showDelete}
              elementName={elementNameInUrl}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }
  return <Loading />;
};

TREVTable.propTypes = {
  elementNameInUrl: PropTypes.string,
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
  }),
  showDelete: PropTypes.bool,
  tableTitle: PropTypes.string,
};

export default TREVTable;
