import { useMemo } from "react";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
import {
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnNameDetails,
} from "brm/tables/services/column/columnFactory";

// Styles
import * as S from "brm/styles/details-table.styles";
import { SystemApi, useRoles } from "features/brm";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms";
import { ErrorBanner, LoadingSpinner } from "components/elements";
import { RoutePath } from "routes/route-paths";

interface IAllDataProps {
  elementNameInUrl: string;
  selectedElement: {
    id: string;
  };
  tableTitle: string;
}

const AllDataTable = ({ elementNameInUrl, selectedElement, tableTitle }: IAllDataProps) => {
  const { isRiskAnalyst, isSystemEngineer } = useRoles();

  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Datatype.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnBooleanNoEditMapped("isInternal"),
      createColumnBooleanNoEditMapped("isStored"),
      createColumnBooleanNoEditMapped("isInput"),
      createColumnBooleanNoEditMapped("isOutput"),
      createColumnBooleanNoEditMapped("isProcessed"),
    ],
    []
  );

  const projectId = useRecoilValue(projectIdState);

  const { data: elementAllData, isError: isElementAllDataError } = SystemApi.useElementAllData({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    options: {
      enabled: !!selectedElement.id && (isRiskAnalyst || isSystemEngineer),
    },
  });

  if (isElementAllDataError) {
    return <ErrorBanner msg="Error while loading all data" />;
  }

  if (elementAllData) {
    return (
      <S.DetailsContainer id="AllDataTable_detailsPanel">
        <S.DetailsTableContainer>
          <BrmDetailTable
            data={elementAllData}
            columns={columns}
            customProps={{ id: "AllDataTable_detailsTable" }}
            showRowSelect={false}
            tableTitle={tableTitle}
          />
        </S.DetailsTableContainer>
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

export default AllDataTable;
