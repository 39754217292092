import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./person-query-key-factory";

interface ICreatePersonProps {
  projectId: string;
  personCreateDto: any;
}

/**
 * Creates a person.
 * @param {string} projectId uuid of the project
 * @param {PersonCreateDto} personCreateDto a PersonCreateDto object
 * @returns id of created person
 */
export const createPerson = ({ projectId, personCreateDto }: ICreatePersonProps) => {
  return Brm.personApi.createPerson(projectId, { personCreateDto });
};

export interface IUseCreatePerson {
  options?: MutationOption<typeof createPerson>;
}

/**
 * Custom hook to create a new person.
 *
 * @param {object} config - react-query configuration object
 * @returns react-query for getNodeByID
 */
export const useCreatePerson = ({ options = {} } = {}) => {
  return ReactQuery.useMutation({
    ...options,
    onSuccess: (data, { projectId }) => {
      queryClient.invalidateQueries(KeyFactory.personKeys.project(projectId));
    },
    mutationFn: createPerson,
  });
};
