/* eslint-disable no-param-reassign */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./asset-query-key-factory";

export const getAssetCategories = async ({ projectId }: { projectId: string }) => {
  return Brm.assetCategoryApi.findAssetCategory(projectId);
};

interface IUseAssetCategories {
  projectId: string;
  options?: QueryOption<typeof getAssetCategories>;
}

export const useAssetCategories = ({ projectId, options }: IUseAssetCategories) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.assetCategoryKeys.project(projectId),
    queryFn: () => getAssetCategories({ projectId }),
  });
};
