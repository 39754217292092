export const ImportContext = {
  system: "SYSTEM",
  sctm: "SCTM",
  rar: "RAR",
  tra: "TRA",
  savepoint: "SAVEPOINT",
  knowledgebase: "KNOWLEDGEBASE",
  bor: "BOR",
  baseline: "BASELINE",
} as const;

export type ImportContextValues = typeof ImportContext[keyof typeof ImportContext];
