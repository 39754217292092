import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./attacker-category-query-key-factory";

export const getAttackerCategories = ({ projectId }: { projectId: string }) => {
  return Brm.attackerCategoryApi.findAttackerCategory(projectId);
};

interface IUseAttackerCategories {
  projectId: string;
  options?: QueryOption<typeof getAttackerCategories>;
}

/**
 * React-query hook for getting all Attacker categories
 *
 * @param {string} projectId - uuid of the project
 * @returns react-query for getAttackerCategories
 */
export const useAttackerCategories = ({ projectId, options = {} }: IUseAttackerCategories) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.attackerCategoryKeys.project(projectId),
    queryFn: () => getAttackerCategories({ projectId }),
  });
};
