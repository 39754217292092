import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

interface IGetControlFamilies {
  controlCatalogId: string;
}

/**
 *
 * @param {string} controlCatalogId - uuid of control catalog
 * @returns array list of control families under control catalog
 */
export const getControlFamilies = ({ controlCatalogId }: IGetControlFamilies) => {
  return Brm.controlCatalogApi.getControlCatalogFamily(controlCatalogId);
};

interface IUseControlFamilies {
  controlCatalogId: string;
  options: QueryOption<typeof getControlFamilies>;
}

/**
 * React-query hook for getting all Control Families
 *
 * @param {object} options - configuration for this hook
 * @param {string} controlCatalogId - uuid of the controlCatalog
 * @returns react-query for getControlFamilies
 */
export const useControlFamilies = ({ controlCatalogId, options = {} }: IUseControlFamilies) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.controlFamilyKeys.controlCatalog(controlCatalogId),
    queryFn: () => getControlFamilies({ controlCatalogId }),
  });
};
