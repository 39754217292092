import { useContext } from "react";
import { ThemeContext } from "styled-components";

import { ReactComponent as ZoomIn } from "assets/icons/studio91/sidebars-toolbars/magnify-plus.svg";
import { ReactComponent as ZoomOut } from "assets/icons/studio91/sidebars-toolbars/magnify-minus.svg";
import { ReactComponent as ZoomReset } from "assets/icons/studio91/sidebars-toolbars/1-to-1.svg";
import { ReactComponent as ZoomFit } from "assets/icons/studio91/sidebars-toolbars/magnify-grid.svg";

import { DIAGRAM_EVENT } from "features/diagram-constants";
import messenger from "services/messenger";

import { ToolbarButton } from "../ToolbarButton";

interface IZoomButtonGroup {
  zoomIn: () => void;
  resetZoom: () => void;
  zoomOut: () => void;
  fitContent: () => void;
}

export const ZoomButtonsGroup = ({ zoomIn, resetZoom, zoomOut, fitContent }: IZoomButtonGroup) => {
  const theme = useContext(ThemeContext);

  const {
    colors: { buttonPrimary: buttonFill },
    // TODO the standard sizes from the theme look terrible... might have to revisit for now override with smaller icons
    // sizes: { standaloneIconWidth: iconWidth, standaloneIconWidth: iconHeight },
  } = theme;
  const iconWidth = "16px";
  const iconHeight = "16px";

  const zoomInIcon = <ZoomIn fill={buttonFill} width={iconWidth} height={iconHeight} />;
  const zoomOutIcon = <ZoomOut fill={buttonFill} width={iconWidth} height={iconHeight} />;
  const zoomResetIcon = <ZoomReset fill={buttonFill} width={iconWidth} height={iconHeight} />;
  const zoomFitIcon = <ZoomFit fill={buttonFill} width={iconWidth} height={iconHeight} />;

  return (
    <>
      <ToolbarButton
        icon={zoomInIcon}
        tooltip="Zoom In"
        onClick={() => {
          messenger.dispatch(DIAGRAM_EVENT.zoomIn);
          zoomIn();
        }}
      />
      <ToolbarButton
        icon={zoomResetIcon}
        tooltip="Reset Zoom"
        onClick={() => {
          messenger.dispatch(DIAGRAM_EVENT.zoomReset);
          resetZoom();
        }}
      />
      <ToolbarButton
        icon={zoomOutIcon}
        tooltip="Zoom Out"
        onClick={() => {
          messenger.dispatch(DIAGRAM_EVENT.zoomOut);
          zoomOut();
        }}
      />
      <ToolbarButton
        icon={zoomFitIcon}
        tooltip="Fit Diagram"
        onClick={() => {
          messenger.dispatch(DIAGRAM_EVENT.zoomToFit);
          fitContent();
        }}
      />
    </>
  );
};
