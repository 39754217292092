import * as Recoil from "recoil";
import * as React from "react";
import * as BrmGql from "generated/graphql";
import { SystemApi, GlobalControlApi, RiskApi, useVariant, MitigationApi } from "features/brm";
import { projectIdState, userIdState } from "atoms/atoms-admin";
import { sctmIdState } from "atoms/atoms-mitigation";
import { createOptionsFromSrc } from "utils/react-select-utils";
import { DEFAULT_CATALOG_NAME, DEFAULT_CATALOG_STANDARD } from "constants/brm";
import { sortByLabel } from "utils/sorting";
import { useBaselineControlTypes /* useSctmAllocatedControls, */ } from "../../../api";
import { getReactSelectArray, controlTypesToOptions } from "./utils";

interface IAddAllocatedControlProps {
  nodes: string[];
  exchanges: string[];
  vulnerabilityCategories: string[];
}

export const useAddAllocatedControlsForm = ({
  nodes,
  exchanges,
  vulnerabilityCategories,
}: IAddAllocatedControlProps) => {
  const userId = Recoil.useRecoilValue(userIdState);
  const [sctmId] = Recoil.useRecoilState(sctmIdState);
  const [projectId] = Recoil.useRecoilState(projectIdState);
  const { variantId } = useVariant();
  const [postError, setPostError] = React.useState("");
  // const [baseline, setBaseline] = useState<any>({});
  // const [controlTypeOptions, setControlTypeOptions] = useState([]);
  const [selectedVulnerabilityCategories, setSelectedVulnerabilityCategories] = React.useState([]);
  const [selectedNodes, setSelectedNodes] = React.useState(getReactSelectArray(nodes));
  const [selectedExchanges, setSelectedExchanges] = React.useState(getReactSelectArray(exchanges));
  const [selectedControlTypes, setSelectedControlTypes] = React.useState<any>([]);
  const [selectedFamily, setSelectedFamily] = React.useState({});
  // const [previousControls, setPreviousControls] = useState([]);
  const [, setDefaultCntrlCatalog] = React.useState("");
  const [defaultVulnerabilityCategoryOptions, setDefaultVulnerabilityCategoryOptions] = React.useState([]);
  const [controlTypesOptions, setControlTypesOptions] = React.useState([]);
  const [controlTypesSource, setControlTypesSource] = React.useState("catalog");
  const isInitialDefault = React.useRef(true);

  const { data: catalogProjData, isError } = BrmGql.useGetProjectCatalogConfigurationQuery(
    { userId: userId || "" },
    {
      select: React.useCallback(
        (data: any) =>
          data.projectFindAllByUser
            .filter((project: any) => project.id === projectId)
            .map((project: any) => project?.controlCatalog || {}),
        [projectId]
      ),
      enabled: !!userId,
    }
  );

  const { data: vulnerabilityCategoryOptions } = RiskApi.useVulnerabilityCategories({
    projectId,
    options: {
      select: React.useCallback((data: any) => createOptionsFromSrc({ src: data }).sort(sortByLabel), []),
    },
  });

  const { data: catalog } = MitigationApi.useGetVariantProperty({ property: "catalog", variantId });
  const { data: cntrlCatalogs } = GlobalControlApi.useControlCatalogs({ options: {} });
  const { data: cntrlCatalogFamilies } = GlobalControlApi.useControlFamilies({
    controlCatalogId: catalogProjData?.[0]?.id,
    options: { enabled: !!catalogProjData?.[0]?.id },
  });
  const { data: nodeslist } = SystemApi.useNodes({
    projectId,
    options: {
      enabled: !!projectId,
      select: (data) => {
        return data.filter((n: any) => n.structuralcat === "Leaf" && n.isInternal);
      },
    },
  });
  const { data: exchangeList } = SystemApi.useExchanges({
    projectId,
    variantId,
    options: { enabled: !!projectId },
  });

  const { data: sctmBaseline } = MitigationApi.useGetVariantProperty({
    property: "baseline",
    variantId,
    options: { enabled: !!sctmId },
  });

  const { data: cntrlTypesOptions } = useBaselineControlTypes({
    baselineId: sctmBaseline?.id,
    config: {
      enabled: !!sctmBaseline?.id,
      select: React.useCallback((data: any) => controlTypesToOptions(data), []),
    },
  });

  const { data: allControlTypeOptions } = GlobalControlApi.useCatalogControlTypes({
    catalogId: catalog?.id || "",
    options: {
      enabled: !!catalog,
      select: React.useCallback((data: any) => controlTypesToOptions(data), []),
    },
  });

  // const {data: allControlTypes } = GlobalControlApi.useControlTypes({controlFamilyId,
  //   options: { enabled: !!controlFamilyId },
  // });
  // console.log("options1", vulnerabilityCategoryOptions);
  // console.log("options2", vulnerabilityCategories);
  // console.log(
  //   "options3",
  //   vulnerabilityCategoryOptions.filter((o) => vulnerabilityCategories.some((c) => o.label === c))
  // );

  React.useEffect(() => {
    if (vulnerabilityCategories.length && vulnerabilityCategoryOptions) {
      setDefaultVulnerabilityCategoryOptions(
        vulnerabilityCategoryOptions.filter((o: any) => vulnerabilityCategories.some((c: any) => o.label === c))
      );
    }
  }, [vulnerabilityCategories, vulnerabilityCategoryOptions]);

  const activeControlTypeOptions = React.useMemo(() => {
    return controlTypesSource === "catalog" ? allControlTypeOptions : cntrlTypesOptions;
  }, [allControlTypeOptions, cntrlTypesOptions, controlTypesSource]);

  React.useEffect(() => {
    if (
      activeControlTypeOptions &&
      Array.isArray(defaultVulnerabilityCategoryOptions) &&
      defaultVulnerabilityCategoryOptions.length
    ) {
      if (isInitialDefault.current) {
        setSelectedVulnerabilityCategories(defaultVulnerabilityCategoryOptions);
        isInitialDefault.current = false;
      }
      const types = activeControlTypeOptions.filter((type: any) =>
        defaultVulnerabilityCategoryOptions.some((cat: any) => cat.value === type.vulnerabilitycategory)
      );
      setSelectedControlTypes([]);
      setControlTypesOptions(types.sort(sortByLabel));
    }
  }, [defaultVulnerabilityCategoryOptions, activeControlTypeOptions]);

  // useEffect(() => {
  //   if (activeControlTypeOptions && selectedVulnerabilityCategories.length === 0) {
  //     setSelectedVulnerabilityCategories(
  //       vulnerabilityCategoryOptions.filter((o) => vulnerabilityCategories.some((c) => o.label === c))
  //     );
  //   }
  // }, [selectedVulnerabilityCategories.length, vulnerabilityOptions]);

  // set default NIST 800-53 control catalog
  React.useEffect(() => {
    if (cntrlCatalogs) {
      const nistCatalog = cntrlCatalogs.filter(
        (e: any) => e.name === DEFAULT_CATALOG_NAME.name_5 && e.standard === DEFAULT_CATALOG_STANDARD.standard_5
      );
      if (nistCatalog.length) {
        setDefaultCntrlCatalog(nistCatalog[0]);
      }
    }
  }, [cntrlCatalogs]);

  // useEffect(() => {
  //   if (allocatedControls) {
  //     setPreviousControls(allocatedControls);
  //   }
  // }, [allocatedControls]);

  // useEffect(() => {
  //   if (sctmBaseline) {
  //     setBaseline(sctmBaseline);
  //   }
  // }, [sctmBaseline]);

  React.useEffect(() => {
    if (activeControlTypeOptions) {
      setControlTypesOptions(activeControlTypeOptions);
      setSelectedFamily([]);
    }
  }, [activeControlTypeOptions]);

  // useEffect(() => {
  //   if (cntrlTypes) {
  //     setControlTypeOptions(getReactSelectArray(cntrlTypes));
  //   }
  // }, [cntrlTypes]);
  const resetControlTypes = React.useCallback(() => {
    setControlTypesOptions(activeControlTypeOptions);
  }, [activeControlTypeOptions, setControlTypesOptions]);

  return {
    selectedVulnerabilityCategories,
    selectedNodes,
    selectedExchanges,
    selectedFamily,
    selectedControlTypes,
    isError,
    sctmBaseline,
    sctmId,
    nodeslist,
    exchangeList,
    cntrlCatalogFamilies,
    controlTypesSource,
    activeControlTypeOptions,
    vulnerabilityCategoryOptions,
    controlTypesOptions,
    catalog,
    defaultVulnerabilityCategoryOptions,
    postError,
    setSelectedVulnerabilityCategories,
    setSelectedControlTypes,
    setSelectedFamily,
    setSelectedNodes,
    setSelectedExchanges,
    setControlTypesSource,
    setControlTypesOptions,
    setPostError,
    resetControlTypes,
  };
};
