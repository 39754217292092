export class ElementType {
  static values: ElementType[] = [];

  static readonly ASSET = new ElementType("Asset", "asset");

  static readonly ASSET_CATEGORY = new ElementType("Asset Category", "assetCategory");

  static readonly ATTACK_KIND = new ElementType("Attack Kind", "attackkind");

  static readonly ATTACK_MECHANISM = new ElementType("Attack Mechanism", "attackmechanism");

  static readonly ATTACK_TACTIC = new ElementType("Attack Tactic", "attackTactic");

  static readonly ATTACK = new ElementType("Attack", "attack");

  static readonly ATTACK_PATH = new ElementType("Attack Path", "attackPath");

  static readonly UNDESIRED_EVENT = new ElementType("Undesired Event", "undesiredEvent");

  static readonly HARM_CATEGORY = new ElementType("Harm Category", "harmCategory");

  static readonly RISK_CATEGORY = new ElementType("Risk Category", "riskCategory");

  static readonly RISK = new ElementType("Risk", "risk");

  static readonly THREAT_CATEGORY = new ElementType("Threat Category", "threatCategory");

  static readonly THREAT_EVENT_PATTERN = new ElementType("Threat Event Pattern", "threatEventPattern");

  static readonly VULNERABILITY = new ElementType("Vulnerability", "vunerability");

  static readonly VULNERABILITY_CATEGORY = new ElementType("Vulnerability Category", "vunerabilityCategory");

  static readonly ACTIVITY = new ElementType("Activity", "activity");

  static readonly BUS = new ElementType("Bus", "bus");

  static readonly CAPABILITY = new ElementType("Capability", "capability");

  static readonly DATAFLOW = new ElementType("Dataflow", "dataflow");

  static readonly DATATYPE = new ElementType("Datatype", "datatype");

  static readonly ELEMENT_CATEGORY = new ElementType("Element Category", "elementCategory");

  static readonly EXCHANGE = new ElementType("Exchange", "exchange");

  static readonly LINK = new ElementType("Link", "link");

  static readonly MISSION = new ElementType("Mission", "mission");

  static readonly NODE = new ElementType("Node", "node");

  static readonly PERSON = new ElementType("Person", "person");

  static readonly RESOURCE = new ElementType("Resource", "resource");

  static readonly STRUCTURAL_CATEGORY = new ElementType("Structural Category", "structuralCategory");

  static readonly SYSTEM_ASSET = new ElementType("System Asset", "systemAsset");

  static readonly SYSTEM_ASSET_CATEGORY = new ElementType("System Asset Category", "systemAssetCategory");

  static readonly SYSTEM_ASSET_TYPE = new ElementType("System Asset Type", "systemAssetType");

  static readonly CATEGORIZED_ENTITY = new ElementType("Categorized Entity", "categorizedEntity");

  static readonly CLASSIFICATION_CATEGORY = new ElementType("Classification Category", "classificationCategory");

  static readonly SECURITY_OBJECTIVE = new ElementType("Security Objective", "securityObjective");

  static readonly ACCESS_POINT = new ElementType("Access Point", "accessPoint");

  static readonly ACCESS_POINT_CATEGORY = new ElementType("Access Point Category", "accessPointCategory");

  static readonly ENTRY_POINT = new ElementType("Entry Point", "entryPoint");

  static readonly ENTRY_POINT_CATEGORY = new ElementType("Entry Point Category", "entryPointCategory");

  static readonly ATTACK_SURFACE = new ElementType("Attack Surface", "attackSurface");

  static readonly ATTACK_SURFACE_CATEGORY = new ElementType("Attack Surface Category", "attackSurfaceCategory");

  static readonly ATTACK_VECTOR = new ElementType("Attack Vector", "attackVector");

  static readonly ATTACK_VECTOR_CATEGORY = new ElementType("Attack Vector Category", "attackVectorCategory");

  static readonly BOUNDARY = new ElementType("Boundar", "boundary");

  static readonly ALLOCATED_CONTROLS = new ElementType("Allocated Controls", "allocatedControls");

  static readonly SCTM = new ElementType("SCTM", "sctm");

  static readonly CONTROL_CATALOG = new ElementType("Control Catalog", "controlCatalog");

  static readonly CONTROL_FAMILY = new ElementType("Control Family", "controlFamily");

  static readonly CONTROL_TYPE = new ElementType("Control Family", "controlFamily");

  static readonly STANDARD_BASELINE = new ElementType("Standard Baseline", "standardBaseline");

  static readonly ATTACKER = new ElementType("Attacker", "attacker");

  static readonly ATTACKER_CATEGORY = new ElementType("Attacker Category", "attackerCategory");

  static readonly THREAT_CATALOG = new ElementType("Threat Catalog", "threatCatalog");

  static readonly THREAT_GROUP = new ElementType("Threat Group", "threatGroup");

  static readonly THREAT_TIER = new ElementType("Threat Tier", "threatTier");

  static readonly TTP = new ElementType("TTP", "ttp");

  static readonly INSTALLATION = new ElementType("Installation", "installation");

  static readonly PROJECT = new ElementType("Project", "project");

  static readonly USER = new ElementType("User", "user");

  static readonly ORGANIZATION = new ElementType("Organization", "organization");

  static readonly METADATA = new ElementType("Metadata", "metadata");

  static readonly VARIANT = new ElementType("Variant", "variant");

  static readonly MODULE = new ElementType("Module", "module");

  static readonly SYSTEM = new ElementType("System", "system");

  static readonly KNOWLEDGEBASE = new ElementType("Knowledgebase", "knowledgebase");

  static readonly RULESET = new ElementType("Ruleset", "ruleset");

  static fromName(name: string) {
    const result = ElementType.values.find((v) => v.name === name);
    if (result) return result;

    throw new RangeError(`No instance of ${this.constructor.name} exists with the name ${name}.`);
  }

  static fromKey(key: string) {
    const result = ElementType.values.find((v) => v.key === key);
    if (result) return result;

    throw new RangeError(`No instance of ${this.constructor.name} exists with the key ${key}.`);
  }

  public readonly name: string;

  public readonly key: string;

  constructor(name: string, key: string) {
    this.name = name;
    this.key = key;
    ElementType.values.push(this);
  }

  toJSON() {
    return this.key;
  }
}
