import { atom } from "recoil";

export const selectedNodesState = atom({
  key: "selectedNodes",
  default: [],
});

export const selectedExchangesState = atom({
  key: "selectedExchange",
  default: [],
});

export const selectedSystemState = atom({
  key: "selectedSystem",
  default: [],
});

export const selectedEffectiveState = atom({
  key: "selectedEffective",
  default: [],
});

export const selectedWorksheetState = atom({
  key: "selectedWorksheet",
  default: [],
});

export const selectedHeaderRowState = atom({
  key: "selectedHeaderRow",
  default: [],
});

export const selectedControlColumnState = atom({
  key: "selectedControlColumn",
  default: [],
});
