import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { queryClient } from "libs/react-query";
// State
import { projectIdState } from "atoms/atoms-admin";
import {
  impactOptionsState,
  objectiveSelectedFiltersState,
  assetSelectedFiltersState,
  impactSelectedFiltersState,
  calibratedImpactSelectedFiltersState,
  filteredUEImpactCalibrationState,
} from "atoms/atoms-impact-calibration";
import { useRecoilState, useRecoilValue } from "recoil";
import { variantIdState } from "atoms/atoms-component";

// Components
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { BrmMainTable } from "brm/tables/BrmTables";
import Select from "react-select";
import { createColumnImpactMapped, createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";

// URLs
import FilterIcon from "assets/icons/filter.svg";

// Styles
import { calibrationStyle } from "components/elements/ReactSelectCustomStyles";
import Title from "components/elements/StyledTitle";
import CustomTable from "components/elements/CustomTableDivStyled";
import NavListIconStyled from "components/elements/NavListIconStyled";

import getReactSelectArray from "utils/react-select-array";
import { RoutePath } from "routes/route-paths";

// Services
import * as BrmGql from "generated/graphql";
import { RiskApi } from "features/brm";

// Constants
import { COMMON, TYPE } from "constants/brm";
import { LEVEL } from "../../constants";

import { ClearImpactLevelModal } from "../../components/modals/ClearImpactLevelModal";
import { SetImpactLevelModal } from "../../components/modals/SetImpactLevelModal";
import S from "../styles/calibration.styles";
import {
  useGetAssets,
  useGetSecurityObjectives,
  useUndesiredEvents,
  useChangeImpactLevel,
} from "../../api/impact-calibration-queries";

// Impact level adjust through severity and criticality
export const ImpactCalibration = () => {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const variantId = useRecoilValue(variantIdState);
  const projectId = useRecoilValue(projectIdState);
  const impactOptions = useRecoilValue(impactOptionsState);
  const [hideDisabledUes, setHideDisabledUes] = useState(true);
  // const queryClient = useQueryClient();

  const filteredUeList = useRecoilValue(filteredUEImpactCalibrationState);
  const [showFilters, setShowFilters] = useState(false);

  const [selectedObjectives, setSelectedObjectives] = useRecoilState(objectiveSelectedFiltersState);
  const [selectedAssets, setSelectedAssets] = useRecoilState(assetSelectedFiltersState);
  const [selectedImpacts, setSelectedImpacts] = useRecoilState(impactSelectedFiltersState);
  const [selectedCalibratedImpacts, setSelectedCalibratedImpacts] = useRecoilState(
    calibratedImpactSelectedFiltersState
  );
  const [numOfActiveFilters, setNumOfActiveFilters] = useState(0);
  const [filterNumDisplay, setFilterNumDisplay] = useState("");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [clearModalIsOpen, setClearModalIsOpen] = useState(false);

  const { data: ueData, isError: isUeDataError } = useUndesiredEvents(projectId, variantId, hideDisabledUes);
  const { data: secObjList, isError: isSecObjListError } = useGetSecurityObjectives(projectId);
  const { data: assetsList, isError: isAssetListError } = useGetAssets(projectId);
  const { mutate: changeImpactLevel } = useChangeImpactLevel(projectId, variantId, hideDisabledUes);

  const { mutate: setUndesiredEventImpact } = BrmGql.useSetUndesiredEventImpactMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(
        RiskApi.undesiredEventKeys.impactCalibrationUEs(projectId, variantId, hideDisabledUes)
      );
    },
    onError: () => {
      toast.error("Error occured while setting impact level", {
        duration: 4000,
      });
    },
  });

  const columns = useMemo(
    () => [
      createColumnMappedNoEdit("ue"),
      createColumnImpactMapped("calcimpact"),
      createColumnImpactMapped("calibratedimpact"),
    ],
    []
  );

  // Switch pages if variant is null
  useEffect(() => {
    if (variantId === COMMON.nullUuid) {
      navigate(RoutePath.Projects);
    }
  }, [variantId, navigate]);

  function handleFilterChange(triggeredAction, array) {
    let currentNum = numOfActiveFilters;
    if (triggeredAction.action === "clear") {
      array.forEach(() => {
        currentNum -= 1;
      });
    } else if (triggeredAction.action === "remove-value") {
      currentNum -= 1;
    } else if (triggeredAction.action === "select-option") {
      currentNum += 1;
    }
    setNumOfActiveFilters(currentNum);

    if (currentNum > 0) {
      setFilterNumDisplay(`(${currentNum})`);
    } else {
      setFilterNumDisplay("");
    }
  }

  const handleObjectiveSelectChange = (selectedItems, triggeredAction) => {
    const items = selectedItems || [];

    handleFilterChange(triggeredAction, selectedObjectives);
    setSelectedObjectives(items);
  };
  const handleAssetSelectChange = (selectedItems, triggeredAction) => {
    const items = selectedItems || [];

    handleFilterChange(triggeredAction, selectedAssets);
    setSelectedAssets(items);
  };
  const handleImpactSelectChange = (selectedItems, triggeredAction) => {
    const items = selectedItems || [];

    handleFilterChange(triggeredAction, selectedImpacts);
    setSelectedImpacts(items);
  };

  const handleCalibratedImpactSelectChange = (selectedItems, triggeredAction) => {
    const items = selectedItems || [];

    handleFilterChange(triggeredAction, selectedCalibratedImpacts);
    setSelectedCalibratedImpacts(items);
  };

  const disableUE = () => {
    Promise.all(
      selectedRows.map((each) => {
        return changeImpactLevel({ ueId: each.id, level: "zero" });
      })
    );
  };

  const setLevel = () => {
    setModalIsOpen(true);
  };

  const clearLevel = () => {
    setClearModalIsOpen(true);
  };

  const modifyLevel = (levelShift) => {
    const ueIds = selectedRows.map((each) => each.id);
    setUndesiredEventImpact(
      { ids: ueIds, project: projectId, variant: variantId, calibratedStringValue: levelShift },
      {
        onSuccess: () => {
          toast.success("Impact Level set successfully", { duration: 4000 });
        },
        enabled: !!projectId && !!variantId && !!ueIds,
      }
    );
  };

  function toggleDisabledUes() {
    const newBool = !hideDisabledUes;
    setHideDisabledUes(newBool);
    queryClient.invalidateQueries(
      RiskApi.undesiredEventKeys.impactCalibrationUEs(projectId, variantId, hideDisabledUes)
    );
  }

  if (isAssetListError || isSecObjListError || isUeDataError) {
    return <ErrorBanner msg="Error while loading Undesired events" />;
  }

  return (
    <>
      <Title>Impact Calibration</Title>

      {modalIsOpen && (
        <SetImpactLevelModal
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          selectedRows={selectedRows}
          hideDisabledUes={hideDisabledUes}
        />
      )}

      {clearModalIsOpen && (
        <ClearImpactLevelModal
          modalIsOpen={clearModalIsOpen}
          setModalIsOpen={setClearModalIsOpen}
          selectedRows={selectedRows}
          hideDisabledUes={hideDisabledUes}
        />
      )}

      <S.FilterSection>
        {/* Buttons */}
        <S.CalibrateButtons
          id="ImpactCalibration_increaseLevelButton"
          onClick={() => modifyLevel(LEVEL.increase)}
          disabled={selectedRows.length === 0}
        >
          Increase Level
        </S.CalibrateButtons>
        <S.CalibrateButtons
          id="ImpactCalibration_decreaseLevelButton"
          onClick={() => modifyLevel(LEVEL.decrease)}
          disabled={selectedRows.length === 0}
        >
          Decrease Level
        </S.CalibrateButtons>
        <S.CalibrateButtons
          id="ImpactCalibration_setLevelButton"
          onClick={setLevel}
          disabled={selectedRows.length === 0}
        >
          Set Level
        </S.CalibrateButtons>
        <S.CalibrateButtons
          id="ImpactCalibration_clearCalibrationButton"
          onClick={clearLevel}
          disabled={selectedRows.length === 0}
        >
          Clear Calibration
        </S.CalibrateButtons>
        <S.CalibrateButtons
          id="ImpactCalibration_disableUeButton"
          onClick={disableUE}
          disabled={selectedRows.length === 0}
        >
          Disable UE
        </S.CalibrateButtons>

        {/* Filters */}
        <S.ShowFilterButton onClick={() => setShowFilters(!showFilters)}>
          <NavListIconStyled id="ImpactCalibration_filters" src={FilterIcon} alt="Filter" />
          Filters {filterNumDisplay}
        </S.ShowFilterButton>

        <S.HideLabel>
          <input
            type="checkbox"
            checked={hideDisabledUes}
            onChange={() => toggleDisabledUes(hideDisabledUes)}
            id="ImpactCalibration_disableUeCheckbox"
          />
          Hide Disabled UEs
        </S.HideLabel>
        <br />
        {showFilters ? (
          <>
            {/* Assets */}
            <S.SelectDiv>
              <S.SelectLabel>
                Assets
                <Select
                  isMulti
                  options={getReactSelectArray(assetsList)}
                  defaultValue={selectedAssets}
                  onChange={handleAssetSelectChange}
                  id="ImpactCalibration_assetDropdown"
                  classNamePrefix="assetDropdown"
                  styles={calibrationStyle}
                />
              </S.SelectLabel>
            </S.SelectDiv>
            {/* Security Objectives */}
            <S.SelectDiv>
              <S.SelectLabel>
                Security Objectives
                <Select
                  isMulti
                  options={getReactSelectArray(secObjList)}
                  defaultValue={selectedObjectives}
                  onChange={handleObjectiveSelectChange}
                  id="ImpactCalibration_objectiveDropdown"
                  classNamePrefix="objectiveDropdown"
                  styles={calibrationStyle}
                />
              </S.SelectLabel>
            </S.SelectDiv>
            {/* Impact */}
            <S.SelectDiv>
              <S.SelectLabel>
                Impact
                <Select
                  isMulti
                  options={impactOptions}
                  defaultValue={selectedImpacts}
                  onChange={handleImpactSelectChange}
                  id="ImpactCalibration_impactDropdown"
                  classNamePrefix="impactDropdown"
                  styles={calibrationStyle}
                />
              </S.SelectLabel>
            </S.SelectDiv>
            {/* Calibrated Impact */}
            <S.SelectDiv>
              <S.SelectLabel>
                Calibrated Impact
                <Select
                  isMulti
                  options={impactOptions}
                  defaultValue={selectedCalibratedImpacts}
                  onChange={handleCalibratedImpactSelectChange}
                  id="ImpactCalibration_calibratedImpactDropdown"
                  classNamePrefix="calibratedImpactDropdown"
                  styles={calibrationStyle}
                />
              </S.SelectLabel>
            </S.SelectDiv>
          </>
        ) : (
          <></>
        )}
      </S.FilterSection>
      {ueData && ueData[0]?.impact?.calcStringValue === null && (
        <S.Note>
          <em>
            <b>Note</b>
          </em>{" "}
          : You must click <b>Calculate Risks</b> button prior to making any adjustments
        </S.Note>
      )}

      {ueData && filteredUeList ? (
        <CustomTable>
          <BrmMainTable
            data={filteredUeList}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "ImpactCalibration_table" }}
            elementName={TYPE.ue}
            dataLength={ueData.length}
            showRowSelect={ueData[0]?.impact?.calcStringValue !== null}
          />
        </CustomTable>
      ) : (
        <Loading />
      )}
    </>
  );
};
