import * as React from "react";
import { ActionButton } from "components/elements";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "routes/route-paths";
import * as S from "./LicenseError.styles";

export const LicenseError = ({ licenseError = "License Error" }: { licenseError: string }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const timer = setTimeout(() => navigate(RoutePath.Logout), 20000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <S.Root>
      <S.Header>
        <h1>BRM License Error</h1>
      </S.Header>
      <S.Body>
        <S.Description>
          <S.Label>Description:</S.Label>
          <span>
            {licenseError}
            <br /> Please contact your BRM Administrator for more license information
          </span>
        </S.Description>
        <S.AutoLogoutWarning>
          You will be automatically logged out in 20 seconds or you can click the button below
        </S.AutoLogoutWarning>
      </S.Body>
      <S.Footer>
        <ActionButton onClick={() => navigate(RoutePath.Logout)}>Logout</ActionButton>
      </S.Footer>
    </S.Root>
  );
};
