import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./dataflow-query-key-factory";

/**
 * Sets the name of an Dataflow
 *
 * @param {string} dataflowId - uuid of the dataflow
 * @param {string} name - new name of the dataflow
 */
export const setDataflowName = ({ dataflowId, name }: { dataflowId: string; name: string }) => {
  return Brm.dataflowApi.setDataFlowName(dataflowId, { body: name });
};

interface IUseSetDataflowName {
  options?: MutationOption<typeof setDataflowName>;
}

/**
 * Custom hook to change the name of an existing dataflow
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetDataflowName = ({ options }: IUseSetDataflowName) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.dataflowKeys.all);
    },
    ...options,
    mutationFn: setDataflowName,
  });
};
