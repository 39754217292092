import { EdgeCreator } from "yfiles";

/**
 * Diagram edge creator that delegates to the the diagram builder helper.
 * @extends EdgeCreator
 */
export default class DiagramEdgeCreator extends EdgeCreator {
  /**
   * Creates a new instance of  {@link DiagramEdgeCreator}
   *
   * @param {!DiagramBuilderHelper} diagramBuilder
   * @param {boolean} [labelDataFromSourceAndTarget=false]
   */
  constructor(diagramBuilder, labelDataFromSourceAndTarget = false) {
    super();
    this.$diagramBuilder = diagramBuilder;
    this.$labelDataFromSourceAndTarget = labelDataFromSourceAndTarget;
  }

  /**
   * Create an edge, delegates to builderCreateEdge.
   *
   * @param {!IGraph} graph
   * @param {!INode} source
   * @param {!Node} target
   * @param {*} dataItem
   * @returns {!IEdge}
   */
  createEdge(graph, source, target, dataItem) {
    const labelData = this.$getLabelData(dataItem, source, target);
    const edgeObject = this.$getEdgeObject(dataItem);
    const edge = this.$diagramBuilder.$builderCreateEdge(graph, source, target, labelData, edgeObject);
    if (edge == null) {
      throw new Error("An edge must be created by createEdge");
    }
    return edge;
  }

  /**
   * Update an edge, delgates to builderUpdateEdge.
   * @param {!IGraph} graph
   * @param {!IEdge} edge
   * @param {*} dataItem
   */
  updateEdge(graph, edge, dataItem) {
    const labelData = this.$getLabelData(dataItem, edge.sourceNode, edge.targetNode);
    const edgeObject = this.$getEdgeObject(dataItem);
    this.$diagramBuilder.$builderUpdateEdge(graph, edge, labelData, edgeObject);
  }

  /**
   * Returns the business dataItem for this edge.
   * @param {*} dataItem
   * @returns {*}
   */
  $getEdgeObject(dataItem) {
    if (this.tagProvider) {
      return this.tagProvider(dataItem);
    }
    return dataItem;
  }

  /**
   * Returns the label for this edge.
   *
   * @param {*} dataItem
   * @param {!INode} source
   * @param {!INode} target
   * @returns {*}
   */
  $getLabelData(dataItem, source, target) {
    if (this.labelDataFromSourceAndTarget) {
      return this.$diagramBuilder.edgeLabelProvider
        ? this.$diagramBuilder.edgeLabelProvider(source.tag, target.tag)
        : null;
    }
    return this.$diagramBuilder.edgeLabelProvider ? this.$diagramBuilder.edgeLabelProvider(dataItem) : null;
  }
}
