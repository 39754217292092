/**
 * Node for the AttackGraph that tracks adjacent nodes.
 */
export default class AttackGraphNode {
  constructor(value) {
    this.$value = value;
    this.$adjacencyList = new Set();
  }

  /**
   * Return the node value.
   */
  get value() {
    return this.$value;
  }

  /**
   * return the adjacency list.
   */
  get adjacencyList() {
    return this.$adjacencyList;
  }

  /**
   * Add node to the adjacency list
   *
   * Runtime: O(1)
   *
   * @param {AttackGraphNode} node
   * @returns {AttackGraphNode} return the added node
   */
  addAdjacent(node) {
    this.$adjacencyList.add(node);
    return node;
  }

  /**
   * Remove the given node from the adjacency list.
   *
   * Runtime: O(1)
   *
   * @param {AttackGraphNode} node
   * @returns the removed node or false if the node could not be found.
   */
  removeAdjacent(node) {
    return this.$adjacencyList.delete(node);
  }

  /**
   * Returns all adjacent nodes
   *
   * @returns array of adjacent nodes
   */
  getAdjacents() {
    return Array.from(this.$adjacencyList);
  }

  /**
   * Check if the given node is adjacent to this node.
   *
   * Runtime: O(1)
   * @param {AttackGraphNode} node
   * @returns true if node is adjcent to this node;
   */
  isAdjacent(node) {
    return this.$adjacencyList.has(node);
  }
}
