import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./mission-query-key-factory";

/**
 * Gets the missions for a project
 *
 * @param {string} projectId - uuid of the project
 */
export const getMissions = (projectId: string) => {
  return Brm.missionApi.findMission(projectId);
};

export interface IUseMissions {
  projectId: string;
  options?: QueryOption<typeof getMissions>;
}

/**
 * Return a list of missions.
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query configuration object
 * @returns react-query-data object for project missions
 */
export const useMissions = ({ projectId, options = {} }: IUseMissions) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.missionKeys.project(projectId),
    queryFn: () => getMissions(projectId),
  });
};
