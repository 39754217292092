import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { COMMON } from "constants/brm";
import * as KeyFactory from "./variant-query-key-factory";
import { BrmError } from "../../../errors/BrmError";

interface IGetVariantDetailsProps {
  id: string;
  projectId: string;
}

const getVariantDetails = async ({ id, projectId }: IGetVariantDetailsProps) => {
  let variantsToDisplay: any = {};
  try {
    const initialRiskScore = await Brm.variantApi.getVariantScore(COMMON.nullUuid, projectId);
    const data = await Brm.variantApi.getVariantByID(id);
    const sctmData = await Brm.variantApi.getVariantSctm(id);
    const baselineData = await Brm.variantApi.getVariantBaseline(id);
    const ctrlCatalogData = await Brm.controlCatalogApi.getControlCatalogByID(data.catalog);
    variantsToDisplay = data;
    variantsToDisplay.sctm = sctmData;
    variantsToDisplay.baseline = baselineData;
    variantsToDisplay.initialScore = initialRiskScore;
    variantsToDisplay.controlCatalog = ctrlCatalogData;
  } catch (err: any) {
    console.error("err in GET variants: ", err);
    throw new BrmError("Cannot access variant details. Check Catalog assigned and try again.");
  }
  return [variantsToDisplay];
};

interface IUseVariantFullDetailsProps {
  variantId: string;
  projectId: string;
  setSelectedElement: (value: any) => void;
  setSelectedControlCatalogId: (value: any) => void;
  options?: QueryOption<typeof getVariantDetails>;
}

/**
 * React-query hook to get variant full details
 *
 * @param {string} variantId - uuid of variant
 * @param {string} projectId - uuid of project
 * @param {function} setSelectedElement - A useState setter
 * @param {object} options - react-query configuration object
 * @returns react-query for getVariants
 */
export const useVariantFullDetails = ({
  variantId,
  projectId,
  setSelectedElement,
  setSelectedControlCatalogId,
  options = {},
}: IUseVariantFullDetailsProps) => {
  return ReactQuery.useQuery(
    KeyFactory.variantsKeys.variant(projectId, variantId),
    () => getVariantDetails({ id: variantId, projectId }),
    {
      ...options,
      onSuccess: (data) => {
        setSelectedElement(data[0]);
        setSelectedControlCatalogId(data[0].catalog);
      },
    }
  );
};
