import * as React from "react";
import PropTypes from "prop-types";
import systemModals from "components/modals/Modals";
import { BrmDetailTable } from "brm/tables/BrmTables";
import LinkButton from "components/elements/LinkButton";
import { DeleteButton, AdminApi, useProject, useRoles, ProjectAccessLevel } from "features/brm";
import {
  createColumnMappedNoEdit,
  createColumnUsernameDetails,
  createColumnCreatedAt,
  createColumnModifiedAt,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";
import { toast } from "react-hot-toast";
import { AddWorkerForm } from "../../forms/AddWorkerForm";

export const WorkerDetailsTable = ({ elementNameInUrl, selectedElement }) => {
  const { userId, isAdmin, isSuperAdmin } = useRoles();
  const [currentProject, { setCurrentProject }] = useProject();

  const [selectedWorker, setSelectedWorker] = React.useState([]);
  const [selectedWorkers, setSelectedWorkers] = React.useState([]);
  const [showDelete] = React.useState(true);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const { mutateAsync: removeProjectWorker } = AdminApi.useRemoveProjectWorker();

  const projId = selectedElement?.id;

  const { hasProjectPermission: canAccessProject } = AdminApi.useUserProjectPermission({
    userId,
    projectId: projId,
    options: {
      enabled: !!userId && !!projId,
    },
  });

  const { data: projectAccessLevel } = AdminApi.useProjectAccess({
    projectId: projId,
    options: { enabled: !!projId },
  });

  // console.log("canAccessProject", canAccessProject);

  const { data: projUsers, isError: isProjUsersError } = AdminApi.useProjectWorkers({
    projectId: projId,
    options: {
      enabled:
        (!!projId && canAccessProject) || (isAdmin && projectAccessLevel !== ProjectAccessLevel.None) || isSuperAdmin,
    },
  });

  const columns = React.useMemo(
    () => [
      createColumnUsernameDetails(RoutePath.User.replace(":id", "")),
      createColumnMappedNoEdit("firstname"),
      createColumnMappedNoEdit("lastname"),
      createColumnMappedNoEdit("org"),
      createColumnMappedNoEdit("status"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
    ],
    []
  );

  const addWorker = () => {
    setModalIsOpen(true);
  };

  const deleteWorker = async () => {
    try {
      await Promise.all(
        selectedWorkers.map((e) => removeProjectWorker({ projectId: selectedElement.id, userId: e.id }))
      )
        .then(() => {
          /* CHECK FOR CURRENT PROJECT IF ASSIGNED TO THE WORKER ABOUT TO DELETE */
          if (
            selectedWorker &&
            selectedElement &&
            currentProject &&
            selectedWorker.id === userId &&
            selectedElement.id === currentProject.id
          ) {
            setCurrentProject(null);
          }
        })
        .catch(() => {
          toast.error("Unable to delete user");
        });
    } catch (err) {
      console.error(err);
    }
  };

  if (isProjUsersError) {
    return <ErrorBanner msg="Error while loading users data." />;
  }

  if (projUsers) {
    return (
      <S.DetailsContainer id="WorkerTable_detailsPanel">
        {modalIsOpen &&
          systemModals.customModal(
            "WorkerTable_panel",
            modalIsOpen,
            setModalIsOpen,
            <AddWorkerForm
              setModalIsOpen={setModalIsOpen}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              workerData={projUsers}
            />,
            "Add User"
          )}

        <S.ActionContainer>
          <S.DetailsTableContainer id="WorkerTable_table">
            <BrmDetailTable
              data={projUsers}
              columns={columns}
              setSelectedElement={setSelectedWorker}
              setSelectedRows={setSelectedWorkers}
              customProps={{ id: "WorkerTable_detailsTable" }}
              showRowSelect={showDelete}
            />
          </S.DetailsTableContainer>

          <S.DetailsTableButtonsContainer id="WorkerTable_buttons">
            {selectedWorkers?.length > 0 && <DeleteButton md onClick={deleteWorker} />}
            <LinkButton md onClick={addWorker} />
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

WorkerDetailsTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
};
