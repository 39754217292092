import safelyParseJson from "utils/safely-parse-json";
import { MutableSnapshot } from "recoil";
import {
  projectIdState,
  userIdState,
  userRoleState,
  userAssignedRolesState,
  projectNameState,
  isReadOnlyState,
} from "atoms/atoms-admin";
import { currentProjectState } from "features/brm";

const atomMap = new Map<string, any>()
  .set("projectId", projectIdState)
  .set("userId", userIdState)
  .set("userRole", userRoleState)
  .set("userAssignedRoles", userAssignedRolesState)
  .set("projectNameState", projectNameState)
  .set("currentProjectState", currentProjectState)
  .set("isReadOnlyState", isReadOnlyState);

// eslint-disable-next-line import/prefer-default-export
export const initializeRecoilState = ({ set }: MutableSnapshot) => {
  const storage = window.sessionStorage;
  for (let i = 0; i < storage.length; i += 1) {
    const key = storage.key(i);
    if (key !== null) {
      const unsafeValue = storage.getItem(key);
      if (unsafeValue === null) {
        return;
      }
      const safeValue = safelyParseJson(unsafeValue);
      if (safeValue) {
        const { value } = safeValue;
        if (atomMap.has(key)) {
          set(atomMap.get(key), value);
        }
      }
    }
  }
};
