import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./resource-query-key-factory";

export interface ISetResourceCategory {
  resourceId: string;
  category: string;
}

/**
 * Sets the category property for resource
 *
 * @param {string} resourceId - uuid of the resource
 * @param {string} category - category value
 */
export const setResourceCategory = ({ resourceId, category }: ISetResourceCategory) => {
  return Brm.resourceApi.setResourceCategory(resourceId, { body: category });
};

export interface IUseSetResourceCategory {
  options?: MutationOption<typeof setResourceCategory>;
}

/**
 * Custom hook to change the category property of an existing resource
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetResourceCategory = ({ options = {} }: IUseSetResourceCategory = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.resourceKeys.all);
    },
    ...options,
    mutationFn: setResourceCategory,
  });
};
