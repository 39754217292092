import { useMemo, useEffect } from "react";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";
import { LoadingSpinner } from "components/elements";
import * as AttackApi from "brm/risk-model/attack/api";
import { createColumnCategoryName } from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm";

const AttackKindTable = ({ setSelectedRows, refresh, setRefresh }) => {
  const { isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data: attackKinds, isError } = AttackApi.useAttackKinds(projectId, {
    enabled: !!projectId && isRiskAnalyst,
  });

  const columns = useMemo(
    () => [createColumnCategoryName(selectedInstance.id, setSelectedInstance, "attackKind")],
    [selectedInstance.id, setSelectedInstance]
  );

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh, setRefresh]);

  if (isError) {
    return <div>Error While loading {TYPE.attackKind} data</div>;
  }

  if (attackKinds) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={attackKinds}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "AttackKindTable_table" }}
            showRowSelect={false}
            elementName={TYPE.attackKind}
          />
        </CustomTable>
      </>
    );
  }
  return <LoadingSpinner />;
};

AttackKindTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default AttackKindTable;
