import { useState, useEffect, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import errorUtils from "utils/error-utils";
import { organizationApi } from "services/brm/admin-service";

import {
  createColumnNameDetails,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";

export const InstallationOrganizationDetailsTable = () => {
  const [organizationData, setOrganizationData] = useState([]);
  const [, setSelectedOrganization] = useState([]);

  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Organization.replace(":id", "")),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnMappedNoEdit("status"),
    ],
    []
  );

  useEffect(() => {
    async function getData() {
      // get organizations
      try {
        const { data, response } = await organizationApi.findOrganizationWithHttpInfo();
        errorUtils.checkErrorStatus(response.status, response.statusText);
        setOrganizationData(data);
      } catch (error) {
        console.error("Installation Organization GET: ", error);
      }
    }
    getData();
  }, []);

  return (
    <S.DetailsContainer id="InstallationOrganizationTable_detailsPanel">
      <BrmDetailTable
        data={organizationData}
        columns={columns}
        setSelectedElement={setSelectedOrganization}
        setSelectedRows={() => {}}
        customProps={{ id: "InstallationOrganizationTable_detailsTable" }}
        showRowSelect={false}
      />
    </S.DetailsContainer>
  );
};
