import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

export interface ISetSystemAssetTypeCategory {
  saTypeId: string;
  saTypeCat: string;
}

export const setSystemAssetTypeCategory = ({ saTypeId, saTypeCat }: ISetSystemAssetTypeCategory) => {
  return Brm.systemAssetTypeApi.setSystemAssetTypeCategory(saTypeId, { body: saTypeCat });
};

export interface IUseSetSystemAssetTypeCategory {
  options?: MutationOption<typeof setSystemAssetTypeCategory>;
}

/**
 * Custom hook to change the asset type category of a existing system asset Type
 * @returns react-query ReactQuery.useMutation
 */
export const useSetSystemAssetTypeCategory = ({ options }: IUseSetSystemAssetTypeCategory = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.systemAssetTypeKeys.all);
    },
    ...options,
    mutationFn: setSystemAssetTypeCategory,
  });
};
