// const font = "12px Segoe UI";
const fontFamily = '"Segoe UI", "Nunito Sans", "Roboto", "Helvetica", "Arial", "sans-serif"';
const fontSize = "14px";

export const customStyles = {
  control: (base) => ({
    ...base,
    height: 27,
    minHeight: 27,
    flexWrap: "no-wrap",
    width: "200px",
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999, width: "200px" }),
  option: (provided) => ({
    ...provided,
    padding: 5,
    width: "100%",
    height: "auto",
    fontFamily,
    fontSize,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily,
    fontSize,
  }),
  noOptionsMessage: () => ({
    display: "flex",
    justifyContent: "space-around",
    fontFamily,
    fontSize,
    color: "black",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  dropdownIndicator: () => ({
    color: "black",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily,
    fontSize,
  }),
};
const selectHeight = 113;

export const calibrationStyle = {
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999, fontFamily, fontSize }),
  option: (provided) => ({
    ...provided,
    fontFamily,
    fontSize,
  }),
  container: (provided) => ({
    ...provided,
    fontFamily,
    fontSize,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily,
    fontSize,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily,
    fontSize,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily,
    fontSize,
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontFamily,
    fontSize,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    fontFamily,
    fontSize,
  }),
  valueContainer: (styles) => ({
    ...styles,
    overflowY: "auto",
    maxHeight: selectHeight,
  }),
};

export const setCalibrationStyle = {
  menu: (provided) => ({ ...provided, zIndex: 9999, fontFamily, fontSize }),
  menuList: (provided) => ({
    ...provided,
    height: "120px",
    overflowY: "scroll",
  }),
};

export const menuListStyle = {
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "150px",
    overflowY: "auto",
  }),
  dropdownIndicator: () => ({
    padding: "8px",
    color: "#005ca6",
  }),
};
