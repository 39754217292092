import { useMemo } from "react";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { TYPE } from "constants/brm";
import { createColumnName, createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { ThreatApi, METADATA_TYPE, useRoles } from "features/brm";

const VulnerabilityCategoryTable = ({ setSelectedRows }) => {
  const { isRiskAnalyst } = useRoles();
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data: vulCategories, error } = ThreatApi.useGlobalThreatMetadata({
    type: METADATA_TYPE.vulncat,
    options: { enabled: isRiskAnalyst },
  });

  const columns = useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, false, null, null),
      createColumnMappedNoEdit(METADATA_TYPE.vulncat),
    ],
    [selectedInstance.id, setSelectedInstance]
  );

  if (error) {
    return <ErrorBanner msg="Error loading vulnerability categories data" />;
  }

  if (vulCategories) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={vulCategories}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "VulnerabilityCategoryTable_table" }}
            showRowSelect={false}
            elementName={TYPE.vulnerabilityCat}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

VulnerabilityCategoryTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

export default VulnerabilityCategoryTable;
