export const ColumnId = {
  rank: "rank",
  level: "level",
  findings: "findings",
  controls: "controls",
  category: "category",
  name: "name",
  controlFamily: "controlFamily",
  cost: "cost",
  strength: "strength",
  controlType: "controlType",
  targets: "targets",
} as const;

export type ContentId = typeof ColumnId[keyof typeof ColumnId];
