import styled from "styled-components/macro";

export const HelpButton = styled.div`
  display: flex;
  // justify-content: center;

  &:hover {
    background-color: #ebecf0;
  }
`;

export const Separator = styled.div`
  display: flex;
  padding: 0 10px;
`;

export const Icon = styled.div`
  padding: 10px;
`;
