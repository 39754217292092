export const variantsKeys = {
  all: ["variants"] as const,
  detail: (variantId: string) => [...variantsKeys.all, variantId] as const,
  project: (projectId: string) => [...variantsKeys.all, projectId] as const,
  // projectVariants: (projectId: string) => [...variantsKeys.project(projectId), "getVariants"] as const,
  variant: (projectId: string, variantId: string) => [...variantsKeys.project(projectId), variantId] as const,
  sctm: (variantId: string) => [...variantsKeys.all, variantId, "sctm"] as const,
  diff: (variant1Id: string, variant2Id: string) => [...variantsKeys.all, "diff", variant1Id, variant2Id] as const,
  variantsWithScores: (projectId: string) => [...variantsKeys.all, projectId, "scores"] as const,
  property: (variantId: string, property: string) => [...variantsKeys.all, variantId, "property", property] as const,
};
