import UserService from "services/brm/user-service";

export const endpointUrl = "/graphql";

export const getFetchParams = async () => {
  await UserService.updateToken();
  const token = UserService.getToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: "include" as RequestCredentials,
  };
};
