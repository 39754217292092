import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as BrmGql from "generated/graphql";
import * as KeyFactory from "./threat-group-query-key-factory";

export const deleteThreatGroup = ({ threatGroupId }: { threatGroupId: string }) => {
  return Brm.threatGroupApi.deleteThreatGroup(threatGroupId);
};

export interface IUseDeleteThreatGroup {
  options?: MutationOption<typeof deleteThreatGroup>;
}

export const useDeleteThreatGroup = ({ options }: IUseDeleteThreatGroup = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.threatGroupKeys.all);
      queryClient.invalidateQueries(BrmGql.useGetThreatGroupsQuery.getKey());
    },
    ...options,
    mutationFn: deleteThreatGroup,
  });
};
