export const RiskAnalystHelpContents = {
  metadata: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Add meta data information",
        filePath: "/help-assets/RA/AddMetaDataInformation/AddMetaDataInformation_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Update metadata by RA",
        filePath: "/help-assets/RA/updateMetadata/updateMetadata_player.html",
      },
    ],
  },

  nodes: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View node details by RA",
        filePath: "/help-assets/RA/ViewNodeDetails_RA/ViewNodeDetails_RA_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Download BOR target guidance report",
        filePath: "/help-assets/RA/DownloadBORTargetGuidanceReport/DownloadBORTargetGuidanceReport_player.html",
      },
    ],
  },

  nodeFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate vulnerability conditions per node",
        filePath: "/help-assets/RA/autoMitigateNodeVulnerabilities/autoMitigateNodeVulnerabilities_player.html",
      },
    ],
  },

  riskFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate vulnerability conditions per risk",
        filePath:
          "/help-assets/RA/AutoMitigateVulnerabilityConditionsPerRisk/AutoMitigateVulnerabilityConditionsPerRisk_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate vulnerability conditions using RAR",
        filePath:
          "/help-assets/RA/AutoMitigateVulnerabilityConditionsUsingRar/AutoMitigateVulnerabilityConditionsUsingRar_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "User-Guided Mitigation",
        filePath: "/help-assets/RA/manualMitigation/manualMitigation_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View risk assessment report",
        filePath: "/help-assets/RA/ViewARiskAssessmentReport/ViewARiskAssessmentReport_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Display RAR additional placement data for a risk",
        filePath:
          "/help-assets/RA/displayRARAdditionalPlacementDataForARisk/displayRARAdditionalPlacementDataForARisk_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Export RAR",
        filePath: "/help-assets/RA/exportRar/exportRar_player.html ",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Focus RAR by contributor",
        filePath: "/help-assets/RA/focusRARByContributor/focusRARByContributor_player.html ",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Focus on selected RAR",
        filePath: "/help-assets/RA/focusOnSelectedRAR/focusOnSelectedRAR_player.html ",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Layout the RAR attack tree",
        filePath: "/help-assets/RA/layoutTheRARAttackTree/layoutTheRARAttackTree_player.html ",
      },
    ],
  },

  activities: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View activity details",
        filePath: "/help-assets/RA/ViewActivityDetails_RA/ViewActivityDetails_RA_player.html",
      },
    ],
  },

  activityFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate activity vulnerability conditions",
        filePath: "/help-assets/RA/autoMitigateActivityVulnerabilities/autoMitigateActivityVulnerabilities_player.html",
      },
    ],
  },

  attackFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate attack vulnerability conditions",
        filePath:
          "/help-assets/RA/AutoMitigateAttackVulnerabilityConditions/AutoMitigateAttackVulnerabilityConditions_player.html",
      },
    ],
  },

  attackersDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate attacker vulnerability conditions",
        filePath:
          "/help-assets/RA/AutoMitigateAttackerVulnerabilityConditions/AutoMitigateAttackerVulnerabilityConditions_player.html",
      },
    ],
  },

  capabilityFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate capability vulnerability conditions",
        filePath:
          "/help-assets/RA/autoMitigateCapabilityVulnerabilities/autoMitigateCapabilityVulnerabilities_player.html",
      },
    ],
  },

  dataflowFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate dataflow vulnerability conditions",
        filePath: "/help-assets/RA/autoMitigateDataflowVulnerabilities/autoMitigateDataflowVulnerabilities_player.html",
      },
    ],
  },

  datatypeFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate datatype vulnerability conditions",
        filePath: "/help-assets/RA/autoMitigateDatatypeVulnerabilities/autoMitigateDatatypeVulnerabilities_player.html",
      },
    ],
  },

  missionFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate mission vulnerability conditions",
        filePath:
          "/help-assets/RA/AutoMitigateMissionVulnerabilityConditions/AutoMitigateMissionVulnerabilityConditions_player.html",
      },
    ],
  },

  personFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate person vulnerability conditions",
        filePath: "/help-assets/RA/autoMitigatePersonVulnerabilities/autoMitigatePersonVulnerabilities_player.html",
      },
    ],
  },

  resourceFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate resource vulnerability conditions",
        filePath: "/help-assets/RA/autoMitigateResourceVulnerabilities/autoMitigateResourceVulnerabilities_player.html",
      },
    ],
  },

  undesiredEventFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate undesired event vulnerability conditions",
        filePath:
          "/help-assets/RA/AutoMitigateUndesiredEventVulnerabilityConditions/AutoMitigateUndesiredEventVulnerabilityConditions_player.html",
      },
    ],
  },

  exchangeFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate exchange vulnerability conditions",
        filePath:
          "/help-assets/RA/AutoMitigateExchangeVulnerabilityConditions/AutoMitigateExchangeVulnerabilityConditions_player.html",
      },
    ],
  },

  exchanges: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View exchange details",
        filePath: "/help-assets/RA/ViewExchangeDetails_RA/ViewExchangeDetails_RA_player.html",
      },
    ],
  },

  missions: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View mission details",
        filePath: "/help-assets/RA/ViewMissionDetails_RA/ViewMissionDetails_RA_player.html",
      },
    ],
  },

  persons: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View person details",
        filePath: "/help-assets/RA/ViewPersonDetails_RA/ViewPersonDetails_RA_player.html",
      },
    ],
  },

  resources: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View resource details",
        filePath: "/help-assets/RA/ViewResourceDetails_RA/ViewResourceDetails_RA_player.html",
      },
    ],
  },

  capabilities: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View capability details",
        filePath: "/help-assets/RA/ViewCapabilityDetails_RA/ViewCapabilityDetails_RA_player.html",
      },
    ],
  },

  dataflows: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View dataflow details",
        filePath: "/help-assets/RA/ViewDataflowDetails_RA/ViewDataflowDetails_RA_player.html",
      },
    ],
  },

  datatypes: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View datatype details",
        filePath: "/help-assets/RA/ViewDatatypeDetails_RA/ViewDatatypeDetails_RA_player.html",
      },
    ],
  },

  assets: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View asset details",
        filePath: "/help-assets/RA/ViewAssetDetails_RA/ViewAssetDetails_RA_player.html",
      },
    ],
  },

  assetFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate asset vulnerabilities",
        filePath: "/help-assets/RA/autoMitigateAssetVulnerabilities/autoMitigateAssetVulnerabilities_player.html",
      },
    ],
  },

  attacks: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View attack related attack mechanism",
        filePath:
          "/help-assets/RA/ViewAttackRelatedAttackMechanismDefinition/ViewAttackRelatedAttackMechanismDefinition_player.html",
      },
    ],
  },

  attackMechanisms: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View attack mechanism definition",
        filePath: "/help-assets/RA/ViewAttackMechanismDefinition_RA/ViewAttackMechanismDefinition_player.html",
      },
    ],
  },

  attackSurfacesDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View attack surface details",
        filePath: "/help-assets/RA/ViewAttackSurfaceDetails_RA/ViewAttackSurfaceDetails_RA_player.html",
      },
    ],
  },

  attackVectorsDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View attack vector details",
        filePath: "/help-assets/RA/ViewAttackVectorDetails_RA/ViewAttackVectorDetails_RA_player.html",
      },
    ],
  },

  accessPointsDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View access point details",
        filePath: "/help-assets/RA/ViewAccessPoints_RA/ViewAccessPoints_RA_player.html",
      },
    ],
  },

  entryPointsDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View entry point details",
        filePath: "/help-assets/RA/ViewEntryPointDetails_RA/ViewEntryPointDetails_RA_player.html",
      },
    ],
  },

  threatEventFullDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate threat event vulnerability conditions",
        filePath:
          "/help-assets/RA/AutoMitigateThreatEventVulnerabilityConditions/AutoMitigateThreatEventVulnerabilityConditions_player.html",
      },
    ],
  },

  undesiredEvents: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View undesired event details",
        filePath: "/help-assets/RA/ViewUndesiredEventDetails/ViewUndesiredEventDetails_player.html",
      },
    ],
  },

  attackGraph: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Add and remove nodes in the attack diagram",
        filePath: "/help-assets/RA/AddRemoveNodesInTheAttackDiagram/AddRemoveNodesInTheAttackDiagram_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Changing layouts in the attack diagram",
        filePath: "/help-assets/RA/ChangingLayoutsInTheAttackDiagram/ChangingLayoutsInTheAttackDiagram_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Export attack diagram as image",
        filePath: "/help-assets/RA/ExportAttackDiagramAsImage/ExportAttackDiagramAsImage_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Filtering the attack diagram",
        filePath: "/help-assets/RA/FilteringTheAttackDiagram/FilteringTheAttackDiagram_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Focus on selected in attack diagram",
        filePath: "/help-assets/RA/FocusOnSelectedInAttackDiagram/FocusOnSelectedInAttackDiagram_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View the attack diagram",
        filePath: "/help-assets/RA/ViewTheAttackDiagram/ViewTheAttackDiagram_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Show and hide labels in the attack diagram",
        filePath: "/help-assets/RA/ShowHideLabelsInTheAttackDiagram/ShowHideLabelsInTheAttackDiagram_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Zooming the attack diagram",
        filePath: "/help-assets/RA/ZoomingTheAttackDiagram/ZoomingTheAttackDiagram_player.html",
      },
    ],
  },

  allocatedControls: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Run compliance risk",
        filePath: "/help-assets/RA/runComplianceRisk/runComplianceRisk_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Clear compliance for allocated controls",
        filePath: "/help-assets/RA/clearComplianceForAllocatedControls/clearComplianceForAllocatedControls_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Clear calibrated strength for allocated controls",
        filePath:
          "/help-assets/RA/clearCalibratedStrengthForAllocatedControls/clearCalibratedStrengthForAllocatedControls_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Set compliance for allocated controls",
        filePath: "/help-assets/RA/setComplianceForAllocatedControls/setComplianceForAllocatedControls_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Set calibrated strength for allocated controls",
        filePath:
          "/help-assets/RA/setCalibratedStrengthForAllocatedControls/setCalibratedStrengthForAllocatedControls_player.html",
      },

      {
        role: "Risk Analyst",
        type: "Video",
        name: "Allocate controls to a variant",
        filePath: "/help-assets/RA/AllocateControlsToAVariant/AllocateControlsToAVariant_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Filtering allocated controls",
        filePath: "/help-assets/RA/filteringAllocatedControls/filteringAllocatedControls_player.html",
      },
    ],
  },

  vulnerabilities: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Auto mitigate a set of vulnerabilities",
        filePath: "/help-assets/RA/AutoMitigateASetOfVulnerabilities/AutoMitigateASetOfVulnerabilities_player.html",
      },
    ],
  },

  threatEvents: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View threat event details",
        filePath: "/help-assets/RA/ViewThreatEventDetails/ViewThreatEventDetails_player.html",
      },
    ],
  },

  impactCalibration: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Calibrate impact",
        filePath: "/help-assets/RA/CalibrateImpact/CalibrateImpact_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Filtering impact calibration",
        filePath: "/help-assets/RA/filteringImpactCalibration/filteringImpactCalibration_player.html",
      },
    ],
  },

  likelihoodCalibration: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Calibrate likelihood",
        filePath: "/help-assets/RA/CalibrateLikelihood/CalibrateLikelihood_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Filtering likelihood calibration",
        filePath: "/help-assets/RA/filteringLikelihoodCalibration/filteringLikelihoodCalibration_player.html",
      },
    ],
  },

  sctmsDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Import an SCTM workbook",
        filePath: "/help-assets/RA/ImportAnSCTMWorkbook/ImportAnSCTMWorkbook_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Export an SCTM workbook",
        filePath: "/help-assets/RA/ExportAnSctmWorkbook/ExportAnSctmWorkbook_player.html",
      },
    ],
  },
  variants: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Clone a variant",
        filePath: "/help-assets/RA/CloneVariant/CloneVariant_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Create a variant",
        filePath: "/help-assets/RA/CreateAVariant/CreateAVariant_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Delete a variant",
        filePath: "/help-assets/RA/deleteVariant/deleteVariant_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Modify a project variant",
        filePath: "/help-assets/RA/ModifyAProjectVariant/ModifyAProjectVariant_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Set variant compliance",
        filePath: "/help-assets/RA/SetVariantCompliance/SetVariantCompliance_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Use the reports page",
        filePath: "/help-assets/RA/UseTheReportsPage/UseTheReportsPage_player.html",
      },

      {
        role: "Risk Analyst",
        type: "Video",
        name: "Run mitigated risk",
        filePath: "/help-assets/RA/runMitigatedRisk/runMitigatedRisk_player.html",
      },
    ],
  },

  reports: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Export Import tra reports",
        filePath: "/help-assets/RA/export-importTRAReports/export-importTRAReports_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Export threat risk analysis report",
        filePath: "/help-assets/RA/ExportThreatRiskAnalysisReport/ExportThreatRiskAnalysisReport_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Export risk analysis report",
        filePath: "/help-assets/RA/RarExporter/RarExporter_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Export SCTM compliance report",
        filePath: "/help-assets/RA/exportSCTMComplianceReport/exportSCTMComplianceReport_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View variant difference report from Reports page",
        filePath: "/help-assets/RA/Reports-ViewVariantDifferenceReport/Reports-ViewVariantDifferenceReport_player.html",
      },
    ],
  },

  projectOverview: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Import vulnerability findings",
        filePath: "/help-assets/RA/ImportVulnerabilityFindings/ImportVulnerabilityFindings_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View a CWE report ",
        filePath: "/help-assets/RA/ViewACWEReport/ViewACWEReport_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View a system CWE guidance report ",
        filePath: "/help-assets/RA/viewasystemcweguidancereport/viewasystemcweguidancereport_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Export calibration log ",
        filePath: "/help-assets/RA/exportCalibrationLog/exportCalibrationLog_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Create save point for project by RA",
        filePath: "/help-assets/RA/createSavePoint/createSavePoint_player.html",
      },
    ],
  },

  variantsDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View a control report",
        filePath: "/help-assets/RA/Reports-ViewAControlReport/Reports-ViewAControlReport_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View control guidance report",
        filePath: "/help-assets/RA/Reports-ViewControlGuidanceReport/Reports-ViewControlGuidanceReport_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View CSA KPP report",
        filePath: "/help-assets/RA/Reports-ViewCsaKppReport/Reports-ViewCsaKppReport_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View cyber security framework report",
        filePath:
          "/help-assets/RA/Reports-ViewCyberSecurityFrameworkReport/Reports-ViewCyberSecurityFrameworkReport_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View variant difference report from Variant Details page",
        filePath: "/help-assets/RA/variantDifferenceReport/variantDifferenceReport_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Set variant baseline",
        filePath: "/help-assets/RA/SetVariantBaseline/SetVariantBaseline_player.html",
      },
    ],
  },

  systemDiagram: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Review controls,vulnerability conditions and vulnerability findings",
        filePath: "/help-assets/RA/ReviewControlsVulCondVulFindRASDv2/ReviewControlsVulCondVulFindRASDv2_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View decorators in system diagram",
        filePath: "/help-assets/RA/ViewDecoratorsInSystemDiagramv2/ViewDecoratorsInSystemDiagramv2_player.html",
      },
    ],
  },

  risks: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Review prioritized risk details",
        filePath: "/help-assets/RA/ReviewPrioritizedRiskDetails/ReviewPrioritizedRiskDetails_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Custom prioritized risks view",
        filePath: "/help-assets/RA/customPrioritzedRisksView/customPrioritzedRisksView_player.html",
      },
    ],
  },

  projects: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Run risk analysis",
        filePath: "/help-assets/RA/RunRiskAnalysis/RunRiskAnalysis_player.html",
      },
    ],
  },

  systemsDetails: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View system details",
        filePath: "/help-assets/RA/ViewSystemDetails/ViewSystemDetails_player.html",
      },
    ],
  },

  contextDiagram: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "View context diagram by RA",
        filePath: "/help-assets/RA/viewContextDiagram/viewContextDiagram_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Filter context diagram by RA",
        filePath: "/help-assets/RA/filteringContextDiagram/filteringContextDiagram_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Highlight entry points related to external access points by RA",
        filePath:
          "/help-assets/RA/highlightEntryPointsRelatedToExternalAccessPoints/highlightEntryPointsRelatedToExternalAccessPoints_player.html",
      },
    ],
  },

  systemAssets: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Add system asset",
        filePath: "/help-assets/RA/addSytemAsset_RA/addSytemAsset_RA_player.html",
      },
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Export system asset list",
        filePath: "/help-assets/RA/exportSystemAssetList_RA/exportSystemAssetList_RA_player.html",
      },
    ],
  },

  systemAssetTypes: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Add System Asset Types by RA",
        filePath: "/help-assets/RA/addSystemAssetTypes/addSystemAssetTypes_player.html",
      },
    ],
  },

  categorizedEntities: {
    contents: [
      {
        role: "Risk Analyst",
        type: "Video",
        name: "Edit categorized entity from list",
        filePath: "/help-assets/RA/EditCategorizedEntityFromList/EditCategorizedEntityFromList_player.html",
      },
    ],
  },
};
