import { keycloak } from "libs/keycloak";
import log from "loglevel";
import * as Brm from "services/brm";

let idleTimer: ReturnType<typeof setTimeout>;

export const onKeycloakEvent = async (event: any, error: any) => {
  function resetTimer() {
    if (keycloak?.refreshTokenParsed?.exp === undefined || keycloak?.timeSkew === undefined) {
      return;
    }

    clearTimeout(idleTimer);
    const expiresIn = Math.round(
      (keycloak.refreshTokenParsed.exp - new Date().getTime() / 1000 + keycloak.timeSkew) * 1000
    );
    // console.log(`expiresIn ${expiresIn}`);
    // console.log(`Refresh Token expires in ${Math.round(expiresIn / 1000)} s`);
    idleTimer = setTimeout(keycloak.logout, expiresIn);
  }

  log.debug("[ReactKeycloakProvider]: onKeycloakEvent", event, error);
  // console.log("onKeycloakEvent", event, error);
  // console.log("keycloak.refreshTokenParsed", keycloak.refreshTokenParsed);

  if (typeof error === "undefined") {
    switch (event) {
      case "onReady":
        break;
      case "onAuthSuccess": {
        const idToken = keycloak.idTokenParsed;
        if (idToken === undefined) {
          keycloak.logout();
          return;
        }
        sessionStorage.setItem(
          "user-info",
          JSON.stringify({ name: idToken.name, email: idToken.email, id: idToken.sub })
        );
        sessionStorage.setItem("userId", JSON.stringify({ value: idToken.sub }));

        Brm.userApi.getUserRole(idToken.sub).then((data: any) => {
          sessionStorage.setItem("userRole", JSON.stringify({ value: data.result }));
        });
        resetTimer();
        break;
      }
      case "onAuthRefreshError":
        break;
      case "onAuthRefreshSuccess":
        resetTimer();
        break;
      case "onAuthLogout":
        sessionStorage.clear();
        break;
      case "onTokenExpired":
        break;
      default:
        break;
    }
  }
};
