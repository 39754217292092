import { Rect, Point } from "yfiles";

import DiagramGraphBuilderItemEventArgs from "./DiagramBuilderItemEventArgs";
import DiagramNodeCreator from "./DiagramNodeCreator";
import DiagramGroupCreator from "./DiagramGroupCreator";
import DiagramEdgeCreator from "./DiagramEdgeCreator";

/**
 * @typedef {function} CreateNodeSignature
 */

/**
 * @typedef {function} UpdateNodeSignature
 */

/**
 * @typedef {function} CreateGroupNodeSignature
 */

/**
 * @typedef {function} UpdateGroupNodeSignature
 */

/**
 * @typedef {function} CreateEdgeSignature
 */

/**
 * @typedef {function} UpdateEdgeSignature
 */

/**
 * Private interface/helper for the DiagramBuilder
 */
export default class DiagramBuilderHelper {
  static $addEventListener(listenersList, listener) {
    listenersList.push(listener);
  }

  static $removeEventListener(listenersList, listener) {
    const index = listenersList.indexOf(listener);
    if (index >= 0) {
      listenersList.splice(index, 1);
    }
  }

  static $updateLabels(graph, labelDefaults, item, labelData) {
    const { labels } = item;
    if (typeof labelData === "undefined" || labelData === null) {
      while (labels.size > 0) {
        graph.remove(labels.get(labels.size - 1));
      }
    } else if (labels.size === 0) {
      graph.addLabel(
        item,
        labelData.toString(),
        labelDefaults.getLayoutParameterInstance(item),
        labelDefaults.getStyleInstance(item),
        null,
        labelData
      );
    } else if (labels.size > 0) {
      const label = labels.get(0);
      if (label.text !== labelData.toString()) {
        graph.setLabelText(label, labelData.toString());
      }
      if (label.tag !== labelData) {
        label.tag = labelData;
      }
    }
  }

  static createIdProvider(binding) {
    if (binding === null || binding === undefined) {
      return null;
    }
    if (typeof binding === "string") {
      return (dataItem) => dataItem[binding];
    }
    return binding;
  }

  static createBinding(binding) {
    if (binding === undefined || binding === null) {
      return null;
    }
    if (typeof binding === "string") {
      return (dataItem) => dataItem[binding];
    }
    return binding;
  }

  /**
   *
   * @param {Object} builder the DiagramBuilder
   * @param {IGraph} graph the graph
   * @param {CreateNodeSignature} createNode create node function
   * @param {UpdateNodeSignature} updateNode update node function
   * @param {CreateGroupNodeSignature} createGroupNode create group node function
   * @param {UpdateGroupNodeSignature} updateGroupNode update group node function
   * @param {CreateEdgeSignature} createEdge create edge function
   * @param {UpdateEdgeSignature} updateEdge update edge function
   */
  constructor(builder, graph, createNode, updateNode, createGroupNode, updateGroupNode, createEdge, updateEdge) {
    this.builder = builder;
    this.graph = graph;

    this.$builderCreateNode = createNode;
    this.$builderUpdateNode = updateNode;
    this.$builderCreateGroupNode = createGroupNode;
    this.$builderUpdateGroupNode = updateGroupNode;
    this.$builderCreateEdge = createEdge;
    this.$builderUpdateEdge = updateEdge;

    this.$nodeCreatedListeners = [];
    this.$nodeUpdatedListeners = [];
    this.$groupNodeCreatedListeners = [];
    this.$groupNodeUpdatedListeners = [];
    this.$edgeCreatedListeners = [];
    this.$edgeUpdatedListeners = [];

    this.nodeIdBinding = null;
    this.groupIdBinding = null;
    this.nodeLabelBinding = null;
    this.groupLabelBinding = null;
    this.edgeLabelBinding = null;
    this.groupBinding = null;
    this.locationXBinding = null;
    this.locationYBinding = null;
    this.parentGroupBinding = null;
  }

  initializeProviders() {
    this.nodeLabelProvider = DiagramBuilderHelper.createBinding(this.nodeLabelBinding);
    this.groupLabelProvider = DiagramBuilderHelper.createBinding(this.groupLabelBinding);
    this.edgeLabelProvider = DiagramBuilderHelper.createBinding(this.edgeLabelBinding);

    this.locationXProvider = DiagramBuilderHelper.createBinding(this.locationXBinding);
    this.locationYProvider = DiagramBuilderHelper.createBinding(this.locationYBinding);
  }

  /**
   * Creates a node.  Informs listeners.
   *
   * @param {!IGraph} graph
   * @param {?INode} parent
   * @param {!Point} location
   * @param {*} labelData
   * @param {*} nodeObject
   * @returns {!INode}
   */
  createNode(graph, parent, location, labelData, nodeObject) {
    const { nodeDefaults } = graph;
    try {
      const node = graph.createNode(
        parent,
        new Rect(location, nodeDefaults.size),
        nodeDefaults.getStyleInstance(),
        nodeObject
      );
      if (labelData != null) {
        this.graph.addLabel(node, labelData.toString(), null, null, null, labelData);
      }
      return this.$onNodeCreated(node, nodeObject);
    } catch (err) {
      if (err instanceof Error && err.message === "No node created!") {
        // This usually only happens when the GraphBuilder is used on a foldingView
        throw new Error(
          "Could not create node. When folding is used, make sure to use the master graph in the GraphBuilder."
        );
      }
      throw err;
    }
  }

  /**
   *  Create a group node.  Informs listeners.
   *
   * @param {!IGraph} graph
   * @param {*} labelData
   * @param {*} groupObject
   * @returns {!INode}
   */
  createGroupNode(graph, labelData, groupObject) {
    const { groupNodeDefaults } = graph;
    const groupNode = graph.createGroupNode(
      null,
      new Rect(Point.ORIGIN, groupNodeDefaults.size),
      groupNodeDefaults.getStyleInstance(),
      groupObject
    );
    if (labelData != null) {
      this.graph.addLabel(groupNode, labelData.toString(), null, null, null, labelData);
    }
    return this.$onGroupCreated(groupNode, groupObject);
  }

  /**
   * Creates an edge.  Informs listeners.
   *
   * @param {!IGraph} graph
   * @param {!INode} source
   * @param {!INode} target
   * @param {*} labelData
   * @param {*} edgeObject
   * @returns {!IEdge}
   */
  createEdge(graph, source, target, labelData, edgeObject) {
    if (target === null || source === null) {
      // we need a source and target to create edge
      return null;
    }
    const edge = graph.createEdge(source, target, graph.edgeDefaults.getStyleInstance(), edgeObject);
    if (labelData != null) {
      graph.addLabel(edge, labelData.toString(), null, null, null, labelData);
    }
    return this.$onEdgeCreated(edge, edgeObject);
  }

  /**
   * Updates an existing node informs listeners.
   *
   * @param {!IGraph} graph
   * @param {!INode} node
   * @param {?INode} parent
   * @param {!Point} location
   * @param {*} labelData
   * @param {*} nodeObject
   */
  updateNode(graph, node, parent, location, labelData, nodeObject) {
    if (node.tag !== nodeObject) {
      // eslint-disable-next-line no-param-reassign
      node.tag = nodeObject;
    }
    DiagramBuilderHelper.$updateLabels(graph, graph.nodeDefaults.labels, node, labelData);
    if (graph.getParent(node) !== parent) {
      graph.setParent(node, parent);
    }
    if (!node.layout.topLeft.equals(location)) {
      graph.setNodeLayout(node, new Rect(location, node.layout.toSize()));
    }
    this.$onNodeUpdated(node, nodeObject);
  }

  /**
   * Updates a group node and informs listeners.
   *
   * @param {!IGraph} graph
   * @param {!INode} groupNode
   * @param {*} labelData
   * @param {*} groupObject
   */
  updateGroupNode(graph, groupNode, labelData, groupObject) {
    if (groupNode.tag !== groupObject) {
      // eslint-disable-next-line no-param-reassign
      groupNode.tag = groupObject;
    }
    DiagramBuilderHelper.$updateLabels(graph, graph.nodeDefaults.labels, groupNode, labelData);
    this.$onGroupUpdated(groupNode, groupObject);
  }

  /**
   * Updates an existing edge and informs listeners.
   *
   * @param {!IGraph} graph
   * @param {!IEdge} edge
   * @param {*} labelData
   * @param {*} edgeObject
   */
  updateEdge(graph, edge, labelData, edgeObject) {
    if (edge.tag !== edgeObject) {
      // eslint-disable-next-line no-param-reassign
      edge.tag = edgeObject;
    }
    DiagramBuilderHelper.$updateLabels(graph, graph.edgeDefaults.labels, edge, labelData);
    this.$onEdgeUpdated(edge, edgeObject);
  }

  /**
   * Creates an instance of a NodeCreator.
   * @returns {!DiagramNodeCreator}
   */
  createNodeCreator() {
    return new DiagramNodeCreator(this);
  }

  /**
   * Creates an instance of a NodeCreator.
   * @returns {!DiagramNodeCreator}
   */
  createGroupCreator() {
    return new DiagramGroupCreator(this);
  }

  /**
   * Creates an instance of an EdgeCreator.
   * @param {boolean} [labelDataFromSourceAndTarget=false]
   * @returns {!IDiagramEdgeCreator}
   */
  createEdgeCreator(labelDataFromSourceAndTarget = false) {
    return new DiagramEdgeCreator(this, labelDataFromSourceAndTarget);
  }

  /**
   * @param {!IModelItem} item
   * @returns {*}
   */
  // eslint-disable-next-line class-methods-use-this
  getBusinessObject(item) {
    return item.tag;
  }

  /**
   * @param {*} businessObject
   * @returns {?IEdge}
   */
  getEdge(businessObject) {
    return this.graph.edges.find((e) => e.tag === businessObject);
  }

  /**
   * @param {*} groupObject
   * @returns {?INode}
   */
  getGroup(groupObject) {
    return this.graph.nodes.find((n) => n.tag === groupObject);
  }

  /**
   * @param {*} nodeObject
   * @returns {?INode}
   */
  getNode(nodeObject) {
    return this.graph.nodes.find((n) => n.tag === nodeObject);
  }

  /**
   * @param {!DiagramNodeListener} listener
   */
  addNodeCreatedListener(listener) {
    DiagramBuilderHelper.$addEventListener(this.$nodeCreatedListeners, listener);
  }

  /**
   * @param {!DiagramNodeListener} listener
   */
  removeNodeCreatedListener(listener) {
    DiagramBuilderHelper.$removeEventListener(this.$nodeCreatedListeners, listener);
  }

  /**
   * @param {!DiagramNodeListener} listener
   */
  addGroupNodeCreatedListener(listener) {
    DiagramBuilderHelper.$addEventListener(this.$groupNodeCreatedListeners, listener);
  }

  /**
   * @param {!DiagramNodeListener} listener
   */
  removeGroupNodeCreatedListener(listener) {
    DiagramBuilderHelper.$removeEventListener(this.$groupNodeCreatedListeners, listener);
  }

  /**
   * @param {!DiagramEdgeListener} listener
   */
  addEdgeCreatedListener(listener) {
    DiagramBuilderHelper.$addEventListener(this.$edgeCreatedListeners, listener);
  }

  /**
   * @param {!DiagramEdgeListener} listener
   */
  removeEdgeCreatedListener(listener) {
    DiagramBuilderHelper.$removeEventListener(this.$edgeCreatedListeners, listener);
  }

  /**
   * @param {!DiagramNodeListener} listener
   */
  addNodeUpdatedListener(listener) {
    DiagramBuilderHelper.$addEventListener(this.$nodeUpdatedListeners, listener);
  }

  /**
   * @param {!DiagramNodeListener} listener
   */
  removeNodeUpdatedListener(listener) {
    DiagramBuilderHelper.$removeEventListener(this.$nodeUpdatedListeners, listener);
  }

  /**
   * @param {!DiagramNodeListener} listener
   */
  addGroupNodeUpdatedListener(listener) {
    DiagramBuilderHelper.$addEventListener(this.$groupNodeUpdatedListeners, listener);
  }

  /**
   * @param {!DiagramNodeListener} listener
   */
  removeGroupNodeUpdatedListener(listener) {
    DiagramBuilderHelper.$removeEventListener(this.$groupNodeUpdatedListeners, listener);
  }

  /**
   * @param {!DiagramEdgeListener} listener
   */
  addEdgeUpdatedListener(listener) {
    DiagramBuilderHelper.$addEventListener(this.$edgeUpdatedListeners, listener);
  }

  /**
   * @param {!DiagramEdgeListener} listener
   */
  removeEdgeUpdatedListener(listener) {
    DiagramBuilderHelper.$removeEventListener(this.$edgeUpdatedListeners, listener);
  }

  $fireEvent(listeners, event) {
    listeners.forEach((l) => l(this.builder, event));
  }

  /**
   *
   * @param {!INode} node
   * @param {*} dataItem
   * @returns {!INode}
   */
  $onNodeCreated(node, dataItem) {
    if (this.$nodeCreatedListeners.length > 0) {
      const evt = new DiagramGraphBuilderItemEventArgs(this.graph, node, dataItem);
      this.$fireEvent(this.$nodeCreatedListeners, evt);
      return evt.item;
    }
    return node;
  }

  /**
   *
   * @param {!INode} node
   * @param {*} dataItem
   * @returns {!INode}
   */
  $onNodeUpdated(node, dataItem) {
    if (this.$nodeUpdatedListeners.length > 0) {
      const evt = new DiagramGraphBuilderItemEventArgs(this.graph, node, dataItem);
      this.$fireEvent(this.$nodeUpdatedListeners, evt);
      return evt.item;
    }
    return node;
  }

  /**
   *
   * @param {!INode} node
   * @param {*} dataItem
   * @returns {!INode}
   */
  $onGroupCreated(group, dataItem) {
    if (this.$groupNodeCreatedListeners.length > 0) {
      const evt = new DiagramGraphBuilderItemEventArgs(this.graph, group, dataItem);
      this.$fireEvent(this.$groupNodeCreatedListeners, evt);
      return evt.item;
    }
    return group;
  }

  /**
   *
   * @param {!INode} node
   * @param {*} dataItem
   * @returns {!INode}
   */
  $onGroupUpdated(group, dataItem) {
    if (this.$groupNodeUpdatedListeners.length > 0) {
      const evt = new DiagramGraphBuilderItemEventArgs(this.graph, group, dataItem);
      this.$fireEvent(this.$groupNodeUpdatedListeners, evt);
      return evt.item;
    }
    return group;
  }

  /**
   *
   * @param {!IEdge} node
   * @param {*} dataItem
   * @returns {!IEdge}
   */
  $onEdgeCreated(edge, dataItem) {
    if (this.$edgeCreatedListeners.length > 0) {
      const evt = new DiagramGraphBuilderItemEventArgs(this.graph, edge, dataItem);
      this.$fireEvent(this.$edgeCreatedListeners, evt);
      return evt.item;
    }
    return edge;
  }

  /**
   *
   * @param {!IEdge} node
   * @param {*} dataItem
   * @returns {!IEdge}
   */
  $onEdgeUpdated(edge, dataItem) {
    if (this.$edgeUpdatedListeners.length > 0) {
      const evt = new DiagramGraphBuilderItemEventArgs(this.graph, edge, dataItem);
      this.$fireEvent(this.$edgeUpdatedListeners, evt);
      return evt.item;
    }
    return edge;
  }
}
