import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

/**
 * Gets a projects workers
 * @param {string} projectId - uuid of project
 */
export const getProjectWorkers = ({ projectId }: { projectId: string | null }) => {
  return !projectId ? Promise.reject(new Error("Invalid id")) : Brm.projectApi.getProjectWorker(projectId);
};

interface IProjectStatusProps {
  projectId: string | null;
  options?: QueryOption<typeof getProjectWorkers>;
}

/**
 * Custom hook for getting workers from a project
 *
 * @param {string} projectId - uuid of user
 * @param {object} options - react-query options
 * @returns getprojectCatalog
 */
export const useProjectWorkers = ({ projectId, options = {} }: IProjectStatusProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.workers(projectId),
    queryFn: () => getProjectWorkers({ projectId }),
  });
};
