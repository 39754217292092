import ReactModal from "react-modal";
import Cancel from "assets/icons/cancelModal.svg";
import * as S from "./ExportAarModal.styles";
import { ExportAarForm } from "../../forms/ExportAarForm";

interface IExportAarModalProps {
  isVisible: boolean;
  onClosing: () => void;
}

export const ExportAarModal = ({ isVisible, onClosing }: IExportAarModalProps) => {
  const handleClose = () => {
    onClosing();
  };

  return (
    <ReactModal
      isOpen={isVisible}
      onAfterClose={handleClose}
      ariaHideApp={false}
      onRequestClose={handleClose}
      style={S.rootModalStyle}
    >
      <S.Header>
        <S.ModalTitle>Export AAR Document</S.ModalTitle>
        <S.HeaderButtonsDiv>
          <S.Close onClick={handleClose}>
            <S.CloseIcon src={Cancel} />
          </S.Close>
        </S.HeaderButtonsDiv>
      </S.Header>
      <S.Body>
        <ExportAarForm onClose={handleClose} />
      </S.Body>
    </ReactModal>
  );
};
