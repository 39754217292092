import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * sets likelihood of the selected attacker
 * @param {string} id - uuid of the attacker
 * @param {string} likelihood - string value of available means
 *
 */
export const setAttackerLikelihood = ({ id, likelihood }) => {
  return Brm.attackerApi.setAttackerLikelihood(id, { body: likelihood });
};

/**
 * Custom hook to change the likelihood of a existing Attacker
 * @param {object} config hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerLikelihood = ({ config = {} }) => {
  return ReactQuery.useMutation({
    ...config,
    mutationFn: setAttackerLikelihood,
  });
};
