import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./person-query-key-factory";
import * as NodeKeyFactory from "../node/node-query-key-factory";

interface IRemovePersonNodeProps {
  personId: string | null;
  nodeId: string | null;
}

/**
 * Remove a person from a node
 *
 * @returns
 */
export const removePersonNode = ({ personId, nodeId }: IRemovePersonNodeProps) => {
  return personId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.personApi.removePersonNode(personId, nodeId);
};

interface IRemovePersonNodeQueryProps {
  options?: MutationOption<typeof removePersonNode>;
}

/**
 * Custom hook to remove a person from a node
 *
 * @returns react-query Mutation.
 */
export const useRemovePersonNode = ({ options }: IRemovePersonNodeQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.personKeys.all);
      queryClient.invalidateQueries(NodeKeyFactory.nodeKeys.persons(variables.nodeId));
    },
    ...options,
    mutationFn: removePersonNode,
  });
};
