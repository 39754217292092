import PropTypes from "prop-types";
import { useState } from "react";

// Constants
import { IMPORT_AS } from "constants/brm";

// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyledError from "components/forms/FormStyledError";
import { selectedExchangesState, selectedNodesState, selectedSystemState } from "atoms/atoms-sctm-wizard";
import { useRecoilState, useSetRecoilState } from "recoil";
import { CheckBox } from "components/elements";
import { isSubmissionPendingState } from "atoms/atoms-importer";
import * as S from "./SCTM.styles";

const Step2 = ({
  scope,
  options,
  handleBack,
  handleSubmission,
  setUploadModalIsOpen,
  isAutoAllocate,
  currentStep,
  postError,
  onCancel,
}) => {
  const setIsSubmissionPending = useSetRecoilState(isSubmissionPendingState);

  const [systems, setSytems] = useRecoilState(selectedSystemState);
  const selectedNodeOptions = useSetRecoilState(selectedNodesState);
  const selectedExchangeOptions = useSetRecoilState(selectedExchangesState);
  const NODE_OPTIONS = options?.nodes?.values || [];
  const EXCHANGE_OPTIONS = options?.exchanges?.values || [];
  const [exchangesCheckboxes, setExchangesCheckboxes] = useState(
    EXCHANGE_OPTIONS.reduce((values, option) => ({ ...values, [option]: false }), {})
  );
  const [checkboxes, setCheckboxes] = useState(
    NODE_OPTIONS.reduce((values, option) => ({ ...values, [option]: false }), {})
  );

  const selectAllNodes = (isSelected) => {
    const values = {};
    Object.keys(checkboxes).forEach((checkbox) => {
      values[checkbox] = isSelected;
    });
    setCheckboxes(values);
    selectedNodeOptions(Object.keys(values).filter((checkbox) => values[checkbox]));
  };

  const selectAllExchanges = (isSelected) => {
    const values = {};
    Object.keys(exchangesCheckboxes).forEach((each) => {
      values[each] = isSelected;
    });
    setExchangesCheckboxes(values);
    selectedExchangeOptions(Object.keys(values).filter((each) => values[each]));
  };

  const selectAll = () => {
    selectAllNodes(true);
    if (!isAutoAllocate) {
      selectAllExchanges(true);
    }
  };

  const deSelectAll = () => {
    selectAllNodes(false);
    if (!isAutoAllocate) {
      selectAllExchanges(false);
    }
  };

  const handleCheckBoxChange = (changeEvent) => {
    const values = checkboxes;
    const { name } = changeEvent.target;
    values[name] = !checkboxes[name];
    setCheckboxes(values);
    selectedNodeOptions(Object.keys(values).filter((checkbox) => values[checkbox]));
  };

  const handleExchangeCheckBoxChange = (changeEvent) => {
    const values = exchangesCheckboxes;
    const { name } = changeEvent.target;
    values[name] = !exchangesCheckboxes[name];
    setExchangesCheckboxes(values);
    selectedExchangeOptions(Object.keys(values).filter((checkbox) => values[checkbox]));
  };

  const createCheckBoxes = () => {
    return NODE_OPTIONS.map((each) => (
      <>
        <CheckBox label={each} isSelected={checkboxes[each]} onCheckboxChange={handleCheckBoxChange} key={each} />
        <br />
      </>
    ));
  };

  const createExchangeCheckBoxes = () => {
    return EXCHANGE_OPTIONS.map((each) => (
      <>
        <CheckBox
          label={each}
          isSelected={exchangesCheckboxes[each]}
          onCheckboxChange={handleExchangeCheckBoxChange}
          key={each}
        />
        <br />
      </>
    ));
  };

  if (currentStep !== 2) {
    return null;
  }
  return (
    <div id="SCTM_step2">
      {scope &&
        scope.map((val) => {
          if (val === IMPORT_AS.system) {
            return (
              <div key={val}>
                <label>Select the name of the Column representing System:</label>
                <S.SCTMNodeOptions>
                  {options?.systems?.values &&
                    options.systems.values.map((val1) => (
                      <div key={val1} className="options">
                        <input
                          type="radio"
                          name={val1}
                          id={val1}
                          checked={val1 === systems[0]}
                          value={val1}
                          onChange={(e) => {
                            setSytems([e.target.value]);
                          }}
                        />
                        <label style={{ paddingLeft: "5px" }}>{val1}</label>
                      </div>
                    ))}
                </S.SCTMNodeOptions>
              </div>
            );
          }
          return (
            <div key={val}>
              <label>Select the name of the column(s) representing Sub-System:</label>
              <br />
              <div style={{ justifyContent: "center" }}>
                <S.SelectionButton onClick={selectAll}>Select All</S.SelectionButton>

                <S.SelectionButton onClick={deSelectAll}>Deselect All</S.SelectionButton>
                {!isAutoAllocate && EXCHANGE_OPTIONS.length > 0 && (
                  <>
                    <S.SelectionButton onClick={() => selectAllNodes(true)}>Select All Nodes</S.SelectionButton>

                    <S.SelectionButton onClick={() => selectAllNodes(false)}>Deselect All Nodes</S.SelectionButton>

                    <S.SelectionButton onClick={() => selectAllExchanges(true)}>Select All Exchanges</S.SelectionButton>

                    <S.SelectionButton onClick={() => selectAllExchanges(false)}>
                      Deselect All Exchanges
                    </S.SelectionButton>
                  </>
                )}
              </div>

              <br />
              <S.SCTMNodeOptions>
                <div className="row">
                  <div className="column">
                    <p>Nodes: </p>
                    {createCheckBoxes()}
                  </div>
                  {!isAutoAllocate && EXCHANGE_OPTIONS.length > 0 && (
                    <div className="column">
                      <p>Exchanges: </p>
                      {createExchangeCheckBoxes()}
                    </div>
                  )}
                </div>
              </S.SCTMNodeOptions>
            </div>
          );
        })}

      <FormStyledError id="SCTM_Error">{postError}</FormStyledError>
      <DialogButtonDivStyled id="SCTM_buttons">
        <DialogButtonStyled
          onClick={() => {
            setIsSubmissionPending(false);
            setUploadModalIsOpen(false);
            onCancel();
          }}
          id="SCTM_cancelButton"
        >
          Cancel
        </DialogButtonStyled>
        <DialogButtonStyled onClick={() => handleBack()} id="SCTM_backButton">
          Back
        </DialogButtonStyled>
        <DialogButtonStyled onClick={() => handleSubmission()} id="SCTM_submitButton">
          Submit
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </div>
  );
};

Step2.propTypes = {
  scope: PropTypes.shape({
    map: PropTypes.func,
  }),
  postError: PropTypes.string,
  currentStep: PropTypes.number,
  isAutoAllocate: PropTypes.bool,
  handleBack: PropTypes.func,
  handleSubmission: PropTypes.func,
  options: PropTypes.shape({
    systems: PropTypes.shape({
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    nodes: PropTypes.shape({
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    exchanges: PropTypes.shape({
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    currentStep: PropTypes.any,
  }),
  setUploadModalIsOpen: PropTypes.func,
  onCancel: PropTypes.func,
};

export default Step2;
