import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./mitigation-query-key-factory";
/**
 * Returns  name of standard baseline
 *
 * @param {string} baselineId - uuid of the baseline
 */
export const getStandardBaselineName = ({ baselineId }: { baselineId: string }) => {
  return Brm.baselineApi.getStandardBaselineName(baselineId);
};

interface IUseStandardBaselineName {
  baselineId: string;
  options?: QueryOption<typeof getStandardBaselineName>;
}

/**
 * React-query hook for getting name of standard baseline
 *
 * @param {string} baselineId - uuid of baseline
 * @param {object} config - react-query configuration object
 * @returns react-query for getStandardBaselineName
 */
export const useStandardBaselineName = ({ baselineId, options }: IUseStandardBaselineName) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.baselineKeys.name(baselineId),
    queryFn: () => getStandardBaselineName({ baselineId }),
  });
};
