import { useState, useEffect } from "react";
// Utils
import errorUtils from "utils/error-utils";
// Components
import { DeleteButton } from "features/brm";
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
// Styles
import Title from "components/elements/StyledTitle";
import styled from "styled-components";
import FormStyledError from "components/forms/FormStyledError";
import Buttons from "components/elements/TableButtonsDivStyled";
// State
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
// Services
import { metadataApi } from "services/brm/component-service";
import { MetadataCreateDto } from "@kdmanalytics/brm-component";
// Constants
import { TYPE } from "constants/brm";

export function Metadata() {
  const [refresh, setRefresh] = useState(true);
  const projectId = useRecoilValue(projectIdState);
  const [metadata, setMetadata] = useState({});
  const [postError, setPostError] = useState("");
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  useEffect(() => {
    async function multiDeleteApiCalls() {
      Promise.all(selectedRows.map((each) => metadataApi.deleteMetadataWithHttpInfo(each.id)))
        .then(() => {
          setConfirmDelete(false);
          setDeleteModalIsOpen(false);
          setMetadata({});
          setRefresh(true);

          // Reset all values
          document.getElementById("identification").value = "";
          document.getElementById("organization").value = "";
          document.getElementById("address").value = "";
          document.getElementById("email").value = "";
          document.getElementById("version").value = "";
          document.getElementById("description").value = "";
          document.getElementById("note").value = "";
          document.getElementById("author").value = "";
          document.getElementById("owner").value = "";
          document.getElementById("contact").value = "";
          document.getElementById("phone").value = "";
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, selectedRows]);

  useEffect(() => {
    async function getData() {
      setRefresh(false);
      let metadataId = "";

      try {
        const res = await metadataApi.findMetadataWithHttpInfo(projectId);
        if (res?.data) {
          if (res.data.length === 1) {
            metadataId = res.data[0].id;
          } else {
            setMetadata({});
          }
        }
      } catch (err) {
        console.error("Error while loading metadata id: ", err);
      }

      if (metadataId) {
        try {
          const res = await metadataApi.getMetadataByIDWithHttpInfo(metadataId);
          if (res?.data) {
            errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
            setMetadata(res.data);
            setSelectedRows([res.data]);
            setSelectedInstance({
              id: res.data.id,
              name: res.data.identification,
              type: TYPE.metadata,
            });
          }
        } catch (err) {
          console.error("err in get metadata object: ", err);
        }
      } else {
        setMetadata({});
      }
    }
    if (refresh || projectId) {
      getData();
    }
  }, [refresh, projectId, setSelectedInstance]);

  function validate(formData) {
    let error = true;
    if (
      formData.identification.value === "" ||
      formData.note.value === "" ||
      formData.description.value === "" ||
      formData.organization.value === "" ||
      formData.address.value === "" ||
      formData.email.value === "" ||
      formData.version.value === "" ||
      formData.author.value === "" ||
      formData.owner.value === "" ||
      formData.contact.value === "" ||
      formData.phone.value === ""
    ) {
      error = false;
    }
    return error;
  }

  async function updateMetadata(item) {
    try {
      await metadataApi.setMetadataAuthorWithHttpInfo(metadata.id, {
        body: item.author.value,
      });
      await metadataApi.setMetadataContactWithHttpInfo(metadata.id, {
        body: item.contact.value,
      });
      await metadataApi.setMetadataDescriptionWithHttpInfo(metadata.id, {
        body: item.description.value,
      });
      await metadataApi.setMetadataEmailWithHttpInfo(metadata.id, {
        body: item.email.value,
      });
      await metadataApi.setMetadataIdentificationWithHttpInfo(metadata.id, {
        body: item.identification.value,
      });
      await metadataApi.setMetadataNoteWithHttpInfo(metadata.id, {
        body: item.note.value,
      });
      await metadataApi.setMetadataOrganizationWithHttpInfo(metadata.id, {
        body: item.organization.value,
      });
      await metadataApi.setMetadataOwnerWithHttpInfo(metadata.id, {
        body: item.owner.value,
      });
      await metadataApi.setMetadataPhoneWithHttpInfo(metadata.id, {
        body: item.phone.value,
      });
      await metadataApi.setMetadataVersionWithHttpInfo(metadata.id, {
        body: item.version.value,
      });
      await metadataApi.setMetadataAddressWithHttpInfo(metadata.id, {
        body: item.address.value,
      });
    } catch (err) {
      console.error("Error updating metadata: ", err);
    }
    setRefresh(true);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (metadata.id) {
      const isValid = validate(e.target.elements);

      if (isValid) {
        setPostError("");
        updateMetadata(e.target.elements);
      } else {
        setPostError("*Please ensure all fields are complete prior to updating metadata.");
      }
    } else {
      const isValid = validate(e.target.elements);

      if (isValid) {
        setPostError("");

        const params = {
          identification: e.target.elements.identification.value,
          note: e.target.elements.note.value,
          description: e.target.elements.description.value,
          organization: e.target.elements.organization.value,
          address: e.target.elements.address.value,
          email: e.target.elements.email.value,
          version: e.target.elements.version.value,
          author: e.target.elements.author.value,
          owner: e.target.elements.owner.value,
          contact: e.target.elements.contact.value,
          phone: e.target.elements.phone.value,
        };

        const dto = MetadataCreateDto.constructFromObject(params);
        const opts = { metadataCreateDto: dto };

        metadataApi.createMetadataWithHttpInfo(projectId, opts);

        setRefresh(true);
      } else {
        setPostError("*Please fill out all fields in the form prior to submitting.");
      }
    }
  }

  const textFieldChange = () => {
    setPostError("");
  };

  return (
    <div id="MetadataForm">
      <Title id="MetadataForm_title">
        Metadata
        {metadata.id && (
          <Buttons id="MetadataForm_buttons">
            <DeleteButton id="MetadataForm_DeleteButton" onClick={() => setDeleteModalIsOpen(true)} />
          </Buttons>
        )}
      </Title>

      {/* modal for delete confirmation */}
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "MetadataForm",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName="Metadata"
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          "Metadata"
        )}

      <FormDiv>
        <form id="MetadataForm_form" onSubmit={handleSubmit} action="">
          <div className="row" id="MetadataForm_formContent">
            <div className="column" id="MetadataForm_formInputFields">
              <div className="input_group">
                <InputLabel id="MetadataForm_identificationLabel" htmlFor="identification">
                  Identification:
                </InputLabel>
                <TextInput
                  type="text"
                  id="identification"
                  name="identification"
                  placeholder="Enter identification..."
                  defaultValue={metadata.identification}
                  onChange={textFieldChange}
                />
              </div>

              <div className="input_group">
                <InputLabel id="MetadataForm_organizationLabel" htmlFor="organization">
                  Organization:
                </InputLabel>
                <TextInput
                  type="text"
                  id="organization"
                  name="organization"
                  placeholder="Enter organization..."
                  defaultValue={metadata.organization}
                  onChange={textFieldChange}
                />
              </div>
              <div className="input_group">
                <InputLabel id="MetadataForm_addressLabel" htmlFor="address">
                  Address:
                </InputLabel>
                <TextInput
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Enter address..."
                  defaultValue={metadata.address}
                  onChange={textFieldChange}
                />
              </div>

              <div className="input_group">
                <InputLabel id="MetadataForm_emailLabel" htmlFor="email">
                  Email:
                </InputLabel>
                <TextInput
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter email..."
                  defaultValue={metadata.email}
                  onChange={textFieldChange}
                />
              </div>
              <div className="input_group">
                <InputLabel id="MetadataForm_versionLabel" htmlFor="version">
                  Version:
                </InputLabel>
                <TextInput
                  type="text"
                  id="version"
                  name="version"
                  placeholder="Enter version..."
                  defaultValue={metadata.version}
                  onChange={textFieldChange}
                />
              </div>

              <div className="input_group">
                <InputLabel id="MetadataForm_descriptionLabel" htmlFor="description">
                  Description:
                </InputLabel>
                <TextInput
                  type="text"
                  id="description"
                  name="description"
                  placeholder="Enter description..."
                  defaultValue={metadata.description}
                  onChange={textFieldChange}
                />

                {/* For some reason, textarea defaultValue is not working so leaving as text box for now... */}
                {/* <TextAreaInput
                  type="textarea"
                  name="description"
                  placeholder="Enter description..."
                  rows="3"
                  cols="50"
                  id="description"
                  defaultValue={metadata.description}
                  onChange={textFieldChange}
                /> */}
              </div>
            </div>

            <div className="column">
              <div className="input_group">
                <InputLabel id="MetadataForm_authorLabel" htmlFor="author">
                  Author:
                </InputLabel>
                <TextInput
                  type="text"
                  id="author"
                  name="author"
                  placeholder="Enter author..."
                  defaultValue={metadata.author}
                  onChange={textFieldChange}
                />
              </div>
              <div className="input_group">
                <InputLabel id="MetadataForm_ownerLabel" htmlFor="owner">
                  Owner:
                </InputLabel>
                <TextInput
                  type="text"
                  id="owner"
                  name="owner"
                  placeholder="Enter owner..."
                  defaultValue={metadata.owner}
                  onChange={textFieldChange}
                />
              </div>

              <div className="input_group">
                <InputLabel id="MetadataForm_contactLabel" htmlFor="contact">
                  Contact:
                </InputLabel>
                <TextInput
                  type="text"
                  id="contact"
                  name="contact"
                  placeholder="Enter contact..."
                  defaultValue={metadata.contact}
                  onChange={textFieldChange}
                />
              </div>

              <div className="input_group">
                <InputLabel id="MetadataForm_phoneLabel" htmlFor="phone">
                  Phone:
                </InputLabel>
                <TextInput
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Enter phone..."
                  defaultValue={metadata.phone}
                  onChange={textFieldChange}
                />
              </div>

              <div className="input_group">
                <InputLabel id="MetadataForm_noteLabel" htmlFor="note">
                  Note:
                </InputLabel>
                {/* <TextAreaInput
                  type="textarea"
                  name="note"
                  placeholder="Enter note..."
                  rows="3"
                  cols="50"
                  id="note"
                  defaultValue={metadata.note}
                  onChange={textFieldChange}
                /> */}
                <TextInput
                  type="text"
                  id="note"
                  name="note"
                  placeholder="Enter note..."
                  defaultValue={metadata.note}
                  onChange={textFieldChange}
                />
              </div>
            </div>
          </div>

          <FormStyledError>{postError}</FormStyledError>

          <ButtonDiv>
            {metadata.id ? (
              <UpdateButton id="MetadataForm_updateButton">Update</UpdateButton>
            ) : (
              <Submit id="MetadataForm_submitButton" type="submit" value="Submit" />
            )}
          </ButtonDiv>
        </form>
      </FormDiv>
    </div>
  );
}

const TextInput = styled.input`
  width: 100%;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

// const TextAreaInput = styled.textarea`
//   width: 100%;
//   display: inline-block;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   box-sizing: border-box;
// `;

const InputLabel = styled.label`
  margin: 5px 8px;
  display: inline-block;
`;

const Submit = styled.input`
  width: 20%;
  background-color: #005ca6;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    background-color: #45a049;
  }
`;

const UpdateButton = styled.button`
  width: 20%;
  background-color: #005ca6;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    background-color: #45a049;
  }
`;

const ButtonDiv = styled.div`
  margin: 10px;
  text-align: center;
`;

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  padding: 1em;
  justify-content: center;

  .row {
    display: flex;
    justify-content: center;
  }

  .column {
    display: flex;
    flex-direction: column;
    padding: 1em;
    justify-content: flex-start;
    width: 45%;
  }
  .input_group {
    display: flex;
    padding: 1em;
    justify-content: flex-end;
  }
`;
