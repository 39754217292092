import * as Recoil from "recoil";
import * as React from "react";
import { LoadingSpinner } from "components/elements";
import { CONTEXT_SIDEBAR_CONTENT, contextSidebarContentState } from "features/brm";
import { lazyImport } from "utils/lazy-import";
import { SelectedElementNotesPane } from "./SelectedElementNotesPane";
import { HelpPane } from "./HelpPane";

const { SystemDiagramContextPanelContainer } = lazyImport(
  () => import("features/diagram-system"),
  "SystemDiagramContextPanelContainer"
);
const { ContextDiagramContextPanelContainer } = lazyImport(
  () => import("features/diagram-context"),
  "ContextDiagramContextPanelContainer"
);
const { AttackDiagramContextPanelContainer } = lazyImport(
  () => import("features/diagram-attack"),
  "AttackDiagramContextPanelContainer"
);

export const RightSidebarDrawer = () => {
  const contentType = Recoil.useRecoilValue(contextSidebarContentState);

  let content = null;

  if (contentType === CONTEXT_SIDEBAR_CONTENT.help) {
    content = <HelpPane />;
  }

  if (contentType === CONTEXT_SIDEBAR_CONTENT.notes) {
    content = <SelectedElementNotesPane />;
  }
  // SystemDiagram
  if (
    contentType === CONTEXT_SIDEBAR_CONTENT.systemDiagramPalette ||
    contentType === CONTEXT_SIDEBAR_CONTENT.systemDiagramPerson ||
    contentType === CONTEXT_SIDEBAR_CONTENT.systemDiagramDatatype ||
    contentType === CONTEXT_SIDEBAR_CONTENT.systemDiagramDataflow ||
    contentType === CONTEXT_SIDEBAR_CONTENT.systemDiagramProperties ||
    contentType === CONTEXT_SIDEBAR_CONTENT.systemDiagramIconMapper ||
    contentType === CONTEXT_SIDEBAR_CONTENT.systemDiagramLegend
  ) {
    content = <SystemDiagramContextPanelContainer />;
  }

  // ContextDiagram
  if (
    contentType === CONTEXT_SIDEBAR_CONTENT.contextDiagramInfo ||
    contentType === CONTEXT_SIDEBAR_CONTENT.contextDiagramFiltering
  ) {
    content = <ContextDiagramContextPanelContainer />;
  }

  // AttackDiagram
  if (contentType === CONTEXT_SIDEBAR_CONTENT.attackGraphAddNodes) {
    content = <AttackDiagramContextPanelContainer />;
  }

  return <React.Suspense fallback={<LoadingSpinner />}>{content}</React.Suspense>;
};
