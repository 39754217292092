import * as Recoil from "recoil";
// State
import * as AdminAtom from "atoms/atoms-admin";
import * as ComponentAtom from "atoms/atoms-component";
// Services
import { RiskApi } from "features/brm";
// Constants
import { TYPE } from "constants/brm";
import { CommonPanelStyle } from "features/diagram-common";
import { NODE_TYPE_STYLE_MAP } from "../../constants";
// Styles
// Local
import AddNodeSection from "./AddNodeSection";

const AddNodes = () => {
  const projectId = Recoil.useRecoilValue(AdminAtom.projectIdState);
  const variantId = Recoil.useRecoilValue(ComponentAtom.variantIdState);
  const { data: riskHasBeenRun } = RiskApi.useRiskCalculated({
    projectId,
    variantId,
    options: {
      refetchOnWindowFocus: false,
    },
  });

  const content = !riskHasBeenRun ? (
    <>Risk has not been run.</>
  ) : (
    <>
      <AddNodeSection
        key={TYPE.assetCat}
        section={{ name: TYPE.assetCat, color: NODE_TYPE_STYLE_MAP[TYPE.assetCat].color }}
      />
      <AddNodeSection key={TYPE.asset} section={{ name: TYPE.asset, color: NODE_TYPE_STYLE_MAP[TYPE.asset].color }} />
      <AddNodeSection key={TYPE.risk} section={{ name: TYPE.risk, color: NODE_TYPE_STYLE_MAP[TYPE.risk].color }} />
      <AddNodeSection key={TYPE.ue} section={{ name: TYPE.ue, color: NODE_TYPE_STYLE_MAP[TYPE.ue].color }} />
      <AddNodeSection key="Targets" section={{ name: "Targets", color: NODE_TYPE_STYLE_MAP[TYPE.node].color }} />
      <AddNodeSection key={TYPE.trev} section={{ name: TYPE.trev, color: NODE_TYPE_STYLE_MAP[TYPE.trev].color }} />
      <AddNodeSection
        key={TYPE.attack}
        section={{ name: TYPE.attack, color: NODE_TYPE_STYLE_MAP[TYPE.attack].color }}
      />
    </>
  );

  return (
    <CommonPanelStyle.Container>
      <CommonPanelStyle.PanelTitle>Attack Tree Nodes</CommonPanelStyle.PanelTitle>
      {content}
    </CommonPanelStyle.Container>
  );
};

export default AddNodes;
