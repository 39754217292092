import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { KnowledgebaseOrganizationConfigurationTable } from "../../components/tables/KnowledgebaseOrganizationConfigurationTable";

export function KBOrganization() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const kbOrgHeader = <Title>Organization Knowledgebase Configuration</Title>;

  const kbOrgMain = (
    <KnowledgebaseOrganizationConfigurationTable
      setSelectedRows={setSelectedRows}
      refresh={refresh}
      setRefresh={setRefresh}
    />
  );

  return <ContentLayout pageId="kbOrganization_page" header={kbOrgHeader} main={kbOrgMain} />;
}
