import { AdminApi, KnowledgebaseApi } from "features/brm";
import * as React from "react";

export const useKnowledgebase = ({ kbId }: { kbId: string }) => {
  const { data: selfData } = AdminApi.useSelf({ options: {} });
  const { data: kb } = KnowledgebaseApi.useKb({ kbId });

  const result = React.useMemo(() => {
    if (selfData && kb) {
      const { isGlobal } = kb;

      return {
        isGlobalKb: isGlobal,
        isReadOnlyKb: selfData.organization !== kb.organization,
      };
    }

    return {
      isGlobalKb: false,
      isReadOnlyKb: false,
    };
  }, [kb, selfData]);

  return result;
};
