import { useQuery } from "@tanstack/react-query";

import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "brm/tables/details/api/element-query-key-factory";
import { DetailTableType } from "../../../types/DetailTableType";
import { responseAsArray } from "./utils/responseAsArray";

const getElementExchanges = (
  elementId: string,
  elementType: string,
  dataTypeExchangesData: BrmGql.GetDataTypeExchangesQuery | undefined,
  missionExchangesData: BrmGql.GetMissionExchangesQuery | undefined
) => {
  const exchangesMap: IExchangesMap = {
    [DetailTableType.DATATYPES.key]: () => dataTypeExchangesData?.dataType?.exchanges,
    [DetailTableType.MISSIONS.key]: () => missionExchangesData?.mission?.exchanges,
    [DetailTableType.DATAFLOWS.key]: (id) => Brm.dataflowApi.getDataFlowExchange(id),
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.getCapabilityExchange(id),
    [DetailTableType.ATTACK_VECTOR_CATEGORIES.key]: (id) =>
      Brm.attackVectorCatApi.getAttackVectorCategoryAllExchange(id),
    [DetailTableType.PERSONS.key]: (id) => Brm.personApi.getPersonExchange(id),
    [DetailTableType.ACCESS_POINTS.key]: (id) => Brm.accessPointApi.getAccessPointExchange(id),
    [DetailTableType.ATTACK_VECTORS.key]: (id) => Brm.attackVectorApi.getAttackVectorExchange(id),
  };

  return responseAsArray({ map: exchangesMap, elementId, elementType });
};

interface IElementExchangesProps {
  elementId: string;
  elementName: string;
  projectId: string;
  config: QueryOption<typeof getElementExchanges>;
}

interface IExchangesMap {
  [index: string]: (id?: string) => any;
}

/**
 * custom-hook to retreive all exchanges
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {string} variantId - uuid of the variant
 * @param {object} config - configuration for the react-query
 */
export const useElementExchanges = ({ elementId, elementName, projectId, config = {} }: IElementExchangesProps) => {
  const dataTypeExchangesQuery = BrmGql.useGetDataTypeExchangesQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.DATATYPES.key }
  );

  const missionExchangesQuery = BrmGql.useGetMissionExchangesQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.MISSIONS.key }
  );

  return useQuery({
    ...config,
    queryKey: KeyFactory.elementKeys.exchanges(
      elementId,
      elementName,
      dataTypeExchangesQuery.data,
      missionExchangesQuery.data
    ),
    queryFn: () => getElementExchanges(elementId, elementName, dataTypeExchangesQuery.data, missionExchangesQuery.data),
  });
};
