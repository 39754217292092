import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./exchange-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

interface ISetExchangeConsumer {
  exchangeId: string;
  consumerId: string;
}

/**
 * Sets the Consumer of a exchange
 *
 * @param {string} exchangeId - uuid of the exchange
 * @param {string} consumerId - uuid of the Consumer for the exchange
 */
export const setExchangeConsumer = ({ exchangeId, consumerId }: ISetExchangeConsumer) => {
  return Brm.exchangeApi.setExchangeConsumer(exchangeId, { body: consumerId });
};

interface IUseSetExchangeConsumer {
  options?: MutationOption<typeof setExchangeConsumer>;
}

/**
 * Custom hook to change the Consumer of an existing exchange
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetExchangeConsumer = ({ options }: IUseSetExchangeConsumer = {}) => {
  return ReactQuery.useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(KeyFactory.exchangeKeys.exchange(variables.exchangeId));
      queryClient.invalidateQueries(KeyFactory.exchangeKeys.all);
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: setExchangeConsumer,
  });
};
