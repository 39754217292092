import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";

interface IAddExchangeActConsumer {
  exchangeId: string;
  consumerId: string;
}

/**
 * Sets the act consumer of a exchange
 *
 * @param {string} exchangeId - uuid of the exchange
 * @param {string} consumerId - uuid of new consumer or null
 */
export const addExchangeActConsumer = ({ exchangeId, consumerId }: IAddExchangeActConsumer) => {
  return Brm.exchangeApi.addExchangeActConsumer(exchangeId, { body: consumerId });
};

interface IExchangeActConsumerProps {
  options?: MutationOption<typeof addExchangeActConsumer>;
}

/**
 * Custom hook to change the act consumer of an existing exchange
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useAddExchangeActConsumer = ({ options }: IExchangeActConsumerProps = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: addExchangeActConsumer,
  });
};
