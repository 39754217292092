/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import * as Recoil from "recoil";
import { useForm, SubmitHandler } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { userIdState, userRoleState } from "atoms/atoms-admin";
import { ActionButton } from "components/elements/ActionButton";
import { SavePointApi } from "features/brm";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import * as S from "./RestoreSavePointModal.styles";

interface IInputs {
  projectId: string;
  savePointId: string;
}

interface IRestoreSavePointModalModalProps {
  show: boolean;
  onHide: () => void;
  onRestore: (value: { projId: string; savePointId: string }) => void;
  project: {
    id: string;
    name: string;
  };
}

const DEFAULT = "default";

export const RestoreSavePointModal = ({
  show,
  onHide,
  onRestore,
  project,
  ...rest
}: IRestoreSavePointModalModalProps) => {
  const userId = Recoil.useRecoilValue(userIdState);
  const userRole = Recoil.useRecoilValue(userRoleState);
  const [projectValueOption, setProjectValueOption] = React.useState(DEFAULT);
  const [savePointValueOption, setSavePointValueOption] = React.useState(DEFAULT);
  const [disableSavePointsOptions, setDisableSavePointsOptions] = React.useState(true);
  const [disableSubmit, setDisableSubmit] = React.useState(true);
  const [savePoints, setSavePoints] = React.useState([]);

  const { data: projects, error: projectsError } = SavePointApi.useProjectsWithSavepoints({
    userId,
    userRole,
    options: {
      enabled: show,
      select: React.useCallback((data) => data?.filter((proj: any) => proj.savepoints.length > 0), []),
    },
  });

  const handleChangeProject = React.useCallback(
    (e: { target: { value: React.SetStateAction<string> } }) => {
      setProjectValueOption(e.target.value);
      setDisableSavePointsOptions(false);
    },
    [setDisableSavePointsOptions]
  );

  const handleChangeSavePoint = React.useCallback((e: { target: { value: React.SetStateAction<string> } }) => {
    setSavePointValueOption(e.target.value);
    setDisableSubmit(false);
  }, []);

  const { register, handleSubmit, reset } = useForm<IInputs>({ mode: "onChange" });

  React.useEffect(() => {
    if (projects && projectValueOption !== DEFAULT) {
      const arr: any = projects
        ?.filter((proj: any) => proj.id === projectValueOption)
        .map((proj: any) => {
          const { name } = proj;
          return proj.savepoints.map((sp: any) => {
            sp.projectName = name;
            return sp;
          });
        });
      setSavePoints(arr[0]);
    }
  }, [projectValueOption, projects]);

  const onSubmit: SubmitHandler<IInputs> = (data) => {
    onRestore({ projId: project.id, savePointId: data.savePointId });
    reset();
    setProjectValueOption(DEFAULT);
    setSavePointValueOption(DEFAULT);
    onHide();
  };

  if (projectsError) {
    return <div>Error while loading projects</div>;
  }

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered {...rest}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Restore {project.name} from save point</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <S.RestoreSavePointRoot>
          <S.RestoreSavePointContainer>
            <S.RestoreSavePointGroup>
              <S.FormRoot>
                <Form>
                  <Form.Group className="mb-3" controlId="formCurrentPassword">
                    <S.FieldLabel>Project</S.FieldLabel>
                    <Form.Select
                      {...register("projectId")}
                      defaultValue={projectValueOption}
                      onChange={handleChangeProject}
                    >
                      <option value={DEFAULT} disabled hidden>
                        Select project...
                      </option>
                      {projects?.map((p: any) => (
                        <option key={p.id} value={p.id}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formCurrentPassword">
                    <S.FieldLabel>Save point </S.FieldLabel>
                    <Form.Select
                      {...register("savePointId")}
                      defaultValue={savePointValueOption}
                      onChange={handleChangeSavePoint}
                      disabled={disableSavePointsOptions}
                    >
                      <option value={DEFAULT} disabled hidden>
                        Select save point...
                      </option>
                      {savePoints?.map((sp: any) => (
                        <option key={sp.runId} value={sp.runId}>
                          {`${sp.projectName} ${sp.timestamp}`}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <S.WarningContainer>
                    <AiOutlineExclamationCircle size="50px" fill="#6c757d" />
                    <Form.Text id="warningblock" muted>
                      Restoring a project from a savepoint will overwrite your current project. If you are not sure what
                      this means, you should switch to an empty project before restoring.
                      <br />
                      <br />
                      <strong>After restoring you need to re-apply your vulnerability findings.</strong>
                    </Form.Text>
                  </S.WarningContainer>
                </Form>
              </S.FormRoot>
            </S.RestoreSavePointGroup>
          </S.RestoreSavePointContainer>
        </S.RestoreSavePointRoot>
      </Modal.Body>
      <Modal.Footer>
        <ActionButton
          onClick={() => {
            reset();
            setProjectValueOption(DEFAULT);
            setSavePointValueOption(DEFAULT);
            onHide();
          }}
        >
          Close
        </ActionButton>
        <ActionButton onClick={handleSubmit(onSubmit)} disabled={disableSubmit}>
          Restore Save Point
        </ActionButton>
      </Modal.Footer>
    </Modal>
  );
};
