import styled from "styled-components/macro";

const footerStyles = {
  Footer: styled.div`
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.fonts.small};
    height: ${({ theme }) => theme.footer.height};
    background-color: #ffffff;
    color: ${({ theme }) => theme.colors.veryHighContrastBackground};
    padding-left: ${({ theme }) => theme.sizes.defaultPadding};
    padding-top: 4px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 4px;
      background: linear-gradient(
        180deg,
        rgba(9, 30, 66, 0.13) 0,
        rgba(9, 30, 66, 0.13) 1px,
        rgba(9, 30, 66, 0.08) 1px,
        rgba(9, 30, 66, 0) 4px
      );
    }

    .centeredText {
      text-align: center;
      position: absolute;
      right: 0;
      left: 0;
    }

    .leftText {
      float: left;
    }

    .rightText {
      float: right;
      padding-right: ${({ theme }) => theme.sizes.defaultPadding};
    }
  `,
};
export default footerStyles;
