/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { ErrorSpan } from "components/elements";
import { FileList } from "./FileList";
import * as CommonStyle from "./styles";
import { useConfigureProjectContext } from "./ConfigureProjectContext";
import { StandardStepButtons } from "./StandardStepButtons";

type ViewType = "Operational" | "System";
interface ISystemModelArchitecture {
  files?: FileList;
  isSysMl: boolean;
  viewType: ViewType;
}

// TODO these probably shouldn't be hardcoded.

const viewTypes = ["Operational", "System"] as ViewType[];
const mdxmlExts = [".mdxml", ".mdzip", ".xml"];

const isUploadingMdxml = (files: FileList | undefined) => {
  if (files === undefined) {
    return false;
  }
  const fileArray = [...files];
  if (fileArray.length === 1) {
    const file = fileArray[0];
    const fileExtMatch = file.name.match('.[^.\\/:*?"<>|\r\n]+$');
    if (fileExtMatch) {
      const fileExt = fileExtMatch[0].toLowerCase();
      if (mdxmlExts.find((ext) => ext === fileExt)) {
        return true;
      }
    }
  }
  return false;
};

export const SystemModelArchitectureStepForm = () => {
  const modelFileRef = React.useRef<HTMLInputElement>();
  const [isImportingSysMl, setIsImportingSysMl] = React.useState(false);
  const [viewType, setViewType] = React.useState<ViewType>("Operational");
  const { next, configureData, setConfigureData } = useConfigureProjectContext();

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { isDirty, errors },
  } = useForm<ISystemModelArchitecture>({
    defaultValues: {
      files: configureData.modelFiles,
    },
  });

  const files = watch("files");
  const isMdxml = isUploadingMdxml(files);
  const setConfigureDataHook = (data: ISystemModelArchitecture) => {
    if (isDirty && data.files) {
      setConfigureData({
        ...configureData,
        modelFiles: data.files,
        isSysMl: data.isSysMl,
        viewType: data.viewType,
        isMdxml,
      });
    }
  };

  const onSubmit = (data: ISystemModelArchitecture) => {
    setConfigureDataHook(data);
    next();
  };

  const removeSelectedFile = (file: File) => {
    if (!files) {
      return;
    }
    const newList = [...files].filter((f) => f.name !== file.name);
    if (newList.length) {
      const list = new DataTransfer();
      newList.forEach((newFile) => list.items.add(newFile));
      setValue("files", list.files);
    } else {
      setValue("files", undefined);
      if (modelFileRef.current) {
        modelFileRef.current.value = "";
      }
    }
  };

  const fileArray = files ? [...files] : [];

  let fileListLabel = `Currently Selected System Architecture Model Files:`;
  if (fileArray.length) {
    fileListLabel += `(${fileArray.length})`;
  }

  return (
    <CommonStyle.StepForm onSubmit={handleSubmit(onSubmit)} id="createProject">
      <CommonStyle.Col>
        <legend>System Model Architecture</legend>
        <Form.Group className="mb-3" controlId="files">
          <CommonStyle.UploadLabelContainer>
            <span>Select System Model Architecture files for upload:</span>
            <CommonStyle.UploadLabel htmlFor="files">Choose files</CommonStyle.UploadLabel>
          </CommonStyle.UploadLabelContainer>
          <Controller
            name="files"
            control={control}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ field: { onChange, value, ref, ...rest } }) => (
              <Form.Control
                style={{ display: "none" }}
                type="file"
                accept=".doc,.docx,.xml,.csv,.mdxml,.DOCX,.mdzip,.zip"
                multiple
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event?.target?.files !== null && event?.target?.files.length > 0) {
                    onChange(event.target.files);
                    handleSubmit(onSubmit);
                  }
                }}
                ref={(e: HTMLInputElement) => {
                  ref(e);
                  modelFileRef.current = e;
                }}
                {...rest}
              />
            )}
          />
          {errors.files && errors.files.type === "validate" && <ErrorSpan>Unsupported file type</ErrorSpan>}
        </Form.Group>
        {isMdxml ? (
          <>
            <Form.Check
              type="switch"
              id="sysml-switch"
              label="Importing SysML"
              {...register("isSysMl")}
              checked={isImportingSysMl}
              onChange={() => {
                setIsImportingSysMl(!isImportingSysMl);
                if (!isImportingSysMl) {
                  setViewType("System");
                } else {
                  setViewType("Operational");
                }
              }}
            />
            <CommonStyle.SysmlFieldset disabled={isImportingSysMl}>
              <CommonStyle.SysmlLegend>View Types</CommonStyle.SysmlLegend>
              {viewTypes.map((each: ViewType) => (
                <Form.Check
                  {...register("viewType")}
                  key={each}
                  type="radio"
                  label={each}
                  value={each}
                  checked={each === viewType}
                  onChange={() => setViewType(each)}
                  disabled={isImportingSysMl}
                />
              ))}
            </CommonStyle.SysmlFieldset>
          </>
        ) : null}
        <FileList fileArray={fileArray} onRemoveClicked={removeSelectedFile} label={fileListLabel} />
      </CommonStyle.Col>

      <StandardStepButtons isBackVisible onBack={handleSubmit(setConfigureDataHook)} />
    </CommonStyle.StepForm>
  );
};
