export const importKeys = {
  all: ["import"] as const,
  project: (projectId: string | null) => [...importKeys.all, projectId] as const,
  // context: (projectId: string | null, context: any) => [...importKeys.project(projectId), context] as const,
  // submissionStatus: (projectId: string, context: string, runId: string) =>
  //   [...importKeys.context(projectId, context), runId] as const,
  // savePointStatus: (projectId: string, runId: string) => [...importKeys.all, "savePoint", projectId, runId] as const,
  // sctmPointStatus: (projectId: string, runId: string) => [...importKeys.all, "sctm", projectId, runId] as const,
  // traPointStatus: (projectId: string, runId: string) => [...importKeys.all, "tra", projectId, runId] as const,
  // systemStatus: (projectId: string, runId: string) => [...importKeys.all, "system", projectId, runId] as const,
  status: (projectId: string, context: string, runId: string) =>
    [...importKeys.all, "status", context, projectId, runId] as const,
  submissionStatus: (projectId: string, context: string, runId: string) =>
    [...importKeys.all, "submissionStatus", context, projectId, runId] as const,
  history: (projectId: string, context: string, opt: unknown) =>
    [...importKeys.all, "history", context, projectId, opt] as const,
  assets: (projectId: string, context: string, runId: string) =>
    [...importKeys.all, "assets", context, projectId, runId] as const,
  downloadAsset: (projectId: string, context: string, assetId: string) =>
    [...importKeys.all, "downloadAsset", context, projectId, assetId] as const,
};
