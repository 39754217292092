export const standardBaselineKeys = {
  all: ["standardBaseline"] as const,
  baseline: (baselineId: string) => [...standardBaselineKeys.all, baselineId] as const,
  controlType: (baselineId: string) => [...standardBaselineKeys.all, baselineId, "controlType"] as const,
  controlCatalog: (controlCatalogId: string) =>
    [...standardBaselineKeys.all, "controlCatalog", controlCatalogId] as const,
};

export const catalogBaselineKeys = {
  all: ["catalogBaseline"] as const,
  catalog: (catalogId: string) => [...catalogBaselineKeys.all, catalogId] as const,
};

export const controlTypeKeys = {
  all: ["controlType"] as const,
  controlType: (controlTypeId: string) => [...controlTypeKeys.all, controlTypeId] as const,
  controlFamily: (controlFamilyId: string) => [...controlTypeKeys.all, controlFamilyId] as const,
};

export const controlFamilyKeys = {
  all: ["controlFamily"] as const,
  controlFamily: (controlFamilyId: string, catalogId: string) =>
    [...controlFamilyKeys.all, controlFamilyId, catalogId] as const,
  controls: (controlFamilyId: string) => [...controlFamilyKeys.all, controlFamilyId, "controls"] as const,
  controlCatalog: (catalogId: string) => [...controlFamilyKeys.all, catalogId] as const,
};

export const controlCatalogKeys = {
  all: ["controlCatalog"] as const,
  catalog: (catalogId: string) => [...controlCatalogKeys.all, catalogId] as const,
};
