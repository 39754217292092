import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./resource-query-key-factory";

export const getResource = ({ resourceId }: { resourceId: string | undefined }) => {
  return Brm.resourceApi.getResourceByID(resourceId);
};

interface IResourceProps {
  resourceId: string | undefined;
  options?: QueryOption<typeof getResource>;
}

/**
 * Custom-hook to retrieve resource by ID.
 *
 * @param {string} resourceId - uuid of resource
 * @param {object} options - react-query configuration object
 */

export const useResource = ({ resourceId, options = {} }: IResourceProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.resourceKeys.resource(resourceId),
    queryFn: () => getResource({ resourceId }),
  });
};
