import { useQuery } from "@tanstack/react-query";
import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";
// Constants
import { BrmError, DetailTableType } from "features/brm";
import { useRecoilValue } from "recoil";
import { riskUpdatedState } from "atoms/atoms-risk";
import { queryClient } from "libs/react-query";
import * as KeyFactory from "./element-query-key-factory";

interface IElementVulProps {
  elementId: string;
  elementName: string;
  projectId: string;
  variantId: string;
  config: {};
}

interface IVulMap {
  [index: string]: (id?: string) => any;
}

export const invalidateElementVulnerabilities = () => {
  queryClient.invalidateQueries(["getRiskVulnerabilities"]);
  queryClient.invalidateQueries(["getAssetVulnerability"]);
  queryClient.invalidateQueries(["getUndesiredEventVulnerability"]);
  queryClient.invalidateQueries(["getThreatEventVulnerability"]);
  queryClient.invalidateQueries(["getSystemAssetVulnerability"]);
  queryClient.invalidateQueries(["getSystemAssetTypeVulnerability"]);
  queryClient.invalidateQueries(["getExchangeVulnerabilities"]);
  queryClient.invalidateQueries(["getNodeVulnerabilities"]);
  queryClient.invalidateQueries(["getTargetVulnerability"]);
};

export const getElementVulnerabilities = (
  projectId: string,
  variantId: string,
  elementType: string,
  elementId: string,
  riskVulnerabilitiesQueryData: BrmGql.GetRiskVulnerabilitiesQuery | undefined,
  allocatedControlVulnerabilitiesQueryData: BrmGql.GetAllocatedControlVulnerabilitiesQuery | undefined,
  attackVulnerabilitiesQueryData: BrmGql.GetAttackVulnerabilitiesQuery | undefined,
  attackerVulnerabilitiesQueryData: BrmGql.GetAttackerVulnerabilitiesQuery | undefined,
  assetVulnerabilityData: BrmGql.GetAssetVulnerabilityQuery | undefined,
  undesiredEventVulnerabilityData: BrmGql.GetUndesiredEventVulnerabilityQuery | undefined,
  trevVulnerabilityData: BrmGql.GetThreatEventVulnerabilityQuery | undefined,
  systemAssetVulnerabilityData: BrmGql.GetSystemAssetVulnerabilityQuery | undefined,
  systemAssetTypeVulnerabilityData: BrmGql.GetSystemAssetTypeVulnerabilityQuery | undefined,
  targetVulnerabilityData: BrmGql.GetTargetVulnerabilityQuery | undefined,
  nodeVulnerabilityData: BrmGql.GetNodeVulnerabilitiesQuery | undefined,
  exchangeVulnerabilityData: BrmGql.GetExchangeVulnerabilitiesQuery | undefined
) => {
  const vulMap: IVulMap = {
    [DetailTableType.RISKS.key]: () => riskVulnerabilitiesQueryData?.risk?.vulnerabilities,
    [DetailTableType.ALLOCATED_CONTROLS.key]: () =>
      allocatedControlVulnerabilitiesQueryData?.allocatedControl?.vulnerabilities,
    [DetailTableType.ATTACKS.key]: () => attackVulnerabilitiesQueryData?.attack?.vulnerabilities,
    [DetailTableType.ATTACKERS.key]: () => attackerVulnerabilitiesQueryData?.attacker?.vulnerabilities,
    [DetailTableType.ASSETS.key]: () => assetVulnerabilityData?.asset?.vulnerabilities,
    [DetailTableType.UNDESIRED_EVENTS.key]: () => undesiredEventVulnerabilityData?.undesiredEvent?.vulnerabilities,
    [DetailTableType.THREAT_EVENTS.key]: () => trevVulnerabilityData?.trev?.vulnerabilities,
    [DetailTableType.SYSTEM_ASSETS.key]: () => systemAssetVulnerabilityData?.systemAsset?.vulnerabilities,
    [DetailTableType.SYSTEM_ASSET_TYPES.key]: () => systemAssetTypeVulnerabilityData?.systemAssetType?.vulnerabilities,
    [DetailTableType.CAPABILITIES.key]: () => targetVulnerabilityData?.target?.all_vulnerabilities,
    [DetailTableType.ACTIVITIES.key]: () => targetVulnerabilityData?.target?.all_vulnerabilities,
    [DetailTableType.DATAFLOWS.key]: () => targetVulnerabilityData?.target?.all_vulnerabilities,
    [DetailTableType.RESOURCES.key]: () => targetVulnerabilityData?.target?.all_vulnerabilities,
    [DetailTableType.PERSONS.key]: () => targetVulnerabilityData?.target?.all_vulnerabilities,
    [DetailTableType.MISSIONS.key]: () => targetVulnerabilityData?.target?.all_vulnerabilities,
    [DetailTableType.DATATYPES.key]: () => targetVulnerabilityData?.target?.all_vulnerabilities,
    [DetailTableType.NODES.key]: () => nodeVulnerabilityData?.node?.vulnerabilities,
    [DetailTableType.EXCHANGES.key]: () => exchangeVulnerabilityData?.exchange?.vulnerabilities,
    [DetailTableType.VULNERABILITY_CATEGORIES.key]: () =>
      Brm.vulnerabilityCategoryApi.findVulnerabilityCategory(projectId),
  };

  if (vulMap[elementType]) {
    const value = vulMap[elementType](elementId);
    return value !== undefined ? value : null;
  }

  throw new BrmError(`Unsupported element type: ${elementType}`);
};

export const useElementVulnerabilities = ({
  elementId,
  elementName,
  projectId,
  variantId,
  config = {},
}: IElementVulProps) => {
  const riskUpdated = useRecoilValue(riskUpdatedState);

  const riskVulnerabilitiesQuery = BrmGql.useGetRiskVulnerabilitiesQuery(
    { risk: elementId, variant: variantId },
    { enabled: !!elementId && elementName === DetailTableType.RISKS.key }
  );

  const allocatedControlVulnerabilitiesQuery = BrmGql.useGetAllocatedControlVulnerabilitiesQuery(
    { id: elementId, project: projectId, variant: variantId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.ALLOCATED_CONTROLS.key }
  );

  const attackVulnerabilitiesQuery = BrmGql.useGetAttackVulnerabilitiesQuery(
    { attack: elementId, variant: variantId },
    { enabled: !!elementId && elementName === DetailTableType.ATTACKS.key }
  );

  const attackerVulnerabilitiesQuery = BrmGql.useGetAttackerVulnerabilitiesQuery(
    { id: elementId, project: projectId, variant: variantId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.ATTACKERS.key }
  );

  const assetVulnerabilityQuery = BrmGql.useGetAssetVulnerabilityQuery(
    { asset: elementId, variant: variantId },
    { enabled: !!elementId && elementName === DetailTableType.ASSETS.key }
  );

  const undesiredEventVulnerabilityQuery = BrmGql.useGetUndesiredEventVulnerabilityQuery(
    { id: elementId, project: projectId, variant: variantId },
    { enabled: !!elementId && elementName === DetailTableType.UNDESIRED_EVENTS.key }
  );

  const trevVulnerabilityQuery = BrmGql.useGetThreatEventVulnerabilityQuery(
    { trev: elementId, variant: variantId },
    { enabled: !!elementId && elementName === DetailTableType.THREAT_EVENTS.key }
  );

  const systemAssetVulnerabilityQuery = BrmGql.useGetSystemAssetVulnerabilityQuery(
    { id: elementId, project: projectId, variant: variantId },
    { enabled: !!elementId && elementName === DetailTableType.SYSTEM_ASSETS.key }
  );
  const systemAssetTypeVulnerabilityQuery = BrmGql.useGetSystemAssetTypeVulnerabilityQuery(
    { id: elementId, project: projectId, variant: variantId },
    { enabled: !!elementId && elementName === DetailTableType.SYSTEM_ASSET_TYPES.key }
  );

  const exchangeVulnerabilitiesQuery = BrmGql.useGetExchangeVulnerabilitiesQuery(
    { id: elementId, project: projectId, variant: variantId },
    { enabled: !!elementId && elementName === DetailTableType.EXCHANGES.key }
  );

  const nodeVulnerabilitiesQuery = BrmGql.useGetNodeVulnerabilitiesQuery(
    { id: elementId, project: projectId, variant: variantId },
    { enabled: !!elementId && elementName === DetailTableType.NODES.key }
  );

  const targetVulnerabilityQuery = BrmGql.useGetTargetVulnerabilityQuery(
    { target: elementId, variant: variantId },
    {
      enabled:
        !!elementId &&
        (elementName === DetailTableType.CAPABILITIES.key ||
          elementName === DetailTableType.PERSON.key ||
          elementName === DetailTableType.MISSIONS.key ||
          elementName === DetailTableType.DATATYPES.key ||
          elementName === DetailTableType.ACTIVITIES.key ||
          elementName === DetailTableType.DATAFLOWS.key ||
          elementName === DetailTableType.RESOURCES.key),
      refetchOnWindowFocus: false,
    }
  );

  const elementVulnerabilitiesQuery = useQuery({
    ...config,
    queryKey: KeyFactory.elementKeys.vulnerabilities(
      elementId,
      elementName,
      projectId,
      variantId,
      riskUpdated,
      riskVulnerabilitiesQuery.data,
      allocatedControlVulnerabilitiesQuery.data,
      attackVulnerabilitiesQuery.data,
      attackerVulnerabilitiesQuery.data,
      assetVulnerabilityQuery.data,
      undesiredEventVulnerabilityQuery.data,
      trevVulnerabilityQuery.data,
      systemAssetTypeVulnerabilityQuery.data,
      systemAssetVulnerabilityQuery.data,
      targetVulnerabilityQuery.data,
      exchangeVulnerabilitiesQuery.data,
      nodeVulnerabilitiesQuery.data
    ),
    queryFn: () =>
      getElementVulnerabilities(
        projectId,
        variantId,
        elementName,
        elementId,
        riskVulnerabilitiesQuery.data,
        allocatedControlVulnerabilitiesQuery.data,
        attackVulnerabilitiesQuery.data,
        attackerVulnerabilitiesQuery.data,
        assetVulnerabilityQuery.data,
        undesiredEventVulnerabilityQuery.data,
        trevVulnerabilityQuery.data,
        systemAssetVulnerabilityQuery.data,
        systemAssetTypeVulnerabilityQuery.data,
        targetVulnerabilityQuery.data,
        nodeVulnerabilitiesQuery.data,
        exchangeVulnerabilitiesQuery.data
      ),
  });

  if (riskVulnerabilitiesQuery.isError) {
    return riskVulnerabilitiesQuery;
  }

  if (allocatedControlVulnerabilitiesQuery.isError) {
    return allocatedControlVulnerabilitiesQuery;
  }

  if (attackVulnerabilitiesQuery.isError) {
    return attackVulnerabilitiesQuery;
  }

  if (attackerVulnerabilitiesQuery.isError) {
    return attackerVulnerabilitiesQuery;
  }

  if (assetVulnerabilityQuery.isError) {
    return assetVulnerabilityQuery;
  }

  if (undesiredEventVulnerabilityQuery.isError) {
    return undesiredEventVulnerabilityQuery;
  }

  if (trevVulnerabilityQuery.isError) {
    return trevVulnerabilityQuery;
  }

  if (systemAssetVulnerabilityQuery.isError) {
    return systemAssetVulnerabilityQuery;
  }

  if (systemAssetTypeVulnerabilityQuery.isError) {
    return systemAssetTypeVulnerabilityQuery;
  }

  if (targetVulnerabilityQuery.isError) {
    return targetVulnerabilityQuery;
  }

  if (nodeVulnerabilitiesQuery.isError) {
    return nodeVulnerabilitiesQuery;
  }

  if (exchangeVulnerabilitiesQuery.isError) {
    return exchangeVulnerabilitiesQuery;
  }

  return elementVulnerabilitiesQuery;
};
