import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import errorUtils from "utils/error-utils";
import { threatCatalogApi, threatGroupApi } from "services/brm/global-threat-service";
import { createColumnBooleanNoEditMapped, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { TYPE } from "constants/brm";
import { BrmError, DetailTableType, useRoles } from "features/brm";
import { RoutePath } from "routes/route-paths";

const ThreatGroupTable = ({ elementNameInUrl, selectedElement }) => {
  const { isThreatAnalyst } = useRoles();

  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.ThreatGroup.replace(":id", "")),
      createColumnBooleanNoEditMapped("isNationState"),
    ],
    []
  );

  const [tGroupData, setTGroupData] = useState([]);
  const [, setSelectedThreatGroup] = useState([]);

  useEffect(() => {
    function getElementRequest() {
      switch (elementNameInUrl) {
        case DetailTableType.THREAT_CATALOGS.key: {
          return threatCatalogApi.getThreatCatalogThreatgroupWithHttpInfo(selectedElement.id);
        }
        case DetailTableType.THREAT_TIERS.key: {
          return threatGroupApi.findThreatGroupWithHttpInfo({ tier: selectedElement.id });
        }
        default: {
          break;
        }
      }

      throw new BrmError(`Unsupported element type:${elementNameInUrl}`);
    }

    async function getData() {
      try {
        const res = await getElementRequest();
        errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
        setTGroupData(res.data);
      } catch (error) {
        console.error("Details: Threat group GET: ", error);
      }
    }
    getData();
  }, [elementNameInUrl, selectedElement.id]);

  if (!isThreatAnalyst) {
    return null;
  }

  return (
    <S.DetailsContainer id="ThreatGroupTable_detailsPanel">
      <BrmDetailTable
        data={tGroupData}
        columns={columns}
        setSelectedRows={setSelectedThreatGroup}
        setSelectedElement={setSelectedThreatGroup}
        customProps={{ id: "ThreatGroupTable_detailsTable" }}
        showRowSelect={false}
        elementName={TYPE.threatGroup}
      />
    </S.DetailsContainer>
  );
};

ThreatGroupTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
};

export default ThreatGroupTable;
