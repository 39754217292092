import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import { SettingCreateDto } from "@kdmanalytics/brm-component";
import * as KeyFactory from "./project-query-key-factory";
import * as UserKeyFactory from "../user/user-query-key-factory";

export type ProjectSetting = "physicalMode" | "vulnerabilityComputationMode" | "compliantControlMode" | "baselineMode";

export interface ISetProjectSetting {
  projectId: string;
  setting: ProjectSetting;
  value: string;
}

/**
 * Sets setting for a project
 *
 * @param {string} projectId - uuid of project
 * @param {ProjectSetting} name - name of setting
 * @param {string} value - value of setting
 */
export const setProjectSetting = ({ projectId, setting, value }: ISetProjectSetting) => {
  const dto = SettingCreateDto.constructFromObject({
    name: setting,
    value,
    note: "",
  });
  return Brm.settingsApi.createSetting(projectId, { settingCreateDto: dto });
};

interface IUseSetProjectPhysicalMode {
  options?: MutationOption<typeof setProjectSetting>;
}

/**
 * Custom hook to change setting for a project
 *
 * @param {object} options - configuration of react-query
 * @returns react-query ReactQuery.useMutation
 */
export const useSetProjectSetting = ({ options }: IUseSetProjectPhysicalMode = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.projectKeys.all);
      queryClient.invalidateQueries(KeyFactory.projectKeys.projectConf);
      queryClient.invalidateQueries(UserKeyFactory.userKeys.all);
    },
    ...options,
    mutationFn: setProjectSetting,
  });
};
