import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
// Utils
import errorUtils from "utils/error-utils";
import { createColumnBooleanNoEditMapped, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
import { RoutePath } from "routes/route-paths";
// Services
import { nodeApi } from "services/brm/system-service";
// Styles
import * as S from "brm/styles/details-table.styles";
import { DetailTableType } from "features/brm";

const ChildTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Node.replace(":id", "")),
      createColumnBooleanNoEditMapped("isInternal"),
      createColumnBooleanNoEditMapped("isBoundary"),
    ],
    []
  );

  const [childData, setChildData] = useState([]);

  useEffect(() => {
    async function getData() {
      function getElementRequest() {
        switch (elementNameInUrl) {
          case DetailTableType.NODES.key: {
            return nodeApi.getNodeChildrenWithHttpInfo(selectedElement.id);
          }
          default: {
            break;
          }
        }
        return undefined;
      }

      try {
        const res = await getElementRequest();
        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setChildData(res.data);
        }
      } catch (err) {
        console.error("Error while loading child ", err);
      }
      return undefined;
    }
    getData();
  }, [elementNameInUrl, selectedElement]);

  return (
    <S.DetailsContainer id="ChildTable_detailsPanel">
      <BrmDetailTable
        data={childData}
        columns={columns}
        customProps={{ id: "ChildTable_detailsTable" }}
        showRowSelect={false}
        tableTitle={tableTitle}
        setSelectedElement={() => {}}
      />
    </S.DetailsContainer>
  );
};

ChildTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  tableTitle: PropTypes.string,
};

export default ChildTable;
