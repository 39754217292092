export class DiagramType {
  static readonly SYSTEM = new DiagramType("SYSTEM");

  static readonly CONTEXT = new DiagramType("CONTEXT");

  static readonly ATTACK = new DiagramType("ATTACK");

  static get values() {
    return [this.SYSTEM, this.CONTEXT, this.ATTACK] as const;
  }

  static fromString(string: string) {
    const value = (this as any)[string];
    if (value) {
      return value;
    }
    throw new RangeError(
      `Illegal argument passed to fromString(): ${string} does not correspond to any instance of the enum ${
        (this as any).prototype.constructor.name
      }`
    );
  }

  public readonly name: string;

  private constructor(name: string) {
    this.name = name;
  }

  public toJSON() {
    return this.name;
  }
}
