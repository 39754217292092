import { userRoleState } from "atoms/atoms-admin";
import { AdminApi, ImportApi, ImportContext } from "features/brm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { projectApi } from "services/brm";
// import { genericImportApi } from "services/brm/importer-service";
import { isRiskAnalyst } from "services/user";

const getImportHistory = async (id, userRole) => {
  let history;
  if (isRiskAnalyst(userRole)) {
    // history = await genericImportApi.importHistory(ImportContext.bor, id);
    history = await ImportApi.importHistory({ projectId: id, context: ImportContext.bor });
  } else {
    history = await ImportApi.importHistory({ projectId: id, context: ImportContext.system });
    // history = await genericImportApi.importHistory(ImportContext.system, id);
  }
  return history;
};

export const useImportHistory = (id, setUserAlertIsOpen, setUploadModalIsOpen) => {
  const userRole = useRecoilValue(userRoleState);
  return useQuery(["importHistory", id, userRole], () => getImportHistory(id, userRole), {
    enabled: false,
    onSuccess: (data) => {
      if (data.length > 0) {
        setUserAlertIsOpen(true);
      } else setUploadModalIsOpen(true);
    },
  });
};

const setProjectName = ({ id, projectName }) => {
  projectApi.setProjectNameWithHttpInfo(id, {
    body: projectName,
  });
};
export const useProjNameUpdate = (projectId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setProjectName,
    onMutate: async ({ id, projectName }) => {
      await queryClient.cancelQueries(AdminApi.projectKeys.projectData(id));
      const previousValues = queryClient.getQueryData(AdminApi.projectKeys.projectData(id));

      queryClient.setQueryData(AdminApi.projectKeys.projectData(id), (data) => {
        if (id === data.id) {
          return {
            ...data,
            name: projectName,
          };
        }
        return data;
      });
      return previousValues;
    },
    onError: (previousValues) => queryClient.setQueryData(AdminApi.projectKeys.projectData(projectId), previousValues),
    onSuccess: () => {
      queryClient.invalidateQueries(AdminApi.projectKeys.project(projectId));
    },
  });
};

const isProjectListed = ({ id, isListed }) => {
  return projectApi.setProjectIsListedWithHttpInfo(id, { body: isListed });
};

export const useProjIsListedUpdate = (projectId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: isProjectListed,
    // onMutate: async ({ id, isListed }) => {
    //   await queryClient.cancelQueries(AdminApi.projectKeys.projectData(id));
    //   const previousValues = queryClient.getQueryData(AdminApi.projectKeys.projectData(id));

    //   queryClient.setQueryData(AdminApi.projectKeys.projectData(id), (data) => {
    //     if (id === data.id) {
    //       return {
    //         ...data,
    //         isListed,
    //       };
    //     }
    //     return data;
    //   });
    //   return previousValues;
    // },
    onError: (previousValues) => queryClient.setQueryData(AdminApi.projectKeys.projectData(projectId), previousValues),
    onSuccess: () => {
      queryClient.invalidateQueries(AdminApi.projectKeys.project(projectId));
    },
  });
};
