import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./knowledgebase-query-key-factory";

/**
 * Sets the version of Kb
 *
 * @param {string} kbId - uuid of the Kb
 * @param {string} version - version of the kb
 */
export const setKbVersion = ({ kbId, version }: { kbId: string; version: string }) => {
  return Brm.kbApi.setKBVersion(kbId, { body: version });
};

interface IUseSetKbName {
  options?: MutationOption<typeof setKbVersion>;
}

/**
 * Custom hook to change the version of an existing Kb
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetKbVersion = ({ options = {} }: IUseSetKbName = {}) => {
  return ReactQuery.useMutation({
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.knowledgebaseKeys.all);
    },
    mutationFn: setKbVersion,
  });
};
