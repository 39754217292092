import * as Recoil from "recoil";
import * as React from "react";
import * as log from "loglevel";
import { projectIdState } from "atoms";
import { SOCKET_NAMESPACE } from "constants/brm";
import { SocketConfig, useSocket } from "hooks/useSocket";
import { queryClient } from "libs/react-query";
import { variantsKeys } from "../../api/mitigation";
import { systemKeys } from "../../api/component/system";
import { VariantState } from "../../types";
import { SERVER_PUSH_EVENTS } from "../../constants";
import { useVariant } from "../useVariant";

export const useVariantRiskStatusNotification = () => {
  const [projectId] = Recoil.useRecoilState(projectIdState);
  const {
    currentVariant,
    handlers: { setCurrentVariant },
  } = useVariant();

  const socketConfig = React.useMemo<SocketConfig>(
    () => ({ namespace: SOCKET_NAMESPACE.brm, auth: { projectId } }),
    [projectId]
  );
  const { socket } = useSocket(socketConfig);

  React.useEffect(() => {
    if (socket === null) {
      return () => {};
    }
    socket.on(SERVER_PUSH_EVENTS.variantRiskCalcStatusChanged, (data: any) => {
      log.debug(`[Socket] ${SERVER_PUSH_EVENTS.variantRiskCalcStatusChanged}`, data);
      if (data.payload) {
        const { "project-id": projId, recalc, "variant-id": varId } = data.payload;
        if (currentVariant !== null) {
          if (projectId === projId && varId === currentVariant.id) {
            setCurrentVariant((prev) => ({ ...prev, isRecalcNeeded: recalc } as VariantState));
          }
        }
        queryClient.invalidateQueries(variantsKeys.all);
        queryClient.invalidateQueries(systemKeys.all);
      }
    });
    return () => {
      socket.off(SERVER_PUSH_EVENTS.variantRiskCalcStatusChanged);
    };
  }, [projectId, socket, currentVariant, setCurrentVariant]);
};
