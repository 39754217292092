import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./project-query-key-factory";

interface IRemoveProjectWorkerProps {
  projectId: string | null;
  sapProgramId: string | null;
}

/**
 * Remove a project sap program
 *
 * @returns
 */
export const removeProjectSapProgram = ({ projectId, sapProgramId }: IRemoveProjectWorkerProps) => {
  return projectId === null
    ? Promise.reject(new Error("Invalid projectId"))
    : Brm.projectApi.removeProjectSapprogram(projectId, sapProgramId);
};

type MutateFunction = typeof removeProjectSapProgram;
interface IRemoveProjectWorkerQueryProps {
  options?: MutationOption<MutateFunction>;
}

/**
 * Custom hook to remove a sap program from project
 *
 * @returns react-query Mutation.
 */
export const useRemoveProjectSapProgram = ({ options }: IRemoveProjectWorkerQueryProps = {}) => {
  const queryClient = ReactQuery.useQueryClient();

  return ReactQuery.useMutation({
    onSettled: (data, context, variables) => {
      queryClient.invalidateQueries(KeyFactory.projectKeys.project(variables.projectId));
      //   // queryClient.invalidateQueries(KeyFactory.projectKeys.workers(variables.projectId));
    },
    ...options,
    mutationFn: removeProjectSapProgram,
  });
};
