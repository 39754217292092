import { AdminApi, useRoles } from "features/brm";
import { Setting } from "features/admin/types/setting-types";
import { COMMON } from "constants/brm";

interface IUseInstallationConfigurationPanelApi {
  installationId: string;
}

export const useInstallationConfigurationPanelApi = ({ installationId }: IUseInstallationConfigurationPanelApi) => {
  const { isSuperAdmin } = useRoles();
  const { data: projectSettings } = AdminApi.useGetInstallationProperty({
    installationId: COMMON.defaultUuid,
    property: "projectSettings",
    options: {
      enabled: isSuperAdmin,
    },
  });

  const { mutate: setProjectSettingProperty } = AdminApi.useSetInstallationProperty();

  const setProjectSetting = (setting: Setting) => {
    setProjectSettingProperty({ installationId, property: "projectSetting", value: setting });
  };

  return {
    projectSettings,
    setProjectSetting,
  };
};
