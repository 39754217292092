import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { variantIdState } from "atoms/atoms-component";
import { BrmMainTable } from "brm/tables/BrmTables";
import CustomTable from "components/elements/CustomTableDivStyled";
import { createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
import { useQueryClient } from "@tanstack/react-query";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { useGetElementFindings } from "brm/tables/details/api";
import { projectIdState } from "atoms/atoms-admin";
import { useRoles } from "features/brm";

const SoftwareVulnerabilityTable = ({ elementNameInUrl, selectedElement }) => {
  const { isRiskAnalyst } = useRoles();

  const columns = useMemo(
    () => [
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("cwe"),
      createColumnMappedNoEdit("sfp"),
      createColumnMappedNoEdit("lineNumber"),
      createColumnMappedNoEdit("tool"),
      createColumnMappedNoEdit("resource"),
      createColumnMappedNoEdit("weakness"),
    ],
    []
  );

  const [, setSelectedFindings] = useState([]);
  const variantId = useRecoilValue(variantIdState);
  const projectId = useRecoilValue(projectIdState);

  const queryClient = useQueryClient();
  const { data: getFindingsData, isError: isGetFindingsDataError } = useGetElementFindings({
    elementId: projectId,
    elementName: "projects",
    projectId,
    variantId,
    elementCategory: null,
    nodeId: null,
    options: { enabled: isRiskAnalyst },
  });

  useEffect(() => {
    queryClient.invalidateQueries(["getElementFindings", selectedElement, elementNameInUrl, variantId]);
  }, [elementNameInUrl, queryClient, selectedElement, variantId]);

  if (isGetFindingsDataError) {
    return <ErrorBanner msg="Error while loading findings data" />;
  }

  if (getFindingsData) {
    return (
      <CustomTable id="SoftwareVulnerabilityTable">
        <BrmMainTable
          data={getFindingsData}
          columns={columns}
          setSelectedElement={setSelectedFindings}
          setSelectedRows={setSelectedFindings}
          customProps={{ id: "SoftwareVulnerabilityTable_table" }}
          showRowSelect={false}
        />
      </CustomTable>
    );
  }

  return <Loading />;
};

SoftwareVulnerabilityTable.propTypes = {
  elementNameInUrl: PropTypes.string,
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default SoftwareVulnerabilityTable;
