import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import CategorizedEntityTable from "brm/tables/details/CategorizedEntityTable";
import PropTypes from "prop-types";
import SystemAssetTable from "brm/tables/details/SystemAssetTable";
import DynamicSubTabSelector from "components/elements/DynamicSubTabSelector";
import { DetailsTableMap } from "brm/tables/details/DetailsTableMap";
import { DetailTableType } from "features/brm";
import { useRoles } from "features/brm/hooks/useRoles";

const systemMenu = [
  DetailTableType.PRODUCER,
  DetailTableType.CONSUMER,
  DetailTableType.PRODUCING_ACTIVITY,
  DetailTableType.CONSUMING_ACTIVITY,
  DetailTableType.DATAFLOWS,
  DetailTableType.PERSONS,
  DetailTableType.CAPABILITIES,
  DetailTableType.MISSIONS,
  DetailTableType.PARENT,
  DetailTableType.CHARACTERISTICS,
];

const attacksMenu = [DetailTableType.THREAT_EVENTS, DetailTableType.ATTACKS, DetailTableType.ATTACKERS];

const controlsMenu = [DetailTableType.ALLOCATED_CONTROLS];

const vulnerabilityMenu = [DetailTableType.VULNERABILITY_CONDITIONS];

const accessMenu = [
  DetailTableType.ACCESS_POINTS,
  DetailTableType.ATTACK_SURFACES,
  DetailTableType.ATTACK_VECTORS,
  DetailTableType.ENTRY_POINTS,
];

const riskMenu = [DetailTableType.DEP_RISKS, DetailTableType.DEP_ASSETS, DetailTableType.DEP_UNDESIRED_EVENTS];

const ExchangeDetails = ({ selectedElement, defaultTabIndex = 0, defaultSubTabIndex = 0 }) => {
  const [tabIndex, setTabIndex] = React.useState(defaultTabIndex);
  const { isRiskAnalyst } = useRoles();
  const [, setSubTabIndex] = React.useState(defaultSubTabIndex);
  const elementNameInUrl = DetailTableType.EXCHANGES.key;

  const [selectedContext, setSelectedContext] = React.useState(DetailTableType.PRODUCER.key);
  const [selectedControlsContext, setSelectedControlsContext] = React.useState(DetailTableType.ALLOCATED_CONTROLS.key);
  const [selectedRiskContext, setSelectedRiskContext] = React.useState(DetailTableType.RISKS.key);
  const [selectedAttackContext, setSelectedAttackContext] = React.useState(DetailTableType.THREAT_EVENTS.key);
  const [selectedAccessContext, setSelectedAccessContext] = React.useState(DetailTableType.ACCESS_POINTS.key);
  const [selectedVulContext, setSelectedVulContext] = React.useState(DetailTableType.VULNERABILITY_CONDITIONS.key);
  const [curr, setCurr] = React.useState("");

  React.useEffect(() => {
    setTabIndex(defaultTabIndex);
  }, [defaultTabIndex]);

  React.useEffect(() => {
    setSubTabIndex(defaultSubTabIndex);
  }, [defaultSubTabIndex]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
      <TabList>
        <Tab onClick={() => setCurr(DetailTableType.SYSTEM.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.SYSTEM.name}
            menu={systemMenu}
            setSelectedContext={setSelectedContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.CATEGORIZATION.name)}>{DetailTableType.CATEGORIZATION.name}</Tab>
        <Tab onClick={() => setCurr(DetailTableType.ACCESS.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.ACCESS.name}
            menu={accessMenu}
            setSelectedContext={setSelectedAccessContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.SYSTEM_ASSETS.name)}>{DetailTableType.SYSTEM_ASSETS.name}</Tab>
        {isRiskAnalyst && (
          <>
            <Tab onClick={() => setCurr(DetailTableType.RISK.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.RISK.name}
                menu={riskMenu}
                setSelectedContext={setSelectedRiskContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.ATTACK.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.ATTACK.name}
                menu={attacksMenu}
                setSelectedContext={setSelectedAttackContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.VULNERABILITIES.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.VULNERABILITIES.name}
                menu={vulnerabilityMenu}
                setSelectedContext={setSelectedVulContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.CONTROLS.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.CONTROLS.name}
                menu={controlsMenu}
                setSelectedContext={setSelectedControlsContext}
                curr={curr}
              />
            </Tab>
          </>
        )}
      </TabList>
      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedContext}
        />
      </TabPanel>
      <TabPanel>
        <CategorizedEntityTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} showAdd />
      </TabPanel>
      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedAccessContext}
        />
      </TabPanel>
      <TabPanel>
        <SystemAssetTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
      {isRiskAnalyst && (
        <>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedRiskContext}
            />
          </TabPanel>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedAttackContext}
            />
          </TabPanel>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedVulContext}
            />
          </TabPanel>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedControlsContext}
            />
          </TabPanel>
        </>
      )}
    </Tabs>
  );
};

ExchangeDetails.propTypes = {
  selectedElement: PropTypes.object.isRequired,
  defaultTabIndex: PropTypes.number,
  defaultSubTabIndex: PropTypes.number,
};

export default ExchangeDetails;
