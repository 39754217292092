import { useState, useEffect } from "react";
import * as ReactQuery from "@tanstack/react-query";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import { AddButton, DeleteButton, SystemApi } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { TYPE } from "constants/brm";
import systemModals from "components/modals/Modals";

import ContentLayout from "brm/layout/Content/ContentLayout";
import { useRoles } from "features/brm/hooks/useRoles";
import ResourceTable from "./tables/ResourceTable";
import ResourceAddForm from "./forms/ResourceAddForm";

function Resource() {
  const { isSystemEngineer } = useRoles();
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const queryClient = ReactQuery.useQueryClient();

  const deleteResource = SystemApi.useDeleteResource({
    options: {
      onSuccess: () => {
        setDeleteModalIsOpen(false);
        setSelectedInstance("");
        queryClient.invalidateQueries(SystemApi.resourceKeys.all);
      },
      onError: (error: { message: string | null }) => {
        console.error("Error when deleting mission:", error.message);
      },
    },
  });

  const addResource = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      Promise.all(selectedRows.map((each: { id: string }) => deleteResource.mutateAsync({ resourceId: each.id })));
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteResource, queryClient, selectedRows, setSelectedInstance]);

  const resourceHeader = (
    <Title id="Resource_title">
      Resources
      <Buttons id="Resource_buttons">
        {isSystemEngineer ? (
          <>
            {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
            <AddButton onClick={addResource} />
          </>
        ) : (
          <br />
        )}
      </Buttons>
    </Title>
  );

  const resourceMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "Resource_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.resource}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.mission
        )}
      {modalIsOpen &&
        systemModals.addModal(
          "Resource_page",
          modalIsOpen,
          setModalIsOpen,
          <ResourceAddForm setModalIsOpen={setModalIsOpen} />,
          TYPE.resource
        )}
      <ResourceTable onRowSelect={setSelectedRows} />
    </>
  );

  return <ContentLayout pageId="Resource_page" header={resourceHeader} main={resourceMain} />;
}

export default Resource;
