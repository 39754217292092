import { COMMON } from "constants/brm";
import { BrmError } from "../../../../errors/BrmError";

interface IResponseAsArrayProps {
  map: {
    [index: string]: (id?: string) => any;
  };
  elementType: string;
  elementId: string;
}

export const responseAsArray = async ({ map, elementType, elementId }: IResponseAsArrayProps) => {
  if (map[elementType]) {
    const data = await map[elementType](elementId);
    if (data && !Array.isArray(data)) {
      return data?.id === COMMON.nullUuid ? [] : [data];
    }
    return data || [];
  }

  throw new BrmError(`Unsupported element type: ${elementType}`);
};
