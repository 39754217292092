// import * as React from "react";
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { STATUS } from "constants/brm";
import { useAdminProjects } from "../../hooks/useAdminProjects";
import * as KeyFactory from "./save-point-query-key-factory";
/**
 *
 * Returns a list of projects with all their save points
 *
 * @param {object} projects - list of project
 */
export const getProjectsWithSavepoints = async ({ projects }: { projects: any }) => {
  // TODO: To improve efficiency the backend should provide Save Points information in projectShortDto
  // Note: When user creates a backup a savepoint is created; same on restore. savepointType=false requests only savepoints created by user (not backup/restore).
  if (projects && projects.length > 0) {
    const projectsWithSp = await Promise.all(
      projects
        ?.filter((e: any) => e.status !== STATUS.deleted)
        .map((proj: any) =>
          Brm.savePointApi.savepointHistoryProjectIdGet(proj.id, { savepointType: false }).then((sp: any) => {
            const project = proj;
            project.savepoints = sp;
            return project;
          })
        )
    );

    return projectsWithSp;
  }

  return [];
};

interface IProjectsWithSavepointsProps {
  userId: string | null;
  userRole: string | null;
  options?: QueryOption<typeof getProjectsWithSavepoints>;
}

/**
 * Custom hook to list projects with their save points
 *
 * @param {object} options - react-query options
 * @returns getprojectCatalog
 */
export const useProjectsWithSavepoints = ({ userId, userRole, options = {} }: IProjectsWithSavepointsProps) => {
  const projectsQuery = useAdminProjects(userId, userRole);

  const projectsWithSavepointsQuery = ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.savePointKeys.projects(projectsQuery.data),
    queryFn: () => getProjectsWithSavepoints({ projects: projectsQuery.data }),
    enabled: options.enabled && !!projectsQuery?.data,
  });

  if (projectsQuery.isError) {
    return projectsQuery;
  }

  return projectsWithSavepointsQuery;
};
