// Libs
import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { useRecoilValue } from "recoil";
// Utils
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
// State
import { variantIdState } from "atoms/atoms-component";
// Constants
import { TYPE } from "constants/brm";
// Hooks
import { useModal } from "hooks";
import { useRoles } from "features/brm/hooks/useRoles";
// Styles
import * as S from "brm/styles/details-table.styles";
// Queries
import * as BrmGql from "generated/graphql";
// Local
import { DetailTableType, ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { RoutePath } from "routes/route-paths";
import { useElementAttackers } from "./api";

const AttackerTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const { isThreatAnalyst } = useRoles();
  const variantId = useRecoilValue(variantIdState);
  const [, setSelectedAttacker] = useState([]);
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();
  const { data: attackersData, isError: isGetAttackerDataError } = useElementAttackers({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    variantId,
    config: {
      enabled:
        !!selectedElement.id &&
        !!elementNameInUrl &&
        elementNameInUrl !== DetailTableType.RESOURCES.key &&
        elementNameInUrl !== DetailTableType.CAPABILITIES.key &&
        elementNameInUrl !== DetailTableType.PERSON.key &&
        elementNameInUrl !== DetailTableType.MISSIONS.key &&
        elementNameInUrl !== DetailTableType.DATATYPES.key &&
        elementNameInUrl !== DetailTableType.DATAFLOWS.key &&
        elementNameInUrl !== DetailTableType.ACTIVITIES.key,
    },
  });
  const {
    data: targetAttackersData,
    isError: isTargetAttackersDataError,
    error: targetAttackersDataError,
  } = BrmGql.useGetTargetAttackersQuery(
    { target: selectedElement.id, variant: variantId },
    {
      enabled:
        !!selectedElement.id &&
        (elementNameInUrl === DetailTableType.CAPABILITIES.key ||
          elementNameInUrl === DetailTableType.PERSON.key ||
          elementNameInUrl === DetailTableType.MISSIONS.key ||
          elementNameInUrl === DetailTableType.DATATYPES.key ||
          elementNameInUrl === DetailTableType.RESOURCES.key ||
          elementNameInUrl === DetailTableType.DATAFLOWS.key ||
          elementNameInUrl === DetailTableType.ACTIVITIES.key),
      refetchOnWindowFocus: false,
    }
  );

  const columns = useMemo(
    () =>
      isThreatAnalyst
        ? [createColumnNameDetails(RoutePath.Attacker.replace(":id", "")), createColumnMappedNoEdit("category")]
        : [createColumnMappedNoEdit("name"), createColumnMappedNoEdit("category")],
    [isThreatAnalyst]
  );

  const data = useMemo(() => {
    let result = null;
    if (attackersData) {
      result = Array.isArray(attackersData) ? attackersData : [attackersData];
    } else if (targetAttackersData) {
      const res = targetAttackersData?.target?.all_attackers;
      result = Array.isArray(res) ? res : [res];
    }
    return result;
  }, [attackersData, targetAttackersData]);

  if (isGetAttackerDataError) {
    return <ErrorBanner msg="Error while loading Attackers" />;
  }

  if (isTargetAttackersDataError) {
    return <ErrorBanner msg={targetAttackersDataError?.message} />;
  }

  if (data) {
    return (
      <S.DetailsContainer id="AttackerTable_detailsPanel">
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedAttacker}
              customProps={{ id: "AttackerTable_detailsTable" }}
              showRowSelect={false}
              elementName={TYPE.attacker}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }
  return <Loading />;
};

AttackerTable.propTypes = {
  elementNameInUrl: PropTypes.string.isRequired,
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
  }),
  tableTitle: PropTypes.string,
};

export default AttackerTable;
