// Local
import { AUDIT_FORMAT, AUDIT_TYPE } from "../../constants";
import S from "../ExportWizard/ExportWizard.styles";

interface IImportAuditTypeSelector {
  selectedFormat: string;
  setSelectedFormat: (value: string) => void;
  selectedType: string;
  setSelectedType: (value: string) => void;
}

export const ImportAuditLogsSelector = ({
  selectedFormat,
  setSelectedFormat,
  selectedType,
  setSelectedType,
}: IImportAuditTypeSelector) => {
  return (
    <div>
      <label>Export as :</label>
      <S.ExportMenu>
        <S.List>
          <li>
            <input
              type="radio"
              value="CSV"
              checked={selectedFormat === AUDIT_FORMAT.csv}
              onChange={(e) => setSelectedFormat(e.target.value)}
            />
            <S.ExportLabel>.csv</S.ExportLabel>
          </li>
          <li>
            <input
              type="radio"
              value="JSON"
              checked={selectedFormat === AUDIT_FORMAT.json}
              onChange={(e) => setSelectedFormat(e.target.value)}
            />
            <S.ExportLabel>.json</S.ExportLabel>
          </li>
        </S.List>
        <div>
          Report type :
          <S.ExportMenu>
            <S.List>
              <li>
                <input
                  type="radio"
                  value={AUDIT_TYPE.session}
                  checked={selectedType === AUDIT_TYPE.session}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                  }}
                />
                <S.ExportLabel>Session</S.ExportLabel>
              </li>
              <li>
                <input
                  type="radio"
                  value={AUDIT_TYPE.activeSessions}
                  checked={selectedType === AUDIT_TYPE.activeSessions}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                  }}
                />
                <S.ExportLabel>Active Sessions</S.ExportLabel>
              </li>
              <li>
                <input
                  type="radio"
                  value={AUDIT_TYPE.admin}
                  checked={selectedType === AUDIT_TYPE.admin}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                  }}
                />
                <S.ExportLabel>Admin</S.ExportLabel>
              </li>
            </S.List>
          </S.ExportMenu>
        </div>
      </S.ExportMenu>
    </div>
  );
};
