import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./capability-query-key-factory";

export interface IDeleteElementCapabilitiesProps {
  elementId: string;
  elementName: string;
  capabilityId: string;
}

interface IcapabilityMap {
  [index: string]: (id?: string) => any;
}

/**
 * Deletes Capability for an element
 *
 * @param {string} elementId - uuid of the element
 * @param {string} elementName - type of the element
 * @param {string} capabilityId - uuid of capabiblity
 */
export const deleteElementCapabilities = ({
  elementId,
  elementName,
  capabilityId,
}: IDeleteElementCapabilitiesProps) => {
  const capabilityMap: IcapabilityMap = {
    missions: (id) => Brm.missionApi.removeMissionCapability(id, capabilityId),
  };

  if (capabilityMap[elementName]) {
    return capabilityMap[elementName](elementId);
  }

  return [];
};

export interface IUseDeleteElementCapabilities {
  options?: MutationOption<typeof deleteElementCapabilities>;
}

/**
 * Custom hook to delete activity from an element
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteElementCapabilities = ({ options = {} }: IUseDeleteElementCapabilities = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.capabilityKeys.all);
    },
    ...options,
    mutationFn: deleteElementCapabilities,
  });
};
