import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./license-query-key-factory";

/**
 * checks the validity of the license
 *
 */
export const getLicenseCheck = () => {
  return Brm.licensingApi.licenseCheck();
  // const err = new Error("Failed");
  // // (err as any).status = 461;
  // // (err as any).statusText = "Total user sessions exceeded";

  // (err as any).status = 460;
  // (err as any).statusText = "No License";

  // throw err;
};

interface IUseLicenseCheck {
  options?: QueryOption<typeof getLicenseCheck>;
}

/**
 * Custom-hook to check the validity of the license
 *
 * @param {object} options - react-query configuration object
 * @returns react-query for getLicenseCheck
 */
export const useLicenseCheck = ({ options }: IUseLicenseCheck = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.licenseKeys.check(),
    queryFn: () => getLicenseCheck(),
  });
};
