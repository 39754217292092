import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./variant-query-key-factory";

type VariantProperty = "baseline" | "catalog" | "sctm";

/**
 * Gets the variantId's properties
 */
export const getVariantProperty = ({
  variantId,
  property,
}: {
  variantId: string | null;
  property: VariantProperty;
}) => {
  if (variantId === null) {
    return Promise.reject(new Error("Invalid VariantId"));
  }
  switch (property) {
    case "catalog":
      return Brm.variantApi.getVariantCatalog(variantId);
    case "baseline":
      return Brm.variantApi.getVariantBaseline(variantId);
    case "sctm":
      return Brm.variantApi.getVariantSctm(variantId);
    default:
      return Promise.reject(new Error(`Unsupported property:${property}`));
  }
};

type FetcherFunction = typeof getVariantProperty;
interface IVariantsProps {
  variantId: string;
  property: VariantProperty;
  options?: QueryOption<FetcherFunction>;
}

/**
 * Custom hook for getting projects projects
 *
 * @param {object} options - react-query options
 */
export const useGetVariantProperty = ({ variantId, property, options = {} }: IVariantsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.variantsKeys.property(variantId, property),
    queryFn: () => getVariantProperty({ variantId, property }),
  });
};
