import * as React from "react";
import * as Recoil from "recoil";

// import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// State
import { userIdState } from "atoms/atoms-admin";
import {
  riskUpdatedState,
  fromRiskMatrixState,
  riskMatrixRowState,
  riskFromNavigateState,
  isRiskMatrixCellClickedState,
} from "atoms/atoms-risk";
// Hooks
import {
  AdminApi,
  // currentProjectState,
  isCurrentProjectActiveState,
  isCurrentProjectGeneratingState,
  RiskApi,
  useProject,
  useProjectStatus,
  useVariant,
} from "features/brm";
import usePrevious from "hooks/usePrevious";
import { RoutePath } from "routes/route-paths";
// Services
// Helpers
import { queryClient } from "libs/react-query";
import { useRoles } from "features/brm/hooks/useRoles";
import updateRiskCounts from "./RiskMatrixHelpers";
// Styles
import S from "./RiskMatrix.styles";

export const RiskMatrix = () => {
  const { isReadableAsRa } = useProjectStatus();
  const { variantId } = useVariant();
  const [currentProject, { projectId }] = useProject();

  const isCurrentProjectActive = Recoil.useRecoilValue(isCurrentProjectActiveState);
  const isCurrentProjectGenerating = Recoil.useRecoilValue(isCurrentProjectGeneratingState);
  const [riskUpdated, setRiskUpdated] = Recoil.useRecoilState(riskUpdatedState);
  const setFromRiskMatrix = Recoil.useSetRecoilState(fromRiskMatrixState);
  const [isRiskMatrixCellClicked, setIsRiskMatrixCellClicked] = Recoil.useRecoilState(isRiskMatrixCellClickedState);
  const riskFromNavigate = Recoil.useRecoilValue(riskFromNavigateState);
  const defaultRiskRows = Recoil.useRecoilValue(riskMatrixRowState);
  const [riskRowDisplay, setRiskRowDisplay] = React.useState(defaultRiskRows);
  const navigate = useNavigate();
  const location = useLocation();
  const userId = Recoil.useRecoilValue(userIdState);
  const { isRiskAnalyst } = useRoles();
  const prevProject = usePrevious(currentProject);
  const prevVariant = usePrevious(variantId);

  const { data: projectAccessLevel } = AdminApi.useProjectAccess({
    projectId,
    options: { enabled: !!currentProject },
  });

  // TODO handle errors
  const { data: risks } = RiskApi.useRisks({
    projectId,
    variantId,
    options: {
      enabled: projectId !== "" && isReadableAsRa && !!projectAccessLevel,
      select: React.useCallback((data) => data.filter((r) => r.score.value !== 0), []),
    },
  });

  const { hasProjectPermission: projectPermission } = AdminApi.useUserProjectPermission({
    userId,
    projectId,
    options: {
      enabled: !!userId && isCurrentProjectActive,
    },
  });

  React.useEffect(() => {
    if (!isCurrentProjectGenerating) {
      queryClient.invalidateQueries(RiskApi.riskKeys.all);
    }
  }, [isCurrentProjectGenerating]);

  React.useEffect(() => {
    // console.log("here1", risks, projectPermission, isCurrentProjectActive);
    if (risks && projectPermission && isCurrentProjectActive) {
      // console.log("here2");
      const finalRiskRows = updateRiskCounts(risks, defaultRiskRows);
      setRiskRowDisplay(finalRiskRows);
    }
    if (riskUpdated) {
      setRiskUpdated(false);
    }
  }, [
    isCurrentProjectActive,
    riskUpdated,
    projectPermission,
    defaultRiskRows,
    setRiskUpdated,
    currentProject,
    risks,
    prevProject,
    prevVariant,
  ]);

  React.useEffect(() => {
    if (location.pathname !== RoutePath.Risks || riskUpdated || riskFromNavigate) {
      const cells = document.getElementsByTagName("td");
      for (let i = 0; i < cells.length; i += 1) {
        cells[i].style.outline = "white";
      }
    }
  }, [location, riskUpdated, riskFromNavigate]);

  const renderTableRows = React.useCallback(() => {
    const rows1 = [];
    const rows2 = [];
    const rows3 = [];
    const rows4 = [];
    const rows5 = [];

    const handleClick = async (e) => {
      if (isRiskAnalyst) {
        const cells = document.getElementsByTagName("td");
        for (let i = 0; i < cells.length; i += 1) {
          cells[i].style.outline = "white";
        }
        e.target.style.outline = "1px solid black";

        await setFromRiskMatrix({
          navFromRiskMatrix: true,
          filter: e.target.id,
        });
        setIsRiskMatrixCellClicked(() => !isRiskMatrixCellClicked);
        navigate(RoutePath.Risks);
      }
    };

    function getTableDataStyle(row) {
      let newRow = null;
      // set the style based on risk level
      switch (row.risk.id) {
        case "R1":
          newRow = (
            <S.R1Cell
              data-testid={`${row.likelihood.id}_${row.impact.id}`}
              hover={isRiskAnalyst}
              key={`${row.likelihood.id}_${row.impact.id}`}
              id={`${row.likelihood.id}_${row.impact.id}`}
              onClick={handleClick}
            >
              {row.risk.count}
            </S.R1Cell>
          );
          break;
        case "R2":
          newRow = (
            <S.R2Cell
              data-testid={`${row.likelihood.id}_${row.impact.id}`}
              hover={isRiskAnalyst}
              key={`${row.likelihood.id}_${row.impact.id}`}
              id={`${row.likelihood.id}_${row.impact.id}`}
              onClick={handleClick}
            >
              {row.risk.count}
            </S.R2Cell>
          );
          break;
        case "R3":
          newRow = (
            <S.R3Cell
              data-testid={`${row.likelihood.id}_${row.impact.id}`}
              hover={isRiskAnalyst}
              key={`${row.likelihood.id}_${row.impact.id}`}
              id={`${row.likelihood.id}_${row.impact.id}`}
              onClick={handleClick}
            >
              {row.risk.count}
            </S.R3Cell>
          );
          break;
        case "R4":
          newRow = (
            <S.R4Cell
              data-testid={`${row.likelihood.id}_${row.impact.id}`}
              hover={isRiskAnalyst}
              key={`${row.likelihood.id}_${row.impact.id}`}
              id={`${row.likelihood.id}_${row.impact.id}`}
              onClick={handleClick}
            >
              {row.risk.count}
            </S.R4Cell>
          );
          break;
        case "R5":
          newRow = (
            <S.R5Cell
              data-testid={`${row.likelihood.id}_${row.impact.id}`}
              hover={isRiskAnalyst}
              key={`${row.likelihood.id}_${row.impact.id}`}
              id={`${row.likelihood.id}_${row.impact.id}`}
              onClick={handleClick}
            >
              {row.risk.count}
            </S.R5Cell>
          );
          break;
        default:
          break;
      }
      return newRow;
    }

    riskRowDisplay.row1.forEach((row) => {
      rows1.push(getTableDataStyle(row));
    });

    riskRowDisplay.row2.forEach((row) => {
      rows2.push(getTableDataStyle(row));
    });

    riskRowDisplay.row3.forEach((row) => {
      rows3.push(getTableDataStyle(row));
    });

    riskRowDisplay.row4.forEach((row) => {
      rows4.push(getTableDataStyle(row));
    });

    riskRowDisplay.row5.forEach((row) => {
      rows5.push(getTableDataStyle(row));
    });

    let tby = null;
    tby = (
      <tbody>
        <tr>
          <th>
            <S.Tooltip left>
              L5
              <span className="tooltiptext">L5 - Near Certainty</span>
            </S.Tooltip>
          </th>
          {rows1.map((obj) => obj)}
        </tr>
        <tr>
          <th>
            <S.Tooltip left>
              L4
              <span className="tooltiptext">L4 - Probable</span>
            </S.Tooltip>
          </th>
          {rows2.map((obj) => obj)}
        </tr>
        <tr>
          <th>
            <S.Tooltip left>
              L3
              <span className="tooltiptext">L3 - Occasional</span>
            </S.Tooltip>
          </th>
          {rows3.map((obj) => obj)}
        </tr>
        <tr>
          <th>
            <S.Tooltip left>
              L2
              <span className="tooltiptext">L2 - Remote</span>
            </S.Tooltip>
          </th>
          {rows4.map((obj) => obj)}
        </tr>
        <tr>
          <th>
            <S.Tooltip left>
              L1
              <span className="tooltiptext">L1 - Improbable</span>
            </S.Tooltip>
          </th>
          {rows5.map((obj) => obj)}
        </tr>
      </tbody>
    );
    return tby;
  }, [
    isRiskAnalyst,
    isRiskMatrixCellClicked,
    navigate,
    riskRowDisplay.row1,
    riskRowDisplay.row2,
    riskRowDisplay.row3,
    riskRowDisplay.row4,
    riskRowDisplay.row5,
    setFromRiskMatrix,
    setIsRiskMatrixCellClicked,
  ]);

  return (
    <S.RiskMatrixStyled>
      <S.RiskMatrixTable id="RiskMatrix_table">
        <thead>
          <tr>
            <th />
            <th>
              <S.Tooltip>
                I1
                <span className="tooltiptext">I1 - Negligible</span>
              </S.Tooltip>
            </th>
            <th>
              <S.Tooltip>
                I2
                <span className="tooltiptext">I2 - Minor</span>
              </S.Tooltip>
            </th>
            <th>
              <S.Tooltip>
                I3
                <span className="tooltiptext">I3 - Moderate</span>
              </S.Tooltip>
            </th>
            <th>
              <S.Tooltip>
                I4
                <span className="tooltiptext">I4 - Major</span>
              </S.Tooltip>
            </th>
            <th>
              <S.Tooltip>
                I5
                <span className="tooltiptext">I5 - Catastrophic</span>
              </S.Tooltip>
            </th>
          </tr>
        </thead>
        {renderTableRows()}
      </S.RiskMatrixTable>
    </S.RiskMatrixStyled>
  );
};
