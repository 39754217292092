import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./activity-query-key-factory";

interface ISetActivityIsInitialProps {
  activityId: string;
  isInitial: string;
}

/**
 * Sets isInitial value of an activity
 *
 * @param {string} activityId - uuid of the activity
 * @param {string} isInitial - isInitial value of the activity
 */
export const setActivityIsInitial = ({ activityId, isInitial }: ISetActivityIsInitialProps) => {
  return Brm.activityApi.setActivityIsInitial(activityId, { body: isInitial });
};

export interface IUseSetActivityIsInitial {
  options?: MutationOption<typeof setActivityIsInitial>;
}

/**
 * Custom hook to change the isInitial value of an existing activity
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetActivityIsInitial = ({ options }: IUseSetActivityIsInitial) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.activitiesKeys.all);
    },
    ...options,
    mutationFn: setActivityIsInitial,
  });
};
