/* eslint-disable import/prefer-default-export */
import * as ReactQuery from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";

export const useThreatEvents = (projectId, variantId, config = {}) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["threatEvents", projectId, variantId],
    queryFn: () => Brm.threatEventApi.findThreatEvent(projectId, { variant: variantId }),
  });
};
