import { COMMON } from "constants/brm";

/**
 * Mapping is based on the Risk Matrix
 *    I1   I2   I3   I4  I5
 *
 * L5   20   12    7   3   1
 * L4   22   14    9   5   2
 * L3   23   15    10   6  4
 * L2   24   18    16  11  8
 * L1   25   21    19  17  13
 *
 * @param {number} riskLevel
 * @returns string
 */

const veryLowValues = ["20", "22", "23", "24", "25", "21", "19"];
const lowValues = ["12", "13", "14", "15", "18", "16", "17", "11"];
const moderateValues = ["7", "8", "9", "10", "6"];
const highValues = ["3", "5", "4"];
const veryHighValues = ["1", "2"];

function riskLevelMapper(riskLevel) {
  if (veryHighValues.includes(riskLevel)) {
    return COMMON.veryHigh;
  }
  if (highValues.includes(riskLevel)) {
    return COMMON.high;
  }
  if (moderateValues.includes(riskLevel)) {
    return COMMON.moderate;
  }
  if (lowValues.includes(riskLevel)) {
    return COMMON.low;
  }
  if (veryLowValues.includes(riskLevel)) {
    return COMMON.veryLow;
  }

  return "";
}

export default riskLevelMapper;
