import PropTypes from "prop-types";

function DynamicSelect({ name, label = "", placeholder = "", optionData = [], disabled = false }) {
  let options;
  if (Array.isArray(optionData) && optionData.length) {
    // check if there is any data in the array first
    options = optionData.map((data) => (
      <option key={data.id} value={data.id}>
        {data.name}
      </option>
    ));
  }

  const placeHolderTxt = placeholder || `Select ${label}`;

  return (
    <label htmlFor={name}>
      {label}
      <select id={name} name={name} disabled={disabled}>
        <option value="" disabled hidden defaultValue>
          {placeHolderTxt}
        </option>
        {options}
      </select>
    </label>
  );
}

DynamicSelect.propTypes = {
  optionData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default DynamicSelect;
