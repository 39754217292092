import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";
import { useQueryClient } from "@tanstack/react-query";
import * as KeyFactory from "./save-point-query-key-factory";

/**
 * Returns a list of all save points of a given project
 *
 * @param {string} projectId - uuid of project
 * @returns list of all save points of a given project
 */
export const savePointCreateProject = ({ projectId }: { projectId: string }) => {
  return Brm.savePointApi.savepointCreateProjectIdPost(projectId);
};

export interface IUseSavepointCreateProject {
  options?: MutationOption<typeof savePointCreateProject>;
}

/**
 * Custom hook to create a save point of a project
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for savepointCreateProjectIdPost
 */
export const useSavePointCreateProject = ({ options }: IUseSavepointCreateProject = {}) => {
  const queryClient = useQueryClient();

  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.savePointKeys.all);
    },
    ...options,
    mutationFn: savePointCreateProject,
  });
};
