import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { SystemApi } from "features/brm";

/**
 * Sets the IsInput of an Datatype
 *
 * @param {string} dataTypeId - uuid of the dataType
 * @param {string} isInput - new isInput of the dataType
 */
export const setDataTypeIsInput = ({ dataTypeId, isInput }) => {
  return Brm.dataTypeApi.setDataTypeIsInput(dataTypeId, { body: isInput });
};

/**
 * Custom hook to change the IsInput of an existing datatype
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetDataTypeIsInput = ({ projectId, config = {} } = {}) => {
  const queryClient = ReactQuery.useQueryClient();

  return ReactQuery.useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(SystemApi.dataTypeKeys.project(projectId));
      queryClient.invalidateQueries(SystemApi.dataTypeKeys.dataType(variables.dataTypeId));
    },
    ...config,
    mutationFn: setDataTypeIsInput,
  });
};
