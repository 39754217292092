import { MouseEvent } from "react";
import { MdOutlinePublishedWithChanges } from "react-icons/md";

// Styles
import SecondaryButton from "components/elements/SecondaryStyledButton";

interface IPublishButtonProps {
  onClick: (event: MouseEvent) => void;
  disablePublish: boolean;
  title?: string;
}

export const PublishButton = ({ onClick, disablePublish = true, title = "Publish" }: IPublishButtonProps) => {
  return (
    <span title={title}>
      <SecondaryButton id="PublishButton" onClick={onClick} disabled={disablePublish}>
        <MdOutlinePublishedWithChanges width="16" height="16" />
      </SecondaryButton>
    </span>
  );
};
