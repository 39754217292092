import { useEffect, useState } from "react";

import { controlFamilyIdState, controlCatalogIdState } from "atoms/atoms-global-control";
import { useRecoilState } from "recoil";
import errorUtils from "utils/error-utils";
import { controlCatalogApi } from "services/brm/global-control-service";
import * as CFStyle from "./ControlFamilyDropdownSelectionStyled";

const ControlFamilyDropdownSelection = () => {
  const [controlFamilyId, setControlFamilyId] = useRecoilState(controlFamilyIdState);
  const [currentlySelectedCatalog] = useRecoilState(controlCatalogIdState);
  const [controlFamilyList, setControlFamilyList] = useState([]);

  const handleChange = (e) => {
    if (e.target.value !== "default") {
      const familyId = e.target.value;
      setControlFamilyId(familyId);
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const res = await controlCatalogApi.getControlCatalogFamilyWithHttpInfo(currentlySelectedCatalog);

        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setControlFamilyList(res.data);
        }
      } catch (err) {
        console.error("err in Get control catalog for dropdown menu in control family: ", err);
      }
    }
    if (currentlySelectedCatalog) {
      getData();
    }
  }, [currentlySelectedCatalog]);

  return (
    <CFStyle.Dropdown id="ControlFamilyDropdownSelection">
      <CFStyle.ControlSelector
        id="controlCatalogMenu"
        onBlur={() => {}}
        onChange={(e) => handleChange(e)}
        value={controlFamilyId || ""}
      >
        <option value="default">Select Control Family</option>
        {controlFamilyList &&
          controlFamilyList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
      </CFStyle.ControlSelector>
    </CFStyle.Dropdown>
  );
};

export default ControlFamilyDropdownSelection;
