import PropTypes from "prop-types";
// Styles
import S from "./IconLink.styles";

const IconLink = ({ id, href, before, beforeAlt, after, afterAlt, children, onClick, width = "20", height = "20" }) => {
  return (
    <>
      <S.Link id={id} href={href} onClick={onClick}>
        {before ? <S.Image id={`${id}_before_img`} src={before} alt={beforeAlt} width={width} height={height} /> : null}
        {children}
        {after ? <S.Image id={`${id}_after_img`} src={after} alt={afterAlt} width={width} height={height} /> : null}
      </S.Link>
    </>
  );
};

IconLink.propTypes = {
  after: PropTypes.any,
  afterAlt: PropTypes.any,
  before: PropTypes.any,
  beforeAlt: PropTypes.any,
  children: PropTypes.any,
  href: PropTypes.any,
  id: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default IconLink;
