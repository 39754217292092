import * as React from "react";
import Modals from "react-modal";
import Buttons from "components/elements/TableButtonsDivStyled";
import Cancel from "assets/icons/cancelModal.svg";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import * as S from "./Modal.styles";

function afterOpenModal() {}

function deleteModal(id, deleteModalIsOpen, setDeleteModalIsOpen, deleteElementForm, elementName) {
  return (
    <Modals
      isOpen={deleteModalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={() => setDeleteModalIsOpen(false)}
      ariaHideApp={false}
      appElement={document.getElementById(id)}
      style={S.defaultModalStyles}
      shouldCloseOnOverlayClick={false}
      id={`${id}_deleteModal`}
    >
      <S.Header id="deleteModal_header">
        <S.ModalTitle id="deleteModal_title">
          Delete {elementName}
          <Buttons id="deleteModal_button">
            <S.Close onClick={() => setDeleteModalIsOpen(false)} id="deleteModal_close">
              <S.CloseIcon src={Cancel} />
            </S.Close>
          </Buttons>
        </S.ModalTitle>
      </S.Header>

      <S.Body id="deleteModal_content">{deleteElementForm}</S.Body>
    </Modals>
  );
}

/* "disableCancel - value boolean - received from "project Add form". Purpose is to disable the close button functionality
  When the project is getting created. */
function addModal(id, modalIsOpen, setModalIsOpen, addElementForm, elementName) {
  return (
    <Modals
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={() => setModalIsOpen(false)}
      ariaHideApp={false}
      appElement={document.getElementById(id)}
      style={S.defaultModalStyles}
      shouldCloseOnOverlayClick={false}
      id={`${id}_addModal`}
    >
      <S.Header id="addModal_header">
        <S.ModalTitle id="addModal_title">
          Add {elementName}
          <Buttons id="addModal_button">
            <S.Close onClick={() => setModalIsOpen(false)} id="addModal_close">
              <S.CloseIcon src={Cancel} />
            </S.Close>
          </Buttons>
        </S.ModalTitle>
      </S.Header>

      <S.Contents id="addModal_content">{addElementForm}</S.Contents>
    </Modals>
  );
}

function customModal(id, modalIsOpen, setModalIsOpen, addElementForm, elementName) {
  return (
    <Modals
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={() => setModalIsOpen(false)}
      ariaHideApp={false}
      appElement={document.getElementById(id)}
      style={S.defaultModalStyles}
      shouldCloseOnOverlayClick={false}
      id={`${id}_customModal`}
    >
      <S.Header id="customModal_header">
        <S.ModalTitle id="customModal_title">
          {elementName}
          <Buttons id="customModal_button">
            <S.Close onClick={() => setModalIsOpen(false)} id="customModal_close">
              <S.CloseIcon src={Cancel} />
            </S.Close>
          </Buttons>
        </S.ModalTitle>
      </S.Header>

      <S.Body id="customModal_content">{addElementForm}</S.Body>
    </Modals>
  );
}

// eslint-disable-next-line react/prop-types
export const WizardModal = ({ id, modalIsOpen, content, elementName }) => {
  const [closeTrigger, setCloseTrigger] = React.useState(false);

  const onClose = () => {
    setCloseTrigger(true);
  };

  return (
    <Modals
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      ariaHideApp={false}
      appElement={document.getElementById(id)}
      style={S.defaultModalStyles}
      shouldCloseOnOverlayClick={false}
      id={`${id}_customModal`}
    >
      <S.Header id="customModal_header">
        <S.ModalTitle id="customModal_title">
          {elementName}
          <Buttons id="customModal_button">
            <S.Close onClick={onClose} id="customModal_close">
              <S.CloseIcon src={Cancel} />
            </S.Close>
          </Buttons>
        </S.ModalTitle>
      </S.Header>

      <S.Body id="customModal_content">{React.cloneElement(content, { closeTrigger })} </S.Body>
    </Modals>
  );
};

function confirmModal(id, modalIsOpen, setModalIsOpen, content, elementName, confirmationHandler) {
  return (
    <Modals
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={() => setModalIsOpen(false)}
      ariaHideApp={false}
      appElement={document.getElementById(id)}
      style={S.defaultModalStyles}
      shouldCloseOnOverlayClick={false}
      id={`${id}_confirmModal`}
    >
      <S.Header id="confirmModal_header">
        <S.ModalTitle id="confirmModal_title">
          {elementName}
          <Buttons id="confirmModal_button">
            <S.Close onClick={() => setModalIsOpen(false)} id="confirmModal_close">
              <S.CloseIcon src={Cancel} />
            </S.Close>
          </Buttons>
        </S.ModalTitle>
      </S.Header>

      <S.Body id="confirmModal_content">{content}</S.Body>
      <DialogButtonDivStyled>
        <DialogButtonStyled id="confirmModal_cancelButton" onClick={() => setModalIsOpen(false)}>
          Cancel
        </DialogButtonStyled>
        <DialogButtonStyled id="confirmModal_confirmButton" onClick={() => confirmationHandler()}>
          Confirm
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </Modals>
  );
}

function rarAutoMitigateModal(id, modalIsOpen, setModalIsOpen, addElementForm, elementName) {
  return (
    <Modals
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={() => setModalIsOpen(false)}
      ariaHideApp={false}
      appElement={document.getElementById(id)}
      style={S.autoMitigateModalStyles}
      shouldCloseOnOverlayClick={false}
      id={`${id}_rarAutoMitigateModal`}
    >
      <S.Header id="rarAutoMitigateModal_header">
        <S.ModalTitle id="rarAutoMitigateModal_title">
          {elementName}
          <Buttons id="rarAutoMitigateModal_button">
            <S.Close onClick={() => setModalIsOpen(false)} id="rarAutoMitigateModal_close">
              <S.CloseIcon src={Cancel} />
            </S.Close>
          </Buttons>
        </S.ModalTitle>
      </S.Header>

      <S.Body id="rarAutoMitigateModal_content">{addElementForm}</S.Body>
    </Modals>
  );
}

function informationModal(id, modalIsOpen, setModalIsOpen, content, isOkButtonVisible) {
  return (
    <Modals
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={() => setModalIsOpen(false)}
      ariaHideApp={false}
      appElement={document.getElementById(id)}
      style={S.defaultModalStyles}
      shouldCloseOnOverlayClick={false}
      id={`${id}_informationModal`}
    >
      <S.Header id="informationModal_header">
        <S.ModalTitle id="informationModal_title">Information</S.ModalTitle>
      </S.Header>
      <S.Body id="informationModal_content">{content}</S.Body>
      {isOkButtonVisible && (
        <>
          <DialogButtonDivStyled>
            <DialogButtonStyled id="informationModal_okButton" onClick={() => setModalIsOpen(false)}>
              OK
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </>
      )}
    </Modals>
  );
}

const modals = {
  deleteModal,
  addModal,
  customModal,
  confirmModal,
  rarAutoMitigateModal,
  informationModal,
  WizardModal,
};

export default modals;
