import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import errorUtils from "utils/error-utils";
import Title from "components/elements/StyledTitle";
import { moduleApi } from "services/brm/component-service";
import { DetailsContainer } from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";

import {
  createColumnImportedAt,
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";

export function ModuleOverview() {
  const { id } = useParams();
  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [, setSelectedRowId] = useState("");

  const columns = useMemo(
    () => [
      createColumnMappedNoEdit("importsource"),
      createColumnMappedNoEdit("sourcetype"),
      createColumnBooleanNoEditMapped("isImported"),
      createColumnImportedAt(),
    ],
    []
  );

  useEffect(() => {
    async function getData() {
      setRefresh(false);
      try {
        let ModuleToDisplay = {};

        const res = await moduleApi.getModuleByIDWithHttpInfo(id);

        if (res?.data) {
          if (res.response.status !== 200 && res.response.status !== 201) {
            errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          }
          setSelectedElement(res.data);
          ModuleToDisplay = res.data;
          setData([ModuleToDisplay]);
        }
        // }
      } catch (err) {
        console.error("err in get Module full details : ", err);
      }
    }
    if (refresh && id) {
      getData();
    }
  }, [refresh, id]);

  return (
    <>
      <Title>{selectedElement.name}</Title>

      <DetailsContainer>
        <BrmSingleElementTable
          data={data}
          columns={columns}
          setSelectedElement={setSelectedElement}
          setSelectedRowId={setSelectedRowId}
          customProps={{ id: "ModuleFullDetails_table" }}
        />
      </DetailsContainer>
    </>
  );
}
