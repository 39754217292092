import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
// Utils
import errorUtils from "utils/error-utils";
// Service
import { userApi } from "services/brm/admin-service";
import { createColumnNameDetails } from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";

export const AttachedOrganizationDetailsTable = ({ elementNameInUrl, selectedElement }) => {
  const columns = useMemo(() => [createColumnNameDetails(RoutePath.Organization.replace(":id", ""))], []);

  const [organizationData, setOrganizationData] = useState([]);
  const [, setSelectedWorker] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    async function getData() {
      // get organizations
      try {
        const { data, response } = await userApi.getUserAttachedOrganizationWithHttpInfo(selectedElement.id);
        if (isSubscribed) {
          // check the error and redirect if necessary
          errorUtils.checkErrorStatus(response.status, response.statusText);
          setOrganizationData(data);
        }
      } catch (error) {
        console.error("Attached Organization GET: ", error);
      }
    }
    getData();

    // cancel subscription to un-mounted component - clean up
    return () => {
      isSubscribed = false;
    };
  }, [elementNameInUrl, selectedElement.id]);

  return (
    <S.DetailsContainer id="AttachedOrganizationTable_detailsPanel">
      <BrmDetailTable
        data={organizationData}
        columns={columns}
        setSelectedElement={setSelectedWorker}
        customProps={{ id: "AttachedOrganizationTable_detailsTable" }}
        showRowSelect={false}
      />
    </S.DetailsContainer>
  );
};

AttachedOrganizationDetailsTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.object.isRequired,
};
