import * as React from "react";
import { BrmMainTable } from "brm/tables/BrmTables";
import { createColumnHelpMapped, createColumnHelpName } from "brm/tables/services/column/columnFactory";
import { useHelpCenterVideoContent, useFaqContent } from "features/help/hooks";
import { TYPE } from "constants/brm";
import { LoadingSpinner } from "components/elements";
import { IContentObj } from "features/help/types";

interface IHelpTable {
  setSelectedRows: (v: any) => void;
  handleArticleOnClick: (v: IContentObj) => void;
  userRoles: string[];
  contexts: string[];
}

export const HelpTable = ({ setSelectedRows, handleArticleOnClick, userRoles, contexts }: IHelpTable) => {
  const [contentsData, setContentsData] = React.useState<IContentObj[]>([]);

  const contentsVideoData = useHelpCenterVideoContent({ userRoles, contexts });
  const contentsFaqData = useFaqContent({ userRoles, contexts });

  React.useEffect(
    () => setContentsData(contentsVideoData.concat(contentsFaqData)),
    [contentsVideoData, contentsFaqData]
  );

  const columns = React.useMemo(
    () => [
      createColumnHelpMapped("helpType"),
      createColumnHelpMapped("helpRole"),
      createColumnHelpName(handleArticleOnClick),
    ],
    [handleArticleOnClick]
  );

  if (contentsData) {
    return (
      <>
        <BrmMainTable
          data={contentsData}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "HelpTable_table" }}
          showRowSelect={false}
          elementName={TYPE.help}
          isfocusBackgroundEnabled
        />
      </>
    );
  }
  return <LoadingSpinner />;
};
