/* eslint-disable import/prefer-default-export */
import * as Log from "loglevel";
import * as prefix from "loglevel-plugin-prefix";

/** Default Log Formatter */
const defaultFormatter = {
  // template: "%t [%n] %l",
  template: "[%n] %l",
  timestampFormatter(date: Date) {
    return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
  },
  levelFormatter(level: string) {
    return level.toUpperCase();
  },
  nameFormatter(name: string | undefined) {
    return name || "root";
  },
  format: undefined,
  // format(level : string, name : string, timestamp: string) {
  //     return `TIME: ${timestamp}, LOGGER: [${name}], LEVEL: ${level}, MESSAGE:`;
  // },
};

// export const initLogLevel = () => {
//   // log.setLevel("debug");
//   log.setLevel(log.levels.INFO);
//   // log.setLevel("trace"); // yeah stack traces
// };

// prefix.reg(log);
// prefix.apply(log);

/**
 * Root Logger
 */
export const rootLogger = Log.noConflict();
rootLogger.setLevel(Log.levels.WARN);
prefix.reg(rootLogger);
prefix.apply(rootLogger, defaultFormatter);

/**
 * Project Config Logger
 */
export const projectConfigLogger = Log.getLogger("ProjectConfigureLogger");
projectConfigLogger.setLevel(Log.levels.INFO);
prefix.apply(projectConfigLogger);

/**
 * Variant Push Notification Logger
 */
export const mitigationLogger = Log.getLogger("mitigation");
// mitigationLogger.setLevel(rootLogger.getLevel());
mitigationLogger.setLevel(Log.levels.INFO);
prefix.apply(mitigationLogger, defaultFormatter);
