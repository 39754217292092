import PropTypes from "prop-types";
import S from "./BrmTablePaginationSmall.styles";

const TablePaginationSmall = ({
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  setInitialPageSize,
  pageIndex,
  pageSize,
}) => {
  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
    setInitialPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <S.PaginationDiv>
      <span title="First Page">
        <S.PaginationButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </S.PaginationButton>
      </span>
      <span title="Previous Page">
        <S.PaginationButton onClick={previousPage} disabled={!canPreviousPage}>
          {"<"}
        </S.PaginationButton>
      </span>
      <S.PaginationText>
        Page{" "}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>
      </S.PaginationText>
      <S.PaginationInput
        type="number"
        min={1}
        max={pageOptions.length}
        defaultValue={pageIndex + 1}
        onChange={onChangeInInput}
      />
      <S.PaginationSelect value={pageSize} onChange={onChangeInSelect} onBlur={() => {}}>
        {[10, 20, 30, 40, 50].map((pgSize) => (
          <option key={pgSize} value={pgSize}>
            Show {pgSize}
          </option>
        ))}
      </S.PaginationSelect>
      <span title="Next Page">
        <S.PaginationButton onClick={nextPage} disabled={!canNextPage}>
          {">"}
        </S.PaginationButton>
      </span>
      <span title="Last Page">
        <S.PaginationButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </S.PaginationButton>
      </span>
    </S.PaginationDiv>
  );
};

TablePaginationSmall.propTypes = {
  canNextPage: PropTypes.any,
  canPreviousPage: PropTypes.any,
  gotoPage: PropTypes.func,
  nextPage: PropTypes.any,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  pageSize: PropTypes.any,
  previousPage: PropTypes.any,
  setPageSize: PropTypes.func,
  setInitialPageSize: PropTypes.func,
};

TablePaginationSmall.defaultProps = {};
export default TablePaginationSmall;
