import { LinkStyles } from "features/brm";

export const NotFound = () => {
  return (
    <div>
      <h1>404 - Not Found!</h1>
      <LinkStyles.CleanLink to="/">Go Home</LinkStyles.CleanLink>
    </div>
  );
};
