import styled from "styled-components";

export const InlineButton = styled.button`
  border: 1px solid #d7d7d7;
  height: 32px;
  width: 32px;
  border-radius: 3px;
  background-color: transparent;
  margin-right: 4px;

  &:disabled {
    opacity: 50%;
  }

  &:hover {
    background-color: #ddd;
  }
`;
