import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import BaselineTable from "brm/tables/details/BaselineTable";
import ControlFamilyTable from "brm/tables/details/ControlFamilyTable";
import PropTypes from "prop-types";
import { DetailTableType } from "features/brm";

export const ControlCatalogTab = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.CONTROL_CATALOGS.key;

  return (
    <Tabs>
      <TabList>
        <Tab>Baseline</Tab>
        <Tab>Control Family</Tab>
      </TabList>

      <TabPanel>
        <BaselineTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
      <TabPanel>
        <ControlFamilyTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
    </Tabs>
  );
};

ControlCatalogTab.propTypes = {
  selectedElement: PropTypes.object.isRequired,
};
