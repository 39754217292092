import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { cloneDeep } from "lodash";
import { SystemApi } from "features/brm";

export const setCategorizedEntitySubject = ({ id, sub }) => {
  return Brm.categorizedEntityApi.setCategorizedEntitySubject(id, { body: sub });
};

/**
 * Custom hook to change the subject of a existing categorized entity
 * @returns react-query ReactQuery.useMutation
 */
export const useSetCategorizedEntitySubject = (projectId) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation(({ id, sub }) => setCategorizedEntitySubject({ id, sub }), {
    onMutate: async ({ id, sub }) => {
      await queryClient.cancelQueries(SystemApi.categorizedEntityKeys.project(projectId));
      const previousValues = queryClient.getQueryData(SystemApi.categorizedEntityKeys.project(projectId));
      queryClient.setQueryData(SystemApi.categorizedEntityKeys.project(projectId), (old) =>
        old.map((data) => {
          if (id === data.id) {
            const newValue = cloneDeep(data.subject);
            newValue.name = sub;
            return {
              ...data,
              subject: newValue,
            };
          }
          return data;
        })
      );
      return previousValues;
    },
    onError: (previousValues) =>
      queryClient.setQueryData(SystemApi.categorizedEntityKeys.project(projectId), previousValues),
    onSuccess: (data, params) => {
      queryClient.invalidateQueries(SystemApi.categorizedEntityKeys.project(projectId));
      queryClient.invalidateQueries(SystemApi.categorizedEntityKeys.categorizedEntity(params.id));
    },
  });
};
