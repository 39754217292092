import * as React from "react";
// import * as Recoil from "recoil";
import { PageSpinner } from "components/elements/PageSpinner";
import * as BrmGql from "generated/graphql";
import modals from "components/modals/Modals";
import { RiskApi, SystemApi, useProject, useVariant } from "features/brm";
import useSessionStorage from "hooks/useSessionStorage";
// import useEffectDebug from "hooks/useEffectDebug";
// Local
import { isActiveStatus } from "utils/filter-utils";
import { CommonDiagramStyles as S } from "features/diagram-common";
import { TYPE } from "constants/brm";
import { IGraphData } from "features/diagram-attack/types";
import { usePrevious, useRoles } from "hooks";
import AttackNodeFilterState from "../../services/AttackNodeFilterState";
import * as AgQuery from "../../api";
import { AttackDiagramGraphComponent } from "../AttackDiagramGraphComponent";
import AttackGraphEngine from "../../model/AttackGraphEngine";

interface IAttackDiagramComponentProps {
  toolbar: any;
  initialData: any;
  targets: string[] | null;
  highlighted: string[];
  onHighlightNodes: (v: any) => void;
  onToggleOverview: () => void;
  showAttacks: boolean;
  showOverview: boolean;
}

interface IHasId {
  id: string;
}

interface IHasType extends IHasId {
  type: string;
}

export const AttackDiagramComponent = ({
  toolbar,
  initialData,
  targets = null,
  showAttacks = false,
  onHighlightNodes,
  highlighted = [],
  showOverview,
  onToggleOverview,
}: IAttackDiagramComponentProps) => {
  // console.log("initialData", initialData);

  const { isRiskAnalyst } = useRoles();
  const prevInitialData = usePrevious(initialData);
  const prevTargets = usePrevious(targets);
  const [currentProject, { isActive: isCurrentProjectActive }] = useProject();
  const { currentVariant } = useVariant();
  const variantId = currentVariant?.id || "";
  const projectId = currentProject?.id || "";

  const [sessionFilterJson, setSessionFilterJson] = useSessionStorage(`ad_filter_state_${projectId}`, "");
  const [assetCatNodeData, setAssetCatNodeData] = React.useState<IHasId | null>(null);
  const [assetNodeData, setAssetNodeData] = React.useState<IHasId | null>(null);
  const [undesiredEventNodeData, setUndesiredEventNodeData] = React.useState<IHasId | null>(null);
  const [riskNodeData, setRiskNodeData] = React.useState<IHasId | null>(null);
  const [targetNodeData, setTargetNodeData] = React.useState<IHasId | null>(null);
  const [threatEventNodeData, setThreatEventNodeData] = React.useState<IHasId | null>(null);
  const [attackNodeData, setAttackNodeData] = React.useState<IHasId | null>(null);
  const [attackGraphEngine, setAttackGraphEngine] = React.useState(() => new AttackGraphEngine({ targets }));
  const [graphData, setGraphData] = React.useState<IGraphData | null>(() => null);
  const [nodeFilter, setNodeFilter] = React.useState(() => {
    if (sessionFilterJson) {
      return AttackNodeFilterState.from(JSON.parse(sessionFilterJson));
    }
    return null;
  });
  const [i, setI] = React.useState(0);
  const [isAddAllNodes, setIsAddAllNodes] = React.useState(false);
  const isInitialLoading = React.useRef(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
  const prevAttackEngine = usePrevious(attackGraphEngine);

  const { data: assetCategories } = SystemApi.useAssetCategories({
    projectId,
    options: {
      enabled: isCurrentProjectActive && isRiskAnalyst,
    },
  });

  const { data: riskCategories } = RiskApi.useRiskCategories({
    projectId,
    options: {
      enabled: isCurrentProjectActive && isRiskAnalyst,
    },
  });

  const { data: attackTactics } = RiskApi.useAttackTactics({
    projectId,
    options: {
      enabled: isCurrentProjectActive && isRiskAnalyst,
    },
  });

  // const { data: threatEventCategories } = AgQuery.useTrevCategories(projectId);
  const { data: threatEventCategories } = RiskApi.useThreatEventCategories({
    projectId,
    options: {
      enabled: isCurrentProjectActive && isRiskAnalyst,
    },
  });

  const { data: harmCategories } = RiskApi.useHarmCategories({
    projectId,
    options: {
      enabled: isCurrentProjectActive && isRiskAnalyst,
    },
  });

  const { data: assetsByCategory } = AgQuery.useAssetsByCategory(projectId, variantId, {
    enabled: isCurrentProjectActive && isRiskAnalyst,
  });

  const { data: targetsAsNodeData } = AgQuery.useTargetsAsNodeData(projectId, variantId, {
    enabled: isCurrentProjectActive && isRiskAnalyst,
    refetchOnWindowFocus: false,
  });

  const { data: assetCatData, refetch: refetchAssetCats } = BrmGql.useGetAssetCategoryTreeQuery(
    { assetcat: assetCatNodeData?.id || "", project: projectId, variant: variantId },
    { enabled: false, refetchOnWindowFocus: false }
  );

  const { data: assetData, refetch: refetchAssets } = BrmGql.useGetAssetTreeQuery(
    { asset: assetNodeData?.id || "", variant: variantId },
    { enabled: false, refetchOnWindowFocus: false }
  );

  const { data: undesiredEventData, refetch: refetchUndesiredEvents } = BrmGql.useGetUndesiredEventTreeQuery(
    { ue: undesiredEventNodeData?.id || "", variant: variantId },
    { enabled: false, refetchOnWindowFocus: false }
  );

  const { data: riskData, refetch: refetchRisks } = BrmGql.useGetRiskTreeQuery(
    { risk: riskNodeData?.id || "", variant: variantId },
    { enabled: false, refetchOnWindowFocus: false }
  );

  const { data: targetData, refetch: refetchTargets } = BrmGql.useGetTargetTreeQuery(
    { target: targetNodeData?.id || "", variant: variantId },
    { enabled: false, refetchOnWindowFocus: false }
  );

  const { data: threatEventData, refetch: refetchThreatEvents } = BrmGql.useGetThreatEventTreeQuery(
    { trev: threatEventNodeData?.id || "", variant: variantId },
    { enabled: false, refetchOnWindowFocus: false }
  );

  const { data: attackData, refetch: refetchAttacks } = BrmGql.useGetAttackTreeQuery(
    { attack: attackNodeData?.id || "", variant: variantId },
    { enabled: false, refetchOnWindowFocus: false }
  );

  const updateGraphData = React.useCallback(() => {
    if (isInitialLoading.current) {
      return;
    }
    const [nodes, edges] = attackGraphEngine.getNodesAndEdges();
    setGraphData({ nodes, edges });
  }, [attackGraphEngine]);

  const handleNodeAdded = (nodeData: IHasType) => {
    if (nodeData.type === TYPE.assetCat) {
      setAssetCatNodeData(nodeData);
    } else if (nodeData.type === TYPE.asset) {
      setAssetNodeData(nodeData);
    } else if (nodeData.type === TYPE.risk) {
      setRiskNodeData(nodeData);
    } else if (nodeData.type === TYPE.ue) {
      setUndesiredEventNodeData(nodeData);
    } else if (nodeData.type === TYPE.node || nodeData.type === TYPE.exchange) {
      setTargetNodeData(nodeData);
    } else if (nodeData.type === TYPE.trev) {
      setThreatEventNodeData(nodeData);
    } else if (nodeData.type === TYPE.attack) {
      setAttackNodeData(nodeData);
    }
  };

  /**
   * Create a new AG Engine whenever the intial data is different or the targets change.
   * This is ensures a diagram that doesn't build when switching projects/variants
   */
  React.useEffect(() => {
    if ((targets && prevTargets !== null && targets !== prevTargets) || initialData !== prevInitialData) {
      setAttackGraphEngine(new AttackGraphEngine({ targets }));
    }
  }, [initialData, prevInitialData, prevTargets, setAttackGraphEngine, targets]);

  React.useEffect(() => {
    if (attackGraphEngine && prevAttackEngine !== null && attackGraphEngine !== prevAttackEngine) {
      isInitialLoading.current = true;
      initialData.forEach((nd: any) => handleNodeAdded(nd));
      isInitialLoading.current = false;
      updateGraphData();
    }
  }, [attackGraphEngine, initialData, prevAttackEngine, updateGraphData]);

  const handleNodesRemoved = (removedNodesData: any) => {
    attackGraphEngine.removeNodes(removedNodesData);
    updateGraphData();
  };

  const handleRemoveAllNodes = () => {
    attackGraphEngine.clear();
    setGraphData({ nodes: [], edges: [] });
  };

  React.useEffect(() => {
    if (isAddAllNodes && targetsAsNodeData && targetNodeData === null) {
      if (i < targetsAsNodeData.length) {
        setTargetNodeData(targetsAsNodeData[i]);
        setI(i + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddAllNodes, targetsAsNodeData, targetNodeData]);

  React.useEffect(() => {
    if (!targetData || !isAddAllNodes) {
      return;
    }
    if (i === targetsAsNodeData?.length) {
      updateGraphData();
      setIsInfoModalOpen(false);
      setIsAddAllNodes(false);
      setI(0);
    } else {
      attackGraphEngine.addTargetTree(targetData.target);
      setTargetNodeData(null);
    }
  }, [attackGraphEngine, targetData, isAddAllNodes, i, targetsAsNodeData, updateGraphData]);

  const handleAddAllNodes = () => {
    setIsInfoModalOpen(true);
    setIsAddAllNodes(true);
  };

  const handleFocusOnSelectedNodes = (nodeData: any) => {
    attackGraphEngine.setNodes(nodeData);
    updateGraphData();
  };

  //
  //  ASSET CATEGORY useEffects
  //
  React.useEffect(() => {
    if (!assetCatNodeData) {
      return;
    }
    refetchAssetCats();
  }, [refetchAssetCats, assetCatNodeData]);

  React.useEffect(() => {
    if (!assetCatData) {
      return;
    }
    attackGraphEngine.addAssetCategoryTree(assetCatData.assetcat);
    updateGraphData();
    setAssetCatNodeData(null);
  }, [attackGraphEngine, assetCatData, updateGraphData]);

  //
  //  ASSET useEffects
  //
  React.useEffect(() => {
    if (!assetNodeData) {
      return;
    }
    refetchAssets();
  }, [refetchAssets, assetNodeData]);

  React.useEffect(() => {
    if (!assetData) {
      return;
    }
    attackGraphEngine.addAssetTree(assetData.asset);
    updateGraphData();
    setAssetNodeData(null);
  }, [attackGraphEngine, assetData, updateGraphData]);

  //
  //  UNDESIRED EVENTS useEffects
  //
  React.useEffect(() => {
    if (!undesiredEventNodeData) {
      return;
    }
    refetchUndesiredEvents();
  }, [refetchUndesiredEvents, undesiredEventNodeData]);

  React.useEffect(() => {
    if (!undesiredEventData) {
      return;
    }
    attackGraphEngine.addUndesiredEventTree(undesiredEventData.ue);
    updateGraphData();
    setUndesiredEventNodeData(null);
  }, [attackGraphEngine, undesiredEventData, updateGraphData]);

  //
  //  RISK useEffects
  //
  React.useEffect(() => {
    // useEffectDebug(() => {
    if (!riskNodeData) {
      return;
    }
    const doRefetch = async () => {
      if (currentProject && isActiveStatus(currentProject)) {
        await refetchRisks();
      }
    };
    doRefetch();
  }, [currentProject, refetchRisks, riskNodeData]);

  React.useEffect(() => {
    if (!riskData) {
      return;
    }
    attackGraphEngine.addRiskTree(riskData.risk);
    updateGraphData();
    setRiskNodeData(null);
  }, [attackGraphEngine, riskData, updateGraphData]);

  //
  //  TARGET useEffects
  //
  React.useEffect(() => {
    if (!targetNodeData) {
      return;
    }
    refetchTargets();
  }, [refetchTargets, targetNodeData]);

  React.useEffect(() => {
    if (!targetData || isAddAllNodes) {
      return;
    }
    // console.log("updateing Graph data");
    attackGraphEngine.addTargetTree(targetData.target);
    updateGraphData();
    setTargetNodeData(null);
  }, [attackGraphEngine, targetData, updateGraphData, isAddAllNodes]);

  //
  //  THREAT EVENTS useEffects
  //
  React.useEffect(() => {
    if (!threatEventNodeData) {
      return;
    }
    refetchThreatEvents();
  }, [refetchThreatEvents, threatEventNodeData]);

  React.useEffect(() => {
    if (!threatEventData) {
      return;
    }
    attackGraphEngine.addThreatEventTree(threatEventData.trev);
    updateGraphData();
    setThreatEventNodeData(null);
  }, [attackGraphEngine, threatEventData, threatEventNodeData, updateGraphData]);

  //
  //  ATTACKS useEffects
  //
  React.useEffect(() => {
    if (!attackNodeData) {
      return;
    }
    refetchAttacks();
  }, [refetchAttacks, attackNodeData]);

  React.useEffect(() => {
    if (!attackData) {
      return;
    }
    attackGraphEngine.addAttackTree(attackData.attack);
    updateGraphData();
    setAttackNodeData(null);
  }, [attackGraphEngine, attackData, updateGraphData]);

  React.useEffect(() => {
    if (
      assetCategories &&
      assetsByCategory &&
      riskCategories &&
      harmCategories &&
      threatEventCategories &&
      attackTactics
    ) {
      let newFilter: AttackNodeFilterState;
      if (sessionFilterJson) {
        newFilter = AttackNodeFilterState.from(JSON.parse(sessionFilterJson));
      } else {
        newFilter = new AttackNodeFilterState();
        newFilter.assetCatTypes = assetCategories.map((item: any) => item);
        newFilter.assetTypes = assetsByCategory.map((item: any) => item.title);
        newFilter.riskCatTypes = riskCategories.map((item: any) => item);
        newFilter.harmCatTypes = harmCategories.map((item: any) => item);
        newFilter.targetTypes = [TYPE.node, TYPE.exchange];
        newFilter.trevCatTypes = threatEventCategories.map((item: any) => item);
        if (showAttacks) {
          newFilter.attackTacticTypes = attackTactics.map((item: any) => item);
        }
        newFilter.orphanCheck = false;
      }
      setNodeFilter((prev) => {
        if (prev !== null && prev.equals(newFilter)) {
          return prev;
        }
        return newFilter;
      });
      // setSessionFilterJson(JSON.stringify(newFilter));
    }
  }, [
    showAttacks,
    setNodeFilter,
    assetCategories,
    riskCategories,
    attackTactics,
    threatEventCategories,
    assetsByCategory,
    harmCategories,
    sessionFilterJson,
    setSessionFilterJson,
  ]);

  // React.useEffect(() => {
  //   if ((initialData && prevInitialData === undefined) || (initialData && initialData !== prevInitialData)) {
  //     isInitialLoading.current = true;
  //     initialData.forEach((nd: any) => handleNodeAdded(nd));
  //     isInitialLoading.current = false;
  //     updateGraphData();
  //   }
  // }, [initialData, prevInitialData, updateGraphData]);

  const createSpinner = () => {
    return (
      <S.CenterContainer>
        <PageSpinner text="Loading Attack Data..." />
      </S.CenterContainer>
    );
  };

  let content = null;
  if (graphData && nodeFilter && currentProject && isActiveStatus(currentProject)) {
    // console.log("graphData", graphData);
    const main = (
      <S.MainGraphContainer>
        <AttackDiagramGraphComponent
          toolbar={toolbar}
          graphData={graphData}
          nodeFilter={nodeFilter}
          setNodeFilter={(filter) => {
            setNodeFilter(filter);
            setSessionFilterJson(JSON.stringify(filter));
          }}
          onNodeAdded={handleNodeAdded}
          onNodesRemoved={handleNodesRemoved}
          onRemoveAllNodes={handleRemoveAllNodes}
          onAddAllNodes={handleAddAllNodes}
          onFocusOnSelectedNodes={handleFocusOnSelectedNodes}
          onHighlightNodes={onHighlightNodes}
          highlighted={highlighted}
          showOverview={showOverview}
          onToggleOverview={onToggleOverview}
        />
      </S.MainGraphContainer>
    );

    // All good... show graph!!
    content = (
      <>
        {isInfoModalOpen &&
          modals.informationModal(
            "AttackDiagram_page",
            isInfoModalOpen,
            setIsInfoModalOpen,
            <PageSpinner text="Adding all targets to graph. This operation may take time..." />,
            null
          )}
        {main}
      </>
    );
  } else {
    content = createSpinner();
  }
  return <S.Container>{content}</S.Container>;
};
