import * as Recoil from "recoil";
import { useMutation } from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MitigationApi } from "features/brm";
import { queryClient } from "libs/react-query";
import { isAddingAllocatedControlsState } from "atoms/atoms-mitigation";

export const useCreateAllocatedControl = (setModalIsOpen, sctmId) => {
  const setIsAddingAllocatedControls = Recoil.useSetRecoilState(isAddingAllocatedControlsState);

  return useMutation(
    ({ opts }) => {
      return Brm.sctmApi.importSCTM(sctmId, { body: opts });
    },
    {
      onMutation: () => {
        setIsAddingAllocatedControls(true);
      },
      onSuccess: () => {
        setModalIsOpen(false);
        queryClient.invalidateQueries(["getAllocatedControls"]);
        queryClient.invalidateQueries(MitigationApi.allocatedControlsGqlKey.all);
        setIsAddingAllocatedControls(false);
      },
    }
  );
};
