import * as Recoil from "recoil";
import PropTypes from "prop-types";
import { isReadOnlyState } from "atoms/atoms-admin";
import { COMMON } from "constants/brm";

const EditButtonGroup = ({ buttons }) => {
  const isReadOnly = Recoil.useRecoilValue(isReadOnlyState);
  return <>{!isReadOnly ? <div className="btn-group-sm">{buttons}</div> : COMMON.na}</>;
};

EditButtonGroup.propTypes = {
  buttons: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};

export default EditButtonGroup;
