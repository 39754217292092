import PropTypes from "prop-types";
import styled from "styled-components";

function InLineSelectBox({ arrayOfData, item, updateValues, value }) {
  let options;
  if (Array.isArray(arrayOfData) && arrayOfData.length) {
    // check if there is any data in the array first
    options = arrayOfData
      .filter((d) => d !== value)
      .map((data) => (
        <option key={data} value={data}>
          {data}
        </option>
      ));
  }

  const handleFieldChange = (e) => {
    updateValues(e.target.value);
  };

  return (
    <Select name={item} id={item} onBlur={handleFieldChange}>
      <option value="">{value || `Select ${item}`}</option>
      {options}
    </Select>
  );
}

InLineSelectBox.propTypes = {
  arrayOfData: PropTypes.array.isRequired,
  item: PropTypes.string,
  updateValues: PropTypes.func,
  value: PropTypes.any,
};

const Select = styled.select`
  width: 100%;
`;

export default InLineSelectBox;
