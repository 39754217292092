import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";

interface ISetAllocatedControlComplianceProps {
  allocatedControlId: string;
  isCompliance: string;
}

/**
 * Sets the compliance of an allocated control
 *
 * @param {string} allocatedControlId - uuid of the allocated control
 * @param {string} isCompliance - new value for the compliance
 */
export const setAllocatedControlCompliance = ({
  allocatedControlId,
  isCompliance,
}: ISetAllocatedControlComplianceProps) => {
  return Brm.allocatedControlApi.setAllocatedControlIsCompliantWithHttpInfo(allocatedControlId, {
    body: isCompliance,
  });
};

interface IUseSetAllocatedControlComplianceProps {
  options?: MutationOption<typeof setAllocatedControlCompliance>;
}

/**
 * Custom hook to change the compliance of a existing allocated control
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAllocatedControlCompliance = ({ options }: IUseSetAllocatedControlComplianceProps = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: setAllocatedControlCompliance,
  });
};
