/* eslint-disable no-param-reassign */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import convertedPercent from "utils/percent-converter";
import { sortByRiskDistributionRank } from "utils/sorting";
import { QueryOption } from "libs/react-query";
import { ExchangeShortDto } from "@kdmanalytics/brm-system";
import { COMMON } from "constants/brm";
import * as KeyFactory from "./exchange-query-key-factory";

interface IGetExchangesWithRiskProps {
  projectId: string;
  variantId: string;
}

interface IRank {
  calcValue: number;
}

interface IRisk {
  rank: IRank;
}

/**
 * Returns a list of ExchangeShortDto's augmented with Risk Data
 *
 * @param {string} projectId uuid of the project
 * @param {string} variant uuid of the variant
 *
 * @returns list of exchanges with risk data
 */
export const getExchangesWithRisk = async ({ projectId, variantId }: IGetExchangesWithRiskProps) => {
  const data = await Brm.exchangeApi.findExchange(projectId, { variant: variantId });

  const riskData = await Brm.riskApi.findRisk(projectId, { variant: variantId });
  // Find if risk with score of 1 exists
  const riskHasBeenRun = riskData.some((r: IRisk) => r.rank?.calcValue === 1);

  if (riskHasBeenRun) {
    // use the zero values in distribution
    const updatedExchangeData = data.map((ex: ExchangeShortDto) => {
      const percent = ex.riskDistributionPercent * 100;
      ex.riskDistributionPercent = convertedPercent(percent);

      return ex;
    });
    updatedExchangeData.sort(sortByRiskDistributionRank);
    let i = 1;

    // Use the sorted exchange data to update ranking to start at 1
    const updatedExchangeDataRanking = updatedExchangeData.map((ex: ExchangeShortDto) => {
      if (ex.riskDistributionRank > 0) {
        ex.riskDistributionRank = i;
        i += 1;
      }
      return ex;
    });
    return updatedExchangeDataRanking;
  }
  // set risk distribution values to N/A
  const updatedExchangeData = data.map((ex: ExchangeShortDto) => {
    ex.riskDistributionRank = COMMON.na;
    ex.riskDistributionPercent = COMMON.na;

    return ex;
  });
  return updatedExchangeData;
};

interface IUseExchangesWithRiskProps {
  projectId: string;
  variantId: string;
  options?: QueryOption<typeof getExchangesWithRisk>;
}

/**
 * Returns the Exchanges with Risk.
 *
 * @param {string} projectId uuid of project
 * @param {string} variantId uuid of variant
 * @param {object} options configuration object
 * @returns
 */
export const useExchangesWithRisk = ({ projectId, variantId, options = {} }: IUseExchangesWithRiskProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exchangeKeys.risks(projectId, variantId),
    queryFn: () => getExchangesWithRisk({ projectId, variantId }),
  });
};
