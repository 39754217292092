import * as React from "react";
import { getRefElement } from "utils/ref-utils";
import { useEventListener } from "hooks/useEventListener";

type Event = React.MouseEvent | React.TouchEvent;
type Handler = (event: Event) => void;

export const useOnClickOutside = (element: React.RefObject<Element>, handler: Handler) => {
  const handleClick = React.useCallback(
    (event) => {
      if (!getRefElement<Element>(element)?.contains(event.target)) {
        handler(event);
      }
    },
    [handler, element]
  );

  useEventListener({ type: "click", listener: handleClick });
};
