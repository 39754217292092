import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./ttp-query-key-factory";

export const getTtps = () => {
  return Brm.ttpApi.findTTP();
};

interface IUseTtpps {
  options?: QueryOption<typeof getTtps>;
}

/**
 * React-query hook for getting all TTP's
 *
 * @param
 * @returns react-query for getTtps
 */
export const useTtps = ({ options }: IUseTtpps = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.ttpKeys.all,
    queryFn: () => getTtps(),
  });
};
