import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import AttackSurfaceCategoryTable from "./tables/AttackSurfaceCategoryTable";

function AttackSurfaceCategory() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const attackSurfaceCatHeader = <Title id="AttackSurfaceCategory_title">Attack Surface Categories</Title>;

  const attackSurfaceCatMain = (
    <>
      <AttackSurfaceCategoryTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return (
    <ContentLayout pageId="AttackSurfaceCategory_page" header={attackSurfaceCatHeader} main={attackSurfaceCatMain} />
  );
}

export default AttackSurfaceCategory;
