import styled from "styled-components/macro";

interface IOverviewCollapsedProps {
  readonly collapsed: boolean;
}

interface IOverviewAbsoluteProps extends IOverviewCollapsedProps {
  readonly left: number;
  readonly top: number;
}

interface IOverviewComponentProps {
  readonly width: number;
  readonly height: number;
}
export const OverviewAbsoluteContainer = styled.div<IOverviewAbsoluteProps>`
  position: absolute;
  left: ${(props) => `${props.left}px`};
  top: ${(props) => `${props.top}px`};
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: height 1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  overflow: hidden;
  height: ${(props) => (props.collapsed ? "38px" : "238px")};
`;

export const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OverviewComponent = styled.div<IOverviewComponentProps>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  background-color: white;
  border-radius: 0 0 4px 4px;
`;

export const OverviewHeader = styled.div`
  padding: 3px;
  display: flex;
  justify-content: center;
  font-size: 1.15rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  color: #fff;
  background-color: #38434f;
  border-radius: 4px 4px 0 0;
  transition: background-color 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0s;

  & :hover {
    background-color: #005ca6;
    cursor: pointer;
  }
`;

export const OverviewChevronContainer = styled.div<IOverviewCollapsedProps>`
  position: absolute;
  top: 2px;
  left: 5px;
  transform: ${(props) => (props.collapsed ? "rotate(0deg)" : "rotate(90deg)")};
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0s;
`;

export const OverviewCloseContainer = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
`;
