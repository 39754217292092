// Components
import systemModals from "components/modals/Modals";
import { ExportContext } from "features/brm";
import { ExportTableWizard } from "../ExportWizard";
import { IExportTableModal } from "../../types";

export const ExportTableModal = ({
  onTableExport,
  isVisible,
  toggle,
  context = ExportContext.table,
}: IExportTableModal) => {
  return (
    <>
      {isVisible
        ? systemModals.customModal(
            "ExportTable_modal",
            isVisible,
            toggle,
            <ExportTableWizard onTableExport={onTableExport} toggle={toggle} exportContext={context} />,
            "Table Exporter"
          )
        : null}
    </>
  );
};
