import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import { AddButton, BrmError, DeleteButton, DetailTableType, SystemApi } from "features/brm";
import { capabilityApi, dataflowApi, busApi, linkApi } from "services/brm/system-service";
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
import * as S from "brm/styles/details-table.styles";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { ErrorBanner, LoadingSpinner } from "components/elements";

import { RoutePath } from "routes/route-paths";
import multiSelectDelete from "./utils/DetailTablesUtils";
import ExchangeAddForm from "./forms/ExchangeAddForm";

// Styles
const ExchangeTable = ({ elementNameInUrl, selectedElement, showAdd, showDelete = false, setRefresh, tableTitle }) => {
  const columns = useMemo(
    () => [createColumnNameDetails(RoutePath.Exchange.replace(":id", "")), createColumnMappedNoEdit("category")],
    []
  );

  const [, setSelectedExchange] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);

  const { data: exchangeData, isError: isExchangeDataError } = SystemApi.useElementExchanges({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    variantId,
    config: { enabled: !!selectedElement?.id && !!elementNameInUrl },
  });

  function getDeleteRequest(exchangeId) {
    switch (elementNameInUrl) {
      case DetailTableType.BUSES.key: {
        return busApi.removeBusExchangeWithHttpInfo(selectedElement.id, exchangeId);
      }
      case DetailTableType.LINKS.key: {
        return linkApi.removeLinkExchangeWithHttpInfo(selectedElement.id, exchangeId);
      }
      case DetailTableType.DATAFLOWS.key: {
        return dataflowApi.removeDataFlowExchangeWithHttpInfo(selectedElement.id, exchangeId);
      }
      case DetailTableType.CAPABILITIES.key: {
        return capabilityApi.removeCapabilityExchangeWithHttpInfo(selectedElement.id, exchangeId);
      }
      default: {
        break;
      }
    }
    throw new BrmError(`Unsupported element type:${elementNameInUrl}`);
  }

  const addExchange = () => {
    setModalIsOpen(true);
  };

  if (isExchangeDataError) {
    return <ErrorBanner msg="Error while loading exchanges" />;
  }

  if (exchangeData) {
    return (
      <S.DetailsContainer id="ExchangeTable_detailsPanel">
        {/* modal for adding instance */}
        {modalIsOpen &&
          systemModals.addModal(
            "ExchangeTable_page",
            modalIsOpen,
            setModalIsOpen,
            <ExchangeAddForm
              setModalIsOpen={setModalIsOpen}
              setRefresh={setRefresh}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
            />,
            "Exchange"
          )}
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={exchangeData}
              columns={columns}
              setSelectedElement={setSelectedExchange}
              setSelectedRows={setSelectedRows}
              customProps={{ id: "ExchangeTable_detailsTable" }}
              showRowSelect={showDelete}
              tableTitle={tableTitle}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            {showDelete && (
              <DeleteButton md onClick={() => multiSelectDelete(selectedRows, getDeleteRequest, setRefresh)} />
            )}
            {showAdd && <AddButton md onClick={addExchange} />}
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

ExchangeTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setRefresh: PropTypes.any,
  showAdd: PropTypes.any,
  showDelete: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default ExchangeTable;
