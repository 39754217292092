import styled from "styled-components";
import Form from "react-bootstrap/Form";

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  border-top: 1px solid #ccc;
  padding: 5px 0px 0px 0px;
  /* margin-right: 5px;
  margin-left: 5px; */
`;

export const StepForm = styled(Form)`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const StepDiv = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const SysmlFieldset = styled.fieldset`
  border: solid 1px #a9a9a9;
  border-radius: 5px;
  padding: 5px;
  padding-block-start: 0;
`;

export const SecurityArchFieldset = styled.fieldset`
  overflow: auto;
  padding: 3px;
`;

export const SysmlLegend = styled.legend`
  float: none;
  width: auto;
  padding: 0px 5px;
  font-size: 16px;
`;

export const ScrollingDiv = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

export const selectListStyle = {
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: "150px",
    overflowY: "auto",
  }),
  dropdownIndicator: () => ({
    padding: "8px",
    color: "#005ca6",
  }),
};

export const UploadLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
`;

export const UploadLabel = styled.label`
  border: 1px solid #999999;
  padding: 0.475rem 0.5rem;
  border-radius: 0.375rem;
  cursor: pointer;
  background-color: #cccccc;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #d9dee2;
    transition: background-color 0.15s ease-in-out;
  }
`;

export const StepLabel = styled.span`
  font-weight: 600;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const FileListGroupContainer = styled.div`
  padding: 5px 0px;
`;

export const OptionHeader = styled.div`
  padding: 5px 0px;
`;

export const Title = styled.h6`
  padding: 5px 0px;
`;
