import PropTypes from "prop-types";
import PrimaryStyledButton from "components/elements/PrimaryStyledButton";
import { FaFileAlt } from "react-icons/fa";

const PlacementDataIcon = <FaFileAlt size={18} />;

const PlacementDataButton = ({ onClick, disabled = false }) => (
  <span title="Show placement Data">
    <PrimaryStyledButton id="PlacementDataButton_button" onClick={onClick} disabled={disabled}>
      {PlacementDataIcon}
    </PrimaryStyledButton>
  </span>
);

PlacementDataButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PlacementDataButton;
