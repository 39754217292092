// Libs
import { useState, useMemo } from "react";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
// Utils
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";

// Hooks
import { useRoles } from "features/brm/hooks/useRoles";
// Styles
import * as S from "brm/styles/details-table.styles";
import { DetailTableType, SystemApi } from "features/brm";
import { CenterText, ErrorBanner, LoadingSpinner } from "components/elements";
import { RoutePath } from "routes/route-paths";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms";

interface IAttackSurfaceProps {
  elementNameInUrl: string;
  selectedElement: {
    id: string;
  };
  tableTitle: string;
}

const AttackSurfaceTable = ({ elementNameInUrl, selectedElement, tableTitle }: IAttackSurfaceProps) => {
  const { isThreatAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const columns = useMemo(() => {
    if (isThreatAnalyst) {
      return [createColumnMappedNoEdit(`name`), createColumnMappedNoEdit("category")];
    }
    return [createColumnNameDetails(RoutePath.AttackSurface.replace(":id", "")), createColumnMappedNoEdit("category")];
  }, [isThreatAnalyst]);

  const [, setSelectedAttackSurface] = useState([]);

  const { data: elementAttackSurfaceData, isError: isElementAttackSurfaceDataError } =
    SystemApi.useElementAttackSurface({
      elementId: selectedElement?.id,
      elementName: elementNameInUrl,
      projectId,
      options: {
        enabled:
          !!selectedElement.id &&
          elementNameInUrl !== DetailTableType.DATATYPES.key &&
          elementNameInUrl !== DetailTableType.MISSIONS.key &&
          elementNameInUrl !== DetailTableType.CAPABILITIES.key &&
          elementNameInUrl !== DetailTableType.DATAFLOWS.key &&
          elementNameInUrl !== DetailTableType.PERSONS.key &&
          elementNameInUrl !== DetailTableType.RESOURCES.key,
      },
    });

  if (
    elementNameInUrl === DetailTableType.DATATYPES.key ||
    elementNameInUrl === DetailTableType.MISSIONS.key ||
    elementNameInUrl === DetailTableType.CAPABILITIES.key ||
    elementNameInUrl === DetailTableType.DATAFLOWS.key ||
    elementNameInUrl === DetailTableType.PERSONS.key ||
    elementNameInUrl === DetailTableType.RESOURCES.key
  ) {
    return <CenterText msg="Attack Surfaces are not applicable" />;
  }

  if (isElementAttackSurfaceDataError) {
    return <ErrorBanner msg="Error while loading attack surfaces" />;
  }

  if (elementAttackSurfaceData) {
    return (
      <S.DetailsContainer id="AttackSurfaceTable_detailsPanel">
        <BrmDetailTable
          data={elementAttackSurfaceData}
          columns={columns}
          setSelectedElement={setSelectedAttackSurface}
          customProps={{ id: "AttackSurfaceTable_detailsTable" }}
          showRowSelect={false}
          tableTitle={tableTitle}
        />
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

export default AttackSurfaceTable;
