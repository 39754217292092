import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./mission-query-key-factory";

interface ISetMissionNameProps {
  missionId: string;
  name: string;
}

/**
 * Sets the name of a mission
 *
 * @param {string} missionId - uuid of the mission
 * @param {string} name - name of the mission
 */
export const setMissionName = ({ missionId, name }: ISetMissionNameProps) => {
  return Brm.missionApi.setMissionName(missionId, { body: name });
};

export interface IUseSetMissionName {
  projectId: string;
  options?: MutationOption<typeof setMissionName>;
}

/**
 * Custom hook to change the name of an existing mission
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetMissionName = ({ projectId, options = {} }: IUseSetMissionName) => {
  const queryClient = ReactQuery.useQueryClient();

  return ReactQuery.useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(KeyFactory.missionKeys.mission(variables.missionId));
      queryClient.invalidateQueries(KeyFactory.missionKeys.project(projectId));
    },
    ...options,
    mutationFn: setMissionName,
  });
};
