import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./exchange-query-key-factory";

/**
 * Returns the parent of the given exchange
 *
 * @param {string} childId - uuid of exchange
 * @returns parent of the given exchange
 */
export const getExchangeParent = ({ exchangeId }: { exchangeId: string }) => {
  return Brm.exchangeApi.getExchangeParent(exchangeId);
};

interface IUseExchangeParent {
  exchangeId: string;
  options?: QueryOption<typeof getExchangeParent>;
}

/**
 * Custom hook to retreive a parent of an exchange.
 *
 * @param {string} exchangeId - uuid of a child node
 * @param {object} config - react-query configuration object
 * @returns react-query for getNodeParent
 */
export const useExchangeParent = ({ exchangeId, options }: IUseExchangeParent) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exchangeKeys.parent(exchangeId),
    queryFn: () => getExchangeParent({ exchangeId }),
  });
};
