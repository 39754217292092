import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";

// Atoms
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";

// Components
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";

// Queries
import { useElementVulnerabilities } from "../api";

import VulnerabilityTableView from "./VulnerabilityTableView";

// Styles

const VulnerabilityTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const variantId = useRecoilValue(variantIdState);
  const projectId = useRecoilValue(projectIdState);

  const {
    data: vulData,
    isError: isVulDataError,
    error: elementVulnerabilityError,
  } = useElementVulnerabilities({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    variantId,
    config: {
      enabled: !!selectedElement.id,
    },
  });

  if (isVulDataError) {
    return <ErrorBanner msg={elementVulnerabilityError?.message} />;
  }

  if (Array.isArray(vulData)) {
    return <VulnerabilityTableView data={vulData} elementNameInUrl={elementNameInUrl} tableTitle={tableTitle} />;
  }

  return <Loading />;
};

VulnerabilityTable.propTypes = {
  elementNameInUrl: PropTypes.string,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  tableTitle: PropTypes.string,
};

export default VulnerabilityTable;
