import * as React from "react";
import toast from "react-hot-toast";

interface IUseSetSystemElementNameProps {
  updatedName: any;
  elementsList: any;
}

interface IUseSetSystemElementNameProps2 {
  updatedName: string;
  elementsList: any[];
}

/**
 * Validates the name of an existing system element
 */
export function useCanRename() {
  const canRename = React.useCallback(({ updatedName, elementsList }: IUseSetSystemElementNameProps) => {
    let response = true;
    if (elementsList.some((e: any) => e.name === updatedName.current)) {
      toast.error(`An element with the name ${updatedName.current} already exists choose another name`);
      updatedName.current = "";
      response = false;
      return response;
    }
    return response;
  }, []);

  const canRename2 = React.useCallback(({ updatedName, elementsList }: IUseSetSystemElementNameProps2) => {
    return !elementsList.some((e: any) => e.name === updatedName);
  }, []);

  return { canRename, canRename2 };
}
