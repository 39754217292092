import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import AttackVectorCategoryTable from "./tables/AttackVectorCategoryTable";

function AttackVectorCategory() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const attackVectorCatHeader = <Title id="AttackVectorCategory_title">Attack Vector Categories</Title>;

  const attackVectorCatMain = (
    <>
      <AttackVectorCategoryTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="AttackVectorCategory_page" header={attackVectorCatHeader} main={attackVectorCatMain} />;
}

export default AttackVectorCategory;
