import styled from "styled-components/macro";

export const Header = styled.div`
  display: flex;
  background: #ccc;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.formbackground};
  padding: 0px 10px;
`;

export const ModalTitle = styled.h4`
  color: ${({ theme }) => theme.colors.title};
  font-weight: 600;
  padding: 8px;
`;

export const CloseIcon = styled.img`
  height: 10px;
`;

export const Close = styled.div`
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  margin: 0 5px;
  background-color: none;
  border-color: none;
  color: black;
  border: none;
`;
export const HeaderButtonsDiv = styled.div`
  padding-right: 10px;
`;

export const Footer = styled.div`
  display: flex;
  background: #f7f7f7;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 10px 10px 10px;
  width: 100%;
`;
