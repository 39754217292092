import { BrmError, DetailTableType } from "features/brm";
import { useQuery } from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";

/**
 * Returns a list of categorizations of a given selected element
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 *
 */
export const getElementCategorization = ({ elementId, elementName }) => {
  const categorizationMap = {
    [DetailTableType.CATEGORIZED_ENTITIES.key]: (id) => Brm.categorizedEntityApi.getCategorizedEntityCategorization(id),
  };

  if (categorizationMap[elementName]) {
    return categorizationMap[elementName](elementId);
  }

  throw new BrmError(`Unsupported element type :${elementName}`);
};

/**
 * custom-hook to retreive all categorizations
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {object} config - configuration for the react-query
 * @returns react-query for categorization
 */
export const useElementCategorization = ({ elementId, elementName, projectId, config = {} }) => {
  return useQuery({
    ...config,
    queryKey: ["elementCategorization", elementId, elementName, projectId],
    queryFn: () => getElementCategorization({ elementId, elementName, projectId }),
  });
};
