import UploadIcon from "assets/icons/upload.svg";
import SecondaryButton from "components/elements/SecondaryStyledButton";
import ImgSized from "components/elements/ImgSized";
import { MouseEvent } from "react";

interface IUploadButtonProps {
  onClick: (event: MouseEvent) => void;
  uploadTitle?: string;
}

export const UploadButton = ({ onClick, uploadTitle = "Import" }: IUploadButtonProps) => (
  <span title={uploadTitle}>
    <SecondaryButton id="UploadButton_button" onClick={onClick}>
      <ImgSized id="UploadButton_icon" src={UploadIcon} alt="upload" />
    </SecondaryButton>
  </span>
);
