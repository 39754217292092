import * as React from "react";
import { ExportToCsv } from "export-to-csv";
import { processData } from "./processing";
import { IExportTableData } from "../../types";

interface ICallbackOpts {
  target: string;
  type: string;
}

export const useExportTable = () => {
  const [exportTableData, setExportTableData] = React.useState<IExportTableData>();
  const [disableExport, setDisableExport] = React.useState(true);
  const [fileNamePrefix, setFileNamePrefix] = React.useState("BRMExport_");

  React.useEffect(() => {
    if (exportTableData?.rawRows?.length) {
      setDisableExport(false);
    } else {
      setDisableExport(true);
    }
  }, [exportTableData, setDisableExport]);

  const handleTableExport = React.useCallback(
    ({ type }: ICallbackOpts) => {
      const data = processData(exportTableData, type);
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        filename: `${fileNamePrefix}${exportTableData?.originalName}_${type}`,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);
    },
    [exportTableData, fileNamePrefix]
  );

  return {
    disableExport,
    setExportTableData,
    handleTableExport,
    setFileNamePrefix,
  };
};
