import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./global-settings-query-key-factory";

interface CreateGlobalSettingProps {
  globalSettingCreateDto: any;
}

/**
 * Create a global setting
 *
 * @returns
 */
export const createGlobalSetting = ({ globalSettingCreateDto }: CreateGlobalSettingProps) => {
  return globalSettingCreateDto === null
    ? Promise.reject(new Error("Invalid createbinding dto"))
    : Brm.globalSettingApi.createGlobalSetting({ globalSettingCreateDto });
};

type MutateFunction = typeof createGlobalSetting;
interface ICreateGlobalSettingProps {
  options?: MutationOption<MutateFunction>;
}

/**
 * Create a global setting
 *
 * @returns react-query Mutation.
 */
export const useCreateGlobalSetting = ({ options = {} }: ICreateGlobalSettingProps = {}) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation({
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: KeyFactory.globalSettingKeys.all });
    },
    mutationFn: createGlobalSetting,
  });
};
