import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { SystemApi } from "features/brm";

/**
 * Sets the IsProcessed of an Datatype
 *
 * @param {string} dataTypeId - uuid of the dataType
 * @param {string} isProcessed - new isProcessed of the dataType
 */
export const setDataTypeIsProcessed = ({ dataTypeId, isProcessed }) => {
  return Brm.dataTypeApi.setDataTypeIsProcessed(dataTypeId, { body: isProcessed });
};

/**
 * Custom hook to change the IsProcessed of an existing datatype
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetDataTypeIsProcessed = ({ projectId, config = {} } = {}) => {
  const queryClient = ReactQuery.useQueryClient();

  return ReactQuery.useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(SystemApi.dataTypeKeys.project(projectId));
      queryClient.invalidateQueries(SystemApi.dataTypeKeys.dataType(variables.dataTypeId));
    },
    ...config,
    mutationFn: setDataTypeIsProcessed,
  });
};
