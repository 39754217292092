import styled from "styled-components/macro";

const LayoutStyle = {
  LeftPanel: styled.div`
    grid-area: left-panel;
  `,

  RightPanel: styled.div`
    grid-area: right-panel;
  `,

  Banner: styled.div`
    grid-area: banner;
    background: grey;
  `,

  Contents: styled.div`
    grid-area: content / content / content / content;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex: 1;
  `,

  ContentContainer: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;

    & > :first-of-type {
      width: calc(
        100vw - var(--leftPanelWidth, 0px) - var(--leftSidebarWidth, 40px) - var(--rightPanelWidth, 0px) -
          var(--rightSidebarWidth, 40px)
      ) !important;
    }
  `,
};

export default LayoutStyle;
