import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { SystemApi, useRoles } from "features/brm";
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { userRoles } from "services/brm/admin-service";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";

import { createColumnNameNoNav } from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";

const SystemAssetCategoryTable = ({ setSelectedRows, setRefresh }) => {
  const { isRiskAnalyst, isSystemEngineer, isThreatAnalyst, userRole } = useRoles();
  const projectId = useRecoilValue(projectIdState); // current project Id

  const [systemAssetCategoryData, setSystemAssetCategoryData] = useState([]);
  const updatedName = useRef("");
  const cyberSecurityExpertRole = userRoles.properties[userRoles.CYBERSECURITY_EXPERT].key;
  const [selectedInstance] = useRecoilState(selectedInstanceState);
  const { data, error } = SystemApi.useSystemAssetCategories({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer || isThreatAnalyst) },
  });

  const getNameCell = useCallback(() => {
    return createColumnNameNoNav(selectedInstance.id, false, updatedName);
  }, [selectedInstance.id]);

  const columns = useMemo(() => [getNameCell()], [getNameCell]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    if (data) {
      setSystemAssetCategoryData(data.map((e) => ({ name: e })));
    }
  }, [data]);

  if (error) {
    return <ErrorBanner msg="Error while loading system asset categories" />;
  }

  if (data) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={systemAssetCategoryData}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "SystemAssetCategoryTable_table" }}
            elementName={TYPE.systemAssetCat}
            showRowSelect={userRole === cyberSecurityExpertRole}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

SystemAssetCategoryTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default SystemAssetCategoryTable;
