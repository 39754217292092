import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { Popover } from "react-bootstrap";
import Avatar from "react-avatar";

export const Group = styled.div`
  &::before {
    display: block;
    height: 6px;
    content: "";
  }
  &::after {
    display: block;
    height: 6px;
    content: "";
  }
`;

export const Group2 = styled.div`
  &::before {
    display: block;
    height: 6px;
    content: "";
  }
  &::after {
    display: block;
    height: 6px;
    content: "";
  }
  border-top: 2px solid rgba(9, 30, 66, 0.08);
`;

export const Header = styled.div`
  &:first-of-type {
    margin-top: 14px;
  }

  text-transform: uppercase;
  margin-top: 20px;
  padding: 0px 20px;
  line-height: 1.45455;
  font-size: 11px;
  font-weight: 700;
  color: var(--ds-text-subtle, #5e6c84);
`;

export const ShadowPopover = styled(Popover)`
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
  border: none;
  font-size: 1rem;
  & .popover-arrow {
    visibility: hidden;
  }
`;

const sharedMenuStyle = css`
  display: flex;
  padding: 0px 20px;
  min-height: 40px;
  text-decoration: none;
  width: 100%;
  margin: 0;
  align-items: center;
  &:hover {
    background: #f4f5f7;
    color: currentcolor;
  }
  color: currentcolor;
`;

export const MenuLink = styled(Link)`
  ${sharedMenuStyle}
`;

export const MenuDiv = styled.div`
  ${sharedMenuStyle}
`;

export const IconAfterDiv = styled.div`
  margin-left: 12px;
`;

export const AvatarButton = styled(Avatar)`
  cursor: pointer;
`;
