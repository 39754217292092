import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "components/elements";
// Components
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import ButtonCell from "components/EditComponents/ButtonCell";
import AttackVectorDetails from "brm/system-model/access/details/AttackVectorDetails";
// Services
import { attackVectorApi } from "services/brm/access-service";
import { userRoles } from "services/brm/admin-service";
// Helpers
import handleApi from "services/brm/api-util";
// State
import { userRoleState, projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
// Styles
import Title from "components/elements/StyledTitle";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
// Constants
import { COMMON, TYPE } from "constants/brm";
// Hooks
import useRedirectDetailsPage from "hooks/useRedirectDetailsPage";
// Table Columns
import {
  createColumnCategoryMapped,
  createColumnMappedNoEdit,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
// Queries
import * as ACQueries from "brm/system-model/access/access-queries";
import { exchangeApi, nodeApi } from "services/brm";
import { queryClient } from "libs/react-query";
import { RoutePath } from "routes/route-paths";

function AttackVectorFullDetails() {
  const { id } = useParams();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const userRole = useRecoilValue(userRoleState); // current userRole
  const [selectedElement, setSelectedElement] = useState({});
  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedCategory = useRef("");
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const sysEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
  const riskAnalystRole = userRoles.properties[userRoles.RISK_ANALYST].key;
  let content = null;
  const { data: attackVector, isLoading, isError } = ACQueries.useAttackVectorById(id);
  const { data: attackVectorCategories } = ACQueries.useAttackVectorCategories(projectId);

  const createButton = useCallback(
    (cellProps) => {
      if (userRole === sysEngineerRole || userRole === riskAnalystRole) {
        return (
          <ButtonCell
            selectedRowId={selectedRowId}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedCategory.current !== "") {
                await handleApi(
                  attackVectorApi.setAttackVectorCategoryWithHttpInfo(selectedRowId, {
                    body: updatedCategory.current,
                  })
                );
                updatedCategory.current = "";
              }

              queryClient.invalidateQueries(["attackVectorById"]);
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return COMMON.na;
    },

    [sysEngineerRole, riskAnalystRole, editMode, selectedRowId, userRole]
  );

  const columns = useMemo(
    () => [
      createColumnCategoryMapped("category", selectedRowId, attackVectorCategories, editMode, updatedCategory),
      createColumnMappedNoEdit("node"),
      createColumnMappedNoEdit("exchange"),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [editMode, selectedRowId, attackVectorCategories, createButton]
  );

  useRedirectDetailsPage(projectId, localProjectId, RoutePath.AttackVectors);

  useEffect(() => {
    const getData = async () => {
      const display = attackVector;
      if (attackVector.node) {
        display.node = await nodeApi.getNodeByID(display.node);
      }
      if (attackVector.exchange) {
        display.exchange = await exchangeApi.getExchangeByID(display.exchange);
      }
      setSelectedElement(display);
      setData([display]);
    };

    if (attackVector) {
      getData();
    }
  }, [projectId, id, attackVector]);

  if (isError) {
    content = <>Error while loading Attack Vector details.</>;
  }

  if (isLoading) {
    content = <LoadingSpinner />;
  }

  if (attackVector && data && selectedElement) {
    content = (
      <>
        <Title id="AttackVectorFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "AttackVectorFullDetails_table" }}
              elementName={TYPE.attackVector}
            />
          </DetailsContainerSingleTable>
        ) : (
          <></>
        )}

        {selectedElement.id && (
          <DetailsContainerParent showSummaryTable={showSummaryTable}>
            <DetailsContainer id="AttackVectorFullDetails_detailsPanel">
              <AttackVectorDetails selectedElement={selectedElement} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
  }

  return content;
}

export default AttackVectorFullDetails;
