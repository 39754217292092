import { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useRecoilState, useRecoilValue } from "recoil";

// Atoms
import { runIdState, runIdCanceledSubmissionState } from "atoms/atoms-importer";
import { variantIdState } from "atoms/atoms-component";

// Helpers
import { handleNoteInTooltip } from "utils/report-utils";
import {
  createColumnMappedNoEditRpt,
  createColumnMappedNoEdit,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";

// Components
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import { DownloadForm } from "features/importer";
import systemModals from "components/modals/Modals";

// Constants
import { SIZES /* COMMON */ } from "constants/brm";

// Services
// import { genericImportApi } from "services/brm/importer-service";

import { useRoles } from "features/brm/hooks/useRoles";
import { ErrorBanner, DownloadButton } from "components/elements";
import { ImportApi, useProject, ImportContext } from "features/brm";
import { useSystemImportAssets, useSctmImportAssets, useBorImportAssets } from "./upload-history-details-queries";
import * as S from "./UploadHistoryTable.styles";

const decimal = 2;

export const UploadHistoryTable = ({ uploadModalIsOpen, context }) => {
  const { isRiskAnalyst, isControlsCoordinator } = useRoles();

  const variantId = useRecoilValue(variantIdState);
  const [currentProject] = useProject();
  const [runId, setRunId] = useRecoilState(runIdState);
  const runIdCanceledSubmission = useRecoilValue(runIdCanceledSubmissionState);
  const [uploadHistoryData, setUploadHistoryData] = useState([]);
  const [, setSelectedRowId] = useState("");
  const [, setSelectedItem] = useState("");
  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);
  const [filesToDownload, setFilesToDownload] = useState([]);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const filterOpts = `{ "sctm-variant": "${variantId}" }`;

  const projectId = currentProject?.id;

  // Queries
  const {
    data: systemImportAssetsData,
    isError: systemImportAssetIsError,
    refetch: fetchSystemImportAssets,
  } = useSystemImportAssets(projectId, runId, setFilesToDownload, setDownloadModalIsOpen);
  const {
    data: sctmImportAssetsData,
    isError: sctmImportAssetIsError,
    refetch: fetchSctmImportAssets,
  } = useSctmImportAssets(projectId, runId, setFilesToDownload, setDownloadModalIsOpen);

  const {
    data: borImportAssetsData,
    isError: borImportAssetIsError,
    refetch: fetchBorImportAssets,
  } = useBorImportAssets(projectId, runId, setFilesToDownload, setDownloadModalIsOpen);

  const createButton = useCallback(
    () => (
      <DownloadButton
        onClick={async () => {
          if (isRiskAnalyst) {
            if (context === ImportContext.sctm) {
              fetchSctmImportAssets();
            } else if (context === ImportContext.bor) {
              fetchBorImportAssets();
            }
          } else {
            fetchSystemImportAssets();
          }
        }}
      />
    ),
    [context, fetchBorImportAssets, fetchSctmImportAssets, fetchSystemImportAssets, isRiskAnalyst]
  );

  const sortByTimestamp = useCallback(
    (array) => {
      if (array.length > 0) {
        const filteredObject = array
          .filter((obj) => obj.runId !== runIdCanceledSubmission)
          .reduce((a, b) => {
            const res = a.timestamp > b.timestamp ? a : b;
            return res;
          });
        // console.log("runid: ", filteredObject.runId, filteredObject);
        setRunId(filteredObject.runId);
        setUploadHistoryData([filteredObject]);
      }
    },
    [runIdCanceledSubmission, setRunId]
  );

  const columns = useMemo(
    () => [
      createColumnMappedNoEditRpt("name", handleNoteInTooltip),
      createColumnMappedNoEdit("size"),
      createColumnMappedNoEdit("importer"),
      createColumnMappedNoEdit("uploadStatus"),
      createColumnMappedNoEdit("timestamp"),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [createButton]
  );

  /* USE EFFECT FOR GETTING THE UPLOADED HISTORY OF FILES DATA */
  useEffect(() => {
    async function getData() {
      const projId = currentProject?.id;

      const k = 1024;
      try {
        let data;
        if (context === ImportContext.sctm) {
          data = await ImportApi.getImportHistory({
            projectId: projId,
            context,
            opt: {
              filter: filterOpts,
            },
          });
          // data = await genericImportApi.importHistory(ImportContext.sctm, projId, {
          //   filter: filterOpts,
          // });
        } else if (context === ImportContext.baseline) {
          // Uncomment the call to getImportHistory when the API service is fixed. BRMCD-7119.
          // data = await ImportApi.getImportHistory({ projectId: COMMON.nullUuid, context });
          data = null;
        } else {
          data = await ImportApi.getImportHistory({ projectId: projId, context });
          // data = await genericImportApi.importHistory(context, projId);
        }
        if (data && data.length > 0) {
          // File size converstion
          const finalArray = data.map((each) => {
            const convertedSizeValue = Math.floor(Math.log(each.size) / Math.log(k));
            const toDisplay = each;
            toDisplay.size =
              `${parseFloat((each.size / k ** convertedSizeValue).toFixed(decimal))}` +
              " " +
              `${SIZES[convertedSizeValue]}`;
            toDisplay.timestamp = each.timestamp.toString();
            return toDisplay;
          });

          if (finalArray.length !== 0) {
            if (isControlsCoordinator) {
              // const filteredList = finalArray.filter(
              //   (e) => e.attributes.CONTEXT === "SCTM" && e.attributes["sctm-variant"] === variantId
              // );
              sortByTimestamp(finalArray);
            } else if (isRiskAnalyst) {
              const borList = finalArray.filter((e) => e.importer !== "BOR");
              sortByTimestamp(borList);
            } else {
              const nonSctmList = finalArray.filter((e) => e.importer !== "SCTM");
              sortByTimestamp(nonSctmList);
            }
          }
        } else {
          setRunId("");
          setUploadHistoryData([]);
        }
      } catch (error) {
        console.error("UploadHistory GET: ", error);
      }
    }
    if (projectId || context === ImportContext.baseline) {
      getData();
    }
  }, [
    setUploadHistoryData,
    currentProject,
    uploadModalIsOpen,
    setRunId,
    sortByTimestamp,
    filterOpts,
    variantId,
    context,
    isControlsCoordinator,
    isRiskAnalyst,
    projectId,
  ]);

  const Label = () => {
    const msgMap = {
      [ImportContext.system]: "Model Upload Details:",
      [ImportContext.bor]: "Software Vulnerability (BOR) Upload Details:",
    };
    const msg = msgMap[context] || "File Upload Details:";
    return <S.Title>{msg}</S.Title>;
  };

  return (
    <div id="UploadHistoryDetails_panel" style={{ position: "relative" }}>
      {uploadHistoryData ? (
        <>
          <Label />
          <BrmSingleElementTable
            data={uploadHistoryData}
            columns={columns}
            setSelectedElement={setSelectedItem}
            setSelectedRowId={setSelectedRowId}
            customProps={{ id: "UploadHistory_detailsTable" }}
          />
        </>
      ) : (
        <div>Loading...</div>
      )}

      {downloadModalIsOpen &&
        (systemImportAssetsData || sctmImportAssetsData || borImportAssetsData) &&
        systemModals.customModal(
          "WorkerTable_panel",
          downloadModalIsOpen,
          setDownloadModalIsOpen,
          <DownloadForm
            setDownloadModalIsOpen={setDownloadModalIsOpen}
            filesToDownload={filesToDownload}
            projectId={projectId}
          />,
          "Download"
        )}

      {errorModalIsOpen &&
        (!sctmImportAssetIsError || !systemImportAssetIsError || !borImportAssetIsError) &&
        systemModals.customModal(
          "WorkerTable_panel",
          errorModalIsOpen,
          setErrorModalIsOpen,
          <ErrorBanner msg="Error in Downloading files" />,
          "Download Error"
        )}
    </div>
  );
};

UploadHistoryTable.propTypes = {
  uploadModalIsOpen: PropTypes.bool,
  context: PropTypes.string.isRequired,
};
