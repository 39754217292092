import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

interface IDeleteNode {
  nodeId: string;
}

/**
 * Deletes a node with the given uuid
 * @param {string} nodeId uuid of the node
 * @returns
 */
export const deleteNode = ({ nodeId }: IDeleteNode) => {
  return Brm.nodeApi.deleteNode(nodeId);
};

export interface IUseDeleteNode {
  options?: MutationOption<typeof deleteNode>;
}

/**
 * Custom hook to delete an existing node
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteNode = ({ options }: IUseDeleteNode = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.nodeKeys.all);
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: deleteNode,
  });
};
