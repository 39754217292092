import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./activity-query-key-factory";

interface ISetActivityNodeProps {
  activityId: string;
  nodeId: string;
}

/**
 * Sets node value of an activity
 *
 * @param {string} activityId - uuid of the activity
 * @param {string} nodeId - uuid of selected node
 */
export const setActivityNode = ({ activityId, nodeId }: ISetActivityNodeProps) => {
  return Brm.activityApi.setActivityNode(activityId, { body: nodeId });
};

export interface IUseSetActivityNode {
  options?: MutationOption<typeof setActivityNode>;
}

/**
 * Custom hook to change the node value of an existing activity
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetActivityNode = ({ options }: IUseSetActivityNode) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.activitiesKeys.all);
    },
    ...options,
    mutationFn: setActivityNode,
  });
};
