export const projectKeys = {
  all: ["project"] as const,
  project: (projectId: string | null) => [...projectKeys.all, "project", projectId] as const,
  projectData: (projectId: string | null) => [...projectKeys.all, "projectData", projectId] as const,
  access: (projectId: string | null) => [...projectKeys.all, "access", projectId] as const,
  projectWithOrgName: (projectId: string | null) => [...projectKeys.all, projectId, "withOrgName"] as const,
  adminProjects: (userId: string | null, userRole: string) => [...projectKeys.all, "admin", userId, userRole] as const,
  name: (projectId: string | null) => [...projectKeys.project(projectId), "name"] as const,
  status: (projectId: string | null) => [...projectKeys.project(projectId), "status"] as const,
  statusImport: (projectId: string | null) => [...projectKeys.project(projectId), "statusImport"] as const,
  workers: (projectId: string | null) => [...projectKeys.project(projectId), "workers"] as const,
  readOnlyUsers: (projectId: string | null) => [...projectKeys.project(projectId), "read-only-users"] as const,
  kb: (projectId: string | null, userProjects: any) => [...projectKeys.project(projectId), userProjects, "kb"] as const,
  cntrlCatalog: (userProjects: any) => [...projectKeys.all, userProjects, "cntrlcatalog"] as const,
  projectConf: ["getKbProjectConfiguration"] as const,
  sapPrograms: (projectId: string) => [...projectKeys.project(projectId), "sapprograms"] as const,
  sapSandbox: (projectId: string) => [...projectKeys.project(projectId), "sapsandbox"] as const,
  projectCatalogConf: ["getProjectCatalogConfiguration"] as const,
  property: (projectId: string | null, property: string | null) =>
    [...projectKeys.project(projectId), "property", property] as const,
};
