export const SIONSP = {
  services: "/sionsp_services",
};
/**
 * Socket.io - Service Event prefixes
 */
export const SIO = {
  get: "sioget_",
  req: "sioreq_",
  resp: "sioresp_",
  prog: "sioprog_",
  err: "sioerr_",
  eventReg: "sioeventreg_",
  eventDereg: "sioeventdereg_",
  event: "sioeventdereg_",
  eventDisable: "sioeventdisable_",
  eventEnable: "sioeventenable_",
  joinRoom: "siojoinroom",
  leaveRoom: "sioleaveroom",
};

export const SOCKET_NAMESPACE = {
  reporting: "/sionsp_services",
  admin: "/admin",
  general: "/general",
  brm: "/brm",
};

export const SOCKET_PATH = "/brm/v1/reporting";
