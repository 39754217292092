import styled from "styled-components/macro";

export const FullDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const DetailsContainerSingleTable = styled.div`
  padding: 10px;
  padding-bottom: 20px;
`;

export const ShowSummaryTableButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

export const DetailsContainerParent = styled.div`
  display: flex;
  min-height: 0;
  flex-direction: column;
  flex: 1;

  /* height: ${(props) => (props.showSummaryTable ? "81%" : "88%")}; */
`;

export const PasswordResetDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  padding: 0px 10px 5px 10px;
  /* width: 100%;
  height: inherit; */
  background-color: ${({ theme }) => theme.colors.background};
  margin: 0px;

  .react-tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;

    /* height: 100%; */

    .react-tabs__tab-panel--selected {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;

      /* height: 100%; */
    }
  }
`;
