import { atom } from "recoil";

export const contentTypeState = atom({
  key: "contentType",
  default: "", // default value (aka initial value)
});

export const CONTENT_TYPE = {
  table: "table",
  system: "system",
  context: "context",
  attack: "attack",
  general: "general",
};

export const selectedInstanceState = atom<any>({
  key: "selectedInstanceState",
  default: "",
});

export const nodeDetailTabIndexState = atom<number>({
  key: "nodeDetailTabIndexState",
  default: 0,
});

export const nodeDetailSubTabIndexState = atom<number>({
  key: "nodeDetailSubTabIndexState",
  default: 0,
});

export const filterSearchSubstringState = atom<string | null>({
  key: "filterSearchSubstringState",
  default: null,
});
