import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./person-query-key-factory";

interface ISetPersonIsInternal {
  personId: string;
  isInternal: boolean;
}

/**
 * Sets the isInternal of a person
 *
 * @param {string} personId - uuid of the person
 * @param {string} isInternal - isInternal of the person
 */
export const setPersonIsInternal = ({ personId, isInternal }: ISetPersonIsInternal) => {
  return Brm.personApi.setPersonIsInternal(personId, { body: isInternal });
};

interface IUseSetPersonIsInternal {
  options?: MutationOption<typeof setPersonIsInternal>;
}

/**
 * Custom hook to change the isInternal of an existing person
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetPersonIsInternal = ({ options }: IUseSetPersonIsInternal = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.personKeys.all);
    },
    ...options,
    mutationFn: setPersonIsInternal,
  });
};
