import * as ReactQuery from "@tanstack/react-query";
import { MutationOption /* , queryClient */ } from "libs/react-query";
import * as Brm from "services/brm";
import * as SapProgramKeyFactory from "features/brm/api/admin/sapProgram/sap-program-query-key-factory";
import * as SapBindingKeyFactory from "features/brm/api/admin/sapBinding/sap-binding-query-key-factory";

interface IDeleteSapProjBindingProps {
  bindingId: string | null;
}

/**
 * Delete an sap project binding
 *
 * @returns
 */
export const deleteSapProjBinding = ({ bindingId }: IDeleteSapProjBindingProps) => {
  return bindingId === null
    ? Promise.reject(new Error("Invalid binding id"))
    : Brm.sapProjBindingApi.deleteSapProjBinding(bindingId);
};

interface IDeleteSapProjBindingQueryProps {
  options?: MutationOption<typeof deleteSapProjBinding>;
}

/**
 * Custom hook to Delete an SapProjBinding
 *
 * @returns react-query Mutation.
 */
export const useDeleteSapProjBinding = ({ options }: IDeleteSapProjBindingQueryProps = {}) => {
  const queryClient = ReactQuery.useQueryClient();

  return ReactQuery.useMutation({
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: SapProgramKeyFactory.sapProgramKeys.all });
      queryClient.invalidateQueries({ queryKey: SapBindingKeyFactory.sapBindingKeys.allProject });
    },
    mutationFn: deleteSapProjBinding,
  });
};
