import { COMMON } from "constants/brm";

export function stringToBoolean(stringValue) {
  return stringValue.trim().toLowerCase() === "true";
}

export function booleanInTables(rowValue) {
  if (rowValue !== null && rowValue !== undefined) {
    if (typeof rowValue === "string") return <>{rowValue === "yes" ? COMMON.yes : COMMON.no}</>;
    return <>{rowValue ? COMMON.yes : COMMON.no}</>;
  }
  return COMMON.hyphen;
}
