import { useState } from "react";
import { ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
// Hooks
import { useModal } from "hooks";
// Styles
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import ContentLayout from "brm/layout/Content/ContentLayout";
// Local
import UndesiredEventsTable from "./tables/UndesiredEventsTable";

function UndesiredEvents() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  const ueHeader = (
    <Title id="UndesiredEvents_title">
      Undesired Events
      <Buttons id="UndesiredEvents_buttons">
        <ExportButton onClick={() => toggle()} disableExport={disableExport} />
      </Buttons>
    </Title>
  );

  const ueMain = (
    <>
      <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
      <UndesiredEventsTable
        setSelectedRows={setSelectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
        setExportTableData={setExportTableData}
      />
    </>
  );

  return <ContentLayout pageId="UndesiredEvent_page" header={ueHeader} main={ueMain} />;
}

export default UndesiredEvents;
