import styled from "styled-components";

export const OuterContainer = styled.div`
  /* padding: 0px;
  & .react-select__option {
    padding: 0px;
  } */
`;

export const Container = styled.div`
  /* display: flex; */
  font-size: ${({ theme }) => theme.fonts.default};
  padding: 0;
`;

export const Input = styled.input`
  padding-right: 5px;
`;

export const Label = styled.span`
  padding-left: 5px;
  width: 100%;
  color: black;
  font-weight: ${(props) => (props?.disabled ? "bold" : "normal")};
  text-align: ${(props) => (props?.disabled ? "center" : "left")};
  display: ${(props) => (props?.disabled ? "inline-block" : "default")};
`;
