import { TYPE } from "constants/brm";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import * as Recoil from "recoil";
import { AccordionStyles } from "features/diagram-common";
import { nodesVisibleInAttackGraphState } from "../../stores";
// Components
import AddNodeItem from "./AddNodeItem";
import * as S from "./AddNodeGroup.styles";

const AddNodeGroup = ({ data, type, svg }) => {
  const nodesVisibleInAttackGraph = Recoil.useRecoilValue(nodesVisibleInAttackGraphState);

  const numberInGraph = nodesVisibleInAttackGraph.reduce((prevValue, node) => {
    const exists = data.instances.some((dataInstance) => node === dataInstance.id);
    let m = 0;
    if (exists) {
      m = 1;
    }
    return prevValue + m;
  }, 0);

  return (
    <S.Container>
      <Accordion
        key={data.title}
        defaultActiveKey={type === TYPE.risk && data.instances.some((r) => r.rank?.value === 1) ? data.title : ""}
      >
        <Accordion.Item eventKey={data.title}>
          <AccordionStyles.SecondaryAccordionHeader>
            {data.title} {numberInGraph > 0 && <>({numberInGraph})</>}{" "}
          </AccordionStyles.SecondaryAccordionHeader>
          {/* <Accordion.Button key={data.title} as={Card.Header} eventKey={data.title} style={{ cursor: "pointer" }}>
              {data.title} {numberInGraph > 0 && <>({numberInGraph})</>}
            </Accordion.Button> */}
          {/* <Accordion.Collapse key={`${data.title}_content`} eventKey={data.title}> */}
          <AccordionStyles.MediumBody>
            {data.instances.map((item) => (
              <AddNodeItem key={item.id} data={item} type={type} svg={svg} />
            ))}
          </AccordionStyles.MediumBody>
          {/* </Accordion.Collapse> */}
        </Accordion.Item>
      </Accordion>
    </S.Container>
  );
};
AddNodeGroup.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  svg: PropTypes.string,
};

export default AddNodeGroup;
