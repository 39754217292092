import { useEffect, useMemo } from "react";
// State
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";

import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";

import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { queryClient } from "libs/react-query";
import * as AssetsApi from "brm/risk-model/assets/api";
import { RiskApi, useRoles } from "features/brm";

import {
  createColumnName,
  createColumnMapped,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";

const AssetTable = ({ setSelectedRows, refresh, setRefresh }) => {
  const { isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const { data: assetData, isError: isAssetDataError } = AssetsApi.useAssets(projectId, variantId, {
    enabled: !!projectId && isRiskAnalyst,
  });

  const columns = useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, false, null, "asset"),
      createColumnMapped("category", selectedInstance.id, null, false, null),
      createColumnMappedNoEdit("riskDistributionRank"),
      createColumnMappedNoEdit("riskDistributionPercent"),
    ],
    [setSelectedInstance, selectedInstance.id]
  );

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      queryClient.invalidateQueries(RiskApi.assetKeys.project(projectId));
    }
  }, [refresh, projectId, setRefresh]);

  if (isAssetDataError) {
    return <ErrorBanner msg="Error while loading Asset data." />;
  }

  if (assetData) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={assetData}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "AssetTable_table" }}
            showRowSelect={false}
            elementName={TYPE.asset}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

AssetTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default AssetTable;
