import PropTypes from "prop-types";
import styled from "styled-components";

function DynamicSelectBoxOrgs({ arrayOfData, item, disabled }) {
  let options;
  if (Array.isArray(arrayOfData) && arrayOfData.length) {
    // check if there is any data in the array first
    options = arrayOfData.map((data) => (
      <option key={data.id} value={data.id}>
        {data.name}
      </option>
    ));
  }
  return (
    <Select name={item} id={item} disabled={disabled}>
      <option>Select {item}</option>
      {options}
    </Select>
  );
}

DynamicSelectBoxOrgs.propTypes = {
  arrayOfData: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
  disabled: PropTypes.bool,
  item: PropTypes.string,
};

const Select = styled.select`
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

export default DynamicSelectBoxOrgs;
