import * as ReactQuery from "@tanstack/react-query";
import { BrmError } from "features/brm/errors/BrmError";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./data-type-query-key-factory";

type DatatypeProperty = "isInput" | "isOutput" | "isProcessed" | "IsStored";

interface ISetDatatypeProperty {
  datatypeId: string;
  property: DatatypeProperty;
  value: any;
}

const setDatatypeProperty = ({ datatypeId, property, value }: ISetDatatypeProperty) => {
  if (property === "isInput") {
    return Brm.dataTypeApi.setDataTypeIsInput(datatypeId, { body: value });
  }
  if (property === "isOutput") {
    return Brm.dataTypeApi.setDataTypeIsOutput(datatypeId, { body: value });
  }
  if (property === "isProcessed") {
    return Brm.dataTypeApi.setDataTypeIsProcessed(datatypeId, { body: value });
  }
  if (property === "IsStored") {
    return Brm.dataTypeApi.setDataTypeIsStored(datatypeId, { body: value });
  }
  throw new BrmError("Unsupported Property");
};

type MutationFunc = typeof setDatatypeProperty;
interface IUseSetDatatypeProperty {
  options?: MutationOption<MutationFunc>;
}

export const useSetDatatypeProperty = ({ options }: IUseSetDatatypeProperty = {}) => {
  return ReactQuery.useMutation({
    onSettled: () => {
      queryClient.invalidateQueries(KeyFactory.dataTypeKeys.all);
    },
    ...options,
    mutationFn: setDatatypeProperty,
  });
};
