import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

interface IGetSystemAssetCategories {
  projectId: string;
}

export const getSystemAssetCategories = ({ projectId }: IGetSystemAssetCategories) => {
  return Brm.systemAssetCategoryApi.findSystemAssetCategory(projectId);
};

interface IUseSystemAssetCategories {
  projectId: string;
  options?: QueryOption<typeof getSystemAssetCategories>;
}

/**
 * React-query hook for getting all System Assets Categories
 *
 * @param {string} projectId - uuid of the project
 * @returns react-query for getSystemAssetCategories
 */
export const useSystemAssetCategories = ({ projectId, options }: IUseSystemAssetCategories) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.systemAssetCategoryKeys.project(projectId),
    queryFn: () => getSystemAssetCategories({ projectId }),
  });
};
