import { TYPE } from "constants/brm";
import { useState } from "react";
import parse from "html-react-parser";
import S from "./NotePane.styles";

interface INotePaneProps {
  note: string;
  setNote?: (value: string) => void;
  type: string;
  name: string;
}

const urlify = (text: string) => {
  const expMatch = /(\b(https?|):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  const elementContent = text?.replace(expMatch, '<a target="_blank" href="$1">$1</a>');

  if (elementContent) {
    return parse(elementContent);
  }
  return text;
};

export const NotePane = ({ note, setNote, type, name }: INotePaneProps) => {
  const [invalidMsg, setInvalidMsg] = useState("");

  const getUpdatedText = () => {
    const noteText = (document.getElementsByClassName("noteText")[0] as HTMLElement).innerText;
    if (noteText.match(/^[\w\-\s.():[],]+$/) && noteText.length > 0) {
      // input string include something other than alphanumeric chars, dash, underscore, paranthesis, sq brackets, colon or comma; invalid
      setInvalidMsg("Invalid character(s)");
    } else {
      setInvalidMsg("");
      if (setNote) {
        setNote(noteText);
      }
    }
  };

  return (
    <S.Container>
      <S.Title id="RightSidePanel_selectedNotesTitle">
        {type} - {name}
        <S.Msg title="Valid characters are alphanumeric, period (.), dash (-), or underscore (_)">{invalidMsg}</S.Msg>
      </S.Title>
      <S.Text
        contentEditable={type !== TYPE.attackMechanism && type !== TYPE.threatGroup}
        suppressContentEditableWarning
        id="Notes_text"
        onBlur={getUpdatedText}
        className="noteText"
      >
        {urlify(note)}
      </S.Text>
    </S.Container>
  );
};
