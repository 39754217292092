import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./exchange-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

interface IDeleteExchange {
  exchangeId: string;
}

/**
 * Deletes an exchange with the given id
 *
 * @param {string} exchangeId uuid of exchange to delete
 * @returns
 */
export const deleteExchange = ({ exchangeId }: IDeleteExchange) => {
  return Brm.exchangeApi.deleteExchange(exchangeId);
};

export interface IUseDeleteExchange {
  options?: MutationOption<typeof deleteExchange>;
}

/**
 * Mutation to delete an exchange.
 *
 * @param {object} options - react-query configuration object
 * @returns mutation to delete exchanges
 */
export const useDeleteExchange = ({ options }: IUseDeleteExchange = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.exchangeKeys.all);
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: deleteExchange,
  });
};
