import { useState, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { AddButton, DetailTableType, SystemApi } from "features/brm";
import systemModals from "components/modals/Modals";
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";
import * as S from "brm/styles/details-table.styles";
import { CenterText, ErrorBanner, LoadingSpinner } from "components/elements";
import { RoutePath } from "routes/route-paths";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms";
import EntryPointAddForm from "./forms/EntryPointAddForm";
// Styles

interface IEntryPointProps {
  elementNameInUrl: string;
  selectedElement: {
    id: string;
  };
  showAdd: boolean;
  tableTitle: string;
}

const EntryPointTable = ({ elementNameInUrl, selectedElement, showAdd, tableTitle }: IEntryPointProps) => {
  const { isThreatAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const columns = useMemo(() => {
    if (isThreatAnalyst) {
      return [
        createColumnMappedNoEdit("name"),
        createColumnMappedNoEdit("category"),
        createColumnMappedNoEdit("surface"),
      ];
    }
    return [
      createColumnNameDetails(RoutePath.EntryPoint.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("surface"),
    ];
  }, [isThreatAnalyst]);

  const [, setSelectedEntryPoint] = useState([]);
  const [, setRefresh] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { data: elementEntryPointData, isError: isElementEntryPointDataError } = SystemApi.useElementEntryPoints({
    elementId: selectedElement?.id,
    elementName: elementNameInUrl,
    projectId,
    options: {
      enabled:
        !!selectedElement.id &&
        elementNameInUrl !== DetailTableType.DATATYPES.key &&
        elementNameInUrl !== DetailTableType.MISSIONS.key &&
        elementNameInUrl !== DetailTableType.CAPABILITIES.key &&
        elementNameInUrl !== DetailTableType.DATAFLOWS.key &&
        elementNameInUrl !== DetailTableType.PERSONS.key &&
        elementNameInUrl !== DetailTableType.RESOURCES.key,
    },
  });

  const addEntryPoint = () => {
    setModalIsOpen(true);
  };

  if (
    elementNameInUrl === DetailTableType.DATATYPES.key ||
    elementNameInUrl === DetailTableType.MISSIONS.key ||
    elementNameInUrl === DetailTableType.CAPABILITIES.key ||
    elementNameInUrl === DetailTableType.DATAFLOWS.key ||
    elementNameInUrl === DetailTableType.PERSONS.key ||
    elementNameInUrl === DetailTableType.RESOURCES.key
  ) {
    return <CenterText msg="Entry points are not applicable" />;
  }

  if (isElementEntryPointDataError) {
    return <ErrorBanner msg="Error occured while loading entry points" />;
  }

  if (elementEntryPointData) {
    return (
      <S.DetailsContainer id="EntryPointTable_detailsPanel">
        <S.DetailsTableButtonsContainer>
          {showAdd && <AddButton onClick={addEntryPoint} />}
        </S.DetailsTableButtonsContainer>

        {/* modal for adding instance */}
        {modalIsOpen &&
          systemModals.addModal(
            "EntryPointTable_detailsPanel",
            modalIsOpen,
            setModalIsOpen,
            <EntryPointAddForm
              setModalIsOpen={setModalIsOpen}
              setRefresh={setRefresh}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
            />,
            "Activity"
          )}
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={elementEntryPointData}
              columns={columns}
              setSelectedElement={setSelectedEntryPoint}
              setSelectedRows={() => {}}
              customProps={{ id: "EntryPointTable_detailsTable" }}
              showRowSelect={false}
              tableTitle={tableTitle}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            {showAdd && <AddButton onClick={addEntryPoint} />}
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

export default EntryPointTable;
