import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import PropTypes from "prop-types";
import { REPORT } from "constants/brm";
import { ImporterPages } from "features/importer";
import { DetailTableType, ImportContext } from "features/brm";
import { useRoles } from "features/brm/hooks/useRoles";
import { defaultReportTabState, isReportsTabDefaultState } from "atoms";
import { DynamicSubTabSelector } from "components/elements";
import { DetailsTableMap } from "brm/tables/details/DetailsTableMap";
import { useSap } from "features/admin/hooks";
import { ProjectSapPanel } from "features/admin/components/ProjectSapPanel";
import { ReadOnlyUserDetailsTable } from "../../tables/ReadOnlyUserDetailsTable";
import { WorkerDetailsTable } from "../../tables/WorkerDetailsTable";
import { ProjectConfigurationPanel } from "../../ProjectConfigurationPanel/ProjectConfigurationPanel";

const reportsMenu = [DetailTableType.CWE_RPT, DetailTableType.SYSTEM_CWE_RPT];

export const ProjectDetailsTab = ({ project }) => {
  const { isSuperAdmin, isAdmin, isRiskAnalyst, isSystemEngineer } = useRoles();
  const [selectedReportContext, setSelectedReportContext] = useState(DetailTableType.CWE_RPT.key);
  const elementNameInUrl = DetailTableType.PROJECTS.key;
  const [tabIndex, setTabIndex] = useState(0);
  const [curr, setCurr] = useState("");
  const [isReportstabDefault, setIsReportsTabDefault] = useRecoilState(isReportsTabDefaultState);
  const [defaultReportsTab, setDefaultReportsTab] = useRecoilState(defaultReportTabState);
  const { isEnabled: isSapEnabled } = useSap({ isAdminMode: isAdmin });

  const onSelect = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (isReportstabDefault && isRiskAnalyst) {
      setTabIndex(1);
      if (defaultReportsTab !== DetailTableType.RAR_RPT.key) {
        setSelectedReportContext(defaultReportsTab);
      }
    }
    return () => {
      setIsReportsTabDefault(false);
      setDefaultReportsTab("");
    };
  }, [defaultReportsTab, isReportstabDefault, isRiskAnalyst, setDefaultReportsTab, setIsReportsTabDefault]);

  if (isSystemEngineer) {
    return <ImporterPages projectId={project.id} context={ImportContext.system} />;
  }

  return (
    <Tabs id="ProjectDetails_tabs" selectedIndex={tabIndex} onSelect={onSelect}>
      <TabList id="ProjectDetails_tabList">
        {(isSuperAdmin || isAdmin) && (
          <>
            <Tab>Users</Tab>
            <Tab>Users - Read Only</Tab>
          </>
        )}

        {isRiskAnalyst && (
          <>
            <Tab>BOR Import Details</Tab>
            <Tab onClick={() => setCurr(REPORT.reports)}>
              <DynamicSubTabSelector
                title={REPORT.reports}
                menu={reportsMenu}
                setSelectedContext={setSelectedReportContext}
                curr={curr}
              />
            </Tab>
          </>
        )}

        {(isSuperAdmin || isAdmin || isRiskAnalyst) && (
          <>
            <Tab>Configuration</Tab>
          </>
        )}

        {isSapEnabled ? <Tab>SAP</Tab> : null}
      </TabList>

      {(isSuperAdmin || isAdmin) && (
        <>
          <TabPanel className="ProjectDetails_workerTable">
            <WorkerDetailsTable elementNameInUrl={elementNameInUrl} selectedElement={project} />
          </TabPanel>
          <TabPanel className="ProjectDetails_ReadOnlyTable">
            <ReadOnlyUserDetailsTable elementNameInUrl={elementNameInUrl} project={project} />
          </TabPanel>
        </>
      )}

      {isRiskAnalyst && (
        <>
          <TabPanel>
            <ImporterPages projectId={project.id} context={ImportContext.bor} />
          </TabPanel>

          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={project}
              selectedContext={selectedReportContext}
            />
          </TabPanel>
        </>
      )}

      {(isSuperAdmin || isAdmin || isRiskAnalyst) && (
        <TabPanel className="ProjectDetails_ReadOnlyTable">
          <ProjectConfigurationPanel projectId={project.id} isReadOnly={isRiskAnalyst || isAdmin} />
        </TabPanel>
      )}

      {isSapEnabled ? (
        <TabPanel>
          <ProjectSapPanel projectId={project.id} />
        </TabPanel>
      ) : null}
    </Tabs>
  );
};

ProjectDetailsTab.propTypes = {
  project: PropTypes.object,
};
