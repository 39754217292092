import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

/**
 * Gets the projects
 */
export const getProjects = async () => {
  return Brm.projectApi.findProject();
};

interface IGetProjects {
  options?: QueryOption<typeof getProjects>;
}

/**
 * Custom hook for getting the projects for SUPERADMINS
 *
 * @param {object} config - react-query configuration
 * @returns
 */
export const useProjects = ({ options }: IGetProjects = {}) => {
  return ReactQuery.useQuery({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: KeyFactory.projectKeys.all,
    queryFn: () => getProjects(),
  });
};
