import * as Recoil from "recoil";
import * as React from "react";
import { COMMON } from "constants/brm";
import { isCurrentProjectActiveState, MitigationApi, RiskApi, useProject, useRoles, useVariant } from "features/brm";
import { useRarData } from "brm/reports/rar/rar-report-queries";
// import { autoMitigateState } from "atoms/atoms-risk";
import { sctmIdState } from "atoms/atoms-mitigation";
import toast from "react-hot-toast";
import { RequestTooLargeError } from "services/errors";
import { useImportSctm } from "../../../features/mitigation/hooks/useImportSctm/useImportSctm";

interface INamedElement {
  id: string;
  name: string;
}

interface IOption {
  label: string;
  value: string;
}

export interface IRarReportData {
  placement: any[];
  vulnerabilities: any[];
  component: string;
  level: string;
  statement: string;
  num: string;
  contrib: any;
  threat: any;
  vulnerability: any[];
  magnitude: any;
  mitigations: string[];
  residual: any;
  recommended: string[];
  additional: string[];
}

const allOption = { value: COMMON.nullUuid, label: "ALL" };

export const useRarReport = ({ riskElement }: { riskElement: INamedElement }) => {
  const { isRiskAnalyst } = useRoles();
  const isCurrentProjectActive = Recoil.useRecoilValue(isCurrentProjectActiveState);
  // const autoMigitatedState = Recoil.useRecoilValue(autoMitigateState);
  const sctmId = Recoil.useRecoilValue(sctmIdState);

  // const riskUpdated = Recoil.useRecoilValue(RiskAtom.riskUpdatedState);

  const [currentProject] = useProject();
  const { currentVariant } = useVariant();

  const [isAllocatedControlModalOpen, setIsAllocatedControlModalOpen] = React.useState(false);
  const [dataModalIsOpen, setDataModalIsOpen] = React.useState(false);
  const [exportModalIsOpen, setExportModalIsOpen] = React.useState(false);
  const [vulnerabilityModalIsOpen, setVulnerabilityModalIsOpen] = React.useState(false);
  const [vulnerabilities, setVulnerabilities] = React.useState([]);
  const [selectedContributor, setSelectedContributor] = React.useState(allOption);
  const [contributorsList, setContributorsList] = React.useState([]);
  const [contributorsIdList, setContributorsIdList] = React.useState([]);
  const [highlighted, setHighlighted] = React.useState([]);

  const currentContributor = React.useRef<IOption>();

  const projectId = currentProject?.id || "";
  const variantId = currentVariant?.id || "";

  const [rarReportData, setRarReportData] = React.useState<IRarReportData>();

  const sId = sctmId === null ? "" : sctmId;
  const { data: sctmNameData } = MitigationApi.useSctmName({ sctmId: sId, options: { enabled: sctmId !== null } });

  const { data: riskHasBeenRun } = RiskApi.useRiskCalculated({
    projectId,
    variantId,
    options: {
      enabled: isCurrentProjectActive && currentVariant !== null && isRiskAnalyst,
      refetchOnWindowFocus: false,
    },
  });

  const {
    data: rarData,
    isFetching,
    isError: isRarReportDataError,
    error: rarDataError,
  } = useRarData({
    riskId: riskElement?.id,
    sysElId: selectedContributor?.value,
    variantId,
    config: {
      enabled: !!riskElement?.id && !!selectedContributor?.value && riskHasBeenRun && isRiskAnalyst,
      // &&
      // (autoMigitatedState === "Completed" || autoMigitatedState === "None"),
      onSuccess: (data: any) => {
        if (data?.vulnerability) {
          setVulnerabilities(data.vulnerability);
        }
      },
      refetchOnWindowFocus: false,
    },
  });

  const { importSctm } = useImportSctm({
    onSuccess: () => {},
    onError: (error: any) => {
      if (error instanceof RequestTooLargeError) {
        toast.error("Attempted to add too many controls.  Please try again with a reduced number of controls");
        console.error("SCTM import error");
      }
    },
  });

  const handleNodeSelectChange = (selectedItem: unknown) => {
    currentContributor.current = selectedItem as IOption;
    setSelectedContributor(selectedItem as IOption);
    setHighlighted([]);
  };

  function contribsDisplayStructure(contributors: any) {
    const contribs = contributors.map((c: any) => `${c.name} (${c.level})`);
    return contribs?.join(", ") || "None";
  }

  const showAdditionalContribs = React.useCallback(
    (contributors: any) => {
      const filteredContribs = contributors.filter((x: any) => x.name !== rarReportData?.component);
      return contribsDisplayStructure(filteredContribs);
    },
    [rarReportData]
  );

  React.useEffect(() => {
    if (rarData?.contrib) {
      const options = rarData.contrib.map((so: any) => ({ value: so.id, label: `${so.name} (${so.level})` }));

      if (options.length !== contributorsIdList.length) {
        setContributorsIdList(options.map((o: any) => o.value));
      }

      // if (options.length !== contributorsList.length) {
      // update the label of selected contributor upon data change
      if (currentContributor.current) {
        options.forEach((e: any) => {
          if (currentContributor.current?.value === e.value && currentContributor.current?.label !== e.label) {
            setSelectedContributor(e);
          }
        });
      }
      setContributorsList(options);

      // } else if (currentContributor.current) {
      //   contributorsList.forEach((e: any) => {
      //     if (currentContributor.current?.value === e.value && currentContributor.current?.label !== e.label) {
      //       setSelectedContributor(e);
      //     }
      //   });
      // }
    }
  }, [contributorsIdList.length, rarData, setSelectedContributor]);

  React.useEffect(() => {
    if (rarData) {
      setRarReportData(rarData);
    }
  }, [rarData]);

  /**
   * Set the nodes to highlight on the diagram
   *
   * @param {array} currentNodes and array of node ids to highlight
   */
  const handleHightlightNodes = (currentNodes: any) => {
    if (highlighted.length === 0) {
      setHighlighted(currentNodes);
    } else {
      setHighlighted([]);
    }
  };

  return {
    rarReportData,
    rarDataError,
    isFetchingRarData: isFetching,
    isRarReportDataError,
    isAllocatedControlModalOpen,
    setIsAllocatedControlModalOpen,
    dataModalIsOpen,
    setDataModalIsOpen,
    exportModalIsOpen,
    setExportModalIsOpen,
    vulnerabilityModalIsOpen,
    setVulnerabilityModalIsOpen,
    selectedContributor,
    contributorsList,
    contributorsIdList,
    handleNodeSelectChange,
    contribsDisplayStructure,
    showAdditionalContribs,
    handleHightlightNodes,
    highlighted,
    vulnerabilities,
    sctmNameData,
    importSctm,
  };
};
