import PropTypes from "prop-types";
import { useState, useMemo } from "react";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
import { AddButton, DeleteButton, DetailTableType } from "features/brm";
// Utils
import {
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnNameDetails,
} from "brm/tables/services/column/columnFactory";
// Services
import { capabilityApi } from "services/brm/system-service";
import systemModals from "components/modals/Modals";
// Local
import * as S from "brm/styles/details-table.styles";
import { useRecoilValue } from "recoil";
import { ErrorBanner, LoadingSpinner } from "components/elements";
import { projectIdState } from "atoms/atoms-admin";
import { RoutePath } from "routes/route-paths";
import { useElementDataTypes } from "./api";
import multiSelectDelete from "./utils/DetailTablesUtils";
import DataAddForm from "./forms/DataAddForm";

// Styles
const DataTable = ({ elementNameInUrl, selectedElement, showAdd, showDelete, setRefresh, tableTitle }) => {
  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Datatype.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnBooleanNoEditMapped("isInternal"),
      createColumnBooleanNoEditMapped("isInput"),
      createColumnBooleanNoEditMapped("isOutput"),
      createColumnBooleanNoEditMapped("isStored"),
      createColumnBooleanNoEditMapped("isProcessed"),
    ],
    []
  );

  const [, setSelectedData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const projectId = useRecoilValue(projectIdState);

  const { data: dataTypeData, isError: isDataTypeDataError } = useElementDataTypes({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    config: { enabled: !!selectedElement?.id && !!elementNameInUrl },
  });

  function getDeleteRequest(dataId) {
    switch (elementNameInUrl) {
      case DetailTableType.CAPABILITIES.key: {
        return capabilityApi.removeCapabilityDataWithHttpInfo(selectedElement.id, dataId);
      }
      default: {
        break;
      }
    }
    return undefined;
  }

  const addData = () => {
    setModalIsOpen(true);
  };

  if (isDataTypeDataError) {
    return <ErrorBanner msg="Error while loading DataTypes" />;
  }

  if (dataTypeData) {
    return (
      <S.DetailsContainer id="DataTable_detailsPanel">
        {modalIsOpen &&
          systemModals.addModal(
            "DataTable_detailsPanel",
            modalIsOpen,
            setModalIsOpen,
            <DataAddForm
              setModalIsOpen={setModalIsOpen}
              setRefresh={setRefresh}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
            />,
            "Data"
          )}
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={dataTypeData}
              columns={columns}
              setSelectedElement={setSelectedData}
              setSelectedRows={setSelectedRows}
              customProps={{ id: "DataTable_detailsTable" }}
              showRowSelect={showDelete}
              tableTitle={tableTitle}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            {showDelete && (
              <DeleteButton sm onClick={() => multiSelectDelete(selectedRows, getDeleteRequest, setRefresh)}>
                Delete
              </DeleteButton>
            )}
            {showAdd && (
              <AddButton sm onClick={addData}>
                Add
              </AddButton>
            )}
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

DataTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setRefresh: PropTypes.any,
  showAdd: PropTypes.any,
  showDelete: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default DataTable;
