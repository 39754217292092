import React from "react";
import { getErrorMessage } from "utils/error-message-utils";
// import Recoil from "recoil";
// import { lookupSfpsState } from "atoms/atoms-report";
import * as log from "loglevel";
import { useCsvFileRows } from "../useCsvFile";

interface ISfp {
  sfpName: string;
  sfpCluster: string;
  note: string;
}

interface ISfpObj {
  [key: string]: ISfp | undefined;
}

export const useKbSfps = (csvUrl: string = "/kbs/KB_sfp.csv") => {
  const { data } = useCsvFileRows({ csvUrl });
  const [lookupSfps, setLookupSfps] = React.useState<ISfpObj>();

  React.useEffect(() => {
    if (data) {
      try {
        const rows = data as string[][];
        const csvFileSfps: Partial<ISfpObj> = {};

        rows.forEach((row) => {
          csvFileSfps[row[0]] = {
            sfpName: row[1],
            sfpCluster: row[2],
            note: row[3],
          };
        });

        if (csvFileSfps) {
          setLookupSfps(csvFileSfps);
        }
      } catch (err) {
        log.error(`Unable to load SFPS${getErrorMessage(err)}`);
        setLookupSfps({});
      }
    }
  }, [data, setLookupSfps]);

  return { lookupSfps };
};
