import { cloneDeep } from "lodash";

const updateRiskCounts = (riskData, riskRows) => {
  const riskRowsUpdated = cloneDeep(riskRows);

  riskData.forEach((risk) => {
    // do not show risks with score zero
    const riskLevel = risk.score.value !== 0 ? parseInt(risk.mitigatedRiskLevel, 10) : null;
    // can be 1 - 25
    switch (riskLevel) {
      case 1: // row 1
        riskRowsUpdated.row1.forEach((row = {}) => {
          const myRow = row;
          if (row.location.column === 5) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 3: // row 1
        riskRowsUpdated.row1.forEach((row) => {
          const myRow = row;
          if (row.location.column === 4) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 7: // row 1
        riskRowsUpdated.row1.forEach((row) => {
          const myRow = row;
          if (row.location.column === 3) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 15: // row 1
        riskRowsUpdated.row1.forEach((row) => {
          const myRow = row;
          if (row.location.column === 2) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 20: // row 1
        riskRowsUpdated.row1.forEach((row) => {
          const myRow = row;
          if (row.location.column === 1) {
            myRow.risk.count += 1;
          }
        });
        break;

      case 2: // row 2
        riskRowsUpdated.row2.forEach((row) => {
          const myRow = row;
          if (row.location.column === 5) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 5: // row 2
        riskRowsUpdated.row2.forEach((row) => {
          const myRow = row;
          if (row.location.column === 4) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 9: // row 2
        riskRowsUpdated.row2.forEach((row) => {
          const myRow = row;
          if (row.location.column === 3) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 16: // row 2
        riskRowsUpdated.row2.forEach((row) => {
          const myRow = row;
          if (row.location.column === 2) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 22: // row 2
        riskRowsUpdated.row2.forEach((row) => {
          const myRow = row;
          if (row.location.column === 1) {
            myRow.risk.count += 1;
          }
        });
        break;

      case 4: // row 3
        riskRowsUpdated.row3.forEach((row) => {
          const myRow = row;
          if (row.location.column === 5) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 6: // row 3
        riskRowsUpdated.row3.forEach((row) => {
          const myRow = row;
          if (row.location.column === 4) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 10: // row 3
        riskRowsUpdated.row3.forEach((row) => {
          const myRow = row;
          if (row.location.column === 3) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 17: // row 3
        riskRowsUpdated.row3.forEach((row) => {
          const myRow = row;
          if (row.location.column === 2) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 23: // row 3
        riskRowsUpdated.row3.forEach((row) => {
          const myRow = row;
          if (row.location.column === 1) {
            myRow.risk.count += 1;
          }
        });
        break;

      case 8: // row 4
        riskRowsUpdated.row4.forEach((row) => {
          const myRow = row;
          if (row.location.column === 5) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 11: // row 4
        riskRowsUpdated.row4.forEach((row) => {
          const myRow = row;
          if (row.location.column === 4) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 13: // row 4
        riskRowsUpdated.row4.forEach((row) => {
          const myRow = row;
          if (row.location.column === 3) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 18: // row 4
        riskRowsUpdated.row4.forEach((row) => {
          const myRow = row;
          if (row.location.column === 2) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 24: // row 4
        riskRowsUpdated.row4.forEach((row) => {
          const myRow = row;
          if (row.location.column === 1) {
            myRow.risk.count += 1;
          }
        });
        break;

      case 12: // row 5
        riskRowsUpdated.row5.forEach((row) => {
          const myRow = row;
          if (row.location.column === 5) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 14: // row 5
        riskRowsUpdated.row5.forEach((row) => {
          const myRow = row;
          if (row.location.column === 4) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 19: // row 5
        riskRowsUpdated.row5.forEach((row) => {
          const myRow = row;
          if (row.location.column === 3) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 21: // row 5
        riskRowsUpdated.row5.forEach((row) => {
          const myRow = row;
          if (row.location.column === 2) {
            myRow.risk.count += 1;
          }
        });
        break;
      case 25: // row 5
        riskRowsUpdated.row5.forEach((row) => {
          const myRow = row;
          if (row.location.column === 1) {
            myRow.risk.count += 1;
          }
        });
        break;
      default:
        break;
    }
  });
  return riskRowsUpdated;
};
export default updateRiskCounts;
