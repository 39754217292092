import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./user-query-key-factory";

/**
 * Gets the organization of a user
 * @param {string} userId - uuid of the user
 */
export const getUserPrimaryOrganization = ({ userId }: { userId: string | null }) => {
  return userId === null ? Promise.reject(new Error("Invalid user id")) : Brm.userApi.getUserOrganization(userId);
};

interface IUserOrganizationProps {
  userId: string | null;
  options?: QueryOption<typeof getUserPrimaryOrganization>;
}

/**
 * Custom hook for getting the primary organization shortdto of a user
 *
 * @param {string|null} userId - uuid of user
 * @param {object} options - react-query options
 * @returns
 */
export const useUserPrimaryOrganization = ({ userId, options = {} }: IUserOrganizationProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.userKeys.primaryOrg(userId),
    queryFn: () => getUserPrimaryOrganization({ userId }),
  });
};
