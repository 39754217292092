import { useStableCallback } from "hooks/useStableCallback";
import * as React from "react";

type SetValue<T> = React.Dispatch<React.SetStateAction<T>>;

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === "undefined" ? undefined : JSON.parse(value ?? "");
  } catch {
    console.error("parsing error on", { value });
    return undefined;
  }
}

export const useSessionStorage2 = <T>(key: string, defaultValue: T) => {
  const readValue = React.useCallback(() => {
    if (typeof window === "undefined") {
      return defaultValue;
    }
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? (parseJSON(item) as T) : defaultValue;
    } catch (error) {
      console.warn(`Error reading session storage key "${key}":`, error);
      return defaultValue;
    }
  }, [defaultValue, key]);

  const [storedValue, setStoredValue] = React.useState<T>(readValue);

  const setValue: SetValue<T> = useStableCallback(
    (value: any) => {
      // Prevent build error "window is undefined" but keeps working
      if (typeof window === "undefined") {
        console.warn(`Tried setting sessionStorage key “${key}” even though environment is not a client`);
      }

      try {
        // Allow value to be a function so we have the same API as useState
        const newValue = value instanceof Function ? value(storedValue) : value;

        // Save to session storage
        window.sessionStorage.setItem(key, JSON.stringify(newValue));

        // Save state
        setStoredValue(newValue);

        // // We dispatch a custom event so every useSessionStorage hook are notified
        // window.dispatchEvent(new Event("session-storage"));
      } catch (error) {
        console.warn(`Error setting sessionStorage key “${key}”:`, error);
      }
    },
    [key, storedValue]
  );

  React.useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storedValue, setValue] as const;
};
