// import * as ReactQuery from "react-query";
// import * as Brm from "services/brm";
// import { QueryOption } from "libs/react-query";
// import * as KeyFactory from "./import-query-key-factory";

// interface IGetImportHistory {
//   projectId: string | null;
//   context: any;
// }

// /**
//  *
//  * Gets import history of a project
//  * @param {string} projectId - uuid of project
//  */
// export const getImportHistory = ({ projectId, context }: IGetImportHistory) => {
//   return projectId === null
//     ? Promise.reject(new Error("Invalid projectId"))
//     : Brm.genericImportApi.importHistory(context, projectId);
// };

// interface IImportHistoryProps {
//   projectId: string | null;
//   context: any;
//   options?: QueryOption<typeof getImportHistory>;
// }

// /**
//  * Custom hook for getting import history of project
//  *
//  * @param {string} projectId - uuid of user
//  * @param {string} context - import context
//  *
//  * @param {object} options - react-query options
//  */
// export const useProjectStatus = ({ projectId, context, options = {} }: IImportHistoryProps) => {
//   return ReactQuery.useQuery({
//     ...options,
//     queryKey: KeyFactory.importKeys.context(projectId, context),
//     queryFn: () => getImportHistory({ projectId, context }),
//   });
// };

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { QueryOption } from "libs/react-query";
import { ImportContext, ImportContextValues } from "../../constants";
import * as KeyFactory from "./import-query-key-factory";

interface IImportOptions {
  filter?: string;
}

interface IGetImportHistory {
  projectId: string;
  context: ImportContextValues;
  opt?: IImportOptions;
}

interface ImporterFunctionMap {
  [key: string]: any;
}

export const getImportHistory = async ({ projectId, context, opt }: IGetImportHistory) => {
  const importer: ImporterFunctionMap = {
    [ImportContext.bor]: () => Brm.borImportApi.importHistoryBOR(projectId, opt),
    [ImportContext.baseline]: () => Brm.baselineImportApi.importHistoryBaseline(projectId, opt),
    [ImportContext.savepoint]: () => Brm.savePointImportApi.importHistorySavepoint(projectId, opt),
    [ImportContext.sctm]: () => Brm.sctmImportApi.importHistorySctm(projectId, opt),
    [ImportContext.tra]: () => Brm.traImportApi.importHistoryTra(projectId, opt),
    [ImportContext.system]: () => Brm.systemImportApi.importHistorySystem(projectId, opt),
  };

  if (!Object.keys(importer).some((k) => k === context)) {
    throw new Error(`Unsupported import context: ${context}`);
  }

  return importer[context]();
};

export interface IUseImportHistory {
  projectId: string;
  context: ImportContextValues;
  opt?: IImportOptions;
  options?: QueryOption<typeof getImportHistory>;
}

export const useImportHistory = ({ projectId, context, opt, options }: IUseImportHistory) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.importKeys.history(projectId, context, opt),
    queryFn: () => getImportHistory({ projectId, context, opt }),
  });
};
