import PropTypes from "prop-types";
import { useState } from "react";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import SelectAll from "components/forms/SelectAllReactSelect";
import { FORM_LABEL } from "constants/brm";
import { sortByLabel } from "utils/sorting";
// import filterList from "utils/filter-list";
import { isNotDeletedStatus } from "utils/filter-utils";

import { AdminApi } from "features/brm";
import { LoadingSpinner as Loading } from "components/elements";
import { toast } from "react-hot-toast";
import getUniqueArray from "../../utils/get-unique-array";
// import customDetailStyle from "../../styles/react-select-styles";

function getReactSelectArray(array) {
  const objects = [];
  array.forEach((obj) => {
    objects.push({
      value: obj.id,
      label: obj.username,
    });
  });
  return objects.sort(sortByLabel);
}

export const AddReadOnlyUserForm = ({ setModalIsOpen, selectedElement }) => {
  const [postError, setPostError] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { data: projectWorkers } = AdminApi.useProjectWorkers({ projectId: selectedElement.id });
  const { data: readonlyUsers } = AdminApi.useProjectReadOnlyUsers({ projectId: selectedElement.id });
  const { data: attachedUsers } = AdminApi.useOrganizationAttachedUser({
    organizationId: selectedElement.organization,
  });
  const { data: orgUser } = AdminApi.useOrganizationUser({ organizationId: selectedElement.organization });
  const { mutateAsync: addProjectReadOnlyUser } = AdminApi.useAddProjectReadOnlyUser();

  const handleSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedOptions(items);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (selectedOptions.length > 0) {
      try {
        await Promise.all(
          selectedOptions.map((item) => {
            return addProjectReadOnlyUser({ projectId: selectedElement.id, userId: item.value });
          })
        );
      } catch (error) {
        if (error.status === 403) {
          toast.error(
            `Insufficient permissions to add user to the project. Error: ${error.status} ${error.statusText}`
          );
        } else {
          toast.error(`Unable to add read-only user to the project: ${error.status} ${error.statusText}`);
        }
      }
      setModalIsOpen(false);
    } else {
      setPostError("*Please fill out all fields in the form prior to submitting.");
    }
  }

  if (projectWorkers && readonlyUsers && attachedUsers && orgUser) {
    const allUsers = [...orgUser, ...attachedUsers];
    const uniqueArray = getUniqueArray(allUsers, [...projectWorkers, readonlyUsers]).filter(isNotDeletedStatus);
    const options = getReactSelectArray(uniqueArray);
    return (
      <div id="AddReadOnlyForm">
        <form onSubmit={handleSubmit} action="" id="AddReadOnlyForm_form">
          <FormStyled>
            <div className="form-style" id="AddReadOnlyForm_input">
              <label id="AddReadOnlyForm_selectUser">{FORM_LABEL.users}</label>
              <SelectAll
                // styles={customDetailStyle}
                isMulti
                options={options}
                defaultValue={selectedOptions}
                value={selectedOptions}
                onChange={handleSelectChange}
                allowSelectAll
                allOption={{
                  label: "Select all",
                  value: "*",
                }}
                elementId="AddReadOnlyForm"
              />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="AddReadOnlyForm_cancelButton">
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled type="submit" id="AddReadOnlyForm_addButton">
              Add
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }

  return <Loading />;
};

AddReadOnlyUserForm.propTypes = {
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
    organization: PropTypes.any,
  }),
  setModalIsOpen: PropTypes.func,
};
