import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./attacker-query-key-factory";

export interface ISetAttackerLikelihood {
  attackerId: string;
  likelihood: string;
}

/**
 * sets likelihood of the selected attacker
 * @param {string} attackerId - uuid of the attacker
 * @param {string} likelihood - string value of available means
 *
 */
export const setAttackerLikelihood = ({ attackerId, likelihood }: ISetAttackerLikelihood) => {
  return Brm.attackerApi.setAttackerLikelihood(attackerId, { body: likelihood });
};

export interface IUseSetAttackerLikelihood {
  options?: MutationOption<typeof setAttackerLikelihood>;
}

/**
 * Custom hook to change the likelihood of a existing Attacker
 * @param {object} options hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerLikelihood = ({ options }: IUseSetAttackerLikelihood = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.attackerKeys.all);
    },
    ...options,
    mutationFn: setAttackerLikelihood,
  });
};
