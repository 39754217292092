import { Route, Routes } from "react-router-dom";
import { lazyImport } from "utils/lazy-import";
import { RoutePath } from "./route-paths";

const { SystemDiagram } = lazyImport(() => import("features/diagram-system"), "SystemDiagram");
const { ContextDiagram } = lazyImport(() => import("features/diagram-context"), "ContextDiagram");
const { AttackDiagram } = lazyImport(() => import("features/diagram-attack"), "AttackDiagram");

export const DiagramRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={RoutePath.SystemDiagramRelative} element={<SystemDiagram />} />
        <Route path={RoutePath.AttackDiagramRelative} element={<AttackDiagram />} />
        <Route path={RoutePath.ContextDiagramRelative} element={<ContextDiagram />} />
      </Routes>
    </>
  );
};
