export const getFilterStyle = (theme) => {
  const selectHeight = 25;
  const selectWidth = 45;

  return {
    control: (styles, state) => ({
      ...styles,
      height: selectHeight,
      minHeight: selectHeight,
      width: selectWidth,
      maxWidth: selectWidth,
      fontSize: theme.fonts.small,
      backgroundColor: "#ddddd",
      borderColor: state.isFocused ? "#80bdff" : "#cccccc",
      boxShadow: state.isFocused ? "0 0 0 0.1rem rgb(0 123 255 / 25%)" : styles.boxShadow,
    }),
    container: (styles) => ({
      ...styles,
      height: "100%",
      display: "inline-block",
    }),
    menu: (styles) => ({ ...styles, zIndex: 9999, width: "200px", backgroundColor: "#ddddd" }),
    menuList: (styles) => ({ ...styles, zIndex: 9999, padding: 0, margin: 0, backgroundColor: "#ffffff" }),
    option: (styles, state) => ({
      ...styles,
      padding: "0px 5px",
      backgroundColor: state.isSelected ? "#80bdff" : "#ffffff",
      borderBottom: state.data?.disabled ? "1px solid black" : "none",
    }),
    placeholder: () => ({
      margin: 0,
      width: "auto",
      height: "100%",
      fontSize: theme.fonts.small,
      color: "black",
    }),
    input: () => ({
      margin: 0,
      width: "auto",
      height: selectHeight,
      minHeight: selectHeight,
      fontSize: theme.fonts.small,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      margin: 0,
      padding: 0,
      fontSize: theme.fonts.small,
      color: "black",
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: "1px",
      height: selectHeight,
      minHeight: selectHeight,
      width: "auto",
      flexWrap: "nowrap",
      display: "flex",
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      padding: 0,
      height: selectHeight,
      minHeight: selectHeight,
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      marginTop: "5px",
      marginBottom: "5px",
    }),
    clearIndicator: (styles) => ({
      ...styles,
      padding: 0,
    }),

    singleValue: () => ({
      margin: 0,
      width: "auto",
      height: "auto",
      fontSize: theme.fonts.small,
      color: "black",
    }),
  };
};
