import UserService from "services/brm/user-service";

const API_PATH = "/brm/v1";
const BEARER_AUTH = "BearerAuth";

// update the token before performing the request
async function updateToken(auth) {
  const myAuth = auth;
  await UserService.updateToken();
  myAuth.accessToken = UserService.getToken();
}

export { API_PATH, BEARER_AUTH, updateToken };
