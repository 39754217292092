import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

export const getMission = ({ missionId }) => {
  return Brm.missionApi.getMissionByID(missionId);
};

export const useMission = ({ missionId, config = {} }) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["mission", missionId],
    queryFn: () => getMission({ missionId }),
  });
};
