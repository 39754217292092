import { BrmError, DetailTableType } from "features/brm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * Deletes a ttp with the given uuid of selected element
 * @param {string} ttpId uuid of the ttp
 * @param {string} elementName type/category of selected element
 * @param {string} elementId -uuid of selected element
 * @returns
 */
export const deleteElementTtps = ({ ttpId, elementName, elementId }) => {
  const ttpMap = {
    [DetailTableType.THREAT_GROUPS.key]: (id) => Brm.threatGroupApi.removeThreatGroupTtp(id, ttpId),
    [DetailTableType.ATTACKERS.key]: (id) => Brm.attackerApi.removeAttackerTtp(id, ttpId),
  };

  if (ttpMap[elementName]) {
    return ttpMap[elementName](elementId);
  }

  throw new BrmError(`Unsupported element type: ${elementName}`);
};

/**
 * Custom hook to delete/ remove a ttp for a selected element
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteElementTtps = ({ config = {} } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: deleteElementTtps,
    onSuccess: () => {
      queryClient.invalidateQueries(["elementTtps"]);
    },
  });
};
