import styled from "styled-components/macro";

const ExportWizardStyles = {
  WizardExport: styled.div`
    .wizardInput {
      float: left;
      padding-top: ${({ theme }) => theme.sizes.defaultPadding};
      padding-bottom: ${({ theme }) => theme.sizes.defaultPadding};
      font-size: 1rem;
    }

    .uploadButton {
      float: right;
      padding-right: ${({ theme }) => theme.sizes.defaultPadding};
    }

    .uploadSpinner {
      float: left;
      padding: 10px 0px 0px 80px;
      margin: 10px 5px;
      text-align: center;
      font-size: 16px;
    }
  `,
  ExportLabel: styled.label`
    padding-left: 5px;
  `,
  List: styled.ul`
    list-style: none;
    margin: 0px;
  `,
  ExportMenu: styled.div``,
  DodafWizard: styled.div`
    padding-left: ${({ theme }) => theme.sizes.defaultPadding};
    .wizardTitle {
      text-align: center;
    }
    .inputLabel {
      padding: 0px 3px;
    }
  `,
  WizardResponsePanel: styled.div`
    padding-top: ${({ theme }) => theme.sizes.defaultPadding};
  `,
  DefaultWizard: styled.div`
    .wizardTitle {
      text-align: center;
    }
  `,
  SCTMWizard: styled.div`
    .wizardTitle {
      text-align: center;
    }
  `,
  SCTMStep1: styled.div``,
  SCTMInput: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 2px;
  `,
  SCTMSelect: styled.div`
    padding-left: ${(props) => props.theme.padding};
  `,
  SCTMNodeOptions: styled.div`
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 15px;
    justify-content: center;

    .row: {
      display: flex;

      flex-wrap: no-wrap;
      gap: 12px;
      justify-content: center;
      width: absolute;
    }

    .column: {
      display: flex;
      flex-direction: column;
      width: 50%;
      justify-content: flex-start;
    }
  `,
  Statement: styled.div`
    font: ${({ theme }) => theme.fonts.primary};
    margin-bottom: 5px;
  `,
  SelectionButton: styled.button`
    background-color: ${({ theme }) => theme.primarybuttonbackgroundcolor};
    border-radius: 3px;
    color: white;
    font-family: ${({ theme }) => theme.fonts.primary};
    border: 1px black;
    padding: 5px;
    margin: 2px 8px;
    opacity: ${(prop) => (prop.disabled ? 0.3 : 1)};
    &:hover {
      opacity: ${(prop) => (prop.disabled ? 0.3 : 0.8)};
      cursor: ${(prop) => (prop.disabled ? "auto" : "pointer")};
      background-color: #45a049;
    }
  `,
};

export default ExportWizardStyles;
