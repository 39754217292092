import { useVariant } from "features/brm";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import * as S from "./ManualMitigationSummaryStep.styles";
import { IControlTypeData, IVulnerabilityData } from "../types";

interface ISummaryStepProps {
  vulnerabilities: IVulnerabilityData[];
  controlTypes: IControlTypeData[];
  nodes: any[];
  exchanges: any[];
}

// interface ISummaryStep extends ISummaryProps {
//   updateSelectedVulnerabilities: (selected: VulnerabilitySelection[]) => void;
// }

export const ManualMitigationSummaryStep = ({ vulnerabilities, controlTypes, nodes, exchanges }: ISummaryStepProps) => {
  const { currentVariant } = useVariant();

  // console.log(vulnerabilities, controlTypes, nodes, exchanges);

  function makeList(src: any[]) {
    return (
      <S.ListContainer>
        <ListGroup>
          {src.map((s) => {
            return <ListGroup.Item key={s.name}>{s.name}</ListGroup.Item>;
          })}
        </ListGroup>
      </S.ListContainer>
    );
  }

  return (
    <S.Container>
      <strong>Manual Mitigation Summary:</strong>
      <S.GridContainer>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column lg={2}>
            Variant
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="text" readOnly disabled defaultValue={currentVariant?.name} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column>Vulnerabilities to be mitigated: ({vulnerabilities.length})</Form.Label>
          <Col sm={10}>
            {makeList(vulnerabilities)}

            {/* <Form.Control type="text" readOnly disabled defaultValue={vulnerabilities.length} /> */}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column lg={2}>
            Control Types ({controlTypes.length})
          </Form.Label>
          <Col sm={10}>
            {makeList(controlTypes)}

            {/* <Form.Control type="text" readOnly disabled defaultValue={controlTypes.length} /> */}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column lg={2}>
            Nodes ({nodes.length})
          </Form.Label>
          <Col sm={10}>
            {makeList(nodes)}

            {/* <Form.Control type="text" readOnly disabled defaultValue={nodes.length} /> */}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column lg={2}>
            Exchanges ({exchanges.length})
          </Form.Label>
          <Col sm={10}>
            {makeList(exchanges)}

            {/* <Form.Control type="text" readOnly disabled defaultValue={exchanges.length} /> */}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column lg={2}>
            Controls to be added
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              readOnly
              disabled
              defaultValue={controlTypes.length * (nodes.length + exchanges.length)}
            />
          </Col>
        </Form.Group>
      </S.GridContainer>
    </S.Container>
  );
};
