import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./resource-query-key-factory";
import * as ActivityKeyFactory from "../activity/activity-query-key-factory";

interface IAddResourceActivityProps {
  resourceId: string | any;
  activityId: string;
}

/**
 * Add an activity to resource
 *
 * @returns
 */
export const addResourceActivity = ({ resourceId, activityId }: IAddResourceActivityProps) => {
  return resourceId === null
    ? Promise.reject(new Error("Invalid resource id"))
    : Brm.resourceApi.addResourceActivity(resourceId, { body: activityId });
};

interface IAddResourceActivityQueryProps {
  options?: MutationOption<typeof addResourceActivity>;
}

/**
 * Custom hook to add an activity to resource
 *
 * @returns react-query Mutation.
 */
export const useAddResourceActivity = ({ options }: IAddResourceActivityQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.resourceKeys.all);
      queryClient.invalidateQueries(ActivityKeyFactory.activitiesKeys.resources(variables.activityId));
    },
    ...options,
    mutationFn: addResourceActivity,
  });
};
