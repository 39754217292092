import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import HarmTable from "./tables/HarmTable";

function Harm() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const harmCatHeader = <Title id="HarmList_title">Harm Categories</Title>;

  const harmCatMain = <HarmTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />;

  return <ContentLayout pageId="HarmCategory_page" header={harmCatHeader} main={harmCatMain} />;
}

export default Harm;
