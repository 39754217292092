import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import errorUtils from "utils/error-utils";
import { controlCatalogApi } from "services/brm/global-control-service";
import { createColumnNameDetails } from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { DetailTableType } from "features/brm";
import { RoutePath } from "routes/route-paths";

const ControlFamilyTable = ({ elementNameInUrl, selectedElement }) => {
  const columns = useMemo(() => [createColumnNameDetails(RoutePath.ControlFamily.replace(":id", ""))], []);
  const [controlFamilyData, setControlFamilyData] = useState([]);

  useEffect(() => {
    function getElementRequest() {
      switch (elementNameInUrl) {
        case DetailTableType.CONTROL_CATALOGS.key: {
          return controlCatalogApi.getControlCatalogFamilyWithHttpInfo(selectedElement.id);
        }
        default: {
          break;
        }
      }
      return undefined;
    }

    async function getData() {
      try {
        const res = await getElementRequest();

        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setControlFamilyData(res.data);
        }
      } catch (err) {
        console.error("err in get Control family details : ", err);
      }
    }
    getData();
  }, [elementNameInUrl, selectedElement.id]);

  return (
    <S.DetailsContainer id="ControlFamilyTable_detailsPanel">
      <BrmDetailTable
        data={controlFamilyData}
        columns={columns}
        customProps={{ id: "ControlFamilyTable_detailsTable" }}
        showRowSelect={false}
      />
    </S.DetailsContainer>
  );
};

ControlFamilyTable.propTypes = {
  selectedElement: PropTypes.object.isRequired,
  elementNameInUrl: PropTypes.string.isRequired,
};

export default ControlFamilyTable;
