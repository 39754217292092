import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import { InstallationSapPanel } from "features/admin/components/InstallationSapPanel";
import { useSap } from "features/admin/hooks";
import { InstallationOrganizationDetailsTable } from "../../tables/InstallationOrganizationDetailsTable";
import { InstallationProjectDetailsTable } from "../../tables/InstallationProjectDetailsTable";
import { InstallationUserDetailsTable } from "../../tables/InstallationUserDetailsTable";
import { InstallationConfigurationPanel } from "../../InstallationConfigurationPanel";

interface IInstallationDetailsTab {
  tab?: string | null;
}

export const InstallationDetailsTab = ({ tab }: IInstallationDetailsTab) => {
  const [tabIndex, setTabIndex] = React.useState(tab ? 3 : 0);
  const { isEnabled } = useSap();

  const onSelect = (index: number) => {
    setTabIndex(index);
  };
  return (
    // we only support navigation to the configuration tab right now...
    <Tabs id="InstallationDetails_tabs" selectedIndex={tabIndex} onSelect={onSelect}>
      <TabList id="InstallationDetails_tabList">
        <Tab>Organizations</Tab>
        <Tab>Projects</Tab>
        <Tab>Users</Tab>
        <Tab>Configuration</Tab>
        {isEnabled ? <Tab>SAP</Tab> : null}
      </TabList>

      <TabPanel className="InstallationDetails_organizationPanel" id="InstallationDetails_organizationPanel">
        <InstallationOrganizationDetailsTable />
      </TabPanel>
      <TabPanel>
        <InstallationProjectDetailsTable />
      </TabPanel>
      <TabPanel>
        <InstallationUserDetailsTable />
      </TabPanel>
      <TabPanel>
        <InstallationConfigurationPanel />
      </TabPanel>
      {isEnabled ? (
        <TabPanel>
          <InstallationSapPanel />
        </TabPanel>
      ) : null}
    </Tabs>
  );
};
