import styled from "styled-components/macro";

const contentLayoutStyle = {
  Container: styled.div`
    /* height: 100%;
    width: 100%; */
    flex: 1;
    display: flex;
    margin: 0px;
    padding: 0px;

    flex-direction: column;

    background-color: ${({ theme }) => theme.colors.background};
  `,
  ContentHeader: styled.div`
    top: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background};
    padding: 0px 5px;
  `,
  ContentMain: styled.div`
    /* width: 100%;
    height: 100%; */
    background-color: ${({ theme }) => theme.colors.background};
    padding: 0px 5px;
    margin: 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
  `,
  ContentFooter: styled.div`
    bottom: 0;
    width: 100%;
    height: 0%;
    background-color: ${({ theme }) => theme.colors.background};
    padding: 0px 5px;
  `,
};

export default contentLayoutStyle;
