/* eslint-disable no-param-reassign */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
// Helpers
import { sortByRiskRank, sortByRiskDistributionRank, sortByName } from "utils/sorting";
import { TYPE } from "constants/brm";
import { queryClient } from "libs/react-query";
import { RiskApi, SystemApi } from "features/brm";
import NodeData from "../model/NodeData";
import * as KeyFactory from "./attack-diagram-query-keyfactory";

/**
 *
 * @typedef {Object} RiskShortDto
 * @property name
 * @property id
 */

/**
 * @typedef {Object} RiskByCat
 * @property {string} title - the name of the risk category
 * @property {RiskShortDto[]} instances - an array of risks {@link RiskShortDto}
 */

/**
 * @typedef {Object} AttackByTatic
 * @property {string} title - the name of the risk category
 * @property {AttackShortDto[]} instances - an array of risks {@link AttackShortDto}
 */

/**
 * @typedef {Object} AssetCategoryShortDto
 * @property name
 * @property id
 */

export async function getHasRiskBeenRun(projectId, variantId) {
  if (projectId) {
    const riskData = await Brm.riskApi.findRisk(projectId, { variant: variantId });
    const riskHasBeenRun = await riskData.some((r) => r.rank?.calcValue === 1);
    return riskHasBeenRun;
  }
  return [];
}

export const useRiskHasBeenRun = (projectId, variantId, riskUpdated) => {
  return ReactQuery.useQuery(
    ["riskHasBeenRun", projectId, variantId, riskUpdated],
    () => getHasRiskBeenRun(projectId, variantId),
    {
      refetchOnWindowFocus: false,
    }
  );
};

// const staleTime = 120000;

function getDataByCategory(elementList, categoryList) {
  const data = [];

  categoryList.forEach((cat) => {
    const array = elementList.filter((element) => element.category === cat);
    array.sort(sortByName);
    if (array.length > 0) {
      data.push({ title: cat, instances: array });
    }
  });

  return data;
}

export const getDataWithNonZeroRisks = (data) => {
  const updatedRemoveZeroRank = data.filter((item) => item.riskDistributionRank > 0);

  updatedRemoveZeroRank.sort(sortByRiskDistributionRank);

  const updatedRiskRanking = updatedRemoveZeroRank.map((item) => {
    item.rank = { value: item.riskDistributionRank };
    return item;
  });

  return updatedRiskRanking;
};

/**
 *  Returns Risks organized by category
 *
 * @param {string} projectId the UUID of a project
 * @param {string} variantId the UUID of a variant
 * @returns {RiskByCat[]} Risks grouped by category
 */
const getRisksByCategory = async (projectId, variantId) => {
  const risks = await queryClient.fetchQuery(RiskApi.riskKeys.project(projectId, variantId), () =>
    Brm.riskApi.findRisk(projectId, { variant: variantId })
  );
  const riskCategories = await Brm.riskCategoryApi.findRiskCategory(projectId);

  // remove risks with score of 0
  const nonZeroRisks = risks.filter((r) => r.score.value !== 0);

  const riskByCat = getDataByCategory(nonZeroRisks, riskCategories);
  riskByCat.forEach((riskCat) => {
    riskCat.instances.sort(sortByRiskRank);
  });
  return riskByCat;
};

/**
 *  Returns Attacks organized by tactic
 *
 * @param {string} projectId the UUID of a project
 * @param {string} variantId the UUID of a variant
 * @returns {AttackByTatic[]} Attack grouped by tactic
 */
const getAttacksByTactic = async (projectId, variantId) => {
  const attacks = await queryClient.fetchQuery(RiskApi.attackKeys.attacks(projectId, variantId), () =>
    Brm.attackApi.findAttack(projectId, { variant: variantId })
  );
  const attackTactics = await Brm.attackTacticApi.findAttackTactic(projectId);

  const attackData = [];
  attackTactics.forEach((tactic) => {
    const attackArray = attacks.filter((attack) => attack.tactic === tactic);
    // Only add to the array if there are any attacks associated with the tactic
    if (attackArray.length > 0) {
      attackArray.sort(sortByName);
      attackData.push({ title: tactic, instances: attackArray });
    }
  });
  return attackData;
};

// export const useNodes = (projectId, variantId, config = {}) => {
//   return ReactQuery.useQuery(
//     ["nodes", projectId, variantId],
//     () => SystemApi.getNodesWithRisk(projectId, variantId),
//     config
//   );
// };

const getTargetsWithRiskAsNodeData = async (projectId, variantId) => {
  const nodes = await queryClient.fetchQuery(SystemApi.nodeKeys.project(projectId, variantId), () =>
    Brm.nodeApi.findNode(projectId, { variant: variantId })
  );
  const exchanges = await queryClient.fetchQuery(SystemApi.exchangeKeys.project(projectId, variantId), () =>
    Brm.exchangeApi.findExchange(projectId, { variant: variantId })
  );

  const nonZeroRiskNodes = getDataWithNonZeroRisks(nodes);
  const nonZeroRiskExchangess = getDataWithNonZeroRisks(exchanges);

  const nodeDataNonZeroNodes = nonZeroRiskNodes.map((node) => {
    return new NodeData({
      id: node.id,
      name: node.name,
      type: TYPE.node,
      rank: node.rank,
      category: node.category,
    });
  });
  const nodeDataNonZeroExchanges = nonZeroRiskExchangess.map((node) => {
    return new NodeData({
      id: node.id,
      name: node.name,
      type: TYPE.exchange,
      rank: node.rank,
      category: node.category,
    });
  });
  return [...nodeDataNonZeroNodes, ...nodeDataNonZeroExchanges];
};

export const useTargetsAsNodeData = (projectId, variantId, config = {}) => {
  return ReactQuery.useQuery(
    KeyFactory.attackDiagramKeys.targetsAsNodeData(projectId, variantId),
    () => getTargetsWithRiskAsNodeData(projectId, variantId),
    config
  );
};

export const useRisksByCategory = (projectId, variantId, config = {}) => {
  return ReactQuery.useQuery(
    KeyFactory.attackDiagramKeys.risksByCategory(projectId, variantId),
    () => getRisksByCategory(projectId, variantId),
    config
  );
};

/**
 * Custom hook to get attacks grouped by tactic.
 *
 * @param {string} projectId the UUID of a project
 * @param {string} variantId the UUID of a variant
 * @param {object} config configuration options
 * @returns {AttackByTatic[]} Attack grouped by tactic
 */
export const useAttacksByTactic = (projectId, variantId, config = {}) => {
  return ReactQuery.useQuery(
    KeyFactory.attackDiagramKeys.attacksByTactic(projectId, variantId),
    () => getAttacksByTactic(projectId, variantId),
    config
  );
};

const getThreatEventsByCategory = async (projectId, variantId) => {
  const trevs = await queryClient.fetchQuery(RiskApi.threatEventKeys.project(projectId, variantId), () =>
    Brm.threatEventApi.findThreatEvent(projectId, { variant: variantId })
  );
  const trevCategories = await Brm.threatEventCategoryApi.findThreatEventCategory(projectId);

  return getDataByCategory(trevs, trevCategories);
};

/**
 * Custom hook to find the threat events grouped by category for a project
 *
 * @param {string} projectId UUID of a project
 * @param {string} variantId UUID of a variant
 * @param {object} config configuration options
 * @returns {ThreatEventByCategory[]}
 */
export const useThreatEventsByCategory = (projectId, variantId, config = {}) => {
  return ReactQuery.useQuery(
    KeyFactory.attackDiagramKeys.threatEventsByCategory(projectId, variantId),
    () => getThreatEventsByCategory(projectId, variantId),
    config
  );
};

const getUndesiredEventsByCategory = async (projectId, variantId) => {
  const ues = await queryClient.fetchQuery(RiskApi.undesiredEventKeys.project(projectId, variantId), () =>
    Brm.undesiredEventApi.findUndesiredEvent(projectId, { variant: variantId })
  );
  const harmCategories = await Brm.harmCategoryApi.findHarmCategory(projectId);

  return getDataByCategory(ues, harmCategories);
};

/**
 * Custom hook to find the UndesiredEvents grouped by for a project.
 *
 * @param {string} projectId UUID of a project
 * @param {string} variantId UUID of a variant
 * @param {object} config configuration options
 * @returns {UndesiredEventByCategory[]}
 */
export const useUndesiredEventsByCategory = (projectId, variantId, config = {}) => {
  return ReactQuery.useQuery(
    KeyFactory.attackDiagramKeys.undesiredEventsByCategory(projectId, variantId),
    () => getUndesiredEventsByCategory(projectId, variantId),
    config
  );
};

const getAssetWithRisk = async (assetsByCat) => {
  const assetsByCatWithRisk = assetsByCat.map((obj) => {
    const updatedRemoveZeroRank = obj.instances.filter((asset) => asset.riskDistributionRank > 0);

    updatedRemoveZeroRank.sort(sortByRiskDistributionRank);

    const updatedRiskRanking = updatedRemoveZeroRank.map((asset) => {
      asset.rank = { value: asset.riskDistributionRank };
      return asset;
    });

    return { title: obj.title, instances: updatedRiskRanking };
  });
  return assetsByCatWithRisk;
};

const getAssetsByCategory = async (projectId, variantId) => {
  const assets = await queryClient.fetchQuery(SystemApi.assetKeys.project(projectId, variantId), () =>
    Brm.assetApi.findAsset(projectId, { variant: variantId })
  );
  const assetCategories = await Brm.assetCategoryApi.findAssetCategory(projectId);

  const assetsByCat = getDataByCategory(assets, assetCategories);
  return getAssetWithRisk(assetsByCat);
};

/**
 * Custom Hook to get the assets of a project grouped by category
 *
 * @param {string} projectId UUID of a project
 * @param {string} variantId UUID of a variant
 * @param {object} config configuration options
 */
export const useAssetsByCategory = (projectId, variantId, options = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.attackDiagramKeys.assetsByCategory(projectId, variantId),
    queryFn: () => getAssetsByCategory(projectId, variantId),
  });
};

/**
 * ATTACK GRAPH FILTER QUERIES
 */

// export const useRiskCategories = (projectId) => {
//   return ReactQuery.useQuery(["riskCategories", projectId], () => Brm.riskCategoryApi.findRiskCategory(projectId), {
//     refetchOnWindowFocus: false,
//     staleTime,
//   });
// };

// export const useAttackTactics = (projectId) => {
//   return ReactQuery.useQuery(["attackTactics", projectId], () => Brm.attackTacticApi.findAttackTactic(projectId), {
//     refetchOnWindowFocus: false,
//     staleTime,
//   });
// };

// export const useHarmCategories = (projectId) => {
//   return ReactQuery.useQuery(["harmCategories", projectId], () => Brm.harmCategoryApi.findHarmCategory(projectId), {
//     refetchOnWindowFocus: false,
//     staleTime,
//   });
// };

// export const useTrevCategories = (projectId) => {
//   return ReactQuery.useQuery(
//     ["trevCategories", projectId],
//     () => Brm.threatEventCategoryApi.findThreatEventCategory(projectId),
//     { refetchOnWindowFocus: false, staleTime }
//   );
// };

// Attack Graph Filter Options

// async function getAssetCategorySelect(projectId) {
//   const assetCategories = await Brm.assetCategoryApi.findAssetCategory(projectId);
//   return assetCategories.map((ac) => ({ value: ac, label: ac }));
// }

// export const useAssetCategoriesSelect = (projectId) => {
//   return ReactQuery.useQuery(["assetCategoriesSelect", projectId], () => getAssetCategorySelect(projectId), {
//     refetchOnWindowFocus: false,
//   });
// };

// async function getAssetByCategorySelect(projectId) {
//   const assetsByCategories = await getAssetsByCategory(projectId);
//   return assetsByCategories.map((as) => ({ value: as.title, label: as.title }));
// }

// export const useAssetsByCategorySelect = (projectId) => {
//   return ReactQuery.useQuery(["assetsByCategorySelect", projectId], () => getAssetByCategorySelect(projectId), {
//     refetchOnWindowFocus: false,
//   });
// };

// async function getRiskCategoriesSelect(projectId) {
//   const riskCategories = await Brm.riskCategoryApi.findRiskCategory(projectId);
//   return riskCategories.map((riskCat) => ({ value: riskCat, label: riskCat }));
// }

// export const useRiskCategoriesSelect = (projectId) => {
//   return ReactQuery.useQuery(["riskCategoriesSelect", projectId], () => getRiskCategoriesSelect(projectId), {
//     refetchOnWindowFocus: false,
//   });
// };

// async function getAttackTacticsSelect(projectId) {
//   const attackTactics = await Brm.attackTacticApi.findAttackTactic(projectId);
//   return attackTactics.map((attackTactic) => ({ value: attackTactic, label: attackTactic }));
// }

// export const useAttackTacticsSelect = (projectId) => {
//   return ReactQuery.useQuery(["attackTacticsSelect", projectId], () => getAttackTacticsSelect(projectId), {
//     refetchOnWindowFocus: false,
//   });
// };

// async function getTrevCategoriesSelect(projectId) {
//   const threatEventCategories = await Brm.threatEventCategoryApi.findThreatEventCategory(projectId);
//   return threatEventCategories.map((trevCat) => ({ value: trevCat, label: trevCat }));
// }

// export const useTrevCategoriesSelect = (projectId) => {
//   return ReactQuery.useQuery(["trevCategoriesSelect", projectId], () => getTrevCategoriesSelect(projectId), {
//     refetchOnWindowFocus: false,
//   });
// };

// async function getHarmCategoriesSelect(projectId) {
//   const harmCategories = await Brm.harmCategoryApi.findHarmCategory(projectId);
//   return harmCategories.map((harmCat) => ({ value: harmCat, label: harmCat }));
// }

// export const useHarmCategoriesSelect = (projectId) => {
//   return ReactQuery.useQuery(["harmCategoriesSelect", projectId], () => getHarmCategoriesSelect(projectId), {
//     refetchOnWindowFocus: false,
//   });
// };
