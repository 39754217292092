import { MouseEvent } from "react";

// Styles
import IconStyled from "components/elements/EditIconStyled";
import DownloadIcon from "assets/icons/download.svg";
import PrimaryStyledButton from "components/elements/PrimaryStyledButton";

interface IExportButtonProps {
  buttonTitle?: string;
  disableExport: boolean;
  onClick: (event: MouseEvent) => void;
}

export const ExportButton = ({ buttonTitle = "Export", disableExport, onClick }: IExportButtonProps) => {
  return (
    <span title={!disableExport ? buttonTitle : "Nothing to export"}>
      <PrimaryStyledButton id="ExportButton" onClick={onClick} disabled={disableExport}>
        <IconStyled id="ExportButton_icon" src={DownloadIcon} alt="download" />
      </PrimaryStyledButton>
    </span>
  );
};
