import { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import { LoadingSpinner } from "components/elements";
import Title from "components/elements/StyledTitle";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { riskUpdatedState } from "atoms/atoms-risk";
import { variantIdState } from "atoms/atoms-component";
import { createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
import * as VulnerabilityApi from "brm/risk-model/vulnerability/api";
import { TYPE } from "constants/brm";
import { RoutePath } from "routes/route-paths";
import VulnerabilityDetails from "../details/VulnerabilityDetails";

const listUrl = RoutePath.Vulnerabilities;

function VulnerabilityFullDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [, /* selectedRowId */ setSelectedRowId] = useState("");
  const riskUpdated = useRecoilValue(riskUpdatedState);
  const variantId = useRecoilValue(variantIdState);
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const {
    data: vulnerabilityById,
    isLoading,
    isError,
  } = VulnerabilityApi.useGetVulnerabilityById(id, variantId, riskUpdated, { enabled: !!projectId });

  let content = null;

  const columns = useMemo(
    () => [createColumnMappedNoEdit("category"), createColumnMappedNoEdit("vulLevelCalcStringValue")],
    []
  );

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(listUrl);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    if (vulnerabilityById) {
      setSelectedElement(vulnerabilityById);
      setData([vulnerabilityById]);
    }
  }, [vulnerabilityById]);

  if (isError) {
    content = <>An error occurred while retrieving Vulnerability data.</>;
  }

  if (isLoading) {
    content = <LoadingSpinner />;
  }

  if (vulnerabilityById) {
    content = (
      <>
        <Title id="VulnerabilityFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "VulnerabilityFullDetails_table" }}
              elementName={TYPE.vulnerability}
            />
          </DetailsContainerSingleTable>
        ) : (
          <></>
        )}

        {selectedElement.id && (
          <DetailsContainerParent showSummaryTable={showSummaryTable}>
            <DetailsContainer id="Vulnerability_detailsPanel">
              <VulnerabilityDetails selectedElement={selectedElement} setRefresh={setRefresh} refresh={refresh} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
  }
  return content;
}

export default VulnerabilityFullDetails;
