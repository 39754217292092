import PropTypes from "prop-types";
import styled from "styled-components";

const InLineSelectBox2 = ({ arrayOfData, item, value, updateValues }) => {
  let options;
  if (arrayOfData !== undefined && arrayOfData.length > 0) {
    options = arrayOfData
      .filter((d) => d.name !== value)
      .map((data) => (
        <option key={data.id} value={data.id}>
          {data.name}
        </option>
      ));
  }

  const handleFieldChange = (e) => {
    updateValues(e.target.value);
  };

  return (
    <Select name={item} id={item} onBlur={handleFieldChange}>
      {/* {name !== undefined ? <option value="">{value}</option> : <option value="">Select {item}</option>} */}
      {value === undefined ? <option value="">Select {item}</option> : <option value="">{value}</option>}
      {options}
    </Select>
  );
};

InLineSelectBox2.propTypes = {
  arrayOfData: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
    })
  ),
  item: PropTypes.string.isRequired,
  // name: PropTypes.string,
  value: PropTypes.any,
  updateValues: PropTypes.func,
};

const Select = styled.select`
  width: 100%;
`;

export default InLineSelectBox2;
