import { queryClient } from "libs/react-query";
import PropTypes from "prop-types";
import toast from "react-hot-toast";

import * as BrmGql from "generated/graphql";
import { RiskApi } from "features/brm";
// state
import { variantIdState } from "atoms/atoms-component";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
// Components
import systemModals from "components/modals/Modals";
// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import * as S from "../styles/modal-styles";

export const ClearImpactLevelModal = ({ modalIsOpen, setModalIsOpen, selectedRows, hideDisabledUes }) => {
  const variantId = useRecoilValue(variantIdState);
  const projectId = useRecoilValue(projectIdState);
  const { mutate: setUndesiredEventImpact } = BrmGql.useSetUndesiredEventImpactMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(
        RiskApi.undesiredEventKeys.impactCalibrationUEs(projectId, variantId, hideDisabledUes)
      );
    },
    onError: () => {
      toast.error("Error ocurred upon clearing calibrated impact", {
        duration: 4000,
      });
      setModalIsOpen(false);
    },
  });

  async function setLevelClick() {
    const ueIds = selectedRows.map((each) => each.id);
    setUndesiredEventImpact(
      { ids: ueIds, project: projectId, variant: variantId, calibratedStringValue: "unset" },
      {
        onSuccess: () => {
          toast.success("Calibrated impact cleared successfully", { duration: 4000 });
          setModalIsOpen(false);
        },
        enabled: !!projectId && !!variantId && !!ueIds,
      }
    );
  }

  const handleCancel = () => {
    setModalIsOpen(false);
  };

  const body = (
    <>
      <S.BodyDiv>
        Are you sure you want to clear the calibrated impact levels of the selected Undesired Event(s)?
      </S.BodyDiv>

      <DialogButtonDivStyled>
        <DialogButtonStyled id="ImpactLevelModal_cancelButton" onClick={handleCancel}>
          Cancel
        </DialogButtonStyled>
        <DialogButtonStyled id="ImpactLevelModal_confirmButton" onClick={() => setLevelClick()}>
          Confirm
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </>
  );

  return (
    <div id="ClearImpactLevelModal_modal">
      {systemModals.customModal(
        "ClearImpactLevelModal_modal",
        modalIsOpen,
        setModalIsOpen,
        body,
        "Clear Caliberated Impact Level"
      )}
    </div>
  );
};

ClearImpactLevelModal.propTypes = {
  modalIsOpen: PropTypes.any,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
  setModalIsOpen: PropTypes.func,
  hideDisabledUes: PropTypes.bool,
};
ClearImpactLevelModal.defaultProps = {};
