/**
 * Details Table related styles
 *
 */
import styled from "styled-components/macro";

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const DetailsTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DetailsTableButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const RuleButton = styled.button`
  background: none;
  outline: none;
  border: none;
  color: blue;

  &:focus {
    outline: none;
  }

  &:hover {
    color: blue;
    text-decoration: underline;
  }
  .p {
    color: blue;
  }
`;

export const RuleTitle = styled.h1`
  color: ${({ theme }) => theme.colors.title};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.3em;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
`;

export const RuleTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DetailButtonTopCorner = styled.div`
  position: absolute;
  display: flex;
  gap: 10px;
  right: 5px;
`;
