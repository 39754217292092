import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * sets sa type of the selected attacker
 * @param {string} id - uuid of the attacker
 * @param {string} saType - uuid of system asset type
 *
 */
export const setAttackerSaType = ({ id, saType }) => {
  return Brm.attackerApi.setAttackerSatype(id, { body: saType });
};

/**
 * Custom hook to change the sa type of a existing Attacker
 * @param {object} config hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerSaType = ({ config = {} }) => {
  return ReactQuery.useMutation({
    ...config,
    mutationFn: setAttackerSaType,
  });
};
