import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";

interface ISetControlTypeImplGuidance {
  controlTypeId: string;
  impl: any;
}

/**
 * function performing api call to set impl guidance of control type
 *
 * @param {string} cntrlTypeId - uuid of the selected control type
 * @param {string} impl - user entered impl guidance value
 * @returns object of control type
 */
export const setControlTypeImplGuidance = ({ controlTypeId, impl }: ISetControlTypeImplGuidance) => {
  return Brm.controlTypeApi.setControlTypeImplGuidance(controlTypeId, { body: impl });
};

interface IUseSetControlTypeImplGuidance {
  options?: MutationOption<typeof setControlTypeImplGuidance>;
}

/**
 * Custom hook to change impl Guidance of a existing control type
 *
 * @param {object} options - configuration of react-query
 * @returns react-query ReactQuery.useMutation
 */
export const useSetControlTypeImplGuidance = ({ options = {} }: IUseSetControlTypeImplGuidance) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: setControlTypeImplGuidance,
  });
};
