/* eslint-disable no-param-reassign */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./asset-query-key-factory";

export const getAssets = async ({ projectId, variantId }: { projectId: string; variantId: string }) => {
  return Brm.assetApi.findAsset(projectId, { variant: variantId });
};

interface IUseAssets {
  projectId: string;
  variantId: string;
  options?: QueryOption<typeof getAssets>;
}

export const useAssets = ({ projectId, variantId, options }: IUseAssets) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.assetKeys.project(projectId, variantId),
    queryFn: () => getAssets({ projectId, variantId }),
  });
};
