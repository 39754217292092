import { useState, useEffect } from "react";

import { useRoles } from "features/brm/hooks/useRoles";
import { useRecoilValue } from "recoil";
import { contentKeyState } from "atoms";

import {
  AdminHelpContents,
  CntrlCoordinatorHelpContents,
  CyberSecurityHelpContents,
  RiskAnalystHelpContents,
  SuperAdminHelpContents,
  SystemEngHelpContents,
  ThreatAnalystHelpContents,
} from "features/help/constants";

interface ContentObj {
  name: string;
  filePath: string;
}

interface Contents {
  contents: ContentObj[];
}

const riskAnalystContents: { [key: string]: Contents } = RiskAnalystHelpContents;
const systemEngContents: { [key: string]: Contents } = SystemEngHelpContents;
const threatAnalystContents: { [key: string]: Contents } = ThreatAnalystHelpContents;
const adminContents: { [key: string]: Contents } = AdminHelpContents;
const superAdminContents: { [key: string]: Contents } = SuperAdminHelpContents;
const cntrlCoordinatorContents: { [key: string]: Contents } = CntrlCoordinatorHelpContents;
const cseContents: { [key: string]: Contents } = CyberSecurityHelpContents;

export const useVideoContent = () => {
  const {
    isAdmin,
    isSuperAdmin,
    isSystemEngineer,
    isControlsCoordinator,
    isRiskAnalyst,
    isThreatAnalyst,
    isCyberSecurityExpert,
  } = useRoles();
  const contentKey = useRecoilValue(contentKeyState);
  const [contentsData, setContentsData] = useState<undefined | ContentObj[]>();

  useEffect(() => {
    if (isAdmin && contentKey) {
      setContentsData(() => adminContents[contentKey]?.contents);
    } else if (isSuperAdmin && contentKey) {
      setContentsData(() => superAdminContents[contentKey]?.contents);
    } else if (isSystemEngineer && contentKey) {
      setContentsData(() => systemEngContents[contentKey]?.contents);
    } else if (isThreatAnalyst && contentKey) {
      setContentsData(() => threatAnalystContents[contentKey]?.contents);
    } else if (isCyberSecurityExpert && contentKey) {
      setContentsData(() => cseContents[contentKey]?.contents);
    } else if (isControlsCoordinator && contentKey) {
      setContentsData(() => cntrlCoordinatorContents[contentKey]?.contents);
    } else if (isRiskAnalyst && contentKey) {
      setContentsData(() => riskAnalystContents[contentKey]?.contents);
    } else setContentsData(undefined);
  }, [
    contentKey,
    isAdmin,
    isControlsCoordinator,
    isCyberSecurityExpert,
    isRiskAnalyst,
    isSuperAdmin,
    isSystemEngineer,
    isThreatAnalyst,
  ]);

  return contentsData;
};
