/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import * as React from "react";
import { useSetRecoilState } from "recoil";
import { selectedInstanceState } from "atoms/atoms-content";
// Services
import userRoles from "services/brm/user-roles";

// Hooks
import { useRoles } from "features/brm/hooks/useRoles";
// Local
import { Filter } from "./BrmTableFilter";
// Styles
import * as S from "./styles/tables.styles";

const SUPERADMIN = userRoles.properties[userRoles.SUPER_ADMIN].key;
const ADMIN = userRoles.properties[userRoles.ADMIN].key;

export const BrmDetailTableView = ({
  hasData,
  prepareRow,
  headerGroups,
  selectedFlatRows,
  setSelectedElement,
  setSelectedRows,
  customProps,
  showHeaderFilters,
  getTableProps,
  getTableBodyProps,
  page,
  visibleColumns,
  elementName = "",
}) => {
  const { isSuperAdmin, isAdmin } = useRoles();
  const setSelectedInstance = useSetRecoilState(selectedInstanceState);

  function selectRow(row) {
    if (setSelectedElement) {
      setSelectedElement(row);
    }
    if (row.name && elementName) {
      setSelectedInstance({
        id: row.id,
        name: row.name,
        type: elementName,
      });
    } else if (row.username && elementName) {
      setSelectedInstance({
        id: row.id,
        name: row.username,
        type: elementName,
      });
    }
  }

  React.useEffect(() => {
    if (setSelectedRows && hasData) {
      try {
        const selectedRows = selectedFlatRows.reduce((acc, flatRow) => {
          if (
            (isSuperAdmin && flatRow.original.id === SUPERADMIN) ||
            (isAdmin && (flatRow.original.id === SUPERADMIN || flatRow.original.id === ADMIN))
          ) {
            return acc;
          }
          acc.push(flatRow.original);
          return acc;
        }, []);
        setSelectedRows(selectedRows);
      } catch (err) {
        console.error("Error in obtaining selected rows ", err);
      }
    }
  }, [isAdmin, isSuperAdmin, selectedFlatRows, setSelectedRows, hasData]);

  return (
    <S.Table {...getTableProps(customProps)}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr key="headerGroup" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th key="header" {...column.getHeaderProps()}>
                {/* the column.getSortByToggleProps will sort by column headings */}
                <div {...column.getSortByToggleProps()}>{column.render("Header")}</div>
                {showHeaderFilters && <Filter column={column} />}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <tbody {...getTableBodyProps()}>
        {(page.length > 0 &&
          page.map((row) => {
            prepareRow(row);
            return (
              <tr
                key="tableRow"
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...row.getRowProps()}
                onClick={() => selectRow(row.original)}
                style={row.isSelected ? { backgroundColor: "#D1EAFE" } : { backgroundColor: "#FFFFFF" }}
              >
                {row.cells.map((cell) => (
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  <td key="tableCell" {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })) || (
          <tr>
            <td className="noDataFound" colSpan={visibleColumns.length}>
              No data found...
            </td>
          </tr>
        )}
      </tbody>
    </S.Table>
  );
};

BrmDetailTableView.propTypes = {
  prepareRow: PropTypes.func.isRequired,
  headerGroups: PropTypes.any.isRequired,
  selectedFlatRows: PropTypes.any.isRequired,
  customProps: PropTypes.shape({
    id: PropTypes.string,
  }),
  getTableProps: PropTypes.func.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  page: PropTypes.any.isRequired,
  visibleColumns: PropTypes.any.isRequired,
  setSelectedElement: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  showHeaderFilters: PropTypes.bool.isRequired,
  elementName: PropTypes.string,
  hasData: PropTypes.bool,
};
