import { useState, useEffect } from "react";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import { userRoles } from "services/brm/admin-service";
import { AddButton, DeleteButton, SystemApi } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { activityApi } from "services/brm/system-service";
import { TYPE, CRUD_EVENT } from "constants/brm";
import systemModals from "components/modals/Modals";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { useRoles } from "features/brm/hooks/useRoles";
import { queryClient } from "libs/react-query";
import ActivityTable from "./tables/ActivityTable";
import ActivityForm from "./forms/ActivityAddForm";
import Event from "./crudEvent";

function Activity() {
  const { isSystemEngineer } = useRoles();
  const sysEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const addActivity = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      const promises = selectedRows.map((each) => activityApi.deleteActivityWithHttpInfo(each.id));
      Promise.all(promises)
        .then(() => {
          queryClient.invalidateQueries(SystemApi.activitiesKeys.all);
          setDeleteModalIsOpen(false);
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, selectedRows, setSelectedInstance]);

  const activityHeader = (
    <Title>
      Activities
      <Buttons>
        <Event constant={CRUD_EVENT.activity} setRefresh={setRefresh} />
        {isSystemEngineer ? (
          <>
            {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
            <AddButton onClick={addActivity} />
          </>
        ) : (
          <br />
        )}
      </Buttons>
    </Title>
  );

  const activityMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "Activity_Page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.activity}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.activity
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "Activity_Page",
          modalIsOpen,
          setModalIsOpen,
          <ActivityForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} />,
          TYPE.activity
        )}

      <ActivityTable
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
        accessibleRole={sysEngineerRole}
      />
    </>
  );

  return <ContentLayout pageId="Activity_Page" header={activityHeader} main={activityMain} />;
}

export default Activity;
