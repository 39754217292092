import { userRoleState } from "atoms/atoms-admin";
import * as React from "react";
import { useNavigate, useLocation, useNavigationType } from "react-router-dom";
import { useRecoilValue } from "recoil";
import * as PrevNextStyle from "./PrevNextButton.styles";

const PrevNextButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const userRole = useRecoilValue(userRoleState);

  const [start, setStart] = React.useState(location.pathname);
  const [end, setEnd] = React.useState();

  const [isPreviousDisabled, setIsPreviousDisabled] = React.useState(true);
  const [isNextDisabled, setIsNextDisabled] = React.useState(true);

  const goToPreviousPath = () => {
    navigate(-1);
  };

  const goToNextPath = () => {
    navigate(1);
  };

  React.useEffect(() => {
    function navControls() {
      if ((start === "/" && location.pathname !== "/") || !start) {
        setStart(location.pathname);
      }
      if ((start !== "/" && location.pathname !== start) || !start) {
        setIsPreviousDisabled(false);
      } else setIsPreviousDisabled(true);
    }
    if (!isPreviousDisabled && navigationType === "PUSH") {
      setEnd(location.pathname);
    }
    if (end && end !== location.pathname) {
      setIsNextDisabled(false);
    }
    if (end && end === location.pathname) {
      setIsNextDisabled(true);
    }
    navControls();
  }, [end, navigationType, isPreviousDisabled, location.pathname, start]);

  React.useEffect(() => {
    if (userRole) {
      setStart(null);
      setEnd(null);

      setIsPreviousDisabled(true);
      setIsNextDisabled(true);
    }
  }, [userRole]);

  return (
    <PrevNextStyle.ButtonArea id="PrevNextButton">
      <PrevNextStyle.Button onClick={goToPreviousPath} id="PrevNextButton_previousButton" disabled={isPreviousDisabled}>
        Previous
      </PrevNextStyle.Button>
      <PrevNextStyle.Button onClick={goToNextPath} id="PrevNextButton_nextButton" disabled={isNextDisabled}>
        Next
      </PrevNextStyle.Button>
    </PrevNextStyle.ButtonArea>
  );
};

export default PrevNextButton;
