import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { Outlet } from "react-router-dom";

import { PageSpinner } from "components/elements/PageSpinner";
// import { Header } from "features/header";
import { AccountHeader } from "features/header";
import { Footer } from "features/footer";
import { ErrorFallback } from "components/error";

import * as S from "./MainLayout.styles";

export const AccountLayout = () => {
  const HeaderErrorFallback = ({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) => {
    return (
      <Alert variant="danger">
        <Alert.Heading>
          Something went wrong: {error.message}.
          <Button size="sm" variant="link" onClick={resetErrorBoundary}>
            Try Again
          </Button>
        </Alert.Heading>
      </Alert>
    );
  };

  return (
    <S.AppContainer>
      <S.LayoutContainer>
        <React.Suspense
          fallback={
            <div>
              <PageSpinner text="Loading BRM Data..." />
            </div>
          }
        >
          <S.TopNavigation>
            <ErrorBoundary FallbackComponent={HeaderErrorFallback} onReset={() => {}}>
              <AccountHeader />
            </ErrorBoundary>
          </S.TopNavigation>
          <S.MainLayoutContainer>
            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
              <Outlet />
            </ErrorBoundary>
          </S.MainLayoutContainer>
          <S.Footer>
            <Footer />
          </S.Footer>
        </React.Suspense>
      </S.LayoutContainer>
    </S.AppContainer>
  );
};
