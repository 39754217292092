import { AiOutlineExport } from "react-icons/ai";
import SecondaryButton from "components/elements/SecondaryStyledButton";

import { MouseEvent } from "react";

interface IRequestButtonProps {
  buttonTitle?: string;
  disableBorrow: boolean;
  onClick: (event: MouseEvent) => void;
}

export const BorrowRequestButton = ({
  buttonTitle = "License Borrow",
  disableBorrow,
  onClick,
}: IRequestButtonProps) => (
  <span title={buttonTitle}>
    <SecondaryButton id="IRequestButtonProps_button" onClick={onClick} disabled={disableBorrow}>
      <AiOutlineExport />
    </SecondaryButton>
  </span>
);
