import { AiOutlineFileSync } from "react-icons/ai";
import { InlineButton } from "features/brm";

interface IRestoreFromFileButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export const RestoreFromFileButton = ({ onClick, disabled = false }: IRestoreFromFileButtonProps) => {
  return (
    <InlineButton
      title="Restore project from file"
      id="RestoreFromFileButton_button"
      onClick={onClick}
      disabled={disabled}
    >
      <AiOutlineFileSync size="16" />
    </InlineButton>
  );
};
