import { Spinner } from "react-bootstrap";
import * as S from "./LoadingSpinner.style";

export const LoadingSpinner = ({ message = "Loading..." }: { message?: string }) => {
  return (
    <S.SpinnerContainer>
      <Spinner animation="border" size="sm" variant="secondary" role="status" aria-hidden="true" />
      <S.SpinnerMessageSpan className="sr-only">{message}</S.SpinnerMessageSpan>
    </S.SpinnerContainer>
  );
};
