import Cancel from "assets/icons/cancelModal.svg";
import ReactModal from "react-modal";
import { MergeBaselineForm } from "features/global-control/components/forms/MergeBaselineForm";
import { toast } from "react-hot-toast";
import { useMergeBaselineModalApi } from "./useMergeBaselineModalApi";
import * as S from "./MergeBaselineModal.styles";

interface IMergeBaselineModal {
  currentBaselineId: string;
  isVisible: boolean;
  onClose: () => void;
}

export const MergeBaselineModal = ({ currentBaselineId, isVisible, onClose }: IMergeBaselineModal) => {
  const { mergeBaseline, mergeBaselineError } = useMergeBaselineModalApi();

  const handleSubmit = ({ baselineId }: { baselineId: string }) => {
    mergeBaseline({ id: currentBaselineId, baseline: baselineId });
  };

  if (mergeBaselineError) {
    toast.error(`Error: ${mergeBaselineError}`);
  }

  return (
    <ReactModal
      isOpen={isVisible}
      onAfterClose={onClose}
      ariaHideApp={false}
      onRequestClose={onClose}
      style={S.rootModalStyle}
    >
      <S.Header>
        <S.ModalTitle>Merge Baseline</S.ModalTitle>
        <S.HeaderButtonsDiv>
          <S.Close onClick={onClose}>
            <S.CloseIcon src={Cancel} />
          </S.Close>
        </S.HeaderButtonsDiv>
      </S.Header>
      <S.Body>
        <MergeBaselineForm currentBaselineId={currentBaselineId} onSubmit={handleSubmit} onClose={onClose} />
      </S.Body>
    </ReactModal>
  );
};
