import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./capability-query-key-factory";
import * as ActivityKeyFactory from "../activity/activity-query-key-factory";

interface IAddCapabilityActivityProps {
  capabilityId: string | null;
  activityId: string;
}

/**
 * Add a capability from a node
 *
 * @returns
 */
export const addCapabilityActivity = ({ capabilityId, activityId }: IAddCapabilityActivityProps) => {
  return capabilityId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.capabilityApi.addCapabilityActivity(capabilityId, { body: activityId });
};

interface IAddCapabilityActivityQueryProps {
  options?: MutationOption<typeof addCapabilityActivity>;
}

/**
 * Custom hook to add a capability from a node
 *
 * @returns react-query Mutation.
 */
export const useAddCapabilityActivity = ({ options }: IAddCapabilityActivityQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.capabilityKeys.all);
      queryClient.invalidateQueries(ActivityKeyFactory.activitiesKeys.capability(variables.activityId));
    },
    ...options,
    mutationFn: addCapabilityActivity,
  });
};
