// Yfiles
import "yfiles/yfiles.css";
import { GraphComponent, Rect, ShapeNodeStyle, SvgExport } from "yfiles";
import { TYPE } from "constants/brm";
import { NODE_TYPE_STYLE_MAP } from "../../../constants";

export default function getSvg(type, nodeCategory) {
  const graphComponent = new GraphComponent();
  graphComponent.contentRect = new Rect(0, 0, 10, 10);

  const height = type === TYPE.exchange ? 10 / 2 : 10;

  const node = graphComponent.graph.createNode(new Rect(0, 0, 10, height));

  const color =
    nodeCategory === "Disclosure" && NODE_TYPE_STYLE_MAP[type].category
      ? NODE_TYPE_STYLE_MAP[type].category[nodeCategory].color
      : NODE_TYPE_STYLE_MAP[type].color;
  const shape = NODE_TYPE_STYLE_MAP[type].drawerShape;

  graphComponent.graph.setStyle(node, new ShapeNodeStyle({ shape, fill: color, stroke: "transparent" }));

  const exporter = new SvgExport(graphComponent.contentRect);
  // configure the export instance
  exporter.encodeImagesBase64 = true;
  exporter.inlineSvgImages = true;
  // create the <svg> element
  const nodeSvg = exporter.exportSvg(graphComponent);

  // Firefox does not display the SVG correctly because of the clip - so we remove it.
  // nodeSvg.removeAttribute("clip-path");

  const nodeSvgString = SvgExport.exportSvgString(nodeSvg);

  return nodeSvgString;
}
