import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./installation-query-key-factory";
/**
 * Gets the installation
 */
export const getInstallations = () => {
  return Brm.installationApi.findInstallation();
};

interface IInstallationsProps {
  options?: QueryOption<typeof getInstallations>;
}

/**
 * Custom hook for getting the installation
 *
 * @param {string} installationId - uuid of user
 * @param {object} config - react-query configuration
 * @returns
 */
export const useInstallations = ({ options = {} }: IInstallationsProps = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.installationKeys.all,
    queryFn: getInstallations,
  });
};
