import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./mitigation-query-key-factory";
/**
 * Returns  name of sctmId
 *
 * @param {string} sctmId - uuid of the baseline
 */
export const getSctmName = ({ sctmId }: { sctmId: string }) => {
  return Brm.sctmApi.getSCTMName(sctmId);
};

interface IUseSctmName {
  sctmId: string;
  options?: QueryOption<typeof getSctmName>;
}

/**
 * React-query hook for getting name of sctmId
 *
 * @param {string} sctmId - uuid of baseline
 * @param {object} config - react-query configuration object
 * @returns react-query for getStandardBaselineName
 */
export const useSctmName = ({ sctmId, options }: IUseSctmName) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.sctmKeys.name(sctmId),
    queryFn: () => getSctmName({ sctmId }),
  });
};
