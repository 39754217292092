import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { dataflowApi } from "services/brm/system-service";
import handleApi from "services/brm/api-util";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import ButtonCell from "components/EditComponents/ButtonCell";
import { ElementFullDetails, SystemApi } from "features/brm";
import { TYPE } from "constants/brm";

import {
  createColumnBooleanNoEditMapped,
  createColumnBooleanMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import DataflowDetails from "brm/system-model/system-facts/details/DataflowDetails";
import { LoadingSpinner as Loading } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";
import { RoutePath } from "routes/route-paths";

function DataflowFullDetails() {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const { id } = useParams(); // current selected element id
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedCategory = useRef("");
  const updatedIsInternal = useRef("");

  const { data: elementCats } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: dataFlow } = SystemApi.useDataflow({
    dataflowId: id,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedRowId}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedCategory.current !== "") {
              await handleApi(
                dataflowApi.setDataFlowCategoryWithHttpInfo(selectedRowId, {
                  body: updatedCategory.current,
                })
              );
              updatedCategory.current = "";
            }

            if (updatedIsInternal.current !== "") {
              await handleApi(
                dataflowApi.setDataFlowIsInternalWithHttpInfo(selectedRowId, {
                  body: updatedIsInternal.current,
                })
              );
              updatedIsInternal.current = "";
            }
            setRefresh(true);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [selectedRowId, editMode]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnCategoryMapped("category", selectedRowId, elementCats, editMode, updatedCategory),
        createColumnBooleanMapped("isInternal", selectedRowId, editMode, updatedIsInternal),
        createColumnBooleanNoEditMapped("isBoundary"),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnCategoryMapped("category", selectedRowId, elementCats, false, null),
      createColumnBooleanMapped("isInternal", selectedRowId, false, null),
      createColumnBooleanNoEditMapped("isBoundary"),
    ];
  }, [isSystemEngineer, selectedRowId, elementCats, editMode, createButton]);

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(RoutePath.Dataflows);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    if (dataFlow) {
      setSelectedElement(dataFlow);
    }
  }, [dataFlow]);

  if (elementCats && dataFlow && selectedElement) {
    const detailsComp = <DataflowDetails selectedElement={dataFlow} setRefresh={setRefresh} refresh={refresh} />;

    return (
      <ElementFullDetails
        showBackLink
        data={[dataFlow]}
        columns={columns}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        setSelectedRowId={setSelectedRowId}
        detailsComp={detailsComp}
        compName={TYPE.dataflow}
      />
    );
  }
  return <Loading />;
}

export default DataflowFullDetails;
