import { useEffect /* useState */ } from "react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
// Constants
import { COMMON } from "constants/brm";
// Recoil
import { isSubmissionPendingState, runIdState, runIdCanceledSubmissionState } from "atoms/atoms-importer";
import { projectStatusState, userIdState, isProjectBusyState } from "atoms/atoms-admin";

// Components
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import DialogButtonStyled from "components/elements/DialogButtonStyled";

// Local
import { queryClient } from "libs/react-query";
import { MitigationApi, SystemApi, ImportApi, ImportContext } from "features/brm";
import { HTMLParser } from "../HtmlParser";
// Styles
import S from "./ImporterPages.styles";
import { useProjectGetStatusForImport } from "../../api";

const constants = {
  PASS: "PASS",
  FAIL: "FAIL",
  PENDING: "PENDING",
};

export const ImporterPages = ({ projectId, context }) => {
  const runId = useRecoilValue(runIdState);
  const [runIdCanceledSubmission, setRunIdCanceledSubmission] = useRecoilState(runIdCanceledSubmissionState);
  const userRole = useRecoilValue(userIdState);
  const projectStatus = useRecoilValue(projectStatusState);
  const setIsSubmissionPending = useSetRecoilState(isSubmissionPendingState);
  const isProjectBusy = useRecoilValue(isProjectBusyState);
  const isSubmissionPending = useRecoilValue(isSubmissionPendingState);

  // Risk Elements are being commented out while a final decision is made on whether to leave it here or move it somewhere else. Remove this note along with the commented code if the functionality is already placed somewhere else. BRMCD-5522.
  // const [showRiskProgress, setShowRiskProgress] = useState(false);

  // console.log("runId:", runId, "runIdCanceledSubmission:", runIdCanceledSubmission);

  // Queries
  const { data: submissionData, isError: submissionIsError } = ImportApi.useSubmissionStatus({
    projectId: projectId || COMMON.nullUuid,
    runId,
    context,
    options: {
      enabled: !!runId && !!context && runId !== runIdCanceledSubmission && context !== ImportContext.baseline,
      refetchInterval: isProjectBusy || isSubmissionPending ? 3000 : false,
    },
  });

  // console.log("submissionData", submissionData);

  const { isError: projectStatusIsError } = useProjectGetStatusForImport(projectId, submissionData);
  const { mutate: cancelIimport } = ImportApi.useCancelImport();

  /**
   * UseEffect to set submission pending state based on project staus checks
   */
  useEffect(() => {
    function checkStatus() {
      // Risk Elements are being commented out while a final decision is made on whether to leave it here or move it somewhere else. Remove this note along with the commented code if the functionality is already placed somewhere else. BRMCD-5522.
      /* if (submissionData?.pages.length > 0 || context === ImportContext.bor) {
        if (submissionData.pages[0]?.progress?.worked > 50 || submissionData.status === constants.PASS) {
          setShowRiskProgress(true);
        } else setShowRiskProgress(false);
      } */

      if (submissionData.status === constants.PASS || submissionData.status === constants.FAIL) {
        setIsSubmissionPending(false);
        queryClient.invalidateQueries(MitigationApi.variantKeys.all);
        queryClient.invalidateQueries(SystemApi.nodeKeys.project(projectId)); // Refresh nodes to enable publishSystemProject
      } else {
        // setIsSubmissionPending(true);
        queryClient.invalidateQueries(SystemApi.allocatedControlKeys.all);
      }
    }

    if (submissionData) {
      checkStatus();
    }
  }, [projectStatus, setIsSubmissionPending, submissionData, userRole, context, projectId]);

  const handleCancelSubmission = () => {
    cancelIimport({ projectId, runId });
    setRunIdCanceledSubmission(runId);
    setIsSubmissionPending(false);
    toast("System model submission canceled");
  };

  if (submissionIsError) {
    return <div>Error in getting Submission Details</div>;
  }

  if (projectStatusIsError) {
    return <div>Error in getting project status</div>;
  }

  if (submissionData) {
    return (
      <S.Container>
        {submissionData?.status && submissionData?.pages.length === 0 ? <p>No data found...</p> : null}

        {submissionData?.status && submissionData?.pages.length !== 0 && (
          <S.SubmissionStatus id="ImporterPages_SubmissionStatus">
            <p id="ImporterPages_status">
              Status of Submission:
              <S.StatusDisplay submissionStatus={submissionData.status} projectStatus={projectStatus}>
                {submissionData.status} &emsp;{submissionData.status === constants.PENDING ? <progress /> : null}
              </S.StatusDisplay>
            </p>
            {(isSubmissionPending || submissionData.status === constants.PENDING) && context === ImportContext.system && (
              <DialogButtonStyled onClick={handleCancelSubmission} id="System_model_cancel_submit">
                Cancel Submission
              </DialogButtonStyled>
            )}
          </S.SubmissionStatus>
        )}
        {submissionData?.pages.length !== 0 ? (
          <>
            <S.Title id="ImporterPages_Details">Details after Submission: </S.Title>
            {submissionData.pages.map((i) => (
              <S.ProgressDetails id="ImporterPages_progressDetails" key={i.name}>
                <div className="col1">
                  <p className="name">{i.name}:</p>
                </div>
                <div className="col2">
                  <S.ProgressBar value={i.progress.worked} max={i.progress.total} id="ImporterPages_progressBar">
                    {i.progress.worked}%
                  </S.ProgressBar>
                </div>
                <div className="col3">
                  <S.ProgressInfo id="ImporterPages_progressInfo">{i.progress.task}</S.ProgressInfo>
                </div>
                <div className="col4">{((i.progress.worked / i.progress.total) * 100).toFixed(2)}% done</div>
              </S.ProgressDetails>
            ))}
            {/* Risk Elements are being commented out while a final decision is made on whether to leave it here or move it somewhere else. Remove this note along with the commented code if the functionality is already placed somewhere else. BRMCD-5522. */}
            {/* {showRiskProgress ? (
              <S.ProgressDetails>
                <div className="col1">Risk Elements:</div>
                <div className="col2">
                  <S.ProgressBar value={submissionData.status === constants.PENDING ? "50" : "100"} max="100" />
                </div>
                <div className="col3">
                  <S.ProgressInfo>
                    {submissionData.status === constants.PENDING
                      ? "Processing risk elements"
                      : "Processing risk elements"}
                  </S.ProgressInfo>
                </div>
                <div className="col4">
                  {submissionData.status === constants.PENDING ? "50.00 % done" : "100.00 % done"}
                </div>
              </S.ProgressDetails>
            ) : (
              <S.ProgressDetails>
                <div className="col1">Risk Elements:</div>
                <div className="col2">
                  <S.ProgressBar value="0" max="100" />
                </div>
                <div className="col3">
                  <S.ProgressInfo>Processing Risk Elements</S.ProgressInfo>
                </div>
                <div className="col4">0.00 % done</div>
              </S.ProgressDetails>
            )} */}
            {submissionData.pages.length > 0 ? (
              <S.Tabs>
                <Tabs>
                  <TabList>
                    {submissionData.pages.map((each) => (
                      <Tab key={each.name}>{each.name}</Tab>
                    ))}
                  </TabList>
                  {submissionData.pages.map((i) => (
                    <TabPanel key={i.name} className="ImporterPages_HTML">
                      <HTMLParser html={i.html} progress={i.progress} />
                    </TabPanel>
                  ))}
                </Tabs>
              </S.Tabs>
            ) : null}
          </>
        ) : null}
      </S.Container>
    );
  }

  return <p>No data found...</p>;
};

ImporterPages.propTypes = {
  projectId: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
};
