/* eslint-disable import/prefer-default-export */
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import * as S from "./ErrorFallback.styles";

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary(): void;
}

export const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps): JSX.Element => {
  return (
    <div>
      <Alert variant="danger">
        <Alert.Heading>Something went wrong!</Alert.Heading>
      </Alert>
      <S.Contents>
        <p>Please report the following error to KDM Analytics:</p>
        <S.ErrorHeader>Error:</S.ErrorHeader>
        <S.MessageContainer>
          <p>{error.message}</p>
        </S.MessageContainer>
        <p>You can attempt to recover from this error by clicking the reload button below.</p>
        <hr />
        <S.ButtonContainer>
          <Button size="sm" onClick={resetErrorBoundary}>
            Reload
          </Button>
        </S.ButtonContainer>
      </S.Contents>
    </div>
  );
};
