import { useState, useEffect } from "react";
// Components
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { DeleteButton, KnowledgebaseApi } from "features/brm";
// Styles
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
// Services
import { selectedInstanceState } from "atoms/atoms-content";
// State
import { useRecoilState } from "recoil";
// Constants
import { TYPE } from "constants/brm";

import ContentLayout from "brm/layout/Content/ContentLayout";
// import { useDeleteKb } from "./api";
import * as log from "loglevel";

// Local
import { BsFileEarmarkDiff } from "react-icons/bs";
import { ActionButton } from "components/elements";
import { useModal } from "hooks";
import { KnowledgebaseDiffModal } from "features/knowledgebase/components/modals/KnowledgebaseDiffModal";
import { KnowledgebaseTable } from "../../components/tables/KnowledgebaseTable";

export function KnowledgebaseList() {
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { isVisible: isDiffModalVisible, toggle: toggleDiffModalVisible } = useModal(false);

  const { mutate: deleteKb } = KnowledgebaseApi.useDeleteKb({ options: {} });

  useEffect(() => {
    async function multiDeleteApiCalls() {
      await Promise.all(selectedRows.map((each) => deleteKb({ kbId: each.id })));
      setConfirmDelete(false);
      setDeleteModalIsOpen(false);
      setSelectedInstance({});
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteKb, selectedRows, setSelectedInstance]);

  const kbHeader = (
    <Title>
      Knowledgebases
      <Buttons>
        {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
        <ActionButton
          title="Generate Difference Report"
          onClick={() => {
            toggleDiffModalVisible();
          }}
        >
          <BsFileEarmarkDiff size={20} />
        </ActionButton>
      </Buttons>
    </Title>
  );

  // console.log("isDiffModalVisible", isDiffModalVisible);

  const kbMain = (
    <>
      <KnowledgebaseDiffModal
        isVisible={isDiffModalVisible}
        onClosing={() => {
          toggleDiffModalVisible();
        }}
        onDiff={(kbIdA, kbIdB) => {
          log.info("kbIdA", kbIdA);
          log.info("kbIdB", kbIdB);
        }}
      />
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "KnowledgebaseList_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.knowledgebase}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            // setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.knowledgebase
        )}

      <KnowledgebaseTable setSelectedRows={setSelectedRows} />
    </>
  );

  return <ContentLayout pageId="KnowledgebaseList_page" header={kbHeader} main={kbMain} />;
}

export default KnowledgebaseList;
