import * as React from "react";
// Components
// Styles
import FormStyledError from "components/forms/FormStyledError";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { LicenseApi } from "features/brm";
import toast from "react-hot-toast";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
// import * as ModalStyles from "components/modals/Modal.styles";
import { UploadSpinner /* , ActionButton, ErrorSpan */ } from "components/elements";
import * as S from "../styles/wizard.styles";

export const LicenseWizard = ({ setUploadModalIsOpen }: { setUploadModalIsOpen: (v: boolean) => void }) => {
  const [showUploadProgress, setShowUploadProgress] = React.useState(false);
  const [disableUpload, setDisableUpload] = React.useState(false);
  const [licenseInfo, setLicenseInfo] = React.useState<any>();
  const [postError, setPostError] = React.useState("");
  const [selectedFiles, setSelectedFiles] = React.useState<File>();
  const { mutate: fileUpload } = LicenseApi.useUploadLicenseFile();

  const handleFile = (e: any) => {
    const fileList = e.target.files;

    if (fileList && fileList.length > 0) {
      setSelectedFiles(fileList[0]);
      setPostError("");
    }
  };

  /**
   * File Upload
   */
  const handleFileUpload = () => {
    if (selectedFiles) {
      setDisableUpload(true);
      setShowUploadProgress(true);
      fileUpload(
        { selectedFiles },
        {
          onSuccess: (data) => {
            setDisableUpload(true);
            setShowUploadProgress(false);
            setLicenseInfo(data);

            toast.success("License file submitted");
          },
          onError: (error, data) => {
            setLicenseInfo(data);
            setDisableUpload(false);
            setShowUploadProgress(false);
            toast.error(`Error occured while submiting file. ${error?.message}`);
          },
        }
      );
    } else setPostError("Please select file(s) to upload");
  };

  return (
    <div id="LicenseWizard">
      <S.WizardUpload id="Wizard_import">
        {/* <ModalStyles.Body>
          <Form>
            <Form.Label>Select a license file to import</Form.Label>
            <Form.Group className="mb-3">
              <Form.Control accept=".lic,.json" type="file" onChange={(e) => handleFile(e)} disabled={disableUpload} />
            </Form.Group>
          </Form>
          {postError ? <ErrorSpan>ERROR: {postError}</ErrorSpan> : ""}
          {showUploadProgress ? (
            <div className="uploadSpinner" id="Wizard_spinner">
              <UploadSpinner />
            </div>
          ) : null}
        </ModalStyles.Body>
        <ModalStyles.Footer>
          <ActionButton onClick={handleFileUpload} disabled={disableUpload}>
            Upload
          </ActionButton>
        </ModalStyles.Footer> */}
        <div style={{ padding: "10px 15px" }}>
          <Form>
            <Form.Label>Select a license file to import</Form.Label>
            <Form.Group className="mb-3">
              <Form.Control accept=".lic,.json" type="file" onChange={(e) => handleFile(e)} disabled={disableUpload} />
            </Form.Group>
          </Form>
        </div>
        {licenseInfo && (
          <div>
            <h6>License status : </h6>
            <br />
            <Container>
              <Row>
                <Col>License validity :</Col>
                <Col> {licenseInfo?.valid ? " valid " : " invalid "}</Col>
              </Row>

              <Row>
                <Col>Issue date : </Col>
                <Col>{licenseInfo?.issueDate}</Col>
              </Row>

              <Row>
                <Col>Start date : </Col>
                <Col>{licenseInfo?.startDate}</Col>
              </Row>

              <Row>
                <Col>Expiration date : </Col>
                <Col> {licenseInfo?.expirationDate}</Col>
              </Row>

              <Row>
                <Col>Days : </Col>
                <Col>{licenseInfo?.days}</Col>
              </Row>
            </Container>
            {licenseInfo.vendorString?.$ERROR_MSG && (
              <FormStyledError>*Error : {licenseInfo.vendorString.$ERROR_MSG}</FormStyledError>
            )}
          </div>
        )}
        <DialogButtonDivStyled>
          {/* <input
            type="file"
            accept=".lic,.json"
            onChange={(e) => handleFile(e)}
            className="wizardInput"
            disabled={disableUpload}
          /> */}
          {showUploadProgress ? (
            <div className="uploadSpinner" id="Wizard_spinner">
              <UploadSpinner />
            </div>
          ) : null}
          <DialogButtonStyled onClick={() => setUploadModalIsOpen(false)} id="Wizard_upload">
            Close
          </DialogButtonStyled>
          {!licenseInfo && (
            <DialogButtonStyled onClick={handleFileUpload} disabled={disableUpload} id="Wizard_upload">
              Upload
            </DialogButtonStyled>
          )}
        </DialogButtonDivStyled>
      </S.WizardUpload>

      <div>
        <FormStyledError id="Importer_Error">{postError}</FormStyledError>
      </div>
    </div>
  );
};
