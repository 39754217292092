import { NO_VALUE } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import EditField from "./EditField";

const EditCell = ({ selectedRowId, elementId, field, editMode, setter, validationMode }) => {
  const updateValues = (value) => {
    // Method to update values passed from EditField
    setter(value);
  };

  if (editMode && selectedRowId === elementId) {
    return <EditField field={field} updateData={updateValues} validationMode={validationMode} />;
  }
  if (field) {
    return <span title={field}>{field}</span>;
  }
  return NO_VALUE;
};

EditCell.propTypes = {
  selectedRowId: PropTypes.any,
  elementId: PropTypes.any,
  field: PropTypes.any,
  editMode: PropTypes.any,
  setter: PropTypes.any,
  validationMode: PropTypes.string,
};

export default EditCell;
