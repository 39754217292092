import * as React from "react";

export const useStableCallback = <T extends Function>(fn: T, deps: ReadonlyArray<unknown>) => {
  const ref = React.useRef<T>(fn);

  React.useEffect(() => {
    ref.current = fn;
    return () => {
      ref.current = undefined as unknown as T;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, ...deps]);

  return React.useCallback((...args: any[]) => ref.current && ref.current(...args), [ref]);
};
