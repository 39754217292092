import PropTypes from "prop-types";
import { useState, useMemo } from "react";
// Components
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { BrmDetailTable } from "brm/tables/BrmTables";
// Utils
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";
import useGetElementDependentAsset from "./api/dependent-asset-table-queries";
// Styles

const DependentAssetTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const { isRiskAnalyst } = useRoles();
  const [, /* selectedAsset */ setSelectedAsset] = useState([]);
  const { data: getDepAssetsData, isError: isGetDepAssetsDataError } = useGetElementDependentAsset(
    selectedElement.id,
    elementNameInUrl
  );

  const columns = useMemo(() => {
    if (isRiskAnalyst) {
      return [createColumnNameDetails(RoutePath.Asset.replace(":id", "")), createColumnMappedNoEdit("category")];
    }

    return [createColumnMappedNoEdit(`name`), createColumnMappedNoEdit("category")];
  }, [isRiskAnalyst]);

  if (isGetDepAssetsDataError) {
    return <ErrorBanner msg="Error while loading dependent assets data" />;
  }

  if (getDepAssetsData) {
    return (
      <S.DetailsContainer id="DependentAssetTable_detailsPanel">
        <BrmDetailTable
          data={Array.isArray(getDepAssetsData) ? getDepAssetsData : [getDepAssetsData]}
          columns={columns}
          setSelectedElement={setSelectedAsset}
          customProps={{ id: "DependentAssetTable_detailsTable" }}
          showRowSelect={false}
          tableTitle={tableTitle}
        />
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

DependentAssetTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  tableTitle: PropTypes.string,
};

export default DependentAssetTable;
