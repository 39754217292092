import {
  OrganizationApi,
  ProjectApi,
  ProjectSettingApi,
  InstallationApi,
  UserApi,
  GlobalSettingApi,
  SapOrganizationApi,
  SapPersonApi,
  SapProjBindingApi,
  SapUserBindingApi,
  SapOrgBindingApi,
  SapProgramApi,
} from "@kdmanalytics/brm-admin";
import BrmApiClient from "services/brm/BrmApiClient";
import USER_ROLES from "services/brm/user-roles";

export const organizationApi = new OrganizationApi(BrmApiClient.instance);
export const projectApi = new ProjectApi(BrmApiClient.instance);
export const projectSettingApi = new ProjectSettingApi(BrmApiClient.instance);
export const installationApi = new InstallationApi(BrmApiClient.instance);
export const userApi = new UserApi(BrmApiClient.instance);
export const globalSettingApi = new GlobalSettingApi(BrmApiClient.instance);
export const sapOrganizationApi = new SapOrganizationApi(BrmApiClient.instance);
export const sapOrgBindingApi = new SapOrgBindingApi(BrmApiClient.instance);
export const sapPersonApi = new SapPersonApi(BrmApiClient.instance);
export const sapProjBindingApi = new SapProjBindingApi(BrmApiClient.instance);
export const sapUserBindingApi = new SapUserBindingApi(BrmApiClient.instance);
export const sapProgramApi = new SapProgramApi(BrmApiClient.instance);

// UserRoles values
/**
 * use USER ROLES FROM services/brm/user-roles directly
 */
export { USER_ROLES as userRoles };
