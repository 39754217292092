import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import AllocatedControlTable from "brm/tables/details/AllocatedControlTable";
import PropTypes from "prop-types";
import { ImporterPages } from "features/importer";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { DetailTableType, ImportContext } from "features/brm";

export const SctmTab = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.SCTM.key;
  const projectId = useRecoilValue(projectIdState);

  return (
    <Tabs forceRenderTabPanel>
      <TabList>
        <Tab>Importer Details</Tab>
        <Tab>Allocated Controls</Tab>
      </TabList>

      <TabPanel forceRender>
        <ImporterPages projectId={projectId} context={ImportContext.sctm} />
      </TabPanel>

      <TabPanel forceRender>
        <AllocatedControlTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
    </Tabs>
  );
};

SctmTab.propTypes = {
  selectedElement: PropTypes.object.isRequired,
};
