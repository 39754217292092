import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

interface IDeleteSystemAssetType {
  systemAssetTypeId: string;
}

/**
 * Delete system asset Type
 *
 * @param {string} systemAssetTypeId uuid of systemAssetType
 */
export const deleteSystemAssetType = ({ systemAssetTypeId }: IDeleteSystemAssetType) => {
  return Brm.systemAssetTypeApi.deleteSystemAssetType(systemAssetTypeId);
};

export interface IUseDeleteSystemAssetType {
  options?: MutationOption<typeof deleteSystemAssetType>;
}

/**
 * Custom hook to delete a system asset type
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for useDeleteSystemAssetType
 */
export const useDeleteSystemAssetType = ({ options }: IUseDeleteSystemAssetType = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.systemAssetTypeKeys.all);
    },
    ...options,
    mutationFn: deleteSystemAssetType,
  });
};
