import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./threat-catalog-query-key-factory";

export interface ISetThreatCatalogName {
  threatCatalogId: string;
  name: string;
}

export const setThreatCatalogName = ({ threatCatalogId, name }: ISetThreatCatalogName) => {
  return Brm.threatCatalogApi.setThreatCatalogName(threatCatalogId, { body: name });
};

export interface IUseSetThreatCatalogName {
  options?: MutationOption<typeof setThreatCatalogName>;
}

/**
 * Custom hook to change the name of a existing Threat Catalog
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetThreatCatalogName = ({ options }: IUseSetThreatCatalogName = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.threatCatalogKeys.all);
    },
    ...options,
    mutationFn: setThreatCatalogName,
  });
};
