import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./organization-query-key-factory";

/**
 * Gets all organizations
 */
export const getOrganizations = () => {
  return Brm.organizationApi.findOrganization();
};

interface IOrganizationsProps {
  options?: QueryOption<typeof getOrganizations>;
}

/**
 * Custom hook for getting all organizations
 *
 * @param {object} config - react-query configuration
 * @returns
 */
export const useOrganizations = ({ options = {} }: IOrganizationsProps = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.organizationKeys.all,
    queryFn: () => getOrganizations(),
  });
};
