import * as React from "react";

import PropTypes from "prop-types";
import toast from "react-hot-toast";
// Utils
import { createOptionsFromProps } from "utils/react-select-utils";
import { sortByLabel } from "utils/sorting";
import { stringToBoolean } from "utils/boolean-checker";
import getReactSelectArray from "utils/react-select-array";
import { isNotDeletedStatus } from "utils/filter-utils";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { ErrorSpan, LoadingSpinner } from "components/elements";
import Form from "react-bootstrap/Form";

// Components
import Select from "react-select";
import SelectAll from "components/forms/SelectAllReactSelect";
import BooleanDropdown from "components/forms/BooleanDropdown";
import TextField from "components/forms/TextField";
// Services
import { ProjectCreateDto } from "@kdmanalytics/brm-admin";
import { isAdmin } from "services/user";
import { userApi, organizationApi } from "services/brm/admin-service";
// Constants
import { STATUS, FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
import { useRoles } from "features/brm/hooks/useRoles";
import { useProject, AdminApi } from "features/brm";
import { queryClient } from "libs/react-query";
import { useSap } from "features/admin/hooks";
// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import FormStyledMsg from "components/forms/FormStyledMsg";
import * as S from "./AddProjectForm.styles";

const POLL_INTERVAL = 1000;

export const AddProjectForm = ({ setModalIsOpen, setCancelDisable }) => {
  const [postError, setPostError] = React.useState("");
  const [organizations, setOrganizations] = React.useState([]);
  const [projectStatus, setProjectStatus] = React.useState(false);
  const [postStatus, setPostStatus] = React.useState("");
  const [userOptions, setUserOptions] = React.useState([]);
  const [selectedWorkerOptions, setSelectedWorkerOptions] = React.useState([]);
  const [selectedReadOnlyOptions, setSelectedReadOnlyOptions] = React.useState([]);
  const [selectedOrganization, setSelectedOrganization] = React.useState(null);
  const [selectedPrograms, setSelectedPrograms] = React.useState(null);
  const [, { setCurrentProject }] = useProject();
  const [isTextValid, setIsTextValid] = React.useState(true);
  const { userId, userRole, isSuperAdmin } = useRoles();
  const { mutateAsync: createProject } = AdminApi.useCreateProject();

  // state for optimistic updates and polling
  const [newProjState, setNewProjectState] = React.useState(null);
  const [stop, setStop] = React.useState(false);

  const selectedOrgId = selectedOrganization ? selectedOrganization.value : "";

  const { data: orgProjects } = AdminApi.useOrganizationProjects({
    organizationId: selectedOrgId,
    options: {
      enabled: !!selectedOrganization,
    },
  });

  // Is Sap enabled or not..
  const {
    isEnabled: isSapEnabled,
    isError: isSapError,
    error: sapError,
    isLoading: isSapLoading,
  } = useSap({ isAdminMode: true });

  // We can only associate programs to which this user has access to
  const { data: userSapProgramOptions } = AdminApi.useGetSapPrograms({
    queryParam: {
      user: userId,
    },
    options: {
      select: (data) => createOptionsFromProps({ src: data, value: "id", label: "programname" }),
    },
  });

  // set the programs for this project
  const { mutateAsync: setSapPrograms } = AdminApi.useSetProjectSapPrograms();
  const { mutateAsync: addProjectWorker } = AdminApi.useAddProjectWorker();
  const { mutateAsync: addProjectReadOnlyWorker } = AdminApi.useAddProjectReadOnlyUser();

  const addOptionals = React.useCallback(async () => {
    if (newProjState?.id) {
      if (isSapEnabled && newProjState.id) {
        try {
          await setSapPrograms({ projectId: newProjState.id, sapProgramIds: selectedPrograms.map((p) => p.value) });
        } catch (error) {
          toast.error("Unable to add Jade programs to the project");
        }
      }

      if (selectedWorkerOptions.length > 0) {
        try {
          await Promise.all(
            selectedWorkerOptions.map((user) => addProjectWorker({ projectId: newProjState.id, userId: user.value }))
          );
        } catch (error) {
          toast.error("Unable to add users to project");
        }
      }

      if (selectedReadOnlyOptions.length > 0) {
        try {
          await Promise.all(
            selectedReadOnlyOptions.map((user) =>
              addProjectReadOnlyWorker({ projectId: newProjState.id, userId: user.value })
            )
          );
        } catch (error) {
          toast.error("Unable to add read only users to project");
        }
      }

      queryClient.invalidateQueries(AdminApi.projectKeys.all);
      queryClient.invalidateQueries(AdminApi.userKeys.all);
    }
  }, [
    addProjectReadOnlyWorker,
    addProjectWorker,
    isSapEnabled,
    newProjState?.id,
    selectedPrograms,
    selectedReadOnlyOptions,
    selectedWorkerOptions,
    setSapPrograms,
  ]);

  const finalizeProject = React.useCallback(async () => {
    await addOptionals();
    if (!(isSuperAdmin || isAdmin)) {
      setCurrentProject(newProjState);
    }

    setNewProjectState(null);
    setModalIsOpen(false);
  }, [addOptionals, isSuperAdmin, newProjState, setCurrentProject, setModalIsOpen]);

  // Requery the status until the project is created...
  AdminApi.useProjectStatus({
    projectId: newProjState?.id,
    options: {
      enabled: newProjState !== null,
      refetchInterval: stop ? false : POLL_INTERVAL,
      onSuccess: (pollData) => {
        const pollStatus = pollData.result;
        if (pollStatus === STATUS.inPreparation || pollStatus === STATUS.active) {
          setStop(true);
          finalizeProject();
        }
      },
      onError: () => {
        setStop(true);
      },
    },
  });

  // const projectId = currentProject?.id || "";

  function getUniqueArray(allData, existingData) {
    if (allData.length === 0) {
      return [];
    }
    const uniqueArrayData = allData.filter((item) => {
      let unique = true;
      existingData.forEach((existing) => {
        if (existing.value === item.value) {
          unique = false;
        }
      });
      return unique;
    });
    return uniqueArrayData;
  }

  const handleReadOnlySelectChange = (selectedItems = {}) => {
    setSelectedReadOnlyOptions(selectedItems);
    // update worker users already selected to remove selected read only user, if exists
    setSelectedWorkerOptions(getUniqueArray(selectedWorkerOptions, selectedItems));
  };

  const handleWorkerSelectChange = (selectedItems = {}) => {
    setSelectedWorkerOptions(selectedItems);
    // updated read only users already selected to remove selected worker user, if exists
    setSelectedReadOnlyOptions(getUniqueArray(selectedReadOnlyOptions, selectedItems));
  };

  const handleProgramSelection = (selectedItems = {}) => {
    setSelectedPrograms(selectedItems);
  };

  function getReactSelectArrayUserName(array) {
    const objects = [];
    array.forEach((obj) => {
      objects.push({
        value: obj.id,
        label: obj.username,
      });
    });
    return objects.sort(sortByLabel);
  }

  const handleOrganizationSelectChange = React.useCallback(
    (selectedItems = {}) => {
      setSelectedOrganization(selectedItems);
    },
    [setSelectedOrganization]
  );

  React.useEffect(() => {
    if (selectedOrganization === null) {
      return;
    }

    const updateUsers = async () => {
      let users = [];
      let attachedUsers = [];

      //  get user options by selected organization
      try {
        const data = selectedOrganization.value
          ? await organizationApi.getOrganizationUser(selectedOrganization.value)
          : null;
        if (data) {
          users = data;
        }
      } catch (err) {
        console.error("error in get user : ", err);
      }

      // get the attached users by selected organization as well
      if (isSuperAdmin) {
        try {
          const data = selectedOrganization.value
            ? await organizationApi.getOrganizationAttachedUser(selectedOrganization.value)
            : null;
          if (data) {
            attachedUsers = data;
          }
        } catch (err) {
          console.error("Error in get user attached orgs: ", err);
        }
      }

      // clear any user options that might be selected
      setSelectedReadOnlyOptions([]);
      setSelectedWorkerOptions([]);
      const filteredUsers = [...users, ...attachedUsers].filter(isNotDeletedStatus);
      setUserOptions(getReactSelectArrayUserName(filteredUsers));
    };
    updateUsers();
  }, [isSuperAdmin, selectedOrganization]);

  React.useEffect(() => {
    async function getData() {
      let orgList;
      let attachedOrgList = [];
      let combinedOrgList;

      /* for admin role - the organization dropdown shows the combined list of both the organization and attached organization for creating a project */
      if (isAdmin(userRole)) {
        try {
          const res = await userApi.getUserOrganizationWithHttpInfo(userId);
          if (res?.data) {
            orgList = [res.data];
            handleOrganizationSelectChange({
              value: orgList[0].id,
            });
          }

          const { data } = await userApi.getUserAttachedOrganizationWithHttpInfo(userId);
          if (data.length > 0) {
            attachedOrgList = [...attachedOrgList, ...data];
          }
          combinedOrgList = [...orgList, ...attachedOrgList];
          const filteredData = combinedOrgList.filter(isNotDeletedStatus);
          const orgOpts = getReactSelectArray(filteredData);
          setOrganizations(orgOpts);
          if (orgOpts.length === 1) {
            setSelectedOrganization(orgOpts[0]);
          }
        } catch (err) {
          console.error("ERROR in GET ORG and Attached ORG : ", err);
        }
      } else {
        try {
          const { data } = await organizationApi.findOrganizationWithHttpInfo();
          const filteredData = data.filter(isNotDeletedStatus);
          const orgOpts = getReactSelectArray(filteredData);
          setOrganizations(orgOpts);
          if (orgOpts.length === 1) {
            setSelectedOrganization(orgOpts[0]);
          }
        } catch (err) {
          console.error("error in get org : ", err);
        }
      }
    }
    if (userRole && userId) {
      getData();
    }
  }, [userRole, userId, handleOrganizationSelectChange]);

  const isProjectNameUnique = React.useCallback(
    (projectName) => {
      if (selectedOrganization && projectName.trim().length) {
        return !orgProjects.some((o) => o.name === projectName.trim());
      }
      return false;
    },
    [orgProjects, selectedOrganization]
  );

  // validate that all fields in the form have been completed
  const validate = React.useCallback(
    (formData) => {
      return !(formData.name.value.trim() === "" || formData.isListed.value === null || selectedOrganization === null);
    },
    [selectedOrganization]
  );

  async function handleSubmit(e) {
    e.preventDefault();

    const isNameValid = isProjectNameUnique(e.target.elements.name.value);
    const isValid = validate(e.target.elements);

    if (isNameValid && isValid && isTextValid) {
      setPostError("");
      setProjectStatus(true);
      setCancelDisable(true);
      // the new project from user form entry
      const params = {
        name: e.target.elements.name.value.trim(),
        note: e.target.elements.note.value || "",
        isListed: stringToBoolean(e.target.elements.isListed.value),
      };

      // send new project to the backend
      const orgId = selectedOrganization.value;
      try {
        const projId = await createProject({
          organizationId: orgId,
          projectCreateDto: ProjectCreateDto.constructFromObject(params),
        });
        setPostStatus("Creating Project...");

        const projState = {
          id: projId,
          name: params.name,
          status: STATUS.inPreparation,
          isListed: params.isListed,
          organization: {
            id: orgId,
            name: selectedOrganization.label,
            status: null,
          },
        };
        // optimistically create the new projectstate to set after project is created.
        setNewProjectState(projState);
      } catch (err) {
        console.error("error in creating project : ", err);
      }
    } else {
      if (!isValid) {
        setPostError(FORM_ERROR.missingFields);
        return null;
      }
      if (!isNameValid) {
        setPostError(FORM_ERROR.duplicateProjectName);
        return null;
      }
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  if (isSapError) {
    if (sapError.status === 403) {
      return <ErrorSpan>Unauthorized: You do not have sufficent SAP Permissions to create projects</ErrorSpan>;
    }
  }

  if (isSapLoading) {
    return <LoadingSpinner message="Verifying Permissions...." />;
  }

  return (
    <S.AddProjectPanel id="ProjectAddForm_Panel">
      <form onSubmit={handleSubmit} action="" id="ProjectAddForm_form">
        <FormStyled>
          <div className="form-style" id="ProjectAddForm_formContent">
            <TextField
              label={{ id: "ProjectAddForm_Name", name: FORM_LABEL.nameMandatory }}
              input={{ name: "name", placeholder: FORM_PLACEHOLDER.name, disabled: projectStatus }}
              setIsTextValid={setIsTextValid}
            />
            <TextField
              label={{ id: "ProjectAddForm_Note", name: FORM_LABEL.note }}
              input={{ name: "note", placeholder: FORM_PLACEHOLDER.note, disabled: projectStatus }}
              setIsTextValid={setIsTextValid}
            />

            <BooleanDropdown
              label={{ id: "ProjectAddForm_isListed", name: FORM_LABEL.listedMandatory }}
              select={{ id: "isListed", name: "isListed" }}
            />

            {isSuperAdmin && (
              <div>
                <label id="ProjectAddForm_Organization">{FORM_LABEL.organizationMandatory}</label>
                <Select
                  isMulti={false}
                  options={organizations}
                  value={selectedOrganization}
                  onChange={handleOrganizationSelectChange}
                  id="ProjectAddForm_organizationDropdown"
                  classNamePrefix="organizationDropdown"
                />
              </div>
            )}
            <br />

            {isSapEnabled ? (
              <>
                <div>
                  <label id="ProjectAddForm_SapProgram">Jade Programs</label>
                  <Select
                    options={userSapProgramOptions}
                    value={selectedPrograms}
                    onChange={handleProgramSelection}
                    id="ProjectAddForm_organizationDropdown"
                    classNamePrefix="organizationDropdown"
                    isMulti
                    isClearable
                    hideSelectedOptions
                  />
                  <Form.Text className="text-muted">
                    Note: Without a Jade program selected you will be unable to add users to the created project.
                  </Form.Text>
                </div>
                <br />
              </>
            ) : null}

            <label>{FORM_LABEL.usersOptional}</label>
            <SelectAll
              isMulti
              options={userOptions}
              defaultValue={selectedWorkerOptions}
              value={selectedWorkerOptions}
              onChange={handleWorkerSelectChange}
              allowSelectAll
              allOption={{
                label: "Select all",
                value: "*",
              }}
              elementId="ProjectAddForm_user"
            />
            <br />
            <label>{FORM_LABEL.usersReadOnlyOptional}</label>
            <SelectAll
              isMulti
              options={userOptions}
              defaultValue={selectedReadOnlyOptions}
              value={selectedReadOnlyOptions}
              onChange={handleReadOnlySelectChange}
              allowSelectAll
              allOption={{
                label: "Select all",
                value: "*",
              }}
              elementId="ProjectAddForm_readonly"
            />
          </div>
        </FormStyled>
        <FormStyledError id="ProjectAddForm_Error">{postError}</FormStyledError>
        <S.InstructionsWrapper>
          <S.InstructionsWrapper2>
            <AiOutlineExclamationCircle size="100px" fill="#6c757d" />

            <S.InstructionsText>
              After creating the project, ensure the Threat and Security Environment for this project is configured
              prior to importing the System Model, otherwise default configurations will be used. To configure the
              Threat and Security Environment, refer to the following roles and tasks:
              <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                <ul>
                  <li>Cybersecurity Expert - Configure Knowledgebase</li>
                  <li>Control Coordinator - Configure Control Catalog and Baseline</li>
                  <li>Threat Analyst - Configure Threat Catalog and Threat Groups</li>
                </ul>
              </div>
            </S.InstructionsText>
          </S.InstructionsWrapper2>
        </S.InstructionsWrapper>

        <FormStyledMsg>{postStatus}</FormStyledMsg>
        <DialogButtonDivStyled id="ProjectAddForm_buttons">
          <DialogButtonStyled
            id="ProjectAddForm_cancelButton"
            onClick={() => setModalIsOpen(false)}
            disabled={projectStatus}
          >
            Cancel
          </DialogButtonStyled>
          <DialogButtonStyled type="submit" id="ProjectAddForm_addButton" disabled={projectStatus}>
            {projectStatus ? "Adding..." : "Add"}
          </DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </S.AddProjectPanel>
  );
};

AddProjectForm.propTypes = {
  setCancelDisable: PropTypes.func,
  setModalIsOpen: PropTypes.func.isRequired,
};
