// import localForage from "localforage";
// import { LOCAL_DB } from "constants/brm";

export const handleNoteInTooltip = (cell) => (
  <div>
    <span title={cell.value}>{cell.value}</span>
  </div>
);

export const formattedTimeStamp = () => {
  const ts = Date.now();
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(ts);
};

export const dloadJSON = (data, fileName) => {
  try {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    // Create an anchor element and link the URL to it
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    console.error(`dloadJSON - Failed to download file: ${fileName}. Error: `, err);
  }
};
