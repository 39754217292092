/* eslint-disable import/prefer-default-export */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./attack-query-key-factory";

export const getAttackTactics = ({ projectId }: { projectId: string }) => {
  return Brm.attackTacticApi.findAttackTactic(projectId);
};

interface IUseAttackTactics {
  projectId: string;
  options?: QueryOption<typeof getAttackTactics>;
}

/**
 * Custom hook to retreive an attack tactics.
 *
 * @param {string} projectId - uuid of a undesired event
 * @param {string} options - uuid of the current variant
 * @returns react-query for findAttackTactic
 *
 */
export const useAttackTactics = ({ projectId, options = {} }: IUseAttackTactics) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.attackKeys.attackTactics(projectId),
    queryFn: () => getAttackTactics({ projectId }),
  });
};
