import { useState, useEffect } from "react";
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import { AddButton, DeleteButton } from "features/brm";
import { moduleApi } from "services/brm/component-service";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { TYPE } from "constants/brm";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { AddModuleForm } from "../../components/forms/AddModuleForm";
import { ModuleTable } from "../../components/tables/ModuleTable";

export function Modules() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  useEffect(() => {
    async function multiDeleteApiCalls() {
      Promise.all(selectedRows.map((each) => moduleApi.deleteModuleWithHttpInfo(each.id)))
        .then(() => {
          setConfirmDelete(false);
          setDeleteModalIsOpen(false);
          setRefresh(true);
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, selectedRows, setSelectedInstance]);

  const addModule = () => {
    setModalIsOpen(true);
  };

  const moduleHeader = (
    <Title>
      Modules
      <Buttons>
        {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
        <AddButton onClick={addModule} />
      </Buttons>
    </Title>
  );

  const moduleMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "Module_Page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.module}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.module
        )}

      {modalIsOpen &&
        systemModals.customModal(
          "Module_Page",
          modalIsOpen,
          setModalIsOpen,
          <AddModuleForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} refresh={refresh} />,
          TYPE.module
        )}

      <ModuleTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="Module_Page" header={moduleHeader} main={moduleMain} />;
}
