import { useMemo } from "react";
// import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { COMMON, TYPE } from "constants/brm";

import {
  createColumnName,
  createColumnMapped,
  createColumnMappedNoEdit,
  createColumnImpactMapped,
  createColumnLikelihoodMapped,
  createColumnRiskLevel,
} from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading } from "components/elements";
// import { useNonZeroRisks } from "../api/risk-queries";

const RiskTable = ({ risks, setSelectedRows, setExportTableData, unfilteredLength }) => {
  // const projectId = useRecoilValue(projectIdState);
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  // const { data: riskData, isError: isRiskDataError } = useNonZeroRisks(projectId);
  const columns = useMemo(() => {
    const makePercentCell = (cellProps) => {
      if (cellProps.cell.row.original.percent.value) {
        // Percent is coming back as a value between 0-1 --> therefore multiply by 100
        const percent = cellProps.cell.row.original.percent.value * 100;
        if (percent > 0.2) {
          return percent.toFixed(1);
        }
        return <>0.001</>;
      }
      return COMMON.hyphen;
    };

    const makeScoreCell = (cellProps) => {
      if (cellProps.cell.row.original.score.value) {
        return cellProps.cell.row.original.score.value.toFixed(1);
      }
      return COMMON.hyphen;
    };
    const makeMitScoreCell = (cellProps) => {
      if (cellProps.cell.row.original.mitigatedScore.value) {
        return cellProps.cell.row.original.mitigatedScore.value.toFixed(1);
      }
      if (cellProps.cell.row.original.mitigatedScore.value === 0) {
        return "0";
      }
      return COMMON.hyphen;
    };
    return [
      createColumnMappedNoEdit("rank"),
      createColumnName(selectedInstance.id, setSelectedInstance, false, null, "risk"),
      createColumnMapped("category", selectedInstance.id, null, false, null),
      createColumnMapped("objective"),
      createColumnMappedNoEdit("asset"),
      createColumnImpactMapped("impactlevel"),
      createColumnLikelihoodMapped("likelihoodlevel"),
      createColumnRiskLevel(),
      {
        Header: "Percent",
        accessor: "percent.value",
        disableFilters: true,
        Cell: makePercentCell,
      },
      {
        Header: "Score",
        accessor: "score.value",
        disableFilters: true,
        Cell: makeScoreCell,
      },
      {
        Header: "Mitigated Score",
        accessor: "mitigatedScore.value",
        disableFilters: true,
        Cell: makeMitScoreCell,
      },
    ];
  }, [selectedInstance.id, setSelectedInstance]);

  // if (isRiskDataError) {
  //   return <ErrorBanner msg="Error while loading Risks" />;
  // }

  if (risks) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={risks}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "RiskTable_table" }}
            showRowSelect={false}
            elementName={TYPE.risk}
            setExportTableData={setExportTableData}
            dataLength={unfilteredLength}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

RiskTable.propTypes = {
  risks: PropTypes.any,
  setSelectedRows: PropTypes.func.isRequired,
  setExportTableData: PropTypes.func,
  unfilteredLength: PropTypes.number,
};

export default RiskTable;
