import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import errorUtils from "utils/error-utils";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { organizationApi } from "services/brm/admin-service";

import {
  createColumnMappedNoEdit,
  createColumnUsernameDetails,
  createColumnCreatedAt,
  createColumnModifiedAt,
} from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { DetailTableType } from "features/brm";
import { RoutePath } from "routes/route-paths";

export const UserDetailsTable = ({ elementNameInUrl, selectedElement }) => {
  const columns = useMemo(
    () => [
      createColumnUsernameDetails(RoutePath.User.replace(":id", "")),
      createColumnMappedNoEdit("firstname"),
      createColumnMappedNoEdit("lastname"),
      createColumnMappedNoEdit("org"),
      createColumnMappedNoEdit("status"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
    ],
    []
  );

  const [userData, setUserData] = useState([]);
  const [, setSelectedUser] = useState([]);

  useEffect(() => {
    function getElementRequest() {
      switch (elementNameInUrl) {
        case DetailTableType.ORGANIZATIONS.key: {
          return organizationApi.getOrganizationUserWithHttpInfo(selectedElement.id);
        }
        default: {
          break;
        }
      }
      return undefined;
    }

    async function getData() {
      try {
        const res = await getElementRequest();
        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setUserData(res.data);
        }
      } catch (err) {
        console.error("There was an error getting users ", err);
      }
    }
    getData();
  }, [elementNameInUrl, selectedElement.id]);

  return (
    <S.DetailsContainer id="UserTable_detailsPanel">
      <BrmDetailTable
        data={userData}
        columns={columns}
        setSelectedElement={setSelectedUser}
        setSelectedRows={() => {}}
        customProps={{ id: "UserTable_detailsTable" }}
        showRowSelect={false}
      />
    </S.DetailsContainer>
  );
};

UserDetailsTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
};
