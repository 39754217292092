import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./vulnerability-query-key-factory";

/**
 * Returns a list of VulnerabilityCategory for the given project
 *
 * @param {string} projectId - uuid of the project
 * @returns list of VulnerabilityCategory
 */
export const getVulnerabilityCategories = ({ projectId }: { projectId: string }) => {
  return Brm.vulnerabilityCategoryApi.findVulnerabilityCategory(projectId);
};

interface IVulnerabilityCategoriesProps {
  projectId: string;
  options?: QueryOption<typeof getVulnerabilityCategories>;
}

/**
 * React-query hook for getting all vulnerability categories
 *
 * @param {string} projectId - uuid of the project
 * @param {object} options - react-query configuration object
 * @returns react-query for getVulnerabilityCategories
 */
export const useVulnerabilityCategories = ({ projectId, options }: IVulnerabilityCategoriesProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.vulnerabilityCategoryKeys.project(projectId),
    queryFn: () => getVulnerabilityCategories({ projectId }),
  });
};
