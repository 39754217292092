import { atom } from "recoil";

export const cweFp = atom({
  key: "cweFp",
  default: "./kbs/KB_cwe.csv",
});

export const lookupCwesState = atom({
  key: "lookupCwesState",
  default: {},
});

export const lookupSfpsState = atom({
  key: "lookupSfpsState",
  default: {},
});

export const lookupSubcatsState = atom({
  key: "lookupSubcatsState",
  default: {},
});

export const subcatCwesState = atom({
  key: "subcatCwesState",
  default: {},
});

export const controlGuidanceReportState = atom({
  key: "controlGuidanceReportState",
  default: {
    data: [],
    summary: "",
    timestamp: "",
    count: 0,
  },
});

export const controlReportState = atom({
  key: "controlReportState",
  default: {
    data: [],
    summary: "",
    timestamp: "",
    count: 0,
  },
});

export const systemCweGuidanceReportState = atom({
  key: "systemCweGuidanceReportState",
  default: {
    data: [],
    timestamp: "",
    count: 0,
  },
});

export const factAnalysisReportState = atom({
  key: "factAnalysisReportState",
  default: {
    data: [],
    summary: "",
    timestamp: "",
    count: 0,
  },
});

export const factAnalysisInfoLevelReportState = atom({
  key: "factAnalysisInfoLevelReportState",
  default: {
    data: [],
    summary: "",
    timestamp: "",
    count: 0,
  },
});

export const factAnalysisInventoryReportState = atom({
  key: "factAnalysisInventoryReportState",
  default: {
    data: [],
    summary: "",
    timestamp: "",
    count: 0,
  },
});
