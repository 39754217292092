import * as Recoil from "recoil";
import { useRoles } from "hooks";
import {
  isCurrentProjectActiveState,
  isCurrentProjectPrepState,
  isCurrentProjectGeneratingState,
  isEditingState,
} from "../../stores";

export const useProjectStatus = () => {
  const { isSystemEngineer, isRiskAnalyst, isThreatAnalyst /* , isControlsCoordinator, isCyberSecurityExpert  */ } =
    useRoles();
  const [isEditing, setIsEditing] = Recoil.useRecoilState(isEditingState);
  const isCurrentProjectActive = Recoil.useRecoilValue(isCurrentProjectActiveState);
  const isCurrentProjectInPrep = Recoil.useRecoilValue(isCurrentProjectPrepState);
  const isCurrentProjectGenerating = Recoil.useRecoilValue(isCurrentProjectGeneratingState);

  // const isReadableAsCE = isCyberSecurityExpert;
  // const isReadableAsCC = isControlsCoordinator;
  const isReadableAsTa = isThreatAnalyst && isCurrentProjectActive;
  const isReadableAsRa = isRiskAnalyst && isCurrentProjectActive;
  const isReadableAsSe = isSystemEngineer && (isCurrentProjectActive || isCurrentProjectInPrep);

  // currently only support SE and RA roles
  const isReadable =
    !isEditing && (isReadableAsRa || isReadableAsSe || isReadableAsTa); /* || isReadableAsCC || isReadableAsCE */
  // console.log("isReadable", isReadable);
  return {
    isReadable,
    isReadableAsRa,
    isReadableAsSe,
    isCalculatingRisk: isCurrentProjectGenerating,
    isEditing,
    setIsEditing,
  };
};
