import { useEffect } from "react";

// Refresh the table list when user changes the selected project
function useRefreshTableList(projectId, setRefresh) {
  useEffect(() => {
    setRefresh(true);
  }, [projectId, setRefresh]);
}

export default useRefreshTableList;
