import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { LoadingSpinner } from "components/elements";
import PropTypes from "prop-types";
import toast from "react-hot-toast";

// Components
import { BrmMainTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import GenericModal from "components/modals/GenericModal";
import ButtonCell from "components/EditComponents/ButtonCell";

// State
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
// Styles
import CustomTable from "components/elements/CustomTableDivStyled";
// Services
import * as BrmGql from "generated/graphql";
import { variantApi } from "services/brm/component-service";
import { sctmApi } from "services/brm/mitigation-service";
import handleApi from "services/brm/api-util";
// Hooks
import useRefreshTableList from "hooks/useRefreshTableList";
// Constants
import { TYPE } from "constants/brm";

import {
  createColumnName,
  createColumnActions,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnBooleanWithVariantMapped,
  createColumnMappedNoEdit,
  createColumnMapped,
  createColumnBaselineMapped,
} from "brm/tables/services/column/columnFactory";
import { riskUpdatedState } from "atoms/atoms-risk";
import { selectedControlCatalogIdState } from "atoms/atoms-global-control";
import { useQueryClient } from "@tanstack/react-query";
import { variantIdState, variantNameState } from "atoms/atoms-component";
import { useRoles } from "features/brm/hooks/useRoles";
import { CloneButton, currentVariantState, MitigationApi, GlobalControlApi } from "features/brm";
import { setSctmName } from "features/brm/api/system";
import { isEmpty } from "utils/object";

const TOAST_DURATION = 4000;

export const VariantTable = ({ setSelectedRows, setRefresh }) => {
  let content = <LoadingSpinner />;
  const updatedName = useRef("");
  const updatedCompliance = useRef("");
  const queryClient = useQueryClient();
  const { isRiskAnalyst } = useRoles();
  const toastId = useRef(null);
  const updatedBaseline = useRef("");
  const updatedCatalog = useRef("");

  const riskUpdated = useRecoilValue(riskUpdatedState);
  const projectId = useRecoilValue(projectIdState);

  const setVariantId = useSetRecoilState(variantIdState);
  const setVariantName = useSetRecoilState(variantNameState);
  const setCurrentVariant = useSetRecoilState(currentVariantState);
  const setSelectedControlCatalogId = useSetRecoilState(selectedControlCatalogIdState);

  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const [resetModalIsOpen, setResetModalIsOpen] = useState(false);
  const [resetCompleteModalIsOpen, setResetCompleteModalIsOpen] = useState(false);
  const [resetCompleteMsg, setResetCompleteMsg] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [isControlCatalogEditingEnabled, setIsControlCatalogEditingEnabled] = useState(true);

  const { mutate: updateVariantName } = MitigationApi.useSetVariantName();
  const { mutate: setVariantCompliance } = MitigationApi.useSetVariantCompliance();
  const { mutate: setVariantBaseline } = MitigationApi.useUpdateVariantBaseline();
  const { mutateAsync: setVariantProperty } = MitigationApi.useSetVariantProperty();

  const { data: variantData, isError: isVariantDataError } = MitigationApi.useVariantsWithScores({
    projectId,
    options: { enabled: !!projectId },
  });

  const { data: sctm } = MitigationApi.useVariantSctm({
    variantId: selectedInstance?.id,
    options: {
      enabled:
        !isEmpty(selectedInstance) &&
        selectedInstance !== "" &&
        selectedInstance.id !== undefined &&
        selectedInstance.type === TYPE.variant,
    },
  });

  // Check if variant has allocated controls
  BrmGql.useGetSctmAllocatedControlsQuery(
    { sctm: sctm?.id },
    {
      enabled: !!sctm?.id,
      onSuccess: (data) => {
        if (Array.isArray(data.allocatedControls) && data.allocatedControls.length) {
          setIsControlCatalogEditingEnabled(false);
        } else {
          setIsControlCatalogEditingEnabled(true);
        }
      },
    }
  );

  const { data: catalogList } = GlobalControlApi.useControlCatalogs({ options: { enabled: isRiskAnalyst } });

  const { mutate: cloneVariantMutation } = BrmGql.useCloneVariantMutation({
    onError: () => {
      toast.error(<span id="cloneVariantToast">An error occurred upon cloning variant</span>, {
        id: toastId.current,
        duration: TOAST_DURATION,
      });
    },
    onSuccess: () => {
      toast.success(<span id="cloneVariantToast">Clone complete</span>, {
        id: toastId.current,
        duration: TOAST_DURATION,
      });
      queryClient.invalidateQueries(MitigationApi.variantsKeys.all);
    },
    onSettled: () => {
      setIsCloning(false);
    },
  });

  const setNewCntrlCatalogId = useCallback(
    (newCntrlCatalogId) => {
      if (newCntrlCatalogId) {
        setSelectedControlCatalogId(newCntrlCatalogId);
      }
    },
    [setSelectedControlCatalogId]
  );

  const handleVariantClick = useCallback(
    (rowData) => {
      setSelectedInstance(rowData);
      if (isRiskAnalyst) {
        const variant = variantData.find((v) => v.id === rowData.id);

        // new hotness
        const newVar = {
          id: variant.id,
          name: variant.name,
          isRecalcNeeded: variant.isRecalcNeeded,
        };
        setCurrentVariant(newVar);

        // old and busted
        setVariantId(rowData.id);
        setVariantName(rowData.name);
      }
    },
    [isRiskAnalyst, setCurrentVariant, setSelectedInstance, setVariantId, setVariantName, variantData]
  );

  const handleResetClick = useCallback(async () => {
    setResetModalIsOpen(false);
    try {
      await variantApi.resetVariantWithHttpInfo(selectedInstance.id);
      setResetCompleteMsg(`Successful reset of variant: ${selectedInstance.name}`);
    } catch (err) {
      console.error("Error on reset of variant ", err);
      setResetCompleteMsg(`Error on reset of variant: ${selectedInstance.name}`);
    }
    setResetCompleteModalIsOpen(true);
  }, [selectedInstance.id, selectedInstance.name]);

  const checkVariantNameUniqueness = useCallback(
    (newName) => {
      let isUnique = true;
      variantData.forEach((variant) => {
        if (variant.name === newName) {
          isUnique = false;
        }
      });
      return isUnique;
    },
    [variantData]
  );

  const cloneVariant = useCallback(
    (cellProps) => {
      toastId.current = toast.loading(<span id="cloneVariantToast">Cloning variant...</span>);
      setIsCloning(true);
      cloneVariantMutation({ project: projectId, variant: cellProps.cell.row.original?.id });
    },
    [cloneVariantMutation, projectId, toastId]
  );

  const createButton = useCallback(
    (cellProps) => {
      return (
        <>
          {/* <ResetButton onClick={() => setResetModalIsOpen(true)} /> */}
          <ButtonCell
            selectedRowId={selectedInstance.id}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedName.current !== "") {
                updateVariantName(
                  { variantId: selectedInstance.id, name: updatedName.current },
                  {
                    onSettled: () => {
                      setSelectedInstance({
                        id: selectedInstance.id,
                        name: updatedName.current,
                        type: selectedInstance.type,
                      });
                      updatedName.current = "";
                    },
                    onError: () => {
                      toast.error(<span id="variantTableToast">Error setting name of variant</span>, {
                        duration: TOAST_DURATION,
                      });
                    },
                  }
                );
                setSctmName({ sctmId: sctm?.id || "", name: updatedName.current });
              }

              if (updatedCompliance.current !== "") {
                setVariantCompliance(
                  { variantId: selectedInstance.id, isCompliance: updatedCompliance.current },
                  {
                    onSettled: () => {
                      updatedCompliance.current = "";
                    },
                    onError: () => {
                      toast.error(<span id="variantTableToast">Error setting compliance of variant</span>, {
                        duration: TOAST_DURATION,
                      });
                    },
                  }
                );
              }

              if (updatedCatalog.current !== "") {
                // console.log("updatedCatalog:", updatedCatalog.current);
                try {
                  await setVariantProperty({
                    variantId: selectedInstance.id,
                    property: "catalog",
                    value: updatedCatalog.current,
                  });
                  updatedCatalog.current = "";
                } catch (err) {
                  toast.error(`Setting control catalog caused an error: ${err}`);
                }
              }

              if (updatedBaseline.current !== "") {
                setVariantBaseline(
                  { variantId: selectedInstance.id, baselineId: updatedBaseline.current },
                  {
                    onSuccess: async () => {
                      const { id: sctmId } = await variantApi.getVariantSctm(selectedInstance.id);
                      // also change the SCTM baseline accordingly, so that they are in sync
                      if (sctmId) {
                        await handleApi(
                          sctmApi.setSCTMBaselineWithHttpInfo(sctmId, {
                            body: updatedBaseline.current,
                          })
                        );
                      }
                      updatedBaseline.current = "";
                    },
                  }
                );
              }
            }}
            setEditMode={setEditMode}
            editMode={editMode}
            disable={isCloning}
          />

          <CloneButton onClick={() => cloneVariant(cellProps)} disabled={isCloning} />
        </>
      );
    },
    [
      selectedInstance?.id,
      selectedInstance?.type,
      editMode,
      isCloning,
      updateVariantName,
      sctm?.id,
      setSelectedInstance,
      setVariantCompliance,
      setVariantProperty,
      setVariantBaseline,
      cloneVariant,
    ]
  );

  const makeMitigatedRiskCell = (cellProps) => {
    if (cellProps.cell.row.original.mitigatedRisk.calcValue) {
      return cellProps.cell.row.original.mitigatedRisk.calcValue.toFixed(1);
    }
    return <>-</>;
  };

  const columns = useMemo(() => {
    if (isRiskAnalyst) {
      return [
        createColumnName(
          selectedInstance.id,
          handleVariantClick,
          editMode,
          updatedName,
          "variant",
          checkVariantNameUniqueness
        ),
        createColumnBooleanWithVariantMapped("isCompliance", selectedInstance.id, editMode, updatedCompliance),
        createColumnCreatedAt(),
        createColumnModifiedAt(),
        createColumnMapped(
          "cntrlCatalog",
          selectedInstance.id,
          catalogList,
          editMode && isControlCatalogEditingEnabled,
          updatedCatalog,
          setNewCntrlCatalogId
        ),
        createColumnBaselineMapped("baseline", selectedInstance.id, editMode, updatedBaseline),
        {
          Header: "Mitigated Risk Score",
          accessor: "mitigatedRisk.calcValue",
          disableFilters: true,
          Cell: makeMitigatedRiskCell,
        },
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }

    return [
      createColumnName(selectedInstance.id, handleVariantClick, false, null, "variant"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnMappedNoEdit("cntrlCatalog"),
      createColumnMappedNoEdit("baseline"),
      {
        Header: "Mitigated Risk Score",
        accessor: "mitigatedRisk.calcValue",
        disableFilters: true,
        Cell: makeMitigatedRiskCell,
      },
    ];
  }, [
    isRiskAnalyst,
    selectedInstance.id,
    handleVariantClick,
    editMode,
    checkVariantNameUniqueness,
    catalogList,
    isControlCatalogEditingEnabled,
    setNewCntrlCatalogId,
    createButton,
  ]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    queryClient.invalidateQueries(["getUnmitigatedScore"]);
  }, [queryClient, riskUpdated]);

  if (isVariantDataError) {
    content = "Error in getting variants";
  }

  if (variantData) {
    content = (
      <CustomTable className="mainTablePanel">
        <BrmMainTable
          data={variantData}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "VariantTable_table" }}
          elementName={TYPE.variant}
          showRowSelect={isRiskAnalyst}
        />
      </CustomTable>
    );
  }

  return (
    <>
      {resetModalIsOpen &&
        systemModals.confirmModal(
          "VariantTable_resetModal",
          resetModalIsOpen,
          setResetModalIsOpen,
          "Are you sure you want to reset?",
          "Reset Variant",
          handleResetClick
        )}

      {resetCompleteModalIsOpen && (
        <GenericModal
          elementId="VariantTable_resetCompleteModal"
          modalIsOpen={resetCompleteModalIsOpen}
          setModalIsOpen={setResetCompleteModalIsOpen}
          headerText="Reset Varaint Complete"
          bodyText={resetCompleteMsg}
        />
      )}
      {content}
    </>
  );
};

VariantTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};
