import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";

interface ISetControlTypeAssmtGuidance {
  controlTypeId: string;
  assmt: string;
}

/**
 * function calling api to set the assmt guidance of control type
 *
 * @param {string} cntrlTypeId - uuid of the control type
 * @param {string} assmt - user entered value for assmt guidance
 * @returns object of the control type
 */
export const setControlTypeAssmtGuidance = ({ controlTypeId, assmt }: ISetControlTypeAssmtGuidance) => {
  return Brm.controlTypeApi.setControlTypeAssmtGuidance(controlTypeId, { body: assmt });
};

interface IUseSetControlTypeAssmtGuidance {
  options?: MutationOption<typeof setControlTypeAssmtGuidance>;
}

/**
 * Custom hook to change assmt Guidance of a existing control type
 *
 * @param {object} options - configuration of react-query
 * @returns react-query ReactQuery.useMutation
 */
export const useSetControlTypeAssmtGuidance = ({ options = {} }: IUseSetControlTypeAssmtGuidance) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: setControlTypeAssmtGuidance,
  });
};
