import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * Returns a list of allocated controls for the given SCTM
 *
 * @param {string} sctmId - uuid of the SCTM
 */
export const getSctmAllocatedControls = ({ sctmId }) => {
  return Brm.sctmApi.getSCTMAllocatedcontrol(sctmId);
};

/**
 * React-query hook for getting all allocated controls
 *
 * @param {string} sctmId - uuid of sctm
 * @param {object} config - react-query configuration object
 * @returns react-query for getSctmAllocatedControls
 */
export const useSctmAllocatedControls = ({ sctmId, config = {} }) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["sctmAllocatedControls", sctmId],
    queryFn: () => getSctmAllocatedControls({ sctmId }),
  });
};
