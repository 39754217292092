import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

export interface IMergeStandardBaseline {
  id: string;
  baseline: string;
}

/**
 * Merge the baseline
 *
 * @param {string} id - uuid of the selected control type
 * @param {string} baseline - id of merged baseline
 * @returns object of control type
 */
export const mergeStandardBaseline = ({ id, baseline }: IMergeStandardBaseline) => {
  return Brm.baselineApi.mergeStandardBaseline(id, baseline);
};

export interface IUseMergeStandardBaseline {
  options?: MutationOption<typeof mergeStandardBaseline>;
}

/**
 * Custom hook to change strength of a existing control type
 *
 * @param {object} options - configuration of react-query
 * @returns react-query ReactQuery.useMutation
 */
export const useMergeStandardBaseline = ({ options }: IUseMergeStandardBaseline = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.standardBaselineKeys.all);
      queryClient.invalidateQueries(["getElementControlTypes"]);
    },
    ...options,
    mutationFn: mergeStandardBaseline,
  });
};
