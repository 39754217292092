import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./data-type-query-key-factory";

interface IGetDataTypeProps {
  dataTypeId: string;
}

/**
 * Returns an DataTypeDto corresponding to the given id
 *
 * @param {string} dataTypeId uuid of the exchange
 * @returns DataTypeDto
 */
export const getDataType = ({ dataTypeId }: IGetDataTypeProps) => {
  return Brm.dataTypeApi.getDataTypeByID(dataTypeId);
};

interface IUseDataTypeProps {
  dataTypeId: string;
  options?: QueryOption<typeof getDataType>;
}

/**
 * React-query mutation hook for retrieving a dataType
 *
 * @param {string} dataTypeId - uuid of the exchange
 * @param {object} options - react-query configuration object
 * @returns react-query mutation
 */
export const useDataType = ({ dataTypeId, options = {} }: IUseDataTypeProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.dataTypeKeys.dataType(dataTypeId),
    queryFn: () => getDataType({ dataTypeId }),
  });
};
