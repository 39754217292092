/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import ReactModal from "react-modal";
import * as ModalStyles from "components/modals/Modal.styles";
import { useForm, SubmitHandler } from "react-hook-form";
import Cancel from "assets/icons/cancelModal.svg";
import Form from "react-bootstrap/Form";
import { ErrorSpan } from "components/elements";
import { ActionButton } from "components/elements/ActionButton";
import { ExportApi } from "features/brm";

interface IExportFileModalProps {
  tcId: string;
  tcName: string;
  onHide: () => void;
  show: boolean;
}

interface IInputs {
  format: string;
}

export const ExportThreatCatalogFileModal = ({ tcId, tcName, show, onHide }: IExportFileModalProps) => {
  const [exportMessage, setExportMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);

  const { mutate: exportThreatCatalog } = ExportApi.useExportThreatCatalog();

  const onHideWrapper = () => {
    setIsError(false);
    setExportMessage("");
    onHide();
  };

  const {
    register,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<IInputs>({ mode: "onChange" });

  const handleClose = () => {
    reset();
    onHideWrapper();
  };

  const onSubmit: SubmitHandler<IInputs> = (formData: { format: any }) => {
    const fileFormat = formData.format;
    if (fileFormat) {
      exportThreatCatalog(
        { threatCatalogId: tcId, format: fileFormat },
        {
          onSuccess: (data2: any) => {
            const url = window.URL.createObjectURL(data2);
            const a = document.createElement("a");
            a.href = url;
            const timestamp = new Date(Date.now())
              .toISOString()
              .replace(/T/, "_")
              .replace(/\..+/, "")
              .replace(/-|:/g, "");

            a.download = `${tcName}-Threat_Catalog_${timestamp}.${fileFormat.toLowerCase()}`;
            a.click();
            window.URL.revokeObjectURL(url);
            onHideWrapper();
          },
        }
      );
    }
  };

  return (
    <ReactModal
      isOpen={show}
      onAfterClose={onHideWrapper}
      ariaHideApp={false}
      onRequestClose={() => handleClose()}
      style={ModalStyles.defaultModalStyles}
    >
      <ModalStyles.HeaderFlex>
        <ModalStyles.ModalTitle>Export a Threat Catalog to a file</ModalStyles.ModalTitle>
        <ModalStyles.ButtonsDiv>
          <ModalStyles.Close onClick={() => handleClose()}>
            <ModalStyles.CloseIcon src={Cancel} />
          </ModalStyles.Close>
        </ModalStyles.ButtonsDiv>
      </ModalStyles.HeaderFlex>
      <ModalStyles.Body>
        <Form>
          <Form.Label>Select export format for the Threat Catalog file</Form.Label>
          <Form.Group className="mb-3">
            {["CSV", "JSON"].map((type) => (
              <Form.Check
                key={type}
                type="radio"
                label={type}
                value={type}
                {...register("format", { required: true })}
              />
            ))}
          </Form.Group>
        </Form>
        {isError ? <ErrorSpan>ERROR: {exportMessage}</ErrorSpan> : exportMessage}
      </ModalStyles.Body>
      <ModalStyles.Footer>
        <ActionButton onClick={handleClose}>Close</ActionButton>
        <ActionButton onClick={handleSubmit(onSubmit)} disabled={!isDirty || !isValid}>
          Export
        </ActionButton>
      </ModalStyles.Footer>
    </ReactModal>
  );
};
