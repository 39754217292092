import { COMMON } from "constants/brm";

export const LIKELIHOOD_MAP = new Map([
  [
    "O5",
    new Map([
      ["M1", COMMON.low],
      ["M2", COMMON.moderate],
      ["M3", COMMON.high],
      ["M4", COMMON.veryHigh],
      ["M5", COMMON.veryHigh],
    ]),
  ],
  [
    "O4",
    new Map([
      ["M1", COMMON.low],
      ["M2", COMMON.moderate],
      ["M3", COMMON.high],
      ["M4", COMMON.veryHigh],
      ["M5", COMMON.veryHigh],
    ]),
  ],
  [
    "O3",
    new Map([
      ["M1", COMMON.veryLow],
      ["M2", COMMON.low],
      ["M3", COMMON.moderate],
      ["M4", COMMON.high],
      ["M5", COMMON.veryHigh],
    ]),
  ],
  [
    "O2",
    new Map([
      ["M1", COMMON.veryLow],
      ["M2", COMMON.low],
      ["M3", COMMON.moderate],
      ["M4", COMMON.high],
      ["M5", COMMON.high],
    ]),
  ],
  [
    "O1",
    new Map([
      ["M1", COMMON.veryLow],
      ["M2", COMMON.veryLow],
      ["M3", COMMON.low],
      ["M4", COMMON.moderate],
      ["M5", COMMON.moderate],
    ]),
  ],
]);
