import * as YFiles from "yfiles";
import Stack from "services/data-structures/stack";

/**
 * Return predecessors of the given node
 *
 * @param {INode} node node to check for predecessors
 * @param {IGraph} graph the graph containing the nodes
 * @returns set of Nodes that are predecessors of the given node
 */
const getPredecessors = (node, graph) => {
  const stack = new Stack();
  const results = [];
  stack.push(node);
  while (!stack.isEmpty()) {
    const n = stack.pop();
    const predecessors = graph.predecessors(n);
    if (predecessors.size > 0) {
      predecessors.forEach((p) => {
        stack.push(p);
        results.push(p);
      });
    }
  }
  return results;
};

/**
 * Return the set of nodes that are predecessors of the given nodes.
 *
 * @param {INode[]} nodes nodes to find all parent nodes
 * @param {IGraph} graph graph containing the nodes
 * @returns {Set} nodes that are predecessors of the given nodes
 */
export const getStartNodes = (nodes, graph) => {
  const startNodes = new Set();
  nodes.forEach((n) => {
    getPredecessors(n, graph).forEach((p) => startNodes.add(p));
  });
  return startNodes;
};

/**
 * Returns array of nodes that are reachable from the given start nodes
 *
 * @param {INode[]} startNodes2 nodes to start reachability from
 * @param {IGraph} graph the graph containing the nodes
 * @returns {INode[]} reachable nodes
 */
export const getReachable = (startNodes2, graph) => {
  const result = new YFiles.Reachability({
    directed: true,
    startNodes: startNodes2,
  }).run(graph);

  return result.reachableNodes.toArray();
};
