import { ModalRow } from "../ModalRow";
import { RECORD_KEY } from "../../../constants";

interface IRecordProps {
  record: {
    duration: number;
    expiryDate: string;
    hostId: string;
    issueDate: string;
    sessionsMax: number;
    userName: string;
    userSessionsMax: number;
  };
}

export const BorrowRecord = ({ record }: IRecordProps) => {
  return (
    <>
      <br />
      {record?.userName && <ModalRow label={RECORD_KEY.userName} value={record?.userName} />}
      <ModalRow label={RECORD_KEY.hostId} value={record?.hostId} />
      <ModalRow label={RECORD_KEY.issueDate} value={record?.issueDate} />
      <ModalRow label={RECORD_KEY.expiryDate} value={record?.expiryDate} />
      <ModalRow label={RECORD_KEY.duration} value={record?.duration} />
      <ModalRow label={RECORD_KEY.sessionsMax} value={record?.sessionsMax} />
      <ModalRow label={RECORD_KEY.userSessionsMax} value={record?.userSessionsMax} />
    </>
  );
};
