import { useRef, useMemo } from "react";
import { BrmMainTable } from "brm/tables/BrmTables";
import { GlobalControlApi, useRoles } from "features/brm";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import CustomTable from "components/elements/CustomTableDivStyled";
import { TYPE } from "constants/brm";

import { createColumnName } from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading, ErrorBanner as ErrorDisplay } from "components/elements";
import { useControlCatalog } from "../../../hooks";

export const ControlFamilyTable = ({ setSelectedRows }: { setSelectedRows: (v: any) => void }) => {
  const { isControlsCoordinator } = useRoles();
  const updatedName = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const { controlCatalog, controlCatalogId, isReadOnlyCatalog } = useControlCatalog();

  const { data, isError, error } = GlobalControlApi.useControlFamilies({
    controlCatalogId: controlCatalogId || "",
    options: { enabled: !!controlCatalogId && isControlsCoordinator },
  });

  const columns = useMemo(
    () => [createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "controlFamily")],
    [setSelectedInstance, selectedInstance.id]
  );

  if (isError) {
    return <ErrorDisplay msg={(error as any)?.statusText} />;
  }

  if (!controlCatalogId) {
    return <>Please select a control catalog, in order to view control families</>;
  }

  if (data && controlCatalog) {
    return (
      <CustomTable>
        <BrmMainTable
          data={data}
          columns={columns}
          showRowSelect={!isReadOnlyCatalog}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "ControlFamilyTable_table" }}
          elementName={TYPE.controlFamily}
        />
      </CustomTable>
    );
  }

  return <Loading />;
};
