import { cloneDeep } from "lodash";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// Services
import { controlFamilyApi } from "services/brm/global-control-service";
import { sctmApi, allocatedControlApi } from "services/brm/mitigation-service";
import { nodeApi, linkApi } from "services/brm/system-service";
import { MitigationApi, useRoles } from "features/brm";

const getSystemObjects = async (projectId) => {
  let links = [];
  let nodes = [];
  links = await linkApi.findLink(projectId);
  nodes = await nodeApi.findNode(projectId);

  return [...links, ...nodes];
};

const getControlTypes = async (controlFamily) => {
  const ids = controlFamily.map((item) => item.value);
  const result = await Promise.all(ids.map((each) => controlFamilyApi.getControlFamilyControl(each)));
  return result.flat();
};

/*
 * Sending requests in batches of 10
 */
const deleteAC = async (selectedRows) => {
  while (selectedRows.length) {
    // eslint-disable-next-line no-await-in-loop
    await Promise.all(
      selectedRows.splice(0, 10).map(async (each) => {
        const result = await allocatedControlApi.deleteAllocatedControl(each.id);
        return result;
      })
    );
  }
};

export const useSystemObjects = (projectId) => {
  const { isRiskAnalyst } = useRoles();
  return useQuery({
    queryKey: ["getSystemObjects", projectId],
    queryFn: () => getSystemObjects(projectId),
    enabled: isRiskAnalyst,
  });
};

export const useControlTypes = (controlFamily) => {
  const { isRiskAnalyst } = useRoles();
  return useQuery({
    queryKey: ["getControlTypes", controlFamily],
    queryFn: () => getControlTypes(controlFamily),
    enabled: !!controlFamily && !!controlFamily.length > 0 && isRiskAnalyst,
    cacheTime: 0,
  });
};

export const useVulCategoryUpdate = (sctmId) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ allocatedControlId, category }) => {
      allocatedControlApi.setAllocatedControlVulnerabilitycategory(allocatedControlId, {
        body: category,
      });
    },
    {
      onMutate: async ({ allocatedControlId, category }) => {
        await queryClient.cancelQueries(["getAllocatedControls", sctmId]);
        const prevValues = queryClient.getQueryData(["getAllocatedControls", sctmId]);

        queryClient.setQueryData(["getAllocatedControls", sctmId], (old) =>
          old.map((data) => {
            if (allocatedControlId === data.id) {
              return {
                ...data,
                vulnerabilitycategory: category,
              };
            }
            return data;
          })
        );
        return prevValues;
      },
      onError: (prevValues) => queryClient.setQueryData(["getAllocatedControls", sctmId], prevValues),
      onSettled: () => {
        queryClient.invalidateQueries(["getAllocatedControls", sctmId]); // likely deprecated..
        queryClient.invalidateQueries(MitigationApi.allocatedControlsGqlKey.all);
      },
    }
  );
};

export const useTargetUpdate = (sctmId) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ allocatedControlId, systemObject }) =>
      allocatedControlApi.setAllocatedControlObject(allocatedControlId, {
        body: systemObject.value,
      }),
    {
      onMutate: async ({ allocatedControlId, systemObject }) => {
        await queryClient.cancelQueries(["getAllocatedControls", sctmId]);
        const prevValues = queryClient.getQueryData(["getAllocatedControls", sctmId]);

        queryClient.setQueryData(["getAllocatedControls", sctmId], (old) =>
          old.map((data) => {
            if (allocatedControlId === data.id) {
              const newValue = cloneDeep(data.anobject);
              newValue.name = systemObject.label;
              return {
                ...data,
                anobject: newValue,
              };
            }
            return data;
          })
        );
        return prevValues;
      },
      onError: (prevValues) => queryClient.setQueryData(["getAllocatedControls", sctmId], prevValues),
      onSettled: () => {
        queryClient.invalidateQueries(["getAllocatedControls", sctmId]); // likely deprecated..
        queryClient.invalidateQueries(MitigationApi.allocatedControlsGqlKey.all);
      },
    }
  );
};

export const useCreateAllocatedControl = (setModalIsOpen, sctmId) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ opts }) => {
      return sctmApi.importSCTM(sctmId, { body: opts });
    },
    {
      onSuccess: () => {
        setModalIsOpen(false);
        queryClient.invalidateQueries(["getAllocatedControls"]); // likely deprecated..
        queryClient.invalidateQueries(MitigationApi.allocatedControlsGqlKey.all);
      },
    }
  );
};

export const useDeleteAllocatedControl = (setConfirmDelete, setDeleteModalIsOpen, setSelectedInstance) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ selectedRows }) => {
      return deleteAC(selectedRows);
    },
    {
      onError: () => {
        setDeleteModalIsOpen(false);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["getAllocatedControls"]); // likely deprecated..
        queryClient.invalidateQueries(MitigationApi.allocatedControlsGqlKey.all);
        setConfirmDelete(false);
        setDeleteModalIsOpen(false);
        setSelectedInstance({});
      },
    }
  );
};
