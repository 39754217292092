import * as ReactQuery from "@tanstack/react-query";
import { MutationOption /* , queryClient */ } from "libs/react-query";
import * as Brm from "services/brm";
import * as SapOrgKeyFactory from "features/brm/api/admin/sapOrganization/sap-org-query-key-factory";
import * as SapBindingKeyFactory from "features/brm/api/admin/sapBinding/sap-binding-query-key-factory";

interface IDeleteSapOrgBindingProps {
  bindingId: string | null;
}

/**
 * Delete an sap org binding
 *
 * @returns
 */
export const deleteSapOrgBinding = ({ bindingId }: IDeleteSapOrgBindingProps) => {
  return bindingId === null
    ? Promise.reject(new Error("Invalid binding id"))
    : Brm.sapOrgBindingApi.deleteSapOrgBinding(bindingId);
};

interface IDeleteSapOrgBindingQueryProps {
  options?: MutationOption<typeof deleteSapOrgBinding>;
}

/**
 * Custom hook to Delete an SapOrgBinding
 *
 * @returns react-query Mutation.
 */
export const useDeleteSapOrgBinding = ({ options }: IDeleteSapOrgBindingQueryProps = {}) => {
  const queryClient = ReactQuery.useQueryClient();

  return ReactQuery.useMutation({
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: SapOrgKeyFactory.sapOrganizationKeys.all });
      queryClient.invalidateQueries({ queryKey: SapBindingKeyFactory.sapBindingKeys.allOrg });
    },
    mutationFn: deleteSapOrgBinding,
  });
};
