/**
 * SuperAdmin Help contents -  this is a key value pair mapping help videos to each page , where key is the "contentKey" which is unique to each page
 */

export const SuperAdminHelpContents = {
  installation: {
    contents: [
      {
        role: "Super Admin",
        type: "Video",
        name: "Import license of BRM",
        filePath: "/help-assets/SA/applyABrmLicenseFile/applyABrmLicenseFile_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "View license information",
        filePath: "/help-assets/SA/ViewLicenseInformation/ViewLicenseInformation_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "Export user login audit log",
        filePath: "/help-assets/SA/exportUserLoginAuditLog/exportUserLoginAuditLog_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "Create a borrow license",
        filePath: "/help-assets/SA/createABorrowLicense/createABorrowLicense_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "Apply a borrow license",
        filePath: "/help-assets/SA/applyBorrowLicense/applyBorrowLicense_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "Configure projects at installation level",
        filePath: "/help-assets/SA/configureProjectsInstallationLevel/configureProjectsInstallationLevel_player.html",
      },
    ],
  },

  projects: {
    contents: [
      {
        role: "Super Admin",
        type: "Video",
        name: "Create a project",
        filePath: "/help-assets/SA/createaproject/createaproject_player.html",
      },
    ],
  },

  projectOverview: {
    contents: [
      {
        role: "Super Admin",
        type: "Video",
        name: "Configure a project at project level",
        filePath: "/help-assets/SA/projectConfigurationSettingsSa/projectConfigurationSettingsSa_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "Create save point for project by Super Admin",
        filePath: "/help-assets/SA/createSavePoint/createSavePoint_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "Add and remove a user to the project",
        filePath: "/help-assets/SA/LinkAndRemoveAUserToAProject/LinkAndRemoveAUserToAProject_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "Attach a read only user to a project",
        filePath: "/help-assets/SA/AttachAReadOnlyUserToAProject/AttachAReadOnlyUserToAProject_player.html",
      },
    ],
  },

  installationOverview: {
    contents: [
      {
        role: "Super Admin",
        type: "Video",
        name: "Modify the installation admin user",
        filePath: "/help-assets/SA/ModifyTheInstallationAdminUser/ModifyTheInstallationAdminUser_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "Review the details of the installation",
        filePath: "/help-assets/SA/ReviewTheDetailsOfTheInstallation/ReviewTheDetailsOfTheInstallation_player.html",
      },
    ],
  },

  organizations: {
    contents: [
      {
        role: "Super Admin",
        type: "Video",
        name: "Create an organization",
        filePath: "/help-assets/SA/CreateAnOrganization/CreateAnOrganization_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "View an organization by Super Admin",
        filePath: "/help-assets/SA/ViewOrganizationDetails/ViewOrganizationDetails_player.html",
      },
    ],
  },

  organizationOverview: {
    contents: [
      {
        role: "Super Admin",
        type: "Video",
        name: "Assign an organization an admin user",
        filePath: "/help-assets/SA/AssignAnOrganizationAnAdminUser/AssignAnOrganizationAnAdminUser_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "Attach a user to an organization",
        filePath: "/help-assets/SA/AttachAUserToAnOrganization/AttachAUserToAnOrganization_player.html",
      },
    ],
  },

  users: {
    contents: [
      {
        role: "Super Admin",
        type: "Video",
        name: "Create a user by Super Admin",
        filePath: "/help-assets/SA/CreateAUser_SA/CreateAUser_SA_player.html",
      },

      {
        role: "Super Admin",
        type: "Video",
        name: "Edit a user by SuperAdmin",
        filePath: "/help-assets/SA/EditAUser_SA/EditAUser_SA_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "Delete a user by Super Admin",
        filePath: "/help-assets/SA/DeleteAUser/DeleteAUser_player.html",
      },
    ],
  },

  userOverview: {
    contents: [
      {
        role: "Super Admin",
        type: "Video",
        name: "Assign a role to user",
        filePath: "/help-assets/SA/AssignARoleToAUser/AssignARoleToAUser_player.html",
      },
      {
        role: "Super Admin",
        type: "Video",
        name: "Reset password for user by Super Admin",
        filePath: "/help-assets/SA/resetPassword/resetPassword_player.html",
      },
    ],
  },
};
