import { ImportApi, ImportContextValues, useProject } from "features/brm";
import { IMPORT_STATUS } from "constants/brm";
import { getErrorMessage } from "utils/error-message-utils";
import { isBrmApiError } from "utils/type-guard";
import * as React from "react";
import { IStepInfo, StepStatus } from "./types";
import { errorMessageLookup } from "./error-lookup";

interface IProp {
  context: ImportContextValues;
  state: any;
  onStepUpdate: (updatedStep: IStepInfo) => void;
  dispatch: (v: any) => void;
  onError: () => void;
  isCancelled: boolean;
}
const POLL_INTERVAL = 1000;

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const useSubmissionStatusQuery = ({ context, state, onStepUpdate, dispatch, onError, isCancelled }: IProp) => {
  const [, { projectId }] = useProject();
  const [isStopped, setIsStopped] = React.useState(false);

  // console.log("state", state);

  ImportApi.useSubmissionStatus({
    projectId,
    context,
    runId: state.jobId || "",
    options: {
      enabled: !isStopped && !isCancelled && state.jobId !== undefined,
      refetchInterval: !isStopped || state.jobId !== undefined ? POLL_INTERVAL : false,
      onSuccess: (pollData) => {
        if (!pollData) {
          return;
        }
        if (pollData.status === IMPORT_STATUS.pass) {
          setIsStopped(true);
          // TODO FIX this to poll to make sure the project is active instead of sleeping,
          // TODO imports like SCTM do a little processing after import is complete which
          // TODO which causes a project locked error if the next step starts to early
          sleep(3000).then(() => {
            onStepUpdate({ ...state.step!, status: StepStatus.Complete });
            dispatch({ type: "finish" });
          });
        }
        if (pollData.status === IMPORT_STATUS.fail) {
          const msg = `Import failed. ${errorMessageLookup(context)}`;
          const error = new Error(msg);
          setIsStopped(true);
          onStepUpdate({
            ...state.step!,
            status: StepStatus.Error,
            error,
          });
          dispatch({ type: "failure", error });
          onError();
        }
      },
      onError: (error) => {
        if (isBrmApiError(error)) {
          const err = new Error(`Import Failed. ${errorMessageLookup(context)}`);
          onStepUpdate({ ...state.step!, status: StepStatus.Error, error: err });
          dispatch({ type: "failure", err });
          onError();
        } else {
          // console.log("import polling query failure", error);
          const err = new Error(getErrorMessage(error));
          onStepUpdate({ ...state.step!, status: StepStatus.Error, err });
          dispatch({ type: "failure", err });
          onError();
        }
      },
    },
  });
};
