import styled from "styled-components/macro";
import Accordion from "react-bootstrap/Accordion";

export const Container = styled.div`
  line-height: 0.75;
`;

export const SkinnyBody = styled(Accordion.Body)`
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
`;

export const MediumBody = styled(Accordion.Body)`
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  overflow: auto;
`;

export const Body = styled(Accordion.Body)`
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
`;

export const Header = styled(Accordion.Header)`
  & .accordion-button {
    padding: 0.5rem;
  }
`;

export const SecondaryAccordionHeader = styled(Accordion.Header)`
  & .accordion-button {
    background-color: ${({ theme }) => theme.colors.mediumLowConstrastBackground};
    padding: 0.5rem;
  }
`;
