import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./user-query-key-factory";

/**
 *
 * Gets all projects of a user
 * @param {string} userId - uuid of the user
 */
export const getUserProjects = ({ userId }: { userId: string | null }) => {
  return userId === null ? Promise.reject(new Error("Invalid user id")) : Brm.userApi.getUserAllProject(userId);
};

interface IGetUserProjectsProps {
  userId: string | null;
  options?: QueryOption<typeof getUserProjects>;
}

/**
 * Custom hook for getting all projects of a user
 *
 * @param {string} userId - uuid of user
 * @param {object} options - react-query configuration
 * @returns
 */
export const useUserProjects = ({ userId, options = {} }: IGetUserProjectsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.userKeys.project(userId),
    queryFn: () => getUserProjects({ userId }),
  });
};
