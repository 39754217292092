/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { ActionButton } from "components/elements/ActionButton";
import { useForm, SubmitHandler } from "react-hook-form";
import { getErrorMessage } from "utils/error-message-utils";
import { ErrorSpan } from "components/elements";
import toast from "react-hot-toast";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import localforage from "localforage";
import { useRestoreFromFile } from "../../hooks/useRestoreFromFile";
import * as S from "./RestoreFromFileModal.styles";

interface IRestoreFromFileModalProps {
  projectId: string;
  onHide: () => void;
  show: boolean;
}

interface IInputs {
  restoreFile: FileList;
}

export const RestoreFromFileModal = ({ projectId, onHide, ...rest }: IRestoreFromFileModalProps) => {
  const [importMessage, setImportMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);

  const onHideWrapper = () => {
    setIsError(false);
    setImportMessage("");
    onHide();
  };

  const {
    register,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<IInputs>({ mode: "onChange" });

  const handleClose = () => {
    reset();
    onHideWrapper();
  };

  const { restoreProjectFromFile } = useRestoreFromFile({
    projectId,
    onStart: () => {
      setImportMessage("Restoring project...");
    },
    onSuccess: ({ projectId: projId }) => {
      localforage.removeItem(`sd_layout_${projId}`);
      setImportMessage("Restore successful");
      reset();
      onHideWrapper();
      toast.success("Successfully Restored Project");
    },
    onFailure: (data: any) => {
      reset();
      setIsError(true);
      setImportMessage(`Restore failed ${data.message}`);
    },
    onError: (error: Error) => {
      reset();
      setIsError(true);
      setImportMessage(`Error while attempting to restore project: ${getErrorMessage(error)}`);
    },
  });
  // const { runId, setRunId } = React.useState("");

  // ImportApi.useSubmissionStatus({ projectId, context: ImportContext.savepoint, runId, options: {} });

  const onSubmit: SubmitHandler<IInputs> = (data) => {
    const fileArray = Array.from(data.restoreFile);
    if (fileArray.length > 0) {
      restoreProjectFromFile(fileArray[0]);
    }
  };

  return (
    <Modal {...rest} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Restore from File </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Select restore file</Form.Label>
            <Form.Control accept=".zip" type="file" {...register("restoreFile", { required: true })} />
          </Form.Group>
        </Form>
        <S.WarningContainer>
          <AiOutlineExclamationCircle size="50px" fill="#6c757d" />
          <Form.Text id="warningblock" muted>
            Restoring a project will overwrite your current project. If you are not sure what this means, you should
            switch to an empty project before restoring.
            <br />
            <br />
            <strong>After restoring you need to re-apply your vulnerability findings.</strong>
          </Form.Text>
        </S.WarningContainer>

        {isError ? <ErrorSpan>ERROR: {importMessage}</ErrorSpan> : importMessage}
      </Modal.Body>
      <Modal.Footer>
        <ActionButton onClick={handleClose}>Close</ActionButton>
        <ActionButton onClick={handleSubmit(onSubmit)} disabled={!isDirty || !isValid}>
          Restore
        </ActionButton>
      </Modal.Footer>
    </Modal>
  );
};
