/**
 * This is the attack table that is shown in the details overview
 */

// Libs
import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { useRecoilValue } from "recoil";
import toast from "react-hot-toast";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
import { LoadingSpinner as Loading } from "components/elements";
// Utils
import {
  createColumnAttackMechanism,
  createColumnMappedNoEdit,
  createColumnNameDetails,
  createColumnLikelihoodMapped,
} from "brm/tables/services/column/columnFactory";
// import systemModals from "components/modals/Modals";
import { AttackMechanismNoteModal } from "brm/risk-model/attack/modals/AttackMechanismNoteModal";
// Hooks
import { useModal } from "hooks";
import { useRoles } from "features/brm/hooks/useRoles";
import * as AttackApi from "brm/risk-model/attack/api";
// State
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
// Styles
import * as S from "brm/styles/details-table.styles";
// Local
import { ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { RoutePath } from "routes/route-paths";
import { useElementAttacks } from "./api";

const AttackTable = ({ elementNameInUrl, selectedElement, showDelete = false, tableTitle }) => {
  const { isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const { isVisible, toggle } = useModal();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [mechanism, setMechanism] = useState("");
  const [, setSelectedAttack] = useState([]);

  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { data: mechanismNotes, isError: isMechanismNoteError } = AttackApi.useAttackMechanismNote({
    mechanism,
    config: { enabled: !!mechanism && isRiskAnalyst },
  });

  const { data: attacksData, isError: isGetAttacksDataError } = useElementAttacks({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    variantId,
    config: {
      enabled: !!selectedElement.id && !!elementNameInUrl && !!projectId && !!variantId && isRiskAnalyst,
    },
  });

  const columns = useMemo(() => {
    if (isRiskAnalyst) {
      return [
        createColumnNameDetails(RoutePath.Attack.replace(":id", "")),
        createColumnLikelihoodMapped("atkMitigatedlikelihood"),
        createColumnMappedNoEdit("target"),
        createColumnMappedNoEdit("systemasset"),
        createColumnMappedNoEdit("trev"),
        createColumnMappedNoEdit("kind"),
        createColumnMappedNoEdit("tactic"),
        createColumnAttackMechanism(setMechanism, setModalIsOpen),
      ];
    }
    return [
      createColumnMappedNoEdit("name"),
      createColumnMappedNoEdit("target"),
      createColumnMappedNoEdit("systemasset"),
      createColumnMappedNoEdit("trev"),
      createColumnMappedNoEdit("kind"),
      createColumnMappedNoEdit("tactic"),
      createColumnAttackMechanism(setMechanism, setModalIsOpen),
    ];
  }, [isRiskAnalyst]);

  if (isGetAttacksDataError) {
    toast.error(<span>Error while loading attacks</span>);
  }

  if (isMechanismNoteError) {
    toast.error(<span>Error while loading attack mechanism note</span>);
  }

  if (attacksData) {
    return (
      <S.DetailsContainer id="AttackTable_detailsPanel">
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <AttackMechanismNoteModal
              isVisible={modalIsOpen}
              title={mechanism}
              mechanisms={mechanismNotes}
              onClosing={() => setModalIsOpen(false)}
            />
            {/* {mechanismNote &&
              modalIsOpen &&
              systemModals.customModal(
                "AttackTable_detailsPanel",
                modalIsOpen,
                setModalIsOpen,
                <div>{mechanismNote}</div>,
                `${mechanism}`
              )} */}
            <BrmDetailTable
              data={attacksData}
              columns={columns}
              setSelectedElement={setSelectedAttack}
              customProps={{ id: "AttackTable_detailsTable" }}
              showRowSelect={showDelete}
              elementName={elementNameInUrl}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

AttackTable.propTypes = {
  elementNameInUrl: PropTypes.string.isRequired,
  selectedElement: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  showDelete: PropTypes.bool,
  tableTitle: PropTypes.string,
};

export default AttackTable;
