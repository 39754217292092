// TODO horrible... put this somewhere better
import styled from "styled-components";

export const AddAllocatedControlPanel = styled.div`
  max-height: 90vh;
  overflow-y: auto;
`;

export const menuListStyle = {
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: "150px",
    overflowY: "auto",
  }),
  dropdownIndicator: () => ({
    padding: "8px",
    color: "#005ca6",
  }),
};

export const menuListAndContainerStyle = {
  ...menuListStyle,
  container: (provided: any) => ({
    ...provided,
    maxHeight: "120px",
    overflowY: "auto",
  }),
};
