import * as Recoil from "recoil";
import Toolbar, { Separator } from "components/elements/NavigationToolbar";

import { contentTypeState, CONTENT_TYPE } from "atoms/atoms-content";
import { DrawerContentType } from "features/brm";
import { RightSidebarFixedButtons as FixedButtons } from "./RightSidebarFixedButtons";
import { RightSidebarSystemDiagramButtons as SystemDiagramButtons } from "./RightSidebarSystemDiagramButtons";
import { RightSidebarContextDiagramButtons as ContextDiagramButtons } from "./RightSidebarContextDiagramButtons";
import { RightSidebarAttackGraphButtons as AttackGraphButtons } from "./RightSidebarAttackGraphButtons";
import withCondition from "./withCondition";

interface IHasType {
  type: string;
}

export const RightSidebarToolbar = ({ onClick }: { onClick: (type: DrawerContentType) => void }) => {
  const contentType = Recoil.useRecoilValue(contentTypeState);

  const isTypeCondtion = ({ type }: IHasType) => contentType === type;
  const CondSystemDiagramButtons = withCondition(isTypeCondtion, SystemDiagramButtons);
  const CondContextDiagramButtons = withCondition(isTypeCondtion, ContextDiagramButtons);
  const CondAttackGraphButtons = withCondition(isTypeCondtion, AttackGraphButtons);

  return (
    <Toolbar orientation="vertical">
      <FixedButtons onClick={onClick} />
      <Separator />
      <CondAttackGraphButtons type={CONTENT_TYPE.attack} onClick={onClick} />
      <CondSystemDiagramButtons type={CONTENT_TYPE.system} onClick={onClick} />
      <CondContextDiagramButtons type={CONTENT_TYPE.context} onClick={onClick} />
    </Toolbar>
  );
};
