import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./activity-query-key-factory";

interface ISetActivityIsFinalProps {
  activityId: string;
  isFinal: string;
}

/**
 * Sets isFinal value of an activity
 *
 * @param {string} activityId - uuid of the activity
 * @param {string} isFinal - isFinal value of the activity
 */
export const setActivityIsFinal = ({ activityId, isFinal }: ISetActivityIsFinalProps) => {
  return Brm.activityApi.setActivityIsFinal(activityId, { body: isFinal });
};

export interface IUseSetActivityIsFinal {
  options?: MutationOption<typeof setActivityIsFinal>;
}

/**
 * Custom hook to change the isFinal value of an existing activity
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetActivityIsFinal = ({ options }: IUseSetActivityIsFinal) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.activitiesKeys.all);
    },
    ...options,
    mutationFn: setActivityIsFinal,
  });
};
