import * as React from "react";

import SelectBoxCategory from "components/forms/DynamicSelectBoxCategory";
import { stringToBoolean } from "utils/boolean-checker";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import { DataFlowCreateDto } from "@kdmanalytics/brm-system";
import PropTypes from "prop-types";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import BooleanDropdown from "components/forms/BooleanDropdown";
import SelectAll from "components/forms/SelectAllReactSelect";
import { sortUpperCase } from "utils/sorting";
import TextField from "components/forms/TextField";
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
import { LoadingSpinner as Loading } from "components/elements";
import { SystemApi } from "features/brm";
import { validateNameUniqueness } from "features/system-model";

const SELECT_CATEGORY = "Select Category";

const AddDataflowForm = ({ setModalIsOpen }) => {
  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = React.useState("");
  const [selectedExchanges, setSelectedExchanges] = React.useState([]);
  const [isTextValid, setIsTextValid] = React.useState(true);
  const { mutate: createDataflow } = SystemApi.useCreateDataflow();

  const { data: dataflows } = SystemApi.useDataflows({ projectId, options: { enabled: !!projectId } });

  const { data: elementCategories } = SystemApi.useElementCategories({
    projectId,
    options: {
      select: React.useCallback((data) => data.sort(sortUpperCase), []),
    },
  });

  const { data: activities } = SystemApi.useActivities({
    projectId,
    options: {
      select: React.useCallback((data) => data.map((d) => ({ value: d.id, label: d.name })), []),
    },
  });

  const { data: exchanges } = SystemApi.useExchanges({
    projectId,
    options: {
      select: React.useCallback((data) => data.map((d) => ({ value: d.id, label: d.name })), []),
    },
  });

  function validate(formData) {
    let error = true;
    if (
      formData.name.value === "" ||
      formData.category.value === SELECT_CATEGORY ||
      formData.isInternal.value === null ||
      selectedExchanges.length === 0
    ) {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isNameValid = validateNameUniqueness(dataflows, e.target.elements.name.value);
    if (!isNameValid) {
      setPostError(FORM_ERROR.duplicateDataflowName);
      return null;
    }

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      const exchangeArray = [];
      if (selectedExchanges.length > 0) {
        selectedExchanges.forEach(async (exchange) => {
          exchangeArray.push(exchange.value);
        });
      }

      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value,
        category: e.target.elements.category.value,
        isInternal: stringToBoolean(e.target.elements.isInternal.value),
        exchange: exchangeArray,
      };

      const dto = DataFlowCreateDto.constructFromObject(params);

      createDataflow(
        { projectId, dataflowCreateDto: dto },
        {
          onSettled: () => {
            setModalIsOpen(false);
          },
          onError: () => {
            setPostError("Error occured while adding Dataflow");
          },
        }
      );
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  const handleExchangeSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedExchanges(items);
  };

  if (elementCategories && activities && exchanges) {
    return (
      <div>
        <form onSubmit={handleSubmit} action="">
          <FormStyled>
            <div className="form-style">
              <TextField
                label={{ id: "AddDataflowForm_name", name: FORM_LABEL.nameMandatory }}
                input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "AddDataflowForm_note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />

              <BooleanDropdown
                label={{ id: "AddDataflowForm_isInternal", name: FORM_LABEL.internalMandatory }}
                select={{ id: "isInternal", name: "isInternal" }}
              />

              <label id="AddDataflowForm_category">{FORM_LABEL.categoryMandatory}</label>
              <SelectBoxCategory
                id="AddDataflowForm_categoryDropdown"
                arrayOfData={elementCategories}
                name="Category"
                item="category"
              />

              <label id="AddDataflowForm_exchanges">{FORM_LABEL.exchangesMandatory}</label>
              <SelectAll
                id="AddDataflowForm_exchangeDropdown"
                classNamePrefix="exchangeDropdown"
                isMulti
                options={exchanges}
                defaultValue={selectedExchanges}
                value={selectedExchanges}
                onChange={handleExchangeSelectChange}
                allowSelectAll
                allOption={{
                  label: "Select all",
                  value: "*",
                }}
              />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="AddDataflowForm_cancelButton">
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled type="submit" id="AddDataflowForm_addButton">
              Add
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }
  return <Loading />;
};

AddDataflowForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};

export default AddDataflowForm;
