import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./exchange-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

interface ISetExchangeDataProps {
  exchangeId: string | null;
  dataTypeId: string;
}

/**
 * Set the exchange Data
 *
 * @param param0 exchange and datatype
 * @returns
 */
export const setExchangeData = ({ exchangeId, dataTypeId }: ISetExchangeDataProps) => {
  return exchangeId === null
    ? Promise.reject(new Error("Invalid exchangeId id"))
    : Brm.exchangeApi.setExchangeData(exchangeId, { body: dataTypeId });
};

interface ISetExchangeDataQueryProps {
  options?: MutationOption<typeof setExchangeData>;
}

/**
 * Custom Hook to set the datatype for an exchange.
 *
 * @returns react-query Mutation.
 */
export const useSetExchangeData = ({ options }: ISetExchangeDataQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(KeyFactory.exchangeKeys.exchange(variables.exchangeId));
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: setExchangeData,
  });
};
