/**
 * Data structure that adds and removes elements in a FILO order.
 *
 * Uses WeakMaps and IIFE to hide implementation details.
 */
const Stack = () => {
  const items = new WeakMap();
  return class StackImpl {
    constructor() {
      items.set(this, []);
    }

    /**
     * Push element on to the stack.
     * @param {ANY} element
     */
    push(element) {
      const temp = items.get(this);
      temp.push(element);
    }

    /**
     * Remove element from the top of the stack.
     * @returns {any} removed element
     */
    pop() {
      const temp = items.get(this);
      return temp.pop();
    }

    /**
     * Return the element on the top of the stack without removing it.
     * @returns {any} elment on the top of the stack
     */
    peek() {
      const temp = items.get(this);
      return temp[temp.length - 1];
    }

    /**
     * Returns true if there are no elments on the stack, otherwise false.
     * @returns {bool}
     */
    isEmpty() {
      const temp = items.get(this);
      return temp.length === 0;
    }

    /**
     * Removes all elements from the stack.
     */
    clear() {
      const temp = items.get(this);
      temp.length = 0;
    }

    /**
     * Returns the current number of elements on the stack.
     * @returns {int}
     */
    size() {
      const temp = items.get(this);
      return temp.length;
    }
  };
};

export default Stack();
