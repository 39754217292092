import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import * as Brm from "services/brm";

interface IUserEmailProps {
  userId: string;
  newPassword: string;
}

/**
 * Set the user email
 *
 * @param param0 upload file data
 * @returns
 */
export const resetUserPassword = ({ userId, newPassword }: IUserEmailProps) => {
  return userId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.userApi.setUserResetPassword(userId, { body: newPassword });
};

interface ISetNameProps {
  options?: MutationOption<typeof resetUserPassword>;
}

/**
 * mutate the user email.
 *
 * @returns react-query Mutation.
 */
export const useResetUserPassword = ({ options }: ISetNameProps = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: resetUserPassword,
  });
};
