/* eslint-disable import/prefer-default-export */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

export const useAttackKinds = (projectId, config = {}) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["attackKinds", projectId],
    queryFn: () => Brm.attackKindApi.findAttackKind(projectId),
  });
};
