import { COMMON } from "constants/brm";
import PropTypes from "prop-types";

import InLineSelectBox from "./InLineSelectBoxForUsers";

const EditCellUsersDropDown = ({ selectedRowId, field, dropdownList, elementId, id, editMode, setter }) => {
  const updateValues = (value) => {
    setter(value);
  };

  if (editMode && selectedRowId === elementId) {
    return <InLineSelectBox arrayOfData={dropdownList} item={id} updateValues={updateValues} value={field} />;
  }
  if (field === null || field === "" || field === undefined) {
    return COMMON.hyphen;
  }
  return <>{field}</>;
};

EditCellUsersDropDown.propTypes = {
  dropdownList: PropTypes.any,
  editMode: PropTypes.any,
  elementId: PropTypes.any,
  field: PropTypes.string,
  id: PropTypes.any,
  selectedRowId: PropTypes.any,
  setter: PropTypes.func,
};

export default EditCellUsersDropDown;
