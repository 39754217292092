import * as React from "react";
import RulesTable from "brm/tables/details/RuleTable";
import { UndesiredEventRulesetTable } from "features/knowledgebase/components/tables/UndesiredEventRulesetTable";
import { RulesetsTable } from "../RulesetsTable";

export const RulesetTable = ({ selectedElement, kbId }: any) => {
  const [isRulesetClicked, setIsRulesetClicked] = React.useState(false);
  const [selectedRuleset, setSelectedRuleset] = React.useState<any>();

  if (isRulesetClicked && selectedRuleset) {
    if (selectedRuleset.name === "Attack") {
      return (
        <RulesTable
          kbId={kbId}
          selectedElement={selectedElement}
          setIsRulesetClicked={setIsRulesetClicked}
          selectedRuleset={selectedRuleset}
        />
      );
    }
    if (selectedRuleset.name === "UE") {
      return (
        <UndesiredEventRulesetTable
          kbId={kbId}
          selectedElement={selectedElement}
          setIsRulesetClicked={setIsRulesetClicked}
          selectedRuleset={selectedRuleset}
        />
      );
    }
  }

  return (
    <RulesetsTable kbId={kbId} setIsRulesetClicked={setIsRulesetClicked} setSelectedRuleset={setSelectedRuleset} />
  );
};
