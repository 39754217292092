import Keycloak from "keycloak-js";

const keycloakConfig: Keycloak.KeycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_HOST,
  realm: "brm",
  clientId: "brm",
};

export const keycloakInitOptions: Keycloak.KeycloakInitOptions = {
  onLoad: "login-required",
  checkLoginIframe: false,
  // enableLogging: true,
};
// export const keycloakInitOptions: Keycloak.KeycloakInitOptions = { onLoad: "login-required", checkLoginIframe: true };
// export const keycloakInitOptions: Keycloak.KeycloakInitOptions = { onLoad: "login-required" };

export const keycloak = new Keycloak(keycloakConfig);

// keycloak.createLogoutUrl = (): string => {
//   const url = encodeURIComponent(window.location.href);
//   const logoutUrl = `https://scruffy.kdma.click/realms/brm/protocol/openid-connect/logout?id_token_hint=${keycloak.idToken}&post_logout_redirect_uri=${url}`;
//   return logoutUrl;
// };
