import styled from "styled-components/macro";

interface ILabelProps {
  isActive: boolean;
  isCompleted: boolean;
}

export const Container = styled.span`
  display: flex;
  align-items: center;
  text-align: left;
  padding: 8px 0;
`;

export const StepLabelIconContainer = styled.span`
  display: flex;
  padding-right: 8px;
  flex-shrink: 0;
`;

export const StepLabelLabelContainer = styled.span<ILabelProps>`
  width: 100%;
  color: ${({ theme, isActive, isCompleted }) => {
    if (isActive || isCompleted) {
      return "#FFF";
    }
    return theme.colors.disabledLight;
  }};
  transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: ${({ isActive }) => {
    if (isActive) {
      return 600;
    }
    return 500;
  }};
`;

export const StepLabelLabel = styled.span`
  display: block;
`;
