import * as React from "react";
import * as Recoil from "recoil";
import PropTypes from "prop-types";
// Styles
import CustomTable from "components/elements/CustomTableDivStyled";
// Components
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";
// State
import { selectedInstanceState } from "atoms/atoms-content";
// Constants
import { TYPE, STATUS, COMMON } from "constants/brm";

import {
  createColumnName,
  createColumnMapped,
  createColumnOrganization,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnBooleanNoEditMapped,
  createColumnBooleanMapped,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";

// Hooks
// import { riskUpdatedState } from "atoms/atoms-risk";
import { useRoles } from "features/brm/hooks/useRoles";
import { LicenseAlert } from "components/elements";
import { useProject, LICENSE_VALIDATION_ERROR_STATUS } from "features/brm";
import toast from "react-hot-toast";
import useProjectRename from "./hooks/useProjectRename";
import { useProjectPutStatus } from "./hooks/useProjectStatus";
import useProjectListed from "./hooks/useProjectListed";
import { useRoleProjects } from "./hooks/useRoleProjects";

const statusList = [
  { id: STATUS.active, name: STATUS.active },
  { id: STATUS.suspended, name: STATUS.suspended },
  { id: STATUS.inPreparation, name: STATUS.inPreparation },
];

export const ProjectTable = ({ setSelectedRows }) => {
  const [, { setCurrentProject }] = useProject();

  const { isAdmin, isSuperAdmin, isCyberSecurityExpert, isSystemEngineer, currentRoleHasProjectContext, userRole } =
    useRoles();

  const { mutate: renameProject } = useProjectRename();
  const { mutate: setProjectStatus } = useProjectPutStatus();
  const { mutate: setProjectListed } = useProjectListed();

  const [editMode, setEditMode] = React.useState(false);
  const updatedName = React.useRef("");
  const updatedStatus = React.useRef("");
  const updatedIsListed = React.useRef("");
  const [selectedInstance, setSelectedInstance] = Recoil.useRecoilState(selectedInstanceState);

  const {
    projects,
    isUserProjectsError,
    isAdminProjectsError,
    isSuperAdminProjectsError,
    userProjectsError,
    adminProjectsError,
    // superAdminProjectsError,
  } = useRoleProjects();

  const handleProjectClick = React.useCallback(
    (rowData) => {
      setSelectedInstance({ ...rowData, type: TYPE.project });

      const proj = projects.find((p) => p.id === rowData.id);
      const projState = {
        id: proj.id,
        name: proj.name,
        status: proj.status,
        isListed: proj.isListed,
        organization: {
          id: proj.organization.id,
          name: proj.organization.name,
          status: proj.organization.status,
        },
      };

      if (currentRoleHasProjectContext(userRole)) {
        setCurrentProject(projState);
      }
    },
    [currentRoleHasProjectContext, projects, setCurrentProject, setSelectedInstance, userRole]
  );

  const hideCheckBoxCondition = React.useCallback((row) => row.original.status !== STATUS.suspended, []);

  React.useEffect(() => {
    if (!isAdmin && !isSuperAdmin) {
      setEditMode(false);
    }
  }, [isAdmin, isSuperAdmin]);

  const createButton = React.useCallback(
    (cellProps) => {
      const handleConfirmEdit = async () => {
        setEditMode(false);
        // only perform update if updatedName is not an empty string
        if (updatedName.current !== "") {
          if (projects.some((p) => p.name === updatedName.current)) {
            toast.error(`A project with the name ${updatedName.current} already exists choose another name`);
            return;
          }
          // send request to update  name
          renameProject(
            { projectId: selectedInstance.id, name: updatedName.current },
            {
              onSettled: () => {
                setSelectedInstance({
                  id: selectedInstance.id,
                  name: updatedName.current,
                  type: selectedInstance.type,
                });
                if (currentRoleHasProjectContext()) {
                  setCurrentProject((prev) => {
                    if (prev) {
                      return { ...prev, name: updatedName.current };
                    }
                    return null;
                  });
                }
                updatedName.current = "";
              },
              onError: (err) => {
                toast.error(`Unable to rename project: ${err}`);
              },
            }
          );
        }

        // send request to update status
        if (updatedStatus.current && updatedStatus.current !== "Select status") {
          // only perform update if updatedStatus is not an empty string
          setProjectStatus(
            { projectId: selectedInstance.id, status: updatedStatus.current },
            {
              onError: (err) => {
                toast.error(`Unable to change project status: ${err}`);
              },
            }
          );
          // project status will be updated from backend....
          // setCurrentProject((prev) => ({ ...prev, status: updatedStatus.current }));
          updatedStatus.current = "";
        }

        // send request to update isListed
        if (updatedIsListed.current && updatedIsListed.current !== "Select isListed") {
          setProjectListed(
            { projectId: selectedInstance.id, isListed: updatedIsListed.current },
            {
              onError: (err) => {
                toast.error(`Unable to change projects listed status: ${err}`);
              },
            }
          );
          if (currentRoleHasProjectContext()) {
            setCurrentProject((prev) => {
              if (prev) {
                return { ...prev, isListed: updatedIsListed.current };
              }
              return null;
            });
          }
          updatedIsListed.current = "";
        }
      };

      if (isSuperAdmin || isAdmin) {
        if (cellProps.cell.row.original.status !== STATUS.deleted) {
          return (
            <>
              <ButtonCell
                selectedRowId={selectedInstance.id}
                elementId={cellProps.cell.row.original.id}
                handleConfirmEditClick={handleConfirmEdit}
                setEditMode={setEditMode}
                editMode={editMode}
              />
            </>
          );
        }
        return COMMON.na;
      }
      return COMMON.na;
    },
    [
      isSuperAdmin,
      isAdmin,
      projects,
      renameProject,
      selectedInstance.id,
      selectedInstance.type,
      setSelectedInstance,
      currentRoleHasProjectContext,
      setCurrentProject,
      setProjectStatus,
      setProjectListed,
      editMode,
    ]
  );

  const pathFilter = React.useCallback(
    (path, status) => {
      if (isAdmin || isSuperAdmin) {
        return path &&
          (status === undefined ||
            status === STATUS.active ||
            status === STATUS.inPreparation ||
            status === STATUS.suspended)
          ? path
          : undefined;
      }

      if (isSystemEngineer) {
        return path && (status === undefined || status === STATUS.active || status === STATUS.inPreparation)
          ? path
          : undefined;
      }

      return path && (status === undefined || status === STATUS.active) ? path : undefined;
    },
    [isAdmin, isSuperAdmin, isSystemEngineer]
  );

  const columns = React.useMemo(() => {
    const column = [
      createColumnName(selectedInstance.id, handleProjectClick, editMode, updatedName, "projects", undefined, {
        pathFilter,
      }),
      createColumnMapped("status", selectedInstance.id, statusList, editMode, updatedStatus),
      createColumnOrganization("organization.name"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnBooleanNoEditMapped("isShared"),
      createColumnBooleanMapped("isListed", selectedInstance.id, editMode, updatedIsListed),
    ];

    if (isAdmin || isSuperAdmin || isCyberSecurityExpert) {
      column.push(createColumnActions(createButton, { disableFilters: true }));
    }

    return column;
  }, [
    isAdmin,
    isSuperAdmin,
    isCyberSecurityExpert,
    selectedInstance.id,
    handleProjectClick,
    editMode,
    pathFilter,
    createButton,
  ]);

  const isError = isSuperAdminProjectsError || isAdminProjectsError || isUserProjectsError;

  if (isError) {
    if (
      adminProjectsError?.status === LICENSE_VALIDATION_ERROR_STATUS ||
      userProjectsError?.status === LICENSE_VALIDATION_ERROR_STATUS
    ) {
      return <LicenseAlert />;
    }
    if (userProjectsError?.status === 403) {
      return <div>This user does not have permissions to view the project list</div>;
    }
    if ((isSuperAdminProjectsError && isSuperAdmin) || (isAdminProjectsError && isAdmin) || isUserProjectsError) {
      return <div>Error while loading projects.</div>;
    }
  }

  if (projects) {
    return (
      <>
        <CustomTable id="ProjectTable_table">
          <BrmMainTable
            data={projects}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "ProjectTable_table" }}
            elementName={TYPE.project}
            showRowSelect={isAdmin || isSuperAdmin || isCyberSecurityExpert}
            hideCheckBoxCondition={hideCheckBoxCondition}
          />
        </CustomTable>
      </>
    );
  }

  return <div>Loading...</div>;
};

ProjectTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};
