import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./mission-query-key-factory";

interface ISetMissionIsInternalProps {
  missionId: string;
  isInternal: string;
}

/**
 * Sets isInternal value of a mission
 *
 * @param {string} missionId - uuid of the mission
 * @param {string} isInternal - isInternal value for the mission
 */
export const setMissionIsInternal = ({ missionId, isInternal }: ISetMissionIsInternalProps) => {
  return Brm.missionApi.setMissionIsInternal(missionId, { body: isInternal });
};

export interface IUseSetMissionIsInternal {
  projectId: string;
  options?: MutationOption<typeof setMissionIsInternal>;
}

/**
 * Custom hook to change  isInternal of an existing mission
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetMissionIsInternal = ({ projectId, options = {} }: IUseSetMissionIsInternal) => {
  const queryClient = ReactQuery.useQueryClient();

  return ReactQuery.useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(KeyFactory.missionKeys.mission(variables.missionId));
      queryClient.invalidateQueries(KeyFactory.missionKeys.project(projectId));
    },
    ...options,
    mutationFn: setMissionIsInternal,
  });
};
