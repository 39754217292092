import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

/**
 * Get control types of control family
 *
 * @param {string} controlFamilyId - uuid of the control catalog
 */
export const getControlFamilyControls = ({ controlFamilyId }: { controlFamilyId: string }) => {
  return Brm.controlFamilyApi.getControlFamilyControl(controlFamilyId);
};

type FetcherFunction = typeof getControlFamilyControls;
interface ICatalogControlTypes {
  controlFamilyId: string;
  options?: QueryOption<FetcherFunction>;
}

/**
 * React-query hook for getting ControlTypes from a control catalog
 *
 * @param {string} controlFamilyId - uuid of the control Catalog
 * @param {object} options - configuration for useControlCatalog hook
 * @returns react-query for getCatalogControlTypes
 */
export const useGetControlFamilyControls = ({ controlFamilyId, options = {} }: ICatalogControlTypes) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.controlFamilyKeys.controls(controlFamilyId),
    queryFn: () => getControlFamilyControls({ controlFamilyId }),
  });
};
