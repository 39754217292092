import { useContext } from "react";
import * as Recoil from "recoil";

// Images
import { ReactComponent as PaletteIcon } from "assets/icons/studio91/system-diagram/palette.svg";
import { ReactComponent as PropertiesIcon } from "assets/icons/studio91/system-diagram/properties.svg";
import { ReactComponent as DataflowIcon } from "assets/icons/studio91/system-diagram/dataflow.svg";
import { ReactComponent as DatatypeIcon } from "assets/icons/studio91/system-diagram/datatype.svg";
import { ReactComponent as PersonIcon } from "assets/icons/studio91/system-diagram/person.svg";
import { ReactComponent as IconMapperIcon } from "assets/icons/studio91/sidebars-toolbars/icon-mapper.svg";
import { ReactComponent as LegendIcon } from "assets/icons/studio91/rm/legend.svg";

import { ThemeContext } from "styled-components";
// Components
import { BasicButton as Button } from "components/elements";
import { CONTEXT_SIDEBAR_CONTENT, contextSidebarContentState, DrawerContentType, useProjectStatus } from "features/brm";
import { useRoles } from "features/brm/hooks/useRoles";

export const RightSidebarSystemDiagramButtons = ({ onClick }: { onClick: (type: DrawerContentType) => void }) => {
  const { isReadable } = useProjectStatus();
  const { isSystemEngineer } = useRoles();
  const theme = useContext(ThemeContext);
  const setContentType = Recoil.useSetRecoilState(contextSidebarContentState);

  const {
    colors: { buttonPrimary: buttonFill },
    sizes: { standaloneIconWidth: iconWidth, standaloneIconWidth: iconHeight },
  } = theme;

  const handleButtonClick = (contentType: DrawerContentType) => {
    onClick(contentType);
    setContentType(contentType);
  };

  return (
    <>
      {isSystemEngineer ? (
        <>
          <Button
            onClick={() => handleButtonClick(CONTEXT_SIDEBAR_CONTENT.systemDiagramPalette)}
            disabled={!isReadable}
          >
            <span title="Palette">
              <PaletteIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
            </span>
          </Button>
          <Button
            onClick={() => handleButtonClick(CONTEXT_SIDEBAR_CONTENT.systemDiagramIconMapper)}
            disabled={!isReadable}
          >
            <span title="IconMapper">
              <IconMapperIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
            </span>
          </Button>
        </>
      ) : null}
      <Button onClick={() => handleButtonClick(CONTEXT_SIDEBAR_CONTENT.systemDiagramProperties)} disabled={!isReadable}>
        <span title="Properties">
          <PropertiesIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
        </span>
      </Button>
      <Button onClick={() => handleButtonClick(CONTEXT_SIDEBAR_CONTENT.systemDiagramDataflow)} disabled={!isReadable}>
        <span title="Dataflow">
          <DataflowIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
        </span>
      </Button>
      <Button onClick={() => handleButtonClick(CONTEXT_SIDEBAR_CONTENT.systemDiagramDatatype)} disabled={!isReadable}>
        <span title="Datatype">
          <DatatypeIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
        </span>
      </Button>
      <Button onClick={() => handleButtonClick(CONTEXT_SIDEBAR_CONTENT.systemDiagramPerson)} disabled={!isReadable}>
        <span title="Person">
          <PersonIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
        </span>
      </Button>
      <Button onClick={() => handleButtonClick(CONTEXT_SIDEBAR_CONTENT.systemDiagramLegend)} disabled={!isReadable}>
        <span title="Legend">
          <LegendIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
        </span>
      </Button>
    </>
  );
};
