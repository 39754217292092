import { useQuery } from "@tanstack/react-query";
import * as Brm from "services/brm";
import { responseAsArray } from "./utils/responseAsArray";
import * as KeyFactory from "../access/access-query-key-factory";
import { DetailTableType } from "../../../types/DetailTableType";
import { IElementProps, IElementMap } from "./types/elementInterface";

/**
 * custom-hook to get all accesspoints
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {object} config - configuration for the react-query
 */

export const getElementAccessPoints = async (elementId: string, elementType: string) => {
  const accessPointMap: IElementMap = {
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityAllAccesspoint(id),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeAllAccesspoint(id),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeAllAccesspoint(id),
    [DetailTableType.ATTACK_SURFACES.key]: (id) => Brm.attackSurfaceApi.getAttackSurfaceAllAccesspoint(id),
    [DetailTableType.ATTACKERS.key]: (id) => Brm.attackerApi.getAttackerAllAccesspoint(id),
    [DetailTableType.ENTRY_POINTS.key]: (id) => Brm.entryPointApi.getEntryPointAllAccesspoint(id),
    [DetailTableType.ATTACK_VECTORS.key]: (id) => Brm.attackVectorApi.getAttackVectorAllAccesspoint(id),
  };

  return responseAsArray({ map: accessPointMap, elementId, elementType });
};

export const useElementAccessPoints = ({ elementId, elementName, projectId, options = {} }: IElementProps) => {
  const elementAccessPointsQuery = useQuery({
    ...options,
    queryKey: KeyFactory.accessPointKeys.details(elementId, elementName, projectId),
    queryFn: () => getElementAccessPoints(elementId, elementName),
  });

  return elementAccessPointsQuery;
};
