import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";
import { useQueryClient } from "@tanstack/react-query";

export interface ISavePointRestoreProject {
  projectId: string;
  runId: string;
}

/**
 * Restores a save point
 *
 * @param {string} projectId - uuid of project
 * @param {string} runId - uuid of save point
 * @returns list of all save points of a given project
 */
export const savePointRestoreProject = ({ projectId, runId }: ISavePointRestoreProject) => {
  return Brm.savePointApi.savepointRestoreProjectIdRunIdPost(projectId, runId);
};

export interface IUseSavePointRestoreProject {
  options?: MutationOption<typeof savePointRestoreProject>;
}

/**
 * Custom hook to restore a save point from a new project
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for savepointRestoreProjectIdRunIdPost
 */
export const useSavePointRestoreProject = ({ options }: IUseSavePointRestoreProject = {}) => {
  const queryClient = useQueryClient();
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.clear();
    },
    ...options,
    mutationFn: savePointRestoreProject,
  });
};
