import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ListContainer = styled.div`
  overflow-y: auto;
  height: 100px;
  /* display: flex;
  flex-direction: column;
  height: 100%; */
  background: #fff;
`;

export const GridContainer = styled.div`
  padding-top: 10px;
`;
