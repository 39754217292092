import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as Brm from "services/brm";
import { STATUS } from "constants/brm";
import { AdminApi } from "features/brm";

const notDeletedStatus = (e: any) => e.status !== STATUS.deleted;

export const getRelatedOrgUsers = async ({ userId }: { userId: string | null }) => {
  if (userId === null) {
    return Promise.reject(new Error("Invalid user id"));
  }
  const orgIds = [];
  let result = [];
  try {
    const getUserOrg = await Brm.userApi.getUserOrganizationWithHttpInfo(userId); // get current user's organization id
    orgIds.push(getUserOrg.data.id);
  } catch (err) {
    console.error("error in get User organization : ", err);
  }
  const userOrgs = orgIds.map((x) => Brm.organizationApi.getOrganizationUser(x));
  const allUsersArrays = (await Promise.all(userOrgs)).reduce((arr, row) => {
    return arr.concat(row);
  }, []);
  result = allUsersArrays.filter(notDeletedStatus);
  return result;
};

interface IRelatedOrgUsersProps {
  userId: string | null;
  options?: QueryOption<typeof getRelatedOrgUsers>;
}

export const useRelatedOrgUsers = ({ userId, options }: IRelatedOrgUsersProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: AdminApi.userKeys.relatedOrg(userId),
    queryFn: () => getRelatedOrgUsers({ userId }),
  });
};
