import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./element-query-key-factory";
import { DetailTableType } from "../../../types/DetailTableType";

export interface IAddElementActivitiesProps {
  elementId: string;
  elementName: string;
  activityId: string;
}

interface IActivityMap {
  [index: string]: (id?: string) => any;
}

/**
 * Sets activity for an element
 *
 * @param {string} elementId - uuid of the element
 * @param {string} elementName - name of the element
 *  @param {string} activityId - uuid of the activity
 */
export const addElementActivities = ({ elementId, elementName, activityId }: IAddElementActivitiesProps) => {
  const activityMap: IActivityMap = {
    [DetailTableType.PERSONS.key]: (id) => Brm.personApi.addPersonActivity(id, { body: activityId }),
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.addCapabilityActivity(id, { body: activityId }),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.addNodeActivity(id, { body: activityId }),
    [DetailTableType.DATAFLOWS.key]: (id) => Brm.dataflowApi.addDataflowActivity(id, { body: activityId }),
    [DetailTableType.ACCESS_POINTS.key]: (id) => Brm.accessPointApi.addAccessPointActivity(id, { body: activityId }),
    [DetailTableType.ATTACK_VECTORS.key]: (id) => Brm.attackVectorApi.addAttackVectorActivity(id, { body: activityId }),
    [DetailTableType.ENTRY_POINTS.key]: (id) => Brm.entryPointApi.addEntryPointActivity(id, { body: activityId }),
    [DetailTableType.RESOURCES.key]: (id) => Brm.resourceApi.addResourceActivity(id, { body: activityId }),
  };

  if (activityMap[elementName]) {
    return activityMap[elementName](elementId);
  }

  return [];
};

export interface IUseAddElementActivities {
  options?: MutationOption<typeof addElementActivities>;
}

/**
 * Custom hook to add activity to an element
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useAddElementActivities = ({ options = {} }: IUseAddElementActivities = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.activityKeys.all);
    },
    ...options,
    mutationFn: addElementActivities,
  });
};
