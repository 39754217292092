export class Levels {
  static values: Levels[] = [];

  static readonly VERY_HIGH = new Levels("very low", 0, "Very Low");

  static readonly HIGH = new Levels("high", 1, "High");

  static readonly MODERATE = new Levels("moderate", 2, "Moderate");

  static readonly LOW = new Levels("low", 3, "Low");

  static readonly VERY_LOW = new Levels("very low", 4, "Very Low");

  static fromString(id: string) {
    const value = Levels.values.find((v) => v.id === id);
    if (value) return value;

    throw new RangeError(`No instance of ${this.constructor.name} exists with the id ${id}.`);
  }

  static fromNameString(name: string) {
    const value = Levels.values.find((v) => v.name === name);
    if (value) return value;

    throw new RangeError(`No instance of ${this.constructor.name} exists with the id ${name}.`);
  }

  public readonly index: number;

  public readonly id: string;

  public readonly name: string;

  constructor(id: string, index: number, name: string) {
    this.id = id;
    this.index = index;
    this.name = name;
    Levels.values.push(this);
  }

  toJSON() {
    return this.name;
  }
}
