import styled from "styled-components";

export const ControlTypeSourceContainer = styled.div`
  display: flex;
`;

export const CheckContainer = styled.div`
  margin-left: 20px;
`;

export const ControlTypeListLabel = styled.div`
  font-weight: 550;
`;
