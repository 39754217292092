import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";

/**
 * Fetches the node with the given id
 *
 * @param {string} nodeId - uuid of a node
 * @returns NodeDto for the node with the given id
 */
export const getNode = ({ nodeId }: { nodeId: string }) => {
  return Brm.nodeApi.getNodeByID(nodeId);
};

interface IUseNodeProps {
  nodeId: string;
  options?: QueryOption<typeof getNode>;
}

/**
 * Custom hook to retreive a node.
 *
 * @param {string} nodeId - uuid of a node
 * @param {object} config - react-query configuration object
 * @returns react-query for getNodeByID
 */
export const useNode = ({ nodeId, options = {} }: IUseNodeProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.nodeKeys.node(nodeId),
    queryFn: () => getNode({ nodeId }),
  });
};
