export const CRITICALITY = [
  { label: "C1", value: "C1" },
  { label: "C2", value: "C2" },
  { label: "C3", value: "C3" },
  { label: "C4", value: "C4" },
  { label: "C5", value: "C5" },
];
export const SEVERITY = [
  { label: "S1", value: "S1" },
  { label: "S2", value: "S2" },
  { label: "S3", value: "S3" },
  { label: "S4", value: "S4" },
  { label: "S5", value: "S5" },
];
