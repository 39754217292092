import { STATUS } from "constants/brm";

interface IHasStatus {
  status: string;
}
/**
 * Negates the return value of the given function
 * @param f - predicate function that return true or false
 * @returns the opposite value of f
 */
export const not = (f: any) => (e: any) => !f(e);

/**
 * Return true if the objects status is STATUS.deleted otherwise return false
 * @param e an object with a status
 * @returns
 */
export const isDeletedStatus = (e: IHasStatus) => e.status === STATUS.deleted;

/**
 * Return true if the objects status is not STATUS.deleted otherwise return false
 * @param e an object with a status
 * @returns
 */
export const isNotDeletedStatus = (e: IHasStatus) => !isDeletedStatus(e);

/**
 * Return true if the objects status is STATUS.suspended otherwise return false
 * @param e an object with a status
 * @returns
 */
export const isSuspendedStatus = (e: IHasStatus) => e.status === STATUS.suspended;

/**
 * Return true if the objects status is STATUS.suspended otherwise return false
 * @param e an object with a status
 * @returns
 */
export const isNotSuspendedStatus = (e: IHasStatus) => !isSuspendedStatus(e);

/**
 * Return true if the objects status is STATUS.inPreparation otherwise return false
 * @param e an object with a status
 * @returns
 */
export const isInPrepStatus = (e: IHasStatus) => e.status === STATUS.inPreparation;

/**
 * Return true if the objects status is STATUS.generating otherwise return false
 * @param e an object with a status
 * @returns
 */
export const isGeneratingStatus = (e: IHasStatus) => e.status === STATUS.generating;

/**
 * Return true if the objects status is STATUS.mitigtating otherwise return false
 * @param e an object with a status
 * @returns
 */
export const isMitigatingStatus = (e: IHasStatus) => e.status === STATUS.mitigating;

/**
 * Return true if the objects status is STATUS.generating otherwise return false
 * @param e an object with a status
 * @returns
 */
export const isImportingStatus = (e: IHasStatus) => e.status === STATUS.importing;

/**
 * Return true if the objects status is STATUS.inPreparation otherwise return false
 * @param e an object with a status
 * @returns
 */
export const isNotInPrepStatus = (e: IHasStatus) => !isInPrepStatus(e);

/**
 * Return true if the objects status is STATUS.inPreparation otherwise return false
 * @param e an object with a status
 * @returns
 */
export const isViewableByRiskAnalyst = (e: IHasStatus) =>
  !(e.status === STATUS.inPreparation || e.status === STATUS.suspended || e.status === STATUS.deleted);

/**
 * Return true if the objects status is STATUS.active otherwise return false
 * @param e an object with a status
 * @returns
 */
export const isActiveStatus = (e: IHasStatus) => e.status === STATUS.active;

/**
 * Return true if the objects status is STATUS.importing otherwise return false
 * @param e an object with a status
 * @returns
 */

export const getControlsCoordinatorFilteredProjects = (project: IHasStatus) =>
  isInPrepStatus(project) || isActiveStatus(project) || isSuspendedStatus(project) || isImportingStatus(project);

export const getCyberSecurityExpertFilteredProjects = (project: IHasStatus) =>
  isInPrepStatus(project) || isActiveStatus(project) || isSuspendedStatus(project) || isImportingStatus(project);
