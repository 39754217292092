import errorUtils from "utils/error-utils";

const DEBUG = false; // enable or disable debugging

async function handleApi(promise, opts) {
  const tag = opts?.tag ? opts.tag : ""; // to find out which request
  try {
    const { data, response } = await promise;
    if (response) {
      errorUtils.checkErrorStatus(response.status, response.statusText);
      if (DEBUG) {
        console.error(`${tag} :: response from promise :: response : `, `${response.statusText} data : `, data);
      }
    } else if (DEBUG) {
      console.error(`${tag} :: received no response from promise`);
    }
  } catch (err) {
    console.error(`${tag} :: err in making the api call : `, err);
  }
}

export default handleApi;
