import styled from "styled-components/macro";
import { Form, Button, ListGroup } from "react-bootstrap";
import ReactSelect from "react-select";

const systemDiagramPanelStyles = {
  Container: styled.div`
    display: flex;
    flex-flow: column nowrap;
    background: #ffffff;
    height: 100%;
    width: calc(100% - var(--navigationToolbarWidth));
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px;
  `,
  PanelTitle: styled.h2`
    font-size: 18px;
    letter-spacing: -0.003em;
    font-weight: 600;
    color: #42526e;
    padding: 10px 20px;
  `,
  SectionTitle: styled.h6`
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: 2px solid #ebecf0;
    border-top: 2px solid #ebecf0;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.fonts.large};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.veryHighContrastBackground};
    background-color: #fafbfc;
  `,
  SubSectionTitle: styled.h6`
    text-align: left;
    padding: 2px 5px;
    font-size: 14px;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
  `,
  SectionContainer: styled.div`
    display: flex;
    flex-flow: column nowrap;
    font: ${(props) => props.theme.tablefont};
  `,
  TableContainer: styled.div`
    padding: 5px;
    margin-bottom: 10px;
  `,
  AddForm: styled.form`
    padding: 5px 10px;
  `,
  AddFormError: styled.p`
    color: red;
  `,
  FormGroup: styled(Form.Group)`
    margin-bottom: 0.2rem;
  `,
  FormAddButton: styled(Button)`
    float: right;

    &.btn-brm {
      background-color: ${({ theme }) => theme.colors.primaryButton};
      color: white;
    }
  `,
  PropertyContainer: styled.div`
    padding: 0px 4px;
  `,
  PropertyContainerDisabled: styled.div`
    padding: 0px 6px;
    text-align: center;
  `,
  PropertyError: styled.span`
    color: ${({ theme }) => theme.colors.errorText};
  `,
  MultiSelect: styled(ReactSelect)`
    & .react-select__control {
      min-height: 27px;
    }
    & .react-select__value-container {
      /* Ignore */
    }

    & .react-select-container {
      /* Ignore */
    }
    & .react-select__control--is-focused {
      min-height: 27px;
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
    & .react-select__indicator {
      padding: 2px;
    }
    & .react-select__indicator-separator {
      margin-bottom: 7px;
      margin-top: 4px;
    }
    & .react-select__multi-value_label {
      /* Ignore */
    }
  `,
  FakeLink: styled.span`
    cursor: pointer;
    color: blue;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  `,

  UnorderedListStyle: styled(ListGroup)`
    margin: 5px;
    li {
      padding: 4px 0px;
    }
  `,
};

export default systemDiagramPanelStyles;
