import PropTypes from "prop-types";
import { useState, useMemo, useRef, useCallback } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import ButtonCell from "components/EditComponents/ButtonCell";
import {
  createColumnCategoryMapped,
  createColumnMapped,
  createColumnMappedNoEdit,
  createColumnNameDetails,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import { useModal } from "hooks";
import { useRoles } from "features/brm/hooks/useRoles";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { SystemApi, ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";
// Styles

const SystemAssetTable = ({ elementNameInUrl, selectedElement, showDelete = false, tableTitle }) => {
  const [selectedSystemAsset, setSelectedSystemAsset] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const { isRiskAnalyst, isSystemEngineer, isThreatAnalyst } = useRoles();
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();
  const updatedCategory = useRef("");
  const updatedSaType = useRef("");
  const updatedOwner = useRef("");

  const { data: systemAssetData, isError: isSystemAssetDataError } = SystemApi.useElementSystemAssets({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    variantId,
    options: {
      enabled: !!selectedElement.id && (isRiskAnalyst || isSystemEngineer || isThreatAnalyst),
    },
  });
  const { data: categoryList } = SystemApi.useSystemAssetCategories({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer || isThreatAnalyst) },
  });
  const { data: saTypeList } = SystemApi.useSystemAssetsTypes({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer || isThreatAnalyst) },
  });
  const { data: ownerList } = SystemApi.useNodes({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer || isThreatAnalyst) },
  });

  const { mutate: setSystemAssetCategory } = SystemApi.useSetSystemAssetCategory();
  const { mutate: setSystemAssetType } = SystemApi.useSetSystemAssetSystemAssetType();
  const { mutate: setSystemAssetOwner } = SystemApi.useSetSystemAssetOwner();

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedSystemAsset.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedCategory.current !== "") {
              setSystemAssetCategory(
                {
                  saId: selectedSystemAsset.id,
                  saCat: updatedCategory.current,
                },
                {
                  onSettled: () => {
                    updatedCategory.current = "";
                  },
                }
              );
            }

            if (updatedSaType.current !== "") {
              setSystemAssetType(
                {
                  saId: selectedSystemAsset.id,
                  saType: updatedSaType.current,
                },
                {
                  onSettled: () => {
                    updatedSaType.current = "";
                  },
                }
              );
            }

            if (updatedOwner.current !== "") {
              setSystemAssetOwner(
                {
                  saId: selectedSystemAsset.id,
                  owner: updatedOwner.current,
                },
                {
                  onSuccess: () => {
                    updatedOwner.current = "";
                  },
                }
              );
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [selectedSystemAsset.id, editMode, setSystemAssetCategory, setSystemAssetType, setSystemAssetOwner]
  );

  const columns = useMemo(() => {
    if (isRiskAnalyst || isSystemEngineer) {
      return [
        createColumnNameDetails(RoutePath.SystemAsset.replace(":id", "")),
        createColumnCategoryMapped("category", selectedSystemAsset.id, categoryList, editMode, updatedCategory),
        createColumnMapped("satype", selectedSystemAsset.id, saTypeList, editMode, updatedSaType),
        createColumnMapped("owner", selectedSystemAsset.id, ownerList, editMode, updatedOwner),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }

    return [
      createColumnMappedNoEdit("name"),
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("satype"),
      createColumnMappedNoEdit("owner"),
    ];
  }, [
    categoryList,
    createButton,
    editMode,
    isRiskAnalyst,
    isSystemEngineer,
    ownerList,
    saTypeList,
    selectedSystemAsset.id,
  ]);

  if (isSystemAssetDataError) {
    return <ErrorBanner msg="Error while loading System Asset Data" />;
  }

  if (systemAssetData) {
    return (
      <S.DetailsContainer id="SystemAssetTable_detailsPanel">
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={systemAssetData}
              columns={columns}
              setSelectedElement={setSelectedSystemAsset}
              customProps={{ id: "SystemAssetTable_detailsTable" }}
              showRowSelect={showDelete}
              elementName={elementNameInUrl}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }
  return <Loading />;
};

SystemAssetTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  showDelete: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default SystemAssetTable;
