import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { SystemApi } from "features/brm";

export const getCategorizedEntity = async ({ ceId }) => {
  const data = await Brm.categorizedEntityApi.getCategorizedEntityByID(ceId);
  if (data?.subject) {
    const subject = await Brm.systemElementApi.getSystemElementName(data.subject);
    data.subject = { id: data.subject, name: subject?.result };
  }

  return [data];
};

/**
 * React-query hook for getting  Categorized Entity details by id
 *
 * @param {string} ceId - uuid of the categorized entity
 * @returns react-query for getCategorizedEntity
 */
export const useCategorizedEntity = ({ ceId, setSelectedElement }) => {
  return ReactQuery.useQuery({
    queryKey: SystemApi.categorizedEntityKeys.categorizedEntity(ceId),
    queryFn: () => getCategorizedEntity({ ceId }),
    enabled: !!ceId,
    onSuccess: (data) => {
      setSelectedElement(data[0]);
    },
  });
};
