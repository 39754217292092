import { useQuery } from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { responseAsArray } from "./utils/responseAsArray";
import * as KeyFactory from "../categorizedEntity/categorized-entity-query-key-factory";
import { DetailTableType } from "../../../types/DetailTableType";
import { IElementMap } from "./types/elementInterface";

interface IGetElementCategorizedEntityProps {
  elementId: string;
  elementName: string;
}

export const getElementCategorizedEntity = async ({ elementId, elementName }: IGetElementCategorizedEntityProps) => {
  const catEntityMap: IElementMap = {
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityCategorizedentity(id),
    [DetailTableType.ASSETS.key]: (id) => Brm.assetApi.getAssetCategorizedentity(id),
    [DetailTableType.BUSES.key]: (id) => Brm.busApi.getBusCategorizedentity(id),
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.getCapabilityCategorizedentity(id),
    [DetailTableType.DATAFLOWS.key]: (id) => Brm.dataflowApi.getDataFlowCategorizedentity(id),
    [DetailTableType.DATATYPES.key]: (id) => Brm.dataTypeApi.getDataTypeCategorizedentity(id),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeCategorizedentity(id),
    [DetailTableType.LINKS.key]: (id) => Brm.linkApi.getLinkCategorizedentity(id),
    [DetailTableType.MISSIONS.key]: (id) => Brm.missionApi.getMissionCategorizedentity(id),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeCategorizedentity(id),
    [DetailTableType.PERSON.key]: (id) => Brm.personApi.getPersonCategorizedentity(id),
    [DetailTableType.RESOURCES.key]: (id) => Brm.resourceApi.getResourceCategorizedentity(id),
  };

  return responseAsArray({ map: catEntityMap, elementId, elementType: elementName });
};

interface IUseElementCategorizedEntityProps {
  elementId: string;
  elementName: string;
  options: QueryOption<typeof getElementCategorizedEntity>;
}

/**
 * Custom hook to retrieve categorized entities of an element
 *
 * @param {string} elementId - uuid of any selected element
 * @param {string} elementName - type/category of selected element
 * @param {object} options - react-query configuration object
 * @returns list of categorized entities for any given selected element
 */
export const useElementCategorizedEntity = ({
  elementId,
  elementName,
  options = {},
}: IUseElementCategorizedEntityProps) => {
  return useQuery({
    ...options,
    queryKey: KeyFactory.categorizedEntityKeys.element(elementId, elementName),
    queryFn: () => getElementCategorizedEntity({ elementId, elementName }),
  });
};
