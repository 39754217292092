import * as React from "react";
import * as Recoil from "recoil";
import * as BrmGql from "generated/graphql";
import { queryClient } from "libs/react-query";
import log from "loglevel";
import { MitigationApi, ReportsApi, RiskApi } from "features/brm";
import { isAddingAllocatedControlsState } from "atoms/atoms-mitigation";

const POLL_INTERVAL = 3000;
const EMPTY_SCTM_IMPORT_ID = "";

export const useImportSctm = ({ onSuccess, onError }: { onSuccess?: Function; onError?: Function }) => {
  const [sctmImportId, setSctmImportId] = React.useState<string>(EMPTY_SCTM_IMPORT_ID);
  const [stop, setStop] = React.useState(false);
  const setIsAddingAllocatedControls = Recoil.useSetRecoilState(isAddingAllocatedControlsState);

  const { mutate: importSctm } = BrmGql.useImportSctmMutation({
    onMutate: () => {
      setStop(false);
      setIsAddingAllocatedControls(true);
    },
    onError: (error) => {
      // log.error("Import sctm error:", error);
      if (onError !== undefined) {
        onError(error);
      }
      setIsAddingAllocatedControls(false);
      setStop(true);
    },
    onSuccess: (data) => {
      if (data && data.importSctm) {
        setSctmImportId(data.importSctm);
      }
      queryClient.invalidateQueries(["getAllocatedControls"]); // likely deprecated...
      queryClient.invalidateQueries(MitigationApi.allocatedControlsGqlKey.all);
      setIsAddingAllocatedControls(false);
    },
  });

  BrmGql.useImportSctmStatusQuery(
    { run: sctmImportId },
    {
      onSuccess: (pollData) => {
        if (pollData.importSctmStatus === BrmGql.CompletionStatus.Completed) {
          queryClient.invalidateQueries(MitigationApi.variantsKeys.all);
          queryClient.invalidateQueries(MitigationApi.allocatedControls.all);
          queryClient.invalidateQueries(MitigationApi.allocatedControlsGqlKey.all);
          queryClient.invalidateQueries(ReportsApi.rarKeys.all);
          queryClient.invalidateQueries(RiskApi.riskKeys.all);
          setSctmImportId(EMPTY_SCTM_IMPORT_ID);
          setStop(true);
          if (onSuccess !== undefined) {
            onSuccess();
          }
        }
      },
      onError: (error) => {
        log.error("Status query Error:", error);
        setStop(true);
        if (onError !== undefined) {
          onError();
        }
      },
      enabled: sctmImportId !== EMPTY_SCTM_IMPORT_ID,
      refetchInterval: stop ? false : POLL_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );

  return {
    importSctm,
  };
};
