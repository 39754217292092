import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import ControlTypeTable from "brm/tables/details/ControlTypeTable";
import PropTypes from "prop-types";
import { DetailTableType } from "features/brm";

export const ControlFamilyTab = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.CONTROL_FAMILIES.key;

  return (
    <Tabs>
      <TabList>
        <Tab>Control Types</Tab>
      </TabList>

      <TabPanel>
        <ControlTypeTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
    </Tabs>
  );
};

ControlFamilyTab.propTypes = {
  selectedElement: PropTypes.object.isRequired,
};
