import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { COMMON } from "constants/brm";
import * as KeyFactory from "./exchange-query-key-factory";

/**
 * Retrieve producer for an exchange
 *
 * @param {string} exchangeId - uuid of exchange
 * @returns list of all persons within the given exchange
 */
export const getExchangeProducer = async ({ exchangeId }: { exchangeId: string | null }) => {
  if (exchangeId === null) {
    return Promise.reject(new Error("Invalid exchangeId id"));
  }

  const data = await Brm.exchangeApi.getExchangeProducer(exchangeId);
  if (!Array.isArray(data)) {
    return data?.id === COMMON.nullUuid ? [] : [data];
  }
  return data || [];
};

interface IExchangeProducerProps {
  exchangeId: string | null;
  options?: QueryOption<typeof getExchangeProducer>;
}

/**
 * Custom-hook to retrieve producer for an exchange.
 *
 * @param {string} exchangeId - uuid of exchange
 * @param {object} options - react-query configuration object
 * @returns react-query for findNode
 */
export const useExchangeProducer = ({ exchangeId, options = {} }: IExchangeProducerProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exchangeKeys.producer(exchangeId),
    queryFn: () => getExchangeProducer({ exchangeId }),
  });
};
