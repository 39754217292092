import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

export interface ISetSystemAssetTypeName {
  saTypeId: string;
  name: string;
}

export const setSystemAssetTypeName = ({ saTypeId, name }: ISetSystemAssetTypeName) => {
  return Brm.systemAssetTypeApi.setSystemAssetTypeName(saTypeId, { body: name });
};

export interface IUseSetSystemAssetTypeName {
  projectId: string;
  options?: MutationOption<typeof setSystemAssetTypeName>;
}

/**
 * Custom hook to change the name of a existing system asset type
 * @returns react-query ReactQuery.useMutation
 */
// eslint-disable-next-line import/prefer-default-export
export const useSetSystemAssetTypeName = ({ projectId, options }: IUseSetSystemAssetTypeName) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.systemAssetTypeKeys.project(projectId));
    },
    ...options,
    mutationFn: setSystemAssetTypeName,
  });
};
