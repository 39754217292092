import { useEffect, useMemo } from "react";
import { BrmMainTable } from "brm/tables/BrmTables";
import CustomTable from "components/elements/CustomTableDivStyled";
import PropTypes from "prop-types";
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import useRefreshTableList from "hooks/useRefreshTableList";
import { variantIdState } from "atoms/atoms-component";
import { TYPE } from "constants/brm";
import { RiskApi, useRoles } from "features/brm";

import {
  createColumnName,
  createColumnMapped,
  createColumnImpactMapped,
  createColumnMappedNoEdit,
  createColumnLikelihoodMapped,
} from "brm/tables/services/column/columnFactory";
import { LoadingSpinner } from "components/elements";

const UndesiredEventsTable = ({ setSelectedRows, refresh, setRefresh, setExportTableData }) => {
  const { isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data: undesiredEvents, isError } = RiskApi.useUndesiredEvents({
    projectId,
    variantId,
    options: { enabled: isRiskAnalyst },
  });

  const columns = useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, false, null, "undesiredEvent"),
      createColumnMapped("category", selectedInstance.id, null, false, null),
      createColumnMapped("asset", selectedInstance.id, null, false, null),
      createColumnMapped("objective", selectedInstance.id, null, false, null),
      createColumnImpactMapped("impact"),
      createColumnMappedNoEdit("anobject"),
      createColumnLikelihoodMapped("likelihood"),
      createColumnLikelihoodMapped("mitigatedlikelihood"),
    ],
    [setSelectedInstance, selectedInstance.id]
  );

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh, setRefresh]);

  if (isError) {
    return <div>Error While loading Undesired Event data</div>;
  }

  if (undesiredEvents) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={undesiredEvents}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "UndesiredEventsTable_table" }}
            showRowSelect={false}
            elementName={TYPE.ue}
            setExportTableData={setExportTableData}
          />
        </CustomTable>
      </>
    );
  }

  return <LoadingSpinner />;
};

UndesiredEventsTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  setExportTableData: PropTypes.func,
};

export default UndesiredEventsTable;
