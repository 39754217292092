// Libs
import PropTypes from "prop-types";
// Components
import { BrmDetailTableView } from "brm/tables/BrmTables/BrmDetailTableView";
// Styles
import * as S from "./styles/tables.styles";

export const BrmDetailTableSimple = ({
  prepareRow,
  headerGroups,
  selectedFlatRows,
  setSelectedElement,
  setSelectedRows,
  customProps,
  showHeaderFilters,
  getTableProps,
  getTableBodyProps,
  page,
  visibleColumns,
  elementName,
}) => {
  return (
    <S.TableDiv>
      <S.TableWrap>
        <BrmDetailTableView
          customProps={customProps}
          prepareRow={prepareRow}
          headerGroups={headerGroups}
          selectedFlatRows={selectedFlatRows}
          setSelectedElement={setSelectedElement}
          setSelectedRows={setSelectedRows}
          showHeaderFilters={showHeaderFilters}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          page={page}
          visibleColumns={visibleColumns}
          elementName={elementName}
        />
      </S.TableWrap>
    </S.TableDiv>
  );
};

BrmDetailTableSimple.propTypes = {
  prepareRow: PropTypes.func.isRequired,
  headerGroups: PropTypes.any.isRequired,
  selectedFlatRows: PropTypes.any.isRequired,
  customProps: PropTypes.shape({
    id: PropTypes.string,
  }),
  getTableProps: PropTypes.func.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  page: PropTypes.any.isRequired,
  visibleColumns: PropTypes.any.isRequired,
  setSelectedElement: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  showHeaderFilters: PropTypes.bool.isRequired,
  elementName: PropTypes.string,
};
