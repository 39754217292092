import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
// import * as S from "./AlertDialog.styles";

const AlertDialog = ({ show = false, onClose = () => {}, title = "", message }) => {
  return (
    <div>
      <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

AlertDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AlertDialog;
