import * as React from "react";
import * as Recoil from "recoil";
import { ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
// Hooks
import { useModal } from "hooks";
// Styles
import Title from "components/elements/StyledTitle";
import ContentLayout from "brm/layout/Content/ContentLayout";
import Buttons from "components/elements/TableButtonsDivStyled";
import { projectIdState } from "atoms/atoms-admin";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import riskLevelMapper from "brm/risk-model/risk/utils/RiskLevelMapper";
import { RiskFilters } from "./RiskFilters";
// Local
import RiskTable from "./tables/RiskTable";
import { useNonZeroRisks } from "./api/risk-queries";

export const Risks = () => {
  const projectId = Recoil.useRecoilValue(projectIdState);
  const { data: riskData, isError: isRiskDataError } = useNonZeroRisks(projectId);

  const [, setSelectedRows] = React.useState([]);
  const [refresh, setRefresh] = React.useState(true);
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();
  const [riskLevelFilters, setRiskLevelFilters] = React.useState([]);
  const [riskPercentageThreshold, setRiskPercentageThreshold] = React.useState(0);

  const riskHeader = (
    <Title id="Risk_title">
      Risks
      <Buttons id="Risk_buttons">
        <ExportButton onClick={() => toggle()} disableExport={disableExport} />
      </Buttons>
    </Title>
  );

  // console.log("riskData", riskData);
  const levels = riskData
    ? Array.from(
        new Set(Array.from(new Set(riskData.map((r) => r.mitigatedRiskLevel))).map((rl) => riskLevelMapper(rl)))
      )
    : [];
  // console.log("levels", levels);

  const handleLevelChange = (selectedItems) => {
    // console.log("handleLevelChange:", selectedItems);
    const items = selectedItems || [];
    setRiskLevelFilters(items);
  };

  const handlePercentageChange = (event) => {
    const percentage = parseFloat(event.target.value);
    // console.log("percentage", percentage);
    setRiskPercentageThreshold(percentage);
  };

  const filteredRiskData = React.useMemo(() => {
    if (Array.isArray(riskData)) {
      let filteredRisks = [...riskData];
      if (Array.isArray(riskLevelFilters) && riskLevelFilters.length) {
        filteredRisks = filteredRisks.filter((rd) =>
          riskLevelFilters.map((rlf) => rlf.value).find((rlf) => rlf === riskLevelMapper(rd.mitigatedRiskLevel))
        );
      }
      // if (riskPercentageThreshold) {
      // filteredRisks.forEach((f) => console.log(f.percent.value));
      filteredRisks = filteredRisks.filter((fr) => fr.percent.value > riskPercentageThreshold / 100);
      // }
      return filteredRisks;
    }
    return undefined;
  }, [riskData, riskLevelFilters, riskPercentageThreshold]);

  const maxPercent = React.useMemo(() => {
    if (riskData) {
      return Math.ceil(Math.max(...riskData.map((r) => r.percent.value)) * 100);
    }
    return 100;
  }, [riskData]);

  if (isRiskDataError) {
    return <ErrorBanner msg="Error while loading Risks" />;
  }

  if (filteredRiskData) {
    const riskMain = (
      <>
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <RiskFilters
          levels={levels}
          levelValue={riskLevelFilters}
          onLevelChange={handleLevelChange}
          percentageValue={riskPercentageThreshold}
          onPercentageChange={handlePercentageChange}
          maxPercent={maxPercent}
        />
        <RiskTable
          risks={filteredRiskData}
          setSelectedRows={setSelectedRows}
          refresh={refresh}
          setRefresh={setRefresh}
          setExportTableData={setExportTableData}
          unfilteredLength={riskData.length}
        />
      </>
    );

    return <ContentLayout pageId="Risk_page" header={riskHeader} main={riskMain} />;
  }
  return <Loading />;
};
