import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRoles } from "hooks";
import { kbApi, rulesetApi } from "services/brm";

export const useGetRuleset = (kbId) => {
  const { isCyberSecurityExpert } = useRoles();
  return useQuery(["getRulesets", kbId], () => kbApi.getKBRuleset(kbId), {
    enabled: !!kbId && isCyberSecurityExpert,
    cacheTime: 0,
  });
};

export const useUpdateRulesetName = (kbId) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ ruleSetId, ruleSetName }) =>
      rulesetApi.setRulesetNameWithHttpInfo(ruleSetId, {
        body: ruleSetName,
      }),
    {
      onMutate: async ({ ruleSetId, ruleSetName }) => {
        await queryClient.cancelQueries(["getRulesets", kbId]);
        const previousValues = queryClient.getQueryData(["getRulesets", kbId]);

        queryClient.setQueryData(["getRulesets", kbId], (old) =>
          old.map((data) => {
            if (ruleSetId === data.id) {
              return {
                ...data,
                name: ruleSetName,
              };
            }
            return data;
          })
        );
        return previousValues;
      },
      onError: (previousValues) => queryClient.setQueryData(["getRulesets", kbId], previousValues),
      onSuccess: () => {
        queryClient.invalidateQueries(["getRulesets", kbId]);
      },
    }
  );
};

export const useUpdateRulesetVersion = (kbId) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ ruleSetId, ruleSetVersion }) =>
      rulesetApi.setRulesetVersionWithHttpInfo(ruleSetId, {
        body: ruleSetVersion,
      }),
    {
      onMutate: async ({ ruleSetId, ruleSetVersion }) => {
        await queryClient.cancelQueries(["getRulesets", kbId]);
        const previousValues = queryClient.getQueryData(["getRulesets", kbId]);

        queryClient.setQueryData(["getRulesets", kbId], (old) =>
          old.map((data) => {
            if (ruleSetId === data.id) {
              return {
                ...data,
                version: ruleSetVersion,
              };
            }
            return data;
          })
        );
        return previousValues;
      },
      onError: (previousValues) => queryClient.setQueryData(["getRulesets", kbId], previousValues),
      onSuccess: () => {
        queryClient.invalidateQueries(["getRulesets", kbId]);
      },
    }
  );
};

export const useUpdateRulesetResource = (kbId) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ ruleSetId, ruleSetResource }) =>
      rulesetApi.setRulesetResourceWithHttpInfo(ruleSetId, {
        body: ruleSetResource,
      }),
    {
      onMutate: async ({ ruleSetId, ruleSetResource }) => {
        await queryClient.cancelQueries(["getRulesets", kbId]);
        const previousValues = queryClient.getQueryData(["getRulesets", kbId]);

        queryClient.setQueryData(["getRulesets", kbId], (old) =>
          old.map((data) => {
            if (ruleSetId === data.id) {
              return {
                ...data,
                resource: ruleSetResource,
              };
            }
            return data;
          })
        );
        return previousValues;
      },
      onError: (previousValues) => queryClient.setQueryData(["getRulesets", kbId], previousValues),
      onSuccess: () => {
        queryClient.invalidateQueries(["getRulesets", kbId]);
      },
    }
  );
};

export const useDeleteRuleSet = (kbId) => {
  const queryClient = useQueryClient();

  return useMutation(({ ruleSetId }) => rulesetApi.deleteRuleset(ruleSetId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getRulesets", kbId]);
    },
  });
};

export const useAddRuleSet = (kbId) => {
  const queryClient = useQueryClient();

  return useMutation(({ opts }) => rulesetApi.createRuleset(kbId, opts), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getRulesets", kbId]);
    },
  });
};

export const useRuleSetImport = () => {
  const queryClient = useQueryClient();
  return useMutation(({ rulesetId, opts }) => rulesetApi.importRuleset(rulesetId, opts), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getRulesets"]);
    },
  });
};
