import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./capability-query-key-factory";

export interface IAddElementCapabilitiesProps {
  elementId: string;
  elementName: string;
  capabilityId: string;
}

interface IcapabilityMap {
  [index: string]: (id?: string) => any;
}

/**
 * Sets capability for an element
 *
 * @param {string} elementId - uuid of the element
 * @param {string} elementName - name of the element
 *  @param {string} capabilityId - uuid of the activity
 */
export const addElementCapabilities = ({ elementId, elementName, capabilityId }: IAddElementCapabilitiesProps) => {
  const capabilityMap: IcapabilityMap = {
    missions: (id) => Brm.missionApi.addMissionCapability(id, { body: capabilityId }),
  };

  if (capabilityMap[elementName]) {
    return capabilityMap[elementName](elementId);
  }

  return [];
};

export interface IUseAddElementCapabilities {
  options?: MutationOption<typeof addElementCapabilities>;
}

/**
 * Custom hook to add capability to an element
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useAddElementCapabilities = ({ options = {} }: IUseAddElementCapabilities = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.capabilityKeys.all);
    },
    ...options,
    mutationFn: addElementCapabilities,
  });
};
