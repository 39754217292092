import * as React from "react";

import { AdminApi } from "features/brm";
import { useRoles } from "hooks";

const SAP_SETTING_NAME = "sap";
const SAP_UPDATE_SETTING_NAME = "sapUpdate";
const SAP_SIZE_SETTING_NAME = "sapSize";

interface IUseSapProps {
  isAdminMode?: boolean;
}

/**
 * Hook to determine is SAP permissions and UI elements are enabled.
 *
 * Uses the global settings and the user role to determine if SAP is enabled
 *
 */
export const useSap = ({ isAdminMode }: IUseSapProps = { isAdminMode: false }) => {
  const { isSuperAdmin, isAdmin } = useRoles();

  const { data: installations } = AdminApi.useInstallations({
    options: {
      enabled: isSuperAdmin,
      cacheTime: 0,
      retry: 1,
    },
  });

  const installationId = Array.isArray(installations) && installations.length > 0 ? installations[0].id : "";

  const { data: isSapAvailable } = AdminApi.useGetInstallationProperty({
    installationId,
    property: "sapAvailability",
    options: { enabled: installationId !== "" && isSuperAdmin, select: (data) => data.result },
  });

  const {
    data: sapSetting,
    isLoading: isNameLoading,
    isError: isNameError,
    error: nameError,
  } = AdminApi.useGlobalSetting({
    name: SAP_SETTING_NAME,
    options: { enabled: isSuperAdmin || isAdmin, cacheTime: 0, retry: 1 },
  });

  const {
    data: sapUpdateSetting,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
  } = AdminApi.useGlobalSetting({
    name: SAP_UPDATE_SETTING_NAME,
    options: { enabled: isSuperAdmin || isAdmin, cacheTime: 0, retry: 1 },
  });

  const {
    data: sapRecordCountSetting,
    isLoading: isCountLoading,
    isError: isCountError,
    error: countError,
  } = AdminApi.useGlobalSetting({
    name: SAP_SIZE_SETTING_NAME,
    options: { enabled: isSuperAdmin || isAdmin, cacheTime: 0, retry: 1 },
  });

  const isEnabled = React.useMemo(() => {
    if (sapSetting && sapSetting[0].value === "enabled" && (isSuperAdmin || (isAdmin && isAdminMode))) {
      return true;
    }
    return false;
  }, [sapSetting, isSuperAdmin, isAdmin, isAdminMode]);

  const lastSapUpdate = React.useMemo(() => {
    return Array.isArray(sapUpdateSetting) && sapUpdateSetting.length ? sapUpdateSetting[0].value : "Unknown";
  }, [sapUpdateSetting]);

  const sapRecordCount = React.useMemo(() => {
    return Array.isArray(sapRecordCountSetting) && sapRecordCountSetting.length
      ? sapRecordCountSetting[0].value
      : "Unknown";
  }, [sapRecordCountSetting]);

  const isLoading = isNameLoading || isUpdateLoading || isCountLoading;
  const isError = isNameError || isUpdateError || isCountError;
  const error = [nameError, updateError, countError].find((e) => e !== undefined);

  return React.useMemo(() => {
    return {
      isEnabled,
      isLoading,
      isSapAvailable,
      lastSapUpdate,
      sapRecordCount,
      isError,
      error,
    };
  }, [error, isEnabled, isError, isLoading, isSapAvailable, lastSapUpdate, sapRecordCount]);
};
