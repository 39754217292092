import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./person-query-key-factory";

interface IDeletePerson {
  personId: string;
}

/**
 *  Deletes a person from the system
 *
 * @param {string} personId - uuid of person to delete
 */
export const deletePerson = ({ personId }: IDeletePerson) => {
  return Brm.personApi.deletePerson(personId);
};

interface IDeletePersonQueryProps {
  options?: MutationOption<typeof deletePerson>;
}

/**
 * Custom hook to delete an existing person
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeletePerson = ({ options }: IDeletePersonQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.personKeys.all);
    },
    ...options,
    mutationFn: deletePerson,
  });
};
