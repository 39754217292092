import { useState, useMemo, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import ButtonCell from "components/EditComponents/ButtonCell";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import Title from "components/elements/StyledTitle";
import { kbApi } from "services/brm/knowledgebase-service";
import { userRoles } from "services/brm/admin-service";
import handleApi from "services/brm/api-util";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import { userRoleState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { TYPE } from "constants/brm";
import {
  createColumnModifiedAt,
  createColumnCreatedAt,
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnActions,
  createColumnMapped,
} from "brm/tables/services/column/columnFactory";
import { useKnowledgebase } from "../../hooks";
import { KnowledgebaseDetailsTab } from "../../components/tabs/KnowledgebaseDetailsTab";
import { useKbById } from "../../api";
import { KB_VERSIONS } from "../../constants";

export function KBFullDetails() {
  const { id } = useParams();
  const [selectedElement, setSelectedElement] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedVersion = useRef("");
  const userRole = useRecoilValue(userRoleState);
  const cyberSecurityExpertRole = userRoles.properties[userRoles.CYBERSECURITY_EXPERT].key;
  const [showSummaryTable, setShowSummaryTable] = useState(true);

  const { data: KbByIdData, isError: isKbByIdDataError } = useKbById(id, setSelectedElement);
  // const { data: selfData } = AdminApi.useSelf({ options: {} });
  const { isGlobalKb, isReadOnlyKb } = useKnowledgebase({ kbId: id });

  let content = <Loading />;

  const createButton = useCallback(
    (cellProps) => {
      if (userRole === cyberSecurityExpertRole && !isGlobalKb && !isReadOnlyKb) {
        return (
          <ButtonCell
            selectedRowId={selectedRowId}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedVersion.current !== "") {
                await handleApi(
                  kbApi.setKBVersionWithHttpInfo(selectedRowId, {
                    body: updatedVersion.current,
                  })
                );
                updatedVersion.current = "";
              }

              setRefresh(true);
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return null;
    },
    [userRole, cyberSecurityExpertRole, isGlobalKb, isReadOnlyKb, selectedRowId, editMode]
  );

  const columns = useMemo(
    () => [
      createColumnMappedNoEdit("orgNoName"),
      createColumnMapped("version", selectedElement.id, KB_VERSIONS, editMode, updatedVersion),
      createColumnBooleanNoEditMapped("isGlobal"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [selectedElement, editMode, createButton]
  );

  if (isKbByIdDataError) {
    content = <ErrorBanner msg="Error in loading Kb data" />;
  }

  if (KbByIdData) {
    content = (
      <DetailsContainerSingleTable>
        <BrmSingleElementTable
          data={[KbByIdData]}
          columns={columns}
          setSelectedElement={setSelectedElement}
          setSelectedRowId={setSelectedRowId}
          customProps={{ id: "KBFullDetails_table" }}
          elementName={TYPE.knowledgebase}
        />
      </DetailsContainerSingleTable>
    );
  }

  return (
    <>
      <Title id="KBFullDetails_title">
        {selectedElement.name}
        <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
      </Title>

      {showSummaryTable ? content : <></>}
      {selectedElement !== "" && (
        <DetailsContainerParent showSummaryTable={showSummaryTable}>
          <DetailsContainer>
            <KnowledgebaseDetailsTab
              selectedElement={selectedElement}
              setRefresh={setRefresh}
              refresh={refresh}
              kbId={id}
            />
          </DetailsContainer>
        </DetailsContainerParent>
      )}
    </>
  );
}
