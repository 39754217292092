import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./access-query-key-factory";

interface ISetAttackSurfaceNameProps {
  attackSurfaceId: string;
  name: string;
}

/**
 * Sets the name of an AttackSurface
 *
 * @param {string} AttackSurfaceId - uuid of the AttackSurface
 * @param {string} name - new name of the AttackSurface
 */
export const setAttackSurfaceName = ({ attackSurfaceId, name }: ISetAttackSurfaceNameProps) => {
  return Brm.attackSurfaceApi.setAttackSurfaceName(attackSurfaceId, { body: name });
};

export interface IUseSetAttackSurfaceName {
  options?: MutationOption<typeof setAttackSurfaceName>;
}

/**
 * Custom hook to change the name of an existing AttackSurface
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackSurfaceName = ({ options }: IUseSetAttackSurfaceName) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.attackSurfaceKeys.all);
    },
    ...options,
    mutationFn: setAttackSurfaceName,
  });
};
