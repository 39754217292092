import VersionData from "assets/version.json";
// Styles
import S from "./Footer.styles";

export const Footer = () => {
  const date = new Date();
  return (
    <S.Footer>
      <p className="leftText">
        Version: {VersionData.version}, Build: {VersionData.build}
      </p>
      <p className="centeredText">&#169; KDM Analytics, Inc. {date.getFullYear()}</p>
    </S.Footer>
  );
};
