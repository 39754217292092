import { useState } from "react";
import systemModals from "components/modals/Modals";

import Title from "components/elements/StyledTitle";
import { TYPE } from "constants/brm";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { AddSystemForm } from "../../components/forms/AddSystemForm";
import { SystemTable } from "../../components/tables/SystemTable";

export function Systems() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const systemHeader = <Title>Systems</Title>;

  const systemMain = (
    <>
      {modalIsOpen &&
        systemModals.addModal(
          "SystemForm_page",
          modalIsOpen,
          setModalIsOpen,
          <AddSystemForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} refresh={refresh} />,
          TYPE.system
        )}
      <SystemTable
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );

  return <ContentLayout pageId="SystemForm_page" header={systemHeader} main={systemMain} />;
}
