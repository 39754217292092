import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Redirect to list page when user changes project while viewing details page
function useRedirectDetailsPage(projectId, localProjectId, listUrl) {
  const navigate = useNavigate();
  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(listUrl);
    }
  }, [projectId, navigate, listUrl, localProjectId]);
}

export default useRedirectDetailsPage;
