import * as React from "react";
import { SIO, SOCKET_NAMESPACE } from "constants/brm";
import { SocketConfig, useSocket } from "hooks/useSocket";

const useSioService = (projectId: string) => {
  const socketConfig = React.useMemo<SocketConfig>(
    () => ({ namespace: SOCKET_NAMESPACE.reporting, auth: { projectId } }),
    [projectId]
  );

  const { socket: localSocket, isConnected, error } = useSocket(socketConfig);

  function get(eventName: string, request: any) {
    if (localSocket !== null) {
      let timer: ReturnType<typeof setTimeout>;
      const timeout = new Promise<void>((resolve, reject) => {
        timer = setTimeout(() => {
          reject(new Error("Request Timeout"));
        }, 10000);
      });
      const doEmit = new Promise<void>((resolve, reject) => {
        localSocket.emit(`${SIO.get}${eventName}`, request, (err: any, res: any) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });

      return Promise.race([doEmit, timeout]).finally(() => clearTimeout(timer));
    }
    return Promise.reject(new Error("No Socket connection"));
  }

  function sendReq(eventName: string, request: any) {
    if (localSocket) {
      localSocket.emit(`${SIO.req}${eventName}`, request);
    }
  }

  function waitProg(eventName: string, cb: Function) {
    if (localSocket) {
      localSocket.on(`${SIO.prog}${eventName}`, (data: any) => {
        cb(data);
      });
    }
  }

  function waitResp(eventName: string, cb: Function) {
    if (localSocket) {
      localSocket.on(`${SIO.resp}${eventName}`, (data: any) => {
        cb(data);
      });
    }
  }

  function waitErr(eventName: string, cb: Function) {
    if (localSocket) {
      localSocket.on(`${SIO.err}${eventName}`, (data: any) => {
        cb(data);
      });
    }
  }

  return [
    {
      get,
      sendReq,
      waitProg,
      waitResp,
      waitErr,
      id: localSocket ? localSocket.id : "null Id",
    },
    isConnected,
    error,
  ];
};

export default useSioService;
