import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./access-query-key-factory";

interface ISetEntryPointCategoryProps {
  entryPointId: string;
  category: string;
}

/**
 * Sets the Entry Point's Category
 *
 * @param {string} entryPointId - uuid of the entry point
 * @param {string} category - new name of the Category
 */
export const setEntryPointCategory = ({ entryPointId, category }: ISetEntryPointCategoryProps) => {
  return Brm.entryPointApi.setEntryPointCategory(entryPointId, { body: category });
};

interface IUseSetEntryPointCategory {
  projectId: string;
  options?: MutationOption<typeof setEntryPointCategory>;
}

/**
 * Custom hook to change the name of an existing Entry Point's Category
 *
 * @param {string} projectId - uuid of the project
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetEntryPointCategory = ({ projectId, options }: IUseSetEntryPointCategory) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.entryPointKeys.project(projectId));
    },
    ...options,
    mutationFn: setEntryPointCategory,
  });
};
