import styled from "styled-components";

const riskButtonStyles = {
  RiskButton: styled.button`
    opacity: ${(prop) => (prop.disabled ? 0.3 : 1)};
    background-color: #005ca6;
    border-radius: 3px;
    color: white;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    border: 1px black;
    padding: 5px;
    margin-left: 7px;
    &:hover {
      opacity: ${(prop) => (prop.disabled ? 0.3 : 0.8)};
      cursor: ${(prop) => (prop.disabled ? "auto" : "pointer")};
    }
  `,
  RiskCalculatingDiv: styled.div`
    align-items: center;
    display: flex;
    background-color: white;
    border: none;
    padding: 8px 10px;
    border-radius: 8px;
    gap: 1em;
    color: #363636;
    line-height: 1.3;
    box-shadow: 0 3px 10px rgb(0 0 0 / 10%), 0 3px 3px rgb(0 0 0 / 5%);
  `,
  DismissButton: styled.button`
    border: none;
    background-color: white;
  `,
};

export default riskButtonStyles;
