import { useMemo, useRef, useCallback, useState } from "react";
import { queryClient } from "libs/react-query";
import toast from "react-hot-toast";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";
import {
  UploadButton,
  LoadingSpinner as Loading,
  ErrorBanner as ErrorDisplay,
  ButtonSpacer,
  ButtonWrapper,
  DownloadButton,
  // FormatDownloadButton,
} from "components/elements";

// State
import { controlCatalogIdState } from "atoms/atoms-global-control";

import { CloneButton, GlobalControlApi, AdminApi } from "features/brm";
import PropTypes from "prop-types";
import { selectedInstanceState } from "atoms";
import { useRecoilState } from "recoil";
import { TYPE, DEFAULT_CATALOG_NAME } from "constants/brm";
import useToggle from "hooks/useToggle";
import { AlertDialog } from "components/modals";

import {
  createColumnName,
  createColumnStandard,
  createColumnImportedAt,
  createColumnModifiedAt,
  createColumnBooleanNoEditMapped,
  createColumnActions,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";

export const ControlCatalogTable = ({ setSelectedRows, setUploadModalIsOpen, setIsExportModalOpen }) => {
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const [, setControlCatalogId] = useRecoilState(controlCatalogIdState);

  const [showAlert, toggleAlert] = useToggle(false);
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedStandard = useRef("");
  const [editElement, setEditElement] = useState(null);

  const { data: selfData } = AdminApi.useSelf();
  const orgId = selfData?.organization || "";

  const handleSelection = useCallback(
    (ctInstance) => {
      setSelectedInstance(ctInstance);
      if (ctInstance.id) {
        setControlCatalogId(ctInstance.id);
      }
    },
    [setControlCatalogId, setSelectedInstance]
  );

  const { data, isError, error } = GlobalControlApi.useControlCatalogs({ options: {} });
  const { mutate: cloneControlCatalog } = GlobalControlApi.useCloneControlCatalog({
    options: {
      onError: () => {
        toast.error(<span id="cloneControlCatalogError">Error occuring while cloning</span>, {
          duration: 4000,
        });
      },
    },
  });

  const { mutate: updateControlCatalogName } = GlobalControlApi.useSetCCName({
    options: {
      onError: (previousValues) => queryClient.setQueryData(GlobalControlApi.controlCatalogKeys.all, previousValues),

      onSettled: () => {
        updatedName.current = "";
      },
    },
  });

  const { mutate: updateControlCatalogStandard } = GlobalControlApi.useSetCCStandard({
    options: {
      onError: (previousValues) => queryClient.setQueryData(GlobalControlApi.controlCatalogKeys.all, previousValues),
      onSettled: () => {
        updatedStandard.current = "";
      },
    },
  });

  const clone = useCallback(
    (cellProps) => {
      const ccId = cellProps?.cell?.row?.original?.id || "";
      if (ccId) {
        cloneControlCatalog({ ccId });
      }
    },
    [cloneControlCatalog]
  );

  // const downloadHandler = useCallback(
  //   (ccId, ccName, format) => {
  //     exportControlCatalog(
  //       { controlCatalogId: ccId, format },
  //       {
  //         onSuccess: (data2) => {
  //           const url = window.URL.createObjectURL(data2);
  //           const a = document.createElement("a");
  //           a.href = url;
  //           const timestamp = new Date(Date.now())
  //             .toISOString()
  //             .replace(/T/, "_")
  //             .replace(/\..+/, "")
  //             .replace(/-|:/g, "");

  //           a.download = `${ccName}-${timestamp}.${format.toLowerCase()}`;
  //           a.click();
  //           window.URL.revokeObjectURL(url);
  //         },
  //       }
  //     );
  //   },
  //   [exportControlCatalog]
  // );

  const createButton = useCallback(
    (cellProps) => {
      const currentRow = cellProps.cell.row.original;

      const exportButton = (
        <DownloadButton
          title="Export ControlCatalog"
          onClick={() => {
            setSelectedInstance(currentRow);
            setIsExportModalOpen(true);
          }}
        />
        // <FormatDownloadButton id="dropdown-basic-button" title="Export...">
        //   <Dropdown.Item onClick={() => downloadHandler(currentRow.id, currentRow.name, "CSV")}>
        //     Export as CSV
        //   </Dropdown.Item>
        //   <Dropdown.Item onClick={() => downloadHandler(currentRow.id, currentRow.name, "JSON")}>
        //     Export as JSON
        //   </Dropdown.Item>
        // </FormatDownloadButton>
      );

      const editButton = (
        <ButtonCell
          selectedRowId={editElement?.id || ""}
          elementId={currentRow.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);
            if (updatedName.current !== "") {
              updateControlCatalogName({ ccId: currentRow.id, name: updatedName.current });
            }
            if (updatedStandard.current !== "") {
              updateControlCatalogStandard({ ccId: currentRow.id, standard: updatedStandard.current });
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
          onEditModeStart={() => {
            setEditElement(currentRow);
          }}
        />
      );

      return (
        <ButtonWrapper>
          {currentRow.name === DEFAULT_CATALOG_NAME.name_4 || currentRow.name === DEFAULT_CATALOG_NAME.name_5 ? (
            <>
              <ButtonSpacer />
              <CloneButton onClick={() => clone(cellProps)} />
              {exportButton}
              <ButtonSpacer />
            </>
          ) : (
            <>
              {currentRow.organization.id === orgId ? editButton : <ButtonSpacer />}
              <CloneButton onClick={() => clone(cellProps)} />
              {exportButton}
              {currentRow.organization.id === orgId ? (
                <UploadButton
                  onClick={() => {
                    setSelectedInstance(currentRow);
                    setUploadModalIsOpen(true);
                  }}
                />
              ) : (
                <ButtonSpacer />
              )}
            </>
          )}

          {/* NEEDS TO BE ENABLED AFTER MARCH RELEASE -  BRMCD-5180 */}
          {/* <DownloadButton
          onClick={async () => {
            if (cellProps.cell.row.original.id) {
              try {
                const res = await controlCatalogApi.exportControlCatalogWithHttpInfo(cellProps.cell.row.original.id);
                const fileName = cellProps.cell.row.original.name;
                const json = JSON.stringify(res.response.body);
                const blob = new Blob([json], { type: "application/json" });
                const href = await URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = href;
                link.download = `${fileName}_controlCatalog.json`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              } catch (err) {
                console.error("Error on download of control catalog ", err);
              }
            } else {
              toggleAlert();
            }
          }}
        /> */}
        </ButtonWrapper>
      );
    },
    [
      editElement,
      editMode,
      orgId,
      setSelectedInstance,
      setIsExportModalOpen,
      updateControlCatalogName,
      updateControlCatalogStandard,
      clone,
      setUploadModalIsOpen,
    ]
  );

  const columns = useMemo(
    () => [
      createColumnName(editElement?.id, handleSelection, editMode, updatedName, "controlCatalog"),
      createColumnStandard(editElement?.id, editMode, updatedStandard),
      createColumnMappedNoEdit("org"),
      createColumnImportedAt(),
      createColumnModifiedAt(),
      createColumnBooleanNoEditMapped("isImported"),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [editElement?.id, handleSelection, editMode, createButton]
  );

  if (isError) {
    return <ErrorDisplay msg={error?.statusText} />;
  }

  if (data) {
    return (
      <>
        <AlertDialog
          show={showAlert}
          onClose={toggleAlert}
          title="Information"
          message="Please select a control catalog to download."
        />
        <CustomTable>
          <BrmMainTable
            data={data}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "ControlCatalogTable_table" }}
            elementName={TYPE.controlCatalog}
            showRowSelect={false} // needs to be enabled after March release - BRMCD-5176
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

ControlCatalogTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setUploadModalIsOpen: PropTypes.func.isRequired,
  setIsExportModalOpen: PropTypes.func.isRequired,
};
