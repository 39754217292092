import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./project-query-key-factory";

interface IRemoveProjectReadOnlyUserProps {
  projectId: string | null;
  userId: string | null;
}

/**
 * Remove a project readonly user
 *
 * @returns
 */
export const removeProjectReadOnlyUser = ({ projectId, userId }: IRemoveProjectReadOnlyUserProps) => {
  return projectId === null
    ? Promise.reject(new Error("Invalid projectId"))
    : Brm.projectApi.removeProjectReadonly(projectId, userId);
};

interface IRemoveProjectWorkerQueryProps {
  options?: MutationOption<typeof removeProjectReadOnlyUser>;
}

/**
 * Custom hook to a project readonly user
 *
 * @returns react-query Mutation.
 */
export const useRemoveProjectReadOnlyUser = ({ options }: IRemoveProjectWorkerQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, context, variables) => {
      queryClient.invalidateQueries(KeyFactory.projectKeys.readOnlyUsers(variables.projectId));
    },
    ...options,
    mutationFn: removeProjectReadOnlyUser,
  });
};
