import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import { DetailTableType, ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import * as BrmGql from "generated/graphql";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { useRecoilValue } from "recoil";
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";
import { useModal } from "hooks";
import { COMMON } from "constants/brm";
import * as S from "brm/styles/details-table.styles";
import { riskUpdatedState } from "atoms/atoms-risk";
import { RoutePath } from "routes/route-paths";
import RiskAddForm from "./forms/RiskAddForm";
import { useElementRisks } from "./api";

const RiskTable = ({ elementNameInUrl, selectedElement, setRefresh, tableTitle }) => {
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const riskUpdated = useRecoilValue(riskUpdatedState);
  const { isRiskAnalyst } = useRoles();
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  const columns = useMemo(() => {
    const objectiveColumn =
      elementNameInUrl !== DetailTableType.SYSTEM_ASSET_TYPES.key ? "objective" : "objectiveObject";
    const makeScoreCell = (cellProps) => {
      if (cellProps.cell.row.original.score?.value) {
        return cellProps.cell.row.original.score.value?.toFixed(1);
      }
      return COMMON.hyphen;
    };

    const makeCell = (cellProps) => {
      if (cellProps.cell.row.original.mitigatedScore?.value) {
        return cellProps.cell.row.original.mitigatedScore.value?.toFixed(1);
      }
      return COMMON.hyphen;
    };
    if (isRiskAnalyst) {
      return [
        createColumnMappedNoEdit("rank"),
        createColumnNameDetails(RoutePath.Risk.replace(":id", "")),
        createColumnMappedNoEdit("category"),
        createColumnMappedNoEdit(objectiveColumn),
        {
          Header: "Score",
          accessor: "score.value",
          Cell: makeScoreCell,
        },
        {
          Header: "Mitigated Score",
          accessor: "mitigatedScore.value",
          filterable: true,
          Cell: makeCell,
        },
      ];
    }

    return [
      createColumnMappedNoEdit("name"),
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit(objectiveColumn),
      {
        Header: "Score",
        accessor: "score.value",
        Cell: makeScoreCell,
      },
    ];
  }, [isRiskAnalyst, elementNameInUrl]);

  const [, setSelectedRisk] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { data: riskData, isError: isRiskDataError } = useElementRisks({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    variantId,
    riskUpdated,
    options: {
      enabled:
        !!selectedElement.id &&
        !!elementNameInUrl &&
        !!projectId &&
        elementNameInUrl !== DetailTableType.SYSTEM_ASSET_TYPES.key,
    },
  });
  const {
    data: systemAssetTypeRiskData,
    isError: isSystemAssetTypeRiskDataError,
    error: systemAssetTypeRiskDataError,
  } = BrmGql.useGetSystemAssetTypeRiskQuery(
    { id: selectedElement.id, project: projectId, variant: variantId },
    {
      enabled: !!selectedElement.id && elementNameInUrl === DetailTableType.SYSTEM_ASSET_TYPES.key,
      refetchOnWindowFocus: false,
    }
  );

  const data = useMemo(() => {
    let response = null;
    if (riskData || systemAssetTypeRiskData) {
      if (elementNameInUrl === DetailTableType.SYSTEM_ASSET_TYPES.key) {
        const res = systemAssetTypeRiskData?.systemAssetType.risks;
        response = res || [];
      } else if (Array.isArray(riskData) && riskData.length > 0 && riskData[0] !== undefined) {
        // remove risks with score of 0
        const nonZeroRisks = riskData.filter((r) => r.score?.value !== 0);
        if (elementNameInUrl === DetailTableType.RISK_CATEGORIES.key) {
          // filter risks by selected category id
          const risksByCategory = nonZeroRisks.filter((risk) => risk.category.id === selectedElement?.id);
          response = risksByCategory;
        } else if (nonZeroRisks.length === 1 && nonZeroRisks[0].id === COMMON.nullUuid) {
          response = []; // backend returns null uuid for risk when no risk exists. Show empty in UI with empty array
        } else {
          response = nonZeroRisks;
        }
      } else if (riskData[0]?.id === COMMON.nullUuid || riskData[0] === undefined) {
        response = []; // backend returns null uuid for risk when no risk exists. Show empty in UI with empty array
      } else {
        response = [riskData]; // undesired events risks is returning an object
      }
    }
    return response;
  }, [elementNameInUrl, systemAssetTypeRiskData, riskData, selectedElement]);

  if (isRiskDataError) {
    return <ErrorBanner msg="Error while loading risks" />;
  }
  if (isSystemAssetTypeRiskDataError) {
    return <ErrorBanner msg={systemAssetTypeRiskDataError?.message} />;
  }

  if (data) {
    return (
      <S.DetailsContainer id="RiskTable_detailsPanel">
        {modalIsOpen &&
          systemModals.addModal(
            "RiskTable_detailsPanel",
            modalIsOpen,
            setModalIsOpen,
            <RiskAddForm
              setModalIsOpen={setModalIsOpen}
              setRefresh={setRefresh}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
            />,
            "Risk"
          )}
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedRisk}
              customProps={{ id: "RiskTable_detailsTable" }}
              showRowSelect={false}
              elementName={elementNameInUrl}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

RiskTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setRefresh: PropTypes.func,
  tableTitle: PropTypes.string,
};

export default RiskTable;
