import * as ReactQuery from "@tanstack/react-query";
import { MutationOption /* , queryClient */ } from "libs/react-query";
import * as Brm from "services/brm";
import * as SapPersonKeyFactory from "features/brm/api/admin/sapPerson/sap-person-query-key-factory";
import * as SapBindingKeyFactory from "features/brm/api/admin/sapBinding/sap-binding-query-key-factory";
import * as SapProgramKeyFactory from "features/brm/api/admin/sapProgram/sap-program-query-key-factory";
import * as ProjectKeyFactory from "features/brm/api/admin/project/project-query-key-factory";

interface IDeleteSapUserBindingProps {
  bindingId: string | null;
}

/**
 * Delete an sap org binding
 *
 * @returns
 */
export const deleteSapUserBinding = ({ bindingId }: IDeleteSapUserBindingProps) => {
  return bindingId === null
    ? Promise.reject(new Error("Invalid binding id"))
    : Brm.sapUserBindingApi.deleteSapUserBinding(bindingId);
};

interface IDeleteSapUserBindingQueryProps {
  options?: MutationOption<typeof deleteSapUserBinding>;
}

/**
 * Custom hook to Delete an SapUserBinding
 *
 * @returns react-query Mutation.
 */
export const useDeleteSapUserBinding = ({ options }: IDeleteSapUserBindingQueryProps = {}) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation({
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ProjectKeyFactory.projectKeys.all });
      queryClient.invalidateQueries({ queryKey: SapProgramKeyFactory.sapProgramKeys.all });
      queryClient.invalidateQueries({ queryKey: SapPersonKeyFactory.sapPersonKeys.all });
      queryClient.invalidateQueries({ queryKey: SapBindingKeyFactory.sapBindingKeys.allUser });
    },
    mutationFn: deleteSapUserBinding,
  });
};
