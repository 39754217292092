import { IMPORT_STATUS, STATUS } from "constants/brm";
import styled from "styled-components";

const ImporterPagesStyles = {
  Container: styled.div`
    font-family: ${({ theme }) => theme.fonts.primary};
    position: relative;
    height: 30%;
  `,
  Tabs: styled.div`
    padding-top: 15px;
  `,
  SubmissionStatus: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  Title: styled.h5`
    padding: 5px 0px;
  `,
  ProgressInfo: styled.i`
    margin-left: 10px;
    font-size: 14px;
  `,
  ProgressBar: styled.progress`
    margin-top: 3px;
  `,
  ProgressDetails: styled.div`
    display: flex;
    flex-direction: row;
    max-width: 80%;
    .col1 {
      width: 7rem;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .col2 {
      min-width: 12%;
      text-align: center;
      padding-top: 3px;
      padding-left: 3px;
      flex-grow: 0;
      flex-shrink: 0;
      width: 10rem;
    }

    .col3 {
      min-width: 17%;
      max-width: 50%;
      flex-grow: 0;
      flex-shrink: 0;
      width: 15rem;
    }
    .col4 {
      flex-grow: 0;
      flex-shrink: 0;
    }

    .col {
      padding: 0px;
    }
  `,
  StatusDisplay: styled.strong`
    color: ${(props) => {
      if (
        props.submissionStatus === IMPORT_STATUS.pass &&
        (props.projectStatus === STATUS.active || !props.projectStatus)
      ) {
        return `green`;
      }
      if (props.submissionStatus === IMPORT_STATUS.fail) {
        return `red`;
      }
      return `#f8ba27`;
    }};
    padding-left: 5px;
  `,
};

export default ImporterPagesStyles;
