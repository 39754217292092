// Assets
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
// Constants
import { STATUS } from "constants/brm";

// Toggles the folder icon from open to closed
export const useStatusChevron = (active: string) => {
  const style = {
    height: "12px",
    width: "12px",
    marginRight: "5px",
    marginBottom: "2px",
  };

  return active === STATUS.active ? (
    <FaChevronDown fill="#003A70" style={style} />
  ) : (
    <FaChevronRight fill="#003A70" style={style} />
  );
};
