import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./ttp-query-key-factory";

interface ICreateTtp {
  catalogId: string;
  ttpCreateDto: any;
}

/**
 *
 * @param {TtpCreateDto} ttpCreateDto creation dto
 * @returns id of created threat group
 */
export const createTtp = ({ catalogId, ttpCreateDto }: ICreateTtp) => {
  return Brm.ttpApi.createTTP(catalogId, { tTPCreateDto: ttpCreateDto });
};

export interface IUseCreateTtp {
  options?: MutationOption<typeof createTtp>;
}

/**
 * Custom hook to create a new ttp.
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for useCreateTtp
 */
export const useCreateTtp = ({ options }: IUseCreateTtp = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.ttpKeys.all);
    },
    ...options,
    mutationFn: createTtp,
  });
};
