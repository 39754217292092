import * as React from "react";
import ButtonAsLink from "components/modals/ButtonAsLinkStyled";
import { IVideoListContent } from "../../types";
import { HelperTextContainer } from "./HelpVideosList.styles";

interface IHelpVideoProps {
  contentsData: IVideoListContent[] | null;
  handleBtnOnClick: (openModal: boolean, filepath: string, name: string) => void;
  className: string;
}

export const HelpVideosList: React.FunctionComponent<IHelpVideoProps> = ({
  contentsData,
  handleBtnOnClick,
  className,
}): React.ReactElement | null => {
  if (contentsData) {
    return (
      <ul className={className}>
        {contentsData &&
          contentsData?.map((e: IVideoListContent) => (
            <li key={e.name}>
              <ButtonAsLink
                id={`show ${e.name} video`}
                onClick={() => {
                  handleBtnOnClick(true, e.filePath, e.name);
                }}
                className="videoLink"
              >
                {e.name}
              </ButtonAsLink>
              {e?.helperText && <HelperTextContainer>{e?.helperText || ""}</HelperTextContainer>}
            </li>
          ))}
      </ul>
    );
  }

  return null;
};
