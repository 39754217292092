import safelyParseJson from "utils/safely-parse-json";

/*
 *  Configuration for the Backend - Port & Host
 */

// Return the host and port
function getBackend() {
  /*
    

    let port = "";
    let host = "";

    if (process.env.NODE_ENV !== "development") {
        port = process.env.REACT_APP_PORT_PRODUCTION;
        host = process.env.REACT_APP_HOST_PRODUCTION;
    } else {
        port = process.env.REACT_APP_PORT;
        host = process.env.REACT_APP_HOST;
    }


    

    return 'http://' + host + ':' + port + '/brm/v1/';
    */
  return "/brm/v1/";
}

// Returns the typical header needed for axios requests that includes the bearer token
function getHeader() {
  let header = "";

  // check userinfo in local storage
  const userToken = localStorage.getItem("react-token");

  if (userToken !== null) {
    //

    header = {
      Authorization: `Bearer ${userToken}`,
    };
  }

  //

  return header;
}

// Returns the typical header needed for axios requests that includes the bearer token + Plain text content type
function getHeaderPlainText() {
  let header = "";

  // check userinfo in local storage
  const userToken = localStorage.getItem("react-token");

  if (userToken !== null) {
    header = {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "text/plain",
    };
  }

  return header;
}

// Returns the typical header needed for axios requests that includes the bearer token + JSON content type
function getHeaderJSON() {
  let header = "";

  // check userinfo in local storage
  const userToken = localStorage.getItem("react-token");

  if (userToken !== null) {
    header = {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    };
  }

  return header;
}

// Returns userInfo from session storage
function getUserInfo() {
  const userInfo = safelyParseJson(sessionStorage.getItem("user-info"));
  return userInfo;
}

// Returns currentProjectId from session storage
function getCurrentProjectId() {
  const currentProjectId = sessionStorage.getItem("projectId");
  return currentProjectId;
}

const backendUtils = {
  getBackend,
  getHeader,
  getHeaderPlainText,
  getHeaderJSON,
  getUserInfo,
  getCurrentProjectId,
};

export default backendUtils;
