import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { personApi } from "services/brm/system-service";
import handleApi from "services/brm/api-util";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import ButtonCell from "components/EditComponents/ButtonCell";
import { ElementFullDetails, SystemApi } from "features/brm";
import { TYPE } from "constants/brm";
import * as ReactQuery from "@tanstack/react-query";

import {
  createColumnMapped,
  createColumnBooleanMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";
import { RoutePath } from "routes/route-paths";
import PersonDetails from "../details/PersonDetails";

function PersonFullDetails() {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);

  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedCategory = useRef("");
  const updatedIsInternal = useRef("");

  const queryClient = ReactQuery.useQueryClient();
  const { data: person } = SystemApi.usePerson({
    personId: id,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: elementCats } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedRowId}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedIsInternal.current !== "") {
              await handleApi(
                personApi.setPersonIsInternalWithHttpInfo(selectedRowId, {
                  body: updatedIsInternal.current,
                })
              );
              updatedIsInternal.current = "";
            }

            if (updatedCategory.current !== "") {
              await handleApi(
                personApi.setPersonCategoryWithHttpInfo(selectedRowId, {
                  body: updatedCategory.current,
                })
              );
              updatedCategory.current = "";
            }

            setRefresh(true);
            queryClient.invalidateQueries(["nodes"]);
            queryClient.invalidateQueries(["node", id]);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [selectedRowId, editMode, queryClient, id]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnCategoryMapped("category", selectedRowId, elementCats, editMode, updatedCategory),
        createColumnBooleanMapped("isInternal", selectedRowId, editMode, updatedIsInternal),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnMapped("category", selectedRowId, elementCats, false, null),
      createColumnBooleanMapped("isInternal", selectedRowId, false, null),
    ];
  }, [isSystemEngineer, selectedRowId, elementCats, editMode, createButton]);

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(RoutePath.Persons);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    if (person) {
      setSelectedElement(person);
    }
  }, [person]);

  if (person && selectedElement) {
    const detailsComp = <PersonDetails selectedElement={person} setRefresh={setRefresh} refresh={refresh} />;

    return (
      <ElementFullDetails
        showBackLink
        data={[person]}
        columns={columns}
        selectedElement={person}
        setSelectedElement={setSelectedElement}
        setSelectedRowId={setSelectedRowId}
        detailsComp={detailsComp}
        compName={TYPE.person}
      />
    );
  }

  return <Loading />;
}

export default PersonFullDetails;
