import { Breadcrumb as BsBreadcrumb } from "react-bootstrap";
import { routes } from "routes/routes";
import { Link } from "react-router-dom";
import { useRoles } from "features/brm/hooks/useRoles";
import { Breadcrumb } from "./Breadcrumb";

interface RouteInfo {
  contentKey: string;
  showProject?: boolean;
  showProjects?: boolean;
  path: string;
  name: string;
  crumbs: string[];
}

export const BreadcrumbParent = ({ contentKey }: { contentKey: string }) => {
  const typedRoutes: { [key: string]: RouteInfo } = routes;
  const {
    isAdmin,
    isSuperAdmin,
    isSystemEngineer,
    isRiskAnalyst,
    isThreatAnalyst,
    isCyberSecurityExpert,
    isControlsCoordinator,
  } = useRoles();
  const routeInfo = typedRoutes[contentKey];

  const projectActive = contentKey === "projectOverview";
  const projectsActive = contentKey === "projects";

  const organizationsActive = contentKey === "organizations";
  const usersActive = contentKey === "users";
  const installationsActive = contentKey === "installation";

  const showOrganizations = (isAdmin || isSuperAdmin) && (organizationsActive || contentKey === "organizationOverview");

  const showUsers =
    (isAdmin || isSuperAdmin) && (usersActive || contentKey === "userOverview" || contentKey === "addUsers");

  const showInstallations = isSuperAdmin && (installationsActive || contentKey === "installationOverview");

  let showProject =
    !projectActive &&
    !projectsActive &&
    (isSystemEngineer || isRiskAnalyst || (isThreatAnalyst && contentKey !== "threatCatalogs")) &&
    !isAdmin &&
    !isSuperAdmin;
  let showProjects =
    isSystemEngineer ||
    isRiskAnalyst ||
    isThreatAnalyst ||
    (!showOrganizations && !showUsers && !showInstallations && !isControlsCoordinator && !isCyberSecurityExpert);

  let crumbs = null;

  if (routeInfo) {
    crumbs = routeInfo.crumbs.map((c) => {
      const key = typedRoutes[c]?.contentKey;
      showProject =
        typedRoutes[c]?.showProject ||
        (!isAdmin &&
          !isSuperAdmin &&
          !isControlsCoordinator &&
          !isCyberSecurityExpert &&
          !projectActive &&
          !projectsActive &&
          contentKey !== "threatCatalogs" &&
          contentKey !== "threatCatalogsDetails" &&
          contentKey !== "threatGroups" &&
          contentKey !== "threatGroupDetails" &&
          contentKey !== "threatTiers");

      showProjects =
        typedRoutes[c]?.showProjects ||
        (!showOrganizations &&
          !showUsers &&
          !showInstallations &&
          !isControlsCoordinator &&
          !isCyberSecurityExpert &&
          !projectsActive &&
          contentKey !== "threatCatalogs" &&
          contentKey !== "threatCatalogsDetails" &&
          contentKey !== "threatGroups" &&
          contentKey !== "threatGroupDetails" &&
          contentKey !== "threatTiers");

      if (key === "noPath") {
        return null;
      }

      return (
        <BsBreadcrumb.Item
          active={key === contentKey || typedRoutes[c]?.path === ""}
          key={c}
          linkAs={Link}
          linkProps={{ to: typedRoutes[c]?.path }}
        >
          {typedRoutes[c]?.name}
        </BsBreadcrumb.Item>
      );
    });
  }

  return (
    <>
      <Breadcrumb
        projectsActive={projectsActive}
        projectActive={projectActive}
        showProject={showProject}
        showProjects={showProjects}
      >
        {crumbs}
      </Breadcrumb>
    </>
  );
};
