import { userAssignedRolesState } from "atoms";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { RoutePath } from "routes/route-paths";
// Services
import UserService from "services/brm/user-service";
import USER_ROLES from "services/brm/user-roles";

interface ILicenseAlert {
  errorMsg?: string;
}

export const LicenseAlert = ({ errorMsg }: ILicenseAlert) => {
  const allocatedRoles = useRecoilValue<string[]>(userAssignedRolesState);
  const navigate = useNavigate();

  if (allocatedRoles.length > 0 && allocatedRoles.includes(USER_ROLES.properties[1].key)) {
    return (
      <Alert variant="warning">
        <Alert.Heading>No License Available</Alert.Heading>
        <p>
          No valid license available at this time.
          <br />
          Switch to Super Admin role and
          <Button
            size="sm"
            variant="link"
            onClick={() => {
              navigate(RoutePath.Installations);
            }}
          >
            Go to Installation page
          </Button>
        </p>
      </Alert>
    );
  }

  return (
    <Alert variant="warning">
      <Alert.Heading>No License Available</Alert.Heading>
      {errorMsg}
      <p>
        No valid license available at this time. <br />
        Contact your Super Admin for information.
        <Button size="sm" variant="link" onClick={() => UserService.doLogout()}>
          Logout
        </Button>
      </p>
    </Alert>
  );
};
