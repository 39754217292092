import * as React from "react";
import ReactSelect from "react-select";
import { AdminApi, useRoles } from "features/brm";
import { CheckBox, ErrorSpan, LoadingSpinner } from "components/elements";
import { createOptionsFromProps } from "utils/react-select-utils";
import { IOption } from "types";
// import * as log from "loglevel";
import { isBrmApiError } from "utils/type-guard";
import * as S from "./ProjectSapPanel.styled";

interface IProjectPanelProps {
  projectId: string;
}
const FORBIDDEN_STATUS = 403;

export const ProjectSapPanel = ({ projectId }: IProjectPanelProps) => {
  const { userId, isAdmin, isSuperAdmin } = useRoles();

  // All available programs must be retrieved from the sap users accessible programs
  const {
    data: allSapProgramOptions,
    error: allSapProgramError,
    isError: isOptionsError,
    isLoading: isLoadingOptions,
    isFetching: isFetchingOptions,
  } = AdminApi.useGetSapPrograms({
    queryParam: {
      user: userId!,
    },
    options: {
      select: (data) => createOptionsFromProps({ src: data, value: "id", label: "programname" }),
      enabled: isAdmin || isSuperAdmin,
      retry: false,
      // meta: {
      //   errorMessage: "Unable to load program names",
      // },
    },
  });

  /** The programs already assigned to this project */
  const { data: projectSapProgramOptions, error: sapProgramError } = AdminApi.useGetProjectSapPrograms({
    projectId,
    options: {
      select: (data) => createOptionsFromProps({ src: data, value: "id", label: "programname" }),
      enabled: isAdmin || isSuperAdmin,
      retry: false,
      // meta: {
      //   errorMessage: "Unable to load program options",
      // },
    },
  });

  const { mutate: setSapPrograms } = AdminApi.useSetProjectSapPrograms();
  const { mutate: setSapSandbox } = AdminApi.useSetProjectSapSandbox();

  // const [isSandboxEnabled, setSandboxEnabled] = React.useState(false);

  const { data: isSandboxEnabled, error: sapSandboxError } = AdminApi.useGetProjectSapSandbox({
    projectId,
    options: {
      select: (data) => data.result,
      enabled: isAdmin || isSuperAdmin,
      retry: false,
      // meta: {
      //   errorMessage: "Unable to load sandbox state",
      // },
    },
  });

  const handleProgramChange = async (selectedProgramOptions: readonly IOption[]) => {
    setSapPrograms({ projectId, sapProgramIds: selectedProgramOptions.map((o) => o.value) });
  };

  const projSapProjectOptions = React.useMemo(() => {
    if (!Array.isArray(projectSapProgramOptions) || !Array.isArray(allSapProgramOptions)) {
      return null;
    }
    // const projIds = projectSapProgramOptions.map((o: any) => o.value);
    // return allSapProgramOptions.filter((s: any) => projIds.includes(s.value));
    return projectSapProgramOptions;
  }, [projectSapProgramOptions, allSapProgramOptions]);

  const handleSandboxClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSapSandbox({ projectId, enabled: event.target.checked });
  };

  const combinedProgramOptions = React.useMemo(() => {
    if (Array.isArray(allSapProgramOptions) && Array.isArray(projSapProjectOptions)) {
      return [...allSapProgramOptions, ...projectSapProgramOptions];
    }
    if (Array.isArray(allSapProgramOptions)) {
      return [...allSapProgramOptions];
    }
    return [];
  }, [allSapProgramOptions, projSapProjectOptions, projectSapProgramOptions]);

  if (isOptionsError || allSapProgramError || sapProgramError || sapSandboxError) {
    if (
      (isBrmApiError(allSapProgramError) && allSapProgramError.status === FORBIDDEN_STATUS) ||
      (isBrmApiError(sapProgramError) && sapProgramError.status === FORBIDDEN_STATUS) ||
      (isBrmApiError(sapSandboxError) && sapSandboxError.status === FORBIDDEN_STATUS)
    ) {
      return <ErrorSpan>Unauthorized to view SAP information</ErrorSpan>;
    }
    return <ErrorSpan>Unknown error Unable to view SAP information</ErrorSpan>;
  }

  if (
    !isOptionsError &&
    !isLoadingOptions &&
    !isFetchingOptions &&
    Array.isArray(projectSapProgramOptions) &&
    Array.isArray(combinedProgramOptions)
  ) {
    return (
      <S.PropertiesContainer>
        <S.PropertyContainer>
          <S.PropertyName>Jade Programs:</S.PropertyName>
          <ReactSelect
            isMulti
            isClearable
            closeMenuOnSelect={false}
            options={combinedProgramOptions}
            value={Array.isArray(projSapProjectOptions) && projSapProjectOptions.length ? projSapProjectOptions : null}
            onChange={handleProgramChange}
          />
        </S.PropertyContainer>
        <S.PropertyContainer>
          <S.PropertyName>Jade Sandbox:</S.PropertyName>
          <CheckBox label="" onCheckboxChange={handleSandboxClicked} isSelected={isSandboxEnabled} />
        </S.PropertyContainer>
        <S.PropertyContainer>
          <S.PropertyName />
          <span>
            By selecting this box you acknowledge that this project is exempt from Special Access Program (SAP) rules
          </span>
        </S.PropertyContainer>
      </S.PropertiesContainer>
    );
  }
  return <LoadingSpinner />;
};
