import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

/**
 * Function performing api call to get list of control catalogs
 *
 * @returns array list of control catalogs
 */
export const getControlCatalogs = () => {
  return Brm.controlCatalogApi.findControlCatalog();
};

interface IUseControlCatalogs {
  options?: QueryOption<typeof getControlCatalogs>;
}

/**
 * React-query hook for getting all Control Catalogs
 * @param options - pass configurations for hook
 * @returns react-query for getControlCatalogs
 */
export const useControlCatalogs = ({ options }: IUseControlCatalogs = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.controlCatalogKeys.all,
    queryFn: () => getControlCatalogs(),
  });
};
