import ListGroup from "react-bootstrap/ListGroup";
import * as CommonStyle from "./styles";
import { FileListItem } from "./FileListItem";

export const FileList = ({
  fileArray,
  onRemoveClicked,
  label,
}: {
  fileArray: File[];
  onRemoveClicked: (f: File) => void;
  label: string | null;
}) => {
  return (
    <CommonStyle.Col>
      {label}
      <CommonStyle.Col>
        <CommonStyle.FileListGroupContainer>
          <ListGroup>
            {Array.isArray(fileArray) && fileArray.length ? (
              fileArray.map((f: File) => {
                return (
                  <ListGroup.Item key={f.name}>
                    <FileListItem file={f} onRemoveClicked={onRemoveClicked} />
                  </ListGroup.Item>
                );
              })
            ) : (
              <ListGroup.Item>No files currently selected for upload</ListGroup.Item>
            )}
          </ListGroup>
        </CommonStyle.FileListGroupContainer>
      </CommonStyle.Col>
    </CommonStyle.Col>
  );
};
