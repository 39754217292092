import { useRecoilValue } from "recoil";
// import BrmLogoPng from "assets/icons/BladeRM-logo-for-docs-transparentBackground.png";
import BrmLogoPng from "assets/icons/BladeRiskManagerLogo.png";

// Components
import { currentProjectState, useShowProjectText, useShowProjectSysAssetFDText, useVariant } from "features/brm";
import { useRoles } from "features/brm/hooks/useRoles";

import { isInPrepStatus } from "utils/filter-utils";
import { contentKeyState } from "atoms";
import { ProjectSelector } from "../ProjectSelector";
import { VariantSelector } from "../VariantSelector";
import ControlCatalogDropdownSelection from "../ControlCatalogDropdown";
import { UserAvatarButton } from "../UserAvatarButton";
import RoleSelector from "../RoleSelector";
import { CalculateRiskButton } from "../CalculateRiskButton";
import { Home } from "../Home";

// Atoms
// Styles
import BrmLogo from "../Logo";
import S from "./Header.styles";

const riskOutdatedText = "*Risk Outdated*";
const selectProjectText = `Select a project to enable "Where to go" links below`;
const selectVariantText = `Select a variant to enable "Impact/Likelihoods" links below`;
const selectSysAssetFDText = `Select a project to show accurate data`;

const Header = () => {
  const currentProject = useRecoilValue(currentProjectState);
  const { currentVariant } = useVariant();
  const { isRiskAnalyst, isThreatAnalyst, isControlsCoordinator, isSystemEngineer } = useRoles();
  const contentKey = useRecoilValue(contentKeyState);
  const isHome = contentKey === "home";

  const showProjectsSelect = isRiskAnalyst || isSystemEngineer || isThreatAnalyst;
  const showVariantSelect = isRiskAnalyst && currentProject?.id && !isInPrepStatus(currentProject);

  const showRiskOutdated = isRiskAnalyst && currentProject && currentVariant?.isRecalcNeeded && !isHome;
  const showControlCatalogSelect = isControlsCoordinator;
  const showCalcRisk = isRiskAnalyst && currentProject?.id && !isInPrepStatus(currentProject) && !isHome;
  const showSelectprojectText = useShowProjectText();
  const showSelectSysAssetFDText = useShowProjectSysAssetFDText();
  const showSelectVariantText =
    (currentVariant === null || currentVariant?.name === "Initial") && isHome && isRiskAnalyst;

  return (
    <S.Header id="Header">
      <S.PrimaryContainer>
        <BrmLogo src={BrmLogoPng} alt="BRM Logo" className="logo" id="Navbar_logo" />
        {showProjectsSelect ? <ProjectSelector /> : null}
        {showSelectprojectText ? <S.RiskOutdatedSpan>{selectProjectText}</S.RiskOutdatedSpan> : null}
        {showVariantSelect ? <VariantSelector /> : null}
        {!showSelectprojectText && showSelectVariantText ? (
          <S.RiskOutdatedSpan>{selectVariantText}</S.RiskOutdatedSpan>
        ) : null}
        {showControlCatalogSelect ? <ControlCatalogDropdownSelection /> : null}
        {showCalcRisk ? <CalculateRiskButton /> : null}
        {showRiskOutdated ? <S.RiskOutdatedSpan>{riskOutdatedText}</S.RiskOutdatedSpan> : null}
        {showSelectSysAssetFDText ? <S.SelectProjectSpan>{selectSysAssetFDText}</S.SelectProjectSpan> : null}
      </S.PrimaryContainer>
      <S.SecondaryContainer>
        <Home />
        <S.Separator>|</S.Separator>
        <RoleSelector />
        <S.Separator>|</S.Separator>
        <UserAvatarButton />
        {/* <LogoutButton /> */}
      </S.SecondaryContainer>
    </S.Header>
  );
};
export default Header;
