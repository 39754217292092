import { useMemo, useRef, useCallback } from "react";

import { userRoleState, projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { userRoles } from "services/brm/admin-service";
import * as SecurityObjectiveApi from "brm/system-model/categorization/api";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";

import { createColumnName, createColumnNameNoNav } from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";

const SecurityObjectiveTable = ({ setSelectedRows, setRefresh }) => {
  const userRole = useRecoilValue(userRoleState);
  const projectId = useRecoilValue(projectIdState);
  const cyberSecurityExpertRole = userRoles.properties[userRoles.CYBERSECURITY_EXPERT].key;
  const updatedName = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data, error } = SecurityObjectiveApi.useSecurityObjectives({ projectId, config: { enabled: !!projectId } });

  const getNameCell = useCallback(() => {
    if (userRole !== cyberSecurityExpertRole) {
      return createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "securityObjectives");
    }
    return createColumnNameNoNav(selectedInstance.id, false, updatedName);
  }, [cyberSecurityExpertRole, userRole, selectedInstance.id, setSelectedInstance]);

  const columns = useMemo(() => [getNameCell()], [getNameCell]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  if (error) {
    return <ErrorBanner msg="Error while loading security objectives" />;
  }

  if (data) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={data}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "SecurityObjectiveTable_table" }}
            elementName={TYPE.securityObjective}
            showRowSelect={false}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

SecurityObjectiveTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default SecurityObjectiveTable;
