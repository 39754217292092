import { MouseEvent } from "react";
import { AiOutlineFileZip } from "react-icons/ai";
import { InlineButton } from "features/brm";

interface IBackupButtonProps {
  onClick: (event: MouseEvent) => void;
  disableBackup: boolean;
}

export const ExportBackupButton = ({ onClick, disableBackup = false }: IBackupButtonProps) => {
  return (
    <InlineButton
      title={!disableBackup ? "Backup to file" : "Exporting backup to file"}
      id="ExportBackupFileButton_button"
      onClick={onClick}
      disabled={disableBackup}
    >
      <AiOutlineFileZip size="16" />
    </InlineButton>
  );
};
