import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import { DeleteButton, AddButton, AdminApi } from "features/brm";
// Utils
import errorUtils from "utils/error-utils";
// Services
import { organizationApi } from "services/brm/admin-service";
// Utils
import { createColumnMappedNoEdit, createColumnUsernameDetails } from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
// Local
import { RoutePath } from "routes/route-paths";
import { AddAttachedUserForm } from "../../forms/AddAttachedUserForm";

export const AttachedUserDetailsTable = ({ elementNameInUrl, selectedElement, showAdd, setRefresh, refresh }) => {
  const columns = useMemo(
    () => [
      createColumnUsernameDetails(RoutePath.User.replace(":id", "")),
      createColumnMappedNoEdit("firstname"),
      createColumnMappedNoEdit("lastname"),
      createColumnMappedNoEdit("org"),
      createColumnMappedNoEdit("status"),
    ],
    []
  );

  const [attachedUserData, setAttachedUserData] = useState([]);
  const [selectedAttachedUser, setSelectedAttachedUser] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { mutateAsync: removeOrganizationAttachedUser } = AdminApi.useRemoveOrganizationAttachedUser();

  useEffect(() => {
    // let isSubscribed = true;

    async function getData() {
      try {
        const { data, response } = await organizationApi.getOrganizationAttachedUserWithHttpInfo(selectedElement.id);
        errorUtils.checkErrorStatus(response.status, response.statusText);
        setAttachedUserData(data); // refreshing the data
      } catch (error) {
        console.error("Details: Attached User GET: ", error);
      }
    }
    getData();

    // cancel subscription to un-mounted component - clean up
    return () => false;
  }, [selectedElement, refresh, setAttachedUserData]);

  const addAttachedUser = () => {
    setModalIsOpen(true);
  };

  const deleteWorker = async () => {
    try {
      await Promise.all(
        selectedAttachedUser.map((e) =>
          removeOrganizationAttachedUser({ organizationId: selectedElement.id, userId: e.id })
        )
      ).then(() => {
        setRefresh(true);
        setSelectedAttachedUser([]);
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <S.DetailsContainer id="AttachedUserTable_detailsPanel">
      <S.DetailsTableButtonsContainer id="AttachedUserTable_buttons">
        {selectedAttachedUser?.length > 0 && <DeleteButton md onClick={deleteWorker} />}
        {showAdd && (
          <AddButton md onClick={addAttachedUser}>
            Add
          </AddButton>
        )}
      </S.DetailsTableButtonsContainer>

      {/* modal for adding instance */}
      {modalIsOpen &&
        systemModals.addModal(
          "AttachedUserTable_detailsPanel",
          modalIsOpen,
          setModalIsOpen,
          <AddAttachedUserForm
            setModalIsOpen={setModalIsOpen}
            setRefresh={setRefresh}
            refresh={refresh}
            elementNameInUrl={elementNameInUrl}
            selectedElement={selectedElement}
            attachedUsers={attachedUserData}
          />,
          "Attached User"
        )}

      <S.DetailsTableContainer id="AttachedUserTable_detailsTableSection">
        <BrmDetailTable
          data={attachedUserData}
          columns={columns}
          setSelectedElement={setSelectedAttachedUser}
          customProps={{ id: "AttachedUserTable_detailsTable" }}
          setSelectedRows={setSelectedAttachedUser}
          showRowSelect
        />
      </S.DetailsTableContainer>
    </S.DetailsContainer>
  );
};

AttachedUserDetailsTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  refresh: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setRefresh: PropTypes.any,
  showAdd: PropTypes.any,
};
