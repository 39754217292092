import { useQuery } from "@tanstack/react-query";
import { DetailTableType, BrmError } from "features/brm";
import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";
import * as KeyFactory from "./element-query-key-factory";

const getElementDataflows = (
  elementId: string,
  elementType: string,
  nodesDataflowsData: BrmGql.GetNodeDataflowsQuery | undefined,
  exchangeDataflowsData: BrmGql.GetExchangeDataflowsQuery | undefined,
  dataTypeDataflowsData: BrmGql.GetDataTypeDataflowsQuery | undefined,
  activityDataflowsData: BrmGql.GetActivityDataflowsQuery | undefined
) => {
  const dataFlowsMap: IDataflowsMap = {
    [DetailTableType.NODES.key]: () => nodesDataflowsData?.node?.dataFlows,
    [DetailTableType.EXCHANGES.key]: () => exchangeDataflowsData?.exchange?.dataFlows,
    [DetailTableType.DATATYPES.key]: () => dataTypeDataflowsData?.dataType?.dataFlows,
    [DetailTableType.ACTIVITIES.key]: () => activityDataflowsData?.activity?.dataFlows,
    [DetailTableType.PERSONS.key]: (id) => Brm.personApi.getPersonDataflow(id),
  };

  if (dataFlowsMap[elementType]) {
    return dataFlowsMap[elementType](elementId) || [];
  }

  return new BrmError(`Unsupported element type: ${elementType}`);
};

interface IElementDataflowsProps {
  elementId: string;
  elementName: string;
  projectId: string;
  variantId: string;
  config: {};
}

interface IDataflowsMap {
  [index: string]: (id?: string) => any;
}
/**
 * custom-hook to get all dataFlows
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {object} config - configuration for the react-query
 * @returns react-query for dataFlows
 */
export const useElementDataflows = ({
  elementId,
  elementName,
  projectId,
  variantId,
  config = {},
}: IElementDataflowsProps) => {
  const nodesDataflowsQuery = BrmGql.useGetNodeDataflowsQuery(
    { project: projectId, variant: variantId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.NODES.key }
  );

  const exchangeDataflowsQuery = BrmGql.useGetExchangeDataflowsQuery(
    { project: projectId, variant: variantId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.EXCHANGES.key }
  );

  const dataTypeDataflowsQuery = BrmGql.useGetDataTypeDataflowsQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.DATATYPES.key }
  );

  const activityDataflowsQuery = BrmGql.useGetActivityDataflowsQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.ACTIVITIES.key }
  );

  const dataflowsQuery = useQuery({
    ...config,
    queryKey: KeyFactory.elementKeys.dataflows(
      elementId,
      elementName,
      nodesDataflowsQuery.data,
      exchangeDataflowsQuery.data,
      dataTypeDataflowsQuery.data,
      activityDataflowsQuery.data
    ),
    queryFn: () =>
      getElementDataflows(
        elementId,
        elementName,
        nodesDataflowsQuery.data,
        exchangeDataflowsQuery.data,
        dataTypeDataflowsQuery.data,
        activityDataflowsQuery.data
      ),
  });

  if (nodesDataflowsQuery.isError) {
    return nodesDataflowsQuery;
  }

  if (exchangeDataflowsQuery.isError) {
    return exchangeDataflowsQuery;
  }

  if (dataTypeDataflowsQuery.isError) {
    return dataTypeDataflowsQuery;
  }

  if (activityDataflowsQuery.isError) {
    return activityDataflowsQuery;
  }

  return dataflowsQuery;
};
