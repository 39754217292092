import { useEffect, useMemo, useRef, useCallback } from "react";
import { userRoleState, projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { userRoles } from "services/brm/admin-service";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";
import { isCyberSecurityExpert } from "services/user";
import * as ACQueries from "brm/system-model/access/access-queries";
import { LoadingSpinner as Loading } from "components/elements";
import { createColumnCategoryName, createColumnNameNoNav } from "brm/tables/services/column/columnFactory";

const AttackSurfaceCategoryTable = ({ setSelectedRows, refresh, setRefresh }) => {
  const userRole = useRecoilValue(userRoleState);
  const updatedName = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const cyberSecurityExpertRole = userRoles.properties[userRoles.CYBERSECURITY_EXPERT].key;
  const projectId = useRecoilValue(projectIdState);

  const { data: attackSurfaceCategories, isError } = ACQueries.useAttackSurfaceCategories(projectId);

  const getNameCell = useCallback(() => {
    if (userRole !== cyberSecurityExpertRole) {
      return createColumnCategoryName(selectedInstance.id, setSelectedInstance, "attackSurfaceCategory");
    }
    return createColumnNameNoNav(selectedInstance.id, false, updatedName);
  }, [cyberSecurityExpertRole, userRole, selectedInstance.id, setSelectedInstance]);

  const columns = useMemo(() => [getNameCell()], [getNameCell]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh, setRefresh]);

  if (isError) {
    return <div>Error while loading Attack Surface Category data</div>;
  }

  if (attackSurfaceCategories) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={attackSurfaceCategories}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "AttackSurfaceCategoryTable_table" }}
            elementName={TYPE.attackSurfaceCat}
            showRowSelect={isCyberSecurityExpert(userRole)}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

AttackSurfaceCategoryTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default AttackSurfaceCategoryTable;
