import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
// import * as KeyFactory from "./project-query-key-factory";
// import * as UserKeyFactory from "../user/user-query-key-factory";

interface IRemoveProjectWorkerProps {
  projectId: string | null;
  userId: string | null;
}

/**
 * Remove a project worker
 *
 * @returns
 */
export const removeProjectWorker = ({ projectId, userId }: IRemoveProjectWorkerProps) => {
  return projectId === null
    ? Promise.reject(new Error("Invalid projectId"))
    : Brm.projectApi.removeProjectWorker(projectId, userId);
};

interface IRemoveProjectWorkerQueryProps {
  options?: MutationOption<typeof removeProjectWorker>;
}

/**
 * Custom hook to remove a project worker
 *
 * @returns react-query Mutation.
 */
export const useRemoveProjectWorker = ({ options }: IRemoveProjectWorkerQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: async (/* data, context, variables */) => {
      // queryClient.invalidateQueries(KeyFactory.projectKeys.project(variables.projectId));
      // queryClient.invalidateQueries(KeyFactory.projectKeys.workers(variables.projectId));
      // queryClient.invalidateQueries(KeyFactory.projectKeys.access(variables.projectId));
      // queryClient.invalidateQueries(UserKeyFactory.userKeys.all);
      await queryClient.invalidateQueries();
    },
    ...options,
    mutationFn: removeProjectWorker,
  });
};
