import * as React from "react";
import * as Recoil from "recoil";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { /* projectIdState, */ isPublishingState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";

// Constants
import { STATUS, COMMON } from "constants/brm";

// Components
import { RoutePath } from "routes/route-paths";
import { UploadButton, TraUploadButton } from "components/elements";
import { RestoreFromFileButton } from "features/importer/components/RestoreFromFileButton";
import ButtonCell from "components/EditComponents/ButtonCell";

// Services
import {
  createColumnMapped,
  createColumnOrganization,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnBooleanNoEditMapped,
  createColumnBooleanMapped,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";

// Hooks

import { useRoles, useModal } from "hooks";
import { CreateSavePointButton, RestoreSavePointButton } from "features/importer";
import {
  useExportFile,
  ExportBackupButton,
  ExportAarButton,
  // useExport,
  useGetExportStatus,
  useDownloadFile,
  // useExportAar,
  EXPORT_STATUS,
  // ExportRiskFileButton,
  EXPORT_TARGET,
} from "features/exporter";
import {
  AdminApi,
  ConfigurationApi,
  AuditApi,
  ImportApi,
  ExportApi,
  SavePointApi,
  ExportButton,
  PublishButton,
  useProject,
  isCurrentProjectPrepState,
  ProjectState,
  ProjectAccessLevel,
  useAdminProjects,
  ImportContext,
  ExportContext,
  LICENSE_VALIDATION_ERROR_STATUS,
  SystemApi,
} from "features/brm";

// Styles
import localforage from "localforage";
import { useProjIsListedUpdate, useProjNameUpdate } from "./project-overview-table-queries";

interface IHasId {
  id: string;
}

interface IHasStatus {
  status: number;
}

const statusList = [
  { id: STATUS.active, name: STATUS.active },
  { id: STATUS.sandbox, name: STATUS.sandbox },
  { id: STATUS.archived, name: STATUS.archived },
];

export const useProjectOverview = (id: string | undefined) => {
  const navigate = useNavigate();

  const { userId, userRole } = useRoles();
  const [statusError] = React.useState("");

  const [currentProject, { setCurrentProject }] = useProject();
  const { isAdmin, isSuperAdmin, isSystemEngineer, isRiskAnalyst, isThreatAnalyst } = useRoles();
  const { downloadFile } = useExportFile();
  const { isVisible: isAuditModalOpen, toggle: setAuditModalOpen } = useModal();
  const { canPublish } = ConfigurationApi.useCanPublish();

  const isProjectInPrep = Recoil.useRecoilValue(isCurrentProjectPrepState);
  // const projectId = Recoil.useRecoilValue(projectIdState);
  const variantId = Recoil.useRecoilValue(variantIdState);
  const isPublishing = Recoil.useRecoilValue(isPublishingState);

  const [uploadModalIsOpen, setUploadModalIsOpen] = React.useState(false);
  const [userAlertIsOpen, setUserAlertIsOpen] = React.useState(false);
  const [licenseCheckIsOpen, setLicenseCheckIsOpen] = React.useState(false);
  const [showSummaryTable, setShowSummaryTable] = React.useState(true);
  const [disablePublish, setDisablePublish] = React.useState(true);
  const [publishTooltip, setPublishTooltip] = React.useState("Publish");
  const [editMode, setEditMode] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [selectedElement, setSelectedElement] = React.useState({});
  const [disableCreateSavePoint, setDisableCreateSavePoint] = React.useState(false);
  const [disableRestoreSavePoint, setDisableRestoreSavePoint] = React.useState(false);
  const [disableExport, setDisableExport] = React.useState(false);
  // const [isAarExportDisabled, setIsAarDisabledExport] = React.useState(false);
  const [showRestoreSavePointModal, setShowRestoreSavePointModal] = React.useState(false);
  const [isRestoreFromFileModalVisible, setRestoreFromFileModalVisible] = React.useState(false);
  const [isExportAarModalVisible, setIsExportAarModalVisible] = React.useState(false);

  // Backup states
  const [disableExportBackup, setDisableExportBackup] = React.useState(false);
  const [responseFromExportBackup, setResponseFromExportBackup] = React.useState<IHasId>();
  const [exportBackupStatus, setExportBackupStatus] = React.useState("");
  const [backupStatusError, setBackupStatusError] = React.useState("");

  const isBORImport = React.useRef(false);
  const toastId = React.useRef("");
  const updatedName = React.useRef("");
  const updatedStatus = React.useRef("");
  const updatedIsListed = React.useRef("");

  // Queries

  // const { mutate: exportSystemRisk } = ComponentApi.useExportSystemRisk();
  // const { mutate: exportVariantRisk } = ComponentApi.useExportVariantRisk();
  const { mutate: exportAuditEditEvents } = AuditApi.useExportAuditEditEvents({});
  const { mutate: projRename } = useProjNameUpdate(id);
  const { mutate: projIsListedUpdate } = useProjIsListedUpdate(id);

  const paramId = id || "";
  const { mutate: publishSystemProject, isError: isPublishSystemProjectError } = ConfigurationApi.usePublishSystem({
    projectId: paramId,
  });
  const { mutate: savePointCreateProject } = SavePointApi.useSavePointCreateProject();
  const { mutate: savePointRestoreProject } = SavePointApi.useSavePointRestoreProject();
  const { mutate: fileExport } = ExportApi.useExportFile({
    onSuccess: (data: any) => {
      setResponseFromExportBackup(data);
    },
  });

  const {
    data: projectAccessLevel,
    isError: isProjectAccessError,
    error: projectAccessError,
  } = AdminApi.useProjectAccess({
    projectId: paramId,
    options: { enabled: !!paramId && !!userRole },
  });

  const { refetch: fetchStatus } = useGetExportStatus(
    id,
    responseFromExportBackup?.id,
    setExportBackupStatus,
    exportBackupStatus,
    setBackupStatusError,
    setDisableExportBackup,
    ExportContext.savepoint
  );

  const projId2 = id || "";
  const { hasProjectPermission: canUserAccessProject } = AdminApi.useUserProjectPermission({
    userId,
    projectId: projId2,
    options: {
      enabled: !!userId && !!projId2 && userRole !== "",
    },
  });

  // React.useEffect(() => {
  //   console.log("projectAccessLevel", projectAccessLevel);
  // }, [projectAccessLevel]);

  // console.log("userRole", userRole);
  // console.log("projOver canAccessProject", canUserAccessProject);
  // console.log("projOver projectAccessLevel", projectAccessLevel);
  // console.log("projOver enablement1", isAdmin && !!projectAccessLevel);
  // console.log("projOver enablement2", !!projectAccessLevel && canUserAccessProject);
  // console.log(
  //   "projOver enablement",
  //   !!paramId && ((!!projectAccessLevel && canUserAccessProject) || (isAdmin && !!projectAccessLevel) || isSuperAdmin)
  // );

  const {
    data: projectData,
    isError: isGetProjectDataError,
    error: projectDataError,
    refetch: refetchProjectData,
  } = AdminApi.useGetProjectWithOrgName({
    id: paramId,
    options: {
      enabled:
        !!paramId &&
        ((!!projectAccessLevel && canUserAccessProject) || (isAdmin && !!projectAccessLevel) || isSuperAdmin),
    },
  });

  const { refetch: fetchDownload } = useDownloadFile(
    id,
    projectData?.name,
    responseFromExportBackup?.id,
    exportBackupStatus,
    EXPORT_TARGET.zip,
    setDisableExportBackup,
    ExportContext.savepoint
  );

  const {
    data: system,
    isError: isSystemError,
    error: systemError,
  } = ConfigurationApi.useSystems({
    projectId: paramId,
    options: {
      enabled: !!paramId && isSystemEngineer && canUserAccessProject && !!projectAccessLevel,
    },
  });

  // const { data: subjects } = CategorizedEntityApi.useSystemElements({
  //   projectId: paramId,
  //   config: { enabled: !!paramId && isSystemEngineer },
  // });

  const {
    data: exchangeCount,
    isError: isExchangeError,
    error: exchangeError,
  } = SystemApi.useExchanges({
    projectId: paramId,
    variantId,
    options: {
      enabled:
        !!projectAccessLevel &&
        projectAccessLevel !== ProjectAccessLevel.None &&
        isSystemEngineer &&
        canUserAccessProject &&
        (currentProject?.status === STATUS.active || currentProject?.status === STATUS.inPreparation),
      select: (data) => data.length,
    },
  });

  const getUploadContext = () => {
    const uploadContext = {
      context: "",
      name: "",
    };

    if (isSystemEngineer) {
      uploadContext.context = ImportContext.system;
      uploadContext.name = "Import a system model";
    } else if (isBORImport.current) {
      uploadContext.context = ImportContext.bor;
      uploadContext.name = "Import BOR Findings";
    } else {
      uploadContext.context = ImportContext.tra;
      uploadContext.name = "Import TRA Template";
    }

    return uploadContext;
  };

  const {
    refetch: fetchImportHistory,
    isError: isImportHistoryError,
    error: importHistoryError,
    isLoading,
  } = ImportApi.useImportHistory({
    projectId: paramId,
    // context: isSystemEngineer ? ImportContext.system : ImportContext.bor,
    context: isSystemEngineer ? ImportContext.system : ImportContext.bor,
    options: {
      enabled: false,
      onSuccess: (data) => {
        setLicenseCheckIsOpen(false);
        if (data.length > 0) {
          setUserAlertIsOpen(true);
        } else {
          setUploadModalIsOpen(true);
        }
      },
      cacheTime: 0,
    },
  });

  const { data: adminProjects } = useAdminProjects(userId, userRole);
  // const { data: sapPrograms } = AdminApi.useGetSapPrograms({ queryParam: { project: projectId } });
  // console.error("sapPrograms", sapPrograms);

  React.useEffect(() => {
    if (responseFromExportBackup) {
      if (exportBackupStatus && exportBackupStatus === EXPORT_STATUS.pass) {
        fetchDownload();
      } else fetchStatus();
    }
    if (backupStatusError) {
      toast.error(backupStatusError, {
        duration: 4000,
      });
    }
  }, [backupStatusError, exportBackupStatus, fetchDownload, fetchStatus, responseFromExportBackup]);

  React.useEffect(() => {
    if (!isRiskAnalyst) {
      return;
    }

    if (currentProject !== null && isProjectInPrep && !isPublishing) {
      setCurrentProject(null);
      navigate(RoutePath.Projects);
    }
  }, [isRiskAnalyst, currentProject, isProjectInPrep, isPublishing, projectData, navigate, setCurrentProject]);

  React.useEffect(() => {
    if (system) {
      const isProjectPublishable = canPublish({
        onFailure: (nodes: any, isInPreparation: boolean) => {
          if (nodes && nodes.length > 0 && !isInPreparation) {
            setPublishTooltip("Project already published");
          } else if (nodes && !nodes.length && isInPreparation) {
            setPublishTooltip("Project unpublishable - no nodes");
          } else if (nodes && !nodes.length && !isInPreparation) {
            setPublishTooltip("Nothing to publish");
          }
        },
      });
      setDisablePublish(!isProjectPublishable);
    }
  }, [canPublish, isProjectInPrep, projectData, system]);

  React.useEffect(() => {
    // if (!isLoading) {
    //   setLicenseCheckIsOpen(true);
    // } else {
    //   setLicenseCheckIsOpen(false);
    // }

    if (isImportHistoryError) {
      if ((importHistoryError as unknown as IHasStatus)?.status === LICENSE_VALIDATION_ERROR_STATUS) {
        navigate(RoutePath.LicenseError);
        setLicenseCheckIsOpen(false);
      } else toast.error("Error occured while getting import history", { id: toastId.current });
    }
  }, [importHistoryError, isImportHistoryError, isLoading, navigate]);

  /**
   * If we don't have access to the project redirect to the projects list
   */
  React.useEffect(() => {
    // Admin can view projects in their organization..
    if (isAdmin && adminProjects?.some((p: any) => p.id === paramId)) {
      return;
    }

    // everyone else must have project access
    if (projectAccessLevel === ProjectAccessLevel.None && !isSuperAdmin) {
      setCurrentProject(null);
      navigate(RoutePath.Projects);
    }
  }, [adminProjects, isAdmin, isSuperAdmin, navigate, paramId, projectAccessLevel, setCurrentProject]);

  /**
   * When the current project changes and it's the same id as the project being displayed
   * then just refresh the data... mainly to catch status updates...
   */
  React.useEffect(() => {
    if (paramId === currentProject?.id) {
      if (canUserAccessProject) {
        // console.log("refetch");
        refetchProjectData();
      }
    }
  }, [canUserAccessProject, currentProject, paramId, refetchProjectData]);

  const handleAuditReportDownload = React.useCallback(
    ({ format, type }: any) => {
      setDisableExport(true);
      exportAuditEditEvents(
        { projectId: paramId, variantId, format },
        {
          onSuccess: (auditData) => {
            setDisableExport(false);
            downloadFile({ data: auditData, format, name: `AUDIT-${type}` });
          },
          onError: (error) => {
            setDisableExport(false);

            const msg =
              (error as any).error.statusCode === 403
                ? "Not authorized to create audit report"
                : "Error occurred while generating audit report";
            toast.error(msg, {
              duration: 4000,
            });
          },
        }
      );
    },
    [downloadFile, exportAuditEditEvents, paramId, variantId]
  );

  const handleRestoreSavePointModalOpen = () => {
    setShowRestoreSavePointModal(true);
  };

  const handleRestoreSavePointModalClose = () => {
    setShowRestoreSavePointModal(false);
  };

  const handleRestoreFromFile = () => {
    setRestoreFromFileModalVisible(true);
  };

  const handleRestoreFromFileModalClose = () => {
    setRestoreFromFileModalVisible(false);
  };

  // const handleExportRiskFile = React.useCallback(() => {
  //   if (currentVariant?.id === COMMON.nullUuid) {
  //     exportSystemRisk({ systemId: system.id });
  //   } else if (currentVariant) {
  //     exportVariantRisk({ variantId: currentVariant.id });
  //   } else {
  //     toast.error("Unable to export risk");
  //   }
  // }, [currentVariant, exportSystemRisk, exportVariantRisk, system]);

  const createSavePoint = React.useCallback(() => {
    setDisableCreateSavePoint(true);
    savePointCreateProject(
      { projectId: paramId },
      {
        onSuccess: () => {
          setDisableCreateSavePoint(false);
          toast.success("Save point created successfully");
        },
        onError: (error) => {
          setDisableCreateSavePoint(false);
          const msg =
            (error as any).error.statusCode === 403
              ? "Not authorized to create save point"
              : "Error occurred while generating save point";
          toast.error(msg, {
            duration: 4000,
          });
        },
      }
    );
  }, [paramId, savePointCreateProject]);

  const restoreSavePoint = React.useCallback(
    ({ projId, savePointId }: any) => {
      setDisableRestoreSavePoint(true);
      savePointRestoreProject(
        { projectId: projId, runId: savePointId },
        {
          onSuccess: () => {
            setDisableRestoreSavePoint(false);
            localforage.removeItem(`sd_layout_${projId}`);
            toast.success("Save point restored successfully");
          },
          onError: (error) => {
            setDisableRestoreSavePoint(false);
            const msg =
              (error as any).error.statusCode === 403
                ? "Not authorized to restore save point"
                : "Error occurred while restoring save point";
            toast.error(msg, {
              duration: 4000,
            });
          },
        }
      );
    },
    [savePointRestoreProject]
  );

  const handleExportBackup = React.useCallback(() => {
    setDisableExportBackup(true);
    const opts = {
      target: EXPORT_TARGET.zip,
    };
    fileExport(
      // { projectId: paramId, body: opts, context: ExportContext.savepoint },
      { projectId: paramId, body: opts, context: ExportContext.savepoint },
      {
        onError: (error) => {
          setDisableExportBackup(false);
          const msg =
            (error as any).error.statusCode === 403
              ? "Not authorized to generate backup file"
              : "Error occurred while generating backup file";
          toast.error(msg, {
            duration: 4000,
          });
        },
      }
    );
  }, [fileExport, paramId]);

  // const handleExportAar = React.useCallback(() => {
  //   exportAarFile();
  // }, [exportAarFile]);

  const createButton = React.useCallback(
    (cellProps: any) => {
      const handleUploadbtnClicked = (isBORImportFlag = true) => {
        isBORImport.current = isBORImportFlag;
        fetchImportHistory();
      };
      let userButtons;

      const createSavePointButton = (
        <CreateSavePointButton
          onClick={() => {
            createSavePoint();
          }}
          disableSavePoint={disableCreateSavePoint}
        />
      );

      const restoreSavePointButton = (
        <RestoreSavePointButton
          onClick={() => {
            handleRestoreSavePointModalOpen();
          }}
          disableSavePoint={disableRestoreSavePoint}
        />
      );

      const exportBackupButton = (
        <ExportBackupButton
          onClick={() => {
            handleExportBackup();
          }}
          disableBackup={disableExportBackup}
        />
      );

      const exportAarButton = (
        <ExportAarButton
          onClick={() => {
            setIsExportAarModalVisible(true);
          }}
          disabled={exchangeCount === 0}
        />
      );

      if (isSuperAdmin || isAdmin) {
        userButtons = (
          <>
            <ButtonCell
              selectedRowId={selectedRowId}
              elementId={cellProps.cell.row.original.id}
              handleConfirmEditClick={async () => {
                setEditMode(false);
                if (updatedName.current !== "") {
                  projRename(
                    {
                      id: selectedRowId,
                      projectName: updatedName.current,
                    },
                    {
                      onSettled: () => {
                        updatedName.current = "";
                      },
                    }
                  );
                }

                if (updatedIsListed.current && updatedIsListed.current !== "Select isListed") {
                  projIsListedUpdate(
                    {
                      id: selectedRowId,
                      isListed: updatedIsListed.current,
                    },
                    {
                      onSettled: () => {
                        updatedIsListed.current = "";
                      },
                    }
                  );
                }
              }}
              setEditMode={setEditMode}
              editMode={editMode}
            />
            {createSavePointButton}
            {isAdmin ? restoreSavePointButton : null}
            {isAdmin ? exportBackupButton : null}
            <RestoreFromFileButton onClick={handleRestoreFromFile} />
          </>
        );
      }
      if (isSystemEngineer && system?.id) {
        userButtons = (
          <div>
            <UploadButton
              onClick={() => {
                fetchImportHistory();
              }}
              uploadTitle="Model Import"
            />
            <PublishButton
              onClick={() => publishSystemProject({ systemId: system.id })}
              disablePublish={disablePublish}
              title={publishTooltip}
            />
            {createSavePointButton}
            {exportAarButton}
          </div>
        );
      }

      if (isRiskAnalyst) {
        userButtons = (
          <>
            <UploadButton onClick={() => handleUploadbtnClicked(true)} uploadTitle="BOR Import" />
            <ExportButton
              buttonTitle="Export Calibration Audit Records"
              onClick={() => setAuditModalOpen()}
              disableExport={disableExport}
            />
            <TraUploadButton onClick={() => handleUploadbtnClicked(false)} uploadTitle="TRA Templates Import" />
            {createSavePointButton}
            {/* <ExportRiskFileButton onClick={handleExportRiskFile} /> */}
          </>
        );
      }

      return userButtons || <>{COMMON.na}</>;
    },
    [
      disableCreateSavePoint,
      disableRestoreSavePoint,
      disableExportBackup,
      exchangeCount,
      isSuperAdmin,
      isAdmin,
      isSystemEngineer,
      system?.id,
      isRiskAnalyst,
      fetchImportHistory,
      createSavePoint,
      handleExportBackup,
      selectedRowId,
      editMode,
      projRename,
      projIsListedUpdate,
      disablePublish,
      publishTooltip,
      publishSystemProject,
      disableExport,
      setAuditModalOpen,
    ]
  );

  React.useEffect(() => {
    if (
      projectData &&
      currentProject === null &&
      (isSystemEngineer || isRiskAnalyst || isThreatAnalyst) &&
      projectAccessLevel !== ProjectAccessLevel.None
    ) {
      const projState: ProjectState = {
        id: projectData.id,
        name: projectData.name,
        status: projectData.status,
        isListed: projectData.isListed,
        organization: {
          id: projectData.organization.id,
          name: projectData.organization.name,
          status: projectData.organization.status,
        },
      };
      // console.log("setting project", projState);
      setCurrentProject(projState);
    }
  }, [
    isSystemEngineer,
    isRiskAnalyst,
    isThreatAnalyst,
    currentProject,
    projectData,
    setCurrentProject,
    projectAccessLevel,
  ]);

  const columns = React.useMemo(() => {
    if (isSuperAdmin || isAdmin) {
      return [
        createColumnMapped("status", selectedRowId, statusList, false, updatedStatus),
        createColumnOrganization("orgName"),
        createColumnCreatedAt(),
        createColumnModifiedAt(),
        createColumnBooleanNoEditMapped("isShared"),
        createColumnBooleanMapped("isListed", selectedRowId, editMode, updatedIsListed),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnMapped("status", selectedRowId, statusList, false, updatedStatus),
      createColumnOrganization("orgName"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnBooleanNoEditMapped("isShared"),
      createColumnBooleanMapped("isListed", selectedRowId, false, updatedIsListed),
      createColumnActions(createButton, { disableFilters: true }),
    ];
  }, [isSuperAdmin, isAdmin, selectedRowId, createButton, editMode]);

  return {
    selectedRowId,
    isGetProjectDataError,
    isSystemError,
    systemError,
    isPublishSystemProjectError,
    projectDataError,
    columns,
    isRestoreFromFileModalVisible,
    handleRestoreFromFileModalClose,
    showRestoreSavePointModal,
    restoreSavePoint,
    handleRestoreSavePointModalClose,
    uploadModalIsOpen,
    setUploadModalIsOpen,
    uploadContext: getUploadContext(),
    licenseCheckIsOpen,
    setLicenseCheckIsOpen,
    userAlertIsOpen,
    setUserAlertIsOpen,
    isAuditModalOpen,
    setAuditModalOpen,
    handleAuditReportDownload,
    projectData,
    showSummaryTable,
    setShowSummaryTable,
    projectId: id,
    id,
    selectedElement,
    setSelectedElement,
    setSelectedRowId,
    statusError,
    isExportAarModalVisible,
    setIsExportAarModalVisible,
    isExchangeError,
    exchangeError,
    isProjectAccessError,
    projectAccessError,
  };
};
