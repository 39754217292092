import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./resource-query-key-factory";
import * as NodeKeyFactory from "../node/node-query-key-factory";

interface IAddResourceNodeProps {
  resourceId: string | any;
  nodeId: string | null;
}

/**
 * Add a node to resource
 *
 * @returns
 */
export const addResourceNode = ({ resourceId, nodeId }: IAddResourceNodeProps) => {
  return resourceId === null
    ? Promise.reject(new Error("Invalid resource id"))
    : Brm.resourceApi.addResourceNode(resourceId, { body: nodeId });
};

interface IAddResourceNodeQueryProps {
  options?: MutationOption<typeof addResourceNode>;
}

/**
 * Custom hook to add a node to resource
 *
 * @returns react-query Mutation.
 */
export const useAddResourceNode = ({ options }: IAddResourceNodeQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.resourceKeys.all);
      queryClient.invalidateQueries(NodeKeyFactory.nodeKeys.resources(variables.nodeId));
    },
    ...options,
    mutationFn: addResourceNode,
  });
};
