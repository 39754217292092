import { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import Title from "components/elements/StyledTitle";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { variantIdState } from "atoms/atoms-component";
import { riskUpdatedState } from "atoms/atoms-risk";
import { LoadingSpinner } from "components/elements";
import {
  createColumnMapped,
  createColumnMappedNoEdit,
  createColumnLikelihoodMapped,
} from "brm/tables/services/column/columnFactory";
import AttackDetails from "brm/risk-model/attack/details/AttackDetails";
import * as AttackApi from "brm/risk-model/attack/api";
import { TYPE } from "constants/brm";
import { RoutePath } from "routes/route-paths";
import { useRoles } from "hooks";

const listUrl = RoutePath.Attacks;

function AttackFullDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [editMode /* setEditMode */] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [kindList /* setKindList */] = useState([]);
  const [tacticList /* setTacticList */] = useState([]);
  const [mechanismList /* setMechanismList */] = useState([]);
  const updatedKind = useRef("");
  const updatedTactic = useRef("");
  const updatedMechanism = useRef("");
  const riskUpdated = useRecoilValue(riskUpdatedState);
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const { isRiskAnalyst } = useRoles();
  const {
    data: attackById,
    isLoading,
    isError: isAttackByIdError,
  } = AttackApi.useAttack({ attackId: id, variantId, config: { enabled: !!id && isRiskAnalyst } });

  let content = null;

  const columns = useMemo(
    () => [
      createColumnMapped("kind", selectedRowId, kindList, editMode, updatedKind),
      createColumnMapped("tactic", selectedRowId, tacticList, editMode, updatedTactic),
      createColumnMapped("mechanism", selectedRowId, mechanismList, editMode, updatedMechanism),
      createColumnLikelihoodMapped("likelihood"),
      createColumnLikelihoodMapped("mitigatedlikelihood"),
      createColumnMappedNoEdit("trevpat"),
      createColumnMappedNoEdit("attackercat"),
    ],
    [kindList, tacticList, mechanismList, selectedRowId, editMode]
  );

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(listUrl);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    if (attackById) {
      setSelectedElement(attackById);
      setData([attackById]);
    }
  }, [attackById, riskUpdated]);

  if (isAttackByIdError) {
    content = <>An error occurred while retrieving Attack data.</>;
  }

  if (isLoading) {
    content = <LoadingSpinner />;
  }

  if (attackById) {
    content = (
      <>
        <Title id="AttackFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "AttackFullDetails_table" }}
              elementName={TYPE.attack}
            />
          </DetailsContainerSingleTable>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <>
      {content}

      {selectedElement.id && (
        <DetailsContainerParent showSummaryTable={showSummaryTable}>
          <DetailsContainer id="Attack_detailsPanel">
            <AttackDetails selectedElement={selectedElement} setRefresh={setRefresh} refresh={refresh} />
          </DetailsContainer>
        </DetailsContainerParent>
      )}
    </>
  );
}

export default AttackFullDetails;
