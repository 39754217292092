import PropTypes from "prop-types";
import { useState } from "react";
import toast from "react-hot-toast";
import { queryClient } from "libs/react-query";

// State
import { useRecoilState, useRecoilValue } from "recoil";
import { variantIdState } from "atoms/atoms-component";
import { likelihoodOptionsState } from "atoms/atoms-likelihood-calibration";
import { projectIdState } from "atoms/atoms-admin";
// import { riskUpdatedState } from "atoms/atoms-risk";

// Components
import Select from "react-select";
import systemModals from "components/modals/Modals";

// Services
import * as BrmGql from "generated/graphql";
import { RiskApi } from "features/brm";
import { convertLevelForBackend } from "utils/calibration-converter";

// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { setCalibrationStyle } from "components/elements/ReactSelectCustomStyles";
import * as S from "../styles/modal-styles";

export const SetLikelihoodLevelModal = ({ modalIsOpen, setModalIsOpen, selectedRows }) => {
  const [likelihoodOptions] = useRecoilState(likelihoodOptionsState);
  const [selectedLikelihoodLevel, setSelectedLikelihoodLevel] = useState("");
  const [variantId] = useRecoilState(variantIdState);
  const projectId = useRecoilValue(projectIdState);
  // const riskUpdated = useRecoilValue(riskUpdatedState);
  const { mutate: setAttackLikelihood } = BrmGql.useSetAttackLikelihoodMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(RiskApi.attackKeys.likelihoodCalibrationAttacks(projectId, variantId));
    },
    onError: () => {
      toast.error("Error occured while setting likelihood level", {
        duration: 4000,
      });
      setModalIsOpen(false);
    },
  });

  function setLevelClick() {
    const convertedLevel = convertLevelForBackend(selectedLikelihoodLevel.label.toLowerCase());
    let nonUpdatedLikelihood = false;
    const selectRowsToModify = (row) => {
      if (row.likelihood.stringValue !== convertedLevel) {
        return true;
      }
      nonUpdatedLikelihood = true;
      return false;
    };
    const attackIds = selectedRows.filter(selectRowsToModify).map((each) => each.id);
    setAttackLikelihood(
      { ids: attackIds, project: projectId, variant: variantId, calibratedStringValue: convertedLevel },
      {
        onSuccess: () => {
          if (nonUpdatedLikelihood) {
            if (selectedRows.length > 1) {
              toast(
                "Some of the chosen Attacks are already at the Likelihood Level selected, so those chosen Attacks will not be adjusted",
                {
                  duration: 6000,
                }
              );
            } else {
              toast("Attack is already at the Likelihood Level selected, please select a different Likelihood Level", {
                duration: 5000,
              });
            }
          } else {
            toast.success("Likelihood Level set successfully", { duration: 4000 });
          }
          setModalIsOpen(false);
        },
        enabled: !!projectId && !!variantId && !!attackIds && !!convertedLevel,
      }
    );
  }

  const handleCancel = () => {
    setModalIsOpen(false);
  };

  const body = (
    <>
      <S.ShortBodyDiv>
        <p>Select the desired calibrated level:</p>
        <Select
          isMulti={false}
          options={likelihoodOptions}
          defaultValue={selectedLikelihoodLevel}
          onChange={(selectedItem) => setSelectedLikelihoodLevel(selectedItem)}
          styles={setCalibrationStyle}
          classNamePrefix="likelihoodLevelDropdown"
          id="SetLikelihoodLevelModal_likelihoodLevelDropdown"
        />
      </S.ShortBodyDiv>
      <DialogButtonDivStyled>
        <DialogButtonStyled id="SetLikelihoodLevelModal_cancelButton" onClick={handleCancel}>
          Cancel
        </DialogButtonStyled>
        <DialogButtonStyled
          id="SetLikelihoodLevelModal_confirmButton"
          onClick={() => setLevelClick()}
          disabled={selectedLikelihoodLevel === ""}
        >
          Confirm
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </>
  );

  return (
    <div id="SetLikelihoodLevelModel_modal">
      {systemModals.customModal(
        "SetLikelihoodLevelModel_modal",
        modalIsOpen,
        setModalIsOpen,
        body,
        "Set Calibrated Likelihood Level"
      )}
    </div>
  );
};

SetLikelihoodLevelModal.propTypes = {
  modalIsOpen: PropTypes.any,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
  setModalIsOpen: PropTypes.func,
};
