import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { COMMON } from "constants/brm";
import * as KeyFactory from "./variant-query-key-factory";

interface IGetVariantSctm {
  variantId: string;
}

/**
 *
 * @param {string} variantId - uuid of the project
 * @returns list of variants
 */
export const getVariantSctm = async ({ variantId }: IGetVariantSctm): Promise<any> => {
  if (variantId === COMMON.nullUuid) {
    throw new Error(`VariantId: ${variantId} does not have sctm`);
  }
  return Brm.variantApi.getVariantSctm(variantId);
};

interface IUseVariants {
  variantId: string;
  options?: QueryOption<typeof getVariantSctm>;
}

/**
 * React-query hook for getting all variants
 *
 * @param {string} variantId - uuid of the project
 * @param {object} options - react-query configuration object
 * @returns react-query for getVariantsSctm
 */
export const useVariantSctm = ({ variantId, options = {} }: IUseVariants) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.variantsKeys.sctm(variantId),
    queryFn: () => getVariantSctm({ variantId }),
  });
};
