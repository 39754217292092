import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

export const setThreatTierName = ({ id, name }) => {
  return Brm.threatTierApi.setThreatTierName(id, { body: name });
};

/**
 * Custom hook to change the name of a existing ThreatTier
 * @returns react-query ReactQuery.useMutation
 */
export const useSetThreatTierName = () => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation(({ id, name }) => setThreatTierName({ id, name }), {
    onMutate: async ({ id, name }) => {
      await queryClient.cancelQueries(["threatTiers"]);
      const previousValues = queryClient.getQueryData(["threatTiers"]);
      queryClient.setQueryData(["threatTiers"], (old) =>
        old.map((data) => {
          if (id === data.id) {
            return {
              ...data,
              name,
            };
          }
          return data;
        })
      );
      return previousValues;
    },
    onError: (previousValues) => queryClient.setQueryData(["threatTiers"], previousValues),
    onSuccess: () => {
      queryClient.invalidateQueries(["threatTiers"]);
      queryClient.invalidateQueries(["threatTier"]);
    },
  });
};
