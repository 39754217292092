import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import { DetailTableType } from "features/brm";
import { UserSapPanel } from "features/admin/components/UserSapPanel";
import { useSap } from "features/admin/hooks";
import { ProjectDetailsTable } from "../../tables/ProjectDetailsTable";
import { AttachedOrganizationDetailsTable } from "../../tables/AttachedOrganizationDetailsTable";
import { AssignedRoleDetailsTable } from "../../tables/AssignedRolesDetailsTable";

export const UserDetailsTab = ({ selectedElement }: { selectedElement: any }) => {
  const elementNameInUrl = DetailTableType.USER.key;
  const { isEnabled } = useSap();

  return (
    <Tabs id="UserDetails_tabs">
      <TabList id="UserDetails_tabList">
        <Tab>Assigned Roles</Tab>
        <Tab>Projects</Tab>
        <Tab>Attached Organizations</Tab>
        {isEnabled ? <Tab>SAP</Tab> : null}
      </TabList>

      <TabPanel className="UserDetails_AssignedRoleTable">
        <AssignedRoleDetailsTable selectedElement={selectedElement} showAdd showDelete />
      </TabPanel>
      <TabPanel className="UserDetails_ProjectTable">
        <ProjectDetailsTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
      <TabPanel className="UserDetails_AttachedOrganizationTable">
        <AttachedOrganizationDetailsTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
      {isEnabled ? (
        <TabPanel>
          <UserSapPanel userId={selectedElement.id} />
        </TabPanel>
      ) : null}
    </Tabs>
  );
};
