import * as React from "react";

import SelectCategory from "components/forms/DynamicSelectBoxCategory";
import SelectBoxByID from "components/forms/DynamicSelectBox";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import { ExchangeCreateDto } from "@kdmanalytics/brm-system";
import PropTypes from "prop-types";
import { sortUpperCase, sortByName } from "utils/sorting";
import { validateNameUniqueness } from "features/system-model";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import TextField from "components/forms/TextField";
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
import { useQueryClient } from "@tanstack/react-query";
import { LoadingSpinner as Loading } from "components/elements";
import { SystemApi } from "features/brm";
import localforage from "localforage";

const SELECT_PARENT = "Select Parent";
const SELECT_CATEGORY = "Select Category";
const SELECT_DATA = "Select Data";
const SELECT_ACT_PRODUCER = "Select Activity Producer";
const SELECT_ACT_CONSUMER = "Select Activity Consumer";

const AddExchangeForm = ({ setModalIsOpen }) => {
  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = React.useState("");
  const [isTextValid, setIsTextValid] = React.useState(true);

  const queryClient = useQueryClient();

  const { data: elementCategories } = SystemApi.useElementCategories({
    projectId,
    options: {
      select: React.useCallback((data) => data.sort(sortUpperCase), []),
    },
  });

  const { data: exchanges } = SystemApi.useExchanges({
    projectId,
    options: { enabled: !!projectId },
  });

  const { data: nodes } = SystemApi.useNodes({
    projectId,
    options: {
      select: React.useCallback((data) => data.sort(sortByName), []),
    },
  });

  const { data: datatypes } = SystemApi.useDataTypes({
    projectId,
    options: {
      select: React.useCallback((data) => data.sort(sortByName), []),
    },
  });

  const { data: activities } = SystemApi.useActivities({
    projectId,
    options: {
      select: React.useCallback((data) => data.sort(sortByName), []),
    },
  });

  const createExchange = SystemApi.useCreateExchange({
    options: {
      onSuccess: async () => {
        queryClient.invalidateQueries(SystemApi.exchangeKeys.project(projectId));
      },
    },
  });

  const setExchangeParent = SystemApi.useSetExchangeParent();
  const addExchangeActProducer = SystemApi.useAddExchangeActProducer();
  const addExchangeActConsumer = SystemApi.useAddExchangeActConsumer();

  // validate that all fields in the form have been completed
  const validate = (formData) => {
    let error = true;
    if (
      formData.name.value === "" ||
      formData.category.value === SELECT_CATEGORY ||
      formData.data.value === SELECT_DATA ||
      formData.data.value === "" ||
      formData.producer.value === SELECT_ACT_PRODUCER ||
      formData.consumer.value === SELECT_ACT_CONSUMER
    ) {
      error = false;
    }
    return error;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isNameValid = validateNameUniqueness(exchanges, e.target.elements.name.value);
    if (!isNameValid) {
      setPostError(FORM_ERROR.duplicateExchangeName);
      return null;
    }

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      let parent = "";
      let producingActivity = "";
      let consumingActivity = "";

      if (e.target.elements.parent.value !== SELECT_PARENT) {
        parent = e.target.elements.parent.value;
      }

      if (e.target.elements.actProducer.value !== SELECT_ACT_PRODUCER) {
        producingActivity = e.target.elements.actProducer.value;
      }

      if (e.target.elements.actConsumer.value !== SELECT_ACT_CONSUMER) {
        consumingActivity = e.target.elements.actConsumer.value;
      }

      // the new instance from user - form entry
      const params = {
        // mandatory
        name: e.target.elements.name.value,
        note: e.target.elements.note.value,
        category: e.target.elements.category.value,
        data: e.target.elements.data.value,
        producer: e.target.elements.producer.value,
        consumer: e.target.elements.consumer.value,
      };

      const dto = ExchangeCreateDto.constructFromObject(params);

      // const exchangeId = await Brm.exchangeApi.createExchange(projectId, { exchangeCreateDto: dto });
      const exchangeId = await createExchange.mutateAsync({ projectId, exchangeCreateDto: dto });

      if (parent !== "") {
        await setExchangeParent.mutateAsync({ exchangeId, parentId: parent });
      }

      if (producingActivity !== "") {
        await addExchangeActProducer.mutateAsync({ exchangeId, producerId: producingActivity });
      }
      if (consumingActivity !== "") {
        await addExchangeActConsumer.mutateAsync({ exchangeId, consumerId: consumingActivity });
      }
      queryClient.invalidateQueries(SystemApi.exchangeKeys.project(projectId));
      localforage.removeItem(`sd_layout_${projectId}`);
      setModalIsOpen(false);
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  };
  if (elementCategories && nodes && datatypes && activities) {
    return (
      <div id="ExchangeAddForm" style={{ maxHeight: "95vh", overflow: "auto" }}>
        <form onSubmit={handleSubmit} action="">
          <FormStyled>
            <div className="form-style">
              <TextField
                label={{ id: "AddExchangeForm_name", name: FORM_LABEL.nameMandatory }}
                input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "AddExchangeForm_note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />

              <label id="AddExchangeForm_category">{FORM_LABEL.categoryMandatory}</label>
              <SelectCategory arrayOfData={elementCategories} name="Category" item="category" defaultCategory="Basic" />

              <label id="AddExchangeForm_data">{FORM_LABEL.dataMandatory}</label>
              <SelectBoxByID arrayOfData={datatypes} name="Data" item="data" />

              <label id="AddExchangeForm_producer">{FORM_LABEL.producerMandatory}</label>
              <SelectBoxByID
                id="AddExchangeForm_producerDropdown"
                arrayOfData={nodes}
                name="Producer"
                item="producer"
              />

              <label id="AddExchangeForm_consumer">{FORM_LABEL.consumerMandatory}</label>
              <SelectBoxByID
                id="AddExchangeForm_consumerDropdown"
                arrayOfData={nodes}
                name="Consumer"
                item="consumer"
              />

              <label id="AddExchangeForm_activityConsumer">{FORM_LABEL.activityConsumerOptional}</label>
              <SelectBoxByID
                id="AddExchangeForm_activityConsumerDropdown"
                arrayOfData={activities}
                name="Activity Consumer"
                item="actConsumer"
                isOptional
              />

              <label id="AddExchangeForm_activityProducer">{FORM_LABEL.activityProducerOptional}</label>
              <SelectBoxByID
                id="AddExchangeForm_activityProducerDropdown"
                arrayOfData={activities}
                name="Activity Producer"
                item="actProducer"
                isOptional
              />

              <label id="AddExchangeForm_parent">{FORM_LABEL.parentOptional}</label>
              <SelectBoxByID
                id="AddExchangeForm_parentDropdown"
                arrayOfData={nodes}
                name="Parent"
                item="parent"
                isOptional
              />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
            <DialogButtonStyled type="submit">Add</DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }
  return <Loading />;
};

AddExchangeForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};

export default AddExchangeForm;
