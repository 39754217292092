import * as Recoil from "recoil";
import toast from "react-hot-toast";
import { useProject, useSwitchUser, useSwitchRole } from "features/brm";
import { userAssignedRolesState, userRoleState } from "atoms/atoms-admin";
import Dropdown from "./Dropdown";

const RoleSelector = () => {
  const currentUserRole = Recoil.useRecoilValue(userRoleState);
  const allocatedRoles = Recoil.useRecoilValue(userAssignedRolesState);

  const { refreshAssignedRoles } = useSwitchUser();
  const [currentProject] = useProject();

  const { setUserRole } = useSwitchRole({
    onPublish: () => {
      toast.success(`${currentProject.name} successfully published`);
    },
  });

  return (
    <Dropdown
      currentUserRole={currentUserRole || ""}
      allocatedRoles={allocatedRoles}
      onRoleChange={setUserRole}
      onRoleRefresh={refreshAssignedRoles}
    />
  );
};

export default RoleSelector;
