import * as ReactQuery from "@tanstack/react-query";
import { MutationOption /* , queryClient */ } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./sap-org-query-key-factory";
import * as SapProgramKeyFactory from "../sapProgram/sap-program-query-key-factory";
import * as SapPersonKeyFactory from "../sapPerson/sap-person-query-key-factory";
import * as SapBindingKeyFactory from "../sapBinding/sap-binding-query-key-factory";
import * as GlobalSettingKeyFactory from "../globalsetting/global-settings-query-key-factory";

/**
 * Reset SAP organization
 *
 * @returns
 */
export const resetSapOrganization = () => {
  return Brm.sapOrganizationApi.resetSapOrganization();
};

type MutationFunction = typeof resetSapOrganization;
export interface IUseResetSapOrganization {
  options?: MutationOption<MutationFunction>;
}

/**
 * Custom hook to reset Sap data
 *
 * @returns react-query Mutation.
 */
export const useResetSapOrganization = ({ options = {} }: IUseResetSapOrganization = {}) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation({
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: KeyFactory.sapOrganizationKeys.all });
      queryClient.invalidateQueries({ queryKey: SapProgramKeyFactory.sapProgramKeys.all });
      queryClient.invalidateQueries({ queryKey: SapPersonKeyFactory.sapPersonKeys.all });
      queryClient.invalidateQueries({ queryKey: SapBindingKeyFactory.sapBindingKeys.allOrg });
      queryClient.invalidateQueries({ queryKey: SapBindingKeyFactory.sapBindingKeys.allProject });
      queryClient.invalidateQueries({ queryKey: SapBindingKeyFactory.sapBindingKeys.allUser });
      queryClient.invalidateQueries({ queryKey: GlobalSettingKeyFactory.globalSettingKeys.all });
    },
    mutationFn: resetSapOrganization,
  });
};
