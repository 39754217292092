import PropTypes from "prop-types";
import { useState } from "react";

// Components
import Select from "react-select";
import systemModals from "components/modals/Modals";

// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { setCalibrationStyle } from "components/elements/ReactSelectCustomStyles";
import getReactSelectArray from "utils/react-select-array";
import { useRecoilValue } from "recoil";
import { sctmIdState } from "atoms/atoms-mitigation";
import S from "../styles/calibration.styles";
import { useVulCategoryUpdate } from "../../../api";

export const SetVulCatModal = ({ modalIsOpen, setModalIsOpen, selectedRows, vulCategoryData }) => {
  const [selectedVulCategory, setSelectedVulCategory] = useState("");
  const sctmId = useRecoilValue(sctmIdState);
  const setValuCategory = useVulCategoryUpdate(sctmId);
  async function setLevelClick() {
    selectedRows.forEach((each) =>
      setValuCategory.mutate({ allocatedControlId: each.id, category: selectedVulCategory.label })
    );
    setModalIsOpen(false);
  }

  const handleCancel = () => {
    setModalIsOpen(false);
  };

  const body = (
    <>
      <S.BodyDiv>
        <p>Select the desired vulnerability category:</p>
        <Select
          isMulti={false}
          options={getReactSelectArray(vulCategoryData)}
          defaultValue={selectedVulCategory}
          onChange={(selectedItem) => setSelectedVulCategory(selectedItem)}
          styles={setCalibrationStyle}
          classNamePrefix="vulnerabilityCategoryDropdown"
          id="SetVulCatModal_vulnerabilityCategoryDropdown"
        />
      </S.BodyDiv>
      <DialogButtonDivStyled>
        <DialogButtonStyled id="SetVulCatModal_cancelButton" onClick={handleCancel}>
          Cancel
        </DialogButtonStyled>
        <DialogButtonStyled
          id="SetVulCatModal_confirmButton"
          onClick={() => setLevelClick()}
          disabled={selectedVulCategory === ""}
        >
          Confirm
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </>
  );

  return (
    <div id="SetVulCatModal_modal">
      {systemModals.customModal(
        "SetVulCatModal_modal",
        modalIsOpen,
        setModalIsOpen,
        body,
        "Set Vulnerability Category"
      )}
    </div>
  );
};

SetVulCatModal.propTypes = {
  modalIsOpen: PropTypes.func,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
  setModalIsOpen: PropTypes.func,
  vulCategoryData: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
};
