import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

interface IGetControlFamily {
  controlFamilyId: string;
  controlCatalogId: string;
}

/**
 * function performing api call to get details of control family by its id
 *
 * @param {string} cfId - uuid of the control family
 * @param {string} controlCatalogId - uuid of the control catalog
 * @returns object of the control family details - controlFamilylongDto
 */
export const getControlFamily = async ({ controlFamilyId, controlCatalogId }: IGetControlFamily) => {
  let controlFamilyToDisplay: any = {};
  if (controlFamilyId) {
    const data = await Brm.controlFamilyApi.getControlFamilyByID(controlFamilyId);
    controlFamilyToDisplay = data;
  }
  if (controlCatalogId) {
    const catalog = await Brm.controlCatalogApi.getControlCatalogByID(controlCatalogId);
    controlFamilyToDisplay.catalog = catalog;
  }

  return [controlFamilyToDisplay];
};

interface IUseControlFamily {
  controlFamilyId: string;
  controlCatalogId: string;
  options: QueryOption<typeof getControlFamily>;
}

/**
 * React-query hook for getting  Control Family details by id
 *
 * @param {string} controlFamilyId - uuid of the control Family
 * @param {string} controlCatalogId - uuid of the control catalog
 * @param {object} config - configuration for useControlFamily hook
 * @returns react-query for getControlFamily
 */
export const useControlFamily = ({ controlFamilyId, controlCatalogId, options = {} }: IUseControlFamily) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.controlFamilyKeys.controlFamily(controlFamilyId, controlCatalogId),
    queryFn: () => getControlFamily({ controlFamilyId, controlCatalogId }),
  });
};
