import styled from "styled-components/macro";

const notesPaneStyles = {
  Container: styled.div`
    width: 80%;
    height: 100%;
    background-color: #ffffff;
    margin-left: ${({ theme }) => theme.sizes.defaultPadding};
    padding: ${({ theme }) => theme.sizes.dragBarWidth};
    overflow: auto;
  `,
  Text: styled.div`
    font: ${({ theme }) => theme.fonts.primary};
    a {
      &:hover {
        cursor: pointer;
      }
    }
  `,
  Title: styled.h6`
    font: ${({ theme }) => theme.fonts.primary};
    font-weight: bold;
  `,
  Msg: styled.p`
    color: ${({ theme }) => theme.colors.errorText};
    display: inline;
    padding-left: ${({ theme }) => theme.sizes.dragBarWidth};
  `,
};

export default notesPaneStyles;
