import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./activity-query-key-factory";

/**
 * Returns list of activities
 *
 * @param {string} projectId - uuid of project
 * @returns list of activities
 */
export const getActivities = ({ projectId }: { projectId: string }) => {
  return Brm.activityApi.findActivity(projectId);
};

interface IUseActivitiesProps {
  projectId: string;
  options?: QueryOption<typeof getActivities>;
}

/**
 * Returns a list of activities
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query configuration object
 * @returns list of activities
 */
export const useActivities = ({ projectId, options = {} }: IUseActivitiesProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.activitiesKeys.activities(projectId),
    queryFn: () => getActivities({ projectId }),
  });
};
