import * as React from "react";
import { useLocation } from "react-router-dom";
import { RoutePath } from "routes/route-paths";

import PropTypes from "prop-types";

import MenuEntry from "./MenuEntry";
import S from "./ProjectExplorer.styles";

import { HighlightElementContext } from "./HighlightElementContext";

const MenuItem = ({ id, path, onClick, icon, text, alt }) => {
  const highlightedElement = React.useContext(HighlightElementContext);
  const location = useLocation();

  const handleClick = () => {
    highlightedElement.setId(path);
    onClick();
  };

  React.useEffect(() => {
    if (path === location.pathname) {
      highlightedElement.setId(path);
    } else if (location.pathname === RoutePath.Projects) {
      highlightedElement.setId(null);
    }
  }, [highlightedElement, location.pathname, path]);

  return (
    <S.MenuItemContainer
      className={highlightedElement.id === path && "highlighted__element"}
      onClick={handleClick}
      id={id}
    >
      <MenuEntry icon={icon} text={text} alt={alt} />
    </S.MenuItemContainer>
  );
};

MenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.shape({
    height: PropTypes.string,
  }),
  text: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default MenuItem;
