import { useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { createColumnNameDetails } from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { ErrorBanner, LoadingSpinner } from "components/elements";
import { RoutePath } from "routes/route-paths";
import { useElementResources } from "./api";

interface IResourceProps {
  elementNameInUrl: string;
  selectedElement: {
    id: string;
  };
  tableTitle: string;
}

const ResourceTable = ({ elementNameInUrl, selectedElement, tableTitle }: IResourceProps) => {
  const columns = useMemo(() => [createColumnNameDetails(RoutePath.Resource.replace(":id", ""))], []);
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);

  const { data: resourcesData, isError: isResourcesDataError } = useElementResources({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    variantId,
    config: { enabled: !!selectedElement?.id && !!elementNameInUrl },
  });

  if (isResourcesDataError) {
    return <ErrorBanner msg="Error while loading resources" />;
  }

  if (resourcesData) {
    return (
      <S.DetailsContainer id="MemberTable_detailsPanel">
        <BrmDetailTable
          data={resourcesData}
          columns={columns}
          customProps={{ id: "MemberTable_detailsTable" }}
          showRowSelect={false}
          tableTitle={tableTitle}
        />
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

export default ResourceTable;
