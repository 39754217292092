import PropTypes from "prop-types";
import { useMemo } from "react";
// Components
import { BrmDetailTable, NO_VALUE } from "brm/tables/BrmTables";
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
// Styles

import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";
import { useVariant } from "features/brm";
import useGetElementDependentRisk from "./api/dependent-risk-table-queries";
// Styles

const DependentRiskTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const { variantId } = useVariant;

  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Risk.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("objective"),
      {
        Header: "Mitigated Score",
        accessor: "mitigatedScore.value",
        filterable: true,
        Cell: (cellProps) => {
          if (cellProps.cell.row.original.mitigatedScore.value) {
            return cellProps.cell.row.original.mitigatedScore.value.toFixed(1);
          }
          return NO_VALUE;
        },
      },
    ],
    []
  );

  const { data: getDepRiskData, isError: isGetDepRiskDataError } = useGetElementDependentRisk(
    selectedElement.id,
    elementNameInUrl,
    variantId
  );

  if (isGetDepRiskDataError) {
    return <ErrorBanner msg="Error  gettting dependent risks data" />;
  }

  if (getDepRiskData) {
    return (
      <S.DetailsContainer id="DependentRiskTable_detailsPanel">
        <S.DetailsTableContainer>
          <BrmDetailTable
            data={getDepRiskData}
            columns={columns}
            customProps={{ id: "DependentRiskTable_detailsTable" }}
            showRowSelect={false}
            tableTitle={tableTitle}
          />
        </S.DetailsTableContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

DependentRiskTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  tableTitle: PropTypes.string,
};

export default DependentRiskTable;
