import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

interface ISetControlTypeStrength {
  controlTypeId: string;
  strength: any;
}

/**
 * Sets strength value for control type
 *
 * @param {string} controlTypeId - uuid of the selected control type
 * @param {string} strength - user selected strength value for control type
 * @returns object of control type
 */
export const setControlTypeStrength = ({ controlTypeId, strength }: ISetControlTypeStrength) => {
  return Brm.controlTypeApi.setControlTypeStrength(controlTypeId, { body: strength });
};

interface IUseSetControlTypeStrength {
  options?: MutationOption<typeof setControlTypeStrength>;
}

/**
 * Custom hook to change strength of a existing control type
 *
 * @param {object} options - configuration of react-query
 * @returns react-query ReactQuery.useMutation
 */
export const useSetControlTypeStrength = ({ options }: IUseSetControlTypeStrength = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.controlTypeKeys.all);
    },
    ...options,
    mutationFn: setControlTypeStrength,
  });
};
