import * as React from "react";
import ReactModal from "react-modal";
import Cancel from "assets/icons/cancelModal.svg";
import { CreateProjectForm, ConfigureProjectForm } from "features/admin/components/forms/CreateAndConfigureProjectForm";
import { CancelContext } from "features/admin/stores/CancelContext/CancelContext";
import { useProject, useIsProjectWizardOpen } from "features/brm";
import * as S from "./CreateProjectModal.styles";

interface ICreateProjectModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export const CreateProjectModal = ({ isVisible, onClose }: ICreateProjectModalProps) => {
  const [isConfiguring, setIsConfiguring] = React.useState(false);
  const [isCancelRequested, setIsCancelRequested] = React.useState(false);
  const [, { setCurrentProject, getProjectAccessLevel }] = useProject();
  const { setIsProjectWizardOpen } = useIsProjectWizardOpen();

  const handleConfigClose = () => {
    setIsProjectWizardOpen(false);
    onClose();
  };

  const handleClose = () => {
    setIsConfiguring(false);
    onClose();
    setCurrentProject(null);
    setIsProjectWizardOpen(false);
  };

  const handleRequestClose = () => {
    if (isConfiguring) {
      setIsCancelRequested(true);
    } else {
      onClose();
      setCurrentProject(null);
    }
    setIsProjectWizardOpen(false);
  };

  const handleConfigure = () => {
    setIsConfiguring(true);
    setIsProjectWizardOpen(true);
  };

  const context = React.useMemo(() => {
    return { isCancelRequested, setIsCancelRequested };
  }, [isCancelRequested]);

  return (
    <CancelContext.Provider value={context}>
      <ReactModal
        isOpen={isVisible}
        onAfterClose={handleClose}
        ariaHideApp={false}
        onRequestClose={handleClose}
        style={S.rootModalStyle}
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
      >
        <S.Header>
          <S.ModalTitle>Create and Configure Project</S.ModalTitle>
          <S.HeaderButtonsDiv>
            <S.Close onClick={handleRequestClose}>
              <S.CloseIcon src={Cancel} />
            </S.Close>
          </S.HeaderButtonsDiv>
        </S.Header>
        <S.Body>
          {isConfiguring ? (
            <ConfigureProjectForm onClose={handleConfigClose} projectAccessLevel={getProjectAccessLevel} />
          ) : (
            <CreateProjectForm onClose={onClose} onConfigure={handleConfigure} />
          )}
        </S.Body>
      </ReactModal>
    </CancelContext.Provider>
  );
};
