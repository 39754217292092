import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./access-query-key-factory";

interface ISetEntryPointNameProps {
  entryPointId: string;
  name: string;
}

/**
 * Sets the Entry Point's Name
 *
 * @param {string} entryPointId - uuid of the entry point
 * @param {string} name - new name of the Name
 */
export const setEntryPointName = ({ entryPointId, name }: ISetEntryPointNameProps) => {
  return Brm.entryPointApi.setEntryPointName(entryPointId, { body: name });
};

interface IUseSetEntryPointName {
  projectId: string;
  options?: MutationOption<typeof setEntryPointName>;
}

/**
 * Custom hook to change the name of an existing Entry Point's Name
 *
 * @param {string} projectId - uuid of the project
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetEntryPointName = ({ projectId, options }: IUseSetEntryPointName) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.entryPointKeys.project(projectId));
    },
    ...options,
    mutationFn: setEntryPointName,
  });
};
