import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;

  p {
    margin-top: 20px;
    text-align: justify;
  }
`;
