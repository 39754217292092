import { selectedInstanceState } from "atoms/atoms-content";
import { contentKeyState } from "atoms";
import { useRecoilValue } from "recoil";

import { useRoles } from "features/brm/hooks/useRoles";

import { RoutePath } from "routes/route-paths";
import TitledPane from "components/elements/TitledPane";
import { MdOutlineHelp, MdOpenInNew } from "react-icons/md";
import { TbFiles } from "react-icons/tb";
import { BasicButton as Button } from "components/elements";
import { DemoVideos, HelpFaq, HelpVideos, HelpHomeText } from "features/help";

import * as S from "./HelpPane.styles";

export const HelpPane = () => {
  const selectedInstance = useRecoilValue(selectedInstanceState);
  const { userRole } = useRoles();
  const contentKey = useRecoilValue(contentKeyState);
  const isHome = contentKey === "home";

  const handleHelpButtonClick = () => {
    window.open(`${RoutePath.Help}?contentKey=${contentKey}&userRole=${userRole}`);
  };

  return (
    <TitledPane
      title="Help"
      iconComp={MdOutlineHelp}
      alt="Help Icon"
      size={22}
      width="calc(100% - 44px)"
      overflow="auto"
    >
      <S.HelpButton>
        <Button onClick={handleHelpButtonClick}>
          <S.Icon>
            <TbFiles />
            <> Browse complete help </>
            <MdOpenInNew />
          </S.Icon>
        </Button>
      </S.HelpButton>

      {isHome ? (
        <HelpHomeText />
      ) : (
        <>
          <DemoVideos />
          <HelpVideos type={selectedInstance?.type} />
          <HelpFaq />
        </>
      )}
    </TitledPane>
  );
};
