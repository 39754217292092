import RightFloatButton from "./CloseButton.styles";

interface ICloseButtonProps {
  onClick: () => void;
}

export const CloseButton = ({ onClick }: ICloseButtonProps) => (
  <RightFloatButton size="sm" onClick={onClick}>
    <span>&times;</span>
  </RightFloatButton>
);
