import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import NavListIconStyled from "components/elements/NavListIconStyled";
import FilterIcon from "assets/icons/filter.svg";
import systemModals from "components/modals/Modals";
import { ExportButton, ExportContext } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { useModal } from "hooks";

import { calibrationStyle } from "components/elements/ReactSelectCustomStyles";
import styled from "styled-components";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import BrmCsfReportTable from "brm/tables/BrmTables/BrmCSFReportTable";

// Styles
import * as DS from "brm/styles/details-table.styles";
import * as S from "./styles/frameworkTable.styles";
import { useGetCsfCategories, useGetCsfData, useGetCsfSubCategories } from "./csframework-table-queries";

const SHOW_ALL = "show all";
const SHOW_TARGETS = "show targets";
const SHOW_ZERO = "show zero";

const CSFrameworkTable = ({ selectedElement, tableTitle }) => {
  const allOption = { value: "All", label: "All" };
  const [modifiedData, setModifiedData] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFunction, setSelectedFunction] = useState(allOption);
  const [selectedCategory, setSelectedCategory] = useState(allOption);
  const [selectedSubCat, setSelectedSubCat] = useState(allOption);
  const [selectedChoice, setSelectedChoice] = useState(SHOW_ALL);
  const [modalData, setModalData] = useState([]);
  const [showACModal, setShowACModal] = useState(false);
  const { isVisible, toggle } = useModal();
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();

  const { data: csfData, isError: isCsfDataError } = useGetCsfData(selectedElement);
  const { data: getFuncCat } = useGetCsfCategories(selectedFunction.value, csfData);
  const { data: getSubCats } = useGetCsfSubCategories(selectedCategory.value, csfData);

  const functionsArray = [
    allOption,
    {
      value: "Identify (ID)",
      label: "Identify (ID)",
    },
    {
      value: "Protect (PR)",
      label: "Protect (PR)",
    },
    {
      value: "Detect (DE)",
      label: "Detect (DE)",
    },
    {
      value: "Respond (RS)",
      label: "Respond (RS)",
    },
    {
      value: "Recover (RC)",
      label: "Recover (RC)",
    },
  ];

  const columns = useMemo(() => {
    const getColouredValue = ({ row }) => {
      return (
        <span title={row.original.funcdescription}>
          <FunctionCell name={row.original.function}>{row.original.function}</FunctionCell>
        </span>
      );
    };

    const makeTargetsCell = ({ row }) => {
      if (row.original.target !== 0) {
        return (
          <Button
            type="button"
            onClick={() => {
              setModalData(row.original);
              setShowACModal(true);
            }}
          >
            <p>{row.original.target}</p>
          </Button>
        );
      }
      return 0;
    };

    const makeCategoryCell = ({ row }) => {
      return <span title={row.original.description}>{row.original.category}</span>;
    };

    const makeSubCatCell = ({ row }) => {
      return <span title={row.original.subdescription}>{row.original.subcategory}</span>;
    };

    return [
      {
        Header: "Function",
        accessor: "function",
        Cell: getColouredValue,
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: makeCategoryCell,
      },
      {
        Header: "Sub category",
        accessor: "subcategory",
        Cell: makeSubCatCell,
      },
      {
        Header: "Control type",
        accessor: "control",
      },
      {
        Header: "Target count",
        accessor: "target",
        Cell: makeTargetsCell,
      },
    ];
  }, []);

  const handleFuncChange = (selectedItems = []) => {
    const items = selectedItems;
    setSelectedCategory(allOption);
    setSelectedFunction(items);
  };
  const handleCatChange = (selectedItems = []) => {
    const items = selectedItems;
    setSelectedSubCat(allOption);
    setSelectedCategory(items);
  };
  const handleSubCatChange = (selectedItems = []) => {
    const items = selectedItems;
    setSelectedSubCat(items);
  };

  const DisplayAllocControls = ({ data }) => {
    if (data?.objects?.length > 0) {
      return (
        <div>
          <p>
            List of targets under control type <b>{data.control}</b> of sub category <b>{data.subcategory}</b>:
          </p>
          <br />
          <div style={{ maxHeight: "300px", overflow: "auto" }}>
            <ul style={{ paddingLeft: "40px" }}>
              {data.objects.map((e) => (
                <li key={e.id}>{e.anobject.name}</li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
    return "No controls";
  };

  DisplayAllocControls.propTypes = {
    data: PropTypes.any,
  };

  useEffect(() => {
    if (csfData && csfData.length > 0) {
      let array = csfData;
      if (selectedFunction?.value !== "All") {
        array = csfData.filter((e) => e.function === selectedFunction.value);
      }

      if (selectedCategory?.value !== "All") {
        array = csfData.filter((e) => e.category === selectedCategory.value);
      }

      if (selectedSubCat?.value !== "All") {
        array = csfData.filter((e) => e.subcategory === selectedSubCat.value);
      }

      let hideZeroItems = array;

      if (selectedChoice === SHOW_TARGETS) {
        hideZeroItems = array.filter((e) => e.target !== 0);
      }
      if (selectedChoice === SHOW_ZERO) {
        hideZeroItems = array.filter((e) => e.target === 0);
      }

      const functions = hideZeroItems.reduce((result, item) => {
        // First, take the name field as a new array result
        if (result.indexOf(item.function) < 0) {
          result.push(item.function);
        }
        return result;
      }, []);

      // get array of categories
      const categories = hideZeroItems.reduce((result, item) => {
        if (result.indexOf(item.category) < 0) {
          result.push(item.category);
        }
        return result;
      }, []);

      // get array of sub-categories
      const subCategories = hideZeroItems.reduce((result, item) => {
        if (result.indexOf(item.subcategory) < 0) {
          result.push(item.subcategory);
        }
        return result;
      }, []);

      // Add function cell span info
      const dataWithFuncSpanInfo = functions.reduce((result, func) => {
        const funcList = hideZeroItems.filter((item) => item.function === func);
        // eslint-disable-next-line no-param-reassign
        result = result.concat(
          funcList.map((item, index) => ({
            ...item,
            Rowspan: index === 0 ? funcList.length : 0, // add rowspan field to the first row of data
          }))
        );

        return result;
      }, []);

      const dataWithCatSpanInfo = categories.reduce((result, cat) => {
        const catList = dataWithFuncSpanInfo.filter((item) => item.category === cat);

        // eslint-disable-next-line no-param-reassign
        result = result.concat(
          catList.map((item, index) => ({
            ...item,
            catRowSpan: index === 0 ? catList.length : 0,
          }))
        );
        return result;
      }, []);

      const dataWithSubCatSpanInfo = subCategories.reduce((result, sub) => {
        const subCatList = dataWithCatSpanInfo.filter((item) => item.subcategory === sub);

        // eslint-disable-next-line no-param-reassign
        result = result.concat(
          subCatList.map((item, index) => ({
            ...item,
            subCatRowSpan: index === 0 ? subCatList.length : 0,
          }))
        );
        return result;
      }, []);

      setModifiedData(dataWithSubCatSpanInfo);
    }
  }, [csfData, selectedCategory.value, selectedChoice, selectedFunction.value, selectedSubCat.value]);

  if (isCsfDataError) {
    return <ErrorBanner msg="Error while loading CSF Data" />;
  }

  if (csfData) {
    return (
      <>
        {showACModal &&
          systemModals.customModal(
            "CSFrameworkTable_modal",
            showACModal,
            setShowACModal,
            <DisplayAllocControls data={modalData} />,
            "Targets"
          )}
        {/* 
        
        BRMCD-4583 : needs backend support 
        
        {modalIsOpen &&
          systemModals.addModal(
            "CSFrameworkTable_modal",
            modalIsOpen,
            setModalIsOpen,
            <AllocatedControlAddForm
              setModalIsOpen={setModalIsOpen}
              sctmName={sctmNameData?.result}
              frameworkData={modifiedData}
            />,
            "Allocated controls"
          )} */}

        <S.FilterSection>
          <S.ShowFilterButton onClick={() => setShowFilters(() => !showFilters)}>
            <NavListIconStyled src={FilterIcon} />
            Filters
          </S.ShowFilterButton>
          <S.HideLabel>
            <input
              type="radio"
              value={SHOW_ALL}
              checked={selectedChoice === SHOW_ALL}
              onChange={() => {
                setSelectedChoice(SHOW_ALL);
              }}
            />{" "}
            Show All
          </S.HideLabel>
          <S.HideLabel>
            <input
              type="radio"
              value={SHOW_TARGETS}
              checked={selectedChoice === SHOW_TARGETS}
              onChange={() => {
                setSelectedChoice(SHOW_TARGETS);
              }}
            />{" "}
            Show with Targets
          </S.HideLabel>

          <S.HideLabel>
            <input
              type="radio"
              value={SHOW_ZERO}
              checked={selectedChoice === SHOW_ZERO}
              onChange={() => {
                setSelectedChoice(SHOW_ZERO);
              }}
            />{" "}
            Show Gaps
          </S.HideLabel>

          <br />
          {showFilters ? (
            <>
              <S.SelectDiv>
                <S.SelectLabel>
                  Function
                  <Select
                    options={functionsArray}
                    defaultValue={selectedFunction}
                    onChange={handleFuncChange}
                    styles={calibrationStyle}
                  />
                </S.SelectLabel>
              </S.SelectDiv>

              {selectedFunction.value !== "All" && (
                <S.SelectDiv>
                  <S.SelectLabel>
                    Category
                    <Select
                      options={getFuncCat}
                      defaultValue={selectedCategory}
                      onChange={handleCatChange}
                      styles={calibrationStyle}
                      isDisabled={selectedFunction.value === "All"}
                    />
                  </S.SelectLabel>
                </S.SelectDiv>
              )}

              {selectedCategory.value !== "All" && (
                <S.SelectDiv>
                  <S.SelectLabel>
                    Sub category
                    <Select
                      options={getSubCats}
                      defaultValue={selectedSubCat}
                      onChange={handleSubCatChange}
                      styles={calibrationStyle}
                    />
                  </S.SelectLabel>
                </S.SelectDiv>
              )}
            </>
          ) : null}
        </S.FilterSection>
        <ExportTableModal
          onTableExport={handleTableExport}
          isVisible={isVisible}
          toggle={toggle}
          context={ExportContext.unfilteredTable}
        />
        <DS.DetailsContainer id="CSFrameworkTable_detailsPanel">
          <DS.ActionContainer>
            <DS.DetailsTableContainer>
              <BrmCsfReportTable
                columns={columns}
                data={modifiedData}
                elementName={selectedElement.name}
                tableTitle={tableTitle}
                setExportTableData={setExportTableData}
              />
            </DS.DetailsTableContainer>
            <DS.DetailsTableButtonsContainer>
              <ExportButton onClick={() => toggle()} disableExport={disableExport} />
            </DS.DetailsTableButtonsContainer>
          </DS.ActionContainer>
        </DS.DetailsContainer>
      </>
    );
  }

  return <Loading />;
};

const FunctionCell = styled.p`
  font-weight: 500;
  color: ${(props) => {
    if (props.name === "Identify (ID)") {
      return `blue`;
    }
    if (props.name === "Protect (PR)") {
      return `purple`;
    }
    if (props.name === "Detect (DE)") {
      return `orange`;
    }
    if (props.name === "Respond (RS)") {
      return `red`;
    }
    if (props.name === "Recover (RC)") {
      return `green`;
    }
    return `black`;
  }};
`;

const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  color: blue;
  width: 25px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: blue;
    text-decoration: underline;
  }
  .p {
    color: blue;
  }
`;

CSFrameworkTable.propTypes = {
  selectedElement: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default CSFrameworkTable;
