import { useState, useMemo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import * as ControlFamilyApi from "brm/global-control/api";
import Title from "components/elements/StyledTitle";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import { controlCatalogIdState } from "atoms/atoms-global-control";
import { useRecoilState } from "recoil";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import { createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading, ErrorBanner as ErrorDisplay } from "components/elements";
import { TYPE } from "constants/brm";
import { GlobalControlApi, useRoles } from "features/brm";
import { RoutePath } from "routes/route-paths";
import { ControlFamilyTab } from "../../components/tabs/ControlFamilyTab";

export function ControlFamily() {
  const { isControlsCoordinator } = useRoles();
  const [selectedElement, setSelectedElement] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [, setSelectedRowId] = useState("");
  const { id } = useParams();
  const [controlCatalogId] = useRecoilState(controlCatalogIdState);
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const [controlFamilyTypeId, setControlFamilyTypeId] = useState(id);
  const navigate = useNavigate();

  const { data: controlFamilyData } = GlobalControlApi.useControlFamilies({
    controlCatalogId,
    options: { enabled: !!controlCatalogId },
  });

  const { data, isError, error } = GlobalControlApi.useControlFamily({
    controlFamilyId: controlFamilyTypeId,
    controlCatalogId,
    options: { enabled: !!controlFamilyTypeId && isControlsCoordinator },
  });

  const columns = useMemo(() => [createColumnMappedNoEdit("catalog")], []);

  useEffect(() => {
    if (controlFamilyData && controlFamilyData.length > 0) {
      let controlFamilyTypeFound = false;
      for (let i = 0; i < controlFamilyData.length; i += 1) {
        const selectedElementName = selectedElement?.name || "";
        if (controlFamilyData[i].name === selectedElementName) {
          setControlFamilyTypeId(controlFamilyData[i].id);
          controlFamilyTypeFound = true;
        }
      }
      if (!controlFamilyTypeFound && selectedElement) {
        navigate(RoutePath.ControlFamilies);
      }
    }
  }, [controlFamilyData, navigate, selectedElement]);

  useEffect(() => {
    if (data && data.length) {
      const selectedControlType = data[0];
      setSelectedElement(selectedControlType);
    }
  }, [data]);

  if (!controlCatalogId) {
    return <div> Select a control catalog to continue</div>;
  }

  if (isError) {
    return <ErrorDisplay msg={error?.statusText} />;
  }

  if (data) {
    return (
      <>
        <Title>
          {selectedElement?.name || ""}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "ControlFamilyFullDetails_table" }}
              elementName={TYPE.controlFamily}
            />
          </DetailsContainerSingleTable>
        ) : null}

        {selectedElement?.id && (
          <DetailsContainerParent showSummaryTable={showSummaryTable}>
            <DetailsContainer>
              <ControlFamilyTab selectedElement={selectedElement} setRefresh={setRefresh} refresh={refresh} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
  }

  return <Loading />;
}
