import { atom } from "recoil";
import { CONTEXT_SIDEBAR_CONTENT } from "../constants";

export type DrawerContentType = typeof CONTEXT_SIDEBAR_CONTENT[keyof typeof CONTEXT_SIDEBAR_CONTENT];

/**
 * Logged in user id
 */
export const contextSidebarContentState = atom<DrawerContentType>({
  key: "rightSidebarContentState",
  default: CONTEXT_SIDEBAR_CONTENT.notes,
});
