// React Query
import { useQuery } from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";
import { accessPointApi, entryPointApi, attackVectorApi } from "services/brm";

/**
 *  Returns Access Point by ID with additional details added
 *
 * @param {string} id the UUID of an access point
 * @returns {AccessPointById} Access Point
 */
const getAccessPointById = async (id) => {
  return accessPointApi.getAccessPointByID(id);
};

export const useAccessPointById = (id) => {
  return useQuery(["accessPointById", id], () => getAccessPointById(id));
};

/**
 *  Returns Entry Point by ID with additional details added
 *
 * @param {string} id the UUID of an entry point
 * @returns {getEntryPointById} Entry Point
 */
const getEntryPointById = (id) => {
  return entryPointApi.getEntryPointByID(id);
};

export const useEntryPointById = (id) => {
  return useQuery(["entryPointById", id], () => getEntryPointById(id));
};

/**
 *  Returns Attack Vector by ID with additional details added
 *
 * @param {string} id the UUID of an attack vector
 * @returns {getEntryPointById} Attack Vector
 */
const getAttackVectorById = async (id) => {
  const attackVector = await attackVectorApi.getAttackVectorByID(id);

  return attackVector;
};

export const useAttackVectorById = (id) => {
  return useQuery(["attackVectorById", id], () => getAttackVectorById(id));
};

export const useAttackSurfaceById = (id) => {
  return useQuery(["attackSurfaceById", id], () => Brm.attackSurfaceApi.getAttackSurfaceByID(id));
};

export const useAccessPointCategories = (projectId) => {
  return useQuery(["accessPointCategories", projectId], () => Brm.accessPointCatApi.findAccessPointCategory(projectId));
};

export const useEntryPointCategories = (projectId) => {
  return useQuery(["entryPointCategories", projectId], () => Brm.entryPointCatApi.findEntryPointCategory(projectId));
};

export const useAttackVectorCategories = (projectId) => {
  return useQuery(["attackVectorCategories", projectId], () =>
    Brm.attackVectorCatApi.findAttackVectorCategory(projectId)
  );
};

export const useAttackSurfaceCategories = (projectId) => {
  return useQuery(["attackSurfaceCategories", projectId], () =>
    Brm.attackSurfaceCatApi.findAttackSurfaceCategory(projectId)
  );
};

export const useAttackSurfaces = (projectId) => {
  return useQuery(["attackSurfaces", projectId], () => Brm.attackSurfaceApi.findAttackSurface(projectId));
};

export const useAccessPoints = (projectId) => {
  return useQuery(["accessPoints", projectId], () => Brm.accessPointApi.findAccessPoint(projectId));
};

export const useAttackVectors = (projectId) => {
  return useQuery(["attackVectors", projectId], () => Brm.attackVectorApi.findAttackVector(projectId));
};

export const useEntryPoints = (projectId) => {
  return useQuery(["entryPoints", projectId], () => Brm.entryPointApi.findEntryPoint(projectId));
};
