import ReactSelect from "react-select";

interface ISelectColumnFilter {
  filterValue: any;
  allValues: any[];
  onFilterChange: (value: any) => void;
  size?: string;
}

export const SelectBooleanColumnFilter = ({ filterValue, allValues, onFilterChange, size }: ISelectColumnFilter) => {
  // console.log("allValues", allValues);

  const options = allValues.map((v) => ({ label: v ? "Yes" : "No", value: v }));
  const currentOption = options.filter((o) => o.value === filterValue);

  const handleOnChange = (value: any) => {
    onFilterChange(value);
  };

  // console.log("currentOption", currentOption);

  const selectHeight = "20px";
  const indicatorHeight = "16px";

  return (
    <ReactSelect
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (base) => ({
          ...base,
          minWidth: size && size === "small" ? "40px" : "75px",
          minHeight: selectHeight,
          height: selectHeight,
          maxHeight: selectHeight,
        }),
        placeholder: (base) => ({
          ...base,
          fontSize: "11px",
          minHeight: selectHeight,
          height: selectHeight,
          maxHeight: selectHeight,
        }),
        input: (base) => ({
          ...base,
          margin: "0px",
          minHeight: selectHeight,
          height: selectHeight,
          maxHeight: selectHeight,
        }),
        valueContainer: (base) => ({ ...base, padding: "1px 2px", maxHeight: selectHeight }),
        singleValue: (base) => ({ ...base, fontSize: "11px", maxHeight: selectHeight }),
        indicatorsContainer: (base) => ({
          ...base,
          minHeight: indicatorHeight,
          height: indicatorHeight,
          maxHeight: indicatorHeight,
          padding: "1px",
        }),
        dropdownIndicator: (base) => ({ ...base, padding: "0px" }),
        clearIndicator: (base) => ({
          ...base,
          minHeight: indicatorHeight,
          height: indicatorHeight,
          maxHeight: indicatorHeight,
          padding: "0px",
        }),
      }}
      value={currentOption}
      options={options}
      isClearable
      onChange={handleOnChange}
    />
  );

  // const [visibleFilterValue, setVisibleFilterValue] = React.useState("");
  // const variantId = Recoil.useRecoilValue(variantIdState);
  // const filterRef = React.useRef({ id, filterValue });
  // const options = React.useMemo(() => {
  //   const opt = new Set();
  //   preFilteredRows.forEach((row) => {
  //     opt.add(row.values[id] === "" ? COMMON.hyphen : row.values[id]);
  //   });
  //   return [...opt.values()].sort((a, b) => {
  //     if (a < b) return -1;
  //     if (a > b) return 1;
  //     return 0;
  //   });
  // }, [id, preFilteredRows]);
  // React.useEffect(() => {
  //   if (variantId) {
  //     setVisibleFilterValue("all");
  //   }
  // }, [variantId]);
  // React.useEffect(() => {
  //   if (filterRef.current.id === id && filterRef.current.filterValue !== filterValue) {
  //     setFilter(filterRef.current.filterValue);
  //   }
  // }, [filterValue, setFilter, id]);
  // return (
  //   <Select
  //     id="custom-select"
  //     value={visibleFilterValue}
  //     onBlur={() => {}} // error without onBlur but using onBlur vs onChange causes issues in component functionality
  //     onChange={(e) => {
  //       setVisibleFilterValue(e.target.value);
  //       if (e.target.value === "all") e.target.value = "";
  //       const value = e.target.value === COMMON.hyphen ? "" : e.target.value || undefined;
  //       filterRef.current = {
  //         id,
  //         filterValue: value,
  //       };
  //       setFilter(value);
  //     }}
  //   >
  //     <option value="all">All</option>
  //     {options.map((option) => {
  //       if (option !== null && option !== undefined) {
  //         return (
  //           <option key={option} value={option}>
  //             {option.charAt(0).toUpperCase() + option.slice(1)}
  //           </option>
  //         );
  //       }
  //       return null;
  //     })}
  //   </Select>
  // );
};
