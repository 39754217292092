export const risksKeys = {
  all: ["risk"] as const,
  element: (
    elementId: string | null,
    elementName: string | null,
    riskData: any | null,
    projectId: string | null,
    variantId: string | null
  ) => [...risksKeys.all, "elementRisks", elementId, elementName, riskData, projectId, variantId],
};
