import styled from "styled-components";
import styledMap from "styled-map";
import StyledButton from "components/elements/StyledButton";

const PrimaryStyledButton = styled(StyledButton)`
  height: ${({ theme }) => theme.sizes.defaultButton};
  width: ${({ theme }) => theme.sizes.defaultButton};
  border: none;
  color: white;
  text-align: center;
  /* border-radius: 5px; */
  background-color: ${({ theme }) => theme.colors.primaryButton};
  font-size: ${styledMap`
        lg: 1.3rem;
        md: 1rem;
        sm: 0.7rem;
        default: 1rem;
    `};
  &:hover {
    cursor: pointer;
    background-color: #0065ff;
  }
  &:disabled {
    background-color: #cccccc;
  }
  margin: 0 3px;
`;

export default PrimaryStyledButton;
