import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./user-query-key-factory";

interface IUserEmailProps {
  userId: string | null;
  newEmail: string;
}

/**
 * Set the user email
 *
 * @param param0 upload file data
 * @returns
 */
export const setUserEmail = ({ userId, newEmail }: IUserEmailProps) => {
  return userId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.userApi.setUserEmail(userId, { body: newEmail });
};

interface ISetNameProps {
  options?: MutationOption<typeof setUserEmail>;
}

/**
 * mutate the user email.
 *
 * @returns react-query Mutation.
 */
export const useSetUserEmail = ({ options }: ISetNameProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.userKeys.user(variables.userId));
      queryClient.invalidateQueries(KeyFactory.userKeys.all);
    },
    ...options,
    mutationFn: setUserEmail,
  });
};
