import { useParams, useNavigate } from "react-router-dom";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import Title from "components/elements/StyledTitle";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { TYPE } from "constants/brm";
import RiskDetails from "../details/RiskDetails";
import { useRiskFullDetails } from "./useRiskFullDetails";

export const RiskFullDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    columns,
    riskData,
    isRiskError,
    showSummaryTable,
    setShowSummaryTable,
    selectedElement,
    setSelectedElement,
    setSelectedRowId,
  } = useRiskFullDetails({
    riskId: id || "",
    navigate,
  });

  let content = null;

  if (isRiskError) {
    return <ErrorBanner msg="An error occurred while retrieving Risk data." />;
  }

  if (riskData) {
    content = (
      <>
        <Title id="RiskFullDetails_title">
          {selectedElement?.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={riskData}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "RiskFullDetails_table" }}
              elementName={TYPE.risk}
            />
          </DetailsContainerSingleTable>
        ) : null}
      </>
    );
  } else {
    content = <Loading />;
  }

  return (
    <>
      {content}

      {selectedElement?.id && (
        <DetailsContainerParent>
          <DetailsContainer id="Risk_detailsPanel">
            <RiskDetails selectedElement={selectedElement} />
          </DetailsContainer>
        </DetailsContainerParent>
      )}
    </>
  );
};
