import styled from "styled-components/macro";

export const rootModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    borderRadius: "8px",
    background: "#f7f7f7",
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
  overlay: {
    zIndex: 1000,
    backdropFilter: "contrast(90%)",
  },
};

export const HeaderButtonsDiv = styled.div`
  padding-right: 10px;
`;

export const Header = styled.div`
  display: flex;
  background: #ccc;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
`;

export const Body = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.formbackground};
`;

export const ModalTitle = styled.h4`
  color: ${({ theme }) => theme.colors.title};
  padding: 8px;
`;

export const CloseIcon = styled.img`
  height: 10px;
`;

export const Close = styled.div`
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  margin: 0 5px;
  background-color: none;
  border-color: none;
  color: black;
  border: none;
`;
