import { RarManualMitigateForm } from "brm/reports/rar/RarManualMitigateForm";
import ReactModal from "react-modal";
import Cancel from "assets/icons/cancelModal.svg";
import * as S from "./ManualMitigationModal.styles";
import { IVulnerabilityData } from "../../RarManualMitigateForm/types";

interface IManualMitigationModalProps {
  isVisible: boolean;
  onClosing: () => void;
  vulnerabilities: IVulnerabilityData[];
  importSctm: ({ id, body }: { id: string; body: string }) => void;
}

export const ManualMitigationModal = ({
  isVisible,
  onClosing,
  vulnerabilities,
  importSctm,
}: IManualMitigationModalProps) => {
  const handleClose = () => {
    onClosing();
  };

  return (
    <ReactModal
      isOpen={isVisible}
      onAfterClose={handleClose}
      ariaHideApp={false}
      onRequestClose={handleClose}
      style={S.rootModalStyle}
    >
      <S.Header>
        <S.ModalTitle>User-Guided Vulnerability Mitigation</S.ModalTitle>
        <S.HeaderButtonsDiv>
          <S.Close onClick={handleClose}>
            <S.CloseIcon src={Cancel} />
          </S.Close>
        </S.HeaderButtonsDiv>
      </S.Header>
      <S.Body>
        <RarManualMitigateForm onClose={handleClose} rarVulnerabilities={vulnerabilities} importSctm={importSctm} />
      </S.Body>
    </ReactModal>
  );
};
