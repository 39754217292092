import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { MdInfoOutline } from "react-icons/md";
import { RowSelectionState } from "@tanstack/react-table";
import * as S from "./VulnerabilitySelectionStep.styles";

import { IManualMitigationState } from "../types";
import { VulnerabilitySelectionTable } from "../VulnerabilitySelectionTable";

interface IVulnerabilitiesSelectionStep {
  wizardState: IManualMitigationState;
  updateWizardState: (newData: Partial<IManualMitigationState>) => void;
}

export const VulnerabilitySelectionStep = ({
  wizardState: { rarVulnerabilities, selectedVulnerabilities },
  updateWizardState,
}: IVulnerabilitiesSelectionStep) => {
  const handleRowSelect = (selectedRows: RowSelectionState) => {
    updateWizardState({ selectedVulnerabilities: selectedRows });
  };

  const popover = (
    <S.ShadowPopover id="popover-basic">
      <Popover.Header as="h3">Mitigation Hints</Popover.Header>
      <Popover.Body>
        When performing a user guided mitigation select vulnerabilities in only one of the following three ways:
        <S.OrderedList>
          <S.ListItem>one vulnerability to one target</S.ListItem>
          <S.ListItem>a set of the same vulnerability to multiple targets</S.ListItem>
          <S.ListItem>multiples vulnerabilities to the same target</S.ListItem>
        </S.OrderedList>
        <S.Title>Rank</S.Title>
        <S.List>
          <S.ListItem>
            The Global rank of the Vulnerability Conditions within this Variant of the Project, based on importance to
            the Risk score for the Contributors affected by this Risk.
          </S.ListItem>
        </S.List>
        <S.Title>Vulnerability Category</S.Title>
        <S.List>
          <S.ListItem>
            A Vulnerability Category is a classification associated with a Vulnerability Condition that more precisely
            indicates the affect of the vulnerability. The category name can be captured as an abbreviation. For
            example, “iaaa” is “Inadequate audit and accountability”.
          </S.ListItem>
        </S.List>
        <S.Title>Vulnerability Condition</S.Title>
        <S.List>
          <S.ListItem>
            A Vulnerability Condition allows exploiting this system in unauthorised and possibly even malicious ways.
          </S.ListItem>
        </S.List>
        <S.Title>Vulnerability Condition Level</S.Title>
        <S.List>
          <S.ListItem>
            The Vulnerability Condition Level is the impact range (very low, low, moderate, high, very high) of a
            Vulnerability Condition.
          </S.ListItem>
        </S.List>
      </Popover.Body>
    </S.ShadowPopover>
  );

  return (
    <S.Container>
      {rarVulnerabilities.length > 0 ? (
        <>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "5px" }}>
            <S.VulnerabilityListLabel>Select Vulnerabilities:</S.VulnerabilityListLabel>
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
              <Button title="User Guided Mitigation Hints" size="sm" variant="link">
                <MdInfoOutline size="24" />
              </Button>
            </OverlayTrigger>
          </div>
          <S.VulnerabilityListContainer>
            <VulnerabilitySelectionTable
              vulnerabilities={rarVulnerabilities}
              selectedVulnerabilities={selectedVulnerabilities}
              onRowSelect={handleRowSelect}
            />
          </S.VulnerabilityListContainer>
        </>
      ) : (
        <div>All vulnerabilities mitigated</div>
      )}
    </S.Container>
  );
};
