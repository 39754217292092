import { useState } from "react";
import modals from "components/modals/Modals";
import HtmlHelpViewer from "components/elements/HtmlHelpViewer";
import { useVideoContent } from "features/help/hooks/useVideoContent";
import { Separator } from "components/elements/NavigationToolbar";
import * as S from "./HelpVideos.styles";
import { HelpVideosList } from "./HelpVideosList";

export const HelpVideos = () => {
  const contentData = useVideoContent();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [path, setPath] = useState("");
  const [title, setTitle] = useState("");
  const handleBtnOnClick = (openModal: boolean, filePath: string, name: string) => {
    setIsModalOpen(true);
    setPath(filePath);
    setTitle(name);
  };
  if (contentData && contentData.length) {
    return (
      <>
        <S.Separator>
          <Separator />
        </S.Separator>
        <S.Wrapper>
          <b>Videos</b>
          <p style={{ marginTop: "20px" }}>
            A list of &quot;How to&quot; <b>videos</b> related to this page. Click on a link to view the video.
          </p>
          <HelpVideosList handleBtnOnClick={handleBtnOnClick} contentsData={contentData} className="contentList" />
          {isModalOpen &&
            modals.customModal(
              "HelpButton_page",
              isModalOpen,
              setIsModalOpen,
              <HtmlHelpViewer fileUrl={path} />,
              title
            )}
        </S.Wrapper>
      </>
    );
  }

  return null;
};
