import * as YFiles from "yfiles";
import * as React from "react";
import { useConstructor } from "hooks/useConstructor";

export const useGraphComponentListeners = (
  graphComp: YFiles.GraphComponent,
  onNodeAdded: (value: any) => void,
  onNodesRemoved: (value: any) => void
) => {
  const [nodeDnd, setNodeDnd] = React.useState(() => false);
  const hasNodeSelectionRef = React.useRef(false);
  const [hasNodeSelection, setHasNodeSelection] = React.useState(() => false);

  const handleUserDeleteStart = (
    sender: YFiles.GraphEditorInputMode,
    args: YFiles.SelectionEventArgs<YFiles.IModelItem>
  ) => {
    // we only care about nodes
    const nodes = args.selection
      .toList()
      .filter((s: YFiles.IModelItem) => YFiles.INode.isInstance(s))
      .map((n: YFiles.IModelItem) => n.tag);
    onNodesRemoved(nodes);
  };

  /**
   * Handle changes in selection in the graph component.
   */
  const handleNodeSelectionChanged = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sender: YFiles.ISelectionModel<YFiles.INode>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    evt: YFiles.ItemSelectionChangedEventArgs<YFiles.INode>
  ) => {
    const { selectedNodes } = graphComp.selection;
    hasNodeSelectionRef.current = selectedNodes.size > 0;
    setHasNodeSelection(hasNodeSelectionRef.current);
  };

  useConstructor(() => {
    const inputMode = graphComp.inputMode as YFiles.GraphEditorInputMode;
    const { nodeDropInputMode } = inputMode;

    // don't create a node on drag and drop
    nodeDropInputMode.itemCreator = (context, graph, info) => {
      // Set node drag and drop state true to trigger filtering
      setNodeDnd(true);
      onNodeAdded(info.tag);
      return null;
    };

    inputMode.addDeletingSelectionListener(handleUserDeleteStart);
    graphComp.selection.selectedNodes.addItemSelectionChangedListener(handleNodeSelectionChanged);
  });

  return [nodeDnd, setNodeDnd, hasNodeSelection] as const;
};
