import { useState, createContext } from "react";
import PropTypes from "prop-types";

export const HighlightElementContext = createContext();

// Wrapper function for handling the state to highlight an element in the Explorer panel
// and pushing out the HighlightElementContext to all of the component children passed in
export function HighlightElementProvider({ children }) {
  const [highlightedElementId, setHighlightedElementId] = useState(null);

  return (
    <HighlightElementContext.Provider value={{ id: highlightedElementId, setId: setHighlightedElementId }}>
      {children}
    </HighlightElementContext.Provider>
  );
}
HighlightElementProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
