import styled from "styled-components/macro";

const CustomTableDivStyled = styled.div`
  /* width: 100%;
  height: 100%; */
  flex: 1;
  display: flex;
`;

export default CustomTableDivStyled;
