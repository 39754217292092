import styled from "styled-components";

export const TextInput = styled.input`
  width: 100%;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

export const TextArea = styled.textarea`
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 166px;
`;

export const InputLabel = styled.label`
  margin: 5px 8px;
  display: inline-block;
`;

export const CheckBox = styled.input`
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px;
  padding-top: 2px;
  padding-left: 5px;
`;

export const RoleName = styled.label`
  padding-left: 10px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  padding: 1em;
  justify-content: center;

  .row {
    display: flex;
    justify-content: center;
  }

  .column {
    display: flex;
    flex-direction: column;
    padding: 1em;
    justify-content: flex-start;
    width: 45%;
  }

  .column.space-between {
    justify-content: space-between;
  }

  .input_group {
    display: flex;
    padding: 1em;
    justify-content: flex-end;
  }

  .assigned_roles > label,
  .roles > label {
    display: block;
  }

  .passwordPolicyWrapper {
    margin: 0 0 10px 20px;
    max-width: 315px;
  }
`;

export const ButtonsArea = styled.div`
  text-align: center;
`;

export const AddUserPanel = styled.div`
  height: 100%;
`;
