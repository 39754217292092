import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
// Utils
import errorUtils from "utils/error-utils";
import { createColumnNameDetails } from "brm/tables/services/column/columnFactory";
// Services
import { attackSurfaceApi } from "services/brm/access-service";
// Styles
import * as S from "brm/styles/details-table.styles";
import { BrmError, DetailTableType } from "features/brm";
import { RoutePath } from "routes/route-paths";

const AccessableNodesTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const columns = useMemo(() => [createColumnNameDetails(RoutePath.Node.replace(":id", ""))], []);

  const [accessableNodesData, setAccessableNodesData] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    function getElementRequest() {
      switch (elementNameInUrl) {
        case DetailTableType.ATTACK_SURFACES.key: {
          return attackSurfaceApi.getAttackSurfaceAllAccessableNodeWithHttpInfo(selectedElement.id);
        }
        default: {
          break;
        }
      }
      throw new BrmError(`Unsupported element type: ${elementNameInUrl}`);
    }
    async function getData() {
      try {
        const res = await getElementRequest();
        if (res?.data) {
          if (isSubscribed) {
            errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
            setAccessableNodesData(res.data);
          }
        }
      } catch (err) {
        console.error("Error while loading accessable nodes ", err);
      }
    }
    getData();

    // cancel subscription to un-mounted component - clean up
    return () => {
      isSubscribed = false;
    };
  }, [elementNameInUrl, selectedElement.id]);

  return (
    <S.DetailsContainer id="AccessableNodesTable_detailsPanel">
      <BrmDetailTable
        data={accessableNodesData}
        columns={columns}
        customProps={{ id: "AccessableNodesTable_detailsTable" }}
        showRowSelect={false}
        tableTitle={tableTitle}
      />
    </S.DetailsContainer>
  );
};

AccessableNodesTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  tableTitle: PropTypes.string,
};

export default AccessableNodesTable;
