const withCondition = (conditionFunction, Component) => {
  return function WithCondition(props) {
    if (conditionFunction(props)) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component {...props} />;
    }
    return null;
  };
};

export default withCondition;
