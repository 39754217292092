import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./organization-query-key-factory";

type OrganizationProperty = "ownedKb" | "defaultKb" | "defaultControlCatalog" | "projectSettings";

/**
 * Gets the organization's projects
 */
export const getOrganizationProperty = ({
  organizationId,
  property,
}: {
  organizationId: string | null;
  property: OrganizationProperty;
}) => {
  if (!organizationId) {
    return Promise.reject(new Error(`Invalid organizationId: ${organizationId}`));
  }
  switch (property) {
    case "ownedKb":
      return Brm.organizationApi.getOrganizationOwnedKb(organizationId);
    case "defaultKb":
      return Brm.organizationApi.getOrganizationDefaultKb(organizationId);
    case "defaultControlCatalog":
      return Brm.organizationApi.getOrganizationDefaultCatalog(organizationId);
    case "projectSettings":
      return Brm.projectSettingApi.findProjectSetting({ organization: organizationId });
    default:
      return Promise.reject(new Error("Unsupported property"));
  }
};

type FetcherFunction = typeof getOrganizationProperty;
interface IOrganizationsProps {
  organizationId: string | null;
  property: OrganizationProperty;
  options?: QueryOption<FetcherFunction>;
}

/**
 * Custom hook for getting organizations projects
 *
 * @param {object} options - react-query options
 */
export const useGetOrganizationProperty = ({ organizationId, property, options = {} }: IOrganizationsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.organizationKeys.property(organizationId, property),
    queryFn: () => getOrganizationProperty({ organizationId, property }),
  });
};
