import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./sap-program-query-key-factory";

/**
 * Gets the programs by id
 */
export const getSapProgram = ({ programId }: { programId: string }) => {
  return Brm.sapProgramApi.getSapProgramByID(programId);
};

type FunctionType = typeof getSapProgram;
interface IProgramsProps {
  programId: string;
  options?: QueryOption<FunctionType>;
}

/**
 * Custom hook for getting all sap Programs
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useGetSapProgram = ({ programId, options = {} }: IProgramsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.sapProgramKeys.program(programId),
    queryFn: () => getSapProgram({ programId }),
  });
};
