import { useQuery } from "@tanstack/react-query";
// Services
import { nodeApi, busApi, linkApi, exchangeApi } from "services/brm/system-service";
import { BrmError, DetailTableType } from "features/brm";

async function getPivotPath(elementId, elementName) {
  switch (elementName) {
    case DetailTableType.BUSES.key: {
      return busApi.getBusAllPivotPath(elementId);
    }
    case DetailTableType.LINKS.key: {
      return linkApi.getLinkAllPivotPath(elementId);
    }
    case DetailTableType.NODES.key: {
      return nodeApi.getNodeAllPivotPath(elementId);
    }
    case DetailTableType.EXCHANGES.key: {
      return exchangeApi.getExchangeAllPivotPath(elementId);
    }
    default: {
      break;
    }
  }
  throw new BrmError(`Unsupported element type: ${elementName}`);
}

const useGetElementPivotPath = (elementId, elementName) => {
  return useQuery(["getElementPivotPath", elementId, elementName], () => getPivotPath(elementId, elementName), {
    enabled: !!elementId,
    cacheTime: 0,
  });
};

export default useGetElementPivotPath;
