// React Query
import * as ReactQuery from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./threat-catalog-query-key-factory";

interface IImportThreatCatalog {
  threatCatalogId: string;
  fileContents: any;
}

const importThreatCatalog = ({ threatCatalogId, fileContents }: IImportThreatCatalog) => {
  return Brm.threatCatalogApi.importThreatCatalogWithHttpInfo(threatCatalogId, { body: fileContents });
};

interface IUseImportThreatCatalog {
  threatCatalogId: string;
  fileContents: any;
  options?: QueryOption<typeof importThreatCatalog>;
}

/**
 * Custom-hook to import into threat catalog.
 *
 * @param {object} threatCatalogId - the selected threat catalog instance
 * @param {string} fileContents - the contents of the uploaded file
 * @param {boolean} enabled - enable query
 * @returns react-query for import threat catalog
 */
export const useImportThreatCatalog = ({ threatCatalogId, fileContents, options }: IUseImportThreatCatalog) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatCatalogKeys.import(threatCatalogId, fileContents),
    queryFn: () => importThreatCatalog({ threatCatalogId, fileContents }),
  });
};
