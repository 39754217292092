import PropTypes from "prop-types";
import { useState } from "react";
import * as Recoil from "recoil";
import toast from "react-hot-toast";
import { queryClient } from "libs/react-query";
import * as BrmGql from "generated/graphql";

// Atoms
import { projectIdState } from "atoms/atoms-admin";

// Queries
import { MitigationApi, useVariant } from "features/brm";

// Components
import Select from "react-select";
import systemModals from "components/modals/Modals";

// Helpers
import getReactSelectArray from "utils/react-select-array";

// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { setCalibrationStyle } from "components/elements/ReactSelectCustomStyles";
import { strengthLevels } from "../../../constants";
import S from "../styles/calibration.styles";

// Utils

export const SetCalibratedStrengthModal = ({ modalIsOpen, setModalIsOpen, selectedRows }) => {
  const [selectedCalibStrengthLevel, setSelectedCalibStrengthLeve] = useState("");
  const projectId = Recoil.useRecoilValue(projectIdState);
  const { variantId } = useVariant();

  const { mutate: setAllAllocatedControlsCalibratedStrength } =
    BrmGql.useSetAllAllocatedControlsCalibratedStrengthMutation({
      onSettled: () => {
        queryClient.invalidateQueries(MitigationApi.allocatedControlsGqlKey.all);
      },
      onError: () => {
        toast.error("Error ocurred upon setting calibrated strength", {
          duration: 4000,
        });
        setModalIsOpen(false);
      },
    });

  function setLevelClick() {
    const ids = selectedRows.map((row) => row.id);
    setAllAllocatedControlsCalibratedStrength(
      {
        ids,
        calibratedStrength: selectedCalibStrengthLevel.value,
        project: projectId,
        variant: variantId,
      },
      {
        onSuccess: () => {
          toast.success("Calibrated Strength set successfully", { duration: 3000 });
          setModalIsOpen(false);
        },
        enabled: !!projectId && !!ids,
      }
    );
  }

  const handleCancel = () => {
    setModalIsOpen(false);
  };

  const body = (
    <>
      <S.BodyDiv>
        <p>Select the desired calibrated Strength level:</p>
        <Select
          isMulti={false}
          options={getReactSelectArray(strengthLevels)}
          defaultValue={selectedCalibStrengthLevel}
          onChange={(selectedItem) => setSelectedCalibStrengthLeve(selectedItem)}
          styles={setCalibrationStyle}
          classNamePrefix="impactLevelDropdown"
          id="SetImpactLevelModal_impactLevelDropdown"
        />
      </S.BodyDiv>
      <DialogButtonDivStyled>
        <DialogButtonStyled id="SetImpactLevelModal_cancelButton" onClick={handleCancel}>
          Cancel
        </DialogButtonStyled>
        <DialogButtonStyled
          id="SetImpactLevelModal_confirmButton"
          onClick={() => setLevelClick()}
          disabled={selectedCalibStrengthLevel === ""}
        >
          Confirm
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </>
  );

  return (
    <div id="SetCalibratedStrengthModal_modal">
      {systemModals.customModal(
        "SetCalibratedStrengthModal_modal",
        modalIsOpen,
        setModalIsOpen,
        body,
        "Set Calibrated Strength Level"
      )}
    </div>
  );
};

SetCalibratedStrengthModal.propTypes = {
  modalIsOpen: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
  setModalIsOpen: PropTypes.func,
};
