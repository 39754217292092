import PropTypes from "prop-types";
import { useState, useMemo } from "react";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
import { AddButton, DeleteButton } from "features/brm";
import systemModals from "components/modals/Modals";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
// Utils
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";

// Local
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";
import ControlTypeAddForm from "./forms/ControlTypeAddForm";
import { useDeleteElementControlTypes, useGetElementControlTypes } from "./api/control-type-tables-queries";
// Styles

const ControlTypeTable = ({ elementNameInUrl, selectedElement, showAdd, showDelete = false, tableTitle }) => {
  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.ControlType.replace(":id", "")),
      createColumnMappedNoEdit("vulnerabilitycategory"),
      createColumnMappedNoEdit("family"),
      createColumnMappedNoEdit("strength"),
      createColumnMappedNoEdit("catalog"),
    ],
    []
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [, setSelectedControlType] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { data: controlTypes, isError: isControlTypesError } = useGetElementControlTypes(
    selectedElement?.id,
    elementNameInUrl
  );

  const { mutate: deleteCT } = useDeleteElementControlTypes(selectedElement?.id, elementNameInUrl);

  const addControlType = () => {
    setModalIsOpen(true);
  };

  const handleDelete = async () => {
    await Promise.all(selectedRows.map((each) => deleteCT({ controlId: each.id })));
  };

  if (isControlTypesError) {
    return <ErrorBanner msg="Error while loading control types" />;
  }

  if (controlTypes) {
    return (
      <S.DetailsContainer id="ControlTypeTable_detailsPanel">
        {/* modal for adding instance */}
        {modalIsOpen &&
          systemModals.addModal(
            "ControlTypeTable_page",
            modalIsOpen,
            setModalIsOpen,
            <ControlTypeAddForm
              setModalIsOpen={setModalIsOpen}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
            />,
            "Control Type"
          )}

        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={controlTypes}
              columns={columns}
              setSelectedElement={setSelectedControlType}
              setSelectedRows={setSelectedRows}
              customProps={{ id: "ControlTypeTable_detailsTable" }}
              showRowSelect={showDelete}
              tableTitle={tableTitle}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            {showDelete && <DeleteButton md onClick={handleDelete} />}
            {showAdd && <AddButton md onClick={addControlType} />}
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }
  return <Loading />;
};

ControlTypeTable.propTypes = {
  elementNameInUrl: PropTypes.any,

  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  tableTitle: PropTypes.string,
  showAdd: PropTypes.any,
  showDelete: PropTypes.any,
};

export default ControlTypeTable;
