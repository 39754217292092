import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./attacker-query-key-factory";

interface ICreateNode {
  projectId: string;
  attackerCreateDto: any;
}

/**
 *
 * @param {AttackerCreateDto} attackerCreateDto creation dto
 * @returns id of created attacker
 */
export const createAttacker = ({ projectId, attackerCreateDto }: ICreateNode) => {
  return Brm.attackerApi.createAttacker(projectId, { attackerCreateDto });
};

export interface IUseCreateAttacker {
  options?: MutationOption<typeof createAttacker>;
}

/**
 * Custom hook to create a new attacker.
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for useCreateAttacker
 */
export const useCreateAttacker = ({ options }: IUseCreateAttacker = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.attackerKeys.all);
    },
    ...options,
    mutationFn: createAttacker,
  });
};
