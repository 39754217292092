import styled from "styled-components";

const homeStyle = {
  UserRole: styled.div`
    padding: 6px;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
  `,

  RoleSubHeading: styled.div`
    font-size: 20px;
    padding: 4px;
    font-weight: 500;
    text-align: center;
  `,
  Flex: styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media screen and (min-width: 1025px) {
      justify-content: center;
      flex-wrap: nowrap;
    }
  `,
  LeftSection: styled.div`
    padding: 10px;
    flex: 0 0 50%;
    @media screen and (min-width: 1025px) {
      padding: 20px;
      flex: 0 0 32%;
    }
  `,
  SectionHeading: styled.div`
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;
    color: red;
  `,
  Heading: styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 13px;
  `,
  Subtext: styled.ul`
    line-height: 25px;
    padding-left: 20px;
    font-weight: 400;
    font-size: 14px;

    color: #007bff;
    li {
      color: black;
    }
    .listtext {
      color: #007bff;
    }
    .pagelinks {
      color: #007bff;
      text-decoration-line: underline;
      background: transparent;
      border: none;
      text-align: left;
      padding: 0;
    }
    .pagelinks:focus,
    .pagelinks:link,
    .pagelinks:active {
      color: #007bff;
    }
    .pagelinks:hover {
      color: blue;
    }
    .pl-15 {
      padding-left: 35px;
    }
    ul {
      list-style-type: disc;
    }
    .disabledlink {
      pointer-events: none;
      cursor: default;
      text-decoration: none !important;
    }
  `,
  RightSection: styled.div`
    padding: 10px;
    flex: 0 0 100%;
    @media screen and (min-width: 1025px) {
      padding: 20px;
      flex: 0 0 26%;
    }
  `,
  Maintasks: styled.div`
    &&.list {
      counter-reset: list-number;
      display: flex;
      flex-wrap: wrap;
      @media screen and (min-width: 1025px) {
        display: block;
        flex-wrap: nowrap;
      }
    }
    &&.list div {
      margin: 15px 0 0 10px;
      flex-basis: 48%;
      @media screen and (min-width: 1025px) {
        flex-basis: 98%;
      }
    }
    .contentList {
      padding-left: 15px;
      padding-top: 10px;
    }
    .contentList button {
      text-decoration-line: underline;
      text-align: left;
    }
  `,
  Taskheading: styled.span`
    font-size: 16px;
    font-weight: 600;
  `,
  Subtasks: styled.ul`
    list-style-type: square;
    padding-left: 60px;
  `,

  CenterContainer: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  PageWrapper: styled.div`
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
  `,
};

export default homeStyle;
