import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

export interface ISetNodeStructCategory {
  nodeId: string;
  structCat: string;
}

/**
 * Sets the struct Category of a node
 *
 * @param {string} nodeId - uuid of the node
 * @param {string} structCat - structural category value of the node
 */
export const setNodeStructCategory = ({ nodeId, structCat }: ISetNodeStructCategory) => {
  return Brm.nodeApi.setNodeStructuralcat(nodeId, { body: structCat });
};

export interface IUseSetNodeStructuralCategory {
  options?: MutationOption<typeof setNodeStructCategory>;
}

/**
 * Custom hook to change structural category value of an existing node
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetNodeStructuralCategory = ({ options }: IUseSetNodeStructuralCategory) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.nodeKeys.all);
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: setNodeStructCategory,
  });
};
