/* eslint-disable no-param-reassign */
import * as YFiles from "yfiles";
import * as React from "react";
import { TYPE } from "constants/brm";
import { DiagramBuilderItemEventArgs } from "features/diagram-common";
import { AttackGraphBuilder } from "../../../services/graph-building";
import { getShapeData } from "../../../services/yfiles";
import { IGraphData } from "../../../types";

const createBuilder = (graphComp: YFiles.GraphComponent) => {
  const builder = new AttackGraphBuilder(graphComp.graph);

  builder.addNodeCreatedListener((src: AttackGraphBuilder, event: DiagramBuilderItemEventArgs) => {
    const { graph } = graphComp;
    const { type: nodeType } = event.item.tag;

    const { color, shape } = getShapeData(event.item.tag);

    if (nodeType === TYPE.exchange) {
      // Update the node layout so that height is half the current width to appear correctly as a rectangle
      graph.setNodeLayout(
        event.item,
        new YFiles.Rect(event.item.layout.x, event.item.layout.y, event.item.layout.width, event.item.layout.width / 2)
      );
    }
    event.graph.setStyle(event.item, new YFiles.ShapeNodeStyle({ shape, fill: color, stroke: "transparent" }));
  });

  // Build the graph data from the given data
  // builder.nodeData = graphData.nodes;
  // builder.edgeData = graphData.edges;
  // graphComp.graph = builder.buildGraph();

  return builder;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useAttackGraphBuilder = (graphComp: YFiles.GraphComponent, graphData: IGraphData) => {
  const [diagramBuilder] = React.useState<AttackGraphBuilder | undefined>(() => createBuilder(graphComp));

  React.useEffect(() => {
    const initialLayout = async () => {
      await graphComp.fitGraphBounds();
    };
    initialLayout();
  }, [graphComp]);

  // // const [diagramBuilder, setDiagramBuilder] = React.useState<AttackGraphBuilder | undefined>();
  // const diagramBuilderRef = React.useRef<AttackGraphBuilder>();

  // useConstructor(() => {
  //   if (!diagramBuilderRef.current) {
  //     console.log("Creating builder");

  //     // ensure the builder is using the master graph
  //     diagramBuilderRef.current = new AttackGraphBuilder((graphComp.graph as YFiles.FilteredGraphWrapper).wrappedGraph);
  //     const builder = diagramBuilderRef.current;
  //     builder.addNodeCreatedListener((src: AttackGraphBuilder, event: DiagramBuilderItemEventArgs) => {
  //       const { graph } = graphComp;
  //       const { type: nodeType } = event.item.tag;

  //       const { color, shape } = getShapeData(event.item.tag);

  //       if (nodeType === TYPE.exchange) {
  //         // Update the node layout so that height is half the current width to appear correctly as a rectangle
  //         graph.setNodeLayout(
  //           event.item,
  //           new YFiles.Rect(
  //             event.item.layout.x,
  //             event.item.layout.y,
  //             event.item.layout.width,
  //             event.item.layout.width / 2
  //           )
  //         );
  //       }
  //       event.graph.setStyle(event.item, new YFiles.ShapeNodeStyle({ shape, fill: color, stroke: "transparent" }));
  //     });

  //     // // Build the graph data from the given data
  //     // builder.nodeData = graphData.nodes;
  //     // builder.edgeData = graphData.edges;
  //     // graphComp.graph = builder.buildGraph();

  //     // Center the graph
  //     const initialLayout = async () => {
  //       await graphComp.fitGraphBounds();
  //     };
  //     initialLayout();
  //   }
  //   // setDiagramBuilder(builder);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // });

  return diagramBuilder;
};
