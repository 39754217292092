// const generateExportFile = ({ projectId, body, context }) => {
//   return genericExportApi.generateExportFile(context, projectId, { requestBody: body });
// };

import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { ExportContext, ExportContextValues } from "../../constants";

interface IGenerateExportFile {
  projectId: string;
  context: ExportContextValues;
  body: any;
}
interface ExporterFunctionMap {
  [key: string]: any;
}

const generateExportFile = async ({ projectId, context, body }: IGenerateExportFile) => {
  const exporter: ExporterFunctionMap = {
    [ExportContext.rar]: () => Brm.rarExportApi.generateExportFileRar(projectId, { requestBody: body }),
    [ExportContext.savepoint]: () =>
      Brm.savePointExportApi.generateExportFileSavepoint(projectId, {
        requestBody: body,
      }),
    [ExportContext.sctm]: () => Brm.sctmExportApi.generateExportFileSctm(projectId, { requestBody: body }),
    [ExportContext.tra]: () => Brm.traExportApi.generateExportFileTra(projectId, { requestBody: body }),
    [ExportContext.system]: () => Brm.systemExportApi.generateExportFileSystem(projectId, { requestBody: body }),
  };

  if (!Object.keys(exporter).some((k) => k === context)) {
    throw new Error(`generateExportFile: Unsupported export context: ${context}`);
  }

  return exporter[context]();
};

export const useExportFile = (options = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: generateExportFile,
  });
};
