import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./organization-query-key-factory";

/**
 * Gets the organization attached user
 */
export const getOrganizationAttachedUser = ({ organizationId }: { organizationId: string | null }) => {
  return organizationId === null
    ? Promise.reject(new Error("Invalid organizationId id"))
    : Brm.organizationApi.getOrganizationAttachedUser(organizationId);
};

interface IOrganizationsProps {
  organizationId: string | null;
  options?: QueryOption<typeof getOrganizationAttachedUser>;
}

/**
 * Custom hook for getting organization attached user
 *
 * @param {object} config - react-query configuration
 * @returns
 */
export const useOrganizationAttachedUser = ({ organizationId, options = {} }: IOrganizationsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.organizationKeys.attachedUser(organizationId),
    queryFn: () => getOrganizationAttachedUser({ organizationId }),
  });
};
