import { IOption } from "types";

export type ViewType = "Operational" | "System";

export interface ICreateProjectInput {
  name: string;
  note: string;
  users: IOption[];
  readOnlyUsers: IOption[];
  testField: IOption[];
  sapPrograms?: IOption[];
}

export type UiOption = "uploadSctm" | "none" | "existingBaseline";

export interface IConfigureData {
  modelFiles: FileList | undefined;
  isBaselineIncludedInSctm: boolean | undefined;
  excludeNonCompliantControls: boolean | undefined;
  sctmFile: FileList | undefined;
  findingsFiles: FileList | undefined;
  knowledgebase: IOption | undefined;
  baseline: string | undefined;
  baselineName: string | undefined;
  baselineMode: string | undefined;
  catalog: string | undefined;
  catalogName: string | undefined;
  isMdxml: boolean | undefined;
  isSysMl: boolean | undefined;
  viewType: ViewType | undefined;
  isComplete: boolean;
  isConfigureInProgress: boolean;
  isConfigureCancelled: boolean;
  uiSecurityOption: UiOption;
  ceilingBaseline: IOption | undefined;
}

export const StepStatus = {
  NotStarted: "Not Started",
  InProgress: "In Progress",
  Complete: "Complete",
  Aborted: "Aborted",
  Error: "Error",
  Skipped: "Skipped",
  NotApplicable: "Not Applicable",
} as const;

// export interface IConfigureStep {
//   id: string;
//   label: string;
//   status: StepStatus;
//   error?: any;
// }

// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
export type StepStatus = typeof StepStatus[keyof typeof StepStatus];

export const ConfigureStep = {
  SetProjectKnowledgebase: "SetProjectKnowledgebase",
  CloneControlCatalog: "CloneControlCatalog",
  SetProjectCatalog: "SetProjectCatalog",
  SetVariantCatalog: "SetVariantCatalog",
  CreateBaseline: "CreateBaseline",
  ImportBaseline: "ImportBaseline",
  SetProjectBaseline: "SetProjectBaseline",
  SetVariantBaseline: "SetVariantBaseline",
  ImportModel: "ImportModel",
  CalculateInitialRisk: "CalculateInitialRisk",
  ImportSctm: "ImportSctm",
  ImportFindings: "ImportFindings",
  CalculateVariantRisk: "CalculateVariantRisk",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare, no-redeclare
export type ConfigureStep = typeof ConfigureStep[keyof typeof ConfigureStep];

export interface IStepInfo {
  id: ConfigureStep;
  label: string;
  status: StepStatus;
  error?: any;
  isVisible: boolean;
}
