import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./user-query-key-factory";
/**
 * Gets the user by id
 */
export const getUserAttachedOrganizations = ({ userId }: { userId: string | null }) => {
  return userId === null ? Promise.reject(new Error("Invalid id")) : Brm.userApi.getUserAttachedOrganization(userId);
};

interface IUserAttachedOrganizationsProps {
  userId: string | null;
  options?: QueryOption<typeof getUserAttachedOrganizations>;
}

/**
 * Custom hook for getting the user
 *
 * @param {string} userId - uuid of user
 * @param {object} options - react-query options
 * @returns
 */
export const useUserAttachedOrganizations = ({ userId, options = {} }: IUserAttachedOrganizationsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.userKeys.attachedOrg(userId),
    queryFn: () => getUserAttachedOrganizations({ userId }),
  });
};
