import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./system-query-key-factory";

interface IPublishSystemProps {
  systemId: string;
}

/**
 * Service to change a project state from InPreparation to Active
 * @param {string} systemId - uuid of system
 * @returns
 */
export const publishSystem = ({ systemId }: IPublishSystemProps) => {
  return systemId === null ? Promise.reject(new Error("Invalid systemId dto")) : Brm.systemApi.publishSystem(systemId);
};

interface IPublishSystemQueryProps {
  projectId: string;
  options?: MutationOption<typeof publishSystem>;
}

/**
 * Custom hook for publishing a project by changing its state
 *
 * @param {string} projectId - uuid of project
 * @returns react-query Mutation.
 */
export const usePublishSystem = ({ projectId, options }: IPublishSystemQueryProps) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.systemKeys.project(projectId));
    },
    ...options,
    mutationFn: publishSystem,
  });
};
