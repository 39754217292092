import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
  .contentList {
    padding-left: 30px;
    padding-top: 10px;
  }
  .videoLink {
    text-align: left;
  }

  p {
    margin-top: 20px;
  }
`;

export const Separator = styled.div`
  display: flex;
  padding: 0 10px;
`;
