// import UploadIcon from "assets/icons/mer";
import SecondaryButton from "components/elements/SecondaryStyledButton";
import { MouseEvent } from "react";
import { TbArrowMerge } from "react-icons/tb";

interface IMergeButtonProps {
  onClick: (event: MouseEvent) => void;
  mergeTitle?: string;
}

export const MergeButton = ({ onClick, mergeTitle = "Merge" }: IMergeButtonProps) => (
  <span title={mergeTitle}>
    <SecondaryButton id="MergeButton_button" onClick={onClick}>
      <TbArrowMerge size={18} />
    </SecondaryButton>
  </span>
);
