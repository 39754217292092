import * as React from "react";
import * as Recoil from "recoil";
// Components
import { AddButton, DeleteButton, MitigationApi, useRoles } from "features/brm";
import systemModals from "components/modals/Modals";

// Styles
import styled from "styled-components";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { isAddingAllocatedControlsState, sctmIdState } from "atoms/atoms-mitigation";
// State
import { selectedInstanceState } from "atoms/atoms-content";
import { variantIdState } from "atoms/atoms-component";

// Constants
import { COMMON, TYPE } from "constants/brm";
import ContentLayout from "brm/layout/Content/ContentLayout";

// Local
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import { queryClient } from "libs/react-query";
import toast from "react-hot-toast";
import { RequestTooLargeError } from "services/errors";
import { AllocatedControlTable } from "../../components/tables/AllocatedControlTable";
import { AddAllocatedControlForm } from "../../components/forms/AddAllocatedControlForm";
import { useSctmName, useDeleteAllocatedControl } from "../../api";
import { useImportSctm } from "../../hooks/useImportSctm/useImportSctm";

export function AllocatedControls() {
  const { isRiskAnalyst } = useRoles();
  const variantId = Recoil.useRecoilValue(variantIdState);
  const setSelectedInstance = Recoil.useSetRecoilState(selectedInstanceState);
  const sctmId = Recoil.useRecoilValue(sctmIdState);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const isAddingAllocatedControls = Recoil.useRecoilValue(isAddingAllocatedControlsState);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const localSctmId = React.useRef(sctmId);

  // Query
  const { importSctm } = useImportSctm({
    onSuccess: () => {},
    onError: (error) => {
      if (error instanceof RequestTooLargeError) {
        toast.error("Attempted to add too many controls.  Please try again with a reduced number of controls");
        console.error("SCTM import error");
      }
    },
  });

  // Queries
  const { data: sctmNameData } = useSctmName(sctmId);
  const { data: variantCompliance } = MitigationApi.useVariantCompliance({
    variantId,
    options: { enabled: !!variantId && variantId !== COMMON.nullUuid && isRiskAnalyst },
  });
  const { mutate: deleteAC } = useDeleteAllocatedControl(setConfirmDelete, setDeleteModalIsOpen, setSelectedInstance);

  React.useEffect(() => {
    if (localSctmId.current !== sctmId) {
      queryClient.invalidateQueries(["getAllocatedControls"]);
      queryClient.invalidateQueries(MitigationApi.allocatedControlsGqlKey.all);
    }
  }, [sctmId]);

  const addAllocatedControl = () => {
    setModalIsOpen(true);
  };

  const handleClick = () => {
    setConfirmDelete(true);
    deleteAC({ selectedRows });
  };

  const header = sctmId && (
    <Title>
      {sctmNameData?.result} - Allocated Controls
      {isAddingAllocatedControls ? (
        <CalcProgressSpan id="AllocatedControls_progressInfo">
          Adding allocated controls... <progress id="AllocatedControls_progressbar" />
        </CalcProgressSpan>
      ) : (
        <></>
      )}
      <Buttons>
        {variantCompliance && !variantCompliance.result && selectedRows.length > 0 ? (
          <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />
        ) : (
          <></>
        )}
        {variantCompliance && !variantCompliance.result && (
          <AddButton onClick={addAllocatedControl} disabled={isAddingAllocatedControls} />
        )}
      </Buttons>
    </Title>
  );

  const main = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "AllocatedControls_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <div>
            Are you sure you want to delete the selected allocated control(s) ?
            <DialogButtonDivStyled>
              <DialogButtonStyled disabled={confirmDelete} onClick={() => setDeleteModalIsOpen(false)}>
                Cancel
              </DialogButtonStyled>
              <DialogButtonStyled disabled={confirmDelete} onClick={handleClick}>
                {confirmDelete ? `Deleting...` : `Confirm`}
              </DialogButtonStyled>
            </DialogButtonDivStyled>
          </div>,
          TYPE.allocatedControl
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "AllocatedControls_page",
          modalIsOpen,
          setModalIsOpen,
          <AddAllocatedControlForm
            setModalIsOpen={setModalIsOpen}
            sctmName={sctmNameData?.result}
            importSctm={importSctm}
          />,
          TYPE.allocatedControl
        )}

      <AllocatedControlTable selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
    </>
  );

  return <ContentLayout pageId="AllocatedControls_page" header={header} main={main} />;
}

const CalcProgressSpan = styled.span`
  margin-left: 10px;
  font-size: 14px;
`;
