import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./data-type-query-key-factory";

/**
 *
 * Gets categorized entity of a datatype
 * @param {string} dataTypeId - uuid of the datatype
 */
export const getDataTypeCategorizedEntity = async ({ dataTypeId }: { dataTypeId: string | null }) => {
  return dataTypeId === null || dataTypeId === "" || dataTypeId === undefined
    ? Promise.reject(new Error("Invalid datatype id"))
    : Brm.dataTypeApi.getDataTypeCategorizedentity(dataTypeId);
};

interface IDataTypeCategorizedEntity {
  dataTypeId: string | null;
  options?: QueryOption<typeof getDataTypeCategorizedEntity>;
}

/**
 * Custom hook for getting categorized entity of a dataType
 *
 * @param {string} userId - uuid of dataType
 * @param {object} options - react-query configuration
 * @returns
 */
export const useDataTypeCategorizedEntity = ({ dataTypeId, options = {} }: IDataTypeCategorizedEntity) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.dataTypeKeys.categorizedEntity(dataTypeId),
    queryFn: () => getDataTypeCategorizedEntity({ dataTypeId }),
  });
};
