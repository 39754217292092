import styled from "styled-components";

export const SmallSearchInput = styled.input`
  font-size: 8pt;
  font-weight: 600;
  max-height: 20px;
  width: 100%;

  &:focus-visible {
    outline: 2px solid #2684ff;
  }
`;
