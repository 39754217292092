import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./person-query-key-factory";
import * as NodeKeyFactory from "../node/node-query-key-factory";

interface IAddPersonNodeProps {
  personId: string | null;
  nodeId: string | null;
}

/**
 * Add a person from a node
 *
 * @returns
 */
export const addPersonNode = ({ personId, nodeId }: IAddPersonNodeProps) => {
  return personId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.personApi.addPersonNode(personId, { body: nodeId });
};

interface IAddPersonNodeQueryProps {
  options?: MutationOption<typeof addPersonNode>;
}

/**
 * Custom hook to add a person from a node
 *
 * @returns react-query Mutation.
 */
export const useAddPersonNode = ({ options }: IAddPersonNodeQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.personKeys.all);
      queryClient.invalidateQueries(NodeKeyFactory.nodeKeys.persons(variables.nodeId));
    },
    ...options,
    mutationFn: addPersonNode,
  });
};
