import * as ReactQuery from "@tanstack/react-query";
import { MutationOption /* , queryClient */ } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./sap-org-query-key-factory";
import * as SapProgramKeyFactory from "../sapProgram/sap-program-query-key-factory";

export interface ISetOrganizationSapProgramsProps {
  orgId: string;
  sapPrograms: string[];
}

/**
 * Set sap programs for a sap organization
 *
 * @returns
 */
export const setSapOrganizationSapPrograms = ({ orgId, sapPrograms }: ISetOrganizationSapProgramsProps) => {
  // console.log("remove orgId, progId", orgId, progId);
  return Brm.sapOrganizationApi.setsapprogramSapOrganization(orgId, { requestBody: sapPrograms });
};

type MutationFunction = typeof setSapOrganizationSapPrograms;
export interface IUseSetSapOrganizationSapProgramsProps {
  options?: MutationOption<MutationFunction>;
}

/**
 * Custom hook to set sap programs for a sap organization
 *
 * @returns react-query Mutation.
 */
export const useSetSapOrganizationSapPrograms = ({ options }: IUseSetSapOrganizationSapProgramsProps = {}) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation({
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: KeyFactory.sapOrganizationKeys.all });
      queryClient.invalidateQueries({ queryKey: SapProgramKeyFactory.sapProgramKeys.all });
    },
    mutationFn: setSapOrganizationSapPrograms,
  });
};
