import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./sap-org-query-key-factory";

/**
 * Gets the sap organization bound to the given organization id
 */
export const getBoundSapOrganization = ({ organizationId }: { organizationId: string }) => {
  return Brm.sapOrganizationApi.findSapOrganization({ org: organizationId });
};

type FunctionType = typeof getBoundSapOrganization;
export interface IBoundOrganizationProps {
  organizationId: string;
  options?: QueryOption<FunctionType>;
}

/**
 * Custom hook for getting all sap organizations
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useGetBoundSapOrganization = ({ organizationId, options = {} }: IBoundOrganizationProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.sapOrganizationKeys.find(organizationId),
    queryFn: () => getBoundSapOrganization({ organizationId }),
  });
};
