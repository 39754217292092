import { AiOutlineImport } from "react-icons/ai";
import SecondaryButton from "components/elements/SecondaryStyledButton";

import { MouseEvent } from "react";

interface IUploadButtonProps {
  onClick: (event: MouseEvent) => void;
  uploadTitle?: string;
}

export const TraUploadButton = ({ onClick, uploadTitle = "Import" }: IUploadButtonProps) => (
  <SecondaryButton title={uploadTitle} id="TraUploadButton_button" onClick={onClick}>
    <AiOutlineImport />
  </SecondaryButton>
);
