import { atom, selector } from "recoil";
import { convertToLevel } from "utils/calibration-converter";

// export const trevOptionsState = atom({
//   key: "trevOptions",
//   default: [],
// });

// export const attackTacticOptionsState = atom({
//   key: "attackTacticOptions",
//   default: [],
// });

// export const attackerOptionsState = atom({
//   key: "attackerOptions",
//   default: [],
// });

// export const nodeOptionsState = atom({
//   key: "nodeOptions",
//   default: [],
// });

// export const exchangeOptionsState = atom({
//   key: "exchangeOptions",
//   default: [],
// });

export const likelihoodOptionsState = atom({
  key: "likelihoodOptions",
  default: [
    { value: "L5", label: "near certainty" },
    { value: "L4", label: "probable" },
    { value: "L3", label: "occasional" },
    { value: "L2", label: "remote" },
    { value: "L1", label: "improbable" },
  ],
});

// export const meansOptionsState = atom({
//   key: "meansOptions",
//   default: [
//     { value: "M5", label: "Very High" },
//     { value: "M4", label: "High" },
//     { value: "M3", label: "Moderate" },
//     { value: "M2", label: "Low" },
//     { value: "M1", label: "Very Low" },
//   ],
// });

// export const opportunityOptionsState = atom({
//   key: "opportunityOptions",
//   default: [
//     { value: "O5", label: "Confirmed" },
//     { value: "O4", label: "Expected" },
//     { value: "O3", label: "Anticipated" },
//     { value: "O2", label: "Predicted" },
//     { value: "O1", label: "Possible" },
//   ],
// });

export const attackListState = atom({
  key: "attackListState",
  default: [],
});

export const trevPatternSelectedFiltersState = atom({
  key: "trevPatternSelectedFiltersState",
  default: [],
});

export const nodeSelectedFiltersState = atom({
  key: "nodeSelectedFiltersState",
  default: [],
});

export const exchangeSelectedFiltersState = atom({
  key: "exchangeSelectedFiltersState",
  default: [],
});

export const attackerSelectedFiltersState = atom({
  key: "attackerSelectedFiltersState",
  default: [],
});

export const tacticsSelectedFiltersState = atom({
  key: "tacticsSelectedFiltersState",
  default: [],
});

export const likelihoodSelectedFiltersState = atom({
  key: "likelihoodSelectedFiltersState",
  default: [],
});

export const calibratedLikelihoodSelectedFiltersState = atom({
  key: "calibratedLikelihoodSelectedFiltersState",
  default: [],
});

/**
 * User Filtered Attack in Likelihood Calibration Editor
 */
export const filteredAttackLikelihoodCalibrationState = selector({
  key: "filteredAttackLikelihoodCalibrationState",
  get: ({ get }) => {
    const nodeFilter = get(nodeSelectedFiltersState);
    const exchangeFilter = get(exchangeSelectedFiltersState);
    const attackerFilter = get(attackerSelectedFiltersState);
    const trevPatternFilter = get(trevPatternSelectedFiltersState);
    const tacticFilter = get(tacticsSelectedFiltersState);
    const likelihoodFilter = get(likelihoodSelectedFiltersState);
    const calibratedLikelihoodFilter = get(calibratedLikelihoodSelectedFiltersState);
    let attackfilteredList = get(attackListState);

    if (nodeFilter.length !== 0) {
      const items = nodeFilter.map((n) => n.value);
      const collectionSet = new Set(items);
      attackfilteredList = attackfilteredList.filter((attack) => collectionSet.has(attack.target?.id));
    }

    if (exchangeFilter.length !== 0) {
      const items = exchangeFilter.map((e) => e.value);
      const collectionSet = new Set(items);
      attackfilteredList = attackfilteredList.filter((attack) => collectionSet.has(attack.target?.id));
    }

    if (attackerFilter.length !== 0) {
      const items = attackerFilter.map((e) => e.value);
      const collectionSet = new Set(items);
      attackfilteredList = attackfilteredList.filter((attack) => collectionSet.has(attack.attacker?.id));
    }

    if (trevPatternFilter.length !== 0) {
      const items = trevPatternFilter.map((t) => t.value);
      const collectionSet = new Set(items);
      attackfilteredList = attackfilteredList.filter((attack) => collectionSet.has(attack?.trevpattern));
    }

    if (tacticFilter.length !== 0) {
      const items = tacticFilter.map((t) => t.value);
      const collectionSet = new Set(items);
      attackfilteredList = attackfilteredList.filter((attack) => collectionSet.has(attack?.tactic));
    }

    if (likelihoodFilter.length !== 0) {
      const items = likelihoodFilter.map((l) => l.label.toLowerCase());
      const collectionSet = new Set(items);
      attackfilteredList = attackfilteredList.filter((attack) =>
        collectionSet.has(convertToLevel(attack.likelihood.calcStringValue, "likelihood"))
      );
    }

    if (calibratedLikelihoodFilter.length !== 0) {
      const items = calibratedLikelihoodFilter.map((c) => c.label.toLowerCase());
      const collectionSet = new Set(items);
      attackfilteredList = attackfilteredList.filter((attack) =>
        collectionSet.has(convertToLevel(attack.likelihood.calibratedStringValue, "likelihood"))
      );
    }

    return attackfilteredList;
  },
});
