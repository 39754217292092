import useToggle from "hooks/useToggle";
import { AttackDiagramComponent } from "../AttackDiagramComponent";
import { RarDiagramToolbar } from "../RarDiagramToolbar";
import { IRarData, IRarToolBar } from "../../types";

interface IRarDiagramProps {
  rarData: IRarData[];
  targets: string[];
  highlighted: string[];
  onHighlightNodes: (v: any) => void;
}

export const RarDiagram = ({ rarData, targets, highlighted, onHighlightNodes }: IRarDiagramProps) => {
  const [isOverviewVisible, toggleOverviewVisibility] = useToggle(false);
  const [isAttacksVisible, toggleAttackVisibility] = useToggle(false);

  const createToolbar = (toolbarData: IRarToolBar) => {
    return (
      <RarDiagramToolbar
        onZoomIn={toolbarData.onZoomIn}
        onZoomOut={toolbarData.onZoomOut}
        onResetZoom={toolbarData.onResetZoom}
        onFitContent={toolbarData.onFitContent}
        onOrganicLayout={toolbarData.onOrganicLayout}
        onCircularLayout={toolbarData.onCircularLayout}
        onRadialLayout={toolbarData.onRadialLayout}
        onToggleLabels={toolbarData.onToggleLabels}
        onHighlightNodes={toolbarData.onHighlightNodes}
        onToggleOverview={toolbarData.onToggleOverview}
        showOverview={toolbarData.showOverview}
        onToggleAttacksVisibility={toggleAttackVisibility}
        isAttacksVisible={isAttacksVisible}
        showLabels={toolbarData.showLabels}
        hasNodeSelection={toolbarData.hasNodeSelection}
        currentLayout={toolbarData.currentLayout}
        isFocused={targets.length === 1}
        focusEnabled={targets.length === 1 || highlighted.length > 0}
      />
    );
  };

  return (
    <AttackDiagramComponent
      toolbar={(data: IRarToolBar) => createToolbar(data)}
      initialData={rarData}
      targets={targets}
      showAttacks={isAttacksVisible}
      highlighted={highlighted}
      onHighlightNodes={onHighlightNodes}
      showOverview={isOverviewVisible}
      onToggleOverview={toggleOverviewVisibility}
    />
  );
};
