import * as Recoil from "recoil";
import * as React from "react";
import { SocketConfig, useSocket } from "hooks/useSocket";
import { userIdState } from "atoms";
import { SOCKET_NAMESPACE, STATUS } from "constants/brm";
import { queryClient } from "libs/react-query";
import * as log from "loglevel";
import { useRoles } from "features/brm/hooks/useRoles";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "routes/route-paths";
import { getErrorMessage } from "utils/error-message-utils";
import { ProjectState } from "../../types";
import { projectKeys, userKeys } from "../../api/admin";
import { nodeKeys, nodeKeysGraphQl, vulnerabilityConditionKeys } from "../../api/system";
import { assetKeys, threatEventKeys } from "../../api/risk";
import { SERVER_PUSH_EVENTS } from "../../constants";
import { useProject } from "../useProject";

export const useProjectStatusNotification = () => {
  const [currentProject, { setCurrentProject }] = useProject();
  const userId = Recoil.useRecoilValue(userIdState);
  const { isRiskAnalyst } = useRoles();
  const navigate = useNavigate();

  const socketConfig = React.useMemo<SocketConfig>(
    () => ({ namespace: SOCKET_NAMESPACE.admin, auth: { projectId: currentProject?.id || "" } }),
    [currentProject]
  );

  const { socket, error } = useSocket(socketConfig);

  React.useEffect(() => {
    if (error) {
      console.error("Socket Error:", getErrorMessage(error));
      log.info(`[Socket] Setting current project to null`);
      setCurrentProject(null);
    }
  }, [error, setCurrentProject]);

  React.useEffect(() => {
    if (socket === null) {
      return () => {};
    }
    socket.on(SERVER_PUSH_EVENTS.projectStatusChanged, (data: any) => {
      log.debug(`[Socket] ${SERVER_PUSH_EVENTS.projectStatusChanged}`, data);
      // console.log(SERVER_PUSH_EVENTS.projectStatusChanged, data);

      if (data.payload) {
        const { "project-id": projId, "project-status": projectStatus } = data.payload;

        if (projectStatus) {
          if (currentProject !== null && currentProject.id === projId && projectStatus === STATUS.suspended) {
            setCurrentProject(null);
          }

          setCurrentProject((prev: ProjectState | null) => {
            if (prev) {
              return { ...prev, status: projectStatus };
            }
            return null;
          });

          if (projectStatus === STATUS.active) {
            queryClient.invalidateQueries(threatEventKeys.all);
            queryClient.invalidateQueries(vulnerabilityConditionKeys.all);
            queryClient.invalidateQueries(assetKeys.all);
            queryClient.invalidateQueries(nodeKeysGraphQl.getAllNodes);
            queryClient.invalidateQueries(nodeKeys.all);
            queryClient.invalidateQueries(nodeKeysGraphQl.systemDiagramData);
            queryClient.invalidateQueries(projectKeys.all);
            queryClient.invalidateQueries(userKeys.all);
          }

          if (isRiskAnalyst && projectStatus === STATUS.inPreparation) {
            setCurrentProject(null);
            navigate(RoutePath.Projects);
          }
        }
      }
    });
    socket.on(SERVER_PUSH_EVENTS.projectDeleted, (data: any) => {
      log.debug(`[Socket] ${SERVER_PUSH_EVENTS.projectDeleted}`, data);
      // console.log(SERVER_PUSH_EVENTS.projectDeleted, data);

      if (data.payload) {
        const { "project-id": projId } = data.payload;

        if (projId) {
          if (currentProject !== null && currentProject.id === projId) {
            setCurrentProject(null);
            navigate(RoutePath.Projects);
            queryClient.invalidateQueries(projectKeys.all);
          }
        }
      }
    });
    return () => {
      socket.off(SERVER_PUSH_EVENTS.projectStatusChanged);
      socket.off(SERVER_PUSH_EVENTS.projectDeleted);
    };
  }, [isRiskAnalyst, navigate, socket, userId, setCurrentProject, currentProject]);

  return null;
};
