import { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import { LoadingSpinner } from "components/elements";
import Title from "components/elements/StyledTitle";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { variantIdState } from "atoms/atoms-component";
import { riskUpdatedState } from "atoms/atoms-risk";
import {
  createColumnMappedNoEdit,
  createColumnImpactMapped,
  createColumnLikelihoodMapped,
} from "brm/tables/services/column/columnFactory";
// import * as ImpactApi from "brm/risk-model/impact/api";
import { TYPE } from "constants/brm";
import { RoutePath } from "routes/route-paths";
import { RiskApi, useRoles } from "features/brm";
import UndesiredEventsDetails from "../details/UndesiredEventsDetails";

const listUrl = RoutePath.UndesiredEvents;

function UndesiredEventFullDetails() {
  const { isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const { id } = useParams();
  const navigate = useNavigate();
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [data, setData] = useState([]);
  const [, setSelectedRowId] = useState("");
  const riskUpdated = useRecoilValue(riskUpdatedState);
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const { data: ueById, isError } = RiskApi.useUndesiredEvent({
    ueId: id,
    variantId,
    options: { enabled: isRiskAnalyst },
  });
  let content = null;
  const [tabIndex, setTabIndex] = useState(0);

  const columns = useMemo(
    () => [
      createColumnMappedNoEdit("category"),
      createColumnImpactMapped("impact"),
      createColumnMappedNoEdit("objective"),
      createColumnLikelihoodMapped("likelihood"),
      createColumnLikelihoodMapped("mitigatedlikelihood"),
    ],
    []
  );

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(listUrl);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    if (ueById) {
      setSelectedElement(ueById);
      setData([ueById]);
    }
  }, [ueById, riskUpdated]);

  if (isError) {
    return "An error occurred while retrieving Undesired Event data.";
  }

  if (ueById) {
    content = (
      <>
        <Title id="UndesiredEventFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "UndesiredEventFullDetails_table" }}
              elementName={TYPE.ue}
            />
          </DetailsContainerSingleTable>
        ) : (
          <></>
        )}

        {selectedElement.id && (
          <DetailsContainerParent showSummaryTable={showSummaryTable}>
            <DetailsContainer id="UndesiredEvent_detailsPanel">
              <UndesiredEventsDetails selectedElement={selectedElement} setTabIndex={setTabIndex} tabIndex={tabIndex} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
    return content;
  }

  return <LoadingSpinner />;
}

export default UndesiredEventFullDetails;
