import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./mitigation-query-key-factory";
/**
 * Returns control types for the given baseline
 *
 * @param {string} baselineId - uuid of the baseline
 */
export const getBaselineControlTypes = ({ baselineId }: { baselineId: string }) => {
  return Brm.baselineApi.getStandardBaselineControltype(baselineId);
};

interface IUseBaselineControlTypes {
  baselineId: string;
  options?: QueryOption<typeof getBaselineControlTypes>;
}

/**
 * React-query hook for getting control types of a baseline
 *
 * @param {string} baselineId - uuid of baseline
 * @param {object} config - react-query configuration object
 * @returns react-query for getBaselineControlTypes
 */
export const useBaselineControlTypes = ({ baselineId, options }: IUseBaselineControlTypes) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.baselineKeys.controlsTypes(baselineId),
    queryFn: () => getBaselineControlTypes({ baselineId }),
  });
};
