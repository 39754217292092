import MessageEvent from "./MessengerEvent";

class Messenger {
  constructor() {
    this.events = {};
  }

  dispatch(eventName, data) {
    const event = this.events[eventName];
    if (event) {
      event.fire(data);
    }
  }

  register(eventName, callback) {
    let event = this.events[eventName];
    if (!event) {
      event = new MessageEvent(eventName);
      this.events[eventName] = event;
    }
    event.registerCallback(callback);
  }

  unregister(eventName, callback) {
    const event = this.events[eventName];

    if (event && event.callbacks.indexOf(callback) > -1) {
      event.unregisterCallback(callback);
      if (event.callbacks.length === 0) {
        delete this.events[eventName];
      }
    }
    if (!callback) {
      delete this.events[eventName];
    }
  }
}

export default new Messenger();
