import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./knowledgebase-query-key-factory";

/**
 * create kb
 *
 * @param {KBCreateDto} kBCreateDto - KbcreateDto object
 * @returns id of create Kb
 */
export const createKb = async ({ kBCreateDto }: { kBCreateDto: any }) => {
  const data = await Brm.userApi.findSelf();
  if (data?.organization) {
    return Brm.kbApi.createKB(data.organization, { kBCreateDto });
  }
  return null;
};

interface IUseCreateKb {
  options?: MutationOption<typeof createKb>;
}

/**
 * custom hook to create a new kb .
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useCreateKb = ({ options }: IUseCreateKb) => {
  return ReactQuery.useMutation({
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.knowledgebaseKeys.all);
    },
    mutationFn: createKb,
  });
};
