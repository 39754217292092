import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import AssetTable from "./tables/AssetTable";

function Asset() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const assetHeader = <Title id="AssetList_title">Assets</Title>;

  const assetMain = <AssetTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />;

  return <ContentLayout pageId="Asset_page" header={assetHeader} main={assetMain} />;
}

export default Asset;
