import PropTypes from "prop-types";

import ButtonGroup from "./ButtonGroup";
import ConfirmButton from "./ConfirmButton";
import EditButton from "./EditButton";
import CancelButton from "./CancelButton";

const ButtonCell = ({
  selectedRowId,
  elementId,
  handleConfirmEditClick,
  setEditMode,
  editMode,
  disable,
  onEditModeStart,
}) => {
  const handleEditModeClick = (e) => {
    setEditMode(true);
    e.preventDefault();
    if (onEditModeStart) {
      onEditModeStart();
    }
  };

  const handleExitEditModeClick = () => {
    setEditMode(false);
  };

  let buttons;
  if (editMode && selectedRowId === elementId) {
    buttons = [
      <ConfirmButton key="confirmbutton" onClick={handleConfirmEditClick} />,
      <CancelButton key="cancelbutton" onClick={handleExitEditModeClick} />,
    ];
  } else {
    buttons = <EditButton onClick={(e) => handleEditModeClick(e)} disabled={disable} />;
  }

  return <ButtonGroup buttons={buttons} />;
};

ButtonCell.propTypes = {
  disable: PropTypes.any,
  editMode: PropTypes.any,
  elementId: PropTypes.any,
  handleConfirmEditClick: PropTypes.any,
  selectedRowId: PropTypes.any,
  setEditMode: PropTypes.func,
  onEditModeStart: PropTypes.func,
};

export default ButtonCell;
