/* eslint-disable no-param-reassign */
import * as YFiles from "yfiles";
import * as React from "react";

import { NODE_HEIGHT, NODE_WIDTH } from "../../../constants";

const configureDefaultNodeStyle = (graph: YFiles.IGraph) => {
  graph.nodeDefaults.size = new YFiles.Size(NODE_WIDTH, NODE_HEIGHT);
  graph.nodeDefaults.style = new YFiles.ShapeNodeStyle({
    shape: "round-rectangle",
  });

  const labelStyle = new YFiles.DefaultLabelStyle({
    maximumSize: new YFiles.Size(180, 30),
    wrapping: YFiles.TextWrapping.CHARACTER_ELLIPSIS,
  });
  graph.nodeDefaults.labels.style = labelStyle;

  const labelModel = new YFiles.ExteriorLabelModel({ insets: 5 });
  graph.nodeDefaults.labels.layoutParameter = labelModel.createParameter(YFiles.ExteriorLabelModelPosition.SOUTH);
};

const configureDefaultEdgeStyle = (graph: YFiles.IGraph) => {
  graph.edgeDefaults.style = new YFiles.PolylineEdgeStyle({ targetArrow: YFiles.IArrow.DEFAULT });
};

const configureInputHandles = (inputMode: YFiles.GraphEditorInputMode) => {
  const { handleInputMode } = inputMode;
  handleInputMode.enabled = false;
};

const configureDragAndDrop = (inputMode: YFiles.GraphEditorInputMode) => {
  // Obtain the input mode for handling dropped nodes from the GraphEditorInputMode.
  const { nodeDropInputMode } = inputMode;
  // By default the mode available in GraphEditorInputMode is disabled, so first enable it.
  nodeDropInputMode.enabled = true;
  // When dragging the node within the GraphComponent, we want to show a preview of that node.
  nodeDropInputMode.showPreview = true;
};

export const createGraphComponent = () => {
  const graphComp = new YFiles.GraphComponent();

  const inputMode = new YFiles.GraphEditorInputMode({
    allowGroupingOperations: false,
    allowCreateNode: false,
    allowCreateEdge: false,
    allowGroupSelection: false,
    allowUngroupSelection: false,
    allowReparentNodes: false,
    allowEditLabel: false,
  });
  inputMode.clickHitTestOrder = [
    YFiles.GraphItemTypes.LABEL,
    YFiles.GraphItemTypes.BEND,
    // eslint-disable-next-line no-bitwise
    YFiles.GraphItemTypes.NODE | YFiles.GraphItemTypes.EDGE,
  ];
  configureInputHandles(inputMode);
  configureDragAndDrop(inputMode);

  graphComp.inputMode = inputMode;
  const { graph } = graphComp;
  graph.undoEngineEnabled = false;

  configureDefaultNodeStyle(graph);
  configureDefaultEdgeStyle(graph);

  return graphComp;
};

export const useAttackGraphComponent = () => {
  const [graphComponent] = React.useState<YFiles.GraphComponent>(() => createGraphComponent());
  return graphComponent;
};
