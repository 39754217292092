export const userKeys = {
  all: ["user"] as const,
  self: ["user", "self"] as const,
  org: (orgId: string | null) => [...userKeys.all, "org", orgId] as const,
  user: (userId: string | null) => [...userKeys.all, "user", userId] as const,
  role: (userId: string | null) => [...userKeys.all, "role", userId] as const,
  attachedOrg: (userId: string | null) => [...userKeys.all, "attachedOrg", userId] as const,
  assignedRole: (userId: string | null) => [...userKeys.all, "assignedRole", userId] as const,
  project: (userId: string | null) => [...userKeys.all, "project", userId] as const,
  relatedOrg: (userId: string | null) => [...userKeys.all, "relatedOrg", userId] as const,
  primaryOrg: (userId: string | null) => [...userKeys.all, "primaryOrg", userId] as const,
  projectsPermissions: (userId: string | null) => [...userKeys.all, "userProjects", userId] as const,
};
