import * as ReactQuery from "@tanstack/react-query";
import * as KeyFactory from "../hooks-query-key-factory";

const fetchCsvAsString = async (cweCsvUrl: string) => {
  const response = await fetch(cweCsvUrl);
  if (response.ok) {
    return response.text();
  }
  const error = new Error(response.statusText);
  return Promise.reject(error);
  // fetch(cweCsvUrl)
  //   .then((response) => response.text())
  //   .then((data) => console.log(data));

  // const response = await fetch(cweCsvUrl);
  // if (response.ok && response.body) {
  //   const reader = response.body.getReader();
  //   const decoder = new TextDecoder("utf-8");
  //   const result = await reader.read();
  //   return decoder.decode(result.value);
  // }
  // const error = new Error(response.statusText);
  // return Promise.reject(error);
};

const csvToArray = (data: string, delimiter = ",", omitFirstRow = true): string[][] => {
  return data
    .slice(omitFirstRow ? data.indexOf("\n") + 1 : 0)
    .split("\n")
    .map((v) => v.split(delimiter));
};

const getCsv = async (csvUrl: string) => {
  const csv = await fetchCsvAsString(csvUrl);
  return csv;
};

interface IUseKbCwes {
  csvUrl: string;
  select?: (data: string) => unknown;
}

export const useCsvFile = ({ csvUrl, select }: IUseKbCwes) => {
  return ReactQuery.useQuery({
    select,
    queryKey: KeyFactory.cweKeys.file(csvUrl),
    queryFn: () => getCsv(csvUrl),
  });
};

export const useCsvFileRows = ({ csvUrl }: IUseKbCwes) => {
  return useCsvFile({ csvUrl, select: (data: string) => csvToArray(data) });
};
