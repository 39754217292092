import { atom } from "recoil";

export const runIdState = atom({
  key: "runId",
  default: null,
});

export const runIdCanceledSubmissionState = atom({
  key: "runIdCanceledSubmission",
  default: null,
});

export const isSubmissionPendingState = atom({
  key: "isSubmissionPending",
  default: false,
});
