import * as YFiles from "yfiles";
// import * as React from "react";
import { useConstructor } from "hooks/useConstructor";

export const useNodeDecorator = (graphComp: YFiles.GraphComponent) => {
  useConstructor(() => {
    const style = new YFiles.ShapeNodeStyle();
    style.fill = YFiles.Fill.TRANSPARENT;
    style.stroke = YFiles.Stroke.BLUE;
    const nodeStyleDecorationInstaller = new YFiles.NodeStyleDecorationInstaller({
      nodeStyle: style,
    });
    const graphDecorator = graphComp.graph.decorator;
    graphDecorator.nodeDecorator.highlightDecorator.setImplementation(nodeStyleDecorationInstaller);
  });
};
