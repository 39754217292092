import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as globalThreatKeyFactory from "../threat/threatCatalog";

export interface IImportThreatCatalog {
  threatCatalogId: string;
  file: any;
}

/**
 * Import a ThreatCatalog
 *
 * @param {string} threatCatalogId - uuid of the threatCatalogId
 * @param {any} file - import context
 * @returns
 */
export const importThreatCatalog = async ({ threatCatalogId, file }: IImportThreatCatalog) => {
  return Brm.threatCatalogIoApi.threatCatalogImport(threatCatalogId, { file });
};

interface IUseThreatCatalog {
  options?: MutationOption<typeof importThreatCatalog>;
}

/**
 * Custom hook to import a threat catalog
 *
 *
 * @param {object} options - react-query options
 */
export const useImportThreatCatalog = ({ options }: IUseThreatCatalog = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: importThreatCatalog,
    onSuccess: () => {
      queryClient.invalidateQueries(globalThreatKeyFactory.threatCatalogKeys.all);
    },
  });
};
