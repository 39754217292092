import * as React from "react";
// Services
import { AdminApi, ProjectState, useProject } from "features/brm";
// Components
import Select from "react-select";
import toast from "react-hot-toast";
// Styles
import { customStyles } from "components/elements/ReactSelectCustomStyles";
import { useRoles } from "features/brm/hooks/useRoles";
import { isDeletedStatus, isInPrepStatus, isSuspendedStatus, isImportingStatus } from "utils/filter-utils";
import { toOptionFromProps } from "utils/react-select-utils";
import { sortByLabel } from "utils/sorting";
import * as log from "loglevel";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "routes/route-paths";

interface IOption {
  value: any;
  label: any;
}

export const ProjectSelector = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isSuperAdmin, isAdmin, isRiskAnalyst, userId } = useRoles();
  const [currentProject, { setCurrentProject }] = useProject();
  const [defaultProject, setDefaultProject] = React.useState<IOption | string>("");

  const projectId = currentProject?.id || "";

  const {
    data: userProjectOptionList,
    error: userProjectsError,
    refetch: refetchUserProjects,
  } = AdminApi.useUserProjects({
    userId,
    options: {
      enabled: !(isSuperAdmin || isAdmin || isRiskAnalyst),
      select: React.useCallback(
        (data: any) =>
          data
            ?.filter((item: any) => !(isDeletedStatus(item) || isSuspendedStatus(item) || isImportingStatus(item)))
            .map((item: any) => toOptionFromProps(item, "id", "name")),
        []
      ),
    },
  });

  const {
    data: riskAnalystProjectOptionList,
    error: riskAnalystProjectsError,
    refetch: refetchRiskAnalystProjects,
  } = AdminApi.useUserProjects({
    userId,
    options: {
      enabled: isRiskAnalyst,
      select: React.useCallback(
        (data: any) =>
          data
            ?.filter((item: any) => !(isDeletedStatus(item) || isSuspendedStatus(item) || isInPrepStatus(item)))
            .map((item: any) => toOptionFromProps(item, "id", "name")),
        []
      ),
    },
  });

  const { data: projectList } = AdminApi.useUserProjects({
    userId,
    options: {
      enabled: !(isSuperAdmin || isAdmin),
    },
  });

  const { hasProjectPermission: projectPermission } = AdminApi.useUserProjectPermission({
    userId,
    projectId,
    options: {
      enabled: !!userId && !!projectId,
    },
  });

  React.useEffect(() => {
    if (riskAnalystProjectsError) {
      toast.error(`Unable to load projects: ${riskAnalystProjectsError.message}`);
    }
  }, [riskAnalystProjectsError]);

  React.useEffect(() => {
    setDefaultProject(
      currentProject && projectPermission ? { value: currentProject.id, label: currentProject.name } : ""
    );
  }, [currentProject, projectPermission]);

  React.useEffect(() => {
    if (userProjectsError) {
      toast.error(`Error loading user projects: ${userProjectsError.message}`);
    }
  }, [userProjectsError]);

  const projectOptionList = React.useMemo(() => {
    if (isRiskAnalyst) {
      return riskAnalystProjectOptionList?.sort(sortByLabel);
    }
    return userProjectOptionList?.sort(sortByLabel);
  }, [isRiskAnalyst, riskAnalystProjectOptionList, userProjectOptionList]);

  const refetch = React.useCallback(() => {
    if (isRiskAnalyst) {
      refetchRiskAnalystProjects();
    } else {
      refetchUserProjects();
    }
  }, [isRiskAnalyst, refetchRiskAnalystProjects, refetchUserProjects]);

  const handleProjectSelectChange = React.useCallback(
    async (selectedItems: any = {}) => {
      const proj = projectList.find((p: any) => p.id === selectedItems.value);
      // convert projectshortdto to projectState
      const projState: ProjectState = {
        id: proj.id,
        name: proj.name,
        status: proj.status,
        isListed: proj.isListed,
        organization: {
          id: proj.organization.id,
          name: proj.organization.name,
          status: proj.organization.status,
        },
      };
      setCurrentProject(projState);
      log.debug("[ProjectSelector] Current Project set to: ", projState);

      if (isRiskAnalyst && location.pathname.startsWith(RoutePath.Projects)) {
        navigate(RoutePath.SystemDiagram);
      } else if (
        location.pathname.startsWith(RoutePath.ProjectOverview) ||
        location.pathname.startsWith(RoutePath.Projects)
      ) {
        navigate(`${RoutePath.ProjectOverview}${proj.id}`);
      }
    },
    [isRiskAnalyst, location.pathname, navigate, projectList, setCurrentProject]
  );

  return (
    <span title="Project Selection">
      <Select
        // isSearchable={false}
        name="project"
        menuPortalTarget={document.body}
        maxMenuHeight={300}
        id="ProjectDropdownSelection_projectDropdown"
        classNamePrefix="projectDropdown"
        isMulti={false}
        options={projectOptionList}
        value={projectId ? defaultProject : ""}
        onChange={handleProjectSelectChange}
        onBlur={() => {
          refetch();
        }}
        onFocus={() => {
          refetch();
        }}
        placeholder="Select Project..."
        styles={customStyles}
      />
    </span>
  );
};
