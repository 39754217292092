import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { KnowledgebaseProjectConfigurationTable } from "../../components/tables/KnowledgebaseProjectConfigurationTable";

export function KBProject() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const kbProjectHeader = <Title>Project Configuration</Title>;

  const kbProjectMain = (
    <KnowledgebaseProjectConfigurationTable
      setSelectedRows={setSelectedRows}
      refresh={refresh}
      setRefresh={setRefresh}
    />
  );

  return <ContentLayout pageId="KBProjectConfig_page" header={kbProjectHeader} main={kbProjectMain} />;
}
