// Styles
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";
import { ReportsTable } from "../../components/tables/Reports";

export const Reports = () => {
  const userHeader = <Title id="User_title">Reports</Title>;

  const userMain = <ReportsTable />;

  return <ContentLayout pageId="User_page" header={userHeader} main={userMain} />;
};
