// Report types (used for tab names and report names)
export const REPORT = {
  servicePath: "/brm/v1/reporting",
  reports: "Reports",
  control: "Control Report",
  controlGuidance: "Control Guidance Report",
  controlGuidance2: "Control Guidance Rpt - Experimental",
  cwe: "CWE Report",
  systemCweGuidance: "System CWE Guidance Report",
  factAnalysis: "Fact Analysis Report",
  factAnalysisInfoLevel: "Fact Analysis Information Level Report",
  factAnalysisIventory: "Fact Analysis Inventory Report",
};

// System Model Analytics
export const SYSTEM_MODEL_ANALYTICS = `systemModelAnalytics`;

// System Model Inventory
export const SYSTEM_MODEL_INVENTORY = `systemModelInventory`;

// Control Guidance Data
export const CONTROL_GUIDANCE_DATA = `controlGuidanceData`;

// Control Data
export const CONTROL_DATA = `controlData`;

// Exported Report File name suffixes
export const REPORT_SUFFIX = {
  control: "_CONTROL_RPT",
  controlGuidance: "_CONTROL_GUIDANCE_RPT",
  systemCweGuidance: "_SYSTEM_CWE_GUIDANCE_RPT",
  factAnalysis: "_FACT_ANALYSIS_RPT",
  factAnalysisInfoLevel: "_FACT_ANALYSIS_INFO_LEVEL_RPT",
  factAnalysisIventory: "_FACT_ANALYSIS_INVENTORY_RPT",
};

export const REPORT_LEVEL = {
  info: "info",
  warn: "warning",
  error: "error",
  missing: "none found", // always enabled
  structural: "inconsistent data",
  unused: "unused",
  count: "count",
  inventory: "inventory",
};

export const REPORT_STATUS = {
  complete: "complete",
  failed: "failed",
  done: "done",
  notDone: "not done",
};

export const REPORT_NAME_FOR_EXPORT = {
  variantDiff: "var-diff",
};
