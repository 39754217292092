import styled from "styled-components";

export const WizardTitle = styled.h5`
  text-align: center;
`;

export const SysmlFieldset = styled.fieldset`
  border: solid 1px #a9a9a9;
  border-radius: 5px;
  padding: 5px;
  padding-block-start: 0;
`;

export const SysmlLegend = styled.legend`
  float: none;
  width: auto;
  padding: 0px 5px;
  font-size: 16px;
`;

export const ConfigurationContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
  padding-left: 3px;
`;
