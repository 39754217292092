import { GraphBuilder } from "yfiles";

import DiagramBuilderHelper from "./DiagramBuilderHelper";
/**
 * Diagram Builder class enabled creation of simple graphs with listeners for node, groupNode and edge creation and updates
 */
export default class DiagramBuilder {
  /**
   * Construct a new instance of {@link DiagramBuilder}.
   *
   * @param {IGraph} graph
   */
  constructor(graph) {
    this.$diagramBuilderHelper = new DiagramBuilderHelper(
      this,
      graph,
      (grph, parent, location, labelData, nodeObject) => this.createNode(grph, parent, location, labelData, nodeObject),
      (grph, node, parent, location, labelData, nodeObject) =>
        this.updateNode(grph, node, parent, location, labelData, nodeObject),
      (grph, labelData, groupObject) => this.createGroupNode(grph, labelData, groupObject),
      (grph, groupNode, labelData, groupObject) => this.updateGroupNode(grph, groupNode, labelData, groupObject),
      (grph, source, target, labelData, edgeObject) => this.createEdge(grph, source, target, labelData, edgeObject),
      (grph, edge, labelData, edgeObject) => this.updateEdge(grph, edge, labelData, edgeObject)
    );

    this.$nodeData = null;
    this.$edgeData = null;
    this.$groupData = null;
    this.$edgeIdBinding = null;
    this.$sourceNodeBinding = null;
    this.$targetNodeBinding = null;

    this.$graphBuilder = new GraphBuilder(graph);
    this.$builderNodesSource = this.$graphBuilder.createNodesSource([], null);
    this.$builderNodesSource.nodeCreator = this.$diagramBuilderHelper.createNodeCreator();

    this.$builderGroupsSource = this.$graphBuilder.createGroupNodesSource([], null);
    this.$builderGroupsSource.nodeCreator = this.$diagramBuilderHelper.createGroupCreator();

    this.$builderEdgesSource = this.$graphBuilder.createEdgesSource(
      [],
      (dataItem) => this.$sourceIdProvider && this.$sourceIdProvider(dataItem),
      (dataItem) => this.$sourceIdProvider && this.$targetIdProvider(dataItem)
    );
    this.$builderEdgesSource.edgeCreator = this.$diagramBuilderHelper.createEdgeCreator();
  }

  /**
   * Populates the graph with items generated from the bound data.  The graph is cleared and new nodes,
   * groupNodes and edges are added as defined by the business data.
   * @returns {!IGraph} the created graph
   */
  buildGraph() {
    this.graph.clear();
    this.$initialize();
    return this.$graphBuilder.buildGraph();
  }

  /**
   * Updates the graph after changes in the bound data.  The graph is not cleared see {@link GraphBuilder.updateGraph}
   */
  updateGraph() {
    this.$initialize();
    this.$graphBuilder.updateGraph();
  }

  $initialize() {
    if (this.$nodeData === null) {
      throw new Error("nodeData must be set");
    }

    if (this.$edgesSource != null && (this.sourceNodeBinding == null || this.targetNodeBinding == null)) {
      throw new Error("Since edgesSource is set, sourceNodeBinding and targetNodeBinding must be set, too.");
    }

    this.$initializeProviders();
    this.$prepareData();
  }

  $initializeProviders() {
    this.$diagramBuilderHelper.initializeProviders();
    this.$builderNodesSource.idProvider = DiagramBuilderHelper.createIdProvider(this.nodeIdBinding);
    this.$builderGroupsSource.idProvider = DiagramBuilderHelper.createIdProvider(this.groupIdBinding);
    this.$builderEdgesSource.idProvider = DiagramBuilderHelper.createIdProvider(this.$edgeIdBinding);

    this.$builderEdgesSource.edgeCreator.tagProvider = (e) => e;

    this.$builderNodesSource.parentIdProvider = DiagramBuilderHelper.createBinding(this.groupBinding);
    this.$builderGroupsSource.parentIdProvider = DiagramBuilderHelper.createBinding(this.parentGroupBinding);

    this.$sourceIdProvider = DiagramBuilderHelper.createBinding(this.$sourceNodeBinding);
    this.$targetIdProvider = DiagramBuilderHelper.createBinding(this.$targetNodeBinding);
  }

  $prepareData() {
    this.$graphBuilder.setData(this.$builderNodesSource, this.$nodeData);
    this.$graphBuilder.setData(this.$builderGroupsSource, this.$groupData || []);
    this.$graphBuilder.setData(this.$builderEdgesSource, this.$edgeData || []);
  }

  /**
   *  Creates a node with the specifed properties.
   *
   * @param {!IGraph} graph the graph in which to create a node
   * @param {?INode} parent the nodes parent
   * @param {!Point} location the location of the node
   * @param {*} [labelData] optional label data of the node
   * @param {*} nodeObject the business object from which to create the node
   * @returns {!INode} the created node
   */
  createNode(graph, parent, location, labelData, nodeObject) {
    return this.$diagramBuilderHelper.createNode(graph, parent, location, labelData, nodeObject);
  }

  /**
   *  Createa group node with the specified properties.
   *
   * @param {!IGraph} graph the graph in which to create a group node
   * @param {*} [labelData] optional label data of the group node
   * @param {*} groupObject the business object from which to create the group
   * @returns {!INode} the created group node
   */
  createGroupNode(graph, labelData, groupObject) {
    return this.$diagramBuilderHelper.createGroupNode(graph, labelData, groupObject);
  }

  /**
   * Creates an edge with the specified source, target nodes and optional data.
   * @param {!IGraph} graph the graph in which to create the edge
   * @param {!INode} source the source node for the edge
   * @param {!INode} target the target node for the edge
   * @param {*} labelData optional label data
   * @param {*} edgeObject business objec from which to create the edge
   * @returns {!IEdge}
   */
  createEdge(graph, source, target, labelData, edgeObject) {
    return this.$diagramBuilderHelper.createEdge(graph, source, target, labelData, edgeObject);
  }

  /**
   * Updates the given node.
   *
   * @param {!IGraph} graph the graph in which to update the node
   * @param {!INode} node the node to be updated
   * @param {?INode} parent parent of the node
   * @param {!Point} location the node location
   * @param {*} [labelData] optional label data
   * @param {*} nodeObject the business object from which update the node
   */
  updateNode(graph, node, parent, location, labelData, nodeObject) {
    this.$diagramBuilderHelper.updateNode(graph, node, parent, location, labelData, nodeObject);
  }

  /**
   * Updates the existing group node.
   *
   * @param {!IGraph} graph the graph in which to update the node
   * @param {!INode} groupNode the groupNode to be updated
   * @param {*} [labelData] optional label data
   * @param {*} nodeObject the business object from which to update the node
   */
  updateGroupNode(graph, groupNode, labelData, groupObject) {
    this.$diagramBuilderHelper.updateGroupNode(graph, groupNode, labelData, groupObject);
  }

  /**
   * Updates an existing edge.
   *
   * @param {!IGraph} graph the graph in which to update the edge
   * @param {!IEdge} edge the existing edge
   * @param {*} [labelData] optional label data
   * @param {*} edgeObject the business object from which to update the edge
   */
  updateEdge(graph, edge, labelData, edgeObject) {
    this.$diagramBuilderHelper.updateEdge(graph, edge, labelData, edgeObject);
  }

  /**
   * Gets the object from which a given item as been created
   *
   * @param {!IModelItem} item the item to get the business object for.
   * @returns {*} the object from which the graph item as been created
   */
  getBusinessObject(item) {
    return this.$diagramBuilderHelper.getBusinessObject(item);
  }

  /**
   * Gets the edge associated with the given business object
   * @param {*} businessObject an object from the edgeData
   * @returns {?IEdge} edge associated with the businessObject or null
   */
  getEdge(businessObject) {
    return this.$diagramBuilderHelper.getEdge(businessObject);
  }

  /**
   * Gets the groupNode associated with the given business object
   * @param {*} groupObject an object from groupData
   * @returns {?INode} the group node assiciated with the businessObject or null
   */
  getGroup(groupObject) {
    return this.$diagramBuilderHelper.getGroup(groupObject);
  }

  /**
   * Gets the node associated with the given business object
   * @param {*} nodeObject an object from nodeData
   * @returns {?INode} the group node assiciated with the nodeObject or null
   */
  getNode(nodeObject) {
    return this.$diagramBuilderHelper.getNode(nodeObject);
  }

  /**
   * Gets the node associated with the given id
   * @param {*} id an id of a node
   * @returns {?INode} the node associated with the id
   */
  getNodeById(id) {
    return this.$graphBuilder.getNodeById(id);
  }

  /**
   * Gets the node associated with the given item
   * @param {*} item an item of a node
   * @returns {?INode} the node associated with the item
   */
  getNodeForItem(item) {
    return this.$graphBuilder.getNodeForItem(item);
  }

  /**
   * Gets the item associated with the given edge or node
   * @param {*} nodeOrEdge an node or edge
   * @returns {?DataItem} the node associated with the item
   */
  getDataItem(nodeOrEdge) {
    return this.$graphBuilder.getDataItem(nodeOrEdge);
  }

  /**
   * Gets the edge associated
   * @param {*} id an id of an edge
   * @returns {?IEdge} the edge associated with the id
   */
  getEdgeById(id) {
    return this.$graphBuilder.getEdgeById(id);
  }

  /**
   * Gets the edge associated with the item
   * @param {*} id an id of an edge
   * @returns {?IEdge} the edge associated with the id
   */
  getEdgeForItem(item) {
    return this.$graphBuilder.getEdgeForItem(item);
  }

  /**
   * Returns the graph used by this class
   *
   */
  get graph() {
    return this.$graphBuilder.graph;
  }

  /**
   * Gets the objects that represent nodes
   */
  get nodeData() {
    return this.$nodeData;
  }

  /**
   * Sets the objects that represent nodes
   *
   * @param {*} value
   */
  set nodeData(value) {
    this.$nodeData = value;
  }

  /**
   * Get the objects that represent edges
   */
  get edgeData() {
    return this.$edgeData;
  }

  /**
   * Set the objects that represent edges
   * @param {*} value
   */
  set edgeData(value) {
    this.$edgeData = value;
  }

  /**
   * Get the objects that represent groups
   */
  get groupData() {
    return this.$groupData;
  }

  /**
   * Set the objects that represent groups
   *
   * @param {*} value
   */
  set groupData(value) {
    this.$groupData = value;
  }

  /**
   * Get the binding that maps objects to their id.
   */
  get nodeIdBinding() {
    return this.$diagramBuilderHelper.nodeIdBinding;
  }

  /**
   * Set the binding that maps node objects to their id. The binding can either be a plain JavaScript function,
   * a String, <code>null</code>, or an array which contains the same types recursively. A function is called with the
   * business object to convert as first and only parameter, and the function's <code>this</code>
   *
   * @param {*} value
   */
  set nodeIdBinding(value) {
    this.$diagramBuilderHelper.nodeIdBinding = value;
  }

  /**
   *
   */
  get nodeLabelBinding() {
    return this.$diagramBuilderHelper.nodeLabelBinding;
  }

  set nodeLabelBinding(value) {
    this.$diagramBuilderHelper.nodeLabelBinding = value;
  }

  get groupBinding() {
    return this.$diagramBuilderHelper.groupBinding;
  }

  set groupBinding(value) {
    this.$diagramBuilderHelper.groupBinding = value;
  }

  get parentGroupBinding() {
    return this.$diagramBuilderHelper.parentGroupBinding;
  }

  set parentGroupBinding(value) {
    this.$diagramBuilderHelper.parentGroupBinding = value;
  }

  /**
   * Set the binding that maps group node objects to their id. The binding can either be a plain JavaScript function,
   * a String, <code>null</code>, or an array which contains the same types recursively. A function is called with the
   * business object to convert as first and only parameter, and the function's <code>this</code>
   *
   * @param {*} value
   */
  get groupIdBinding() {
    return this.$diagramBuilderHelper.groupIdBinding;
  }

  /**
   * Set the binding that maps group node objects to their id.
   */
  set groupIdBinding(value) {
    this.$diagramBuilderHelper.groupIdBinding = value;
  }

  /**
   * Get the binding that maps group objects to their label
   */
  get groupLabelBinding() {
    return this.$diagramBuilderHelper.groupLabelBinding;
  }

  /**
   * Set the binding that maps group objects to their label
   */
  set groupLabelBinding(value) {
    this.$diagramBuilderHelper.groupLabelBinding = value;
  }

  /**
   * Get the binding that maps edge objects to their id.
   */
  get edgeIdBinding() {
    return this.$diagramBuilderHelper.$edgeIdBinding;
  }

  /**
   * Set the binding that maps edgeobjects to their id. The binding can either be a plain JavaScript function,
   * a String, <code>null</code>, or an array which contains the same types recursively. A function is called with the
   * business object to convert as first and only parameter, and the function's <code>this</code>
   *
   * @param {*} value
   */
  set edgeIdBinding(value) {
    this.$diagramBuilderHelper.$edgeIdBinding = value;
  }

  /**
   * Get the binding that maps an edge object to a label.
   */
  get edgeLabelBinding() {
    return this.$diagramBuilderHelper.edgeLabelBinding;
  }

  /**
   * Get the binding that maps an edge object to a label.
   *
   * @param {*} value
   */
  set edgeLabelBinding(value) {
    this.$diagramBuilderHelper.edgeLabelBinding = value;
  }

  /**
   * Set the binding that maps edge objects to their source node.
   */
  get sourceNodeBinding() {
    return this.$sourceNodeBinding;
  }

  /**
   * Sets the binding that maps edge objects to their source node.
   *
   * @param {*}
   */
  set sourceNodeBinding(value) {
    this.$sourceNodeBinding = value;
  }

  /**
   * Get the binding that maps edge objects to their target node.
   */
  get targetNodeBinding() {
    return this.$targetNodeBinding;
  }

  /**
   * Sets the binding that maps edge objects to their target node.
   * @param {*}
   */
  set targetNodeBinding(value) {
    this.$targetNodeBinding = value;
  }

  /**
   * Set the binding for setting a nodes position on the x-axis.
   */
  get locationXBinding() {
    return this.$diagramBuilderHelper.locationXBinding;
  }

  /**
   * Set the the location binding for setting a nodes position on the x-axis.
   */
  set locationXBinding(value) {
    this.$diagramBuilderHelper.locationXBinding = value;
  }

  /**
   * Get the location binding for setting a nodes position on the y-axis.
   */
  get locationYBinding() {
    return this.$diagramBuilderHelper.locationYBinding;
  }

  /**
   * Set the location binding for setting a nodes position on the y-axis.
   */
  set locationYBinding(value) {
    this.$diagramBuilderHelper.locationYBinding = value;
  }

  /**
   * Add the given listner from the NodeCreates event that occurs when an node has been created.
   * @param {DiagramNodeListener} listener
   */
  addNodeCreatedListener(listener) {
    this.$diagramBuilderHelper.addNodeCreatedListener(listener);
  }

  /**
   * Remove the given listner from the NodeCreated event that occurs when an node has been created.
   * @param {DiagramNodeListener} listener
   */

  removeNodeCreatedListener(listener) {
    this.$diagramBuilderHelper.removeNodeCreatedListener(listener);
  }

  /**
   * Remove the given listner from the GroupNodeCreated event that occurs when an groupNode has been created.
   * @param {DiagramNodeListener} listener
   */
  addGroupNodeCreatedListener(listener) {
    this.$diagramBuilderHelper.addGroupNodeCreatedListener(listener);
  }

  /**
   * Remove the given listner from the GroupNodeCreated event that occurs when an edge has been created.
   * @param {DiagramNodeListener} listener
   */

  removeGroupNodeCreatedListener(listener) {
    this.$diagramBuilderHelper.removeGroupNodeCreatedListener(listener);
  }

  /**
   * Add the given listner from the EdgeCreated event that occurs when an edge has been created.
   * @param {DiagramNodeListener} listener
   */

  addEdgeCreatedListener(listener) {
    this.$diagramBuilderHelper.addEdgeCreatedListener(listener);
  }

  /**
   * Remove the given listner from the EdgeCreated event that occurs when an edge has been created.
   * @param {DiagramNodeListener} listener
   */
  removeEdgeCreatedListener(listener) {
    this.$diagramBuilderHelper.removeEdgeCreatedListener(listener);
  }

  /**
   * Add the given listener for the NodeUpdated event that occurs when an node has been updated.
   *
   * @param {DiagramNodeListener} listener
   */
  addNodeUpdatedListener(listener) {
    this.$diagramBuilderHelper.addNodeUpdatedListener(listener);
  }

  /**
   * Remove the given listener for the NodeUpdated event that occurs when an node has been updated.
   *
   * @param {DiagramNodeListener} listener
   */
  removeNodeUpdatedListener(listener) {
    this.$diagramBuilderHelper.removeNodeUpdatedListener(listener);
  }

  /**
   * Add the given listener for the GroupNodeUpdated event that occurs when an group has been updated.
   *
   * @param {DiagramGroupNodeListener} listener
   */
  addGroupNodeUpdatedListener(listener) {
    this.$diagramBuilderHelper.addGroupNodeUpdatedListener(listener);
  }

  /**
   * Remove the given listener for the GroupNodeUpdated event that occurs when an group has been updated.
   *
   * @param {DiagramGroupNodeListener} listener
   */
  removeGroupNodeUpdatedListener(listener) {
    this.$diagramBuilderHelper.removeGroupNodeUpdatedListener(listener);
  }

  /**
   * Add the given listener for the EdgeUpdated event that occurs when an edge has been updated.
   *
   * @param {DiagramEdgeListener} listener
   */
  addEdgeUpdatedListener(listener) {
    this.$diagramBuilderHelper.addEdgeUpdatedListener(listener);
  }

  /**
   * Remove the given listener for the EdgeUpdated event that occurs when an edge has been updated.
   *
   * @param {DiagramEdgeListener} listener
   */
  removeEdgeUpdatedListener(listener) {
    this.$diagramBuilderHelper.removeEdgeUpdatedListener(listener);
  }
}
