import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { SystemApi } from "features/brm";

export const setCategorizedEntityClassification = ({ id, classification }) => {
  return Brm.categorizedEntityApi.setCategorizedEntityClassification(id, { body: classification });
};

/**
 * Custom hook to change the classification of a existing categorized entity
 * @returns react-query ReactQuery.useMutation
 */
export const useSetCategorizedEntityClassification = (projectId) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation(
    ({ id, classification }) => setCategorizedEntityClassification({ id, classification }),
    {
      onMutate: async ({ id, classification }) => {
        await queryClient.cancelQueries(SystemApi.categorizedEntityKeys.project(projectId));
        const previousValues = queryClient.getQueryData(SystemApi.categorizedEntityKeys.project(projectId));
        queryClient.setQueryData(
          SystemApi.categorizedEntityKeys.project(projectId),
          (old) =>
            old?.map((data) => {
              if (id === data.id) {
                return {
                  ...data,
                  classification,
                };
              }
              return data;
            }) || []
        );
        return previousValues;
      },
      onError: (previousValues) =>
        queryClient.setQueryData(SystemApi.categorizedEntityKeys.project(projectId), previousValues),
      onSuccess: (data, params) => {
        queryClient.invalidateQueries(SystemApi.categorizedEntityKeys.project(projectId));
        queryClient.invalidateQueries(SystemApi.categorizedEntityKeys.categorizedEntity(params.id));
      },
    }
  );
};
