import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./activity-query-key-factory";

interface ISetActivityIsInternalProps {
  activityId: string;
  isInternal: string;
}

/**
 * Sets isInternal attribute of an activity
 *
 * @param {string} activityId - uuid of the activity
 * @param {string} isInternal - isIntenal value of the activity
 */
export const setActivityIsInternal = ({ activityId, isInternal }: ISetActivityIsInternalProps) => {
  return Brm.activityApi.setActivityIsInternal(activityId, { body: isInternal });
};

export interface IUseSetActivityIsInternal {
  options?: MutationOption<typeof setActivityIsInternal>;
}

/**
 * Custom hook to change the isInternal value of an existing activity
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetActivityIsInternal = ({ options }: IUseSetActivityIsInternal) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.activitiesKeys.all);
    },
    ...options,
    mutationFn: setActivityIsInternal,
  });
};
