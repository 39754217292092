import { useRecoilValue } from "recoil";

// Components
import { useRoles } from "features/brm/hooks/useRoles";
import { contentKeyState } from "atoms";
import { currentProjectState } from "../../stores";

export const useShowProjectText = () => {
  const currentProject = useRecoilValue(currentProjectState);
  const { isRiskAnalyst, isThreatAnalyst, isSystemEngineer } = useRoles();
  const contentKey = useRecoilValue(contentKeyState);
  const isHome = contentKey === "home";

  return (isRiskAnalyst || isSystemEngineer || isThreatAnalyst) && !currentProject?.id && isHome;
};
