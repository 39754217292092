import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

interface ICreateNode {
  projectId: string;
  nodeCreateDto: any;
}

/**
 * Creates a node.
 * @param {string} projectId uuid of the project
 * @param {NodeCreateDto} nodeCreateDto a NodeCreateDto object
 * @returns id of created node
 */
export const createNode = ({ projectId, nodeCreateDto }: ICreateNode) => {
  return Brm.nodeApi.createNode(projectId, { nodeCreateDto });
};

export interface IUseCreateNode {
  options?: MutationOption<typeof createNode>;
}

/**
 * Custom hook to create a new.
 *
 * @param {object} config - react-query configuration object
 * @returns react-query for getNodeByID
 */
export const useCreateNode = ({ options }: IUseCreateNode = {}) => {
  return ReactQuery.useMutation({
    onSuccess: (data, { projectId }) => {
      queryClient.invalidateQueries(KeyFactory.nodeKeys.project(projectId));
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.project(projectId));
    },
    ...options,
    mutationFn: createNode,
  });
};
