import PropTypes from "prop-types";
import { useState, useMemo, useCallback } from "react";
import toast from "react-hot-toast";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import GenericModal from "components/modals/GenericModal";
import SelectBox from "components/forms/DynamicSelectBox";
import { AlertDialog } from "components/modals";

// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
// Utils
import { keyToName } from "services/user";
import { createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
import useToggle from "hooks/useToggle";
// Services
import { userRoles } from "services/brm/admin-service";
// State
import { userIdState, userRoleState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
// Styles
import * as S from "brm/styles/details-table.styles";
// Local
import { AdminApi, AddButton, DeleteButton } from "features/brm";
import { LoadingSpinner as Loading } from "components/elements";
import { AddAssignedRoleForm } from "../../forms/AddAssignedRoleForm";

const unusedRoles = [
  userRoles.properties[userRoles.EVIDENCE_SERVER_COORDINATOR],
  userRoles.properties[userRoles.SW_DEVELOPER],
  userRoles.properties[userRoles.BOR_PROJECT_COORDINATOR],
  userRoles.properties[userRoles.TECH_ADMIN],
  userRoles.properties[userRoles.BUILD_ENGINEER],
  userRoles.properties[userRoles.AUTH_OFFICER],
  userRoles.properties[userRoles.RMF_COORDINATOR],
  userRoles.properties[userRoles.EXECUTIVE],
  userRoles.properties[userRoles.SCA],
];

export const AssignedRoleDetailsTable = ({ selectedElement, showAdd, showDelete }) => {
  const { showAlert, toggleAlert } = useToggle();
  const columns = useMemo(() => [createColumnMappedNoEdit("name")], []);

  const adminRoleKey = userRoles.properties[userRoles.ADMIN].key;
  const superAdminRoleKey = userRoles.properties[userRoles.SUPER_ADMIN].key;
  const userRole = useRecoilValue(userRoleState);
  const userId = useRecoilValue(userIdState);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAssignedRole, setSelectedAssignedRole] = useState();
  // Modals
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);

  const { data: defaultRole } = AdminApi.useUserRole({ userId: selectedElement.id });

  const { mutateAsync: setUserAssignedRole } = AdminApi.useSetUserAssignedRole();
  const { mutateAsync: setUserRole } = AdminApi.useSetUserRole();
  const { mutateAsync: removeUserAssignedRole } = AdminApi.useRemoveUserAssignedRole();

  const { data: assignedRoleData } = AdminApi.useUserAssignedRoles({
    userId: selectedElement.id,
    options: {
      select: useCallback((data) => {
        return data
          .map((item) => {
            return { id: item, name: keyToName[item] };
          })
          .filter((i) => !unusedRoles.some((j) => j.key === i.id));
      }, []),
    },
  });

  const addAssignedRole = () => {
    setModalIsOpen(true);
  };

  function checkForDeleteRoleValidity() {
    // if selectedElement.role is super admin/ admin thay cannot delete the assigned role of "superAdmin" to themselves
    if (selectedElement.id === userId) {
      if (userRole === superAdminRoleKey && selectedAssignedRole.id === superAdminRoleKey) {
        return false;
      }
      if (
        userRole === adminRoleKey &&
        (selectedAssignedRole.id === superAdminRoleKey || selectedAssignedRole.id === adminRoleKey)
      ) {
        return false;
      }
      return true;
    }
    if (userRole === adminRoleKey && selectedAssignedRole.id === superAdminRoleKey) {
      return false;
    }
    return true;
  }

  async function handleDelete() {
    if (selectedRows.length !== 0) {
      await Promise.all(
        selectedRows.map((each) => {
          try {
            return removeUserAssignedRole({ userId: selectedElement.id, roleId: each.id });
          } catch {
            toast.error(`Unable to delete assigned role ${each.name}`);
            return null;
          }
        })
      );
      setSelectedRows([]);
      setSelectedAssignedRole("");
    } else if (selectedAssignedRole) {
      try {
        await setUserAssignedRole({ userId: selectedElement.id, assignedRoleData, deletedRole: selectedAssignedRole });
        setSelectedAssignedRole("");
      } catch {
        toast.error(<p>Error assigning roles</p>, {});
      }
    }
  }

  async function handleConfirmation(e) {
    e.preventDefault();
    if (e.target.elements.role.value !== "Select role") {
      await setUserRole({ userId: selectedElement.id, role: e.target.elements.role.value });
      await handleDelete();
      setAlertModalIsOpen(false);
    }
  }

  const deleteAssignedRole = async () => {
    const isValid = checkForDeleteRoleValidity();
    if (isValid) {
      if (selectedAssignedRole.id === defaultRole.result) {
        setAlertModalIsOpen(true);
      } else {
        await handleDelete();
      }
    } else {
      toggleAlert();
      setSelectedAssignedRole("");
    }
  };

  if (defaultRole && assignedRoleData) {
    return (
      <S.DetailsContainer id="AssignedRoleTable_detailsPanel">
        <AlertDialog show={showAlert} onClose={toggleAlert} title="Error" message="Cannot delete the selected Role!" />

        {/* modal for adding instance */}
        {modalIsOpen &&
          systemModals.customModal(
            "AssignedRoleTable_detailsPanel",
            modalIsOpen,
            setModalIsOpen,
            <AddAssignedRoleForm
              setModalIsOpen={setModalIsOpen}
              selectedElement={selectedElement}
              userRole={userRole}
              assignedRoleData={assignedRoleData}
              setSelectedAssignedRole={setSelectedAssignedRole}
            />,
            "Assigned Role"
          )}

        {alertModalIsOpen && (
          <GenericModal
            elementId="AssignedRoleTable_detailsPanel"
            modalIsOpen={alertModalIsOpen}
            setModalIsOpen={setAlertModalIsOpen}
            headerText="User Alert"
            bodyText={
              <div>
                <p>
                  You are deleting the default role for this user. Please select another role as default before
                  proceeding?
                </p>
                <form onSubmit={handleConfirmation} action="" id="AssignedRoleTable_form">
                  <SelectBox arrayOfData={assignedRoleData.filter((e) => e.id !== defaultRole)} item="role" />
                  <DialogButtonDivStyled id="AssignedRoleAddForm_buttons">
                    <DialogButtonStyled onClick={() => setAlertModalIsOpen(false)} id="WorkerAddForm_cancelButton">
                      Cancel
                    </DialogButtonStyled>
                    <DialogButtonStyled type="submit" id="AssignedRoleAddForm_addButton">
                      Confirm
                    </DialogButtonStyled>
                  </DialogButtonDivStyled>
                </form>
              </div>
            }
          />
        )}
        <S.ActionContainer>
          <S.DetailsTableContainer id="AssignedRoleTable_table">
            <BrmDetailTable
              data={assignedRoleData}
              columns={columns}
              setSelectedElement={setSelectedAssignedRole}
              setSelectedRows={setSelectedRows}
              customProps={{ id: "AssignedRoleTable_detailsTable" }}
              showRowSelect={showDelete}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer id="AssignedRoleTable_buttons">
            {showAdd && <AddButton md onClick={addAssignedRole} />}
            {showDelete && selectedRows.length > 0 ? <DeleteButton md onClick={deleteAssignedRole} /> : null}
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

AssignedRoleDetailsTable.propTypes = {
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  showAdd: PropTypes.any,
  showDelete: PropTypes.any,
};
