import * as React from "react";
import * as Recoil from "recoil";
import * as ReactQuery from "@tanstack/react-query";

import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { AddButton, DeleteButton } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { controlCatalogApi } from "services/brm/global-control-service";
import { selectedInstanceState } from "atoms/atoms-content";
import { TYPE } from "constants/brm";
import ContentLayout from "brm/layout/Content/ContentLayout";
import { ImportControlCatalogFileModal, ExportControlCatalogFileModal } from "features/importer";
import { ControlCatalogTable, AddControlCatalogForm } from "../../components";

export function ControlCatalogs() {
  const [selectedInstance, setSelectedInstance] = Recoil.useRecoilState(selectedInstanceState);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [uploadModalIsOpen, setUploadModalIsOpen] = React.useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const queryClient = ReactQuery.useQueryClient();

  const addControlCatalog = () => {
    setModalIsOpen(true);
  };

  React.useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      await Promise.all(
        selectedRows
          .filter((e: any) => e.isDefault !== true)
          .map((each: any) => controlCatalogApi.deleteControlCatalogWithHttpInfo(each.id))
      );
      queryClient.invalidateQueries(["controlCatalogs"]);
      setDeleteModalIsOpen(false);
      setSelectedInstance({});
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, queryClient, selectedRows, setSelectedInstance]);

  const header = (
    <Title>
      Control Catalogs
      <Buttons>
        {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
        <AddButton onClick={addControlCatalog} />
      </Buttons>
    </Title>
  );

  const main = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "ControlCatalog_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.controlCatalog}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.controlCatalog
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "ControlCatalog_page",
          modalIsOpen,
          setModalIsOpen,
          <AddControlCatalogForm setModalIsOpen={setModalIsOpen} />,
          TYPE.controlCatalog
        )}

      <ImportControlCatalogFileModal
        show={uploadModalIsOpen}
        controlCatalogId={selectedInstance.id}
        onHide={() => {
          setUploadModalIsOpen(false);
        }}
      />
      <ExportControlCatalogFileModal
        show={isExportModalOpen}
        ccId={selectedInstance.id}
        ccName={selectedInstance.name}
        onHide={() => {
          setIsExportModalOpen(false);
        }}
      />

      <ControlCatalogTable
        setSelectedRows={setSelectedRows}
        setUploadModalIsOpen={setUploadModalIsOpen}
        setIsExportModalOpen={setIsExportModalOpen}
      />
    </>
  );

  return <ContentLayout pageId="ControlCatalog_page" header={header} main={main} />;
}
