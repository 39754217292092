import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * Creates a mission.
 * @param {string} projectId uuid of the project
 * @param {MissionCreateDto} missionCreateDto a MissionCreateDto object
 * @returns id of created mission
 */
export const createMission = ({ projectId, missionCreateDto }) => {
  return Brm.missionApi.createMission(projectId, { missionCreateDto });
};

/**
 * Custom hook to create a new mission.
 *
 * @param {object} config - react-query configuration object
 * @returns react-query for getNodeByID
 */
export const useCreateMission = ({ config = {} } = {}) => {
  return ReactQuery.useMutation({
    ...config,
    mutationFn: createMission,
  });
};
