import { TYPE } from "constants/brm";
import AttackGraph from "./AttackGraph";
import NodeData from "./NodeData";
import * as SetUtil from "./set-utilities";

const ATTACKS_ENABLED = true;

/**
 * AttackGraphEngine contains an attack graph and provides functions
 * to manipulate nodes based on business logic.
 *
 *
 * To make my brain hurt less, 'TOP' is considered AssetCategory
 * and the BOTTOM is Attacks.
 *
 * The heirarchy is
 *
 * Asset Category
 * Asset
 * Risk
 * Undesired Event
 * Targets
 * Threat Event
 * Attack
 *
 * Elements can be added from anywhere in the heirarchy so the Engine has to
 * determine which elements to add by going up or down or both from the added
 * element.
 *
 * Elements lower than risk can belong to more than single risk so to simplify
 * the diagram we combine them but track which risk caused them to be added
 * in order to delete the node when it is no longer referenced.
 *
 */
export default class AttackGraphEngine {
  /**
   * Construct an Directed AttackGraph with a default id mapping of i => i.id
   */
  constructor({ targets }) {
    this.$attackGraph = new AttackGraph(AttackGraph.DIRECTED, (i) => i.id);
    this.$targets = targets;
  }

  /**
   * Removes all nodes from the AttackGraph
   */
  clear() {
    this.$attackGraph.clear();
  }

  /**
   * Adds all nodes that are in an asset query.  This is the most simple add
   * just start at the top (assetCategory) and add everything on the way down.
   * Nodes added lower than risk require the risk context they are added in.
   *
   * @param {any} assetCategoryTree graphQL query result
   */
  addAssetCategoryTree(assetCategoryTree) {
    const { name, asset: assets } = assetCategoryTree;
    const assetCatData = this.$createAssetCategory({ name });
    assets.forEach((asset) => {
      const assetData = this.$createAsset(asset);
      this.$attackGraph.addEdge(assetCatData, assetData);
      const { risk: risks } = asset;
      risks.forEach((risk) => {
        const riskData = this.$addRiskAndDown(risk);
        this.$attackGraph.addEdge(assetData, riskData);
      });
    });
  }

  $isTargetInScope(target) {
    return this.$targets ? this.$targets.some((t) => t === target) : true;
  }

  /**
   * Add all nodes that are related to asset on the way DOWN.  Assets know
   * their category so that is added by going UP.
   *
   * @param {any} assetTree GraphQL query result
   */
  addAssetTree(assetTree) {
    const { category: assetCat } = assetTree;
    const assetCatData = this.$createAssetCategory(assetCat);
    const assetData = this.$createAsset(assetTree);
    this.$attackGraph.addEdge(assetCatData, assetData);
    const { risk: risks } = assetTree;
    risks.forEach((risk) => {
      const riskData = this.$addRiskAndDown(risk);
      this.$attackGraph.addEdge(assetData, riskData);
    });
  }

  /**
   * Adds all nodes that are related to a risk on the way DOWN, Assets and
   * Categories on the way UP...
   *
   * @param {*} risk
   * @param {*} riskNodeData KLUDGE to get the category...
   */
  addRiskTree(riskTree) {
    // console.log(`riskTree`, riskTree); // leaving until data is fixed
    const { asset } = riskTree;
    const { category: assetCategory } = asset;
    const assetCatData = this.$createAssetCategory(assetCategory);
    const assetData = this.$createAsset(asset);
    this.$attackGraph.addEdge(assetCatData, assetData);

    const riskData = this.$addRiskAndDown(riskTree);
    this.$attackGraph.addEdge(assetData, riskData);
  }

  addUndesiredEventTree(undesiredEventTree) {
    // console.log(`undesiredEventTree`, undesiredEventTree); // leaving until data is fixed
    const { id, name, category, target, trev: trevs } = undesiredEventTree;
    const { risk: risks } = undesiredEventTree;
    risks.forEach((risk) => {
      const riskData = this.$createRisk(risk, risk.category);
      const { asset } = risk;
      const { category: assetCategory } = asset;
      const assetCatData = this.$createAssetCategory(assetCategory);
      const assetData = this.$createAsset(asset);
      this.$attackGraph.addEdge(assetCatData, assetData);
      this.$attackGraph.addEdge(assetData, riskData);
      const ueData = this.$createUndesiredEvent({ id, name, category }, risk.id);
      this.createEdge(riskData, ueData);

      const targetData = this.$createTarget(target, risk.id);
      this.createEdge(ueData, targetData);
      trevs.forEach((trev) => {
        const trevData = this.$createThreatEvent(trev, risk.id);
        this.createEdge(targetData, trevData);
        if (ATTACKS_ENABLED) {
          const { attack: attacks } = trev;
          attacks.forEach((a) => {
            const attackData = this.$createAttack(a, risk.id);
            this.createEdge(trevData, attackData);
          });
        }
      });
    });
  }

  addTargetTree(targetTree) {
    // console.log(`targetTree`, targetTree); // leaving until data is fixed
    const { ue: ues } = targetTree;
    ues.forEach((ue) => {
      const { risk: risks } = ue;
      risks.forEach((risk) => {
        const riskData = this.$createRisk(risk, risk.category);
        const assetData = this.$createAsset(risk.asset);
        const assetCatData = this.$createAssetCategory(risk.asset.category);
        this.createEdge(assetCatData, assetData);
        this.createEdge(assetData, riskData);
        const ueData = this.$createUndesiredEvent(ue, risk.id);
        this.createEdge(riskData, ueData);
        const targetData = this.$createTarget(targetTree, risk.id);
        this.createEdge(ueData, targetData);

        const { trev: threatEvents } = targetTree;
        threatEvents.forEach((trev) => {
          const threatEventData = this.$createThreatEvent(trev, risk.id);
          if (ATTACKS_ENABLED) {
            const { attack: attacks } = trev;
            attacks.forEach((attack) => {
              // let attackData = null;
              // if (this.$attackGraph.hasNode(attack)) {
              //   attackData = this.$attackGraph.getNode(target);
              //   attackData.addContext(risk.id);
              // } else {
              // }

              const attackData = this.$createAttack(attack, risk.id);
              this.createEdge(threatEventData, attackData);
            });
          }
          this.createEdge(targetTree, trev);
        });
      });
    });
  }

  /**
   *
   * @param {any} threatEventTree graphql results for a threatEvent
   */
  addThreatEventTree(threatEventTree) {
    // console.log(`threatEventTree`, threatEventTree); // leaving until data is fixed
    const { target, ue: ues } = threatEventTree;

    // const targetData = this.$createThreatEvent(target, risk.id);

    ues.forEach((ue) => {
      const { risk: risks } = ue;
      risks.forEach((risk) => {
        const riskData = this.$createRisk(risk, risk.category);
        const assetData = this.$createAsset(risk.asset);
        const assetCatData = this.$createAssetCategory(risk.asset.category);
        this.createEdge(assetCatData, assetData);
        this.createEdge(assetData, riskData);
        const ueData = this.$createUndesiredEvent(ue, risk.id);
        this.createEdge(riskData, ueData);
        // let targetData = null;
        // if (this.$attackGraph.hasNode(target)) {
        //   targetData = this.$attackGraph.getNode(target);
        //   targetData.addContext(risk.id);
        // } else {
        const targetData = this.$createTarget(target, risk.id);
        // }
        this.createEdge(ueData, targetData);

        // const  threatEventData = null;
        // if (this.$attackGraph.hasNode(threatEventTree)) {
        //   threatEventData = this.$attackGraph.getNode(threatEventTree);
        //   threatEventData.addContext(risk.id);
        // } else {
        //   threatEventData =
        const threatEventData = this.$createThreatEvent(threatEventTree, risk.id);
        //        }
        this.createEdge(targetData, threatEventData);
        if (ATTACKS_ENABLED) {
          const { attack: attacks } = threatEventTree;
          attacks.forEach((attack) => {
            // let attackData = null;
            // if (this.$attackGraph.hasNode(attack)) {
            //   attackData = this.$attackGraph.getNode(target);
            //   attackData.addContext(risk.id);
            // } else {
            const attackData = this.$createAttack(attack, risk.id);
            // }
            this.createEdge(threatEventData, attackData);
          });
        }
      });
    });
    // const targetData = this.$attackGraph.getNode(target);
  }

  addAttackTree(attackTree) {
    // console.log(`AttackTree`, attackTree); // leaving until data is fixed
    const { trev } = attackTree;
    const { target, ue: ues } = trev;

    ues.forEach((ue) => {
      const { risk: risks } = ue;
      risks.forEach((risk) => {
        const riskData = this.$createRisk(risk, risk.category);
        const assetData = this.$createAsset(risk.asset);
        const assetCatData = this.$createAssetCategory(risk.asset.category);
        this.$attackGraph.addEdge(assetCatData, assetData);
        this.$attackGraph.addEdge(assetData, riskData);
        const ueData = this.$createUndesiredEvent(ue, risk.id);
        this.createEdge(riskData, ueData);
        const targetData = this.$createTarget(target, risk.id);
        this.createEdge(ueData, targetData);
        const trevData = this.$createThreatEvent(trev, risk.id);
        this.createEdge(targetData, trevData);
        if (ATTACKS_ENABLED) {
          const attackData = this.$createAttack(attackTree, risk.id);
          this.createEdge(trevData, attackData);
        }
      });
    });
  }

  $addRiskAndDown(risk) {
    const riskData = this.$createRisk(risk, risk.category);

    risk.ue.forEach((ue) => {
      const { target } = ue;
      // only need target scoping on
      if (this.$isTargetInScope(target.id)) {
        const ueData = this.$createUndesiredEvent(ue, risk.id);
        this.createEdge(riskData, ueData);

        const targetData = this.$createTarget(target, risk.id);
        this.createEdge(ueData, targetData);

        const { trev: trevs } = ue;
        trevs.forEach((t) => {
          const trevData = this.$createThreatEvent(t, risk.id);
          this.createEdge(targetData, trevData);

          if (ATTACKS_ENABLED) {
            const { attack: attacks } = t;
            attacks.forEach((a) => {
              const attackData = this.$createAttack(a, risk.id);
              this.createEdge(trevData, attackData);
            });
          }
        });
      }
    });
    return riskData;
  }

  $createAssetCategory(assetCat) {
    // Keeping id and making cat id same as it's name
    const assetCatData = this.createNode(assetCat.name, assetCat.name, TYPE.assetCat);
    return assetCatData;
  }

  $createAsset(asset) {
    const assetData = this.createNode(asset.id, asset.name, TYPE.asset, asset.category.name);
    return assetData;
  }

  $createUndesiredEvent(ue, riskContextId) {
    const ueData = this.createContextNode(ue.id, ue.name, TYPE.ue, riskContextId, ue.category);
    return ueData;
  }

  $createRisk(risk, riskCategory) {
    const riskData = this.createRiskNode(risk.id, risk.name, risk.rank.value, riskCategory);
    return riskData;
  }

  $createTarget(target, riskContextId) {
    const targetType = target.noun === "exchange" ? TYPE.exchange : TYPE.node;
    const targetData = this.createContextNode(target.id, target.name, targetType, riskContextId);
    return targetData;
  }

  $createThreatEvent(threatEvent, riskContextId) {
    const trevData = this.createContextNode(
      threatEvent.id,
      threatEvent.name,
      TYPE.trev,
      riskContextId,
      threatEvent.category
    );
    return trevData;
  }

  $createAttack(attack, riskContextId) {
    const attackData = this.createContextNode(attack.id, attack.name, TYPE.attack, riskContextId, null, attack.tactic);
    return attackData;
  }

  createNode(id, name, type, category, tactic = null) {
    const data = new NodeData({ id, name, type, category, tactic });
    const node = this.$attackGraph.addNode(data);
    return node.value;
  }

  createRiskNode(id, name, rank, category) {
    const data = new NodeData({ id, name, type: TYPE.risk, rank, category });
    const node = this.$attackGraph.addNode(data);
    return node.value;
  }

  createContextNode(id, name, type, contextId, category, tactic = null) {
    const data = this.createNode(id, name, type, category, tactic);
    data.addContext(contextId);
    return data;
  }

  createEdge(source, destination) {
    if (!this.$attackGraph.areAjacent(source, destination)) {
      this.$attackGraph.addEdge(source, destination);
    }
  }

  getNodesAndEdges() {
    const edges = this.$attackGraph.nodes.reduce((acc, srcAtkGraphNode) => {
      const e = srcAtkGraphNode.getAdjacents().map((destAtkGraphNode) => {
        return { source: srcAtkGraphNode.value.id, target: destAtkGraphNode.value.id };
      });
      return [...acc, ...e];
    }, []);
    return [this.$attackGraph.nodeValues, edges];
  }

  /**
   * Removes all nodes in the nodesData and their successors if they no longer have a context.
   *
   * @param {Array} nodesData an array of node data
   */
  removeNodes(nodesData) {
    nodesData.forEach((nd) => {
      this.removeSuccessors(nd, nd.context);
    });
  }

  removeSuccessors(nodeData, nodeContextSet) {
    // Only nodes lower than risk have context..
    const riskContextSet = nodeData.type === TYPE.risk ? new Set([nodeData.id]) : nodeContextSet;
    const adjacentNodes = this.$attackGraph.getAdjacent(nodeData);

    // go through each adjacent nodes and remove the riskContext and the node if context is empty
    // recursively do the same to all successors.
    adjacentNodes.forEach((n) => {
      if (n.hasContext()) {
        SetUtil.removeAll(n.context, riskContextSet);
      }
      if (!n.hasContext()) {
        this.removeSuccessors(n, riskContextSet);
        this.$attackGraph.removeNode(n);
      } else {
        this.removeSuccessors(n, riskContextSet);
      }
    });
    this.$attackGraph.removeNode(nodeData);
  }

  setNodes(nodeData) {
    this.$attackGraph.nodeValues.forEach((nd) => {
      if (nodeData.filter((n) => nd.id === n.id).length === 0) {
        this.$attackGraph.removeNode(nd);
      }
    });
  }
}
