import * as React from "react";
import * as Recoil from "recoil";

import { BrmMainTable } from "brm/tables/BrmTables";
import { GlobalControlApi, useRoles } from "features/brm";
import { selectedInstanceState } from "atoms/atoms-content";
import CustomTable from "components/elements/CustomTableDivStyled";
import PropTypes from "prop-types";
import { TYPE } from "constants/brm";

import { createColumnName } from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading, ErrorBanner as ErrorDisplay } from "components/elements";
import { useControlCatalog } from "../../../hooks";

export const StandardBaselineTable = ({ setSelectedRows }) => {
  const { isControlsCoordinator } = useRoles();

  const [selectedInstance, setSelectedInstance] = Recoil.useRecoilState(selectedInstanceState);

  const updatedName = React.useRef("");

  const { controlCatalogId, controlCatalog, isReadOnlyCatalog } = useControlCatalog();

  const { data, isError, error } = GlobalControlApi.useStandardBaselines({
    controlCatalogId,
    options: { enabled: !!controlCatalogId && isControlsCoordinator },
  });

  const columns = React.useMemo(
    () => [createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "standardBaseline")],
    [setSelectedInstance, selectedInstance.id]
  );

  if (isError) {
    return <ErrorDisplay msg={error?.statusText} />;
  }

  if (!controlCatalog) {
    return <>Please select a control catalog, in order to view standard baselines</>;
  }

  if (data && controlCatalog) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={data}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "StandardBaselineTable_table" }}
            elementName={TYPE.standardBaseline}
            showRowSelect={!isReadOnlyCatalog}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

StandardBaselineTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};
