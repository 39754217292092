import PropTypes from "prop-types";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import { ProjectDetailsTable } from "features/admin";
import { DetailTableType } from "features/brm";
import { RulesetTable } from "../../tables/RulesetTable";
import { useKnowledgebase } from "../../../hooks";

export const KBDetails = ({ selectedElement, kbId }) => {
  const elementNameInUrl = DetailTableType.KNOWLEDGEBASE.key;
  const { isGlobalKb, isReadOnlyKb } = useKnowledgebase({ kbId });

  return (
    <Tabs>
      <TabList>
        <Tab>Rulesets</Tab>
        <Tab>Projects</Tab>
      </TabList>

      <TabPanel>
        <RulesetTable
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          showAdd={!isGlobalKb && !isReadOnlyKb}
          showDelete={!isGlobalKb && !isReadOnlyKb}
          kbId={kbId}
        />
      </TabPanel>

      <TabPanel>
        <ProjectDetailsTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
    </Tabs>
  );
};

KBDetails.propTypes = {
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
  }),
  kbId: PropTypes.string,
};
