import { useQuery } from "@tanstack/react-query";
// import { DetailTableType, BrmError } from "features/brm";
import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "brm/tables/details/api/element-query-key-factory";
import { BrmError } from "../../../errors/BrmError";
import { DetailTableType } from "../../../types/DetailTableType";

const getElementOutData = (
  elementId: string,
  elementType: string,
  nodeOutData: BrmGql.GetNodeOutDataQuery | undefined
) => {
  const outDataMap: IOutDataMap = {
    [DetailTableType.NODES.key]: () => nodeOutData?.node?.outData,
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityOutData(id),
  };

  if (outDataMap[elementType]) {
    return outDataMap[elementType](elementId) || [];
  }
  throw new BrmError(`Unsupported element type: ${elementType}`);
};

interface IElementOutDataProps {
  elementId: string;
  elementName: string;
  projectId: string;
  variantId: string;
  options: QueryOption<typeof getElementOutData>;
}

interface IOutDataMap {
  [index: string]: (id?: string) => any;
}

/**
 * custom-hook to retreive all outData
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {string} variantId - uuid of the variant
 * @param {object} options - configuration for the react-query
 */
export const useElementOutData = ({
  elementId,
  elementName,
  projectId,
  variantId,
  options = {},
}: IElementOutDataProps) => {
  const nodeOutDataQuery = BrmGql.useGetNodeOutDataQuery(
    { project: projectId, variant: variantId, id: elementId },
    { enabled: !!elementId && !!projectId && !!variantId && elementName === DetailTableType.NODES.key }
  );

  const elementOutDataQuery = useQuery({
    ...options,
    queryKey: KeyFactory.elementKeys.outData(elementId, elementName, nodeOutDataQuery.data),
    queryFn: () => getElementOutData(elementId, elementName, nodeOutDataQuery.data),
  });

  if (nodeOutDataQuery.isError) {
    return nodeOutDataQuery;
  }

  return elementOutDataQuery;
};
