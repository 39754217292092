import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as ReactQuery from "@tanstack/react-query";
import * as KeyFactory from "./knowledgebase-query-key-factory";

const getModifiedRules = async (kbId: string) => {
  let finalRes;

  try {
    const res = await Brm.kbApi.getKBDeepRe(kbId);
    const rulesWithId = res.rules.map((e: any, i: number) => ({ ...e, id: i }));
    finalRes = { ...res, rules: rulesWithId };
  } catch (err) {
    throw new Error("Unable to retrieve KB rules");
  }
  return finalRes || [];
};

type FetcherFunction = typeof getModifiedRules;

interface IUseGetRuleset {
  kbId: string;
  options?: QueryOption<FetcherFunction>;
}

export const useGetRules = ({ kbId, options = {} }: IUseGetRuleset) => {
  return ReactQuery.useQuery(KeyFactory.ruleKey.kb(kbId), () => getModifiedRules(kbId), {
    ...options,
  });
};
