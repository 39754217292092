import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

/**
 *
 * @param {string} controlFamilyId - uuid of control family
 * @returns array of objects - list of control types under control family
 */
export const getControlTypes = ({ controlFamilyId }: { controlFamilyId: string }) => {
  return Brm.controlFamilyApi.getControlFamilyControl(controlFamilyId);
};

interface IUseControlTypes {
  controlFamilyId: string;
  options: QueryOption<typeof getControlTypes>;
}

/**
 * React-query hook for getting all Control Types
 * @param {object} options - optionsuration for this hook
 * @param {string} controlFamilyId - uuid of the controlFamily
 * @returns react-query for getControlTypes
 */
export const useControlTypes = ({ controlFamilyId, options = {} }: IUseControlTypes) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.controlTypeKeys.controlFamily(controlFamilyId),
    queryFn: () => getControlTypes({ controlFamilyId }),
  });
};
