import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./export-query-key-factory";
import { ExportContext, ExportContextValues } from "../../constants";

interface IGetExportStatus {
  projectId: string;
  context: ExportContextValues;
  assetId: string;
}

interface ExporterFunctionMap {
  [key: string]: any;
}

export const downloadAsset = async ({ projectId, context, assetId }: IGetExportStatus) => {
  const exporter: ExporterFunctionMap = {
    [ExportContext.savepoint]: () => Brm.savePointExportApi.exportAssetDownloadSavepoint(projectId, assetId),
    [ExportContext.sctm]: () => Brm.sctmExportApi.exportAssetDownloadSctm(projectId, assetId),
    [ExportContext.tra]: () => Brm.traExportApi.exportAssetDownloadTra(projectId, assetId),
    [ExportContext.rar]: () => Brm.rarExportApi.exportAssetDownloadRar(projectId, assetId),
    [ExportContext.system]: () => Brm.systemExportApi.exportAssetDownloadSystem(projectId, assetId),
  };

  if (!Object.keys(exporter).some((k) => k === context)) {
    throw new Error(`Unsupported export context: ${context}`);
  }

  return exporter[context]();
};

export interface IUseDownloadAsset {
  projectId: string;
  context: ExportContextValues;
  assetId: string;
  options?: QueryOption<typeof downloadAsset>;
}

export const useDownloadAsset = ({ projectId, assetId, context, options }: IUseDownloadAsset) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exportKeys.downloadAsset(projectId, context, assetId),
    queryFn: () => downloadAsset({ projectId, context, assetId }),
  });
};
