import { useQuery } from "@tanstack/react-query";
// Services
import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";
import { responseAsArray } from "./utils/responseAsArray";
import * as KeyFactory from "../systemAsset/system-asset-query-key-factory";
import { DetailTableType } from "../../../types/DetailTableType";
import { IElementProps, IElementMap } from "./types/elementInterface";

interface IgetElementSystemAssetProps {
  elementId: string;
  elementName: string;
  projectId: string;
  targetData?: BrmGql.GetTargetSystemAssetsQuery | undefined;
}

export const getElementSystemAssets = async ({ elementId, elementName, projectId }: IgetElementSystemAssetProps) => {
  const sysAssetMap: IElementMap = {
    [DetailTableType.LINKS.key]: (id) => Brm.linkApi.getLinkAllSystemasset(id),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeAllSystemasset(id),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeAllSystemasset(id),
    [DetailTableType.SYSTEM_ASSET_TYPES.key]: (id) => Brm.systemAssetApi.findSystemAsset(projectId, { satype: id }),
    [DetailTableType.ASSETS.key]: (id) => Brm.assetApi.getAssetAllSystemasset(id),
    [DetailTableType.UNDESIRED_EVENTS.key]: (id) => Brm.undesiredEventApi.getUndesiredEventSystemasset(id),
    [DetailTableType.RISKS.key]: (id) => Brm.riskApi.getRiskAllSystemasset(id),
    [DetailTableType.THREAT_EVENTS.key]: (id) => Brm.threatEventApi.getThreatEventSystemasset(id),
    [DetailTableType.ATTACKERS.key]: (id) => Brm.attackerApi.getAttackerAllSystemasset(id),
    [DetailTableType.VULNERABILITIES.key]: (id) => Brm.vulnerabilityApi.getVulnerabilitySystemasset(id),
    [DetailTableType.ATTACKS.key]: (id) => Brm.attackApi.getAttackSystemasset(id),
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityAllSystemasset(id),
    [DetailTableType.RESOURCES.key]: (id) => Brm.resourceApi.getResourceAllSystemasset(id),
    [DetailTableType.DATAFLOWS.key]: (id) => Brm.dataflowApi.getDataFlowAllSystemasset(id),
    [DetailTableType.MISSIONS.key]: (id) => Brm.missionApi.getMissionAllSystemasset(id),
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.getCapabilityAllSystemasset(id),
    [DetailTableType.PERSON.key]: (id) => Brm.personApi.getPersonAllSystemasset(id),
    [DetailTableType.DATATYPES.key]: (id) => Brm.dataTypeApi.getDataTypeAllSystemasset(id),
  };

  return responseAsArray({ map: sysAssetMap, elementId, elementType: elementName });
};

export const useElementSystemAssets = ({
  elementId,
  elementName,
  variantId,
  projectId,
  options = {},
}: IElementProps) => {
  const targetSystemAssetsQuery = BrmGql.useGetTargetSystemAssetsQuery(
    { target: elementId, variant: variantId },
    {
      enabled: false, // This should be enabled when a GQL query for SAs gets enabled
      refetchOnWindowFocus: false,
    }
  );

  const elementSystemAssetsQuery = useQuery({
    ...options,
    queryKey: KeyFactory.systemAssetKeys.element(elementId, elementName, targetSystemAssetsQuery.data),
    queryFn: () =>
      getElementSystemAssets({ elementId, elementName, projectId, targetData: targetSystemAssetsQuery.data }),
  });

  if (targetSystemAssetsQuery.isError) {
    return targetSystemAssetsQuery;
  }

  return elementSystemAssetsQuery;
};
