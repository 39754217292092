/**
 * ThreatAnalyst Help contents -  this is a key value pair mapping help videos to each page , where key is the "contentKey" which is unique to each page
 */

export const ThreatAnalystHelpContents = {
  threatCatalogs: {
    contents: [
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Add threat catalog",
        filePath: "/help-assets/TA/AddAThreatCatalog/AddAThreatCatalog_player.html",
      },
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Export threat catalog",
        filePath: "/help-assets/TA/ExportThreatCatalog/ExportThreatCatalog_player.html",
      },
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Import into a threat catalog",
        filePath: "/help-assets/TA/ImportIntoAThreatCatalog/ImportIntoAThreatCatalog_player.html",
      },
    ],
  },

  threatCatalogsDetails: {
    contents: [
      {
        role: "Threat Analyst",
        type: "Video",
        name: "View threat catalog details",
        filePath: "/help-assets/TA/ViewThreatCatalogDetails/ViewThreatCatalogDetails_player.html",
      },
    ],
  },

  threatGroups: {
    contents: [
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Add threat group",
        filePath: "/help-assets/TA/AddAThreatGroup/AddAThreatGroup_player.html",
      },
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Edit threat group from list",
        filePath: "/help-assets/TA/EditThreatGroupFromList/EditThreatGroupFromList_player.html",
      },
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Clone a threat group",
        filePath: "/help-assets/TA/cloneAThreatGroup/cloneAThreatGroup_player.html",
      },
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Delete a threat group",
        filePath: "/help-assets/TA/deleteAThreatGroup/deleteAThreatGroup_player.html",
      },
      {
        role: "Threat Analyst",
        type: "Video",
        name: "View threat groups",
        filePath: "/help-assets/TA/ViewThreatGroups/ViewThreatGroups_player.html",
      },
    ],
  },

  attackers: {
    contents: [
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Add attacker",
        filePath: "/help-assets/TA/AddAttacker/AddAttacker_player.html",
      },
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Delete attacker",
        filePath: "/help-assets/TA/DeleteAnAttacker/DeleteAnAttacker_player.html",
      },
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Edit attacker from list",
        filePath: "/help-assets/TA/EditAttackerFromList/EditAttackerFromList_player.html",
      },
      {
        role: "Threat Analyst",
        type: "Video",
        name: "View local attackers",
        filePath: "/help-assets/TA/ViewLocalAttackers/ViewLocalAttackers_player.html",
      },
    ],
  },

  attackersDetails: {
    contents: [
      {
        role: "Threat Analyst",
        type: "Video",
        name: "View attacker details",
        filePath: "/help-assets/TA/ViewAttackerDetails/ViewAttackerDetails_player.html",
      },
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Edit attacker from details",
        filePath: "/help-assets/TA/EditAttackerFromDetails/EditAttackerFromDetails_player.html",
      },
    ],
  },

  threatGroupDetails: {
    contents: [
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Edit threat group from details",
        filePath: "/help-assets/TA/EditThreatGroupFromDetails/EditThreatGroupFromDetails_player.html",
      },
    ],
  },

  metadata: {
    contents: [
      {
        role: "Threat Analyst",
        type: "Video",
        name: "Update metadata by TA",
        filePath: "/help-assets/TA/updateMetadata/updateMetadata_player.html",
      },
    ],
  },
};
