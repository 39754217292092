import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./threat-tier-query-key-factory";

export interface ISetThreatTierName {
  threatTierId: string;
  name: string;
}

export const setThreatTierName = ({ threatTierId, name }: ISetThreatTierName) => {
  return Brm.threatTierApi.setThreatTierName(threatTierId, { body: name });
};

export interface IUseSetThreatTierName {
  options?: MutationOption<typeof setThreatTierName>;
}

/**
 * Custom hook to change the name of a existing ThreatTier
 * @returns react-query ReactQuery.useMutation
 */
export const useSetThreatTierName = ({ options }: IUseSetThreatTierName = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.threatTierKeys.all);
    },
    ...options,
    mutationFn: setThreatTierName,
  });
};
