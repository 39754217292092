import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { entryPointKeys } from "./access-query-key-factory";

/**
 * Returns a list of all entrypoints within a given project
 *
 * @param {string} projectId - uuid of project
 * @returns list of all entry points within the given project
 */
export const getEntryPoints = async ({ projectId }: { projectId: string }) => {
  return Brm.entryPointApi.findEntryPoint(projectId);
};

interface IUseEntryPoints {
  projectId: string;
  options?: QueryOption<typeof getEntryPoints>;
}

/**
 * Custom-hook to retrieve all entry points for a project.
 *
 * @param {string} projectId - uuid of project
 * @param {object} config - react-query configuration object
 * @returns react-query for findEntryPoint
 */
export const useEntryPoints = ({ projectId, options }: IUseEntryPoints) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: entryPointKeys.project(projectId),
    queryFn: () => getEntryPoints({ projectId }),
  });
};
