import * as React from "react";
import PropTypes from "prop-types";
// Components
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import VariantTable from "brm/tables/details/VariantTable";
import RiskTable from "brm/tables/details/RiskTable";
import SCTMTable from "brm/tables/details/SCTMTable";

// Constants
import { REPORT } from "constants/brm";
import { useRoles } from "features/brm/hooks/useRoles";
import { DetailTableType, useProject } from "features/brm";
import DynamicSubTabSelector from "components/elements/DynamicSubTabSelector";
import { DetailsTableMap } from "brm/tables/details/DetailsTableMap";
import { useRecoilState } from "recoil";
import { defaultReportTabState, isReportsTabDefaultState } from "atoms";
import { ProjectConfigurationPanel } from "features/admin/components/ProjectConfigurationPanel";

const reportsMenu = [DetailTableType.FACT_ANALYSIS_RPT, DetailTableType.FACT_ANALYSIS_INVENTORY_RPT];

export const SystemDetailsTab = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.SYSTEM.key;
  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  const [, { projectId }] = useProject();
  const [selectedReportContext, setSelectedReportContext] = React.useState(
    DetailTableType.FACT_ANALYSIS_INVENTORY_RPT.key
  );
  const [isReportsTabDefault, setIsReportsTabDefault] = useRecoilState(isReportsTabDefaultState);
  const [defaultReportsTab, setDefaultReportsTab] = useRecoilState(defaultReportTabState);
  const [curr, setCurr] = React.useState("");
  const [tabIndex, setTabIndex] = React.useState(0);

  const onSelect = (index) => {
    setTabIndex(index);
  };

  React.useEffect(() => {
    if (isReportsTabDefault) {
      if (isRiskAnalyst) {
        setTabIndex(4);
      }
      if (defaultReportsTab === DetailTableType.RAR_RPT.key) {
        setSelectedReportContext(DetailTableType.FACT_ANALYSIS_RPT.key);
        setTabIndex(0);
      } else {
        setSelectedReportContext(defaultReportsTab);
      }
    }

    return () => {
      setIsReportsTabDefault(false);
      setDefaultReportsTab("");
    };
  }, [defaultReportsTab, isReportsTabDefault, isRiskAnalyst, setDefaultReportsTab, setIsReportsTabDefault]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={onSelect}>
      <TabList>
        {isRiskAnalyst && (
          <>
            <Tab>Configuration</Tab>
            <Tab>Variant</Tab>
            <Tab>Risk</Tab>
            <Tab>SCTM</Tab>
          </>
        )}

        {(isRiskAnalyst || isSystemEngineer) && (
          <>
            <Tab onClick={() => setCurr(REPORT.reports)}>
              <DynamicSubTabSelector
                title={REPORT.reports}
                menu={reportsMenu}
                setSelectedContext={setSelectedReportContext}
                curr={curr}
              />
            </Tab>
          </>
        )}
      </TabList>

      {isRiskAnalyst && (
        <>
          <TabPanel>
            <ProjectConfigurationPanel projectId={projectId} />
          </TabPanel>
          <TabPanel>
            <VariantTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
          </TabPanel>
          <TabPanel>
            <RiskTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
          </TabPanel>
          <TabPanel>
            <SCTMTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
          </TabPanel>
        </>
      )}

      {(isRiskAnalyst || isSystemEngineer) && (
        <>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedReportContext}
            />
          </TabPanel>
        </>
      )}
    </Tabs>
  );
};

SystemDetailsTab.propTypes = {
  selectedElement: PropTypes.any,
};
