import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import BoundaryActivitiesTable from "./tables/BoundaryActivitiesTable";

function BoundaryActivities() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const boundaryActivitiesHeader = <Title>Boundary Activities</Title>;

  const boundaryActivitiesMain = (
    <>
      <BoundaryActivitiesTable
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );

  return <ContentLayout pageId="AttackActivity_page" header={boundaryActivitiesHeader} main={boundaryActivitiesMain} />;
}

export default BoundaryActivities;
