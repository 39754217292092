import styled from "styled-components";
import Card from "react-bootstrap/Card";

export const Container = styled.div`
  line-height: 1;
  width: 100%;
`;

export const StyledCardBody = styled(Card.Body)`
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  padding-left: 20px;
  height: auto;
  overflow-x: auto;
`;

export const StyledCard = styled(Card)`
  background-color: white;
`;
