import { useOrganizationConfigurationPanelApi } from "./useOrganizationConfigurationPanelApi";
import { ProjectSettingsView } from "../ProjectSettingsView";
import * as PS from "../styles/project-settings-styles";

interface IOrganizationConfigurationPane {
  organizationId: string;
  isReadOnly?: boolean;
}

export const OrganizationConfigurationPane = ({
  organizationId,
  isReadOnly = false,
}: IOrganizationConfigurationPane) => {
  const { projectSettings, setProjectSetting } = useOrganizationConfigurationPanelApi({ organizationId });

  return (
    <>
      <PS.SettingsHeading>Project Configuration Settings</PS.SettingsHeading>
      <ProjectSettingsView
        projectSettings={projectSettings}
        isReadOnly={isReadOnly}
        onSettingChange={setProjectSetting}
      />
    </>
  );
};
