import {
  StandardBaselineApi,
  ControlTypeApi,
  ControlCatalogApi,
  ControlFamilyApi,
  ControlFrameworkApi,
} from "@kdmanalytics/brm-knowledgebase";
import BrmApiClient from "services/brm/BrmApiClient";

export const baselineApi = new StandardBaselineApi(BrmApiClient.instance);
export const controlCatalogApi = new ControlCatalogApi(BrmApiClient.instance);
export const controlFamilyApi = new ControlFamilyApi(BrmApiClient.instance);
export const controlTypeApi = new ControlTypeApi(BrmApiClient.instance);
export const controlFrameworkApi = new ControlFrameworkApi(BrmApiClient.instance);
