import { useQuery } from "@tanstack/react-query";
import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { DetailTableType } from "../../../types/DetailTableType";
import { BrmError } from "../../../errors/BrmError";
import * as KeyFactory from "./capability-query-key-factory";

const getElementCapabilities = (
  elementId: string,
  elementType: string,
  exchangeCapabilitiesData: BrmGql.GetExchangeCapabilitiesQuery | undefined,
  dataTypeCapabilitiesData: BrmGql.GetDataTypeCapabilitiesQuery | undefined,
  personCapabilitiesData: BrmGql.GetPersonCapabilitiesQuery | undefined,
  resourceCapabilitiesData: BrmGql.GetResourceCapabilitiesQuery | undefined
) => {
  const capabilitiesMap: ICapabiblitesMap = {
    [DetailTableType.RESOURCES.key]: () => resourceCapabilitiesData?.resource?.capabilities,
    [DetailTableType.EXCHANGES.key]: () => exchangeCapabilitiesData?.exchange?.capabilities,
    [DetailTableType.DATATYPES.key]: () => dataTypeCapabilitiesData?.dataType?.capabilities,
    [DetailTableType.PERSONS.key]: () => personCapabilitiesData?.person?.capabilities,
    [DetailTableType.MISSIONS.key]: (id) => Brm.missionApi.getMissionCapability(id),
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityCapability(id),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeCapability(id),
  };

  if (capabilitiesMap[elementType]) {
    return capabilitiesMap[elementType](elementId) || [];
  }

  throw new BrmError(`Unsupported element type: ${elementType}`);
};

interface IElementCapabilitiesProps {
  elementId: string;
  elementName: string;
  variantId: string;
  projectId: string;
  options: QueryOption<typeof getElementCapabilities>;
}

interface ICapabiblitesMap {
  [index: string]: (id?: string) => any;
}

/**
 * custom-hook to retreive all capabilities
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {string} variantId - uuid of the variant
 * @param {object} options - configuration for the react-query
 */
export const useElementCapabilities = ({
  elementId,
  elementName,
  projectId,
  variantId,
  options = {},
}: IElementCapabilitiesProps) => {
  const exchangeCapabilitiesQuery = BrmGql.useGetExchangeCapabilitiesQuery(
    { project: projectId, variant: variantId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.EXCHANGES.key }
  );

  const dataTypeCapabilitiesQuery = BrmGql.useGetDataTypeCapabilitiesQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.DATATYPES.key }
  );

  const personCapabilitiesQuery = BrmGql.useGetPersonCapabilitiesQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.PERSON.key }
  );

  const resourceCapabilitiesQuery = BrmGql.useGetResourceCapabilitiesQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.RESOURCES.key }
  );

  const elementCapabilityQuery = useQuery({
    ...options,
    queryKey: KeyFactory.capabilityKeys.element(
      elementId,
      elementName,
      projectId,
      variantId,
      exchangeCapabilitiesQuery.data,
      dataTypeCapabilitiesQuery.data,
      personCapabilitiesQuery.data,
      resourceCapabilitiesQuery.data
    ),
    queryFn: () =>
      getElementCapabilities(
        elementId,
        elementName,
        exchangeCapabilitiesQuery.data,
        dataTypeCapabilitiesQuery.data,
        personCapabilitiesQuery.data,
        resourceCapabilitiesQuery.data
      ),
  });

  if (exchangeCapabilitiesQuery.isError) {
    return exchangeCapabilitiesQuery;
  }

  if (dataTypeCapabilitiesQuery.isError) {
    return dataTypeCapabilitiesQuery;
  }

  if (personCapabilitiesQuery.isError) {
    return personCapabilitiesQuery;
  }

  if (resourceCapabilitiesQuery.isError) {
    return resourceCapabilitiesQuery;
  }

  return elementCapabilityQuery;
};
