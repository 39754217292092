import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./attacker-query-key-factory";

export const getAttackers = ({ projectId }: { projectId: string }) => {
  return Brm.attackerApi.findAttacker(projectId);
};

interface IUseAttackers {
  projectId: string;
  options?: QueryOption<typeof getAttackers>;
}

/**
 * React-query hook for getting all Attackers
 *
 * @param {string} projectId - uuid of the project
 * @returns react-query for getAttackers
 */
export const useAttackers = ({ projectId, options = {} }: IUseAttackers) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.attackerKeys.project(projectId),
    queryFn: () => getAttackers({ projectId }),
  });
};
