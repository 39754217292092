import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

interface ICreateControlFamily {
  controlCatalogId: string;
  controlFamilyCreateDto: any;
}

/**
 * Function to create a new controlfamily
 *
 * @param {ControlFamilyCreateDto} controlFamilyCreateDto creation dto
 * @returns id of created catalog
 */
export const createControlFamily = ({ controlCatalogId, controlFamilyCreateDto }: ICreateControlFamily) => {
  return Brm.controlFamilyApi.createControlFamily(controlCatalogId, { controlFamilyCreateDto });
};

interface IUseCreateControlFamily {
  options?: MutationOption<typeof createControlFamily>;
}

/**
 * Custom hook to create a new control family.
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for useCreateControlFamily
 */
export const useCreateControlFamily = ({ options }: IUseCreateControlFamily = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.controlFamilyKeys.all);
    },
    ...options,
    mutationFn: createControlFamily,
  });
};
