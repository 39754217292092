import { useQuery } from "@tanstack/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "../access/access-query-key-factory";
import { DetailTableType } from "../../../types/DetailTableType";
import { IElementProps, IElementMap } from "./types/elementInterface";
import { responseAsArray } from "./utils/responseAsArray";

/**
 * custom-hook to get all attackvectors of an element
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {object} config - configuration for the react-query
 */

export const getElementAttackVectors = async (elementId: string, elementType: string) => {
  const attackVectorsMap: IElementMap = {
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityAllAttackvector(id),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeAllAttackvector(id),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeAllAttackvector(id),
    [DetailTableType.ATTACK_SURFACES.key]: (id) => Brm.attackSurfaceApi.getAttackSurfaceAllAttackvector(id),
    [DetailTableType.ATTACKERS.key]: (id) => Brm.attackerApi.getAttackerAllAttackvector(id),
    [DetailTableType.ENTRY_POINTS.key]: (id) => Brm.entryPointApi.getEntryPointAllAttackvector(id),
    [DetailTableType.ACCESS_POINTS.key]: (id) => Brm.accessPointApi.getAccessPointAllAttackvector(id),
  };

  return responseAsArray({ map: attackVectorsMap, elementId, elementType });
};

export const useElementAttackVectors = ({ elementId, elementName, projectId, options = {} }: IElementProps) => {
  const elementAttackVectorsQuery = useQuery({
    ...options,
    queryKey: KeyFactory.attackVectorsKeys.details(elementId, elementName, projectId),
    queryFn: () => getElementAttackVectors(elementId, elementName),
  });

  return elementAttackVectorsQuery;
};
