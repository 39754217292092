import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./organization-query-key-factory";

/**
 * Gets the organization's projects
 */
export const getOrganizationProjects = ({ organizationId }: { organizationId: string | null }) => {
  return organizationId === null
    ? Promise.reject(new Error("Invalid organizationId id"))
    : Brm.organizationApi.getOrganizationProject(organizationId);
};

interface IOrganizationsProps {
  organizationId: string | null;
  options?: QueryOption<typeof getOrganizationProjects>;
}

/**
 * Custom hook for getting organizations projects
 *
 * @param {object} options - react-query options
 * @returns
 */
export const useOrganizationProjects = ({ organizationId, options = {} }: IOrganizationsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.organizationKeys.project(organizationId),
    queryFn: () => getOrganizationProjects({ organizationId }),
  });
};
