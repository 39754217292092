import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import PropTypes from "prop-types";
import DynamicSubTabSelector from "components/elements/DynamicSubTabSelector";
import { DetailsTableMap } from "brm/tables/details/DetailsTableMap";
import { DetailTableType } from "features/brm";
import { useRoles } from "features/brm/hooks/useRoles";
import { CenterText } from "components/elements";

const systemMenu = [DetailTableType.TARGETS];

const riskMenu = [DetailTableType.RISKS, DetailTableType.DEP_ASSETS, DetailTableType.UNDESIRED_EVENTS];

const attacksMenu = [DetailTableType.THREAT_EVENTS, DetailTableType.ATTACKS, DetailTableType.ATTACKERS];

const controlsMenu = [DetailTableType.ALLOCATED_CONTROLS];

const vulnerabilityMenu = [DetailTableType.VULNERABILITY_CONDITIONS, DetailTableType.VULNERABILITY_FINDINGS];

const SystemAssetDetails = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.SYSTEM_ASSETS.key;
  const { isRiskAnalyst } = useRoles();
  const [selectedContext, setSelectedContext] = React.useState(DetailTableType.TARGETS.key);
  const [selectedRiskContext, setSelectedRiskContext] = React.useState(DetailTableType.RISKS.key);
  const [selectedAttackContext, setSelectedAttackContext] = React.useState(DetailTableType.THREAT_EVENTS.key);
  const [selectedVulContext, setSelectedVulContext] = React.useState(DetailTableType.VULNERABILITY_CONDITIONS.key);
  const [selectedControlsContext, setSelectedControlsContext] = React.useState(DetailTableType.ALLOCATED_CONTROLS.key);
  const [curr, setCurr] = React.useState("");

  return (
    <Tabs>
      <TabList>
        <Tab onClick={() => setCurr(DetailTableType.SYSTEM.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.SYSTEM.name}
            menu={systemMenu}
            setSelectedContext={setSelectedContext}
            curr={curr}
          />
        </Tab>
        <Tab>{DetailTableType.ACCESS.name}</Tab>

        {isRiskAnalyst && (
          <>
            <Tab onClick={() => setCurr(DetailTableType.RISK.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.RISK.name}
                menu={riskMenu}
                setSelectedContext={setSelectedRiskContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.ATTACK.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.ATTACK.name}
                menu={attacksMenu}
                setSelectedContext={setSelectedAttackContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.VULNERABILITIES.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.VULNERABILITIES.name}
                menu={vulnerabilityMenu}
                setSelectedContext={setSelectedVulContext}
                curr={curr}
              />
            </Tab>
            <Tab onClick={() => setCurr(DetailTableType.CONTROLS.name)}>
              <DynamicSubTabSelector
                title={DetailTableType.CONTROLS.name}
                menu={controlsMenu}
                setSelectedContext={setSelectedControlsContext}
                curr={curr}
              />
            </Tab>
          </>
        )}
      </TabList>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedContext}
        />
      </TabPanel>
      <TabPanel>
        <CenterText msg="Access is not applicable" />
      </TabPanel>

      {isRiskAnalyst && (
        <>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedRiskContext}
            />
          </TabPanel>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedAttackContext}
            />
          </TabPanel>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedVulContext}
            />
          </TabPanel>
          <TabPanel>
            <DetailsTableMap
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
              selectedContext={selectedControlsContext}
            />
          </TabPanel>
        </>
      )}
    </Tabs>
  );
};

SystemAssetDetails.propTypes = {
  selectedElement: PropTypes.object.isRequired,
};

export default SystemAssetDetails;
