import PropTypes from "prop-types";
import { useState } from "react";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import * as S from "../styles/wizard.styles";

export const Blv = ({ options, handleSubmit }) => {
  const [selectedValues, setSelectedValues] = useState(
    options["import-mode"] ? options["import-mode"].defaults[0] : ""
  );

  function handleSelection(e) {
    setSelectedValues(e.target.value);
  }

  const handleSubmission = () => {
    const opt = options["import-mode"];
    opt.selection = [selectedValues];
    handleSubmit(options);
  };

  return (
    <S.DodafWizard id="Blv">
      <h5 id="Blv_title" className="wizardTitle">
        Blade Validate Wizard
      </h5>
      <div>
        <label>Import Mode:</label>
        {Object.entries(options) &&
          options["import-mode"].values.map((each) => (
            <div key={each} id="Blv_importModes">
              <input
                type="radio"
                name={each}
                id={each}
                value={each}
                checked={each === selectedValues}
                onChange={(e) => handleSelection(e)}
              />
              <label className="inputLabel">{each}</label>
            </div>
          ))}
      </div>
      <DialogButtonDivStyled>
        <DialogButtonStyled onClick={handleSubmission} id="Blv_submit">
          Submit
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </S.DodafWizard>
  );
};

Blv.propTypes = {
  handleSubmit: PropTypes.func,
  options: PropTypes.any,
};
