import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { entryPointKeys } from "./access-query-key-factory";

/**
 * Returns an entry point's details
 *
 * @param {string} entryPointId - uuid of entry point
 * @returns list of all entry points within the given project
 */
export const getEntryPointById = async ({ entryPointId }: { entryPointId?: string }) => {
  return Brm.entryPointApi.getEntryPointByID(entryPointId);
};

interface IUseEntryPoints {
  entryPointId?: string;
  options?: QueryOption<typeof getEntryPointById>;
}

/**
 * Custom-hook to retrieve an entry point's details
 *
 * @param {string} entryPointId - uuid of entry point
 * @param {object} config - react-query configuration object
 * @returns react-query for getEntryPointById
 */
export const useGetEntryPointById = ({ entryPointId, options }: IUseEntryPoints) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: entryPointKeys.all,
    queryFn: () => getEntryPointById({ entryPointId }),
  });
};
