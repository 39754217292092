// Libs
import PropTypes from "prop-types";
// Components
import { BrmDetailTableView } from "brm/tables/BrmTables/BrmDetailTableView";
import TablePaginationSmall from "./BrmTablePaginationSmall";

// Styles
import * as S from "./styles/tables.styles";

export const BrmDetailTableDefault = ({
  prepareRow,
  headerGroups,
  selectedFlatRows,
  setSelectedElement = () => {},
  setSelectedRows,
  customProps,
  showHeaderFilters,
  getTableProps,
  getTableBodyProps,
  rows,
  page,
  visibleColumns,
  showRowCount,
  showTableTitle,
  data,
  showPagination,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  setInitialPageSize,
  pageIndex,
  pageSize,
  elementName,
}) => {
  return (
    <S.MainTableContainer>
      {showRowCount && (
        <S.TableHeaderWrap>
          {showTableTitle ? (
            <S.RowCountTextSpan>
              <S.RowCountSpan>{showTableTitle}</S.RowCountSpan> ( Showing
              <S.RowCountSpan>{rows.length}</S.RowCountSpan>
              items of <S.RowCountSpan>{data.length}</S.RowCountSpan>)
            </S.RowCountTextSpan>
          ) : (
            <S.RowCountTextSpan>
              Showing
              <S.RowCountSpan>{rows.length}</S.RowCountSpan>
              items of <S.RowCountSpan>{data.length}</S.RowCountSpan>
            </S.RowCountTextSpan>
          )}
        </S.TableHeaderWrap>
      )}
      <S.ContentWrapper>
        <S.OverflowContainer>
          <S.ZeroHeightDiv>
            <S.TableDiv>
              <S.TableWrap>
                <BrmDetailTableView
                  hasData={data?.length > 0}
                  customProps={customProps}
                  prepareRow={prepareRow}
                  headerGroups={headerGroups}
                  selectedFlatRows={selectedFlatRows}
                  setSelectedElement={setSelectedElement}
                  setSelectedRows={setSelectedRows}
                  showHeaderFilters={showHeaderFilters}
                  getTableProps={getTableProps}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                  visibleColumns={visibleColumns}
                  elementName={elementName}
                />
              </S.TableWrap>
            </S.TableDiv>
          </S.ZeroHeightDiv>
        </S.OverflowContainer>
      </S.ContentWrapper>
      {showPagination && (
        <S.TableFooterWrap>
          <TablePaginationSmall
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            setInitialPageSize={setInitialPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        </S.TableFooterWrap>
      )}
    </S.MainTableContainer>
  );
};

BrmDetailTableDefault.propTypes = {
  prepareRow: PropTypes.func.isRequired,
  headerGroups: PropTypes.any.isRequired,
  selectedFlatRows: PropTypes.any.isRequired,
  customProps: PropTypes.shape({
    id: PropTypes.string,
  }),
  getTableProps: PropTypes.func.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  rows: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  visibleColumns: PropTypes.any.isRequired,
  setSelectedElement: PropTypes.func,
  setSelectedRows: PropTypes.func.isRequired,
  showHeaderFilters: PropTypes.bool.isRequired,
  showRowCount: PropTypes.bool.isRequired,
  showTableTitle: PropTypes.string,
  data: PropTypes.any.isRequired,
  showPagination: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageOptions: PropTypes.array.isRequired,
  pageCount: PropTypes.any,
  gotoPage: PropTypes.any,
  nextPage: PropTypes.any,
  previousPage: PropTypes.any,
  setPageSize: PropTypes.func,
  setInitialPageSize: PropTypes.func,
  pageIndex: PropTypes.any,
  pageSize: PropTypes.any,
  elementName: PropTypes.string,
};
