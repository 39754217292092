import * as ReactQuery from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";

export const useVulnerabilities = (projectId, variantId, riskUpdated, config = {}) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["getVulnerabilities", projectId, variantId, riskUpdated],
    queryFn: () => Brm.vulnerabilityApi.findVulnerability(projectId, { variant: variantId }),
  });
};

export const useVulCategories = ({ projectId, config = {} }) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["getVulnerabilityCategories", projectId],
    queryFn: () => Brm.vulnerabilityCategoryApi.findVulnerabilityCategory(projectId),
  });
};
