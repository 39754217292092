import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

interface ICreateControlCatalog {
  controlCatalogCreateDto: any;
}

/**
 * Function to create new control catalog
 *
 * @param {ControlCatalogCreateDto} controlCatalogCreateDto creation dto
 * @returns id of created catalog
 */
export const createControlCatalog = ({ controlCatalogCreateDto }: ICreateControlCatalog) => {
  return Brm.controlCatalogApi.createControlCatalog({ controlCatalogCreateDto });
};

interface IUseCreateControlCatalog {
  options?: MutationOption<typeof createControlCatalog>;
}

/**
 * Custom hook to create a new Control Catalog.
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for useCreateControlCatalog
 */
export const useCreateControlCatalog = ({ options }: IUseCreateControlCatalog = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.controlCatalogKeys.all);
    },
    ...options,
    mutationFn: createControlCatalog,
  });
};
