import * as Recoil from "recoil";

import { useParams, useNavigate } from "react-router-dom";
import { ElementFullDetails } from "features/brm";

import { TYPE } from "constants/brm";
import { userRoleState, userIdState } from "atoms";
import { OrganizationDetailsTab } from "../../components/tabs/OrganizationDetailsTab";
import { useOrganizationOverview } from "./useOrganizationOverview";

export const OrganizationOverview = () => {
  const navigate = useNavigate();
  const userId = Recoil.useRecoilValue(userIdState);
  const userRole = Recoil.useRecoilValue(userRoleState);
  const { id } = useParams();

  const { refresh, setRefresh, columns, selectedElement, organizationList, setSelectedElement, setSelectedRowId } =
    useOrganizationOverview({
      organizationId: id,
      userId,
      userRole,
      navigate,
    });

  const detailsComp = userRole ? (
    <OrganizationDetailsTab selectedElement={selectedElement} setRefresh={setRefresh} refresh={refresh} />
  ) : null;

  return (
    <>
      {organizationList && detailsComp ? (
        <ElementFullDetails
          showBackLink
          data={organizationList}
          columns={columns}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          setSelectedRowId={setSelectedRowId}
          detailsComp={detailsComp}
          compName={TYPE.organization}
        />
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};
