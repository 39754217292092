/**
 * Class for all the data available at each node on the attack diagram.
 */
export default class NodeData {
  /**
   * Constructor for Node Data.
   *
   * @param {*} param0
   */
  constructor({ id, name, type, rank = undefined, category = null, contextId = null, tactic = null }) {
    this.$id = id;
    this.$name = name;
    this.$type = type;
    this.$category = category;
    this.$rank = rank;
    this.$tactic = tactic;
    this.$context = new Set();
    if (contextId) {
      this.$context.add(contextId);
    }
  }

  get id() {
    return this.$id;
  }

  get name() {
    return this.$name;
  }

  get type() {
    return this.$type;
  }

  get category() {
    return this.$category;
  }

  get rank() {
    return this.$rank;
  }

  get context() {
    return this.$context;
  }

  get tactic() {
    return this.$tactic;
  }

  isInContext(id) {
    this.$context.has(id);
  }

  addContext(id) {
    this.$context.add(id);
  }

  removeContext(id) {
    this.$context.remove(id);
  }

  hasContext() {
    return this.$context.size !== 0;
  }
}
