import { useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { LoadingSpinner, ErrorBanner } from "components/elements";
import { useElementDataflows } from "./api";

interface IDataflowProps {
  elementNameInUrl: string;
  selectedElement: {
    id: string;
  };
  tableTitle: string;
}

const DataflowTable = ({ elementNameInUrl, selectedElement, tableTitle }: IDataflowProps) => {
  const columns = useMemo(() => [createColumnMappedNoEdit("name"), createColumnMappedNoEdit("category")], []);
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);

  const { data: dataflowsData, isError: isDataflowsError } = useElementDataflows({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    variantId,
    config: { enabled: !!selectedElement?.id && !!elementNameInUrl },
  });

  if (isDataflowsError) {
    return <ErrorBanner msg="Error while attempting to load dataflow data" />;
  }

  if (dataflowsData) {
    return (
      <S.DetailsContainer id="MemberTable_detailsPanel">
        <BrmDetailTable
          data={dataflowsData}
          columns={columns}
          customProps={{ id: "MemberTable_detailsTable" }}
          showRowSelect={false}
          tableTitle={tableTitle}
          setSelectedElement={() => {}}
        />
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

export default DataflowTable;
