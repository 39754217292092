import * as React from "react";
import { useMultiStepForm } from "hooks/useMultStepForm";
import { Stepper, Step, StepLabel } from "components/elements/Stepper";
// import { CancelContext } from "features/admin/stores/CancelContext/CancelContext";
import { YesNoModal } from "components/modals/YesNoModel";
import { useCancelContext } from "features/admin/stores";
import { ProjectAccessLevel } from "features/brm";
import { ActionButton, ErrorSpan } from "components/elements";
import { SecurityArchitectureStepForm } from "./SecurityArchitectureStepForm";
import { SystemModelArchitectureStepForm } from "./SystemModelArchitectureStepForm";
import { VulnerabilityFindingsStepForm } from "./VulnerabilityFindingsStepForm";
import { KnowledgebaseStepForm } from "./KnowledgebaseStepForm";
import { ConfigureProjectStepForm } from "./ConfigureProjectStepForm";
import * as S from "./CreateProjectForm.styles";
import { ConfigureProjectContext } from "./ConfigureProjectContext";
import { IConfigureData } from "./types";
import * as CommonStyle from "./styles";

interface ICreateProjectFormProps {
  onClose: () => void;
  projectAccessLevel: () => number;
}

const optionalComp = <S.Optional>(optional)</S.Optional>;

const steps = [
  {
    label: "Knowledgebase",
    step: <KnowledgebaseStepForm />,
    optional: null,
  },
  {
    label: "System Model Architecture",
    step: <SystemModelArchitectureStepForm />,
    optional: optionalComp,
  },
  {
    label: "Security Architecture Configuration (SCTM)",
    step: <SecurityArchitectureStepForm />,
    optional: optionalComp,
  },
  {
    label: "Vulnerability Findings",
    optional: optionalComp,
    step: <VulnerabilityFindingsStepForm />,
  },
  {
    label: "Configuring Project",
    step: <ConfigureProjectStepForm />,
    optional: null,
  },
];

export const ConfigureProjectForm = ({ onClose, projectAccessLevel }: ICreateProjectFormProps) => {
  // const { close } = useProjectConfigu
  const [configureData, setConfigureData] = React.useState({} as IConfigureData);

  const { isCancelRequested, setIsCancelRequested } = useCancelContext();
  // const [isYesNoModalVisible, setYesNoModalVisible] = React.useState(false);

  const formSteps = steps.map((s) => s.step);
  const { currentStep, isLastStep, currentStepIndex, prev, next } = useMultiStepForm(formSteps);

  const context = React.useMemo(() => {
    return { next, prev, close: onClose, configureData, setConfigureData };
  }, [next, prev, onClose, configureData]);

  const handleConfirmationClose = (result: boolean) => {
    setIsCancelRequested(false);
    if (result) {
      if (!configureData.isConfigureInProgress) {
        onClose();
      } else {
        setConfigureData({ ...configureData, isConfigureCancelled: true });
      }
    }
  };

  React.useEffect(() => {
    if (configureData.isComplete && isCancelRequested) {
      onClose();
    }
  }, [configureData, isCancelRequested, onClose]);

  const confirmationComponent = (
    <div style={{ padding: "10px 0px 0px 0px" }}>
      <h5 style={{ fontWeight: "600" }}>Are you sure that you want to leave the project unconfigured? </h5>
      <div style={{ padding: "10px 0px 0px 0px" }}>
        If you cancel refer to the following roles and tasks to configure the project:
        <div style={{ marginLeft: "20px", marginTop: "5px" }}>
          <ul>
            <li>Cybersecurity Expert - Configure Knowledgebase</li>
            <li>Control Coordinator - Configure Control Catalog and Baseline</li>
            <li>Threat Analyst - Configure Threat Catalog and Threat Groups</li>
            <li>Risk Analyst - Calculate Risk</li>
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <S.Container>
      {isCancelRequested ? (
        <YesNoModal
          isVisible={isCancelRequested}
          title="Confirm Cancel"
          onClose={handleConfirmationClose}
          content={confirmationComponent}
          yesButtonText="Cancel Configuration"
          noButtonText="Return to Configuration"
        />
      ) : null}

      <S.VerticalStepContainer>
        <S.VerticalStepHeader>Project Configuration</S.VerticalStepHeader>
        <Stepper activeStep={currentStepIndex}>
          {steps.map((step) => (
            <Step
              key={step.label}
              isLast={isLastStep || undefined}
              isCompleted={isLastStep ? configureData.isComplete : undefined}
            >
              <StepLabel optional={step.optional}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </S.VerticalStepContainer>

      {projectAccessLevel() === ProjectAccessLevel.None ? (
        <S.Container>
          <S.Form>
            <CommonStyle.StepDiv>
              <div style={{ padding: "1rem" }}>
                <ErrorSpan>Insufficent Project Access to continue project configuration</ErrorSpan>
              </div>
              <CommonStyle.ButtonContainer>
                <div style={{ padding: "0px 5px 5px 5px" }}>
                  <ActionButton
                    onClick={() => {
                      onClose();
                    }}
                  >
                    OK
                  </ActionButton>
                </div>
              </CommonStyle.ButtonContainer>
            </CommonStyle.StepDiv>
          </S.Form>
        </S.Container>
      ) : (
        <ConfigureProjectContext.Provider value={context}>
          <S.StepContainer>{currentStep}</S.StepContainer>
        </ConfigureProjectContext.Provider>
      )}

      {/* 
      <ConfigureProjectContext.Provider value={context}>
        <S.StepContainer>{currentStep}</S.StepContainer>
      </ConfigureProjectContext.Provider> */}
    </S.Container>
  );
};
