import * as React from "react";
import * as Recoil from "recoil";
import log from "loglevel";
import { sctmIdState } from "atoms/atoms-mitigation";
import * as BrmGql from "generated/graphql";

import { MitigationApi, useProject } from "features/brm";
import { SYSTEM_ELEMENT } from "constants/brm";
import { IVulnerabilityData, IManualMitigationState, ISctm, IAllocatedControl } from "./types";

const INITIAL_MITIGATION_WIZARD_STATE: IManualMitigationState = {
  rarVulnerabilities: [],
  vulnerabilities: [],
  controlTypes: [],
  nodes: [],
  exchanges: [],
  controlTypeSource: "catalog",
  selectedVulnerabilities: {},
  selectedControlTypes: {},
};

const NOUNS = {
  allocatedcontrol: "allocatedcontrol",
  sctm: "SCTM",
};

interface IUseRarManualMitigation {
  rarVulnerabilities: IVulnerabilityData[];
  importSctm: ({ id, body }: { id: string; body: string }) => void;
  onClose: () => void;
}

/**
 * Query data required for populating manual mitigation wizard.  Maintain the selection state mitigation wizard.
 */
export const useRarManualMitigation = ({ rarVulnerabilities, importSctm }: IUseRarManualMitigation) => {
  const [sctmId] = Recoil.useRecoilState(sctmIdState);
  const [currentProject] = useProject();

  const [mitigationWizardState, setMitigationWizardState] = React.useState(() => ({
    ...INITIAL_MITIGATION_WIZARD_STATE,
    rarVulnerabilities,
  }));

  // const selectedVulnerabilityRef = React.useRef<VulnerabilitySelection[]>([]);
  // const selectedControlTypeRef = React.useRef<ControlTypeSelection[]>([]);
  // const [hasSelectedVulnerabilities, setHasSelectedVulnerabilities] = React.useState(false);
  // const [hasSelectedControlTypes, setHasSelectedControlTypes] = React.useState(false);

  // const [vulnerabilitySelection, setVulnerabilitySelection] = React.useState<RowSelectionState>({});

  const elementIds = Array.from(new Set(mitigationWizardState.vulnerabilities.map((v) => v.target)));
  // console.log("elementIds", elementIds);

  React.useEffect(() => {
    setMitigationWizardState((prev) => ({ ...prev, rarVulnerabilities }));
  }, [rarVulnerabilities]);

  const { data: systemElements } = BrmGql.useGetSystemElementNamesQuery(
    {
      project: currentProject?.id || "",
      ids: elementIds,
    },
    { enabled: elementIds.length !== 0, select: (d) => d.systemElementsByIds, cacheTime: 0 }
  );

  const { data: sctmBaseline } = MitigationApi.useSctmBaseline({
    sctmId: sctmId || "",
    options: { enabled: !!sctmId },
  });
  const { data: sctmName } = MitigationApi.useSctmName({
    sctmId: sctmId || "",
    options: { enabled: sctmId !== null, select: (d: any) => d.result },
  });

  const updateMitigationWizardState = (newData: Partial<IManualMitigationState>) => {
    setMitigationWizardState((prev) => {
      return { ...prev, ...newData };
    });
  };

  // const updateSelectedVulnerabilities = React.useCallback(
  //   (selVulnerabilities: ITableSelection<IVulnerabilityData>[]) => {
  //     const existingSet = new Set(selectedVulnerabilityRef.current.map((s) => s.rowId));
  //     const selectedSet = new Set(selVulnerabilities.map((s) => s.rowId));

  //     // TODO fix this....
  //     if (existingSet.size !== selectedSet.size) {
  //       selectedControlTypeRef.current = [];
  //     }

  //     selectedVulnerabilityRef.current = selVulnerabilities;
  //     setHasSelectedVulnerabilities(selectedVulnerabilityRef.current.length !== 0);
  //   },
  //   []
  // );

  // const updateSelectedControlTypes = React.useCallback((selControlTypes: any[]) => {
  //   selectedControlTypeRef.current = selControlTypes;
  //   // setHasSelectedControlTypes(selectedControlTypeRef.current.length !== 0);
  // }, []);

  React.useEffect(() => {
    if (systemElements) {
      updateMitigationWizardState({
        // TODO do we have constants for all the nouns?
        nodes: systemElements.filter((se) => se.noun === SYSTEM_ELEMENT.node),
        exchanges: systemElements.filter((se) => se.noun === SYSTEM_ELEMENT.exchange),
      });
    }
  }, [systemElements]);

  const performManualMitigation = () => {
    const controls = mitigationWizardState.controlTypes.map((ct) => {
      if (systemElements) {
        const foo: IAllocatedControl[] = systemElements?.map((element) => {
          return {
            noun: NOUNS.allocatedcontrol,
            name: `${ct.name}@${element?.name}`,
            id: `${ct.name}`,
            object: `${element?.name}`,
            controltype: `${ct.name}`,
            isCompliant: false,
            isImported: false,
          };
        });
        return foo;
      }
      return [];
    });
    const finalControls = [...controls.flat()];

    const schema: ISctm = {
      noun: NOUNS.sctm,
      name: sctmName,
      allocatedcontrol: finalControls,
      baseline: sctmBaseline.name,
      note: "",
      isImported: false,
      autoallocate: false,
    };

    try {
      // console.log("opts", schema);
      const opts = JSON.stringify(schema);
      if (sctmId) {
        importSctm({ id: sctmId, body: opts });
      }
    } catch (err) {
      log.error("error in creating sctm import", err);
    }
  };

  return {
    mitigationWizardState,
    updateMitigationWizardState,
    systemElements,
    // vulnerabilitySelection,
    // setVulnerabilitySelection,
    // selectedVulnerabilityRef,
    // selectedControlTypeRef,
    hasSelectedVulnerabilities: Object.keys(mitigationWizardState.selectedVulnerabilities).length > 0,
    hasSelectedControlTypes: Object.keys(mitigationWizardState.selectedControlTypes).length > 0,
    // updateSelectedVulnerabilities,
    performManualMitigation,
  };
};
