import * as React from "react";
import { ThemeContext } from "styled-components";
import { TYPE } from "constants/brm";

import {
  ZoomButtonsGroup,
  LayoutButtonsGroup,
  Toolbar,
  ToolbarButton,
  ToolbarSeparator,
} from "features/diagram-common";
import { ReactComponent as ToggleLabel } from "assets/icons/studio91/sidebars-toolbars/hide-labels.svg";
import { ReactComponent as FocusOnSelected } from "assets/icons/studio91/sidebars-toolbars/focus.svg";
import { BsFillTriangleFill } from "react-icons/bs";
import { MdOutlineHideImage } from "react-icons/md";
import * as AgConstants from "../../constants";
import { IRarToolBar } from "../../types";

const iconWidth = "16px";
const iconHeight = "16px";

export const RarDiagramToolbar = ({
  onZoomIn,
  onResetZoom,
  onZoomOut,
  onFitContent,
  onOrganicLayout,
  onRadialLayout,
  onCircularLayout,
  onToggleLabels,
  onHighlightNodes,
  onToggleOverview,
  onToggleAttacksVisibility,
  showLabels,
  isFocused,
  currentLayout,
  focusEnabled,
  isAttacksVisible,
  showOverview = false,
}: IRarToolBar) => {
  const theme = React.useContext(ThemeContext);

  const {
    colors: { buttonPrimary: buttonFill },
    // TODO the standard sizes from the theme look terrible... might have to revisit for now override with smaller icons
    // sizes: { standaloneIconWidth: iconWidth, standaloneIconWidth: iconHeight },
  } = theme;

  const toggleLabelIcon = <ToggleLabel fill={buttonFill} width={iconWidth} height={iconHeight} />;
  const focusOnIcon = <FocusOnSelected fill={buttonFill} width={iconWidth} height={iconHeight} />;

  return (
    <Toolbar>
      <ToolbarButton
        icon={<MdOutlineHideImage size="18" color={buttonFill} />}
        tooltip={!showOverview ? "Show Overview" : "Hide Overview"}
        onClick={onToggleOverview}
        toggled={!showOverview}
      />{" "}
      <ToolbarSeparator />
      <ZoomButtonsGroup zoomIn={onZoomIn} resetZoom={onResetZoom} zoomOut={onZoomOut} fitContent={onFitContent} />
      <ToolbarSeparator />
      <LayoutButtonsGroup
        organicLayout={onOrganicLayout}
        radialLayout={onRadialLayout}
        circularLayout={onCircularLayout}
        currentLayout={currentLayout}
      />
      <ToolbarSeparator />
      <ToolbarButton icon={toggleLabelIcon} tooltip="Show/Hide Labels" onClick={onToggleLabels} toggled={!showLabels} />
      <ToolbarSeparator />
      <ToolbarButton
        icon={focusOnIcon}
        tooltip="Focus On Selected"
        onClick={onHighlightNodes}
        toggled={isFocused}
        disabled={!focusEnabled}
      />
      <ToolbarButton
        icon={
          <BsFillTriangleFill
            size="18"
            color={AgConstants.NODE_TYPE_STYLE_MAP[TYPE.attack].color}
            style={{ transform: "rotate(180deg)" }}
          />
        }
        tooltip="Toggle Attacks"
        onClick={() => onToggleAttacksVisibility()}
        toggled={isAttacksVisible}
      />
    </Toolbar>
  );
};
