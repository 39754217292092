import * as Brm from "services/brm";
import * as BrmGql from "generated/graphql";
import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import convertedPercent from "utils/percent-converter";
import { sortByRiskDistributionRank } from "utils/sorting";
import { COMMON } from "constants/brm";
import * as KeyFactory from "./node-query-key-factory";

interface IGetNodesWithRiskAndControls {
  projectId: string | null;
  variantId: string | null;
  allNodesData: BrmGql.GetAllNodesQuery | undefined;
}

export const getNodesWithRiskAndControls = async ({
  projectId,
  variantId,
  allNodesData,
}: IGetNodesWithRiskAndControls) => {
  const riskData = await Brm.riskApi.findRisk(projectId, { variant: variantId });
  // Find if risk with score of 1 exists.
  const riskHasBeenRun = riskData.some((r: any) => r.rank?.calcValue === 1);
  if (riskHasBeenRun) {
    // use the zero values in distribution
    const updatedNodeData = allNodesData?.nodes.map((node: any) => {
      const n = { ...node, riskDistributionPercent: node.riskDistributionPercent * 100 };
      const percent = node.riskDistributionPercent * 100;
      n.riskDistributionPercent = convertedPercent(percent);

      return n;
    });
    updatedNodeData?.sort(sortByRiskDistributionRank);
    let i = 1;

    // Use the sorted node data to update ranking to start at 1
    const updatedNodeDataRanking = updatedNodeData!.map((node: any) => {
      if (node.riskDistributionRank > 0) {
        node.riskDistributionRank = i;
        i += 1;
      }
      return node;
    });
    return updatedNodeDataRanking;
  }
  // set risk distribution values to N/A
  const updatedNodeData = allNodesData?.nodes.map((node: any) => {
    node.riskDistributionRank = COMMON.na;
    node.riskDistributionPercent = COMMON.na;

    return node;
  });
  return updatedNodeData;
};

interface INodesWithRiskAndControlsProps {
  projectId: string;
  variantId: string;
  options?: QueryOption<typeof getNodesWithRiskAndControls>;
}

/**
 * Custom-hook to retrieve all nodes for a project with additional risk data.
 *
 * @param {string} projectId - uuid of project
 * @param {string} variantId - uuid of project variant
 * @param {object} options - react-query configuration object
 * @returns react-query for findNode
 */
export const useNodesWithRiskAndControls = ({ projectId, variantId, options = {} }: INodesWithRiskAndControlsProps) => {
  const allNodesQuery = BrmGql.useGetAllNodesQuery(
    { project: projectId, variant: variantId },
    { enabled: !!projectId && !!variantId }
  );

  const userEnabled = options?.enabled || true;
  const { data } = allNodesQuery;

  const nodesWithRiskAndControls = ReactQuery.useQuery({
    ...options,
    enabled: userEnabled && allNodesQuery?.data !== undefined,
    queryKey: KeyFactory.nodeKeys.riskWithControl(projectId, variantId, data),
    queryFn: () => getNodesWithRiskAndControls({ projectId, variantId, allNodesData: data }),
  });

  if (allNodesQuery.isError) {
    return allNodesQuery;
  }

  return nodesWithRiskAndControls;
};
