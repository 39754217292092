import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./exchange-query-key-factory";

interface IGetExchangeVulnerabilities {
  exchangeId: string;
  variantId: string;
}

/**
 * Fetches the list of vulnerabilities data for the given node
 *
 * @param {string} exchangeId - uuid of exchange
 * @param {string} variantId - uuid of project variant
 * @returns a list of vulnerabilities
 */
export const getExchangeVulnerabilities = ({ exchangeId, variantId }: IGetExchangeVulnerabilities) => {
  return Brm.exchangeApi.getExchangeAllVulnerability(exchangeId, { variant: variantId });
};

interface IUseExchangeVulnerability {
  variantId: string;
  exchangeId: string;
  options?: QueryOption<typeof getExchangeVulnerabilities>;
}

/**
 *  Custom-hook to retrieve the list of vulnerabilities for a given node
 *
 * @param {string} variantId - uuid of project variant
 * @param {string} exchangeId - uuid of the exchange to retreive vulnerabilities
 * @param {object} config - react-query configuration object
 * @returns a list of vulnerabilities
 */
export const useExchangeVulnerabilities = ({ variantId, exchangeId, options = {} }: IUseExchangeVulnerability) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exchangeKeys.vulnerability(exchangeId, variantId),
    queryFn: () => getExchangeVulnerabilities({ exchangeId, variantId }),
  });
};
