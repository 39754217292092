import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./sap-person-query-key-factory";

interface IGetSapPersons {
  organizationId?: string;
}

/**
 * Gets the sap person
 */
export const getSapPersons = ({ organizationId }: IGetSapPersons) => {
  if (organizationId) {
    return Brm.sapPersonApi.findSapPerson({ orgId: organizationId });
  }
  return Brm.sapPersonApi.findSapPerson();

  // return [
  //   {
  //     id: "0",
  //     guid: "",
  //     dutyorg: "Corp1",
  //     namefirst: "Bob",
  //     namemiddle: "J",
  //     namelast: "Fry",
  //     scn: "",
  //     createdAt: undefined,
  //     modifiedAt: undefined,
  //   },
  //   {
  //     id: "1",
  //     guid: "",
  //     dutyorg: "Corp2",
  //     namefirst: "Tom",
  //     namemiddle: "J",
  //     namelast: "Von Bron",
  //     scn: "",
  //     createdAt: undefined,
  //     modifiedAt: undefined,
  //   },
  //   {
  //     id: "2",
  //     guid: "",
  //     dutyorg: "Corp3",
  //     namefirst: "Hill",
  //     namemiddle: "J",
  //     namelast: "Billy",
  //     scn: "",
  //     createdAt: undefined,
  //     modifiedAt: undefined,
  //   },
  // ];
};

type FunctionType = typeof getSapPersons;
interface IPersonsProps {
  organizationId?: string;
  options?: QueryOption<FunctionType>;
}

/**
 * Custom hook for getting all sap Persons
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useGetSapPersons = ({ organizationId, options = {} }: IPersonsProps = {}) => {
  const key = organizationId ? KeyFactory.sapPersonKeys.org(organizationId) : KeyFactory.sapPersonKeys.all;

  return ReactQuery.useQuery({
    ...options,
    queryKey: key,
    queryFn: () => getSapPersons({ organizationId }),
  });
};
