import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { threatEventPatternApi } from "services/brm/threat-event-service";
import errorUtils from "utils/error-utils";
import Title from "components/elements/StyledTitle";
import {
  DetailsContainer,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { RoutePath } from "routes/route-paths";
import ThreatEventPatternDetails from "../details/ThreatEventPatternDetails";

const listUrl = RoutePath.ThreatEventPatterns;

function ThreatEventPatternFullDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(listUrl);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    async function getData() {
      setRefresh(false);

      try {
        const res = await threatEventPatternApi.getThreatEventPatternByIDWithHttpInfo(id);

        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setSelectedElement(res.data);
        }
      } catch (err) {
        console.error("Error in getThreatEventPatternByIDWithHttpInfo: ", err);
      }
    }
    if (refresh) {
      getData();
    }
  }, [refresh, setRefresh, id]);

  return (
    <>
      <Title id="ThreatEventPatternFullDetails_title">{selectedElement.name}</Title>

      {selectedElement.id && (
        <DetailsContainerParent showSummaryTable={false}>
          <DetailsContainer>
            <ThreatEventPatternDetails selectedElement={selectedElement} />
          </DetailsContainer>
        </DetailsContainerParent>
      )}
    </>
  );
}

export default ThreatEventPatternFullDetails;
