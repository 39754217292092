import useToggle from "hooks/useToggle";
import * as React from "react";
import { GraphComponent, GraphOverviewComponent } from "yfiles";
import { FaChevronRight, FaTimes } from "react-icons/fa";
import * as S from "./DiagramOverview.styles";

interface DiagramOverviewProps {
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  visible?: boolean;
  graphComponent: GraphComponent;
  onClose?: () => void;
}

export const DiagramOverview = ({
  left = 20,
  top = 50,
  width = 220,
  height = 200,
  graphComponent,
  visible = true,
  onClose = () => {},
}: DiagramOverviewProps) => {
  const [collapsed, toggleCollapsed] = useToggle(false);
  const overviewDivRef = React.useRef<HTMLDivElement | null>(null);
  const [, setGraphOverviewComponent] = React.useState<GraphOverviewComponent | undefined>(undefined);

  React.useEffect(() => {
    if (visible) {
      setGraphOverviewComponent(new GraphOverviewComponent(overviewDivRef.current!, graphComponent));
    }
  }, [graphComponent, visible]);

  const onHeaderClick = () => {
    toggleCollapsed();
  };

  const onCloseClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };

  if (!visible) {
    return null;
  }

  return (
    <S.OverviewAbsoluteContainer left={left} top={top} collapsed={collapsed}>
      <S.OverviewContainer>
        <S.OverviewHeader onClick={onHeaderClick}>
          <S.OverviewChevronContainer collapsed={collapsed}>
            <FaChevronRight />
          </S.OverviewChevronContainer>
          Overview
          <S.OverviewCloseContainer onClick={onCloseClick}>
            <FaTimes />
          </S.OverviewCloseContainer>
        </S.OverviewHeader>
        <S.OverviewComponent ref={overviewDivRef} width={width} height={height} />
      </S.OverviewContainer>
    </S.OverviewAbsoluteContainer>
  );
};
