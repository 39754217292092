export const exchangeKeys = {
  all: ["exchange"] as const,
  parent: (exchangeId: string) => [...exchangeKeys.all, exchangeId, "parent"] as const,
  project: (projectId: string) => [...exchangeKeys.all, projectId] as const,
  variant: (projectId: string, variantId: string) => [...exchangeKeys.all, projectId, variantId] as const,
  controls: (projectId: string, variantId: string) => [...exchangeKeys.all, projectId, variantId, "controls"] as const,
  risks: (projectId: string, variantId: string) => [...exchangeKeys.all, projectId, variantId, "risks"] as const,
  vulnerability: (exchangeId: string, variantId: string) =>
    [...exchangeKeys.all, exchangeId, variantId, "vulnerability"] as const,
  exchange: (exchangeId: string | null) => [...exchangeKeys.all, exchangeId] as const,
  data: (exchangeId: string | null) => [...exchangeKeys.all, exchangeId, "data"] as const,
  category: (exchangeId: string | null) => [...exchangeKeys.all, exchangeId, "category"] as const,
  producer: (exchangeId: string | null) => [...exchangeKeys.all, exchangeId, "producer"] as const,
  consumer: (exchangeId: string | null) => [...exchangeKeys.all, exchangeId, "consumer"] as const,
  actProducer: (exchangeId: string | null) => [...exchangeKeys.all, exchangeId, "producerActivity"] as const,
  actConsumer: (exchangeId: string | null) => [...exchangeKeys.all, exchangeId, "consumerActivity"] as const,
};
