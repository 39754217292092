import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100%;
  height: auto;
  margin: 10px auto;
  cursor: ${(props) => (props.isInGraph ? "default" : "grab")};
  display: inline-block;
  white-space: nowrap;

  & .yfiles-drag {
    z-index: 15;
    opacity: 0.35;
    transition: opacity 0.2s ease-in-out;
  }

  /* &:selected {
    background-color: blue;
  } */
`;

const NameDiv = styled.div`
  display: inline-block;
  padding-left: 10px;
`;

export { StyledDiv, NameDiv };
