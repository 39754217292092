/**
 * Control Coordinator Help contents -  this is a key value pair mapping help videos to each page , where key is the "contentKey" which is unique to each page
 */

export const CntrlCoordinatorHelpContents = {
  standardBaselines: {
    contents: [
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Add a standard baseline",
        filePath: "/help-assets/CC/AddAStandardBaseline/AddAStandardBaseline_player.html",
      },
    ],
  },

  standardBaselineFullDetails: {
    contents: [
      {
        role: "Control Coordinator",
        type: "Video",
        name: "View standard baseline details",
        filePath: "/help-assets/CC/ViewStandardBaselineDetails/ViewStandardBaselineDetails_player.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Add control type to standard baseline",
        filePath: "/help-assets/CC/AddControlTypesToStandardBaseline/AddControlTypesToStandardBaseline_player.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Import controls into a standard baseline",
        filePath: "/help-assets/CC/ImportControlsIntoAStandardBaseline/ImportControlsIntoAStandardBaseline.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Delete control type from a standard baseline",
        filePath:
          "/help-assets/CC/DeleteControlTypeFromStandardBaseline/DeleteControlTypeFromStandardBaseline_player.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Merging baselines",
        filePath: "/help-assets/CC/mergingBaselines/mergingBaselines_player.html",
      },
    ],
  },

  projectCatalogConfig: {
    contents: [
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Set a baseline For project",
        filePath: "/help-assets/CC/SetBaselineForProject/SetBaselineForProject_player.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Set control catalog for project",
        filePath: "/help-assets/CC/SetControlCatalogForProject/SetControlCatalogForProject_player.html",
      },
    ],
  },

  controlCatalogs: {
    contents: [
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Clone a control catalog",
        filePath: "/help-assets/CC/cloneControlCatalog/cloneControlCatalog_player.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Add a custom control catalog",
        filePath: "/help-assets/CC/AddACustomControlCatalog/AddACustomControlCatalog_player.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Review a control catalog",
        filePath: "/help-assets/CC/ReviewControlCatalogs/ReviewControlCatalogs_player.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Upload to a custom control catalog",
        filePath: "/help-assets/CC/UploadToACustomControlCatalog/UploadToACustomControlCatalog_player.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Export Import control catalog",
        filePath: "/help-assets/CC/exportImportControlCatalog/exportImportControlCatalog_player.html",
      },
    ],
  },

  controlType: {
    contents: [
      {
        role: "Control Coordinator",
        type: "Video",
        name: "View control type details",
        filePath: "/help-assets/CC/ViewControlTypeDetails/ViewControlTypeDetails_player.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Add a control type",
        filePath: "/help-assets/CC/AddAControlType/AddAControlType_player.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Delete a control Type",
        filePath: "/help-assets/CC/DeleteControlType/DeleteControlType_player.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Set control type strength",
        filePath: "/help-assets/CC/SetControlTypeStrength/SetControlTypeStrength_player.html",
      },
    ],
  },

  controlTypeFullDetails: {
    contents: [
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Edit control type details",
        filePath: "/help-assets/CC/EditControlTypeFromDetails/EditControlTypeFromDetails_player.html",
      },
    ],
  },

  controlFamily: {
    contents: [
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Add a control family",
        filePath: "/help-assets/CC/AddAControlFamily/AddAControlFamily_player.html",
      },
      {
        role: "Control Coordinator",
        type: "Video",
        name: "Delete a control family",
        filePath: "/help-assets/CC/DeleteControlFamily/DeleteControlFamily_player.html",
      },
    ],
  },

  controlFamilyFullDetails: {
    contents: [
      {
        role: "Control Coordinator",
        type: "Video",
        name: "View control family details",
        filePath: "/help-assets/CC/ViewControlFamilyDetails/ViewControlFamilyDetails_player.html",
      },
    ],
  },
};
