import { convertToLevel } from "utils/calibration-converter";
import { riskLevelMapper } from "features/risk-model";
import { IExportTableData, IHeader, IRow } from "../../types";
import { EXPORT_RANGE } from "../../constants";

const HEADER_TYPE = {
  actions: "Actions",
};

export function processData(tableData: IExportTableData | undefined, type: string): {}[] {
  let data: { [key: string]: string }[] = [];
  if (tableData !== undefined && Array.isArray(tableData.rawRows) && Array.isArray(tableData.rawHeaders)) {
    let rawData = tableData.rawRows;
    if (Array.isArray(tableData.unfilteredRows) && type === EXPORT_RANGE.allTable) {
      rawData = tableData.unfilteredRows;
    }
    const headers: { [key: string]: string }[] = [];
    tableData.rawHeaders[0].headers.forEach((header: IHeader) => {
      if (typeof header.Header === "string" && header.Header !== HEADER_TYPE.actions) {
        headers.push({ Header: header.Header, id: header.id });
      }
    });
    // console.log("tableData.originalName:", tableData.originalName, "headers:", headers, "rawData:", rawData);
    data = rawData.map((row: IRow) => {
      const processedRow: { [key: string]: string } = {};
      headers.forEach((header) => {
        if (tableData.originalName === "Risk") {
          switch (header.Header) {
            case "Percent":
              // Percent is coming back as a value between 0-1 --> therefore multiply by 100
              processedRow[header.Header] = (Number(row.values[header.id]) * 100).toFixed(1);
              break;
            case "Score":
              processedRow[header.Header] = Number(row.values[header.id]).toFixed(1);
              break;
            case "Mitigated Score":
              processedRow[header.Header] = Number(row.values[header.id]).toFixed(1);
              break;
            case "Impact Level":
              processedRow[header.Header] = convertToLevel(row.values[header.id], "impact");
              break;
            case "Likelihood Level":
              processedRow[header.Header] = convertToLevel(row.values[header.id], "likelihood");
              break;
            case "Risk Level":
              processedRow[header.Header] = riskLevelMapper(row.values[header.id]);
              break;
            default:
              processedRow[header.Header] = row.values[header.id];
              break;
          }
        } else {
          processedRow[header.Header] = row.values[header.id];
        }
      });
      return processedRow;
    });
  }
  return data;
}
