import PropTypes from "prop-types";
import { useState } from "react";
import toast from "react-hot-toast";
// Components
import SelectBoxCategory from "components/forms/DynamicSelectBoxCategory";
import SelectBox from "components/forms/DynamicSelectBox";
import TextField from "components/forms/TextField";
// Constants
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
// State
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
// Services
import { SystemApi } from "features/brm";
import { SystemAssetCreateDto } from "@kdmanalytics/brm-system";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
// Utils
import { sortByName, sortUpperCase } from "utils/sorting";
import { validateNameUniqueness } from "features/system-model";

const AddSystemAssetForm = ({ setModalIsOpen }) => {
  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = useState("");
  const [isTextValid, setIsTextValid] = useState(true);
  const { data: systemAssets, isError: isSystemAssetsError } = SystemApi.useSystemAssets({
    projectId,
    options: { enabled: !!projectId },
  });
  const { data: sysAssetCategoriesList, isError: isSysAssetCategoriesListError } = SystemApi.useSystemAssetCategories({
    projectId,
    options: { enabled: !!projectId },
  });
  const { data: saTypesList, isError: isSaTypesError } = SystemApi.useSystemAssetsTypes({
    projectId,
    options: { enabled: !!projectId },
  });
  const { data: nodesList, isError: isNodesListError } = SystemApi.useNodes({
    projectId,
    config: { enabled: !!projectId },
  });
  const { data: exchangesList, isError: isExchangesListError } = SystemApi.useExchanges({
    projectId,
    options: { enabled: !!projectId },
  });
  const createSystemAsset = SystemApi.useCreateSystemAsset();
  const { mutate: setSystemAssetType } = SystemApi.useSetSystemAssetSystemAssetType({ projectId });

  function validate(formData) {
    let error = true;
    if (
      formData.name.value === "" ||
      formData.category.value === FORM_LABEL.placeholder ||
      formData.owner.value === FORM_LABEL.placeholder
    ) {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isNameValid = validateNameUniqueness(systemAssets, e.target.elements.name.value);
    if (!isNameValid) {
      setPostError(FORM_ERROR.duplicateSysAssetName);
      return null;
    }

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      let saType = null;

      if (e.target.elements.satype.value !== FORM_LABEL.placeholder) {
        saType = e.target.elements.satype.value;
      }

      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value || "",
        category: e.target.elements.category.value,
        owner: e.target.elements.owner.value,
      };
      const systemAssetCreateDto = SystemAssetCreateDto.constructFromObject(params);
      createSystemAsset.mutate(
        { projectId, systemAssetCreateDto },
        {
          onSettled: (data) => {
            if (saType) {
              setSystemAssetType({ saId: data, saType });
            }
            setModalIsOpen(false);
          },
          onError: (err) => {
            toast.error(`Creating system asset object caused an error: ${err}`);
          },
        }
      );
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  if (
    isSaTypesError ||
    isNodesListError ||
    isExchangesListError ||
    isSysAssetCategoriesListError ||
    isSystemAssetsError
  ) {
    return <ErrorBanner msg="Error while loading data for adding system asset" />;
  }

  if (nodesList && exchangesList && sysAssetCategoriesList && saTypesList) {
    return (
      <div>
        <form onSubmit={handleSubmit} action="">
          <FormStyled>
            <div className="form-style">
              <TextField
                label={{ id: "AddSystemAssetForm_name", name: FORM_LABEL.nameMandatory }}
                input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "AddSystemAssetForm_note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />

              <label id="AddSystemAssetForm_category">{FORM_LABEL.categoryMandatory}</label>
              <SelectBoxCategory
                id="AddSystemAssetForm_categoryDropdown"
                arrayOfData={sysAssetCategoriesList && sysAssetCategoriesList.sort(sortUpperCase)}
                item="category"
              />
              <label id="AddSystemAssetForm_owner">{FORM_LABEL.ownerMandatory}</label>
              <SelectBox
                id="AddSystemAssetForm_ownerDropdown"
                arrayOfData={nodesList.sort(sortByName).concat(exchangesList.sort(sortByName))}
                item="owner"
              />

              <label id="AddSystemAssetForm_systemAssetType">{FORM_LABEL.systemAssetTypeOptional}</label>
              <SelectBox
                id="AddSystemAssetForm_systemAssetTypeDropdown"
                arrayOfData={saTypesList.sort(sortByName)}
                item="satype"
              />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
            <DialogButtonStyled type="submit">Add</DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }

  return <Loading />;
};

AddSystemAssetForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};

export default AddSystemAssetForm;
