import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./user-query-key-factory";

interface IRemoveUserAssignedRoleProps {
  userId: string | null;
  roleId: string | null;
}

/**
 * Remove a user assigned role
 *
 * @returns
 */
export const removeUserAssignedRole = ({ userId, roleId }: IRemoveUserAssignedRoleProps) => {
  return userId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.userApi.removeUserAssignedRoleWithHttpInfo(userId, roleId);
};

interface IUserRemoveAssignedRoleProps {
  options?: MutationOption<typeof removeUserAssignedRole>;
}

/**
 * Custom hook to remove a user assigned role.
 *
 * @returns react-query Mutation.
 */
export const useRemoveUserAssignedRole = ({ options }: IUserRemoveAssignedRoleProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.userKeys.user(variables.userId));
      queryClient.invalidateQueries(KeyFactory.userKeys.assignedRole(variables.userId));
      queryClient.invalidateQueries(KeyFactory.userKeys.all);
    },
    ...options,
    mutationFn: removeUserAssignedRole,
  });
};
