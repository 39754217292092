import * as ReactQuery from "@tanstack/react-query";
import { queryClient } from "libs/react-query";
import * as BrmGql from "generated/graphql";
import * as KeyFactory from "./user-query-key-factory";

interface ISetUserAssignedRole {
  userId: string;
  assignedRoleData: { id: string; name: string }[];
  selectedRole: { value: string; label: string }[] | null;
  deletedRole: { id: string; name: string | null }[] | null;
  setUserAssignedRoleMutation: (
    value: BrmGql.SetUserAssignedRoleMutationVariables
  ) => Promise<BrmGql.SetUserAssignedRoleMutation>;
}

/**
 * Add or delete user assigned role
 *
 * @returns
 */
export const setUserAssignedRole = async ({
  userId,
  assignedRoleData,
  selectedRole,
  deletedRole,
  setUserAssignedRoleMutation,
}: ISetUserAssignedRole) => {
  const assignedRole: string[] = [];
  assignedRoleData
    .filter((role) => {
      if (deletedRole?.length) {
        return !deletedRole?.some((roleToDelete) => roleToDelete.id === role.id);
      }
      return true;
    })
    .forEach((role) => {
      assignedRole.push(role.id);
    });
  selectedRole?.forEach((role) => {
    assignedRole.push(role.value);
  });
  let data = null;
  try {
    data = await setUserAssignedRoleMutation({ userId, assignedRole });
  } catch (err) {
    console.error(err);
  }
  return data;
};

interface IUseSetUserAssignedRole {
  userId: string;
  assignedRoleData: { id: string; name: string }[];
  selectedRole: { value: string; label: string }[] | null;
  deletedRole: { id: string; name: string | null }[] | null;
}

/**
 * Custom Hook to add and delete assigned role(s).
 *
 * @returns react-query Mutation.
 */
export const useSetUserAssignedRole = () => {
  const { mutateAsync: setUserAssignedRoleMutation } = BrmGql.useSetUserAssignedRoleMutation<string, string>();
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.userKeys.all);
    },
    mutationFn: async (variables: IUseSetUserAssignedRole) =>
      setUserAssignedRole({ ...variables, setUserAssignedRoleMutation }),
  });
};
