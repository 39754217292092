import React, { useState } from "react";

import SelectCategory from "components/forms/DynamicSelectBoxCategory";
import SelectBoxID from "components/forms/DynamicSelectBox";
import BooleanDropdown from "components/forms/BooleanDropdown";
import { stringToBoolean } from "utils/boolean-checker";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import { NodeCreateDto } from "@kdmanalytics/brm-system";
import PropTypes from "prop-types";
import Select from "react-select";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import TextField from "components/forms/TextField";
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
import { sortUpperCase } from "utils/sorting";
import { SystemApi } from "features/brm";
import { LoadingSpinner as Loading } from "components/elements";
import localforage from "localforage";
import { validateNameUniqueness } from "features/system-model";

const AddNodeForm = ({ setModalIsOpen }) => {
  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = useState("");
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isTextValid, setIsTextValid] = useState(true);
  const [isInternal, setIsInternal] = useState(true);

  const { data: elementCategories } = SystemApi.useElementCategories({
    projectId,
    options: {
      select: React.useCallback((data) => data.sort(sortUpperCase), []),
    },
  });

  const { data: structuralCategories } = SystemApi.useGetNodeStructuralCategories({ projectId });

  const { data: dataOptions } = SystemApi.useDataTypes({
    projectId,
    options: {
      select: React.useCallback((data) => data.map((d) => ({ value: d.id, label: d.name })), []),
    },
  });

  const { data: groupOptions } = SystemApi.useNodes({
    projectId,
    options: {
      select: React.useCallback((data) => data.map((d) => ({ value: d.id, label: d.name })), []),
    },
  });

  const createNode = SystemApi.useCreateNode();
  const setNodeParent = SystemApi.useSetNodeParent();
  const setNodeStoredData = SystemApi.useSetNodeStoredData();
  const setNodeGroup = SystemApi.useSetNodeGroup();

  const { data: nonLeafNodes } = SystemApi.useNodeParents({
    projectId,
  });

  const { data: nodes } = SystemApi.useNodes({
    projectId,
    options: { enabled: !!projectId },
  });

  // validate that all fields in the form have been completed
  function validate(formData) {
    let error = true;
    if (
      formData.name.value === "" ||
      formData.category.value === "Select category" ||
      formData.isInternal.value === "" ||
      formData.structcat.value === "Select structcat"
    ) {
      error = false;
    }
    return error;
  }

  const handleDataSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedData(items);
  };

  const handleGroupSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedGroup(items);
  };

  const handleIsInternalChanged = (event) => {
    const { value } = event.target;
    setIsInternal(value === "true");
  };

  const internalFilter = (n) => n.isInternal || n.id === null;
  const externalFilter = (n) => !internalFilter(n) || n.id === null;
  const nodeFilter = isInternal ? internalFilter : externalFilter;
  const filteredNodes = nonLeafNodes?.filter(nodeFilter);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isNameValid = validateNameUniqueness(nodes, e.target.elements.name.value);
    if (!isNameValid) {
      setPostError(FORM_ERROR.duplicateNodeName);
      return null;
    }

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value || "",
        category: e.target.elements.category.value,
        isInternal: stringToBoolean(e.target.elements.isInternal.value),
        structuralcat: e.target.elements.structcat.value,
      };

      let parent = "";

      if (e.target.elements.parent.value !== "Select Parent") {
        parent = e.target.elements.parent.value;
      }

      const dto = NodeCreateDto.constructFromObject(params);

      try {
        const nodeId = await createNode.mutateAsync({ projectId, nodeCreateDto: dto });
        if (parent !== "") {
          await setNodeParent.mutateAsync({ nodeId, parentId: parent === "-" ? null : parent });
        }

        Promise.all(
          selectedData.map((d) => {
            return setNodeStoredData.mutateAsync({ nodeId, dataId: d.value });
          })
        );
        Promise.all(
          selectedGroup.map((d) => {
            return setNodeGroup.mutateAsync({ nodeId, groupId: d.value });
          })
        );
        localforage.removeItem(`sd_layout_${projectId}`);
        setModalIsOpen(false);
      } catch (error) {
        console.error(error);
      }
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  };

  if (elementCategories && structuralCategories && dataOptions && groupOptions && nonLeafNodes) {
    return (
      <div id="NodeAddForm" style={{ maxHeight: "95vh", overflow: "auto" }}>
        <form onSubmit={handleSubmit} action="" id="NodeAddForm_form">
          <FormStyled>
            <div className="form-style" id="NodeAddForm_content">
              <TextField
                label={{ id: "NodeAddForm_name", name: FORM_LABEL.nameMandatory }}
                input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "NodeAddForm_note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />

              <BooleanDropdown
                label={{ id: "NodeAddForm_isInternal", name: FORM_LABEL.internalMandatory }}
                select={{ id: "isInternal", name: "isInternal" }}
                onChange={handleIsInternalChanged}
              />

              <label id="NodeAddForm_category">{FORM_LABEL.categoryMandatory}</label>
              <SelectCategory
                id="NodeAddForm_categoryDropdown"
                arrayOfData={elementCategories}
                name="Category"
                item="category"
                defaultCategory="Basic"
              />

              <label id="NodeAddForm_structualCategory">{FORM_LABEL.structuralCategoryMandatory}</label>
              <SelectCategory
                id="NodeAddForm_structuralCategoryDropdown"
                arrayOfData={structuralCategories}
                name="Structural Category"
                item="structcat"
                defaultCategory="Leaf"
              />

              <label id="NodeAddForm_Parent">{FORM_LABEL.parentOptional}</label>
              <SelectBoxID id="NodeAddForm_parentDropdown" arrayOfData={filteredNodes} name="Parent" item="parent" />

              <label id="NodeAddForm_stroredData">{FORM_LABEL.storedDataOptional}</label>
              <Select
                id="NodeAddForm_dataDropdown"
                classNamePrefix="dataDropdown"
                isMulti
                options={dataOptions}
                defaultValue={selectedData}
                onChange={handleDataSelectChange}
              />
              <br />

              <label id="NodeAddForm_group">{FORM_LABEL.groupOptional}</label>
              <Select
                id="NodeAddForm_groupDropdown"
                classNamePrefix="groupDropdown"
                isMulti
                options={groupOptions}
                defaultValue={selectedGroup}
                onChange={handleGroupSelectChange}
              />
              <br />
            </div>
          </FormStyled>
          <FormStyledError id="NodeAddForm_error">{postError}</FormStyledError>
          <DialogButtonDivStyled id="NodeAddForm_buttons">
            <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="NodeAddForm_cancelButton">
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled type="submit" id="NodeAddForm_addButton">
              Add
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }
  return <Loading />;
};

AddNodeForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};

export default AddNodeForm;
