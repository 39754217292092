import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1px;
`;
