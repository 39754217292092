import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./resource-query-key-factory";

/**
 * Returns list of resources
 *
 * @param {string} projectId - uuid of project
 */
export const getResources = ({ projectId }: { projectId: string }) => {
  return Brm.resourceApi.findResource(projectId);
};

interface IResourceProps {
  projectId: string;
  options?: QueryOption<typeof getResources>;
}

/**
 * Returns a list of resources
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query configuration object
 */
export const useResources = ({ projectId, options = {} }: IResourceProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.resourceKeys.all,
    queryFn: () => getResources({ projectId }),
  });
};
