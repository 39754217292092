import { useMemo } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
// Components
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
// Utils
import { BrmDetailTable } from "brm/tables/BrmTables";
import { createColumnName, createColumnCiaTriadMapped } from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";

import { SystemApi } from "features/brm";

interface ICategorizedEntityTableProps {
  selectedElement: any;
  tableTitle?: string;
}

export const CategorizedEntityObjectiveDetailsTable = ({
  selectedElement,
  tableTitle,
}: ICategorizedEntityTableProps) => {
  const projectId = useRecoilValue(projectIdState);
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const { data, error } = SystemApi.useCategorizedEntities({
    projectId,
    objectiveId: selectedElement.id,
    options: { enabled: !!projectId },
  });

  const columns = useMemo(() => {
    return [
      createColumnName(selectedInstance.id, setSelectedInstance, false, null, "categorizationEntity"),
      createColumnCiaTriadMapped(selectedElement.name.toLowerCase()),
    ];
  }, [selectedElement.name, selectedInstance.id, setSelectedInstance]);

  if (error) {
    return <ErrorBanner msg="Error while loading Categorized Entity Data" />;
  }

  if (data) {
    return (
      <S.DetailsContainer id="CategorizedEntityTable_detailsPanel">
        <BrmDetailTable
          data={data}
          columns={columns}
          customProps={{ id: "CategorizedEntityTable_detailsTable" }}
          showRowSelect={false}
          tableTitle={tableTitle}
        />
      </S.DetailsContainer>
    );
  }
  return <Loading />;
};
