import { useState, useEffect } from "react";
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { userRoleState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { userRoles } from "services/brm/admin-service";
import { AddButton, DeleteButton } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { securityObjectiveApi } from "services/brm/categorization-service";
import { TYPE } from "constants/brm";

import ContentLayout from "brm/layout/Content/ContentLayout";

import SecurityObjectiveTable from "./tables/SecurityObjectiveListTable";
import SecurityObjectiveAddForm from "./forms/SecObjectiveAddForm";

function SecurityObjectives() {
  const userRole = useRecoilValue(userRoleState);
  const cyberSecurityExpertRole = userRoles.properties[userRoles.CYBERSECURITY_EXPERT].key;
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  useEffect(() => {
    async function multiDeleteApiCalls() {
      Promise.all(selectedRows.map((each) => securityObjectiveApi.deleteSecurityObjectiveWithHttpInfo(each.id)))
        .then(() => {
          setConfirmDelete(false);
          setDeleteModalIsOpen(false);
          setRefresh(true);
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, selectedRows, setSelectedInstance]);

  const addSecurityObjective = () => {
    setModalIsOpen(true);
  };

  const securityObjectiveHeader = (
    <Title id="SecurityObjectiveList_title">
      Security Objectives
      <Buttons id="SecurityObjectiveList_buttons">
        {userRole && userRole === cyberSecurityExpertRole ? (
          <>
            <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />
            <AddButton onClick={addSecurityObjective} />
          </>
        ) : (
          <br />
        )}
      </Buttons>
    </Title>
  );

  const securityObjectiveMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "SecurityObjectiveList_panel",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.securityObjective}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.securityObjective
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "SecurityObjectiveList_panel",
          modalIsOpen,
          setModalIsOpen,
          <SecurityObjectiveAddForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} refresh={refresh} />,
          TYPE.securityObjective
        )}

      <SecurityObjectiveTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return (
    <ContentLayout pageId="SecurityObjectiveList_panel" header={securityObjectiveHeader} main={securityObjectiveMain} />
  );
}

export default SecurityObjectives;
