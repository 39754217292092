/* eslint-disable react/prop-types */
import * as React from "react";
import { ColumnDef, RowSelectionState, createColumnHelper } from "@tanstack/react-table";
import { SelectionTable } from "../SelectionTable";
import { IControlTypeData } from "../types";
import * as S from "./ControlTypeSelectionTable.styles";
import { ColumnId } from "../../modals/column-ids";
import { ColumnFilter } from "../../modals/ColumnFilter";

interface IControlTypeSelectionTableProp {
  controlTypes: IControlTypeData[];
  onRowSelect?: (value: RowSelectionState) => void;
  selectedControlTypes: Record<string, boolean>;
}

const columnHelper = createColumnHelper<IControlTypeData>();

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & React.HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <input type="checkbox" ref={ref} className={`${className} cursor-pointer`} {...rest} />;
}

const columns: ColumnDef<IControlTypeData, any>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  },
  columnHelper.accessor("vulnerabilitycategory", {
    id: ColumnId.category,
    header: "Category",
  }),
  columnHelper.accessor("family.name", {
    id: ColumnId.controlFamily,
    header: "Control Family",
  }),
  columnHelper.accessor("name", {
    id: ColumnId.controlType,
    header: "Control Type",
  }),
  columnHelper.accessor("strength", {
    id: ColumnId.strength,
    header: "Strength",
  }),
  columnHelper.accessor("cost", {
    id: ColumnId.cost,
    header: "Cost",
  }),
  columnHelper.accessor("targets", {
    id: ColumnId.targets,
    header: "Targets",
  }),
];

export const ControlTypeSelectionTable = ({
  controlTypes,
  onRowSelect,
  selectedControlTypes,
}: IControlTypeSelectionTableProp) => {
  const [rowCount, setRowCount] = React.useState(controlTypes.length);

  return (
    <>
      <S.TableContainer>
        <SelectionTable
          minHeight="310px"
          columns={columns}
          data={controlTypes}
          setRowCount={setRowCount}
          onRowSelectionChange={onRowSelect}
          rowSelection={selectedControlTypes}
          renderFilter={({ column, table }) => <ColumnFilter column={column} table={table} />}
        />
      </S.TableContainer>
      <S.FilterStateContainer>
        Showing {rowCount} control types of {controlTypes.length}
      </S.FilterStateContainer>
    </>
  );
};
