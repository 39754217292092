import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { SystemApi } from "features/brm";

export const setCategorizedEntityName = ({ id, name }) => {
  return Brm.categorizedEntityApi.setCategorizedEntityName(id, { body: name });
};

/**
 * Custom hook to change the name of a existing categorized entity
 * @returns react-query ReactQuery.useMutation
 */
export const useSetCategorizedEntityName = (projectId) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation(({ id, name }) => setCategorizedEntityName({ id, name }), {
    onMutate: async ({ id, name }) => {
      await queryClient.cancelQueries(SystemApi.categorizedEntityKeys.project(projectId));
      const previousValues = queryClient.getQueryData(SystemApi.categorizedEntityKeys.project(projectId));
      queryClient.setQueryData(SystemApi.categorizedEntityKeys.project(projectId), (old) =>
        old.map((data) => {
          if (id === data.id) {
            return {
              ...data,
              name,
            };
          }
          return data;
        })
      );
      return previousValues;
    },
    onError: (previousValues) =>
      queryClient.setQueryData(SystemApi.categorizedEntityKeys.project(projectId), previousValues),
    onSuccess: (data, params) => {
      queryClient.invalidateQueries(SystemApi.categorizedEntityKeys.project(projectId));
      queryClient.invalidateQueries(SystemApi.categorizedEntityKeys.categorizedEntity(params.id));
    },
  });
};
