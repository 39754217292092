import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./variant-query-key-factory";

interface IGetVariantsWithScores {
  projectId: string;
}

/**
 *
 * @param {string} projectId - uuid of the project
 * @returns list of variants's
 */
export const getVariantsWithScores = async ({ projectId }: IGetVariantsWithScores): Promise<any> => {
  let modifiedData = [];
  try {
    const data = await Brm.variantApi.findVariant(projectId);

    if (data.length > 0) {
      modifiedData = await Promise.all(
        data.map(async (x: any) => {
          const scoreData = await Brm.variantApi.getVariantScore(x.id, projectId);
          const mitigatedScoreData = await Brm.variantApi.getVariantMitigatedRisk(x.id, projectId);
          const ctrlCatalogData = await Brm.variantApi.getVariantCatalog(x.id);
          const baselineData = await Brm.variantApi.getVariantBaseline(x.id);
          return {
            ...x,
            score: scoreData,
            mitigatedRisk: mitigatedScoreData,
            controlCatalog: ctrlCatalogData,
            baseline: baselineData,
          };
        })
      );
    }
  } catch (err) {
    console.error("err in GET variants : ", err);
  }

  return modifiedData;
};

interface IUseVariantsWithScoress {
  projectId: string;
  options?: QueryOption<typeof getVariantsWithScores>;
}

/**
 * React-query hook for getting all variants
 *
 * @param {string} projectId - uuid of the project
 * @param {object} config - react-query configuration object
 * @returns react-query for getVariants
 */
export const useVariantsWithScores = ({ projectId, options = {} }: IUseVariantsWithScoress) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.variantsKeys.variantsWithScores(projectId),
    queryFn: () => getVariantsWithScores({ projectId }),
  });
};
