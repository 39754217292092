import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { SystemApi } from "features/brm";

/**
 * Sets categorization value of selected categorrized entity
 *
 * @param {string} catEntityId - uuid of the categorized Entity
 * @param {ImpactStatementCreateDto} impactStatementCreateDto - Impact statement
 */
export const setCategorizedEntityCategorization = ({ catEntityId, impactStatementCreateDto }) => {
  return Brm.categorizedEntityApi.addCategorizedEntityCategorization(catEntityId, { impactStatementCreateDto });
};

/**
 * Custom hook to change the categroization value of an existing categorized entity
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetCategorizedEntityCategorization = ({ config = {} } = {}) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation({
    ...config,
    mutationFn: setCategorizedEntityCategorization,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: SystemApi.categorizedEntityKeys.all });
    },
  });
};
