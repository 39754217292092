import * as Recoil from "recoil";
import * as React from "react";
// import * as AdminAtom from "atoms/atoms-admin";
// import * as ComponentAtom from "atoms/atoms-component";
import * as RiskAtom from "atoms/atoms-risk";

import { CONTENT_TYPE } from "atoms";

import { useLocalForage } from "hooks/useLocalForage";

import { PageSpinner } from "components/elements/PageSpinner";
import { TYPE } from "constants/brm";
import { CommonDiagramStyles as S, useContentKey, useUserCheckPermissions } from "features/diagram-common";
import { DiagramContentLayout, RiskApi, CONTENT_KEY, useProject, useVariant, useRoles } from "features/brm";
import useToggle from "hooks/useToggle";
import * as AgQuery from "../../api";
import { AttackDiagramComponent } from "../AttackDiagramComponent";
import { AttackDiagramToolbar } from "../AttackDiagramToolbar";
import NodeData from "../../model/NodeData";

const DEFAULT_CONFIG = {
  orphansVisible: false,
};

export const AttackDiagram = () => {
  const { isRiskAnalyst } = useRoles();
  useUserCheckPermissions(CONTENT_KEY.AttackDiagram);
  useContentKey(CONTENT_KEY.AttackDiagram);

  const [currentProject, { isActive }] = useProject();
  const { variantId } = useVariant();
  const projectId = currentProject?.id || "";

  const riskCalcStatus = Recoil.useRecoilValue(RiskAtom.riskCalcStatus);
  const resetRiskCalcStatus = Recoil.useResetRecoilState(RiskAtom.riskCalcStatus);
  const [{ status: configStatus, data: diagramConfig }, saveConfig] = useLocalForage(`ad_config`, DEFAULT_CONFIG);
  const [config, setConfig] = React.useState(null);
  const [isOverviewVisible, toggleOverviewVisibility] = useToggle(false);
  const configRef = React.useRef(null);

  const {
    data: isRiskCalculated,
    isLoading: checkingIfRiskHasBeenRun,
    refetch: refetchingRiskHasBeenRun,
  } = RiskApi.useRiskCalculated({
    projectId,
    variantId,
    options: {
      enabled: isRiskAnalyst,
    },
  });

  const { data: risksByCat } = AgQuery.useRisksByCategory(projectId, variantId, {
    refetchOnWindowFocus: false,
    enabled: isRiskCalculated && isRiskAnalyst,
  });

  const [initialNodeData, setInitialNodeData] = React.useState(() => null);

  React.useEffect(() => {
    configRef.current = config;
  }, [config]);

  React.useEffect(() => {
    if (configStatus === "resolved") {
      setConfig(diagramConfig);
    }
  }, [configStatus, diagramConfig]);

  const toggleOrphansVisible = () => {
    const newConfig = {
      ...configRef.current,
      orphansVisible: !configRef.current.orphansVisible,
    };
    setConfig(newConfig);
    configRef.current = newConfig;
    saveConfig(newConfig);
  };

  const createToolbar = (toolbarData) => {
    return (
      <AttackDiagramToolbar
        onZoomIn={toolbarData.onZoomIn}
        onZoomOut={toolbarData.onZoomOut}
        onResetZoom={toolbarData.onResetZoom}
        onFitContent={toolbarData.onFitContent}
        onOrganicLayout={toolbarData.onOrganicLayout}
        onCircularLayout={toolbarData.onCircularLayout}
        onRadialLayout={toolbarData.onRadialLayout}
        onToggleLabels={toolbarData.onToggleLabels}
        onExportAsImage={toolbarData.onExportAsImage}
        onRemoveAllNodes={toolbarData.onRemoveAllNodes}
        onAddAllNodes={toolbarData.onAddAllNodes}
        onFocusOnSelectedNodes={toolbarData.onFocusOnSelectedNodes}
        showLabels={toolbarData.showLabels}
        hasNodeSelection={toolbarData.hasNodeSelection}
        nodeFilterState={toolbarData.nodeFilterState}
        onNodeFilterChange={toolbarData.onNodeFilterChange}
        currentLayout={toolbarData.currentLayout}
        onToggleOverview={toolbarData.onToggleOverview}
        showOverview={toolbarData.showOverview}
        showOrphans={config?.orphansVisible}
        onToggleOrphans={toggleOrphansVisible}
      />
    );
  };

  let content = null;

  /*
   * Handle user change variant - reset the initialNodeData
   */
  React.useEffect(() => {
    setInitialNodeData(null);
  }, [variantId, projectId]);

  React.useEffect(() => {
    if (risksByCat?.length > 0 && isRiskCalculated) {
      const topRisk = risksByCat.reduce((acc, value) => {
        let currentTopRisk = acc;
        value.instances.forEach((r) => {
          if (currentTopRisk == null || r.rank.value < currentTopRisk.rank.value) {
            currentTopRisk = r;
          }
        });
        return currentTopRisk;
      }, null);
      // risks should be sorted... the first should be top risk.
      setInitialNodeData((prev) => {
        if (prev !== null && prev.length) {
          if (prev[0].id === topRisk.id) {
            return prev;
          }
        }
        return [
          new NodeData({
            id: topRisk.id,
            name: topRisk.name,
            type: TYPE.risk,
            category: topRisk.category,
            rank: { value: topRisk.score.value },
          }),
        ];
      });
    }
  }, [risksByCat, isRiskCalculated]);

  React.useEffect(() => {
    if (riskCalcStatus === "COMPLETED" && isActive) {
      // queryClient.invalidate(RiskApi.riskKeys.calculated(projectId, variantId));
      // refetchingRiskHasBeenRun();
      // Reset risk calc status state
      resetRiskCalcStatus();
    }
  }, [riskCalcStatus, refetchingRiskHasBeenRun, resetRiskCalcStatus, isActive]);

  if (checkingIfRiskHasBeenRun || riskCalcStatus === "STARTED") {
    content = (
      <S.CenterContainer>
        <PageSpinner />
      </S.CenterContainer>
    );
  } else if (!isRiskCalculated) {
    content = (
      <S.CenterContainer>
        Calculate Risks has not been run. To view the Attack Diagram please perform Calculate Risks.
      </S.CenterContainer>
    );
  } else if (initialNodeData) {
    content = (
      <AttackDiagramComponent
        showOverview={isOverviewVisible}
        onToggleOverview={toggleOverviewVisibility}
        toolbar={(toolbarData) => createToolbar(toolbarData)}
        initialData={initialNodeData}
      />
    );
  }

  return (
    <DiagramContentLayout contentKey="attackDiagram" type={CONTENT_TYPE.attack}>
      {content}
    </DiagramContentLayout>
  );
};
