import { Title } from "components/elements/Title";
import { useRoles } from "hooks";

import { InstallationTable } from "../../components/tables/InstallationTable";

export const Installations = () => {
  const { isSuperAdmin } = useRoles();
  if (isSuperAdmin) {
    return (
      <div id="Installation_page">
        <Title id="Installation_title">Installation</Title>
        <InstallationTable />
      </div>
    );
  }

  return null;
};
