import { useQuery } from "@tanstack/react-query";
import * as Brm from "services/brm";
import { BrmError, DetailTableType, SystemApi } from "features/brm";
import { COMMON } from "constants/brm";

interface IElementTopicProps {
  elementId: string;
  elementName: string;
  config: {};
}

interface IGetElementTopics {
  elementId: string;
  elementName: string;
}

interface ITopicMap {
  [index: string]: (id?: string) => any;
}

export const getElementTopics = async ({ elementId, elementName }: IGetElementTopics) => {
  const vulMap: ITopicMap = {
    [DetailTableType.RISKS.key]: (id) => Brm.riskApi.getRiskSubject(id),
    [DetailTableType.UNDESIRED_EVENTS.key]: (id) => Brm.undesiredEventApi.getUndesiredEventSubject(id),
    [DetailTableType.ASSETS.key]: (id) => Brm.assetApi.getAssetSubject(id),
    [DetailTableType.THREAT_EVENTS.key]: (id) => Brm.threatEventApi.getThreatEventSubject(id),
  };

  if (vulMap[elementName]) {
    const data = await vulMap[elementName](elementId);
    if (!Array.isArray(data)) {
      return data?.id === COMMON.nullUuid ? [] : [data];
    }
    return data;
  }

  throw new BrmError(`Unsupported element type: ${elementName}`);
};

export const useElementTopics = ({ elementId, elementName, config = {} }: IElementTopicProps) => {
  return useQuery({
    ...config,
    queryKey: SystemApi.topicKeys.element(elementId, elementName),
    queryFn: () => getElementTopics({ elementId, elementName }),
  });
};
