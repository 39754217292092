import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

/**
 * Gets a project's kb
 * @param {string} projectId - uuid of project
 */
export const getProjectKb = ({ projectId }: { projectId: string | null }) => {
  return Brm.projectApi.getProjectKb(projectId);
};

interface IProjectKbProps {
  projectId: string | null;
  options?: QueryOption<typeof getProjectKb>;
}

/**
 * Custom hook for getting the name of a project
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query options
 * @returns getProjectName
 */
export const useProjectKb = ({ projectId, options = { enabled: !!projectId } }: IProjectKbProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.kb(projectId, null),
    queryFn: () => getProjectKb({ projectId }),
  });
};
