/*
 * These are the constants used for adding new rules to attack rulesets in KB.
 * Hardcoded the values since there is no access to get these at KB level via REST requests.
 * Hence Nick suggested to keep these hardcoded.
 */

export const TREV_CAT_OPTIONS = [
  {
    label: "Node Event",
    value: "NodeEvent",
  },
  {
    label: "Information Event",
    value: "InformationEvent",
  },
  {
    label: "Channel Event",
    value: "ChannelEvent",
  },
  {
    label: "Exchange Event",
    value: "ExchangeEvent",
  },
  {
    label: "Command Control Event",
    value: "CommandControlEvent",
  },
  {
    label: "Activity Event",
    value: "ActivityEvent",
  },
];

export const TREV_PAT_OPTIONS = [
  {
    label: "Permanent Failure",
    value: "PermanentFailure",
  },
  {
    label: "Depleting Resources",
    value: "DepletingResources",
  },
  {
    label: "Pivoting",
    value: "Pivoting",
  },
  {
    label: "Corruption",
    value: "Corruption",
  },
  {
    label: "Loss",
    value: "Loss",
  },
  {
    label: "Disclosure",
    value: "Disclosure",
  },
  {
    label: "FailingSend",
    value: "FailingSend",
  },
  {
    label: "FailingReceive",
    value: "FailingReceive",
  },
  {
    label: "Sending Inappropriate",
    value: "SendingInappropriate",
  },
  {
    label: "Denying",
    value: "Denying",
  },
  {
    label: "Jamming",
    value: "Jamming",
  },
  {
    label: "Injecting",
    value: "Injecting",
  },
  {
    label: "Disclosing",
    value: "Disclosing",
  },
  {
    label: "Launching Inappropriate",
    value: "LaunchingInappropriate",
  },
  {
    label: "Failing Launch",
    value: "FailingLaunch",
  },
  {
    label: "Aborting",
    value: "Aborting",
  },
  {
    label: "Delaying Launch",
    value: "DelayingLaunch",
  },
  {
    label: "Failing Activity",
    value: "FailingActivity",
  },
  {
    label: "Delaying Activity",
    value: "DelayingActivity",
  },
  {
    label: "Performing Inappropriate",
    value: "PerformingInappropriate",
  },
  {
    label: "Delaying",
    value: "Delaying",
  },
  {
    label: "Failure Perform",
    value: "Failure Perform",
  },
  {
    label: "Delay Activity",
    value: "DelayActivity",
  },
];

export const ATCK_KIND_OPTIONS = [
  {
    label: "Cyber",
    value: "Cyber",
  },
  /* {
    label: "Social",
    value: "Social",
  }, */
  {
    label: "Physical",
    value: "Physical",
  },
  /* {
    label: "EMF",
    value: "EMF",
  }, */
];

export const ATCK_TACTIC_OPTIONS = [
  {
    label: " Abuse",
    value: "Abuse",
  },
  {
    label: "Exceeding Capacity",
    value: "ExceedingCapacity",
  },
  {
    label: "Damage",
    value: "Damage",
  },
  {
    label: "Modification",
    value: "Modification",
  },
  {
    label: "Loss",
    value: "Loss",
  },
  {
    label: "Information Gathering",
    value: "InformationGathering",
  },
];

export const SYS_ASSET_CAT_OPTIONS = [
  {
    label: "Command Control",
    value: "CommandControl",
  },
  {
    label: "Custom SW",
    value: "CustomSW",
  },
  {
    label: "Firmware",
    value: "Firmware",
  },
  {
    label: "Hardware",
    value: "Hardware",
  },
  {
    label: "Hazard",
    value: "Hazard",
  },
  {
    label: "Media",
    value: "Media",
  },
  {
    label: "Network",
    value: "Network",
  },
  {
    label: "Operating System",
    value: "OperatingSystem",
  },
  {
    label: "Physical Access",
    value: "PhysicalAccess",
  },
  {
    label: "Procedures",
    value: "Procedures",
  },
  {
    label: "UI",
    value: "UI",
  },
];

export const ATCKR_CAT_OPTIONS = [
  {
    label: "Careless",
    value: "Careless",
  },
  {
    label: "Clueless",
    value: "Clueless",
  },
  {
    label: "Counterfeit",
    value: "Counterfeit",
  },
  {
    label: "Custom SW Supplier",
    value: "CustomSWSupplier",
  },
  {
    label: "Environment",
    value: "Environment",
  },
  {
    label: "Equipment Failure",
    value: "EquipmentFailure",
  },
  {
    label: "External",
    value: "External",
  },
  {
    label: "Hardware Supplier",
    value: "HardwareSupplier",
  },
  {
    label: "Maintenance",
    value: "Maintenance",
  },
  {
    label: "Malicious",
    value: "Malicious",
  },
  {
    label: "Malware",
    value: "Malware",
  },
  {
    label: "Media Supplier",
    value: "MediaSupplier",
  },
  {
    label: "Network Supplier",
    value: "NetworkSupplier",
  },
  {
    label: "Operating System Supplier",
    value: "OperatingSystemSupplier",
  },
  {
    label: "Software Failure",
    value: "SoftwareFailure",
  },
  {
    label: "Supplier",
    value: "Supplier",
  },
  {
    label: "Targeted Virus",
    value: "TargetedVirus",
  },
];

export const ATCKR_CAT_TAG = {
  Careless: "i",
  Clueless: "i",
  Counterfeit: "c",
  CustomSWSupplier: "s",
  Environment: "v",
  EquipmentFailure: "f",
  External: "e",
  HardwareSupplier: "s",
  Maintenance: "m",
  Malicious: "i",
  Malware: "w",
  MediaSupplier: "s",
  NetworkSupplier: "s",
  OperatingSystemSupplier: "s",
  SoftwareFailure: "f",
  Supplier: "s",
  TargetedVirus: "t",
};

export const MEANS = [
  {
    label: "M1",
    value: "M1",
  },
  {
    label: "M2",
    value: "M2",
  },
  {
    label: "M3",
    value: "M3",
  },
  {
    label: "M4",
    value: "M4",
  },
  {
    label: "M5",
    value: "M5",
  },
];

export const OPPORTUNITIES = [
  {
    label: "O1",
    value: "O1",
  },
  {
    label: "O2",
    value: "O2",
  },
  {
    label: "O3",
    value: "O3",
  },
  {
    label: "O4",
    value: "O4",
  },
  {
    label: "O5",
    value: "O5",
  },
];

export const ATTACK_KIND = {
  cyber: "Cyber",
  social: "Social",
  physical: "Physical",
  emf: "EMF",
};
