import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./element-category-query-key-factory";

/**
 * Returns a list of element categories
 *
 * @param {string} projectId uuid of project
 * @returns list of element categories
 */
export const getElementCategories = ({ projectId }: { projectId: string | null }) => {
  return Brm.elementCategoryApi.findElementCategory(projectId);
};

interface IElementCategoriesProps {
  projectId: string | null;
  options?: QueryOption<typeof getElementCategories>;
}

/**
 * Returns list of element categories as react-query-data
 *
 * @param {string} projectId uuid of project
 * @param {object} options react-query configuration object
 * @returns list of element categories
 */
export const useElementCategories = ({ projectId, options = {} }: IElementCategoriesProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.elementCategoryKeys.project(projectId),
    queryFn: () => getElementCategories({ projectId }),
  });
};
