/**
 *  Creates a unique name for an exchange or node, to be used when adding element via the System Diagram.
 *
 * @param {string} name the starting name
 * @param {array} list an of elements (either node or exchange) that currently exist in the system
 * @returns {string} a unqie name
 */
export const getUniqueName = (name, list) => {
  let i = 0;

  const lastUnderscore = name.lastIndexOf("_");
  if (lastUnderscore > 0) {
    const possibleNumber = parseInt(name.substring(lastUnderscore), 10);
    if (!Number.isNaN(possibleNumber)) {
      i = possibleNumber + 1;
    }
  }

  let tmpName = name;
  // While name is not unique, append _i to the name and increment i as necessary until name is unique
  do {
    if (i > 0) {
      // eslint-disable-next-line no-param-reassign
      tmpName = `${name}_${i}`;
    }
    i += 1;
    // eslint-disable-next-line no-loop-func
  } while (list.some((item) => item.name === tmpName));

  return tmpName;
};
