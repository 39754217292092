import styled from "styled-components";

const FormStyledMsg = styled.div`
  font: ${({ theme }) => theme.fonts.primary};
  max-width: 400px;
  margin: 10px auto;
  border-radius: 5px;
`;

export default FormStyledMsg;
