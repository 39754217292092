import * as React from "react";
import * as BrmGql from "generated/graphql";
import * as Recoil from "recoil";
import { sctmIdState } from "atoms/atoms-mitigation";

import { GlobalControlApi, AdminApi, useProject, MitigationApi } from "features/brm";
import { mitigationLogger } from "libs/loglevel";
import { IManualMitigationState } from "../types";

import { toSelectedVulnerabilities } from "../selection-to-type";

interface IControlType2TargetMap {
  [key: string]: string[];
}

interface INamedElement {
  id: string;
}

function isCatalogSource(controlTypeSource: string) {
  return controlTypeSource === "catalog";
}

export const useControlTypeSelectionStep = (wizardState: IManualMitigationState) => {
  const [, { projectId }] = useProject();
  const [sctmId] = Recoil.useRecoilState(sctmIdState);

  mitigationLogger.debug("ProjectId", projectId);
  mitigationLogger.debug("sctmId", sctmId);

  const selectedVulnerabilities = toSelectedVulnerabilities(wizardState);
  const targets = selectedVulnerabilities.map((sv) => sv.target);

  mitigationLogger.debug("selectedVulnerabilities", selectedVulnerabilities);
  mitigationLogger.debug("targets", targets);

  const { data: targetNames } = BrmGql.useGetSystemElementNamesQuery(
    { ids: targets, project: projectId },
    { select: (data) => data.systemElementsByIds }
  );

  const { data: allocatedControls } = BrmGql.useGetSctmAllocatedControlsQuery(
    { sctm: sctmId || "" },
    {
      select: (data) => data.allocatedControls,
      enabled: !!sctmId,
      onError: () => {},
    }
  );

  mitigationLogger.debug("allocatedControls", allocatedControls);

  const { data: catalog } = AdminApi.useProjectCatalog({ projectId });

  const { data: catalogControlTypes } = GlobalControlApi.useCatalogControlTypes({
    catalogId: catalog?.id || "",
    options: {
      enabled: !!catalog,
      select: React.useCallback(
        (data: any) => {
          return data.filter((ct: any) =>
            selectedVulnerabilities.some((sv) => sv.category === ct.vulnerabilitycategory)
          );
        },
        [selectedVulnerabilities]
      ),
    },
  });

  const { data: sctmBaseline } = MitigationApi.useSctmBaseline({
    sctmId: sctmId || "",
    options: { enabled: !!sctmId },
  });

  const { data: baselineControlTypes } = MitigationApi.useBaselineControlTypes({
    baselineId: sctmBaseline?.id,
    options: {
      enabled: !!sctmBaseline?.id,
      select: React.useCallback(
        (data: any) => {
          return data.filter((ct: any) =>
            selectedVulnerabilities.some((sv) => sv.category === ct.vulnerabilitycategory)
          );
        },
        [selectedVulnerabilities]
      ),
    },
  });

  const activeControlTypes = React.useMemo(() => {
    if (
      !(
        catalogControlTypes &&
        baselineControlTypes &&
        allocatedControls &&
        Array.isArray(allocatedControls) &&
        targetNames
      )
    ) {
      return [];
    }
    // we need target id to name map since vulnerabilities only give the target id
    const targetNameMap = new Map();
    if (targetNames) {
      targetNames.forEach((tn) => targetNameMap.set(tn.id, tn));
    }

    // mitigationLogger.debug("targetNameMap", targetNameMap);

    // Create a mapping from control type id to target using the allocated controls to determine which controls are already applied which target
    const ct2targetMap: IControlType2TargetMap = {};
    allocatedControls.forEach((ac) => {
      if (ac) {
        const object = ac.anobject;

        if (ct2targetMap[ac.controltype.id]) {
          ct2targetMap[ac.controltype.id] = [...ct2targetMap[ac.controltype.id], object.id];
        } else {
          ct2targetMap[ac.controltype.id] = [object.id];
        }
      }
    });
    mitigationLogger.debug("ct2targetMap", ct2targetMap);

    const activeSource = isCatalogSource(wizardState.controlTypeSource) ? catalogControlTypes : baselineControlTypes;
    mitigationLogger.debug("activeSource", activeSource);

    // Filter out targets that already have the selected control type
    const filteredCt = activeSource.filter((ct: INamedElement) => {
      if (ct2targetMap[ct.id]) {
        if (ct2targetMap[ct.id].length !== targets.length) {
          return true;
        }
        const tgts = ct2targetMap[ct.id];
        return !tgts.every((t) => targets.find((tt) => t === tt));
      }
      return true;
    });

    mitigationLogger.debug("filteredCt", [...filteredCt]);

    // populate the list of targets for this control type
    filteredCt.forEach((ct: any) => {
      const targetIds = ct2targetMap[ct.id] || targets;
      const targetIdSet = new Set(targetIds);
      mitigationLogger.debug("ctid targetIdSet", ct.id, ct2targetMap[ct.id], targetIdSet);

      // if vulnerability and target do not match this ct remove from set.
      const targetIdsMatchingVuls = Array.from(targetIdSet).filter((t) => {
        return selectedVulnerabilities.find((v) => v.category === ct.vulnerabilitycategory && v.target === t);
      });

      // finally get the names for targets
      ct.targets = targetIdsMatchingVuls.map((id) => targetNameMap.get(id).name).join(", ");

      // if there still aren't targets assume the selected vulnerabilities are the targets
      if (!ct.targets) {
        ct.targets = selectedVulnerabilities.map((v) => targetNameMap.get(v.target).name).join(",");
      }
    });
    mitigationLogger.debug("filteredCt2", filteredCt);

    // finally if there are no matching targets remove this ct
    const res = filteredCt.filter((ct: any) => ct.targets !== "");
    return res;
  }, [
    allocatedControls,
    baselineControlTypes,
    catalogControlTypes,
    selectedVulnerabilities,
    targetNames,
    targets,
    wizardState.controlTypeSource,
  ]);

  mitigationLogger.debug("activeControlTypes", activeControlTypes);

  return {
    activeControlTypes,
    catalogControlTypes,
    baselineControlTypes,
    catalog,
    sctmBaseline,
  };
};
