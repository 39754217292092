import { useMutation } from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * Deletes an categroization  (impact statement) with the given uuid of selected element
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export const deleteCategorization = ({ securityObjectiveId, elementName, elementId }) => {
  return Brm.categorizedEntityApi.removeCategorizedEntityCategorization(elementId, securityObjectiveId);
};

/**
 * Custom hook to delete/ remove a categoriization for a selected element
 */
export const useDeleteCategorization = ({ config = {} }) => {
  return useMutation({
    ...config,
    mutationFn: deleteCategorization,
  });
};
