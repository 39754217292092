import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";

interface IGetControlsForNode {
  nodeId: string;
  variantId: string;
}

/**
 * Returns a list of controls applied to the given node
 *
 * @param {string} nodeId uuid of node
 * @param {string} variantId uuid of variant
 */
export const getControlsForNode = ({ nodeId, variantId }: IGetControlsForNode) => {
  return Brm.nodeApi.getNodeAllControl(nodeId, { variant: variantId });
};

interface IUseControlsForNode {
  nodeId: string;
  variantId: string;
  options?: QueryOption<typeof getControlsForNode>;
}

/**
 * React-query custom hook for retrieving controls for a node
 *
 * @param {string} nodeId - uuid of the node
 * @param {string} variantId - uuid of the variant
 * @param {object} options - react-query configuration object
 * @returns react-query
 */
export const useControlsForNode = ({ nodeId, variantId, options }: IUseControlsForNode) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.nodeKeys.controls(nodeId, variantId),
    queryFn: () => getControlsForNode({ nodeId, variantId }),
  });
};
