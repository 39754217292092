import styled from "styled-components";

export const VariantSelection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const Options = styled.div``;
export const OptionLabel = styled.label`
  padding-right: 5px;
`;
export const Selection = styled.div`
  float: right;
`;

export const DisplayArea = styled.div`
  max-height: 55vh;
  overflow-y: auto;
`;
