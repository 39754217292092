import Form from "react-bootstrap/Form";
import { RowSelectionState } from "@tanstack/react-table";

import { LoadingSpinner } from "components/elements";
import * as MS from "../RarManualMitigateForm.styles";
import { ControlTypeSelectionTable } from "../ControlTypeSelectionTable";
import * as S from "./ControlTypeSelectionStep.styles";
import { IManualMitigationState } from "../types";
import { useControlTypeSelectionStep } from "./useControlTypeSelectionStep";

interface IControlTypeSelectionStepProps {
  wizardState: IManualMitigationState;
  updateWizardState: (newData: Partial<IManualMitigationState>) => void;
}

const ControlTypeSource = {
  Baseline: "baseline",
  Catalog: "catalog",
};

export const ControlTypeSelectionStep = ({ wizardState, updateWizardState }: IControlTypeSelectionStepProps) => {
  const { activeControlTypes, catalog, sctmBaseline } = useControlTypeSelectionStep(wizardState);

  const handleRowSelect = (selectedRows: RowSelectionState) => {
    updateWizardState({ selectedControlTypes: selectedRows, controlTypes: activeControlTypes });
  };

  if (activeControlTypes && catalog && sctmBaseline) {
    return (
      <MS.ControlTypeStepContainer>
        <S.ControlTypeSourceContainer>
          <S.ControlTypeListLabel>Select Control Type Source:</S.ControlTypeListLabel>
          <S.CheckContainer>
            <Form.Check
              inline
              id="catalog"
              type="radio"
              label={`Catalog: ${catalog.name}`}
              checked={wizardState.controlTypeSource === ControlTypeSource.Catalog}
              onChange={() => {
                // resetSelectedControlTypes();
                updateWizardState({ controlTypeSource: ControlTypeSource.Catalog });
              }}
            />
            <Form.Check
              inline
              id="baseline"
              type="radio"
              label={`Baseline: ${(sctmBaseline as any).name}`}
              checked={wizardState.controlTypeSource === ControlTypeSource.Baseline}
              onChange={() => {
                // resetSelectedControlTypes();
                updateWizardState({ controlTypeSource: ControlTypeSource.Baseline });
              }}
            />
          </S.CheckContainer>
        </S.ControlTypeSourceContainer>
        <S.ControlTypeListLabel>Select Control Types:</S.ControlTypeListLabel>

        <ControlTypeSelectionTable
          controlTypes={activeControlTypes}
          onRowSelect={handleRowSelect}
          selectedControlTypes={wizardState.selectedControlTypes}
        />
      </MS.ControlTypeStepContainer>
    );
  }
  return <LoadingSpinner />;
};
