import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

export const getSystemElements = ({ projectId }) => {
  return Brm.systemElementApi.findSystemElement(projectId);
};

/**
 * React-query hook for getting all SystemElements
 *
 */
export const useSystemElements = ({ projectId, config = {} }) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["systemElements", projectId],
    queryFn: () => getSystemElements({ projectId }),
  });
};
