import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
// Components
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import SystemAssetDetails from "brm/system-model/system-assets/details/SystemAssetDetails";
import ButtonCell from "components/EditComponents/ButtonCell";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
// Styles
import Title from "components/elements/StyledTitle";

// Services
import { SystemApi, useRoles } from "features/brm";
// Utils
import {
  createColumnMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
// State
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import ShowHideButton from "components/elements/ShowHideButton";

// Constants
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { RoutePath } from "routes/route-paths";
import { TYPE } from "constants/brm";

function SystemAssetFullDetails() {
  const { isSystemEngineer, isRiskAnalyst, isThreatAnalyst } = useRoles();

  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedCategory = useRef("");
  const updatedSaType = useRef("");
  const updatedOwner = useRef("");
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const { data, error } = SystemApi.useSystemAsset({
    saId: id,
    setSelectedElement,
    options: { enabled: isSystemEngineer || isRiskAnalyst || isThreatAnalyst },
  });
  const { data: categoryList, isError: isCategoryListError } = SystemApi.useSystemAssetCategories({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst || isThreatAnalyst) },
  });
  const { data: ownersList, isError: isOwnersListError } = SystemApi.useNodes({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst || isThreatAnalyst) },
  });
  const { data: saTypeList, isError: isSaTypeListError } = SystemApi.useSystemAssetsTypes({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst || isThreatAnalyst) },
  });

  const { mutate: setSystemAssetCategory } = SystemApi.useSetSystemAssetCategory();
  const { mutate: setSystemAssetType } = SystemApi.useSetSystemAssetSystemAssetType();
  const { mutate: setSystemAssetOwner } = SystemApi.useSetSystemAssetOwner();

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedRowId}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedCategory.current !== "") {
              setSystemAssetCategory(
                {
                  saId: selectedRowId,
                  saCat: updatedCategory.current,
                },
                {
                  onSettled: () => {
                    updatedCategory.current = "";
                  },
                }
              );
            }

            if (updatedSaType.current !== "") {
              setSystemAssetType(
                {
                  saId: selectedRowId,
                  saType: updatedSaType.current,
                },
                {
                  onSettled: () => {
                    updatedSaType.current = "";
                  },
                }
              );
            }

            if (updatedOwner.current !== "") {
              setSystemAssetOwner(
                {
                  saId: selectedRowId,
                  owner: updatedOwner.current,
                },
                {
                  onSuccess: () => {
                    updatedOwner.current = "";
                  },
                }
              );
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [selectedRowId, editMode, setSystemAssetCategory, setSystemAssetType, setSystemAssetOwner]
  );

  const columns = useMemo(
    () => [
      createColumnCategoryMapped("category", selectedRowId, categoryList, editMode, updatedCategory),
      createColumnMapped("satype", selectedRowId, saTypeList, editMode, updatedSaType),
      createColumnMapped("owner", selectedRowId, ownersList, editMode, updatedOwner),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [createButton, editMode, selectedRowId, categoryList, saTypeList, ownersList]
  );

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(RoutePath.SystemAssets);
    }
  }, [projectId, navigate]);

  if (error || isCategoryListError || isOwnersListError || isSaTypeListError) {
    return <ErrorBanner msg="Error while loading system asset details" />;
  }

  if (data) {
    return (
      <>
        <Title id="SystemAssetFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "SystemAssetFullDetails_table" }}
              elementName={TYPE.systemAsset}
            />
          </DetailsContainerSingleTable>
        ) : (
          <></>
        )}

        {/* This conditional ensures that the details doesn't render until the selectedElement is assigned a value */}
        {selectedElement.id && (
          <DetailsContainerParent showSummaryTable={showSummaryTable}>
            <DetailsContainer>
              <SystemAssetDetails selectedElement={selectedElement} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
  }

  return <Loading />;
}

export default SystemAssetFullDetails;
