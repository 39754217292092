import styled from "styled-components/macro";

import { ChevronRight, ChevronLeft } from "@styled-icons/heroicons-solid";

export const MainContainer = styled.div`
  position: relative;
  z-index: 199;
`;

export const ExplorerContainer = styled.div`
  display: flex;
  overflow-y: auto;
`;

export const MatrixContainer = styled.div`
  display: flex;
`;

export const TransitionContainer = styled.div`
  position: relative;
  width: var(--leftSidebarWidth, 40px);
  margin-left: 0;
  z-index: 1;
  transition: ${(props) => (props.isDragging ? "" : "width 300ms cubic-bezier(0.2, 0, 0, 1) 0s")};
`;

export const ContentContainer = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.header.height};
  left: var(--leftPanelWidth, 0px);
  bottom: calc(${({ theme }) => theme.footer.height});
  width: var(--leftSidebarWidth, 40px);
  transition: ${(props) => (props.isDragging ? "" : "width 300ms cubic-bezier(0.2, 0, 0, 1) 0s")};
  background-color: #dddddd;
`;

export const LeftSidebarContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
`;

export const GlobalNavigation = styled.div``;

export const DrawerContainer = styled.div`
  display: flex;
  width: calc(100% - 43px);
`;

export const NavigationChevronRight = styled(ChevronRight)`
  color: ${({ theme }) => theme.colors.buttonPrimary};
  width: ${({ theme }) => theme.sizes.chevronWidth};
  height: ${({ theme }) => theme.sizes.chevronHeight};
`;

export const NavigationChevronLeft = styled(ChevronLeft)`
  color: ${({ theme }) => theme.colors.buttonPrimary};
  width: ${({ theme }) => theme.sizes.chevronWidth};
  height: ${({ theme }) => theme.sizes.chevronHeight};
`;

export const Drawer = styled.div`
  width: 100%;
  min-width: 100px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow-y: auto; */
`;
