export const getCustomStyles = (theme) => ({
  control: (base) => ({
    ...base,
    height: 27,
    minHeight: 27,
    flexWrap: "no-wrap",
    minWidth: "175px",
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999, width: "175px" }),
  option: (provided) => ({
    ...provided,
    padding: 5,
    width: "100%",
    height: "auto",
    fontFamily: theme.fonts.primary,
    fontSize: theme.fonts.default,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: theme.fonts.primary,
    fontSize: theme.fonts.default,
  }),
  noOptionsMessage: () => ({
    margin: 0,
    width: "auto",
    height: "auto",
    fontFamily: theme.fonts.primary,
    fontSize: theme.fonts.default,
    color: "black",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  dropdownIndicator: () => ({
    margin: 0,
    width: "auto",
    height: "auto",
    fontFamily: theme.fonts.primary,
    fontSize: theme.fonts.default,
    color: "black",
  }),
});
