import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./attacker-query-key-factory";

export interface ISetAttackerName {
  attackerId: string;
  name: string;
}

/**
 * sets name of the selected attacker
 * @param {string} attackerId - uuid of the attacker
 * @param {string} name - string value of attacker name
 *
 */
export const setAttackerName = ({ attackerId, name }: ISetAttackerName) => {
  return Brm.attackerApi.setAttackerName(attackerId, { body: name });
};

export interface IUseSetAttackerName {
  options?: MutationOption<typeof setAttackerName>;
}

/**
 * Custom hook to change the name of a existing Attacker
 * @param {object} options hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerName = ({ options }: IUseSetAttackerName = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.attackerKeys.all);
    },
    ...options,
    mutationFn: setAttackerName,
  });
};
