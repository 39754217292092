import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

/**
 * Returns the projects sap sandbox state
 * @param {string} projectId - uuid of project
 */
export const getProjectSapSandbox = ({ projectId }: { projectId: string | null }) => {
  return !projectId ? Promise.reject(new Error("Invalid id")) : Brm.projectApi.getsapsandboxProject(projectId);
};

type FunctionType = typeof getProjectSapSandbox;
interface IProjectSapProgams {
  projectId: string;
  options?: QueryOption<FunctionType>;
}

/**
 * Custom hook for getting the sap sandbox state
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useGetProjectSapSandbox = ({ projectId, options = {} }: IProjectSapProgams) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.sapSandbox(projectId),
    queryFn: () => getProjectSapSandbox({ projectId }),
  });
};
