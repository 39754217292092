import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

export interface ISetNodeIsInternal {
  nodeId: string;
  isInternal: boolean;
}

/**
 * Sets the isInternal property for node
 *
 * @param {string} nodeId - uuid of the node
 * @param {string} isInternal - isInternal value
 */
export const setNodeIsInternal = ({ nodeId, isInternal }: ISetNodeIsInternal) => {
  return Brm.nodeApi.setNodeIsInternal(nodeId, { body: isInternal });
};

export interface IUseSetNodeIsInternal {
  options?: MutationOption<typeof setNodeIsInternal>;
}

/**
 * Custom hook to change the isInternal property of an existing node (as well as it's parent)
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetNodeIsInternal = ({ options }: IUseSetNodeIsInternal = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.nodeKeys.all);
      queryClient.invalidateQueries(KeyFactory.nodeKeysGraphQl.getNodeParents);
      queryClient.invalidateQueries(KeyFactory.nodeKeysGraphQl.systemDiagramData);
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: setNodeIsInternal,
  });
};
