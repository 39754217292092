import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import Select from "react-select";
import { customStyles } from "components/elements/ReactSelectCustomStyles";
import { useModal } from "hooks";
// Utils
import { createColumnBooleanNoEditMapped, createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";

import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";

import { REPORT_NAME_FOR_EXPORT } from "constants/brm";

import { projectIdState } from "atoms/atoms-admin";
import getReactSelectArray from "utils/react-select-array";
import { useRecoilValue } from "recoil";
import * as S from "brm/styles/details-table.styles";
import { MitigationApi, AdminApi, ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { useGetDifferenceData, useGetVariantControls, useGetVariantDiff } from "./api/variant-diff-table-queries";
// Styles
import * as VS from "./VariantDiffTable.styles";

const VariantDiffTable = ({ selectedElement, tableTitle }) => {
  const [, setSelectedElements] = useState([]);
  const projectId = useRecoilValue(projectIdState);
  const [selectedVariant, setSelectedVariant] = useState("");
  const { isVisible, toggle } = useModal();
  const { disableExport, setExportTableData, handleTableExport, setFileNamePrefix } = useExportTable();
  const { data: variantList } = MitigationApi.useVariantsWithScores({ projectId });
  const { data: variant1AC } = useGetVariantControls(selectedElement?.id);
  const { data: variant2AC } = useGetVariantControls(selectedVariant?.value);
  const { data: diffInfo, isError: isDiffInfoError } = useGetDifferenceData(variant1AC, variant2AC);
  const getVariantsData = useGetVariantDiff(selectedElement.id, selectedVariant?.value);
  AdminApi.useProjectName({
    projectId,
    options: {
      onSuccess: (data) => {
        setFileNamePrefix(data.result);
      },
      enabled: !!projectId,
    },
  });

  const makeMitigatedRiskCell = ({ row }) => {
    if (row.original?.mitigatedRisk?.calcValue) {
      return row.original.mitigatedRisk.calcValue?.toFixed(1);
    }
    return <>-</>;
  };

  const columns2 = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      createColumnMappedNoEdit("cntrlCatalog"),
      {
        Header: "Baseline",
        accessor: "baseline",
      },
      createColumnBooleanNoEditMapped("isCompliance"),
      {
        Header: "Mitigated Risk",
        accessor: "mitigatedRisk.calcValue",
        Cell: makeMitigatedRiskCell,
      },
      {
        Header: "Total Allocated Controls",
        accessor: "controlsCount",
      },
      {
        Header: "SCTM Name",
        accessor: "sctm",
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Allocated Control",
        accessor: "name",
      },
      createColumnMappedNoEdit("controltype"),
      createColumnMappedNoEdit("strength"),
      createColumnMappedNoEdit("cost"),
      createColumnBooleanNoEditMapped("variant1", `Variant: ${selectedElement.name}`),
      createColumnBooleanNoEditMapped("variant2", `Variant: ${selectedVariant?.label}`),
    ],
    [selectedElement.name, selectedVariant?.label]
  );

  const handleVariantSelectChange = async (selectedItems = {}) => {
    setSelectedVariant(selectedItems);
  };

  let content = null;
  if (selectedVariant?.value) {
    content = (
      <S.DetailsContainer>
        {getVariantsData && diffInfo ? (
          <>
            <S.DetailsContainer id="VariantDiffTable_detailsPanel">
              <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
              <b>Summary</b>
              <>
                {/* <DetailsContainerSingleTable style={{ paddingBottom: "5px" }} id="VariantDiffTable_summaryPanel"> */}
                <BrmDetailTable
                  data={getVariantsData}
                  columns={columns2}
                  setSelectedElement={setSelectedElements}
                  customProps={{ id: "VariantDiffTable_summaryTable" }}
                  showRowSelect={false}
                  showPagination={false}
                  showRowCount={false}
                  showHeaderFilters={false}
                  simple
                />
                {/* </DetailsContainerSingleTable> */}
              </>
              <br />
              <b>Controls Table </b>
              <>
                <S.ActionContainer>
                  <S.DetailsTableContainer id="VarDiffRptTable_table">
                    <BrmDetailTable
                      data={diffInfo}
                      columns={columns}
                      setSelectedElement={setSelectedElements}
                      customProps={{ id: "VariantDiffTable_detailsTable" }}
                      showRowSelect={false}
                      tableTitle={tableTitle}
                      tableTitleForExport={`${REPORT_NAME_FOR_EXPORT.variantDiff}_${selectedVariant?.label}__${selectedElement.name}`}
                      setExportTableData={setExportTableData}
                    />
                  </S.DetailsTableContainer>
                  <S.DetailsTableButtonsContainer>
                    <ExportButton onClick={() => toggle()} disableExport={disableExport} />
                  </S.DetailsTableButtonsContainer>
                </S.ActionContainer>
              </>
            </S.DetailsContainer>
          </>
        ) : (
          <Loading />
        )}
      </S.DetailsContainer>
    );
  }

  if (isDiffInfoError) {
    return <ErrorBanner msg="Error while loading variants data" />;
  }

  if (variantList) {
    return (
      <>
        <VS.VariantSelection id="VariantDiffTable_selectionArea">
          <VS.Options id="VariantDiffTable_variant1Option">
            <VS.OptionLabel>
              <b>Variant 1:</b>
            </VS.OptionLabel>
            {selectedElement.name}
          </VS.Options>
          <VS.Options id="VariantDiffTable_variant2Option">
            <VS.OptionLabel>
              <b>Variant 2 :</b>
            </VS.OptionLabel>
            <VS.Selection id="VariantDiffTable_selection">
              <Select
                id="variantDiffTable_variantDropdown"
                classNamePrefix="variantDropdown"
                menuPortalTarget={document.body}
                isMulti={false}
                options={getReactSelectArray(variantList.filter((e) => e.id !== selectedElement.id))}
                value={selectedVariant}
                onChange={handleVariantSelectChange}
                placeholder="Select Variant..."
                styles={customStyles}
              />
            </VS.Selection>
          </VS.Options>
        </VS.VariantSelection>

        {content}
      </>
    );
  }

  return <Loading />;
};

VariantDiffTable.propTypes = {
  selectedElement: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  tableTitle: PropTypes.string,
};

export default VariantDiffTable;
