import toast from "react-hot-toast";
import { useState, useMemo, useRef, useCallback } from "react";
// React Query
import { useQueryClient } from "@tanstack/react-query";
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import ButtonCell from "components/EditComponents/ButtonCell";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import * as CategorizationApi from "brm/system-model/categorization/api";
import { SystemApi, CIA_TRIAD } from "features/brm";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";
import {
  createColumnName,
  createColumnMapped,
  createColumnActions,
  createColumnCiaTriadMapped,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";
import { useCanRename } from "features/system-model";

const CategorizedEntityTable = ({ setSelectedRows, setRefresh }) => {
  const { canRename } = useCanRename();
  const queryClient = useQueryClient();
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedClassification = useRef("");
  const projectId = useRecoilValue(projectIdState);
  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data, error } = SystemApi.useCategorizedEntities({
    projectId,
    options: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer) },
  });

  const { data: classification, isError: isClassificationError } = SystemApi.useClassificationCategories({
    projectId,
    options: {
      enabled: !!projectId && (isRiskAnalyst || isSystemEngineer),
    },
  });
  const { mutate: setCategorizedEntityName } = CategorizationApi.useSetCategorizedEntityName(projectId);
  const { mutate: setCategorizedEntityClassification } =
    CategorizationApi.useSetCategorizedEntityClassification(projectId);

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);
            if (updatedName.current !== "") {
              if (!canRename({ updatedName, elementsList: data })) return;

              setCategorizedEntityName(
                {
                  id: selectedInstance.id,
                  name: updatedName.current,
                },
                {
                  onSettled: () => {
                    setSelectedInstance({
                      id: selectedInstance.id,
                      name: updatedName.current,
                      type: selectedInstance.type,
                    });
                    updatedName.current = "";
                  },
                  onError: (err) => {
                    toast.error(`Setting categorized entity name caused an error: ${err}`);
                  },
                }
              );
            }

            if (updatedClassification.current !== "") {
              setCategorizedEntityClassification(
                {
                  id: selectedInstance.id,
                  classification: updatedClassification.current,
                },
                {
                  onSettled: async () => {
                    updatedClassification.current = "";
                    // clear classified exchange query to update for system diagram
                    await queryClient.invalidateQueries(["classifiedExchanges", projectId], { refetchInactive: true });
                  },
                  onError: (err) => {
                    toast.error(`Setting categorized entity classification caused an error: ${err}`);
                  },
                }
              );
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [
      selectedInstance.id,
      selectedInstance.type,
      editMode,
      canRename,
      data,
      setCategorizedEntityName,
      setSelectedInstance,
      setCategorizedEntityClassification,
      queryClient,
      projectId,
    ]
  );

  // const newSort = useCallback((rowA, rowB, columnId, desc) => {
  //   if (!rowA.values[columnId].level && !rowB.values[columnId].level) {
  //     return 0;
  //   }

  //   if (!rowA.values[columnId].level) {
  //     return desc ? -1 : 1;
  //   }

  //   if (!rowB.values[columnId].level) {
  //     return desc ? 1 : -1;
  //   }
  //   console.log("rowA.values[columnId]", rowA.values[columnId].level);
  //   console.log("rowb.values[columnId]", rowB.values[columnId].level);

  //   return rowA.values[columnId].level.compare(rowB.values[columnId].level);
  // }, []);

  const columns = useMemo(() => {
    if (isRiskAnalyst || isSystemEngineer) {
      return [
        createColumnName(
          selectedInstance.id,
          setSelectedInstance,
          editMode && isRiskAnalyst,
          updatedName,
          "categorizationEntity"
        ),
        createColumnCategoryMapped(
          "classification",
          selectedInstance.id,
          classification,
          editMode,
          updatedClassification
        ),
        createColumnCiaTriadMapped(CIA_TRIAD.confidentiality),
        createColumnCiaTriadMapped(CIA_TRIAD.integrity),
        createColumnCiaTriadMapped(CIA_TRIAD.availability),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "categorizationEntity"),
      createColumnMapped("classification", selectedInstance.id, classification, editMode, updatedClassification),
    ];
  }, [
    isRiskAnalyst,
    isSystemEngineer,
    selectedInstance.id,
    setSelectedInstance,
    editMode,
    classification,
    createButton,
  ]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  if (error || isClassificationError) {
    return <ErrorBanner msg="Error while loading categorized entity data" />;
  }

  if (data) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={data}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "CategorizationEntityTable_table" }}
            elementName={TYPE.categorizedEntity}
            showRowSelect={isRiskAnalyst || isSystemEngineer}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

CategorizedEntityTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default CategorizedEntityTable;
