// import { Link } from "react-router-dom";
import EditCell from "components/EditComponents/EditCell";
import EditNameCell from "components/EditComponents/EditNameCell";
import EditCellDropdown from "components/EditComponents/EditCellDropdown";
import EditCellDropdownCategory from "components/EditComponents/EditCellDropdownCategory";
import EditCellUsersDropdown from "components/EditComponents/EditCellUsersDropdown";
import EditCellBooleanDropdown from "components/EditComponents/EditCellBooleanDropdown";
import AttackMechanismNotesModal from "components/modals/AttackMechanismNotesModal";
import AllocatedControlsImplGuidanceNotesModal from "components/modals/AllocatedControlsImplGuidanceNotesModal";
import {
  SelectColumnFilter,
  SelectLikelihoodColumnFilter,
  SelectImpactColumnFilter,
  SelectBooleanColumnFilter,
  SelectBooleanWithVariantColumnFilter,
  SelectLevelColumnFilter,
  RetainStringColumnFilter,
  NO_VALUE,
  SelectRiskColumnFilter,
  SelectCiaLevelColumnFilter,
  SelectCategorizedEntityColumnFilter,
} from "brm/tables/BrmTables";
import { COMMON, STATUS, SYSTEM_ELEMENT, Classification } from "constants/brm";
import { convertToLevel } from "utils/calibration-converter";
import { riskLevelMapper, riskLevelSortingMapper } from "features/risk-model";
import { booleanInTables } from "utils/boolean-checker";
import EditCellDropDownForRole from "components/EditComponents/EditCellDropdownForRole";
import { keyToName } from "services/user";
import { LinkStyles, EditCellBaselineDropdown } from "features/brm";
import ButtonAsLink from "components/modals/ButtonAsLinkStyled";
import { RoutePath } from "routes/route-paths";

const resolvePath = (object, path, defaultValue) => path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);
const resolvePathNoEdit = (object, path, defaultValue) => {
  if (typeof path === "string") {
    return path.split(".").reduce((o, p) => (o && o[p] ? o[p] : defaultValue), object);
  }
  return path(object);
};

const sortLevel = (rowA, rowB, accessor) => {
  const levelValue = { "": 5, "very low": 4, low: 3, moderate: 2, high: 1, "very high": 0 };
  const rowLevelA = rowA.values[accessor] || "";
  const rowLevelB = rowB.values[accessor] || "";
  if (levelValue[rowLevelA] > levelValue[rowLevelB]) return 1;
  if (levelValue[rowLevelB] > levelValue[rowLevelA]) return -1;
  return 0;
};

const sortRiskLevel = (rowA, rowB, accessor) => {
  const levelValueA = riskLevelSortingMapper(rowA.values[accessor]);
  const levelValueB = riskLevelSortingMapper(rowB.values[accessor]);
  if (levelValueA > levelValueB) return 1;
  if (levelValueB > levelValueA) return -1;
  return 0;
};

const sortBoolean = (rowA, rowB, accessor) => {
  const levelValueA = rowA.values[accessor] ? 1 : 0;
  const levelValueB = rowB.values[accessor] ? 1 : 0;
  if (levelValueA > levelValueB) return 1;
  if (levelValueB > levelValueA) return -1;
  return 0;
};

const sortNumeric = (rowA, rowB, accessor, desc) => {
  let zeroValue = 0;
  if (typeof desc === "boolean" && desc) zeroValue = -Infinity;
  if (typeof desc === "boolean" && desc === false) zeroValue = Infinity;
  const rowLevelA = rowA.values[accessor] === 0 ? zeroValue : rowA.values[accessor];
  const rowLevelB = rowB.values[accessor] === 0 ? zeroValue : rowB.values[accessor];
  if (rowLevelA > rowLevelB) return 1;
  if (rowLevelB > rowLevelA) return -1;
  return 0;
};

const sortClasification = (rowA, rowB, accessor, desc) => {
  let emptyValue = Infinity;
  if (typeof desc === "boolean" && desc) emptyValue = -Infinity;
  const levelValue = {
    [Classification.NOT_CLASSIFIED.letter]: emptyValue,
    [Classification.UNCLASSIFIED.letter]: 3,
    [Classification.FOUO.letter]: 2,
    [Classification.SECRET.letter]: 1,
    [Classification.TOP_SECRET.letter]: 0,
  };
  const rowLevelA = rowA.values[accessor] || "";
  const rowLevelB = rowB.values[accessor] || "";
  if (levelValue[rowLevelA] > levelValue[rowLevelB]) return 1;
  if (levelValue[rowLevelB] > levelValue[rowLevelA]) return -1;
  return 0;
};

const columnNameMap = {
  rank: { colName: "Rank", accessor: "rank" },
  node: { colName: "Node", accessor: "node.name", filter: { filter: "equals", Filter: SelectColumnFilter } },
  producer: { colName: "Producer", accessor: "producer.name" },
  consumer: { colName: "Consumer", accessor: "consumer.name" },
  data: { colName: "Data", accessor: "data.name" },
  parent: { colName: "Parent", accessor: "parent.name" },
  structcat: {
    colName: "Structural Category",
    accessor: "structuralcat",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  pattern: { colName: "Pattern", accessor: "pattern", filter: { filter: "equals", Filter: SelectColumnFilter } },
  category: {
    colName: "Category",
    accessor: "category",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  actConsumer: { colName: "Consumer Activity", accessor: "actConsumer.name" },
  actProducer: { colName: "Producer Activity", accessor: "actProducer.name" },
  satype: { colName: "System Asset Type", accessor: "satype.name" },
  owner: { colName: "Owner", accessor: "owner.name" },
  subject: { colName: "Topic", accessor: "subject.name", filter: { filter: "equals", Filter: SelectColumnFilter } },
  classification: {
    colName: "Classification",
    accessor: "classification",
    filter: { filter: "equals", Filter: SelectCategorizedEntityColumnFilter },
    sortFn: sortClasification,
  },
  endpoint: { colName: "Endpoint", accessor: "node.name" },
  surface: { colName: "Surface", accessor: "surface.name", filter: { filter: "equals", Filter: SelectColumnFilter } },
  exchange: {
    colName: "Exchange",
    accessor: "exchange.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  categorizedentity: {
    colName: "Categorized Entity",
    accessor: "categorizedentity.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  target: { colName: "Target", accessor: "target.name", filter: { filter: "equals", Filter: SelectColumnFilter } },
  objective: {
    colName: "Objective",
    accessor: "objective.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  systemasset: {
    colName: "System Asset",
    accessor: "systemasset.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  attackercategory: {
    colName: "Attacker Category",
    accessor: "attackercategory",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  trevpattern: {
    colName: "Threat Event Pattern",
    accessor: "trevpattern",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  trevpat: {
    colName: "Threat Event Pattern",
    accessor: "trevpat",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  trevcategory: {
    colName: "Threat Event Category",
    accessor: "trevcategory",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  kind: { colName: "Attack Kind", accessor: "kind", filter: { filter: "equals", Filter: SelectColumnFilter } },
  tactic: {
    colName: "Attack Tactic",
    accessor: "tactic",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  mechanism: {
    colName: "Attack Mechanism",
    accessor: "mechanism",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  entry: { colName: "Entry", accessor: "entry.name" },
  attackvector: { colName: "Attack Vector", accessor: "attackvector.name" },
  entrypoint: { colName: "Entry Point", accessor: "entrypoint.name" },
  targetnode: { colName: "Target Node", accessor: "targetnode.name" },
  condition: { colName: "Condition", accessor: "condition.name" },
  path: { colName: "Path", accessor: "path.name" },
  ue: { colName: "Undesired Event", accessor: "ue.name" },
  attackercat: {
    colName: "Attacker Category",
    accessor: "attackercat.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  accesspoint: { colName: "Access Point", accessor: "accesspoint.name" },
  vulcat: { colName: "Vulnerability Category", accessor: "vulnerabilitycategory" },
  controltype: { colName: "Control Type", accessor: "controltype.name" },
  anobject: { colName: "Target", accessor: "anobject.name" },
  vulcatresult: { colName: "Vulnerability Category", accessor: "vulnerabilitycategory.result" },
  tier: { colName: "Tier", accessor: "tier", filter: { filter: "equals", Filter: SelectColumnFilter } },
  person: { colName: "Person", accessor: "person.name" },
  group: { colName: "Group", accessor: "group.name" },
  kb: { colName: "Knowledgebase", accessor: "kb.name" },
  baseline: { colName: "Baseline", accessor: "baseline.name" },
  standardBaseline: { colName: "Baseline", accessor: "standardBaseline.name" },
  status: { colName: "Status", accessor: "status", filter: { filter: "equals", Filter: SelectColumnFilter } },
  installation: { colName: "Installation", accessor: "installation" },
  channel: { colName: "Channel", accessor: "channel.name" },
  rulesetmeans: { colName: "Means", accessor: "means", filter: { filter: "equals", Filter: SelectColumnFilter } },
  rulesetopportunity: {
    colName: "Opportunity",
    accessor: "opportunity",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  level: { colName: "Level", accessor: "level", filter: { filter: "equals", Filter: SelectColumnFilter } },
  availableMeans: { colName: "Means", accessor: "availableMeans" },
  availableOpportunity: { colName: "Opportunity", accessor: "availableOpportunity" },
  attackerLikelihood: { colName: "Likelihood", accessor: "likelihood" },
  cntrlCatalog: { colName: "Control Catalog", accessor: "controlCatalog.name" },
  asset: { colName: "Asset", accessor: "asset.name", filter: { filter: "equals", Filter: SelectColumnFilter } },
  vulnerabilityComputation: {
    colName: "Vulnerability Computation",
    accessor: (d) => d.setting.find((s) => s.name === "vulnerabilityComputationMode")?.value || "",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  version: { colName: "Version", accessor: "version" },
  severity: { colName: "Severity", accessor: "severity" },
  criticality: { colName: "Criticality", accessor: "criticality" },
};

const getPropertyOrNull = (property) => {
  return (d) => (d[property] != null && typeof d[property] !== "undefined" ? d[property] : null);
};

const columnBooleanMap = {
  isInternal: {
    colName: "Internal",
    accessor: (d) => (d.isInternal !== null && typeof d.isInternal !== "undefined" ? d.isInternal : null),
    field: "isInternal",
  },
  isFinal: {
    colName: "Final",
    accessor: (d) => (d.isFinal !== null && typeof d.isFinal !== "undefined" ? d.isFinal : null),
    field: "isFinal",
  },
  isInitial: {
    colName: "Initial",
    accessor: (d) => (d.isInitial !== null && typeof d.isInitial !== "undefined" ? d.isInitial : null),
    field: "isInitial",
  },
  isBoundary: {
    colName: "Boundary",
    accessor: (d) => (d.isBoundary !== null && typeof d.isBoundary !== "undefined" ? d.isBoundary : null),
    field: "isBoundary",
  },
  isInput: {
    colName: "Input",
    accessor: (d) => (d.isInput !== null && typeof d.isInput !== "undefined" ? d.isInput : null),
    field: "isInput",
  },
  isOutput: {
    colName: "Output",
    accessor: (d) => (d.isOutput !== null && typeof d.isOutput !== "undefined" ? d.isOutput : null),
    field: "isOutput",
  },
  isStored: {
    colName: "Stored",
    accessor: (d) => (d.isStored !== null && typeof d.isStored !== "undefined" ? d.isStored : null),
    field: "isStored",
  },
  isProcessed: {
    colName: "Processed",
    accessor: (d) => (d.isProcessed !== null && typeof d.isProcessed !== "undefined" ? d.isProcessed : null),
    field: "isProcessed",
  },
  isAssumed: {
    colName: "Assumed",
    accessor: (d) => (d.isAssumed !== null && typeof d.isAssumed !== "undefined" ? d.isAssumed : null),
    field: "isAssumed",
  },
  isExisting: {
    colName: "Existing",
    accessor: (d) => (d.isExisting !== null && typeof d.isExisting !== "undefined" ? d.isExisting : null),
    field: "isExisting",
  },
  isCompliant: {
    colName: "Compliant",
    accessor: (d) => (d.isCompliant !== null && typeof d.isCompliant !== "undefined" ? d.isCompliant : null),
    field: "isCompliant",
  },
  isNationState: {
    colName: "Nation State",
    accessor: (d) => (d.isNationState !== null && typeof d.isNationState !== "undefined" ? d.isNationState : null),
    field: "isNationState",
  },
  isImported: {
    colName: "Imported",
    accessor: (d) => (d.isImported !== null && typeof d.isImported !== "undefined" ? d.isImported : null),
    field: "isImported",
  },
  isListed: {
    colName: "Listed",
    accessor: (d) => (d.isListed !== null && typeof d.isListed !== "undefined" ? d.isListed : null),
    field: "isListed",
  },
  isShared: {
    colName: "Shared",
    accessor: (d) => (d.isShared !== null && typeof d.isShared !== "undefined" ? d.isShared : null),
    field: "isShared",
  },
  isAuto: {
    colName: "Auto",
    accessor: (d) => (d.isAuto !== null && typeof d.isAuto !== "undefined" ? d.isAuto : null),
    field: "isAuto",
  },
  isEffective: {
    colName: "Effective",
    accessor: (d) => (d.isEffective !== null && typeof d.isEffective !== "undefined" ? d.isEffective : null),
    field: "isEffective",
  },
  isCommon: {
    colName: "Common",
    accessor: (d) => (d.isCommon !== null && typeof d.isCommon !== "undefined" ? d.isCommon : null),
    field: "isCommon",
  },
  isGlobal: {
    colName: "Global",
    accessor: (d) => (d.isGlobal !== null && typeof d.isGlobal !== "undefined" ? d.isGlobal : null),
    field: "isGlobal",
  },
  isStandard: {
    colName: "Standard",
    accessor: (d) => (d.isStandard !== null && typeof d.isStandard !== "undefined" ? d.isStandard : null),
    field: "isStandard",
  },
  isCompliance: {
    colName: "Compliance",
    accessor: (d) => (d.isCompliance !== null && typeof d.isCompliance !== "undefined" ? d.isCompliance : null),
    field: "isCompliance",
  },
  isCloned: {
    colName: "Cloned",
    accessor: (d) => (d.isCloned !== null && typeof d.isCloned !== "undefined" ? d.isCloned : null),
    field: "isCloned",
  },
  variant1: {
    colName: "Variant 1",
    accessor: (d) => (d.var1 !== null && typeof d.var1 !== "undefined" ? d.var1 : null),
    field: "var1",
  },
  variant2: {
    colName: "Variant 2",
    accessor: (d) => (d.var2 !== null && typeof d.var2 !== "undefined" ? d.var2 : null),
    field: "var2",
  },
  isExternal: {
    colName: "External",
    accessor: (d) => (d.isExternal !== null && typeof d.isExternal !== "undefined" ? d.isExternal : null),
    field: "isExternal",
  },
  physicalMode: {
    colName: "Enable Physical Attacks",
    accessor: (d) => {
      // we convert yes/no to true/false to make the existing boolean logic work and just convert it back to yes/no before
      // any edits
      const v = d.setting.find((s) => s.name === "physicalMode")?.value || null;
      if (v === "yes" || v === true) {
        return true;
      }
      if (v === "no") {
        return false;
      }
      return null;
    },
    field: "physicalMode",
  },
  IsAttackable: {
    colName: "Value",
    accessor: (d) => (d.value !== null && typeof d.value !== "undefined" ? d.value : null),
    field: "value",
  },
  hasFindings: {
    colName: "Findings",
    accessor: getPropertyOrNull("hasFindings"),
    field: "hasFindings",
  },
  hasControls: {
    colName: "Controls",
    accessor: getPropertyOrNull("hasControls"),
    field: "hasControls",
  },
};

const columnNameStringEdit = {
  name: { colName: "Name", accessor: "name", fieldName: "name" },
  op: { colName: "Op", accessor: "op", fieldName: "name" },
  implguidance: { colName: "Impl Guidance", accessor: "implGuidance", fieldName: "implGuidance" },
  assmtguidance: { colName: "Asst Guidance", accessor: "assmtGuidance", fieldName: "assmtGuidance" },
  url: { colName: "Source URL", accessor: "sourceURL", fieldName: "sourceURL" },
  version: { colName: "Version", accessor: "version", fieldName: "version" },
  resource: { colName: "Resource", accessor: "resource", fieldName: "resource" },
  timeToLive: { colName: "TTL", accessor: "timeToLive", fieldName: "timeToLive" },
  cpe: { colName: "CPE", accessor: "cpe", fieldName: "cpe" },
  firstname: { colName: "First Name", accessor: "firstname", fieldName: "firstname" },
  lastname: { colName: "Last Name", accessor: "lastname", fieldName: "lastname" },
  username: { colName: "Username", accessor: "username", fieldName: "username" },
  email: { colName: "Email", accessor: "email", fieldName: "email" },
  workflow: { colName: "Workflow", accessor: "workflow", fieldName: "workflow" },
  cost: { colName: "Cost", accessor: "cost", fieldName: "cost" },
};

const columnNameNoEdit = {
  satype: { colName: "System Asset Type", accessor: "satype.name" },
  trevpat: { colName: "Threat Event Pattern", accessor: "trevpattern" },
  attackercat: { colName: "Attacker Category", accessor: "attackercategory" },
  rank: { colName: "Rank", accessor: "rank.value", filter: { disableFilters: true } },
  vulRank: { colName: "Rank", accessor: "rank", filter: { disableFilters: true } },
  name: { colName: "Name", accessor: "name" },
  asset: { colName: "Asset", accessor: "asset.name", filter: { filter: "equals", Filter: SelectColumnFilter } },
  surface: { colName: "Surface", accessor: "surface.name" },
  trev: { colName: "Threat Event", accessor: "trev.name" },
  target: { colName: "Target", accessor: "target.name" },
  kind: { colName: "Kind", accessor: "kind", filter: { filter: "equals", Filter: SelectColumnFilter } },
  tactic: { colName: "Tactic", accessor: "tactic", filter: { filter: "equals", Filter: SelectColumnFilter } },
  mechanism: {
    colName: "Mechanism",
    accessor: "mechanism",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  op: { colName: "Op", accessor: "op.name" },
  subject: { colName: "Subject", accessor: "subject.name" },
  classification: { colName: "Classification", accessor: "classification.name" },
  tag: { colName: "Tag", accessor: "tag" },
  value: { colName: "Value", accessor: "value" },
  mitigatedRiskLevel: { colName: "Mitigated Risk Level", accessor: "mitigatedRiskLevel" },
  riskLevel: { colName: "Risk Level", accessor: "riskLevel" },
  category: {
    colName: "Category",
    accessor: "category",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  structcat: {
    colName: "Structural Category",
    accessor: "structuralcat",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  vulLevelCalcStringValue: {
    colName: "Level",
    accessor: "level.calcStringValue",
    filter: { filter: "equals", Filter: SelectLevelColumnFilter },
  },
  vulLevelCalibratedStringValue: {
    colName: "Calibrated Level",
    accessor: "level.calibratedStringValue",
    filter: { filter: "equals", Filter: SelectLevelColumnFilter },
  },
  categorizationLevel: {
    colName: "Level",
    accessor: "level",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  anobjectType: {
    colName: "Target Type",
    accessor: "anobject.noun",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  anobject: {
    colName: "Target",
    accessor: "anobject.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  object: { colName: "Target", accessor: "object.name", filter: { filter: "equals", Filter: SelectColumnFilter } },
  objective: {
    colName: "Objective",
    accessor: "objective.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  objectiveObject: {
    colName: "Objective",
    accessor: "objectiveObject.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  systemasset: { colName: "System Asset", accessor: "systemasset.name" },
  sctm: { colName: "SCTM", accessor: "sctm.name" },
  strength: { colName: "Strength", accessor: "strength", filter: { filter: "equals", Filter: SelectColumnFilter } },
  calibratedStrength: {
    colName: "Calibrated Strength",
    accessor: "calibratedStrength",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  controltypeStrength: { colName: "Strength", accessor: "controltype.strength" },
  cost: { colName: "Cost", accessor: "cost", filter: { filter: "equals", Filter: SelectColumnFilter } },
  baseline: {
    colName: "Baseline",
    accessor: "baseline.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  variant: { colName: "Variant", accessor: "variant.name" },
  importsource: { colName: "Import Source", accessor: "importsource" },
  catalog: { colName: "Catalog", accessor: "catalog.name" },
  family: { colName: "Family", accessor: "family.name" },
  controlFamily: {
    colName: "Control Family",
    accessor: "controltype.family.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  familyName: { colName: "Family", accessor: "family", filter: { filter: "equals", Filter: SelectColumnFilter } },
  tcatalog: { colName: "Threat Catalog", accessor: "catalog.name" },
  threatcatalog: {
    colName: "Threat Catalog",
    accessor: "catalog.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  cntrlCatalog: {
    colName: "Control Catalog",
    accessor: "controlCatalog.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  org: {
    colName: "Organization",
    accessor: "organization.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  orgNoName: { colName: "Organization", accessor: "organization" },
  ue: {
    colName: "Undesired Event (UE)",
    accessor: "name",
    filter: {
      Filter: RetainStringColumnFilter,
    },
  },
  attack: { colName: "Attack", accessor: "name", filter: { Filter: RetainStringColumnFilter } },
  node: { colName: "Node", accessor: "node.name", filter: { filter: "equals", Filter: SelectColumnFilter } },
  incoming: { colName: "Incoming", accessor: "incoming" },
  outgoing: { colName: "Outgoing", accessor: "outgoing" },
  stored: { colName: "Stored", accessor: "stored" },
  parent: { colName: "Parent", accessor: "parent" },
  children: { colName: "Children", accessor: "children" },
  pattern: { colName: "Pattern", accessor: "pattern" },
  targetnode: { colName: "Target Node", accessor: "targetnode.name" },
  condition: { colName: "Condition", accessor: "condition.name" },
  producer: { colName: "Producer", accessor: "producer.name" },
  consumer: { colName: "Consumer", accessor: "consumer.name" },
  person: { colName: "Person", accessor: "person.name" },
  group: { colName: "Group", accessor: "group.name" },
  groups: { colName: "Groups", accessor: "groups" },
  members: { colName: "Members", accessor: "members" },
  importedsource: { colName: "Imported Source", accessor: "importedsource" },
  sourcetype: { colName: "Source Type", accessor: "sourceType" },
  path: { colName: "Path", accessor: "path.name" },
  type: { colName: "Type", accessor: "type.name" },
  owner: { colName: "Owner", accessor: "owner.name" },
  firstname: { colName: "First Name", accessor: "firstname" },
  lastname: { colName: "Last Name", accessor: "lastname" },
  username: { colName: "User Name", accessor: "username" },
  status: { colName: "Status", accessor: "status", filter: { filter: "equals", Filter: SelectColumnFilter } },
  controltype: {
    colName: "Control Type",
    accessor: "controltype.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  noun: { colName: "Type", accessor: "noun" },
  vulnerabilitycategory: {
    colName: "Vulnerability Category",
    accessor: "vulnerabilitycategory",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  vulncat: {
    colName: "Description",
    accessor: "data.note",
  },
  kb: { colName: "Knowledgebase", accessor: "kb.name" },
  defaultVariant: { colName: "Default Variant", accessor: "defaultVariant" },
  compliance: { colName: "Compliance", accessor: "compliance" },
  categorizedentity: {
    colName: "Categorized Entity",
    accessor: "categorizedentity.name",
  },
  metadata: { colName: "Metadata", accessor: "metadata" },
  baselineNoName: { colName: "Baseline", accessor: "baseline" },
  sctmNoName: { colName: "SCTM", accessor: "sctm" },
  targetType: {
    colName: "Target Type",
    accessor: "targetType",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  vulnerability: { colName: "Vulnerability", accessor: "vulnerability" },
  vulnerabilityLevel: {
    colName: "Level",
    accessor: "vulnerabilityLevel",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },

  levelNoVal: { colName: "Level", accessor: "level", filter: { filter: "equals", Filter: SelectColumnFilter } },
  elementType: {
    colName: "Element",
    accessor: "elementType",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  controlId: {
    colName: "Control Type",
    accessor: "controlId",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  effective: { colName: "Effective?", accessor: "effective", filter: { filter: "equals", Filter: SelectColumnFilter } },
  inBaseline: {
    colName: "In Baseline?",
    accessor: "inBaseline",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  cweId: { colName: "CWEId", accessor: "cweId" },
  cweName: { colName: "CWE Name", accessor: "cweName" },
  size: { colName: "File Size", accessor: "size" },
  importer: { colName: "Type", accessor: "importer" },
  timestamp: { colName: "Timestamp", accessor: "timestamp" },
  uploadStatus: { colName: "Upload Status", accessor: "status" },
  confidentialityLevel: { colName: "Confidentiality", accessor: "confidentialityLevel" },
  integrityLevel: { colName: "Integrity", accessor: "integrityLevel" },
  availabilityLevel: { colName: "Availability", accessor: "availabilityLevel" },
  endpoint: { colName: "Endpoint", accessor: "node.name" },
  exchange: {
    colName: "Exchange",
    accessor: "exchange.name",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  accesspoint: { colName: "Access Point", accessor: "accesspoint.name" },
  attackvector: { colName: "Attack Vector", accessor: "attackvector.name" },
  entrypoint: { colName: "Entry Point", accessor: "entrypoint.name" },
  ttp: { colName: "TTP", accessor: "ttp.name" },
  riskDistributionRank: { colName: "Risk Rank", accessor: "riskDistributionRank", sortFn: sortNumeric },
  riskDistributionPercent: { colName: "Risk Percentage", accessor: "riskDistributionPercent" },
  weakness: { colName: "Weakness", accessor: "weakness" },
  cwe: { colName: "CWE", accessor: "cwe" },
  lineNumber: { colName: "Line Number", accessor: "lineNumber" },
  sfp: { colName: "SFP", accessor: "sfp" },
  tool: { colName: "Tool", accessor: "tool" },
  resource: { colName: "Resource", accessor: "resource" },
  findingCount: { colName: "Vulnerability Findings Count", accessor: "findingCount" },
  controlsCount: { colName: "Controls Count", accessor: "controlCount" },
  hostId: { colName: "Host ID", accessor: "hostId" },
  helpType: { colName: "Type", accessor: "type", filter: { filter: "equals", Filter: SelectColumnFilter } },
  helpRole: { colName: "Role", accessor: "role", filter: { filter: "equals", Filter: SelectColumnFilter } },
};

const columnNameNoEditRpts = {
  targetName: {
    colName: "Target Name",
    accessor: "targetName",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  vulnerabilityCategory: {
    colName: "Category",
    accessor: "vulnerabilityCategory",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  cweId: {
    colName: "CWEId",
    accessor: "cweId",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  elementName: { colName: "Name", accessor: "elementName" },
  description: { colName: "Description", accessor: "description" },
  elementNameInventory: { colName: "Inventory Item", accessor: "elementName" },
  inventory: { colName: "Inventory", accessor: "inventory" },
  elementDescription: { colName: "Description", accessor: "elementDescription" },
  guidance: { colName: "Guidance", accessor: "guidance" },
  familyName: { colName: "Family", accessor: "family" },
  controlName: {
    colName: "Control Name",
    accessor: "controlName",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  subcatId: {
    colName: "Vul Category",
    accessor: "subcatId",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  sfpId: {
    colName: "SFP",
    accessor: "sfpId",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  note: { colName: "Note", accessor: "note" },
  name: { colName: "Name", accessor: "name" },
};

const columnNameNoEditRuleset = {
  trevcat: { colName: "Trev Category", accessor: "trevcat", filter: { filter: "equals", Filter: SelectColumnFilter } },
  trevpat: { colName: "Trev Pattern", accessor: "trevpat", filter: { filter: "equals", Filter: SelectColumnFilter } },
  atkkind: { colName: "Attack Kind", accessor: "atkkind", filter: { filter: "equals", Filter: SelectColumnFilter } },
  atktactic: {
    colName: "Attack Tactic",
    accessor: "atktactic",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  sacat: {
    colName: "System Asset Category",
    accessor: "sacat",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  atkcat: {
    colName: "Attacker Category",
    accessor: "atkcat",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  tag: { colName: "Tag", accessor: "tag", filter: { filter: "equals", Filter: SelectColumnFilter } },
  targetcat: {
    colName: "Target Category",
    accessor: "targetcat",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  objective: {
    colName: "Objective",
    accessor: "objective",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  severity: {
    colName: "Severity",
    accessor: "severity",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  criticality: {
    colName: "Criticality",
    accessor: "criticality",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
  rfactor: {
    colName: "R Factor",
    accessor: "rfactor",
    filter: { filter: "equals", Filter: SelectColumnFilter },
  },
};

export function createColumn(header, accessor, cell, opts = {}, sort) {
  const column = {
    Header: header,
    accessor,
    Cell: cell,
  };

  if (opts.filter) {
    column.filter = opts.filter;
  }
  if (opts.Filter) {
    column.Filter = opts.Filter;
  }
  if (opts.disableFilters) {
    column.disableFilters = opts.disableFilters;
  }

  if (sort) {
    column.sortType = sort;
  }

  return column;
}

export function createCellEdit(rowId, editMode, valRef, fieldName, validationMode = "default") {
  const editCell = (cellProps) => {
    // const value = cellProps.cell.row.original;
    const {
      cell: {
        row: { original: value },
      },
    } = cellProps;

    return (
      <EditCell
        validationMode={validationMode}
        key={rowId}
        selectedRowId={rowId}
        elementId={value.id}
        field={value[fieldName]}
        editMode={editMode}
        setter={(val) => {
          valRef.current = val; // eslint-disable-line no-param-reassign
        }}
      />
    );
  };
  editCell.displayName = `EditCell-${fieldName}`;
  return editCell;
}

export function createCellEditDropdown(rowId, field, list, id, editMode, valRef, cb = null) {
  const editCell = (cellProps) => {
    // const { original } = cellProps.cell.row;
    const {
      cell: {
        row: { original },
      },
    } = cellProps;

    const resolvedField = typeof field !== "string" ? field(original) : resolvePath(original, field);

    return (
      <EditCellDropdown
        selectedRowId={rowId}
        elementId={original.id || original?.objective?.id} // use objective id of categorization (impact statements)
        field={resolvedField}
        dropdownList={list}
        id={id}
        editMode={editMode}
        setter={(newValue) => {
          valRef.current = newValue; // eslint-disable-line no-param-reassign
          if (cb) {
            cb(newValue);
          }
        }}
      />
    );
  };
  editCell.displayName = `EditCellDropdown-${field}`;
  return editCell;
}

export function createCellEditBaselineDropdown(rowId, field, id, editMode, valRef) {
  const editCell = (cellProps) => {
    const {
      cell: {
        row: { original },
      },
    } = cellProps;

    return (
      <EditCellBaselineDropdown
        selectedRowId={rowId}
        elementId={original.id || original?.objective?.id} // use objective id of categorization (impact statements)
        field={resolvePath(original, field)}
        id={id}
        editMode={editMode}
        setter={(newValue) => {
          valRef.current = newValue; // eslint-disable-line no-param-reassign
        }}
      />
    );
  };
  editCell.displayName = `EditCellDropdown-${field}`;
  return editCell;
}

export function createCellEditCategoryDropdown(rowId, field, list, id, editMode, valRef) {
  const editCell = (cellProps) => {
    // const { original } = cellProps.cell.row;
    const {
      cell: {
        row: { original },
      },
    } = cellProps;

    return (
      <EditCellDropdownCategory
        selectedRowId={rowId}
        elementId={original.id}
        field={resolvePath(original, field)}
        dropdownList={list}
        id={id}
        editMode={editMode}
        setter={(newValue) => {
          valRef.current = newValue; // eslint-disable-line no-param-reassign
        }}
      />
    );
  };
  editCell.displayName = `EditCellDropdown-${field}`;
  return editCell;
}

export function createCellEditBooleanDropdown(rowId, id, field, editMode, valRef, accessor) {
  const editCell = (cellProps) => {
    const {
      cell: {
        row: { original },
      },
    } = cellProps;

    const resolvedField = typeof accessor !== "string" ? accessor(original) : resolvePath(original, field);

    return (
      <EditCellBooleanDropdown
        selectedRowId={rowId}
        elementId={original.id}
        field={resolvedField}
        id={id}
        editMode={editMode}
        setter={(newValue) => {
          valRef.current = newValue; // eslint-disable-line no-param-reassign
        }}
      />
    );
  };
  editCell.displayName = `EditCellBoolean-${field}`;
  return editCell;
}

export function createColumnMappedNoEditRpt(id, cell) {
  const { colName, accessor, filter } = columnNameNoEditRpts[id];
  return createColumn(colName, accessor, cell, filter);
}

export function createColumnMappedNoEditRuleset(id) {
  const { colName, accessor, filter } = columnNameNoEditRuleset[id];
  return createColumn(
    colName,
    accessor,
    (cellProps) => resolvePathNoEdit(cellProps.cell.row.original, accessor, NO_VALUE),
    filter
  );
}

export function createColumnMappedNoEdit(id) {
  const { colName, accessor, filter, sortFn } = columnNameNoEdit[id];
  return createColumn(
    colName,
    accessor,
    (cellProps) => resolvePathNoEdit(cellProps.cell.row.original, accessor, NO_VALUE),
    filter,
    sortFn
  );
}

export function createColumnMappedStringEdit(id, selectedRowId, editMode, valRef) {
  const { colName, accessor, fieldName } = columnNameStringEdit[id];
  return createColumn(colName, accessor, createCellEdit(selectedRowId, editMode, valRef, fieldName));
}

export function createColumnMappedStringEditValidation(id, selectedRowId, editMode, valRef, validationMode) {
  const { colName, accessor, fieldName } = columnNameStringEdit[id];
  return createColumn(colName, accessor, createCellEdit(selectedRowId, editMode, valRef, fieldName, validationMode));
}

export function createColumnMappedUrlEdit(id, selectedRowId, editMode, valRef) {
  const { colName, accessor, fieldName } = columnNameStringEdit[id];
  return createColumn(colName, accessor, createCellEdit(selectedRowId, editMode, valRef, fieldName, "url"));
}

export function createColumnMapped(id, selectedRowId, patternList, editMode, valRef, cb = null) {
  const { colName, accessor, filter } = columnNameMap[id];
  return createColumn(
    colName,
    accessor,
    createCellEditDropdown(selectedRowId, accessor, patternList, id, editMode, valRef, cb),
    filter
  );
}

export function createColumnBaselineMapped(id, selectedRowId, editMode, valRef) {
  const { colName, accessor, filter } = columnNameMap[id];
  return createColumn(
    colName,
    accessor,
    createCellEditBaselineDropdown(selectedRowId, accessor, id, editMode, valRef),
    filter
  );
}

export function createColumnCategoryMapped(id, selectedRowId, patternList, editMode, valRef) {
  const { colName, accessor, filter, sortFn } = columnNameMap[id];
  return createColumn(
    colName,
    accessor,
    createCellEditCategoryDropdown(selectedRowId, accessor, patternList, id, editMode, valRef),
    filter,
    sortFn
  );
}

export function createColumnBooleanMapped(id, selectedRowId, editMode, valRef) {
  const { colName, accessor, field } = columnBooleanMap[id];
  return createColumn(
    colName,
    accessor,
    createCellEditBooleanDropdown(selectedRowId, id, field, editMode, valRef, accessor),
    {
      filter: "equals",
      Filter: SelectBooleanColumnFilter,
    },
    sortBoolean
  );
}

export function createColumnBooleanWithVariantMapped(id, selectedRowId, editMode, valRef) {
  const { colName, accessor, field } = columnBooleanMap[id];
  return createColumn(
    colName,
    accessor,
    createCellEditBooleanDropdown(selectedRowId, id, field, editMode, valRef, accessor),
    {
      filter: "equals",
      Filter: SelectBooleanWithVariantColumnFilter,
    },
    sortBoolean
  );
}

export function createColumnBooleanNoEditMapped(id, colNameOverride = undefined) {
  const { colName, accessor, field } = columnBooleanMap[id];
  const cName = colNameOverride || colName;
  return createColumn(
    cName,
    accessor,
    (cellProps) => booleanInTables(cellProps.cell.row.original[field]),
    {
      filter: "equals",
      Filter: SelectBooleanColumnFilter,
    },
    sortBoolean
  );
}

export function createColumnNameDetails(path, opts = {}, setRefresh) {
  const onClick = () => {
    if (opts.refresh) {
      setRefresh(true);
    }
  };

  return createColumn("Name", "name", (cellProps) => {
    if (cellProps.cell.row.original?.status && cellProps.cell.row.original.status === STATUS.deleted) {
      return cellProps.cell.row.original.name;
    }

    if (cellProps.cell.row.original?.noun) {
      if (cellProps.cell.row.original.noun === SYSTEM_ELEMENT.node) {
        return (
          <LinkStyles.CleanLink onClick={onClick} to={RoutePath.Node.replace(":id", cellProps.cell.row.original.id)}>
            {cellProps.cell.row.original.name}
          </LinkStyles.CleanLink>
        );
      }

      if (cellProps.cell.row.original.noun === SYSTEM_ELEMENT.exchange) {
        return (
          <LinkStyles.CleanLink
            onClick={onClick}
            to={RoutePath.Exchange.replace(":id", cellProps.cell.row.original.id)}
          >
            {cellProps.cell.row.original.name}
          </LinkStyles.CleanLink>
        );
      }

      if (cellProps.cell.row.original.noun === SYSTEM_ELEMENT.datatype) {
        return (
          <LinkStyles.CleanLink
            onClick={onClick}
            to={RoutePath.Datatype.replace(":id", cellProps.cell.row.original.id)}
          >
            {cellProps.cell.row.original.name}
          </LinkStyles.CleanLink>
        );
      }

      if (cellProps.cell.row.original.noun === SYSTEM_ELEMENT.dataFlow) {
        return (
          <LinkStyles.CleanLink
            onClick={onClick}
            to={RoutePath.Dataflow.replace(":id", cellProps.cell.row.original.id)}
          >
            {cellProps.cell.row.original.name}
          </LinkStyles.CleanLink>
        );
      }

      if (cellProps.cell.row.original.noun === SYSTEM_ELEMENT.capability) {
        return (
          <LinkStyles.CleanLink
            onClick={onClick}
            to={RoutePath.Capability.replace(":id", cellProps.cell.row.original.id)}
          >
            {cellProps.cell.row.original.name}
          </LinkStyles.CleanLink>
        );
      }

      if (cellProps.cell.row.original.noun === SYSTEM_ELEMENT.person) {
        return (
          <LinkStyles.CleanLink onClick={onClick} to={RoutePath.Person.replace(":id", cellProps.cell.row.original.id)}>
            {cellProps.cell.row.original.name}
          </LinkStyles.CleanLink>
        );
      }

      if (cellProps.cell.row.original.noun === SYSTEM_ELEMENT.resource) {
        return (
          <LinkStyles.CleanLink
            onClick={onClick}
            to={RoutePath.Resource.replace(":id", cellProps.cell.row.original.id)}
          >
            {cellProps.cell.row.original.name}
          </LinkStyles.CleanLink>
        );
      }

      if (cellProps.cell.row.original.noun === SYSTEM_ELEMENT.activity) {
        return (
          <LinkStyles.CleanLink
            onClick={onClick}
            to={RoutePath.Activity.replace(":id", cellProps.cell.row.original.id)}
          >
            {cellProps.cell.row.original.name}
          </LinkStyles.CleanLink>
        );
      }

      if (cellProps.cell.row.original.noun === SYSTEM_ELEMENT.mission) {
        return (
          <LinkStyles.CleanLink onClick={onClick} to={RoutePath.Mission.replace(":id", cellProps.cell.row.original.id)}>
            {cellProps.cell.row.original.name}
          </LinkStyles.CleanLink>
        );
      }
    }

    return (
      <LinkStyles.CleanLink onClick={onClick} to={`${path}${cellProps.cell.row.original.id}`}>
        {cellProps.cell.row.original.name}
      </LinkStyles.CleanLink>
    );
  });
}

export function createColumnUsernameDetails(path) {
  return createColumn("Username", "username", (cellProps) => {
    if (cellProps.cell.row.original?.status && cellProps.cell.row.original.status === STATUS.deleted) {
      return cellProps.cell.row.original.username;
    }
    return (
      <LinkStyles.CleanLink to={`${path}${cellProps.cell.row.original.id}`}>
        {cellProps.cell.row.original.username}
      </LinkStyles.CleanLink>
    );
  });
}

export function createColumnFirstnameDetails(path) {
  return createColumn("First Name", "firstname", (cellProps) => (
    <LinkStyles.CleanLink to={`${path}${cellProps.cell.row.original.id}`}>
      {cellProps.cell.row.original.firstname}
    </LinkStyles.CleanLink>
  ));
}

const columnLiklihoodMap = {
  likelihood: { colName: "Likelihood", accessor: "likelihood.stringValue" },
  likelihoodlevel: { colName: "Likelihood Level", accessor: "mitigatedLikelihood.stringValue" },
  averagelikelihood: { colName: "Average Likelihood", accessor: "averageLikelihood.stringValue" },
  calclikelihood: { colName: "Likelihood", accessor: "likelihood.calcStringValue" },
  calibratedlikelihood: { colName: "Calibrated Likelihood", accessor: "likelihood.calibratedStringValue" },
  mitigatedlikelihood: { colName: "Mitigated Likelihood", accessor: "mitigatedLikelihood.stringValue" },
  atkMitigatedlikelihood: { colName: "Likelihood", accessor: "mitigatedLikelihood.stringValue" },
};

export function createColumnLikelihoodMapped(id) {
  const { colName, accessor } = columnLiklihoodMap[id];
  return createColumn(
    colName,
    accessor,
    (cellProps) => convertToLevel(resolvePathNoEdit(cellProps.cell.row.original, accessor), "likelihood"),
    {
      filter: "equals",
      Filter: SelectLikelihoodColumnFilter,
    },
    sortLevel
  );
}

const columnImpactMap = {
  impact: { colName: "Impact", accessor: "impact.stringValue" },
  impactlevel: { colName: "Impact Level", accessor: "mitigatedImpact.stringValue" },
  calcimpact: { colName: "Impact", accessor: "impact.calcStringValue" },
  currentImpact: {
    colName: "Impact",
    accessor: (row) => {
      if (row.impact.calibratedStringValue === null) {
        return row.impact.calcStringValue;
      }
      return row.impact.calibratedStringValue;
    },
  },
  calibratedimpact: { colName: "Calibrated Impact", accessor: "impact.calibratedStringValue" },
  mitigatedimpact: { colName: "Mitigated Impact", accessor: "mitigatedImpact.stringValue" },
};

export function createColumnImpactMapped(id) {
  const { colName, accessor } = columnImpactMap[id];
  return createColumn(
    colName,
    accessor,
    (cellProps) => {
      return convertToLevel(resolvePathNoEdit(cellProps.cell.row.original, accessor), "impact");
    },
    {
      filter: "equals",
      Filter: SelectImpactColumnFilter,
    },
    sortLevel
  );
}

export function createColumnVulLevelMapped(id) {
  const { colName, accessor, filter } = columnNameNoEdit[id];
  return createColumn(
    colName,
    accessor,
    (cellProps) => resolvePathNoEdit(cellProps.cell.row.original, accessor, NO_VALUE),
    filter,
    sortLevel
  );
}

export function createColumnHelpMapped(id) {
  const { colName, accessor, filter } = columnNameNoEdit[id];
  return createColumn(
    colName,
    accessor,
    (cellProps) => resolvePathNoEdit(cellProps.cell.row.original, accessor, NO_VALUE),
    filter
  );
}

export function createColumnHelpName(onClick) {
  return createColumn("Help Articles", "name", (cellProps) => {
    const value = cellProps.cell.row.original;
    return (
      <ButtonAsLink
        id={`hrefTo${value.name}`}
        onClick={() => {
          onClick({ ...value });
        }}
      >
        {value.name}
      </ButtonAsLink>
    );
  });
}

export function createCellEditName(
  rowId,
  field,
  onClick,
  editMode,
  valRef,
  opts = {},
  pathFilter,
  showNoValue = false
) {
  const editCell = (cellProps) => {
    const value = cellProps.cell.row.original;
    const status = cellProps.cell.row.original?.status ? cellProps.cell.row.original.status : undefined;
    const { tab = "" } = opts;
    let { path } = opts;
    if (pathFilter) {
      if (field === "username") {
        path = pathFilter(opts.path, value); // [BRMCD-7296] Admin shouldn't see details of other users from different organizations
      } else path = pathFilter(opts.path, status);
    }
    return (
      <EditNameCell
        selectedRowId={rowId}
        elementId={value.id}
        field={showNoValue ? resolvePathNoEdit(value, field, NO_VALUE) : resolvePath(value, field)}
        original={value}
        handleSelect={onClick}
        editMode={editMode}
        setter={(val) => {
          valRef.current = val; // eslint-disable-line no-param-reassign
        }}
        path={path}
        tab={tab}
        checkNameUniqueness={opts.checkNameUniqueness}
      />
    );
  };
  editCell.displayName = `CellEditName-${field}`;
  return editCell;
}

export function createCellEditVersion(rowId, editMode, valRef) {
  const editCell = (cellProps) => {
    const value = cellProps.cell.row.original;

    return (
      <EditCell
        selectedRowId={rowId}
        elementId={value.id}
        field={value.version}
        editMode={editMode}
        setter={(val) => {
          valRef.current = val; // eslint-disable-line no-param-reassign
        }}
      />
    );
  };
  editCell.displayName = `CellEditVersion`;
  return editCell;
}

export function createCellEditStandard(rowId, editMode, valRef) {
  const editCell = (cellProps) => {
    const value = cellProps.cell.row.original;

    return (
      <EditCell
        selectedRowId={rowId}
        elementId={value.id}
        field={value.standard}
        editMode={editMode}
        setter={(val) => {
          valRef.current = val; // eslint-disable-line no-param-reassign
        }}
      />
    );
  };
  editCell.displayName = "CellEditStandard";
  return editCell;
}

export function createCellEditCpe(rowId, editMode, valRef) {
  const editCell = (cellProps) => {
    const value = cellProps.cell.row.original;

    return (
      <EditCell
        selectedRowId={rowId}
        elementId={value.id}
        field={value.cpe}
        editMode={editMode}
        setter={(val) => {
          valRef.current = val; // eslint-disable-line no-param-reassign
        }}
      />
    );
  };
  editCell.displayName = "EditCellCpe";
  return editCell;
}

export function createCellEditUsersDropdown(rowId, field, list, id, editMode, valRef) {
  const editCell = (cellProps) => {
    const { original } = cellProps.cell.row;
    return (
      <EditCellUsersDropdown
        selectedRowId={rowId}
        elementId={original.id}
        field={resolvePath(original, field)}
        dropdownList={list}
        id={id}
        editMode={editMode}
        setter={(newValue) => {
          valRef.current = newValue; // eslint-disable-line no-param-reassign
        }}
      />
    );
  };
  editCell.displayName = `EditUsersDropdown-${field}`;
  return editCell;
}

export function createCellEditRoleDropdown(rowId, list, id, editMode, valRef) {
  const editCell = (cellProps) => {
    const { original } = cellProps.cell.row;
    return (
      <EditCellDropDownForRole
        selectedRowId={rowId}
        elementId={original.id}
        field={keyToName[original.role]}
        dropdownList={list}
        id={id}
        editMode={editMode}
        setter={(newValue) => {
          valRef.current = newValue; // eslint-disable-line no-param-reassign
        }}
      />
    );
  };
  editCell.displayName = `CellEditRoleDropdown`;
  return editCell;
}

export function createColumnCategoryName() {
  const editCell = (cellProps) => {
    const value = cellProps.cell.row.original;
    return value;
  };
  editCell.displayName = "CellEditName-name";

  return createColumn("Name", "name", editCell, { disableFilters: true });
}

export function createColumnName(selectedRowId, onClick, editMode, valRef, path, checkNameUniqueness, opts = {}) {
  return createColumn(
    "Name",
    "name",
    createCellEditName(
      selectedRowId,
      "name",
      onClick,
      editMode,
      valRef,
      { path, checkNameUniqueness },
      opts.pathFilter
    ),
    opts
  );
}

export function createColumnNameNoNav(selectedRowId, editMode, valRef) {
  return createColumn("Name", "name", createCellEdit(selectedRowId, editMode, valRef, "name"));
}

export function createColumnStrength(selectedRowId, strengthList, editMode, valRef) {
  return createColumn(
    "Strength",
    "strength",
    createCellEditDropdown(selectedRowId, "strength", strengthList, "strength", editMode, valRef)
  );
}

export function createColumnStandard(selectedRowId, editMode, valRef) {
  return createColumn("Standard", "standard", createCellEditStandard(selectedRowId, editMode, valRef));
}

export function createColumnCalibratedStrength(selectedRowId, stengthLevels, editMode, valRef) {
  return createColumn(
    "Calibrated Strength",
    "calibratedStrength",
    createCellEditDropdown(selectedRowId, "calibratedStrength", stengthLevels, "calibratedStrength", editMode, valRef),
    { filter: "equals", Filter: SelectColumnFilter }
  );
}

export function createColumnImportedAt() {
  return createColumn("Imported", "importedAt", (cellProps) => {
    const d = new Date(cellProps.cell.row.original.importedAt.replace("[GMT]", ""));
    return cellProps.cell.row.original.importedAt === "" ? NO_VALUE : <div>{d.toLocaleDateString()}</div>;
  });
}

export function createColumnExportedAt() {
  return createColumn(
    "Exported",
    "exportedAt",
    (cellProps) => {
      const d = new Date(cellProps.cell.row.original.exportedAt.replace("[GMT]", ""));
      return cellProps.cell.row.original.exportedAt === "" ? NO_VALUE : <div>{d.toLocaleDateString()}</div>;
    },
    { disableFilters: true }
  );
}

export function createColumnModifiedAt() {
  return createColumn(
    "Modified",
    "modifiedAt",
    (cellProps) => {
      if (typeof cellProps.cell.row.original === "object" && "modifiedAt" in cellProps.cell.row.original) {
        const d = new Date(cellProps.cell.row.original.modifiedAt.replace("[GMT]", ""));
        return cellProps.cell.row.original.modifiedAt === "" ? NO_VALUE : <div>{d.toLocaleDateString()}</div>;
      }
      return NO_VALUE;
    },
    { disableFilters: true }
  );
}

export function createColumnCreatedAt() {
  return createColumn(
    "Created",
    "createdAt",
    (cellProps) => {
      if (typeof cellProps.cell.row.original === "object" && "createdAt" in cellProps.cell.row.original) {
        const d = new Date(cellProps.cell.row.original.createdAt.replace("[GMT]", ""));
        return cellProps.cell.row.original.createdAt === "" ? NO_VALUE : <div>{d.toLocaleDateString()}</div>;
      }
      return NO_VALUE;
    },
    { disableFilters: true }
  );
}

export function createColumnActions(cell, opts) {
  return createColumn("Actions", "actions", cell, opts);
}

export function createColumnAdmin(selectedRowId, usersList, editMode, valRef) {
  return createColumn(
    "Admin",
    "admin",
    createCellEditUsersDropdown(selectedRowId, "admin", usersList, "admin", editMode, valRef)
  );
}

export function createColumnCount(header, accessor) {
  return createColumn(header, accessor, (cellProps) => cellProps.cell.row.original[accessor] || NO_VALUE);
}

export function createColumnOrganization(accessor) {
  return createColumn(
    "Organization",
    accessor,
    (cellProps) => {
      if (accessor === "organization.name") {
        return cellProps.cell.row.original.organization.name || NO_VALUE;
      }
      if (accessor === "orgName") {
        return cellProps.cell.row.original.orgName || NO_VALUE;
      }
      return cellProps.row.original.organization || NO_VALUE;
    },
    { filter: "equals", Filter: SelectColumnFilter }
  );
}

export function createColumnUserName(selectedRowId, onClick, editMode, valRef, path, pathfilter) {
  return createColumn(
    "Username",
    "username",
    createCellEditName(selectedRowId, "username", onClick, editMode, valRef, { path }, pathfilter)
  );
}

export function createColumnDefaultRole(selectedRowId, rolesList, editMode, valRef) {
  return createColumn(
    "Last Active Role",
    "role",
    createCellEditRoleDropdown(selectedRowId, rolesList, "role", editMode, valRef)
  );
}

export function createColumnAttackMechanism(setMechanism, setModalIsOpen) {
  return createColumn(
    "Attack Mechanism",
    "mechanism",
    (cellProps) => {
      return (
        <AttackMechanismNotesModal
          mechanism={cellProps.cell.row.original.mechanism}
          setMechanism={setMechanism}
          setModalIsOpen={setModalIsOpen}
        />
      );
    },
    { filter: "equals", Filter: SelectColumnFilter }
  );
}

export function createColumnAllocatedControlImplGuidance(setModalIsOpen) {
  return createColumn(
    "Impl Guidance",
    "implguidance",
    (cellProps) => {
      const implGuidance = `${cellProps.cell.row.original.controltype.name} Impl. Guidance`;
      return <AllocatedControlsImplGuidanceNotesModal implguidance={implGuidance} setModalIsOpen={setModalIsOpen} />;
    },
    { filter: "equals", Filter: SelectColumnFilter }
  );
}

export function createColumnControlsCount(selectedRowId, onClick, editMode, valRef, path, tab) {
  return createColumn(
    "Controls Count",
    "controlCount",
    createCellEditName(selectedRowId, "controlCount", onClick, editMode, valRef, { path, tab }, undefined, true)
  );
}

export function createColumnRiskLevel() {
  const accessor = "mitigatedRiskLevel";
  return createColumn(
    "Risk Level",
    accessor,
    (cellProps) => riskLevelMapper(resolvePathNoEdit(cellProps.cell.row.original, accessor)),
    {
      filter: (rows, columnId, filterValues) => {
        if (filterValues.length === 0) return rows;
        return rows.filter((r) => filterValues.includes(r.values[columnId]));
      },
      Filter: SelectRiskColumnFilter,
    },
    sortRiskLevel
  );
}

const columnCiaMap = {
  confidentiality: { colName: "Confidentiality", accessor: "categorization[0]" },
  integrity: { colName: "Integrity", accessor: "categorization[1]" },
  availability: { colName: "Availability", accessor: "categorization[2]" },
  safety: { colName: "Safety", accessor: "categorization[3]" },
  privacy: { colName: "Privacy", accessor: "categorization[4]" },
  performance: { colName: "Performance", accessor: "categorization[5]" },
};

const resolvePathCia = (categorization, id) =>
  categorization.find((obj) => obj.objective.name === columnCiaMap[id].colName)?.level.replace(/([A-Z])/g, " $1") ||
  COMMON.hyphen; // Regex inserts a space before all caps

const resolveLevelCia = (row, accessor) => {
  const ciaObjectivesInfo = Object.values(columnCiaMap);
  const objectiveName = ciaObjectivesInfo.find((obj) => obj.accessor === accessor).colName;
  const rowValues = row.values[accessor];
  if (rowValues && rowValues.objective.name === objectiveName) {
    return rowValues.level;
  }
  const categorization = row.original.categorization.find((obj) => obj.objective.name === objectiveName);
  return categorization ? categorization.level : "";
};

const sortCiaLevel = (rowA, rowB, accessor, desc) => {
  const levelValue = { "": 5, VeryLow: 4, Low: 3, Moderate: 2, High: 1, VeryHigh: 0 };
  const rowLevelA = resolveLevelCia(rowA, accessor);
  const rowLevelB = resolveLevelCia(rowB, accessor);

  // These three conditions keep the empty values at the bottom
  if (rowLevelA === "" && rowLevelB !== "") {
    return desc ? -1 : 1;
  }
  if (rowLevelA !== "" && rowLevelB === "") {
    return desc ? 1 : -1;
  }
  if (rowLevelA === "" && rowLevelB === "") {
    return 0;
  }

  if (levelValue[rowLevelA] > levelValue[rowLevelB]) return 1;
  if (levelValue[rowLevelB] > levelValue[rowLevelA]) return -1;
  return 0;
};

export function createColumnCiaTriadMapped(id) {
  const { colName, accessor } = columnCiaMap[id];
  return createColumn(
    colName,
    accessor,
    (cellProps) => resolvePathCia(cellProps.cell.row.original.categorization, id),
    {
      filter: (rows, columnAccessor, filterValues) => {
        const ciaObjectivesInfo = Object.values(columnCiaMap);
        const objectiveName = ciaObjectivesInfo.find((obj) => obj.accessor === columnAccessor[0]).colName;
        if (filterValues.length === 0) return rows;
        if (filterValues === COMMON.hyphen) {
          return rows.filter((r) =>
            ciaObjectivesInfo.every(
              (objective) =>
                !r.values[objective.accessor] || r.values[objective.accessor].objective.name !== objectiveName
            )
          );
        }
        return rows.filter((r) =>
          ciaObjectivesInfo.some(
            (objective) =>
              r.values[objective.accessor] &&
              r.values[objective.accessor].objective.name === objectiveName &&
              r.values[objective.accessor].level === filterValues
          )
        );
      },
      Filter: SelectCiaLevelColumnFilter,
    },
    sortCiaLevel
  );
}
