import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Styles
import FormStyledError from "components/forms/FormStyledError";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
// Recoil
import { useRecoilValue } from "recoil";

// Local
import { variantIdState } from "atoms/atoms-component";
import { COMMON } from "constants/brm";
import { LicenseAlert } from "components/elements";
import systemModals from "components/modals/Modals";
import { ExportApi, ExportContext, LICENSE_VALIDATION_ERROR_STATUS } from "features/brm";
import { EXPORT_STATUS } from "../../constants";
import S from "./ExportWizard.styles";
import { useDownloadFile, useGetExportStatus } from "../../api";

const STATEMENT = "Note this export can take some time";

export const ExportWizard = ({
  projectId,
  filePrefix,
  setExportModalIsOpen,
  exportContext,
  focus,
  riskRank = -1,
  showCurrentOption = true,
}) => {
  const [responseFromExport, setResponseFromExport] = useState();
  const [selectedOption, setSelectedOption] = useState(() => {
    if (exportContext === ExportContext.tra || exportContext === ExportContext.rar) {
      return "docx";
    }
    return "xlsx";
  });
  const [rangeValue, setRangeValue] = useState(undefined);
  const [selectedRange, setSelectedRange] = useState("");
  const [postError, setPostError] = useState("");
  const [disableExport, setDisableExport] = useState(false);
  const [exportStatus, setExportStatus] = useState("");
  const [licenseErrorIsOpen, setLicenseErrorIsOpen] = useState(false);
  const variantId = useRecoilValue(variantIdState);

  // Queries
  const { mutate: fileExport } = ExportApi.useExportFile({
    onSuccess: (data) => {
      setResponseFromExport(data);
    },
  });
  const { refetch: fetchDownload } = useDownloadFile(
    projectId,
    filePrefix,
    responseFromExport?.id,
    exportStatus,
    selectedOption,
    setExportModalIsOpen,
    exportContext
  );
  const { refetch: refetchStatus } = useGetExportStatus(
    projectId,
    responseFromExport?.id,
    setExportStatus,
    exportStatus,
    setPostError,
    setDisableExport,
    exportContext
  );

  useEffect(() => {
    if (exportContext === ExportContext.tra) {
      setSelectedRange("summary");
    } else {
      setSelectedRange("all");
    }
  }, [exportContext]);

  useEffect(() => {
    if (responseFromExport) {
      if (exportStatus && exportStatus === EXPORT_STATUS.pass) {
        fetchDownload();
      } else {
        refetchStatus();
      }
    }
  }, [exportStatus, fetchDownload, refetchStatus, responseFromExport]);

  /**
   * File Upload
   */
  const handleFileExport = (e) => {
    e.preventDefault();
    if (postError) {
      setPostError("");
    }

    if (selectedOption) {
      setDisableExport(true);

      if (exportContext === ExportContext.rar) {
        setRangeValue(e.target.elements.range.value);
        const input = e.target.elements.range.value || "";
        const opts = {
          target: selectedOption,
          "sctm-variant": variantId || COMMON.nullUuid,
          focus,
          range: selectedRange === "current" && riskRank >= 0 ? riskRank : input,
        };

        fileExport(
          { projectId, body: opts, context: exportContext },
          {
            onError: (error) => {
              if (error.status === LICENSE_VALIDATION_ERROR_STATUS) {
                setLicenseErrorIsOpen(true);
                setDisableExport(false);
              }
            },
          }
        );
      } else if (exportContext === ExportContext.tra) {
        const opts = {
          target: selectedOption,
          "sctm-variant": variantId,
          "report-type": selectedRange,
        };
        fileExport(
          { projectId, body: opts, context: exportContext },
          {
            onError: (error) => {
              if (error.status === LICENSE_VALIDATION_ERROR_STATUS) {
                setLicenseErrorIsOpen(true);
                setDisableExport(false);
              }
            },
          }
        );
      } else {
        const opts = {
          target: selectedOption,
          "sctm-variant": variantId || COMMON.nullUuid,
        };

        fileExport(
          { projectId, body: opts, context: exportContext },
          {
            onError: (error) => {
              if (error.status === LICENSE_VALIDATION_ERROR_STATUS) {
                setLicenseErrorIsOpen(true);
                setDisableExport(false);
              }
            },
          }
        );
      }
    } else setPostError("Please select an option to export");
  };
  /**
   *
   * @returns Wizard based on the import type
   */
  const GetExportWizard = () => {
    if (exportContext === ExportContext.rar) {
      return (
        <div>
          <S.Statement>{STATEMENT}</S.Statement>
          <label>Export as:</label>
          <S.ExportMenu>
            <S.List>
              <li>
                <input
                  type="radio"
                  value="docx"
                  checked={selectedOption === "docx"}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                <S.ExportLabel>DOCX</S.ExportLabel>
              </li>
              <li>
                <input
                  type="radio"
                  value="html"
                  checked={selectedOption === "html"}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                <S.ExportLabel>HTML</S.ExportLabel>
              </li>
            </S.List>

            <div>
              Range :
              <S.ExportMenu>
                <S.List>
                  <li>
                    <input
                      type="radio"
                      value="all"
                      checked={selectedRange === "all"}
                      onChange={(e) => {
                        setSelectedRange(e.target.value);
                      }}
                    />
                    <S.ExportLabel> All</S.ExportLabel>
                  </li>
                  {showCurrentOption ? (
                    <li>
                      <input
                        type="radio"
                        value="current"
                        checked={selectedRange === "current"}
                        onChange={(e) => {
                          setSelectedRange(e.target.value);
                        }}
                      />
                      <S.ExportLabel> Current</S.ExportLabel>
                    </li>
                  ) : null}
                  <li>
                    <input
                      type="radio"
                      value="range"
                      checked={selectedRange === "range"}
                      onChange={(e) => setSelectedRange(e.target.value)}
                    />
                    <S.ExportLabel> Range</S.ExportLabel>
                    <S.ExportLabel>
                      <input
                        type="text"
                        value={rangeValue}
                        name="range"
                        placeholder="eg: 1,2"
                        disabled={selectedRange !== "range"}
                      />
                    </S.ExportLabel>
                  </li>
                </S.List>
              </S.ExportMenu>
            </div>
          </S.ExportMenu>
        </div>
      );
    }

    if (exportContext === ExportContext.tra) {
      return (
        <div>
          <label>Export as :</label>
          <S.ExportMenu>
            <S.List>
              <li>
                <input
                  type="radio"
                  value="docx"
                  checked={selectedOption === "docx"}
                  onChange={(e) => {
                    setSelectedOption(e.target.value);
                    setSelectedRange("summary");
                  }}
                />
                <S.ExportLabel>DOCX</S.ExportLabel>
              </li>
              {/* <li>
                <input
                  type="radio"
                  value="html"
                  checked={selectedOption === "html"}
                  onChange={(e) => {
                    setSelectedOption(e.target.value);
                    setSelectedRange("summary");
                  }}
                />
                <S.ExportLabel>HTML</S.ExportLabel>
              </li> */}
              <li>
                <input
                  type="radio"
                  value="zip"
                  checked={selectedOption === "zip"}
                  onChange={(e) => {
                    setSelectedOption(e.target.value);
                    setSelectedRange("");
                  }}
                />
                <S.ExportLabel>TRA Templates only</S.ExportLabel>
              </li>
            </S.List>
            <div>
              Report Type :
              <S.ExportMenu>
                <S.List>
                  <li>
                    <input
                      type="radio"
                      value="summary"
                      checked={selectedRange === "summary"}
                      disabled={selectedOption === "zip"}
                      onChange={(e) => {
                        setSelectedRange(e.target.value);
                      }}
                    />
                    <S.ExportLabel> Summary</S.ExportLabel>
                  </li>
                  <li>
                    <input
                      type="radio"
                      value="full"
                      disabled={selectedOption === "zip"}
                      checked={selectedRange === "full"}
                      onChange={(e) => {
                        setSelectedRange(e.target.value);
                      }}
                    />
                    <S.ExportLabel> Full</S.ExportLabel>
                  </li>
                </S.List>
              </S.ExportMenu>
            </div>
          </S.ExportMenu>
        </div>
      );
    }

    return (
      <div>
        <label>Export as :</label>
        <S.ExportMenu>
          <S.List>
            <li>
              <input
                type="radio"
                value="json"
                checked={selectedOption === "json"}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
              <S.ExportLabel>json</S.ExportLabel>
            </li>
            <li>
              <input
                type="radio"
                value="xlsx"
                checked={selectedOption === "xlsx"}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
              <S.ExportLabel>xlsx</S.ExportLabel>
            </li>
          </S.List>
        </S.ExportMenu>
      </div>
    );
  };

  return (
    <div id="Wizard">
      {licenseErrorIsOpen &&
        systemModals.customModal(
          "wizard",
          licenseErrorIsOpen,
          setLicenseErrorIsOpen,
          <LicenseAlert />,
          "License Alert"
        )}

      <S.WizardExport id="Wizard_import">
        <form onSubmit={handleFileExport} action="" id="WizardForm_form">
          <GetExportWizard />
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setExportModalIsOpen(false)}>Cancel</DialogButtonStyled>
            <DialogButtonStyled type="submit" id="Wizard_export" disabled={disableExport}>
              {disableExport ? "Exporting..." : "Export"}
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </S.WizardExport>
      <div>
        <FormStyledError id="Importer_Error">{postError}</FormStyledError>
      </div>
    </div>
  );
};
ExportWizard.propTypes = {
  projectId: PropTypes.string.isRequired,
  filePrefix: PropTypes.string.isRequired,
  setExportModalIsOpen: PropTypes.func.isRequired,
  exportContext: PropTypes.string.isRequired,
  riskRank: PropTypes.number,
  focus: PropTypes.string,
  showCurrentOption: PropTypes.bool,
};
