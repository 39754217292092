import PropTypes from "prop-types";

// Components
import systemModals from "components/modals/Modals";
// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import S from "brm/reports/rar/RarReport.styles";

const PlacementDataModal = ({ modalIsOpen, setModalIsOpen, data }) => {
  const body = (
    <>
      <S.ModalBody>
        {data.map((each) => {
          return (
            <div key={each}>
              <label>
                <b>{each.contributor}</b>
              </label>
              <p>
                {each.targets.map((e) => {
                  return (
                    <div key={e}>
                      <p>{e.target}</p>
                      <p>{e.controls.join(", ")}</p>
                    </div>
                  );
                })}
              </p>
            </div>
          );
        })}
      </S.ModalBody>

      <DialogButtonDivStyled>
        <DialogButtonStyled id="PlacementDataModal_confirmButton" onClick={() => setModalIsOpen(false)}>
          OK
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </>
  );

  return (
    <div id="PlacementDataModal_modal">
      {systemModals.customModal(
        "PlacementDataModal_modal",
        modalIsOpen,
        setModalIsOpen,
        body,
        "RAR Additonal Placement Data"
      )}
    </div>
  );
};

PlacementDataModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  setModalIsOpen: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
};

export default PlacementDataModal;
