import * as React from "react";
import { REPORT } from "constants/brm";

import { BrmDetailTable } from "brm/tables/BrmTables";
import { handleNoteInTooltip, formattedTimeStamp, dloadJSON } from "utils/report-utils";
import { useModal } from "hooks";

// Styles
import * as S from "brm/styles/details-table.styles";

import { createColumnMappedNoEditRpt, createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading } from "components/elements";
import { ExportTableModal, useExportTable, EXPORT_TARGET } from "features/exporter";
import {
  useVariant,
  useKbCwes,
  useKbSfps,
  useKbSubcats,
  SystemApi,
  useAllVulnerabilitiesForNodes,
  ExportButton,
  ExportContext,
} from "features/brm";

interface ISystemCweGuidanceRptTableProps {
  selectedElement: any;
  tableTitle: string;
}

interface ICallbackOpts {
  target: string;
  type: string;
}

const SystemCweGuidanceRptTable = ({ selectedElement, tableTitle }: ISystemCweGuidanceRptTableProps) => {
  const { lookupCwes, lookupSubcatCwes } = useKbCwes();
  const { lookupSfps } = useKbSfps();
  const { lookupSubcats } = useKbSubcats();
  const { currentVariant } = useVariant();
  const { isVisible, toggle } = useModal();
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();

  const { data: leafNodes } = SystemApi.useNodes({
    projectId: selectedElement.id,
    options: {
      select: (data) => data.filter((n: any) => n.structuralcat === "Leaf"),
    },
  });

  const variantId = currentVariant?.id || "";
  const nodeVulResults = useAllVulnerabilitiesForNodes(leafNodes, variantId, {
    enabled: !!leafNodes && variantId !== "",
  });

  const allSuccessResults = nodeVulResults.every((result) => result.data !== undefined);

  const handleCweNameInTooltip = React.useCallback(
    (cell: any) => {
      // lookup the cwe name and display in tooltip for this column
      if (cell.value && lookupCwes) {
        const { cweName } = lookupCwes[cell.value];

        return (
          <div>
            <span title={cweName}>{cell.value}</span>
          </div>
        );
      }
      return (
        <div>
          <span title={cell.value}>{cell.value}</span>
        </div>
      );
    },
    [lookupCwes]
  );

  const handlesubcatDescriptionInTooltip = React.useCallback(
    (cell: any) => {
      // lookup the subcat description and display in tooltip for this column
      if (cell.value && lookupSubcats && lookupSubcats[cell.value]?.subcatName) {
        const subcatDesc = lookupSubcats[cell.value]?.subcatName;
        return (
          <div>
            <span title={subcatDesc}>{cell.value}</span>
          </div>
        );
      }
      return (
        <div>
          <span title={cell.value}>{cell.value}</span>
        </div>
      );
    },
    [lookupSubcats]
  );

  const columns = React.useMemo(
    () => [
      createColumnMappedNoEditRpt("targetName", handleNoteInTooltip),
      createColumnMappedNoEdit("targetType"),
      createColumnMappedNoEdit("vulnerability"),
      createColumnMappedNoEdit("vulnerabilityLevel"),
      createColumnMappedNoEditRpt("vulnerabilityCategory", handlesubcatDescriptionInTooltip),
      createColumnMappedNoEditRpt("cweId", handleCweNameInTooltip),
    ],
    [handleCweNameInTooltip, handlesubcatDescriptionInTooltip]
  );

  const createVulData = React.useCallback(
    (vulRes: any[]) => {
      const rptData: any = [];
      if (lookupSubcatCwes && lookupCwes && lookupSubcats && lookupSfps && vulRes.length) {
        for (let i = 0; i < vulRes.length; i += 1) {
          const vulList = vulRes[i];

          // Stuff in each vulnerability found for this bus
          for (let j = 0; j < vulList.length; j += 1) {
            const vulItem = vulList[j];
            /**
             * Create as many rows as required for each vulnerability (one per related cwe)
             */
            const relatedCwes = lookupSubcatCwes[vulItem.category] || [];

            relatedCwes.forEach((relatedCwe) => {
              const { sfpId } = lookupCwes[relatedCwe];
              // console.log("vulItem : ", vulItem);
              const vulRow = {
                targetName: vulItem.anobject.name,
                targetType: vulItem.anobject.noun,
                vulnerability: vulItem.name,
                vulnerabilityLevel: vulItem.level.calcStringValue,
                vulnerabilityCategory: vulItem.category,
                vulnerabilityCategoryDescription: lookupSubcats[vulItem.category]?.subcatName,
                cweId: relatedCwe,
                cweName: lookupCwes[relatedCwe].cweName,
                sfpId,
                sfpName: lookupSfps[sfpId]?.sfpName,
              };
              rptData.push(vulRow);
            });
          }
        }
      }
      return rptData;
    },
    [lookupCwes, lookupSfps, lookupSubcatCwes, lookupSubcats]
  );

  const systemCweGuidanceReport = React.useMemo(() => {
    if (allSuccessResults) {
      const vulResults = nodeVulResults.map((result) => result.data);
      const finalResults = createVulData(vulResults);
      return {
        data: finalResults,
        timestamp: formattedTimeStamp(),
        count: finalResults.length.toLocaleString(),
      };
    }
    return undefined;
  }, [allSuccessResults, createVulData, nodeVulResults]);

  /**
   * Download report
   */
  const handleDownloadClick = React.useCallback(
    (opts: ICallbackOpts) => {
      if (opts.target === EXPORT_TARGET.json) {
        if (!systemCweGuidanceReport) {
          return;
        }
        const fileName = selectedElement.name;
        const jsonData = {
          metaData: {
            reportType: REPORT.systemCweGuidance,
            project: fileName,
            reportGenerationDate: systemCweGuidanceReport.timestamp,
            numOfRows: systemCweGuidanceReport.count,
          },
          rows: systemCweGuidanceReport.data,
        };
        dloadJSON(jsonData, `BRMExport__${tableTitle}_${opts.type}.json`);
      } else if (opts.target === EXPORT_TARGET.csv) {
        handleTableExport(opts);
      }
    },
    [handleTableExport, selectedElement.name, systemCweGuidanceReport, tableTitle]
  );

  if (systemCweGuidanceReport && systemCweGuidanceReport.data) {
    return (
      <S.DetailsContainer>
        <ExportTableModal
          onTableExport={handleDownloadClick}
          isVisible={isVisible}
          toggle={toggle}
          context={ExportContext.listWithJson}
        />
        <S.ActionContainer>
          <S.DetailsTableContainer id="CweRptTable_table">
            <BrmDetailTable
              data={systemCweGuidanceReport.data}
              columns={columns}
              customProps={{ id: "SystemCweGuidanceRptTable_detailsTable" }}
              showRowSelect={false}
              tableTitle={tableTitle}
              setExportTableData={(currentData) =>
                setExportTableData((prevData) =>
                  prevData?.toString() !== currentData.toString() ? currentData : prevData
                )
              }
            />
          </S.DetailsTableContainer>
          <S.DetailButtonTopCorner>
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailButtonTopCorner>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

export default SystemCweGuidanceRptTable;
