type Names = "COMPLETED" | "STARTED" | "FAILED" | "UNKNOWN";

export class JobStatus {
  static values: JobStatus[] = [];

  static COMPLETED = new JobStatus("COMPLETED");

  static STARTED = new JobStatus("STARTED");

  static FAILED = new JobStatus("FAILED");

  static UNKNOWN = new JobStatus("UNKNOWN");

  name: string;

  constructor(name: Names) {
    this.name = name;
    JobStatus.values.push(this);
  }
}
