import * as React from "react";
import ReactModal from "react-modal";
import Cancel from "assets/icons/cancelModal.svg";
import { ActionButton } from "components/elements";
import * as S from "./Modal.styles";
import * as S2 from "./Modal.v2.styles";

interface IYesNodeModal {
  isVisible: boolean;
  title: string;
  onClose: (v: boolean) => void;
  content: React.ReactNode;
  yesButtonText?: string;
  noButtonText?: string;
}

export const defaultModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "0px",
    fontSize: "13px",
    borderRadius: "10px",
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
  overlay: {
    zIndex: 1000,
    backdropFilter: "contrast(90%)",
  },
};

export const YesNoModal = ({
  isVisible,
  onClose,
  title,
  content,
  yesButtonText = "Yes",
  noButtonText = "No",
}: IYesNodeModal) => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <ReactModal
      isOpen={isVisible}
      onAfterClose={handleClose}
      ariaHideApp={false}
      onRequestClose={handleClose}
      style={defaultModalStyles}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={200}
    >
      <S2.Header>
        <S2.ModalTitle>{title}</S2.ModalTitle>
        <S2.HeaderButtonsDiv>
          <S2.Close onClick={handleClose}>
            <S2.CloseIcon src={Cancel} />
          </S2.Close>
        </S2.HeaderButtonsDiv>
      </S2.Header>
      <S2.Body>{content}</S2.Body>
      <S2.Footer>
        <S.ButtonContainer>
          <ActionButton onClick={() => onClose(true)}>{yesButtonText}</ActionButton>
          <ActionButton onClick={() => onClose(false)}>{noButtonText}</ActionButton>
        </S.ButtonContainer>
      </S2.Footer>
    </ReactModal>
  );
};
