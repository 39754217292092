import PropTypes from "prop-types";

function InLineSelectBoxForUsers({ arrayOfData, item, updateValues }) {
  let options;
  if (Array.isArray(arrayOfData) && arrayOfData.length) {
    // check if there is any data in the array first
    options = arrayOfData.map((data) => (
      <option key={data.id} value={data.id}>
        {data.username}
      </option>
    ));
  }

  function handleFieldChange(e) {
    updateValues(e.target.value);
  }

  return (
    <select name={item} id={item} onBlur={handleFieldChange}>
      <option>Select {item}</option>
      {options}
    </select>
  );
}

InLineSelectBoxForUsers.propTypes = {
  arrayOfData: PropTypes.shape({
    map: PropTypes.func,
    length: PropTypes.number,
  }),
  item: PropTypes.any,
  updateValues: PropTypes.func,
  // value: PropTypes.any,
};

export default InLineSelectBoxForUsers;
