import * as React from "react";
import getReactSelectArray from "utils/react-select-array";
import PropTypes from "prop-types";
// Components
import { AddButton, currentVariantState, MitigationApi } from "features/brm";
import Select from "react-select";

// Styles
// import Buttons from "components/elements/TableButtonsDivStyled";
import FormStyled from "components/forms/FormStyled";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyledError from "components/forms/FormStyledError";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { COMMON } from "constants/brm";
import { ErrorBanner } from "components/elements";

const VulnerabilityVariantSelect = ({ setModalIsOpen, setVariantSelectModalIsOpen, onAutoMitigate, newVariant }) => {
  const [selectedVariant, setSelectedVariant] = React.useState("");
  const [postError, setPostError] = React.useState("");
  const projectId = useRecoilValue(projectIdState);
  const currentVariant = useRecoilValue(currentVariantState);
  const { data: variantsData, isError: isGetVariantsError } = MitigationApi.useVariantsWithScores({ projectId });

  const handleVariantSelectChange = (selectedItems = []) => {
    setSelectedVariant(selectedItems);
  };

  const handleConfirm = React.useCallback(() => {
    if (selectedVariant) {
      const variant = variantsData.find((v) => v.id === selectedVariant.value);
      const varState = {
        id: variant.id,
        name: variant.name,
        isRecalcNeeded: variant.isRecalcNeeded,
      };
      onAutoMitigate(varState);
      setVariantSelectModalIsOpen(false);
    } else {
      setPostError("Please select a variant.");
    }
  }, [onAutoMitigate, selectedVariant, setVariantSelectModalIsOpen, variantsData]);

  React.useEffect(() => {
    if (currentVariant && currentVariant.id !== COMMON.nullUuid && !newVariant) {
      setSelectedVariant({ label: currentVariant.name, value: currentVariant.id }); // show varaint preselected if already exisitng and not a intial one
    }
    if (newVariant && newVariant.name && newVariant.id) {
      setSelectedVariant({ label: newVariant.name, value: newVariant.id }); // show varaint preselect when a new variant is created
    }
  }, [newVariant, currentVariant]);

  if (isGetVariantsError) {
    return <ErrorBanner msg="Error while loading variants data" />;
  }

  return (
    <div id="VulnerabilityVariantSelect">
      <FormStyled id="VulnerabilityVariantSelect_form">
        <div
          id="VulnerabilityVariantSelect_buttonsSection"
          style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", paddingBottom: "5px" }}
        >
          <label>Select Variant:</label>
          {/* <Buttons id="VulnerabilityVariantSelect_buttons"> */}
          <div id="VulnerabilityVariantSelect_buttons">
            <AddButton onClick={() => setModalIsOpen(true)} />
          </div>
        </div>
        <Select
          options={getReactSelectArray(variantsData)}
          maxMenuHeight={300}
          defaultValue={selectedVariant}
          value={selectedVariant}
          onChange={handleVariantSelectChange}
          id="VulnerabilityVariantSelect_variantDropdown"
          classNamePrefix="VulnerabilityVariantSelect_dropdownList"
        />
        <FormStyledError id="VulnerabilityVariantSelect_error">{postError}</FormStyledError>
        <DialogButtonDivStyled id="VulnerabilityVariantSelect_formButtons">
          <DialogButtonStyled onClick={() => setVariantSelectModalIsOpen(false)} id="VulnerabilityVariantSelect_cancel">
            Cancel
          </DialogButtonStyled>
          <DialogButtonStyled
            disabled={!selectedVariant}
            onClick={handleConfirm}
            id="VulnerabilityVariantSelect_confirm"
          >
            Confirm
          </DialogButtonStyled>
        </DialogButtonDivStyled>
      </FormStyled>
    </div>
  );
};

VulnerabilityVariantSelect.propTypes = {
  setModalIsOpen: PropTypes.func,
  setVariantSelectModalIsOpen: PropTypes.func,
  onAutoMitigate: PropTypes.func,
  newVariant: PropTypes.any,
};

export default VulnerabilityVariantSelect;
