import * as ReactQuery from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";

/**
 * Custom hook to retreive a vulnerability.
 *
 * @param {string} assetId - uuid of a vulnerability
 * @returns react-query for getVulnerabilityByID
 */
export const getVulnerabilityById = async (vulId, variantId) => {
  const vulnerability = await Brm.vulnerabilityApi.getVulnerabilityByID(vulId, {
    variant: variantId,
  });
  vulnerability.object = await Brm.vulnerabilityApi.getVulnerabilityObject(vulId);

  return vulnerability;
};

export const useGetVulnerabilityById = (vulId, variantId, riskUpdated, config = {}) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["vulnerability", vulId, variantId, riskUpdated],
    queryFn: () => getVulnerabilityById(vulId, variantId),
  });
};
