import PropTypes from "prop-types";
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { exchangeApi } from "services/brm/system-service";
import handleApi from "services/brm/api-util";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import ButtonCell from "components/EditComponents/ButtonCell";
import { ElementFullDetails, SystemApi } from "features/brm";
import { TYPE } from "constants/brm";

import {
  createColumnMapped,
  createColumnBooleanNoEditMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading } from "components/elements";
import * as ReactQuery from "@tanstack/react-query";
import { useRoles } from "features/brm/hooks/useRoles";
import { RoutePath } from "routes/route-paths";
import ExchangeDetails from "../details/ExchangeDetails";

function ExchangeFullDetails({
  onCloseClick,
  exchangeId,
  defaultTabIndex = 0,
  defaultSubTabIndex = 0,
  hideSummaryTable = true,
}) {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const { id: pathId } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);

  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedCategory = useRef("");
  const updatedProducer = useRef("");
  const updatedConsumer = useRef("");
  const updatedData = useRef("");
  const updatedParent = useRef("");
  const updatedActProducer = useRef("");
  const updatedActConsumer = useRef("");
  const [id, setId] = useState(exchangeId || pathId);

  const queryClient = ReactQuery.useQueryClient();
  const { data: elementCats } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: nodes } = SystemApi.useNodes({ projectId, options: { enabled: isRiskAnalyst || isSystemEngineer } });
  const { data: datatypes } = SystemApi.useDataTypes({
    projectId,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: activities } = SystemApi.useActivities({
    projectId,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: exchange } = SystemApi.useExchange({
    exchangeId: id,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: exchangeParent } = SystemApi.useExchangeParent({
    exchangeId: id,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: exchangeProducer } = SystemApi.useExchangeProducer({
    exchangeId: id,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: exchangeConsumer } = SystemApi.useExchangeConsumer({
    exchangeId: id,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: exchangeData } = SystemApi.useExchangeData({
    exchangeId: id,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });

  useEffect(() => {
    if (!pathId) {
      setId(exchangeId);
      setRefresh(true);
    }
  }, [exchangeId, pathId]);

  useEffect(() => {
    if (exchange) {
      setSelectedElement(exchange);
    }
  }, [exchange]);

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedRowId}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedProducer.current !== "") {
              await handleApi(
                exchangeApi.setExchangeProducerWithHttpInfo(selectedRowId, {
                  body: updatedProducer.current,
                })
              );
              updatedProducer.current = "";
            }

            if (updatedConsumer.current !== "") {
              await handleApi(
                exchangeApi.setExchangeConsumerWithHttpInfo(selectedRowId, {
                  body: updatedConsumer.current,
                })
              );
              updatedConsumer.current = "";
            }

            if (updatedData.current !== "") {
              await handleApi(
                exchangeApi.setExchangeDataWithHttpInfo(selectedRowId, {
                  body: updatedData.current,
                })
              );
              updatedData.current = "";
            }

            if (updatedCategory.current !== "") {
              await handleApi(
                exchangeApi.setExchangeCategoryWithHttpInfo(selectedRowId, {
                  body: updatedCategory.current,
                })
              );
              updatedCategory.current = "";
            }

            if (updatedParent.current !== "") {
              await handleApi(
                exchangeApi.setExchangeParentWithHttpInfo(selectedRowId, {
                  body: updatedParent.current,
                })
              );
              updatedParent.current = "";
            }

            if (updatedActConsumer.current !== "") {
              await handleApi(
                exchangeApi.setExchangeActConsumerWithHttpInfo(selectedRowId, {
                  body: updatedActConsumer.current,
                })
              );
              updatedActConsumer.current = "";
            }

            if (updatedActProducer.current !== "") {
              await handleApi(
                exchangeApi.setExchangeActProducerWithHttpInfo(selectedRowId, {
                  body: updatedActProducer.current,
                })
              );
              updatedActProducer.current = "";
            }

            setRefresh(true);
            queryClient.invalidateQueries(["exchange", id]);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [selectedRowId, editMode, queryClient, id]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnCategoryMapped("category", selectedRowId, elementCats, editMode, updatedCategory),
        createColumnBooleanNoEditMapped("isInternal"),
        createColumnBooleanNoEditMapped("isBoundary"),
        createColumnBooleanNoEditMapped("isInput"),
        createColumnMapped("producer", selectedRowId, nodes, editMode, updatedProducer),
        createColumnMapped("consumer", selectedRowId, nodes, editMode, updatedConsumer),
        createColumnMapped("data", selectedRowId, datatypes, editMode, updatedData),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnCategoryMapped("category", selectedRowId, elementCats, false, null),
      createColumnBooleanNoEditMapped("isInternal"),
      createColumnBooleanNoEditMapped("isBoundary"),
      createColumnBooleanNoEditMapped("isInput"),
      createColumnMapped("producer", selectedRowId, nodes, false, null),
      createColumnMapped("consumer", selectedRowId, nodes, false, null),
      createColumnMapped("data", selectedRowId, datatypes, false, null),
    ];
  }, [isSystemEngineer, selectedRowId, elementCats, nodes, datatypes, editMode, createButton]);

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(RoutePath.Exchanges);
    }
  }, [projectId, navigate]);

  if (
    elementCats &&
    nodes &&
    datatypes &&
    activities &&
    exchange &&
    exchangeParent &&
    exchangeProducer &&
    exchangeConsumer &&
    exchangeData &&
    selectedElement
  ) {
    const exchangeToDisplay = {
      ...exchange,
      parent: exchangeParent,
      data: exchangeData,
      producer: exchangeProducer[0],
      consumer: exchangeConsumer[0],
    };

    const detailsComp = (
      <ExchangeDetails
        selectedElement={exchangeToDisplay}
        setRefresh={setRefresh}
        refresh={refresh}
        defaultTabIndex={defaultTabIndex}
        defaultSubTabIndex={defaultSubTabIndex}
      />
    );

    return (
      <ElementFullDetails
        onCloseClick={onCloseClick}
        showBackLink={!onCloseClick}
        data={[exchangeToDisplay]}
        columns={columns}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        setSelectedRowId={setSelectedRowId}
        detailsComp={detailsComp}
        compName={TYPE.exchange}
        hideSummaryTable={hideSummaryTable}
      />
    );
  }
  return <Loading />;
}

ExchangeFullDetails.propTypes = {
  exchangeId: PropTypes.string,
  onCloseClick: PropTypes.func,
  defaultTabIndex: PropTypes.number,
  defaultSubTabIndex: PropTypes.number,
  hideSummaryTable: PropTypes.bool,
};

export default ExchangeFullDetails;
