import styled from "styled-components/macro";
import Form from "react-bootstrap/Form";

export const ProfileRoot = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

export const ProfileContainer = styled.div`
  display: flex;
  margin: 0px auto;
  flex-direction: column;
  min-height: 100vh;
  max-width: 624px;
  padding: 0px 20px 32px;
  flex: 1 1 auto;
`;

export const PasswordGroup = styled.div``;

export const TitleContainer = styled.div`
  margin: 24px 0px 16px;

  h1 {
    font-weight: 500;
    font-size: 1.71429em;
    line-height: 32px;
    letter-spacing: -0.01em;
  }
`;

export const AvatorGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserAvatarContainer = styled.div`
  margin: 12px;
`;

export const FormRoot = styled.div`
  display: block;

  form {
    margin: 12px;
  }
`;

export const ProfileTitle = styled.h3`
  margin-top: 20px;
  font-size: 1.14286em;
  font-weight: 600;
  line-height: 1.25;
`;

export const FieldLabel = styled(Form.Label)`
  font-size: 0.857143em;
  line-height: 1.33333;
  font-weight: 600;
  color: #6b778c;
  margin-top: 0px;
  margin-bottom: 4px;
`;
