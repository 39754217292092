import { useState } from "react";
// Styles
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

// Local
import AttackTacticTable from "./tables/AttackTacticTable";

function AttackTactic() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const attackTacticHeader = <Title id="AttackTactic_title">Attack Tactics</Title>;

  const attackTacticMain = (
    <AttackTacticTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
  );

  return <ContentLayout pageId="AttackTactic_page" header={attackTacticHeader} main={attackTacticMain} />;
}

export default AttackTactic;
