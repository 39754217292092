import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./export-query-key-factory";
import { exportKnowledgebase } from "./exportKnowledgebase";

type FetcherFunction = typeof exportKnowledgebase;
export interface IUseExportKnowledgebaseQuery {
  kbId: string;
  format: string;
  options?: QueryOption<FetcherFunction>;
}

/**
 * Custom hook to export a knowledgebase.
 *
 * @param {string} kbId - kb id
 * @param {format} format - format of the export
 * @param {object} options - react-query options
 */
export const useExportKnowledgebaseQuery = ({ kbId, format, options }: IUseExportKnowledgebaseQuery) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exportKeys.knowledgebase(kbId, format),
    queryFn: () => exportKnowledgebase({ kbId, format }),
  });
};
