import * as React from "react";
import * as Recoil from "recoil";
import * as BrmGql from "generated/graphql";
import { queryClient } from "libs/react-query";
import log from "loglevel";
import { riskCalcStatus } from "atoms/atoms-risk";
import { MitigationApi, ReportsApi, RiskApi } from "features/brm";

const POLL_INTERVAL = 1000;
const EMPTY_RISK_RUN_ID = "";

export const useCalculateRisk = ({
  onSuccess,
  onError,
  onRiskStart,
}: {
  onSuccess: Function;
  onError: Function;
  onRiskStart?: (v: string) => void;
}) => {
  const [calcRiskRunId, setRiskRunId] = React.useState<string>(EMPTY_RISK_RUN_ID);
  const [stop, setStop] = React.useState(false);
  const setRiskCalcStatus = Recoil.useSetRecoilState<BrmGql.CompletionStatus | null>(riskCalcStatus);

  const { mutate: calculateRisk } = BrmGql.useCalcRiskMutation({
    onMutate: () => {
      setStop(false);
    },
    onError: (error) => {
      log.error("Calculate Risk Error:", error);
      onError();
      setStop(true);
    },
    onSuccess: (data) => {
      if (data && data.calcRisk) {
        if (onRiskStart) {
          onRiskStart(data.calcRisk);
        }
        setRiskRunId(data.calcRisk);
        setRiskCalcStatus(BrmGql.CompletionStatus.Started);
      }
    },
  });

  BrmGql.useCalcRiskStatusQuery(
    { run: calcRiskRunId },
    {
      onSuccess: (pollData) => {
        if (pollData.calcRiskStatus === BrmGql.CompletionStatus.Completed) {
          queryClient.invalidateQueries(MitigationApi.variantsKeys.all);
          queryClient.invalidateQueries(RiskApi.vulnerabilityGqlKeys.all);
          queryClient.invalidateQueries(RiskApi.vulnerabilityKeys.all);
          queryClient.invalidateQueries(RiskApi.riskKeys.all);
          queryClient.invalidateQueries(RiskApi.attackKeys.all);
          queryClient.invalidateQueries(RiskApi.undesiredEventKeys.all);
          queryClient.invalidateQueries(ReportsApi.rarKeys.all);
          setRiskCalcStatus(BrmGql.CompletionStatus.Completed);
          setRiskRunId(EMPTY_RISK_RUN_ID);
          setStop(true);
          onSuccess(calcRiskRunId);
        } else if (pollData.calcRiskStatus === BrmGql.CompletionStatus.Failed) {
          setRiskCalcStatus(BrmGql.CompletionStatus.Failed);
          setRiskRunId(EMPTY_RISK_RUN_ID);
          setStop(true);
          onError(calcRiskRunId);
        }
      },
      onError: (error) => {
        log.error("Status query Error:", error);
        setStop(true);
        onError();
      },
      enabled: calcRiskRunId !== EMPTY_RISK_RUN_ID,
      refetchInterval: stop ? false : POLL_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );

  return {
    calculateRisk,
  };
};
