import * as React from "react";

import { riskUpdatedState } from "atoms/atoms-risk";
import { useSetRecoilState } from "recoil";
import Select from "react-select";
import { customStyles } from "components/elements/ReactSelectCustomStyles";
import toast from "react-hot-toast";
import { useVariant } from "features/brm/hooks/useVariant";
import { createOptionsFromProps } from "utils/react-select-utils";

export const VariantSelector = () => {
  const {
    variantId,
    variants,
    isError: isVariantError,
    handlers: { setCurrentVariant, refreshVariants },
  } = useVariant();
  const setRiskUpdated = useSetRecoilState(riskUpdatedState);

  const handleVariantSelectChange = React.useCallback(
    async (selectedItem = {}) => {
      setRiskUpdated(true);
      if (selectedItem.value) {
        const variant = variants.find((v) => v.id === selectedItem.value);
        setCurrentVariant(variant);
      }
    },
    [setCurrentVariant, setRiskUpdated, variants]
  );

  React.useEffect(() => {
    if (isVariantError) {
      toast.error("Error occured while loading variants");
    }
  }, [isVariantError]);

  const varOptions = React.useMemo(() => {
    return variants ? createOptionsFromProps({ src: variants, label: "name", value: "id" }) : [];
  }, [variants]);

  return (
    <span title="Variant Selection">
      <Select
        id="VariantDropdownSelection_variantDropdown"
        classNamePrefix="variantDropdown"
        menuPortalTarget={document.body}
        isMulti={false}
        options={varOptions}
        value={varOptions.filter((option) => {
          return option.value === variantId;
        })}
        onChange={handleVariantSelectChange}
        placeholder="Select Variant..."
        styles={customStyles}
        onBlur={refreshVariants}
        onFocus={refreshVariants}
      />
    </span>
  );
};
