import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

export interface ISetSystemAssetTypeCpe {
  saTypeId: string;
  cpeId: string;
}

export const setSystemAssetTypeCpe = ({ saTypeId, cpeId }: ISetSystemAssetTypeCpe) => {
  return Brm.systemAssetTypeApi.setSystemAssetTypeCpe(saTypeId, { body: cpeId });
};

export interface IUseSetSystemAssetTypeCpe {
  projectId: string;
  options?: MutationOption<typeof setSystemAssetTypeCpe>;
}

/**
 * Custom hook to change the name of a existing system asset type
 * @returns react-query ReactQuery.useMutation
 */
export const useSetSystemAssetTypeCpe = ({ options }: IUseSetSystemAssetTypeCpe) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.systemAssetTypeKeys.all);
    },
    ...options,
    mutationFn: setSystemAssetTypeCpe,
  });
};
