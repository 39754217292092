import { useState, useRef, useMemo, useCallback } from "react";
import { BrmMainTable } from "brm/tables/BrmTables";
import { GlobalControlApi, useRoles } from "features/brm";
import { controlFamilyIdState } from "atoms/atoms-global-control";
import { selectedInstanceState } from "atoms/atoms-content";
import * as Recoil from "recoil";
import CustomTable from "components/elements/CustomTableDivStyled";
import PropTypes from "prop-types";
import { strengthLevels } from "features/mitigation";
import { TYPE } from "constants/brm";

import {
  createColumnName,
  createColumnStrength,
  createColumnMappedNoEdit,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import ButtonCell from "components/EditComponents/ButtonCell";
import { LoadingSpinner as Loading, ErrorBanner as ErrorDisplay } from "components/elements";
import { useQueryClient } from "@tanstack/react-query";
import { useControlCatalog } from "../../../hooks";

export const ControlTypeTable = ({ setSelectedRows }) => {
  const { isControlsCoordinator } = useRoles();
  const controlFamilyId = Recoil.useRecoilValue(controlFamilyIdState);
  const [selectedInstance, setSelectedInstance] = Recoil.useRecoilState(selectedInstanceState);

  const [editElement, setEditElement] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedStrength = useRef("");

  const queryClient = useQueryClient();
  const { controlCatalog, isReadOnlyCatalog } = useControlCatalog();

  const { data, isError, error } = GlobalControlApi.useControlTypes({
    controlFamilyId,
    options: { enabled: !!controlFamilyId && isControlsCoordinator },
  });
  const { mutate: setControlTypeStrength } = GlobalControlApi.useSetControlTypeStrength({
    option: {
      onSuccess: () => {
        updatedStrength.current = "";
        queryClient.invalidateQueries(["controlTypes", controlFamilyId]);
      },
    },
  });

  const handleConfirmEdit = useCallback(() => {
    setEditMode(false);
    if (updatedStrength.current !== "") {
      // mutate
      setControlTypeStrength({ controlTypeId: editElement?.id, strength: updatedStrength.current });
    }
  }, [editElement, setControlTypeStrength]);

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={editElement?.id || ""}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={handleConfirmEdit}
          setEditMode={setEditMode}
          editMode={editMode}
          onEditModeStart={() => {
            setEditElement(cellProps.cell.row.original);
          }}
        />
      );
    },
    [editElement, handleConfirmEdit, editMode]
  );

  const columns = useMemo(() => {
    let result = [
      createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "controlType"),
      createColumnStrength(selectedInstance.id, strengthLevels, editMode, updatedStrength),
      createColumnMappedNoEdit("catalog"),
      createColumnMappedNoEdit("family"),
    ];
    if (!isReadOnlyCatalog) {
      result = [...result, createColumnActions(createButton, { disableFilters: true })];
    }
    return result;
  }, [selectedInstance.id, setSelectedInstance, editMode, isReadOnlyCatalog, createButton]);

  if (isError) {
    return <ErrorDisplay msg={error?.statusText} />;
  }

  if (!controlFamilyId) {
    return null;
  }

  if (data && controlCatalog) {
    return (
      <CustomTable>
        <BrmMainTable
          data={data}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "ControlTypeTable_table" }}
          elementName={TYPE.controlType}
          showRowSelect={!isReadOnlyCatalog}
        />
      </CustomTable>
    );
  }

  return <Loading />;
};

ControlTypeTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};
