import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import NavListIconStyled from "components/elements/NavListIconStyled";
import FilterIcon from "assets/icons/filter.svg";
import systemModals from "components/modals/Modals";

import { calibrationStyle } from "components/elements/ReactSelectCustomStyles";
import styled from "styled-components";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import BrmCsaReportTable from "brm/tables/BrmTables/BrmCSAReportTable";
import { ExportButton, ExportContext } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { useModal } from "hooks";
import * as DS from "brm/styles/details-table.styles";
import * as S from "./styles/frameworkTable.styles";
import { useGetCsaData, useGetCsaSubCategories } from "./kppframework-table-queries";

const SHOW_ALL = "show all";
const SHOW_TARGETS = "show targets";
const SHOW_ZERO = "show zero";

const KppFrameworkTable = ({ selectedElement, tableTitle }) => {
  const allOption = { value: "All", label: "All" };
  const [modifiedData, setModifiedData] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFunction, setSelectedFunction] = useState(allOption);
  const [selectedSubCat, setSelectedSubCat] = useState(allOption);
  const [selectedChoice, setSelectedChoice] = useState(SHOW_ALL);
  const [modalData, setModalData] = useState([]);
  const [showACModal, setShowACModal] = useState(false);
  const { data: csaData, isError: isCsaDataError } = useGetCsaData(selectedElement);
  const subcatOptions = useGetCsaSubCategories(selectedFunction.value, csaData);

  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  const functionsArray = [
    allOption,
    {
      value: "Prevent",
      label: "Prevent",
    },
    {
      value: "Mitigate",
      label: "Mitigate",
    },
    {
      value: "Recover",
      label: "Recover",
    },
    {
      value: "Adapt",
      label: "Adapt",
    },
  ];

  const columns = useMemo(() => {
    const makeFunctionCell = ({ row }) => {
      return <FunctionCell name={row.original.function}>{row.original.function}</FunctionCell>;
    };

    const makeTargetsCell = ({ row }) => {
      if (row.original.target !== 0) {
        return (
          <Button
            type="button"
            onClick={() => {
              setModalData(row.original);
              setShowACModal(true);
            }}
          >
            <p>{row.original.target}</p>
          </Button>
        );
      }
      return 0;
    };

    return [
      {
        Header: "KPP Pillar",
        accessor: "function",
        Cell: makeFunctionCell,
      },
      {
        Header: "CSA",
        accessor: "subcategory",
      },
      {
        Header: "Control type",
        accessor: "control",
      },
      {
        Header: "Target count",
        accessor: "target",
        Cell: makeTargetsCell,
      },
    ];
  }, []);

  const handleFuncChange = (selectedItems = []) => {
    const items = selectedItems;
    setSelectedSubCat(allOption);
    setSelectedFunction(items);
  };

  const handleSubCatChange = (selectedItems = []) => {
    const items = selectedItems;
    setSelectedSubCat(items);
  };

  const DisplayAllocControls = ({ data }) => {
    if (data?.objects?.length > 0) {
      return (
        <div>
          <p>
            List of targets under control type <b>{data.control}</b> of sub category <b>{data.subcategory}</b>:
          </p>
          <br />
          <div style={{ maxHeight: "300px", overflow: "auto" }}>
            <ul style={{ paddingLeft: "40px" }}>
              {data.objects.map((e) => (
                <li key={e}>{e.anobject.name}</li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
    return "No controls";
  };

  DisplayAllocControls.propTypes = {
    data: PropTypes.any,
  };

  useEffect(() => {
    if (csaData && csaData.length > 0) {
      let array = csaData;
      if (selectedFunction?.value !== "All") {
        array = csaData.filter((e) => e.function === selectedFunction.value);
      }

      if (selectedSubCat?.value !== "All") {
        array = csaData.filter((e) => e.subcategory === selectedSubCat.value);
      }

      let hideZeroItems = array;

      if (selectedChoice === SHOW_TARGETS) {
        hideZeroItems = array.filter((e) => e.target !== 0);
      }
      if (selectedChoice === SHOW_ZERO) {
        hideZeroItems = array.filter((e) => e.target === 0);
      }

      const functions = hideZeroItems.reduce((result, item) => {
        // First, take the name field as a new array result
        if (result.indexOf(item.function) < 0) {
          result.push(item.function);
        }
        return result;
      }, []);
      // get array of sub-categories
      const subCategories = hideZeroItems.reduce((result, item) => {
        if (result.indexOf(item.subcategory) < 0) {
          result.push(item.subcategory);
        }
        return result;
      }, []);

      // Add function cell span info
      const dataWithFuncSpanInfo = functions.reduce((result, func) => {
        const funcList = hideZeroItems.filter((item) => item.function === func);
        // eslint-disable-next-line no-param-reassign
        result = result.concat(
          funcList.map((item, index) => ({
            ...item,
            Rowspan: index === 0 ? funcList.length : 0, // add rowspan field to the first row of data
          }))
        );

        return result;
      }, []);

      const dataWithSubCatSpanInfo = subCategories.reduce((result, sub) => {
        const subCatList = dataWithFuncSpanInfo.filter((item) => item.subcategory === sub);

        // eslint-disable-next-line no-param-reassign
        result = result.concat(
          subCatList.map((item, index) => ({
            ...item,
            subCatRowSpan: index === 0 ? subCatList.length : 0,
          }))
        );
        return result;
      }, []);

      setModifiedData(dataWithSubCatSpanInfo);
    }
  }, [csaData, selectedChoice, selectedFunction.value, selectedSubCat.value]);

  if (isCsaDataError) {
    return <ErrorBanner msg="Error while loading CSA Data" />;
  }

  if (csaData) {
    return (
      <>
        {showACModal &&
          systemModals.customModal(
            "CSFrameworkTable_modal",
            showACModal,
            setShowACModal,
            <DisplayAllocControls data={modalData} />,
            "Targets"
          )}
        <S.FilterSection>
          <S.ShowFilterButton onClick={() => setShowFilters(() => !showFilters)}>
            <NavListIconStyled src={FilterIcon} />
            Filters
          </S.ShowFilterButton>

          <S.HideLabel>
            <input
              type="radio"
              value={SHOW_ALL}
              checked={selectedChoice === SHOW_ALL}
              onChange={() => {
                setSelectedChoice(SHOW_ALL);
              }}
            />{" "}
            Show All
          </S.HideLabel>
          <S.HideLabel>
            <input
              type="radio"
              value={SHOW_TARGETS}
              checked={selectedChoice === SHOW_TARGETS}
              onChange={() => {
                setSelectedChoice(SHOW_TARGETS);
              }}
            />{" "}
            Show with Targets
          </S.HideLabel>

          <S.HideLabel>
            <input
              type="radio"
              value="show zero"
              checked={selectedChoice === SHOW_ZERO}
              onChange={() => {
                setSelectedChoice(SHOW_ZERO);
              }}
            />{" "}
            Show Gaps
          </S.HideLabel>

          <br />
          {showFilters ? (
            <>
              <S.SelectDiv>
                <S.SelectLabel>
                  KPP Pillar
                  <Select
                    options={functionsArray}
                    defaultValue={selectedFunction}
                    onChange={handleFuncChange}
                    styles={calibrationStyle}
                  />
                </S.SelectLabel>
              </S.SelectDiv>

              {selectedFunction.value !== "All" && (
                <S.SelectDiv>
                  <S.SelectLabel>
                    CSA
                    <Select
                      options={subcatOptions}
                      defaultValue={selectedSubCat}
                      onChange={handleSubCatChange}
                      styles={calibrationStyle}
                    />
                  </S.SelectLabel>
                </S.SelectDiv>
              )}
            </>
          ) : null}
        </S.FilterSection>
        <ExportTableModal
          onTableExport={handleTableExport}
          isVisible={isVisible}
          toggle={toggle}
          context={ExportContext.unfilteredTable}
        />
        <DS.DetailsContainer id="KppFrameworkTable_detailsPanel">
          <DS.ActionContainer>
            <DS.DetailsTableContainer>
              <BrmCsaReportTable
                columns={columns}
                data={modifiedData}
                elementName={selectedElement.name}
                tableTitle={tableTitle}
                setExportTableData={setExportTableData}
              />
            </DS.DetailsTableContainer>
            <DS.DetailsTableButtonsContainer>
              <ExportButton onClick={() => toggle()} disableExport={disableExport} />
            </DS.DetailsTableButtonsContainer>
          </DS.ActionContainer>
        </DS.DetailsContainer>
      </>
    );
  }

  return <Loading />;
};

const FunctionCell = styled.p`
  font-weight: 500;
`;

const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  color: blue;
  width: 25px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: blue;
    text-decoration: underline;
  }
  .p {
    color: blue;
  }
`;

KppFrameworkTable.propTypes = {
  selectedElement: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default KppFrameworkTable;
