import { COMMON } from "constants/brm";
import { Select } from "./styles/tables.styles";

const SelectCiaLevelColumnFilter = ({ column: { setFilter } }: any) => (
  <Select
    id="custom-select"
    onChange={(e) => {
      setFilter(e.target.value || undefined);
    }}
  >
    <option value="">All</option>
    <option value={COMMON.hyphen}>{COMMON.hyphen}</option>
    <option value="VeryLow">{COMMON.veryLow}</option>
    <option value="Low">{COMMON.low}</option>
    <option value="Moderate">{COMMON.moderate}</option>
    <option value="High">{COMMON.high}</option>
    <option value="VeryHigh">{COMMON.veryHigh}</option>
  </Select>
);

export default SelectCiaLevelColumnFilter;
