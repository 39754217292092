import { AdminApi, useUser } from "features/brm";
import { isNotDeletedStatus } from "utils/filter-utils";

export const useAdminOrganization = () => {
  const { userId } = useUser();

  const { data: organization } = AdminApi.useUserPrimaryOrganization({
    userId,
    options: {
      enabled: !!userId,
    },
  });

  const organizationId = organization?.id || null;
  const { data: organizationUsers } = AdminApi.useOrganizationUser({
    organizationId,
    options: {
      enabled: !!organization,
      select: (data) => {
        return data.filter(isNotDeletedStatus);
      },
    },
  });

  const { data: organizationProjects } = AdminApi.useOrganizationProjects({
    organizationId,
    options: {
      enabled: !!organization,
    },
  });

  return {
    userId,
    organizationId,
    organizationName: organization?.name,
    organizationUsers,
    organizationProjects,
  };
};
