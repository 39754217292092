import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

/**
 * get baselines of cntrl catalog
 *
 * @param {string} catalogId - uuid of the control catalog
 */
export const getCatalogBaselines = ({ catalogId }: { catalogId: string }) => {
  return Brm.controlCatalogApi.getControlCatalogBaseline(catalogId);
};

interface ICatalogBaselines {
  catalogId: string;
  options?: QueryOption<typeof getCatalogBaselines>;
}

/**
 * React-query hook for getting  baselines from a control catalog
 *
 * @param {string} catalogId - uuid of the control Catalog
 * @param {object} options - configuration for useControlCatalog hook
 * @returns react-query for getCatalogBaselines
 */
export const useCatalogBaselines = ({ catalogId, options = {} }: ICatalogBaselines) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.catalogBaselineKeys.catalog(catalogId),
    queryFn: () => getCatalogBaselines({ catalogId }),
  });
};
