import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./exchange-query-key-factory";

/**
 * Retrieve category for an exchange
 *
 * @param {string} exchangeId - uuid of exchange
 * @returns list of all persons within the given exchange
 */
export const getExchangeCategory = ({ exchangeId }: { exchangeId: string | null }) => {
  return exchangeId === null
    ? Promise.reject(new Error("Invalid exchangeId id"))
    : Brm.exchangeApi.getExchangeCategory(exchangeId);
};

interface IExchangeCategoryProps {
  exchangeId: string | null;
  options?: QueryOption<typeof getExchangeCategory>;
}

/**
 * Custom-hook to retrieve category for an exchange.
 *
 * @param {string} exchangeId - uuid of exchange
 * @param {object} options - react-query configuration object
 * @returns react-query for findNode
 */
export const useExchangeCategory = ({ exchangeId, options = {} }: IExchangeCategoryProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exchangeKeys.category(exchangeId),
    queryFn: () => getExchangeCategory({ exchangeId }),
  });
};
