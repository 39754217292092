import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import SystemAssetTable from "brm/tables/details/SystemAssetTable";
import PropTypes from "prop-types";
import { REPORT } from "constants/brm";
import DynamicSubTabSelector from "components/elements/DynamicSubTabSelector";
import { DetailsTableMap } from "brm/tables/details/DetailsTableMap";
import { DetailTableType } from "features/brm";
import { CenterText } from "components/elements";
import { defaultReportTabState, isReportsTabDefaultState } from "atoms";
import { useRecoilState } from "recoil";

const systemMenu = [DetailTableType.TARGETS, DetailTableType.TOPIC];

const riskMenu = [DetailTableType.RISKS, DetailTableType.ASSETS, DetailTableType.UNDESIRED_EVENTS];

const attacksMenu = [DetailTableType.THREAT_EVENTS, DetailTableType.ATTACKS, DetailTableType.ATTACKERS];

const vulnerabilityMenu = [DetailTableType.VULNERABILITY_CONDITIONS, DetailTableType.VULNERABILITY_FINDINGS];

const controlsMenu = [DetailTableType.ALLOCATED_CONTROLS];

const reportsMenu = [DetailTableType.RAR_RPT];

const elementNameInUrl = DetailTableType.RISKS.key;

const RiskDetails = ({ selectedElement }) => {
  const [selectedSystemContext, setSelectedSystemContext] = React.useState(DetailTableType.TARGETS.key);
  const [selectedRiskContext, setSelectedRiskContext] = React.useState(DetailTableType.RISKS.key);
  const [selectedAttackContext, setSelectedAttackContext] = React.useState(DetailTableType.THREAT_EVENTS.key);
  const [selectedVulContext, setSelectedVulContext] = React.useState(DetailTableType.VULNERABILITY_CONDITIONS.key);
  const [selectedControlsContext, setSelectedControlsContext] = React.useState(DetailTableType.ALLOCATED_CONTROLS.key);
  const [selectedReportContext, setSelectedReportContext] = React.useState(DetailTableType.RAR_RPT.key);
  const [isReportsTabDefault, setIsReportsTabDefault] = useRecoilState(isReportsTabDefaultState);
  const [defaultReportsTab, setDefaultReportsTab] = useRecoilState(defaultReportTabState);
  const [curr, setCurr] = React.useState("");
  const [tabIndex, setTabIndex] = React.useState(0);

  const onSelect = (index) => {
    setTabIndex(index);
  };

  React.useEffect(() => {
    if (isReportsTabDefault) {
      setTabIndex(7);
      // setSelectedReportContext(DetailTableType.RAR_RPT.key);
      setSelectedReportContext(defaultReportsTab);

      // setSelectedReportContext(defaultReportsTab);
    }
  }, [defaultReportsTab, isReportsTabDefault]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={onSelect}>
      <TabList>
        <Tab onClick={() => setCurr(DetailTableType.SYSTEM.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.SYSTEM.name}
            menu={systemMenu}
            setSelectedContext={setSelectedSystemContext}
            curr={curr}
          />
        </Tab>
        <Tab>{DetailTableType.ACCESS.name}</Tab>
        <Tab onClick={() => setCurr(DetailTableType.SYSTEM_ASSETS.name)}>{DetailTableType.SYSTEM_ASSETS.name}</Tab>

        <Tab onClick={() => setCurr(DetailTableType.RISK.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.RISK.name}
            menu={riskMenu}
            setSelectedContext={setSelectedRiskContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.ATTACK.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.ATTACK.name}
            menu={attacksMenu}
            setSelectedContext={setSelectedAttackContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.VULNERABILITIES.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.VULNERABILITIES.name}
            menu={vulnerabilityMenu}
            setSelectedContext={setSelectedVulContext}
            curr={curr}
          />
        </Tab>
        <Tab onClick={() => setCurr(DetailTableType.CONTROLS.name)}>
          <DynamicSubTabSelector
            title={DetailTableType.CONTROLS.name}
            menu={controlsMenu}
            setSelectedContext={setSelectedControlsContext}
            curr={curr}
          />
        </Tab>
        <Tab
          onClick={() => {
            // console.log("here");
            setCurr(REPORT.reports);
            setDefaultReportsTab(DetailTableType.RAR_RPT.key);
            setIsReportsTabDefault(true);
            // setSelectedReportContext(DetailTableType.RAR_RPT.key);
          }}
        >
          <DynamicSubTabSelector
            title={REPORT.reports}
            menu={reportsMenu}
            setSelectedContext={setSelectedReportContext}
            curr={curr}
          />
        </Tab>
      </TabList>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedSystemContext}
        />
      </TabPanel>

      <TabPanel>
        <CenterText msg="Access is not applicable" />
      </TabPanel>

      <TabPanel>
        <SystemAssetTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} showDelete={false} />
      </TabPanel>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedRiskContext}
        />
      </TabPanel>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedAttackContext}
        />
      </TabPanel>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedVulContext}
        />
      </TabPanel>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedControlsContext}
        />
      </TabPanel>

      <TabPanel>
        <DetailsTableMap
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          selectedContext={selectedReportContext}
        />
      </TabPanel>
    </Tabs>
  );
};

RiskDetails.propTypes = {
  selectedElement: PropTypes.object.isRequired,
};

export default RiskDetails;
