import * as Recoil from "recoil";
import * as React from "react";

import { controlCatalogIdState } from "atoms/atoms-global-control";
import { DEFAULT_CATALOG_NAME } from "constants/brm";
import * as GlobalControlApi from "../../api/global-control";
import * as AdminApi from "../../api/admin";

export const useControlCatalog = () => {
  const controlCatalogId = Recoil.useRecoilValue(controlCatalogIdState);

  const { data: controlCatalog } = GlobalControlApi.useControlCatalog({ catalogId: controlCatalogId || "" });
  const { data: selfData } = AdminApi.useSelf({ options: {} });

  const result = React.useMemo(() => {
    if (controlCatalog && selfData) {
      // looks like the only way to determine the default catalogs are by using the name... isDefault is always true.
      const isBuiltIn =
        controlCatalog.name === DEFAULT_CATALOG_NAME.name_4 || controlCatalog.name === DEFAULT_CATALOG_NAME.name_5;

      return {
        controlCatalogId,
        controlCatalog,
        isBuiltInCatalog: isBuiltIn,
        isReadOnlyCatalog: isBuiltIn || selfData.organization !== controlCatalog.organization,
      };
    }

    return {
      controlCatalogId,
      controlCatalog: undefined,
      isBuiltInCatalog: false,
      isReadOnlyCatalog: false,
    };
  }, [controlCatalog, controlCatalogId, selfData]);

  return result;
};
