export type ContextValues = Exclude<keyof typeof Context, "prototype" | "fromId" | "fromName" | "values">;

export class Context {
  static values: Context[] = [];

  static readonly SCTM = new Context("SCTM", 0, "SCTM");

  static readonly RAR = new Context("RAR", 1, "RAR");

  static readonly BOR = new Context("BOR", 2, "BOR");

  static readonly BASELINE = new Context("BASELINE", 3, "BASELINE");

  static readonly TRA = new Context("TRA", 4, "TRA");

  static readonly SAVEPOINT = new Context("SAVEPOINT", 5, "SAVEPOINT");

  static readonly SYSTEM = new Context("SYSTEM", 6, "SYSTEM");

  static readonly KNOWLEDGEBASE = new Context("KNOWLEDGEBASE", 7, "KNOWLEDGEBASE");

  static readonly CONTROL_CATALOG = new Context("CONTROL_CATALOG", 8, "CONTROL_CATALOG");

  public readonly id: string;

  public readonly index: number;

  public readonly name: string;

  static fromId(id: string) {
    const value = Context.values.find((v) => v.id === id);
    if (value) return value;

    throw new RangeError(`No instance of ${this.constructor.name} exists with the id ${id}.`);
  }

  static fromName(name: string) {
    const value = Context.values.find((v) => v.name === name);
    if (value) return value;

    throw new RangeError(`No instance of ${this.constructor.name} exists with the name ${name}.`);
  }

  constructor(id: string, index: number, name: string) {
    this.id = id;
    this.index = index;
    this.name = name;
    Context.values.push(this);
  }

  toJSON() {
    return this.id;
  }
}
