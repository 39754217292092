import * as React from "react";

// Styles
import FormStyledError from "components/forms/FormStyledError";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";

// Local
import { ExportContext } from "features/brm";
import { EXPORT_TARGET, EXPORT_RANGE } from "../../constants";
import { IExportTable } from "../../types";
import S from "./ExportWizard.styles";

interface IExportTableWizard {
  onTableExport: IExportTable;
  toggle: (value: boolean) => void;
  exportContext: string;
}

export const ExportTableWizard = ({ onTableExport, toggle, exportContext }: IExportTableWizard) => {
  const [selectedOption, setSelectedOption] = React.useState(EXPORT_TARGET.csv);
  const [selectedRange, setSelectedRange] = React.useState(EXPORT_RANGE.allTable);
  const [postError, setPostError] = React.useState("");
  const [disableExport, setDisableExport] = React.useState(false);

  const handleFileExport = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (postError) {
      setPostError("");
    }

    if (selectedOption) {
      setDisableExport(true);
      const opts = {
        target: selectedOption,
        type: selectedRange,
      };
      onTableExport(opts);
      toggle(false);
    } else setPostError("Please select an option to export");
  };
  /**
   *
   * @returns Wizard based on the import type
   */
  const GetExportTableWizard = () => {
    return (
      <div>
        <label>Export as :</label>
        <S.ExportMenu>
          <S.List>
            <li>
              <input
                type="radio"
                value="csv"
                checked={selectedOption === EXPORT_TARGET.csv}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
              <S.ExportLabel>.csv</S.ExportLabel>
            </li>
            {exportContext === ExportContext.listWithJson && (
              <li>
                <input
                  type="radio"
                  value="json"
                  checked={selectedOption === EXPORT_TARGET.json}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                <S.ExportLabel>.json</S.ExportLabel>
              </li>
            )}
          </S.List>
          {exportContext === ExportContext.table && (
            <div>
              File range :
              <S.ExportMenu>
                <S.List>
                  <li>
                    <input
                      type="radio"
                      value="Full"
                      checked={selectedRange === EXPORT_RANGE.allTable}
                      onChange={(e) => {
                        setSelectedRange(e.target.value);
                      }}
                    />
                    <S.ExportLabel> Full</S.ExportLabel>
                  </li>
                  <li>
                    <input
                      type="radio"
                      value="Summary"
                      checked={selectedRange === EXPORT_RANGE.currentView}
                      onChange={(e) => {
                        setSelectedRange(e.target.value);
                      }}
                    />
                    <S.ExportLabel> Current view</S.ExportLabel>
                  </li>
                </S.List>
              </S.ExportMenu>
            </div>
          )}
        </S.ExportMenu>
      </div>
    );
  };

  return (
    <div id="Wizard">
      <S.WizardExport id="Wizard_import">
        <form onSubmit={handleFileExport} action="" id="WizardForm_form">
          <GetExportTableWizard />
          <FormStyledError id="WizardForm_Error">{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => toggle(false)}>Cancel</DialogButtonStyled>
            <DialogButtonStyled type="submit" id="Wizard_export" disabled={disableExport}>
              {disableExport ? "Exporting..." : "Export"}
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </S.WizardExport>
    </div>
  );
};
