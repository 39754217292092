import { ImportContext, ImportContextValues } from "features/brm";

export const errorMessageLookup = (context: ImportContextValues) => {
  if (context === ImportContext.system) {
    return "To investigate:  As a 'System Engineer', go to the 'Project Details' page, and scan the import details in the 'Validation' tab.";
  }
  if (context === ImportContext.baseline) {
    return "To import a baseline: As a 'Control Coordinator' follow the steps in the Import Baseline help video.";
  }
  if (context === ImportContext.sctm) {
    return "To import SCTM: As a 'Risk Analyst' follow the steps in the Import SCTM help video.";
  }
  if (context === ImportContext.bor) {
    return "To import Vulnerability findings: As a 'Risk Analyst' follow the step in the Import Findings help video.";
  }
  throw new Error(`Unsupported Context: ${context}`);
};
