import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// Services
import { kbApi } from "services/brm";
import { KnowledgebaseApi } from "features/brm";

const getModifiedRules = async (elementId) => {
  let finalRes;

  try {
    const res = await kbApi.getKBDeepRe(elementId);
    const rulesWithId = res.rules.map((e, i) => ({ ...e, id: i }));
    finalRes = { ...res, rules: rulesWithId };
  } catch (err) {
    console.error("err in get kb rules");
  }
  return finalRes || [];
};

export const useGetKbRules = ({ elementId, options = {} }) => {
  return useQuery(KnowledgebaseApi.ruleKey.kb(elementId), () => getModifiedRules(elementId), {
    ...options,
    enabled: !!elementId,
    cacheTime: 0,
  });
};

export const useAddKbRules = (kbId) => {
  const queryClient = useQueryClient();
  return useMutation(({ elementId, jsonData }) => kbApi.setKBDeepRe(elementId, { body: jsonData }), {
    cacheTime: 0,
    onSuccess: () => queryClient.invalidateQueries(KnowledgebaseApi.ruleKey.kb(kbId)),
  });
};

// export const useUpdateRule = (kbId) => {
//   const queryClient = useQueryClient();
//   return useMutation(
//     ({ jsonData }) =>
//       kbApi.setKBDeepRe(kbId, {
//         body: jsonData,
//       }),
//     {
//       onMutate: async ({ elementId, jsonData, updatedMeans }) => {
//         await queryClient.cancelQueries(["getKbRules", kbId]);
//         const previousValues = queryClient.getQueryData(["getKbRules", kbId]);

//         queryClient.setQueryData(["getKbRules", kbId], (old) =>
//           old.map((data) => {
//             if (elementId === data.id) {
//               return {
//                 ...data,
//                 means: updatedMeans,
//               };
//             }
//             return data;
//           })
//         );
//         return previousValues;
//       },
//       onError: (previousValues) => queryClient.setQueryData(["getKbRules", kbId], previousValues),
//       onSuccess: () => {
//         queryClient.invalidateQueries(["getKbRules", kbId]);
//       },
//     }
//   );
// };

export const useRuleUpdate = (elementId) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ kbId, json }) => {
      return kbApi.setKBDeepRe(kbId, { body: json });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KnowledgebaseApi.ruleKey.kb(elementId));
      },
    }
  );
};
