import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as globalControlKeyFactory from "../global-control";

export interface IImportControlCatalog {
  controlCatalogId: string;
  file: any;
}

/**
 * Import a ControlCatalog
 *
 * @param {string} kbId - uuid of the kbId
 * @param {any} file - import context
 * @returns
 */
export const importControlCatalog = async ({ controlCatalogId, file }: IImportControlCatalog) => {
  return Brm.controlCatalogIoApi.controlCatalogImport(controlCatalogId, { file });
};

interface IUseControlCatalog {
  options?: MutationOption<typeof importControlCatalog>;
}

/**
 * Custom hook to import a control catalog
 *
 *
 * @param {object} options - react-query options
 */
export const useImportControlCatalog = ({ options }: IUseControlCatalog = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: importControlCatalog,
    onSuccess: () => {
      queryClient.invalidateQueries(globalControlKeyFactory.controlCatalogKeys.all);
    },
  });
};
