import * as React from "react";

import {
  ROLE_SUPER_ADMIN,
  ROLE_ADMIN,
  ROLE_SYSTEM_ENGINEER,
  ROLE_RISK_ANALYST,
  ROLE_CONTROLS_COORDINATOR,
  ROLE_CYBERSECURITY_EXPERT,
  ROLE_THREAT_ANALYST,
} from "services/user";

import {
  Faq,
  AdminHelpContents,
  CntrlCoordinatorHelpContents,
  CyberSecurityHelpContents,
  RiskAnalystHelpContents,
  SuperAdminHelpContents,
  SystemEngHelpContents,
  ThreatAnalystHelpContents,
} from "features/help/constants";
import { IContentObj } from "features/help/types";
import { ContentKey } from "features/brm";

interface Contents {
  contents: IContentObj[];
}

const superAdminContents: { [key: string]: Contents } = SuperAdminHelpContents;
const adminContents: { [key: string]: Contents } = AdminHelpContents;
const cntrlCoordinatorContents: { [key: string]: Contents } = CntrlCoordinatorHelpContents;
const cseContents: { [key: string]: Contents } = CyberSecurityHelpContents;
const riskAnalystContents: { [key: string]: Contents } = RiskAnalystHelpContents;
const systemEngContents: { [key: string]: Contents } = SystemEngHelpContents;
const threatAnalystContents: { [key: string]: Contents } = ThreatAnalystHelpContents;

const getAllContexts = () => {
  // These contexts are hidden on 5.3.2 but they are likely to exist on 5.4 (except "empty", "licenseError" and "logout" that should be hidden always, and "modules" and "modulesDetails" that don't exist on 5.4)
  const keysWithNoContext = [
    "empty",
    "licenseError",
    "logout",
    "modules",
    "modulesDetails",
    "ttps",
    "ttpDetails",
    "links",
    "linkFullDetails",
    "groups",
    "channels",
    "riskCategoryFullDetails",
    "harmCategoryFullDetails",
    "assetCategoryFullDetails",
    "sctms",
    "projectOverviewImporter",
  ];
  return Object.values(ContentKey)
    .filter((contentKey) => keysWithNoContext.every((key) => key !== contentKey))
    .sort()
    .map((contentKey) => ({ label: contentKey.charAt(0).toUpperCase() + contentKey.slice(1), value: contentKey }));
};

const getFaqContextByRole = (roles: string[]) => {
  return roles
    .map((role) => {
      const roleContexts: string[] = [];
      Faq.forEach((faqObj) => {
        if (faqObj.Role === role && roleContexts.every((context) => context !== faqObj.Page)) {
          roleContexts.push(faqObj.Page);
        }
      });
      return roleContexts;
    })
    .flat();
};

const getVideoContextByRole = (roles: string[]) => {
  const videoContextsObj: { [key: string]: string[] } = {
    [ROLE_SUPER_ADMIN]: Object.keys(superAdminContents),
    [ROLE_ADMIN]: Object.keys(adminContents),
    [ROLE_SYSTEM_ENGINEER]: Object.keys(systemEngContents),
    [ROLE_THREAT_ANALYST]: Object.keys(threatAnalystContents),
    [ROLE_RISK_ANALYST]: Object.keys(riskAnalystContents),
    [ROLE_CONTROLS_COORDINATOR]: Object.keys(cntrlCoordinatorContents),
    [ROLE_CYBERSECURITY_EXPERT]: Object.keys(cseContents),
  };

  const roleContexts: string[] = [];

  roles.forEach((role) => {
    videoContextsObj[role].forEach((videoContext) => {
      if (roleContexts.every((uniqueContext) => videoContext !== uniqueContext)) {
        roleContexts.push(videoContext);
      }
    });
  });

  return roleContexts;
};

export const useContextOptions = (userRoles: string[]) => {
  const [contextData, setContextData] = React.useState<{ label: string; value: string }[]>([]);

  const allContexts = React.useMemo(() => getAllContexts(), []);

  const contextsByRole = React.useMemo(() => {
    if (userRoles.length) {
      const faqContexts = getFaqContextByRole(userRoles);
      const videoContexts = getVideoContextByRole(userRoles);
      const bothContexts = videoContexts;

      faqContexts.forEach((faqContext) => {
        if (videoContexts.every((videoContext: string) => faqContext !== videoContext)) {
          bothContexts.push(faqContext);
        }
      });

      // console.log("bothContexts.sort:", bothContexts.sort());

      return bothContexts.sort().map((context) => ({
        label: context.charAt(0).toUpperCase() + context.slice(1),
        value: context,
      }));
    }
    return null;
  }, [userRoles]);

  React.useEffect(() => {
    if (contextsByRole) {
      setContextData(contextsByRole);
    } else {
      setContextData(allContexts);
    }
  }, [allContexts, contextsByRole]);

  return contextData;
};
