// Local
import { HelpArticleFaq } from "features/help/components/HelpArticleFaq";
import { HelpArticleVideo } from "features/help/components/HelpArticleVideo";

interface IHelpArticlesProps {
  type: string;
  name: string;
  filePath?: string;
  response?: string;
}

export const HelpArticle = ({ type, name, filePath, response }: IHelpArticlesProps) => {
  if (response || filePath) {
    return type === "FAQ" ? (
      <HelpArticleFaq title={name} response={response} />
    ) : (
      <HelpArticleVideo title={name} filePath={filePath} />
    );
  }
  return null;
};
