import * as React from "react";

import SelectCategory from "components/forms/DynamicSelectBoxCategory";
import { stringToBoolean } from "utils/boolean-checker";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import PropTypes from "prop-types";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { sortUpperCase } from "utils/sorting";
import { validateNameUniqueness } from "features/system-model";
import BooleanDropdown from "components/forms/BooleanDropdown";
import TextField from "components/forms/TextField";
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR, Classification } from "constants/brm";
import { SystemApi, useCreateDatatype } from "features/brm";
import { LoadingSpinner as Loading } from "components/elements";
import { useQueryClient } from "@tanstack/react-query";

const SELECT_CATEGORY = "Select Category";

const AddDatatypeForm = ({ setModalIsOpen }) => {
  const { createDatatype } = useCreateDatatype();

  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = React.useState("");
  const [isTextValid, setIsTextValid] = React.useState(true);

  const queryClient = useQueryClient();
  const { data: datatypes } = SystemApi.useDataTypes({ projectId, options: { enabled: !!projectId } });
  const { data: elementCategories } = SystemApi.useElementCategories({
    projectId,
    options: {
      select: React.useCallback((data) => data.sort(sortUpperCase), []),
    },
  });

  function validate(formData) {
    let error = true;
    if (formData.name.value === "" || formData.category.value === SELECT_CATEGORY || formData.isInternal.value === "") {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isNameValid = validateNameUniqueness(datatypes, e.target.elements.name.value);
    if (!isNameValid) {
      setPostError(FORM_ERROR.duplicateDatatypeName);
      return null;
    }

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      const datatypeObj = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value,
        category: e.target.elements.category.value,
        isInternal: stringToBoolean(e.target.elements.isInternal.value),
        // Optional
        isInput: stringToBoolean(e.target.elements.isInput.value),
        isOutput: stringToBoolean(e.target.elements.isOutput.value),
        isProcessed: stringToBoolean(e.target.elements.isProcessed.value),
        isStored: stringToBoolean(e.target.elements.isStored.value),
        classificationCategory: Classification.NOT_CLASSIFIED.letter,
        categorization: [],
      };

      createDatatype({
        projectId,
        dto: datatypeObj,
        onSuccess: () => {
          queryClient.invalidateQueries(SystemApi.dataTypeKeys.project(projectId));
          setModalIsOpen(false);
        },
      });
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  if (elementCategories) {
    return (
      <div>
        <form onSubmit={handleSubmit} action="">
          <FormStyled>
            <div className="form-style">
              <TextField
                label={{ id: "AddDatatypeForm_name", name: FORM_LABEL.nameMandatory }}
                input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "AddDatatypeForm_note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />

              <BooleanDropdown
                label={{ id: "AddDatatypeForm_isInternal", name: FORM_LABEL.internalMandatory }}
                select={{ id: "isInternal", name: "isInternal" }}
                defaultValue
              />

              <label id="AddDatatypeForm_category">{FORM_LABEL.categoryMandatory}</label>
              <SelectCategory
                id="AddDatatypeForm_categoryDropdown"
                arrayOfData={elementCategories}
                name="Category"
                item="category"
              />

              <BooleanDropdown
                label={{ id: "AddDatatypeForm_isInput", name: FORM_LABEL.inputOptional }}
                select={{ id: "isInput", name: "isInput" }}
              />

              <BooleanDropdown
                label={{ id: "AddDatatypeForm_isOutput", name: FORM_LABEL.outputOptional }}
                select={{ id: "isOutput", name: "isOutput" }}
              />

              <BooleanDropdown
                label={{ id: "AddDatatypeForm_isProcessed", name: FORM_LABEL.processedOptional }}
                select={{ id: "isProcessed", name: "isProcessed" }}
              />

              <BooleanDropdown
                label={{ id: "AddDatatypeForm_isStored", name: FORM_LABEL.storedOptional }}
                select={{ id: "isStored", name: "isStored" }}
              />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
            <DialogButtonStyled type="submit">Add</DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }
  return <Loading />;
};

AddDatatypeForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};

export default AddDatatypeForm;
