import React from "react";

interface IDynamicSelectBoxProps {
  arrayOfData: Array<{ id: string; name: string }>;
  name: string;
  item: string;
  disabled?: boolean;
  isOptional?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => {};
}

const DynamicSelectBox = ({ arrayOfData, item, name, disabled, isOptional, onChange }: IDynamicSelectBoxProps) => {
  let options;

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  if (Array.isArray(arrayOfData) && arrayOfData.length) {
    // check if there is any data in the array first
    options = arrayOfData.map((data) => (
      <option key={data.id} value={data.id}>
        {data.name}
      </option>
    ));
  }

  return (
    <select name={name || item} id={item} disabled={disabled} onChange={handleOnChange} className="border rounded">
      {arrayOfData.length > 1 || isOptional ? <option>Select {name}</option> : null}
      {options}
    </select>
  );
};

export default DynamicSelectBox;
