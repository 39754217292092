import PropTypes from "prop-types";
import { useState, useMemo, useEffect, useRef } from "react";
import { COMMON } from "constants/brm";
import { alphanumeric } from "utils/sorting";
import { useRecoilValue, useRecoilState } from "recoil";
import { variantIdState } from "atoms/atoms-component";
import { filterSearchSubstringState } from "atoms/atoms-content";
import { FilterInput, Select } from "./styles/tables.styles";

export const Filter = ({ column }) => <div style={{ marginTop: 5 }}>{column.canFilter && column.render("Filter")}</div>;

Filter.propTypes = {
  column: PropTypes.shape({
    canFilter: PropTypes.bool,
    render: PropTypes.func,
  }),
};

export const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => (
  <FilterInput
    type="search"
    value={filterValue || ""}
    onChange={(e) => {
      setFilter(e.target.value || undefined);
    }}
    placeholder="Search..."
  />
);
DefaultColumnFilter.propTypes = {
  column: PropTypes.any,
};

// After setting a new Calibrated Impact or Likelihood, the filter should retain the rows filtered out
export const RetainStringColumnFilter = ({ column: { filterValue, setFilter } }) => {
  const [filterSearchString, setFilterSearchString] = useRecoilState(filterSearchSubstringState);
  if (filterSearchString && !filterValue) {
    setFilter(filterSearchString);
  }
  return (
    <FilterInput
      value={filterValue || ""}
      onChange={(e) => {
        if (filterSearchString) {
          setFilterSearchString(null);
        }
        setFilter(e.target.value || undefined);
        setFilterSearchString(e.target.value || undefined);
      }}
      placeholder="Search..."
    />
  );
};
RetainStringColumnFilter.propTypes = {
  column: PropTypes.any,
};

export const SelectColumnFilter = ({ column: { setFilter, preFilteredRows, id, filterValue } }) => {
  const [visibleFilterValue, setVisibleFilterValue] = useState("");
  const variantId = useRecoilValue(variantIdState);
  const filterRef = useRef({ id, filterValue });

  const options = useMemo(() => {
    const opt = new Set();
    preFilteredRows.forEach((row) => {
      opt.add(row.values[id] === "" ? COMMON.hyphen : row.values[id]);
    });
    const finalOptions = [...opt.values()];
    if (filterRef.current.filterValue && !finalOptions.includes(filterRef.current.filterValue)) {
      // The current options must include the pre-filtered-value even if it does no exist in the preFilteredRows when another column's filtered-value changes
      finalOptions.push(filterRef.current.filterValue);
    }
    return finalOptions.sort(alphanumeric);
  }, [id, preFilteredRows]);

  useEffect(() => {
    if (variantId) {
      setVisibleFilterValue("all");
    }
  }, [variantId]);

  useEffect(() => {
    if (filterRef.current.id === id && filterRef.current.filterValue !== filterValue) {
      setFilter(filterRef.current.filterValue);
    }
  }, [filterValue, setFilter, id]);

  return (
    <Select
      id="custom-select"
      value={visibleFilterValue}
      onBlur={() => {}} // error without onBlur but using onBlur vs onChange causes issues in component functionality
      onChange={(e) => {
        setVisibleFilterValue(e.target.value);
        if (e.target.value === "all") e.target.value = "";
        const value = e.target.value === COMMON.hyphen ? "" : e.target.value || undefined;
        filterRef.current = {
          id,
          filterValue: value,
        };
        setFilter(value);
      }}
    >
      <option value="all">All</option>
      {options.map((option) => {
        if (option !== null && option !== undefined) {
          return (
            <option key={option} value={option}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </option>
          );
        }
        return null;
      })}
    </Select>
  );
};
SelectColumnFilter.propTypes = {
  column: PropTypes.any,
};

export const SelectLikelihoodColumnFilter = ({ column: { filterValue, setFilter } }) => (
  <Select
    id="custom-select"
    value={filterValue || ""}
    onBlur={() => {}} // error without onBlur but using onBlur vs onChange causes issues in component functionality
    onChange={(e) => {
      setFilter(e.target.value || undefined);
    }}
  >
    <option value="">All</option>
    <option value="very low">Improbable</option>
    <option value="low">Remote</option>
    <option value="moderate">Occasional</option>
    <option value="high">Probable</option>
    <option value="very high">Near Certainty</option>
  </Select>
);
SelectLikelihoodColumnFilter.propTypes = {
  column: PropTypes.any,
};

export const SelectImpactColumnFilter = ({ column: { filterValue, setFilter } }) => (
  <Select
    id="custom-select"
    value={filterValue}
    onBlur={() => {}} // error without onBlur but using onBlur vs onChange causes issues in component functionality
    onChange={(e) => {
      setFilter(e.target.value || undefined);
    }}
  >
    <option value="">All</option>
    <option value="very low">Negligible</option>
    <option value="low">Minor</option>
    <option value="moderate">Moderate</option>
    <option value="high">Major</option>
    <option value="very high">Catastrophic</option>
  </Select>
);
SelectImpactColumnFilter.propTypes = {
  column: PropTypes.any,
};

export const SelectBooleanColumnFilter = ({ column: { filterValue, id, setFilter } }) => {
  const filterRef = useRef({ id, filterValue });

  useEffect(() => {
    if (filterRef.current.id === id && filterRef.current.filterValue !== filterValue) {
      setFilter(filterRef.current.filterValue);
    }
  }, [filterRef, filterValue, setFilter, id]);

  return (
    <Select
      id="custom-select"
      value={filterValue}
      onBlur={() => {}} // error without onBlur but using onBlur vs onChange causes issues in component functionality
      onChange={(e) => {
        const map = { true: true, false: false };
        const value = map[e.target.value];
        filterRef.current = {
          id,
          filterValue: value,
        };
        setFilter(value);
      }}
    >
      <option value="all">All</option>
      <option value>{COMMON.yes}</option>
      <option value={false}>{COMMON.no}</option>
    </Select>
  );
};
SelectBooleanColumnFilter.propTypes = {
  column: PropTypes.any,
};

export const SelectBooleanWithVariantColumnFilter = ({ column: { filterValue, id, setFilter } }) => {
  const [visibleFilterValue, setVisibleFilterValue] = useState(null);
  const variantId = useRecoilValue(variantIdState);
  const filterRef = useRef({ id, filterValue });

  useEffect(() => {
    if (variantId) {
      setVisibleFilterValue(null);
      filterRef.current = { filterValue: null };
    }
  }, [variantId]);

  useEffect(() => {
    if (filterRef.current.id === id && filterRef.current.filterValue !== filterValue) {
      setFilter(filterRef.current.filterValue);
    }
  }, [filterRef, filterValue, setFilter, id]);

  return (
    <Select
      id="custom-select"
      value={visibleFilterValue}
      onBlur={() => {}} // error without onBlur but using onBlur vs onChange causes issues in component functionality
      onChange={(e) => {
        const map = { true: true, false: false };
        const value = map[e.target.value];
        setVisibleFilterValue(value);
        filterRef.current = {
          id,
          filterValue: value,
        };
        setFilter(value);
      }}
    >
      <option value="all">All</option>
      <option value>{COMMON.yes}</option>
      <option value={false}>{COMMON.no}</option>
    </Select>
  );
};
SelectBooleanWithVariantColumnFilter.propTypes = {
  column: PropTypes.any,
};

export const SelectLevelColumnFilter = ({ column: { filterValue, setFilter } }) => (
  <Select
    id="custom-select"
    value={filterValue}
    onBlur={() => {}} // error without onBlur but using onBlur vs onChange causes issues in component functionality
    onChange={(e) => {
      setFilter(e.target.value || undefined);
    }}
  >
    <option value="">All</option>
    <option value="very low">{COMMON.veryLow}</option>
    <option value="low">{COMMON.low}</option>
    <option value="moderate">{COMMON.moderate}</option>
    <option value="high">{COMMON.high}</option>
    <option value="very high">{COMMON.veryHigh}</option>
  </Select>
);
SelectLevelColumnFilter.propTypes = {
  column: PropTypes.any,
};
