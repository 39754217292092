import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { userRoles, userApi } from "services/brm/admin-service";

const GenericDeleteConfirmation = ({ elementName, setDeleteModalIsOpen, selectedRows, setConfirmDelete }) => {
  const [result, setResult] = useState(false);

  // let predefinedItem = "";

  useEffect(() => {
    function checkForSuperAdmin() {
      //   let result;
      if (selectedRows.length > 0) {
        selectedRows.forEach(async (rowToBeDeleted) => {
          if (rowToBeDeleted.id) {
            const uuid = rowToBeDeleted.id;
            try {
              const response = await userApi.getUserAssignedRoleWithHttpInfo(uuid);
              if (response) {
                // check for superadmin role in the list of assigned roles
                if (response.data.includes(userRoles.properties[userRoles.SUPER_ADMIN].key)) {
                  // predefinedItem = rowToBeDeleted.item.username;
                  setResult(true);
                } else setResult(false);
              }
            } catch (err) {
              console.error("error in check for superadmin : ", err);
            }
          }
        });
      }
    }
    checkForSuperAdmin();
  }, [selectedRows]);

  const deleteSelected = async () => {
    setConfirmDelete(true);
  };

  const handleCancel = () => {
    setDeleteModalIsOpen(false);
  };

  return (
    <>
      {result ? (
        <div>
          Cannot delete user! The user has superadmin as assigned role
          <DialogButtonDivStyled>
            <DialogButtonStyled id="GenericDeleteConfirmation_cancelButton" onClick={handleCancel}>
              Cancel
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </div>
      ) : (
        <>
          <div>Are you sure you want to delete the selected {elementName}(s)?</div>
          <DialogButtonDivStyled>
            <DialogButtonStyled id="GenericDeleteConfirmation_cancelButton" onClick={handleCancel}>
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled id="GenericDeleteConfirmation_confirmButton" onClick={() => deleteSelected()}>
              Confirm
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </>
      )}
    </>
  );
};

GenericDeleteConfirmation.propTypes = {
  elementName: PropTypes.any,
  selectedRows: PropTypes.shape({
    forEach: PropTypes.func,
    length: PropTypes.number,
  }),
  setConfirmDelete: PropTypes.func,
  setDeleteModalIsOpen: PropTypes.func,
};

export default GenericDeleteConfirmation;
