import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";

// Services
import { sctmApi } from "services/brm/mitigation-service";

import {
  selectedControlColumnState,
  selectedEffectiveState,
  selectedExchangesState,
  selectedHeaderRowState,
  selectedNodesState,
  selectedSystemState,
  selectedWorksheetState,
} from "atoms/atoms-sctm-wizard";

// Constants
import { IMPORT_AS } from "constants/brm";
import { ImportApi, useProject } from "features/brm";
import { SCTM_FORM_ELEMENTS } from "../../constants";

// Styles
import * as S from "./SCTM.styles";

import Step1 from "./Step1";
import Step2 from "./Step2";

export const SCTM = ({ options, handleSubmit, setUploadModalIsOpen, sctmId, submissionId }) => {
  // recoil
  const selectedSystem = useRecoilValue(selectedSystemState);
  const selectedNodes = useRecoilValue(selectedNodesState);
  const selectedEffective = useRecoilValue(selectedEffectiveState);
  const selectedExchange = useRecoilValue(selectedExchangesState);
  const selectedWorksheet = useRecoilValue(selectedWorksheetState);
  const selectedHeaderRow = useRecoilValue(selectedHeaderRowState);
  const selectedControlColumn = useRecoilValue(selectedControlColumnState);

  // inputs
  const [, { projectId }] = useProject();
  const { mutate: cancelIimport } = ImportApi.useCancelImport();

  const [currentStep, setCurrentStep] = useState(1);
  const [postError, setPostError] = useState("");
  const [scope, setScope] = useState(
    options[SCTM_FORM_ELEMENTS.importScope] ? options[SCTM_FORM_ELEMENTS.importScope].defaults : []
  );
  const [isAutoAllocate, setIsAutoAllocate] = useState(false);
  const [excludeNonCompliantControls, setExcludeNonCompliantControls] = useState(true);

  // useQuery
  const { data: sctmName, isError: isSctmNameError } = useQuery(["sctmName"], () => sctmApi.getSCTMName(sctmId));
  const { data: sctmVariant, isError: isSctmVariantError } = useQuery(["sctmVariant"], () =>
    sctmApi.getSCTMVariant(sctmId)
  );
  const { data: sctmBaseline, isError: isSctmBaselineError } = useQuery(["sctmBaseline"], () =>
    sctmApi.getSCTMBaseline(sctmId)
  );

  const handleSubmission = useCallback(() => {
    function validate() {
      if (scope[0] === IMPORT_AS.system && selectedSystem.length > 0) {
        return true;
      }
      if (scope[0] === IMPORT_AS.target && (selectedNodes.length > 0 || selectedExchange.length > 0)) {
        return true;
      }
      if (isSctmBaselineError || isSctmNameError || isSctmVariantError) {
        setPostError("Error in getting sctm name, variant, baseline");
        return false;
      }
      return false;
    }

    const isValid = validate();

    if (isValid) {
      setPostError("");
      const result = options;
      result[SCTM_FORM_ELEMENTS.autoAllocate].selection = [isAutoAllocate];
      result.systems.selection = selectedSystem;
      result.nodes.selection = selectedNodes;
      result.exchanges.selection = selectedExchange;
      result[SCTM_FORM_ELEMENTS.importScope].selection = scope;
      result[SCTM_FORM_ELEMENTS.convertEffective].selection = selectedEffective;
      result.worksheet.selection = [selectedWorksheet[0].value];
      result[SCTM_FORM_ELEMENTS.headerRow].selection = [selectedHeaderRow[0].value];
      result[SCTM_FORM_ELEMENTS.controlColumn].selection = [selectedControlColumn[0].value];
      result[SCTM_FORM_ELEMENTS.sctmName].selection = [sctmName.result];
      result[SCTM_FORM_ELEMENTS.sctmBaseline].selection = [sctmBaseline.name];
      result[SCTM_FORM_ELEMENTS.sctmVariant].selection = [sctmVariant.id];
      result[SCTM_FORM_ELEMENTS.excludeNonCompliant].selection = [excludeNonCompliantControls];
      handleSubmit(result);
    } else {
      setPostError("Please select at least one column value to submit!");
    }
  }, [
    handleSubmit,
    excludeNonCompliantControls,
    isAutoAllocate,
    isSctmBaselineError,
    isSctmNameError,
    isSctmVariantError,
    options,
    scope,
    sctmBaseline,
    sctmName,
    sctmVariant,
    selectedControlColumn,
    selectedEffective,
    selectedExchange,
    selectedHeaderRow,
    selectedNodes,
    selectedSystem,
    selectedWorksheet,
  ]);

  const handleNext = () => {
    let step = currentStep;
    step = 2;
    setCurrentStep(step);
  };

  const handleBack = () => {
    let step = currentStep;
    step = step > 1 ? step - 1 : 1;
    setCurrentStep(step);
    setPostError("");
  };

  const handleCancel = useCallback(() => {
    cancelIimport({ projectId, runId: submissionId });
  }, [cancelIimport, projectId, submissionId]);

  return (
    <S.SCTMWizard>
      <div style={{ padding: "10px" }} id="SCTM_title">
        <h4 className="wizardTitle">SCTM Wizard</h4>
      </div>
      <p style={{ fontWeight: "600" }}>Step {currentStep}</p>
      <Step1
        currentStep={currentStep}
        options={options}
        handleNext={handleNext}
        setUploadModalIsOpen={setUploadModalIsOpen}
        isAutoAllocate={isAutoAllocate}
        excludeNonCompliantControls={excludeNonCompliantControls}
        setExcludeNonCompliantControls={setExcludeNonCompliantControls}
        setIsAutoAllocate={setIsAutoAllocate}
        scope={scope}
        setScope={setScope}
        onCancel={handleCancel}
      />
      <Step2
        scope={scope}
        options={options}
        handleBack={handleBack}
        handleSubmission={handleSubmission}
        setUploadModalIsOpen={setUploadModalIsOpen}
        isAutoAllocate={isAutoAllocate}
        currentStep={currentStep}
        postError={postError}
        onCancel={handleCancel}
      />
    </S.SCTMWizard>
  );
};

SCTM.propTypes = {
  handleSubmit: PropTypes.func,
  sctmId: PropTypes.string,
  options: PropTypes.shape({
    "convert-effective": PropTypes.shape({
      defaults: PropTypes.any,
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    "header-row": PropTypes.shape({
      defaults: PropTypes.any,
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    "control-column": PropTypes.shape({
      defaults: PropTypes.any,
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    "import-scope": PropTypes.shape({
      defaults: PropTypes.any,
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    systems: PropTypes.shape({
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    nodes: PropTypes.shape({
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    exchanges: PropTypes.shape({
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    worksheet: PropTypes.shape({
      defaults: PropTypes.any,
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    currentStep: PropTypes.any,
  }),
  setUploadModalIsOpen: PropTypes.func,
  submissionId: PropTypes.string,
};
