import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as BrmGql from "generated/graphql";
import * as KeyFactory from "./threat-group-query-key-factory";

export interface ISetThreatGroupTier {
  threatGroupId: string;
  tier: string;
}

/**
 * Sets threat tier property for threat group
 *
 * @param {string} threatGroupId - uuid of the threat group
 * @param {string} tier - threat tier
 */
export const setThreatGroupTier = ({ threatGroupId, tier }: ISetThreatGroupTier) => {
  return Brm.threatGroupApi.setThreatGroupTier(threatGroupId, { body: tier });
};

export interface IUseSetThreatGroupTier {
  options?: MutationOption<typeof setThreatGroupTier>;
}

/**
 * Custom hook to change the threat tier property of an existing threat group
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetThreatGroupTier = ({ options }: IUseSetThreatGroupTier = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.threatGroupKeys.all);
      queryClient.invalidateQueries(BrmGql.useGetThreatGroupsQuery.getKey());
    },
    ...options,
    mutationFn: setThreatGroupTier,
  });
};
