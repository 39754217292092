import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./sap-binding-query-key-factory";

interface IGetSapProjBindingProps {
  projectId?: string;
}

/**
 * Gets the sap project objects
 */
export const getSapProjBindings = ({ projectId }: IGetSapProjBindingProps) => {
  if (projectId) {
    return Brm.sapProjBindingApi.findSapProjBinding({ project: projectId });
  }
  return Brm.sapProjBindingApi.findSapProjBinding();
};

type FuncType = typeof getSapProjBindings;

interface ISapProjBindinProps {
  projectId?: string;
  options?: QueryOption<FuncType>;
}

/**
 * Custom hook for getting the sap project binding
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useSapProjBindings = ({ projectId, options = {} }: ISapProjBindinProps = {}) => {
  const key = projectId ? KeyFactory.sapBindingKeys.project(projectId) : KeyFactory.sapBindingKeys.allProject;

  return ReactQuery.useQuery({
    ...options,
    queryKey: key,
    queryFn: () => getSapProjBindings({ projectId }),
  });
};
