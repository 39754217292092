/* eslint-disable react/react-in-jsx-scope */
import { AppProvider } from "providers/AppProvider";
import { AppRoutes } from "routes";
// import { useKeycloak } from "@react-keycloak/web";
// import { PageSpinner } from "components/elements";
// import styled from "styled-components";

/**
 * The App
 * @returns The App
 */
const App = () => {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
};

export default App;
