import * as React from "react";
import * as Recoil from "recoil";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import Title from "components/elements/StyledTitle";
import { GlobalControlApi, useRoles } from "features/brm";

import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";

// State
import { controlCatalogIdState } from "atoms/atoms-global-control";

import {
  createColumnStandard,
  createColumnImportedAt,
  createColumnModifiedAt,
  createColumnBooleanNoEditMapped,
} from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading, ErrorBanner as ErrorDisplay } from "components/elements";
import { TYPE } from "constants/brm";
import { ControlCatalogTab } from "../../components/tabs/ControlCatalogTab";

interface INamedElement {
  id: string;
  name: string;
}

export function ControlCatalog() {
  const { isControlsCoordinator } = useRoles();
  const controlCatalogId = Recoil.useRecoilValue(controlCatalogIdState);

  const [selectedElement, setSelectedElement] = React.useState<Partial<INamedElement>>({});
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [showSummaryTable, setShowSummaryTable] = React.useState(true);

  const updatedStandard = React.useRef("");
  const { data, isError, error } = GlobalControlApi.useControlCatalog({
    catalogId: controlCatalogId || "",
    options: { enabled: !!controlCatalogId && isControlsCoordinator },
  });

  const columns = React.useMemo(
    () => [
      createColumnStandard(selectedRowId, false, updatedStandard),
      createColumnImportedAt(),
      createColumnModifiedAt(),
      createColumnBooleanNoEditMapped("isImported"),
    ],
    [selectedRowId]
  );

  React.useEffect(() => {
    if (data) {
      setSelectedElement(data);
    }
  }, [data]);

  if (isError) {
    return <ErrorDisplay msg={(error as any)?.statusText} />;
  }

  if (data) {
    return (
      <>
        <Title>
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={[data]}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "ControlCatalogFullDetails_table" }}
              elementName={TYPE.controlCatalog}
            />
          </DetailsContainerSingleTable>
        ) : null}

        {selectedElement.id && (
          <DetailsContainerParent>
            <DetailsContainer>
              <ControlCatalogTab selectedElement={selectedElement} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
  }

  return <Loading />;
}
