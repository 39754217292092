// Components
import systemModals from "components/modals/Modals";
import { BorrowRequestWizard } from "../BorrowWizard";
import { IBorrowRequestModalProps } from "../../../types";

export const BorrowRequestModal = ({ onBorrowRequest, isVisible, toggle }: IBorrowRequestModalProps) => {
  return (
    <>
      {isVisible
        ? systemModals.customModal(
            "BorrowRequest_modal",
            isVisible,
            toggle,
            <BorrowRequestWizard onBorrowRequest={onBorrowRequest} toggle={toggle} />,
            "License Borrow Request"
          )
        : null}
    </>
  );
};
