import { BsFillShieldFill } from "react-icons/bs";
import PrimaryButton from "components/elements/PrimaryStyledButton";
import { useRecoilValue } from "recoil";
import { isReadOnlyState } from "atoms/atoms-admin";
// Services
import { useRoles } from "features/brm/hooks/useRoles";
import { MouseEvent } from "react";

interface IShieldButtonProps {
  onClick: (event: MouseEvent) => void;
  tooltip?: string;
  disabled?: boolean;
}

export const ShieldButton = ({ onClick, tooltip = "Protect", disabled = false }: IShieldButtonProps) => {
  const { isSuperAdmin, isAdmin } = useRoles();
  const isReadOnly = useRecoilValue(isReadOnlyState);

  return (
    <>
      {isSuperAdmin || isAdmin || !isReadOnly ? (
        <PrimaryButton title={tooltip} id="ShieldButton_button" onClick={onClick} disabled={disabled}>
          <BsFillShieldFill size={18} />
        </PrimaryButton>
      ) : null}
    </>
  );
};
