import { useEffect, useMemo } from "react";
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";
import { LoadingSpinner } from "components/elements";
// import * as ImpactApi from "brm/risk-model/impact/api";
import { createColumnCategoryName } from "brm/tables/services/column/columnFactory";
import { RiskApi, useRoles } from "features/brm";

const HarmTable = ({ setSelectedRows, refresh, setRefresh }) => {
  const { isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data: harmCats, isError } = RiskApi.useHarmCategories({
    projectId,
    options: {
      enabled: !!projectId && isRiskAnalyst,
    },
  });

  const columns = useMemo(
    () => [createColumnCategoryName(selectedInstance.id, setSelectedInstance, "harm")],
    [selectedInstance.id, setSelectedInstance]
  );

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh, setRefresh]);

  if (isError) {
    return <div>Error While loading {TYPE.harmCat} data</div>;
  }

  if (harmCats) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={harmCats}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "HarmTable_table" }}
            showRowSelect={false}
            elementName={TYPE.harmCat}
          />
        </CustomTable>
      </>
    );
  }
  return <LoadingSpinner />;
};

HarmTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default HarmTable;
