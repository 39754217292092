import { BsShieldFillCheck } from "react-icons/bs";
import PropTypes from "prop-types";
import PrimaryStyledButton from "components/elements/PrimaryStyledButton";

const AutoMitigateButton = ({ onClick, selectedRows, disabled = selectedRows?.length === 0 }) => {
  return (
    <span>
      <PrimaryStyledButton
        title="Auto Mitigation"
        id="MitigateButton_button"
        name="auto mitigate"
        onClick={onClick}
        disabled={disabled}
      >
        <BsShieldFillCheck size={18} />
      </PrimaryStyledButton>
    </span>
  );
};
AutoMitigateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  selectedRows: PropTypes.any,
  disabled: PropTypes.bool,
};

export default AutoMitigateButton;
