import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./organization-query-key-factory";

interface ICreateOrganizationProps {
  organizationCreateDto: any;
}

/**
 * Create an organization
 *
 * @param param0 upload file data
 * @returns
 */
export const createOrganization = ({ organizationCreateDto }: ICreateOrganizationProps) => {
  return organizationCreateDto === null
    ? Promise.reject(new Error("Invalid organizationDto dto"))
    : Brm.organizationApi.createOrganization({ organizationCreateDto });
};

interface ICreateOrganizationQueryProps {
  options?: MutationOption<typeof createOrganization>;
}

/**
 * Custom hook to create an Organization
 *
 * @returns react-query Mutation.
 */
export const useCreateOrganization = ({ options }: ICreateOrganizationQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: () => {
      queryClient.invalidateQueries(KeyFactory.organizationKeys.all);
    },
    ...options,
    mutationFn: createOrganization,
  });
};
