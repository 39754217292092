import Select from "react-select";
import { Setting } from "features/admin/types/setting-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { MdInfoOutline } from "react-icons/md";
import { IOption } from "types";
import Button from "react-bootstrap/Button";

import * as S from "./ProjectSettingsView.styles";

const ProjectSettingKey = [
  "physicalMode",
  "vulnerabilityComputationMode",
  "compliantControlMode",
  "baselineMode",
] as const;
/* prettier-ignore */
type ProjectSettingKeyType = typeof ProjectSettingKey[number];

const isProjectSettingKey = (key: string): key is ProjectSettingKeyType => {
  return ProjectSettingKey.includes(key as ProjectSettingKeyType);
};

interface IProjectSettingsView {
  projectSettings: Setting[];
  isReadOnly: boolean;
  isThreatAnalyst?: boolean;
  onSettingChange?: (newSetting: Setting) => void;
}

const physicalModeOptions: IOption[] = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

const vulnerabilityComputationOptions: IOption[] = [
  { value: "default", label: "Default" },
  { value: "aggressive", label: "Aggressive" },
];

const complianceControlModeOptions: IOption[] = [
  { value: "include", label: "Include" },
  { value: "exclude", label: "Exclude" },
];

const baselineModeOptions: IOption[] = [
  { value: "floor", label: "Floor" },
  { value: "ceiling", label: "Ceiling" },
];

export const ProjectSettingsView = ({
  projectSettings,
  isReadOnly,
  isThreatAnalyst = false,
  onSettingChange = () => {},
}: IProjectSettingsView) => {
  // console.log("projectSettings", projectSettings);

  // console.log("projectSettings", projectSettings);

  const settingsMap = new Map<ProjectSettingKeyType, Setting>();
  const pSettings = projectSettings || [];
  pSettings.forEach((s) => {
    if (isProjectSettingKey(s.name)) {
      settingsMap.set(s.name, s);
    }
  });

  const handleOnChange = (key: ProjectSettingKeyType, e: IOption | null) => {
    // console.log("handleOnChange", e);
    const setting = settingsMap.get(key);
    if (setting && e?.value) {
      const newSetting = { ...setting, value: e.value };
      onSettingChange(newSetting);
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Settings Legend</Popover.Header>
      <Popover.Body>
        The Configuration tab displays the following project configuration settings:
        <S.Title>Generate physical attacks</S.Title>
        <S.Definition>
          indicates whether physical attacks are taken into account (Yes) or not (No) when calculating risks
        </S.Definition>
        <S.Title>Vulnerability Computation Mode</S.Title>
        <S.Definition>
          indicates if security controls are taken into account (DEFAULT) or if importing vulnerability findings
          override existing controls (AGGRESSIVE)
        </S.Definition>
        <S.Title>Non-Compliant Controls</S.Title>{" "}
        <S.Definition>
          indicates if non-compliant security controls are included (Include) or not (Exclude) in the control baseline{" "}
        </S.Definition>
        <S.Title>Baseline Mode</S.Title>{" "}
        <S.Definition>
          indicates if security controls baseline has a minimum (Floor) set of controls or a maximum (Ceiling).
        </S.Definition>
      </Popover.Body>
    </Popover>
  );

  // console.log("settings", projectSettings);

  return (
    <>
      <div style={{ paddingLeft: "450px" }}>
        <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
          <Button type="button" title="Configuration" size="sm" variant="link">
            <MdInfoOutline size="16" />
          </Button>
        </OverlayTrigger>
      </div>
      <S.Column>
        <S.InputGroup>
          <label htmlFor="physicalMode">Generate Physical Attacks</label>
          <Select
            inputId="physicalMode"
            options={physicalModeOptions}
            isDisabled={isReadOnly}
            value={physicalModeOptions.find((o) => o.value === settingsMap.get("physicalMode")?.value)}
            onChange={(e) => handleOnChange("physicalMode", e)}
          />
        </S.InputGroup>
        {!isThreatAnalyst ? (
          <>
            <S.InputGroup>
              <label htmlFor="vulnerabilityComputationMode">Vulnerability Computation Mode</label>
              <Select
                inputId="vulnerabilityComputationMode"
                isDisabled={isReadOnly}
                options={vulnerabilityComputationOptions}
                value={vulnerabilityComputationOptions.find(
                  (o) => o.value === settingsMap.get("vulnerabilityComputationMode")?.value
                )}
                onChange={(e) => handleOnChange("vulnerabilityComputationMode", e)}
              />
            </S.InputGroup>
            <S.InputGroup>
              <label htmlFor="complianceControlMode">Non-Compliant Controls</label>
              <Select
                inputId="complianceControlMode"
                isDisabled={isReadOnly}
                options={complianceControlModeOptions}
                value={complianceControlModeOptions.find(
                  (o) => o.value === settingsMap.get("compliantControlMode")?.value
                )}
                onChange={(e) => handleOnChange("compliantControlMode", e)}
              />
            </S.InputGroup>
            <S.InputGroup>
              <label htmlFor="baselineMode">Baseline Mode </label>
              <Select
                inputId="baselineMode"
                isDisabled={isReadOnly}
                options={baselineModeOptions}
                value={baselineModeOptions.find((o) => o.value === settingsMap.get("baselineMode")?.value)}
                onChange={(e) => handleOnChange("baselineMode", e)}
              />
            </S.InputGroup>
          </>
        ) : null}
      </S.Column>
    </>
  );
};
