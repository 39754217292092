import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Contents = styled.div`
  padding: 0px 15px 0px 15px;
  background-color: #ffffff;
  color: black;
`;

export const MessageContainer = styled.div`
  padding-left: 50px;
  font-family: monospace;
  white-space: pre;
  margin-bottom: 15px;
  background-color: #ffffff;
  color: black;
`;

export const ErrorHeader = styled.h5`
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const DescriptionContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
`;
