import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";

interface IExportAuditEvents {
  projectId: string;
  variantId?: string;
  format: string;
}

/**
 * Export Audit event records
 * @param {string} projectId - uuid of project
 * @param {string} variantId - uuid of variant
 */
export const exportAuditEdit = ({ projectId, variantId, format }: IExportAuditEvents) => {
  return Brm.systemAuditApi.findAudit(projectId, { variant: variantId, exportType: format });
};

interface IUseExportAuditEventsProps {
  options?: MutationOption<typeof exportAuditEdit>;
}

/**
 * Custom hook for downloading audit events report
 * @param {object} options - react-query options
 * @returns react-query for export audit events
 */
export const useExportAuditEditEvents = ({ options = {} }: IUseExportAuditEventsProps) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: exportAuditEdit,
  });
};
