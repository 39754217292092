import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import AttackerCategoryTable from "./tables/AttackerCategoryTable";

function AttackerCategory() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const header = <Title id="AttackerCategory_title">Attacker Categories</Title>;

  const main = (
    <>
      <AttackerCategoryTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="AttackerCategory_page" header={header} main={main} />;
}

export default AttackerCategory;
