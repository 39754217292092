import * as React from "react";
import ButtonCell from "components/EditComponents/ButtonCell";
import errorUtils from "utils/error-utils";
import handleApi from "services/brm/api-util";
import { userRoles, organizationApi, userApi, installationApi } from "services/brm/admin-service";
import {
  createColumnMapped,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnAdmin,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import { STATUS } from "constants/brm";

import { AdminApi } from "features/brm";
import { RoutePath } from "routes/route-paths";
import { useRoles } from "hooks";
import * as FilterUtils from "utils/filter-utils";

const superAdminRole = (userRoles.properties as any)[userRoles.SUPER_ADMIN].key;
const ADMIN = (userRoles.properties as any)[userRoles.ADMIN].key;

interface IUseOrganizationOverviewProps {
  organizationId: string | undefined;
  userRole: string | null;
  userId: string | null;
  navigate: (url: string) => void;
}

export const useOrganizationOverview = ({
  organizationId,
  userRole,
  userId,
  navigate,
}: IUseOrganizationOverviewProps) => {
  const { isSuperAdmin, isAdmin } = useRoles();

  const [refresh, setRefresh] = React.useState(true);
  const [organizationList, setOrganizationList] = React.useState<any[]>();
  const [editMode, setEditMode] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [installationList, setInstallationList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [selectedElement, setSelectedElement] = React.useState({});
  const [statusList] = React.useState([{ id: STATUS.active, name: STATUS.active }]);
  const updatedName = React.useRef("");
  const updatedStatus = React.useRef("");
  const updatedAdmin = React.useRef("");
  const updatedInstallation = React.useRef("");

  const { data: organizations } = AdminApi.useOrganizations({ options: { enabled: isSuperAdmin } });

  const { data: userOrganization } = AdminApi.useUserPrimaryOrganization({
    userId,
  });
  const { data: userAttachedOrganizations } = AdminApi.useUserAttachedOrganizations({
    userId,
  });

  const organizationData = React.useMemo(() => {
    let userOrgs = userAttachedOrganizations;
    if (userOrgs && !Array.isArray(userOrgs)) {
      userOrgs = [userOrgs];
    }
    if ((Array.isArray(organizations) && organizations.length) || (userOrganization && userOrgs)) {
      const orgData = isSuperAdmin ? organizations : [userOrganization, ...userOrgs];
      if (isSuperAdmin) {
        return orgData;
      }
      return orgData?.filter(FilterUtils.isNotDeletedStatus);
    }
    return undefined;
  }, [isSuperAdmin, organizations, userAttachedOrganizations, userOrganization]);

  /**
   * If we don't have access to the project redirect to the projects list
   */
  React.useEffect(() => {
    // Admin can view projects in their organization..
    if (isAdmin && organizationData?.some((o: any) => o.id === organizationId)) {
      return;
    }

    if (!isSuperAdmin) {
      navigate(RoutePath.Organizations);
    }
  }, [isAdmin, isSuperAdmin, navigate, organizationData, organizationId]);

  const createButton = React.useCallback(
    (cellProps) => (
      <ButtonCell
        selectedRowId={selectedRowId}
        elementId={cellProps.cell.row.original.id}
        handleConfirmEditClick={async () => {
          setEditMode(false);
          // send request to update name
          if (updatedName.current !== "") {
            // only perform update if updatedName is not an empty string
            await handleApi(
              organizationApi.setOrganizationNameWithHttpInfo(selectedRowId, {
                body: updatedName.current,
              })
            );
            updatedName.current = "";
          }

          // send request to update status
          if (updatedStatus.current && updatedStatus.current !== "Select status") {
            // only perform update if updatedStatus is not an empty string
            await handleApi(
              organizationApi.setOrganizationStatusWithHttpInfo(selectedRowId, {
                body: updatedStatus.current,
              })
            );
            updatedStatus.current = "";
          }

          // send request to update admin
          if (updatedAdmin.current && updatedAdmin.current !== "Select admin") {
            // only perform update if updatedAdmin is not an empty string
            await handleApi(
              organizationApi.setOrganizationAdminWithHttpInfo(selectedRowId, {
                body: updatedAdmin.current,
              })
            );
            updatedAdmin.current = "";
          }
          setRefresh(true);
        }}
        setEditMode={setEditMode}
        editMode={editMode}
      />
    ),
    [editMode, selectedRowId, setRefresh]
  );

  const columns = React.useMemo(() => {
    if (userRole === superAdminRole) {
      return [
        createColumnMapped("status", selectedRowId, statusList, editMode, updatedStatus),
        createColumnCreatedAt(),
        createColumnModifiedAt(),
        createColumnAdmin(selectedRowId, userList, editMode, updatedAdmin),
        createColumnMapped("installation", selectedRowId, installationList, editMode, updatedInstallation),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnMapped("status", selectedRowId, statusList, editMode, updatedStatus),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnAdmin(selectedRowId, userList, editMode, updatedAdmin),
      createColumnActions(createButton, { disableFilters: true }),
    ];
  }, [userRole, selectedRowId, editMode, statusList, userList, createButton, installationList]);

  React.useEffect(() => {
    async function getData() {
      setRefresh(false);
      // get Admin Name
      async function getAdminName(adminId: string) {
        try {
          const { data } = await userApi.getUserByIDWithHttpInfo(adminId);
          if (data) {
            return data.username;
          }
        } catch (err) {
          console.error("error in GET admin name : ", err);
        }
        return "-";
      }

      // get Installation name
      async function getInstallationName(installId: string) {
        try {
          const { data } = await installationApi.getInstallationByIDWithHttpInfo(installId);
          if (data?.name) {
            return data.name;
          }
        } catch (err) {
          console.error("error in GET Installation name : ", err);
        }
        return "-";
      }

      try {
        const { data, response } = await organizationApi.getOrganizationByIDWithHttpInfo(organizationId);
        if (data) {
          errorUtils.checkErrorStatus(response.status, response.statusText);
          setSelectedElement(data);
          const OrgToDisplay = data;
          if (data?.admin) {
            OrgToDisplay.admin = await getAdminName(data.admin);
          }
          if (data?.installation && userRole === superAdminRole) {
            OrgToDisplay.installation = await getInstallationName(data.installation);
          }
          setOrganizationList([OrgToDisplay]);
        } else {
          console.error("received no data");
        }
      } catch (err) {
        console.error("err in get Org by id : ", err);
      }

      // get Users List - data for dropdown
      try {
        const { data } = await organizationApi.getOrganizationUserWithHttpInfo(organizationId);
        if (data) {
          // TODO :: setuserList must include users who have role = admin or superadmin
          // and are part of this organization as users or attached users.
          const filteredUsers = data.length && data.filter((user: any) => user?.assignedRole?.includes(ADMIN));
          setUserList(filteredUsers);
        } else {
          console.error("received no data for users list ");
        }
      } catch (err) {
        console.error("err in setting users list : ", err);
      }

      // get Installation List - data for dropdown
      if (userRole === superAdminRole) {
        try {
          const { data } = await installationApi.findInstallationWithHttpInfo();
          if (data) {
            setInstallationList(data);
          } else {
            console.error("received no data for installation list ");
          }
        } catch (err) {
          console.error("err in getting installation list : ", err);
        }
      }
    }
    if (organizationId && refresh) {
      getData();
    }
  }, [refresh, organizationId, userRole]);

  return {
    refresh,
    setRefresh,
    columns,
    selectedElement,
    organizationList,
    setSelectedElement,
    setSelectedRowId,
  };
};
