import styled from "styled-components";

const ButtonAsLink = styled.button`
  background: none;
  padding: 0px;
  outline: none;
  border: none;
  color: #007bff;
  text-align: justify;

  &:focus {
    outline: none;
  }
  &:hover {
    color: blue;
    text-decoration: underline;
  }
  .p {
    color: blue;
  }
`;

export default ButtonAsLink;
