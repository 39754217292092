import * as React from "react";
import { useParams } from "react-router-dom";
import { queryClient } from "libs/react-query";
// Services
import {
  createColumnMappedNoEdit,
  createColumnStrength,
  createColumnMappedStringEdit,
  createColumnBooleanNoEditMapped,
  createColumnMapped,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";

// Styles
import Title from "components/elements/StyledTitle";
// Components
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import { strengthLevels } from "features/mitigation";
import ShowHideButton from "components/elements/ShowHideButton";
import { DetailsContainerSingleTable } from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
// Local
import ButtonCell from "components/EditComponents/ButtonCell";
import { LoadingSpinner as Loading, ErrorBanner as ErrorDisplay } from "components/elements";
import { TYPE } from "constants/brm";
import { GlobalControlApi, useRoles } from "features/brm";
import { useControlCatalog } from "../../hooks";
import { VULNERABILITIES } from "../../constants";

export function ControlType() {
  const { isControlsCoordinator } = useRoles();

  const [selectedElement, setSelectedElement] = React.useState({});
  const [editMode, setEditMode] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [showSummaryTable, setShowSummaryTable] = React.useState(true);
  const { id } = useParams();
  const updatedStrength = React.useRef("");
  const updatedCost = React.useRef("");
  const updatedImplGuidance = React.useRef("");
  const updatedAssmtGuidance = React.useRef("");
  const updatedVulCategory = React.useRef("");

  const { isReadOnlyCatalog } = useControlCatalog();

  const { data, isError, error } = GlobalControlApi.useControlType({
    controlTypeId: id,
    options: { enabled: !!id && isControlsCoordinator },
  });
  const { mutate: setControlStrength } = GlobalControlApi.useSetControlTypeStrength({
    options: {
      onMutate: async ({ controlTypeId, strength }) => {
        await queryClient.cancelQueries(["controlType", controlTypeId]);
        const previousValues = queryClient.getQueryData(["controlType", controlTypeId]);
        queryClient.setQueryData(["controlType", controlTypeId], (old) =>
          old.map((e) => {
            if (controlTypeId === e.id) {
              return {
                ...e,
                strength,
              };
            }
            return e;
          })
        );
        return previousValues;
      },
      onError: (previousValues) => queryClient.setQueryData(["controlType", id], previousValues),
      onSuccess: () => {
        updatedStrength.current = "";
        queryClient.invalidateQueries(["controlType", id]);
      },
    },
  });
  const { mutate: setControlCost } = GlobalControlApi.useSetControlTypeCost({
    options: {
      onMutate: async ({ controlTypeId, cost }) => {
        await queryClient.cancelQueries(["controlType", controlTypeId]);
        const previousValues = queryClient.getQueryData(["controlType", controlTypeId]);
        queryClient.setQueryData(["controlType", controlTypeId], (old) =>
          old.map((e) => {
            if (controlTypeId === e.id) {
              return {
                ...e,
                cost,
              };
            }
            return e;
          })
        );
        return previousValues;
      },
      onError: (previousValues) => queryClient.setQueryData(["controlType", id], previousValues),
      onSuccess: () => {
        updatedCost.current = "";
        queryClient.invalidateQueries(["controlType", id]);
      },
    },
  });
  const { mutate: setControlImplGuidance } = GlobalControlApi.useSetControlTypeImplGuidance({
    options: {
      onMutate: async ({ controlTypeId, impl }) => {
        await queryClient.cancelQueries(["controlType", controlTypeId]);
        const previousValues = queryClient.getQueryData(["controlType", controlTypeId]);
        queryClient.setQueryData(["controlType", controlTypeId], (old) =>
          old.map((e) => {
            if (controlTypeId === e.id) {
              return {
                ...e,
                implGuidance: impl,
              };
            }
            return e;
          })
        );
        return previousValues;
      },
      onError: (previousValues) => queryClient.setQueryData(["controlType", id], previousValues),
      onSuccess: () => {
        updatedImplGuidance.current = "";
        queryClient.invalidateQueries(["controlType", id]);
      },
    },
  });
  const { mutate: setControlAssmtGuidance } = GlobalControlApi.useSetControlTypeAssmtGuidance({
    options: {
      onMutate: async ({ controlTypeId, assmt }) => {
        await queryClient.cancelQueries(["controlType", controlTypeId]);
        const previousValues = queryClient.getQueryData(["controlType", controlTypeId]);
        queryClient.setQueryData(["controlType", controlTypeId], (old) =>
          old.map((e) => {
            if (controlTypeId === e.id) {
              return {
                ...e,
                assmtGuidance: assmt,
              };
            }
            return e;
          })
        );
        return previousValues;
      },
      onError: (previousValues) => queryClient.setQueryData(["controlType", id], previousValues),
      onSuccess: () => {
        updatedAssmtGuidance.current = "";
        queryClient.invalidateQueries(["controlType", id]);
      },
    },
  });
  const { mutate: setControlVulCat } = GlobalControlApi.useSetControlTypeVulCategory({
    options: {
      onMutate: async ({ controlTypeId, vulCat }) => {
        await queryClient.cancelQueries(["controlType", controlTypeId]);
        const previousValues = queryClient.getQueryData(["controlType", controlTypeId]);
        queryClient.setQueryData(["controlType", controlTypeId], (old) =>
          old.map((e) => {
            if (controlTypeId === e.id) {
              return {
                ...e,
                vulnerabilitycategory: vulCat,
              };
            }
            return e;
          })
        );
        return previousValues;
      },
      onError: (previousValues) => queryClient.setQueryData(["controlType", id], previousValues),
      onSuccess: () => {
        updatedVulCategory.current = "";
        queryClient.invalidateQueries(["controlType", id]);
      },
    },
  });

  const createButton = React.useCallback(
    (cellProps) => {
      if (isReadOnlyCatalog) {
        return null;
      }

      return (
        <ButtonCell
          selectedRowId={selectedRowId}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);
            if (updatedStrength.current !== "") {
              setControlStrength({ controlTypeId: id, strength: updatedStrength.current });
            }

            if (updatedCost.current !== "") {
              setControlCost({ controlTypeId: id, cost: updatedCost.current });
            }

            if (updatedVulCategory.current !== "") {
              setControlVulCat({ controlTypeId: id, vulCat: updatedVulCategory.current });
            }

            if (updatedAssmtGuidance.current !== "") {
              setControlAssmtGuidance({ controlTypeId: id, assmt: updatedAssmtGuidance.current });
            }

            if (updatedImplGuidance.current !== "") {
              setControlImplGuidance({ controlTypeId: id, impl: updatedImplGuidance.current });
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [
      editMode,
      id,
      isReadOnlyCatalog,
      selectedRowId,
      setControlAssmtGuidance,
      setControlCost,
      setControlImplGuidance,
      setControlStrength,
      setControlVulCat,
    ]
  );

  const columns = React.useMemo(() => {
    return [
      createColumnStrength(selectedRowId, strengthLevels, editMode, updatedStrength),
      createColumnMappedStringEdit("cost", selectedRowId, editMode, updatedCost),
      createColumnMappedStringEdit("implguidance", selectedRowId, editMode, updatedImplGuidance),
      createColumnMappedStringEdit("assmtguidance", selectedRowId, editMode, updatedAssmtGuidance),
      createColumnMappedNoEdit("catalog"),
      createColumnMappedNoEdit("family"),
      createColumnBooleanNoEditMapped("isStandard"),
      createColumnMapped("vulcat", selectedRowId, VULNERABILITIES, editMode, updatedVulCategory),
      createColumnActions(createButton, { disableFilters: true }),
    ];
  }, [createButton, editMode, selectedRowId]);

  React.useEffect(() => {
    if (data) {
      setSelectedElement(data[0]);
    }
  }, [data]);

  if (isError) {
    return <ErrorDisplay msg={error?.satusText} />;
  }

  if (data) {
    return (
      <>
        <Title>
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "ControlTypeFullDetails_table" }}
              elementName={TYPE.controlType}
            />
          </DetailsContainerSingleTable>
        ) : null}
      </>
    );
  }

  return <Loading />;
}
