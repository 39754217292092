import { useQuery } from "@tanstack/react-query";

import { DetailTableType, BrmError } from "features/brm";
import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";

import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./element-query-key-factory";

const getElementDataTypes = (
  elementId: string,
  elementType: string,
  dataflowsDataTypeData: BrmGql.GetDataflowDataTypesQuery | undefined,
  missionDataTypeData: BrmGql.GetMissionDataTypesQuery | undefined
) => {
  const dataTypeMap: IDataTypesMap = {
    [DetailTableType.DATAFLOWS.key]: () => dataflowsDataTypeData?.dataFlow?.dataTypes,
    [DetailTableType.MISSIONS.key]: () => missionDataTypeData?.mission?.dataTypes,
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.getCapabilityData(id),
    [DetailTableType.PERSONS.key]: (id) => Brm.personApi.getPersonAllData(id),
  };

  if (dataTypeMap[elementType]) {
    return dataTypeMap[elementType](elementId) || [];
  }

  throw new BrmError(`Unsupported element type: ${elementType}`);
};
interface IElementDataTypeProps {
  elementId: string;
  elementName: string;
  projectId: string;
  config: QueryOption<typeof getElementDataTypes>;
}

interface IDataTypesMap {
  [index: string]: (id?: string) => any;
}

/**
 * custom-hook to get all dataTypes
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {object} config - configuration for the react-query
 * @returns react-query for element dataTypes
 */
export const useElementDataTypes = ({ elementId, elementName, projectId, config = {} }: IElementDataTypeProps) => {
  const dataflowsDataTypeQuery = BrmGql.useGetDataflowDataTypesQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.DATAFLOWS.key }
  );

  const missionDataTypeQuery = BrmGql.useGetMissionDataTypesQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.MISSIONS.key }
  );

  const datatypesQuery = useQuery({
    ...config,
    queryKey: KeyFactory.elementKeys.dataTypes(
      elementId,
      elementName,
      dataflowsDataTypeQuery.data,
      missionDataTypeQuery.data
    ),
    queryFn: () => getElementDataTypes(elementId, elementName, dataflowsDataTypeQuery.data, missionDataTypeQuery.data),
  });

  if (dataflowsDataTypeQuery.isError) {
    return dataflowsDataTypeQuery;
  }
  if (missionDataTypeQuery.isError) {
    return missionDataTypeQuery;
  }
  return datatypesQuery;
};
