import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import errorUtils from "utils/error-utils";
import { nodeApi } from "services/brm/system-service";
import { BrmDetailTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import { AddButton, DeleteButton, DetailTableType } from "features/brm";
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";
import GroupAddForm from "./forms/GroupAddForm";
import multiSelectDelete from "./utils/DetailTablesUtils";

// Styles
const GroupTable = ({ elementNameInUrl, selectedElement, showAdd, showDelete, tableTitle }) => {
  const [refresh, setRefresh] = useState(false);
  const columns = useMemo(
    () => [createColumnNameDetails(RoutePath.Node.replace(":id", "")), createColumnMappedNoEdit("category")],
    []
  );

  const [outData, setOutData] = useState([]);
  const [, setSelectedGroup] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  function getDeleteRequest(groupId) {
    switch (elementNameInUrl) {
      case DetailTableType.NODES.key: {
        return nodeApi.removeNodeGroupWithHttpInfo(selectedElement.id, groupId);
      }
      default: {
        break;
      }
    }
    return undefined;
  }

  useEffect(() => {
    async function getData() {
      function getElementRequest() {
        switch (elementNameInUrl) {
          case DetailTableType.NODES.key: {
            return nodeApi.getNodeGroupWithHttpInfo(selectedElement.id);
          }
          default: {
            break;
          }
        }
        return undefined;
      }

      try {
        const res = await getElementRequest();
        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setOutData(res.data);
          setRefresh(false);
        }
      } catch (err) {
        console.error("Error while loading groups ", err);
      }
      return undefined;
    }
    getData();
  }, [refresh, elementNameInUrl, selectedElement]);

  const addGroup = () => {
    setModalIsOpen(true);
  };

  return (
    <S.DetailsContainer id="GroupTable_detailsPanel">
      {/* modal for adding instance */}
      {modalIsOpen &&
        systemModals.addModal(
          "GroupTable_detailsPanel",
          modalIsOpen,
          setModalIsOpen,
          <GroupAddForm
            setModalIsOpen={setModalIsOpen}
            setRefresh={setRefresh}
            elementNameInUrl={elementNameInUrl}
            selectedElement={selectedElement}
          />,
          "Group"
        )}

      <S.ActionContainer>
        <S.DetailsTableContainer>
          <BrmDetailTable
            data={outData}
            columns={columns}
            setSelectedElement={setSelectedGroup}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "GroupTable_detailsTable" }}
            showRowSelect={showDelete}
            tableTitle={tableTitle}
          />
        </S.DetailsTableContainer>
        <S.DetailsTableButtonsContainer>
          {showDelete && (
            <DeleteButton md onClick={() => multiSelectDelete(selectedRows, getDeleteRequest, setRefresh)} />
          )}
          {showAdd && <AddButton md onClick={addGroup} />}
        </S.DetailsTableButtonsContainer>
      </S.ActionContainer>
    </S.DetailsContainer>
  );
};

GroupTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  showAdd: PropTypes.any,
  showDelete: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default GroupTable;
