import PropTypes from "prop-types";
import * as React from "react";
import * as Recoil from "recoil";
// import { queryClient } from "libs/react-query";
import { BrmDetailTable } from "brm/tables/BrmTables";
// Atoms
import { projectIdState, sctmIdState } from "atoms";

// Constants
import { COMMON } from "constants/brm";
import { RoutePath } from "routes/route-paths";

// Components
import AutoMitigateButton from "components/elements/AutoMitigateButton/AutoMitigateButton";
import VulnerabilityVariantSelect from "brm/risk-model/vulnerability/utils/VulnerabilityVariantSelect";
import systemModals from "components/modals/Modals";
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";
import { usePerformAutoMitigate } from "features/risk";

import * as S from "brm/styles/details-table.styles";
import { DetailTableType, MitigationApi, currentVariantState } from "features/brm";

// Local
import { AddVariantForm } from "../../../reports/forms/AddVariantForm";
import { invalidateElementVulnerabilities } from "../api";

const VulnerabilityTableView = ({ data, elementNameInUrl, tableTitle }) => {
  // const [riskUpdated, setRiskUpdated] = Recoil.useRecoilState(riskUpdatedState);
  const projectId = Recoil.useRecoilValue(projectIdState);
  const setSctmId = Recoil.useSetRecoilState(sctmIdState);
  const currentVariant = Recoil.useRecoilValue(currentVariantState);

  const [, setSelectedVulnerability] = React.useState({});
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [variantSelectModalIsOpen, setVariantSelectModalIsOpen] = React.useState(false);
  const [newVariant, setNewVariant] = React.useState();

  const { isRiskAnalyst } = useRoles();

  const { data: variantsData } = MitigationApi.useVariantsWithScores({ projectId });
  const { data: sctm } = MitigationApi.useVariantSctm({
    variantId: currentVariant?.id,
    options: {
      enabled: !(
        currentVariant === null ||
        currentVariant === undefined ||
        (currentVariant && currentVariant.id === COMMON.nullUuid)
      ),
    },
  });

  const autoMitigate = usePerformAutoMitigate({
    onSuccess: () => {
      setModalIsOpen(false);
      // setRiskUpdated(true);
      invalidateElementVulnerabilities();
    },
    onError: () => {},
  });

  const columns = React.useMemo(() => {
    if (isRiskAnalyst && elementNameInUrl === DetailTableType.EXCHANGES.key) {
      return [
        createColumnMappedNoEdit("vulRank"),
        createColumnNameDetails(RoutePath.Vulnerability.replace(":id", "")),
        createColumnMappedNoEdit("category"),
        createColumnMappedNoEdit("vulLevelCalcStringValue"),
        createColumnMappedNoEdit("anobject"),
        createColumnMappedNoEdit("controlsCount"),
      ];
    }

    if (isRiskAnalyst) {
      return [
        createColumnMappedNoEdit("vulRank"),
        createColumnNameDetails(RoutePath.Vulnerability.replace(":id", "")),
        createColumnMappedNoEdit("category"),
        createColumnMappedNoEdit("vulLevelCalcStringValue"),
        createColumnMappedNoEdit("anobject"),
        createColumnMappedNoEdit("controlsCount"),
        createColumnMappedNoEdit("findingCount"),
      ];
    }

    return [
      createColumnMappedNoEdit("name"),
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("vulLevelCalcStringValue"),
      createColumnMappedNoEdit("anobject"),
    ];
  }, [elementNameInUrl, isRiskAnalyst]);

  const checkForVariants = React.useCallback(() => {
    if (variantsData.length > 0) {
      setVariantSelectModalIsOpen(true);
    } else {
      setModalIsOpen(true);
    }
  }, [variantsData]);

  React.useEffect(() => {
    if (sctm) {
      setSctmId(sctm.id);
    }
  }, [sctm, setSctmId]);

  const performAutoMitigate = async (varState) => {
    if (varState && varState.id !== COMMON.nullUuid) {
      autoMitigate(
        varState,
        selectedRows.map((v) => v.id)
      );
    } else {
      checkForVariants();
    }
  };

  return (
    <S.DetailsContainer id="VulnerabilityTable_detailsPanel">
      {modalIsOpen &&
        systemModals.addModal(
          "VulnerabilityTable_detailsPanel",
          modalIsOpen,
          setModalIsOpen,
          <AddVariantForm setModalIsOpen={setModalIsOpen} onNewVariant={setNewVariant} />,
          "Variant"
        )}

      {variantSelectModalIsOpen &&
        systemModals.customModal(
          "VulnerabilityTable_detailsPanel",
          variantSelectModalIsOpen,
          setVariantSelectModalIsOpen,
          <VulnerabilityVariantSelect
            setModalIsOpen={setModalIsOpen}
            setVariantSelectModalIsOpen={setVariantSelectModalIsOpen}
            onAutoMitigate={performAutoMitigate}
            newVariant={newVariant}
          />,
          "Select Variant"
        )}

      <S.ActionContainer>
        <S.DetailsTableContainer>
          <BrmDetailTable
            data={data}
            columns={columns}
            setSelectedElement={setSelectedVulnerability}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "VulnerabilityTable_detailsTable" }}
            tableTitle={tableTitle}
            showRowSelect={isRiskAnalyst}
          />
        </S.DetailsTableContainer>
        {isRiskAnalyst && (
          <S.DetailsTableButtonsContainer>
            <AutoMitigateButton
              onClick={() => {
                setVariantSelectModalIsOpen(true);
              }}
              selectedRows={selectedRows}
            />
          </S.DetailsTableButtonsContainer>
        )}
      </S.ActionContainer>
    </S.DetailsContainer>
  );
};

VulnerabilityTableView.propTypes = {
  data: PropTypes.array,
  elementNameInUrl: PropTypes.string,
  tableTitle: PropTypes.string,
};

export default VulnerabilityTableView;
