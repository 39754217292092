import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { COMMON } from "constants/brm";
import InLineSelectBox from "./InLineSelectBox2";

const EditCellBooleanDropDown = ({ selectedRowId, field, elementId, id, editMode, setter }) => {
  const name = useRef("");

  useEffect(() => {
    if (id.charAt(0) === "i" && id.charAt(1) === "s") {
      name.current = id.replace("is", "");
    }
  }, [id]);

  const updateValues = (value) => {
    setter(value);
  };

  if (editMode && selectedRowId === elementId) {
    return (
      <InLineSelectBox
        arrayOfData={[
          { id: "true", name: COMMON.yes },
          { id: "false", name: COMMON.no },
        ]}
        item={id}
        name={name.current}
        updateValues={updateValues}
        value={field ? COMMON.yes : COMMON.no}
      />
    );
  }

  if (field !== null && field !== undefined) {
    const convertedValue = field ? COMMON.yes : COMMON.no;
    return <>{convertedValue}</>;
  }

  return <>{COMMON.hyphen}</>;
};

EditCellBooleanDropDown.propTypes = {
  editMode: PropTypes.any,
  elementId: PropTypes.any,
  field: PropTypes.any,
  id: PropTypes.any,
  selectedRowId: PropTypes.any,
  setter: PropTypes.func,
};

export default EditCellBooleanDropDown;
