import PropTypes from "prop-types";

import EditIconStyled from "components/elements/EditIconStyled";
import EditIcon from "assets/icons/edit.svg";
import InLineEditButton from "components/elements/InLineEditButtonStyled";

const EditButton = ({ onClick, disabled }) => (
  <span title="Edit">
    <InLineEditButton id="EditButton_button" onClick={onClick} disabled={disabled}>
      <EditIconStyled id="EditButton_editIcon" src={EditIcon} alt="edit" width="14" height="14" />
    </InLineEditButton>
  </span>
);

EditButton.propTypes = {
  disabled: PropTypes.any,
  onClick: PropTypes.func,
};

export default EditButton;
