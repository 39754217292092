import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./organization-query-key-factory";

interface IDeleteOrganizationProps {
  organizationId: string | null;
}

/**
 * Delete an organization
 *
 * @param param0 upload file data
 * @returns
 */
export const deleteOrganization = ({ organizationId }: IDeleteOrganizationProps) => {
  return organizationId === null
    ? Promise.reject(new Error("Invalid organizationDto id"))
    : Brm.organizationApi.deleteOrganization(organizationId);
};

interface IDeleteOrganizationQueryProps {
  options?: MutationOption<typeof deleteOrganization>;
}

/**
 * Custom hook to Delete an Organization
 *
 * @returns react-query Mutation.
 */
export const useDeleteOrganization = ({ options }: IDeleteOrganizationQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: () => {
      queryClient.invalidateQueries(KeyFactory.organizationKeys.all);
    },
    ...options,
    mutationFn: deleteOrganization,
  });
};
