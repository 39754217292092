import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

/**
 *
 * Gets status of project
 * @param {string} projectId - uuid of project
 */
export const getProjectStatus = ({ projectId }: { projectId: string | null }) => {
  return projectId === null ? Promise.reject(new Error("Invalid id")) : Brm.projectApi.getProjectStatus(projectId);
};

interface IProjectStatusProps {
  projectId: string | null;
  options?: QueryOption<typeof getProjectStatus>;
}

/**
 * Custom hook for getting status of project
 *
 * @param {string} projectId - uuid of user
 * @param {object} options - react-query options
 * @returns getprojectCatalog
 */
export const useProjectStatus = ({ projectId, options = {} }: IProjectStatusProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.status(projectId),
    queryFn: () => getProjectStatus({ projectId }),
  });
};
