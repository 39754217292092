const COMMON = {
  na: "N/A",
  nullUuid: "00000000-0000-0000-0000-000000000000",
  defaultUuid: "ffffffff-ffff-ffff-ffff-ffffffffffff",
  yes: "Yes",
  no: "No",
  veryLow: "Very Low",
  low: "Low",
  moderate: "Moderate",
  high: "High",
  veryHigh: "Very High",
  confidentiality: "Confidentiality",
  availability: "Availability",
  integrity: "Integrity",
  hyphen: "-",
  none: "None",
};

export default COMMON;
