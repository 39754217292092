// import { projectNameState } from "atoms/atoms-admin";
import { useQuery } from "@tanstack/react-query";
// import { useRecoilValue } from "recoil";
// import { genericExportApi } from "services/brm/importer-service";
// import { useProject } from "features/brm";
import { ExportApi } from "features/brm";
import { EXPORT_STATUS } from "../constants";
// export const useGetExportModules = (context) => {
//   return useQuery(["getExportModules", context], () => genericExportApi.exporterModules(context), {
//     enabled: false,
//   });
// };

// export const useExport = (setResponseFromExport) => {
//   return useMutation(
//     ({ projectId, body, context }) => {
//       return genericExportApi.generateExportFile(context, projectId, { requestBody: body });
//     },
//     {
//       onSuccess: (data) => {
//         setResponseFromExport(data);
//       },
//     }
//   );
// };

// const generateExportFile = ({ projectId, body, context }) => {
//   return genericExportApi.generateExportFile(context, projectId, { requestBody: body });
// };

// export const useExport = (options = {}) => {
//   return useMutation({
//     ...options,
//     mutationFn: generateExportFile,
//   });
// };

export const useDownloadFile = (
  projectId,
  fileprefix,
  assetId,
  exportStatus,
  selectedOption,
  setExportModalIsOpen,
  context
) => {
  return useQuery(
    ["getDownloadFile", projectId, assetId, context],
    () => ExportApi.downloadAsset({ projectId, context, assetId }),
    // () => genericExportApi.exportAssetDownload(context, projectId, assetId),
    {
      enabled: !!assetId && !!exportStatus && !!exportStatus === EXPORT_STATUS.pass,
      cacheTime: 0,
      onSuccess: (data) => {
        setExportModalIsOpen(false);

        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);

        const timestamp = new Date(Date.now()).toISOString().replace(/T/, "_").replace(/\..+/, "").replace(/-|:/g, "");
        a.setAttribute("download", `${fileprefix}_${context}_${timestamp}.${selectedOption}`);
        a.click();
      },
    }
  );
};

export const useGetExportStatus = (
  projectId,
  runId,
  setExportStatus,
  exportStatus,
  setPostError,
  setDisableExport,
  context
) => {
  return useQuery(
    ["getExportStatus", projectId, runId, context],
    // () => genericExportApi.exportStatus(context, projectId, assetId),
    () => ExportApi.getExportStatus({ projectId, context, runId }),
    {
      enabled: !!runId && !!context,
      refetchInterval: exportStatus !== EXPORT_STATUS.pass && exportStatus !== EXPORT_STATUS.fail ? 3000 : false,
      onSuccess: (data) => {
        setExportStatus(data.status);
        if (data.status === EXPORT_STATUS.fail) {
          setPostError("*Error occured during export. Export status = FAIL");
          setDisableExport(false);
        }
      },
    }
  );
};
