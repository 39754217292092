import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./project-query-key-factory";

interface ISetProjectSapProgramsNameProps {
  projectId: string;
  enabled: boolean;
}

/**
 * configure the given project to be SAP sandbox
 *
 * @param param0 upload file data
 * @returns
 */
export const setProjectSapSandbox = ({ projectId, enabled }: ISetProjectSapProgramsNameProps) => {
  if (enabled) {
    return Brm.projectApi.setsapsandboxProject(projectId);
  }
  return Brm.projectApi.unsetsapsandboxProject(projectId);
};

interface ISetProjectSapProgramsProps {
  options?: MutationOption<typeof setProjectSapSandbox>;
}

/**
 * Custom hook to configure a project as a SAP sandbox
 *
 * @returns react-query Mutation.
 */
export const useSetProjectSapSandbox = ({ options }: ISetProjectSapProgramsProps = {}) => {
  return ReactQuery.useMutation({
    ...options,
    onSettled: (data, err, { projectId }) => {
      queryClient.invalidateQueries(KeyFactory.projectKeys.sapSandbox(projectId));
      queryClient.invalidateQueries(KeyFactory.projectKeys.sapPrograms(projectId));
    },
    mutationFn: setProjectSapSandbox,
  });
};
