import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";
import * as KeyFactory from "./capability-query-key-factory";

interface ISetCapabilbityIsInternalProps {
  capabilityId: string;
  isInternal: string;
}

/**
 * Sets isInternal of a capability
 *
 * @param {string} capabilityId - uuid of the capability
 * @param {string} isInternal - isInternal value of the capability
 */
export const setCapabilityIsInternal = ({ capabilityId, isInternal }: ISetCapabilbityIsInternalProps) => {
  return Brm.capabilityApi.setCapabilityIsInternal(capabilityId, { body: isInternal });
};

export interface IUseSetCapabilityIsInternal {
  projectId: string;
  options?: MutationOption<typeof setCapabilityIsInternal>;
}

/**
 * Custom hook to change isInternal of an existing capability
 *
 * @param {string} projectId - uuid of the project
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetCapabilityIsInternal = ({ projectId, options = {} }: IUseSetCapabilityIsInternal) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation({
    ...options,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(KeyFactory.capabilityKeys.capability(variables.capabilityId));
      queryClient.invalidateQueries(KeyFactory.capabilityKeys.project(projectId));
    },
    mutationFn: setCapabilityIsInternal,
  });
};
