export const VULNERABILITIES = [
  { name: "iaaa", id: "iaaa" },
  { name: "iaco", id: "iaco" },
  { name: "iasa", id: "iasa" },
  { name: "ibpr", id: "ibpr" },
  { name: "icma", id: "icma" },
  { name: "idsc", id: "idsc" },
  { name: "iiau", id: "iiau" },
  { name: "iiie", id: "iiie" },
  { name: "iilp", id: "iilp" },
  { name: "impr", id: "impr" },
  { name: "ipma", id: "ipma" },
  { name: "ippd", id: "ippd" },
  { name: "irpr", id: "irpr" },
  { name: "isas", id: "isas" },
  { name: "isba", id: "isba" },
  { name: "isfa", id: "isfa" },
  { name: "isma", id: "isma" },
  { name: "ismo", id: "ismo" },
  { name: "issa", id: "issa" },
  { name: "issp", id: "issp" },
  { name: "itop", id: "itop" },
  { name: "pdch", id: "pdch" },
  { name: "umsh", id: "umsh" },
];
