/* eslint-disable no-param-reassign */
import * as YFiles from "yfiles";
import * as React from "react";
import { useConstructor } from "hooks/useConstructor";
import * as ContextMenu from "../components/ContextMenu";

type ContextMenuState = { show: boolean; x: number; y: number; items: ContextMenu.ContextMenuItem[] };

export const useContextMenu = (
  graphComp: YFiles.GraphComponent,
  showPredicate: (args: YFiles.PopulateItemContextMenuEventArgs<YFiles.IModelItem>) => boolean,
  createItems: (args: YFiles.PopulateItemContextMenuEventArgs<YFiles.IModelItem>) => ContextMenu.ContextMenuItem[]
) => {
  const [contextMenu, setContextMenu] = React.useState<ContextMenuState>({
    show: false,
    x: 0,
    y: 0,
    items: [],
  });

  const hideMenu = () => {
    setContextMenu((prev: any) => ({ ...prev, show: false }));
  };

  /**
   * Shows the context menu at the given location
   * @param {Point} location
   */
  const openMenu = (location: { x: number; y: number }) => {
    setContextMenu((prev) => ({ ...prev, x: location.x, y: location.y, show: true }));
  };

  /**
   * Populates the context menu depending on the given context.
   * @param {PopulateItemContextMenuEventArgs} args
   */
  const populateContextMenu = (args: YFiles.PopulateItemContextMenuEventArgs<YFiles.IModelItem>) => {
    if (!showPredicate(args)) {
      args.showMenu = false;
      return;
    }
    const contextMenuItems: ContextMenu.ContextMenuItem[] = createItems(args);
    setContextMenu((prev: any) => ({ ...prev, items: contextMenuItems }));
    if (contextMenuItems.length > 0) {
      args.showMenu = true;
    }
  };
  useConstructor(() => {
    const inputMode = graphComp.inputMode as YFiles.GraphViewerInputMode;
    ContextMenu.addOpeningEventListeners(graphComp, (location) => {
      const worldLocation = graphComp.toWorldFromPage(location);
      const showMenu = inputMode.contextMenuInputMode.shouldOpenMenu(worldLocation);
      if (showMenu) {
        openMenu(location);
      }
    });

    inputMode.addPopulateItemContextMenuListener((sender, args) => {
      if (!args.item) {
        return;
      }
      // select the item
      graphComp.selection.clear();
      graphComp.selection.setSelected(args.item, true);
      populateContextMenu(args);
    });
    inputMode.contextMenuInputMode.addCloseMenuListener(hideMenu);
  });

  return [contextMenu, hideMenu] as const;
};
