import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./project-query-key-factory";

interface ISetProjectSapProgramsNameProps {
  projectId: string;
  sapProgramIds: string[];
}

/**
 * Set the sap programs for this project
 *
 * @param param0 upload file data
 * @returns
 */
export const setProjectSapPrograms = ({ projectId, sapProgramIds }: ISetProjectSapProgramsNameProps) => {
  return Brm.projectApi.setsapprogramProject(projectId, { requestBody: sapProgramIds });
};

interface ISetProjectSapProgramsProps {
  options?: MutationOption<typeof setProjectSapPrograms>;
}

/**
 * Custom hook to Set the sap programs for this project
 *
 * @returns react-query Mutation.
 */
export const useSetProjectSapPrograms = ({ options }: ISetProjectSapProgramsProps = {}) => {
  return ReactQuery.useMutation({
    ...options,
    onSettled: (data, err, { projectId }) => {
      queryClient.invalidateQueries(KeyFactory.projectKeys.sapPrograms(projectId));
      queryClient.invalidateQueries(KeyFactory.projectKeys.sapSandbox(projectId));
    },
    mutationFn: setProjectSapPrograms,
  });
};
