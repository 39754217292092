import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { TYPE } from "constants/brm";

import ShowHideButton from "components/elements/ShowHideButton";

import CloseButton from "./CloseButton";
import { DetailsContainer, DetailsContainerSingleTable, DetailsContainerParent } from "./ElementFullDetails.styles";

export const ElementFullDetails = ({
  compName,
  selectedElement,
  showBackLink,
  onCloseClick,
  data,
  columns,
  setSelectedElement,
  setSelectedRowId,
  detailsComp,
  hideSummaryTable = true,
}) => {
  const [showSummaryTable, setShowSummaryTable] = useState(true);

  useEffect(() => {
    setShowSummaryTable(hideSummaryTable);
  }, [hideSummaryTable]);

  return (
    <>
      <Title id={`${compName}_title`}>
        {selectedElement.name || selectedElement.username}
        {!(compName === TYPE.elementCat || compName === TYPE.structuralCat || compName === TYPE.classificationCat) && (
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        )}
        <Buttons id={`${compName}_buttons`}>{!showBackLink && <CloseButton onClick={onCloseClick} />}</Buttons>
      </Title>

      {showSummaryTable ? (
        !(compName === TYPE.elementCat || compName === TYPE.structuralCat || compName === TYPE.classificationCat) && (
          <DetailsContainerSingleTable id={`${compName}_table`}>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: `${compName}_fullDetailsTable` }}
              elementName={compName}
            />
          </DetailsContainerSingleTable>
        )
      ) : (
        <></>
      )}

      {selectedElement.id && (
        <DetailsContainerParent showSummaryTable={showSummaryTable}>
          <DetailsContainer id={`${compName}_detailsPanel`}>{selectedElement && detailsComp}</DetailsContainer>
        </DetailsContainerParent>
      )}
    </>
  );
};

ElementFullDetails.propTypes = {
  columns: PropTypes.array.isRequired,
  compName: PropTypes.any,
  data: PropTypes.array.isRequired,
  detailsComp: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func,
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
  }),
  setSelectedElement: PropTypes.func.isRequired,
  setSelectedRowId: PropTypes.func.isRequired,
  showBackLink: PropTypes.bool,
  hideSummaryTable: PropTypes.bool,
};

// export default ElementFullDetails;
