import * as Recoil from "recoil";
import { Link } from "react-router-dom";
import { Breadcrumb as BsBreadcrumb } from "react-bootstrap";

// Atoms
import { projectIdState, projectNameState } from "atoms/atoms-admin";
import { ReactNode } from "react";
import { RoutePath } from "routes/route-paths";
import * as S from "./Breadcrumb.styles";

interface IBreadcrumbProp {
  children: ReactNode;
  showProjects: boolean;
  showProject: boolean;
  projectActive: boolean;
  projectsActive: boolean;
}

/** Funky */
const Breadcrumb2 = ({
  children,
  showProjects = false,
  showProject = false,
  projectActive = false,
  projectsActive = false,
}: IBreadcrumbProp) => {
  const projectId = Recoil.useRecoilValue(projectIdState);
  const projectName = Recoil.useRecoilValue(projectNameState);

  return (
    <S.Breadcrumb data-testid="brm-breadcrumb">
      {showProjects ? (
        <BsBreadcrumb.Item active={projectsActive} linkAs={Link} linkProps={{ to: RoutePath.Projects }}>
          Projects
        </BsBreadcrumb.Item>
      ) : null}
      {showProject ? (
        <BsBreadcrumb.Item
          active={projectActive}
          linkAs={Link}
          linkProps={{ to: `${RoutePath.ProjectOverview}${projectId}` }}
        >
          {projectName}
        </BsBreadcrumb.Item>
      ) : null}
      {children}
    </S.Breadcrumb>
  );
};

export const Breadcrumb = Object.assign(Breadcrumb2, {
  Item: BsBreadcrumb.Item,
});
