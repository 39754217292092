/* eslint-disable import/prefer-default-export */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./system-query-key-factory";

/**
 * Returns a list of all system within a given project
 *
 * @param {string} projectId - uuid of project
 * @returns list of all nodes within the given project
 */
export const getSystems = ({ projectId }: { projectId: string }): Promise<any> => {
  if (projectId === "") {
    return Promise.reject(new Error("Invalid projectId"));
  }
  return Brm.systemApi.findSystem(projectId);
};

interface IUseSystemsProps {
  projectId: string;
  options?: QueryOption<typeof getSystems>;
}

/**
 * Custom-hook to retrieve all systems for a project
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query configuration object
 * @returns react-query for findNode
 */
export const useSystems = ({ projectId, options = {} }: IUseSystemsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.systemKeys.systems(projectId),
    queryFn: () => getSystems({ projectId }),
  });
};
