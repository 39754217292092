import PropTypes from "prop-types";
import S from "./Button.styles";

export const BasicButton = ({ children, onClick: onClickProp, id, disabled = false }) => {
  const startIcon = null;
  const endIcon = null;

  return (
    <S.Button onClick={onClickProp} id={id} disabled={disabled}>
      <S.Span>
        {startIcon}
        {children}
        {endIcon}
      </S.Span>
    </S.Button>
  );
};

BasicButton.propTypes = {
  children: PropTypes.node,
  id: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
