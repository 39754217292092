// React
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
// Components
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import ButtonCell from "components/EditComponents/ButtonCell";
import Title from "components/elements/StyledTitle";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import systemModals from "components/modals/Modals";
import { MitigationApi } from "features/brm";

// Helpers
import errorUtils from "utils/error-utils";

// Services
import handleApi from "services/brm/api-util";
import { allocatedControlApi } from "services/brm/mitigation-service";
import { controlFamilyApi } from "services/brm/global-control-service";
import { nodeApi, linkApi, busApi } from "services/brm/system-service";
import { vulnerabilityCategoryApi } from "services/brm/vulnerability-service";

// Hooks
import { useRoles } from "features/brm/hooks/useRoles";

// State
import { controlFamilyIdState } from "atoms/atoms-global-control";
import { variantIdState } from "atoms/atoms-component";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";

// Misc
import { COMMON, TYPE } from "constants/brm";

import {
  createColumnMapped,
  createColumnMappedNoEdit,
  createColumnMappedStringEdit,
  createColumnBooleanMapped,
  createColumnBooleanNoEditMapped,
  createColumnCalibratedStrength,
  createColumnActions,
  createColumnAllocatedControlImplGuidance,
} from "brm/tables/services/column/columnFactory";
import { RoutePath } from "routes/route-paths";
import { strengthLevels } from "../../constants";
import { AllocatedControlTab } from "../../components/tabs/AllocatedControlTab";

const listUrl = RoutePath.AllocatedControls;

export function AllocatedControlOverview() {
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const localVariantId = useRef(variantId);
  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const controlFamilyId = useRecoilValue(controlFamilyIdState);
  const { id } = useParams();
  const [vulCategoryList, setVulCategoryList] = useState([]);
  const [systemObjectList, setSystemObjectList] = useState([]);
  const [controlTypeList, setControlTypeList] = useState([]);
  const updatedVulCategory = useRef("");
  const updatedControlType = useRef("");
  const updatedObject = useRef("");
  const updatedCalibratedStrength = useRef("");
  const updatedIsExisting = useRef("");
  const updatedIsCompliant = useRef("");
  const updatedImplGuidance = useRef("");
  const updatedAssmtGuidance = useRef("");
  const { isRiskAnalyst, isControlsCoordinator } = useRoles();
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { mutate: setAllocatedControlCompliance } = MitigationApi.useSetAllocatedControlCompliance();

  const createButton = useCallback(
    (cellProps) => {
      if (isControlsCoordinator) {
        return (
          <ButtonCell
            selectedRowId={selectedRowId}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedVulCategory.current !== "") {
                await handleApi(
                  allocatedControlApi.setAllocatedControlVulnerabilitycategoryWithHttpInfo(selectedRowId, {
                    body: updatedVulCategory.current,
                  })
                );
                updatedVulCategory.current = "";
              }

              if (updatedControlType.current !== "") {
                await handleApi(
                  allocatedControlApi.setAllocatedControlControltypeWithHttpInfo(selectedRowId, {
                    body: updatedControlType.current,
                  })
                );
                updatedControlType.current = "";
              }

              if (updatedObject.current !== "") {
                await handleApi(
                  allocatedControlApi.setAllocatedControlObjectWithHttpInfo(selectedRowId, {
                    body: updatedObject.current,
                  })
                );
                updatedObject.current = "";
              }

              if (updatedImplGuidance.current !== "") {
                await handleApi(
                  allocatedControlApi.setAllocatedControlImplGuidanceWithHttpInfo(selectedRowId, {
                    body: updatedImplGuidance.current,
                  })
                );
                updatedImplGuidance.current = "";
              }
              if (updatedAssmtGuidance.current !== "") {
                await handleApi(
                  allocatedControlApi.setAllocatedControlAssmtGuidanceWithHttpInfo(selectedRowId, {
                    body: updatedAssmtGuidance.current,
                  })
                );
                updatedAssmtGuidance.current = "";
              }

              if (updatedCalibratedStrength.current !== "") {
                await handleApi(
                  allocatedControlApi.setAllocatedControlCalibratedStrengthWithHttpInfo(selectedRowId, {
                    body: updatedCalibratedStrength.current,
                  })
                );
                updatedCalibratedStrength.current = "";
              }

              if (updatedIsCompliant.current !== "") {
                await handleApi(
                  allocatedControlApi.setAllocatedControlIsCompliantWithHttpInfo(selectedRowId, {
                    body: updatedIsCompliant.current,
                  })
                );
                updatedIsCompliant.current = "";
              }

              if (updatedIsExisting.current !== "") {
                await handleApi(
                  allocatedControlApi.setAllocatedControlIsExistingWithHttpInfo(selectedRowId, {
                    body: updatedIsExisting.current,
                  })
                );
                updatedIsExisting.current = "";
              }
              setRefresh(true);
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      if (isRiskAnalyst) {
        return (
          <ButtonCell
            selectedRowId={selectedRowId}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);
              if (updatedIsCompliant.current !== "") {
                setAllocatedControlCompliance({
                  allocatedControlId: selectedRowId,
                  isCompliance: updatedIsCompliant.current,
                });
                updatedIsCompliant.current = "";
              }
              setRefresh(true);
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return COMMON.na;
    },
    [isControlsCoordinator, isRiskAnalyst, selectedRowId, editMode, setAllocatedControlCompliance]
  );

  const columns = useMemo(() => {
    if (isControlsCoordinator) {
      return [
        createColumnMapped("vulcat", selectedRowId, vulCategoryList, editMode, updatedVulCategory),
        createColumnMapped("controltype", selectedRowId, controlTypeList, editMode, updatedControlType),
        createColumnMapped("anobject", selectedRowId, systemObjectList, editMode, updatedObject),
        createColumnMappedNoEdit("strength"),
        createColumnCalibratedStrength(selectedRowId, strengthLevels, editMode, updatedCalibratedStrength),
        createColumnMappedNoEdit("sctm"),
        createColumnBooleanNoEditMapped("isEffective"),
        createColumnBooleanNoEditMapped("isAuto"),
        createColumnBooleanNoEditMapped("isImported"),
        createColumnBooleanMapped("isExisting", selectedRowId, editMode, updatedIsExisting),
        createColumnBooleanMapped("isCompliant", selectedRowId, editMode, updatedIsCompliant),
        createColumnAllocatedControlImplGuidance(setModalIsOpen),
        createColumnMappedStringEdit("assmtguidance", selectedRowId, editMode, updatedAssmtGuidance),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    if (isRiskAnalyst) {
      return [
        createColumnMapped("vulcat", selectedRowId, vulCategoryList, false, null),
        createColumnMapped("controltype", selectedRowId, controlTypeList, false, null),
        createColumnMapped("anobject", selectedRowId, systemObjectList, false, null),
        createColumnMappedNoEdit("strength"),
        createColumnCalibratedStrength(selectedRowId, strengthLevels, false, null),
        createColumnMappedNoEdit("sctm"),
        createColumnBooleanNoEditMapped("isEffective"),
        createColumnBooleanNoEditMapped("isAuto"),
        createColumnBooleanNoEditMapped("isImported"),
        createColumnBooleanNoEditMapped("isExisting"),
        createColumnBooleanMapped("isCompliant", selectedRowId, editMode, updatedIsCompliant),
        createColumnAllocatedControlImplGuidance(setModalIsOpen),
        createColumnMappedStringEdit("assmtguidance", selectedRowId, false, null),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnMapped("vulcat", selectedRowId, vulCategoryList, false, null),
      createColumnMapped("controltype", selectedRowId, controlTypeList, false, null),
      createColumnMapped("anobject", selectedRowId, systemObjectList, false, null),
      createColumnMappedNoEdit("strength"),
      createColumnCalibratedStrength(selectedRowId, strengthLevels, false, null),
      createColumnMappedNoEdit("sctm"),
      createColumnBooleanNoEditMapped("isEffective"),
      createColumnBooleanNoEditMapped("isAuto"),
      createColumnBooleanNoEditMapped("isImported"),
      createColumnBooleanNoEditMapped("isExisting"),
      createColumnBooleanMapped("isCompliant", selectedRowId, editMode, updatedIsCompliant),
      createColumnAllocatedControlImplGuidance(setModalIsOpen),
      createColumnMappedStringEdit("assmtguidance", selectedRowId, false, null),
    ];
  }, [
    isControlsCoordinator,
    isRiskAnalyst,
    selectedRowId,
    vulCategoryList,
    editMode,
    controlTypeList,
    systemObjectList,
    createButton,
  ]);

  useEffect(() => {
    if (variantId !== localVariantId.current) {
      navigate(listUrl);
    }
  }, [variantId, navigate]);

  useEffect(() => {
    async function getData() {
      setRefresh(false);

      try {
        // get dropdown data
        let links = [];
        let buses = [];
        let nodes = [];

        const res = await linkApi.findLinkWithHttpInfo(projectId);
        if (res?.data) {
          links = res.data;
        }

        const resBuses = await busApi.findBusWithHttpInfo(projectId);
        if (resBuses?.data) {
          buses = resBuses.data;
        }

        const resNodes = await nodeApi.findNodeWithHttpInfo(projectId);
        if (resNodes?.data) {
          nodes = resNodes.data;
        }
        setSystemObjectList([...links, ...buses, ...nodes]);

        const resVulCat = await vulnerabilityCategoryApi.findVulnerabilityCategoryWithHttpInfo(projectId);
        if (resVulCat?.data) {
          setVulCategoryList(resVulCat.data);
        }
      } catch (err) {
        console.error("Error while loading drop down data: ", err);
      }

      if (controlFamilyId) {
        const res = await controlFamilyApi.getControlFamilyControlWithHttpInfo(controlFamilyId);
        if (res?.data) {
          setControlTypeList(res.data);
        }
      }

      let acToDisplay = {};

      try {
        const res = await allocatedControlApi.getAllocatedControlByIDWithHttpInfo(id);

        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setSelectedElement(res.data);
          acToDisplay = res.data;
        }
      } catch (err) {
        console.error("err in get allocated controls : ", err);
      }

      try {
        const res = await allocatedControlApi.getAllocatedControlSctmWithHttpInfo(id);

        if (res?.data) {
          acToDisplay.sctm = res.data;
        }
      } catch (err) {
        console.error("error in useEffect for getting allocated control sctm: ", err);
      }

      try {
        const res = await allocatedControlApi.getAllocatedControlObjectWithHttpInfo(id);

        if (res?.data) {
          acToDisplay.anobject = res.data;
        }
      } catch (err) {
        console.error("error in useEffect for getting allocated control object: ", err);
      }

      try {
        const res = await allocatedControlApi.getAllocatedControlControltypeWithHttpInfo(id);

        if (res?.data) {
          acToDisplay.controltype = res.data;
        }
      } catch (err) {
        console.error("error in useEffect for getting allocated control control type: ", err);
      }
      setData([acToDisplay]);
    }
    if (refresh || id) {
      getData();
    }
  }, [id, refresh, setRefresh, projectId, controlFamilyId]);

  return (
    <>
      <Title>
        {selectedElement.name}
        <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
      </Title>

      {showSummaryTable ? (
        <DetailsContainerSingleTable>
          {modalIsOpen &&
            systemModals.customModal(
              "AllocatedControlsFullDetails",
              modalIsOpen,
              setModalIsOpen,
              <div>{data[0].implGuidance}</div>,
              `${data[0].controltype?.name}  Impl. Guidance`
            )}
          <BrmSingleElementTable
            data={data}
            columns={columns}
            setSelectedElement={setSelectedElement}
            setSelectedRowId={setSelectedRowId}
            customProps={{ id: "AllocatedControlFullDetails_table" }}
            elementName={TYPE.allocatedControl}
          />
        </DetailsContainerSingleTable>
      ) : (
        <></>
      )}

      <DetailsContainerParent showSummaryTable={showSummaryTable}>
        <DetailsContainer>
          {selectedElement.id && (
            <AllocatedControlTab selectedElement={selectedElement} setRefresh={setRefresh} refresh={refresh} />
          )}
        </DetailsContainer>
      </DetailsContainerParent>
    </>
  );
}
