import { COMMON } from "constants/brm";
import { atom } from "recoil";

/**
 * The active varaint id
 */
export const variantIdState = atom({
  key: "variantId",
  default: COMMON.nullUuid,
});

export const variantAddedState = atom({
  key: "variantAdded",
  default: false,
});

export const variantNameState = atom({
  key: "variantName",
  default: "",
});

export const isVariantDataUpdatedState = atom({
  key: "isVariantDataUpdated",
  default: false,
});

export const isReportsTabDefaultState = atom({
  key: "isReportsTabDefault",
  default: false,
});

export const defaultReportTabState = atom({
  key: "defaultReportTab",
  default: "",
});
