import styled from "styled-components";
import styledMap from "styled-map";

const riskMatrixStyles = {
  Tooltip: styled.div`
    position: relative;
    display: inline-block;

    .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: ${({ theme }) => theme.colors.highContrastBackground};
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: ${styledMap`
        left: -20px;
        default: -60px;
    `};
      opacity: 0;
      transition: opacity 0.3s;
    }

    .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: ${styledMap`
        left: 17%;
        default: 50%;
    `};
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }

    :hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  `,
  RiskMatrixStyled: styled.div`
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.fonts.defaultSize};
    flex: 1;
    background-color: #ffffff;
    padding: 10px;
    overflow: visible;
    word-wrap: break-word;
  `,
  RiskMatrixTable: styled.table`
    height: 100%;
    width: 100%;
    text-align: center;
  `,
  R1Cell: styled.td`
    background-color: #1eb147;
    border: 2px solid white;
    height: 20%;
    width: 20%;
    :hover {
      cursor: ${(prop) => (prop.hover ? "pointer" : "auto")};
    }
  `,
  R2Cell: styled.td`
    background-color: #7ed143;
    border: 2px solid white;
    height: 20%;
    width: 20%;
    :hover {
      cursor: ${(prop) => (prop.hover ? "pointer" : "auto")};
    }
  `,
  R3Cell: styled.td`
    background-color: #fdfd96;
    border: 2px solid white;
    height: 20%;
    width: 20%;
    :hover {
      cursor: ${(prop) => (prop.hover ? "pointer" : "auto")};
    }
  `,
  R4Cell: styled.td`
    background-color: #f8ba27;
    border: 2px solid white;
    height: 20%;
    width: 20%;
    :hover {
      cursor: ${(prop) => (prop.hover ? "pointer" : "auto")};
    }
  `,
  R5Cell: styled.td`
    background-color: #ff473d;
    border: 2px solid white;
    height: 20%;
    width: 20%;
    :hover {
      cursor: ${(prop) => (prop.hover ? "pointer" : "auto")};
    }
  `,
};

export default riskMatrixStyles;
