/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import * as React from "react";
import { ColumnDef, RowSelectionState, Row } from "@tanstack/react-table";
import { BsFillShieldFill, BsFillBugFill } from "react-icons/bs";
import { IVulnerabilityData } from "../types";
import { SelectionTable } from "../SelectionTable";
import * as S from "./VulnerabilitySelectTable.styles";
import { ColumnFilter } from "../../modals/ColumnFilter";
import { ColumnId } from "../../modals/column-ids";

interface IVulnerabilitySelectionTableProp {
  vulnerabilities: IVulnerabilityData[];
  selectedVulnerabilities: RowSelectionState;
  onRowSelect?: (value: RowSelectionState) => void;
}

interface IBooleanCellValue {
  value: any;
}

const FindingCell = ({ value }: IBooleanCellValue) => {
  if (value) {
    return (
      <S.IconCell>
        <BsFillBugFill fill="#cd2027" />
      </S.IconCell>
    );
  }
  return <S.IconCell>-</S.IconCell>;
};

const ControlCell = ({ value }: IBooleanCellValue) => {
  if (value) {
    return (
      <S.IconCell>
        <BsFillShieldFill fill="#55ad81" />
      </S.IconCell>
    );
  }
  return <S.IconCell>-</S.IconCell>;
};

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & React.HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return <input type="checkbox" ref={ref} className={`${className} cursor-pointer`} {...rest} />;
}

const columns: ColumnDef<IVulnerabilityData>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
    // enableSorting: false,
  },
  {
    header: "Rank",
    id: ColumnId.rank,
    accessorFn: (row) => row.rank,
    cell: (info) => info.getValue(),
    filterFn: "equals",
    // enableColumnFilter: false,
  },
  {
    header: "Level",
    id: ColumnId.level,
    accessorFn: (row) => row.level,
    cell: (info) => info.getValue(),
    filterFn: "equalsString",
    sortingFn: (rowA: Row<any>, rowB: Row<any>, columnId: string) => {
      const levelValue: Record<string, number> = { "": 5, "very low": 4, low: 3, moderate: 2, high: 1, "very high": 0 };
      const rowLevelA = (rowA.getValue(columnId) as string).toLowerCase() || "";
      const rowLevelB = (rowB.getValue(columnId) as string).toLowerCase() || "";
      if (levelValue[rowLevelA] > levelValue[rowLevelB]) return 1;
      if (levelValue[rowLevelB] > levelValue[rowLevelA]) return -1;
      return 0;
    },
  },

  {
    header: "Findings",
    id: ColumnId.findings,
    accessorFn: (row) => row.hasFindings,
    cell: (info) => <FindingCell value={info.getValue()} />,
  },
  {
    header: "Controls",
    id: ColumnId.controls,
    accessorFn: (row) => row.hasControls,
    cell: (info) => <ControlCell value={info.getValue()} />,
  },
  {
    header: "Category",
    id: ColumnId.category,
    accessorFn: (row) => row.category,
    cell: (info) => info.getValue(),
  },
  {
    header: "Name",
    id: ColumnId.name,
    accessorFn: (row) => row.name,
    cell: (info) => info.getValue(),
    enableSorting: false,
  },
];

export const VulnerabilitySelectionTable = ({
  vulnerabilities,
  selectedVulnerabilities,
  onRowSelect,
}: IVulnerabilitySelectionTableProp) => {
  const [rowCount, setRowCount] = React.useState(vulnerabilities.length);

  return (
    <>
      <S.TableContainer>
        <SelectionTable
          columns={columns}
          data={vulnerabilities}
          rowSelection={selectedVulnerabilities}
          onRowSelectionChange={onRowSelect}
          renderFilter={({ column, table }) => <ColumnFilter column={column} table={table} />}
          setRowCount={setRowCount}
        />
      </S.TableContainer>
      <S.FilterStateContainer>
        Showing {rowCount} vulnerabilities of {vulnerabilities.length}
      </S.FilterStateContainer>
    </>
  );
};
