import styled from "styled-components";

export const CloseIcon = styled.img`
  height: 10px;
`;

export const Close = styled.div`
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  margin: 0 5px;
  background-color: none;
  border-color: none;
  color: black;
  border: none;
`;

export const ButtonsDiv = styled.div`
  float: right;
  padding-right: 10px;
  font-size: 14px;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 0;
  background: #ccc;
`;

export const HeaderFlex = styled.div`
  display: flex;
  background: #ccc;
  flex-shrink: 0;
  flex-wrap: 0;
  align-items: center;
  justify-content: space-between;
`;

export const Footer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: 0;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
  padding-right: 10px;
  background: ${(props) => props.theme.formbackground};
  gap: 10px;
`;

export const Contents = styled.div`
  overflow: auto;
  min-height: 200px;
  max-height: 900px;
  z-index: -1;
`;

export const Body = styled.div`
  font-size: 1rem;
  padding: 5px;
  background: ${(props) => props.theme.formbackground};
`;

export const ModalTitle = styled.div`
  color: ${({ theme }) => theme.colors.title};
  height: 46px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fonts.large};
  font-weight: 550;
  padding-top: 12px;
  padding-left: 10px;
`;

export const HeaderButtonsDiv = styled.div`
  padding-right: 10px;
`;

export const defaultModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
    padding: "0px",
    fontSize: "13px",
    borderRadius: "10px",
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
  overlay: {
    zIndex: 1000,
    backdropFilter: "contrast(90%)",
  },
};

export const autoMitigateModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "900px",
    padding: "0px",
    fontSize: "13px",
    borderRadius: "10px",
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
  overlay: { zIndex: 1000 },
};

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  border-top: 1px solid #ccc;
  padding: 5px 0px 0px 0px;
  /* margin-right: 5px;
  margin-left: 5px; */
  width: 100%;
`;
