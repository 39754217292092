import styled from "styled-components/macro";

interface IOverlowProp {
  overflow: string;
}

interface IWidthProp {
  width: string;
}

const titledPaneStyles = {
  Container: styled.div<IWidthProp>`
    display: flex;
    width: ${(props) => props?.width || "100%"};
    min-width: 40px;
    flex-direction: column;
  `,
  TitleContainer: styled.div`
    padding: 5px;
  `,
  ChildrenContainer: styled.div<IOverlowProp>`
    height: 100%;
    overflow-y: ${({ overflow }) => overflow || "none"};
  `,

  Title: styled.p`
    color: #42526e;
    padding-left: 10px;
    max-width: 75%;
  `,

  TitledPane: styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    border-bottom: 2px solid #ebecf0;
    padding: 15px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.fonts.defaultSize};
    color: ${({ theme }) => theme.colors.veryHighContrastBackground};
    background-color: #fafbfc;
    width: 100%;
    min-height: 50px;
    height: auto;
    padding-left: 25px;
    word-wrap: break-word;
  `,
};

export default titledPaneStyles;
