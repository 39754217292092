import { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SecurityObjectiveDetails from "brm/system-model/categorization/details/SecObjectiveDetails";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { useRoles } from "features/brm/hooks/useRoles";
import { ElementFullDetails, isCurrentProjectActiveState } from "features/brm";
import * as SecurityObjectiveApi from "brm/system-model/categorization/api";
import { TYPE } from "constants/brm";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { RoutePath } from "routes/route-paths";

function SecurityObjectiveFullDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const { isSystemEngineer, isRiskAnalyst } = useRoles();

  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [, setSelectedRowId] = useState("");
  const isCurrentProjectActive = useRecoilValue(isCurrentProjectActiveState);

  const { data, error } = SecurityObjectiveApi.useSecurityObjective({
    soId: id,
    setSelectedElement,
    options: {
      enabled: !!id && (isSystemEngineer || (isCurrentProjectActive && isRiskAnalyst)),
    },
  });

  const columns = useMemo(() => {
    function makeCell(row) {
      return <span title={row.value}>{row.value}</span>;
    }
    return [
      {
        Header: "Levels",
        accessor: "levels",
        Cell: makeCell,
      },
      {
        Header: "Letters",
        accessor: "letters",
        Cell: makeCell,
      },
    ];
  }, []);

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(RoutePath.SecurityObjectives);
    }
  }, [projectId, navigate]);

  if (error) {
    return <ErrorBanner msg="Error while loading security objective details" />;
  }

  if (data) {
    const secObjectiveDetails = <SecurityObjectiveDetails selectedElement={selectedElement} />;
    return (
      <ElementFullDetails
        showBackLink
        data={data}
        columns={columns}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        setSelectedRowId={setSelectedRowId}
        detailsComp={secObjectiveDetails}
        compName={TYPE.securityObjective}
      />
    );
  }

  return <Loading />;
}

export default SecurityObjectiveFullDetails;
