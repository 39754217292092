import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";

interface IAddExchangeActProducer {
  exchangeId: string;
  producerId: string;
}

/**
 * Sets the act producer of a exchange
 *
 * @param {string} exchangeId - uuid of the exchange
 * @param {string} producerId - uuid of new producer or null
 */
export const addExchangeActProducer = ({ exchangeId, producerId }: IAddExchangeActProducer) => {
  return Brm.exchangeApi.addExchangeActProducer(exchangeId, { body: producerId });
};

interface IExchangeActProducerProps {
  options?: MutationOption<typeof addExchangeActProducer>;
}

/**
 * Custom hook to change the act producer of an existing exchange
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useAddExchangeActProducer = ({ options }: IExchangeActProducerProps = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: addExchangeActProducer,
  });
};
