export const SCTM_FORM_ELEMENTS = {
  autoAllocate: "auto-allocate",
  importScope: "import-scope",
  convertEffective: "convert-effective",
  headerRow: "header-row",
  controlColumn: "control-column",
  sctmName: "sctm-name",
  sctmBaseline: "sctm-baseline",
  sctmVariant: "sctm-variant",
  excludeNonCompliant: "exclude-non-compliant",
} as const;
