/* eslint-disable react/prop-types */
import { contentKeyState } from "atoms";
import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { ThemeContext } from "styled-components";

import S from "./TitledPane.styles";

export const BrmTitledPane = ({
  title,
  alt,
  children = null,
  iconComp = null,
  overflow = "none",
  size = 0,
  width = "",
}) => {
  const theme = useContext(ThemeContext);
  const contentKey = useRecoilValue(contentKeyState);

  const Icon = iconComp;

  return (
    <S.Container data-testid={`titled-pane-container ${title}`} width={width}>
      <S.TitledPane>
        {iconComp && (
          <Icon
            title={title}
            alt={alt}
            fill={theme.colors.buttonPrimary}
            size={size}
            width={theme.sizes.standaloneIconWidth}
            height={theme.sizes.standaloneIconHeight}
          />
        )}
        {title === "Help" && contentKey ? (
          <S.Title>
            Help - <b>{contentKey.charAt(0).toUpperCase() + contentKey.slice(1)}</b>
          </S.Title>
        ) : (
          <S.Title>{title}</S.Title>
        )}
      </S.TitledPane>
      <S.ChildrenContainer data-testid="titled-pane-child-container" overflow={overflow}>
        {children}
      </S.ChildrenContainer>
    </S.Container>
  );
};
