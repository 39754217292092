/**
 * This component is the list of all attacks shown when user visits endpoint /brm/main/AttackModel/Attack
 */
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";

import PropTypes from "prop-types";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import useRefreshTableList from "hooks/useRefreshTableList";
// import { riskUpdatedState } from "atoms/atoms-risk";
import { TYPE } from "constants/brm";
import {
  createColumnName,
  createColumnMapped,
  createColumnLikelihoodMapped,
  createColumnAttackMechanism,
} from "brm/tables/services/column/columnFactory";
import * as AttackApi from "brm/risk-model/attack/api";
import { LoadingSpinner } from "components/elements";
import { AttackMechanismNoteModal } from "brm/risk-model/attack/modals/AttackMechanismNoteModal";
import { useRoles } from "features/brm";

const AttackTable = ({ setSelectedRows, setRefresh, setExportTableData }) => {
  const { isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  // const riskUpdated = useRecoilValue(riskUpdatedState);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [mechanism, setMechanism] = useState("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data: attackData, isError: isAttackDataError } = AttackApi.useAttacks(projectId, variantId, true, {
    enabled: isRiskAnalyst,
  });
  const { data: mechanismNotes, isError: isMechanismNoteError } = AttackApi.useAttackMechanismNote({
    mechanism,
    config: { enabled: !!mechanism && isRiskAnalyst },
  });

  const columns = useMemo(() => {
    return [
      createColumnName(selectedInstance.id, setSelectedInstance, false, null, "attack"),
      createColumnLikelihoodMapped("likelihood"),
      createColumnLikelihoodMapped("mitigatedlikelihood"),
      createColumnMapped("target", selectedInstance.id, null, false, null),
      createColumnMapped("systemasset", selectedInstance.id, null, false, null),
      createColumnMapped("trevpattern", selectedInstance.id, null, false, null),
      createColumnMapped("attackercategory", selectedInstance.id, null, false, null),
      createColumnMapped("kind", selectedInstance.id, null, false, null),
      createColumnMapped("tactic", selectedInstance.id, null, false, null),
      createColumnAttackMechanism(setMechanism, setModalIsOpen),
    ];
  }, [setSelectedInstance, selectedInstance.id]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  if (isAttackDataError) {
    return <div>Error While loading {TYPE.attack} data</div>;
  }

  if (isMechanismNoteError) {
    return toast.error(<span>Error while loading attack mechanism note</span>, {
      duration: 4000,
    });
  }

  if (attackData) {
    return (
      <CustomTable id="AttackTable">
        {/* {mechanismNote &&
          modalIsOpen &&
          systemModals.customModal(
            "AttackTable",
            modalIsOpen,
            setModalIsOpen,
            <div>{mechanismNote}</div>,
            `${mechanism}`
          )} */}
        <AttackMechanismNoteModal
          isVisible={modalIsOpen}
          title={mechanism}
          mechanisms={mechanismNotes}
          onClosing={() => setModalIsOpen(false)}
        />

        <BrmMainTable
          data={attackData}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "AttackTable_table" }}
          showRowSelect={false}
          elementName={TYPE.attack}
          setExportTableData={setExportTableData}
        />
      </CustomTable>
    );
  }

  return <LoadingSpinner />;
};

AttackTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  setExportTableData: PropTypes.func,
};

export default AttackTable;
