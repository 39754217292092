import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import * as Brm from "services/brm";
import * as SapProgramKeyFactory from "features/brm/api/admin/sapProgram/sap-program-query-key-factory";
import * as SapBindingKeyFactory from "features/brm/api/admin/sapBinding/sap-binding-query-key-factory";

interface CreateSapProjBindingProps {
  sapProjBindingCreateDto: any;
}

/**
 * Create a sap proj binding
 *
 * @returns
 */
export const createSapProjBinding = ({ sapProjBindingCreateDto }: CreateSapProjBindingProps) => {
  return sapProjBindingCreateDto === null
    ? Promise.reject(new Error("Invalid createbinding dto"))
    : Brm.sapProjBindingApi.createSapProjBinding({ sapProjBindingCreateDto });
};

type MutateFunction = typeof createSapProjBinding;
interface ICreateSapOrgBindingProps {
  options?: MutationOption<MutateFunction>;
}

/**
 * Create a sap proj binding
 *
 * @returns react-query Mutation.
 */
export const useCreateSapProjBinding = ({ options = {} }: ICreateSapOrgBindingProps = {}) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation({
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: SapProgramKeyFactory.sapProgramKeys.all });
      queryClient.invalidateQueries({ queryKey: SapBindingKeyFactory.sapBindingKeys.allProject });
    },
    mutationFn: createSapProjBinding,
  });
};
