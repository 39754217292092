import { useEffect, useState, useMemo } from "react";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";

import { createColumnNameNoNav } from "brm/tables/services/column/columnFactory";
import { SystemApi, useRoles } from "features/brm";

const StructuralCategoryTable = ({ setSelectedRows, setRefresh }) => {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);

  const [namedStructCats, setNamedStructCats] = useState([]);
  const [selectedInstance] = useRecoilState(selectedInstanceState);

  // const { data: structuralCats } = SystemApi.useStructuralCategories({ projectId });
  const { data: structuralCats } = SystemApi.useGetNodeStructuralCategories({
    projectId,
    options: { enabled: isSystemEngineer || isRiskAnalyst },
  });

  useEffect(() => {
    if (structuralCats) {
      setNamedStructCats(structuralCats.map((e) => ({ name: e })));
    }
  }, [structuralCats]);

  const columns = useMemo(
    () => [
      // TODO Do we have a details page for categories?
      // !isCyberSecurityExpert
      //   ? createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "structuralCategory")
      //   : createColumnNameNoNav(selectedInstance.id, editMode, updatedName),
      createColumnNameNoNav(selectedInstance.id, false),
    ],
    [selectedInstance.id]
  );

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  return (
    <>
      <CustomTable>
        <BrmMainTable
          data={namedStructCats}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ od: "StructuralCategoryTable_table" }}
          elementName={TYPE.structuralCat}
          showRowSelect={false}
        />
      </CustomTable>
    </>
  );
};

StructuralCategoryTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default StructuralCategoryTable;
