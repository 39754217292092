import PropTypes from "prop-types";
import * as Recoil from "recoil";
import toast from "react-hot-toast";
import { queryClient } from "libs/react-query";
import * as BrmGql from "generated/graphql";

// Atoms
import { projectIdState } from "atoms/atoms-admin";

// Queries
import { MitigationApi, useVariant } from "features/brm";

// Components
import systemModals from "components/modals/Modals";

// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";

export const ClearCalibratedStrengthModal = ({ modalIsOpen, setModalIsOpen, selectedRows }) => {
  const NOVALUE = "-";
  const projectId = Recoil.useRecoilValue(projectIdState);
  const { variantId } = useVariant();

  const { mutate: setAllAllocatedControlsCalibratedStrength } =
    BrmGql.useSetAllAllocatedControlsCalibratedStrengthMutation({
      onSettled: () => {
        queryClient.invalidateQueries(MitigationApi.allocatedControlsGqlKey.all);
      },
      onError: () => {
        toast.error("Error ocurred upon setting calibrated strength", {
          duration: 4000,
        });
        setModalIsOpen(false);
      },
    });

  function clearLevelClick() {
    const ids = selectedRows.map((row) => row.id);
    setAllAllocatedControlsCalibratedStrength(
      { ids, calibratedStrength: NOVALUE, project: projectId, variant: variantId },
      {
        onSuccess: () => {
          toast.success("Calibrated Strength cleared successfully", { duration: 3000 });
          setModalIsOpen(false);
        },
        enabled: !!projectId && !!ids,
      }
    );
  }

  const handleCancel = () => {
    setModalIsOpen(false);
  };

  const body = (
    <>
      <p>Are you sure you want to clear the calibrated strength values of the selected allocated control(s)?</p>

      <DialogButtonDivStyled>
        <DialogButtonStyled id="clearCalibratedStrengthModal_cancelButton" onClick={handleCancel}>
          Cancel
        </DialogButtonStyled>
        <DialogButtonStyled id="clearCalibratedStrengthModal_confirmButton" onClick={() => clearLevelClick()}>
          Confirm
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </>
  );

  return (
    <div id="clearCalibratedStrengthModal_modal">
      {systemModals.customModal(
        "clearCalibratedStrengthModal_modal",
        modalIsOpen,
        setModalIsOpen,
        body,
        "Clear Calibrated Strength Level"
      )}
    </div>
  );
};

ClearCalibratedStrengthModal.propTypes = {
  modalIsOpen: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
  setModalIsOpen: PropTypes.func,
};
