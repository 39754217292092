import { useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import {
  createColumnMappedNoEdit,
  createColumnBooleanNoEditMapped,
  createColumnNameDetails,
} from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { LoadingSpinner, ErrorBanner } from "components/elements";
import { RoutePath } from "routes/route-paths";
import { SystemApi } from "features/brm";

interface IConsumingActivityProps {
  selectedElement: {
    id: string;
  };
  tableTitle: string;
}

export const ConsumingActivityTable = ({ selectedElement, tableTitle }: IConsumingActivityProps) => {
  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Activity.replace(":id", selectedElement.id)),
      createColumnMappedNoEdit("category"),
      createColumnBooleanNoEditMapped("isInternal"),
      createColumnBooleanNoEditMapped("isBoundary"),
      createColumnBooleanNoEditMapped("isInitial"),
      createColumnBooleanNoEditMapped("isFinal"),
      createColumnMappedNoEdit("node"),
    ],
    [selectedElement.id]
  );

  const { data: consumingActivityData, isError: isConsumingActivityError } = SystemApi.useExchangeConsumingActivity({
    exchangeId: selectedElement.id,
  });

  if (isConsumingActivityError) {
    return <ErrorBanner msg="Error while loading Consuming Activity" />;
  }

  if (consumingActivityData) {
    return (
      <S.DetailsContainer id="ProducingActivityTable_detailsPanel">
        <BrmDetailTable
          data={consumingActivityData}
          columns={columns}
          customProps={{ id: "ProducingActivityTable_detailsTable" }}
          showRowSelect={false}
          tableTitle={tableTitle}
        />
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};
