import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * sets person of the selected attacker
 * @param {string} id - uuid of the attacker
 * @param {string} person - uuid of person
 *
 */
export const setAttackerPerson = ({ id, person }) => {
  return Brm.attackerApi.setAttackerPerson(id, { body: person });
};

/**
 * Custom hook to change the person of a existing Attacker
 * @param {object} config hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerPerson = ({ config = {} }) => {
  return ReactQuery.useMutation({
    ...config,
    mutationFn: setAttackerPerson,
  });
};
