import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./resource-query-key-factory";

export interface ISetResourceName {
  resourceId: string;
  name: string;
}

/**
 * Sets the name property for resource
 *
 * @param {string} resourceId - uuid of the resource
 * @param {string} name - name of resource
 */
export const setResourceName = ({ resourceId, name }: ISetResourceName) => {
  return Brm.resourceApi.setResourceName(resourceId, { body: name });
};

export interface IUseSetResourceName {
  options?: MutationOption<typeof setResourceName>;
}

/**
 * Custom hook to change the name property of an existing resource
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetResourceName = ({ options = {} }: IUseSetResourceName = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.resourceKeys.all);
    },
    ...options,
    mutationFn: setResourceName,
  });
};
