import * as React from "react";

// State
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";

// Components
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { AddButton, DeleteButton, AdminApi } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";

import ContentLayout from "brm/layout/Content/ContentLayout";

// Contants
import { TYPE, COMMON } from "constants/brm";

// Local
import { useRoles } from "features/brm/hooks/useRoles";
import { AddOrganizationForm } from "../../components/forms/AddOrganizationForm";
import { OrganizationTable } from "../../components/tables/OrganizationTable";

export const Organizations = () => {
  const { isSuperAdmin } = useRoles();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [selectedInstance, setSelectedInstance] = useRecoilState<any>(selectedInstanceState);

  const { mutateAsync: deleteOrganization } = AdminApi.useDeleteOrganization();

  const addOrganization = () => {
    setModalIsOpen(true);
  };

  React.useEffect(() => {
    async function multiDeleteApiCalls() {
      const promises = selectedRows.map((each: { id: string }) => {
        if (each.id !== COMMON.defaultUuid) {
          return deleteOrganization({ organizationId: each.id });
        }
        return null;
      });
      Promise.all(promises)
        .then(() => {
          setConfirmDelete(false);
          setDeleteModalIsOpen(false);
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteOrganization, selectedRows, setSelectedInstance]);

  const orgHeader = (
    <Title id="Organization_title">
      Organizations
      {isSuperAdmin ? (
        <Buttons id="Organization_buttonSection">
          {selectedRows.length === 0 || selectedInstance.id === COMMON.defaultUuid ? null : (
            <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />
          )}

          <AddButton onClick={addOrganization} />
        </Buttons>
      ) : null}
    </Title>
  );

  const orgMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "Organization_Page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.organization}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.organization
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "Organization_Page",
          modalIsOpen,
          setModalIsOpen,
          <AddOrganizationForm setModalIsOpen={setModalIsOpen} />,
          TYPE.organization
        )}

      <OrganizationTable onRowSelect={setSelectedRows} />
    </>
  );

  return <ContentLayout pageId="Organization_Page" header={orgHeader} main={orgMain} />;
};
