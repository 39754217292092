import styled from "styled-components/macro";
import { Breadcrumb as BsBreadcrumb } from "react-bootstrap";

// eslint-disable-next-line import/prefer-default-export
export const Breadcrumb = styled(BsBreadcrumb)`
  & ol {
    padding: 0.4rem 1.5rem;
    margin-bottom: 0rem;
    background-color: white;
    font-size: ${({ theme }) => theme.fonts.default};
  }
`;
