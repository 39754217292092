/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { useMemo, useEffect, useRef, forwardRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue, useRecoilState, useResetRecoilState } from "recoil";
import { useTable, useSortBy, useFilters, usePagination, useRowSelect } from "react-table";
import { userIdState, selectedInstanceState, filterSearchSubstringState, pageSizeState } from "atoms";
import { STATUS, COMMON } from "constants/brm";
import { Filter, DefaultColumnFilter } from "./BrmTableFilter";
import TablePaginationSmall from "./BrmTablePaginationSmall";
// Styles
import * as S from "./styles/tables.styles";

const BrmMainTable = ({
  data,
  columns,
  setSelectedRows,
  customProps = { id: "noIdTable_table" },
  showRowSelect = true,
  elementName = "",
  dataLength = 0,
  setExportTableData = () => {},
  hideCheckBoxCondition,
  isfocusBackgroundEnabled = false,
}) => {
  // Get Current User Id from recoil
  const userId = useRecoilValue(userIdState);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const [initialPageSize, setInitialPageSize] = useRecoilState(pageSizeState);

  // This is the initial sorting state, can add more id's for additional columns to sort by
  const initialState = {
    sortBy: [
      { id: "riskDistributionRank", desc: false },
      { id: "username" },
      { id: "rank", desc: false },
      { id: "rank.value" },
      { id: "name" },
    ],
    pageIndex: 0,
    pageSize: initialPageSize,
    hiddenColumns: ["selection"],
  };

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    []
  );

  const makeHeader = ({ getToggleAllRowsSelectedProps }) => (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    </div>
  );

  const makeCell = useCallback(
    ({ row }) => {
      const myRow = row;
      // check for if the cell id is a current user id or a default organization
      if (myRow.original.id !== userId && myRow.original.id !== COMMON.defaultUuid) {
        // check for if the status element in cell of each row
        if (myRow.original?.status && myRow.original.status === STATUS.deleted) {
          // if true, making the row non selectable
          myRow.isSelected = false;
          return null;
        }

        if (row.original?.isDefault === true) {
          myRow.isSelected = false;
          return null;
        }

        if (hideCheckBoxCondition && hideCheckBoxCondition(row)) {
          myRow.isSelected = false;
          return null;
        }

        return (
          <div>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <IndeterminateCheckbox {...myRow.getToggleRowSelectedProps()} />
          </div>
        );
      }
      myRow.isSelected = false;
      return null;
    },

    [userId, hideCheckBoxCondition]
  );

  const showSelectBox = useCallback(
    (hooks) => {
      hooks.visibleColumns.push((visColumns) => {
        return [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: makeHeader,
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: makeCell,
          },
          ...visColumns,
        ];
      });
    },
    [makeCell]
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  });
  IndeterminateCheckbox.displayName = "IndeterminateCheckbox";

  const handleRowSelect = (row) => {
    if (row.name) {
      setSelectedInstance({
        id: row.id,
        name: row.name,
        type: elementName,
      });
    } else if (row.username) {
      setSelectedInstance({
        id: row.id,
        name: row.username,
        type: elementName,
      });
    }
  };

  const location = useLocation();
  const resetFilterSearchSubstring = useResetRecoilState(filterSearchSubstringState);

  useEffect(() => {
    resetFilterSearchSubstring();
  }, [location, resetFilterSearchSubstring]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    preFilteredRows,
    // rows, -> we change 'rows' to 'page'
    rows,
    page,
    prepareRow,
    // below new props related to 'usePagination' hook
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    visibleColumns,
    allColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState, defaultColumn, filterTypes },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    showSelectBox
  );

  useEffect(() => {
    const exportTableData = {
      originalName: elementName,
      rawHeaders: headerGroups,
      rawRows: rows,
      rawPage: page,
      unfilteredRows: preFilteredRows,
    };
    setExportTableData(exportTableData);
  }, [page, rows, preFilteredRows, headerGroups, elementName, setExportTableData]);

  useEffect(() => {
    allColumns.filter((col) => col.id === "selection")[0].toggleHidden(!showRowSelect);
  }, [allColumns, showRowSelect]);

  useEffect(() => {
    let selectedRows = [];
    let filteredSelectedFlatRows = selectedFlatRows;
    if (hideCheckBoxCondition) {
      filteredSelectedFlatRows = selectedFlatRows.filter(
        (flatRow) => hideCheckBoxCondition && !hideCheckBoxCondition(flatRow)
      );
    }
    selectedRows = filteredSelectedFlatRows
      .filter((flatRow) => flatRow.original.status !== STATUS.deleted && flatRow.original.id !== userId)
      .map((r) => r.original);
    setSelectedRows(selectedRows);
  }, [selectedFlatRows, setSelectedRows, userId, hideCheckBoxCondition]);

  const columnSortingChecker = (column) => {
    if (
      (column.id === "riskDistributionRank" ||
        column.id === "riskDistributionPercent" ||
        column.id === "findingCount" ||
        column.id === "vulRank") &&
      column.isSorted &&
      !column.isSortedDesc
    ) {
      const rowEmptyValue = column.id === "riskDistributionPercent" ? COMMON.hyphen : 0;
      if (
        page.length &&
        page[0].values[column.id] === rowEmptyValue &&
        page[page.length - 1].values[column.id] !== rowEmptyValue
      ) {
        do {
          page.push(page.shift());
        } while (page[0].values[column.id] === rowEmptyValue);
      }
    }
  };

  return (
    <S.MainTableContainer>
      <S.TableHeaderWrap>
        <S.RowCountTextSpan>
          Showing
          <S.RowCountSpan>{rows.length}</S.RowCountSpan>
          items of <S.RowCountSpan>{dataLength || data.length}</S.RowCountSpan>
        </S.RowCountTextSpan>
      </S.TableHeaderWrap>
      <S.ContentWrapper>
        <S.OverflowContainer>
          <S.ZeroHeightDiv>
            <S.TableDiv isfocusBackgroundEnabled={isfocusBackgroundEnabled}>
              <S.TableWrap>
                <S.Table {...getTableProps(customProps)}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            <div {...column.getSortByToggleProps()}>
                              {column.render("Header")}
                              {columnSortingChecker(column)}
                            </div>
                            <Filter column={column} />
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {(page.length > 0 &&
                      page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            onClick={() => handleRowSelect(row.original)}
                            style={row.isSelected ? { backgroundColor: "#D1EAFE" } : { backgroundColor: "#FFFFFF" }}
                          >
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            ))}
                          </tr>
                        );
                      })) || (
                      <tr>
                        <td className="noDataFound" colSpan={visibleColumns.length}>
                          No data found...
                        </td>
                      </tr>
                    )}
                  </tbody>
                </S.Table>
              </S.TableWrap>
            </S.TableDiv>
          </S.ZeroHeightDiv>
        </S.OverflowContainer>
      </S.ContentWrapper>
      <S.TableFooterWrap>
        <TablePaginationSmall
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          setInitialPageSize={setInitialPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
        />
      </S.TableFooterWrap>
    </S.MainTableContainer>
  );
};

BrmMainTable.propTypes = {
  row: PropTypes.any,
  columns: PropTypes.any,
  customProps: PropTypes.shape({
    id: PropTypes.string,
  }),
  data: PropTypes.array,
  elementName: PropTypes.string,
  setSelectedRows: PropTypes.func,
  showRowSelect: PropTypes.bool,
  indeterminate: PropTypes.any,
  getToggleAllRowsSelectedProps: PropTypes.func,
  dataLength: PropTypes.number,
  setExportTableData: PropTypes.func,
  hideCheckBoxCondition: PropTypes.func,
  isfocusBackgroundEnabled: PropTypes.bool,
};

export default BrmMainTable;
