import { ChevronDown, ChevronRight } from "@styled-icons/heroicons-solid";
import styled from "styled-components";

export const DisplayVul = styled.div`
  margin: 5px;
  max-height: 450px;
  overflow: auto;
`;

export const VulListDiv = styled.div`
  padding-bottom: 5px;
`;

export const AdditionalVulSpan = styled.div`
  padding-top: 5px;
`;

export const VulColumns = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;
  grid-gap: 1px;
`;

export const Button = styled.button`
  background: none;
  border: none;
  width: auto;
  outline: none;
  font-family: ${({ theme }) => theme.fonts.primary};
  /* margin: 2px 0px; */
  &:focus {
    outline: none;
  }
`;

export const ButtonsSection = styled.div`
  justify-content: center;
`;

export const VulSelection = styled.div`
  height: 150px;
  overflow: auto;
`;

export const Title = styled.h6`
  text-align: left;
  margin-bottom: 5px;
  font-weight: 600;
`;

export const List = styled.div`
  max-height: 300px;
  overflow: auto;
  border: 1px #cccccc solid;
  border-radius: 3px;
  padding-left: 5px;
  padding-top: 5px;
`;

export const AdditonalVulChevronRight = styled(ChevronRight)`
  color: ${({ theme }) => theme.colors.buttonPrimary};
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export const AdditonalVulChevronDown = styled(ChevronDown)`
  color: ${({ theme }) => theme.colors.buttonPrimary};
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;
