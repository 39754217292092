import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import ClassificationCategoryTable from "./tables/ClassificationCategoryTable";

function ClassificationCategory() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const classificationCatHeader = <Title id="ClassificationCategoryList_title">Classification Categories</Title>;

  const classificationCatMain = (
    <ClassificationCategoryTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
  );

  return (
    <ContentLayout
      pageId="ClassificationCategoryList_panel"
      header={classificationCatHeader}
      main={classificationCatMain}
    />
  );
}

export default ClassificationCategory;
