import PropTypes from "prop-types";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";

const GenericDelete = ({ elementName, setDeleteModalIsOpen, selectedRows, setConfirmDelete }) => {
  let predefinedItem = "";

  const checkIsPredefined = () => {
    let result = false;
    selectedRows.forEach(async (rowToBeDeleted) => {
      if (rowToBeDeleted.item) {
        if (rowToBeDeleted.item.isPredefined === true) {
          predefinedItem += `${rowToBeDeleted.item.name}, `;
          result = true;
        } else {
          result = false;
        }
      } else if (rowToBeDeleted.isPredefined === true) {
        predefinedItem += `${rowToBeDeleted.name}, `;
        result = true;
      } else {
        result = false;
      }
    });
    predefinedItem = predefinedItem.substring(0, predefinedItem.length - 2);

    return result;
  };

  const deleteSelected = async () => {
    setConfirmDelete(true);
  };

  const handleCancel = () => {
    setDeleteModalIsOpen(false);
  };

  return (
    <div>
      {checkIsPredefined() ? (
        <>
          <div>Cannot delete predefined element(s): {predefinedItem}.</div>
          <DialogButtonDivStyled>
            <DialogButtonStyled id="GenericDeleteConfirmation_cancelButton" onClick={handleCancel}>
              Cancel
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </>
      ) : (
        <>
          <div>Are you sure you want to delete the selected {elementName}(s)?</div>

          <DialogButtonDivStyled>
            <DialogButtonStyled id="GenericDeleteConfirmation_cancelButton" onClick={handleCancel}>
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled id="GenericDeleteConfirmation_confirmButton" onClick={() => deleteSelected()}>
              Confirm
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </>
      )}
    </div>
  );
};

GenericDelete.propTypes = {
  elementName: PropTypes.string,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      forEach: PropTypes.func,
    })
  ),

  setConfirmDelete: PropTypes.func,
  setDeleteModalIsOpen: PropTypes.func,
};

export default GenericDelete;
