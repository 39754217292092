import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import { userRoles } from "services/brm/admin-service";
import * as AttackerCategoryApi from "brm/threat-model/api";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";
import PropTypes from "prop-types";
import { useRoles, useProject } from "features/brm";

import { createColumnNameNoNav } from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";

const AttackerCategoryTable = ({ setSelectedRows, setRefresh }) => {
  const [, { projectId }] = useProject();
  const { userRole, isThreatAnalyst, isRiskAnalyst } = useRoles();
  const [selectedInstance] = useRecoilState(selectedInstanceState);

  const cyberSecurityExpertRole = userRoles.properties[userRoles.CYBERSECURITY_EXPERT].key;
  const [attackerCatData, setAttackerCatData] = useState([]);
  const updatedName = useRef("");

  const { data, error } = AttackerCategoryApi.useAttackerCategories({
    projectId,
    config: { enabled: !!projectId && (isThreatAnalyst || isRiskAnalyst) },
  });

  const getNameCell = useCallback(() => {
    return createColumnNameNoNav(selectedInstance.id, false, updatedName);
  }, [selectedInstance.id]);

  const columns = useMemo(() => [getNameCell()], [getNameCell]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    if (data) {
      setAttackerCatData(data.map((e) => ({ name: e })));
    }
  }, [data]);

  if (error) {
    return <ErrorBanner msg="Error while loading attacker categories" />;
  }

  if (data) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={attackerCatData}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "AttackerCategoryTable_table" }}
            showRowSelect={userRole === cyberSecurityExpertRole}
            elementName={TYPE.attackerCat}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

AttackerCategoryTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default AttackerCategoryTable;
