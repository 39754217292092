import toast from "react-hot-toast";
// Constants
import { TYPE } from "constants/brm";

// Components

import systemModals, { WizardModal } from "components/modals/Modals";
import Title from "components/elements/StyledTitle";
import { LoadingSpinner as Loading, ErrorBanner, InfoBanner, LicenseAlert } from "components/elements";
import { RestoreFromFileModal } from "features/importer/components/RestoreFromFileModal";
import ShowHideButton from "components/elements/ShowHideButton";
import { BrmSingleElementTable } from "brm/tables/BrmTables";

// Hooks
import { useRoles } from "features/brm/hooks/useRoles";
import { Wizard, RestoreSavePointModal } from "features/importer";
import { ExportAarModal, ExportAuditModal } from "features/exporter";
import { ImportContext, LICENSE_VALIDATION_ERROR_STATUS } from "features/brm";
import { ActionButton } from "components/elements/ActionButton";
import * as ModalStyles from "components/modals/Modal.styles";

// Styles
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
// import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
// import DialogButtonStyled from "components/elements/DialogButtonStyled";

import { useParams } from "react-router-dom";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { UploadHistoryTable } from "../../components/tables/UploadHistoryTable";
import { ProjectDetailsTab } from "../../components/tabs/ProjectsDetailsTab";
import { useProjectOverview } from "./useProjectOverview";

const FORBIDDEN_STATUS = 403;

export const ProjectOverview = () => {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const { id } = useParams();

  const {
    isGetProjectDataError,
    isSystemError,
    systemError,
    isPublishSystemProjectError,
    projectDataError,
    columns,
    isRestoreFromFileModalVisible,
    handleRestoreFromFileModalClose,
    showRestoreSavePointModal,
    restoreSavePoint,
    handleRestoreSavePointModalClose,
    uploadModalIsOpen,
    setUploadModalIsOpen,
    uploadContext,
    licenseCheckIsOpen,
    setLicenseCheckIsOpen,
    userAlertIsOpen,
    setUserAlertIsOpen,
    isAuditModalOpen,
    setAuditModalOpen,
    handleAuditReportDownload,
    projectData,
    showSummaryTable,
    setShowSummaryTable,
    // projectId,
    selectedElement,
    setSelectedElement,
    setSelectedRowId,
    statusError,
    isExportAarModalVisible,
    setIsExportAarModalVisible,
    isProjectAccessError,
    projectAccessError,
    isExchangeError,
    exchangeError,
  } = useProjectOverview(id);

  const ImportConfirmation = () => {
    const isTRA = uploadContext.context === ImportContext.tra;
    return (
      <div>
        {isTRA ? (
          <p>This template is already available. Proceed with import?</p>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <p>
              This Project already contains imported data. Importing another file will overwrite the existing data in
              this project.
            </p>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
              <AiOutlineExclamationCircle size="30px" fill="#6c757d" />
              <strong>We recommend not to import a different system as it will cause data inconsistencies.</strong>
            </div>
            <p>Proceed with import?</p>
          </div>
        )}
        <br />
        <ModalStyles.Footer>
          <ActionButton onClick={() => setUserAlertIsOpen(false)}>Cancel</ActionButton>
          <ActionButton
            onClick={() => {
              setUserAlertIsOpen(false);
              setUploadModalIsOpen(true);
            }}
          >
            Confirm
          </ActionButton>
        </ModalStyles.Footer>

        {/* <DialogButtonStyled
            onClick={() => {
              setUserAlertIsOpen(false);
              setUploadModalIsOpen(true);
            }}
          >
          Confirm
          </DialogButtonStyled>
        <DialogButtonStyled onClick={() => setUserAlertIsOpen(false)}>Cancel</DialogButtonStyled> */}
        {/* <DialogButtonDivStyled>
        </DialogButtonDivStyled> */}
      </div>
    );
  };

  if (isGetProjectDataError) {
    if (projectDataError?.status === LICENSE_VALIDATION_ERROR_STATUS) {
      return <LicenseAlert />;
    }
    return <ErrorBanner msg="Error in getting Project full details" />;
  }

  if (isSystemError && isSystemEngineer) {
    if (systemError.status === FORBIDDEN_STATUS) {
      return <InfoBanner header="Unauthorized" msg="Not authorized to view this project" />;
    }
    return <ErrorBanner msg="Error occured while getting System information" />;
  }

  if (isExchangeError && isSystemEngineer) {
    if (exchangeError.status === FORBIDDEN_STATUS) {
      return <InfoBanner header="Unauthorized" msg="Not authorized to view this project" />;
    }
    return <ErrorBanner msg="Error occured while getting project exchanges" />;
  }

  if (isProjectAccessError) {
    if (projectAccessError.status === FORBIDDEN_STATUS) {
      return <InfoBanner header="Unauthorized" msg="Not authorized to view this project" />;
    }
    return <ErrorBanner msg="Error occured while getting project access information" />;
  }

  if (isPublishSystemProjectError) {
    return <ErrorBanner msg="Error occured updating project status" />;
  }

  if (statusError) {
    toast.error(statusError);
  }

  return (
    <>
      <RestoreFromFileModal
        projectId={id}
        show={isRestoreFromFileModalVisible}
        onHide={handleRestoreFromFileModalClose}
      />
      <RestoreSavePointModal
        show={showRestoreSavePointModal}
        project={selectedElement}
        onRestore={restoreSavePoint}
        onHide={handleRestoreSavePointModalClose}
      />
      <ExportAarModal isVisible={isExportAarModalVisible} onClosing={setIsExportAarModalVisible} />

      {uploadModalIsOpen && (
        <WizardModal
          id="ProjectOverviewTable_page"
          modalIsOpen={uploadModalIsOpen}
          content={
            <Wizard setUploadModalIsOpen={setUploadModalIsOpen} projectId={id} context={uploadContext.context} />
          }
          elementName={uploadContext.name}
        />
      )}

      {licenseCheckIsOpen &&
        systemModals.customModal(
          "ProjectOverviewTable_page",
          licenseCheckIsOpen,
          setLicenseCheckIsOpen,
          <div>Checking license validity...</div>,
          "License check"
        )}

      {userAlertIsOpen &&
        systemModals.customModal(
          "ProjectOverviewTable_page",
          userAlertIsOpen,
          setUserAlertIsOpen,
          <ImportConfirmation />,
          "Warning"
        )}

      <ExportAuditModal
        modalName="AuditEditReport"
        elementName={TYPE.project}
        onReportExport={handleAuditReportDownload}
        isVisible={isAuditModalOpen}
        toggle={setAuditModalOpen}
      />

      {projectData ? (
        <>
          <Title id="ProjectOverviewTable_title">
            {projectData.name}
            <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
          </Title>

          {showSummaryTable ? (
            <DetailsContainerSingleTable>
              <BrmSingleElementTable
                data={[projectData]}
                columns={columns}
                setSelectedElement={setSelectedElement}
                setSelectedRowId={setSelectedRowId}
                customProps={{ id: "ProjectOverviewTable_table" }}
                elementName={TYPE.project}
              />
              <br />
              {(isSystemEngineer || isRiskAnalyst) /*  && projectId === id */ && (
                <UploadHistoryTable
                  uploadModalIsOpen={uploadModalIsOpen}
                  context={isSystemEngineer ? ImportContext.system : ImportContext.bor}
                />
              )}
            </DetailsContainerSingleTable>
          ) : null}

          <DetailsContainerParent showSummaryTable={showSummaryTable}>
            <DetailsContainer id="Project_detailsPanel">
              <ProjectDetailsTab project={projectData} organizationId={projectData.organization} />
            </DetailsContainer>
          </DetailsContainerParent>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
