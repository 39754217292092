import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./variant-query-key-factory";

interface IGetVariantComplianceProps {
  variantId: string;
}

/**
 *
 * @param {string} variantId - uuid of the variant
 */
export const getVariantCompliance = async ({ variantId }: IGetVariantComplianceProps): Promise<any> => {
  return Brm.variantApi.getVariantIsCompliance(variantId);
};

interface IUseVariantComplianceProps {
  variantId: string;
  options?: QueryOption<typeof getVariantCompliance>;
}

/**
 *
 * @param {string} variantId - uuid of the variant
 * @param {object} options - react-query configuration object
 */
export const useVariantCompliance = ({ variantId, options = {} }: IUseVariantComplianceProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.variantsKeys.all,
    queryFn: () => getVariantCompliance({ variantId }),
  });
};
