import styled from "styled-components";

const InLineEditButtonStyled = styled.button`
  border: 1px solid #d7d7d7;
  height: 28px;
  border-radius: 2px;
  float: left;
  background-color: transparent;
  margin-right: 4px;

  &:disabled {
    opacity: 50%;
  }
`;

export default InLineEditButtonStyled;
