import { useState, useMemo, useRef, useCallback } from "react";

import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";
import { ThreatApi, useRoles, useProject } from "features/brm";

import {
  createColumnName,
  createColumnCategoryMapped,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import ButtonCell from "components/EditComponents/ButtonCell";

const AttackerTable = ({ setSelectedRows, setRefresh }) => {
  const [, { projectId }] = useProject();
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedCategory = useRef("");
  const { isRiskAnalyst, isThreatAnalyst } = useRoles();
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data, error } = ThreatApi.useAttackers({
    projectId,
    options: { enabled: (!!projectId && isThreatAnalyst) || isRiskAnalyst },
  });
  const { data: categoryList, isError: isCategoryListError } = ThreatApi.useAttackerCategories({
    projectId,
    options: { enabled: !!projectId && isThreatAnalyst },
  });
  const { mutate: setAttackerName } = ThreatApi.useSetAttackerName({
    options: {
      onSettled: () => {
        updatedName.current = "";
      },
    },
  });

  const { mutate: setAttackerCategory } = ThreatApi.useSetAttackerCategory({
    options: {
      onSettled: () => {
        updatedCategory.current = "";
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);
            if (updatedName.current !== "") {
              setAttackerName({ attackerId: selectedInstance.id, name: updatedName.current });
            }

            if (updatedCategory.current !== "") {
              setAttackerCategory({ attackerId: selectedInstance.id, category: updatedCategory.current });
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [editMode, selectedInstance.id, setAttackerCategory, setAttackerName]
  );

  const columns = useMemo(() => {
    if (isRiskAnalyst) {
      return [
        createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "attacker"),
        createColumnCategoryMapped("category", selectedInstance.id, categoryList, editMode, updatedCategory),
      ];
    }
    return [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "attacker"),
      createColumnCategoryMapped("category", selectedInstance.id, categoryList, editMode, updatedCategory),
      createColumnActions(createButton, { disableFilters: true }),
    ];
  }, [selectedInstance.id, setSelectedInstance, editMode, categoryList, createButton, isRiskAnalyst]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  if (error || isCategoryListError) {
    return <ErrorBanner msg="Error while loading attackers" />;
  }

  if (data) {
    const showRowSelect = !isRiskAnalyst;
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={data}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "AttackerTable_table" }}
            elementName={TYPE.attacker}
            showRowSelect={showRowSelect}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

AttackerTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default AttackerTable;
