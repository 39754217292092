import {
  SystemElementApi,
  StructuralCategoryApi,
  PersonApi,
  NodeApi,
  NodeCreateDto,
  NodeShortDto,
  MissionApi,
  LinkApi,
  ExchangeApi,
  ExchangeCreateDto,
  ExchangeShortDto,
  ElementCategoryApi,
  DataTypeApi,
  DataTypeCreateDto,
  DataFlowApi,
  CapabilityApi,
  BusApi,
  ActivityApi,
  ResourceApi,
  AuditApi,
} from "@kdmanalytics/brm-system";

import BrmApiClient from "services/brm/BrmApiClient";

export const systemElementApi = new SystemElementApi(BrmApiClient.instance);
export const structCatApi = new StructuralCategoryApi(BrmApiClient.instance);
export const personApi = new PersonApi(BrmApiClient.instance);
export const nodeApi = new NodeApi(BrmApiClient.instance);
export const missionApi = new MissionApi(BrmApiClient.instance);
export const linkApi = new LinkApi(BrmApiClient.instance);
export const exchangeApi = new ExchangeApi(BrmApiClient.instance);
export const elementCategoryApi = new ElementCategoryApi(BrmApiClient.instance);
export const dataTypeApi = new DataTypeApi(BrmApiClient.instance);
export const dataflowApi = new DataFlowApi(BrmApiClient.instance);
export const capabilityApi = new CapabilityApi(BrmApiClient.instance);
export const busApi = new BusApi(BrmApiClient.instance);
export const activityApi = new ActivityApi(BrmApiClient.instance);
export const resourceApi = new ResourceApi(BrmApiClient.instance);
export const systemAuditApi = new AuditApi(BrmApiClient.instance);

const findAllSubjects = async (projectId) => {
  const promNodes = nodeApi.findNodeWith(projectId);
  const promLinks = linkApi.findLinkWith(projectId);
  const promBuses = busApi.findBusWith(projectId);
  const promActivities = activityApi.findActivityWith(projectId);
  const promCapabilities = capabilityApi.findCapabilityWith(projectId);
  const promDataTypes = dataTypeApi.findDataTypeWith(projectId);
  const promDataFlows = dataflowApi.findDataFlowWith(projectId);
  const promExchanges = exchangeApi.findExchangeWith(projectId);

  const promGetSubjects = Promise.all([
    promNodes,
    promBuses,
    promLinks,
    promActivities,
    promCapabilities,
    promDataTypes,
    promDataFlows,
    promExchanges,
  ]);
  const subjects = await promGetSubjects.catch((error) => {
    console.error("Error while loading subjects", error);
    return Promise.resolve([]);
  });
  return subjects;
};

const getExchangeShortDto = async (id) => {
  const exDto = await exchangeApi
    .getExchangeByIDWithHttpInfo(id)
    .then((info) => info.data)
    .catch((err) => {
      console.error(err.error);
    });

  const o = {
    id: exDto.id,
    name: exDto.name,
    data: exDto.data,
    producer: exDto.producer,
    consumer: exDto.consumer,
    category: { id: exDto.category, name: "" },
    isInternal: exDto.isInternal,
    isBoundary: exDto.isBoundary,
    isInput: exDto.isInput,
  };
  return ExchangeShortDto.constructFromObject(o);
};

/**
 * Create an exchange using sane defaults.
 *
 * @param {String} projectId UUID of project
 * @param {String} fromName Name of producer
 * @param {String} fromId UUID of producer
 * @param {String} toName Name of consumer
 * @param {String} toId UUID of consumer
 * @param {String} dataType UUID of datatype of the exchange
 */
const createDefaultExchange = async (projectId, fromName, fromId, toName, toId, dataType) => {
  const params = {
    name: `Exchange from ${fromName} to ${toName}`,
    note: "",
    category: "Basic",
    isBoundary: false,
    isInternal: false,
    isInput: false,
    data: dataType,
    consumer: toId,
    producer: fromId,
  };
  // let dto = ExchangeCreateDto.constructFromObject(params);
  const opts = { exchangeCreateDto: ExchangeCreateDto.constructFromObject(params) };
  const exId = await exchangeApi.createExchangeWithHttpInfo(projectId, opts).then(
    (info) => info.data,
    (error) => {
      console.error(error);
    }
  );
  const dto = await getExchangeShortDto(exId);
  return { dto };
};

/**
 * Create a ShortNodeDto from a node
 * @param {String} id UUID of a node
 */
const getShortNodeDto = async (id) => {
  const nodeDto = await nodeApi
    .getNodeByIDWithHttpInfo(id)
    .then((info) => info.data)
    .catch((err) => {
      console.error(err.error);
    });

  const o = {
    id: nodeDto.id,
    name: nodeDto.name,
    structuralcat: { id: nodeDto.structuralcat, name: "" },
    category: { id: nodeDto.category, name: "" },
    isInternal: nodeDto.isInternal,
    isBoundary: nodeDto.isBoundary,
  };
  return NodeShortDto.constructFromObject(o);
};

const getSystemElementsByCategory = async (project, cat) => {
  const opts = { cat };
  return systemElementApi.findSystemElementWithHttpInfo(project, opts);
};

const getNodesByStructuralCategory = async (project, structuralcat) => {
  const opts = { structuralcat };
  return nodeApi.findNodeWithHttpInfo(project, opts);
};

const createElementCategory = async (project, createElCatDto) => {
  // await updateToken(auth);
  const opts = { elementCategoryCreateDto: createElCatDto };
  return elementCategoryApi.createElementCategoryWithHttpInfo(project, opts);
};

// const getElementCategories = async (project) => {
//   await updateToken(auth);
//   return elementCategoryApi.findElementCategoryWithHttpInfo(project);
// };

const createStructuralCategory = async (project, createStructCatDto) => {
  const opts = { structuralCategoryCreateDto: createStructCatDto };
  return structCatApi.createProjectWithHttpInfo(project, opts);
};

const createMission = async (project, missionCreateDto) => {
  const opts = { missionCreateDto };
  return missionApi.createMissionWithHttpInfo(project, opts);
};

const addMissionCapability = async (missionId, capabilityId) => {
  // await updateToken(auth);
  const opts = { body: capabilityId };
  return missionApi.addMissionCapabilityWithHttpInfo(missionId, opts);
};

const createPerson = async (project, personCreateDto) => {
  // await updateToken(auth);
  const opts = { personCreateDto };
  return personApi.createPersonWithHttpInfo(project, opts);
};

const createCapability = async (project, capabilityCreateDto) => {
  // await updateToken(auth);
  const opts = { capabilityCreateDto };
  return capabilityApi.createCapabilityWithHttpInfo(project, opts);
};

const createActivity = async (project, activityCreateDto) => {
  const opts = { activityCreateDto };
  return activityApi.createActivityWithHttpInfo(project, opts);
};

const createDataType = async (project, datatypeCreateDto) => {
  const opts = { dataTypeCreateDto: datatypeCreateDto };
  return dataTypeApi.createDataTypeWithHttpInfo(project, opts);
};

const createDataTypeWithParam = async (project, params) => {
  const dto = DataTypeCreateDto.constructFromObject(params);
  return createDataType(project, dto);
};

const createDataflow = async (project, dataFlowCreateDto) => {
  const opts = { dataFlowCreateDto };
  return dataflowApi.createDataFlowWithHttpInfo(project, opts);
};

const createBus = async (projId, busCreateDto) => {
  const opts = { busCreateDto };
  return busApi.createBusWithHttpInfo(projId, opts);
};

const createLink = async (projId, linkCreateDto) => {
  const opts = { linkCreateDto };
  return linkApi.createLinkWithHttpInfo(projId, opts);
};

const createNode = async (projId, nodeCreateDto) => {
  const opts = { nodeCreateDto };
  return nodeApi.createNodeWithHttpInfo(projId, opts);
};

const createNodeWithParam = async (projId, params) => {
  const nodeCreateDto = NodeCreateDto.constructFromObject(params);
  return createNode(projId, nodeCreateDto);
};

const createExchange = async (projId, exchangeCreateDto) => {
  const opts = { exchangeCreateDto };
  return exchangeApi.createExchangeWithHttpInfo(projId, opts);
};

const createExchangeWithParam = async (projId, params) => {
  const exchangeCreateDto = ExchangeCreateDto.constructFromObject(params);
  return createExchange(projId, exchangeCreateDto);
};

const addNodeOutBus = async (nodeId, busId) => {
  const opts = { body: busId };
  return nodeApi.addNodeOutBusWithHttpInfo(nodeId, opts);
};

const addNodeInBus = async (nodeId, busId) => {
  const opts = { body: busId };
  return nodeApi.addNodeInBusWithHttpInfo(nodeId, opts);
};

const addNodeGroup = async (nodeId, groupId) => {
  const opts = { body: groupId };
  return nodeApi.addNodeGroupWithHttpInfo(nodeId, opts);
};

const addNodeStoredData = async (nodeId, dataId) => {
  const opts = { body: dataId };
  return nodeApi.addNodeStoredDataWithHttpInfo(nodeId, opts);
};

const addActivityStoredData = async (activityId, dataId) => {
  const opts = { body: dataId };
  return activityApi.addActivityStoredDataWithHttpInfo(activityId, opts);
};

const addLinkOwnedData = async (linkId, dataId) => {
  const opts = { body: dataId };
  return linkApi.addLinkOwnedDataWithHttpInfo(linkId, opts);
};

const addBusOwnedData = async (busId, dataId) => {
  const opts = { body: dataId };
  return busApi.addBusOwnedDataWithHttpInfo(busId, opts);
};

const addBusExchange = async (busId, exId) => {
  const opts = { body: exId };
  return busApi.addBusExchangeWithHttpInfo(busId, opts);
};

const addLinkExchange = async (linkId, exId) => {
  const opts = { body: exId };
  return linkApi.addLinkExchangeWithHttpInfo(linkId, opts);
};

const addCapabilityExchange = async (capId, exId) => {
  const opts = { body: exId };
  return capabilityApi.addCapabilityExchangeWithHttpInfo(capId, opts);
};

const addCapabilityNode = async (capId, nodeId) => {
  const opts = { body: nodeId };
  return capabilityApi.addCapabilityNodeWithHttpInfo(capId, opts);
};

const addPersonNode = async (personId, nodeId) => {
  const opts = { body: nodeId };
  return personApi.addPersonNodeWithHttpInfo(personId, opts);
};

const addPersonActivity = async (personId, actId) => {
  const opts = { body: actId };
  return personApi.addPersonActivityWithHttpInfo(personId, opts);
};

const addCapabilityActivity = async (capId, actId) => {
  const opts = { body: actId };
  return capabilityApi.addCapabilityActivityWithHttpInfo(capId, opts);
};

const addNodeActivity = async (nodeId, actId) => {
  const opts = { body: actId };
  return nodeApi.addNodeActivityWithHttpInfo(nodeId, opts);
};

const addDataflowActivity = async (dfId, actId) => {
  const opts = { body: actId };
  return dataflowApi.addDataFlowActivityWithHttpInfo(dfId, opts);
};

const addPersonChannel = async (personId, channelId) => {
  const opts = { body: channelId };
  return personApi.addPersonChannelWithHttpInfo(personId, opts);
};

const addExchangeChannel = async (exId, channelId) => {
  const opts = { body: channelId };
  return exchangeApi.addExchangeChannelWithHttpInfo(exId, opts);
};

const addCapabilityChannel = async (capId, channelId) => {
  const opts = { body: channelId };
  return capabilityApi.addCapabilityChannelWithHttpInfo(capId, opts);
};

const addActivityNext = async (activityId, nextId) => {
  const opts = { body: nextId };
  return activityApi.addActivityNextWithHttpInfo(activityId, opts);
};

const addCapabilityPerson = async (capId, personId) => {
  const opts = { body: personId };
  return capabilityApi.addCapabilityPersonWithHttpInfo(capId, opts);
};

const addCapabilityData = async (capId, dataId) => {
  const opts = { body: dataId };
  return capabilityApi.addCapabilityDataWithHttpInfo(capId, opts);
};

const addActivityCharacteristic = async (activityId, charCreateDto) => {
  const opts = { characteristicCreateDto: charCreateDto };
  return activityApi.addActivityCharacteristicWithHttpInfo(activityId, opts);
};

const addCapabilityCharacteristic = async (capId, charCreateDto) => {
  const opts = { characteristicCreateDto: charCreateDto };
  return capabilityApi.addCapabilityCharacteristicWithHttpInfo(capId, opts);
};

const addMissionCharacteristic = async (missionId, charCreateDto) => {
  const opts = { characteristicCreateDto: charCreateDto };
  return missionApi.addMissionCharacteristicWithHttpInfo(missionId, opts);
};

const addPersonCharacteristic = async (personId, charCreateDto) => {
  const opts = { characteristicCreateDto: charCreateDto };
  return personApi.addPersonCharacteristicWithHttpInfo(personId, opts);
};

const addNodeCharacteristic = async (nodeId, charCreateDto) => {
  const opts = { characteristicCreateDto: charCreateDto };
  return nodeApi.addNodeCharacteristicWithHttpInfo(nodeId, opts);
};

const addLinkCharacteristic = async (linkId, charCreateDto) => {
  const opts = { characteristicCreateDto: charCreateDto };
  return linkApi.addLinkCharacteristicWithHttpInfo(linkId, opts);
};

const addBusCharacteristic = async (busId, charCreateDto) => {
  const opts = { characteristicCreateDto: charCreateDto };
  return busApi.addBusCharacteristicWithHttpInfo(busId, opts);
};

const addDatatypeCharacteristic = async (dtId, charCreateDto) => {
  const opts = { characteristicCreateDto: charCreateDto };
  return dataTypeApi.addDataTypeCharacteristicWithHttpInfo(dtId, opts);
};

const addDataflowCharacteristic = async (dfId, charCreateDto) => {
  const opts = { characteristicCreateDto: charCreateDto };
  return dataflowApi.addDataFlowCharacteristicWithHttpInfo(dfId, opts);
};

const addExchangeCharacteristic = async (exId, charCreateDto) => {
  const opts = { characteristicCreateDto: charCreateDto };
  return exchangeApi.addExchangeCharacteristicWithHttpInfo(exId, opts);
};

const SystemService = {
  findAllSubjects,
  getSystemElementsByCategory,
  getNodesByStructuralCategory,
  createNodeWithParam,
  createNode,
  createExchangeWithParam,
  createExchange,
  createElementCategory,
  createStructuralCategory,
  createMission,
  addMissionCapability,
  createPerson,
  createCapability,
  createActivity,
  createDataTypeWithParam,
  createDataType,
  createDataflow,
  createBus,
  createLink,

  addNodeOutBus,
  addNodeInBus,
  addNodeStoredData,
  addActivityStoredData,
  addNodeGroup,
  addLinkOwnedData,
  addBusOwnedData,
  addLinkExchange,
  addBusExchange,
  addCapabilityExchange,
  addCapabilityNode,
  addPersonNode,
  addPersonActivity,
  addNodeActivity,
  addDataflowActivity,
  addCapabilityActivity,
  addPersonChannel,
  addCapabilityChannel,
  addExchangeChannel,
  addActivityNext,
  addCapabilityPerson,
  addCapabilityData,
  addActivityCharacteristic,
  addMissionCharacteristic,
  addPersonCharacteristic,
  addDatatypeCharacteristic,
  addDataflowCharacteristic,
  addExchangeCharacteristic,
  addNodeCharacteristic,
  addCapabilityCharacteristic,
  addLinkCharacteristic,
  addBusCharacteristic,
  createDefaultExchange,
  getShortNodeDto,
  getExchangeShortDto,
};

export default SystemService;
