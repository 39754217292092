import * as React from "react";
import { Column, Table } from "@tanstack/react-table";
import { ColumnId } from "../column-ids";
import { SelectColumnFilter } from "../SelectColumnFilter";
import { SelectBooleanColumnFilter } from "../SelectBooleanColumnFilter";
import * as S from "./ColumnFilter.styles";

export const ColumnFilter = ({ column, table }: { column: Column<any, any>; table: Table<any> }) => {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
  const columnFilterValue = column.getFilterValue();
  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === "number"
        ? Array.from(column.getFacetedUniqueValues().keys()).sort((a, b) => a - b)
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column, firstValue]
  );

  const handleOnFilterChange = (value: any) => {
    column.setFilterValue(value?.value);
  };

  const defaultSelectColumnFilter = (
    <SelectColumnFilter
      allValues={sortedUniqueValues}
      filterValue={columnFilterValue}
      onFilterChange={handleOnFilterChange}
    />
  );

  const smallSelectColumnFilter = (
    <SelectColumnFilter
      allValues={sortedUniqueValues}
      filterValue={columnFilterValue}
      onFilterChange={handleOnFilterChange}
      size="small"
    />
  );

  const selectBooleanColumnFilter = (
    <SelectBooleanColumnFilter
      allValues={sortedUniqueValues}
      filterValue={columnFilterValue}
      onFilterChange={handleOnFilterChange}
      size="small"
    />
  );

  const textSearch = (
    <S.SmallSearchInput
      type="text"
      value={(column.getFilterValue() ?? "") as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder="Search..."
      className="w-36 border shadow rounded"
    />
  );

  const columnFilterMap: Record<string, any> = {
    [ColumnId.rank]: smallSelectColumnFilter,
    [ColumnId.level]: defaultSelectColumnFilter,
    [ColumnId.category]: smallSelectColumnFilter,
    [ColumnId.controls]: selectBooleanColumnFilter,
    [ColumnId.findings]: selectBooleanColumnFilter,
    [ColumnId.name]: textSearch,
    [ColumnId.targets]: textSearch,
  };

  const filter = columnFilterMap[column.id] ? columnFilterMap[column.id] : defaultSelectColumnFilter;

  return filter;
  // const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
  // const columnFilterValue = column.getFilterValue();
  // const sortedUniqueValues = React.useMemo(
  //   () => (typeof firstValue === "number" ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort()),
  //   [column, firstValue]
  // );

  // console.log("um", table.getPreFilteredRowModel().flatRows);
  // console.log("um2", column.getFacetedUniqueValues());

  // const handleOnFilterChange = (value: any) => {
  //   console.log("new filter value", value);
  //   column.setFilterValue(value?.value);
  // };

  //   if (column.id === "vulnerabilityLevel") {
  // <SelectColumnFilter
  //         allValues={sortedUniqueValues}
  //         filterValue={columnFilterValue}
  //         onFilterChange={handleOnFilterChange}
  //       />
  //   }

  //   const filters = {
  //     vulnerabilityLevel: (

  //     ),
  //     vulnerabilityCategory:
  //   };

  // return (
  //   <SelectColumnFilter
  //     allValues={sortedUniqueValues}
  //     filterValue={columnFilterValue}
  //     onFilterChange={handleOnFilterChange}
  //   />
};
