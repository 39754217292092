import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./dataflow-query-key-factory";

/**
 * Sets the isInternal of an Dataflow
 *
 * @param {string} dataflowId - uuid of the dataflow
 * @param {string} isInternal - isInternal of the dataflow
 */
export const setDataflowIsInternal = ({ dataflowId, isInternal }: { dataflowId: string; isInternal: boolean }) => {
  return Brm.dataflowApi.setDataFlowIsInternal(dataflowId, { body: isInternal });
};

interface IUseSetDataflowIsInternal {
  options?: MutationOption<typeof setDataflowIsInternal>;
}

/**
 * Custom hook to change the isInternal of an existing dataflow
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetDataflowIsInternal = ({ options }: IUseSetDataflowIsInternal) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.dataflowKeys.all);
    },
    ...options,
    mutationFn: setDataflowIsInternal,
  });
};
