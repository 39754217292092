import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { ErrorBanner, LoadingSpinner } from "components/elements";
// Services
import { accessPointApi, attackSurfaceApi } from "services/brm/access-service";
// Helpers
import handleApi from "services/brm/api-util";
// State
import { userRoleState, projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { userRoles } from "services/brm/admin-service";
// Components
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import AccessPointsDetails from "brm/system-model/access/details/AccessPointDetails";
// Styles
import Title from "components/elements/StyledTitle";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
// Constants
import { COMMON, TYPE } from "constants/brm";
// Hooks
import useRedirectDetailsPage from "hooks/useRedirectDetailsPage";
// Table Columns
import {
  createColumnMapped,
  createColumnCategoryMapped,
  createColumnMappedNoEdit,
  createColumnActions,
  createColumnBooleanNoEditMapped,
} from "brm/tables/services/column/columnFactory";
// Queries
import * as ACQueries from "brm/system-model/access/access-queries";
import { exchangeApi, nodeApi } from "services/brm";
import { queryClient } from "libs/react-query";
import { RoutePath } from "routes/route-paths";

function AccessPointFullDetails() {
  const { id } = useParams();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const userRole = useRecoilValue(userRoleState); // current userRole
  const [selectedElement, setSelectedElement] = useState({});
  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedCategory = useRef("");
  const updatedSurface = useRef("");
  const sysEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
  const riskAnalystRole = userRoles.properties[userRoles.RISK_ANALYST].key;
  let content = null;
  const { data: accessPoint, isLoading, isError } = ACQueries.useAccessPointById(id);
  const { data: accessPointCategories } = ACQueries.useAccessPointCategories(projectId);
  const { data: attackSurfaces } = ACQueries.useAttackSurfaces(projectId);
  const [showSummaryTable, setShowSummaryTable] = useState(true);

  const createButton = useCallback(
    (cellProps) => {
      if (userRole === sysEngineerRole || userRole === riskAnalystRole) {
        return (
          <ButtonCell
            selectedRowId={selectedRowId}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedCategory.current !== "") {
                await handleApi(
                  accessPointApi.setAccessPointCategoryWithHttpInfo(selectedRowId, {
                    body: updatedCategory.current,
                  })
                );
                updatedCategory.current = "";
              }

              if (updatedSurface.current !== "") {
                await handleApi(
                  accessPointApi.setAccessPointSurfaceWithHttpInfo(selectedRowId, {
                    body: updatedSurface.current,
                  })
                );
                updatedSurface.current = "";
              }

              queryClient.invalidateQueries(["accessPointById"]);
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return COMMON.na;
    },

    [sysEngineerRole, riskAnalystRole, editMode, selectedRowId, userRole]
  );

  const columns = useMemo(
    () => [
      createColumnBooleanNoEditMapped("isExternal"),
      createColumnCategoryMapped("category", selectedRowId, accessPointCategories, editMode, updatedCategory),
      createColumnMappedNoEdit("node"),
      createColumnMapped("surface", selectedRowId, attackSurfaces, editMode, updatedSurface),
      createColumnMappedNoEdit("exchange"),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [editMode, selectedRowId, accessPointCategories, attackSurfaces, createButton]
  );

  useRedirectDetailsPage(projectId, localProjectId, RoutePath.AccessPoints);

  useEffect(() => {
    const getData = async () => {
      let finalResponse = {};
      finalResponse = { ...accessPoint };
      if (accessPoint.node) {
        finalResponse.node = await nodeApi.getNodeByID(accessPoint.node);
      }
      if (accessPoint.exchange) {
        finalResponse.exchange = await exchangeApi.getExchangeByID(accessPoint.exchange);
      }
      if (accessPoint.surface) {
        finalResponse.surface = await attackSurfaceApi.getAttackSurfaceByID(accessPoint.surface);
      }
      setSelectedElement(finalResponse);
      setData([finalResponse]);
    };

    if (accessPoint) {
      getData();
    }
  }, [accessPoint]);

  if (isError) {
    content = <ErrorBanner msg="Error while loading Access Point details." />;
  }

  if (isLoading) {
    content = <LoadingSpinner />;
  }

  if (accessPoint && data && selectedElement) {
    content = (
      <>
        <Title id="AccessPointFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "AccessPointFullDetails_table" }}
              elementName={TYPE.accessPoint}
            />
          </DetailsContainerSingleTable>
        ) : (
          <></>
        )}

        {selectedElement.id && (
          <DetailsContainerParent showSummaryTable={showSummaryTable}>
            <DetailsContainer id="AccessPointFullDetails_detailsPanel">
              <AccessPointsDetails selectedElement={selectedElement} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
  }

  return content;
}

export default AccessPointFullDetails;
