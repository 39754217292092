import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./installation-query-key-factory";
/**
 * Gets the installation
 */
export const getInstallation = ({ installationId }: { installationId: string | null }) => {
  return installationId === null
    ? Promise.reject(new Error("Invalid id"))
    : Brm.installationApi.getInstallationByID(installationId);
};

interface IInstallationProps {
  installationId: string | null;
  options?: QueryOption<typeof getInstallation>;
}

/**
 * Custom hook for getting the installation
 *
 * @param {string} installationId - uuid of user
 * @param {object} config - react-query configuration
 * @returns
 */
export const useInstallation = ({ installationId, options = {} }: IInstallationProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.installationKeys.installation(installationId),
    queryFn: () => getInstallation({ installationId }),
  });
};
