import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import {
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnNameDetails,
  createColumnNameNoNav,
} from "brm/tables/services/column/columnFactory";
import { ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { useModal, useRoles } from "hooks";
// Styles
import * as S from "brm/styles/details-table.styles";
import { LoadingSpinner, ErrorBanner } from "components/elements";
import { useElementTargets } from "./api";

const SystemObjectTable = ({ elementNameInUrl, selectedElement, showDelete = false, tableTitle }) => {
  const { isThreatAnalyst, isRiskAnalyst, isSystemEngineer } = useRoles();
  const [, setSelectedSystemObject] = useState([]);
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  const columns = useMemo(
    () => [
      isThreatAnalyst ? createColumnNameNoNav() : createColumnNameDetails(""),
      createColumnMappedNoEdit("noun"),
      createColumnBooleanNoEditMapped("isInternal"),
      createColumnBooleanNoEditMapped("isBoundary"),
    ],
    [isThreatAnalyst]
  );

  const {
    data: targetsData,
    isError: isTargetsError,
    error: TargetsError,
  } = useElementTargets({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    config: {
      enabled: !!selectedElement.id && (isThreatAnalyst || isRiskAnalyst || isSystemEngineer),
    },
  });

  if (isTargetsError) {
    return <ErrorBanner msg={TargetsError?.message} />;
  }

  if (targetsData) {
    return (
      <S.DetailsContainer id="SystemObjectTable_detailsPanel">
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={targetsData}
              columns={columns}
              setSelectedElement={setSelectedSystemObject}
              customProps={{ id: "SystemObjectTable_detailsTable" }}
              showRowSelect={showDelete}
              elementName={elementNameInUrl}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

SystemObjectTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  showDelete: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default SystemObjectTable;
