import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./person-query-key-factory";
/**
 * Returns a list of all persons within a given project
 *
 * @param {string} projectId - uuid of project
 * @returns list of all persons within the given project
 */
export const getPersons = ({ projectId }: { projectId: string | null }) => {
  return projectId === null ? Promise.reject(new Error("Invalid projectId")) : Brm.personApi.findPerson(projectId);
};

interface IPersonsProps {
  projectId: string | null;
  options?: QueryOption<typeof getPersons>;
}

/**
 * Custom-hook to retrieve all persons for a project.
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query configuration object
 * @returns react-query for findNode
 */
export const usePersons = ({ projectId, options = {} }: IPersonsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.personKeys.project(projectId),
    queryFn: () => getPersons({ projectId }),
  });
};
