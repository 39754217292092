/* eslint-disable react/jsx-props-no-spreading */
import { LoadingSpinner } from "components/elements";
import { useAdminOrganization } from "./useAdminOranization";

import { CreateProjectStepFormView } from "./CreateProjectStepFormView";
// import { useCreateProject } from "./useCreateProject";

export const CreateProjectStepForm = () => {
  const { userId, organizationUsers, organizationProjects } = useAdminOrganization();

  if (!(userId && organizationUsers && organizationProjects)) {
    return <LoadingSpinner message="Loading Organization Data..." />;
  }
  return (
    <CreateProjectStepFormView
      userId={userId}
      organizationUsers={organizationUsers}
      organizationProjects={organizationProjects}
    />
  );
};
