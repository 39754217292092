import { useMemo } from "react";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";

// Utils
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
// Services
import { useRoles } from "features/brm/hooks/useRoles";

// Styles
import * as S from "brm/styles/details-table.styles";
import { DetailTableType, SystemApi } from "features/brm";
import { CenterText, ErrorBanner, LoadingSpinner } from "components/elements";
import { RoutePath } from "routes/route-paths";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms";

interface IAccessPointProps {
  elementNameInUrl: string;
  selectedElement: {
    id: string;
  };
  tableTitle: string;
}

const AccessPointTable = ({ elementNameInUrl, selectedElement, tableTitle }: IAccessPointProps) => {
  const { isThreatAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const columns = useMemo(() => {
    if (isThreatAnalyst) {
      return [
        createColumnMappedNoEdit("name"),
        createColumnMappedNoEdit("category"),
        createColumnMappedNoEdit("surface"),
      ];
    }
    return [
      createColumnNameDetails(RoutePath.AccessPoint.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("surface"),
    ];
  }, [isThreatAnalyst]);
  const { data: elementAccessPointData, isError: isElementAccessPointDataError } = SystemApi.useElementAccessPoints({
    elementId: selectedElement?.id,
    elementName: elementNameInUrl,
    projectId,
    options: {
      enabled:
        !!selectedElement.id &&
        elementNameInUrl !== DetailTableType.DATATYPES.key &&
        elementNameInUrl !== DetailTableType.MISSIONS.key &&
        elementNameInUrl !== DetailTableType.CAPABILITIES.key &&
        elementNameInUrl !== DetailTableType.DATAFLOWS.key &&
        elementNameInUrl !== DetailTableType.PERSONS.key &&
        elementNameInUrl !== DetailTableType.RESOURCES.key,
    },
  });

  if (
    elementNameInUrl === DetailTableType.DATATYPES.key ||
    elementNameInUrl === DetailTableType.MISSIONS.key ||
    elementNameInUrl === DetailTableType.CAPABILITIES.key ||
    elementNameInUrl === DetailTableType.DATAFLOWS.key ||
    elementNameInUrl === DetailTableType.PERSONS.key ||
    elementNameInUrl === DetailTableType.RESOURCES.key
  ) {
    return <CenterText msg="Access points are not applicable" />;
  }

  if (isElementAccessPointDataError) {
    return <ErrorBanner msg="Error while loading access points" />;
  }

  if (elementAccessPointData) {
    return (
      <S.DetailsContainer id="AccessPointTable_detailsPanel">
        <BrmDetailTable
          data={elementAccessPointData}
          columns={columns}
          customProps={{ id: "AccessPointTable_detailsTable" }}
          showRowSelect={false}
          tableTitle={tableTitle}
          setSelectedElement={() => {}}
        />
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

export default AccessPointTable;
