import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./person-query-key-factory";
/**
 * Returns a person
 *
 * @param {string} personId - uuid of project
 * @returns person
 */
export const getPerson = ({ personId }: { personId: string }) => {
  return personId === null ? Promise.reject(new Error("Invalid projectId")) : Brm.personApi.getPersonByID(personId);
};

interface IPersonsProps {
  personId: string;
  options?: QueryOption<typeof getPerson>;
}

/**
 * Custom-hook to retrieve a person
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query configuration object
 * @returns react-query for findNode
 */
export const usePerson = ({ personId, options }: IPersonsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.personKeys.project(personId),
    queryFn: () => getPerson({ personId }),
  });
};
