import { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Constants
import { STATUS } from "constants/brm";

import PropTypes from "prop-types";

// Styles
import "./Accordion.css";
import { useStatusChevron } from "features/brm";
import S from "./ProjectExplorer.styles";

// Hooks

import { HighlightElementContext } from "./HighlightElementContext";

const AccordionLevelTwo = ({ title, content2 }) => {
  const highlightedElement = useContext(HighlightElementContext);
  const [active, setActive] = useState("");
  const [height, setHeight] = useState("0px");
  const navigate = useNavigate();
  const content = useRef(null);
  const folderIcon = useStatusChevron(active);

  const toggleAccordion = () => {
    setActive(active === "" ? STATUS.active : "");
    setHeight(active === STATUS.active ? "0px" : `${content.current.scrollHeight}px`);
  };
  const handleClick = (e) => {
    highlightedElement.setId(e.target.id);
    navigate(e.target.id);
  };
  return (
    <div className="accordion__section">
      <S.Item id={title} className={`accordion ${active}`} onClick={toggleAccordion}>
        {folderIcon}
        {title}
      </S.Item>
      <div ref={content} style={{ maxHeight: `${height}` }} className="accordion__content">
        <div className="accordion__text">
          {content2.map((value) => (
            <S.SubMenuAccordionLevelTwo
              className={highlightedElement.id === value.path && "highlighted__element"}
              id={value.path}
              key={value.path}
              onClick={handleClick}
            >
              {value.name}
            </S.SubMenuAccordionLevelTwo>
          ))}
        </div>
      </div>
    </div>
  );
};

AccordionLevelTwo.propTypes = {
  title: PropTypes.string,
  content2: PropTypes.array.isRequired,
};
AccordionLevelTwo.defaultProps = {
  title: "",
};

export default AccordionLevelTwo;
