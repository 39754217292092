/* eslint-disable no-bitwise */
import { useConstructor } from "hooks/useConstructor";

import * as YFiles from "yfiles";

export const useToolTips = (
  graphComp: YFiles.GraphComponent,
  onPopulateTooltip: (item: YFiles.IModelItem) => HTMLElement,
  tooltipItems: YFiles.GraphItemTypes | YFiles.GraphItemTypesConvertible
) => {
  useConstructor(() => {
    const inputMode = graphComp.inputMode as YFiles.GraphViewerInputMode;
    // show tooltips only for nodes and edges
    inputMode.toolTipItems = tooltipItems; // YFiles.GraphItemTypes.NODE; // | YFiles.GraphItemTypes.EDGE;

    // Customize the tooltips behavior to our liking.
    const { mouseHoverInputMode } = inputMode;
    mouseHoverInputMode.toolTipLocationOffset = new YFiles.Point(15, 15);
    mouseHoverInputMode.delay = YFiles.TimeSpan.fromMilliseconds(500);
    mouseHoverInputMode.duration = YFiles.TimeSpan.fromSeconds(5);

    // Register a listener for when a tooltip should be shown.
    inputMode.addQueryItemToolTipListener((src, args) => {
      const eventArgs = args;
      if (eventArgs.handled) {
        // Tooltip content has already been assigned => nothing to do.
        return;
      }
      // Get the tooltip content
      eventArgs.toolTip = onPopulateTooltip(args.item!);

      // Indicate that the tooltip content has been set.
      eventArgs.handled = true;
    });
  });
};
