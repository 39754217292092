import { useState, useEffect } from "react";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import { userRoles } from "services/brm/admin-service";
import { AddButton, DeleteButton, SystemApi } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { dataTypeApi } from "services/brm/system-service";
import { TYPE, CRUD_EVENT } from "constants/brm";
import systemModals from "components/modals/Modals";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { useRoles } from "features/brm/hooks/useRoles";
import { queryClient } from "libs/react-query";
import DatatypeTable from "./tables/DatatypeTable";
import DatatypeAddForm from "./forms/DatatypeAddForm";
import Event from "./crudEvent";

function Datatype() {
  const { isSystemEngineer } = useRoles();
  const sysEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const addDatatype = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      const promises = selectedRows.map((each) => dataTypeApi.deleteDataTypeWithHttpInfo(each.id));
      Promise.all(promises)
        .then(() => {
          queryClient.invalidateQueries(SystemApi.dataTypeKeys.all);
          setDeleteModalIsOpen(false);
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }

    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, selectedRows, setSelectedInstance]);

  const dataTypeHeader = (
    <Title id="Datatype_title">
      Datatypes
      <Buttons id="Datatype_buttons">
        <Event constant={CRUD_EVENT.datatype} setRefresh={setRefresh} />
        {isSystemEngineer ? (
          <>
            {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
            <AddButton onClick={addDatatype} />
          </>
        ) : (
          <br />
        )}
      </Buttons>
    </Title>
  );

  const dataTypeMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "Datatype_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.datatype}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.datatype
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "Datatype_page",
          modalIsOpen,
          setModalIsOpen,
          <DatatypeAddForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} />,
          TYPE.datatype
        )}

      <DatatypeTable
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
        accessibleRole={sysEngineerRole}
      />
    </>
  );

  return <ContentLayout pageId="Datatype_page" header={dataTypeHeader} main={dataTypeMain} />;
}

export default Datatype;
