import { TYPE } from "constants/brm";
import { ShapeNodeShape } from "yfiles";

// eslint-disable-next-line import/prefer-default-export
export const NODE_TYPE_STYLE_MAP = {
  [TYPE.assetCat]: { color: "#662D91", shape: ShapeNodeShape.ROUND_RECTANGLE, drawerShape: ShapeNodeShape.RECTANGLE },
  [TYPE.asset]: { color: "#FF1493", shape: ShapeNodeShape.ROUND_RECTANGLE, drawerShape: ShapeNodeShape.RECTANGLE },
  [TYPE.risk]: {
    color: "#ED2024",
    shape: ShapeNodeShape.TRIANGLE,
    drawerShape: ShapeNodeShape.TRIANGLE,
    category: { Disclosure: { color: "#800080" } },
  },
  [TYPE.ue]: { color: "#FAA41A", shape: ShapeNodeShape.ROUND_RECTANGLE, drawerShape: ShapeNodeShape.RECTANGLE },
  [TYPE.trev]: { color: "#2B3990", shape: ShapeNodeShape.TRIANGLE2, drawerShape: ShapeNodeShape.TRIANGLE2 },
  [TYPE.node]: { color: "#006838", shape: ShapeNodeShape.ROUND_RECTANGLE, drawerShape: ShapeNodeShape.RECTANGLE },
  [TYPE.exchange]: { color: "#006838", shape: ShapeNodeShape.RECTANGLE, drawerShape: ShapeNodeShape.RECTANGLE },
  [TYPE.attack]: { color: "#00EFF0", shape: ShapeNodeShape.TRIANGLE2, drawerShape: ShapeNodeShape.TRIANGLE2 },
};

export const NODE_HEIGHT = 30;
export const NODE_WIDTH = 30;
