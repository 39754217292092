/* eslint-disable no-param-reassign */
import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";

import convertedPercent from "utils/percent-converter";
import { sortByRiskDistributionRank } from "utils/sorting";
import { QueryOption } from "libs/react-query";
import { COMMON } from "constants/brm";
import * as KeyFactory from "./node-query-key-factory";

interface IGetNodesWithRisk {
  projectId: string | null;
  variantId: string | null;
}

/**
 * Returns a list of nodes that have risks
 *
 * @param {string} projectId - uuid of a project
 * @param {*} variantId - uuid of a project variant
 * @returns list of NodeShortDto's that have risk values
 */
export const getNodesWithRisk = async ({ projectId, variantId }: IGetNodesWithRisk) => {
  const data = await Brm.nodeApi.findNode(projectId, { variant: variantId });
  const riskData = await Brm.riskApi.findRisk(projectId, { variant: variantId });
  // Find if risk with score of 1 exists.
  const riskHasBeenRun = riskData.some((r: any) => r.rank?.calcValue === 1);

  if (riskHasBeenRun) {
    // use the zero values in distribution
    const updatedNodeData = data.map((node: any) => {
      const percent = node.riskDistributionPercent * 100;
      node.riskDistributionPercent = convertedPercent(percent);

      return node;
    });
    updatedNodeData.sort(sortByRiskDistributionRank);
    let i = 1;

    // Use the sorted node data to update ranking to start at 1
    const updatedNodeDataRanking = updatedNodeData.map((node: any) => {
      if (node.riskDistributionRank > 0) {
        node.riskDistributionRank = i;
        i += 1;
      }
      return node;
    });
    return updatedNodeDataRanking;
  }
  // set risk distribution values to N/A
  const updatedNodeData = data.map((node: any) => {
    node.riskDistributionRank = COMMON.na;
    node.riskDistributionPercent = COMMON.na;

    return node;
  });
  return updatedNodeData;
};

interface INodesWithRiskProps {
  projectId: string | null;
  variantId: string | null;
  options?: QueryOption<typeof getNodesWithRisk>;
}

/**
 * Custom-hook to retrieve all nodes for a project with additional risk data.
 *
 * @param {string} projectId - uuid of project
 * @param {string} variantId - uuid of project variant
 * @param {object} options - react-query configuration object
 * @returns react-query for findNode
 */
export const useNodesWithRisk = ({ projectId, variantId, options = {} }: INodesWithRiskProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.nodeKeys.risk(projectId, variantId),
    queryFn: () => getNodesWithRisk({ projectId, variantId }),
  });
};
