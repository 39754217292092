import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./attacker-query-key-factory";

export interface ISetAttackerSaType {
  attackerId: string;
  saType: string;
}

/**
 * sets sa type of the selected attacker
 * @param {string} attackerId - uuid of the attacker
 * @param {string} saType - uuid of system asset type
 *
 */
export const setAttackerSaType = ({ attackerId, saType }: ISetAttackerSaType) => {
  return Brm.attackerApi.setAttackerSatype(attackerId, { body: saType });
};

export interface IUseSetAttackerSaType {
  options?: MutationOption<typeof setAttackerSaType>;
}

/**
 * Custom hook to change the sa type of a existing Attacker
 * @param {object} options hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerSaType = ({ options }: IUseSetAttackerSaType = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.attackerKeys.all);
    },
    ...options,
    mutationFn: setAttackerSaType,
  });
};
