// URLS
import TrashIcon from "assets/icons/trash.svg";
// Styles
import SecondaryButton from "components/elements/SecondaryStyledButton";
// Components
import ImgSized from "components/elements/ImgSized";
import { useRecoilValue } from "recoil";
import { isReadOnlyState } from "atoms/atoms-admin";
// Services
import { useRoles } from "features/brm/hooks/useRoles";
import { MouseEvent } from "react";

interface IDeletButtonProps {
  onClick: (event: MouseEvent) => void;
}

export const DeleteButton = ({ onClick }: IDeletButtonProps) => {
  const { isSuperAdmin, isAdmin } = useRoles();
  const isReadOnly = useRecoilValue(isReadOnlyState);

  return (
    <>
      {isSuperAdmin || isAdmin || !isReadOnly ? (
        <span title="Delete">
          <SecondaryButton id="DeleteButton_button" onClick={onClick}>
            <ImgSized id="DeleteButton_deleteIcon" src={TrashIcon} alt="delete" width="16" height="16" />
          </SecondaryButton>
        </span>
      ) : null}
    </>
  );
};
