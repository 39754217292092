import styled from "styled-components";

export const TooltipContainer = styled.div`
  /* background-color: #fdfdfd;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
  position: absolute;
  padding: 4px;
  z-index: 1070;
  overflow: visible;
  font-size: 14px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: flex; */
`;

export const TooltipTitle = styled.span`
  font-weight: normal;
`;

export const TooltipValue = styled.span`
  font-weight: bold;
`;
