import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./categorization-query-key-factory";

/**
 * Returns a list of ClassificationCategoryShortDto's for the given project
 *
 * @param {string} projectId - uuid of the project
 * @returns list of ClassificationCategoryShortDto's
 */
export const getClassificationCategories = ({ projectId }: { projectId: string | null }) => {
  return projectId === null
    ? Promise.reject(new Error("Invalid projectId id"))
    : Brm.classificationCategoryApi.findClassificationCategory(projectId);
};

interface IClassificationCategoriesProps {
  projectId: string | null;
  options?: QueryOption<typeof getClassificationCategories>;
}

/**
 * React-query hook for getting all classification categories
 *
 * @param {string} projectId - uuid of the project
 * @param {object} options - react-query configuration object
 * @returns react-query for getClassificationCategories
 */
export const useClassificationCategories = ({ projectId, options }: IClassificationCategoriesProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.categoriziationCategoryKeys.project(projectId),
    queryFn: () => getClassificationCategories({ projectId }),
  });
};
