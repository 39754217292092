import { useEffect, useState, useMemo } from "react";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
// import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";
import { createColumnNameNoNav } from "brm/tables/services/column/columnFactory";
import { LoadingSpinner as Loading } from "components/elements";
import { SystemApi, useRoles } from "features/brm";

const ElementCategoryTable = ({ setSelectedRows }) => {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const [selectedInstance] = useRecoilState(selectedInstanceState);
  const { data: elementCats } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: isSystemEngineer || isRiskAnalyst },
  });

  const [namedElementCats, setNamedCats] = useState([]);

  useEffect(() => {
    if (elementCats) {
      setNamedCats(elementCats.map((e) => ({ name: e })));
    }
  }, [elementCats, setNamedCats]);

  const columns = useMemo(
    () => [
      // TODO Do we have a details page for categories?
      // !isCyberSecurityExpert
      //   ? createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "elementCategory")
      //   : createColumnNameNoNav(selectedInstance.id, editMode, updatedName),
      createColumnNameNoNav(selectedInstance.id, false),
    ],
    [selectedInstance.id]
  );

  // // Refresh the table list if project selection changes
  // useRefreshTableList(projectId, setRefresh);

  if (elementCats && namedElementCats) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={namedElementCats}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "ElementCategory_table" }}
            elementName={TYPE.elementCat}
            showRowSelect={false}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

ElementCategoryTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

export default ElementCategoryTable;
