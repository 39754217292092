import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
`;

export const Separator = styled.div`
  display: flex;
  padding: 0 10px;
`;

export const Text = styled.p`
  margin-top: 20px;
`;

export const Container = styled.div`
  line-height: 1;
  width: 100%;
  padding-left: 12px;
  .accordion-button {
    color: #007bff;
    text-align: left;
    background-color: #fff;
    font-size: 14px;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url('data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e');
  }
  .accordion-button .collapsed {
    color: #212529;
    text-align: left;
    background-color: #fff;
    box-shadow: none;
  }
`;
