export class LayoutType {
  static readonly ORGANIC = new LayoutType("ORGANIC");

  static readonly RADIAL = new LayoutType("RADIAL");

  static readonly HIERARCHICAL = new LayoutType("HIERARCHICAL");

  static readonly HIERARCHICAL_LEFT_TO_RIGHT = new LayoutType("HIERARCHICAL_LEFT_TO_RIGHT");

  static readonly ORTHOGONAL = new LayoutType("ORTHOGONAL");

  static readonly ORTHOGONAL_LEFT_TO_RIGHT = new LayoutType("ORTHOGONAL_LEFT_TO_RIGHT");

  static readonly CIRCULAR = new LayoutType("CIRCULAR");

  static readonly EDGE_ROUTER = new LayoutType("EDGE_ROUTER");

  static get values() {
    return [
      this.ORGANIC,
      this.RADIAL,
      this.HIERARCHICAL,
      this.HIERARCHICAL_LEFT_TO_RIGHT,
      this.ORTHOGONAL,
      this.ORTHOGONAL_LEFT_TO_RIGHT,
      this.CIRCULAR,
      this.EDGE_ROUTER,
    ] as const;
  }

  static fromString(string: string) {
    const value = (this as any)[string];
    if (value) {
      return value;
    }
    throw new RangeError(
      `Illegal argument passed to fromString(): ${string} does not correspond to any instance of the enum ${
        (this as any).prototype.constructor.name
      }`
    );
  }

  public readonly name: string;

  private constructor(name: string) {
    this.name = name;
  }

  public toJSON() {
    return this.name;
  }
}
