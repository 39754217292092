import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./variant-query-key-factory";

/**
 * creates new variant
 *
 * @param {string} projectId uuid of project
 * @param {VariantCreateDto} variantCreateDto creation dto
 * @returns id of created variant
 */
export const createVariant = ({ projectId, variantCreateDto }: { projectId: string; variantCreateDto: any }) => {
  return Brm.variantApi.createVariant(projectId, { variantCreateDto });
};

interface IUseCreateVariant {
  options?: MutationOption<typeof createVariant>;
}

/**
 * Custom hook to create a new Variant.
 *
 * @param {object} config - react-query mutation configuration
 * @returns react-query for useCreateVariant
 */
export const useCreateVariant = ({ options }: IUseCreateVariant = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: createVariant,
  });
};
