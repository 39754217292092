import * as React from "react";

import SelectCategory from "components/forms/DynamicSelectBoxCategory";
import BooleanDropdown from "components/forms/BooleanDropdown";
import { stringToBoolean } from "utils/boolean-checker";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import { MissionCreateDto } from "@kdmanalytics/brm-system";
import PropTypes from "prop-types";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import TextField from "components/forms/TextField";
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
import SelectAll from "components/forms/SelectAllReactSelect";
import { sortUpperCase } from "utils/sorting";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { useQueryClient } from "@tanstack/react-query";
import { SystemApi } from "features/brm";
import { validateNameUniqueness } from "features/system-model";
import * as SysFactsApi from "../api";

const AddMissionForm = ({ setModalIsOpen }) => {
  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = React.useState("");
  const [selectedCapabilities, setSelectedCapabilities] = React.useState([]);
  const [isTextValid, setIsTextValid] = React.useState(true);

  const queryClient = useQueryClient();
  const createMission = SysFactsApi.useCreateMission({
    config: {
      onSuccess: async () => {
        queryClient.invalidateQueries(["mission", projectId]);
      },
    },
  });

  const addMissionCapability = SysFactsApi.useAddMissionCapability();

  const { data: missions, isError: isMissionsError } = SystemApi.useMissions({
    projectId,
    options: { staleTime: 5000, enabled: !!projectId },
  });

  const { data: elementCategories, isError: isElementCategoriesError } = SystemApi.useElementCategories({
    projectId,
    options: {
      enabled: !!projectId,
      select: React.useCallback((data) => data.sort(sortUpperCase), []),
    },
  });

  const { data: capabilities, isError: isCapabilitiesError } = SystemApi.useCapabilities({
    projectId,
    options: {
      enabled: !!projectId,
      select: React.useCallback((data) => data.map((d) => ({ value: d.id, label: d.name })), []),
    },
  });

  // validate that all fields in the form have been completed
  function validate(formData) {
    let error = true;
    if (
      formData.name.value === "" ||
      formData.category.value === "Select Category" ||
      formData.isInternal.value === null
    ) {
      error = false;
    }
    return error;
  }

  function handleSubmit(e) {
    e.preventDefault();

    const isValid = validate(e.target.elements);

    const isNameValid = validateNameUniqueness(missions, e.target.elements.name.value);
    if (!isNameValid) {
      setPostError(FORM_ERROR.duplicateMissionName);
      return null;
    }

    if (isValid && isTextValid) {
      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value,
        category: e.target.elements.category.value,
        isInternal: stringToBoolean(e.target.elements.isInternal.value),
      };

      const dto = MissionCreateDto.constructFromObject(params);

      createMission.mutate(
        { projectId, missionCreateDto: dto },
        {
          onSuccess: async (missionId) => {
            for (let i = 0; i < selectedCapabilities.length; i += 1) {
              // we have to add them one at a time and wait for the previous because the backend locks with each addition
              // eslint-disable-next-line no-await-in-loop
              await addMissionCapability.mutateAsync({ missionId, capabilityId: selectedCapabilities[i].value });
            }
            setModalIsOpen(false);
          },
          onError: (error) => {
            console.error(error);
          },
        }
      );
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  const handleCapabilitySelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedCapabilities(items);
  };

  if (isMissionsError || isElementCategoriesError || isCapabilitiesError) {
    return <ErrorBanner msg="Error while loading required info for add mission" />;
  }

  if (elementCategories && capabilities) {
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <FormStyled>
            <div className="form-style">
              <TextField
                label={{ id: "AddMissionForm_name", name: FORM_LABEL.nameMandatory }}
                input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "AddMissionForm_note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />

              <BooleanDropdown
                label={{ id: "AddMissionForm_isInternal", name: FORM_LABEL.internalMandatory }}
                select={{ id: "isInternal", name: "isInternal" }}
              />

              <label id="AddMissionForm_category">{FORM_LABEL.categoryMandatory}</label>
              <SelectCategory
                id="AddMissionForm_categoryDropdown"
                arrayOfData={elementCategories}
                name="Category"
                item="category"
              />
              <label id="AddMissionForm_capability">{FORM_LABEL.capabilitiesOptional}</label>
              <SelectAll
                isMulti
                options={capabilities}
                defaultValue={selectedCapabilities}
                value={selectedCapabilities}
                onChange={handleCapabilitySelectChange}
                allowSelectAll
                allOption={{
                  label: "Select all",
                  value: "*",
                }}
                elementId="AddMissionForm_capability"
              />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="AddMissionForm_cancelButton">
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled type="submit" id="AddMissionForm_addButton">
              Add
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }
  return <Loading />;
};

AddMissionForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};

export default AddMissionForm;
