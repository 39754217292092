import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./user-query-key-factory";
/**
 * Gets the users
 */
export const getUsers = async ({ organizationId }: { organizationId: string }) => {
  return Brm.userApi.findUser({ organization: organizationId });
};

interface IUserProps {
  organizationId?: string;
  options?: QueryOption<typeof getUsers>;
}

/**
 * Custom hook for getting all users
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useUsers = ({ organizationId = "", options = {} }: IUserProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.userKeys.org(organizationId),
    queryFn: () => getUsers({ organizationId }),
  });
};
