import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./ttp-query-key-factory";

export interface ISetTtpName {
  ttpId: string;
  name: string;
}

export const setTtpName = ({ ttpId, name }: ISetTtpName) => {
  return Brm.ttpApi.setTTPName(ttpId, { body: name });
};

export interface IUseSetTtpName {
  options?: MutationOption<typeof setTtpName>;
}

/**
 * Custom hook to change the name of a existing TTP
 * @returns react-query ReactQuery.useMutation
 */
export const useSetTtpName = ({ options }: IUseSetTtpName = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.ttpKeys.all);
    },
    ...options,
    mutationFn: setTtpName,
  });
};
