import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./threat-catalog-query-key-factory";

export const getThreatCatalogs = () => {
  return Brm.threatCatalogApi.findThreatCatalog();
};

interface IUseThreatCatalogs {
  options?: QueryOption<typeof getThreatCatalogs>;
}

/**
 * React-query hook for getting all Threat Catalogs
 *
 * @param
 * @returns react-query for getThreatCatalogs
 */
export const useThreatCatalogs = ({ options }: IUseThreatCatalogs = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatCatalogKeys.all,
    queryFn: () => getThreatCatalogs(),
  });
};
