import styled from "styled-components";

export const RiskCalculatingDiv = styled.div`
  align-items: center;
  display: flex;
  background-color: white;
  border: none;
  padding: 8px 10px;
  border-radius: 8px;
  gap: 1em;
  color: #363636;
  line-height: 1.3;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%), 0 3px 3px rgb(0 0 0 / 5%);
`;

export const DismissButton = styled.button`
  border: none;
  background-color: white;
`;
