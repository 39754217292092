import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { COMMON } from "constants/brm";
import * as KeyFactory from "./exchange-query-key-factory";

/**
 * Retrieve datatype for an exchange
 *
 * @param {string} exchangeId - uuid of exchange
 * @returns list of all producing activity for a given exchange
 */
export const getExchangeProducingActivity = async ({ exchangeId }: { exchangeId: string | null }) => {
  const data = await Brm.exchangeApi.getExchangeActProducer(exchangeId);
  if (!Array.isArray(data)) {
    return data?.id === COMMON.nullUuid ? [] : [data];
  }
  return data || [];
};

interface IExchangeDataProps {
  exchangeId: string | null;
  options?: QueryOption<typeof getExchangeProducingActivity>;
}

/**
 * Custom-hook to retrieve producing activity for an exchange.
 *
 * @param {string} exchangeId - uuid of exchange
 * @param {object} options - react-query configuration object
 * @returns react-query for producing activity
 */
export const useExchangeProducingActivity = ({ exchangeId, options = {} }: IExchangeDataProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exchangeKeys.actProducer(exchangeId),
    queryFn: () => getExchangeProducingActivity({ exchangeId }),
  });
};
