/* eslint-disable import/prefer-default-export */
import localforage from "localforage";
import * as React from "react";
/**
 * Error handler type
 */
type ErrorHandler = (e?: Error) => void;

/**
 * Default error handler.
 *
 * @param e error
 */
const defaultErrorHandler: ErrorHandler = (e?: Error) => {
  console.error(e);
};

type LocalForageState = {
  status: string;
  data?: any;
  error?: any;
};

interface LocalForageAction {
  type: string;
  data?: any;
  error?: any;
}

const localForageReducer = (state: LocalForageState, action: LocalForageAction): LocalForageState => {
  switch (action.type) {
    case "error": {
      return {
        ...state,
        status: "error",
        error: action.error,
      };
    }
    case "success": {
      return {
        ...state,
        status: "resolved",
        data: action.data,
      };
    }
    case "started": {
      return {
        ...state,
        status: "pending",
        data: null,
      };
    }
    case "updating": {
      return {
        ...state,
        status: "pending",
        data: null,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const initState: LocalForageState = {
  status: "idle",
  data: null,
};

/**
 * Custom Hook to make useLocalForage easier to use.  Value is initially null until an attempt to get the value
 * has been made.
 *
 * @param key forage key
 * @param defaultValue value to return if the the get fails (must be a static reference)
 * @param errorHandler error handler function
 * @returns [value, setValue, removeValue]
 */
export const useLocalForage = <D>(key: string, defaultValue: D, errorHandler?: ErrorHandler) => {
  const [state, dispatch] = React.useReducer(localForageReducer, initState);

  const $errorHandler = React.useRef(
    typeof errorHandler === "undefined" || errorHandler === null ? defaultErrorHandler : errorHandler
  );

  const error = (e?: Error) => {
    $errorHandler.current(e);
  };

  React.useEffect(() => {
    dispatch({ type: "started" });
    (async () => {
      try {
        const value: D | null = await localforage.getItem(key);
        dispatch({ type: "success", data: value === null ? defaultValue : value });
      } catch (err: any) {
        error(err);
        dispatch({ type: "error", error: err });
      }
    })();
  }, [defaultValue, key]);

  const setValue = React.useCallback(
    (v) => {
      dispatch({ type: "updating", data: null });
      const set = async (value: any) => {
        try {
          await localforage.setItem(key, value);
          dispatch({ type: "success", data: value });
        } catch (err: any) {
          error(err);
          dispatch({ type: "error", error: err });
        }
      };
      set(v);
    },
    [key]
  );

  const removeValue = React.useCallback(() => {
    const remove = async () => {
      try {
        dispatch({ type: "success", data: null });
        await localforage.removeItem(key);
      } catch (err: any) {
        error(err);
        dispatch({ type: "error", error: err });
      }
    };
    remove();
  }, [key]);

  return [state, setValue, removeValue] as const;
};
