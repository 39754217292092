import { useState, useEffect } from "react";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { userRoleState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { userRoles } from "services/brm/admin-service";
import { AddButton, DeleteButton, SystemApi } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { exchangeApi } from "services/brm/system-service";
import { TYPE, CRUD_EVENT } from "constants/brm";
import systemModals from "components/modals/Modals";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { queryClient } from "libs/react-query";
import ExchangeTable from "./tables/ExchangeTable";
import ExchangeAddForm from "./forms/ExchangeAddForm";
import Event from "./crudEvent";

const sysEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;

function Exchange() {
  const userRole = useRecoilValue(userRoleState);
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const addExchange = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      const promises = selectedRows.map((each) => exchangeApi.deleteExchangeWithHttpInfo(each.id));
      Promise.all(promises)
        .then(() => {
          queryClient.invalidateQueries(SystemApi.exchangeKeys.all);
          setDeleteModalIsOpen(false);
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, selectedRows, setSelectedInstance]);

  const exchangeHeader = (
    <Title id="Exchange_title">
      Exchanges
      <Buttons id="exchange_buttons">
        <Event constant={CRUD_EVENT.exchange} setRefresh={setRefresh} />
        {userRole && userRole === sysEngineerRole ? (
          <>
            {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
            <AddButton onClick={addExchange} />
          </>
        ) : (
          <br />
        )}
      </Buttons>
    </Title>
  );

  const exchangeMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "Exchange_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.exchange}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.exchange
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "Exchange_page",
          modalIsOpen,
          setModalIsOpen,
          <ExchangeAddForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} />,
          TYPE.exchange
        )}

      <ExchangeTable
        setSelectedRows={setSelectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
        accessibleRole={sysEngineerRole}
      />
    </>
  );

  return <ContentLayout pageId="Exchange_page" header={exchangeHeader} main={exchangeMain} />;
}

export default Exchange;
