import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./attacker-query-key-factory";

export interface ISetAttackerPerson {
  attackerId: string;
  person: string;
}

/**
 * sets person of the selected attacker
 * @param {string} attackerId - uuid of the attacker
 * @param {string} person - uuid of person
 *
 */
export const setAttackerPerson = ({ attackerId, person }: ISetAttackerPerson) => {
  return Brm.attackerApi.setAttackerPerson(attackerId, { body: person });
};

export interface IUseSetAttackerPerson {
  options?: MutationOption<typeof setAttackerPerson>;
}

/**
 * Custom hook to change the person of a existing Attacker
 * @param {object} options hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerPerson = ({ options = {} }: IUseSetAttackerPerson = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.attackerKeys.all);
    },
    ...options,
    mutationFn: setAttackerPerson,
  });
};
