import { useQueries } from "@tanstack/react-query";
import * as SystemApi from "../../api/system";

interface INode {
  id: string;
}

export const useAllVulnerabilitiesForNodes = (nodes: INode[], variantId: string, options: any) => {
  return useQueries({
    queries: nodes
      ? nodes.map(({ id }: { id: string }) => {
          return {
            ...options,
            queryKey: SystemApi.nodeKeys.vulnerability(id, variantId),
            queryFn: () => SystemApi.getNodeVulnerabilities({ nodeId: id, variantId }),
          };
        })
      : [],
  });
};
