import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./risk-query-key-factory";

interface IGetRisks {
  projectId: string;
  variantId: string;
}

export const getRisks = ({ projectId, variantId }: IGetRisks) => {
  return Brm.riskApi.findRisk(projectId, { variant: variantId });
};

interface IUseRisks {
  projectId: string;
  variantId: string;
  options?: QueryOption<typeof getRisks>;
}

export const useRisks = ({ projectId, variantId, options }: IUseRisks) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.riskKeys.project(projectId, variantId),
    queryFn: () => getRisks({ projectId, variantId }),
  });
};
