import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

/**
 *
 * @param {string} controlCatalogId - uuid of control catalog
 * @returns array of objects - list of standard baselines
 */
export const getStandardBaselines = ({ controlCatalogId }: { controlCatalogId: string }) => {
  return Brm.controlCatalogApi.getControlCatalogBaseline(controlCatalogId);
};

interface IUseStandardBaselines {
  controlCatalogId: string;
  options?: QueryOption<typeof getStandardBaselines>;
}

/**
 * React-query hook for getting all baselines
 *
 * @param {string} controlCatalogId - uuid of the controlCatalog
 * @param {object} options- configuration for this hook
 * @returns react-query for getStandardBaselines
 */
export const useStandardBaselines = ({ controlCatalogId, options = {} }: IUseStandardBaselines) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.standardBaselineKeys.controlCatalog(controlCatalogId),
    queryFn: () => getStandardBaselines({ controlCatalogId }),
  });
};
