export const CONTEXT_SIDEBAR_CONTENT = {
  notes: "notes",
  messages: "messages",
  comments: "comments",
  systemDiagramPalette: "systemDiagramPalette",
  systemDiagramDatatype: "systemDiagramDatatype",
  systemDiagramPerson: "systemDiagramPerson",
  systemDiagramDataflow: "systemDiagramDataflow",
  systemDiagramProperties: "systemDiagramProperties",
  systemDiagramLegend: "systemDiagramLegend",
  systemDiagramIconMapper: "systemDiagramIconMapper",
  contextDiagramInfo: "contextDiagramInfo",
  contextDiagramFiltering: "contextDiagramFiltering",
  attackGraphAddNodes: "attackGraphAddNodes",
  help: "help",
} as const;
