import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./categorized-entity-query-key-factory";

interface IGetCategorizedEntities {
  projectId: string | null;
  classification?: string | undefined;
  objectiveId?: string | undefined;
}

/**
 * Returns a list of categorizedEntityShortDto's for the given project
 *
 * @param {string} projectId - uuid of the project
 * @param {string} classification - classification
 * @param {string} objectiveId - uuid of the objective
 * @returns list of categorizedEntityShortDto's
 */
export const getCategorizedEntities = ({ projectId, classification, objectiveId }: IGetCategorizedEntities) => {
  let opts = {};
  if (classification) opts = { ...opts, classification };
  if (objectiveId) opts = { ...opts, objective: objectiveId };
  return Brm.categorizedEntityApi.findCategorizedEntity(projectId, opts);
};

interface ICategorizedEntitiesProps {
  projectId: string | null;
  classification?: string | undefined;
  objectiveId?: string | undefined;
  options?: QueryOption<typeof getCategorizedEntities>;
}

/**
 * React-query hook for getting all Categorized Entities
 *
 * @param {string} projectId - uuid of the project
 * @returns react-query for getCategorizedEntity
 */
export const useCategorizedEntities = ({
  projectId,
  classification,
  objectiveId,
  options,
}: ICategorizedEntitiesProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.categorizedEntityKeys.project(projectId, classification, objectiveId),
    queryFn: () => getCategorizedEntities({ projectId, classification, objectiveId }),
  });
};
