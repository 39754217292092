import { useState } from "react";
// Styles
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import SoftwareVulnerabilityTable from "./tables/SoftwareVulnerabilityTable";

function SoftwareVulnerability() {
  const [, setSelectedRows] = useState([]);

  const vulnerabilityHeader = <Title id="SoftwareVulnerability_title">Vulnerability Findings</Title>;

  const vulnerabilityMain = <SoftwareVulnerabilityTable setSelectedRows={setSelectedRows} />;

  return <ContentLayout pageId="SoftwareVulnerability_page" header={vulnerabilityHeader} main={vulnerabilityMain} />;
}

export default SoftwareVulnerability;
