import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-settings-query-key-factory";

/**
 * Gets the GlobalSettings objects
 */
export const getGlobalSettings = () => {
  return Brm.globalSettingApi.findGlobalSetting();
};

type FuncType = typeof getGlobalSettings;

interface ISettingsProps {
  options?: QueryOption<FuncType>;
}

/**
 * Custom hook for getting the GlobalSettings objects
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useGlobalSettings = ({ options = {} }: ISettingsProps = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.globalSettingKeys.all,
    queryFn: () => getGlobalSettings(),
  });
};
