/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { toast, Toaster, ToastBar } from "react-hot-toast";

// Components
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { PageSpinner } from "components/elements/PageSpinner";
import { ErrorBoundary } from "react-error-boundary";

// Providers
import { ThemeProvider } from "styled-components";

// Styles
import GlobalStyles from "themes/GlobalStyles";

// Misc
import defaultTheme from "themes/default";
import { queryClient } from "libs/react-query";
import { DismissButton } from "components/elements";
import { HiX } from "react-icons/hi";

// Libs
import { initializeRecoilState } from "libs/recoil";

const ErrorFallback = () => {
  return (
    <Alert variant="danger">
      <Alert.Heading>Oh Snap! Something went wrong :(</Alert.Heading>
      <p>
        <Button size="sm" variant="link" onClick={() => window.location.reload()}>
          Refresh
        </Button>
      </p>
    </Alert>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense fallback={<PageSpinner text="Loading BRM Data..." />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
          <ThemeProvider theme={defaultTheme}>
            <GlobalStyles />
            <RecoilRoot initializeState={initializeRecoilState}>
              <Toaster position="top-center">
                {(t) => (
                  <ToastBar toast={t}>
                    {({ icon, message }) => (
                      <>
                        {icon}
                        {message}
                        {t.type !== "loading" && (
                          <DismissButton type="button" onClick={() => toast.dismiss(t.id)}>
                            <HiX />
                          </DismissButton>
                        )}
                      </>
                    )}
                  </ToastBar>
                )}
              </Toaster>
              <BrowserRouter>{children}</BrowserRouter>
            </RecoilRoot>
          </ThemeProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
