import * as React from "react";

import { useRoles } from "features/brm/hooks/useRoles";
import { AdminApi, useAdminProjects } from "features/brm";
import { isDeletedStatus } from "utils/filter-utils";

export const useRoleProjects = () => {
  const { isAdmin, isSuperAdmin, userId, userRole } = useRoles();

  const {
    data: superAdminProjects,
    isError: isSuperAdminProjectsError,
    error: superAdminProjectsError,
  } = AdminApi.useProjects({
    options: { enabled: isSuperAdmin },
  });

  const {
    data: adminProjects,
    isError: isAdminProjectsError,
    error: adminProjectsError,
  } = useAdminProjects(userId, userRole);

  const {
    data: userProjects,
    isError: isUserProjectsError,
    error: userProjectsError,
  } = AdminApi.useUserProjects({
    userId,
    options: {
      enabled: !(isSuperAdmin || isAdmin),
      select: React.useCallback((data) => data?.filter((item) => !isDeletedStatus(item)), []),
    },
  });

  const projects = React.useMemo(() => {
    if (!(isSuperAdmin || isAdmin) && userProjects) {
      return userProjects;
    }
    if (isSuperAdmin && superAdminProjects) {
      return superAdminProjects;
    }
    if (isAdmin && adminProjects) {
      return adminProjects;
    }
    return undefined;
  }, [adminProjects, isAdmin, isSuperAdmin, superAdminProjects, userProjects]);

  return React.useMemo(() => {
    return {
      projects,
      isUserProjectsError,
      isAdminProjectsError,
      isSuperAdminProjectsError,
      userProjectsError,
      adminProjectsError,
      superAdminProjectsError,
    };
  }, [
    adminProjectsError,
    isAdminProjectsError,
    isSuperAdminProjectsError,
    isUserProjectsError,
    projects,
    superAdminProjectsError,
    userProjectsError,
  ]);
};
