import * as React from "react";
import toast from "react-hot-toast";

export const useExportFile = () => {
  const downloadFile = React.useCallback(({ data, format, name }) => {
    try {
      const fileFormat = format.toLowerCase();
      const fileName = `${name}.${fileFormat}`;
      const blob = new Blob([data], { type: `application/${fileFormat}` });
      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error("err downloading file: ", err);
      toast.error("Error occurred while downloading audit report");
    }
  }, []);

  return { downloadFile };
};
