import * as React from "react";

// Utils
import {
  createColumnMappedStringEdit,
  createColumnMapped,
  createColumnDefaultRole,
  createColumnOrganization,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import { useRoles } from "hooks";

// Api
import { AdminApi } from "features/brm";

// Components
import ButtonCell from "components/EditComponents/ButtonCell";

// Constants
import { STATUS } from "constants/brm";
import * as FilterUtils from "utils/filter-utils";
import { RoutePath } from "routes/route-paths";

// Local

interface IUserUserOverview {
  userOverviewId: string | undefined;
  userId: string | null;
  navigate: (v: string) => void;
}

export const useUserOverview = ({ userOverviewId, userId, navigate }: IUserUserOverview) => {
  const { isSuperAdmin, isAdmin, userRole } = useRoles();
  const [editMode, setEditMode] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [selectedElement, setSelectedElement] = React.useState({});
  const [statusList] = React.useState([{ id: STATUS.active, name: STATUS.active }]);

  const updatedStatus = React.useRef("");
  const updatedUserName = React.useRef("");
  const updatedLastName = React.useRef("");
  const updatedFirstName = React.useRef("");
  const updatedEmail = React.useRef("");
  const updatedDefaultRole = React.useRef("");

  const { mutateAsync: setUserStatus } = AdminApi.useSetUserStatus();
  const { mutateAsync: setUserEmail } = AdminApi.useSetUserEmail();
  const { mutateAsync: setUserFirstname } = AdminApi.useSetUserFirstname();
  const { mutateAsync: setUserLastname } = AdminApi.useSetUserLastname();
  const { mutateAsync: setUserRole } = AdminApi.useSetUserRole();

  const { data: assignedRoles } = AdminApi.useUserAssignedRoles({ userId: userOverviewId || "" });
  const { data: user } = AdminApi.useUser({ userId: userOverviewId || "" });

  const orgId = user?.organization;

  const { data: organizationName } = AdminApi.useOrganization({
    organizationId: orgId,
    options: {
      enabled: !!orgId,
      select: (data) => (data ? data.name : ""),
    },
  });

  const { data: organizations } = AdminApi.useOrganizations({ options: { enabled: isSuperAdmin } });

  const { data: userOrganization } = AdminApi.useUserPrimaryOrganization({
    userId,
  });
  const { data: userAttachedOrganizations } = AdminApi.useUserAttachedOrganizations({
    userId,
  });

  const organizationData = React.useMemo(() => {
    let userOrgs = userAttachedOrganizations;
    if (userOrgs && !Array.isArray(userOrgs)) {
      userOrgs = [userOrgs];
    }
    if ((Array.isArray(organizations) && organizations.length) || (userOrganization && userOrgs)) {
      const orgData = isSuperAdmin ? organizations : [userOrganization, ...userOrgs];
      if (isSuperAdmin) {
        return orgData;
      }
      return orgData?.filter(FilterUtils.isNotDeletedStatus);
    }
    return undefined;
  }, [isSuperAdmin, organizations, userAttachedOrganizations, userOrganization]);

  /**
   * If we don't have access to the project redirect to the projects list
   */
  React.useEffect(() => {
    // Admin can only view projects in their organization..
    if (isAdmin && organizationData?.some((o: any) => o.id === orgId)) {
      return;
    }

    if (!isSuperAdmin) {
      navigate(RoutePath.Users);
    }
  }, [isAdmin, isSuperAdmin, navigate, organizationData, orgId]);

  const createButton = React.useCallback(
    (cellProps) => (
      <ButtonCell
        selectedRowId={selectedRowId}
        elementId={cellProps.cell.row.original.id}
        handleConfirmEditClick={async () => {
          setEditMode(false);

          // send request to update status
          if (updatedStatus.current && updatedStatus.current !== "Select status") {
            // only perform update if updatedStatus is not an empty string
            await setUserStatus({ userId: selectedRowId, newStatus: updatedStatus.current });
            updatedStatus.current = "";
          }

          // send request to update admin
          if (updatedEmail.current !== "") {
            // only perform update if updatedEmail is not an empty string
            await setUserEmail({ userId: selectedRowId, newEmail: updatedEmail.current });
            updatedEmail.current = "";
          }

          if (updatedFirstName.current !== "") {
            // only perform update if updatedFirstName is not an empty string
            await setUserFirstname({ userId: selectedRowId, newFirstname: updatedFirstName.current });
            updatedFirstName.current = "";
          }
          if (updatedLastName.current !== "") {
            // only perform update if updatedLastName is not an empty string
            await setUserLastname({ userId: selectedRowId, newLastname: updatedLastName.current });
            updatedLastName.current = "";
          }
          // if (updatedUserName !== "") {
          //   // only perform update if updatedUserName is not an empty string
          //   /* TODO ::: USER CANNOT CHANGE THE USERNAME TO EXISTING NAME */
          //   // await handleApi(userApi.setUserUsernameWithHttpInfo(selectedRowId, { body: updatedUserName }));
          // }
          if (updatedDefaultRole.current && updatedDefaultRole.current !== "Select role") {
            await setUserRole({ userId: selectedRowId, role: updatedDefaultRole.current });
            updatedDefaultRole.current = "";
          }
        }}
        setEditMode={setEditMode}
        editMode={editMode}
      />
    ),
    [editMode, selectedRowId, setUserEmail, setUserFirstname, setUserLastname, setUserRole, setUserStatus]
  );

  const columns = React.useMemo(
    () => [
      createColumnMappedStringEdit("username", selectedRowId, false, updatedUserName),
      createColumnMappedStringEdit("firstname", selectedRowId, editMode, updatedFirstName),
      createColumnMappedStringEdit("lastname", selectedRowId, editMode, updatedLastName),
      createColumnMapped(
        "status",
        selectedRowId,
        statusList,
        selectedRowId === userId ? false : editMode,
        updatedStatus
      ),
      createColumnDefaultRole(selectedRowId, assignedRoles, editMode, updatedDefaultRole),
      createColumnMappedStringEdit("email", selectedRowId, editMode, updatedEmail),
      createColumnOrganization("organization"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [selectedRowId, editMode, statusList, userId, assignedRoles, createButton]
  );

  React.useEffect(() => {
    if (user) {
      setSelectedElement(Array.isArray(user) ? undefined : user);
    }
  }, [user]);

  const userList = React.useMemo(() => {
    if (user && userRole && organizationName) {
      return [{ ...user, organization: organizationName }];
    }
    return [];
  }, [user, userRole, organizationName]);

  return {
    assignedRoles,
    userList,
    user,
    selectedElement,
    columns,
    setSelectedElement,
    setSelectedRowId,
  };
};
