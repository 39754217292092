import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

interface IGetSystemAsset {
  saId: string;
}

export const getSystemAsset = async ({ saId }: IGetSystemAsset) => {
  let systemAssetToDisplay = {} as any;
  const data = await Brm.systemAssetApi.getSystemAssetByID(saId);
  systemAssetToDisplay = data;
  systemAssetToDisplay.owner = await Brm.systemAssetApi.getSystemAssetOwner(saId);
  systemAssetToDisplay.satype = await Brm.systemAssetApi.getSystemAssetSatype(saId);
  return [systemAssetToDisplay];
};

interface IUseSystemAsset {
  saId: string;
  setSelectedElement: (element: any) => void;
  options?: QueryOption<typeof getSystemAsset>;
}

/**
 * React-query hook for getting  System Asset by id
 *
 * @param {string} saId - uuid of System Asset
 * @returns react-query for getSystemAsset
 */
export const useSystemAsset = ({ saId, setSelectedElement, options }: IUseSystemAsset) => {
  return ReactQuery.useQuery({
    enabled: !!saId,
    cacheTime: 0,
    onSettled: (data) => {
      setSelectedElement(data && data[0]);
    },
    ...options,
    queryKey: KeyFactory.systemAssetKeys.detail(saId),
    queryFn: () => getSystemAsset({ saId }),
  });
};
