import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./attacker-query-key-factory";

export interface IUseDeleteAttacker {
  options?: MutationOption<typeof deleteAttacker>;
}

/**
 * Deletes attacker with the given uuid
 * @param {string} attackerId uuid of the attacker
 * @returns
 */
export const deleteAttacker = ({ attackerId }: { attackerId: string }) => {
  return Brm.attackerApi.deleteAttacker(attackerId);
};

/**
 * Custom hook to delete an existing attacker
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteAttacker = ({ options }: IUseDeleteAttacker = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.attackerKeys.all);
    },
    ...options,
    mutationFn: deleteAttacker,
  });
};
