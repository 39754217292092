import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./attacker-query-key-factory";

export interface ISetAttackerOpportunity {
  attackerId: string;
  opportunity: string;
}
/**
 * sets opportunity of the selected attacker
 * @param {string} attackerId - uuid of the attacker
 * @param {string} opportunity - string value of available opportunity
 *
 */
export const setAttackerOpportunity = ({ attackerId, opportunity }: ISetAttackerOpportunity) => {
  return Brm.attackerApi.setAttackerAvailableOpportunity(attackerId, { body: opportunity });
};
export interface IUseSetAttackerOpportunity {
  options?: MutationOption<typeof setAttackerOpportunity>;
}
/**
 * Custom hook to change the opportunity of a existing Attacker
 * @param {object} options hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerOpportunity = ({ options }: IUseSetAttackerOpportunity = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.attackerKeys.all);
    },
    ...options,
    mutationFn: setAttackerOpportunity,
  });
};
