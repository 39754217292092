import * as React from "react";
// import PropTypes from "prop-types";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import { ActionButton } from "components/elements";
import Buttons from "components/elements/TableButtonsDivStyled";
import { TYPE } from "constants/brm";
import ShowHideButton from "components/elements/ShowHideButton";
import { ResetPasswordModal } from "features/admin/components/modals/ResetPasswordModal/ResetPasswordModal";
import { ROLE_SUPER_ADMIN } from "services/user";
import { useRoles } from "hooks";
import { CloseButton } from "./CloseButton";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
  PasswordResetDiv,
  Title,
} from "./UserFullDetails.styles";

interface IUserFullDetailsProp {
  compName: string;
  selectedElement: any;
  showBackLink: boolean;
  onCloseClick?: () => void;
  data: any;
  columns: any;
  setSelectedElement: (e: any) => void;
  setSelectedRowId: (e: any) => void;
  detailsComp: React.ReactNode;
  hideSummaryTable?: boolean;
}

export const UserFullDetails = ({
  compName,
  selectedElement,
  showBackLink,
  onCloseClick = () => {},
  data,
  columns,
  setSelectedElement,
  setSelectedRowId,
  detailsComp,
  hideSummaryTable = true,
}: IUserFullDetailsProp) => {
  const [showSummaryTable, setShowSummaryTable] = React.useState(true);
  const [showResetPasswordModal, setShowResetPasswordModal] = React.useState(false);
  const { userId, isSuperAdmin } = useRoles();

  React.useEffect(() => {
    setShowSummaryTable(hideSummaryTable);
  }, [hideSummaryTable]);

  const handlePasswordReset = () => {
    setShowResetPasswordModal(true);
  };

  const handlePasswordResetModalClose = () => {
    setShowResetPasswordModal(false);
  };

  return (
    <>
      <ResetPasswordModal user={selectedElement} onHide={handlePasswordResetModalClose} show={showResetPasswordModal} />

      <Title id={`${compName}_title`}>
        <span>
          {selectedElement.name || selectedElement.username}
          {!(
            compName === TYPE.elementCat ||
            compName === TYPE.structuralCat ||
            compName === TYPE.classificationCat
          ) && <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />}
        </span>
        {(data[0] && !data[0].assignedRole.includes(ROLE_SUPER_ADMIN)) || // you can change it as long the user isn't superadmin
        (data[0] && data[0].id === userId) || // if this is the current user you can change your own password
        (data[0] && data[0].assignedRole.includes(ROLE_SUPER_ADMIN) && isSuperAdmin) ? ( // if the user is a super user and so are you
          <PasswordResetDiv>
            <ActionButton size="sm" onClick={handlePasswordReset}>
              Reset Password
            </ActionButton>
            <Buttons id={`${compName}_buttons`}>{!showBackLink && <CloseButton onClick={onCloseClick} />}</Buttons>
          </PasswordResetDiv>
        ) : null}
      </Title>

      {showSummaryTable
        ? !(compName === TYPE.elementCat || compName === TYPE.structuralCat || compName === TYPE.classificationCat) && (
            <DetailsContainerSingleTable id={`${compName}_table`}>
              <BrmSingleElementTable
                data={data}
                columns={columns}
                setSelectedElement={setSelectedElement}
                setSelectedRowId={setSelectedRowId}
                customProps={{ id: `${compName}_fullDetailsTable` }}
                elementName={compName}
              />
            </DetailsContainerSingleTable>
          )
        : null}

      {selectedElement.id && (
        <DetailsContainerParent>
          <DetailsContainer id={`${compName}_detailsPanel`}>{selectedElement && detailsComp}</DetailsContainer>
        </DetailsContainerParent>
      )}
    </>
  );
};

// UserFullDetails.propTypes = {
//   columns: PropTypes.array.isRequired,
//   compName: PropTypes.any,
//   data: PropTypes.array.isRequired,
//   detailsComp: PropTypes.object.isRequired,
//   onCloseClick: PropTypes.func,
//   selectedElement: PropTypes.shape({
//     id: PropTypes.string,
//     name: PropTypes.string,
//     username: PropTypes.string,
//   }),
//   setSelectedElement: PropTypes.func.isRequired,
//   setSelectedRowId: PropTypes.func.isRequired,
//   showBackLink: PropTypes.bool,
//   hideSummaryTable: PropTypes.bool,
// };
