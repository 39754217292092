import { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import PropTypes from "prop-types";

const HtmlHelpViewer = ({ fileUrl = "help-html/First_Topic.htm" }) => {
  const [, setHtml] = useState("");

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetch(fileUrl, { signal })
      .then((r) => r.text())
      .then((t) => {
        setHtml(DOMPurify.sanitize(t));
      });

    return function cleanup() {
      controller.abort();
    };
  }, [fileUrl]);

  // eslint-disable-next-line react/no-danger
  // return <div dangerouslySetInnerHTML={{ __html: html }} data-testid="htmlHelpViewer" />;

  return <iframe src={fileUrl} title="iframe" height="400" width="480" data-testid="htmlHelpViewer" />;
};

HtmlHelpViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};
export default HtmlHelpViewer;
