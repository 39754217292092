import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { capabilityApi } from "services/brm/system-service";
import handleApi from "services/brm/api-util";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import ButtonCell from "components/EditComponents/ButtonCell";
import { ElementFullDetails, SystemApi } from "features/brm";
import { TYPE } from "constants/brm";

import {
  createColumnBooleanMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import * as ReactQuery from "@tanstack/react-query";
import { LoadingSpinner as Loading } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";
import { RoutePath } from "routes/route-paths";
import CapabilityDetails from "../details/CapabilityDetails";

function CapabilityFullDetails() {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);

  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedCategory = useRef("");
  const updatedIsInternal = useRef("");

  const queryClient = ReactQuery.useQueryClient();
  const { data: elementCats } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });
  const { data: capability } = SystemApi.useCapability({
    capabilityId: id,
    options: { enabled: isRiskAnalyst || isSystemEngineer },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedRowId}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedIsInternal.current !== "") {
              await handleApi(
                capabilityApi.setCapabilityIsInternalWithHttpInfo(selectedRowId, {
                  body: updatedIsInternal.current,
                })
              );
              updatedIsInternal.current = "";
            }

            if (updatedCategory.current !== "") {
              await handleApi(
                capabilityApi.setCapabilityCategoryWithHttpInfo(selectedRowId, {
                  body: updatedCategory.current,
                })
              );
              updatedCategory.current = "";
            }
            queryClient.invalidateQueries(SystemApi.capabilityKeys.capability(id));
            queryClient.invalidateQueries(SystemApi.capabilityKeys.project(projectId));
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [selectedRowId, editMode, queryClient, id, projectId]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnCategoryMapped("category", selectedRowId, elementCats, editMode, updatedCategory),
        createColumnBooleanMapped("isInternal", selectedRowId, editMode, updatedIsInternal),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnCategoryMapped("category", selectedRowId, elementCats, false, updatedCategory),
      createColumnBooleanMapped("isInternal", selectedRowId, false, updatedIsInternal),
    ];
  }, [isSystemEngineer, selectedRowId, elementCats, editMode, createButton]);

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(RoutePath.Capabilities);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    if (capability) {
      setSelectedElement(capability);
    }
  }, [capability]);

  if (elementCats && capability) {
    const detailsComp = <CapabilityDetails selectedElement={capability} />;

    return (
      <ElementFullDetails
        showBackLink
        data={[capability]}
        columns={columns}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        setSelectedRowId={setSelectedRowId}
        detailsComp={detailsComp}
        compName={TYPE.capability}
      />
    );
  }
  return <Loading />;
}

export default CapabilityFullDetails;
