import { isProjectBusyState, projectStatusState, userRoleState } from "atoms/atoms-admin";
import { isSubmissionPendingState } from "atoms/atoms-importer";
import { STATUS } from "constants/brm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { projectApi } from "services/brm";
import { isSystemEngineer } from "services/user";
import { AdminApi } from "features/brm";

export const useProjectPutStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ projectId, status }) => {
      return projectApi.setProjectStatus(projectId, { body: status });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(AdminApi.projectKeys.all);
      },
    }
  );
};

export const useProjectGetStatusForImport = (projectId, submissionData) => {
  const userRole = useRecoilValue(userRoleState);
  const setIsBusy = useSetRecoilState(isProjectBusyState);
  const setProjectStatus = useSetRecoilState(projectStatusState);
  const isSubmissionPending = useRecoilValue(isSubmissionPendingState);

  return useQuery(AdminApi.projectKeys.statusImport(projectId), () => projectApi.getProjectStatus(projectId), {
    enabled: !!projectId && !!submissionData && isSystemEngineer(userRole),
    onSuccess: (data) => {
      setProjectStatus(data.result);
      if (data.result !== STATUS.active) {
        setIsBusy(true);
      } else setIsBusy(false);
    },
    refetchInterval: isSubmissionPending ? 3000 : false,
  });
};
