/* eslint-disable import/prefer-default-export */
import styled from "styled-components/macro";
import Dropdown from "react-bootstrap/Dropdown";

interface ContextMenuProps {
  show: boolean;
  x: number;
  y: number;
  items: any[];
}

export const ContextMenuContainer = styled.div<ContextMenuProps>`
  background-color: white;
  display: ${({ show, items }) => (show && items.length > 0 ? "block" : "none")};
  top: ${({ y }) => `${y}px`};
  left: ${({ x }) => `${x}px`};
  position: fixed;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 9999;
`;

export const ContextDivider = styled.div<typeof Dropdown["Divider"]>`
  height: 0;
  margin: 0.1rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
`;

export const ContextItem = styled.div<typeof Dropdown["Item"]>`
  padding: 0.25rem 1rem;
`;
