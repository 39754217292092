import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./variant-query-key-factory";

interface IDeleteVariant {
  variantId: string;
}

/**
 * Deletes a variant with the given uuid
 * @param {string} variantId uuid of the variant
 * @returns
 */
export const deleteVariant = ({ variantId }: IDeleteVariant) => {
  return Brm.variantApi.deleteVariant(variantId);
};

export interface IUseDeleteVariant {
  options?: MutationOption<typeof deleteVariant>;
}

/**
 * Custom hook to delete an existing variant
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteVariant = ({ options }: IUseDeleteVariant = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: deleteVariant,
  });
};
