import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import AccessPointCategoryTable from "./tables/AccessPointCategoryTable";

function AccessPointCategory() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const accessPointCatHeader = <Title id="AccessPointCategory_title">Access Point Categories</Title>;

  const accessPointCatMain = (
    <>
      <AccessPointCategoryTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="AccessPointCategory_page" header={accessPointCatHeader} main={accessPointCatMain} />;
}

export default AccessPointCategory;
