/* eslint-disable import/prefer-default-export */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * Note for attack mechanism
 *
 * @param {string} mechanism - name of attack mechanism
 * @returns note of attack mechanism
 */
export const getAttackMechanismNote = async ({ mechanism }) => {
  const data = await Brm.gtMetaApi.findGtMeta({ name: mechanism });
  if (Array.isArray(data[0].data.note)) {
    return data[0].data.note;
  }
  return [data[0].data.note] || [""];
};

/**
 * Custom hook to get note of attack mechanism
 *
 * @param {string} mechanism - name of attack mechanism
 * @param {object} config - react-query configuration
 * @returns
 */
export const useAttackMechanismNote = ({ mechanism, config = {} }) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["attackMechansimNote", mechanism],
    queryFn: () => getAttackMechanismNote({ mechanism }),
  });
};
