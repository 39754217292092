import PropTypes from "prop-types";
import AddIcon from "assets/icons/check.svg";
import InLineEditButtonConfirm from "components/EditComponents/InLineEditButtonConfirmStyled";
import ImgSized from "components/elements/ImgSized";

const ConfirmButton = ({ onClick }) => (
  <>
    <InLineEditButtonConfirm id="ConfirmButton_button" onClick={onClick}>
      <ImgSized id="ConfirmButton_confirmIcon" sm src={AddIcon} alt="confirm" />
    </InLineEditButtonConfirm>
  </>
);

ConfirmButton.propTypes = {
  onClick: PropTypes.func,
};

export default ConfirmButton;
