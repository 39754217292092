import { atom, selector } from "recoil";
import * as BrmGql from "generated/graphql";

// export const autoMitigateState = atom({
//   key: "autoMitigateState",
//   default: "None",
// });

export const riskUpdatedState = atom({
  key: "riskUpdated",
  default: false,
});

export const fromRiskMatrixState = atom({
  key: "fromRiskMatrix",
  default: { navFromRiskMatrix: false, filter: null },
});

// export const riskOutdatedState = atom({
//   key: "riskOutdatedState",
//   default: false,
// });

export const riskFromNavigateState = atom({
  key: "riskFromNavigateState",
  default: false,
});

export const riskCalcStatus = atom<BrmGql.CompletionStatus | null>({
  key: "riskCalcStatus",
  default: null,
});

/**
 * Acts as a toggle for cell click on risk matrix
 */
export const isRiskMatrixCellClickedState = atom({
  key: "isRiskMatrixCellClickedState",
  default: false,
});

export const riskMatrixRowState = selector({
  key: "riskMatrixRowState",
  get: () => {
    return {
      row1: [
        {
          risk: {
            id: "R1",
            name: "Very Low",
            count: 0,
          },
          impact: {
            id: "I1",
            name: "Negligible",
          },
          likelihood: {
            id: "L5",
            name: "Near Certainty",
          },
          location: {
            row: 1,
            column: 1,
          },
        },
        {
          risk: {
            id: "R2",
            name: "Low",
            count: 0,
          },
          impact: {
            id: "I2",
            name: "Minor",
          },
          likelihood: {
            id: "L5",
            name: "Near Certainty",
          },
          location: {
            row: 1,
            column: 2,
          },
        },
        {
          risk: {
            id: "R3",
            name: "Moderate",
            count: 0,
          },
          impact: {
            id: "I3",
            name: "Moderate",
          },
          likelihood: {
            id: "L5",
            name: "Near Certainty",
          },
          location: {
            row: 1,
            column: 3,
          },
        },
        {
          risk: {
            id: "R4",
            name: "High",
            count: 0,
          },
          impact: {
            id: "I4",
            name: "Major",
          },
          likelihood: {
            id: "L5",
            name: "Near Certainty",
          },
          location: {
            row: 1,
            column: 4,
          },
        },
        {
          risk: {
            id: "R5",
            name: "Very High",
            count: 0,
          },
          impact: {
            id: "I5",
            name: "Catastrophic",
          },
          likelihood: {
            id: "L5",
            name: "Near Certainty",
          },
          location: {
            row: 1,
            column: 5,
          },
        },
      ],

      // Row 2

      row2: [
        {
          risk: {
            id: "R1",
            name: "Very Low",
            count: 0,
          },
          impact: {
            id: "I1",
            name: "Negligible",
          },
          likelihood: {
            id: "L4",
            name: "Probable",
          },
          location: {
            row: 2,
            column: 1,
          },
        },
        {
          risk: {
            id: "R2",
            name: "Low",
            count: 0,
          },
          impact: {
            id: "I2",
            name: "Minor",
          },
          likelihood: {
            id: "L4",
            name: "Probable",
          },
          location: {
            row: 2,
            column: 2,
          },
        },
        {
          risk: {
            id: "R3",
            name: "Moderate",
            count: 0,
          },
          impact: {
            id: "I3",
            name: "Moderate",
          },
          likelihood: {
            id: "L4",
            name: "Probable",
          },
          location: {
            row: 2,
            column: 3,
          },
        },
        {
          risk: {
            id: "R4",
            name: "High",
            count: 0,
          },
          impact: {
            id: "I4",
            name: "Major",
          },
          likelihood: {
            id: "L4",
            name: "Probable",
          },
          location: {
            row: 2,
            column: 4,
          },
        },
        {
          risk: {
            id: "R5",
            name: "Very High",
            count: 0,
          },
          impact: {
            id: "I5",
            name: "Catastrophic",
          },
          likelihood: {
            id: "L4",
            name: "Probable",
          },
          location: {
            row: 2,
            column: 5,
          },
        },
      ],

      // Row 3
      row3: [
        {
          risk: {
            id: "R1",
            name: "Very Low",
            count: 0,
          },
          impact: {
            id: "I1",
            name: "Negligible",
          },
          likelihood: {
            id: "L3",
            name: "Occasional",
          },
          location: {
            row: 3,
            column: 1,
          },
        },
        {
          risk: {
            id: "R2",
            name: "Low",
            count: 0,
          },
          impact: {
            id: "I2",
            name: "Minor",
          },
          likelihood: {
            id: "L3",
            name: "Occasional",
          },
          location: {
            row: 3,
            column: 2,
          },
        },
        {
          risk: {
            id: "R3",
            name: "Moderate",
            count: 0,
          },
          impact: {
            id: "I3",
            name: "Moderate",
          },
          likelihood: {
            id: "L3",
            name: "Occasional",
          },
          location: {
            row: 3,
            column: 3,
          },
        },
        {
          risk: {
            id: "R3",
            name: "Moderate",
            count: 0,
          },
          impact: {
            id: "I4",
            name: "Major",
          },
          likelihood: {
            id: "L3",
            name: "Occasional",
          },
          location: {
            row: 3,
            column: 4,
          },
        },
        {
          risk: {
            id: "R4",
            name: "High",
            count: 0,
          },
          impact: {
            id: "I5",
            name: "Catastrophic",
          },
          likelihood: {
            id: "L3",
            name: "Occasional",
          },
          location: {
            row: 3,
            column: 5,
          },
        },
      ],

      // Row 4
      row4: [
        {
          risk: {
            id: "R1",
            name: "Very Low",
            count: 0,
          },
          impact: {
            id: "I1",
            name: "Negligible",
          },
          likelihood: {
            id: "L2",
            name: "Remote",
          },
          location: {
            row: 4,
            column: 1,
          },
        },
        {
          risk: {
            id: "R2",
            name: "Low",
            count: 0,
          },
          impact: {
            id: "I2",
            name: "Minor",
          },
          likelihood: {
            id: "L2",
            name: "Remote",
          },
          location: {
            row: 4,
            column: 2,
          },
        },
        {
          risk: {
            id: "R2",
            name: "Low",
            count: 0,
          },
          impact: {
            id: "I3",
            name: "Moderate",
          },
          likelihood: {
            id: "L2",
            name: "Remote",
          },
          location: {
            row: 4,
            column: 3,
          },
        },
        {
          risk: {
            id: "R2",
            name: "Low",
            count: 0,
          },
          impact: {
            id: "I4",
            name: "Major",
          },
          likelihood: {
            id: "L2",
            name: "Remote",
          },
          location: {
            row: 4,
            column: 4,
          },
        },
        {
          risk: {
            id: "R3",
            name: "Moderate",
            count: 0,
          },
          impact: {
            id: "I5",
            name: "Catastrophic",
          },
          likelihood: {
            id: "L2",
            name: "Remote",
          },
          location: {
            row: 4,
            column: 5,
          },
        },
      ],

      // Row 5
      row5: [
        {
          risk: {
            id: "R1",
            name: "Very Low",
            count: 0,
          },
          impact: {
            id: "I1",
            name: "Negligible",
          },
          likelihood: {
            id: "L1",
            name: "Improbable",
          },
          location: {
            row: 5,
            column: 1,
          },
        },
        {
          risk: {
            id: "R1",
            name: "Very Low",
            count: 0,
          },
          impact: {
            id: "I2",
            name: "Minor",
          },
          likelihood: {
            id: "L1",
            name: "Improbable",
          },
          location: {
            row: 5,
            column: 2,
          },
        },
        {
          risk: {
            id: "R1",
            name: "Very Low",
            count: 0,
          },
          impact: {
            id: "I3",
            name: "Moderate",
          },
          likelihood: {
            id: "L1",
            name: "Improbable",
          },
          location: {
            row: 5,
            column: 3,
          },
        },
        {
          risk: {
            id: "R2",
            name: "Low",
            count: 0,
          },
          impact: {
            id: "I4",
            name: "Major",
          },
          likelihood: {
            id: "L1",
            name: "Improbable",
          },
          location: {
            row: 5,
            column: 4,
          },
        },
        {
          risk: {
            id: "R2",
            name: "Low",
            count: 0,
          },
          impact: {
            id: "I5",
            name: "Catastrophic",
          },
          likelihood: {
            id: "L1",
            name: "Improbable",
          },
          location: {
            row: 5,
            column: 5,
          },
        },
      ],
    };
  },
});
