import { useState } from "react";
// Styles
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

// Local
import EntryPointTable from "./tables/EntryPointTable";

function EntryPoint() {
  const [, setSelectedRows] = useState([]);

  const entryPointHeader = <Title id="EntryPoint_title">Entry Points</Title>;

  const entryPointMain = (
    <>
      <EntryPointTable setSelectedRows={setSelectedRows} />
    </>
  );

  return <ContentLayout pageId="EntryPoint_page" header={entryPointHeader} main={entryPointMain} />;
}

export default EntryPoint;
