/* eslint-disable import/prefer-default-export */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./attack-query-key-factory";

export const getAttacks = ({ projectId, variantId }: { projectId: string; variantId: string }) => {
  return Brm.attackApi.findAttack(projectId, { variant: variantId });
};

interface IUseAttacks {
  projectId: string;
  variantId: string;
  options?: QueryOption<typeof getAttacks>;
}

/**
 * Custom hook to retreive an attacks.
 *
 * @param {string} projectId - uuid of a projectId
 * @param {string} variantId - uuid of a projectId variant
 * @param {string} options - uuid of the current variant
 * @returns react-query for findAttackTactic
 *
 */
export const useAttacks = ({ projectId, variantId, options = {} }: IUseAttacks) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.attackKeys.attacks(projectId, variantId),
    queryFn: () => getAttacks({ projectId, variantId }),
  });
};
