import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

export interface ISetSystemAssetOwner {
  saId: string;
  owner: string;
}

export const setSystemAssetOwner = ({ saId, owner }: ISetSystemAssetOwner) => {
  return Brm.systemAssetApi.setSystemAssetOwner(saId, { body: owner });
};

export interface IUseSetSystemAssetOwner {
  options?: MutationOption<typeof setSystemAssetOwner>;
}

/**
 * Custom hook to change the owner of a existing system asset
 * @returns react-query ReactQuery.useMutation
 */
// eslint-disable-next-line import/prefer-default-export
export const useSetSystemAssetOwner = ({ options }: IUseSetSystemAssetOwner = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.systemAssetKeys.all);
    },
    ...options,
    mutationFn: setSystemAssetOwner,
  });
};
