import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import AssetCategoryTable from "./tables/AssetCategoryTable";

function AssetCategory() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const assetCatHeader = <Title id="AssetCategory_title">Asset Categories</Title>;

  const assetCatMain = (
    <AssetCategoryTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
  );

  return <ContentLayout pageId="AssetCategory_page" header={assetCatHeader} main={assetCatMain} />;
}

export default AssetCategory;
