export function convertToLevel(level, identifier) {
  let convertedLevel = "-";

  switch (level) {
    case "zero": {
      if (identifier === "likelihood") {
        convertedLevel = "-";
      } else if (identifier === "impact") {
        convertedLevel = "disabled";
      }
      break;
    }
    case "very low": {
      if (identifier === "likelihood") {
        convertedLevel = "improbable";
      } else if (identifier === "impact") {
        convertedLevel = "negligible";
      }
      break;
    }
    case "low": {
      if (identifier === "likelihood") {
        convertedLevel = "remote";
      } else if (identifier === "impact") {
        convertedLevel = "minor";
      }
      break;
    }
    case "moderate": {
      if (identifier === "likelihood") {
        convertedLevel = "occasional";
      } else if (identifier === "impact") {
        convertedLevel = "moderate";
      }
      break;
    }
    case "high": {
      if (identifier === "likelihood") {
        convertedLevel = "probable";
      } else if (identifier === "impact") {
        convertedLevel = "major";
      }
      break;
    }
    case "very high": {
      if (identifier === "likelihood") {
        convertedLevel = "near certainty";
      } else if (identifier === "impact") {
        convertedLevel = "catastrophic";
      }
      break;
    }
    default:
      break;
  }
  return convertedLevel;
}

export function convertLevelForBackend(level) {
  let convertedLevel = "";

  switch (level) {
    case "negligible":
      convertedLevel = "very low";
      break;
    case "improbable":
      convertedLevel = "very low";
      break;
    case "minor":
      convertedLevel = "low";
      break;
    case "remote":
      convertedLevel = "low";
      break;
    case "moderate":
      convertedLevel = "moderate";
      break;
    case "occasional":
      convertedLevel = "moderate";
      break;
    case "major":
      convertedLevel = "high";
      break;
    case "probable":
      convertedLevel = "high";
      break;
    case "catastrophic":
      convertedLevel = "very high";
      break;
    case "near certainty":
      convertedLevel = "very high";
      break;
    default:
      break;
  }
  return convertedLevel;
}
