import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./exchange-query-key-factory";

/**
 * Retrieve datatype for an exchange
 *
 * @param {string} exchangeId - uuid of exchange
 * @returns list of all persons within the given exchange
 */
export const getExchangeData = ({ exchangeId }: { exchangeId: string | null }) => {
  return exchangeId === null
    ? Promise.reject(new Error("Invalid exchangeId id"))
    : Brm.exchangeApi.getExchangeData(exchangeId);
};

interface IExchangeDataProps {
  exchangeId: string | null;
  options?: QueryOption<typeof getExchangeData>;
}

/**
 * Custom-hook to retrieve data for an exchange.
 *
 * @param {string} exchangeId - uuid of exchange
 * @param {object} options - react-query configuration object
 * @returns react-query for findNode
 */
export const useExchangeData = ({ exchangeId, options }: IExchangeDataProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exchangeKeys.data(exchangeId),
    queryFn: () => getExchangeData({ exchangeId }),
  });
};
