import PropTypes from "prop-types";
import RightFloatButton from "./CloseButton.styles";

const CloseButton = ({ onClick }) => (
  <RightFloatButton size="sm" onClick={onClick}>
    <span>&times;</span>
  </RightFloatButton>
);

CloseButton.propTypes = {
  onClick: PropTypes.func,
};
export default CloseButton;
