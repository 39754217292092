import { useQuery } from "@tanstack/react-query";
import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";
import { DetailTableType, SystemApi } from "features/brm";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms";
import { responseAsArray } from "features/brm/api/system/element/utils/responseAsArray";

interface IElementTargetsProps {
  elementId: string;
  elementName: string;
  config: {};
}

interface IGetTaregtsElements {
  elementId: string;
  elementName: string;
  attackerData?: any;
}

interface ITargetsMap {
  [index: string]: (id?: string) => any;
}

export const getElementTargets = async ({ elementId, elementName }: IGetTaregtsElements) => {
  const targetMap: ITargetsMap = {
    [DetailTableType.RISKS.key]: (id) => Brm.riskApi.getRiskAllObject(id),
    [DetailTableType.ASSETS.key]: (id) => Brm.assetApi.getAssetObject(id),
    [DetailTableType.ALLOCATED_CONTROLS.key]: (id) => Brm.allocatedControlApi.getAllocatedControlObject(id),
    [DetailTableType.VULNERABILITIES.key]: (id) => Brm.vulnerabilityApi.getVulnerabilityObject(id),
    [DetailTableType.ATTACKS.key]: (id) => Brm.attackApi.getAttackTarget(id),
    [DetailTableType.UNDESIRED_EVENTS.key]: (id) => Brm.undesiredEventApi.getUndesiredEventObject(id),
    [DetailTableType.THREAT_EVENTS.key]: (id) => Brm.threatEventApi.getThreatEventTarget(id),
    [DetailTableType.ATTACKERS.key]: (id) => Brm.attackerApi.getAttackerAllTarget(id),
    [DetailTableType.SYSTEM_ASSETS.key]: (id) => Brm.systemAssetApi.getSystemAssetOwner(id),
  };

  return responseAsArray({ map: targetMap, elementId, elementType: elementName });
};

export const useElementTargets = ({ elementId, elementName, config = {} }: IElementTargetsProps) => {
  const projectId = useRecoilValue(projectIdState);

  const attackerTargetQuery = BrmGql.useGetAttackerTargetQuery(
    { id: elementId, project: projectId },
    {
      enabled: false, // Enable this request if necessary when it is working in the BE.
    }
  );

  const elementTargets = useQuery({
    ...config,
    queryKey: SystemApi.targetKeys.element(elementId, elementName, attackerTargetQuery.data),
    queryFn: () => getElementTargets({ elementId, elementName, attackerData: attackerTargetQuery.data }),
  });

  if (attackerTargetQuery.isError) {
    return attackerTargetQuery;
  }

  return elementTargets;
};
