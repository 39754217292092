import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

/**
 * Gets a projects bound SAP programs
 * @param {string} projectId - uuid of project
 */
export const getProjectSapPrograms = ({ projectId }: { projectId: string | null }) => {
  return !projectId ? Promise.reject(new Error("Invalid id")) : Brm.projectApi.getProjectSapprogram(projectId);
};

type FunctionType = typeof getProjectSapPrograms;
interface IProjectSapProgams {
  projectId: string;
  options?: QueryOption<FunctionType>;
}

/**
 * Custom hook for getting all bound sap programs for a project
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useGetProjectSapPrograms = ({ projectId, options = {} }: IProjectSapProgams) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.sapPrograms(projectId),
    queryFn: () => getProjectSapPrograms({ projectId }),
  });
};
