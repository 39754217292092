export const knowledgebaseKeys = {
  all: ["knowledgebase"] as const,
  kb: (kbId: string | null) => [...knowledgebaseKeys.all, kbId] as const,
  orgKb: (orgId: string) => [...knowledgebaseKeys.all, "organizationKb", orgId] as const,
};

export const ruleKey = {
  all: "getKbRules" as const,
  kb: (kbId: string | null) => [...ruleKey.all, kbId] as const,
};
