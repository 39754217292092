import * as BrmGql from "generated/graphql";
import { useSetRecoilState } from "recoil";
import { allocatedControlsState } from "atoms/atoms-mitigation";
import { useRoles } from "features/brm";

/**
 * Custom hook to get all allocated controls
 *
 * @param {string} sctmId - uuid of the SCTM
 * @returns react-query
 */
export const useGetAllocatedControls = (sctmId: string) => {
  const { isRiskAnalyst } = useRoles();
  const allocatedControlsList = useSetRecoilState(allocatedControlsState);
  return BrmGql.useGetSctmAllocatedControlsQuery(
    { sctm: sctmId },
    {
      enabled: !!sctmId && isRiskAnalyst,
      onSuccess: (data) => {
        allocatedControlsList(data.allocatedControls);
      },
      onError: () => {},
    }
  );
};
