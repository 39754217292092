import { useProject } from "features/brm";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const useRefCallback = ({
  setDrawerVisible,
  setSideContentType,
}: {
  setDrawerVisible: (v: boolean) => void;
  setSideContentType: (v: any) => void;
}) => {
  const navigate = useNavigate();
  const [currentProject] = useProject();

  const ref = React.useRef<null | HTMLUListElement>();
  const setRef = React.useCallback(
    (element) => {
      const handleClick = (event: any) => {
        let url = "/";
        const type = event?.target?.dataset?.type || "";
        const projectId = currentProject?.id;
        if (type === "project") {
          if (!projectId) {
            return;
          }
          url = event?.target?.dataset?.url.replace(":id", projectId);
        } else {
          url = event?.target?.dataset?.url;
        }

        const context = event?.target?.dataset?.context || "";
        if (context) {
          setDrawerVisible(true);
          setSideContentType(context);
        }
        if (url) {
          navigate(url);
        }
      };

      if (ref.current) {
        ref.current.removeEventListener("click", handleClick);
      }
      if (element) {
        element.addEventListener("click", handleClick);
      }

      ref.current = element;
    },
    [currentProject, navigate, setDrawerVisible, setSideContentType]
  );

  return { setRef };
};
