import * as React from "react";
import * as Recoil from "recoil";

// Yfiles
import "yfiles/yfiles.css";
import * as YFiles from "yfiles";
import yFilesLicense from "license.json";

// Utils
import debounce from "utils/debounce";
// import useEffectDebug from "hooks/useEffectDebug";

// Common
import {
  ClientSideImageExport,
  FileSaveSupport,
  CommonDiagramStyles as CDS,
  useGraphFiltering,
  useGraphComponentContainer,
  useToolTips,
  DiagramOverview,
} from "features/diagram-common";
import { LayoutType } from "features/diagram-constants";

// Local
import { usePrevious } from "hooks";
import { getStartNodes, getReachable } from "../../services/node-scoping";
import * as S from "./AttackDiagramGraphComponent.styles";
// State
import { nodesVisibleInAttackGraphState } from "../../stores";
import {
  useAttackGraphComponent,
  useLabelVisibility,
  useNodeDecorator,
  useUpdateGraph,
  useGraphComponentListeners,
  useAttackDiagramLayout,
  useTooltipFactory,
} from "./hooks";
import { IGraphData } from "../../types";

YFiles.License.value = yFilesLicense;

const clientSideImageExport = new ClientSideImageExport();

interface IAttackDiagramGraphComponentProps {
  toolbar: any;
  graphData: IGraphData;
  nodeFilter: any;
  setNodeFilter: (v: any) => void;
  onNodeAdded: (v: any) => void;
  onNodesRemoved: (v: any) => void;
  onRemoveAllNodes: () => void;
  onAddAllNodes: () => void;
  onFocusOnSelectedNodes: (v: any) => void;
  onHighlightNodes: (v: any) => void;
  highlighted?: string[];
  showOverview: boolean;
  onToggleOverview: () => void;
}

export const AttackDiagramGraphComponent = ({
  toolbar,
  graphData,
  nodeFilter,
  setNodeFilter,
  onNodeAdded,
  onNodesRemoved,
  onRemoveAllNodes,
  onAddAllNodes,
  onFocusOnSelectedNodes,
  onHighlightNodes,
  highlighted = [],
  showOverview,
  onToggleOverview,
}: IAttackDiagramGraphComponentProps) => {
  const prevFilter = usePrevious(nodeFilter);

  // global app state
  const setNodesVisibleInAttackGraph = Recoil.useSetRecoilState(nodesVisibleInAttackGraphState);
  // local state
  const nodeFilterStateRef = React.useRef<any>();
  const [currentLayout, setCurrentLayout] = React.useState(() => LayoutType.CIRCULAR);

  // graph creation.
  const graphComponent = useAttackGraphComponent();
  const graphContainerRef = useGraphComponentContainer(graphComponent);

  // Enable Filtering
  const [, filteredGraph] = useGraphFiltering(
    graphComponent,
    (node) => {
      return nodeFilterStateRef.current ? nodeFilterStateRef.current.isVisible(node, graphComponent.graph) : true;
    },
    () => true
  );

  // React.useEffect(() => {
  //   console.log("graphData", graphData);
  // }, [graphData]);
  const [highlightManager] = React.useState(() => {
    const manager = new YFiles.HighlightIndicatorManager();
    manager.install(graphComponent);
    return manager;
  });

  const handleGraphChanged = React.useCallback(() => {
    setNodesVisibleInAttackGraph(graphComponent.graph.nodes.toArray().map((n) => n.tag.id));

    const { graph } = graphComponent;
    highlightManager.clearHighlights();
    if (highlighted.length > 0 && graph.nodes.size > 0) {
      graph.nodes.forEach((n) => {
        if (highlighted.some((h) => h === n.tag.id)) {
          highlightManager.addHighlight(n);
        }
      });
    }
  }, [setNodesVisibleInAttackGraph, graphComponent, highlightManager, highlighted]);

  useUpdateGraph(graphComponent, graphData, currentLayout, handleGraphChanged);
  const createTooltipContent = useTooltipFactory();
  useToolTips(graphComponent, createTooltipContent, YFiles.GraphItemTypes.NODE);

  const [nodeDnd, setNodeDnd, hasNodeSelection] = useGraphComponentListeners(
    graphComponent,
    onNodeAdded,
    onNodesRemoved
  );

  const [isLabelsVisible, toggleLabelsVisible] = useLabelVisibility(graphComponent.graph);

  const executeLayout = useAttackDiagramLayout(graphComponent, currentLayout);
  const isLabelsVisibleFirstFlag = React.useRef(false);
  const isCurrentLayoutFirstFlag = React.useRef(false);
  useNodeDecorator(graphComponent);

  React.useEffect(() => {
    if ((nodeFilter && prevFilter === undefined) || (nodeFilter && nodeFilter !== prevFilter)) {
      nodeFilterStateRef.current = nodeFilter;
      filteredGraph.nodePredicateChanged();
      handleGraphChanged();
    }
  }, [filteredGraph, handleGraphChanged, nodeFilter, prevFilter]);

  React.useEffect(() => {
    if ((nodeFilter && prevFilter === undefined) || (nodeFilter && nodeFilter !== prevFilter)) {
      executeLayout();
    }
  }, [executeLayout, nodeFilter, prevFilter]);

  React.useEffect(() => {
    if (!isLabelsVisibleFirstFlag.current) {
      isLabelsVisibleFirstFlag.current = true;
      return;
    }
    executeLayout();
  }, [executeLayout, isLabelsVisible]);

  const handleNodeFilterChanged = (newNodeFilterState: any) => {
    setNodeFilter(newNodeFilterState);
  };

  React.useEffect(() => {
    // const { graph } = graphComponent;
    // highlightManager.clearHighlights();
    // if (highlighted.length > 0 && graph.nodes.size > 0) {
    //   graph.nodes.forEach((n) => {
    //     if (highlighted.some((h) => h === n.tag.id)) {
    //       highlightManager.addHighlight(n);
    //     }
    //   });
    // }
    if (highlighted.length === 0) {
      highlightManager.clearHighlights();
    }
  }, [graphComponent, highlightManager, highlighted]);

  /**
   * Export the entire graph as an image using the yfiles native exportImage... hope this holds up to
   * large graphs....
   */
  const exportGraphAsImage = async () => {
    const image = await clientSideImageExport.exportImage(graphComponent.graph, null);
    try {
      FileSaveSupport.save(image.src, "atttackGraph.png");
    } catch (err) {
      console.error("An error occurred while attempting to save attack graph as image ", err);
    }
  };

  /**
   * Should remove all nodes from the graph.
   */
  const removeAllNodes = () => {
    onRemoveAllNodes();
    setNodesVisibleInAttackGraph([]);
  };

  /**
   * This should add the entire attack graph.
   * *  Dangerous this could add thousands of nodes/edges..... It doesn't right now but it will.
   */
  const addAllNodes = () => {
    onAddAllNodes();
  };

  const updateLayout = (layoutType: LayoutType) => {
    setCurrentLayout(layoutType);
  };

  React.useEffect(() => {
    if (!isCurrentLayoutFirstFlag.current) {
      isCurrentLayoutFirstFlag.current = true;
      return;
    }
    executeLayout();
  }, [currentLayout, executeLayout]);

  React.useEffect(() => {
    if (nodeDnd) {
      filteredGraph.nodePredicateChanged();
      handleGraphChanged();
      if (nodeDnd) {
        setNodeDnd(false);
      }
    }
  }, [filteredGraph, handleGraphChanged, nodeDnd, setNodeDnd]);

  const handleFocusOnSelectedNodes = () => {
    const { graph } = graphComponent;

    if (graphComponent.selection.selectedNodes.size > 0) {
      const { selectedNodes } = graphComponent.selection;
      const startScopedNodes = getStartNodes(selectedNodes.toArray(), graph);
      const reachableNodes = getReachable(selectedNodes.toArray(), graph);

      const scopedNodes = new Set<YFiles.INode>();
      startScopedNodes.forEach((n) => scopedNodes.add(n));
      reachableNodes.forEach((n) => scopedNodes.add(n));
      onFocusOnSelectedNodes(Array.from(scopedNodes).map((n) => n.tag));
    }
  };

  const handleHightlight = React.useCallback(() => {
    if (filteredGraph.nodes) {
      const currentNodes = filteredGraph.nodes.toArray().map((n) => n.tag.id);
      onHighlightNodes(currentNodes);
      highlightManager.clearHighlights();
    }
  }, [filteredGraph.nodes, highlightManager, onHighlightNodes]);

  return (
    <CDS.GraphContainer>
      <S.RelativeContainer>
        <CDS.GraphContainer>
          <CDS.ToolbarContainer>
            {toolbar({
              onZoomIn: () => YFiles.ICommand.INCREASE_ZOOM.execute(null, graphComponent),
              onZoomOut: () => YFiles.ICommand.DECREASE_ZOOM.execute(null, graphComponent),
              onResetZoom: () => YFiles.ICommand.ZOOM.execute(1.0, graphComponent),
              onFitContent: () => YFiles.ICommand.FIT_GRAPH_BOUNDS.execute(null, graphComponent),
              onOrganicLayout: () => updateLayout(LayoutType.ORGANIC),
              onCircularLayout: () => updateLayout(LayoutType.CIRCULAR),
              onRadialLayout: () => updateLayout(LayoutType.RADIAL),
              onHierarchicalLayout: () => updateLayout(LayoutType.HIERARCHICAL),
              onOrthogonalLayout: () => updateLayout(LayoutType.ORTHOGONAL),
              onToggleLabels: () => toggleLabelsVisible(),
              onExportAsImage: () => exportGraphAsImage(),
              onRemoveAllNodes: () => removeAllNodes(),
              onAddAllNodes: () => addAllNodes(),
              onFocusOnSelectedNodes: () => handleFocusOnSelectedNodes(),
              onHighlightNodes: () => handleHightlight(),
              onToggleOverview: () => onToggleOverview(),
              showOverview,
              showLabels: isLabelsVisible,
              hasNodeSelection,
              nodeFilterState: nodeFilter,
              onNodeFilterChange: debounce(handleNodeFilterChanged, 500),
              currentLayout,
            })}
          </CDS.ToolbarContainer>
          <CDS.ComponentContainer ref={graphContainerRef} />
          <DiagramOverview
            top={50}
            left={20}
            graphComponent={graphComponent}
            onClose={() => onToggleOverview()}
            visible={showOverview}
          />
        </CDS.GraphContainer>
      </S.RelativeContainer>
    </CDS.GraphContainer>
  );
};
