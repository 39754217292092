import * as React from "react";
import * as User from "services/user";
import * as Recoil from "recoil";
import { userIdState } from "atoms/atoms-admin";
import {
  isRiskAnalyst as isRiskAnalystFunc,
  isSystemEngineer as isSystemEngineerFunc,
  isThreatAnalyst as isThreatAnalystFunc,
} from "services/user";
import { useUserRole } from "features/brm/api/admin/user/getUserRole";

/**
 *
 * @returns true if the user is currently a riskAnalyst otherwise false
 */
// eslint-disable-next-line import/prefer-default-export
export const useRoles = () => {
  const userId = Recoil.useRecoilValue(userIdState);

  // const userRole = Recoil.useRecoilValue(userRoleState);
  const { data: userRole } = useUserRole({
    userId,
    options: {
      select: (data) => data.result,
    },
  });

  const roleBoolean = React.useMemo(() => {
    if (!userRole) {
      return {
        isRiskAnalyst: false,
        isAdmin: false,
        isSuperAdmin: false,
        isControlsCoordinator: false,
        isCyberSecurityExpert: false,
        isSystemEngineer: false,
        isThreatAnalyst: false,
      };
    }
    const isRiskAnalyst = User.isRiskAnalyst(userRole);
    const isAdmin = User.isAdmin(userRole);
    const isSuperAdmin = User.isSuperAdmin(userRole);
    const isControlsCoordinator = User.isControlsCoordinator(userRole);
    const isCyberSecurityExpert = User.isCyberSecurityExpert(userRole);
    const isSystemEngineer = User.isSystemEngineer(userRole);
    const isThreatAnalyst = User.isThreatAnalyst(userRole);
    return {
      isRiskAnalyst,
      isAdmin,
      isSuperAdmin,
      isControlsCoordinator,
      isCyberSecurityExpert,
      isSystemEngineer,
      isThreatAnalyst,
    };
  }, [userRole]);

  const roleHasProjectContext = React.useCallback((role) => {
    return isRiskAnalystFunc(role) || isSystemEngineerFunc(role) || isThreatAnalystFunc(role);
  }, []);

  const currentRoleHasProjectContext = React.useCallback(() => {
    return roleHasProjectContext(userRole);
  }, [roleHasProjectContext, userRole]);

  return React.useMemo(() => {
    return {
      userId,
      userRole,
      isAdmin: roleBoolean.isAdmin,
      isSuperAdmin: roleBoolean.isSuperAdmin,
      isRiskAnalyst: roleBoolean.isRiskAnalyst,
      isControlsCoordinator: roleBoolean.isControlsCoordinator,
      isCyberSecurityExpert: roleBoolean.isCyberSecurityExpert,
      isSystemEngineer: roleBoolean.isSystemEngineer,
      isThreatAnalyst: roleBoolean.isThreatAnalyst,
      currentRoleHasProjectContext,
      roleHasProjectContext,
    };
  }, [
    currentRoleHasProjectContext,
    roleBoolean.isAdmin,
    roleBoolean.isControlsCoordinator,
    roleBoolean.isCyberSecurityExpert,
    roleBoolean.isRiskAnalyst,
    roleBoolean.isSuperAdmin,
    roleBoolean.isSystemEngineer,
    roleBoolean.isThreatAnalyst,
    roleHasProjectContext,
    userId,
    userRole,
  ]);
};
