import * as Recoil from "recoil";
import { selectedInstanceState } from "atoms/atoms-content";
import { NotesPane } from "features/notes";
import { ReactComponent as NotesIcon } from "assets/icons/studio91/sidebars-toolbars/notes.svg";
import TitledPane from "components/elements/TitledPane";

// interface ISelectedInstance {
//   type: string;
//   name: string;
//   id: string;
// }

export const SelectedElementNotesPane = () => {
  const selectedInstance = Recoil.useRecoilValue(selectedInstanceState);
  return (
    <TitledPane title="Notes" iconComp={NotesIcon} alt="Notes Icon">
      <NotesPane type={selectedInstance?.type} name={selectedInstance?.name} id={selectedInstance?.id} />
    </TitledPane>
  );
};
