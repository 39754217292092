import * as React from "react";
// import useToggle from "hooks/useToggle";
import { ThemeContext } from "styled-components";
import { ResizeControl } from "components/layout/ResizeControl";
import { contextSidebarContentState, CONTEXT_SIDEBAR_CONTENT, DrawerContentType } from "features/brm";
import * as Recoil from "recoil";
import { rightSidebarOpenState } from "atoms";
import usePrevious from "hooks/usePrevious";
import * as S from "./RightSidebar.styles";
import { RightSidebarToolbar } from "./RightSidebarToolbar";
import { RightSidebarDrawer } from "./RightSidebarDrawer";

interface IRightSidebarProps {
  show?: boolean;
}

const rightResizeControl = {
  location: "right",
  sidebarWidth: "--rightSidebarWidth",
  sidebarMinWidth: "--rightSidebarMinWidth",
  sidebarFlyoutWidth: "--rightSidebarFlyoutWidth",
};

const getProperty = (property: string) => {
  const root = document.documentElement;
  const rootStyle = getComputedStyle(root);
  return parseInt(rootStyle.getPropertyValue(property), 10);
};

const setProperty = (property: string, value: number) => {
  const root = document.documentElement;
  root.style.setProperty(property, `${value}px`);
};

const getDrawerWidth = () => {
  return getProperty(rightResizeControl.sidebarWidth);
};

const setDrawerWidth = (width: number) => {
  setProperty(rightResizeControl.sidebarWidth, width);
};

const getPreferredWidth = () => {
  return getProperty(rightResizeControl.sidebarFlyoutWidth);
};

const setPreferredWidth = (width: number) => {
  setProperty(rightResizeControl.sidebarFlyoutWidth, width);
};

const getDrawerMinWidth = () => {
  return getProperty(rightResizeControl.sidebarMinWidth);
};

const getResizeThreshold = () => {
  return getProperty("--resizeSizeThreshold");
};

const getToolbarWidth = () => {
  return getProperty("--navigationToolbarWidth");
};

const ICON_MAPPER_MIN_WIDTH = 705;

export const RightSidebar = ({ show = false }: IRightSidebarProps) => {
  const theme = React.useContext(ThemeContext);
  const [isDragging, setIsDragging] = React.useState(false);
  const contentType = Recoil.useRecoilValue(contextSidebarContentState);
  const prevContentType = usePrevious(contentType);
  const [isDrawerVisible, setDrawerVisible] = Recoil.useRecoilState(rightSidebarOpenState);

  const contentTypeRef = React.useRef<DrawerContentType | undefined>(undefined);
  const isDrawerVisibleRef = React.useRef<boolean>(show);
  const [iconContentWidth, setIconContentWidth] = React.useState<number>(ICON_MAPPER_MIN_WIDTH);
  const [otherContentWidth, setOtherContentWidth] = React.useState<number>(getDrawerMinWidth());

  const isContentIconPanel = contentType === CONTEXT_SIDEBAR_CONTENT.systemDiagramIconMapper;
  const isPrevContentIconPanel = prevContentType === CONTEXT_SIDEBAR_CONTENT.systemDiagramIconMapper;

  React.useEffect(() => {
    isDrawerVisibleRef.current = isDrawerVisible;
  }, [isDrawerVisible]);

  React.useEffect(() => {
    contentTypeRef.current = contentType;
  }, [contentType]);

  const handleButtonClick = (buttonType: DrawerContentType) => {
    if (!isDrawerVisibleRef.current) {
      setDrawerVisible(true);
    } else if (isDrawerVisible && contentTypeRef.current === buttonType) {
      setDrawerVisible(false);
    } else if (isDrawerVisible) {
      // save the current width of the drawer
      if (buttonType === CONTEXT_SIDEBAR_CONTENT.systemDiagramIconMapper) {
        setOtherContentWidth(getDrawerWidth());
      }
    }
  };

  const handleResizeStop = () => {
    const toolbarWidth = getToolbarWidth();

    if (isDragging) {
      setIsDragging(false);

      const width = getDrawerWidth();
      let minWidth;
      if (isContentIconPanel) {
        minWidth = ICON_MAPPER_MIN_WIDTH;
      } else {
        minWidth = getDrawerMinWidth();
      }
      const resizeThreshold = getResizeThreshold();
      const preferredWidth = getPreferredWidth();

      if (width < minWidth) {
        // resize to min width if close enough to minWidth
        if (width > minWidth - resizeThreshold) {
          setDrawerWidth(minWidth);
          setPreferredWidth(minWidth);
        } else {
          // otherwise hide drawer
          setDrawerWidth(toolbarWidth);
          setPreferredWidth(Math.min(minWidth, preferredWidth));
          setDrawerVisible(false);
        }
      } else {
        setPreferredWidth(width);
        // have to remember width of drawer depending on if it's icon mapper or not...
        if (isContentIconPanel) {
          setIconContentWidth(width);
        } else {
          setOtherContentWidth(width);
        }
      }
    } else {
      // not dragging, must have clicked chevron or double clicked
      setDrawerWidth(toolbarWidth);
    }
  };

  const toggleDrawerVisible = () => {
    setDrawerVisible((prev) => !prev);
  };

  const handleDrag = (e: React.MouseEvent) => {
    setIsDragging(true);
    setDrawerVisible(true);
    const toolbarWidth = getToolbarWidth();
    const root = document.documentElement;
    const vw = Math.max(root.clientWidth || 0, window.innerWidth || 0);

    let newWidth = vw - e.clientX + 2;
    if (newWidth > vw / 2) {
      newWidth = vw / 2;
    } else if (newWidth < toolbarWidth + parseInt(theme.sizes.dragBarWidth, 10) / 2) {
      newWidth = toolbarWidth + parseInt(theme.sizes.dragBarWidth, 10) / 2 - 2;
    }
    setDrawerWidth(newWidth);
  };

  React.useEffect(() => {
    if (isDrawerVisible) {
      // already visible, size it appropriately
      if (isContentIconPanel) {
        setDrawerWidth(Math.max(ICON_MAPPER_MIN_WIDTH, iconContentWidth));
      } else if (isPrevContentIconPanel) {
        const oContentWidth = Number.isNaN(otherContentWidth) ? 0 : otherContentWidth;
        const w = Math.max(getDrawerMinWidth(), oContentWidth);
        setDrawerWidth(w);
        setPreferredWidth(w);
      } else {
        setDrawerWidth(Math.max(getDrawerMinWidth(), getPreferredWidth()));
      }
    } else {
      // hide drawer
      setDrawerWidth(getToolbarWidth());
    }
  }, [
    iconContentWidth,
    isDrawerVisible,
    isContentIconPanel,
    otherContentWidth,
    prevContentType,
    isPrevContentIconPanel,
  ]);

  return (
    <S.MainContainer>
      <S.TransitionContainer isDragging={isDragging}>
        <S.ContentContainer isDragging={isDragging}>
          <ResizeControl
            location="right"
            onStop={handleResizeStop}
            onDrag={handleDrag}
            onDoubleClick={() => toggleDrawerVisible()}
            show={isDrawerVisible}
            onChevronClick={() => toggleDrawerVisible()}
          />
          <S.RightSidebarContainer>
            <RightSidebarDrawer />
            <S.ContextNavigation isDrawerVisible={isDrawerVisible}>
              <RightSidebarToolbar onClick={handleButtonClick} />
            </S.ContextNavigation>
          </S.RightSidebarContainer>
        </S.ContentContainer>
      </S.TransitionContainer>
    </S.MainContainer>
  );
};
