import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./organization-query-key-factory";

interface IOrganizationNameProps {
  organizationId: string | null;
  userId: string | null;
}

/**
 * Add given user to the organization
 *
 * @param param0 upload file data
 * @returns
 */
export const removeOrganizationAttachedUser = ({ organizationId, userId }: IOrganizationNameProps) => {
  return organizationId === null
    ? Promise.reject(new Error("Invalid organization id"))
    : Brm.organizationApi.removeOrganizationAttachedUser(organizationId, userId);
};

interface IAddOrganizationAttachedUserProps {
  options?: MutationOption<typeof removeOrganizationAttachedUser>;
}

/**
 * Remove attached user from the organization
 *
 * @returns react-query Mutation.
 */
export const useRemoveOrganizationAttachedUser = ({ options }: IAddOrganizationAttachedUserProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.organizationKeys.all);
      queryClient.invalidateQueries(KeyFactory.organizationKeys.org(variables.organizationId));
    },
    ...options,
    mutationFn: removeOrganizationAttachedUser,
  });
};
