export const getSelectArray = (array: string[]) => {
  const objects: {
    value: number;
    label: string;
  }[] = [];
  let value = 0;
  if (array && array.length > 0) {
    array.sort().forEach((element) => {
      objects.push({
        // eslint-disable-next-line no-plusplus
        value: ++value,
        label: element,
      });
    });
  }
  return objects;
};
