/* eslint-disable import/prefer-default-export */
import {
  QueryClient,
  QueryCache,
  DefaultOptions,
  Query,
  UseQueryOptions,
  UseMutationOptions,
} from "@tanstack/react-query";
// import toast from "react-hot-toast";
import { PromiseValue } from "types";
import toast from "react-hot-toast";

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: (error: any) => error.response?.status >= 500,
    refetchOnWindowFocus: false,
    networkMode: "always",
  },
  mutations: {
    networkMode: "always",
  },
};

const queryCache = new QueryCache({
  onError: (error: any, query: Query) => {
    if (query.meta?.errorMessage) {
      toast.error(`${query.meta.errorMessage}`);
      return;
    }

    // 🎉 only show error toasts if we already have data in the cache
    // which indicates a failed background update
    if (query.state.data !== undefined) {
      // console.error("background failed fetch:", query);
      // TODO This is a work around till compound operations such as automitigate are implemented as a single transaction
      toast.error(`Background update failed : ${error?.statusText}`);
    }
  },
});

export const queryClient = new QueryClient({ defaultOptions: queryConfig, queryCache });

export type ExtractFnReturnType<FnType extends (...args: any) => any> = PromiseValue<ReturnType<FnType>>;

export type QueryOption<FetcherFnType extends (...args: any) => any> = UseQueryOptions<
  PromiseValue<ReturnType<FetcherFnType>>,
  Error,
  any, // PromiseValue<ReturnType<FetcherFnType>>,
  any // have to specify any since querykey type unknown[] is unfriendly to types...
>;

export type QueryOption2<QueryFnType extends (...args: any) => any, TError = unknown, TData = unknown> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>, TError, TData>,
  "queryKey" | "queryFn"
>;

// export type QueryOption<QueryFnType extends (...args: any) => any> = Omit<
//   UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
//   "queryKey" | "queryFn"
// >;

export type MutationOption<FetcherFnType extends (...args: any) => any> = UseMutationOptions<
  PromiseValue<ReturnType<FetcherFnType>>,
  Error,
  any // Parameters<FetcherFnType>[0]
>;

// export type MutationOption<MutationFnType extends (...args: any) => any> = UseMutationOptions<
//   ExtractFnReturnType<MutationFnType>,
//   Error,
//   Parameters<MutationFnType>[0]
// >;
