import AddIcon from "assets/icons/plus_tmp.svg";
import PrimaryButton from "components/elements/PrimaryStyledButton";
import ImgSized from "components/elements/ImgSized";
import { useRecoilValue } from "recoil";
import { isReadOnlyState } from "atoms/atoms-admin";
// Services
import { useRoles } from "features/brm/hooks/useRoles";
import { MouseEvent } from "react";

interface IAddButtonProps {
  onClick: (event: MouseEvent) => void;
  tooltip?: string;
  disabled?: boolean;
}

export const AddButton = ({ onClick, tooltip = "Add", disabled = false }: IAddButtonProps) => {
  const { isSuperAdmin, isAdmin } = useRoles();
  const isReadOnly = useRecoilValue(isReadOnlyState);

  return (
    <>
      {isSuperAdmin || isAdmin || !isReadOnly ? (
        <span title={tooltip}>
          <PrimaryButton id="AddButton_button" onClick={onClick} disabled={disabled}>
            <ImgSized id="AddButton_addIcon" src={AddIcon} alt="add" width="16" height="16" />
          </PrimaryButton>
        </span>
      ) : null}
    </>
  );
};
