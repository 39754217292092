import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import { attackSurfaceApi } from "services/brm/access-service";
import { LoadingSpinner } from "components/elements";
import handleApi from "services/brm/api-util";
import { userRoleState, projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { userRoles } from "services/brm/admin-service";
import ButtonCell from "components/EditComponents/ButtonCell";
import Title from "components/elements/StyledTitle";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import { COMMON, TYPE } from "constants/brm";
// Queries
import * as ACQueries from "brm/system-model/access/access-queries";
import { createColumnCategoryMapped, createColumnActions } from "brm/tables/services/column/columnFactory";
import { RoutePath } from "routes/route-paths";

import AttackSurfaceDetails from "../details/AttackSurfaceDetails";

function AttackSurfaceFullDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const userRole = useRecoilValue(userRoleState);
  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedCategory = useRef("");

  const sysEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
  const riskAnalystRole = userRoles.properties[userRoles.RISK_ANALYST].key;
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  let content = null;
  const {
    data: attackSurface,
    isLoading,
    isError,
    refetch: refetchAttackSurfaceById,
  } = ACQueries.useAttackSurfaceById(id);
  const { data: attackSurfaceCategories } = ACQueries.useAttackSurfaceCategories(projectId);

  const createButton = useCallback(
    (cellProps) => {
      if (userRole === sysEngineerRole || userRole === riskAnalystRole) {
        return (
          <ButtonCell
            selectedRowId={selectedRowId}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedCategory.current !== "") {
                await handleApi(
                  attackSurfaceApi.setAttackSurfaceCategoryWithHttpInfo(selectedRowId, {
                    body: updatedCategory.current,
                  })
                );
                updatedCategory.current = "";
              }

              setRefresh(true);
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return COMMON.na;
    },

    [sysEngineerRole, riskAnalystRole, editMode, setRefresh, selectedRowId, userRole]
  );

  const columns = useMemo(
    () => [
      createColumnCategoryMapped("category", selectedRowId, attackSurfaceCategories, editMode, updatedCategory),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [editMode, selectedRowId, attackSurfaceCategories, createButton]
  );

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(RoutePath.AttackSurfaces);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    async function getData() {
      setRefresh(false);
      refetchAttackSurfaceById();
    }
    if (refresh) {
      getData();
    }
  }, [refresh, projectId, id, refetchAttackSurfaceById]);

  useEffect(() => {
    setSelectedElement(attackSurface);
    setData([attackSurface]);
  }, [attackSurface]);

  if (isError) {
    content = <>Error while loading Attack Surface details.</>;
  }

  if (isLoading) {
    content = <LoadingSpinner />;
  }

  if (attackSurface && data && selectedElement) {
    content = (
      <>
        <Title id="AttackSurfaceFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "AttackSurfaceFullDetails_table" }}
              elementName={TYPE.attackSurface}
            />
          </DetailsContainerSingleTable>
        ) : (
          <></>
        )}

        {selectedElement.id && (
          <DetailsContainerParent showSummaryTable={showSummaryTable}>
            <DetailsContainer id="AttackSurfaceFullDetails_detailsPanel">
              <AttackSurfaceDetails selectedElement={selectedElement} setRefresh={setRefresh} refresh={refresh} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
  }

  return content;
}

export default AttackSurfaceFullDetails;
