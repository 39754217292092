import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { MutationOption } from "libs/react-query";

interface ICancelImport {
  projectId: string;
  runId: string;
}

const cancelImport = ({ projectId, runId }: ICancelImport) => {
  return Brm.commonImportApi.importCancel(projectId, runId);
};

export interface IUseCancelImport {
  options?: MutationOption<typeof cancelImport>;
}

export const useCancelImport = ({ options }: IUseCancelImport = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: cancelImport,
  });
};
