export const ExportContext = {
  system: "SYSTEM",
  sctm: "SCTM",
  rar: "RAR",
  tra: "TRA",
  savepoint: "SAVEPOINT",
  knowledgebase: "KNOWLEDGEBASE",
  table: "TABLE",
  unfilteredTable: "UNFILTERED-TABLE",
  listWithJson: "JSON",
} as const;

export type ExportContextValues = typeof ExportContext[keyof typeof ExportContext];
