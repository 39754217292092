import PropTypes from "prop-types";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { ShowSummaryTableButton } from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";

const ShowHideButton = ({ setShowSummaryTable, showSummaryTable }) => {
  const style = {
    height: "12px",
    width: "12px",
    marginRight: "5px",
    marginBottom: "2px",
  };

  return (
    <ShowSummaryTableButton onClick={() => setShowSummaryTable(!showSummaryTable)}>
      {showSummaryTable ? (
        <FaChevronDown fill="#003A70" style={style} />
      ) : (
        <FaChevronRight fill="#003A70" style={style} />
      )}
    </ShowSummaryTableButton>
  );
};

ShowHideButton.propTypes = {
  setShowSummaryTable: PropTypes.func.isRequired,
  showSummaryTable: PropTypes.bool.isRequired,
};
export default ShowHideButton;
