export const SYSTEM_ELEMENT = {
  datatype: "datatype",
  dataFlow: "dataflow",
  capability: "capability",
  activity: "activity",
  link: "link",
  bus: "bus",
  person: "person",
  resource: "resource",
  exchange: "exchange",
  node: "node",
  mission: "mission",
  channel: "channel",
  project: "project",
  node_out_exchange: "node_out_exchange",
  node_in_exchange: "node_in_exchange",
  node_out_bus: "node_out_bus",
  node_in_bus: "node_in_bus",
  node_out_link: "node_out_link",
  node_in_link: "node_in_link",
};

export const CATEGORY = {
  activityBased: "",
  basic: "Basic",
  basicNode: "Basic-node",
};
