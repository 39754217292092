import { useQuery } from "@tanstack/react-query";
// Services
import { nodeApi, busApi, linkApi, exchangeApi } from "services/brm/system-service";
import { BrmError, DetailTableType } from "features/brm";

async function getDepUe(elementId, elementName, variantId) {
  switch (elementName) {
    case DetailTableType.BUSES.key: {
      return busApi.getBusAllDependentUe(elementId, { variant: variantId });
    }
    case DetailTableType.LINKS.key: {
      return linkApi.getLinkAllDependentUe(elementId, { variant: variantId });
    }
    case DetailTableType.NODES.key: {
      return nodeApi.getNodeAllDependentUe(elementId, { variant: variantId });
    }
    case DetailTableType.EXCHANGES.key: {
      return exchangeApi.getExchangeAllDependentUe(elementId, { variant: variantId });
    }
    default: {
      break;
    }
  }
  throw new BrmError(`Unsupported element type: ${elementName}`);
}

const useGetElementDependentUe = (elementId, elementName, variantId) => {
  return useQuery(
    ["getElementDependentUe", elementId, elementName, variantId],
    () => getDepUe(elementId, elementName, variantId),
    {
      enabled: !!elementId,
      cacheTime: 0,
    }
  );
};

export default useGetElementDependentUe;
