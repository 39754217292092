import * as React from "react";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { TYPE, COMMON } from "constants/brm";
import { ExportWizard } from "features/exporter";
import { defaultReportTabState, isReportsTabDefaultState, variantIdState } from "atoms";
import { projectIdState } from "atoms/atoms-admin";
import systemModals from "components/modals/Modals";

import { createColumnActions } from "brm/tables/services/column/columnFactory";
import {
  DetailTableType,
  ConfigurationApi,
  LinkStyles,
  DETAIL_TABLE_TYPE,
  useProject,
  ExportContext,
} from "features/brm";
import { ErrorBanner, LoadingSpinner as Loading, DownloadButton } from "components/elements";
import { RoutePath } from "routes/route-paths";
import { useRoles } from "features/brm/hooks/useRoles";

export const ReportsTable = () => {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();

  const [currentProject] = useProject();
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const setIsReportsTabDefault = useSetRecoilState(isReportsTabDefaultState);
  const setDefaultReportTab = useSetRecoilState(defaultReportTabState);
  const [exportTraIsOpen, setExportTraIsOpen] = React.useState(false);

  const [exportRARIsOpen, setExportRARIsOpen] = React.useState(false);
  const [exportSCTMIsOpen, setExportSCTMIsOpen] = React.useState(false);

  const [data, setData] = React.useState([{}]);

  const { data: systemData, isError: isSystemError } = ConfigurationApi.useSystems({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });

  const createButton = React.useCallback((cellProps) => {
    if (cellProps.cell.row.original.name === DetailTableType.TRA_RPT.name) {
      return <DownloadButton onClick={() => setExportTraIsOpen(true)} />;
    }

    if (cellProps.cell.row.original.name === DetailTableType.RAR_RPT.name) {
      return <DownloadButton onClick={() => setExportRARIsOpen(true)} />;
    }

    if (cellProps.cell.row.original.name === DetailTableType.SCTM_COMPL_RPT.name) {
      return <DownloadButton onClick={() => setExportSCTMIsOpen(true)} />;
    }

    return COMMON.na;
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableSortBy: true,
        Cell: (cellProps: {
          cell: {
            row: {
              original: {
                name: string;
                path: string;
                key: string;
              };
            };
          };
        }) => {
          const {
            cell: {
              row: {
                original: { name, path, key },
              },
            },
          } = cellProps;

          if (path) {
            return (
              <LinkStyles.CleanLink
                id={`hrefTo_${path}`}
                to={path || "#"}
                onClick={() => {
                  setIsReportsTabDefault(true);
                  setDefaultReportTab(key);
                }}
              >
                {name}
              </LinkStyles.CleanLink>
            );
          }

          return <p>{name}</p>;
        },
      },
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [createButton, setDefaultReportTab, setIsReportsTabDefault]
  );

  React.useEffect(() => {
    function getData() {
      const systemId = systemData?.id;

      const factsReports = [
        // DetailTableType.FACT_ANALYSIS_INFORMATION_LEVEL_RPT,  // TODO : needs to be enabled after March release
        DetailTableType.FACT_ANALYSIS_INVENTORY_RPT,
        DetailTableType.FACT_ANALYSIS_RPT,
      ];

      const cntrlsReports = [
        DetailTableType.VARIANT_DIFF_RPT,
        DetailTableType.CNTRL_GUINDANCE_RPT,
        DetailTableType.CNTRL_RPT,
        DetailTableType.CSA_KPP_RPT,
        DetailTableType.CSF_RPT,
        DetailTableType.SCTM_COMPL_RPT,
      ];

      const projectReports = [
        { ...DetailTableType.SYSTEM_CWE_RPT, path: RoutePath.Project.replace(":id", projectId) },
        { ...DetailTableType.CWE_RPT, path: RoutePath.Project.replace(":id", projectId) },
        { ...DetailTableType.TRA_RPT_TMP, path: RoutePath.Project.replace(":id", projectId) },
        { ...DetailTableType.TRA_RPT, path: "" },
        { ...DetailTableType.RAR_RPT, path: "" },
      ];

      // const noPathReports = [{ ...DetailTableType.RAR_RPT, path: "" }];

      const sysEnggReports = factsReports.map((e) => ({ ...e, path: RoutePath.System.replace(":id", systemId) }));

      const riskAnalystReports = sysEnggReports.concat(projectReports);

      const riskAnalystWithVariantReports = cntrlsReports
        .map((e) => ({
          ...e,
          path: e.key === DETAIL_TABLE_TYPE.sctm_compl_rpt.key ? "" : RoutePath.Variant.replace(":id", variantId),
        }))
        .concat(sysEnggReports, projectReports);

      if (isSystemEngineer) {
        setData(sysEnggReports);
      } else if (isRiskAnalyst) {
        const tmpData = variantId === COMMON.nullUuid ? riskAnalystReports : riskAnalystWithVariantReports;
        setData(tmpData);
      }
    }
    if (systemData && variantId) {
      getData();
    }
  }, [isRiskAnalyst, isSystemEngineer, projectId, systemData, variantId]);

  if (isSystemError) {
    return <ErrorBanner msg="Error occured while getting Reports" />;
  }

  if (data) {
    return (
      <>
        {exportTraIsOpen &&
          systemModals.customModal(
            "TraExport_modal",
            exportTraIsOpen,
            setExportTraIsOpen,
            <ExportWizard
              filePrefix={currentProject?.name || "TRA"}
              setExportModalIsOpen={setExportTraIsOpen}
              projectId={projectId}
              exportContext={ExportContext.tra}
              focus={COMMON.nullUuid}
            />,
            "TRA Exporter"
          )}

        {exportRARIsOpen &&
          systemModals.customModal(
            "RARExport_modal",
            exportRARIsOpen,
            setExportRARIsOpen,
            <ExportWizard
              filePrefix={currentProject?.name || "RAR"}
              setExportModalIsOpen={setExportRARIsOpen}
              projectId={projectId}
              exportContext={ExportContext.rar}
              focus={COMMON.nullUuid}
              showCurrentOption={false}
            />,
            "RAR Exporter"
          )}

        {exportSCTMIsOpen &&
          systemModals.customModal(
            "SCTMExport_modal",
            exportSCTMIsOpen,
            setExportSCTMIsOpen,
            <ExportWizard
              filePrefix={currentProject?.name || "SCTM"}
              setExportModalIsOpen={setExportSCTMIsOpen}
              projectId={projectId}
              // sctmId={id}
              exportContext={ExportContext.sctm}
            />,
            "SCTM Exporter"
          )}

        <CustomTable id="UserTable_table">
          <BrmMainTable
            data={data}
            columns={columns}
            setSelectedRows={() => {}}
            customProps={{ id: "UserTable_table" }}
            elementName={TYPE.user}
            showRowSelect={false}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};
