import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { activityApi } from "services/brm/system-service";
import handleApi from "services/brm/api-util";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import ButtonCell from "components/EditComponents/ButtonCell";
import { ElementFullDetails, SystemApi } from "features/brm";
import { TYPE } from "constants/brm";

import {
  createColumnMapped,
  createColumnBooleanMapped,
  createColumnBooleanNoEditMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import * as ReactQuery from "@tanstack/react-query";
import { LoadingSpinner as Loading } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";
import { RoutePath } from "routes/route-paths";
import ActivityDetails from "../details/ActivityDetails";
import * as SysFactsApi from "../api";

function ActivityFullDetails() {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const { id } = useParams(); // current selected element id
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);

  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedCategory = useRef("");
  const updatedIsInternal = useRef("");
  const updatedIsInitial = useRef("");
  const updatedIsFinal = useRef("");
  const updatedNode = useRef("");

  const queryClient = ReactQuery.useQueryClient();
  const { data: elementCats } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: isSystemEngineer || isRiskAnalyst },
  });
  const { data: nodes } = SystemApi.useNodes({ projectId, options: { enabled: isSystemEngineer || isRiskAnalyst } });
  const { data: activity } = SysFactsApi.useActivity({
    activityId: id,
    options: { enabled: isSystemEngineer || isRiskAnalyst },
  });
  const { data: activityNode } = SysFactsApi.useActivityNode({
    activityId: id,
    options: { enabled: isSystemEngineer || isRiskAnalyst },
  });

  const { mutateAsync: setActivityNode } = SystemApi.useSetActivityNode({
    options: {
      onSettled: () => {
        updatedNode.current = "";
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["activityNode", id]);
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedRowId}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedIsInternal.current !== "") {
              await handleApi(
                activityApi.setActivityIsInternalWithHttpInfo(selectedRowId, {
                  body: updatedIsInternal.current,
                })
              );
              updatedIsInternal.current = "";
            }

            if (updatedIsInitial.current !== "") {
              await handleApi(
                activityApi.setActivityIsInitialWithHttpInfo(selectedRowId, {
                  body: updatedIsInitial.current,
                })
              );
              updatedIsInitial.current = "";
            }

            if (updatedIsFinal.current !== "") {
              await handleApi(
                activityApi.setActivityIsFinalWithHttpInfo(selectedRowId, {
                  body: updatedIsFinal.current,
                })
              );
              updatedIsFinal.current = "";
            }

            if (updatedCategory.current !== "") {
              await handleApi(
                activityApi.setActivityCategoryWithHttpInfo(selectedRowId, {
                  body: updatedCategory.current,
                })
              );
              updatedCategory.current = "";
            }

            if (updatedNode.current !== "") {
              await setActivityNode({ activityId: id, nodeId: updatedNode.current });
              updatedNode.current = "";
            }
            setRefresh(true);
            queryClient.invalidateQueries(["activity", selectedRowId]);
            queryClient.invalidateQueries(["activities", projectId]);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [selectedRowId, editMode, queryClient, projectId, setActivityNode, id]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnCategoryMapped("category", selectedRowId, elementCats, editMode, updatedCategory),
        createColumnBooleanMapped("isInternal", selectedRowId, editMode, updatedIsInternal),
        createColumnBooleanNoEditMapped("isBoundary"),
        createColumnBooleanMapped("isInitial", selectedRowId, editMode, updatedIsInitial),
        createColumnBooleanMapped("isFinal", selectedRowId, editMode, updatedIsFinal),
        createColumnMapped("node", selectedRowId, nodes, editMode, updatedNode),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnCategoryMapped("category", selectedRowId, elementCats, false, updatedCategory),
      createColumnBooleanMapped("isInternal", selectedRowId, false, updatedIsInternal),
      createColumnBooleanNoEditMapped("isBoundary"),
      createColumnBooleanMapped("isInitial", selectedRowId, false, updatedIsInitial),
      createColumnBooleanMapped("isFinal", selectedRowId, false, updatedIsFinal),
      createColumnMapped("node", selectedRowId, nodes, false, updatedNode),
    ];
  }, [isSystemEngineer, selectedRowId, elementCats, nodes, editMode, createButton]);

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(RoutePath.Activities);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    if (activity) {
      setSelectedElement(activity);
    }
  }, [activity]);

  if ((elementCats, nodes, activity, activityNode)) {
    const act = {
      ...activity,
      node: activityNode,
    };

    const detailsComp = <ActivityDetails selectedElement={act} setRefresh={setRefresh} refresh={refresh} />;

    return (
      <ElementFullDetails
        showBackLink
        data={[act]}
        columns={columns}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        setSelectedRowId={setSelectedRowId}
        detailsComp={detailsComp}
        compName={TYPE.activity}
      />
    );
  }
  return <Loading />;
}

export default ActivityFullDetails;
