import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./access-query-key-factory";

interface ISetEntryPointSurfaceProps {
  entryPointId: string;
  surface: string;
}

/**
 * Sets the Entry Point's AttackSurface
 *
 * @param {string} entryPointId - uuid of the entry point
 * @param {string} surface - new name of the AttackSurface
 */
export const setEntryPointSurface = ({ entryPointId, surface }: ISetEntryPointSurfaceProps) => {
  return Brm.entryPointApi.setEntryPointSurface(entryPointId, { body: surface });
};

interface IUseSetEntryPointSurface {
  projectId: string;
  options?: MutationOption<typeof setEntryPointSurface>;
}

/**
 * Custom hook to change the name of an existing Entry Point's AttackSurface
 *
 * @param {string} projectId - uuid of the project
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetEntryPointSurface = ({ projectId, options }: IUseSetEntryPointSurface) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.entryPointKeys.project(projectId));
    },
    ...options,
    mutationFn: setEntryPointSurface,
  });
};
