import styled from "styled-components";

const StyledButton = styled.button`
  border: solid;
  border-width: 1px;
  border-radius: 2px;
  padding-left: 7px;
  padding-right: 8px;
  padding-bottom: 5px;
  padding-top: 2px;
  text-align: center;
`;

export default StyledButton;
