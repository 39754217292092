import { PageSpinner } from "components/elements/PageSpinner";
import * as S from "./CenteredSpinner.styles";

export const CenteredSpinner = ({ text }: { text: string }) => {
  return (
    <S.CenterContainer>
      <PageSpinner text={text} />
    </S.CenterContainer>
  );
};
