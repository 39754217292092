const LEVELS = [
  {
    id: "VeryHigh",
    name: "Very High",
    value: "very high",
  },
  {
    id: "High",
    name: "High",
    value: "high",
  },
  {
    id: "Moderate",
    name: "Moderate",
    value: "moderate",
  },
  {
    id: "Low",
    name: "Low",
    value: "low",
  },
  {
    id: "VeryLow",
    name: "Very Low",
    value: "very low",
  },
];

export default LEVELS;
