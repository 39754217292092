/**
 * Styles common to all tables
 */
import styled from "styled-components/macro";

export const MainTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  min-height: 0px;
`;

export const OverflowContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

export const ContainerDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0px;
`;

export const TableHeaderWrap = styled.div`
  display: flex;
  padding: 2px 0;
`;

export const TableFooterWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ZeroHeightDiv = styled.div`
  display: flex;
  height: 0px;
  flex-direction: column;
`;

export const Table = styled.table`
  height: 100%;
  width: 100%;
  border-spacing: 0;
  position: relative;
  font-family: ${({ theme }) => theme.fonts.primary};
  border-collapse: separate;
  cursor: default;
`;

export const TableWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableDiv = styled.div`
  flex-direction: column;

  .tableHeaderWrap {
    display: flex;
  }

  .tableWrap {
    display: flex;
  }

  /* This will make the table scrollable when it gets too small */
  .singleTable {
    overflow-x: auto;
    display: block;
  }

  table {
    border-spacing: 0;
    position: relative;
    font-family: ${({ theme }) => theme.fonts.primary};
    border-collapse: separate;
    cursor: default;
  }

  .noDataFound {
    text-align: center;
  }

  th {
    border-bottom: 1px solid rgb(223, 225, 230);
    border-left: 1px solid rgb(223, 225, 230);
    font-weight: normal;
    background-color: ${({ theme }) => theme.tableheaderbackgroundcolor};
    padding: 0px 5px;
    color: ${({ theme }) => theme.tableheadercolor};
    text-align: ${({ theme }) => theme.tableheadertextalign};
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    white-space: nowrap;

    :first-child {
      border-top-left-radius: ${({ theme }) => theme.tables.headers.borderRadius};
    }

    :last-child {
      border-right: 0;
      border-top-right-radius: ${({ theme }) => theme.tables.headers.borderRadius};
    }
  }

  td {
    margin: ${({ theme }) => theme.tabledatamargin};
    padding: 0px 5px;
    text-align: ${({ theme }) => theme.tabledatatextalign};
    color: ${({ theme }) => theme.tabledatacolor};
    border: ${({ theme }) => theme.tabledataborder};
    height: ${({ theme }) => theme.tabledatacellheight};

    :first-child,
    :last-child {
      color: ${({ theme }) => theme.tabledatacolor};
      border: ${({ theme }) => theme.tabledataborder};
      height: ${({ theme }) => theme.tabledatacellheight};
    }
  }

  tr:hover td {
    background-color: ${({ theme }) => theme.tableheaderbackgroundcolor};
  }

  tr:focus-within td {
    ${({ isfocusBackgroundEnabled, theme }) =>
      isfocusBackgroundEnabled && `background-color: ${theme.tableheaderbackgroundcolor}`}
  }
`;

export const RowCountTextSpan = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  float: left;
  margin: 5px;
`;

export const RowCountSpan = styled.span`
  margin: 0px 4px;
  font-weight: bold;
`;

export const FilterInput = styled.input`
  width: 100%;
  height: 22px;
`;

export const Select = styled.select`
  height: 22px;
  width: 100%;
`;
