import PropTypes from "prop-types";
import { useMemo, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import { Filter } from "brm/tables/BrmTables/BrmTableFilter";
import * as S from "./styles/tables.styles";

const BrmCsaReportTable = ({ columns, data, elementName, tableTitle = "", setExportTableData = () => {} }) => {
  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, page, prepareRow, visibleColumns } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    usePagination
  );

  useEffect(() => {
    if (rows.length) {
      const exportTableData = {
        originalName: `${elementName || ""}_${tableTitle}`,
        rawHeaders: headerGroups,
        rawRows: rows,
        rawPage: page,
      };
      setExportTableData(exportTableData);
    }
  }, [rows, headerGroups, elementName, tableTitle, setExportTableData, page]);

  return (
    <S.MainTableContainer>
      <S.TableHeaderWrap>
        {tableTitle ? (
          <S.RowCountTextSpan>
            <S.RowCountSpan>{tableTitle}</S.RowCountSpan> ( Showing
            <S.RowCountSpan>{rows.length}</S.RowCountSpan>
            items of <S.RowCountSpan>{data.length}</S.RowCountSpan>)
          </S.RowCountTextSpan>
        ) : (
          <S.RowCountTextSpan>
            Showing
            <S.RowCountSpan>{rows.length}</S.RowCountSpan>
            items of <S.RowCountSpan>{data.length}</S.RowCountSpan>
          </S.RowCountTextSpan>
        )}
      </S.TableHeaderWrap>
      <S.ContentWrapper>
        <S.OverflowContainer>
          <S.ZeroHeightDiv>
            <S.TableDiv>
              <S.TableWrap>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <S.Table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                        {headerGroup.headers.map((col, i) => (
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          <th {...col.getHeaderProps()} style={{ textAlign: "center" }} key={`${col}_${0 + i}`}>
                            {col.render("Header")}
                            <Filter column={col} />
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <tbody {...getTableBodyProps()}>
                    {(rows.length > 0 &&
                      rows.map((row, i) => {
                        prepareRow(row);
                        return (
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          <tr {...row.getRowProps()} key={`${row}_${0 + i}`}>
                            {row.original.Rowspan > 0 && (
                              <td rowSpan={row.original.Rowspan} style={{ textAlign: "center" }}>
                                {row.cells[0].render("Cell")}
                              </td>
                            )}
                            {row.original.subCatRowSpan > 0 && (
                              <td rowSpan={row.original.subCatRowSpan} style={{ textAlign: "center" }}>
                                {row.cells[1].render("Cell")}
                              </td>
                            )}
                            <td style={{ textAlign: "center" }}>{row.cells[2].render("Cell")}</td>
                            <td style={{ textAlign: "center" }}>{row.cells[3].render("Cell")}</td>
                          </tr>
                        );
                      })) || (
                      <tr>
                        <td className="noDataFound" colSpan={visibleColumns.length}>
                          No data found...
                        </td>
                      </tr>
                    )}
                  </tbody>
                </S.Table>
              </S.TableWrap>
            </S.TableDiv>
          </S.ZeroHeightDiv>
        </S.OverflowContainer>
      </S.ContentWrapper>
    </S.MainTableContainer>
  );
};

BrmCsaReportTable.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  elementName: PropTypes.string,
  tableTitle: PropTypes.string,
  setExportTableData: PropTypes.func,
};

export default BrmCsaReportTable;
