import * as React from "react";
import { IConfigureData } from "./types";

interface IConfigureProjectContext {
  next: () => void;
  prev: () => void;
  close: () => void;
  configureData: IConfigureData;
  setConfigureData: (data: IConfigureData) => void;
}

export const ConfigureProjectContext = React.createContext<IConfigureProjectContext | null>(null);

export const useConfigureProjectContext = () => {
  const context = React.useContext(ConfigureProjectContext);

  if (!context) {
    throw new Error("useConfigureProjectContext has to be used within <ConfigureProjectContext.Provider>");
  }
  return context;
};
