import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

export interface ISetNodeName {
  nodeId: string;
  name: string;
}

/**
 * Sets the name of a node
 *
 * @param {string} id - uuid of the node
 * @param {string} name - new name for the node
 */
export const setNodeName = ({ nodeId, name }: ISetNodeName) => {
  return Brm.nodeApi.setNodeName(nodeId, { body: name });
};

export interface IUseSetNodeName {
  options?: MutationOption<typeof setNodeName>;
}

/**
 * Custom hook to change the name of a existing node
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetNodeName = ({ options }: IUseSetNodeName = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.nodeKeys.all);
      queryClient.invalidateQueries(KeyFactory.nodeKeysGraphQl.systemDiagramData);
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: setNodeName,
  });
};
