import PropTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import useSioCrudService from "hooks/useSioCrudService";
import { projectIdState, userIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";

const Event = ({ constant, setRefresh }) => {
  const projectId = useRecoilValue(projectIdState);
  const userId = useRecoilValue(userIdState);
  const [enableMessage, setEnableMessage] = useState(false);

  // Use the Crud event service.
  const socket = useRef(null);
  const [sio, authorized] = useSioCrudService(projectId);
  socket.current = sio;

  useEffect(() => {
    function personCrudRoom() {
      socket.current.joinCrudRoom(constant);
      socket.current.waitCrudEvent(constant, (data) => {
        if (data.userId !== userId) {
          setEnableMessage(true);
        } else setEnableMessage(false);
      });
    }
    if (authorized) {
      personCrudRoom();
    }
  }, [authorized, constant, userId]);

  return (
    <>
      {enableMessage ? (
        <>
          This page is updated !
          <button
            type="button"
            onClick={() => {
              setRefresh(true);
              setEnableMessage(false);
            }}
          >
            Refresh
          </button>
        </>
      ) : null}
    </>
  );
};

Event.propTypes = {
  constant: PropTypes.any,
  setRefresh: PropTypes.func,
};

export default Event;
