import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./variant-query-key-factory";

interface IGetVariant {
  variantId: string;
}

/**
 *
 * @param {string} projectId - uuid of the project
 * @returns list of variants
 */
export const getVariant = async ({ variantId }: IGetVariant): Promise<any> => {
  return Brm.variantApi.getVariantByID(variantId);
};

interface IUseVariants {
  variantId: string;
  options?: QueryOption<typeof getVariant>;
}

/**
 * React-query hook for getting a variant
 *
 * @param {string} variantId - uuid of the project
 * @param {object} options - react-query configuration object
 * @returns react-query for getVariants
 */
export const useVariant = ({ variantId, options = {} }: IUseVariants) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.variantsKeys.detail(variantId),
    queryFn: () => getVariant({ variantId }),
  });
};
