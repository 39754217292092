import PropTypes from "prop-types";
import S from "./Logo.styles";

const Logo = ({ name = "", src, alt, id }) => {
  return <S.Logo name={name} id={id} src={src} alt={alt} width="321" height="76" />;
};

Logo.propTypes = {
  name: PropTypes.string,
  alt: PropTypes.string,
  id: PropTypes.string,
  src: PropTypes.any,
};

export default Logo;
