import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./license-query-key-factory";

interface IUploadLicenseFile {
  selectedFiles: File;
}

/**
 * @param {File} selectedFiles
 */
export const uploadLicenseFile = ({ selectedFiles }: IUploadLicenseFile) => {
  return Brm.licensingApi.importLicenseFile({ file: selectedFiles });
};

export interface IUseUploadLicenseFile {
  options?: MutationOption<typeof uploadLicenseFile>;
}

/**
 * Custom hook to upload a license file
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for licenseFileUpload
 */
export const useUploadLicenseFile = ({ options }: IUseUploadLicenseFile = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.licenseKeys.all);
    },
    ...options,
    mutationFn: uploadLicenseFile,
  });
};
