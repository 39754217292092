import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
// import * as ExchangeKeyFactory from "features/brm/api/system/exchange/exchange-query-key-factory";
// import * as ExchangeKeyFactory from "features/brm/api/system/exchange/exchange-query-key-factory";
// import * as NodeKeyFactory from "features/brm/api/system/node/node-query-key-factory";
// import { projectKeys } from "features/brm/api/admin/project/project-query-key-factory";
// import { sapBindingKeys } from "features/brm/api/admin/sapBinding/";
// import { sapProgramKeys } from "features/brm/api/admin/sapProgram";
// import { sapPersonKeys } from "features/brm/api/admin/sapPerson";
// import { sapOrganizationKeys } from "features/brm/api/admin/sapOrganization";

import * as KeyFactory from "./user-query-key-factory";
import * as ProjectKeyFactory from "../project/project-query-key-factory";

interface IUserRoleProps {
  userId: string | null;
  role: string;
}

/**
 * Set the user role
 *
 * @returns
 */
export const setUserRole = ({ userId, role }: IUserRoleProps) => {
  return userId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.userApi.setUserRole(userId, { body: role });
};

interface ISetNameProps {
  options?: MutationOption<typeof setUserRole>;
}

/**
 * mutate the user role.  We use optimistic updates here to ensure the
 * role change takes place immediately so that background fetches use the proper
 * role and don't fail miserably when the the new role doesn't have permissions
 *
 * @returns react-query Mutation.
 */
export const useSetUserRole = ({ options }: ISetNameProps = {}) => {
  return ReactQuery.useMutation({
    onMutate: async ({ userId, role }: any) => {
      queryClient.removeQueries(ProjectKeyFactory.projectKeys.all);
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: KeyFactory.userKeys.role(userId) });
      // Snapshot the previous value
      const previousRole = queryClient.getQueryData(KeyFactory.userKeys.role(userId));
      // Optimistically update to the new value
      queryClient.setQueryData(KeyFactory.userKeys.role(userId), { result: role });

      // Return a context with the previous and new todo
      const newRole = { userId, role };
      return { previousRole, newRole };
    },
    onError: (err, newRole, context: any) => {
      // If the mutation fails, use the context we returned above
      queryClient.setQueryData(KeyFactory.userKeys.role(newRole.userId), context.previousRole);
    },
    onSettled: async () => {
      // Always refetch after error or success:
      // queryClient.invalidateQueries(KeyFactory.userKeys.all);
      // queryClient.invalidateQueries(projectKeys.all);
      // queryClient.invalidateQueries(sapProgramKeys.all);
      // queryClient.invalidateQueries(sapPersonKeys.all);
      // queryClient.invalidateQueries(sapBindingKeys.allOrg);
      // queryClient.invalidateQueries(sapBindingKeys.allProject);
      // queryClient.invalidateQueries(sapBindingKeys.allUser);
      // queryClient.invalidateQueries(sapOrganizationKeys.all);
      // queryClient.invalidateQueries(sapOrganizationKeys.all);
      queryClient.removeQueries(ProjectKeyFactory.projectKeys.all);
      await queryClient.invalidateQueries();
      // const status = queryClient.getQueryState(sapProgramKeys.all, { exact: true });
      // if (status?.status === "error") {
      //   queryClient.getQueryCache().find(sapProgramKeys.all, { exact: true })?.reset();
      // }
    },
    ...options,
    mutationFn: setUserRole,
  });
};
