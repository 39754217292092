import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import Select from "react-select";
import {
  ATCKR_CAT_OPTIONS,
  ATCK_KIND_OPTIONS,
  ATCK_TACTIC_OPTIONS,
  ATCKR_CAT_TAG,
  FORM_LABEL,
  MEANS,
  OPPORTUNITIES,
  SYS_ASSET_CAT_OPTIONS,
  TREV_CAT_OPTIONS,
  TREV_PAT_OPTIONS,
  ATTACK_KIND,
} from "constants/brm";
import customDetailStyle from "./styles/BrmReactSelectDetailsStyles";
import { useAddKbRules, useGetKbRules } from "../api/rules-table-queries";

const KbRuleAddForm = ({ setModalIsOpen, selectedElement }) => {
  const [postError, setPostError] = useState("");
  const [selectedTrevCat, setSelectedTrevCat] = useState("");
  const [selectedTrevPat, setSelectedTrevPat] = useState("");
  const [selectedAtckKind, setSelectedAtckKind] = useState("");
  const [selectedAtckTactic, setSelectedAtckTactic] = useState("");
  const [selectedSysAssetCat, setSelelctedSysAssetCat] = useState("");
  const [selectedAtckCat, setSelectedAtckCat] = useState("");
  const [selectedMeans, setSelectedMeans] = useState("");
  const [selectedOpp, setSelectedOpp] = useState("");
  const { data: rulesAndUEsData } = useGetKbRules({ elementId: selectedElement });

  const rulesData = useMemo(() => {
    if (rulesAndUEsData && rulesAndUEsData.rules?.length) {
      return rulesAndUEsData.rules?.filter(
        (rule) => rule.atkkind === ATTACK_KIND.cyber || rule.atkkind === ATTACK_KIND.physical
      );
    }
    return [];
  }, [rulesAndUEsData]);

  const ueData = useMemo(() => {
    if (rulesAndUEsData && rulesAndUEsData.ue?.length) {
      return rulesAndUEsData.ue;
    }
    return [];
  }, [rulesAndUEsData]);

  const { mutate: setKbRules } = useAddKbRules(selectedElement);

  async function handleSubmit(e) {
    e.preventDefault();
    if (
      selectedTrevCat !== "" &&
      selectedTrevPat !== "" &&
      selectedAtckKind !== "" &&
      selectedAtckTactic !== "" &&
      selectedSysAssetCat !== "" &&
      selectedAtckCat !== "" &&
      selectedMeans !== "" &&
      selectedOpp !== ""
    ) {
      const original = rulesData || [];
      const genTag =
        selectedAtckTactic.value.charAt(0) + ATCKR_CAT_TAG[selectedAtckCat.value] + selectedAtckKind.value.charAt(0);
      const result = [
        {
          trevcat: selectedTrevCat.value,
          trevpat: selectedTrevPat.value,
          atkkind: selectedAtckKind.value,
          atktactic: selectedAtckTactic.value,
          sacat: selectedSysAssetCat.value,
          atkcat: selectedAtckCat.value,
          tag: genTag?.toLowerCase() || "",
          means: selectedMeans.value,
          opportunity: selectedOpp.value,
        },
      ];
      const mod = original.concat(result);
      const finalJson = { ue: ueData, rules: mod };
      setKbRules({ elementId: selectedElement, jsonData: finalJson });
      setModalIsOpen(false);
    } else {
      setPostError("*Please fill out all fields in the form prior to submitting");
    }
  }

  return (
    <div id="KbRuleAddForm">
      <form onSubmit={handleSubmit} action="" id="KbRuleAddForm_form">
        <FormStyled>
          <div className="form-style" id="KbRuleAddForm_formContent">
            <label id="KbRuleAddForm_label">{FORM_LABEL.trevCategoryMandatory}</label>
            <Select
              styles={customDetailStyle}
              isMulti={false}
              options={TREV_CAT_OPTIONS}
              defaultValue={selectedTrevCat}
              onChange={(selectedItem) => setSelectedTrevCat(selectedItem)}
              id="KbRuleAddForm_trevcat"
              classNamePrefix="trevcatDropdown"
            />
            <br />
            <label id="KbRuleAddForm_label">{FORM_LABEL.trevPatternMandatory}</label>
            <Select
              styles={customDetailStyle}
              isMulti={false}
              options={TREV_PAT_OPTIONS}
              defaultValue={selectedTrevPat}
              onChange={(selectedItem) => setSelectedTrevPat(selectedItem)}
              id="KbRuleAddForm_trevpat"
              classNamePrefix="trevpatDropdown"
            />
            <br />
            <label id="KbRuleAddForm_label">{FORM_LABEL.attackKindMandatory}</label>
            <Select
              styles={customDetailStyle}
              isMulti={false}
              options={ATCK_KIND_OPTIONS}
              defaultValue={selectedAtckKind}
              onChange={(selectedItem) => setSelectedAtckKind(selectedItem)}
              id="KbRuleAddForm_attackKind"
              classNamePrefix="attackKindDropdown"
            />
            <br />
            <label id="KbRuleAddForm_label">{FORM_LABEL.attackTacticMandatory}</label>
            <Select
              styles={customDetailStyle}
              isMulti={false}
              options={ATCK_TACTIC_OPTIONS}
              defaultValue={selectedAtckTactic}
              onChange={(selectedItem) => setSelectedAtckTactic(selectedItem)}
              id="KbRuleAddForm_attackTactic"
              classNamePrefix="attackTacticDropdown"
            />
            <br />
            <label id="KbRuleAddForm_label">{FORM_LABEL.systemAssetCategoryMandatory}</label>
            <Select
              styles={customDetailStyle}
              isMulti={false}
              options={SYS_ASSET_CAT_OPTIONS}
              defaultValue={selectedSysAssetCat}
              onChange={(selectedItem) => setSelelctedSysAssetCat(selectedItem)}
              id="KbRuleAddForm_sysAssetCat"
              classNamePrefix="sysAssetCatDropdown"
            />
            <br />
            <label id="KbRuleAddForm_label">{FORM_LABEL.attackerCategoryMandatory}</label>
            <Select
              styles={customDetailStyle}
              isMulti={false}
              options={ATCKR_CAT_OPTIONS}
              defaultValue={selectedAtckCat}
              onChange={(selectedItem) => setSelectedAtckCat(selectedItem)}
              id="KbRuleAddForm_attackerCat"
              classNamePrefix="attackerCatDropdown"
            />
            <br />
            <label id="KbRuleAddForm_label">{FORM_LABEL.availableMeansMandatory}</label>
            <Select
              styles={customDetailStyle}
              isMulti={false}
              options={MEANS}
              defaultValue={selectedMeans}
              onChange={(selectedItem) => setSelectedMeans(selectedItem)}
              id="KbRuleAddForm_means"
              classNamePrefix="meansDropdown"
            />
            <br />
            <label id="KbRuleAddForm_label">{FORM_LABEL.availableOpportunityMandatory}</label>
            <Select
              styles={customDetailStyle}
              isMulti={false}
              options={OPPORTUNITIES}
              defaultValue={selectedOpp}
              onChange={(selectedItem) => setSelectedOpp(selectedItem)}
              id="KbRuleAddForm_opportunity"
              classNamePrefix="opportunityDropdown"
            />
          </div>
        </FormStyled>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="KbRuleAddForm_cancelButton">
            Cancel
          </DialogButtonStyled>
          <DialogButtonStyled type="submit" id="KbRuleAddForm_addButton">
            Add
          </DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};

KbRuleAddForm.propTypes = {
  selectedElement: PropTypes.string,
  setModalIsOpen: PropTypes.func,
};

export default KbRuleAddForm;
