import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import AttackerTable from "brm/tables/details/AttackerTable";
import AttackTable from "brm/tables/details/AttackTable";
import PropTypes from "prop-types";
import { DetailTableType } from "features/brm";

const AttackMechanismDetails = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.ATTACK_MECHANISM.key;

  return (
    <Tabs>
      <TabList>
        <Tab>Attackers</Tab>
        <Tab>Attacks</Tab>
      </TabList>

      <TabPanel>
        <AttackerTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
      <TabPanel>
        <AttackTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
    </Tabs>
  );
};

AttackMechanismDetails.propTypes = {
  selectedElement: PropTypes.object.isRequired,
};

export default AttackMechanismDetails;
