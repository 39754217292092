import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

interface ICreateSystemAssetType {
  projectId: string;
  systemAssetTypeCreateDto: any;
}

/**
 *
 * @param {string} projectId uuid of project
 * @param {SystemAssetTypeCreateDto} systemAssetType creation dto
 * @returns id of created node
 */
export const createSystemAssetType = ({ projectId, systemAssetTypeCreateDto }: ICreateSystemAssetType) => {
  return Brm.systemAssetTypeApi.createSystemAssetType(projectId, { systemAssetTypeCreateDto });
};

export interface IUseCreateSystemAssetType {
  options?: MutationOption<typeof createSystemAssetType>;
}

/**
 * Custom hook to create a new exchange.
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for useCreateSystemAssetType
 */
export const useCreateSystemAssetType = ({ options = {} } = {}) => {
  return ReactQuery.useMutation({
    onSuccess: (data, { projectId }) => {
      queryClient.invalidateQueries(KeyFactory.systemAssetTypeKeys.project(projectId));
    },
    ...options,
    mutationFn: createSystemAssetType,
  });
};
