export const vulnerabilityKeys = {
  all: ["vulnerability"] as const,
};

export const vulnerabilityCategoryKeys = {
  all: ["vulnerabilityCategory"] as const,
  project: (projectId: string) => [...vulnerabilityCategoryKeys.all, "project", projectId] as const,
};

export const vulnerabilityGqlKeys = {
  all: ["getAllVulnerabilities"] as const,
};
