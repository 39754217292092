import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import { organizationApi } from "services/brm/admin-service";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";

export const SharedProjectsDetailsTable = ({ selectedElement }) => {
  const columns = useMemo(() => [createColumnMappedNoEdit("name")], []);

  const [projectData, setProjectData] = useState([]);
  const [, setSelectedWorker] = useState([]);

  useEffect(() => {
    async function getData() {
      // get projects
      try {
        const { data } = await organizationApi.getOrganizationSharedWithHttpInfo(selectedElement.id);
        setProjectData(data);
      } catch (error) {
        console.error("Details: Shared Project GET: ", error);
      }
    }
    if (selectedElement?.id) {
      getData();
    }
  }, [selectedElement]);

  return (
    <S.DetailsContainer id="SharedTable_detailsPanel">
      <BrmDetailTable
        data={projectData}
        columns={columns}
        setSelectedElement={setSelectedWorker}
        customProps={{ id: "SharedTable_detailsTable" }}
        showRowSelect={false}
      />
    </S.DetailsContainer>
  );
};

SharedProjectsDetailsTable.propTypes = {
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
};
