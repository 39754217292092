import * as React from "react";
import PropTypes from "prop-types";
import { BrmDetailTable } from "brm/tables/BrmTables";

import {
  createColumnNameDetails,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnMappedNoEdit,
  createColumnBooleanNoEditMapped,
} from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { AdminApi, DetailTableType, KnowledgebaseApi } from "features/brm";
import { RoutePath } from "routes/route-paths";
import { useRoles } from "features/brm/hooks/useRoles";
import { TYPE } from "constants/brm";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";

export const ProjectDetailsTable = ({ elementNameInUrl, selectedElement }) => {
  const [, setSelectedProject] = React.useState([]);
  const { isCyberSecurityExpert } = useRoles();

  const { data: userProjects, isError: isUserError } = AdminApi.useUserProjects({
    userId: selectedElement.id,
    options: {
      enabled: elementNameInUrl === DetailTableType.USER.key,
    },
  });

  const { data: orgProjects, isError: isOrgError } = AdminApi.useOrganizationProjects({
    organizationId: selectedElement.id,
    options: {
      enabled: elementNameInUrl === DetailTableType.ORGANIZATIONS.key,
    },
  });

  const { data: kbProjects, isError: isKbError } = KnowledgebaseApi.useKnowledgebaseProjects({
    kbId: selectedElement.id,
    options: {
      enabled: elementNameInUrl === DetailTableType.KNOWLEDGEBASE.key,
    },
  });

  const columns = React.useMemo(() => {
    if (isCyberSecurityExpert) {
      return [
        createColumnMappedNoEdit("name"),
        createColumnCreatedAt(),
        createColumnModifiedAt(),
        createColumnMappedNoEdit("status"),
        createColumnMappedNoEdit("org"),
        createColumnBooleanNoEditMapped("isListed"),
        createColumnBooleanNoEditMapped("isShared"),
      ];
    }
    return [
      createColumnNameDetails(RoutePath.Project.replace(":id", "")),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnMappedNoEdit("status"),
      createColumnMappedNoEdit("org"),
      createColumnBooleanNoEditMapped("isListed"),
      createColumnBooleanNoEditMapped("isShared"),
    ];
  }, [isCyberSecurityExpert]);

  const projectData = React.useMemo(() => {
    const obj = {
      [DetailTableType.USER.key]: userProjects,
      [DetailTableType.ORGANIZATIONS.key]: orgProjects,
      [DetailTableType.KNOWLEDGEBASE.key]: kbProjects,
    };
    const projects = obj[elementNameInUrl];
    if (projects && Array.isArray(projects)) {
      return projects;
    }
    if (projects) {
      return [projects];
    }
    return undefined;
  }, [elementNameInUrl, kbProjects, orgProjects, userProjects]);

  if (isUserError) {
    return <ErrorBanner msg="Unable to load user project data" />;
  }
  if (isOrgError) {
    return <ErrorBanner msg="Unable to load organization project data" />;
  }
  if (isKbError) {
    return <ErrorBanner msg="Unable to load knowledgebase project data" />;
  }

  if (projectData) {
    return (
      <S.DetailsContainer id="ProjectTable_detailsPanel">
        <BrmDetailTable
          data={projectData}
          columns={columns}
          setSelectedElement={setSelectedProject}
          customProps={{ id: "ProjectTable_detailsTable" }}
          elementName={TYPE.project}
          showRowSelect={false}
        />
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

ProjectDetailsTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
};
