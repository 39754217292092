import * as React from "react";

interface ICheckBoxProp {
  label: string;
  isSelected?: boolean;
  onCheckboxChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

export const CheckBox = ({ label, isSelected, onCheckboxChange }: ICheckBoxProp) => {
  return (
    <label>
      <input type="checkbox" name={label} checked={isSelected} onChange={onCheckboxChange} /> {label}
    </label>
  );
};
