import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import getReactSelectArray from "utils/react-select-array";
import SelectAll from "components/forms/SelectAllReactSelect";
import { FORM_LABEL } from "constants/brm";
import { queryClient } from "libs/react-query";
import { variantIdState } from "atoms";
import { SystemApi } from "features/brm";
import toast from "react-hot-toast";
import getUniqueArray from "./helpers/GetUniqueArray";
// import customDetailStyle from "./styles/BrmReactSelectDetailsStyles";

const AddCapabilityForm = ({ setModalIsOpen, elementNameInUrl, selectedElement }) => {
  const [postError, setPostError] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);

  const { data: capabilitiesData } = SystemApi.useCapabilities({ projectId, options: { enabled: !!projectId } });

  const { data: elementCapabiblitesData } = SystemApi.useElementCapabilities({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    variantId,
    options: {
      enabled: !!elementNameInUrl && !!selectedElement.id,
    },
  });

  const { mutateAsync: addCapabilities } = SystemApi.useAddElementCapabilities();

  useEffect(() => {
    async function getData() {
      const uniqueArray = getUniqueArray(capabilitiesData, elementCapabiblitesData);
      setOptions(getReactSelectArray(uniqueArray));
    }
    if (projectId && capabilitiesData && elementCapabiblitesData) {
      getData();
    }
  }, [projectId, capabilitiesData, elementCapabiblitesData]);

  const handleSelectChange = (selectedItems = []) => {
    setSelectedOptions(selectedItems);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (selectedOptions.length > 0) {
      try {
        await Promise.all(
          selectedOptions.map((each) =>
            addCapabilities({
              elementId: selectedElement.id,
              elementName: elementNameInUrl,
              capabilityId: each.value,
            })
          )
        );
        setModalIsOpen(false);
        queryClient.invalidateQueries(SystemApi.capabilityKeys.details);
      } catch (err) {
        toast.error(<p>Error adding capabilities </p>, {});
      }
    } else {
      setPostError("*Please fill out all fields in the form prior to submitting.");
    }
  }

  return (
    <div id="AddCapbilityForm">
      <form onSubmit={handleSubmit} action="" id="AddCapbilityForm_form">
        <FormStyled>
          <div className="form-style" id="AddCapabilityForm_formContent">
            <label id="AddCapabilityForm_label">{FORM_LABEL.capabilities}</label>
            <SelectAll
              // styles={customDetailStyle}
              isMulti
              options={options}
              defaultValue={selectedOptions}
              value={selectedOptions}
              onChange={handleSelectChange}
              allowSelectAll
              allOption={{
                label: "Select all",
                value: "*",
              }}
              elementId="AddCapbilityForm_capability"
            />
          </div>
        </FormStyled>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="AddCapabilityForm_cancelButton">
            Cancel
          </DialogButtonStyled>
          <DialogButtonStyled type="submit" id="AddCapabilityForm_addButton">
            Add
          </DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};

AddCapabilityForm.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setModalIsOpen: PropTypes.func,
};

export default AddCapabilityForm;
