/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { UserAvatar } from "features/header";
import { ActionButton } from "components/elements/ActionButton";
import InputGroup from "react-bootstrap/InputGroup";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import * as S from "./Profile.styles";

interface IInputs {
  example: string;
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export const Profile = () => {
  const isCurrentPasswordVisible = React.useState(false);
  const isNewPasswordVisible = React.useState(false);

  const {
    register,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
  } = useForm<IInputs>();

  const onSubmit: SubmitHandler<IInputs> = (data) => console.error(data);

  return (
    <S.ProfileRoot>
      <S.ProfileContainer>
        <S.TitleContainer>
          <h1>Profile</h1>
        </S.TitleContainer>
        <S.AvatorGroup>
          <S.ProfileTitle> Avatar</S.ProfileTitle>
          <S.UserAvatarContainer>
            <UserAvatar size="48" />
          </S.UserAvatarContainer>
        </S.AvatorGroup>
        <S.PasswordGroup>
          <S.ProfileTitle> Change your password</S.ProfileTitle>
          <S.FormRoot>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="formCurrentPassword">
                <S.FieldLabel>Current Password</S.FieldLabel>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">
                    <FaLock />
                  </InputGroup.Text>
                  <Form.Control
                    type={isCurrentPasswordVisible ? "password" : "text"}
                    placeholder="Enter Current Password"
                    {...register("currentPassword", { required: true })}
                  />
                  <InputGroup.Text id="basic-addon2">
                    {isCurrentPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formNewPassword">
                <S.FieldLabel>New Password</S.FieldLabel>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">
                    <FaLock />
                  </InputGroup.Text>
                  <Form.Control
                    type={isNewPasswordVisible ? "password" : "text"}
                    placeholder="Enter New Password"
                    {...register("newPassword", { required: true })}
                    isInvalid
                  />
                  <InputGroup.Text id="basic-addon2">
                    {isNewPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                  </InputGroup.Text>
                  <Form.Text id="passwordHelpBlock" muted>
                    Your password must be more than 8-20 characters long, contain letters (uppercase and lowercase) and
                    numbers.
                  </Form.Text>
                </InputGroup>
              </Form.Group>
              <ActionButton variant="primary" type="submit">
                Save Password Change
              </ActionButton>
            </Form>
          </S.FormRoot>
        </S.PasswordGroup>
      </S.ProfileContainer>
    </S.ProfileRoot>
  );
};
