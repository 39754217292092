import { AdminApi } from "features/brm";
import { Setting } from "features/admin/types/setting-types";

interface IUseOrganizationConfigurationPanelApi {
  organizationId: string;
}

export const useOrganizationConfigurationPanelApi = ({ organizationId }: IUseOrganizationConfigurationPanelApi) => {
  const { data: projectSettings } = AdminApi.useGetOrganizationProperty({
    organizationId,
    property: "projectSettings",
  });

  const { mutate: setProjectSettingProperty } = AdminApi.useSetOrganizationProperty();

  const setProjectSetting = (setting: Setting) => {
    setProjectSettingProperty({ organizationId, property: "projectSetting", value: setting });
  };

  return {
    projectSettings,
    setProjectSetting,
  };
};
