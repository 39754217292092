import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";

interface IGetNodeVulnerabilities {
  nodeId: string;
  variantId: string;
}

/**
 * Fetches the list of vulnerabilities data for the given node
 *
 * @param {string} nodeId - uuid of node
 * @param {string} variantId - uuid of project variant
 * @returns a list of vulnerabilities
 */
export const getNodeVulnerabilities = ({ nodeId, variantId }: IGetNodeVulnerabilities) => {
  return Brm.nodeApi.getNodeAllVulnerability(nodeId, { variant: variantId });
};

interface IUseNodeVulnerability {
  variantId: string;
  nodeId: string;
  options?: QueryOption<typeof getNodeVulnerabilities>;
}

/**
 *  Custom-hook to retrieve the list of vulnerabilities for a given node
 *
 * @param {string} variantId - uuid of project variant
 * @param {string} nodeId - uuid of the node to retreive vulnerabilities
 * @param {object} options - react-query configuration object
 * @returns a list of vulnerabilities
 */
export const useNodeVulnerabilities = ({ variantId, nodeId, options = {} }: IUseNodeVulnerability) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.nodeKeys.vulnerability(nodeId, variantId),
    queryFn: () => getNodeVulnerabilities({ nodeId, variantId }),
  });
};
