import PropTypes from "prop-types";
import * as S from "./ToolbarButton.styles";

const ToolbarButton = ({ icon, onClick, toggled, label, iconPos, tooltip, disabled = false }) => {
  let button = null;

  if (icon && !label) {
    button = (
      <S.IconButton aria-label={tooltip} title={tooltip} onClick={onClick} toggled={toggled} disabled={disabled}>
        {icon}
      </S.IconButton>
    );
  } else if (icon && label) {
    button = (
      <S.TextAndIconButton aria-label={tooltip} title={tooltip} onClick={onClick} toggled={toggled} disabled={disabled}>
        {iconPos === "left" && icon}
        {label}
        {iconPos === "right" && icon}
      </S.TextAndIconButton>
    );
  } else if (!icon && label) {
    button = (
      <S.TextButton aria-label={tooltip} title={tooltip} onClick={onClick} toggled={toggled} disabled={disabled}>
        {label}
      </S.TextButton>
    );
  }

  return button;
};

ToolbarButton.propTypes = {
  icon: PropTypes.object,
  iconPos: PropTypes.string,
  label: PropTypes.string,
  toggled: PropTypes.bool,
  tooltip: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ToolbarButton;
