import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./attacker-query-key-factory";

export interface ISetAttackerMeans {
  attackerId: string;
  means: string;
}

/**
 * sets means of the selected attacker
 * @param {string} attackerId - uuid of the attacker
 * @param {string} means - string value of available means
 *
 */
export const setAttackerMeans = ({ attackerId, means }: ISetAttackerMeans) => {
  return Brm.attackerApi.setAttackerAvailableMeans(attackerId, { body: means });
};

export interface IUseSetAttackerMeans {
  options?: MutationOption<typeof setAttackerMeans>;
}

/**
 * Custom hook to change the means of a existing Attacker
 * @param {object} options hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerMeans = ({ options }: IUseSetAttackerMeans = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.attackerKeys.all);
    },
    ...options,
    mutationFn: setAttackerMeans,
  });
};
