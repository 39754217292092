import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import { DetailTableType } from "features/brm";
import ThreatGroupTable from "../../tables/details/ThreatGroupTable";

const ThreatCatalogDetails = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.THREAT_CATALOGS.key;

  return (
    <Tabs>
      <TabList>
        <Tab>Threat Group</Tab>
      </TabList>

      <TabPanel>
        <ThreatGroupTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
    </Tabs>
  );
};

ThreatCatalogDetails.propTypes = {
  selectedElement: PropTypes.any,
};

export default ThreatCatalogDetails;
