import ReactModal from "react-modal";
import Cancel from "assets/icons/cancelModal.svg";
import * as S from "./AttackMechanismNoteModal.styles";

interface IAttackMechanismNoteModalProps {
  title: string;
  isVisible: boolean;
  onClosing: () => void;
  mechanisms: string[];
}

export const AttackMechanismNoteModal = ({
  title,
  isVisible,
  onClosing,
  mechanisms,
}: IAttackMechanismNoteModalProps) => {
  return (
    <ReactModal
      isOpen={isVisible}
      onAfterClose={onClosing}
      ariaHideApp={false}
      onRequestClose={onClosing}
      style={S.rootModalStyle}
    >
      <S.Header>
        <S.ModalTitle>Attack Mechanism: {title}</S.ModalTitle>
        <S.HeaderButtonsDiv>
          <S.Close onClick={onClosing}>
            <S.CloseIcon src={Cancel} />
          </S.Close>
        </S.HeaderButtonsDiv>
      </S.Header>
      <S.Body>
        <S.MechanismContainer>
          <S.NoteList>
            {mechanisms?.map((m, i) => {
              // eslint-disable-next-line react/no-array-index-key
              return <S.Note key={`id${i}`}>{m}</S.Note>;
            })}
          </S.NoteList>
        </S.MechanismContainer>
      </S.Body>
    </ReactModal>
  );
};
