import { DetailTableType } from "features/brm";
import FactAnalysisInventoryRptTable from "brm/reports/FactAnalysisInventoryRptTable";
import FactAnalysisInfoLevelRptTable from "brm/reports/FactAnalysisInfoLevelRptTable";
import FactAnalysisRptTable from "brm/reports/FactAnalysisRptTable";
import CSFrameworkTable from "brm/reports/CSFrameworkTable";
import KppFrameworkTable from "brm/reports/KppFrameworkTable";
import VariantDiffTable from "brm/tables/details/VariantDiffTable";
import CntrlGuidanceRptTable from "brm/reports/CntrlGuidanceRptTable";
import SystemCweGuidanceRptTable from "brm/reports/SystemCweGuidanceRptTable";
import CweRptTable from "brm/reports/CweRptTable";
import CntrlRptTable from "brm/reports/CntrlRptTable";
import { RarReport, IRiskElement } from "brm/reports/rar/RarReport";
import CharacteristicTable from "../CharacteristicTable";
import InTable from "../InTable";
import ActivityTable from "../ActivityTable";
import DataTable from "../DataTable";
import DataflowTable from "../DataflowTable";
import NodeTable from "../NodeTable";
import ExchangeTable from "../ExchangeTable";
import OutTable from "../OutTable";
import ResourceTable from "../ResourceTable";
import InDataTable from "../InDataTable";
import PersonTable from "../PersonTable";
import AllDataTable from "../AllDataTable";
import CapabilityTable from "../CapabilityTable";
import ChildTable from "../ChildTable";
import OutDataTable from "../OutDataTable";
import MissionTable from "../MissionTable";
import MemberTable from "../MemberTable";
import StoredTable from "../StoredTable";
import GroupTable from "../GroupTable";
import EntryPointTable from "../EntryPointTable";
import AccessPointTable from "../AccessPointTable";
import AttackVectorTable from "../AttackVectorTable";
import AttackSurfaceTable from "../AttackSurfaceTable";
import TREVTable from "../TREVTable";
import AttackerTable from "../AttackerTable";
import AttackTable from "../AttackTable";
import DependentAssetTable from "../DependentAssetTable";
import DependentUndesiredEventTable from "../DependentUndesiredEventTable";
import AssetTable from "../AssetTable";
import UndesiredEventTable from "../UndesiredEventTable";
import VulnerabilityTable from "../VulnerabilityTable";
import RiskTable from "../RiskTable";
import ControlTable from "../AllocatedControlTable";
import PivotPathTable from "../PivotPathTable";
import DependentRiskTable from "../DependentRiskTable";
import NextTable from "../NextTable";
import PrevTable from "../PrevTable";
import { useRoles } from "../../../../features/brm/hooks/useRoles";
import BorFindingsTable from "../BorFindingsTable";
import AccessableNodesTable from "../AccesableNodes";
import ControlTypeTable from "../ControlTypeTable";
import SystemObjectTable from "../SystemObjectTable";
import { ProducingActivityTable } from "../ProducingActivity";
import { ConsumingActivityTable } from "../ConsumingActivity";
import ProducerTable from "../ProducerTable";
import ConsumerTable from "../ConsumerTable";
import TopicTable from "../TopicTable";

interface IDetailsTableMapProps {
  elementNameInUrl: string;
  selectedElement: {
    id: string;
  };
  selectedContext: string;
}

interface IDetailMap {
  [index: string]: JSX.Element;
}

export const DetailsTableMap = ({ elementNameInUrl, selectedElement, selectedContext }: IDetailsTableMapProps) => {
  const { isSystemEngineer } = useRoles();

  const detailsMap: IDetailMap = {
    [DetailTableType.CHARACTERISTICS.key]: (
      <CharacteristicTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.CHARACTERISTICS.name}
      />
    ),
    [DetailTableType.IN_EXCHANGE.key]: (
      <InTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.IN_EXCHANGE.name}
      />
    ),
    [DetailTableType.OUT_EXCHANGE.key]: (
      <OutTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.OUT_EXCHANGE.name}
      />
    ),
    [DetailTableType.IN_DATA.key]: (
      <InDataTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.IN_DATA.name}
      />
    ),
    [DetailTableType.OUT_DATA.key]: (
      <OutDataTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.OUT_DATA.name}
      />
    ),
    [DetailTableType.ACTIVITIES.key]: (
      <ActivityTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        showAdd={
          isSystemEngineer &&
          (elementNameInUrl === DetailTableType.CAPABILITIES.key ||
            elementNameInUrl === DetailTableType.DATAFLOWS.key ||
            elementNameInUrl === DetailTableType.RESOURCES.key ||
            elementNameInUrl === DetailTableType.PERSON.key ||
            elementNameInUrl === DetailTableType.ACCESS_POINTS.key ||
            elementNameInUrl === DetailTableType.ATTACK_VECTORS.key ||
            elementNameInUrl === DetailTableType.ENTRY_POINTS.key)
        }
        showDelete={
          isSystemEngineer &&
          (elementNameInUrl === DetailTableType.CAPABILITIES.key ||
            elementNameInUrl === DetailTableType.DATAFLOWS.key ||
            elementNameInUrl === DetailTableType.RESOURCES.key ||
            elementNameInUrl === DetailTableType.PERSON.key ||
            elementNameInUrl === DetailTableType.ACCESS_POINTS.key ||
            elementNameInUrl === DetailTableType.ATTACK_VECTORS.key ||
            elementNameInUrl === DetailTableType.ENTRY_POINTS.key)
        }
        tableTitle={DetailTableType.ACTIVITIES.name}
      />
    ),
    [DetailTableType.PERSONS.key]: (
      <PersonTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        showAdd={false}
        showDelete={false}
        tableTitle={DetailTableType.PERSON.name}
      />
    ),
    [DetailTableType.CAPABILITIES.key]: (
      <CapabilityTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        showAdd={isSystemEngineer && elementNameInUrl === DetailTableType.MISSIONS.key}
        showDelete={isSystemEngineer && elementNameInUrl === DetailTableType.MISSIONS.key}
        tableTitle={DetailTableType.CAPABILITIES.name}
      />
    ),
    [DetailTableType.CHILDREN.key]: (
      <ChildTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.CHILDREN.name}
      />
    ),
    [DetailTableType.NEXT.key]: (
      <NextTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        showAdd={isSystemEngineer && elementNameInUrl === DetailTableType.ACTIVITIES.key}
        showDelete={isSystemEngineer && elementNameInUrl === DetailTableType.ACTIVITIES.key}
        tableTitle={DetailTableType.NEXT.name}
      />
    ),
    [DetailTableType.PREVIOUS.key]: (
      <PrevTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.PREVIOUS.name}
      />
    ),
    [DetailTableType.MEMBER.key]: (
      <MemberTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.MEMBER.name}
      />
    ),
    [DetailTableType.STORED_DATA.key]: (
      <StoredTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        showAdd={
          isSystemEngineer &&
          (elementNameInUrl === DetailTableType.ACTIVITIES.key || elementNameInUrl === DetailTableType.NODES.key)
        }
        showDelete={
          isSystemEngineer &&
          (elementNameInUrl === DetailTableType.ACTIVITIES.key || elementNameInUrl === DetailTableType.NODES.key)
        }
        tableTitle={DetailTableType.STORED_DATA.name}
      />
    ),
    [DetailTableType.GROUP.key]: (
      <GroupTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        showAdd={isSystemEngineer && elementNameInUrl === DetailTableType.NODES.key}
        showDelete={isSystemEngineer && elementNameInUrl === DetailTableType.NODES.key}
        tableTitle={DetailTableType.GROUP.name}
      />
    ),
    [DetailTableType.NODES.key]: (
      <NodeTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        showAdd={
          isSystemEngineer &&
          (elementNameInUrl === DetailTableType.CAPABILITIES.key ||
            elementNameInUrl === DetailTableType.PERSON.key ||
            elementNameInUrl === DetailTableType.RESOURCES.key)
        }
        showDelete={
          isSystemEngineer &&
          (elementNameInUrl === DetailTableType.CAPABILITIES.key ||
            elementNameInUrl === DetailTableType.PERSON.key ||
            elementNameInUrl === DetailTableType.RESOURCES.key)
        }
        tableTitle={DetailTableType.NODES.name}
      />
    ),
    [DetailTableType.ACCESSIBLE_NODES.key]: (
      <AccessableNodesTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} tableTitle="Nodes" />
    ),
    [DetailTableType.DATA.key]: (
      <DataTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        showAdd={false}
        showDelete={false}
        tableTitle={DetailTableType.DATA.name}
      />
    ),
    [DetailTableType.EXCHANGES.key]: (
      <ExchangeTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        showAdd={isSystemEngineer && elementNameInUrl === DetailTableType.DATAFLOWS.key}
        showDelete={isSystemEngineer && elementNameInUrl === DetailTableType.DATAFLOWS.key}
        tableTitle={DetailTableType.EXCHANGES.name}
      />
    ),
    [DetailTableType.MISSIONS.key]: (
      <MissionTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.MISSIONS.name}
      />
    ),
    [DetailTableType.DATAFLOWS.key]: (
      <DataflowTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.DATAFLOWS.name}
      />
    ),
    [DetailTableType.RESOURCES.key]: (
      <ResourceTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.RESOURCES.name}
      />
    ),
    [DetailTableType.ACCESS_POINTS.key]: (
      <AccessPointTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.ACCESS_POINTS.name}
      />
    ),
    [DetailTableType.ENTRY_POINTS.key]: (
      <EntryPointTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.ENTRY_POINTS.name}
        showAdd={false}
      />
    ),
    [DetailTableType.ATTACK_VECTORS.key]: (
      <AttackVectorTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.ATTACK_VECTORS.name}
      />
    ),
    [DetailTableType.ATTACK_SURFACES.key]: (
      <AttackSurfaceTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.ATTACK_SURFACES.name}
      />
    ),
    [DetailTableType.THREAT_EVENTS.key]: (
      <TREVTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.THREAT_EVENTS.name}
      />
    ),
    [DetailTableType.ATTACKERS.key]: (
      <AttackerTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.ATTACKERS.name}
      />
    ),
    [DetailTableType.ATTACKS.key]: (
      <AttackTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.ATTACKS.name}
      />
    ),
    [DetailTableType.RISKS.key]: (
      <RiskTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.RISKS.name}
      />
    ),
    [DetailTableType.DEP_ASSETS.key]: (
      <DependentAssetTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.ASSETS.name}
      />
    ),
    [DetailTableType.DEP_UNDESIRED_EVENTS.key]: (
      <DependentUndesiredEventTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.UNDESIRED_EVENTS.name}
      />
    ),
    [DetailTableType.VULNERABILITY_CONDITIONS.key]: (
      <VulnerabilityTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.VULNERABILITY_CONDITIONS.name}
      />
    ),
    [DetailTableType.VULNERABILITY_FINDINGS.key]: (
      <BorFindingsTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.VULNERABILITY_FINDINGS.name}
      />
    ),
    [DetailTableType.ALLOCATED_CONTROLS.key]: (
      <ControlTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.ALLOCATED_CONTROLS.name}
      />
    ),
    [DetailTableType.ASSETS.key]: (
      <AssetTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.ASSETS.name}
      />
    ),
    [DetailTableType.UNDESIRED_EVENTS.key]: (
      <UndesiredEventTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.UNDESIRED_EVENTS.name}
      />
    ),
    [DetailTableType.ALL_DATA.key]: (
      <AllDataTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.ALL_DATA.name}
      />
    ),
    [DetailTableType.PIVOT_PATHS.key]: (
      <PivotPathTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.PIVOT_PATHS.name}
      />
    ),
    [DetailTableType.DEP_RISKS.key]: (
      <DependentRiskTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.RISKS.name}
      />
    ),
    [DetailTableType.CONTROL_TYPES.key]: (
      <ControlTypeTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.CONTROL_TYPES.name}
      />
    ),
    [DetailTableType.TARGETS.key]: (
      <SystemObjectTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.TARGETS.name}
      />
    ),
    [DetailTableType.PARENT.key]: (
      <NodeTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        showAdd={false}
        showDelete={false}
        tableTitle={DetailTableType.PARENT.name}
      />
    ),
    [DetailTableType.PRODUCING_ACTIVITY.key]: (
      <ProducingActivityTable selectedElement={selectedElement} tableTitle={DetailTableType.PRODUCING_ACTIVITY.name} />
    ),
    [DetailTableType.CONSUMING_ACTIVITY.key]: (
      <ConsumingActivityTable selectedElement={selectedElement} tableTitle={DetailTableType.CONSUMING_ACTIVITY.name} />
    ),
    [DetailTableType.PRODUCER.key]: (
      <ProducerTable selectedElement={selectedElement} tableTitle={DetailTableType.PRODUCER.name} />
    ),
    [DetailTableType.CONSUMER.key]: (
      <ConsumerTable selectedElement={selectedElement} tableTitle={DetailTableType.CONSUMER.name} />
    ),
    [DetailTableType.TOPIC.key]: (
      <TopicTable
        elementNameInUrl={elementNameInUrl}
        selectedElement={selectedElement}
        tableTitle={DetailTableType.TOPIC.name}
      />
    ),
    [DetailTableType.FACT_ANALYSIS_RPT.key]: (
      <FactAnalysisRptTable selectedElement={selectedElement} tableTitle={DetailTableType.FACT_ANALYSIS_RPT.name} />
    ),
    [DetailTableType.FACT_ANALYSIS_INFORMATION_LEVEL_RPT.key]: (
      <FactAnalysisInfoLevelRptTable
        selectedElement={selectedElement}
        tableTitle={DetailTableType.FACT_ANALYSIS_INFORMATION_LEVEL_RPT.name}
      />
    ),
    [DetailTableType.FACT_ANALYSIS_INVENTORY_RPT.key]: (
      <FactAnalysisInventoryRptTable
        selectedElement={selectedElement}
        tableTitle={DetailTableType.FACT_ANALYSIS_INVENTORY_RPT.name}
      />
    ),
    [DetailTableType.CNTRL_GUINDANCE_RPT.key]: (
      <CntrlGuidanceRptTable selectedElement={selectedElement} tableTitle={DetailTableType.CNTRL_GUINDANCE_RPT.name} />
    ),
    [DetailTableType.CNTRL_RPT.key]: (
      <CntrlRptTable selectedElement={selectedElement} tableTitle={DetailTableType.CNTRL_RPT.name} />
    ),
    [DetailTableType.CSF_RPT.key]: (
      <CSFrameworkTable selectedElement={selectedElement} tableTitle={DetailTableType.CSF_RPT.name} />
    ),
    [DetailTableType.CSA_KPP_RPT.key]: (
      <KppFrameworkTable selectedElement={selectedElement} tableTitle={DetailTableType.CSA_KPP_RPT.name} />
    ),
    [DetailTableType.VARIANT_DIFF_RPT.key]: (
      <VariantDiffTable selectedElement={selectedElement} tableTitle={DetailTableType.VARIANT_DIFF_RPT.name} />
    ),
    [DetailTableType.SYSTEM_CWE_RPT.key]: (
      <SystemCweGuidanceRptTable selectedElement={selectedElement} tableTitle={DetailTableType.SYSTEM_CWE_RPT.name} />
    ),
    [DetailTableType.CWE_RPT.key]: <CweRptTable tableTitle={DetailTableType.CWE_RPT.name} />,
    [DetailTableType.RAR_RPT.key]: <RarReport riskElement={selectedElement as unknown as IRiskElement} />,
  };

  return detailsMap[selectedContext] || null;
};

DetailsTableMap.tabsRole = "TabPanel";
