import { Img } from "./ImgComponent.style";

interface IImgProps {
  alt?: string | undefined;
  id?: string | undefined;
  src?: string | undefined;
  height?: string | undefined;
  width?: string | undefined;
  className?: string | undefined;
}

export const ImgComponent = ({ id, alt, src, height, width, className }: IImgProps) => {
  return <Img className={className} height={height} width={width} src={src} alt={alt || ""} id={id} />;
};
