import { NodeCreator, Point } from "yfiles";

/**
 * Creates Nodes delegates to to DiagramBuilder
 */
export default class DiagramNodeCreator extends NodeCreator {
  /**
   * Creates a new instance of {@link DiagramNodeCreator}
   * @param {!DiagramBuilder} $diagramBuilder
   */
  constructor($diagramBuilder) {
    super();
    this.$diagramBuilder = $diagramBuilder;
  }

  /**
   * Create a node delegates creation to digramBuilder.
   * @param {!IGraph} graph
   * @param {?INode} parent
   * @param {*} dataItem
   * @returns {!INode}
   */
  createNode(graph, parent, dataItem) {
    const location = this.$getLocation(dataItem, Point.ORIGIN);
    const labelData = this.$getLabelData(dataItem);
    const nodeObject = this.$getNodeObject(dataItem);
    return this.$diagramBuilder.$builderCreateNode(graph, parent, location, labelData, nodeObject);
  }

  /**
   * Updates the given node, delegates to diagramBuilder.
   * @param {!IGraph} graph
   * @param {!INode} node
   * @param {?INode} parent
   * @param {*} dataItem
   */
  updateNode(graph, node, parent, dataItem) {
    const location = this.$getLocation(dataItem, node.layout.topLeft);
    const labelData = this.$getLabelData(dataItem);
    const nodeObject = this.$getNodeObject(dataItem);
    this.$diagramBuilder.$builderUpdateNode(graph, node, parent, location, labelData, nodeObject);
  }

  /**
   * Return business object
   * @param {*} dataItem
   * @returns {*}
   */
  $getNodeObject(dataItem) {
    if (this.tagProvider) {
      return this.tagProvider(dataItem);
    }
    return dataItem;
  }

  /**
   * Returns node label.
   * @param {*} dataItem
   * @returns {*}
   */
  $getLabelData(dataItem) {
    return this.$diagramBuilder.nodeLabelProvider ? this.$diagramBuilder.nodeLabelProvider(dataItem) : null;
  }

  /**
   * Returns the location of the node
   * @param {*} dataItem
   * @param {!IPoint} fallback
   * @returns {!IPoint}
   */
  $getLocation(dataItem, fallback) {
    return new Point(
      this.$diagramBuilder.locationXProvider ? this.$diagramBuilder.locationXProvider(dataItem) : fallback.x,
      this.$diagramBuilder.locationYProvider ? this.$diagramBuilder.locationYProvider(dataItem) : fallback.y
    );
  }
}
