import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

/**
 * Gets the project
 */
export const getProject = ({ projectId }: { projectId: string | null }) => {
  return projectId === null ? Promise.reject(new Error("Invalid id")) : Brm.projectApi.getProjectByID(projectId);
};

interface IProjectProps {
  projectId: string | null;
  options?: QueryOption<typeof getProject>;
}

/**
 * Custom hook for getting the project
 *
 * @param {string} projectId - uuid of project
 * @param {object} config - react-query configuration
 * @returns
 */
export const useProject = ({ projectId, options = {} }: IProjectProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.project(projectId),
    queryFn: () => getProject({ projectId }),
  });
};
