import * as React from "react";
import ReactSelect from "react-select";
import { useControlCatalog } from "features/global-control/hooks";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { ErrorSpan } from "components/elements";
import { useMergeBaselineFormApi } from "./useMergeBaselineFormApi";

interface IMergeBaselineForm {
  currentBaselineId: string;
  onClose: () => void;
  onSubmit: ({ baselineId }: { baselineId: string }) => void;
}

export const MergeBaselineForm = ({ onClose, onSubmit, currentBaselineId }: IMergeBaselineForm) => {
  const { controlCatalogId } = useControlCatalog();
  const { catalogBaselineOptions } = useMergeBaselineFormApi({ catalogId: controlCatalogId || "", currentBaselineId });
  const [baselineId, setBaselineId] = React.useState("");
  const [selectionErrorMsg, setSelectionErrorMsg] = React.useState("");

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (baselineId === "") {
      setSelectionErrorMsg("Select a baseline to merge");
    } else {
      onSubmit({ baselineId });
      onClose();
    }
  };

  const handleBaselineSelection = (option: any) => {
    if (option) {
      setBaselineId(option.value);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <form onSubmit={handleSubmit} style={{ padding: "5px" }}>
        <ReactSelect options={catalogBaselineOptions} onChange={handleBaselineSelection} />
        {selectionErrorMsg === "" ? null : <ErrorSpan>{selectionErrorMsg}</ErrorSpan>}
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={onClose}>Cancel</DialogButtonStyled>
          <DialogButtonStyled type="submit">Merge</DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};
