import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { ProjectStatus } from "features/brm/types";
import * as KeyFactory from "./project-query-key-factory";

export interface IGetProjectWithOrgName {
  id: string | any;
  status: ProjectStatus;
  name: string;
  isListed: boolean;
  organization: { id: string; name: string; status: ProjectStatus };
}

/**
 * gets project data by ID
 * @param {string} id uuid of the project
 * @returns
 */
export const getProjectWithOrgName = async (id: string): Promise<IGetProjectWithOrgName> => {
  let data = await Brm.projectApi.getProjectByID(id);
  const orgName = await Brm.organizationApi.getOrganizationName(data.organization);
  data = {
    ...data,
    orgName: orgName.result,
  };
  return data;
};

interface IUseGetProjectWithOrgName {
  id: string;
  options?: QueryOption<typeof getProjectWithOrgName>;
}

/**
 * Custom-hook to get project details by its ID
 *
 * @param {string} id - uuid of the project
 * @param {object} options - react-query configuration object
 * @returns react-query for getImportHistory
 */
export const useGetProjectWithOrgName = ({ id, options }: IUseGetProjectWithOrgName) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.projectWithOrgName(id),
    queryFn: () => getProjectWithOrgName(id),
  });
};
