import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./sap-binding-query-key-factory";

interface IGetSapOrgBindingProps {
  organizationId?: string;
}

/**
 * Gets the sap org bindings
 */
export const getSapOrgBindings = ({ organizationId }: IGetSapOrgBindingProps) => {
  if (organizationId) {
    return Brm.sapOrgBindingApi.findSapOrgBinding({ org: organizationId });
  }
  return Brm.sapOrgBindingApi.findSapOrgBinding();
};

type FuncType = typeof getSapOrgBindings;

interface ISettingsProps {
  organizationId?: string;
  options?: QueryOption<FuncType>;
}

/**
 * Custom hook for getting the sap org bindings
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useSapOrgBindings = ({ organizationId, options = {} }: ISettingsProps = {}) => {
  const key = organizationId ? KeyFactory.sapBindingKeys.org(organizationId) : KeyFactory.sapBindingKeys.allOrg;

  return ReactQuery.useQuery({
    ...options,
    queryKey: key,
    queryFn: () => getSapOrgBindings({ organizationId }),
  });
};
