import { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { DeleteButton, AddButton, GlobalControlApi } from "features/brm";
import { controlFamilyIdState } from "atoms/atoms-global-control";
import { selectedInstanceState } from "atoms/atoms-content";
import * as Recoil from "recoil";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { controlFamilyApi } from "services/brm/global-control-service";
import { TYPE } from "constants/brm";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { LoadingSpinner } from "components/elements";
import { ControlFamilyTable, AddControlFamilyForm } from "../../components";
import { useControlCatalog } from "../../hooks";

export function ControlFamilies() {
  const [controlFamilyId, setControlFamilyId] = Recoil.useRecoilState(controlFamilyIdState);
  const [, setSelectedInstance] = Recoil.useRecoilState(selectedInstanceState);

  const [selectedRows, setSelectedRows] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const queryClient = useQueryClient();
  const { controlCatalog, isReadOnlyCatalog } = useControlCatalog();

  const addControlFamily = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      await Promise.all(
        selectedRows.map((each: any) => {
          if (controlFamilyId === each.id) {
            setControlFamilyId(null);
          }
          return controlFamilyApi.deleteControlFamilyWithHttpInfo(each.id);
        })
      )
        .then(() => {
          setConfirmDelete(false);
          queryClient.invalidateQueries(GlobalControlApi.controlFamilyKeys.all);
          setDeleteModalIsOpen(false);
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, controlFamilyId, queryClient, selectedRows, setControlFamilyId, setSelectedInstance]);

  if (controlCatalog) {
    const header = (
      <Title>
        Control Families
        {controlCatalog.id && (
          <Buttons>
            {!isReadOnlyCatalog && selectedRows.length > 0 ? (
              <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />
            ) : null}
            {isReadOnlyCatalog ? null : <AddButton onClick={addControlFamily} />}
          </Buttons>
        )}
      </Title>
    );

    const main = (
      <>
        {deleteModalIsOpen &&
          systemModals.deleteModal(
            "ControlFamily_page",
            deleteModalIsOpen,
            setDeleteModalIsOpen,
            <GenericDeleteConfirmation
              elementName={TYPE.controlFamily}
              setDeleteModalIsOpen={setDeleteModalIsOpen}
              selectedRows={selectedRows}
              setConfirmDelete={setConfirmDelete}
            />,
            TYPE.controlFamily
          )}

        {modalIsOpen &&
          systemModals.addModal(
            "ControlFamily_page",
            modalIsOpen,
            setModalIsOpen,
            <AddControlFamilyForm setModalIsOpen={setModalIsOpen} />,
            TYPE.controlFamily
          )}

        <ControlFamilyTable setSelectedRows={setSelectedRows} />
      </>
    );

    return <ContentLayout pageId="ControlFamily_page" header={header} main={main} />;
  }
  return <LoadingSpinner />;
}
