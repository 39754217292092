import { io } from "socket.io-client";
import { SOCKET_NAMESPACE, SOCKET_PATH } from "constants/brm";

export const getWebsocketUrl = () => {
  // console.log("websocket host", process.env.REACT_APP_WEBSOCKET_HOST);
  let url = `ws://${process.env.REACT_APP_WEBSOCKET_HOST}:${process.env.REACT_APP_WEBSOCKET_PORT}`;
  if (window.location.hostname !== "localhost") {
    url = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }`;
  }
  return url;
};

interface IWebsocketConfig {
  namespace: string;
  auth: { [key: string]: any };
}

export const createLocalWebsocket = ({ namespace = SOCKET_NAMESPACE.general, auth = {} }: IWebsocketConfig) => {
  const wsUrl = getWebsocketUrl();
  // console.log("createLocalWebsocket", `${wsUrl}${namespace}`);
  const socket = io(`${wsUrl}${namespace}`, { transports: ["websocket"], path: SOCKET_PATH, auth });
  // console.log("createLocalWebsocket", socket);
  return socket;
};
