import { useRef, useEffect, useState } from "react";
import { getServicesSocketNsp, secureSocketService } from "utils/socket-service";
import { SIO, CRUD_EVENT } from "constants/brm";

const useSioCrudService = (projectId) => {
  const [authorized, setAuthorized] = useState(false);
  const [sioError, setSioError] = useState(false);
  const localSocket = useRef(null);
  const localProjectId = useRef(projectId);

  function waitCrudEvent(eventName, cb) {
    if (localSocket.current) {
      if (eventName === CRUD_EVENT.systemModel) {
        /**
         * Client is signalling the need to listen to 'all' 10 system elements.
         * Register to listen to each one of them
         */
        localSocket.current.on(`${SIO.event}${CRUD_EVENT.node}`, (data) => {
          cb(data);
        });
        localSocket.current.on(`${SIO.event}${CRUD_EVENT.person}`, (data) => {
          cb(data);
        });
        localSocket.current.on(`${SIO.event}${CRUD_EVENT.activity}`, (data) => {
          cb(data);
        });
        localSocket.current.on(`${SIO.event}${CRUD_EVENT.bus}`, (data) => {
          cb(data);
        });
        localSocket.current.on(`${SIO.event}${CRUD_EVENT.capability}`, (data) => {
          cb(data);
        });
        localSocket.current.on(`${SIO.event}${CRUD_EVENT.dataFlow}`, (data) => {
          cb(data);
        });
        localSocket.current.on(`${SIO.event}${CRUD_EVENT.datatype}`, (data) => {
          cb(data);
        });
        localSocket.current.on(`${SIO.event}${CRUD_EVENT.exchange}`, (data) => {
          cb(data);
        });
        localSocket.current.on(`${SIO.event}${CRUD_EVENT.link}`, (data) => {
          cb(data);
        });
        localSocket.current.on(`${SIO.event}${CRUD_EVENT.mission}`, (data) => {
          cb(data);
        });
      } else {
        /**
         * Client has chosen a specific system element to listen to
         */
        localSocket.current.on(`${SIO.event}${eventName}`, (data) => {
          cb(data);
        });
      }
    }
  }

  async function joinCrudRoom(roomName) {
    if (localSocket.current) {
      // console.log(`Join room: ${roomName}`);
      localSocket.current.emit(SIO.joinRoom, { roomName });
    }
  }

  function leaveCrudRoom(roomName) {
    if (localSocket.current) {
      // console.log(`Leave room: ${roomName}`);
      localSocket.current.emit(SIO.leaveRoom, { roomName });
    }
  }

  useEffect(() => {
    async function initSocket() {
      try {
        // console.log(`wait for crud secureSocketService to execute`);
        localSocket.current = getServicesSocketNsp({
          projectId: localProjectId.current,
        });
        await secureSocketService(localSocket.current);
        // console.log(`crud secureSocketService completed successfully`);
        setAuthorized(true);
      } catch (error) {
        // console.error(`crud secureSocketService. Error: `, error);
        setSioError(error);
      }
    }

    // console.log(`localSocket.current: `, localSocket.current);
    if (localSocket.current === null) {
      initSocket();
    }

    return function cleanup() {
      if (localSocket.current !== null) {
        // console.log(`initSocket cleanup - disconnect localSocket`);
        localSocket.current.disconnect();
        localSocket.current = null;
      }
    };
  }, [projectId]);

  return [
    {
      joinCrudRoom,
      leaveCrudRoom,
      waitCrudEvent,
      id: localSocket.current ? localSocket.current.id : "null Id",
    },
    authorized,
    sioError,
  ];
};

export default useSioCrudService;
