import { MouseEvent } from "react";
import { MdSettingsBackupRestore } from "react-icons/md";
import { InlineButton } from "features/brm";

interface ISavePointButtonProps {
  onClick: (event: MouseEvent) => void;
  disableSavePoint: boolean;
}

export const CreateSavePointButton = ({ onClick, disableSavePoint = false }: ISavePointButtonProps) => {
  return (
    <InlineButton
      title={!disableSavePoint ? "Create Save Point" : "Creating Save Point"}
      id="RestoreFromFileButton_button"
      onClick={onClick}
      disabled={disableSavePoint}
    >
      <MdSettingsBackupRestore size="16" />
    </InlineButton>
  );
};
