import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { accessPointKeys } from "./access-query-key-factory";

/**
 * Returns a list of all entrypoints for a given access point
 *
 * @param {string} projectId - uuid of project
 * @returns list of all entry points within the given project
 */
export const getAccessPointEntryPoints = async ({ accessPointId }: { accessPointId: string }) => {
  return Brm.accessPointApi.getAccessPointAllEntrypoint(accessPointId);
};

interface IUseEntryPoints {
  accessPointId: string;
  options?: QueryOption<typeof getAccessPointEntryPoints>;
}

/**
 * Custom-hook to retrieve all entry points for a accesspoint.
 *
 * @param {string} accessPointId - uuid of access point
 * @param {object} config - react-query configuration object
 * @returns react-query for findAttackSurfaces
 */
export const useAccessPointEntryPoints = ({ accessPointId, options }: IUseEntryPoints) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: accessPointKeys.entryPoints(accessPointId),
    queryFn: () => getAccessPointEntryPoints({ accessPointId }),
  });
};
