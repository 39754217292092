import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./attack-query-key-factory";

/**
 * Custom hook to retreive an attack.
 *
 * @param {string} attackId - uuid of a attack
 * @param {string} variantId - uuid of the current variant
 * @returns react-query for getAttackByID
 */
export const getAttack = async ({ attackId, variantId }) => {
  const attack = await Brm.attackApi.getAttackByID(attackId, { variant: variantId });
  attack.target = await Brm.attackApi.getAttackTarget(attackId);
  const systemAsset = await Brm.attackApi.getAttackSystemasset(attackId);
  attack.systemasset = systemAsset;
  attack.satype = systemAsset.satype;
  attack.trev = await Brm.attackApi.getAttackTrev(attackId);
  attack.ttp = await Brm.attackApi.getAttackTtp(attackId);

  return attack;
};

export const useAttack = ({ attackId, variantId, config = {} }) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: KeyFactory.attackKeys.attack(attackId, variantId),
    queryFn: () => getAttack({ attackId, variantId }),
  });
};
