import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import { AddButton, DeleteButton, AdminApi } from "features/brm";
import {
  createColumnMappedNoEdit,
  createColumnUsernameDetails,
  createColumnCreatedAt,
  createColumnModifiedAt,
} from "brm/tables/services/column/columnFactory";
import * as S from "brm/styles/details-table.styles";
import { LoadingSpinner as Loading } from "components/elements";
import { RoutePath } from "routes/route-paths";
import { AddReadOnlyUserForm } from "../../forms/AddReadOnlyUserForm";
// Styles

export const ReadOnlyUserDetailsTable = ({ elementNameInUrl, project }) => {
  const columns = useMemo(
    () => [
      createColumnUsernameDetails(RoutePath.User.replace(":id", "")),
      createColumnMappedNoEdit("firstname"),
      createColumnMappedNoEdit("lastname"),
      createColumnMappedNoEdit("org"),
      createColumnMappedNoEdit("status"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
    ],
    []
  );
  const [selectedReadOnly, setSelectedReadOnly] = useState([]);
  // Modals
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showAdd] = useState(true);
  const [showDelete] = useState(true);

  const { data: readOnlyData } = AdminApi.useProjectReadOnlyUsers({ projectId: project.id });
  const { mutateAsync: removeProjectReadOnlyUser } = AdminApi.useRemoveProjectReadOnlyUser();

  const addReadOnly = () => {
    setModalIsOpen(true);
  };

  const deleteReadOnly = async () => {
    await Promise.all(
      selectedReadOnly.map((ro) => removeProjectReadOnlyUser({ projectId: project.id, userId: ro.id }))
    );
  };

  if (readOnlyData) {
    return (
      <S.DetailsContainer id="ReadOnlyTable_detailsPanel">
        {/* modal for adding instance */}
        {modalIsOpen &&
          systemModals.addModal(
            "ReadOnlyTable_detailsPanel",
            modalIsOpen,
            setModalIsOpen,
            <AddReadOnlyUserForm
              setModalIsOpen={setModalIsOpen}
              elementNameInUrl={elementNameInUrl}
              selectedElement={project}
            />,
            "Read Only"
          )}
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={readOnlyData}
              columns={columns}
              setSelectedElement={setSelectedReadOnly}
              setSelectedRows={setSelectedReadOnly}
              customProps={{ id: "ReadOnlyTable_detailsTable" }}
              showRowSelect={showDelete}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            {showDelete && selectedReadOnly?.length > 0 && <DeleteButton md onClick={deleteReadOnly} />}
            {showAdd && <AddButton md onClick={addReadOnly} />}
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }
  return <Loading />;
};

ReadOnlyUserDetailsTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  project: PropTypes.shape({
    id: PropTypes.any,
  }),
};
