import { useState, useMemo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
// State
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";

import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable, RetainStringColumnFilter } from "brm/tables/BrmTables";
import { TYPE } from "constants/brm";

import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { useQueryClient } from "@tanstack/react-query";
import { SystemApi } from "features/brm";
import { useCanRename } from "features/system-model";

import {
  createColumnName,
  createColumnMapped,
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";

const ExchangeTable = ({ setSelectedRows }) => {
  const { canRename } = useCanRename();
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);

  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedCategory = useRef("");
  const updatedProducer = useRef("");
  const updatedConsumer = useRef("");
  const updatedData = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const queryClient = useQueryClient();

  const { data: exchangesWithRisk, isError: isExchangesWithRiskError } = SystemApi.useExchangesWithRisk({
    projectId,
    variantId,
    options: { enabled: !!projectId && isRiskAnalyst },
  });

  const { data: exchanges, isError: isExchangesError } = SystemApi.useExchanges({
    projectId,
    options: { enabled: !!projectId && isSystemEngineer && !updatedData.current },
  });
  const { data: categoryList } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });
  const { data: nodeList } = SystemApi.useNodes({
    projectId,
    options: { enabled: !!projectId } && (isSystemEngineer || isRiskAnalyst),
  });
  const { data: dataList } = SystemApi.useDataTypes({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });

  const { mutate: setExchangeName } = SystemApi.useSetExchangeName({
    options: {
      onSettled: () => {
        setSelectedInstance({
          id: selectedInstance.id,
          name: updatedName.current,
          type: selectedInstance.type,
        });
        updatedName.current = "";
      },
      onError: (err) => {
        toast.error(`Setting exchange name caused an error: ${err}`);
      },
    },
  });
  const { mutate: setExchangeCategory } = SystemApi.useSetExchangeCategory({
    options: {
      onSettled: () => {
        updatedCategory.current = "";
      },
      onError: (err) => {
        toast.error(`Setting exchange category caused an error: ${err}`);
      },
    },
  });
  const { mutate: setExchangeConsumer } = SystemApi.useSetExchangeConsumer({
    options: {
      onSettled: () => {
        updatedConsumer.current = "";
      },
      onError: (err) => {
        toast.error(`Setting exchange consumer caused an error: ${err}`);
      },
    },
  });
  const { mutate: setExchangeProducer } = SystemApi.useSetExchangeProducer({
    options: {
      onSettled: () => {
        updatedProducer.current = "";
      },
      onError: (err) => {
        toast.error(`Setting exchange producer caused an error: ${err}`);
      },
    },
  });
  const { mutate: setExchangeData } = SystemApi.useSetExchangeData({
    options: {
      onSettled: () => {
        updatedData.current = "";
      },
      onError: (err) => {
        toast.error(`Setting exchange data caused an error: ${err}`);
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);
            if (updatedName.current !== "") {
              if (!canRename({ updatedName, elementsList: exchanges })) return;
              setExchangeName({ exchangeId: selectedInstance.id, name: updatedName.current });
            }

            if (updatedProducer.current !== "") {
              setExchangeProducer({ exchangeId: selectedInstance.id, producerId: updatedProducer.current });
            }

            if (updatedConsumer.current !== "") {
              setExchangeConsumer({ exchangeId: selectedInstance.id, consumerId: updatedConsumer.current });
            }

            if (updatedData.current !== "") {
              setExchangeData({ exchangeId: selectedInstance.id, dataTypeId: updatedData.current });
            }

            if (updatedCategory.current !== "") {
              setExchangeCategory({ exchangeId: selectedInstance.id, category: updatedCategory.current });
            }
            queryClient.invalidateQueries(SystemApi.exchangeKeys.all);
            queryClient.invalidateQueries(["exchanges", projectId]); // legacy
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [
      selectedInstance.id,
      editMode,
      queryClient,
      projectId,
      canRename,
      exchanges,
      setExchangeName,
      setExchangeProducer,
      setExchangeConsumer,
      setExchangeData,
      setExchangeCategory,
    ]
  );

  const checkNameUniqueness = useCallback(
    (newName) => {
      const trimedNamed = newName.trim();
      return isSystemEngineer && Array.isArray(exchanges)
        ? !exchanges.map((exchange) => exchange.name.toLowerCase()).includes(trimedNamed.toLowerCase())
        : false;
    },
    [isSystemEngineer, exchanges]
  );

  const columns = useMemo(() => {
    if (isRiskAnalyst) {
      return [
        createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "exchange"),
        createColumnCategoryMapped("category", selectedInstance.id, categoryList, false, updatedCategory),
        createColumnBooleanNoEditMapped("isInternal"),
        createColumnBooleanNoEditMapped("isBoundary"),
        createColumnBooleanNoEditMapped("isInput"),
        createColumnMapped("producer", selectedInstance.id, nodeList, false, updatedProducer),
        createColumnMapped("consumer", selectedInstance.id, nodeList, false, updatedConsumer),
        createColumnMapped("data", selectedInstance.id, dataList, false, updatedData),
        createColumnMappedNoEdit("riskDistributionRank"),
        createColumnMappedNoEdit("riskDistributionPercent"),
      ];
    }

    if (isSystemEngineer) {
      return [
        createColumnName(
          selectedInstance.id,
          setSelectedInstance,
          editMode,
          updatedName,
          "exchange",
          checkNameUniqueness,
          {
            Filter: RetainStringColumnFilter,
          }
        ),
        createColumnCategoryMapped("category", selectedInstance.id, categoryList, editMode, updatedCategory),
        createColumnBooleanNoEditMapped("isInternal"),
        createColumnBooleanNoEditMapped("isBoundary"),
        createColumnBooleanNoEditMapped("isInput"),
        createColumnMapped("producer", selectedInstance.id, nodeList, editMode, updatedProducer),
        createColumnMapped("consumer", selectedInstance.id, nodeList, editMode, updatedConsumer),
        createColumnMapped("data", selectedInstance.id, dataList, editMode, updatedData),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }

    return [
      createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "exchange"),
      createColumnCategoryMapped("category", selectedInstance.id, categoryList, false, updatedCategory),
      createColumnBooleanNoEditMapped("isInternal"),
      createColumnBooleanNoEditMapped("isBoundary"),
      createColumnBooleanNoEditMapped("isInput"),
      createColumnMapped("producer", selectedInstance.id, nodeList, false, updatedProducer),
      createColumnMapped("consumer", selectedInstance.id, nodeList, false, updatedConsumer),
      createColumnMapped("data", selectedInstance.id, dataList, false, updatedData),
    ];
  }, [
    isRiskAnalyst,
    isSystemEngineer,
    selectedInstance.id,
    setSelectedInstance,
    categoryList,
    nodeList,
    dataList,
    editMode,
    checkNameUniqueness,
    createButton,
  ]);

  if (isExchangesWithRiskError || isExchangesError) {
    return <ErrorBanner msg="Error while loading Exchange data." />;
  }

  if (exchangesWithRisk && isRiskAnalyst) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={exchangesWithRisk}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "Exchange_table" }}
            elementName={TYPE.exchange}
            showRowSelect={isSystemEngineer}
          />
        </CustomTable>
      </>
    );
  }

  if (exchanges && !isRiskAnalyst) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={exchanges}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "Exchange_table" }}
            elementName={TYPE.exchange}
            showRowSelect={isSystemEngineer}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

ExchangeTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

export default ExchangeTable;
