import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";
import * as KeyFactory from "./knowledgebase-query-key-factory";

interface KbRuleJson {
  ue?: any;
  rules: any;
}

interface ISetRule {
  kbId: string;
  json: KbRuleJson;
}

const setRules = ({ kbId, json }: ISetRule) => {
  return Brm.kbApi.setKBDeepRe(kbId, { body: json });
};

interface IUseSetRule {
  kbId: string;
  options?: MutationOption<typeof setRules>;
}

export const useSetRules = ({ kbId, options = {} }: IUseSetRule) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation({
    ...options,
    mutationFn: setRules,
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.ruleKey.kb(kbId));
    },
  });
};
