import PropTypes from "prop-types";
import { useTable } from "react-table";
import { useSetRecoilState } from "recoil";
import { selectedInstanceState } from "atoms/atoms-content";
import * as S from "./styles/tables.styles";

const BrmSingleElementTable = ({
  data,
  columns,
  setSelectedElement,
  setSelectedRowId,
  customProps = { id: "noIdTable_table" },
  elementName = "",
}) => {
  const setSelectedInstance = useSetRecoilState(selectedInstanceState);
  function selectRow(row) {
    setSelectedElement({
      id: row.id,
      name: row.name || row.username,
      type: elementName,
    });
    setSelectedRowId(row.id);
    if (row.name) {
      setSelectedInstance({
        id: row.id,
        name: row.name,
        type: elementName,
      });
    } else if (row.username) {
      setSelectedInstance({
        id: row.id,
        name: row.username,
        type: elementName,
      });
    }
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = useTable({
    columns,
    data,
  });

  return (
    <S.TableDiv>
      <div className="singleTable">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <S.Table {...getTableProps(customProps)}>
          <thead>
            {headerGroups.map((headerGroup) => (
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              <tr key="headerGroup" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  <th key="header" {...column.getHeaderProps()}>
                    <div>{column.render("Header")}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <tbody {...getTableBodyProps()}>
            {(rows.length > 0 &&
              rows.map((row) => {
                prepareRow(row);
                return (
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  <tr key="tableRow" {...row.getRowProps()} onClick={() => selectRow(row.original)}>
                    {row.cells.map((cell) => (
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      <td key="tableCell" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })) || (
              <tr>
                <td className="noDataFound" colSpan={visibleColumns.length}>
                  No data found...
                </td>
              </tr>
            )}
          </tbody>
        </S.Table>
      </div>
    </S.TableDiv>
  );
};

BrmSingleElementTable.propTypes = {
  columns: PropTypes.any,
  customProps: PropTypes.shape({
    id: PropTypes.string,
  }),
  data: PropTypes.any,
  elementName: PropTypes.string,
  setSelectedElement: PropTypes.func,
  setSelectedRowId: PropTypes.func,
};

BrmSingleElementTable.defaultProps = {
  customProps: {},
  setSelectedElement: {},
  setSelectedRowId: {},
};

export default BrmSingleElementTable;
