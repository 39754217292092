import * as React from "react";
import { ExportWizard } from "features/exporter";
import { CenteredSpinner, ErrorBanner, LoadingSpinner as Loading, DownloadButton } from "components/elements";
import systemModals from "components/modals/Modals";
import { COMMON, TYPE } from "constants/brm";
import {
  useProject,
  useVariant,
  ExportContext,
  ShieldButton /* , AddButton */,
  VariantSelectionModal,
} from "features/brm";

import PlacementDataModal from "brm/reports/rar/modals/PlacementDataModal";
import VulnerabilitiesModal from "brm/reports/rar/modals/VulnerabilitiesModal";
import AutoMitigateButton from "components/elements/AutoMitigateButton";
import PlacementDataButton from "components/elements/PlacementDataButton";
import { getErrorMessage } from "utils/error-message-utils";
// TODO ugh... should use proper barrel import will fix in 5.4+
import { AddAllocatedControlForm } from "../../../features/mitigation/components/forms/AddAllocatedControlForm";
import S from "./RarReport.styles";
import { useRarReport } from "./useRarReport";
import { RarReportView } from "./RarReportView";
import { RarDiagramView } from "./RarDiagramView";
import { ManualMitigationModal } from "./modals/ManualMigitationModal";

const allOption = { value: COMMON.nullUuid, label: "ALL" };

export interface IRiskElement {
  id: string;
  name: string;
  rank: {
    value: string;
  };
  score: {
    calcValue: number;
  };
}

export interface IContributor {
  id: string;
  name: string;
  kind: string;
}

export interface IVulnerability {
  id: string;
  name: string;
  category: string;
}

export const RarReport = ({ riskElement }: { riskElement: IRiskElement }) => {
  const [currentProject] = useProject();
  const { currentVariant } = useVariant();

  const {
    rarReportData,
    rarDataError,
    isFetchingRarData,
    isRarReportDataError,
    isAllocatedControlModalOpen,
    setIsAllocatedControlModalOpen,
    dataModalIsOpen,
    setDataModalIsOpen,
    exportModalIsOpen,
    setExportModalIsOpen,
    vulnerabilityModalIsOpen,
    setVulnerabilityModalIsOpen,
    selectedContributor,
    contributorsList,
    contributorsIdList,
    handleNodeSelectChange,
    contribsDisplayStructure,
    highlighted,
    vulnerabilities,
    showAdditionalContribs,
    handleHightlightNodes,
    sctmNameData,
    importSctm,
  } = useRarReport({ riskElement });

  const [isManualMitigationModalVisible, setIsManualMitigationModalVisible] = React.useState(false);

  if (riskElement?.rank?.value === null) {
    return (
      <S.CenterContainer>
        Calculate Risks has not been run. To view RAR please perform Calculate Risks.
      </S.CenterContainer>
    );
  }

  if (isRarReportDataError) {
    return <ErrorBanner msg={`Error while generating RAR Report: ${getErrorMessage(rarDataError)}`} />;
  }

  if (currentProject && rarReportData) {
    const isInitialVariant = currentVariant === null || currentVariant.id === COMMON.nullUuid;
    const hasVulnerabilities = vulnerabilities.length !== 0;

    return (
      <S.ReportContainer data-testid="RarReport">
        {dataModalIsOpen && (
          <PlacementDataModal
            modalIsOpen={dataModalIsOpen}
            setModalIsOpen={setDataModalIsOpen}
            data={rarReportData?.placement}
          />
        )}
        {exportModalIsOpen &&
          systemModals.customModal(
            "RarReport_modal",
            exportModalIsOpen,
            setExportModalIsOpen,
            <ExportWizard
              filePrefix={currentProject.name}
              setExportModalIsOpen={setExportModalIsOpen}
              projectId={currentProject.id}
              exportContext={ExportContext.rar}
              focus={selectedContributor?.value}
              // riskId={selectedElement?.id}
              riskRank={+riskElement.rank.value}
            />,
            "RAR Exporter"
          )}

        {vulnerabilityModalIsOpen && (
          <VulnerabilitiesModal
            modalIsOpen={vulnerabilityModalIsOpen}
            setModalIsOpen={setVulnerabilityModalIsOpen}
            vulnerabilities={vulnerabilities}
            riskId={riskElement?.id}
            projectId={currentProject?.id}
            variantId={currentVariant?.id || ""}
          />
        )}

        {isAllocatedControlModalOpen &&
          systemModals.addModal(
            "AllocatedControls_page",
            isAllocatedControlModalOpen,
            setIsAllocatedControlModalOpen,
            <AddAllocatedControlForm
              setModalIsOpen={setIsAllocatedControlModalOpen}
              sctmName={sctmNameData?.result}
              importSctm={importSctm}
              nodes={rarReportData.contrib.filter((c: IContributor) => c.kind === "node")}
              exchanges={rarReportData.contrib.filter((c: IContributor) => c.kind === "exchange")}
              vulnerabilityCategories={[...new Set(rarReportData.vulnerability.map((c: IVulnerability) => c.category))]}
            />,
            TYPE.allocatedControl
          )}

        <VariantSelectionModal
          isVisible={isManualMitigationModalVisible && isInitialVariant}
          onCancel={() => setIsManualMitigationModalVisible(false)}
          onClosing={() => setIsManualMitigationModalVisible(false)}
        />
        <ManualMitigationModal
          isVisible={isManualMitigationModalVisible && !isInitialVariant && hasVulnerabilities}
          onClosing={() => setIsManualMitigationModalVisible(false)}
          vulnerabilities={rarReportData.vulnerability}
          importSctm={importSctm}
        />

        <S.ReportToolbar>
          <S.ReportNodeSelection>
            <S.ReportElementTitle>Select node/exchange contributor: </S.ReportElementTitle>
            <S.Dropdown>
              <S.NodeSelect
                id="RarReport_nodeDropdown"
                classNamePrefix="react-select"
                isMulti={false}
                options={[allOption, ...contributorsList]}
                value={selectedContributor}
                onChange={handleNodeSelectChange}
                placeholder="Select Contributor..."
              />
            </S.Dropdown>
          </S.ReportNodeSelection>

          {rarReportData && (
            <div>
              <ShieldButton
                tooltip="User-Guided Mitigation"
                onClick={() => setIsManualMitigationModalVisible(true)}
                disabled={!hasVulnerabilities}
              />
              {/* <AddButton
              onClick={() => setIsAllocatedControlModalOpen(true)}
              disabled={currentVariant === null || currentVariant.id === COMMON.nullUuid}
            /> */}
              <AutoMitigateButton
                onClick={() => setVulnerabilityModalIsOpen(true)}
                selectedRows={rarReportData.vulnerability}
                disabled={!hasVulnerabilities}
              />
              <PlacementDataButton onClick={() => setDataModalIsOpen(true)} disabled={vulnerabilities.length === 0} />
              <DownloadButton onClick={() => setExportModalIsOpen(true)} />
            </div>
          )}
        </S.ReportToolbar>
        {rarReportData && (
          <S.RarPanel>
            <RarReportView
              isFetching={isFetchingRarData}
              rarReportData={rarReportData}
              contribsDisplayStructure={contribsDisplayStructure}
              selectedContributor={selectedContributor}
              showAdditionalContribs={showAdditionalContribs}
            />
            <div style={{ flex: 1, minHeight: "200px", minWidth: "200px" }}>
              <RarDiagramView
                rarReportData={rarReportData}
                contributorsIdList={contributorsIdList}
                handleHightlightNodes={handleHightlightNodes}
                highlighted={highlighted}
                riskElement={riskElement}
                selectedContributor={selectedContributor}
              />
            </div>
          </S.RarPanel>
        )}

        {selectedContributor?.value && !rarReportData ? <Loading /> : null}
      </S.ReportContainer>
    );
  }
  return <CenteredSpinner text="Generating RAR Report..." />;
};
