import styled from "styled-components/macro";

export const RootContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const TableContainer = styled.div`
  /* height: 540px; */
  overflow-y: auto;
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const TR = styled.tbody``;

export const IconCell = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const FilterStateContainer = styled.div``;
