import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import getReactSelectArray from "utils/react-select-array";
import { FORM_LABEL } from "constants/brm";
import SelectAll from "components/forms/SelectAllReactSelect";
import { SystemApi } from "features/brm";
import { ErrorBanner } from "components/elements";
import { queryClient } from "libs/react-query";
import toast from "react-hot-toast";
import localforage from "localforage";
import getUniqueArray from "./helpers/GetUniqueArray";
// import customDetailStyle from "./styles/BrmReactSelectDetailsStyles";

const AddNodeForm = ({ setModalIsOpen, elementNameInUrl, selectedElement }) => {
  const [postError, setPostError] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const projectId = useRecoilValue(projectIdState);

  const { data: elementNodesData, isError: isElementNodesDataError } = SystemApi.useElementNodes({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    options: {
      enabled: !!selectedElement.id,
    },
  });

  const { data: nodesData, isError: isNodesDataError } = SystemApi.useNodes({
    projectId,
    options: {
      enabled: !!projectId,
    },
  });

  const { mutateAsync: addNode } = SystemApi.useAddElementNodes();

  useEffect(() => {
    async function getData() {
      const uniqueArray = getUniqueArray(nodesData, elementNodesData);
      setOptions(getReactSelectArray(uniqueArray));
    }
    if (projectId && elementNodesData && nodesData) {
      getData();
    }
  }, [elementNodesData, nodesData, projectId]);

  const handleSelectChange = (items = []) => {
    setSelectedOptions(items);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (selectedOptions.length > 0) {
      try {
        await Promise.all(
          selectedOptions.map((each) =>
            addNode({
              elementId: selectedElement.id,
              elementName: elementNameInUrl,
              nodeId: each.value,
            })
          )
        );
        setModalIsOpen(false);
        queryClient.invalidateQueries(SystemApi.nodeKeys.all);
        localforage.removeItem(`sd_layout_${projectId}`);
      } catch (err) {
        toast.error(<p> Error occurred on put request </p>, {});
      }
    } else {
      setPostError("*Please fill out all fields in the form prior to submitting.");
    }
  }

  if (isNodesDataError || isElementNodesDataError) {
    return <ErrorBanner msg="Error while loading node data" />;
  }

  return (
    <div id="AddNodeForm">
      <form onSubmit={handleSubmit} action="" id="AddNodeForm_form">
        <FormStyled>
          <div className="form-style" id="AddNodeForm_formContent">
            <label id="AddNodeForm_label">{FORM_LABEL.nodes}</label>
            <SelectAll
              // styles={customDetailStyle}
              isMulti
              options={options}
              defaultValue={selectedOptions}
              value={selectedOptions}
              onChange={handleSelectChange}
              allowSelectAll
              allOption={{
                label: "Select all",
                value: "*",
              }}
              elementId="AddNodeForm_node"
            />
          </div>
        </FormStyled>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="AddNodeForm_cancelButton">
            Cancel
          </DialogButtonStyled>
          <DialogButtonStyled type="submit" id="AddNodeForm_addButton">
            Add
          </DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};

AddNodeForm.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setModalIsOpen: PropTypes.func,
};

export default AddNodeForm;
