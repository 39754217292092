import * as React from "react";
import * as Recoil from "recoil";
// Constants
import { COMMON } from "constants/brm";
// State
import { projectIdState, isReadOnlyState } from "atoms/atoms-admin";
import { riskUpdatedState } from "atoms/atoms-risk";
import { sctmIdState } from "atoms/atoms-mitigation";
// Hooks
import { useModal } from "hooks";
// Styles
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
// Utils
import { currentVariantState, MitigationApi, ExportButton } from "features/brm";
// Components
import AutoMitigateButton from "components/elements/AutoMitigateButton/AutoMitigateButton";
import { AddVariantForm } from "features/mitigation/";
import systemModals from "components/modals/Modals";
import VulnerabilityVariantSelect from "brm/risk-model/vulnerability/utils/VulnerabilityVariantSelect";
import { usePerformAutoMitigate } from "features/risk";
import { ExportTableModal, useExportTable } from "features/exporter";

import ContentLayout from "brm/layout/Content/ContentLayout";
// Local
import VulnerabilityTable from "./tables/VulnerabilityTable";

function Vulnerability() {
  const isReadOnly = Recoil.useRecoilValue(isReadOnlyState);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [variantSelectModalIsOpen, setVariantSelectModalIsOpen] = React.useState(false);
  const setRiskUpdated = Recoil.useSetRecoilState(riskUpdatedState);
  const [currentVariant] = Recoil.useRecoilState(currentVariantState);
  const projectId = Recoil.useRecoilValue(projectIdState);
  const { data: variantsData } = MitigationApi.useVariantsWithScores({ projectId });
  const setSctmId = Recoil.useSetRecoilState(sctmIdState);
  const [newVariant, setNewVariant] = React.useState();
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  const autoMitigate = usePerformAutoMitigate({
    onSuccess: () => {
      setModalIsOpen(false);
      setRiskUpdated(true);
    },
  });

  const checkForVariants = () => {
    if (variantsData.length > 0) {
      setVariantSelectModalIsOpen(true);
    } else {
      setModalIsOpen(true);
    }
  };

  const performAutoMitigate = async (varState) => {
    if (varState && varState.id !== COMMON.nullUuid) {
      autoMitigate(
        varState,
        selectedRows.map((v) => v.id)
      );
    } else {
      checkForVariants();
    }
  };

  const { data: sctm } = MitigationApi.useVariantSctm({
    variantId: currentVariant?.id,
    options: {
      enabled: currentVariant !== null && currentVariant?.id !== COMMON.nullUuid,
    },
  });

  React.useEffect(() => {
    if (sctm) {
      setSctmId(sctm.id);
    }
  }, [sctm, setSctmId]);

  const vulnerabilityHeader = (
    <Title id="Vulnerability_title">
      Vulnerability Conditions
      <Buttons id="Vulnerability_buttons">
        <AutoMitigateButton
          onClick={() => setVariantSelectModalIsOpen(true)}
          selectedRows={selectedRows}
          disabled={isReadOnly || selectedRows.length === 0}
        />
        <ExportButton onClick={() => toggle()} disableExport={disableExport} />
      </Buttons>
    </Title>
  );

  const vulnerabilityMain = (
    <>
      {modalIsOpen &&
        systemModals.addModal(
          "VulnerabilityTable_detailsPanel",
          modalIsOpen,
          setModalIsOpen,
          <AddVariantForm setModalIsOpen={setModalIsOpen} onNewVariant={setNewVariant} />,
          "Variant"
        )}

      {variantSelectModalIsOpen &&
        systemModals.customModal(
          "VulnerabilityTable_detailsPanel",
          variantSelectModalIsOpen,
          setVariantSelectModalIsOpen,
          <VulnerabilityVariantSelect
            setModalIsOpen={setModalIsOpen}
            setVariantSelectModalIsOpen={setVariantSelectModalIsOpen}
            onAutoMitigate={performAutoMitigate}
            newVariant={newVariant}
          />,
          "Select Variant"
        )}

      <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
      <VulnerabilityTable setSelectedRows={setSelectedRows} setExportTableData={setExportTableData} />
    </>
  );

  return <ContentLayout pageId="Vulnerability_page" header={vulnerabilityHeader} main={vulnerabilityMain} />;
}

export default Vulnerability;
