// Services
import { RoutePath } from "routes/route-paths";
import {
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
  ROLE_RISK_ANALYST,
  ROLE_THREAT_ANALYST,
  ROLE_SYSTEM_ENGINEER,
  ROLE_CYBERSECURITY_EXPERT,
  ROLE_CONTROLS_COORDINATOR,
} from "services/user";
import { CONTEXT_SIDEBAR_CONTENT } from "features/brm";
import { IVideoListContent } from "features/help";

interface IUserTasks {
  [role: string]: {
    heading: string;
    tasks?: {
      text: string;
      path: string;
    }[];
    contentsData?: IVideoListContent[] | null;
  }[];
}

interface IUserDescription {
  [x: string]: {
    heading?: string;
    list: {
      id?: number;
      text: string;
      path?: string;
    }[];
  }[];
}

interface IRoleSubheading {
  [role: string]: string;
}

export const userTasks: IUserTasks = {
  [ROLE_SUPER_ADMIN]: [
    {
      heading: "Managing the BRM Installation",

      contentsData: [
        {
          name: "Review Details of the Installation",
          filePath: "/help-assets/SA/ReviewTheDetailsOfTheInstallation/ReviewTheDetailsOfTheInstallation_player.html",
        },
      ],
    },
    {
      heading: "Managing Licences",

      contentsData: [
        {
          name: "Apply a BRM License",
          filePath: "/help-assets/SA/applyABrmLicenseFile/applyABrmLicenseFile_player.html",
        },
        {
          name: "View License Status",
          filePath: "/help-assets/SA/viewLicenseStatus/viewLicenseStatus_player.html",
        },
      ],
    },
    {
      heading: "Managing Organizations",

      contentsData: [
        {
          name: "Create an organization",
          filePath: "/help-assets/SA/CreateAnOrganization/CreateAnOrganization_player.html",
        },
        {
          name: "Review Organization Details",
          filePath: "/help-assets/SA/ViewOrganizationDetails/ViewOrganizationDetails_player.html",
        },
      ],
    },
    {
      heading: "Managing Users",

      contentsData: [
        {
          name: "Create user (including Super Admin)",
          filePath: "/help-assets/SA/CreateAUser_SA/CreateAUser_SA_player.html",
        },
        {
          name: "Delete user (including Super Admin)",
          filePath: "/help-assets/SA/DeleteAUser/DeleteAUser_player.html",
        },
        {
          name: "Assign Admin to an Organization",
          filePath: "/help-assets/SA/AssignAnOrganizationAnAdminUser/AssignAnOrganizationAnAdminUser_player.html",
        },
      ],
    },

    {
      heading: "Your Profile & Settings",

      contentsData: [
        {
          name: "Logging Out",
          filePath: "/help-assets/SA/loggingOutOfBrm/loggingOutOfBrm_player.html",
        },
        {
          name: "Changing My Password",
          filePath: "/help-assets/SA/changePassword/changePassword_player.html",
        },
      ],
    },
  ],

  [ROLE_ADMIN]: [
    {
      heading: "Managing your Organization's Users",

      contentsData: [
        {
          name: "Create a user",
          filePath: "/help-assets/Admin/CreateAUser/CreateAUser_player.html",
        },
        {
          name: "Delete a user",
          filePath: "/help-assets/Admin/DeleteAUser_A/DeleteAUser_A_player.html",
        },
      ],
    },
    {
      heading: "Managing your Organization's Projects",

      contentsData: [
        {
          name: "Create and Configure a Project",
          filePath: "/help-assets/Admin/CreateAProject/CreateAProject_player.html",
        },
        {
          name: "Edit Projects",
          filePath: "/help-assets/Admin/EditAProject/EditAProject_player.html",
        },
        {
          name: "Delete Projects",
          filePath: "/help-assets/Admin/DeleteAProject/DeleteAProject_player.html",
        },
        {
          name: "Assign Users to Projects",
          filePath: "/help-assets/Admin/AddAUser/AddAUser_player.html",
        },
      ],
    },
    {
      heading: "Managing your Organization",

      contentsData: [
        {
          name: "View Organization details",
          filePath: "/help-assets/Admin/ViewOrganizations/ViewOrganizations_player.html",
        },
      ],
    },
    {
      heading: "Administration of Roles",

      contentsData: [
        {
          name: "Assign and Remove User Roles",
          filePath:
            "/help-assets/Admin/AssignAndRemoveRolesToAnExistingUser/AssignAndRemoveRolesToAnExistingUser_player.html",
        },
      ],
    },
    {
      heading: "Your Profile & Settings",

      contentsData: [
        {
          name: "Logging Out",
          filePath: "/help-assets/Admin/loggingOutOfBrm/loggingOutOfBrm_player.html",
        },
        {
          name: "Changing My Password",
          filePath: "/help-assets/Admin/changePassword/changePassword_player.html",
        },
      ],
    },
  ],

  [ROLE_CONTROLS_COORDINATOR]: [
    {
      heading: "Managing Control Catalogs",
      contentsData: [
        {
          name: "Review Control Catalogs",
          filePath: "/help-assets/CC/ReviewControlCatalogs/ReviewControlCatalogs_player.html",
        },
        {
          name: "Add a Custom Control Catalog",
          filePath: "/help-assets/CC/AddACustomControlCatalog/AddACustomControlCatalog_player.html",
        },
        {
          name: "Upload a Control Catalog to a Custom Control Catalog",
          filePath: "/help-assets/CC/UploadToACustomControlCatalog/UploadToACustomControlCatalog_player.html",
        },
      ],
    },
    {
      heading: "Managing Control Types",

      contentsData: [
        {
          name: "View Control Type Details",
          filePath: "/help-assets/CC/ViewControlTypeDetails/ViewControlTypeDetails_player.html",
        },
        {
          name: "Set Control Type Strength",
          filePath: "/help-assets/CC/SetControlTypeStrength/SetControlTypeStrength_player.html",
        },
        {
          name: "Add Control Types",
          filePath: "/help-assets/CC/AddAControlType/AddAControlType_player.html",
        },
      ],
    },
    {
      heading: "Managing Control Families",
      contentsData: [
        {
          name: "Add a Control Family",
          filePath: "/help-assets/CC/AddAControlFamily/AddAControlFamily_player.html",
        },
        {
          name: "Delete a Control Family",
          filePath: "/help-assets/CC/DeleteControlFamily/DeleteControlFamily_player.html",
        },
      ],
    },
    {
      heading: "Working with Baselines",
      contentsData: [
        {
          name: "Import controls into a standard baseline",
          filePath: "/help-assets/CC/ImportControlsIntoAStandardBaseline/ImportControlsIntoAStandardBaseline.html",
        },
        {
          name: "Set Project Baselines",
          filePath: "/help-assets/CC/SetBaselineForProject/SetBaselineForProject_player.html",
        },
      ],
    },
    {
      heading: "Your Profile & Settings",

      contentsData: [
        {
          name: "Logging Out",
          filePath: "/help-assets/CC/loggingOutOfBrm/loggingOutOfBrm_player.html",
        },
        {
          name: "Changing My Password",
          filePath: "/help-assets/CC/changePassword/changePassword_player.html",
        },
      ],
    },
  ],

  [ROLE_CYBERSECURITY_EXPERT]: [
    {
      heading: "Working with Knowledgebases",
      contentsData: [
        {
          name: "Clone the Knowledgebase",
          filePath: "/help-assets/CSE/CloneAKnowledgebase/CloneAKnowledgebase_player.html",
        },
        {
          name: "Customize the Knowledgebase",
          filePath: "/help-assets/CSE/CustomizeAKnowledgebase/CustomizeAKnowledgebase_player.html",
        },
        {
          name: "Edit properties of the Knowledgebase",
          filePath: "/help-assets/CSE/EditAKnowledgebase/EditAKnowledgebase_player.html",
        },
      ],
    },
    {
      heading: "Configuring Projects",
      contentsData: [
        {
          name: "Set Vulnerability Computations",
          filePath: "/help-assets/CSE/SetVulnerabilityComputations/SetVulnerabilityComputations_player.html",
        },
        {
          name: "Assign a Knowledgebase to a Project",
          filePath: "/help-assets/CSE/AssignAKnowledgebaseToAProject/AssignAKnowledgebaseToAProject_player.html",
          helperText: "Recommend assigning a knowledgebase after project creation coordinate with project Admin",
        },
      ],
    },

    {
      heading: "Your Profile & Settings",

      contentsData: [
        {
          name: "Logging Out",
          filePath: "/help-assets/CSE/loggingOutOfBrm/loggingOutOfBrm_player.html",
        },
        {
          name: "Changing My Password",
          filePath: "/help-assets/CSE/changePassword/changePassword_player.html",
        },
      ],
    },
  ],

  [ROLE_THREAT_ANALYST]: [
    {
      heading: "Managing the Threat Model",
      contentsData: [
        {
          name: "Add Attackers",
          filePath: "/help-assets/TA/AddAttacker/AddAttacker_player.html",
        },
        {
          name: "Delete Attackers",
          filePath: "/help-assets/TA/DeleteAnAttacker/DeleteAnAttacker_player.html",
        },
        {
          name: "Edit an Attacker",
          filePath: "/help-assets/TA/EditAttackerFromList/EditAttackerFromList_player.html",
        },
      ],
    },
    {
      heading: "Managing Threat Catalogs",
      contentsData: [
        {
          name: "Add a Threat Catalog",
          filePath: "/help-assets/TA/AddAThreatCatalog/AddAThreatCatalog_player.html",
        },

        {
          name: "Import a Threat Catalog",
          filePath: "/help-assets/TA/ImportIntoAThreatCatalog/ImportIntoAThreatCatalog_player.html",
        },
        {
          name: "Export a Threat Catalog",
          filePath: "/help-assets/TA/ExportThreatCatalog/ExportThreatCatalog_player.html",
        },
      ],
    },

    {
      heading: "Managing Threat Groups",
      contentsData: [
        {
          name: "Add a Threat Group",
          filePath: "/help-assets/TA/AddAThreatGroup/AddAThreatGroup_player.html",
        },
        {
          name: "Delete a Threat Group",
          filePath: "/help-assets/TA/deleteAThreatGroup/deleteAThreatGroup_player.html",
        },
        {
          name: "Clone a Threat Group",
          filePath: "/help-assets/TA/cloneAThreatGroup/cloneAThreatGroup_player.html",
        },
        {
          name: "Edit a Threat Group",
          filePath: "/help-assets/TA/EditThreatGroupFromList/EditThreatGroupFromList_player.html",
        },
      ],
    },
    {
      heading: "Your Profile & Settings",

      contentsData: [
        {
          name: "Logging Out",
          filePath: "/help-assets/TA/loggingOutOfBrm/loggingOutOfBrm_player.html",
        },
        {
          name: "Changing My Password",
          filePath: "/help-assets/TA/changePassword/changePassword_player.html",
        },
      ],
    },
  ],

  [ROLE_SYSTEM_ENGINEER]: [
    {
      heading: "Importing System Model",
      contentsData: [
        {
          name: "Map Icons to System model nodes prior to import",
          filePath: "/help-assets/SE/MapIconsNodesInitialImportSD/MapIconsNodesInitialImportSD_player.html",
        },
        {
          name: "Import System Model into a Project",
          filePath: "/help-assets/SE/ImportProjectSystemModel/ImportProjectSystemModel_player.html",
        },
        {
          name: "Review System Facts and Model Analysis Reports",
          filePath: "/help-assets/SE/ReviewSystemModelAnalysisReport/ReviewSystemModelAnalysisReport_player.html",
        },
        {
          name: "Configuring UAF and SySML models",
          filePath: "/help-assets/SE/configuringUafAndSysmlModel/configuringUafAndSysmlModel_player.html",
        },
      ],
    },
    {
      heading: "Working with the System Diagram",
      contentsData: [
        {
          name: "View System Diagram",
          filePath: "/help-assets/SE/ViewAProjectsSystemDiagram/ViewAProjectsSystemDiagram_player.html",
        },
        {
          name: "View Node Details",
          filePath: "/help-assets/SE/ViewNodeDetailsInSD/ViewNodeDetailsInSD_player.html",
        },
        {
          name: "View Node and Exchange Details",
          filePath: "/help-assets/SE/ViewNodeDetailsInSD/ViewNodeDetailsInSD_player.html",
        },
        {
          name: "View Dataflows",
          filePath: "/help-assets/SE/ViewDataflows/ViewDataflows_player.html",
        },
        {
          name: "View Datatypes",
          filePath: "/help-assets/SE/ViewDatatypes/ViewDatatypes_player.html",
        },
      ],
    },
    {
      heading: "Enhancing/Creating the System Diagram",
      contentsData: [
        {
          name: "Create New Nodes",
          filePath: "/help-assets/SE/CreateANewNode/CreateANewNode_player.html",
        },
        {
          name: "Add Exchanges between Nodes",
          filePath: "/help-assets/SE/AddExchangebetweennodesv2/AddExchangebetweennodesv2_player.html",
        },
        {
          name: "Edit Data Type Classification",
          filePath:
            "/help-assets/SE/UpdateDatatypeClassificationFromSystemDiagram/UpdateDatatypeClassificationFromSystemDiagram_player.html",
        },
        {
          name: "Add Dataflows",
          filePath: "/help-assets/SE/AddDataflowFromSystemDiagram/AddDataflowFromSystemDiagram_player.html",
        },
        {
          name: "Publish updated System Diagram",
          filePath: "/help-assets/SE/PublishUpdatedSystemModelDiagram/publishupdatedsystemmodeldiagram_player.html",
        },
      ],
    },
    {
      heading: "Managing the Context Diagram",
      contentsData: [
        {
          name: "Add Image to Context Diagram",
          filePath: "/help-assets/SE/AddImageToContextDiagram/addImageToContextDiagram_player.html",
        },
        {
          name: "Filter Context Diagram",
          filePath: "/help-assets/SE/filteringContextDiagram/filteringContextDiagram_player.html",
        },
        {
          name: "Highlight Entry Points Related to External Access Points",
          filePath:
            "/help-assets/SE/highlightEntryPointsRelatedToExternalAccessPoints/highlightEntryPointsRelatedToExternalAccessPoints_player.html",
        },
        {
          name: "Add Attack Surface to Context Diagram",
          filePath: "/help-assets/SE/AddAttackSurfaceToContextDiagram/AddAttackSurfaceToContextDiagram_player.html",
        },
      ],
    },
    {
      heading: " Managing System Model via UI",
      contentsData: [
        {
          name: "Adding Capabilities",
          filePath: "/help-assets/SE/AddACapabilityToProjectSystemModel/AddACapabilityToProjectSystemModel_player.html",
        },
        {
          name: "Adding Activities",
          filePath: "/help-assets/SE/AddAnActivityToProjectSystemModel/AddAnActivityToProjectSystemModel_player.html",
        },
      ],
    },
    {
      heading: "Your Profile & Settings",

      contentsData: [
        {
          name: "Logging Out",
          filePath: "/help-assets/SE/loggingOutOfBrm/loggingOutOfBrm_player.html",
        },
        {
          name: "Changing My Password",
          filePath: "/help-assets/SE/changePassword/changePassword_player.html",
        },
      ],
    },
  ],

  [ROLE_RISK_ANALYST]: [
    {
      heading: "Assessing Raw Risks",
      contentsData: [
        {
          name: "Run Risk Calculations",
          filePath: "/help-assets/RA/RunRiskAnalysis/RunRiskAnalysis_player.html",
        },
        {
          name: "Review Prioritized Risk Details",
          filePath: "/help-assets/RA/ReviewPrioritizedRiskDetails/ReviewPrioritizedRiskDetails_player.html",
        },
        {
          name: "View the Attack Diagram",
          filePath: "/help-assets/RA/ViewTheAttackDiagram/ViewTheAttackDiagram_player.html",
        },
      ],
    },
    {
      heading: "Calibrating Impacts/Likelihoods",
      contentsData: [
        {
          name: "Calibrate Impacts",
          filePath: "/help-assets/RA/CalibrateImpact/CalibrateImpact_player.html",
        },
        {
          name: "Calibrate Likelihood",
          filePath: "/help-assets/RA/CalibrateLikelihood/CalibrateLikelihood_player.html",
        },
      ],
    },
    {
      heading: "Managing Variants and Controls",
      contentsData: [
        {
          name: "Create Variants",
          filePath: "/help-assets/RA/CreateAVariant/CreateAVariant_player.html",
        },
        {
          name: "Allocate Controls to a Variant",
          filePath: "/help-assets/RA/AllocateControlsToAVariant/AllocateControlsToAVariant_player.html",
        },
        {
          name: "Auto-mitigate Vulnerability Conditions",
          filePath: "/help-assets/RA/AutoMitigateASetOfVulnerabilities/AutoMitigateASetOfVulnerabilities_player.html",
        },
        {
          name: "Import an SCTM workbook",
          filePath: "/help-assets/RA/ImportAnSCTMWorkbook/ImportAnSCTMWorkbook_player.html",
        },
        {
          name: "Export an SCTM workbook",
          filePath: "/help-assets/RA/ExportAnSctmWorkbook/ExportAnSctmWorkbook_player.html",
        },
      ],
    },

    {
      heading: "Running Mitigated, and Compliance Risk",
      contentsData: [
        {
          name: "Run Mitigated Risk",
          filePath: "/help-assets/RA/runMitigatedRisk/runMitigatedRisk_player.html",
        },
        {
          name: "Run Compliance Risk",
          filePath: "/help-assets/RA/runComplianceRisk/runComplianceRisk_player.html",
        },
      ],
    },

    {
      heading: "Generating Risk Reports",
      contentsData: [
        {
          name: "Threat Risk Analysis",
          filePath: "/help-assets/RA/ExportThreatRiskAnalysisReport/ExportThreatRiskAnalysisReport_player.html",
        },
        {
          name: "Risk Assessment Report",
          filePath: "/help-assets/RA/RarExporter/RarExporter_player.html",
        },
        {
          name: "Variant Difference Report",
          filePath:
            "/help-assets/RA/Reports-ViewVariantDifferenceReport/Reports-ViewVariantDifferenceReport_player.html",
        },
        {
          name: "Control Guidance Report",
          filePath: "/help-assets/RA/Reports-ViewControlGuidanceReport/Reports-ViewControlGuidanceReport_player.html",
        },
        {
          name: "CSA/KPP Report",
          filePath: "/help-assets/RA/Reports-ViewCsaKppReport/Reports-ViewCsaKppReport_player.html",
        },
      ],
    },
    {
      heading: "Your Profile & Settings",

      contentsData: [
        {
          name: "Logging Out",
          filePath: "/help-assets/RA/loggingOutOfBrm/loggingOutOfBrm_player.html",
        },
        {
          name: "Changing My Password",
          filePath: "/help-assets/RA/changePassword/changePassword_player.html",
        },
      ],
    },
  ],
};

export const userDescriptions: IUserDescription = {
  [ROLE_ADMIN]: [
    {
      list: [
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.Organizations}>Managing an Organization </button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.Users}>Managing an Organization's Users  </button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.Projects}>Managing an Organization's Projects  </button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.Users}>Administration of user's profile within an organization </button></div>`,
        },
      ],
    },
  ],

  [ROLE_SUPER_ADMIN]: [
    {
      list: [
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.Installations}>Managing the Blade RiskManager installation</button></div>`, // check
        },
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.Installations}>Managing Installation License  </button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.Organizations}>Managing Organizations </button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.Projects}>Managing Projects </button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.Users} onclick="alert('Product added!');" >Managing Users  </button></div>`,
        },
      ],
    },
  ],
  [ROLE_SYSTEM_ENGINEER]: [
    {
      list: [
        {
          text: `<div class='listtext'><button class='pagelinks {placeholder}' data-type='project' data-url=${RoutePath.Project}>Collecting/Importing system models (UPDM/UAF/SysML, AAR)</button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks {placeholder}' data-url=${RoutePath.SystemDiagram}>Managing System Model Graphically</button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks {placeholder}' data-url=${RoutePath.ContextDiagram}>Viewing the Attack Surfaces Graphically</button></div>`,
        },
        {
          text: `<div class='listtext'>Managing System Diagram <button class='pagelinks {placeholder}' data-url=${RoutePath.SystemDiagram} data-context=${CONTEXT_SIDEBAR_CONTENT.systemDiagramIconMapper}> Icons</button> and <button class='pagelinks {placeholder}'  data-url=${RoutePath.SystemDiagram} data-context=${CONTEXT_SIDEBAR_CONTENT.systemDiagramPalette}>Images</button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks {placeholder}' data-url=${RoutePath.AttackSurfaces}>Managing the Attack Surfaces via User Interface</button></div>`,
        },
        {
          text: `<div class='listtext'>
           Managing System Model via User Interface
           <ul class='pl-15'>
           <li>
           <button class='pagelinks {placeholder}' data-url=${RoutePath.AccessPoints}>Access</button>
           </li>
           <li>
           <button class='pagelinks {placeholder}' data-url=${RoutePath.CategorizedEntities}>Categorization</button>
           </li>  
           <li>
           <button class='pagelinks {placeholder}' data-url=${RoutePath.SystemAssets}> System Assets</button>
           </li>
           <li>
           <button class='pagelinks {placeholder}' data-url=${RoutePath.Nodes}> System Facts </button>
           </li>
           </ul>
         </div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks {placeholder}' data-url=${RoutePath.Reports}>Viewing Reports </button></div>`,
        },
      ],
    },
  ],

  [ROLE_CONTROLS_COORDINATOR]: [
    {
      list: [
        {
          text: `<div class='listtext'>Managing Security Control <button class='pagelinks' data-url=${RoutePath.ControlCatalogs}> Catalogs</button>, <button class='pagelinks'
           data-url=${RoutePath.ControlFamilies}>families</button>, <button class='pagelinks' data-url=${RoutePath.ControlTypes} >types</button> and <button class='pagelinks' data-url=${RoutePath.StandardBaselines}>baselines</button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.ControlTypes}>Setting Security Control properties strength, cost  </button></div>`, // check
        },
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.ProjectCatalogConfig}>Configuring a project’s catalog and baseline profile  </button></div>`,
        },
      ],
    },
  ],

  [ROLE_CYBERSECURITY_EXPERT]: [
    {
      list: [
        {
          text: `<div class='listtext'><button class='pagelinks'  data-url=${RoutePath.KbProjects}>Setting Vulnerability Findings overrides for projects  </button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.KbProjects}>Managing Knowledgebase's across Blade RiskManager projects</button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks' data-url=${RoutePath.Knowledgebases}>Customizing Knowledgebase </button></div>`,
        },
      ],
    },
  ],

  [ROLE_THREAT_ANALYST]: [
    {
      list: [
        {
          text: `<div class='listtext'><button class='pagelinks {placeholder}' data-url=${RoutePath.Attackers}>Managing the Threat Model per project</button></div>`,
        },
        {
          text: `<div class='listtext'>Managing Threat <button class='pagelinks {placeholder}' data-url=${RoutePath.ThreatCatalogs}>Catalogs,</button> and <button class='pagelinks {placeholder}' data-url=${RoutePath.ThreatGroups}> Groups </button></div>`,
        },

        {
          text: `<div class='listtext'><button class='pagelinks {placeholder}' data-url=${RoutePath.ThreatGroups}>Configuring a project’s threat catalog</button></div>`,
        },
      ],
    },
  ],

  [ROLE_RISK_ANALYST]: [
    {
      list: [
        {
          text: `<div class='listtext'><button class='pagelinks {placeholder}' data-type='project' data-url=${RoutePath.Project}> Running Risk Analysis</button></div>`, // check
        },
        {
          text: `<div class='listtext'>
                  Working with Mitigation Options
                  <ul class='pl-15'>
                  <li>
                  <button class='pagelinks {placeholder}' data-url=${RoutePath.AllocatedControls} >Allocated Controls</button>
                  </li>
                  <li>
                  <button class='pagelinks {placeholder}' data-url=${RoutePath.Variants}>Variants</button>
                  </li>
                  <li>
                  <button class='pagelinks {placeholder}' data-url=${RoutePath.Sctm}>SCTM </button>
                  </li>
                  </ul>
                </div>`,
        },
        {
          text: `<div class='listtext'> Adjusting <button class='pagelinks {placeholder} {variant}' data-url=${RoutePath.ImpactCalibration}>Impacts</button> and <button class='pagelinks {placeholder} {variant}' data-url=${RoutePath.LikelihoodCalibration}> Likelihoods </button></div>`,
        },

        {
          text: `<div class='listtext'>
          Assign controls to mitigate by:
                  <ul class='pl-15'>
                  <li>
                  <button class='pagelinks {placeholder}' data-url=${RoutePath.Risks}>Risk </button>
                  </li>
                  <li>
                  <button class='pagelinks {placeholder}' data-url=${RoutePath.Vulnerabilities}>Vulnerability</button>
                  </li>
                  <li>
                  <button class='pagelinks {placeholder}' data-url=${RoutePath.Nodes}>Nodes</button>
                  </li>
                  <li>
                  <button class='pagelinks {placeholder}' data-url=${RoutePath.Exchanges}>Exchanges</button>
                  </li>
                  <li>
                  <button class='pagelinks {placeholder}' data-url=${RoutePath.Attacks}>Attacks</button>
                  </li>
                  <li>
                  <button class='pagelinks {placeholder}' data-url=${RoutePath.Attackers}>Attackers</button>
                  </li>
                  </ul>
                </div>`,
        },

        {
          text: `<div class='listtext'><button class='pagelinks {placeholder}' data-url=${RoutePath.ContextDiagram}> Viewing the Attack Surfaces Graphically  </button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks {placeholder}' data-url=${RoutePath.AttackDiagram}>Viewing the Attack Tree Graphically  </button></div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks {placeholder}' data-url=${RoutePath.SystemDiagram}> Viewing System Model Graphically  </button></div>`,
        },

        {
          text: `<div class='listtext'><div>
           Viewing System Model via User Interface
           <ul class='pl-15'>
           <li>
           <button class='pagelinks {placeholder}' data-url=${RoutePath.AccessPoints}>Access</button>
           </li>
           <li>
           <button class='pagelinks {placeholder}' data-url=${RoutePath.CategorizedEntities}>Categorization</button>
           </li>  
           <li>
           <button class='pagelinks {placeholder}' data-url=${RoutePath.SystemAssets}> System Assets</button>
           </li>
           <li>
           <button class='pagelinks {placeholder}' data-url=${RoutePath.Nodes}> System Facts </button>
           </li>
           </ul>
         </div>`,
        },
        {
          text: `<div class='listtext'><button class='pagelinks {placeholder}' data-url=${RoutePath.Reports}>Generating Risk Reports</button></div>`,
        },
      ],
    },
  ],
};

export const roleSubHeading: IRoleSubheading = {
  [ROLE_ADMIN]: "Organizational Management of Projects and Users",
  [ROLE_SUPER_ADMIN]: "Global Management of the Installation, Organizations, Projects and Users",
  [ROLE_RISK_ANALYST]: "Managing the Project's Risk Model",
  [ROLE_THREAT_ANALYST]: "Global Management of Threat Catalogs and Assignment to Projects",
  [ROLE_SYSTEM_ENGINEER]: "Managing the Project's System Model",
  [ROLE_CYBERSECURITY_EXPERT]: "Global Management and Assignment of the Knowledgebase",
  [ROLE_CONTROLS_COORDINATOR]: "Global Management of Security Control Catalogs and Baselines",
};
