import * as BrmGql from "generated/graphql";

export const nodeKeys = {
  all: ["node"] as const,
  node: (nodeId: string | null) => [...nodeKeys.all, nodeId] as const,
  project: (projectId: string | null) => [...nodeKeys.all, projectId] as const,
  controls: (projectId: string, variantId: string) => [...nodeKeys.project(projectId), variantId, "controls"] as const,
  risk: (projectId: string | null, variantId: string | null) =>
    [...nodeKeys.project(projectId), variantId, "risks"] as const,
  riskWithControl: (projectId: string | null, variantId: string | null, data: any) =>
    [...nodeKeys.project(projectId), variantId, "riskWithControl", data] as const,
  vulnerability: (nodeId: string, variantId: string) => [...nodeKeys.all, nodeId, variantId, "vulnerability"] as const,
  parent: (childId: string | null) => [...nodeKeys.all, childId, "parent"] as const,
  parents: (projectId: string | null) => [...nodeKeys.project(projectId), "parents"] as const,
  persons: (nodeId: string | null) => [...nodeKeys.all, nodeId, "persons"] as const,
  resources: (nodeId: string | null) => [...nodeKeys.all, nodeId, "resources"] as const,
  children: (nodeId: string | null) => [...nodeKeys.all, nodeId, "children"] as const,
  element: (
    elementId: string | null,
    elementName: string | null,
    dataTypeNodesData: BrmGql.GetDataTypeNodesQuery | undefined,
    dataFlowNodesData: BrmGql.GetDataflowNodesQuery | undefined,
    missionNodesData: BrmGql.GetMissionNodesQuery | undefined
  ) =>
    [
      ...nodeKeys.all,
      elementId,
      elementName,
      dataTypeNodesData,
      dataFlowNodesData,
      missionNodesData,
      "elementNodes",
    ] as const,
};

export const nodeKeysGraphQl = {
  getNodeParents: ["getNodeParents"] as const,
  systemDiagramData: ["SystemDiagramData"] as const,
  getAllNodes: ["getAllNodes"] as const,
};
