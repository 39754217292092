import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

interface IGetSystemAssetsTypes {
  projectId: string;
  saCat: string | null;
}

export const getSystemAssetsTypes = ({ projectId, saCat }: IGetSystemAssetsTypes) => {
  const opts = {
    category: saCat,
  };
  return Brm.systemAssetTypeApi.findSystemAssetType(projectId, opts);
};

interface IUseSystemAssetsTypes {
  projectId: string;
  saCat: string | null;
  options?: QueryOption<typeof getSystemAssetsTypes>;
}

/**
 * React-query hook for getting all System Assets
 *
 * @param {string} projectId - uuid of the project
 * @returns react-query for getSystemAssets
 */
export const useSystemAssetsTypes = ({ projectId, saCat = null, options }: IUseSystemAssetsTypes) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.systemAssetTypeKeys.project(projectId),
    queryFn: () => getSystemAssetsTypes({ projectId, saCat }),
  });
};
