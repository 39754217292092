import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";
import * as KeyFactory from "./variant-query-key-factory";

interface ISetVariantBaselineProps {
  variantId: string;
  baselineId: string;
}

/**
 * Sets the baseline of a variant
 *
 * @param {string} variantId - uuid of the variant
 * @param {string} baselineId - new baseline
 */
export const setVariantBaseline = ({ variantId, baselineId }: ISetVariantBaselineProps) => {
  return Brm.variantApi.setVariantBaselineWithHttpInfo(variantId, {
    body: baselineId,
  });
};

interface IUpdateVariantBaselineProps {
  options?: MutationOption<typeof setVariantBaseline>;
}

/**
 * Custom hook to update the baseline of an existing variant
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useUpdateVariantBaseline = ({ options }: IUpdateVariantBaselineProps = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setVariantBaseline,
    ...options,
    onSuccess: () => queryClient.invalidateQueries(KeyFactory.variantsKeys.all),
  });
};
