import * as React from "react";
import { useIsFirstRender } from "../useIsFirstRender";
/**
 * Identical to React.useEffect, except that it never runs on mount. This is
 * the equivalent of the componentDidUpdate lifecycle function.
 *
 * @param {function:function} effect - A useEffect effect.
 * @param {array} [dependencies] - useEffect dependency list.
 */
export const useUpdatesOnlyEffect = (effect: React.EffectCallback, dependencies: React.DependencyList | undefined) => {
  const isFirstRender = useIsFirstRender();

  React.useEffect(() => {
    if (!isFirstRender) {
      return effect();
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
