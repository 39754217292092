import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { userApi, organizationApi } from "services/brm";
import { isAdmin } from "services/user";
import { isNotDeletedStatus } from "utils/filter-utils";
import { projectKeys } from "../../api/admin";

const getAdminProjects = async (userId, userRole) => {
  const adminProjects = [];

  /* COMMENTING OUT THE BELOW CODE. CURRENTLY NOT CLEAR ON HOW TO GET 
    PROJECTS BELONGING TO ATTACHED ORGANIZATION */

  //   // Get organizations attached to this user
  //   const { data: userOrgs } = await userApi.getUserAttachedOrganization(userId);

  //   if (userOrgs?.length > 0) {
  //     // Get projects of each organization
  //     const userOrgProjects = await Promise.all(userOrgs.map((x) => organizationApi.getOrganizationProject(x.id))).flat();
  //     adminProjects.push(userOrgProjects);
  //   }

  // We double check to make sure that the user is admin because of a race
  // condition somewhere when switching roles, a bunch of queries are invalidating
  // causing this query to be executed, however the enabled condition doesn't reflect the
  // change in role causeing a bunch of 403 errors.  The race condition is a problem for another day.
  if (isAdmin(userRole)) {
    // Get user's organization
    const { id: orgId } = await userApi.getUserOrganization(userId);
    if (orgId) {
      // Get that organizations projects
      const orgProjects = await organizationApi.getOrganizationProject(orgId);
      adminProjects.push(...orgProjects);
    }
  }
  return adminProjects;
};

export const useAdminProjects = (userId, userRole) => {
  return useQuery(
    { queryKey: projectKeys.adminProjects(userId, userRole), queryFn: () => getAdminProjects(userId, userRole) },
    {
      enabled: isAdmin(userRole),
      select: React.useCallback((data) => data?.filter(isNotDeletedStatus), []),
    }
  );
};
