import styled from "styled-components/macro";

const ToolbarSeparator = styled.span`
  height: 20px;
  width: 1px;
  background: #999;
  display: inline-block;
  vertical-align: middle;
  margin: 0 2px;
`;

export default ToolbarSeparator;
