import { useState, useCallback, useMemo, useRef } from "react";
import toast from "react-hot-toast";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable, RetainStringColumnFilter } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { TYPE } from "constants/brm";
import {
  createColumnName,
  createColumnMapped,
  createColumnBooleanMapped,
  createColumnBooleanNoEditMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import * as ReactQuery from "@tanstack/react-query";
import { useRoles } from "features/brm/hooks/useRoles";
import { SystemApi } from "features/brm";
import { useCanRename } from "features/system-model";

const ActivityTable = ({ setSelectedRows }) => {
  const { canRename } = useCanRename();
  const projectId = useRecoilValue(projectIdState);
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedCategory = useRef("");
  const updatedIsInternal = useRef("");
  const updatedIsInitial = useRef("");
  const updatedIsFinal = useRef("");
  const updatedNode = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const queryClient = ReactQuery.useQueryClient();
  const { data: elementCats, isError: isElementCatsError } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });
  const { data: nodes, isError: isNodesError } = SystemApi.useNodes({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });
  const { data: activities, isError: isActivitiesError } = SystemApi.useActivities({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst), staleTime: 5000 },
  });

  const { mutate: setActivityName } = SystemApi.useSetActivityName({
    options: {
      onSettled: () => {
        setSelectedInstance({
          id: selectedInstance.id,
          name: updatedName.current,
          type: selectedInstance.type,
        });
        updatedName.current = "";
      },
      onError: (err) => {
        toast.error(`Setting activity name caused an error: ${err}`);
      },
    },
  });
  const { mutateAsync: setActivityCategory } = SystemApi.useSetActivityCategory({
    options: {
      onSettled: () => {
        updatedCategory.current = "";
      },
      onError: (err) => {
        toast.error(`Setting activity category caused an error: ${err}`);
      },
    },
  });
  const { mutateAsync: setActivityIsFinal } = SystemApi.useSetActivityIsFinal({
    options: {
      onSettled: () => {
        updatedIsFinal.current = "";
      },
      onError: (err) => {
        toast.error(`Setting activity final caused an error: ${err}`);
      },
    },
  });
  const { mutateAsync: setActivityIsInitial } = SystemApi.useSetActivityIsInitial({
    options: {
      onSettled: () => {
        updatedIsInitial.current = "";
      },
      onError: (err) => {
        toast.error(`Setting activity initial caused an error: ${err}`);
      },
    },
  });
  const { mutateAsync: setActivityIsInternal } = SystemApi.useSetActivityIsInternal({
    options: {
      onSettled: () => {
        updatedIsInternal.current = "";
      },
      onError: (err) => {
        toast.error(`Setting activity internal caused an error: ${err}`);
      },
    },
  });
  const { mutateAsync: setActivityNode } = SystemApi.useSetActivityNode({
    options: {
      onSettled: () => {
        updatedNode.current = "";
      },
      onError: (err) => {
        toast.error(`Setting activity node caused an error: ${err}`);
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedName.current !== "") {
              if (!canRename({ updatedName, elementsList: activities })) return;
              await setActivityName({ activityId: selectedInstance.id, name: updatedName.current });
            }

            if (updatedIsInternal.current !== "") {
              await setActivityIsInternal({
                activityId: selectedInstance.id,
                isInternal: updatedIsInternal.current,
              });
            }

            if (updatedIsInitial.current !== "") {
              await setActivityIsInitial({
                activityId: selectedInstance.id,
                isInitial: updatedIsInitial.current,
              });
            }

            if (updatedIsFinal.current !== "") {
              await setActivityIsFinal({
                activityId: selectedInstance.id,
                isFinal: updatedIsFinal.current,
              });
            }

            if (updatedCategory.current !== "") {
              await setActivityCategory({
                activityId: selectedInstance.id,
                category: updatedCategory.current,
              });
            }

            if (updatedNode.current !== "") {
              await setActivityNode({ activityId: selectedInstance.id, nodeId: updatedNode.current });
            }

            queryClient.invalidateQueries(["activity", selectedInstance.id]);
            queryClient.invalidateQueries(["activities", projectId]);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [
      selectedInstance.id,
      editMode,
      queryClient,
      projectId,
      canRename,
      activities,
      setActivityName,
      setActivityIsInternal,
      setActivityIsInitial,
      setActivityIsFinal,
      setActivityCategory,
      setActivityNode,
    ]
  );

  const checkNameUniqueness = useCallback(
    (newName) => {
      const trimedNamed = newName.trim();
      return isSystemEngineer && Array.isArray(activities)
        ? !activities.map((activity) => activity.name.toLowerCase()).includes(trimedNamed.toLowerCase())
        : false;
    },
    [isSystemEngineer, activities]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnName(
          selectedInstance.id,
          setSelectedInstance,
          editMode,
          updatedName,
          "activity",
          checkNameUniqueness,
          {
            Filter: RetainStringColumnFilter,
          }
        ),
        createColumnCategoryMapped("category", selectedInstance.id, elementCats, editMode, updatedCategory),
        createColumnBooleanMapped("isInternal", selectedInstance.id, editMode, updatedIsInternal),
        createColumnBooleanNoEditMapped("isBoundary"),
        createColumnBooleanMapped("isInitial", selectedInstance.id, editMode, updatedIsInitial),
        createColumnBooleanMapped("isFinal", selectedInstance.id, editMode, updatedIsFinal),
        createColumnMapped("node", selectedInstance.id, nodes, editMode, updatedNode),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "activity"),
      createColumnCategoryMapped("category", selectedInstance.id, elementCats, false, updatedCategory),
      createColumnBooleanMapped("isInternal", selectedInstance.id, false, updatedIsInternal),
      createColumnBooleanNoEditMapped("isBoundary"),
      createColumnBooleanMapped("isInitial", selectedInstance.id, false, updatedIsInitial),
      createColumnBooleanMapped("isFinal", selectedInstance.id, false, updatedIsFinal),
      createColumnMapped("node", selectedInstance.id, nodes, false, updatedNode),
    ];
  }, [
    isSystemEngineer,
    selectedInstance.id,
    setSelectedInstance,
    elementCats,
    nodes,
    editMode,
    checkNameUniqueness,
    createButton,
  ]);

  if (isElementCatsError || isNodesError || isActivitiesError) {
    return <ErrorBanner msg="Error while loading Activities data." />;
  }

  if (elementCats && activities) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={activities}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "ActivityTable_table" }}
            elementName={TYPE.activity}
            showRowSelect={isSystemEngineer}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

ActivityTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

export default ActivityTable;
