import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { queryClient } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

interface ISetNameControlCatalog {
  ccId: string;
  name: string;
}

/**
 * Sets the name of Control Catalog
 *
 * @param {string} ccId - ccID of the control catalog
 * @param {string} name - name of the control catalog
 */
export const setCCName = ({ ccId, name }: ISetNameControlCatalog) => {
  return Brm.controlCatalogApi.setControlCatalogName(ccId, { body: name });
};

/**
 * Custom hook to change the name of an existing Control Catalog
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetCCName = ({ options = {} } = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.controlCatalogKeys.all);
    },
    ...options,
    mutationFn: setCCName,
  });
};
