import * as React from "react";

import SelectCategory from "components/forms/DynamicSelectBoxCategory";
import BooleanDropdown from "components/forms/BooleanDropdown";
import { stringToBoolean } from "utils/boolean-checker";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";

import { CapabilityCreateDto } from "@kdmanalytics/brm-system";
import PropTypes from "prop-types";
import Select from "react-select";
import TextField from "components/forms/TextField";
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { sortUpperCase } from "utils/sorting";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { SystemApi } from "features/brm";
import { validateNameUniqueness } from "features/system-model";

const SELECT_CATEGORY = "Select Category";

const AddCapabilityForm = ({ setModalIsOpen }) => {
  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = React.useState("");
  const [selectedNode, setSelectedNode] = React.useState([]);
  const [selectedActivities, setSelectedActivities] = React.useState([]);
  const [isTextValid, setIsTextValid] = React.useState(true);

  const { mutate: createCapability } = SystemApi.useCreateCapability();
  const { mutateAsync: addCapabilityNode } = SystemApi.useAddCapabilityNode();
  const { mutateAsync: addCapabilityActivity } = SystemApi.useAddCapabilityActivity();

  const { data: capabilities, isError: isCapabilitiesError } = SystemApi.useCapabilities({
    projectId,
    options: { enabled: !!projectId },
  });

  const { data: elementCategories, isError: isElementCategoriesError } = SystemApi.useElementCategories({
    projectId,
    options: {
      enabled: !!projectId,
      select: React.useCallback((data) => data.sort(sortUpperCase), []),
    },
  });

  const { data: activities, isError: isActivitiesError } = SystemApi.useActivities({
    projectId,
    options: {
      enabled: !!projectId,
      select: React.useCallback((data) => data.map((d) => ({ value: d.id, label: d.name })), []),
    },
  });

  const { data: nodes, isError: isNodesError } = SystemApi.useNodes({
    projectId,
    options: {
      enabled: !!projectId,
      select: React.useCallback((data) => data.map((d) => ({ value: d.id, label: d.name })), []),
    },
  });

  function validate(formData) {
    let error = true;
    if (
      formData.name.value === "" ||
      formData.category.value === SELECT_CATEGORY ||
      formData.isInternal.value === null
    ) {
      error = false;
    }
    return error;
  }

  const handleNodeSelectChange = (selectedItems = []) => {
    setSelectedNode(selectedItems);
  };

  const handleActivitiesSelectChange = (selectedItems = []) => {
    setSelectedActivities(selectedItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isNameValid = validateNameUniqueness(capabilities, e.target.elements.name.value);
    if (!isNameValid) {
      setPostError(FORM_ERROR.duplicateCapabilityName);
      return null;
    }

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value || "",
        category: e.target.elements.category.value,
        isInternal: stringToBoolean(e.target.elements.isInternal.value),
      };

      const dto = CapabilityCreateDto.constructFromObject(params);

      createCapability(
        { projectId, capabilityCreateDto: dto },
        {
          onSettled: (capabilityId) => {
            if (selectedActivities.length > 0 && capabilityId) {
              Promise.all(selectedActivities.map((d) => addCapabilityActivity({ capabilityId, activityId: d.value })));
            }

            if (selectedNode.length > 0 && capabilityId) {
              Promise.all(selectedNode.map((d) => addCapabilityNode({ capabilityId, nodeId: d.value })));
            }
            setModalIsOpen(false);
          },
          onError: () => {
            setPostError("Error occured while adding capability");
          },
        }
      );
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  };

  if (isCapabilitiesError || isElementCategoriesError || isActivitiesError || isNodesError) {
    return <ErrorBanner msg="Error while loading required info for add capability" />;
  }

  if (elementCategories && activities && nodes) {
    return (
      <div id="CapabilityAddForm">
        <form onSubmit={handleSubmit} action="" id="CapabilityAddForm_form">
          <FormStyled>
            <div className="form-style">
              <TextField
                label={{ id: "CapabilityAddForm_name", name: FORM_LABEL.nameMandatory }}
                input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "CapabilityAddForm_note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />
              <BooleanDropdown
                label={{ id: "CapabilityAddForm_isInternal", name: FORM_LABEL.internalMandatory }}
                select={{ id: "isInternal", name: "isInternal" }}
              />

              <label id="CapabilityAddForm_category">{FORM_LABEL.categoryMandatory}</label>
              <SelectCategory
                id="CapabilityAddForm_categoryDropdown"
                arrayOfData={elementCategories}
                name="Category"
                item="category"
              />

              <label id="CapabilityAddForm_node">{FORM_LABEL.nodeOptional}</label>
              <Select
                isMulti
                options={nodes}
                defaultValue={selectedNode}
                onChange={handleNodeSelectChange}
                id="CapabilityAddForm_nodeDropdown"
                classNamePrefix="nodeDropdown"
              />
              <br />

              <label id="CapabilityAddForm_activity">{FORM_LABEL.activityOptional}</label>
              <Select
                isMulti
                options={activities}
                defaultValue={selectedActivities}
                onChange={handleActivitiesSelectChange}
                id="CapabilityAddForm_activityDropdown"
                classNamePrefix="activityDropdown"
              />
              <br />
            </div>
          </FormStyled>
          <FormStyledError id="CapabilityAddForm_error">{postError}</FormStyledError>
          <DialogButtonDivStyled id="CapabilityAddForm_buttons">
            <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="CapabilityAddForm_cancelButton">
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled type="submit" id="CapabilityAddForm_addButton">
              Add
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }
  return <Loading />;
};

AddCapabilityForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};

export default AddCapabilityForm;
