import { useContext } from "react";
import { ThemeContext } from "styled-components";

import { ReactComponent as ToggleLabel } from "assets/icons/studio91/sidebars-toolbars/hide-labels.svg";
import { ReactComponent as ExportImage } from "assets/icons/studio91/attack/export-image.svg";
import { ReactComponent as RemoveNodes } from "assets/icons/studio91/attack/remove-nodes.svg";
import { ReactComponent as FocusOnSelected } from "assets/icons/studio91/sidebars-toolbars/focus.svg";
import { FaPlusCircle } from "react-icons/fa";
import { MdOutlineHideImage } from "react-icons/md";

import {
  Toolbar,
  ToolbarButton,
  ToolbarSeparator,
  ZoomButtonsGroup,
  LayoutButtonsGroup,
} from "features/diagram-common";
import { LayoutType } from "features/diagram-constants";
import { FilterToolbar } from "../FilterToolbar";

interface IAttackGraphToolbarProps {
  onZoomIn: () => void;
  onResetZoom: () => void;
  onZoomOut: () => void;
  onFitContent: () => void;
  onOrganicLayout: () => void;
  onRadialLayout: () => void;
  onCircularLayout: () => void;
  onToggleLabels: () => void;
  onExportAsImage: () => void;
  onRemoveAllNodes: () => void;
  onAddAllNodes: () => void;
  onFocusOnSelectedNodes: () => void;
  onToggleOverview: () => void;
  // onToggleOrphans: () => void;
  onNodeFilterChange: () => void;
  showLabels: boolean;
  hasNodeSelection: boolean;
  nodeFilterState: any;
  currentLayout: LayoutType;
  // showOrphans: boolean;
  showOverview: boolean;
}

export const AttackDiagramToolbar = ({
  // resetData,
  onZoomIn,
  onResetZoom,
  onZoomOut,
  onFitContent,
  onOrganicLayout,
  onRadialLayout,
  onCircularLayout,
  onToggleLabels,
  onExportAsImage,
  onRemoveAllNodes,
  onAddAllNodes,
  onFocusOnSelectedNodes,
  onToggleOverview,
  showLabels,
  hasNodeSelection,
  nodeFilterState,
  onNodeFilterChange,
  currentLayout,
  // showOrphans = true,
  // onToggleOrphans,
  showOverview = true,
}: IAttackGraphToolbarProps) => {
  const theme = useContext(ThemeContext);

  const {
    colors: { buttonPrimary: buttonFill },
    // TODO the standard sizes from the theme look terrible... might have to revisit for now override with smaller icons
    // sizes: { standaloneIconWidth: iconWidth, standaloneIconWidth: iconHeight },
  } = theme;
  const iconWidth = "16px";
  const iconHeight = "16px";

  const toggleLabelIcon = <ToggleLabel fill={buttonFill} width={iconWidth} height={iconHeight} />;
  const exportImageIcon = <ExportImage fill={buttonFill} width={iconWidth} height={iconHeight} />;
  const removeNodesIcon = <RemoveNodes fill={buttonFill} width={iconWidth} height={iconHeight} />;
  const addNodesIcon = <FaPlusCircle fill={buttonFill} width={iconWidth} height={iconHeight} />;
  const focusOnIcon = <FocusOnSelected fill={buttonFill} width={iconWidth} height={iconHeight} />;

  return (
    <Toolbar>
      <ToolbarButton
        icon={<MdOutlineHideImage size="18" color={buttonFill} />}
        tooltip="Toggle Overview"
        onClick={onToggleOverview}
        toggled={!showOverview}
      />{" "}
      <ToolbarSeparator />
      <ZoomButtonsGroup zoomIn={onZoomIn} resetZoom={onResetZoom} zoomOut={onZoomOut} fitContent={onFitContent} />
      <ToolbarSeparator />
      <LayoutButtonsGroup
        organicLayout={onOrganicLayout}
        radialLayout={onRadialLayout}
        circularLayout={onCircularLayout}
        currentLayout={currentLayout}
      />
      <ToolbarSeparator />
      <ToolbarButton icon={toggleLabelIcon} tooltip="Show/Hide Labels" onClick={onToggleLabels} toggled={!showLabels} />
      <ToolbarSeparator />
      <ToolbarButton icon={exportImageIcon} tooltip="Export As Image" onClick={onExportAsImage} />
      <ToolbarButton icon={removeNodesIcon} tooltip="Remove All Nodes" onClick={onRemoveAllNodes} />
      <ToolbarButton icon={addNodesIcon} tooltip="Add All Nodes" onClick={onAddAllNodes} />
      <ToolbarButton
        icon={focusOnIcon}
        tooltip="Focus On Selected"
        onClick={onFocusOnSelectedNodes}
        disabled={!hasNodeSelection}
      />
      <ToolbarSeparator />
      <FilterToolbar
        nodeFilterState={nodeFilterState}
        onNodeFilterChange={onNodeFilterChange}
        /* onToggleOrphans={onToggleOrphans} */
        /* showOrphans={showOrphans} */
      />
    </Toolbar>
  );
};
