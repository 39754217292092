/* eslint-disable no-redeclare */
import * as React from "react";
import { getRefElement } from "utils/ref-utils";

interface IUseEventListener {
  type: keyof HTMLElementEventMap;
  listener: EventListener;
  element?: React.RefObject<Element> | Document | Window | null;
}

export function useEventListener({ type, listener, element = document }: IUseEventListener) {
  const savedListener = React.useRef<EventListener>();

  React.useEffect(() => {
    savedListener.current = listener;
  }, [listener]);

  const handleEventListener = React.useCallback((event: Event) => {
    savedListener.current?.(event);
  }, []);

  React.useEffect(() => {
    const target = getRefElement(element);
    target?.addEventListener(type, handleEventListener);
    return () => target?.removeEventListener(type, handleEventListener);
  }, [type, element, handleEventListener]);
}
