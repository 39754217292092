import * as BrmGql from "generated/graphql";
import { UseQueryOptions, QueryKey } from "@tanstack/react-query";

/**
 * Custom hook to get all sctm allocated controls
 *
 * @param {string} sctmId - uuid of the SCTM
 * @returns react-query
 */
export const useGetSctmAllocatedControls = ({
  sctmId,
  options,
}: {
  sctmId: string;
  options:
    | UseQueryOptions<BrmGql.GetSctmAllocatedControlsQuery, unknown, BrmGql.GetSctmAllocatedControlsQuery, QueryKey>
    | undefined;
}) => {
  return BrmGql.useGetSctmAllocatedControlsQuery({ sctm: sctmId }, options);
};
