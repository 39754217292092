import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

type ProjectProperty = "catalog" | "baseline" | "settings" | "organization";

/**
 * Gets the project's projects
 */
export const getProjectProperty = ({
  projectId,
  property,
}: {
  projectId: string | null;
  property: ProjectProperty;
}) => {
  if (projectId === null) {
    return Promise.reject(new Error("Invalid projectId"));
  }
  switch (property) {
    case "catalog":
      return Brm.projectApi.getProjectCatalog(projectId);
    case "baseline":
      return Brm.projectApi.getProjectBaseline(projectId);
    case "organization":
      return Brm.projectApi.getProjectOrganization(projectId);
    case "settings":
      return Brm.settingsApi.findSetting(projectId);
    default:
      return Promise.reject(new Error(`Unsupported property:${property}`));
  }
};

type FetcherFunction = typeof getProjectProperty;
interface IProjectsProps {
  projectId: string | null;
  property: ProjectProperty;
  options?: QueryOption<FetcherFunction>;
}

/**
 * Custom hook for getting projects projects
 *
 * @param {object} options - react-query options
 */
export const useGetProjectProperty = ({ projectId, property, options = {} }: IProjectsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.property(projectId, property),
    queryFn: () => getProjectProperty({ projectId, property }),
  });
};
