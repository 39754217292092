import styled from "styled-components";

export const ShowFilterButton = styled.button`
  display: flex;
  margin: 5px 0;
  background: none;
  color: inherit;
  border: 1px solid black;
  border-radius: 5px;
  padding-top: 5px;
  font-family: ${({ theme }) => theme.fonts.primary};
  cursor: pointer;
  justify-content: center;
  text-align: center;
  :focus {
    outline: none;
  }
  :hover {
    opacity: 0.5;
  }
  align-self: center;
`;

export const SelectDiv = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  width: 23%;
  display: inline-block;
`;

export const SelectLabel = styled.label`
  margin: 10px 0;
  width: 100%;
  display: inline-block;
  font-size: 12px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  background: #fafbfc;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
`;

export const RowFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;
