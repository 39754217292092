import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./impact-query-key-factory";

interface IGetUndesiredEvents {
  projectId: string;
  variantId: string;
}

export const getUndesiredEvents = async ({ projectId, variantId }: IGetUndesiredEvents) => {
  const undesiredEvents = await Brm.undesiredEventApi.findUndesiredEvent(projectId, { variant: variantId });

  undesiredEvents.forEach((ue: any) => {
    if (ue.impact.calibratedStringValue === "zero") {
      const index = undesiredEvents.indexOf(ue);
      undesiredEvents.splice(index, 1);
    }
  });

  return undesiredEvents;
};

interface IUseGetUndesiredEvents {
  projectId: string;
  variantId: string;
  hasRiskChanged: boolean;
  options?: QueryOption<typeof getUndesiredEvents>;
}

export const useUndesiredEvents = ({ projectId, variantId, options }: IUseGetUndesiredEvents) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.undesiredEventKeys.project(projectId, variantId),
    queryFn: () => getUndesiredEvents({ projectId, variantId }),
  });
};
