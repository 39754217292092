import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";
import { ProjectCatalogConfigTable } from "../../components";

export function ProjectCatalogConfiguration() {
  const [, setSelectedRows] = useState([]);

  const Header = <Title>Project Catalog Configuration</Title>;

  const Main = <ProjectCatalogConfigTable setSelectedRows={setSelectedRows} />;

  return <ContentLayout pageId="ProjectCatalogConfiguration_page" header={Header} main={Main} />;
}
