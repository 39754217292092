/**
 * CyberSecurityExpert Help contents -  this is a key value pair mapping help videos to each page , where key is the "contentKey" which is unique to each page
 */

export const CyberSecurityHelpContents = {
  knowledgebases: {
    contents: [
      {
        role: "CyberSecurity Expert",
        type: "Video",
        name: "Clone a knowledgebase",
        filePath: "/help-assets/CSE/CloneAKnowledgebase/CloneAKnowledgebase_player.html",
      },
      {
        role: "CyberSecurity Expert",
        type: "Video",
        name: "Edit a knowledgebase",
        filePath: "/help-assets/CSE/EditAKnowledgebase/EditAKnowledgebase_player.html",
      },
      {
        role: "CyberSecurity Expert",
        type: "Video",
        name: "Export Import knowledgebase",
        filePath: "/help-assets/CSE/exportImportKnowledgebase/exportImportKnowledgebase_player.html",
      },
      {
        role: "CyberSecurity Expert",
        type: "Video",
        name: "Export knowledgebase difference report",
        filePath: "/help-assets/CSE/knowledgebaseDiffReport/knowledgebaseDiffReport_player.html",
      },
    ],
  },

  kbProjects: {
    contents: [
      {
        role: "CyberSecurity Expert",
        type: "Video",
        name: "Assign a knowledgebase to a project",
        filePath: "/help-assets/CSE/AssignAKnowledgebaseToAProject/AssignAKnowledgebaseToAProject_player.html",
      },
      {
        role: "CyberSecurity Expert",
        type: "Video",
        name: "Set a vulnerability computations",
        filePath: "/help-assets/CSE/SetVulnerabilityComputations/SetVulnerabilityComputations_player.html",
      },
      {
        role: "CyberSecurity Expert",
        type: "Video",
        name: "Enable physical attacks",
        filePath: "/help-assets/CSE/enablePhysicalAttacks/enablePhysicalAttacks_player.html",
      },
    ],
  },

  knowledgebasesDetails: {
    contents: [
      {
        role: "CyberSecurity Expert",
        type: "Video",
        name: "Customize a knowledgebase",
        filePath: "/help-assets/CSE/CustomizeAKnowledgebase/CustomizeAKnowledgebase_player.html",
      },
      {
        role: "CyberSecurity Expert",
        type: "Video",
        name: "View a list of projects assigned to a kb",
        filePath: "/help-assets/CSE/ViewAListOfProjectsAssignedToAKB/ViewAListOfProjectsAssignedToAKB_player.html",
      },
    ],
  },
};
