import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./user-query-key-factory";

interface IUserLastnameProps {
  userId: string | null;
  newLastname: string;
}

/**
 * Set the users lastname
 *
 * @param param0 upload file data
 * @returns
 */
export const setUserLastname = ({ userId, newLastname }: IUserLastnameProps) => {
  return userId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.userApi.setUserLastname(userId, { body: newLastname });
};

interface ISetNameProps {
  options?: MutationOption<typeof setUserLastname>;
}

/**
 * mutate the user lastname.
 *
 * @returns react-query Mutation.
 */
export const useSetUserLastname = ({ options }: ISetNameProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.userKeys.user(variables.userId));
      queryClient.invalidateQueries(KeyFactory.userKeys.all);
    },
    ...options,
    mutationFn: setUserLastname,
  });
};
