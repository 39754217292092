import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import errorUtils from "utils/error-utils";
// styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import SelectBox from "components/forms/DynamicUsersSelectBox";
import { organizationApi } from "services/brm/admin-service";
// import filterList from "utils/filter-list";
import { isNotDeletedStatus } from "utils/filter-utils";

import getUniqueArray from "../../utils/get-unique-array";

export const AddAttachedUserForm = ({ setModalIsOpen, setRefresh, refresh, selectedElement, attachedUsers }) => {
  // Dropdowns for forms
  const [postError, setPostError] = useState("");
  const [userList, setUserList] = useState([]);

  /*
   * USE EFFECT
   */
  useEffect(() => {
    async function getData() {
      await organizationApi
        .findOrganizationWithHttpInfo()
        .then((res) => {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          res.data.forEach(async (item) => {
            // check for getting users belonging to other organizations
            if (selectedElement.id !== item.id) {
              try {
                const { data } = await organizationApi.getOrganizationUserWithHttpInfo(item.id);

                setUserList((uList) => [...uList, ...data]);
              } catch (err) {
                console.error("err :", err);
              }
            }
          });
        })
        .catch((err) => {
          console.error("err in get list for  attached users : ", err);
        });
    }
    getData();
  }, [selectedElement.id, setUserList]);

  // validate that all fields in the form have been completed
  function validate(formData) {
    let error = true;
    if (formData.user.value === "" || formData.user.value === "Select user") {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isValid = validate(e.target.elements);

    if (isValid) {
      // the new project from user form entry
      const params = e.target.elements.user.value;

      const opts = { body: params };
      try {
        const res = await organizationApi.addOrganizationAttachedUserWithHttpInfo(selectedElement.id, opts);
        if (res?.response) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setModalIsOpen(false);
          setRefresh(() => !refresh);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setPostError("*Please fill out all fields in the form prior to submitting.");
    }
  }

  return (
    <div id="AddAttachedUserForm">
      <form onSubmit={handleSubmit} action="" id="AddAttachedUserForm_form">
        <FormStyled id="AddAttachedUserForm_content">
          <div className="form-style">
            <label id="AddAttachedUserForm_selectUser">select user ( from other organizations ): </label>
            <SelectBox arrayOfData={getUniqueArray(userList, attachedUsers).filter(isNotDeletedStatus)} item="user" />
          </div>
        </FormStyled>
        <FormStyledError id="AddAttachedUserForm_error">{postError}</FormStyledError>
        <DialogButtonDivStyled id="AddAttachedUserForm_buttons">
          <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="AddAttachedUserForm_cancelButton">
            Cancel
          </DialogButtonStyled>
          <DialogButtonStyled type="submit" id="AddAttachedUserForm_addButton">
            Add
          </DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};

AddAttachedUserForm.propTypes = {
  attachedUsers: PropTypes.any,
  refresh: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setModalIsOpen: PropTypes.func,
  setRefresh: PropTypes.func,
};
