import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./variant-query-key-factory";

interface ISetVariantComplianceProps {
  variantId: string | null;
  isCompliance: string;
}

/**
 * Sets the compliance of an variant
 *
 * @param {string} id - uuid of the variant
 * @param {string} isCompliance - new name for the variant
 */
export const setVariantCompliance = ({ variantId, isCompliance }: ISetVariantComplianceProps) => {
  return Brm.variantApi.setVariantIsCompliance(variantId, { body: isCompliance });
};

interface IUseSetVariantComplianceProps {
  options?: MutationOption<typeof setVariantCompliance>;
}

/**
 * Custom hook to change the compliance of a existing variant
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetVariantCompliance = ({ options }: IUseSetVariantComplianceProps = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => queryClient.invalidateQueries(KeyFactory.variantsKeys.all),
    ...options,
    mutationFn: setVariantCompliance,
  });
};
