import * as React from "react";

export interface IStepContext {
  index: number;
  icon: React.ReactNode;
  isActive: boolean;
  isCompleted: boolean;
  isDisabled: boolean;
  isLast: boolean;
}

export const StepContext = React.createContext<IStepContext | null>(null);

export const useStepContext = () => {
  const context = React.useContext(StepContext);
  if (!context) {
    throw new Error("useStepContext must be used within StepContext.Provider");
  }
  return context;
};
