import * as React from "react";
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import { AddButton, DeleteButton, AdminApi, useProject } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { projectApi } from "services/brm/admin-service";
import { TYPE } from "constants/brm";
import { queryClient } from "libs/react-query";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { useRoles } from "features/brm/hooks/useRoles";
import { CreateProjectModal } from "features/admin/components/modals";
import { AddProjectForm } from "../../components/forms/AddProjectForm";
import { ProjectTable } from "../../components/tables/ProjectTable";

export const Projects = () => {
  const [selectedInstance, setSelectedInstance] = useRecoilState<any>(selectedInstanceState);
  const { isSuperAdmin, isAdmin, userRole } = useRoles();
  const [currentProject, { setCurrentProject }] = useProject();

  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  // Modals
  const [, setCancelDisable] = React.useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [isCreateProjectModalVisible, setIsCreateProjectModalVisible] = React.useState(false);

  const clearQueries = () => {
    queryClient.invalidateQueries(AdminApi.projectKeys.all);
  };

  React.useEffect(() => {
    async function multiDeleteApiCalls() {
      Promise.all(selectedRows.map((each: any) => projectApi.deleteProject(each.id)))
        .then(() => {
          setConfirmDelete(false);
          setDeleteModalIsOpen(false);
          clearQueries();
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }

    if (selectedRows.some((r: any) => r.id === currentProject?.id)) {
      setCurrentProject(null);
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, currentProject?.id, selectedRows, setCurrentProject, setSelectedInstance]);

  const addProject = () => {
    setModalIsOpen(true);
  };

  const createProject = () => {
    setIsCreateProjectModalVisible(true);
  };

  // delete project
  const deleteProject = () => {
    setDeleteModalIsOpen(true);
  };

  const projectHeader = (
    <Title id="Project_title">
      Projects
      <Buttons id="Project_buttons">
        {(isSuperAdmin || isAdmin || userRole === "") && selectedInstance !== "" && selectedRows.length > 0 ? (
          <DeleteButton onClick={deleteProject} />
        ) : null}
        {isAdmin ? (
          <>
            <AddButton tooltip="Create Project" onClick={createProject} />
          </>
        ) : null}
        {isSuperAdmin || userRole === "" ? (
          <>
            <AddButton onClick={addProject} />
          </>
        ) : null}
      </Buttons>
    </Title>
  );

  const projectMain = (
    <>
      <CreateProjectModal
        isVisible={isCreateProjectModalVisible}
        onClose={() => {
          setIsCreateProjectModalVisible(false);
        }}
      />

      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "ProjectPage",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.project}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.project
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "ProjectPage",
          modalIsOpen,
          setModalIsOpen,
          <AddProjectForm setModalIsOpen={setModalIsOpen} setCancelDisable={setCancelDisable} />,
          TYPE.project
        )}

      <ProjectTable setSelectedRows={setSelectedRows} />
    </>
  );

  return <ContentLayout pageId="ProjectPage" header={projectHeader} main={projectMain} />;
};
