import PropTypes from "prop-types";

// URLs
import CloneIcon from "assets/icons/copy.svg";

// Components
import ImgSized from "components/elements/ImgSized";

// Styles
import SecondaryButton from "components/elements/SecondaryStyledButton";
import InLineEditButton from "components/elements/InLineEditButtonStyled";
import { useRoles } from "features/brm/hooks/useRoles";

export const CloneButton = ({ onClick, disabled = false }) => {
  const { isThreatAnalyst } = useRoles();
  if (isThreatAnalyst) {
    return (
      <span title="Clone into Attacker">
        <SecondaryButton id="CloneButton_button" onClick={onClick} disabled={disabled}>
          <ImgSized id="CloneButton_icon" src={CloneIcon} alt="clone" />
        </SecondaryButton>
      </span>
    );
  }
  return (
    <span title="Clone">
      <InLineEditButton id="CloneButton_button" onClick={onClick} disabled={disabled}>
        <ImgSized id="CloneButton_icon" src={CloneIcon} alt="clone" />
      </InLineEditButton>
    </span>
  );
};

CloneButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
