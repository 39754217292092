import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";

/**
 * Returns the parent of the given node
 * @param {string} childId - uuid of the node
 * @returns parent of the given node
 */
export const getNodeParent = ({ childId }: { childId: string }) => {
  return Brm.nodeApi.getNodeParent(childId);
};

interface IUseNodeParentProps {
  childId: string;
  options?: QueryOption<typeof getNodeParent>;
}

/**
 * Custom hook to retreive a parent of a node.
 *
 * @param {string} childId - uuid of a child node
 * @param {object} config - react-query configuration object
 * @returns react-query for getNodeParent
 */
export const useNodeParent = ({ childId, options = {} }: IUseNodeParentProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.nodeKeys.parent(childId),
    queryFn: () => getNodeParent({ childId }),
  });
};
