import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./project-query-key-factory";

interface IAddProjectWorkerProps {
  projectId: string | null;
  sapProgramId: string | null;
}

/**
 * Add a project sap program
 *
 * @returns
 */
export const addProjectSapProgram = ({ projectId, sapProgramId }: IAddProjectWorkerProps) => {
  return projectId === null
    ? Promise.reject(new Error("Invalid projectId"))
    : Brm.projectApi.addProjectSapprogram(projectId, sapProgramId);
};

type MutateFunction = typeof addProjectSapProgram;
interface IAddProjectSapProgramProps {
  options?: MutationOption<MutateFunction>;
}

/**
 * Custom hook to add a sap program from project
 *
 * @returns react-query Mutation.
 */
export const useAddProjectSapProgram = ({ options }: IAddProjectSapProgramProps = {}) => {
  const queryClient = ReactQuery.useQueryClient();

  return ReactQuery.useMutation({
    onSettled: (data, context, variables) => {
      queryClient.invalidateQueries(KeyFactory.projectKeys.project(variables.projectId));
    },
    ...options,
    mutationFn: addProjectSapProgram,
  });
};
