import * as Recoil from "recoil";

import { useState, useMemo, useRef, useCallback, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import CustomTable from "components/elements/CustomTableDivStyled";
import {
  LoadingSpinner as Loading,
  UploadButton,
  ButtonSpacer,
  ButtonWrapper,
  DownloadButton,
} from "components/elements";
import { CloneButton, AdminApi, KnowledgebaseApi, useRoles } from "features/brm";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";

import PropTypes from "prop-types";

import { selectedInstanceState } from "atoms/atoms-content";
import { TYPE, COMMON } from "constants/brm";

import {
  createColumnName,
  createColumnModifiedAt,
  createColumnCreatedAt,
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnActions,
  createColumnMapped,
} from "brm/tables/services/column/columnFactory";

import { ImportKbFileModal, ExportKbFileModal } from "features/importer";
import { KB_VERSIONS } from "features/knowledgebase/constants";

export const KBListTable = ({ setSelectedRows }) => {
  const { isCyberSecurityExpert } = useRoles();
  const [selectedInstance, setSelectedInstance] = Recoil.useRecoilState(selectedInstanceState);

  const [editElement, setEditElement] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [userOrgId, setUserOrgId] = useState("");
  const [isImportKbModalVisible, setIsImportKbModalVisible] = useState(false);
  const [isExportKbModalVisible, setIsExportKbModalVisible] = useState(false);
  const [importKbId, setImportKbId] = useState("");
  const [kbName, setKbName] = useState("");

  const updatedName = useRef("");
  const updatedVersion = useRef("");

  const queryClient = useQueryClient();

  const {
    data: kbData,
    error: kbError,
    isError: isKbDataError,
  } = KnowledgebaseApi.useAllKbs({ options: { enabled: isCyberSecurityExpert } });

  // console.log(kbData);
  const { data: selfData, isError: isSelfDataError } = AdminApi.useSelf({ options: {} });

  const { mutate: cloneKb } = KnowledgebaseApi.useCloneKb({
    options: {
      onError: () => {
        toast.error(<span id="kbListError"> Error occuring while cloning</span>, {
          duration: 4000,
        });
      },
    },
  });
  const { mutate: updateKbName } = KnowledgebaseApi.useSetKbName({
    options: {
      onMutate: async ({ kbId, name }) => {
        await queryClient.cancelQueries(KnowledgebaseApi.knowledgebaseKeys.all);
        const previousValues = queryClient.getQueryData(KnowledgebaseApi.knowledgebaseKeys.all);
        queryClient.setQueryData(KnowledgebaseApi.knowledgebaseKeys.all, (old) =>
          old.map((data) => {
            if (kbId === data.id) {
              return {
                ...data,
                name,
              };
            }
            return data;
          })
        );
        return previousValues;
      },
      onError: (previousValues) => queryClient.setQueryData(KnowledgebaseApi.knowledgebaseKeys.all, previousValues),
      onSettled: () => {
        setSelectedInstance({
          id: selectedInstance.id,
          name: updatedName.current,
          type: selectedInstance.type,
        });
        updatedName.current = "";
      },
    },
  });
  const { mutate: updateKbVersion } = KnowledgebaseApi.useSetKbVersion({
    options: {
      onMutate: async ({ kbId, version }) => {
        await queryClient.cancelQueries(KnowledgebaseApi.knowledgebaseKeys.all);
        const previousValues = queryClient.getQueryData(KnowledgebaseApi.knowledgebaseKeys.all);
        queryClient.setQueryData(KnowledgebaseApi.knowledgebaseKeys.all, (old) =>
          old.map((data) => {
            if (kbId === data.id) {
              return {
                ...data,
                version,
              };
            }
            return data;
          })
        );
        return previousValues;
      },
      onError: (previousValues) => queryClient.setQueryData(KnowledgebaseApi.knowledgebaseKeys.all, previousValues),
      onSettled: () => {
        updatedVersion.current = "";
      },
    },
  });

  const cloneKB = useCallback(
    (cellProps) => {
      if (userOrgId) {
        cloneKb({ kbId: cellProps.cell.row.original.id, orgId: userOrgId });
      }
    },
    [cloneKb, userOrgId]
  );

  // const downloadHandler = useCallback(
  //   (kbId, kbName, format) => {
  //     exportKnowledgebase(
  //       { kbId, format },
  //       {
  //         onSuccess: (data) => {
  //           const url = window.URL.createObjectURL(data);
  //           const a = document.createElement("a");
  //           a.href = url;
  //           const timestamp = new Date(Date.now())
  //             .toISOString()
  //             .replace(/T/, "_")
  //             .replace(/\..+/, "")
  //             .replace(/-|:/g, "");

  //           a.download = `${kbName}-${timestamp}.${format.toLowerCase()}`;
  //           a.click();
  //           window.URL.revokeObjectURL(url);
  //         },
  //       }
  //     );
  //   },
  //   [exportKnowledgebase]
  // );

  const uploadHandler = useCallback((kbId) => {
    setIsImportKbModalVisible(true);
    setImportKbId(kbId);

    // importKnowledgeBase
    // toast.success("Upload Button click", {
    //   duration: 4000,
    // });
  }, []);

  const exportHandler = useCallback((kbId, kbCurrentName) => {
    setIsExportKbModalVisible(true);
    setImportKbId(kbId);
    setKbName(kbCurrentName);
  }, []);

  const handleImportKbModalClose = () => {
    setIsImportKbModalVisible(false);
  };

  const handleExportKbModalClose = () => {
    setIsExportKbModalVisible(false);
  };

  // console.log("userOrg", userOrg);

  const createButton = useCallback(
    (cellProps) => {
      if (!isCyberSecurityExpert) {
        return COMMON.na;
      }
      const currentRow = cellProps.cell.row.original;
      // console.log("currentRow", currentRow);

      const exportButton = (
        <DownloadButton title="Export Knowledgebase" onClick={() => exportHandler(currentRow.id, currentRow.name)} />
        // <FormatDownloadButton id="dropdown-basic-button" title="Export...">
        //   <Dropdown.Item onClick={() => downloadHandler(currentRow.id, currentRow.name, "CSV")}>
        //     Export as CSV
        //   </Dropdown.Item>
        //   <Dropdown.Item onClick={() => downloadHandler(currentRow.id, currentRow.name, "JSON")}>
        //     Export as JSON
        //   </Dropdown.Item>
        // </FormatDownloadButton>
      );

      const canEdit =
        (userOrgId === COMMON.defaultUuid && currentRow.organization.id === COMMON.defaultUuid) ||
        userOrgId === currentRow.organization.id;

      return (
        <ButtonWrapper>
          {currentRow.id === COMMON.defaultUuid || currentRow.isGlobal ? (
            <>
              <ButtonSpacer />
              <CloneButton onClick={() => cloneKB(cellProps)} />
              {exportButton}
              <ButtonSpacer />
            </>
          ) : (
            <>
              {canEdit ? (
                <ButtonCell
                  selectedRowId={editElement?.id || ""}
                  elementId={currentRow.id}
                  handleConfirmEditClick={async () => {
                    setEditMode(false);

                    if (updatedName.current !== "") {
                      updateKbName({ kbId: editElement?.id, name: updatedName.current });
                    }

                    if (updatedVersion.current !== "") {
                      updateKbVersion({ kbId: editElement?.id, version: updatedVersion.current });
                    }
                  }}
                  setEditMode={setEditMode}
                  editMode={editMode}
                  onEditModeStart={() => {
                    setEditElement(cellProps.cell.row.original);
                  }}
                />
              ) : (
                <ButtonSpacer />
              )}
              <CloneButton onClick={() => cloneKB(cellProps)} />
              {exportButton}
              {canEdit ? <UploadButton onClick={() => uploadHandler(currentRow.id)} /> : null}
            </>
          )}
        </ButtonWrapper>
      );
    },
    [
      isCyberSecurityExpert,
      userOrgId,
      editElement?.id,
      editMode,
      exportHandler,
      cloneKB,
      updateKbName,
      updateKbVersion,
      uploadHandler,
    ]
  );

  useEffect(() => {
    if (selfData) {
      setUserOrgId(selfData.organization);
    }
  }, [selfData]);

  const columns = useMemo(
    () => [
      createColumnName(editElement?.id, setSelectedInstance, editMode, updatedName, "kb"),
      createColumnMappedNoEdit("org"),
      createColumnMapped("version", editElement?.id, KB_VERSIONS, editMode, updatedVersion),

      // createColumnMappedStringEdit("version", selectedInstance.id, editMode, updatedVersion),
      createColumnBooleanNoEditMapped("isGlobal"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [editMode, editElement, setSelectedInstance, createButton]
  );

  if (isKbDataError || isSelfDataError) {
    toast.error(<span id="kbListError"> Error while loading knowledgebase: {kbError}</span>, {
      duration: 4000,
    });
  }

  if (kbData) {
    return (
      <>
        <ImportKbFileModal kbId={importKbId} show={isImportKbModalVisible} onHide={handleImportKbModalClose} />
        <ExportKbFileModal
          kbId={importKbId}
          kbName={kbName}
          show={isExportKbModalVisible}
          onHide={handleExportKbModalClose}
        />
        <CustomTable>
          <BrmMainTable
            data={kbData}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "KBListTable_table" }}
            elementName={TYPE.knowledgebase}
            showRowSelect={false} // delete KB is not supported by backend
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

KBListTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

// export default KBListTable;
