import PropTypes from "prop-types";
import { useState } from "react";
import toast from "react-hot-toast";
import SelectBox from "components/forms/DynamicSelectBox";
import SelectBoxCategory from "components/forms/DynamicSelectBoxCategory";
import { ImpactStatementCreateDto, CategorizedEntityCreateDto } from "@kdmanalytics/brm-system";
// styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
// Services
import * as CategorizedEntityApi from "brm/system-model/categorization/api";
import { COMMON, FORM_ERROR, LEVELS } from "constants/brm";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { LoadingSpinner as Loading } from "components/elements";
import { useQueryClient } from "@tanstack/react-query";
import { SystemApi } from "features/brm";
import { useSetCategorizedEntityCategorization } from "../api";

const CategorizationAddForm = ({ setModalIsOpen, elementNameInUrl: elementName, selectedElement: systemElement }) => {
  // Dropdowns for forms
  const [postError, setPostError] = useState("");
  const queryClient = useQueryClient();
  const projectId = useRecoilValue(projectIdState);
  const createCategorizedEntity = SystemApi.useCreateCategorizedEntity();

  const { data: systemElementCategorizedEntity } = SystemApi.useSystemElementCategorizedEntity({
    elementId: systemElement.id,
    options: {
      select: (data) => {
        if (data.id === COMMON.nullUuid) {
          return undefined;
        }
        return data;
      },
      enabled: elementName !== "categorizedEntities",
    },
  });

  const categorizedEntity = elementName !== "categorizedEntities" ? systemElementCategorizedEntity : systemElement;

  // const [soList, setSoList] = useState([]);
  const { data: securityObjectivesList, isError: isSecurityObjectivesListError } =
    CategorizedEntityApi.useSecurityObjectives({
      projectId,
      config: { enabled: !!projectId, select: (data) => data.map((d) => d.name) },
    });

  const { mutate: addCategorization } = useSetCategorizedEntityCategorization({
    config: {
      onSettled: () => {
        setModalIsOpen(false);
        queryClient.invalidateQueries(["elementCategorization"]);
      },
    },
  });

  // validate that all fields in the form have been completed
  function validate(formData) {
    let error = true;
    if (formData.levels.value === "Select" || formData.objective.value === "Select") {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isValid = validate(e.target.elements);

    if (isValid) {
      const impactStatementParams = {
        level: e.target.elements.levels.value,
        objective: e.target.elements.objective.value,
      };

      // If there is no categorized entity for this system element, in the case of an external element
      // we have to created one.
      if (!categorizedEntity) {
        const catEntparams = {
          name: `${systemElement.name} ce`,
          note: "",
          subject: systemElement.id,
          classification: "-",
          categorization: [impactStatementParams],
        };
        const categorizedEntityCreateDto = CategorizedEntityCreateDto.constructFromObject(catEntparams);
        createCategorizedEntity.mutate(
          { projectId, categorizedEntityCreateDto },
          {
            onSettled: () => {
              setModalIsOpen(false);
            },
            onError: (err) => {
              toast.error(`Creating categorization caused an error: ${err.status}`);
            },
          }
        );
      } else {
        const impactStatementCreateDto = ImpactStatementCreateDto.constructFromObject(impactStatementParams);
        addCategorization({
          catEntityId: categorizedEntity.id,
          impactStatementCreateDto,
        });
      }
    } else {
      setPostError(FORM_ERROR.missingFields);
    }
  }

  if (isSecurityObjectivesListError) {
    toast.error(<span id="categorizationAddFormToast">Error while loading security objectives</span>, {
      duration: 4000,
    });
    return <></>;
  }

  if (securityObjectivesList) {
    return (
      <div>
        <form onSubmit={handleSubmit} action="">
          <FormStyled>
            <h5>Categorization (Impact statements)</h5>
            <br />
            <div className="form-style">
              <label id="CategorizedEntityAddForm_Category">Objective*</label>
              <SelectBoxCategory arrayOfData={securityObjectivesList} item="objective" />
              <label id="CategorizedEntityAddForm_Classification">Level*</label>
              <SelectBox arrayOfData={LEVELS} item="levels" />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
            <DialogButtonStyled type="submit">Add</DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }
  return <Loading />;
};

CategorizationAddForm.propTypes = {
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  elementNameInUrl: PropTypes.string,
  setModalIsOpen: PropTypes.func,
};

export default CategorizationAddForm;
