import * as React from "react";
import { Socket } from "socket.io-client";
import { createLocalWebsocket } from "utils/websocket-utils";
import * as log from "loglevel";
import usePrevious from "hooks/usePrevious";
import { getErrorMessage } from "utils/error-message-utils";

export interface SocketConfig {
  namespace: string;
  auth: { [key: string]: any };
}

export const useSocket = ({ namespace, auth }: SocketConfig) => {
  const [socket, setSocket] = React.useState<Socket | null>(null);
  const [isConnected, setIsConnected] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>();
  const prevAuth = usePrevious(auth);

  // console.log("prevAuth", prevAuth);
  // console.log("auth", auth);

  React.useEffect(() => {
    if (auth && auth.projectId && (!prevAuth || (prevAuth && prevAuth.projectId !== auth.projectId))) {
      setError(null);
      // console.log("creating socket");
      const newSocket = createLocalWebsocket({ namespace, auth });
      setSocket((prevSocket) => {
        prevSocket?.disconnect();
        return newSocket;
      });
      setIsConnected(newSocket.connected);
    }
  }, [auth, namespace, prevAuth]);

  React.useEffect(() => {
    if (socket === null) {
      return () => {};
    }
    // console.log("useSocket useEffect");
    let timer: ReturnType<typeof setTimeout>;

    const timeout = new Promise<void>((resolve, reject) => {
      timer = setTimeout(() => {
        // console.log("timeout");
        reject(new Error("Authorization Timeout"));
      }, 4000);
    });

    const connect = new Promise<void>((resolve) => {
      // console.log("useSocket - connecting");
      socket.on("connect", () => {
        // console.log(`useSocket - connected!`);
        setIsConnected(true);
      });
      socket.on("connect_error", (err) => {
        log.debug("[Socket] useSocket - connect_error", getErrorMessage(err));
        // console.log("useSocket - connect_error", err.message);

        setIsConnected(false);
        socket.disconnect();
        setError(err);
      });
      // console.log("useSocket - done");
      resolve();
    });

    const doEffect = async () => {
      // console.log("effect called");
      try {
        await Promise.race([connect, timeout]).finally(() => clearTimeout(timer));
      } catch (err) {
        log.debug("[Socket] useSocket - err", getErrorMessage(err));
        // console.log("useSocket - error", err);
        setIsConnected(false);
        setError(err);
      }
    };

    doEffect();

    return () => {
      socket.off("connect");
      socket.off("connect_error");
      socket.off("disconnect");
    };
  }, [socket]);

  return {
    socket,
    isConnected,
    error,
  };
};
