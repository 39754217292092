import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./capability-query-key-factory";

interface IDeleteCapability {
  capabilityId: string;
}

/**
 *  Deletes a capability from the system
 *
 * @param {string} capabilityId - uuid of capability to delete
 */
export const deleteCapability = ({ capabilityId }: IDeleteCapability) => {
  return Brm.capabilityApi.deleteCapability(capabilityId);
};

interface IDeleteCapabilityQueryProps {
  options?: MutationOption<typeof deleteCapability>;
}

/**
 * Custom hook to delete an existing capability
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteCapability = ({ options }: IDeleteCapabilityQueryProps = {}) => {
  return ReactQuery.useMutation({
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.capabilityKeys.all);
    },
    mutationFn: deleteCapability,
  });
};
