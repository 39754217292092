import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

interface IDeleteResourceProps {
  resourceId: string;
}

interface IUseDeteleResourceProps {
  options: {};
}
/**
 *  Deletes a person from the system
 *
 * @param {string} resourceId - uuid of resource
 */
export const deleteResource = ({ resourceId }: IDeleteResourceProps) => {
  return Brm.resourceApi.deleteResource(resourceId);
};

/**
 * Custom hook to delete an existing resource
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteResource = ({ options = {} }: IUseDeteleResourceProps) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: deleteResource,
  });
};
