import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./installation-query-key-factory";

/**
 * Gets the installation user count
 */
export const getInstallationUserCount = ({ installationId }: { installationId: string | null }) => {
  return installationId === null
    ? Promise.reject(new Error("Invalid id"))
    : Brm.installationApi.getInstallationUserCount(installationId);
};

interface IInstallationProps {
  installationId: string | null;
  options?: QueryOption<typeof getInstallationUserCount>;
}

/**
 * Custom hook for getting the installation user count
 *
 * @param {string} installationId - uuid of installation
 * @param {object} config - react-query configuration
 * @returns
 */
export const useInstallationUserCount = ({ installationId, options = {} }: IInstallationProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.installationKeys.userCount(installationId),
    queryFn: () => getInstallationUserCount({ installationId }),
  });
};
