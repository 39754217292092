import * as YFiles from "yfiles";

import { useContext } from "react";
import { ThemeContext } from "styled-components";

import { ReactComponent as OrganicLayout } from "assets/icons/studio91/sidebars-toolbars/layout-3.svg";
import { ReactComponent as CircularLayout } from "assets/icons/studio91/sidebars-toolbars/layout-1.svg";
import { ReactComponent as HierarchicalLayout } from "assets/icons/studio91/sidebars-toolbars/layout-5.svg";
import { ReactComponent as OrthogaonalLayout } from "assets/icons/studio91/sidebars-toolbars/layout-4.svg";
import { ReactComponent as RadialLayout } from "assets/icons/studio91/sidebars-toolbars/layout-2.svg";

import { LayoutType, DIAGRAM_EVENT } from "features/diagram-constants";

import messenger from "services/messenger";
import { ToolbarButton } from "../ToolbarButton";

interface ILayoutButtonsGroup {
  organicLayout: () => void;
  hierarchicalLayout?: null | ((orientation: YFiles.LayoutOrientation) => void);
  orthogonalLayout?: null | ((orientation: YFiles.LayoutOrientation) => void);
  radialLayout?: null | (() => void);
  circularLayout?: null | (() => void);
  currentLayout: LayoutType;
}

export const LayoutButtonsGroup = ({
  organicLayout,
  hierarchicalLayout = null,
  orthogonalLayout = null,
  radialLayout = null,
  circularLayout = null,
  currentLayout,
}: ILayoutButtonsGroup) => {
  const theme = useContext(ThemeContext);

  const {
    colors: { buttonPrimary: buttonFill },
    // TODO the standard sizes from the theme look terrible... might have to revisit for now override with smaller icons
    // sizes: { standaloneIconWidth: iconWidth, standaloneIconWidth: iconHeight },
  } = theme;
  const iconWidth = "16px";
  const iconHeight = "16px";

  const organicLayoutIcon = <OrganicLayout fill={buttonFill} width={iconWidth} height={iconHeight} />;
  const circlularLayoutIcon = <CircularLayout fill={buttonFill} width={iconWidth} height={iconHeight} />;
  const hierarchicalLayoutIcon = <HierarchicalLayout fill={buttonFill} width={iconWidth} height={iconHeight} />;
  const hierarchicalLayoutIcon2 = (
    <HierarchicalLayout
      style={{ transform: "rotate(270deg)" }}
      fill={buttonFill}
      width={iconWidth}
      height={iconHeight}
    />
  );
  const orthogaonalLayoutIcon = <OrthogaonalLayout fill={buttonFill} width={iconWidth} height={iconHeight} />;
  const orthogaonalLayoutIcon2 = (
    <OrthogaonalLayout
      style={{ transform: "rotate(270deg)" }}
      fill={buttonFill}
      width={iconWidth}
      height={iconHeight}
    />
  );
  const radialLayoutIcon = <RadialLayout fill={buttonFill} width={iconWidth} height={iconHeight} />;

  return (
    <>
      <ToolbarButton
        icon={organicLayoutIcon}
        tooltip="Organic Layout"
        onClick={() => {
          messenger.dispatch(DIAGRAM_EVENT.organicLayout);
          organicLayout();
        }}
        toggled={currentLayout === LayoutType.ORGANIC}
      />
      {circularLayout ? (
        <ToolbarButton
          icon={circlularLayoutIcon}
          tooltip="Circle Layout"
          onClick={() => {
            messenger.dispatch(DIAGRAM_EVENT.circularLayout);
            circularLayout();
          }}
          toggled={currentLayout === LayoutType.CIRCULAR}
        />
      ) : null}
      {radialLayout ? (
        <ToolbarButton
          icon={radialLayoutIcon}
          tooltip="Radial Layout"
          onClick={() => {
            messenger.dispatch(DIAGRAM_EVENT.radialLayout);
            radialLayout();
          }}
          toggled={currentLayout === LayoutType.RADIAL}
        />
      ) : null}
      {hierarchicalLayout ? (
        <ToolbarButton
          icon={hierarchicalLayoutIcon}
          tooltip="Hierarchical Layout (vertical)"
          onClick={() => {
            messenger.dispatch(DIAGRAM_EVENT.hierarchicalLayout);
            hierarchicalLayout(YFiles.LayoutOrientation.TOP_TO_BOTTOM);
          }}
          toggled={currentLayout === LayoutType.HIERARCHICAL}
        />
      ) : null}
      {hierarchicalLayout ? (
        <ToolbarButton
          icon={hierarchicalLayoutIcon2}
          tooltip="Hierarchical Layout (horizontal)"
          onClick={() => {
            messenger.dispatch(DIAGRAM_EVENT.hierarchicalLayout_left_to_right);
            hierarchicalLayout(YFiles.LayoutOrientation.LEFT_TO_RIGHT);
          }}
          toggled={currentLayout === LayoutType.HIERARCHICAL_LEFT_TO_RIGHT}
        />
      ) : null}
      {orthogonalLayout ? (
        <ToolbarButton
          icon={orthogaonalLayoutIcon}
          tooltip="Orthogonal Layout (vertical)"
          onClick={() => {
            messenger.dispatch(DIAGRAM_EVENT.orthogonalLayout);
            orthogonalLayout(YFiles.LayoutOrientation.TOP_TO_BOTTOM);
          }}
          toggled={currentLayout === LayoutType.ORTHOGONAL}
        />
      ) : null}
      {orthogonalLayout ? (
        <ToolbarButton
          icon={orthogaonalLayoutIcon2}
          tooltip="Orthogonal Layout (horizontal)"
          onClick={() => {
            messenger.dispatch(DIAGRAM_EVENT.orthogonalLayout_left_to_right);
            orthogonalLayout(YFiles.LayoutOrientation.LEFT_TO_RIGHT);
          }}
          toggled={currentLayout === LayoutType.ORTHOGONAL_LEFT_TO_RIGHT}
        />
      ) : null}
    </>
  );
};
