import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./attacker-query-key-factory";

export interface ISetAttackerCategory {
  attackerId: string;
  category: string;
}

/**
 * sets category of the given attacker
 * @param {string} attackerId - uuid of the attacker
 * @param {string} category - string value of attacker category
 *
 */
export const setAttackerCategory = ({ attackerId, category }: ISetAttackerCategory) => {
  return Brm.attackerApi.setAttackerCategory(attackerId, { body: category });
};

export interface IUseSetAttackerCategory {
  options?: MutationOption<typeof setAttackerCategory>;
}

/**
 * Custom hook to change the category of a existing Attacker
 * @param {object} options hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerCategory = ({ options }: IUseSetAttackerCategory = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.attackerKeys.all);
    },
    ...options,
    mutationFn: setAttackerCategory,
  });
};
