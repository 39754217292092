import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { MutationOption } from "libs/react-query";

interface IExportKnowledgebase {
  kbId: string;
  format: string;
}

/**
 * Export a knowledgebase.
 *
 * @param {string} kbId - kb id
 * @param {format} format - format of the export
 *
 */
export const exportKnowledgebase = async ({ kbId, format }: IExportKnowledgebase) => {
  return Brm.knowledgebaseApi.kbExport(kbId, format);
};

export interface IUseExportKnowledgebase {
  options?: MutationOption<typeof exportKnowledgebase>;
}

/**
 * Custom hook to export a knowledgebase.
 *
 * @param {string} kbId - kb id
 * @param {format} format - format of the export
 * @param {object} options - react-query options
 */
export const useExportKnowledgebase = ({ options }: IUseExportKnowledgebase) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: exportKnowledgebase,
  });
};
