import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

/**
 * Gets a project's name
 * @param {string} projectId - uuid of project
 */
export const getProjectName = ({ projectId }: { projectId: string | null }) => {
  return Brm.projectApi.getProjectName(projectId);
};

interface IProjectNameProps {
  projectId: string | null;
  options?: QueryOption<typeof getProjectName>;
}

/**
 * Custom hook for getting the name of a project
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query options
 * @returns getProjectName
 */
export const useProjectName = ({ projectId, options = {} }: IProjectNameProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.name(projectId),
    queryFn: () => getProjectName({ projectId }),
  });
};
