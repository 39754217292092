import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * sets means of the selected attacker
 * @param {string} id - uuid of the attacker
 * @param {string} means - string value of available means
 *
 */
export const setAttackerMeans = ({ id, means }) => {
  return Brm.attackerApi.setAttackerAvailableMeans(id, { body: means });
};

/**
 * Custom hook to change the means of a existing Attacker
 * @param {object} config hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerMeans = ({ config = {} }) => {
  return ReactQuery.useMutation({
    ...config,
    mutationFn: setAttackerMeans,
  });
};
