import * as React from "react";
// import PropTypes from "prop-types";
import { ReactComponent as DragBarIcon } from "assets/icons/studio91/function/sidebar-toggle.svg";
import { ThemeContext } from "styled-components";
import * as S from "./ResizeControl.styles";

interface IResizeControlProps {
  location?: string;
  onStart?: (event: React.MouseEvent) => void;
  onDrag?: (event: React.MouseEvent) => void;
  onStop?: (event: React.MouseEvent) => void;
  onDoubleClick?: (event: React.MouseEvent) => void;
  show?: boolean;
  onChevronClick?: (event?: React.MouseEvent) => void;
}

export const ResizeControl = ({
  location = "left",
  onStart = () => {},
  onDrag = () => {},
  onStop = () => {},
  onDoubleClick = () => {},
  show = false,
  onChevronClick = () => {},
}: IResizeControlProps) => {
  const theme = React.useContext(ThemeContext);
  const [dragging, setDragging] = React.useState(() => false);

  const onMouseDown = React.useCallback(
    (e) => {
      onStart(e);
      setDragging(true);
      document.body.style.cursor = "ew-resize";
      e.stopPropagation();
      e.preventDefault();
    },
    [onStart, setDragging]
  );

  const onMouseUp = React.useCallback(
    (e) => {
      if (dragging) {
        document.body.style.cursor = "auto";
        setDragging(false);
        onStop(e);
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [dragging, onStop, setDragging]
  );

  const onMouseMove = React.useCallback(
    (e) => {
      if (dragging) {
        onDrag(e);
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [dragging, onDrag]
  );

  const handleDoubleClick = (e: React.MouseEvent) => {
    onDoubleClick(e);
  };

  React.useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [onMouseMove, onMouseUp]);

  function returnChevron() {
    if ((location === "left" && show) || (location === "right" && !show)) {
      return (
        <S.ExpandButton id={`${location}_leftChevron_dragBarButton`} onClick={onChevronClick}>
          <S.NavigationChevronLeft id="leftChevron" />
        </S.ExpandButton>
      );
    }
    return (
      <S.ExpandButton id={`${location}_rightChevron_dragBarButton`} onClick={onChevronClick}>
        <S.NavigationChevronRight id="rightChevron" />
      </S.ExpandButton>
    );
  }

  return (
    <S.ResizeControlContainer location={location} onMouseDown={onMouseDown} onDoubleClick={handleDoubleClick}>
      <S.ResizeControlGrabArea location={location}>
        {returnChevron()}
        <DragBarIcon title="dragbar" fill={theme.colors.primaryButton} width="4px" />
      </S.ResizeControlGrabArea>
    </S.ResizeControlContainer>
  );
};
