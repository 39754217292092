import ReactModal from "react-modal";
import Cancel from "assets/icons/cancelModal.svg";
import * as S from "./KnowledgebaseDiffModal.styles";
import { KnowledgebaseDiffForm } from "../../forms/KnowledgebaseDiffForm";

interface IKnowledgebaseDiffModalProps {
  isVisible: boolean;
  onClosing: () => void;
}

export const KnowledgebaseDiffModal = ({ isVisible, onClosing }: IKnowledgebaseDiffModalProps) => {
  const handleClose = () => {
    onClosing();
  };

  return (
    <ReactModal isOpen={isVisible} ariaHideApp={false} onRequestClose={handleClose} style={S.rootModalStyle}>
      <S.Header>
        <S.ModalTitle>Knowledgebase Diff Report</S.ModalTitle>
        <S.HeaderButtonsDiv>
          <S.Close onClick={handleClose}>
            <S.CloseIcon src={Cancel} />
          </S.Close>
        </S.HeaderButtonsDiv>
      </S.Header>
      <S.Body>
        <KnowledgebaseDiffForm onClose={handleClose} />
      </S.Body>
    </ReactModal>
  );
};
