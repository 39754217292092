// Lib
import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
// Utils
import errorUtils from "utils/error-utils";
// Services
import { createColumnNameDetails } from "brm/tables/services/column/columnFactory";
import { controlCatalogApi } from "services/brm/global-control-service";
// Styles
import * as S from "brm/styles/details-table.styles";
import { BrmError, DetailTableType } from "features/brm";
import { RoutePath } from "routes/route-paths";

const BaselineTable = ({ elementNameInUrl, selectedElement }) => {
  const columns = useMemo(() => [createColumnNameDetails(RoutePath.StandardBaseline.replace(":id", ""))], []);

  const [baselineData, setBaselineData] = useState([]);

  useEffect(() => {
    function getElementRequest() {
      switch (elementNameInUrl) {
        case DetailTableType.CONTROL_CATALOGS.key: {
          return controlCatalogApi.getControlCatalogBaselineWithHttpInfo(selectedElement.id);
        }
        default: {
          break;
        }
      }
      throw new BrmError(`Unsupported element type ${elementNameInUrl}`);
    }

    async function getData() {
      try {
        const res = await getElementRequest();
        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setBaselineData(res.data);
        }
      } catch (err) {
        console.error("err in get Baseline deails : ", err);
      }
    }
    getData();
  }, [elementNameInUrl, selectedElement.id]);

  return (
    <S.DetailsContainer id="BaselineTable_detailsPanel">
      <BrmDetailTable
        data={baselineData}
        columns={columns}
        customProps={{ id: "BaselineTable_detailsTable" }}
        showRowSelect={false}
      />
    </S.DetailsContainer>
  );
};

BaselineTable.propTypes = {
  selectedElement: PropTypes.object.isRequired,
  elementNameInUrl: PropTypes.string.isRequired,
};

export default BaselineTable;
