import * as React from "react";
import * as Recoil from "recoil";
import toast from "react-hot-toast";
import { queryClient } from "libs/react-query";
import { ErrorBanner, LoadingSpinner } from "components/elements";
import PropTypes from "prop-types";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable, RetainStringColumnFilter } from "brm/tables/BrmTables";

// State
import {
  calibratedStrengthSelectedFilterState,
  complianceFilterState,
  controlFamilyFilterState,
  controlTypeFilterState,
  filteredAllocatedControlState,
  nodeSelectedFilterState,
  sctmIdState,
  strengthSelectedFilterState,
  vulnerabilityCategorySelectedFilterState,
} from "atoms/atoms-mitigation";
import { controlCatalogIdState } from "atoms/atoms-global-control";
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";

// Misc
import * as BrmGql from "generated/graphql";
import {
  createColumnName,
  createColumnMappedNoEdit,
  createColumnBooleanWithVariantMapped,
  createColumnCalibratedStrength,
} from "brm/tables/services/column/columnFactory";
import { MitigationApi, useVariant, SystemApi, useRoles, GlobalControlApi, RiskApi } from "features/brm";
import { TYPE, COMMON } from "constants/brm";
import NavListIconStyled from "components/elements/NavListIconStyled";
import FilterIcon from "assets/icons/filter.svg";
import Select from "react-select";
import { calibrationStyle } from "components/elements/ReactSelectCustomStyles";
import getReactSelectArray from "utils/react-select-array";
import { useGetAllocatedControls } from "../../../hooks/getAllocatedControls/getAllocatedControls";
import { getSelectArray } from "../../../utils";
import { strengthLevels, MAXIMUM_SELECTED_COUNT } from "../../../constants";
// Modals
import { SetCalibratedStrengthModal } from "../../modals/SetCalibratedStrengthModal";
import { ClearCalibratedStrengthModal } from "../../modals/ClearCalibratedStrengthModal";
import { SetTargetModal } from "../../modals/SetTargetModal";
import { SetVulCatModal } from "../../modals/SetVulCatModal";
// Styles
import S from "./AllocatedControlTable.styles";
import { useControlTypes, useSystemObjects } from "../../../api";

const complianceOptions = [
  { value: true, label: COMMON.yes },
  { value: false, label: COMMON.no },
];

const maximumExceededText = `* You have exceeded the maximum selected number of 20000 controls. 
  Please use filters to narrow down your selection before modifying the calibrated strength or compliance. *`;

export const AllocatedControlTable = ({ selectedRows, setSelectedRows }) => {
  // Global State
  const { isRiskAnalyst, userId } = useRoles();
  const { variantId } = useVariant();
  const sctmId = Recoil.useRecoilValue(sctmIdState);
  const [controlCatalogId, setControlCatalogId] = Recoil.useRecoilState(controlCatalogIdState);
  const projectId = Recoil.useRecoilValue(projectIdState);
  const [selectedInstance, setSelectedInstance] = Recoil.useRecoilState(selectedInstanceState);
  const [selectedVulnerabilityCat, setSelectedvulnerabilityCat] = Recoil.useRecoilState(
    vulnerabilityCategorySelectedFilterState
  );
  const [selectedStrengthLevels, setSelectedStrengthLevels] = Recoil.useRecoilState(strengthSelectedFilterState);
  const [selectedCalibratedStrengthLevels, setSelectedCalibratedStrengthLevels] = Recoil.useRecoilState(
    calibratedStrengthSelectedFilterState
  );
  const [selectedNodes, setSelectedNodes] = Recoil.useRecoilState(nodeSelectedFilterState);
  const [selectedCompliance, setSelectedCompliance] = Recoil.useRecoilState(complianceFilterState);
  const [selectedControlType, setSelectedControlType] = Recoil.useRecoilState(controlTypeFilterState);
  const [selectedControlFamily, setSelectedControlFamily] = Recoil.useRecoilState(controlFamilyFilterState);
  const filteredAllocatedControls = Recoil.useRecoilValue(filteredAllocatedControlState);

  // Local State
  const [showFilters, setShowFilters] = React.useState(false);
  const [calibStrengthModalIsOpen, setCalibStrengthModalIsOpen] = React.useState(false);
  const [clearCalibStrengthModalIsOpen, setClearCalibStrengthModalIsOpen] = React.useState(false);
  const [vulCatModalIsOpen, setVulCatModalIsOpen] = React.useState(false);
  const [targetModalIsOpen, setTargetModalIsOpen] = React.useState(false);
  const [disableButtons, setDisableButtons] = React.useState(true);
  const [showMaximumExceeded, setShowMaximumExceeded] = React.useState(false);

  // Instance variables
  const updatedName = React.useRef("");
  const updatedCalibratedStrength = React.useRef("");

  // Queries
  const { data: allocatedControlsData, isError: allocatedControlsIsError } = useGetAllocatedControls(sctmId);
  const { data: systemObjectsData, isError: systemObjectsIsError } = useSystemObjects(projectId);
  const { data: vulnerabilityCategoryData, isError: vulnerabilityCategoryIsError } = RiskApi.useVulnerabilityCategories(
    {
      projectId,
      options: { enabled: isRiskAnalyst },
    }
  );
  const { data: nodeData, isError: nodeDataIsError } = SystemApi.useNodes({
    projectId,
    options: { enabled: isRiskAnalyst },
  });
  const { data: controlFamilyData, isError: controlFamilyIsError } = GlobalControlApi.useControlFamilies({
    controlCatalogId,
    options: { enabled: !!controlCatalogId && isRiskAnalyst },
  });

  const { data: controlTypesData, isError: controlTypesDataIsError } = useControlTypes(selectedControlFamily);

  // const allocatedControlsData = undefined;
  // const allocatedControlsIsError = undefined;
  // const systemObjectsData = undefined;
  // const systemObjectsIsError = undefined;
  // const nodeData = undefined;
  // const nodeDataIsError = undefined;
  // const controlFamilyData = undefined;
  // const controlFamilyIsError = undefined;
  // const controlTypesData = undefined;
  // const controlTypesDataIsError = undefined;
  // const vulnerabilityCategoryData = undefined;
  // const vulnerabilityCategoryIsError = undefined;

  // Mutations
  const { mutate: setAllAllocatedControlsIsCompliant } = BrmGql.useSetAllAllocatedControlsIsCompliantMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(MitigationApi.allocatedControlsGqlKey.all);
    },
    onError: () => {
      toast.error("Error ocurred upon modifying allocated controls compliance", {
        duration: 4000,
      });
    },
  });

  // console.log("allocatedControlsData", allocatedControlsData);

  // controlCatalogIdState must be set according to the ControlCatalog associated to the project, not the ControlCatalog picked by the Control Coordinator in the Control Catalog Selection dropdown.
  const { isError: isCatalogProjDataError } = BrmGql.useGetProjectCatalogConfigurationQuery(
    { userId },
    {
      onSuccess: (data) => {
        const projData = data.projectFindAllByUser.filter((project) => project.id === projectId);
        if (projData && Array.isArray(projData) && projData.length) {
          setControlCatalogId(projData[0].controlCatalog.id);
        }
      },
      enabled: !!userId,
    }
  );

  React.useEffect(() => {
    if (selectedRows.length > 0 && selectedRows.length < MAXIMUM_SELECTED_COUNT) {
      setDisableButtons(false);
      setShowMaximumExceeded(false);
    } else if (selectedRows.length !== 0) {
      setDisableButtons(true);
      setShowMaximumExceeded(true);
    } else {
      setDisableButtons(true);
      setShowMaximumExceeded(false);
    }
  }, [selectedRows]);

  const columns = React.useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "allocatedControl", null, {
        Filter: RetainStringColumnFilter,
      }),
      createColumnMappedNoEdit("vulnerabilitycategory"),
      createColumnMappedNoEdit("controltype"),
      createColumnMappedNoEdit("controlFamily"),
      createColumnMappedNoEdit("strength"),
      createColumnCalibratedStrength(selectedInstance.id, strengthLevels, false, updatedCalibratedStrength),
      createColumnBooleanWithVariantMapped("isCompliant", selectedInstance.id, false, ""),
      createColumnMappedNoEdit("anobjectType"),
      createColumnMappedNoEdit("anobject"),
    ],
    [selectedInstance.id, setSelectedInstance]
  );

  const handleVulCatSelectChange = (selectedItems = []) => {
    const items = selectedItems;
    setSelectedvulnerabilityCat(items);
  };

  const handleStrengthSelectChange = (selectedItems = []) => {
    const items = selectedItems;
    setSelectedStrengthLevels(items);
  };

  const handleCalibratedStrengthSelectChange = (selectedItems = []) => {
    const items = selectedItems;
    setSelectedCalibratedStrengthLevels(items);
  };

  const handleNodeSelectChange = (selectedItems = []) => {
    const items = selectedItems;
    setSelectedNodes(items);
  };

  const handleComplianceSelectChange = (selectedItems = []) => {
    const items = selectedItems;
    setSelectedCompliance(items);
  };

  const handleControlFamilySelectChange = (selectedItems = []) => {
    const items = selectedItems;
    if (selectedControlType.length > 0) {
      setSelectedControlType([]);
    }
    setSelectedControlFamily(items);
  };

  const handleControlTypeSelectChange = (selectedItems = []) => {
    const items = selectedItems;
    setSelectedControlType(items);
  };

  const setCalibStrength = () => {
    setCalibStrengthModalIsOpen(true);
  };

  const clearCalibStrength = () => {
    setClearCalibStrengthModalIsOpen(true);
  };

  const setCompliance = () => {
    const ids = selectedRows.map((row) => row.id);
    setAllAllocatedControlsIsCompliant(
      { ids, isCompliant: true, project: projectId, variant: variantId },
      {
        onSuccess: () => {
          toast.success("Compliance set successfully", { duration: 3000 });
        },
        enabled: !!projectId && !!ids,
      }
    );
  };

  const setRemoveCompliance = () => {
    const ids = selectedRows.map((row) => row.id);
    setAllAllocatedControlsIsCompliant(
      { ids, isCompliant: false, project: projectId, variant: variantId },
      {
        onSuccess: () => {
          toast.success("Compliance removed successfully", { duration: 3000 });
        },
        enabled: !!projectId && !!ids,
      }
    );
  };

  let msg;
  if (allocatedControlsIsError) {
    msg = "Error while loading Allocated Controls.";
  }
  if (systemObjectsIsError) {
    msg = "Error while loading system objects.";
  }
  if (controlTypesDataIsError) {
    msg = "Error while loading control types.";
  }

  if (vulnerabilityCategoryIsError) {
    msg = "Error while loading vulnerability categories.";
  }
  if (nodeDataIsError) {
    msg = "Error while loading node data.";
  }
  if (controlFamilyIsError) {
    msg = "Error while loading control families.";
  }
  if (isCatalogProjDataError) {
    msg = "Error while loading project catalogs.";
  }
  if (
    allocatedControlsIsError ||
    systemObjectsIsError ||
    controlTypesDataIsError ||
    vulnerabilityCategoryIsError ||
    nodeDataIsError ||
    controlFamilyIsError ||
    isCatalogProjDataError
  ) {
    return <ErrorBanner msg={msg} />;
  }

  if (allocatedControlsData) {
    return (
      <>
        {calibStrengthModalIsOpen && (
          <SetCalibratedStrengthModal
            modalIsOpen={calibStrengthModalIsOpen}
            setModalIsOpen={setCalibStrengthModalIsOpen}
            selectedRows={selectedRows}
          />
        )}

        {clearCalibStrengthModalIsOpen && (
          <ClearCalibratedStrengthModal
            modalIsOpen={clearCalibStrengthModalIsOpen}
            setModalIsOpen={setClearCalibStrengthModalIsOpen}
            selectedRows={selectedRows}
          />
        )}

        {vulCatModalIsOpen && (
          <SetVulCatModal
            modalIsOpen={vulCatModalIsOpen}
            setModalIsOpen={setVulCatModalIsOpen}
            selectedRows={selectedRows}
            vulCategoryData={vulnerabilityCategoryData}
          />
        )}

        {targetModalIsOpen && (
          <SetTargetModal
            modalIsOpen={targetModalIsOpen}
            setModalIsOpen={setTargetModalIsOpen}
            selectedRows={selectedRows}
            systemObjectData={systemObjectsData}
          />
        )}

        <S.FilterSection>
          <div style={{ display: "flex", flex: 1 }}>
            <S.ShowFilterButton onClick={() => setShowFilters(!showFilters)}>
              <NavListIconStyled id="AllocatedControls_filters" src={FilterIcon} alt="Filter" />
              Filters
            </S.ShowFilterButton>

            <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
              <S.ControlsButtons
                id="AllocatedControls_SetComplianceButton"
                onClick={setCompliance}
                disabled={disableButtons}
              >
                Set to Compliant
              </S.ControlsButtons>
              <S.ControlsButtons
                id="AllocatedControls_RemoveComplianceButton"
                onClick={setRemoveCompliance}
                disabled={disableButtons}
              >
                Set to Non-Compliant
              </S.ControlsButtons>
              <S.ControlsButtons
                id="AllocatedControls_setCalibrationButton"
                onClick={setCalibStrength}
                disabled={disableButtons}
              >
                Set Calibrated Strength
              </S.ControlsButtons>
              <S.ControlsButtons
                id="AllocatedControls_setCalibrationButton"
                onClick={clearCalibStrength}
                disabled={disableButtons}
              >
                Clear Calibrated Strength
              </S.ControlsButtons>
            </div>
          </div>
          {showFilters ? (
            <div>
              <S.SelectDiv>
                <S.SelectLabel>
                  Vulnerability Category
                  <Select
                    isMulti
                    options={getSelectArray(vulnerabilityCategoryData)}
                    defaultValue={selectedVulnerabilityCat}
                    onChange={handleVulCatSelectChange}
                    id="AllocatedControl_vulnerabilityDropdown"
                    classNamePrefix="vulnerabilityDropdown"
                    styles={calibrationStyle}
                  />
                </S.SelectLabel>
              </S.SelectDiv>

              <S.SelectDiv>
                <S.SelectLabel>
                  Strength
                  <Select
                    isMulti
                    options={getReactSelectArray(strengthLevels)}
                    defaultValue={selectedStrengthLevels}
                    onChange={handleStrengthSelectChange}
                    id="AllocatedControl_StrengthDropdown"
                    classNamePrefix="strengthDropDown"
                    styles={calibrationStyle}
                  />
                </S.SelectLabel>
              </S.SelectDiv>

              <S.SelectDiv>
                <S.SelectLabel>
                  Calibrated Strength
                  <Select
                    isMulti
                    options={getReactSelectArray(strengthLevels)}
                    defaultValue={selectedCalibratedStrengthLevels}
                    onChange={handleCalibratedStrengthSelectChange}
                    id="AllocatedControl_CalibratedStrength"
                    classNamePrefix="calibratedStrength"
                    styles={calibrationStyle}
                  />
                </S.SelectLabel>
              </S.SelectDiv>

              <S.SelectDiv>
                <S.SelectLabel>
                  Node
                  <Select
                    isMulti
                    options={getReactSelectArray(nodeData)}
                    defaultValue={selectedNodes}
                    onChange={handleNodeSelectChange}
                    id="AllocatedControls_nodeDropdown"
                    classNamePrefix="nodeDropdown"
                    styles={calibrationStyle}
                  />
                </S.SelectLabel>
              </S.SelectDiv>

              <S.SelectDiv>
                <S.SelectLabel>
                  Compliance
                  <Select
                    isMulti
                    options={complianceOptions}
                    defaultValue={selectedCompliance}
                    onChange={handleComplianceSelectChange}
                    id="AllocatedControls_complianceDropdown"
                    classNamePrefix="complianceDropdown"
                    styles={calibrationStyle}
                  />
                </S.SelectLabel>
              </S.SelectDiv>

              <S.SelectDiv>
                <S.SelectLabel>
                  Control Family
                  <Select
                    isMulti
                    options={getReactSelectArray(controlFamilyData)}
                    defaultValue={selectedControlFamily}
                    onChange={handleControlFamilySelectChange}
                    id="AllocatedControls_controlFamilyDropdown"
                    classNamePrefix="controlFamilyDropdown"
                    styles={calibrationStyle}
                  />
                </S.SelectLabel>
              </S.SelectDiv>

              {selectedControlFamily && selectedControlFamily.length > 0 ? (
                <S.SelectDiv>
                  <S.SelectLabel>
                    Control Type
                    <Select
                      isMulti
                      options={getReactSelectArray(controlTypesData, true)}
                      defaultValue={selectedControlType}
                      onChange={handleControlTypeSelectChange}
                      id="AllocatedControls_controlTypeDropdown"
                      classNamePrefix="controlTypeDropdown"
                      styles={calibrationStyle}
                    />
                  </S.SelectLabel>
                </S.SelectDiv>
              ) : null}
            </div>
          ) : null}
          {showMaximumExceeded ? <S.MaximumExceededSpan>{maximumExceededText}</S.MaximumExceededSpan> : null}
        </S.FilterSection>
        <CustomTable>
          <BrmMainTable
            data={filteredAllocatedControls}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "AllocatedControlListTable_table" }}
            elementName={TYPE.allocatedControl}
          />
        </CustomTable>
      </>
    );
  }

  if (!sctmId) {
    return (
      <div style={{ textAlign: "center", paddingTop: "50px" }}>
        <h3>Select a user defined variant to view Allocated Controls</h3>
      </div>
    );
  }

  return <LoadingSpinner />;
};

AllocatedControlTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
};
