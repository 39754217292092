import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./threat-catalog-query-key-factory";

interface ICreateThreatCatalog {
  threatCatalogCreateDto: any;
}

/**
 *
 * @param {ThreatCatalogCreateDto} threatCatalogCreateDto creation dto
 * @returns id of created catalog
 */
export const createThreatCatalog = ({ threatCatalogCreateDto }: ICreateThreatCatalog) => {
  return Brm.threatCatalogApi.createThreatCatalog({ threatCatalogCreateDto });
};

export interface IUseCreateThreatCatalog {
  options?: MutationOption<typeof createThreatCatalog>;
}

/**
 * Custom hook to create a new exchange.
 *
 * @param {object} config - react-query mutation configuration
 * @returns react-query for useCreateThreatCatalog
 */
export const useCreateThreatCatalog = ({ options }: IUseCreateThreatCatalog = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.threatCatalogKeys.all);
    },
    ...options,
    mutationFn: createThreatCatalog,
  });
};
