import * as React from "react";
import ReactSelect from "react-select";
import { AdminApi } from "features/brm";
import { LoadingSpinner } from "components/elements";
import { IOption } from "types";
import { createOptionsFromProps } from "utils/react-select-utils";
import * as S from "./UserSapPanel.styled";

interface IUserSapPanel {
  userId: string;
}

export const UserSapPanel = ({ userId }: IUserSapPanel) => {
  const { data: organization } = AdminApi.useUserPrimaryOrganization({ userId });

  const orgId = organization?.id || "";

  const { data: sapPersons } = AdminApi.useGetSapPersons({
    organizationId: orgId,
    options: {
      select: (data) =>
        data.map((d: any) => {
          return { label: `${d.namefirst} ${d.namemiddle} ${d.namelast}`, value: d.id };
        }),
    },
  });
  const { data: currentSapUserBinding } = AdminApi.useSapUserBindings({
    userId,
    options: {
      select: (data) => (Array.isArray(data) && data.length ? data[0] : data),
    },
  });

  const { data: userSapProgramOptions } = AdminApi.useGetSapPrograms({
    queryParam: {
      user: userId!,
    },
    options: {
      select: (data) => createOptionsFromProps({ src: data, value: "id", label: "programname" }),
    },
  });

  const { mutate: createSapUserBinding } = AdminApi.useCreateSapUserBinding();
  const { mutate: deleteSapUserBinding } = AdminApi.useDeleteSapUserBinding();

  const handleUserSelect = (sapPersonOption: IOption | null) => {
    if (sapPersonOption === null) {
      deleteSapUserBinding({ bindingId: currentSapUserBinding.id });
    } else {
      const createBindingDto = {
        brmuser: userId,
        sapperson: sapPersonOption.value,
      };
      createSapUserBinding({ sapUserBindingCreateDto: createBindingDto });
    }
    // setSelectedSapUser(sapPersonOption);
  };

  const allAvailableSapPersons = React.useMemo(() => {
    if (sapPersons) {
      return currentSapUserBinding?.sapperson
        ? [
            {
              label: `${currentSapUserBinding.sapperson.namefirst} ${currentSapUserBinding.sapperson.namemiddle} ${currentSapUserBinding.sapperson.namelast}`,
              value: currentSapUserBinding.sapperson.id,
            },
            ...sapPersons,
          ]
        : sapPersons;
    }
    return [];
  }, [currentSapUserBinding?.sapperson, sapPersons]);

  const personValue = React.useMemo(() => {
    if (
      !currentSapUserBinding ||
      currentSapUserBinding?.sapperson?.id === undefined ||
      !Array.isArray(allAvailableSapPersons)
    ) {
      return null;
    }
    return allAvailableSapPersons.filter((sp: any) => sp.value === currentSapUserBinding.sapperson.id);
  }, [allAvailableSapPersons, currentSapUserBinding]);

  if (sapPersons) {
    return (
      <S.PropertiesContainer>
        <S.PropertyContainer>
          <S.PropertyName>Jade User:</S.PropertyName>
          <ReactSelect
            options={allAvailableSapPersons}
            value={personValue}
            hideSelectedOptions
            isClearable
            onChange={handleUserSelect}
          />
        </S.PropertyContainer>
        <S.PropertyContainer>
          <S.PropertyName>Accessible Jade Programs:</S.PropertyName>
          {Array.isArray(userSapProgramOptions) ? (
            <ul style={{ paddingLeft: "12px" }}>
              {userSapProgramOptions.map((option: IOption) => {
                return <li key={option.value}>{option.label}</li>;
              })}
            </ul>
          ) : (
            <span>None</span>
          )}
        </S.PropertyContainer>
      </S.PropertiesContainer>
    );
  }
  return <LoadingSpinner />;
};
