import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import TREVTable from "brm/tables/details/TREVTable";
import PropTypes from "prop-types";
import { DetailTableType } from "features/brm";

const ThreatEventPatternDetails = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.THREAT_EVENT_PATTERNS.key;

  return (
    <Tabs>
      <TabList>
        <Tab>Threat Events</Tab>
      </TabList>

      <TabPanel>
        <TREVTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
    </Tabs>
  );
};

ThreatEventPatternDetails.propTypes = {
  selectedElement: PropTypes.object.isRequired,
};

export default ThreatEventPatternDetails;
