import PropTypes from "prop-types";
import { RoutePath } from "routes/route-paths";
import { LinkStyles } from "features/brm";
import { NO_VALUE } from "brm/tables/BrmTables";
import EditField from "./EditField";

const pathMap = new Map([
  ["users", RoutePath.User],
  ["projects", RoutePath.Project],
  ["organizations", RoutePath.Organization],
  ["installation", RoutePath.Installation],
  ["categorizationEntity", RoutePath.CategorizedEntity],
  ["classificationCategory", RoutePath.ClassificationCategory],
  ["securityObjectives", RoutePath.SecurityObjective],
  ["accessPointCategory", RoutePath.AccessPointCategory],
  ["accessPoint", RoutePath.AccessPoint],
  ["attackSurface", RoutePath.AttackSurface],
  ["attackSurfaceCategory", RoutePath.AttackSurfaceCategory],
  ["attackVectorCategory", RoutePath.AttackVectorCategory],
  ["attackVector", RoutePath.AttackVector],
  ["entryPoint", RoutePath.EntryPoint],
  ["entryPointCategory", RoutePath.EntryPointCategory],
  ["systemAssetCategory", RoutePath.SystemAssetCategory],
  ["systemAsset", RoutePath.SystemAsset],
  ["systemAssetType", RoutePath.SystemAssetType],
  ["controlCatalog", RoutePath.ControlCatalog],
  ["controlFamily", RoutePath.ControlFamily],
  ["controlType", RoutePath.ControlType],
  ["standardBaseline", RoutePath.StandardBaseline],
  ["allocatedControl", RoutePath.AllocatedControl],
  ["sctm", RoutePath.Sctm],
  ["variant", RoutePath.Variant],
  ["harm", RoutePath.Harm],
  ["undesiredEvent", RoutePath.UndesiredEvent],
  ["activity", RoutePath.Activity],
  ["bus", RoutePath.Bus],
  ["structuralCategory", RoutePath.StructuralCategory],
  ["elementCategory", RoutePath.ElementCategory],
  ["node", RoutePath.Node],
  ["link", RoutePath.Link],
  ["exchange", RoutePath.Exchange],
  ["datatype", RoutePath.Datatype],
  ["person", RoutePath.Person],
  ["resource", RoutePath.Resource],
  ["dataflow", RoutePath.Dataflow],
  ["capability", RoutePath.Capability],
  ["mission", RoutePath.Mission],
  ["vulnerability", RoutePath.Vulnerability],
  ["vulnerabilityCategory", RoutePath.VulnerabilityCategory],
  ["asset", RoutePath.Asset],
  ["assetCategory", RoutePath.AssetCategory],
  ["attackerCategory", RoutePath.AttackerCategory],
  ["attacker", RoutePath.Attacker],
  ["risk", RoutePath.Risk],
  ["riskCategory", RoutePath.RiskCategory],
  ["threatEvent", RoutePath.ThreatEvent],
  ["threatEventCategory", RoutePath.ThreatEventCategory],
  ["threatEventPattern", RoutePath.ThreatEventPattern],
  ["kb", RoutePath.Knowledgebase],
  ["threatTier", RoutePath.ThreatTier],
  ["threatCatalog", RoutePath.ThreatCatalog],
  ["threatGroup", RoutePath.ThreatGroup],
  ["ttp", RoutePath.Ttp],
  ["system", RoutePath.System],
  ["attackKind", RoutePath.AttackKind],
  ["attackMechanism", RoutePath.AttackMechanism],
  ["attackTactic", RoutePath.AttackTactic],
  ["attack", RoutePath.Attack],
  ["modules", RoutePath.Module],
  ["reports", RoutePath.Reports],
]);

const EditNameCell = ({
  selectedRowId,
  elementId,
  field,
  original,
  handleSelect,
  editMode,
  setter,
  path,
  tab = "",
  checkNameUniqueness,
}) => {
  if (field === NO_VALUE) {
    return <span title={field}>{field}</span>;
  }

  if (editMode && selectedRowId === elementId) {
    return <EditField field={field} updateData={setter} checkNameUniqueness={checkNameUniqueness} />;
  }

  const onClick = () => {
    if (handleSelect) {
      handleSelect(original);
    }
  };

  let toObj = pathMap.has(path) ? pathMap.get(path).replace(":id", elementId) : "#";
  if (tab) {
    toObj += tab;
  }

  return (
    <>
      {toObj !== "#" ? (
        <>
          <LinkStyles.CleanLink id={`hrefTo${field}`} to={toObj} onClick={onClick}>
            <span title={field}>{field}</span>
          </LinkStyles.CleanLink>
        </>
      ) : (
        <>
          <span title={field}>{field}</span>
        </>
      )}
    </>
  );
};

EditNameCell.propTypes = {
  editMode: PropTypes.any,
  elementId: PropTypes.any,
  field: PropTypes.any,
  handleSelect: PropTypes.func,
  original: PropTypes.any,
  path: PropTypes.any,
  tab: PropTypes.string,
  selectedRowId: PropTypes.any,
  setter: PropTypes.any,
  checkNameUniqueness: PropTypes.func,
};

export default EditNameCell;
