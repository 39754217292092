import { useMutation, useQueryClient } from "@tanstack/react-query";
import { projectApi } from "services/brm";
import { AdminApi } from "features/brm";

// TODO: Move this hook to src\features\brm\hooks
const useProjectRename = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ projectId, name }) => {
      return projectApi.setProjectName(projectId, { body: name });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(AdminApi.projectKeys.all);
      },
    }
  );
};

export default useProjectRename;
