import { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
// Components
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { AddButton, DeleteButton, ExportButton, SystemApi } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";

import ContentLayout from "brm/layout/Content/ContentLayout";

// State
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
// Hooks
import { useModal } from "hooks/";
// Styles
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
// Constants
import { TYPE } from "constants/brm";
// Local

import { useRoles } from "features/brm/hooks/useRoles";
import toast from "react-hot-toast";
import SystemAssetTable from "./tables/SystemAssetTable";
import SystemAssetAddForm from "./forms/SystemAssetAddForm";

function SystemAsset() {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const queryClient = useQueryClient();
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  const { mutateAsync: deleteSystemAsset } = SystemApi.useDeleteSystemAsset();

  useEffect(() => {
    async function multiDeleteApiCalls() {
      try {
        await Promise.all(selectedRows.map((each) => deleteSystemAsset({ systemAssetId: each.id })));
        setConfirmDelete(false);
        setDeleteModalIsOpen(false);
        setSelectedInstance({});
      } catch (err) {
        toast.error(`Error occured while deleting system asset ${err?.message}`);
      }
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteSystemAsset, projectId, queryClient, selectedRows, setSelectedInstance]);

  const addSystemAsset = () => {
    setModalIsOpen(true);
  };

  const systemAssetHeader = (
    <Title id="SystemAsset_title">
      System Assets
      <Buttons id="SystemAsset_buttons">
        {isRiskAnalyst || isSystemEngineer ? (
          <>
            {selectedRows?.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
            <AddButton onClick={addSystemAsset} />
          </>
        ) : (
          <br />
        )}
        <ExportButton onClick={() => toggle()} disableExport={disableExport} />
      </Buttons>
    </Title>
  );

  const systemAssetMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "SystemAsset_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.systemAsset}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.systemAsset
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "SystemAsset_page",
          modalIsOpen,
          setModalIsOpen,
          <SystemAssetAddForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} />,
          TYPE.systemAsset
        )}

      <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />

      <SystemAssetTable
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
        setExportTableData={setExportTableData}
      />
    </>
  );

  return <ContentLayout pageId="SystemAsset_page" header={systemAssetHeader} main={systemAssetMain} />;
}

export default SystemAsset;
