const convertedPercent = (percent) => {
  let converted;
  if (percent === 0) {
    converted = "-";
  } else if (percent < 0.001) {
    converted = 0.001;
  } else {
    converted = percent.toFixed(1);
  }

  return converted;
};

export default convertedPercent;
