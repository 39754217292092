import { NODE_TYPE_STYLE_MAP } from "../../constants";

export default function getShapeData(nodeData) {
  const { type: nodeType, category: nodeCategory } = nodeData;
  const color =
    nodeCategory === "Disclosure"
      ? NODE_TYPE_STYLE_MAP[nodeType].category[nodeCategory].color
      : NODE_TYPE_STYLE_MAP[nodeType].color;

  const { shape } = NODE_TYPE_STYLE_MAP[nodeType];

  return { color, shape };
}
