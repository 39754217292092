import { RoutePath } from "routes/route-paths";
import { DemoContent as videoList } from "features/help/constants";
import { Separator } from "components/elements/NavigationToolbar";
import * as S from "./DemoVideos.styles";
import { DemoVideosList } from "./DemoVideosList";

export const DemoVideos = () => {
  const handleBtnOnClick = (name: string) => {
    window.open(`${RoutePath.Demo}?name=${name}`);
  };

  if (videoList && videoList.length) {
    return (
      <>
        <S.Separator>
          <Separator />
        </S.Separator>
        <S.Wrapper>
          <b>Demo Videos</b>
          <p>A list of product demo videos. Click on a link to view the video.</p>
          <DemoVideosList handleBtnOnClick={handleBtnOnClick} contentsData={videoList} className="contentList" />
        </S.Wrapper>
      </>
    );
  }

  return null;
};
