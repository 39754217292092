import styled from "styled-components/macro";

interface IDivProps {
  isDragging: boolean;
}

interface IVisibleProps {
  isDrawerVisible: boolean;
}

export const MainContainer = styled.div`
  position: relative;
  z-index: 199;
`;

export const TransitionContainer = styled.div<IDivProps>`
  position: relative;
  width: var(--rightSidebarWidth, 40px);
  margin-right: 0;
  padding-left: ${({ theme }) => `-${theme.sizes.dragBarWidth}`};
  z-index: 1;
`;

export const ContentContainer = styled.div<IDivProps>`
  position: fixed;
  top: ${({ theme }) => theme.header.height};
  left: calc(
    var(--leftPanelWidth, 0px) + var(--leftSidebarWidth, 0px) + 100vw - var(--leftPanelWidth, 0px) -
      var(--leftSidebarWidth, 0px) - var(--rightPanelWidth, 0px) - var(--rightSidebarWidth, 0px)
  );
  right: var(--rightPanelWidth, 0px);
  bottom: calc(${({ theme }) => theme.footer.height});
  background-color: #ffffff;
  width: var(--rightSidebarWidth, 0px);
`;

export const RightSidebarContainer = styled.div`
  margin-left: ${({ theme }) => theme.sizes.dragBarWidth};
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
`;

export const ContextNavigation = styled.div<IVisibleProps>`
  position: fixed;
  top: ${({ theme }) => theme.header.height};
  bottom: calc(${({ theme }) => theme.footer.height});
  right: ${(props) =>
    props.isDrawerVisible ? ({ theme }) => theme.sizes.rightPanelWidth : ({ theme }) => theme.sizes.rightSidebarWidth};
`;
