import toast from "react-hot-toast";
import { useState, useMemo, useRef, useCallback } from "react";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable, RetainStringColumnFilter } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { TYPE } from "constants/brm";

import {
  createColumnName,
  createColumnBooleanMapped,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";
import { useCanRename } from "features/system-model";
import { SystemApi } from "features/brm";

const MissionTable = ({ setSelectedRows }) => {
  const { canRename } = useCanRename();
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedIsInternal = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const { data: missions, isError: isMissionsError } = SystemApi.useMissions({
    projectId,
    options: { staleTime: 5000, enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });

  const { mutate: setMissionName } = SystemApi.useSetMissionName({
    projectId,
    options: {
      onSettled: () => {
        setSelectedInstance({
          id: selectedInstance.id,
          name: updatedName.current,
          type: selectedInstance.type,
        });
        updatedName.current = "";
      },
      onError: (err) => {
        toast.error(`Setting mission name caused an error: ${err}`);
      },
    },
  });
  const { mutate: setMissionIsInternal } = SystemApi.useSetMissionIsInternal({
    projectId,
    options: {
      onSettled: () => {
        updatedIsInternal.current = "";
      },
      onError: (err) => {
        toast.error(`Setting mission internal caused an error: ${err}`);
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedName.current !== "") {
              if (!canRename({ updatedName, elementsList: missions })) return;
              setMissionName({ missionId: selectedInstance.id, name: updatedName.current });
            }

            if (updatedIsInternal.current !== "") {
              setMissionIsInternal({ missionId: selectedInstance.id, isInternal: updatedIsInternal.current });
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [selectedInstance.id, editMode, canRename, missions, setMissionName, setMissionIsInternal]
  );

  const checkNameUniqueness = useCallback(
    (newName) => {
      const trimedNamed = newName.trim();
      return isSystemEngineer && Array.isArray(missions)
        ? !missions.map((mission) => mission.name.toLowerCase()).includes(trimedNamed.toLowerCase())
        : false;
    },
    [isSystemEngineer, missions]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnName(
          selectedInstance.id,
          setSelectedInstance,
          editMode,
          updatedName,
          "mission",
          checkNameUniqueness,
          {
            Filter: RetainStringColumnFilter,
          }
        ),
        createColumnBooleanMapped("isInternal", selectedInstance.id, editMode, updatedIsInternal),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "mission"),
      createColumnBooleanMapped("isInternal", selectedInstance.id, false, updatedIsInternal),
    ];
  }, [isSystemEngineer, selectedInstance.id, setSelectedInstance, editMode, checkNameUniqueness, createButton]);

  if (isMissionsError) {
    return <ErrorBanner msg="Error while loading Mission data." />;
  }

  if (missions) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={missions}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "MissionTable_table" }}
            elementName={TYPE.mission}
            showRowSelect={isSystemEngineer}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

MissionTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

export default MissionTable;
