import {
  AccessPointApi,
  AccessPointCategoryApi,
  AttackSurfaceApi,
  AttackSurfaceCategoryApi,
  AttackVectorApi,
  AttackVectorCategoryApi,
  BoundaryApi,
  EntryPointApi,
  EntryPointCategoryApi,
} from "@kdmanalytics/brm-system";
import BrmApiClient from "services/brm/BrmApiClient";

// const auth = BrmApiClient.instance.authentications[BEARER_AUTH];
export const accessPointApi = new AccessPointApi(BrmApiClient.instance);
export const accessPointCatApi = new AccessPointCategoryApi(BrmApiClient.instance);
export const attackSurfaceApi = new AttackSurfaceApi(BrmApiClient.instance);
export const attackSurfaceCatApi = new AttackSurfaceCategoryApi(BrmApiClient.instance);
export const attackVectorApi = new AttackVectorApi(BrmApiClient.instance);
export const attackVectorCatApi = new AttackVectorCategoryApi(BrmApiClient.instance);
export const boundaryApi = new BoundaryApi(BrmApiClient.instance);
export const entryPointApi = new EntryPointApi(BrmApiClient.instance);
export const entryPointCatApi = new EntryPointCategoryApi(BrmApiClient.instance);
