import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
// React Query
import { useQueryClient } from "@tanstack/react-query";
import ButtonCell from "components/EditComponents/ButtonCell";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { ElementFullDetails, SystemApi } from "features/brm";
import PropTypes from "prop-types";
import * as CategorizedEntityApi from "brm/system-model/categorization/api";
import { TYPE } from "constants/brm";

import {
  createColumnMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";
import { RoutePath } from "routes/route-paths";
import CategorizedEntityDetails from "../details/CategorizedEntityDetails";

function CategorizedEntityFullDetails({ onCloseClick }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [selectedRowId, setSelectedRowId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const updatedClassification = useRef("");
  const updatedSubject = useRef("");
  const queryClient = useQueryClient();
  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  const { data, error } = CategorizedEntityApi.useCategorizedEntity({ ceId: id, setSelectedElement });
  const { data: subject, isError: isSubjectError } = CategorizedEntityApi.useSystemElements({
    projectId,
    config: { enabled: !!projectId && (isRiskAnalyst || isSystemEngineer) },
  });
  const { data: classification, isError: isClassificationError } = SystemApi.useClassificationCategories({
    projectId,
    options: {
      enabled: !!projectId && (isRiskAnalyst || isSystemEngineer),
    },
  });
  const { mutate: setCategorizedEntitySubject } = CategorizedEntityApi.useSetCategorizedEntitySubject(projectId);
  const { mutate: setCategorizedEntityClassification } =
    CategorizedEntityApi.useSetCategorizedEntityClassification(projectId);

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedRowId}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedSubject.current !== "") {
              setCategorizedEntitySubject(
                {
                  id: selectedRowId,
                  sub: updatedSubject.current,
                },
                {
                  onSettled: () => {
                    updatedSubject.current = "";
                  },
                }
              );
            }

            if (updatedClassification.current !== "") {
              setCategorizedEntityClassification(
                {
                  id: selectedRowId,
                  classification: updatedClassification.current,
                },
                {
                  onSettled: () => {
                    updatedClassification.current = "";
                    // clear classified exchange query to update for system diagram
                    queryClient.invalidateQueries(["classifiedExchanges", projectId], { refetchInactive: true });
                  },
                }
              );
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [selectedRowId, editMode, setCategorizedEntitySubject, setCategorizedEntityClassification, queryClient, projectId]
  );

  const columns = useMemo(() => {
    if (isRiskAnalyst || isSystemEngineer) {
      return [
        createColumnMapped("subject", selectedRowId, subject, editMode, updatedSubject),
        createColumnCategoryMapped("classification", selectedRowId, classification, editMode, updatedClassification),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnMapped("subject", selectedRowId, subject, editMode, updatedSubject),
      createColumnCategoryMapped("classification", selectedRowId, classification, editMode, updatedClassification),
    ];
  }, [isRiskAnalyst, isSystemEngineer, selectedRowId, subject, editMode, classification, createButton]);

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(RoutePath.CategorizedEntities);
    }
  }, [projectId, navigate]);

  if (error || isClassificationError || isSubjectError) {
    return <ErrorBanner msg="Error while loading categorized entity details" />;
  }

  if (data) {
    const catEntityDetails = <CategorizedEntityDetails selectedElement={selectedElement} />;

    return (
      <ElementFullDetails
        onCloseClick={onCloseClick}
        showBackLink
        data={data}
        columns={columns}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        setSelectedRowId={setSelectedRowId}
        detailsComp={catEntityDetails}
        compName={TYPE.categorizedEntity}
      />
    );
  }

  return <Loading />;
}

CategorizedEntityFullDetails.propTypes = {
  onCloseClick: PropTypes.func,
};

export default CategorizedEntityFullDetails;
