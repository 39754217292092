import { atom, selector } from "recoil";

/**
 * Selected SCTM atom
 */
export const sctmIdState = atom<string | null>({
  key: "sctmId",
  default: null,
});

export const allocatedControlsState = atom<any[]>({
  key: "allocatedControls",
  default: [],
});

export const totalAddedControlsState = atom<number>({
  key: "totalAddedControls",
  default: 0,
});

export const isAddingAllocatedControlsState = atom<boolean>({
  key: "isAddingAllocatedControls",
  default: false,
});

export const vulnerabilityCategorySelectedFilterState = atom<any[]>({
  key: "vulnerabilityCategoryFilterState",
  default: [],
});

export const strengthSelectedFilterState = atom<any[]>({
  key: "strengthFilterState",
  default: [],
});

export const calibratedStrengthSelectedFilterState = atom<any[]>({
  key: "calibratedStrengthFilterState",
  default: [],
});

export const nodeSelectedFilterState = atom<any[]>({
  key: "nodeFilterState",
  default: [],
});

export const complianceFilterState = atom<any[]>({
  key: "complianceFilterState",
  default: [],
});

export const controlFamilyFilterState = atom<any[]>({
  key: "controlFamilyFilterState",
  default: [],
});

export const controlTypeFilterState = atom<any[]>({
  key: "controlTypeFilterState",
  default: [],
});

export const filteredAllocatedControlState = selector({
  key: "filteredAllocatedControlState",
  get: ({ get }) => {
    const vulCategoryFilter = get(vulnerabilityCategorySelectedFilterState);
    const strengthFilter = get(strengthSelectedFilterState);
    const calibratedStrengthFilter = get(calibratedStrengthSelectedFilterState);
    const nodeFilter = get(nodeSelectedFilterState);
    const complianceFilter = get(complianceFilterState);
    const controlFamilyFilter = get(controlFamilyFilterState);
    const controlTypeFilter = get(controlTypeFilterState);
    let controlsFilterdlist = get(allocatedControlsState);

    if (vulCategoryFilter.length !== 0) {
      const items = vulCategoryFilter.map((item) => item.label);
      const collectionSet = new Set(items);
      controlsFilterdlist = controlsFilterdlist.filter((each) => collectionSet.has(each.vulnerabilitycategory));
    }

    if (strengthFilter.length !== 0) {
      const items = strengthFilter.map((item) => item.label);
      const collectionSet = new Set(items);
      controlsFilterdlist = controlsFilterdlist.filter((each) => collectionSet.has(each.strength));
    }

    if (calibratedStrengthFilter.length !== 0) {
      const items = calibratedStrengthFilter.map((item) => item.label);
      const collectionSet = new Set(items);
      controlsFilterdlist = controlsFilterdlist.filter((each) => collectionSet.has(each.calibratedStrength));
    }

    if (nodeFilter.length !== 0) {
      const items = nodeFilter.map((item) => item.label);
      const collectionSet = new Set(items);

      controlsFilterdlist = controlsFilterdlist.filter((each) => collectionSet.has(each.anobject.name));
    }

    if (complianceFilter.length !== 0) {
      const items = complianceFilter.map((item) => item.value);
      const collectionSet = new Set(items);
      controlsFilterdlist = controlsFilterdlist.filter((each) => collectionSet.has(each.isCompliant));
    }

    if (controlFamilyFilter.length !== 0) {
      const items = controlFamilyFilter.map((item) => item.value);
      const collectionSet = new Set(items);
      controlsFilterdlist = controlsFilterdlist.filter((each) => collectionSet.has(each.controltype.family.id));
    }

    if (controlTypeFilter.length !== 0) {
      const items = controlTypeFilter.map((item) => item.value);

      const collectionSet = new Set(items);
      controlsFilterdlist = controlsFilterdlist.filter((each) => collectionSet.has(each.controltype.id));
    }

    return controlsFilterdlist;
  },
});
