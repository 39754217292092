import { TYPE } from "constants/brm";

function compareArray(obj1, obj2) {
  let i = obj1.length;
  // eslint-disable-next-line no-plusplus
  while (i--) {
    if (obj1[i] !== obj2[i]) {
      return false;
    }
  }
  return true;
}
export default class AttackNodeFilterState {
  constructor() {
    this.$assetCatTypes = [];
    this.$assetTypes = [];
    this.$riskCatTypes = [];
    this.$harmCatTypes = [];
    this.$targetTypes = [TYPE.node, TYPE.exchange];
    this.$trevCatTypes = [];
    this.$attackTacticTypes = [];
    this.$orphanCheck = false;
  }

  get assetCatTypes() {
    return this.$assetCatTypes;
  }

  set assetCatTypes(values) {
    this.$assetCatTypes = values;
  }

  get assetTypes() {
    return this.$assetTypes;
  }

  set assetTypes(values) {
    this.$assetTypes = values;
  }

  get riskCatTypes() {
    return this.$riskCatTypes;
  }

  set riskCatTypes(values) {
    this.$riskCatTypes = values;
  }

  get harmCatTypes() {
    return this.$harmCatTypes;
  }

  set harmCatTypes(values) {
    this.$harmCatTypes = values;
  }

  get targetTypes() {
    return this.$targetTypes;
  }

  set targetTypes(values) {
    this.$targetTypes = values;
  }

  get trevCatTypes() {
    return this.$trevCatTypes;
  }

  set trevCatTypes(values) {
    this.$trevCatTypes = values;
  }

  get attackTacticTypes() {
    return this.$attackTacticTypes;
  }

  set attackTacticTypes(values) {
    this.$attackTacticTypes = values;
  }

  get orphanCheck() {
    return this.$orphanCheck;
  }

  set orphanCheck(values) {
    this.$orphanCheck = values;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  isVisible(node, graph) {
    if (this.$orphanCheck && graph.edgesAt(node).size === 0) {
      return false;
    }

    const typeFilter =
      this.$assetCatTypes.some((assetCat) => node.tag.name === assetCat) ||
      this.$assetTypes.some((asset) => node.tag.category === asset) ||
      this.$riskCatTypes.some((riskCat) => node.tag.category === riskCat) ||
      this.$harmCatTypes.some((harmCat) => node.tag.category === harmCat) ||
      this.$targetTypes.some((type) => node.tag.type === type) ||
      this.$trevCatTypes.some((trevCat) => node.tag.category === trevCat) ||
      this.$attackTacticTypes.some((tactic) => node.tag.tactic === tactic);
    return typeFilter;
  }

  equals(other) {
    if (other === null || other === undefined || !(other instanceof AttackNodeFilterState)) {
      return false;
    }

    const isAllTypesLengthEqual =
      this.$assetCatTypes.length === other.$assetCatTypes.length &&
      this.$assetTypes.length === other.$assetTypes.length &&
      this.$riskCatTypes.length === other.$riskCatTypes.length &&
      this.$harmCatTypes.length === other.$harmCatTypes.length &&
      this.$targetTypes.length === other.$targetTypes.length &&
      this.$trevCatTypes.length === other.$trevCatTypes.length &&
      this.$attackTacticTypes.length === other.$attackTacticTypes.length;

    if (isAllTypesLengthEqual) {
      return (
        compareArray(this.$assetCatTypes, other.$assetCatTypes) &&
        compareArray(this.$assetTypes, other.$assetTypes) &&
        compareArray(this.$riskCatTypes, other.$riskCatTypes) &&
        compareArray(this.$harmCatTypes, other.$harmCatTypes) &&
        compareArray(this.$targetTypes, other.$targetTypes) &&
        compareArray(this.$trevCatTypes, other.$trevCatTypes) &&
        compareArray(this.$attackTacticTypes, other.$attackTacticTypes)
      );
    }

    return false;
  }

  /**
   * Converts the json to a new instance of AttacknodeFilterState
   *
   * @param {Object} json
   * @returns new instance of AttackNodeFilterState initialized from json
   */
  static from(json) {
    return Object.assign(new AttackNodeFilterState(), json);
  }
}
