import PropTypes from "prop-types";
import { useState, useMemo, useEffect } from "react";
import toast from "react-hot-toast";
// Utils

import {
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnNameDetails,
} from "brm/tables/services/column/columnFactory";

// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
import { AddButton, DeleteButton, SystemApi } from "features/brm";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { TYPE } from "constants/brm";

// Services
import systemModals from "components/modals/Modals";
// Styles
import * as S from "brm/styles/details-table.styles";
// Local
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { LoadingSpinner, ErrorBanner } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";
import { RoutePath } from "routes/route-paths";
import ActivityAddForm from "./forms/ActivityAddForm";

const ActivityTable = ({ elementNameInUrl, selectedElement, showAdd, showDelete = false, tableTitle }) => {
  const { isSystemEngineer } = useRoles();

  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Activity.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnBooleanNoEditMapped("isInternal"),
      createColumnBooleanNoEditMapped("isBoundary"),
      createColumnBooleanNoEditMapped("isInitial"),
      createColumnBooleanNoEditMapped("isFinal"),
      createColumnMappedNoEdit("node"),
    ],
    []
  );

  const [, setSelectedActivity] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const projectId = useRecoilValue(projectIdState);

  const { data: activityData, isError: isActivityDataError } = SystemApi.useElementActivities({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    config: { enabled: !!selectedElement?.id && !!elementNameInUrl },
  });

  const deleteActivity = SystemApi.useDeleteElementActivities();

  const addActivity = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      try {
        await Promise.all(
          selectedRows.map((each) =>
            deleteActivity.mutateAsync({
              elementId: selectedElement.id,
              elementName: elementNameInUrl,
              activityId: each.id,
            })
          )
        );
        setDeleteModalIsOpen(false);
        setSelectedRows([]);
      } catch (err) {
        toast.error(<p>Error deleting activities</p>, {});
      }
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteActivity, elementNameInUrl, selectedElement.id, selectedRows]);

  if (isActivityDataError) {
    return <ErrorBanner msg="Error while loading activities" />;
  }

  if (activityData) {
    return (
      <S.DetailsContainer id="ActivityTable_detailsPanel">
        {/* modal for adding instance */}
        {modalIsOpen &&
          systemModals.addModal(
            "ActivityTable_detailsPanel",
            modalIsOpen,
            setModalIsOpen,
            <ActivityAddForm
              setModalIsOpen={setModalIsOpen}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
            />,
            "Activity"
          )}

        {deleteModalIsOpen &&
          systemModals.deleteModal(
            "ActivityTable_detailsPanel",
            deleteModalIsOpen,
            setDeleteModalIsOpen,
            <GenericDeleteConfirmation
              elementName={TYPE.activity}
              setDeleteModalIsOpen={setDeleteModalIsOpen}
              selectedRows={selectedRows}
              setConfirmDelete={setConfirmDelete}
            />,
            TYPE.activity
          )}

        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={activityData}
              columns={columns}
              setSelectedElement={setSelectedActivity}
              setSelectedRows={setSelectedRows}
              customProps={{ id: "ActivityTable_detailsTable" }}
              showRowSelect={!isSystemEngineer}
              tableTitle={tableTitle}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            {showDelete && selectedRows.length > 0 && <DeleteButton md onClick={() => setDeleteModalIsOpen(true)} />}
            {showAdd && <AddButton md onClick={addActivity} />}
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

ActivityTable.propTypes = {
  elementNameInUrl: PropTypes.any,

  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  showAdd: PropTypes.any,
  showDelete: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default ActivityTable;
