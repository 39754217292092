import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";
import { DetailTableType } from "../../../types/DetailTableType";

export interface IDeleteElementNodesProps {
  elementId: string;
  elementName: string;
  nodeId: string;
}

interface InodeMap {
  [index: string]: (id?: string) => any;
}

/**
 * deletes node of an element
 *
 * @param {string} elementId - uuid of the element
 * @param {string} elementName - type of the element
 * @param {string} nodeId - uuid of node
 */
export const deleteElementNodes = ({ elementId, elementName, nodeId }: IDeleteElementNodesProps) => {
  const activityMap: InodeMap = {
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.removeCapabilityNode(id, nodeId),
    [DetailTableType.PERSONS.key]: (id) => Brm.personApi.removePersonNode(id, nodeId),
    [DetailTableType.RESOURCES.key]: (id) => Brm.resourceApi.removeResourceNode(id, nodeId),
  };

  if (activityMap[elementName]) {
    return activityMap[elementName](elementId);
  }

  return [];
};

export interface IUseDeleteElementNodes {
  options?: MutationOption<typeof deleteElementNodes>;
}

/**
 * Custom hook to delete nodes from an element
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteElementNodes = ({ options = {} }: IUseDeleteElementNodes = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.nodeKeys.all);
    },
    ...options,
    mutationFn: deleteElementNodes,
  });
};
