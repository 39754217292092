import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import PropTypes from "prop-types";
import { DetailTableType } from "features/brm";
import { useRoles } from "features/brm/hooks/useRoles";
import { SharedProjectsDetailsTable } from "../../tables/SharedProjectsDetailsTable";
import { AttachedUserDetailsTable } from "../../tables/AttachedUserDetailsTable";
import { UserDetailsTable } from "../../tables/UserDetailsTable";
import { ProjectDetailsTable } from "../../tables/ProjectDetailsTable";
import { OrganizationConfigurationPane } from "../../OrganizationConfigurationPanel/OrganizationConfigurationPanel";

const elementNameInUrl = DetailTableType.ORGANIZATIONS.key;

export const OrganizationDetailsTab = ({ selectedElement, setRefresh, refresh }) => {
  const { isSuperAdmin, isAdmin } = useRoles();

  return (
    <Tabs id="OrganizationDetails_tabs">
      <TabList id="OrganizationDetails_tabList">
        <Tab eventkey="OrganizationDetails_users_Tab">Users</Tab>
        <Tab eventkey="OrganizationDetails_projects_Tab">Projects</Tab>
        <Tab eventkey="OrganizationDetails_sharedProjects_Tab">Shared Projects</Tab>
        {isSuperAdmin ? <Tab eventkey="OrganizationDetails_attachedUsers_Tab">Attached Users</Tab> : null}
        {isAdmin || isSuperAdmin ? <Tab eventkey="OrganizationDetails_attachedUsers_Tab">Configuration</Tab> : null}
      </TabList>

      <TabPanel className="OrganizationDetails_UserTabPanel" eventkey="OrganizationDetails_UserTabPanel">
        <UserDetailsTable
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          showAdd={false}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      </TabPanel>
      <TabPanel className="OrganizationDetails_ProjectTabPanel" eventkey="OrganizationDetails_ProjectTabPanel">
        <ProjectDetailsTable
          showAdd={false}
          showDelete={false}
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          setRefresh={setRefresh}
          refresh={refresh}
          fromElement="organizations"
        />
      </TabPanel>
      <TabPanel className="OrganizationDetails_SharedTabPanel" eventkey="OrganizationDetails_SharedTabPanel">
        <SharedProjectsDetailsTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
      {isSuperAdmin ? (
        <TabPanel
          className="OrganizationDetails_attachedUserTabPanel"
          eventkey="OrganizationDetails_attachedUserTabPanel"
        >
          <AttachedUserDetailsTable
            elementNameInUrl={elementNameInUrl}
            selectedElement={selectedElement}
            showAdd
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </TabPanel>
      ) : null}
      {isAdmin || isSuperAdmin ? (
        <TabPanel
          className="OrganizationDetails_configurationTabPanel"
          eventkey="OrganizationDetails_configurationTabPanel"
        >
          <OrganizationConfigurationPane organizationId={selectedElement.id} isReadOnly={isAdmin} />
        </TabPanel>
      ) : null}
    </Tabs>
  );
};

OrganizationDetailsTab.propTypes = {
  selectedElement: PropTypes.object.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
};
