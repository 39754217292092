// Components
import systemModals from "components/modals/Modals";
import { ExportAuditWizard } from "../ExportWizard";
import { IExportAuditReportsModal } from "../../types";

export const ExportAuditModal = ({
  modalName = "ExportAudit_modal",
  elementName,
  onReportExport,
  isVisible,
  toggle,
}: IExportAuditReportsModal) => {
  return (
    <>
      {isVisible
        ? systemModals.customModal(
            modalName,
            isVisible,
            toggle,
            <ExportAuditWizard onReportExport={onReportExport} toggle={toggle} elementName={elementName} />,
            "Audit Exporter"
          )
        : null}
    </>
  );
};
