import * as React from "react";

import ButtonCell from "components/EditComponents/ButtonCell";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState, useRecoilValue } from "recoil";
import { userIdState } from "atoms/atoms-admin";
import { TYPE, STATUS } from "constants/brm";

import {
  createColumnName,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnActions,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";

import { useRoles } from "features/brm/hooks/useRoles";
import { LoadingSpinner as Loading } from "components/elements";
import { AdminApi } from "features/brm";
import * as FilterUtils from "utils/filter-utils";

interface IOrgTableProps {
  onRowSelect: (rows: any) => void;
}

// interface IStatus {
//   id: string;
//   name: string;
// }

export const OrganizationTable = ({ onRowSelect }: IOrgTableProps) => {
  const { isSuperAdmin } = useRoles();
  const userId = useRecoilValue(userIdState);
  // const statusList = React.useMemo(() => {
  //   const list: [IStatus] = [{ id: STATUS.active, name: STATUS.active }];
  //   if (isSuperAdmin) {
  //     list.push({ id: STATUS.suspended, name: STATUS.suspended });
  //   }
  //   return list;
  // }, [isSuperAdmin]);
  const [editMode, setEditMode] = React.useState(false);
  const updatedName = React.useRef("");
  const updatedStatus = React.useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState<any>(selectedInstanceState);

  const { data: organizations } = AdminApi.useOrganizations({ options: { enabled: isSuperAdmin } });
  const { data: userOrganization } = AdminApi.useUserPrimaryOrganization({
    userId,
    options: { enabled: Boolean(userId) },
  });
  const { data: userAttachedOrganizations } = AdminApi.useUserAttachedOrganizations({
    userId,
    options: { enabled: Boolean(userId) },
  });
  const { mutate: setOrganizationName } = AdminApi.useSetOrganizationName();
  const { mutate: setOrganizationStatus } = AdminApi.useSetOrganizationStatus();

  const createButton = React.useCallback(
    (cellProps) => (
      <ButtonCell
        selectedRowId={selectedInstance.id}
        elementId={cellProps.cell.row.original.id}
        handleConfirmEditClick={async () => {
          setEditMode(false);
          // send request to update name
          if (updatedName.current !== "") {
            setOrganizationName({ organizationId: selectedInstance.id, newName: updatedName.current });
            setSelectedInstance({
              id: selectedInstance.id,
              name: updatedName.current,
              type: selectedInstance.type,
            });
            updatedName.current = "";
          }

          // send request to update  status
          if (updatedStatus.current && updatedStatus.current !== "Select status") {
            setOrganizationStatus({ organizationId: selectedInstance.id, newStatus: updatedStatus.current });
            updatedStatus.current = "";
          }
        }}
        setEditMode={setEditMode}
        editMode={editMode}
        disable={!!(cellProps.cell.row.original.status === STATUS.deleted && isSuperAdmin)}
      />
    ),
    [
      selectedInstance.id,
      selectedInstance.type,
      editMode,
      isSuperAdmin,
      setOrganizationName,
      setSelectedInstance,
      setOrganizationStatus,
    ]
  );

  const columns = React.useMemo(() => {
    const cols = [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "organizations"),
      createColumnMappedNoEdit("status"),

      createColumnCreatedAt(),
      createColumnModifiedAt(),
    ];
    if (isSuperAdmin) {
      cols.push(createColumnActions(createButton, { disableFilters: true }));
    }
    return cols;
  }, [isSuperAdmin, editMode, setSelectedInstance, selectedInstance.id, createButton]);

  const organizationData = React.useMemo(() => {
    let userOrgs = userAttachedOrganizations;
    if (userOrgs && !Array.isArray(userOrgs)) {
      userOrgs = [userOrgs];
    }
    if ((Array.isArray(organizations) && organizations.length) || (userOrganization && userOrgs)) {
      const orgData = isSuperAdmin ? organizations : [userOrganization, ...userOrgs];
      /* if (isSuperAdmin) {
        return orgData;
      } */
      return orgData?.filter(FilterUtils.isNotDeletedStatus);
    }
    return undefined;
  }, [isSuperAdmin, organizations, userAttachedOrganizations, userOrganization]);

  const hideCheckBoxCondition = React.useCallback(
    (row) => {
      if (userOrganization) {
        return isSuperAdmin && row.original?.id === userOrganization.id;
      }
      return true;
    },
    [isSuperAdmin, userOrganization]
  );

  if (organizationData) {
    return (
      <CustomTable id="OrganizationTable_table">
        <BrmMainTable
          data={organizationData}
          columns={columns}
          setSelectedRows={onRowSelect}
          customProps={{ id: "OrganizationTable_table" }}
          elementName={TYPE.organization}
          showRowSelect={isSuperAdmin}
          hideCheckBoxCondition={hideCheckBoxCondition}
        />
      </CustomTable>
    );
  }

  return <Loading />;
};
