/** ****************************************
 * FYI THERE ARE TWO ALLOCATED_CONTROL_TABLES components and add forms with the same name... the other is in the features/mitigation...
 * why you ask? I have no idea and no time to find out.
 */

import PropTypes from "prop-types";
import * as React from "react";
import * as Recoil from "recoil";
import { variantIdState } from "atoms/atoms-component";
import { BrmDetailTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import { AddButton, DetailTableType, SystemApi, ExportButton, currentProjectState } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
import { queryClient } from "libs/react-query";
import { ErrorBanner, LoadingSpinner as Loading, CenterText } from "components/elements";
import { useModal } from "hooks";
import { useRoles } from "features/brm/hooks/useRoles";
import { isSubmissionPendingState } from "atoms/atoms-importer";
import { isImportingStatus } from "utils/filter-utils";

// Styles
import * as S from "brm/styles/details-table.styles";
// Api
import { RoutePath } from "routes/route-paths";
import { Link } from "react-router-dom";
import { AddAllocatedControlForm } from "./forms/AddAllocatedControlForm";

const AllocatedControlTable = ({
  elementNameInUrl,
  selectedElement,
  showAdd,
  tableTitle,
  setIsControlCatalogEditingEnabled,
}) => {
  const variantId = Recoil.useRecoilValue(variantIdState);
  const currentProject = Recoil.useRecoilValue(currentProjectState);
  const isSubmissionPending = Recoil.useRecoilValue(isSubmissionPendingState);

  const [, setSelectedAllocatedControl] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const sctmId = React.useRef(
    elementNameInUrl === DetailTableType.VARIANTS.key && selectedElement.sctm?.id
      ? selectedElement.sctm?.id
      : selectedElement.id
  );

  const { isControlsCoordinator, isThreatAnalyst } = useRoles();
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  const isImporting = currentProject ? isImportingStatus(currentProject) : true;
  const isImportingAndSubmissionDone = !(isImporting || isSubmissionPending);

  const columns = React.useMemo(() => {
    if (isControlsCoordinator) {
      return [
        createColumnMappedNoEdit("name"),
        createColumnMappedNoEdit("vulnerabilitycategory"),
        createColumnMappedNoEdit("controltype"),
        createColumnMappedNoEdit("controlFamily"),
        createColumnMappedNoEdit("strength"),
        createColumnMappedNoEdit("calibratedStrength"),
        createColumnMappedNoEdit("anobjectType"),
        createColumnMappedNoEdit("anobject"),
      ];
    }
    return [
      createColumnNameDetails(RoutePath.AllocatedControl.replace(":id", "")),
      createColumnMappedNoEdit("vulnerabilitycategory"),
      createColumnMappedNoEdit("controltype"),
      createColumnMappedNoEdit("controlFamily"),
      createColumnMappedNoEdit("strength"),
      createColumnMappedNoEdit("calibratedStrength"),
      createColumnMappedNoEdit("anobjectType"),
      createColumnMappedNoEdit("anobject"),
    ];
  }, [isControlsCoordinator]);

  React.useEffect(() => {
    if (elementNameInUrl === DetailTableType.SCTM.key) {
      sctmId.current = selectedElement.id;
    } else if (elementNameInUrl === DetailTableType.VARIANTS.key && selectedElement.sctm?.id) {
      sctmId.current = selectedElement.sctm?.id;
    }
  }, [elementNameInUrl, selectedElement]);

  const {
    data: acData,
    isError: isGetAcDataError,
    error: AllocatedControlsDataError,
  } = SystemApi.useElementAllocatedControls({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId: currentProject?.id,
    sctmId: sctmId.current,
    variantId,
    options: {
      enabled: !!selectedElement.id && !!sctmId.current && !!variantId && isImportingAndSubmissionDone,
      onSuccess: (data) => {
        if (setIsControlCatalogEditingEnabled) {
          setIsControlCatalogEditingEnabled(!(Array.isArray(data) && data.length));
        }
      },
    },
  });

  const addAllocatedControl = () => {
    setModalIsOpen(true);
  };

  React.useEffect(() => {
    queryClient.invalidateQueries(["getElementAllocatedControls", selectedElement, elementNameInUrl, variantId]);
  }, [elementNameInUrl, selectedElement, variantId]);

  if (isThreatAnalyst) {
    return <CenterText msg="Allocated Controls are not applicable" />;
  }

  if (isGetAcDataError) {
    return <ErrorBanner msg={AllocatedControlsDataError?.message} />;
  }

  if (!isImportingAndSubmissionDone) {
    return <Loading />;
  }

  if (acData) {
    return (
      <S.DetailsContainer id="AllocatedControlTable_detailsPanel">
        {/* modal for adding instance */}
        {modalIsOpen &&
          systemModals.addModal(
            "AllocatedControlTable_detailsPanel",
            modalIsOpen,
            setModalIsOpen,
            <AddAllocatedControlForm
              setModalIsOpen={setModalIsOpen}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
            />,
            "AllocatedControl"
          )}
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={acData}
              columns={columns}
              setSelectedElement={setSelectedAllocatedControl}
              customProps={{ id: "AllocatedControlTable_detailsTable" }}
              showRowSelect={false}
              elementName={elementNameInUrl}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailButtonTopCorner>
            <Link to={RoutePath.AllocatedControls}>Manage Allocated Controls</Link>
            {showAdd && <AddButton md onClick={addAllocatedControl} />}
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailButtonTopCorner>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

AllocatedControlTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
    sctm: PropTypes.any,
  }),
  showAdd: PropTypes.any,
  tableTitle: PropTypes.string,
  setIsControlCatalogEditingEnabled: PropTypes.func,
};

export default AllocatedControlTable;
