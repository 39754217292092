import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import StructuralCategoryTable from "./tables/StructuralCategoryTable";

function StructuralCategory() {
  const structCatHeader = <Title id="StructuralCategory_title">Structural Categories</Title>;

  const structCatMain = <StructuralCategoryTable setSelectedRows={() => {}} setRefresh={() => {}} />;

  return <ContentLayout pageId="StructuralCategory_page" header={structCatHeader} main={structCatMain} />;
}

export default StructuralCategory;
