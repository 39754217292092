import Select, { ActionMeta, MultiValue } from "react-select";
import { calibrationStyle } from "components/elements/ReactSelectCustomStyles";
import { rolesInUse } from "services/user";
import { useContextOptions } from "features/help/hooks";
import * as S from "./HelpFilters.styles";

interface IHelpFilters {
  rolesDisplayed: string[];
  onRoleChange: (
    newValue: MultiValue<{ label: string; value: string }>,
    actionMeta: ActionMeta<{ label: string; value: string }>
  ) => void;
  userRole: string;
  onContextChange: (
    newValue: MultiValue<{ label: string; value: string }>,
    actionMeta: ActionMeta<{ label: string; value: string }>
  ) => void;
  contentKey: string;
}

export const HelpFilters = ({ rolesDisplayed, onRoleChange, userRole, onContextChange, contentKey }: IHelpFilters) => {
  const roleOptions = rolesInUse.map((role) => ({ label: role.name, value: role.key }));
  const defaultRole = rolesInUse
    .filter((role) => role.key === userRole)
    .map((role) => ({ label: role.name, value: role.key }));

  const contextOptions = useContextOptions(rolesDisplayed);

  const defaultContext = [{ label: contentKey.charAt(0).toUpperCase() + contentKey.slice(1), value: contentKey }];

  return (
    <S.RowContainer>
      <S.SelectDivRole>
        <S.SelectLabel>
          Role Selector
          <Select
            isMulti
            options={roleOptions}
            defaultValue={defaultRole}
            onChange={onRoleChange}
            id="helpRoles"
            styles={calibrationStyle}
            placeholder="Select Roles"
          />
        </S.SelectLabel>
      </S.SelectDivRole>
      <S.SelectDivContext>
        <S.SelectLabel>
          Page Selector
          <Select
            isMulti
            options={contextOptions}
            defaultValue={defaultContext}
            onChange={onContextChange}
            id="helpContext"
            styles={calibrationStyle}
            placeholder="Type and select Contexts"
          />
        </S.SelectLabel>
      </S.SelectDivContext>
    </S.RowContainer>
  );
};
