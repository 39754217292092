import * as React from "react";
import * as YFiles from "yfiles";
import { LayoutType } from "features/diagram-constants";
import { executeLayout } from "../attack-diagram-layout";

export const useAttackDiagramLayout = (graphComponent: YFiles.GraphComponent, layoutType: LayoutType) => {
  const runLayout = React.useCallback(
    async (animated: boolean = true) => {
      await executeLayout(graphComponent, layoutType, animated);
    },
    [graphComponent, layoutType]
  );

  return runLayout;
};
