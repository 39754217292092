import HtmlHelpViewer from "components/elements/HtmlHelpViewer";
import { Wrapper } from "./HelpArticleVideo.styles";

export const HelpArticleVideo = ({ title, filePath }: { title: string; filePath?: string }) => {
  if (title && filePath) {
    return (
      <Wrapper>
        <b>{title}</b>
        <div>
          <HtmlHelpViewer fileUrl={filePath} />
        </div>
      </Wrapper>
    );
  }
  return null;
};
