import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { MutationOption } from "libs/react-query";

interface IExportThreatCatalog {
  threatCatalogId: string;
  format: string;
}

/**
 * Export a threat catalog.
 *
 * @param {string} threatCatalogId - threat catalog id
 * @param {format} format - format of the export
 */
export const exportThreatCatalog = async ({ threatCatalogId, format }: IExportThreatCatalog) => {
  return Brm.threatCatalogIoApi.threatCatalogExport(threatCatalogId, format);
};

export interface IUseExportThreatCatalog {
  options?: MutationOption<typeof exportThreatCatalog>;
}

/**
 * Custom hook to export a threat catalog.
 *
 * @param {string} threatCatalogId - threat catalog id
 * @param {format} format - format of the export
 * @param {object} options - react-query options
 */
export const useExportThreatCatalog = ({ options }: IUseExportThreatCatalog = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: exportThreatCatalog,
  });
};
