import S from "./ImgSized.style";

const ImgSized = (props) => {
  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <S.ImgSized {...props} />
    </>
  );
};

export default ImgSized;
