import PropTypes from "prop-types";
import { useState, useMemo, useEffect } from "react";
// Components
import { GenericDeleteConfirmation } from "components/modals";
import { BrmDetailTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import { AddButton, DeleteButton, SystemApi } from "features/brm";
// Utils
import { createColumnBooleanNoEditMapped, createColumnNameDetails } from "brm/tables/services/column/columnFactory";

// Local
import * as S from "brm/styles/details-table.styles";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { LoadingSpinner, ErrorBanner } from "components/elements";
import { TYPE } from "constants/brm";
import { queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import { RoutePath } from "routes/route-paths";
import CapabilityAddForm from "./forms/CapabilityAddForm";

const CapabilityTable = ({ elementNameInUrl, selectedElement, showAdd, showDelete, setRefresh, tableTitle }) => {
  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Capability.replace(":id", "")),
      createColumnBooleanNoEditMapped("isInternal"),
    ],
    []
  );

  const [, setSelectedCapability] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);

  const { data: capabilityData, isError: isCapabilityDataError } = SystemApi.useElementCapabilities({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    variantId,
    options: { enabled: !!selectedElement?.id && !!elementNameInUrl },
  });

  const addCapability = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      await Promise.all(
        selectedRows.map((each) => Brm.missionApi.removeMissionCapability(selectedElement.id, each.id))
      );
      setDeleteModalIsOpen(false);
      setSelectedRows([]);
      queryClient.invalidateQueries(SystemApi.capabilityKeys.element);
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, selectedElement.id, selectedRows]);

  if (isCapabilityDataError) {
    return <ErrorBanner msg="Error getting Capabilities" />;
  }

  if (capabilityData) {
    return (
      <S.DetailsContainer id="CapabilityTable_detailsPanel">
        {/* modal for adding instance */}
        {modalIsOpen &&
          systemModals.addModal(
            "CapabilityTable_detailsPanel",
            modalIsOpen,
            setModalIsOpen,
            <CapabilityAddForm
              setModalIsOpen={setModalIsOpen}
              setRefresh={setRefresh}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
            />,
            "Capability"
          )}

        {deleteModalIsOpen &&
          systemModals.deleteModal(
            "CapabilityTable_detailsPanel",
            deleteModalIsOpen,
            setDeleteModalIsOpen,
            <GenericDeleteConfirmation
              elementName={TYPE.capability}
              setDeleteModalIsOpen={setDeleteModalIsOpen}
              selectedRows={selectedRows}
              setConfirmDelete={setConfirmDelete}
            />,
            TYPE.capability
          )}
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={capabilityData}
              columns={columns}
              setSelectedElement={setSelectedCapability}
              setSelectedRows={setSelectedRows}
              customProps={{ id: "CapabilityTable_detailsTable" }}
              showRowSelect={showDelete}
              tableTitle={tableTitle}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            {showDelete && selectedRows.length > 0 && <DeleteButton md onClick={() => setDeleteModalIsOpen(true)} />}
            {showAdd && <AddButton md onClick={addCapability} />}
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

CapabilityTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setRefresh: PropTypes.any,
  showAdd: PropTypes.any,
  showDelete: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default CapabilityTable;
