import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import EntryPointCategoryTable from "./tables/EntryPointCategoryTable";

function EntryPointCategory() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const entryPointCatHeader = <Title id="EntryPointCategory_title">Entry Point Categories</Title>;

  const entryPointCatMain = (
    <>
      <EntryPointCategoryTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="EntryPointCategory_page" header={entryPointCatHeader} main={entryPointCatMain} />;
}

export default EntryPointCategory;
