import PropTypes from "prop-types";
import { useState } from "react";

// Components
import Select from "react-select";
import systemModals from "components/modals/Modals";

// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { setCalibrationStyle } from "components/elements/ReactSelectCustomStyles";
import getReactSelectArray from "utils/react-select-array";
import { useRecoilValue } from "recoil";
import { sctmIdState } from "atoms/atoms-mitigation";
import S from "../styles/calibration.styles";
import { useTargetUpdate } from "../../../api";

export const SetTargetModal = ({ modalIsOpen, setModalIsOpen, selectedRows, systemObjectData }) => {
  const [selectedTarget, setSelectedTarget] = useState("");
  const sctmId = useRecoilValue(sctmIdState);
  const setTargets = useTargetUpdate(sctmId);

  function setLevelClick() {
    selectedRows.forEach((each) => setTargets.mutate({ allocatedControlId: each.id, systemObject: selectedTarget }));

    setModalIsOpen(false);
  }

  const handleCancel = () => {
    setModalIsOpen(false);
  };

  const body = (
    <>
      <S.BodyDiv>
        <p>Select the desired Target:</p>
        <Select
          isMulti={false}
          options={getReactSelectArray(systemObjectData)}
          defaultValue={selectedTarget}
          onChange={(selectedItem) => setSelectedTarget(selectedItem)}
          styles={setCalibrationStyle}
          classNamePrefix="targetDropdown"
          id="SetTargetModal_targetDropdown"
        />
      </S.BodyDiv>
      <DialogButtonDivStyled>
        <DialogButtonStyled id="SetTargetModal_cancelButton" onClick={handleCancel}>
          Cancel
        </DialogButtonStyled>
        <DialogButtonStyled
          id="SetTargetModal_confirmButton"
          onClick={() => setLevelClick()}
          disabled={selectedTarget === ""}
        >
          Confirm
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </>
  );

  return (
    <div id="SetTargetModal_modal">
      {systemModals.customModal("SetTargetModal_modal", modalIsOpen, setModalIsOpen, body, "Set Target")}
    </div>
  );
};

SetTargetModal.propTypes = {
  modalIsOpen: PropTypes.func,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
  setModalIsOpen: PropTypes.func,
  systemObjectData: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
};
