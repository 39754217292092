import { sortByLabel } from "utils/sorting";

export function getReactSelectArray(array: any) {
  return array.map((obj: any) => ({ value: obj.id, label: obj.name })).sort(sortByLabel);
}

export function controlTypesToOptions(data: any) {
  return data
    .map((obj: any) => ({
      value: obj.id,
      label: obj.name,
      vulnerabilitycategory: obj.vulnerabilitycategory,
    }))
    .sort(sortByLabel);
}
