import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { systemApi } from "services/brm/component-service";
import { createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { ErrorBanner } from "components/elements";

const SCTMTable = ({ selectedElement }) => {
  const columns = useMemo(() => [createColumnMappedNoEdit("name"), createColumnMappedNoEdit("baseline")], []);
  const [, setSelectedSctm] = useState([]);

  const { data: sctmData, isError: isSctmDataError } = useQuery(["systemSctm"], () =>
    systemApi.getSystemSctm(selectedElement.id)
  );

  if (sctmData) {
    return (
      <S.DetailsContainer id="SctmTable_detailsPanel">
        <BrmDetailTable
          data={[sctmData]}
          columns={columns}
          setSelectedElement={setSelectedSctm}
          customProps={{ id: "SctmTable_detailsTable" }}
          showRowSelect={false}
        />
      </S.DetailsContainer>
    );
  }

  if (isSctmDataError) {
    return <ErrorBanner msg="Error while loading..." />;
  }

  return <div>Loading...</div>;
};

SCTMTable.propTypes = {
  selectedElement: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export default SCTMTable;
