import { useState, useEffect } from "react";
import PropTypes from "prop-types";
// State
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
// Components
import SelectBoxCategory from "components/forms/DynamicSelectBoxCategory";
import TextField from "components/forms/TextField";
// Constants
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
// Services
import { attackSurfaceApi, attackSurfaceCatApi } from "services/brm/access-service";
import { AttackSurfaceCreateDto } from "@kdmanalytics/brm-system";
import * as ACQueries from "brm/system-model/access/access-queries";
// Utils
import errorUtils from "utils/error-utils";
import { sortUpperCase } from "utils/sorting";
import { validateNameUniqueness } from "features/system-model";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";

const AddAttackSurfaceForm = ({ setModalIsOpen, setRefresh }) => {
  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = useState("");
  const [attackSurfaceCategories, setAttackSurfaceCategories] = useState([]);
  const [isTextValid, setIsTextValid] = useState(true);

  const { data: attackSurfaces, isError } = ACQueries.useAttackSurfaces(projectId);

  useEffect(() => {
    async function getData() {
      try {
        const res = await attackSurfaceCatApi.findAttackSurfaceCategoryWithHttpInfo(projectId);
        setAttackSurfaceCategories(res.data.sort(sortUpperCase));
      } catch (err) {
        console.error("Error in attackSurfaceCatApi.findAttackSurfaceCategoryWithHttpInfo ", err);
      }
    }
    getData();
  }, [projectId]);

  function validate(formData) {
    let error = true;
    if (formData.name.value === "" || formData.category.value === "") {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isNameValid = validateNameUniqueness(attackSurfaces, e.target.elements.name.value);
    if (!isNameValid) {
      setPostError(FORM_ERROR.duplicateAttackSurfaceName);
      return null;
    }

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value || "",
        category: e.target.elements.category.value,
      };

      const dto = AttackSurfaceCreateDto.constructFromObject(params);
      // send new instance to the backend - instance is created in context of project
      try {
        const { response } = await attackSurfaceApi.createAttackSurfaceWithHttpInfo(projectId, {
          attackSurfaceCreateDto: dto,
        });
        errorUtils.checkErrorStatus(response.status, response.statusText); // response check
        setModalIsOpen(false);
        setRefresh(true);
      } catch (err) {
        console.error("error in creating attack surface : ", err);
      }
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  if (isError) {
    return <ErrorBanner msg="Error while loading required info for add Attack Surface" />;
  }

  if (attackSurfaceCategories) {
    return (
      <div>
        <form onSubmit={handleSubmit} action="">
          <FormStyled>
            <div className="form-style">
              <TextField
                label={{ id: "AddAttackSurfaceForm_name", name: FORM_LABEL.nameMandatory }}
                input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "AddAttackSurfaceForm_note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />

              <label id="AddAttackSurfaceForm_category">{FORM_LABEL.categoryMandatory}</label>
              <SelectBoxCategory
                id="AddAttackSurfaceForm_categoryDropdown"
                arrayOfData={attackSurfaceCategories}
                item="category"
              />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
            <DialogButtonStyled type="submit">Add</DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }
  return <Loading />;
};

AddAttackSurfaceForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default AddAttackSurfaceForm;
