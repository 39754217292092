import PropTypes from "prop-types";
import { useMemo } from "react";

import { BrmDetailTable } from "brm/tables/BrmTables";
import {
  createColumnMappedNoEdit,
  createColumnNameDetails,
  createColumnImpactMapped,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";
import { useVariant } from "features/brm";
import useGetElementDependentUe from "./api/dependent-undesiredevent-table-queries";
// Styles

const DependentUndesiredEventTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const { variantId } = useVariant();

  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.UndesiredEvent.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnImpactMapped("currentImpact"),
    ],
    []
  );
  const { data: getDepUeData, isError: isGetDepUeDataError } = useGetElementDependentUe(
    selectedElement.id,
    elementNameInUrl,
    variantId
  );

  if (isGetDepUeDataError) {
    return <ErrorBanner msg="Error while loading dependent undesired events data" />;
  }

  if (getDepUeData) {
    return (
      <S.DetailsContainer id="DependentUndesiredEventTable_detailsPanel">
        <S.DetailsTableContainer>
          <BrmDetailTable
            data={getDepUeData}
            columns={columns}
            customProps={{ id: "DependentUndesiredEventTable_detailsTable" }}
            showRowSelect={false}
            tableTitle={tableTitle}
          />
        </S.DetailsTableContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

DependentUndesiredEventTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  tableTitle: PropTypes.string,
};

export default DependentUndesiredEventTable;
