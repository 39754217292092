import { Col, Row } from "react-bootstrap";
import { BorrowRecord } from "../BorrowRecord";

interface IBorrowRecord {
  duration: number;
  expiryDate: string;
  hostId: string;
  issueDate: string;
  sessionsMax: number;
  userName: string;
  userSessionsMax: number;
}

interface IBorrowRecordListProps {
  borrows: IBorrowRecord[];
}

export const BorrowRecordList = ({ borrows }: IBorrowRecordListProps) => {
  if (borrows) {
    return (
      <>
        <br />
        <Row>
          <b>
            <Col>Borrows from this computer: {`[${borrows.length}]`}</Col>
          </b>
        </Row>
        {borrows?.map((b: IBorrowRecord) => (
          <BorrowRecord key={b.hostId} record={b} />
        ))}
      </>
    );
  }
  return null;
};
