import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import errorUtils from "utils/error-utils";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import SystemService, { activityApi } from "services/brm/system-service";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import getReactSelectArray from "utils/react-select-array";
import { FORM_LABEL } from "constants/brm";
import SelectAll from "components/forms/SelectAllReactSelect";
import { DetailTableType } from "features/brm";
import getUniqueArray from "./helpers/GetUniqueArray";
// import customDetailStyle from "./styles/BrmReactSelectDetailsStyles";

const AddNextForm = ({ setModalIsOpen, setRefresh, elementNameInUrl, selectedElement }) => {
  const [postError, setPostError] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const projectId = useRecoilValue(projectIdState);

  const filterExisting = useCallback(async () => {
    let existingRelations = [];
    switch (elementNameInUrl) {
      case DetailTableType.ACTIVITIES.key: {
        const data = await activityApi.getActivityNext(selectedElement.id);
        existingRelations = data;
        break;
      }
      default: {
        break;
      }
    }
    // now add the current activity, so that it cannot be added as next
    const currentActivity = await activityApi.getActivityByID(selectedElement.id);
    existingRelations.push(currentActivity);

    return existingRelations;
  }, [elementNameInUrl, selectedElement.id]);

  useEffect(() => {
    async function getData() {
      const existingRelations = await filterExisting();

      const res = await activityApi.findActivityWithHttpInfo(projectId);
      if (res?.data) {
        const uniqueArray = getUniqueArray(res.data, existingRelations);
        setOptions(getReactSelectArray(uniqueArray));
      }
    }
    if (projectId) {
      getData();
    }
  }, [projectId, filterExisting]);

  const handleSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedOptions(items);
  };

  function getPutRequest(selectedId) {
    switch (elementNameInUrl) {
      case DetailTableType.ACTIVITIES.key: {
        return SystemService.addActivityNext(selectedElement.id, selectedId);
      }
      default: {
        break;
      }
    }
    return undefined;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (selectedOptions.length > 0) {
      try {
        selectedOptions.forEach(async (item) => {
          const res = await getPutRequest(item.value);
          if (res?.response) {
            errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          }
        });
      } catch (err) {
        console.error("Error occurred on put request: ", err);
      }
      setModalIsOpen(false);
      setRefresh(true);
    } else {
      setPostError("*Please fill out all fields in the form prior to submitting.");
    }
  }

  return (
    <div id="AddNextForm">
      <form onSubmit={handleSubmit} action="" id="AddNextForm_form">
        <FormStyled>
          <div className="form-style" id="AddNextForm_formContent">
            <label>{FORM_LABEL.nexts}</label>
            <SelectAll
              // styles={customDetailStyle}
              isMulti
              options={options}
              defaultValue={selectedOptions}
              value={selectedOptions}
              onChange={handleSelectChange}
              allowSelectAll
              allOption={{
                label: "Select all",
                value: "*",
              }}
              elementId="AddNextForm_next"
            />
          </div>
        </FormStyled>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="AddNextForm_cancelButton">
            Cancel
          </DialogButtonStyled>
          <DialogButtonStyled type="submit" id="AddNextForm_addButton">
            Add
          </DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};

AddNextForm.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setModalIsOpen: PropTypes.func,
  setRefresh: PropTypes.func,
};

export default AddNextForm;
