import * as Brm from "services/brm";
import safelyParseJson from "utils/safely-parse-json";

import userRoles from "services/brm/user-roles";

export const ROLE_ADMIN = userRoles.properties[userRoles.ADMIN].key;
export const ROLE_SUPER_ADMIN = userRoles.properties[userRoles.SUPER_ADMIN].key;
export const ROLE_SYSTEM_ENGINEER = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
export const ROLE_THREAT_ANALYST = userRoles.properties[userRoles.THREAT_ANALYST].key;
export const ROLE_RISK_ANALYST = userRoles.properties[userRoles.RISK_ANALYST].key;
export const ROLE_CONTROLS_COORDINATOR = userRoles.properties[userRoles.CONTROLS_COORDINATOR].key;
export const ROLE_SCA = userRoles.properties[userRoles.SCA].key;
export const ROLE_AUTH_OFFICER = userRoles.properties[userRoles.AUTH_OFFICER].key;
export const ROLE_CYBERSECURITY_EXPERT = userRoles.properties[userRoles.CYBERSECURITY_EXPERT].key;
export const ROLE_TECH_ADMIN = userRoles.properties[userRoles.TECH_ADMIN].key;
export const ROLE_EXECUTIVE = userRoles.properties[userRoles.EXECUTIVE].key;
export const ROLE_BUILD_ENGINEER = userRoles.properties[userRoles.BUILD_ENGINEER].key;
export const ROLE_BOR_PROJECT_COORDINATOR = userRoles.properties[userRoles.BOR_PROJECT_COORDINATOR].key;
export const ROLE_SW_DEVELOPER = userRoles.properties[userRoles.SW_DEVELOPER].key;
export const ROLE_EVIDENCE_SERVER_COORDINATOR = userRoles.properties[userRoles.EVIDENCE_SERVER_COORDINATOR].key;

export const isAdmin = (userRole) => {
  return userRole === ROLE_ADMIN;
};

export const isSuperAdmin = (userRole) => {
  return userRole === ROLE_SUPER_ADMIN;
};

export const isSystemEngineer = (userRole) => {
  return userRole === ROLE_SYSTEM_ENGINEER;
};

export const isThreatAnalyst = (userRole) => {
  return userRole === ROLE_THREAT_ANALYST;
};

export const isRiskAnalyst = (userRole) => {
  return userRole === ROLE_RISK_ANALYST;
};

export const isControlsCoordinator = (userRole) => {
  return userRole === ROLE_CONTROLS_COORDINATOR;
};

export const isSca = (userRole) => {
  return userRole === ROLE_SCA;
};

export const isAuthOfficer = (userRole) => {
  return userRole === ROLE_AUTH_OFFICER;
};

export const isCyberSecurityExpert = (userRole) => {
  return userRole === ROLE_CYBERSECURITY_EXPERT;
};

export const isTechAdmin = (userRole) => {
  return userRole === ROLE_TECH_ADMIN;
};

export const isExecutive = (userRole) => {
  return userRole === ROLE_EXECUTIVE;
};

export const isBuildEngineer = (userRole) => {
  return userRole === ROLE_BUILD_ENGINEER;
};

export const isBorProjectCoordinator = (userRole) => {
  return userRole === ROLE_BOR_PROJECT_COORDINATOR;
};

export const isSoftwareDeveloper = (userRole) => {
  return userRole === ROLE_SW_DEVELOPER;
};

export const isEvidenceServerCoordinator = (userRole) => {
  return userRole === ROLE_EVIDENCE_SERVER_COORDINATOR;
};

export const keyToName = Object.keys(userRoles)
  .filter((key) => key !== "properties")
  .reduce((accumulator, currentValue) => {
    if (userRoles[currentValue]) {
      const { key } = userRoles.properties[userRoles[currentValue]];
      const { name } = userRoles.properties[userRoles[currentValue]];
      return {
        ...accumulator,
        [key]: name,
      };
    }
    return {
      ...accumulator,
    };
  }, []);

export const nameToKey = Object.keys(userRoles)
  .filter((key) => key !== "properties")
  .reduce((accumulator, currentValue) => {
    const { key } = userRoles.properties[userRoles[currentValue]];
    const { name } = userRoles.properties[userRoles[currentValue]];
    return {
      ...accumulator,
      [name]: key,
    };
  }, []);

export const keyToRank = Object.keys(userRoles)
  .filter((key) => key !== "properties")
  .reduce((accumulator, currentValue) => {
    if (userRoles[currentValue]) {
      const { key, rank } = userRoles.properties[userRoles[currentValue]];
      return {
        ...accumulator,
        [key]: rank,
      };
    }
    return {
      ...accumulator,
    };
  }, []);

export const rolesInUse = Object.values(userRoles.properties).filter((role) => role.inUse);

export const setUserInSession = () => {
  const storage = window.sessionStorage;
  const { value } = safelyParseJson(storage.getItem("userId"));

  Brm.userApi.getUserRoleWithHttpInfo(value).then((res) => {
    sessionStorage.setItem("userRole", JSON.stringify({ value: res.data.result }));
  });
};
