export const EXPORT_STATUS = {
  pass: "PASS",
  fail: "FAIL",
  pending: "PENDING",
};

export const EXPORT_TARGET = {
  csv: "csv",
  zip: "zip",
  json: "json",
};

export const EXPORT_RANGE = {
  allTable: "Full",
  currentView: "Summary",
};
