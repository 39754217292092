import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./data-type-query-key-factory";

interface ICreateDataTypeProps {
  projectId: string;
  dataTypeCreateDto: any;
}

/**
 * Create a datatype
 *
 */
export const createDataType = ({ projectId, dataTypeCreateDto }: ICreateDataTypeProps) => {
  return dataTypeCreateDto === null
    ? Promise.reject(new Error("Invalid dataTypeCreateDto dto"))
    : Brm.dataTypeApi.createDataType(projectId, { dataTypeCreateDto });
};

interface ICreateDataTypeQueryProps {
  options?: MutationOption<typeof createDataType>;
}

/**
 * Create a datatype
 *
 * @returns react-query Mutation.
 */
export const useCreateDataType = ({ options }: ICreateDataTypeQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.dataTypeKeys.project(variables.projectId));
    },
    ...options,
    mutationFn: createDataType,
  });
};
