import styled from "styled-components";

const buttonStyles = {
  Button: styled.button`
    border: none;
    background: none;

    & img {
      width: 100%;
      height: 100%;
    }
  `,
  Span: styled.span``,
};

export default buttonStyles;
