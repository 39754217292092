import * as React from "react";
import { getErrorMessage } from "utils/error-message-utils";
import * as log from "loglevel";
import { useCsvFileRows } from "../useCsvFile";

interface ISubCat {
  category: string;
  subcatName: string;
  note: string;
}

interface ISubcatObj {
  [key: string]: ISubCat | undefined;
}

export const useKbSubcats = (csvUrl: string = "/kbs/KB_subcat.csv") => {
  const [lookupSubcats, setLookupSubcats] = React.useState<ISubcatObj>();
  const { data } = useCsvFileRows({ csvUrl });

  React.useEffect(() => {
    if (data) {
      try {
        const rows = data as string[][];
        const csvFileSubcats: Partial<ISubcatObj> = {};

        rows.forEach((row) => {
          csvFileSubcats[row[0]] = {
            category: row[1],
            subcatName: row[2],
            note: row[3],
          };
        });
        if (csvFileSubcats) {
          setLookupSubcats(csvFileSubcats);
        }
      } catch (err) {
        log.error(`Unable to load subcat${getErrorMessage(err)}`);
        setLookupSubcats(undefined);
      }
    }
  }, [data]);

  return { lookupSubcats };
};
