import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { variantIdState } from "atoms/atoms-component";
import { BrmDetailTable } from "brm/tables/BrmTables";

import { createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
import { CenterText, ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import * as S from "brm/styles/details-table.styles";
import { DetailTableType, useRoles } from "features/brm";
import { projectIdState } from "atoms";
import { useGetElementFindings } from "./api";

const BorFindingsTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const { isRiskAnalyst } = useRoles();

  const columns = useMemo(
    () => [
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("cwe"),
      createColumnMappedNoEdit("sfp"),
      createColumnMappedNoEdit("lineNumber"),
      createColumnMappedNoEdit("tool"),
      createColumnMappedNoEdit("resource"),
      createColumnMappedNoEdit("weakness"),
    ],
    []
  );
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const [, setSelectedAllocatedControl] = useState([]);

  const { data: getFindingsData, isError: isGetFindingsDataError } = useGetElementFindings({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    elementCategory: selectedElement.category,
    nodeId: selectedElement.object?.id,
    projectId,
    variantId,
    options: {
      enabled:
        !!selectedElement.id &&
        isRiskAnalyst &&
        (elementNameInUrl === DetailTableType.NODES.key ||
          elementNameInUrl === DetailTableType.VULNERABILITIES.key ||
          elementNameInUrl === DetailTableType.PROJECTS.key ||
          elementNameInUrl === DetailTableType.RISKS.key),
    },
  });

  if (
    elementNameInUrl !== DetailTableType.NODES.key &&
    elementNameInUrl !== DetailTableType.VULNERABILITIES.key &&
    elementNameInUrl !== DetailTableType.PROJECTS.key &&
    elementNameInUrl !== DetailTableType.RISKS.key
  ) {
    return <CenterText msg="Vulnerability Findings are not applicable " />;
  }

  if (isGetFindingsDataError) {
    return <ErrorBanner msg="Error while loading findings data" />;
  }

  if (getFindingsData) {
    return (
      <S.DetailsContainer id="BorFindingsTable_detailsPanel">
        <BrmDetailTable
          data={getFindingsData}
          columns={columns}
          setSelectedElement={setSelectedAllocatedControl}
          customProps={{ id: "BorFindingsTable_detailsTable" }}
          showRowSelect={false}
          tableTitle={tableTitle}
        />
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

BorFindingsTable.propTypes = {
  elementNameInUrl: PropTypes.string,
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
    category: PropTypes.string,
    object: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  tableTitle: PropTypes.string,
};

export default BorFindingsTable;
