import { useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
// Recoil
import * as Recoil from "recoil";
// Svg
import checkIcon from "assets/icons/studio91/attack/check-green.svg";
// Constants
import { TYPE } from "constants/brm";
// Yfiles
import { DragDropEffects, NodeDropInputMode, Rect, SimpleNode, ShapeNodeStyle } from "yfiles";
import "yfiles/yfiles.css";
import { passiveSupported } from "features/diagram-common";

// State
import { ThemeContext } from "styled-components";
import { nodesVisibleInAttackGraphState } from "../../stores";
// Styles
import NodeData from "../../model/NodeData";
import { NODE_TYPE_STYLE_MAP, NODE_HEIGHT, NODE_WIDTH } from "../../constants";
import { StyledDiv, NameDiv } from "./AddNodeItem.styles";

const AddNodeItem = ({ data, type, svg }) => {
  const nodesVisibleInAttackGraph = Recoil.useRecoilValue(nodesVisibleInAttackGraphState);
  const isNodeItemInGraph = nodesVisibleInAttackGraph.some((id) => id === data.id);
  const theme = useContext(ThemeContext);
  const divContainer = useRef(null);
  const imgContainer = useRef(null);
  const displayName = data.rank?.value ? `(${data.rank.value.toString()}) ${data.name}` : data.name;

  const startDrag = () => {
    const nodeCategory = data.category?.name;
    // Define color and shape by node type
    const color =
      nodeCategory === "Disclosure"
        ? NODE_TYPE_STYLE_MAP[type].category[nodeCategory].color
        : NODE_TYPE_STYLE_MAP[type].color;
    const { shape } = NODE_TYPE_STYLE_MAP[type];

    const height = type === TYPE.exchange ? NODE_WIDTH / 2 : NODE_HEIGHT;

    // Create preview node with which the GraphComponent can render a preview during the drag gesture.
    const simpleNode = new SimpleNode();
    simpleNode.layout = new Rect(0, 0, NODE_WIDTH, height);
    simpleNode.style = new ShapeNodeStyle({ shape, fill: color, stroke: "transparent" });

    simpleNode.tag = new NodeData({
      id: data.id,
      name: data.name,
      type,
      rank: data.rank,
      category: nodeCategory === undefined ? "" : nodeCategory,
    });

    // The core method that initiates a drag which is recognized by the GraphComponent.
    NodeDropInputMode.startDrag(
      divContainer.current, // The source of the drag gesture, i.e. the element in the drag and drop panel.
      simpleNode, // The node that is dragged. This is used to provide a preview within the GC during the drag.
      DragDropEffects.ALL, // The allowed actions for this drag.
      true // Whether to the cursor during the drag.
    );
  };

  useEffect(() => {
    if (!isNodeItemInGraph) {
      divContainer.current.addEventListener(
        "mousedown",
        (event) => {
          startDrag();
          event.preventDefault();
        },
        false
      );
      divContainer.current.addEventListener(
        "touchstart",
        (event) => {
          startDrag();
          event.preventDefault();
        },
        passiveSupported ? { passive: false } : false
      );
    }
  });

  const displayImage = isNodeItemInGraph ? (
    <img
      ref={imgContainer}
      src={checkIcon}
      height={theme.attackGraph.palette.iconHeight}
      width={theme.attackGraph.palette.iconWidth}
      alt={data.name}
    />
  ) : (
    <img ref={imgContainer} src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`} alt={data.name} />
  );

  return (
    <StyledDiv ref={divContainer} title={data.name} isInGraph={isNodeItemInGraph}>
      {displayImage}
      <NameDiv>{displayName}</NameDiv>
    </StyledDiv>
  );
};

AddNodeItem.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  svg: PropTypes.string,
};

export default AddNodeItem;
