import * as React from "react";
import { Outlet } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { ErrorBoundary } from "react-error-boundary";

import { PageSpinner } from "components/elements/PageSpinner";
import { Help as HelpHeader } from "features/header";
import { Footer } from "features/footer";
import { ErrorFallback } from "components/error";
import * as S from "./MainLayout.styles";

export const HelpLayout = ({ isHelpCenter }: { isHelpCenter: boolean }) => {
  const HeaderErrorFallback = ({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) => {
    return (
      <Alert variant="danger">
        <Alert.Heading>
          Something went wrong: {error.message}.
          <Button size="sm" variant="link" onClick={resetErrorBoundary}>
            Try Again
          </Button>
        </Alert.Heading>
      </Alert>
    );
  };

  return (
    <S.AppContainer>
      <S.LayoutContainer>
        <React.Suspense
          fallback={
            <div>
              <PageSpinner text="Loading BRM Help Center..." />
            </div>
          }
        >
          <S.TopNavigation>
            <ErrorBoundary FallbackComponent={HeaderErrorFallback} onReset={() => {}}>
              <HelpHeader isHelpCenter={isHelpCenter} />
            </ErrorBoundary>
          </S.TopNavigation>
          <S.MainLayoutContainer>
            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
              {isHelpCenter ? (
                <S.HelpLayoutContentContainer>
                  <Outlet />
                </S.HelpLayoutContentContainer>
              ) : (
                <S.DemoLayoutContentContainer>
                  <Outlet />
                </S.DemoLayoutContentContainer>
              )}
            </ErrorBoundary>
          </S.MainLayoutContainer>

          <S.Footer>
            <Footer />
          </S.Footer>
        </React.Suspense>
      </S.LayoutContainer>
    </S.AppContainer>
  );
};
