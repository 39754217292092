import { useState } from "react";
import Title from "components/elements/StyledTitle";
import ContentLayout from "brm/layout/Content/ContentLayout";

import SystemAssetCategoryTable from "./tables/SystemAssetCategoryTable";

function SystemAssetCategory() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const systemAssetCatHeader = <Title id="SystemAssetCategory_title">System Asset Categories</Title>;

  const systemAssetCatMain = (
    <>
      <SystemAssetCategoryTable
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );

  return <ContentLayout pageId="SystemAssetCategory_page" header={systemAssetCatHeader} main={systemAssetCatMain} />;
}

export default SystemAssetCategory;
