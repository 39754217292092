import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./sap-org-query-key-factory";

/**
 * Gets the organization by id
 */
export const getSapOrganizations = () => {
  return Brm.sapOrganizationApi.findSapOrganization();
  // return [
  //   {
  //     id: "0000",
  //     guid: "0000",
  //     abbr: "org1",
  //     name: "Org 1",
  //     scn: "",
  //     sapprogram: ["prog1"],
  //     createdAt: undefined,
  //     modifiedAt: undefined,
  //   },
  //   {
  //     id: "0001",
  //     guid: "0001",
  //     abbr: "org2",
  //     name: "Org 2",
  //     scn: "",
  //     sapprogram: [] as string[],
  //     createdAt: undefined,
  //     modifiedAt: undefined,
  //   },
  // ];
};

type FunctionType = typeof getSapOrganizations;
export interface IOrganizationsProps {
  options?: QueryOption<FunctionType>;
}

/**
 * Custom hook for getting all sap organizations
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useGetSapOrganizations = ({ options = {} }: IOrganizationsProps = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.sapOrganizationKeys.all,
    queryFn: () => getSapOrganizations(),
  });
};
