import * as React from "react";

import {
  DynamicSelectBoxCategory,
  BooleanDropdown,
  FormStyled,
  FormStyledError,
  TextField,
  SelectAll,
} from "components/forms";
import { stringToBoolean } from "utils/boolean-checker";
import { DialogButtonDivStyled, DialogButtonStyled, ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { ResourceCreateDto } from "@kdmanalytics/brm-system";
import PropTypes from "prop-types";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
import { sortUpperCase } from "utils/sorting";
import { SystemApi } from "features/brm";
import { validateNameUniqueness } from "features/system-model";

interface IResourceAddForm {
  setModalIsOpen: (value: boolean) => void;
}

const AddMissionForm = ({ setModalIsOpen }: IResourceAddForm) => {
  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = React.useState("");
  const [selectedNodes, setSelectedNodes] = React.useState([]);
  const [selectedActivities, setSelectedActivities] = React.useState([]);
  const [isTextValid, setIsTextValid] = React.useState(true);

  const { mutate: createResource } = SystemApi.useCreateResource();
  const { mutateAsync: addResourceNode } = SystemApi.useAddResourceNode();
  const { mutateAsync: addResourceActivity } = SystemApi.useAddResourceActivity();

  const { data: resourcesData, isError: isResourceDataError } = SystemApi.useResources({
    projectId,
    options: { enabled: !!projectId },
  });

  const { data: elementCategories, isError: isElementCategoriesError } = SystemApi.useElementCategories({
    projectId,
    options: {
      enabled: !!projectId,
      select: React.useCallback((data) => data.sort(sortUpperCase), []),
    },
  });

  const { data: nodes, isError: isNodesError } = SystemApi.useNodes({
    projectId,
    options: {
      enabled: !!projectId,
      select: React.useCallback((data) => data.map((d: any) => ({ value: d.id, label: d.name })), []),
    },
  });

  const { data: activities, isError: isActivitiesError } = SystemApi.useActivities({
    projectId,
    options: {
      enabled: !!projectId,
      select: React.useCallback((data) => data.map((d: any) => ({ value: d.id, label: d.name })), []),
    },
  });

  // validate that all fields in the form have been completed
  function validate(formData: any) {
    let error = true;
    if (
      formData.name.value === "" ||
      formData.category.value === "Select Category" ||
      formData.isInternal.value === null
    ) {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const isValid = validate(e.currentTarget.elements);

    if (isValid && isTextValid) {
      const form = e.currentTarget;
      const formElements = form.elements as typeof form.elements & { name: HTMLInputElement };

      const isNameValid = validateNameUniqueness(resourcesData, formElements.name.value);
      if (!isNameValid) {
        setPostError(FORM_ERROR.duplicateResourceName);
        return null;
      }

      const params = {
        name: formElements.name.value,
        note: form.note.value,
        category: form.category.value,
        isInternal: stringToBoolean(form.isInternal.value),
      };

      const dto = ResourceCreateDto.constructFromObject(params);

      createResource(
        { projectId, resourceCreateDto: dto },
        {
          onSettled: (resourceId) => {
            if (selectedNodes.length > 0 && resourceId) {
              Promise.all(
                selectedNodes.map((c: { value: string }) => addResourceNode({ resourceId, nodeId: c.value }))
              );
            }

            if (selectedActivities.length > 0 && resourceId) {
              Promise.all(
                selectedActivities.map((c: { value: string }) =>
                  addResourceActivity({ resourceId, activityId: c.value })
                )
              );
            }
            setModalIsOpen(false);
          },
          onError: () => {
            setPostError("Error occured while adding resource ");
          },
        }
      );
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  const handleActivitySelectChange = (selectedItems = []) => {
    setSelectedActivities(selectedItems);
  };

  const handleNodesSelectChange = (selectedItems = []) => {
    setSelectedNodes(selectedItems);
  };

  if (isResourceDataError || isElementCategoriesError || isNodesError || isActivitiesError) {
    return <ErrorBanner msg="Error while loading required info for adding resource" />;
  }

  if (elementCategories && nodes && activities) {
    return (
      <div>
        <form onSubmit={handleSubmit} action="">
          <FormStyled>
            <div className="form-style">
              <TextField
                label={{ id: "AddResourceForm_name", name: FORM_LABEL.nameMandatory }}
                input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "AddResourceForm_note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />

              <BooleanDropdown
                label={{ id: "AddResourceForm_isInternal", name: FORM_LABEL.internalMandatory }}
                select={{ id: "isInternal", name: "isInternal" }}
              />

              <label id="AddResourceForm_category">{FORM_LABEL.categoryMandatory}</label>
              <DynamicSelectBoxCategory arrayOfData={elementCategories} name="Category" item="category" />
              <label id="AddResourceForm_nodes">{FORM_LABEL.nodesOptional}</label>
              <SelectAll
                isMulti
                options={nodes}
                defaultValue={selectedNodes}
                value={selectedNodes}
                onChange={handleNodesSelectChange}
                allowSelectAll
                allOption={{
                  label: "Select all",
                  value: "*",
                }}
                elementId="AddMissionForm_nodes"
              />

              <br />
              <label id="AddResourceForm_activities">{FORM_LABEL.activitiesOptional}</label>
              <SelectAll
                isMulti
                options={activities}
                defaultValue={selectedActivities}
                value={selectedActivities}
                onChange={handleActivitySelectChange}
                allowSelectAll
                allOption={{
                  label: "Select all",
                  value: "*",
                }}
                elementId="AddMissionForm_Activity"
              />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="AddResourceForm_cancelButton">
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled type="submit" id="AddResourceForm_addButton">
              Add
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }
  return <Loading />;
};

AddMissionForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};

export default AddMissionForm;
