import { useState, useMemo, useRef, useCallback } from "react";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { AdminApi, KnowledgebaseApi } from "features/brm";
import { BrmMainTable } from "brm/tables/BrmTables";
import { userRoles, projectApi } from "services/brm/admin-service";
import PropTypes from "prop-types";
import handleApi from "services/brm/api-util";
import { userRoleState, userIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue } from "recoil";
import { useRoles } from "features/brm/hooks/useRoles";
import { TYPE, COMMON } from "constants/brm";
import {
  createColumnNameNoNav,
  createColumnModifiedAt,
  createColumnCreatedAt,
  createColumnMapped,
  createColumnMappedNoEdit,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
// import { useOrganizationKb } from "../api";

export const KBOrganizationTable = ({ setSelectedRows, setRefresh }) => {
  const userId = useRecoilValue(userIdState);
  const { isAdmin, isSuperAdmin } = useRoles();
  const { data: userOrgId, isError: isUserOrgDataError } = AdminApi.useUserPrimaryOrganization({
    userId,
    options: {
      enabled: Boolean(userId) && (isAdmin || isSuperAdmin),
      select: (data) => data.id,
    },
  });
  const { data: kbByOrgData, isError: isKbByOrgDataError } = KnowledgebaseApi.useOrganizationKb({
    orgId: userOrgId,
    options: { enabled: Boolean(userOrgId) },
  });
  const [editMode, setEditMode] = useState(false);
  const updatedKb = useRef("");
  const userRole = useRecoilValue(userRoleState);
  const cyberSecurityExpertRole = userRoles.properties[userRoles.CYBERSECURITY_EXPERT].key;
  const selectedInstance = useRecoilValue(selectedInstanceState);

  const createButton = useCallback(
    (cellProps) => {
      if (userRole === cyberSecurityExpertRole) {
        return (
          <ButtonCell
            selectedRowId={selectedInstance.id}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedKb.current !== "") {
                await handleApi(
                  projectApi.setProjectKbWithHttpInfo(selectedInstance.id, {
                    body: updatedKb.current,
                  })
                );
                updatedKb.current = "";
              }
              setRefresh(true);
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return COMMON.na;
    },
    [cyberSecurityExpertRole, editMode, setRefresh, selectedInstance.id, userRole]
  );

  const columns = useMemo(
    () => [
      createColumnNameNoNav(selectedInstance.id, editMode, null),
      createColumnMappedNoEdit("org"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnMapped("kb", selectedInstance.id, kbByOrgData, editMode, updatedKb),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [editMode, selectedInstance.id, kbByOrgData, createButton]
  );

  if (isKbByOrgDataError || isUserOrgDataError) {
    return <ErrorBanner msg="Error while loading knowledgebase data" />;
  }

  if (kbByOrgData) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={kbByOrgData}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "KBProjectTable_table" }}
            elementName={TYPE.project}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

KBOrganizationTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

// export default KBOrganizationTable;
