/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { ActionButton, ErrorSpan, LoadingSpinner } from "components/elements";
import { sortByLabel } from "utils/sorting";
import { IOption } from "types";
import { useSap } from "features/admin/hooks";
import { AdminApi, isProjectNameValid } from "features/brm";
import { createOptionsFromProps } from "utils/react-select-utils";
import { MultiSelect } from "./Select";
import { useCreateProjectContext } from "./CreateProjectContext";
import * as CommonStyle from "./styles";
import { ICreateProjectInput } from "./types";

interface IUser {
  id: string;
  username: string;
}

interface IBrmError {
  status: number;
  response: Response;
  statusText: string;
}

export const CreateProjectStepFormView = ({
  userId,
  organizationUsers,
  organizationProjects,
}: {
  userId: string;
  organizationUsers: any[];
  organizationProjects: any[];
}) => {
  const userToOption = (u: IUser): IOption => ({ value: u.id, label: u.username });
  const allUserOptions = organizationUsers?.map(userToOption).sort(sortByLabel) || [];

  const userSelfOption = allUserOptions.find((u) => u.value === userId);
  const {
    isEnabled: isSapEnabled,
    isError: isSapError,
    error: sapError,
    isLoading: isSapLoading,
  } = useSap({ isAdminMode: true });

  // We can only associate programs to which this user has access to
  const { data: userSapProgramOptions } = AdminApi.useGetSapPrograms({
    queryParam: {
      user: userId!,
    },
    options: {
      select: (data) => createOptionsFromProps({ src: data, value: "id", label: "programname" }),
    },
  });

  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { isValid, errors },
  } = useForm<ICreateProjectInput>({
    mode: "onChange",
    defaultValues: {
      users: [userSelfOption],
    },
  });
  const { next, close, createProject } = useCreateProjectContext();

  const isProjectNameUnique = React.useCallback(
    (projectName: string) => {
      const projName = projectName.trim();
      if (Array.isArray(organizationProjects) && projName.length) {
        return !organizationProjects.some((o) => o.name === projName);
      }
      return false;
    },
    [organizationProjects]
  );

  const onSubmit = (data: ICreateProjectInput) => {
    createProject(data);
    next();
  };

  const selUsers = watch("users");
  const selReadonlyUser = watch("readOnlyUsers");

  const userOptions = allUserOptions.filter((u) =>
    selReadonlyUser ? !selReadonlyUser.some((su) => u.value === su.value) : true
  );

  const readOnlyUserOptions = allUserOptions.filter((u) => !selUsers.some((su) => u.value === su.value));

  if (isSapError) {
    if ((sapError as unknown as IBrmError).status === 403) {
      return <ErrorSpan>Unauthorized: You do not have sufficent SAP Permissions to create projects</ErrorSpan>;
    }
  }

  if (isSapLoading) {
    return <LoadingSpinner message="Verifying Permissions...." />;
  }

  return (
    <CommonStyle.StepForm onSubmit={handleSubmit(onSubmit)} id="createProject">
      <fieldset>
        <legend>Create a Project</legend>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Name (required)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter project name"
            {...register("name", {
              required: true,
              validate: {
                validCharacters: (v) => isProjectNameValid(v) || `Invalid Character(s).`,
                unique: (v) =>
                  isProjectNameUnique(v) || `A project with this name already exists. Choose a unique name.`,
              },
            })}
            aria-invalid={errors.name ? "true" : "false"}
          />
          {errors.name === undefined ? <Form.Text className="text-muted">Enter name for your project</Form.Text> : null}
          {errors.name && errors.name.type === "required" && <ErrorSpan>This field is required</ErrorSpan>}
          {errors.name && (errors.name.type === "validCharacters" || errors.name.type === "unique") && (
            <ErrorSpan>{errors.name.message}</ErrorSpan>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formProjectNote">
          <Form.Label>Note (optional)</Form.Label>
          <Form.Control type="text" placeholder="Enter project note" {...register("note")} />
          <Form.Text className="text-muted">Enter a note for your project</Form.Text>
        </Form.Group>

        {isSapEnabled ? (
          <Form.Group className="mb-3" controlId="formProjectNote">
            <Form.Label>Jade Programs</Form.Label>
            <Controller
              name="sapPrograms"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  closeMenuOnSelect={false}
                  options={userSapProgramOptions}
                  // defaultValue={sapOptions[0]
                  placeholder="Select Jade Programs..."
                />
              )}
            />
            <Form.Text className="text-muted">
              Note: Without a Jade program selected you will be unable to add users to the created project.
            </Form.Text>
          </Form.Group>
        ) : null}

        <Form.Group className="mb-3" controlId="formProjectUsers">
          <Form.Label>Add users (optional)</Form.Label>
          <Controller
            name="users"
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                closeMenuOnSelect={false}
                options={userOptions}
                defaultValue={userOptions.find((u) => u.value === userId)}
                placeholder="Select Users..."
              />
            )}
          />
          <Form.Text className="text-muted">
            Note: You need to add yourself to the project in order to proceed with the configuration step
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formProjectReadOnlyUsers">
          <Form.Label>Add additional readonly users if required (optional)</Form.Label>
          <Controller
            name="readOnlyUsers"
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                closeMenuOnSelect={false}
                options={readOnlyUserOptions}
                placeholder="Select Readonly Users..."
              />
            )}
          />
        </Form.Group>
      </fieldset>
      <CommonStyle.ButtonContainer>
        <ActionButton onClick={close}>Cancel</ActionButton>
        <ActionButton type="submit" form="createProject" disabled={!isValid}>
          Create Project
        </ActionButton>
      </CommonStyle.ButtonContainer>
    </CommonStyle.StepForm>
  );
};
