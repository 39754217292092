import { useState, useEffect } from "react";
import systemModals from "components/modals/Modals";
import { AddButton, DeleteButton, MitigationApi, currentVariantState } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { projectIdState, userRoleState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState, useSetRecoilState, useResetRecoilState } from "recoil";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { COMMON, TYPE } from "constants/brm";
import { isRiskAnalyst } from "services/user";
import { isVariantDataUpdatedState, variantIdState } from "atoms/atoms-component";
import { defaultReportTabState, isReportsTabDefaultState } from "atoms";
import ContentLayout from "brm/layout/Content/ContentLayout";
import { AddVariantForm } from "../../components/forms/AddVariantForm";
import { VariantTable } from "../../components/tables/VariantTable";

export const Variants = () => {
  let content = null;
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const projectId = useRecoilValue(projectIdState);
  const [variantId, setVariantId] = useRecoilState(variantIdState);
  const [currentVariant, setCurrentVariant] = useRecoilState(currentVariantState);
  const isVariantDataUpdated = useSetRecoilState(isVariantDataUpdatedState);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const userRole = useRecoilValue(userRoleState);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);
  // const [, setNewVariantId] = useState("");
  // const [, setNewVariantName] = useState("");
  const resetIsReportsTabDefault = useResetRecoilState(isReportsTabDefaultState);
  const resetDefaultReportsTab = useResetRecoilState(defaultReportTabState);
  const {
    data: nullVariantScoreData,
    isError: isNullVariantScoreDataError,
    refetch: refetchUnmitigatedScore,
  } = MitigationApi.useUnmitigatedScore({
    projectId,
    options: { enabled: !!projectId },
  });
  const { mutate: deleteVariant } = MitigationApi.useDeleteVariant();

  useEffect(() => {
    resetDefaultReportsTab();
    resetIsReportsTabDefault();
  }, [resetDefaultReportsTab, resetIsReportsTabDefault]);

  useEffect(() => {
    refetchUnmitigatedScore();
  }, [projectId, refetchUnmitigatedScore]);

  useEffect(() => {
    async function multiDeleteApiCalls() {
      Promise.all(
        selectedRows.map((each) => {
          if (each.id === variantId) {
            setVariantId(COMMON.nullUuid);
          }

          // If the variant to be deleted is selected in the top header, it needs to be cleared off.
          if (currentVariant !== null && each.id === currentVariant.id) {
            setCurrentVariant(null);
          }

          return deleteVariant({ variantId: each.id });
        })
      )
        .then(() => {
          setConfirmDelete(false);
          setSelectedRows([]);
          setDeleteModalIsOpen(false);
          setSelectedInstance({});
          isVariantDataUpdated(true);
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [
    confirmDelete,
    currentVariant,
    deleteVariant,
    isVariantDataUpdated,
    selectedRows,
    setCurrentVariant,
    setSelectedInstance,
    setVariantId,
    variantId,
  ]);

  const addVariant = () => {
    setModalIsOpen(true);
  };

  if (nullVariantScoreData?.value) {
    content = <p style={{ paddingLeft: "10px" }}>Initial Risk Score = {nullVariantScoreData.value?.toFixed(1)}</p>;
  }

  if (nullVariantScoreData?.value === null) {
    content = <em style={{ paddingLeft: "10px" }}>Calculate risk for initial risk score</em>;
  }

  if (isNullVariantScoreDataError) {
    content = "Error in getting initial risk score";
  }

  const variantHeader = (
    <Title id="Variant_title">
      Variants
      <Buttons>
        {isRiskAnalyst(userRole) && selectedRows?.length > 0 ? (
          <>
            <DeleteButton id="Variant_deleteButton" onClick={() => setDeleteModalIsOpen(true)} />
          </>
        ) : (
          <></>
        )}
        {isRiskAnalyst(userRole) ? (
          <>
            <AddButton id="Variant_addButton" onClick={addVariant} />
          </>
        ) : (
          <></>
        )}
      </Buttons>
    </Title>
  );

  const variantMain = (
    <>
      {content}

      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "variant_Page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.variant}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.variant
        )}

      {modalIsOpen &&
        systemModals.customModal(
          "variant_Page",
          modalIsOpen,
          setModalIsOpen,
          <AddVariantForm
            setModalIsOpen={setModalIsOpen}
            onNewVariant={() => {}}
            // setNewVariantName={setNewVariantName}
            // setNewVariantId={setNewVariantId}
          />,
          TYPE.variant
        )}

      <VariantTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="variant_Page" header={variantHeader} main={variantMain} />;
};
