/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { ThemeContext } from "styled-components";
import { FilterOption as Option } from "./FilterOption";
import { FilterValueContainer as ValueContainer } from "./FilterValueContainer";
import { getFilterStyle } from "./filter-select.style";
import { FilterMenuList as MenuList } from "./FilterMenuList";
import { FilterControl as Control } from "./FilterControl";

export const FilterSelect = ({ filterTitle, color, ...rest }) => {
  const theme = React.useContext(ThemeContext);
  return (
    <ReactSelect
      {...rest}
      name={filterTitle}
      hideSelectedOptions={false}
      isMulti
      inputId={filterTitle}
      closeMenuOnSelect={false}
      components={{ Option, ValueContainer, MenuList, Control }}
      filterTitle={filterTitle}
      filterColor={color}
      isClearable={false}
      styles={getFilterStyle(theme)}
      isSearchable={false}
    />
  );
};

FilterSelect.propTypes = {
  filterTitle: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  color: PropTypes.string,
};
