import styled from "styled-components/macro";

export const WizardUpload = styled.div`
  .wizardInput {
    float: left;
    padding-top: ${({ theme }) => theme.sizes.defaultPadding};
    padding-bottom: ${({ theme }) => theme.sizes.defaultPadding};
    font-size: 1rem;
  }

  .uploadButton {
    float: right;
    padding-right: ${({ theme }) => theme.sizes.defaultPadding};
  }

  .uploadSpinner {
    text-align: center;
    font-size: 16px;
  }
`;

export const DefaultWizard = styled.div`
  .wizardTitle {
    text-align: center;
  }
`;

export const DodafWizard = styled.div`
  /* padding-left: ${({ theme }) => theme.sizes.defaultPadding}; */
  .wizardTitle {
    text-align: center;
  }
  .inputLabel {
    padding: 0px 3px;
  }
`;

export const WizardResponsePanel = styled.div`
  padding-top: ${({ theme }) => theme.sizes.defaultPadding};
`;
