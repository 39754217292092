import { useState, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import {
  createColumnModifiedAt,
  createColumnCreatedAt,
  createColumnNameDetails,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { MitigationApi, useProject } from "features/brm";
import { RoutePath } from "routes/route-paths";
import { LoadingSpinner } from "components/elements";

const VariantTable = () => {
  // const [variantData, setVariantData] = useState([]);
  const [, setSelectedVariant] = useState("");

  const [, { projectId }] = useProject();

  // Note: we are using project variants here instead of system variants just to get our
  // catalog and baseline.  Currently these are always the same as system variants just with
  // more data but if that changes then this code will have to get more complicated.
  const { data: variantData } = MitigationApi.useVariantsWithScores({
    projectId,
    options: { enabled: !!projectId },
  });

  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Variant.replace(":id", "")),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnMappedNoEdit("cntrlCatalog"),
      createColumnMappedNoEdit("baseline"),
    ],
    []
  );

  // useEffect(() => {
  //   function getElementRequest() {
  //     switch (elementNameInUrl) {
  //       case DetailTableType.SYSTEM.key: {
  //         return systemApi.getSystemVariantWithHttpInfo(selectedElement.id);
  //       }
  //       default: {
  //         break;
  //       }
  //     }
  //     throw new BrmError(`Unsupported element type:${elementNameInUrl}`);
  //   }

  //   async function getData() {
  //     try {
  //       const res = await getElementRequest();
  //       if (res?.data) {
  //         errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
  //         setVariantData(res.data);
  //       }
  //     } catch (err) {
  //       console.error("There was an error getting variant ", err);
  //     }
  //   }
  //   getData();
  // }, [elementNameInUrl, selectedElement.id]);

  if (variantData) {
    return (
      <S.DetailsContainer id="VariantTable_detailsPanel">
        <BrmDetailTable
          data={variantData}
          columns={columns}
          setSelectedElement={setSelectedVariant}
          customProps={{ id: "VariantTable_detailsTable" }}
          showRowSelect={false}
        />
      </S.DetailsContainer>
    );
  }
  return <LoadingSpinner />;
};

VariantTable.propTypes = {};

export default VariantTable;
