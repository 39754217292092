import * as Recoil from "recoil";
import { useContext } from "react";

// Components
import { BasicButton as Button } from "components/elements";
import { MdOutlineHelp } from "react-icons/md";

// URLS
import { ReactComponent as NotesIcon } from "assets/icons/studio91/sidebars-toolbars/notes.svg";
import { ThemeContext } from "styled-components";
import { CONTEXT_SIDEBAR_CONTENT, contextSidebarContentState, DrawerContentType, useProjectStatus } from "features/brm";
import { useRoles } from "hooks";

export const RightSidebarFixedButtons = ({ onClick }: { onClick: (type: DrawerContentType) => void }) => {
  const { isControlsCoordinator, isCyberSecurityExpert, isSuperAdmin, isAdmin } = useRoles();
  const { isReadable } = useProjectStatus();
  const setContentType = Recoil.useSetRecoilState(contextSidebarContentState);

  const theme = useContext(ThemeContext);

  const {
    colors: { buttonPrimary: buttonFill },
    sizes: { standaloneIconWidth: iconWidth, standaloneIconWidth: iconHeight },
  } = theme;

  const handleButtonClick = (contentType: DrawerContentType) => {
    onClick(contentType);
    setContentType(contentType);
  };

  let disabled = !isReadable;
  if (isControlsCoordinator || isCyberSecurityExpert || isSuperAdmin || isAdmin) {
    disabled = false;
  }

  return (
    <>
      <span title="Help">
        <Button onClick={() => handleButtonClick(CONTEXT_SIDEBAR_CONTENT.help)}>
          <MdOutlineHelp fill={buttonFill} size={26} />
        </Button>
      </span>

      <span title="Notes">
        <Button onClick={() => handleButtonClick(CONTEXT_SIDEBAR_CONTENT.notes)} disabled={disabled}>
          <NotesIcon fill={buttonFill} height={iconHeight} width={iconWidth} />
        </Button>
      </span>
    </>
  );
};
