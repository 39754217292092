import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./findings-query-key-factory";

interface IGetFindings {
  nodeId: string;
}

/**
 * Returns a list of findings for the given project and node
 *
 * @param {string} projectId  - uuid of the project
 * @param {string} nodeId - uuid of the node
 * @returns list of findings for the given node
 */
export const getFindings = ({ nodeId }: IGetFindings) => {
  return Brm.nodeApi.getNodeFinding(nodeId);
};

interface IUseFindings {
  nodeId: string;
  options?: QueryOption<typeof getFindings>;
}

/**
 * React-query hook for getting all exchanges
 *
 * @param {string} nodeid - uuid of the node
 * @param {object} options - react-query configuration object
 * @returns react-query for useFindings
 */
export const useFindings = ({ nodeId, options }: IUseFindings) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.findingsKeys.node(nodeId),
    queryFn: () => getFindings({ nodeId }),
  });
};
