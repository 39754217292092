import PropTypes from "prop-types";
import { useState } from "react";
import Select from "react-select";

import {
  selectedControlColumnState,
  selectedEffectiveState,
  selectedHeaderRowState,
  selectedWorksheetState,
} from "atoms/atoms-sctm-wizard";
import { useSetRecoilState } from "recoil";
import { isSubmissionPendingState } from "atoms/atoms-importer";

// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { CheckBox } from "components/elements";
import * as S from "./SCTM.styles";
// import sctmMenuStyles from "./sctm-menu-styles";

// Constants
import { SCTM_FORM_ELEMENTS } from "../../constants";

const getReactSelectArray = (array) => array.map((obj) => ({ value: obj, label: obj }));

function Step1({
  currentStep,
  options,
  setUploadModalIsOpen,
  isAutoAllocate,
  setIsAutoAllocate,
  excludeNonCompliantControls,
  setExcludeNonCompliantControls,
  scope,
  setScope,
  handleNext,
  onCancel,
}) {
  const setIsSubmissionPending = useSetRecoilState(isSubmissionPendingState);

  const [effective, setEffective] = useState(
    options[SCTM_FORM_ELEMENTS.convertEffective] ? options[SCTM_FORM_ELEMENTS.convertEffective].defaults : []
  );
  const [selectedWorkSheetOptions, setSelectedWorkSheetOptions] = useState(
    getReactSelectArray(options.worksheet.defaults)
  );
  const [selectedHeaderRow, setSelectedHeaderRow] = useState(
    getReactSelectArray(options[SCTM_FORM_ELEMENTS.headerRow].defaults)
  );
  const [selectedControlColumn, setSelectedControlColumn] = useState(
    getReactSelectArray(options[SCTM_FORM_ELEMENTS.controlColumn].defaults)
  );

  // recoil
  const selectedEffective = useSetRecoilState(selectedEffectiveState);
  const selectedWorksheet = useSetRecoilState(selectedWorksheetState);
  const selectedHeader = useSetRecoilState(selectedHeaderRowState);
  const selectedControl = useSetRecoilState(selectedControlColumnState);

  // select input handlers
  const handleWorkSheetSelectChange = (selectedItems = {}) => {
    setSelectedWorkSheetOptions([selectedItems]);
  };
  const handleHeaderRowSelectChange = (selectedItems = {}) => {
    setSelectedHeaderRow([selectedItems]);
  };
  const handleControlColumnSelectChange = (selectedItems = {}) => {
    setSelectedControlColumn([selectedItems]);
  };
  const handleAutoAllocateCheckBoxChange = () => {
    setIsAutoAllocate(!isAutoAllocate);
  };
  const handleExcludeNonCompliantControlsChange = () => {
    setExcludeNonCompliantControls(!excludeNonCompliantControls);
  };

  function handleFormSubmit() {
    selectedEffective(effective);
    selectedWorksheet(selectedWorkSheetOptions);
    selectedHeader(selectedHeaderRow);
    selectedControl(selectedControlColumn);
    handleNext();
  }

  function showValue(value) {
    if (value === "Import as System") {
      return <span title="Imports the file as system">Import at System Level</span>;
    }
    return <span title="Imports the file by selecting system objects">Import at Node/Exchange Level</span>;
  }

  if (currentStep !== 1) {
    return null;
  }
  return (
    <S.SCTMStep1 id="SCTM_step1">
      {options && (
        <form onSubmit={handleFormSubmit} action="" id="Sctm_step1Form">
          <S.Row>
            <S.Column>
              <label>Select the worksheet by name:</label>
            </S.Column>
            <S.Column>
              <Select
                isMulti={false}
                options={getReactSelectArray(options.worksheet.values)}
                defaultValue={selectedWorkSheetOptions}
                onChange={handleWorkSheetSelectChange}
                id="SCTM_worksheetDropdown"
                // classNamePrefix="worksheetDropdown"
                // styles={sctmMenuStyles}
              />
            </S.Column>
          </S.Row>
          <S.Row>
            <S.Column>
              <label>Select the header row number:</label>
            </S.Column>
            <S.Column>
              <S.SelectMargin>
                <Select
                  isMulti={false}
                  options={getReactSelectArray(options[SCTM_FORM_ELEMENTS.headerRow].values)}
                  defaultValue={selectedHeaderRow}
                  onChange={handleHeaderRowSelectChange}
                  id="SCTM_headerRowDropdown"
                  // classNamePrefix="headerRowDropdown"
                  // styles={sctmMenuStyles}
                />
              </S.SelectMargin>
            </S.Column>
          </S.Row>

          <S.Row>
            <S.Column>
              <label>Select the column name with control ID:</label>
            </S.Column>
            <S.Column>
              <Select
                isMulti={false}
                options={getReactSelectArray(options[SCTM_FORM_ELEMENTS.controlColumn].values)}
                defaultValue={selectedControlColumn}
                onChange={handleControlColumnSelectChange}
                id="SCTM_controlIdDropdown"
                // classNamePrefix="controlIdDropdown"
                // styles={sctmMenuStyles}
              />
            </S.Column>
          </S.Row>

          <div>
            <S.SCTMHeadings>Import Scope of controls </S.SCTMHeadings>
            {options[SCTM_FORM_ELEMENTS.importScope] &&
              options[SCTM_FORM_ELEMENTS.importScope].values.map((val) => (
                <S.SCTMOptions key={val}>
                  {scope.length > 0 &&
                    scope.map((defaultValue) => (
                      <div key={defaultValue}>
                        <input
                          type="radio"
                          name={val}
                          id={val}
                          value={val}
                          checked={val === defaultValue}
                          onChange={(e) => setScope([e.target.value])}
                        />
                        <label style={{ paddingLeft: "5px" }}>{showValue(val)}</label>
                      </div>
                    ))}
                </S.SCTMOptions>
              ))}
          </div>

          <S.SCTMHeadings>Import Controls</S.SCTMHeadings>
          <S.SCTMOptions>
            <CheckBox
              label="Auto allocate Exchanges"
              isSelected={isAutoAllocate}
              onCheckboxChange={handleAutoAllocateCheckBoxChange}
            />
          </S.SCTMOptions>
          <S.SCTMOptions>
            <CheckBox
              label="Exclude non-compliant controls"
              isSelected={excludeNonCompliantControls}
              onCheckboxChange={handleExcludeNonCompliantControlsChange}
            />
          </S.SCTMOptions>

          <S.SCTMHeadings>Convert all effective &#39;-&#39; as:</S.SCTMHeadings>
          <div>
            {options[SCTM_FORM_ELEMENTS.convertEffective] &&
              options[SCTM_FORM_ELEMENTS.convertEffective].values.map((val) => (
                <S.SCTMOptions key={val}>
                  {effective.length > 0 &&
                    effective.map((defaultValue) => (
                      <div key={defaultValue}>
                        <input
                          type="radio"
                          name={val}
                          id={val}
                          value={val}
                          checked={val === defaultValue}
                          onChange={(e) => setEffective([e.target.value])}
                        />
                        <label style={{ paddingLeft: "5px" }}>{val}</label>
                      </div>
                    ))}
                </S.SCTMOptions>
              ))}
          </div>

          <DialogButtonDivStyled id="ProjectAddForm_buttons">
            <DialogButtonStyled
              onClick={() => {
                setIsSubmissionPending(false);
                setUploadModalIsOpen(false);
                onCancel();
              }}
            >
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled type="submit"> Next </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      )}
    </S.SCTMStep1>
  );
}

Step1.propTypes = {
  currentStep: PropTypes.number,

  handleNext: PropTypes.func,
  scope: PropTypes.shape({
    length: PropTypes.func,
    map: PropTypes.func,
  }),
  setScope: PropTypes.func,
  setIsAutoAllocate: PropTypes.func,
  isAutoAllocate: PropTypes.bool,

  setExcludeNonCompliantControls: PropTypes.func,
  excludeNonCompliantControls: PropTypes.bool,

  options: PropTypes.shape({
    "convert-effective": PropTypes.shape({
      defaults: PropTypes.any,
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    "header-row": PropTypes.shape({
      defaults: PropTypes.any,
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    "control-column": PropTypes.shape({
      defaults: PropTypes.any,
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    "import-scope": PropTypes.shape({
      defaults: PropTypes.any,
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    systems: PropTypes.shape({
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    nodes: PropTypes.shape({
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    exchanges: PropTypes.shape({
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    worksheet: PropTypes.shape({
      defaults: PropTypes.any,
      values: PropTypes.shape({
        map: PropTypes.func,
      }),
    }),
    currentStep: PropTypes.any,
  }),
  setUploadModalIsOpen: PropTypes.func,
  onCancel: PropTypes.func,
};

export default Step1;
