import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

interface IGetSystemAssets {
  projectId: string;
  saCat: String;
  saType: string;
  owner: String;
}

export const getSystemAssets = ({ projectId, saCat, saType, owner }: IGetSystemAssets) => {
  const opts = {
    cat: saCat,
    satype: saType,
    owner,
  };
  return Brm.systemAssetApi.findSystemAsset(projectId, opts);
};

interface IUseSystemAssets {
  projectId: string;
  saCat: String;
  saType: string;
  owner: String;
  options?: QueryOption<typeof getSystemAssets>;
}

/**
 * React-query hook for getting all System Assets
 *
 * @param {string} projectId - uuid of the project
 * @returns react-query for getSystemAssets
 */
export const useSystemAssets = ({ projectId, saCat = "", saType = "", owner = "", options }: IUseSystemAssets) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.systemAssetKeys.project(projectId),
    queryFn: () => getSystemAssets({ projectId, saCat, saType, owner }),
  });
};
