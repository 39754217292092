import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./user-query-key-factory";

/**
 *
 * Gets logged in User's Information
 */
export const getSelf = () => {
  return Brm.userApi.findSelf();
};

interface ISelfProps {
  options?: QueryOption<typeof getSelf>;
}

/**
 * Custom hook for getting user Self information
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useSelf = ({ options }: ISelfProps = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.userKeys.self,
    queryFn: () => getSelf(),
  });
};
