/*
 *  Handling errors
 */

function checkErrorStatus(status, statusText) {
  const navRoute = "";

  if (status !== null) {
    if (status !== 200) {
      // these are success statuses
      if (status !== 201) {
        // some type of error occurred

        // store error in localstorage for display on the error page
        localStorage.setItem("brmerror", JSON.stringify({ statusText, status }));
        const errorMessage = `error in request : ${statusText} status code: ${status}`;
        // alert(errorMessage);
        console.error(errorMessage);
      }
    }
  }
  return navRoute;
}

const errorUtils = {
  checkErrorStatus,
};

export default errorUtils;
