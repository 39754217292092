import { ActionButton } from "components/elements";
import { useCancelContext } from "features/admin/stores/CancelContext/CancelContext";
import * as CommonStyle from "./styles";
import { useConfigureProjectContext } from "./ConfigureProjectContext";

interface IStandardStepButtonProps {
  isBackVisible?: boolean;
  isBackDisabled?: boolean;
  isNextEnabled?: boolean;
  onBack?: () => {};
}

export const StandardStepButtons = ({
  isBackVisible = false,
  isBackDisabled = false,
  isNextEnabled = true,
  onBack = undefined,
}: IStandardStepButtonProps) => {
  const { setIsCancelRequested } = useCancelContext();
  const { prev } = useConfigureProjectContext();

  const handleBack = () => {
    if (onBack) {
      onBack();
    }
    prev();
  };

  return (
    <CommonStyle.ButtonContainer>
      <ActionButton onClick={() => setIsCancelRequested(true)}>Cancel</ActionButton>
      {isBackVisible ? (
        <ActionButton onClick={handleBack} disabled={isBackDisabled}>
          Back
        </ActionButton>
      ) : null}
      <ActionButton type="submit" disabled={!isNextEnabled}>
        Next
      </ActionButton>
    </CommonStyle.ButtonContainer>
  );
};
