import * as React from "react";
import * as Recoil from "recoil";
import { contentKeyState } from "atoms";

export const useContentKey = (contentKey: string) => {
  const setContentKey = Recoil.useSetRecoilState(contentKeyState);

  React.useEffect(() => {
    setContentKey(contentKey);
  }, [setContentKey, contentKey]);
};
