import Alert from "react-bootstrap/Alert";

import * as S from "./InfoBanner.styles";

export const InfoBanner = ({ header = "Information", msg = "" }: { header: string; msg: string }) => {
  return (
    <div>
      <Alert variant="info">
        <Alert.Heading>{header}</Alert.Heading>
      </Alert>
      <S.Contents>
        <S.MessageContainer>
          <p>{msg}</p>
        </S.MessageContainer>
      </S.Contents>
    </div>
  );
};
