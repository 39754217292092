import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./resource-query-key-factory";

export interface ISetResourceIsInternal {
  resourceId: string;
  isInternal: string;
}

/**
 * Sets the isInternal property for resource
 *
 * @param {string} resourceId - uuid of the resource
 * @param {string} isInternal - isInternal value
 */
export const setResourceIsInternal = ({ resourceId, isInternal }: ISetResourceIsInternal) => {
  return Brm.resourceApi.setResourceIsInternal(resourceId, { body: isInternal });
};

export interface IUseSetResourceIsInternal {
  options?: MutationOption<typeof setResourceIsInternal>;
}

/**
 * Custom hook to change the isInternal property of an existing resource
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetResourceIsInternal = ({ options = {} }: IUseSetResourceIsInternal = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.resourceKeys.all);
    },
    ...options,
    mutationFn: setResourceIsInternal,
  });
};
