import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { userIdState } from "atoms/atoms-admin";
import * as Recoil from "recoil";
import { userApi } from "services/brm/admin-service";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { RoutePath } from "routes/route-paths";
import { keycloak } from "libs/keycloak";
import { FiExternalLink } from "react-icons/fi";
import * as S from "./UserAvatarButton.styles";
import { UserInfoAvatar } from "../UserInfoAvatar";

export const UserAvatarButton = () => {
  const userId = Recoil.useRecoilValue(userIdState);
  const { pathname } = useLocation();
  const [name, setName] = useState("");

  useEffect(() => {
    async function getData() {
      const { firstname, lastname } = await userApi.getUserByID(userId);
      setName(`${firstname} ${lastname}`);
    }
    if (userId) {
      getData();
    }
  }, [userId]);

  const handleAccount = () => {
    // keycloak.login({ action: "password" });
    // const url = keycloak.createAccountUrl().replace("/account?", "/account/password?");
    // keycloak.accountManagement();
    keycloak.login({ action: "UPDATE_PASSWORD" });

    // window.location.href = url;
  };

  return (
    <OverlayTrigger
      flip
      rootClose
      trigger="click"
      key="bottom-start"
      placement="bottom-start"
      popperConfig={{
        strategy: "fixed",
      }}
      overlay={
        <S.ShadowPopover id="popover-positioned-bottom-start">
          <S.Group>
            <S.Header>Account</S.Header>
            <UserInfoAvatar />
            <S.MenuDiv onClick={handleAccount}>
              Change Password
              <S.IconAfterDiv>
                <FiExternalLink />
              </S.IconAfterDiv>
            </S.MenuDiv>
          </S.Group>
          <S.Group2>
            <S.MenuLink to={RoutePath.Logout} state={{ previousPath: pathname }}>
              Log out
            </S.MenuLink>
          </S.Group2>
        </S.ShadowPopover>
      }
    >
      <S.AvatarButton name={name} size="28" round />
    </OverlayTrigger>
  );
};
