import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./system-element-query-key-factory";

export const getSystemElements = ({ projectId }: { projectId: string }) => {
  return Brm.systemElementApi.findSystemElement(projectId);
};

interface IUseSystemElements {
  projectId: string;
  options?: QueryOption<typeof getSystemElements>;
}

/**
 * React-query hook for getting all SystemElements
 *
 * @param {string} projectId - uuid of the project
 * @returns react-query for getSystemElements
 */
export const useSystemElements = ({ projectId, options }: IUseSystemElements) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.systemElementKeys.project(projectId),
    queryFn: () => getSystemElements({ projectId }),
  });
};
