import { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import Title from "components/elements/StyledTitle";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import { variantIdState } from "atoms/atoms-component";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { createColumnMapped, createColumnLikelihoodMapped } from "brm/tables/services/column/columnFactory";
import * as TrevQueries from "brm/risk-model/trev/api";
import { LoadingSpinner as Loading } from "components/elements";
import { TYPE } from "constants/brm";
import { RoutePath } from "routes/route-paths";
import { useRoles } from "features/brm";
import ThreatEventDetails from "../details/ThreatEventDetails";

const listUrl = RoutePath.ThreatEvents;

function ThreatEventFullDetails() {
  const { isRiskAnalyst } = useRoles();
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [data, setData] = useState([]);
  const [editMode /* setEditMode */] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [categoryList /* setCategoryList */] = useState([]);
  const [patternList /* setPatternList */] = useState([]);
  const updatedCategory = useRef("");
  const updatedPattern = useRef("");
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const {
    data: trevById,
    isLoading,
    isError,
  } = TrevQueries.useGetThreatEventById(id, variantId, { enabled: isRiskAnalyst });
  const [tabIndex, setTabIndex] = useState(0);
  let content = null;

  const columns = useMemo(
    () => [
      createColumnMapped("category", selectedRowId, categoryList, editMode, updatedCategory),
      createColumnMapped("pattern", selectedRowId, patternList, editMode, updatedPattern),
      createColumnLikelihoodMapped("likelihood"),
      createColumnLikelihoodMapped("mitigatedlikelihood"),
    ],
    [categoryList, patternList, selectedRowId, editMode]
  );

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(listUrl);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    if (trevById) {
      setSelectedElement(trevById);
      setData([trevById]);
    }
  }, [trevById]);

  if (isError) {
    content = <>An error occurred while retrieving Threat Event data.</>;
  }

  if (isLoading) {
    content = <Loading />;
  }

  if (trevById) {
    content = (
      <>
        <Title id="ThreatEventFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "ThreatEventFullDetails_table" }}
              elementName={TYPE.trev}
            />
          </DetailsContainerSingleTable>
        ) : null}

        {selectedElement.id && (
          <DetailsContainerParent showSummaryTable={showSummaryTable}>
            <DetailsContainer id="ThreatEvent_detailsPanel">
              <ThreatEventDetails selectedElement={selectedElement} setTabIndex={setTabIndex} tabIndex={tabIndex} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
  }
  return content;
}

export default ThreatEventFullDetails;
