import * as React from "react";
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./user-query-key-factory";

/**
 * Gets all projects a user has access to
 */
export const getUserAllProjects = (userId: string | null) => {
  return Brm.userApi.getUserAllProject(userId);
};

interface IUserProjectPermissionProps {
  userId: string | null;
  projectId: string;
  options?: QueryOption<typeof getUserAllProjects>;
}

/**
 * Custom hook for validating permissions for a user to access a project
 *
 * @param {string} userId - uuid of user
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query configuration
 * @returns
 */
export const useUserProjectPermission = ({ userId, projectId, options = {} }: IUserProjectPermissionProps) => {
  const userAllProjectQuery = ReactQuery.useQuery({
    queryKey: KeyFactory.userKeys.project(userId),
    queryFn: () => getUserAllProjects(userId),
    ...options,
  });

  return {
    hasProjectPermission: React.useMemo(
      () => userAllProjectQuery.data?.some((project: any) => project.id === projectId) || false,
      [userAllProjectQuery.data, projectId]
    ),
  };
};

export default useUserProjectPermission;
