import styled from "styled-components";
import { ActionButton } from "./ActionButton";

const DialogButtonStyled = styled(ActionButton)`
  border: 1px solid #005ca6;

  border-radius: 10px;
  margin: 10px 5px;
  text-align: center;
  font-size: 16px;
`;

export default DialogButtonStyled;
