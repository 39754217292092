import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./categorized-entity-query-key-factory";

interface ICreateCategorizedEntityProps {
  projectId: string;
  categorizedEntityCreateDto: any;
}

/**
 * Create a categorizedEntity
 *
 * @param {string} projectId uuid of project
 * @param {CategorizedEntityCreateDto} categorizedEntity creation dto
 * @returns id of created node
 */
export const createCategorizedEntity = ({ projectId, categorizedEntityCreateDto }: ICreateCategorizedEntityProps) => {
  return Brm.categorizedEntityApi.createCategorizedEntity(projectId, { categorizedEntityCreateDto });
};

interface ICreateCategorizedEntityQueryProps {
  options?: MutationOption<typeof createCategorizedEntity>;
}

/**
 * Custom hook to create an CategorizedEntity
 *
 * @returns react-query Mutation.
 */
export const useCreateCategorizedEntity = ({ options }: ICreateCategorizedEntityQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.categorizedEntityKeys.all);
    },
    ...options,
    mutationFn: createCategorizedEntity,
  });
};
