import { GraphComponent, GraphEditorInputMode, GraphItemTypes } from "yfiles";
import { DiagramBuilder } from "features/diagram-common";

export default class AttackGraphBuilder extends DiagramBuilder {
  static createGraph() {
    const graphComponent = new GraphComponent();

    const inputMode = new GraphEditorInputMode({
      allowGroupingOperations: false,
      allowCreateNode: false,
      allowCreateEdge: false,
      allowGroupSelection: false,
      allowUngroupSelection: false,
      allowReparentNodes: false,
      allowEditLabel: false,
    });
    inputMode.clickHitTestOrder = [
      GraphItemTypes.LABEL,
      GraphItemTypes.BEND,
      // eslint-disable-next-line no-bitwise
      GraphItemTypes.NODE | GraphItemTypes.EDGE,
    ];

    graphComponent.inputMode = inputMode;
    graphComponent.graph.undoEngineEnabled = true;
    return graphComponent;
  }

  constructor(graph) {
    super(graph);
    // this.nodeIdBinding = (i) => i.dto.id;
    this.nodeIdBinding = (i) => i.id;
    // this.nodeLabelBinding = (i) => i.dto.name;
    this.nodeLabelBinding = (i) => i.name;
    this.edgeIdBinding = "id";

    // this.sourceNodeBinding = (i) => i.producer.id;
    // this.targetNodeBinding = (i) => i.consumer.id;
    this.sourceNodeBinding = (i) => i.source;
    this.targetNodeBinding = (i) => i.target;
  }
}
