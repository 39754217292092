/**
 * BRM API Client - OpenAPI 3.0
 */

import superagent from "superagent";
import querystring from "querystring";
import { API_PATH } from "services/brm/service-util";
import { keycloak } from "libs/keycloak";
import UserService from "services/brm/user-service";
import { getErrorMessage } from "utils/error-message-utils";

/**
 * @module BrmApiClient
 * @version 1.0.0
 */

/**
 * Manages low level client-server communications, parameter marshalling, etc. There should not be any need for an
 * application to use this class directly - the *Api and model classes provide the public API for the service. The
 * contents of this file should be regarded as internal but are documented for completeness.
 * @alias module:BrmApiClient
 * @class
 */
class BrmApiClient {
  constructor() {
    /**
     * The base URL against which to resolve every API call's (relative) path.
     * @type {String}
     * @default http://localhost/brm/v1
     */
    this.basePath = API_PATH;

    /**
     * The authentication methods to be included for all API calls.
     * @type {Array.<String>}
     */
    this.authentications = {
      BearerAuth: { type: "bearer" },
    };

    /**
     * The default HTTP headers to be included for all API calls.
     * @type {Array.<String>}
     * @default {}
     */
    this.defaultHeaders = {};

    /**
     * The default HTTP timeout for all API calls.
     * @type {Number}
     * @default 60000
     */
    this.timeout = 60000;

    /**
     * If set to false an additional timestamp parameter is added to all API GET calls to
     * prevent browser caching
     * @type {Boolean}
     * @default true
     */
    this.cache = true;

    /**
     * If set to true, the client will save the cookies from each server
     * response, and return them in the next request.
     * @default false
     */
    this.enableCookies = false;

    /*
     * Used to save and return cookies in a node.js (non-browser) setting,
     * if this.enableCookies is set to true.
     */
    if (typeof window === "undefined") {
      // eslint-disable-next-line new-cap
      this.agent = new superagent.agent();
    }

    /*
     * Allow user to override superagent agent
     */
    this.requestAgent = null;

    /*
     * Allow user to add superagent plugins
     */
    this.plugins = null;

    this.listeners = [];
  }

  addProgressListener(listener) {
    this.listeners = [...this.listeners, listener];
  }

  removeProgressListener(listener) {
    this.listeners = this.listeners.filter((item) => item !== listener);
  }

  onProgress(event) {
    this.listeners.forEach((l) => l(event));
  }

  /**
   * Returns a string representation for an actual parameter.
   * @param param The actual parameter.
   * @returns {String} The string representation of <code>param</code>.
   */
  static paramToString(param) {
    if (param === undefined || param === null) {
      return "";
    }
    if (param instanceof Date) {
      return param.toJSON();
    }

    return param.toString();
  }

  /**
   * Builds full URL by appending the given path to the base URL and replacing path parameter place-holders with parameter values.
   * NOTE: query parameters are not handled here.
   * @param {String} path The path to append to the base URL.
   * @param {Object} pathParams The parameter values to append.
   * @param {String} apiBasePath Base path defined in the path, operation level to override the default one
   * @returns {String} The encoded path with parameter values substituted.
   */
  buildUrl(path, pathParams, apiBasePath) {
    let myPath = path;
    if (!myPath.match(/^\//)) {
      myPath = `/${path}`;
    }

    let url = this.basePath + myPath;

    // use API (operation, path) base path if defined
    if (apiBasePath !== null && apiBasePath !== undefined) {
      url = apiBasePath + myPath;
    }

    url = url.replace(/\{([\w-]+)\}/g, (fullMatch, key) => {
      let value;
      if (Object.prototype.hasOwnProperty.call(pathParams, key)) {
        // if (pathParams.hasOwnProperty(key)) {
        value = BrmApiClient.paramToString(pathParams[key]);
      } else {
        value = fullMatch;
      }

      return encodeURIComponent(value);
    });

    return url;
  }

  /**
   * Checks whether the given content type represents JSON.<br>
   * JSON content type examples:<br>
   * <ul>
   * <li>application/json</li>
   * <li>application/json; charset=UTF8</li>
   * <li>APPLICATION/JSON</li>
   * </ul>
   * @param {String} contentType The MIME content type to check.
   * @returns {Boolean} <code>true</code> if <code>contentType</code> represents JSON, otherwise <code>false</code>.
   */
  static isJsonMime(contentType) {
    return Boolean(contentType != null && contentType.match(/^application\/json(;.*)?$/i));
  }

  /**
   * Chooses a content type from the given array, with JSON preferred; i.e. return JSON if included, otherwise return the first.
   * @param {Array.<String>} contentTypes
   * @returns {String} The chosen content type, preferring JSON.
   */
  static jsonPreferredMime(contentTypes) {
    for (let i = 0; i < contentTypes.length; i += 1) {
      if (BrmApiClient.isJsonMime(contentTypes[i])) {
        return contentTypes[i];
      }
    }

    return contentTypes[0];
  }

  /**
   * Checks whether the given parameter value represents file-like content.
   * @param param The parameter to check.
   * @returns {Boolean} <code>true</code> if <code>param</code> represents a file.
   */
  static isFileParam(param) {
    // fs.ReadStream in Node.js and Electron (but not in runtime like browserify)
    if (typeof require === "function") {
      let fs;
      try {
        // eslint-disable-next-line no-undef
        // eslint-disable-next-line global-require
        fs = require("fs");
      } catch (err) {
        // ignore
      }
      if (fs && fs.ReadStream && param instanceof fs.ReadStream) {
        return true;
      }
    }

    // Buffer in Node.js
    // eslint-disable-next-line no-undef
    if (typeof Buffer === "function" && param instanceof Buffer) {
      return true;
    }

    // Blob in browser
    if (typeof Blob === "function" && param instanceof Blob) {
      return true;
    }

    // File in browser (it seems File object is also instance of Blob, but keep this for safe)
    if (typeof File === "function" && param instanceof File) {
      return true;
    }

    return false;
  }

  /**
   * Normalizes parameter values:
   * <ul>
   * <li>remove nils</li>
   * <li>keep files and arrays</li>
   * <li>format to string with `paramToString` for other cases</li>
   * </ul>
   * @param {Object.<String, Object>} params The parameters as object properties.
   * @returns {Object.<String, Object>} normalized parameters.
   */
  static normalizeParams(params) {
    const newParams = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in params) {
      if (
        Object.prototype.hasOwnProperty.call(params, key) /* params.hasOwnProperty(key) */ &&
        params[key] !== undefined &&
        params[key] !== null
      ) {
        const value = params[key];
        if (BrmApiClient.isFileParam(value) || Array.isArray(value)) {
          newParams[key] = value;
        } else {
          newParams[key] = BrmApiClient.paramToString(value);
        }
      }
    }

    return newParams;
  }

  /**
   * Builds a string representation of an array-type actual parameter, according to the given collection format.
   * @param {Array} param An array parameter.
   * @param {module:BrmApiClient.CollectionFormatEnum} collectionFormat The array element separator strategy.
   * @returns {String|Array} A string representation of the supplied collection, using the specified delimiter. Returns
   * <code>param</code> as is if <code>collectionFormat</code> is <code>multi</code>.
   */
  // eslint-disable-next-line class-methods-use-this
  buildCollectionParam(param, collectionFormat) {
    if (param == null) {
      return null;
    }
    // KDMA: handle and array of files....
    if (param.length > 0) {
      if (param[0] instanceof File) {
        return param;
      }
    }
    switch (collectionFormat) {
      case "csv":
        return param.map(BrmApiClient.paramToString).join(",");
      case "ssv":
        return param.map(BrmApiClient.paramToString).join(" ");
      case "tsv":
        return param.map(BrmApiClient.paramToString).join("\t");
      case "pipes":
        return param.map(BrmApiClient.paramToString).join("|");
      case "multi":
        // return the array directly as SuperAgent will handle it as expected
        return param.map(BrmApiClient.paramToString);
      case "passthrough":
        return param;
      default:
        throw new Error(`Unknown collection format: ${collectionFormat}`);
    }
  }

  /**
   * Applies authentication headers to the request.
   * @param {Object} request The request object created by a <code>superagent()</code> call.
   * @param {Array.<String>} authNames An array of authentication method names.
   */
  applyAuthToRequest(request, authNames) {
    authNames.forEach((authName) => {
      const auth = this.authentications[authName];
      switch (auth.type) {
        case "basic":
          if (auth.username || auth.password) {
            request.auth(auth.username || "", auth.password || "");
          }

          break;
        case "bearer":
          // if (auth.accessToken) {
          //   request.set({ Authorization: `Bearer ${auth.accessToken}` });
          // }
          if (keycloak.token) {
            request.set({ Authorization: `Bearer ${keycloak.token}` });
          }

          break;
        case "apiKey":
          if (auth.apiKey) {
            const data = {};
            if (auth.apiKeyPrefix) {
              data[auth.name] = `${auth.apiKeyPrefix} ${auth.apiKey}`;
            } else {
              data[auth.name] = auth.apiKey;
            }

            if (auth.in === "header") {
              request.set(data);
            } else {
              request.query(data);
            }
          }

          break;
        case "oauth2":
          // if (auth.accessToken) {
          if (keycloak.token) {
            request.set({ Authorization: `Bearer ${keycloak.token}` });
            // request.set({ Authorization: `Bearer ${auth.accessToken}` });
          }

          break;
        default:
          throw new Error(`Unknown authentication type: ${auth.type}`);
      }
    });
  }

  /**
   * Deserializes an HTTP response body into a value of the specified type.
   * @param {Object} response A SuperAgent response object.
   * @param {(String|Array.<String>|Object.<String, Object>|Function)} returnType The type to return. Pass a string for simple types
   * or the constructor function for a complex type. Pass an array containing the type name to return an array of that type. To
   * return an object, pass an object with one property whose name is the key type and whose value is the corresponding value type:
   * all properties on <code>data<code> will be converted to this type.
   * @returns A value of the specified type.
   */
  static deserialize(response, returnType) {
    if (response === null || returnType === null || response.status === 204) {
      return null;
    }

    // Rely on SuperAgent for parsing response body.
    // See http://visionmedia.github.io/superagent/#parsing-response-bodies
    let data = response.body;
    if (
      returnType !== File &&
      (data == null || (typeof data === "object" && typeof data.length === "undefined" && !Object.keys(data).length))
    ) {
      // SuperAgent does not always produce a body; use the unparsed response as a fallback
      data = response.text;
    }

    return BrmApiClient.convertToType(data, returnType);
  }

  /**
   * Invokes the REST service using the supplied settings and parameters.
   * @param {String} path The base URL to invoke.
   * @param {String} httpMethod The HTTP method to use.
   * @param {Object.<String, String>} pathParams A map of path parameters and their values.
   * @param {Object.<String, Object>} queryParams A map of query parameters and their values.
   * @param {Object.<String, Object>} headerParams A map of header parameters and their values.
   * @param {Object.<String, Object>} formParams A map of form parameters and their values.
   * @param {Object} bodyParam The value to pass as the request body.
   * @param {Array.<String>} authNames An array of authentication type names.
   * @param {Array.<String>} contentTypes An array of request MIME types.
   * @param {Array.<String>} accepts An array of acceptable response MIME types.
   * @param {(String|Array|ObjectFunction)} returnType The required type to return; can be a string for simple types or the
   * constructor for a complex type.
   * @param {String} apiBasePath base path defined in the operation/path level to override the default one
   * @returns {Promise} A {@link https://www.promisejs.org/|Promise} object.
   */
  callApi(
    path,
    httpMethod,
    pathParams,
    queryParams,
    headerParams,
    formParams,
    bodyParam,
    authNames,
    contentTypes,
    accepts,
    returnType,
    apiBasePath
  ) {
    // console.log("path", path);
    // UserService.updateToken();
    // this.authentications[BEARER_AUTH].accessToken = UserService.getToken();

    const url = this.buildUrl(path, pathParams, apiBasePath);
    const request = superagent(httpMethod, url);

    if (this.plugins !== null) {
      this.plugins.forEach((index) => {
        // if (this.plugins.hasOwnProperty(index)) {
        if (Object.prototype.hasOwnProperty.call(this.plugins, index)) {
          request.use(this.plugins[index]);
        }
      });
    }

    // apply authentications
    this.applyAuthToRequest(request, authNames);

    // set query parameters
    if (httpMethod.toUpperCase() === "GET" && this.cache === false) {
      // eslint-disable-next-line no-param-reassign
      queryParams._ = new Date().getTime();
    }

    request.query(BrmApiClient.normalizeParams(queryParams));

    // set header parameters
    request.set(this.defaultHeaders).set(BrmApiClient.normalizeParams(headerParams));

    // set requestAgent if it is set by user
    if (this.requestAgent) {
      request.agent(this.requestAgent);
    }

    // set request timeout
    request.timeout(this.timeout);

    const contentType = BrmApiClient.jsonPreferredMime(contentTypes);
    if (contentType) {
      // Issue with superagent and multipart/form-data (https://github.com/visionmedia/superagent/issues/746)
      if (contentType !== "multipart/form-data") {
        request.type(contentType);
      }
    }

    if (contentType === "application/x-www-form-urlencoded") {
      request.send(querystring.stringify(BrmApiClient.normalizeParams(formParams)));
    } else if (contentType === "multipart/form-data") {
      // eslint-disable-next-line no-underscore-dangle
      const _formParams = BrmApiClient.normalizeParams(formParams);
      Object.getOwnPropertyNames(_formParams).forEach((key) => {
        //        if (_formParams.hasOwnProperty(key)) {
        if (Object.prototype.hasOwnProperty.call(_formParams, key)) {
          if (BrmApiClient.isFileParam(_formParams[key])) {
            // file field
            request.attach(key, _formParams[key]);
          } else if (Array.isArray(_formParams[key]) && _formParams[key].length > 0) {
            _formParams[key].forEach((f) => {
              if (f instanceof File) {
                request.attach(key, f);
              }
            });
          } else {
            request.field(key, _formParams[key]);
          }
        }
      });
    } else if (bodyParam !== null && bodyParam !== undefined) {
      if (!request.header["Content-Type"]) {
        request.type("application/json");
      }
      request.send(bodyParam);
    }

    request.on("progress", (e) => {
      this.onProgress(e);
      // console.log("Percentage done", e.percent);
    });

    const accept = BrmApiClient.jsonPreferredMime(accepts);
    if (accept) {
      request.accept(accept);
    }

    if (returnType === "Blob" || returnType === File) {
      request.responseType("blob");
    } else if (returnType === "String") {
      request.responseType("string");
    }

    // Attach previously saved cookies, if enabled
    if (this.enableCookies) {
      if (typeof window === "undefined") {
        // eslint-disable-next-line no-underscore-dangle
        this.agent._attachCookies(request);
      } else {
        request.withCredentials();
      }
    }

    return new Promise((resolve, reject) => {
      keycloak
        .updateToken(5)
        .then((refreshed) => {
          if (refreshed) {
            this.applyAuthToRequest(request, authNames);
          }
          request.end((error, response) => {
            if (error) {
              const err = {};
              if (response) {
                err.status = response.status;
                err.statusText = response.statusText;
                err.body = response.body;
                err.response = response;
              }
              err.error = error;

              reject(err);
            } else {
              try {
                const data = BrmApiClient.deserialize(response, returnType);
                if (this.enableCookies && typeof window === "undefined") {
                  // eslint-disable-next-line no-underscore-dangle
                  this.agent._saveCookies(response);
                }

                resolve({ data, response });
              } catch (err) {
                reject(err);
              }
            }
          });
        })
        .catch((error) => {
          console.error("Keycloak", getErrorMessage(error));
          UserService.doLogout();
        });
    });
  }

  /**
   * Parses an ISO-8601 string representation of a date value.
   * @param {String} str The date value as a string.
   * @returns {Date} The parsed date object.
   */
  static parseDate(str) {
    return new Date(str);
  }

  /**
   * Converts a value to the specified type.
   * @param {(String|Object)} data The data to convert, as a string or object.
   * @param {(String|Array.<String>|Object.<String, Object>|Function)} type The type to return. Pass a string for simple types
   * or the constructor function for a complex type. Pass an array containing the type name to return an array of that type. To
   * return an object, pass an object with one property whose name is the key type and whose value is the corresponding value type:
   * all properties on <code>data<code> will be converted to this type.
   * @returns An instance of the specified type or null or undefined if data is null or undefined.
   */
  static convertToType(data, type) {
    if (data === null || data === undefined) return data;
    switch (type) {
      case "Boolean":
        return Boolean(data);
      case "Integer":
        return parseInt(data, 10);
      case "Number":
        return parseFloat(data);
      case "String":
        return String(data);
      case "Date":
        return BrmApiClient.parseDate(String(data));
      case "Blob":
        return data;
      default:
        if (type === Object) {
          // generic object, return directly
          return data;
        }
        if (typeof type.constructFromObject === "function") {
          // for model type like User and enum class
          return type.constructFromObject(data);
        }
        if (Array.isArray(type)) {
          // for array type like: ['String']
          const itemType = type[0];
          return data.map((item) => BrmApiClient.convertToType(item, itemType));
        }
        if (typeof type === "object") {
          // for plain object type like: {'String': 'Integer'}
          let keyType;
          let valueType;
          for (let i = 0; i < type.length; i += i) {
            const k = type[i];
            if (Object.prototype.hasOwnProperty.call(type, k)) {
              keyType = k;
              valueType = type[k];
              break;
            }
          }

          const result = {};
          data.forEach((d) => {
            // if (data.hasOwnProperty(d)) {
            if (Object.prototype.hasOwnProperty.call(data, d)) {
              const key = BrmApiClient.convertToType(d, keyType);
              const value = BrmApiClient.convertToType(data[d], valueType);
              result[key] = value;
            }
          });

          return result;
        }
        // for unknown type, return the data directly
        return data;
    }
  }

  /**
   * Gets an array of host settings
   * @returns An array of host settings
   */
  static hostSettings() {
    return [
      {
        url: "http://localhost/brm/v1/",
        description: "No description provided",
      },
    ];
  }

  static getBasePathFromSettings(index, variables = {}) {
    const servers = BrmApiClient.hostSettings();

    // check array index out of bound
    if (index < 0 || index >= servers.length) {
      throw new Error(`Invalid index ${index} when selecting the host settings. Must be less than ${servers.length}`);
    }

    const server = servers[index];
    let { url } = server;

    // go through variable and assign a value
    server.variables.forEach((variableName) => {
      if (variableName in variables) {
        const variable = server.variables[variableName];
        if (!("enum_values" in variable) || variable.enum_values.includes(variables[variableName])) {
          url = url.replace(`{${variableName}}`, variables[variableName]);
        } else {
          throw new Error(
            `The variable \`${variableName}\` in the host URL has invalid value ${variables[variableName]}. Must be ${server.variables[variableName].enum_values}.`
          );
        }
      } else {
        // use default value
        url = url.replace(`{${variableName}}`, server.variables[variableName].default_value);
      }
    });

    return url;
  }

  /**
   * Constructs a new map or array model from REST data.
   * @param data {Object|Array} The REST data.
   * @param obj {Object|Array} The target object or array.
   */
  static constructFromObject(data, obj, itemType) {
    const myObj = obj;
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i += 1) {
        // if (data.hasOwnProperty(i)) obj[i] = BrmApiClient.convertToType(data[i], itemType);
        if (Object.prototype.hasOwnProperty.call(data, i)) myObj[i] = BrmApiClient.convertToType(data[i], itemType);
      }
    } else {
      data.forEach((k) => {
        // if (data.hasOwnProperty(k)) obj[k] = BrmApiClient.convertToType(data[k], itemType);
        if (Object.prototype.hasOwnProperty.call(data, k)) myObj[k] = BrmApiClient.convertToType(data[k], itemType);
      });
    }
  }
}

/**
 * Enumeration of collection format separator strategies.
 * @enum {String}
 * @readonly
 */
BrmApiClient.CollectionFormatEnum = {
  /**
   * Comma-separated values. Value: <code>csv</code>
   * @const
   */
  CSV: ",",

  /**
   * Space-separated values. Value: <code>ssv</code>
   * @const
   */
  SSV: " ",

  /**
   * Tab-separated values. Value: <code>tsv</code>
   * @const
   */
  TSV: "\t",

  /**
   * Pipe(|)-separated values. Value: <code>pipes</code>
   * @const
   */
  PIPES: "|",

  /**
   * Native array. Value: <code>multi</code>
   * @const
   */
  MULTI: "multi",
};

/**
 * The default API client implementation.
 * @type {module:BrmApiClient}
 */
BrmApiClient.instance = new BrmApiClient();
export default BrmApiClient;
