import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { QueryOption } from "libs/react-query";
import { ImportContext, ImportContextValues } from "../../constants";
import * as KeyFactory from "./import-query-key-factory";

interface IGetImportStatus {
  projectId: string;
  context: ImportContextValues;
  runId: string;
}

interface ImporterFunctionMap {
  [key: string]: any;
}

const getImportStatus = async ({ projectId, context, runId }: IGetImportStatus) => {
  const importer: ImporterFunctionMap = {
    [ImportContext.bor]: () => Brm.borImportApi.importStatusBor(projectId, runId),
    [ImportContext.baseline]: () => Brm.baselineImportApi.importStatusBaseline(projectId, runId),
    [ImportContext.savepoint]: () => Brm.savePointImportApi.importStatusSavepoint(projectId, runId),
    [ImportContext.sctm]: () => Brm.sctmImportApi.importStatusSctm(projectId, runId),
    [ImportContext.tra]: () => Brm.traImportApi.importStatusTra(projectId, runId),
    [ImportContext.system]: () => Brm.systemImportApi.importStatusSystem(projectId, runId),
  };

  if (!Object.keys(importer).some((k) => k === context)) {
    throw new Error(`Unsupported import context: ${context}`);
  }

  return importer[context]() || null;
};

export interface IUseImportStatus {
  projectId: string;
  context: ImportContextValues;
  runId: string;
  options?: QueryOption<typeof getImportStatus>;
}

export const useImportStatus = ({ projectId, runId, context, options }: IUseImportStatus) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.importKeys.status(projectId, context, runId),
    queryFn: () => getImportStatus({ projectId, context, runId }),
  });
};
