import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./categorized-entity-query-key-factory";

interface ISetCategorizedEntityProps {
  catEntityId: string;
  impactStatementCreateDto: any;
}

/**
 * Sets categorization value of selected categorrized entity
 *
 * @param {string} catEntityId - uuid of the categorized Entity
 * @param {ImpactStatementCreateDto} impactStatementCreateDto - Impact statement
 */
export const setCategorizedEntityCategorization = ({
  catEntityId,
  impactStatementCreateDto,
}: ISetCategorizedEntityProps) => {
  return Brm.categorizedEntityApi.addCategorizedEntityCategorization(catEntityId, { impactStatementCreateDto });
};

interface IUseSetCategorizedEntityProps {
  elementId: string | null;
  elementName: string | null;
  options?: MutationOption<typeof setCategorizedEntityCategorization>;
}

/**
 * Custom hook to change the categroization value of an existing categorized entity
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetCategorizedEntityCategorization = ({
  elementId,
  elementName,
  options = {},
}: IUseSetCategorizedEntityProps) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: setCategorizedEntityCategorization,
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.categorizedEntityKeys.element(elementId, elementName));
    },
  });
};
