const safelyParseJson = (parseString) => {
  try {
    return JSON.parse(parseString);
  } catch (e) {
    console.error(`Unable to parse JSON string:${parseString}`);
    return null;
  }
};

export default safelyParseJson;
