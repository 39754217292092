import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./user-query-key-factory";

interface IUserStatusProps {
  userId: string | null;
  newStatus: string;
}

/**
 * Set the user status
 *
 * @param param0 upload file data
 * @returns
 */
export const setUserStatus = ({ userId, newStatus }: IUserStatusProps) => {
  return userId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.userApi.setUserStatus(userId, { body: newStatus });
};

interface ISetNameProps {
  options?: MutationOption<typeof setUserStatus>;
}

/**
 * mutate the user status.
 *
 * @returns react-query Mutation.
 */
export const useSetUserStatus = ({ options }: ISetNameProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.userKeys.user(variables.userId));
      queryClient.invalidateQueries(KeyFactory.userKeys.all);
    },
    ...options,
    mutationFn: setUserStatus,
  });
};
