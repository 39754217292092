import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./capability-query-key-factory";

interface ICreateCapabilityProps {
  projectId: string;
  capabilityCreateDto: any;
}

/**
 * Creates a capability.
 * @param {string} projectId uuid of the project
 * @param {CapabilityCreateDto} capabilityCreateDto a CapabilityCreateDto object
 * @returns id of created capability
 */
export const createCapability = ({ projectId, capabilityCreateDto }: ICreateCapabilityProps) => {
  return Brm.capabilityApi.createCapability(projectId, { capabilityCreateDto });
};

export interface IUseCreateCapability {
  options?: MutationOption<typeof createCapability>;
}

/**
 * Custom hook to create a new capability.
 *
 * @param {object} config - react-query configuration object
 * @returns react-query for getNodeByID
 */
export const useCreateCapability = ({ options }: IUseCreateCapability = {}) => {
  return ReactQuery.useMutation({
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.capabilityKeys.all);
    },
    mutationFn: createCapability,
  });
};
