import * as React from "react";
import * as Recoil from "recoil";

import { variantIdState } from "atoms";
import log from "loglevel";

import toast from "react-hot-toast";
import { COMMON } from "constants/brm";
import {
  VariantState,
  currentVariantState,
  currentProjectState,
  MitigationApi,
  ReportsApi,
  RiskApi,
} from "features/brm";
import * as BrmGql from "generated/graphql";
import { queryClient } from "libs/react-query";
import { isAutoMitigateInProgressState } from "./auto-mitigate-atoms";

const TOAST_ERROR_DURATION = 5000;
const TOAST_SUCCESS_DURATION = 5000;
const EMPTY_AUTO_MITIGATE_RUN_ID = "";
const POLL_INTERVAL = 1000;

interface IAutoMitigateCallBack {
  onSuccess: Function;
  onError: Function;
}

export const usePerformAutoMitigate = ({ onSuccess, onError }: IAutoMitigateCallBack) => {
  const setIsAutoMitigateInProgress = Recoil.useSetRecoilState(isAutoMitigateInProgressState);

  const currentProject = Recoil.useRecoilValue(currentProjectState);
  const [currentVariant, setCurrentVariant] = Recoil.useRecoilState(currentVariantState);
  const setVariantId = Recoil.useSetRecoilState(variantIdState);

  const [autoMitigateRunId, setAutoMitigateRunId] = React.useState<string>(EMPTY_AUTO_MITIGATE_RUN_ID);
  const [stop, setStop] = React.useState(false);
  const selectedVariant = React.useRef<VariantState>();
  const toastId = React.useRef<string>();

  const { mutate: autoMitigate } = BrmGql.useAutoMitigateMutation({
    onMutate: () => {
      setStop(false);
    },
    onError: (error) => {
      log.error("Calculate Risk Error:", error);
      onError();
      setStop(true);
    },
    onSuccess: (data) => {
      if (data && data.mitigateAndCalcVulnerability) {
        setAutoMitigateRunId(data.mitigateAndCalcVulnerability);
      }
    },
  });

  const { refetch: getAutoMitigateCount } = BrmGql.useAutoMitigateCountQuery(
    { run: autoMitigateRunId },
    {
      enabled: false,
    }
  );

  const getCount = React.useCallback(async () => {
    const { data } = await getAutoMitigateCount();
    if (data) {
      const { mitigateAndCalcControlCount: count } = data;
      return count;
    }
    return 0;
  }, [getAutoMitigateCount]);

  BrmGql.useCalcRiskStatusQuery(
    { run: autoMitigateRunId },
    {
      onSuccess: async (pollData) => {
        if (pollData.calcRiskStatus === BrmGql.CompletionStatus.Completed) {
          const count = await getCount();
          const msg = `Auto Mitigation complete for selected variant! '${count}' allocated controls added and risk re-calculated`;
          toast.success(<span id="autoMitigateToast">{msg}</span>, {
            id: toastId.current,
            duration: TOAST_SUCCESS_DURATION,
          });
          if (
            selectedVariant.current !== undefined &&
            selectedVariant.current != null &&
            currentVariant?.id !== selectedVariant.current.id
          ) {
            setVariantId(selectedVariant.current.id);
            setCurrentVariant({ ...selectedVariant.current, isRecalcNeeded: false });
          } else if (selectedVariant.current !== undefined && selectedVariant.current != null) {
            setCurrentVariant({ ...selectedVariant.current, isRecalcNeeded: false });
          }
          setAutoMitigateRunId(EMPTY_AUTO_MITIGATE_RUN_ID);
          setStop(true);
          // console.log("about to invalidate....");
          queryClient.invalidateQueries(MitigationApi.variantsKeys.all);
          queryClient.invalidateQueries(RiskApi.vulnerabilityGqlKeys.all);
          queryClient.invalidateQueries(RiskApi.vulnerabilityKeys.all);
          queryClient.invalidateQueries(RiskApi.riskKeys.all);
          queryClient.invalidateQueries(RiskApi.undesiredEventKeys.all);
          queryClient.invalidateQueries(ReportsApi.rarKeys.all);
          onSuccess();
          setIsAutoMitigateInProgress(false);
        }
      },
      onError: (error) => {
        log.error("Status query Error:", error);
        setStop(true);
        onError();
        setIsAutoMitigateInProgress(false);
      },
      enabled: autoMitigateRunId !== EMPTY_AUTO_MITIGATE_RUN_ID,
      refetchInterval: stop ? false : POLL_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );

  const performAutoMitgate = (varState: VariantState, vulnerabilityIds: string[]) => {
    if (currentProject?.id !== undefined && varState && varState.id !== COMMON.nullUuid) {
      selectedVariant.current = varState;
      toastId.current = toast.loading(<span id="autoMitigateToast">Auto mitigation in progress...</span>);
      setIsAutoMitigateInProgress(true);

      autoMitigate(
        { project: currentProject?.id, variant: varState.id, vulns: vulnerabilityIds },
        {
          onError: (error) => {
            const msg = `There was an error on Auto Mitigation: ${error}`;
            toast.error(<span id="autoMitigateToast">{msg}</span>, {
              id: toastId.current,
              duration: TOAST_ERROR_DURATION,
            });
            setIsAutoMitigateInProgress(false);
          },
          onSuccess: async () => {},
        }
      );
    }
  };

  return performAutoMitgate;
};
