import * as Recoil from "recoil";
import * as React from "react";
import { isFunction } from "lodash";
import { variantIdState, variantNameState } from "atoms/atoms-component";
import { useRoles } from "features/brm/hooks/useRoles";
import { COMMON, UN_MITIGATED_VARIANT } from "constants/brm";
import { sctmIdState } from "atoms/atoms-mitigation";
// import { ProjectAccessLevel } from "features/brm/constants";
import { currentVariantState, isCurrentProjectPrepState } from "../../stores";
import { VariantState } from "../../types";
import * as MitigationApi from "../../api/mitigation";
import * as ConfigurationApi from "../../api/component/system";
import * as AdminApi from "../../api/admin";
import { useProject } from "../useProject";

const DEFAULT_VARIANT_NAME = "var1";

export const useVariant = () => {
  const [currentVariant, setCurrentVariant] = Recoil.useRecoilState(currentVariantState);
  const isProjectInPrep = Recoil.useRecoilValue(isCurrentProjectPrepState);
  const setVariantId = Recoil.useSetRecoilState(variantIdState);
  const setVariantName = Recoil.useSetRecoilState(variantNameState);
  const setSctmId = Recoil.useSetRecoilState(sctmIdState);
  const resetSctmId = Recoil.useResetRecoilState(sctmIdState);
  const { isRiskAnalyst, isAdmin } = useRoles();
  const [currentProject] = useProject();
  const projId = currentProject?.id || "";
  const varId = currentVariant?.id || "";

  const defaultVariantName = DEFAULT_VARIANT_NAME;

  const { data: projectAccessLevel } = AdminApi.useProjectAccess({
    projectId: projId,
    options: { enabled: !!currentProject },
  });

  const {
    data: variantData,
    isError: isVariantDataError,
    refetch: refetchVariants,
  } = MitigationApi.useVariants({
    projectId: projId,
    options: {
      enabled:
        !!projId && !!projectAccessLevel && currentProject !== null && ((!isProjectInPrep && isRiskAnalyst) || isAdmin),
    },
  });

  const {
    data: systemData,
    isError: isSystemError,
    refetch: refetchSystemData,
  } = ConfigurationApi.useSystems({
    projectId: projId,
    options: {
      enabled:
        !!projId &&
        !!projectAccessLevel &&
        currentProject !== null &&
        ((isRiskAnalyst && !isProjectInPrep) || isAdmin) &&
        !!projectAccessLevel,
    },
  });

  const { data: sctmData } = MitigationApi.useVariantSctm({
    variantId: varId,
    options: {
      enabled:
        currentVariant !== null &&
        !!projectAccessLevel &&
        varId !== COMMON.nullUuid &&
        currentProject?.status === "Active" &&
        isRiskAnalyst,
    },
  });

  const systemVariant = React.useMemo<VariantState | undefined>(() => {
    if (systemData) {
      const sysVariant: VariantState = {
        id: COMMON.nullUuid,
        name: UN_MITIGATED_VARIANT,
        isRecalcNeeded: systemData.isRecalcNeeded,
      };
      return sysVariant;
    }
    const sysVariant: VariantState = {
      id: COMMON.nullUuid,
      name: UN_MITIGATED_VARIANT,
      isRecalcNeeded: false,
    };
    return sysVariant;
  }, [systemData]);

  const variants = React.useMemo(() => {
    // if (!systemVariant) {
    //   return [];
    // }

    let variantStates: VariantState[] = [];
    if (variantData) {
      variantStates = variantData.map(
        (v: any) => ({ id: v.id, name: v.name, isRecalcNeeded: v.isRecalcNeeded } as VariantState)
      );
    }
    return !systemVariant ? variantStates : [systemVariant, ...variantStates];
    // return [systemVariant, ...variantStates];
  }, [variantData, systemVariant]);

  const defaultVariant = variants.find((v) => v.name === defaultVariantName) || systemVariant;

  /**
   *  When project changes the variant is to null we change null to the default variant
   */
  React.useEffect(() => {
    if (currentProject && currentVariant === null && systemVariant && defaultVariant) {
      setVariantId(defaultVariant.id);
      setVariantName(defaultVariant!.name);
      setCurrentVariant(defaultVariant);
    }

    // If the user changes the kb as cybersecurity expert it changes system risk so we have to
    // update the isRiskCalculation property
    if (
      currentProject &&
      currentVariant &&
      systemVariant &&
      defaultVariant &&
      ((currentVariant.id === systemVariant.id && currentVariant.isRecalcNeeded !== systemVariant.isRecalcNeeded) ||
        (currentVariant?.id === defaultVariant?.id && currentVariant.isRecalcNeeded !== defaultVariant.isRecalcNeeded))
    ) {
      const isCalcNeeded =
        currentVariant.id === systemVariant.id ? systemVariant.isRecalcNeeded : defaultVariant.isRecalcNeeded;

      setCurrentVariant((prev) => {
        if (prev !== null) {
          const value = { ...prev, isRecalcNeeded: isCalcNeeded };
          return value;
        }
        return prev;
      });
    }
  }, [currentProject, systemVariant, currentVariant, setCurrentVariant, setVariantName, setVariantId, defaultVariant]);

  /**
   *  when the variant changes update the sctmid
   */
  React.useEffect(() => {
    if (sctmData && currentVariant && currentVariant.id !== COMMON.nullUuid) {
      setSctmId(sctmData.id);
    } else {
      resetSctmId();
    }
  }, [currentVariant, resetSctmId, sctmData, setSctmId]);

  const handlers = React.useMemo(
    () => ({
      setCurrentVariant: (
        valueOrUpdater: VariantState | null | ((currVal: VariantState | null) => VariantState | null)
      ) => {
        const newVariant = isFunction(valueOrUpdater) ? valueOrUpdater(currentVariant) : valueOrUpdater;
        const defVariant = defaultVariant;
        if (newVariant === null && defVariant) {
          setVariantId(defVariant.id);
          setVariantName(defVariant.name);
          setCurrentVariant(defVariant);
        } else if (newVariant === null && systemVariant) {
          setVariantId(systemVariant.id);
          setVariantName(systemVariant.name);
          setCurrentVariant(systemVariant);
        } else if (newVariant === null) {
          setVariantId("");
          setVariantName("");
          setCurrentVariant(null);
        } else {
          setVariantId(newVariant.id);
          setVariantName(newVariant.name);
          setCurrentVariant(newVariant);
        }
      },
      getSystemVariant: () => {
        return systemVariant;
      },
      getDefaultVariant: () => {
        return defaultVariant || systemVariant;
      },
      refreshVariants: () => {
        refetchVariants();
        refetchSystemData();
      },
      resetVariant: () => {
        setVariantId(systemVariant!.id);
        setVariantName(systemVariant!.name);
        setCurrentVariant(systemVariant!);
      },
    }),
    [
      currentVariant,
      defaultVariant,
      systemVariant,
      setVariantId,
      setVariantName,
      setCurrentVariant,
      refetchVariants,
      refetchSystemData,
    ]
  );

  const isError = isVariantDataError || isSystemError;

  return { currentVariant, variantId: currentVariant?.id || "", variants, isError, handlers };
};
