import PropTypes from "prop-types";
import InLineSelectBox from "components/forms/DynamicRoleSelect";
import { COMMON } from "constants/brm";

const EditCellDropDownForRole = ({ selectedRowId, field, dropdownList, elementId, id, editMode, setter }) => {
  const updateValues = (value) => {
    setter(value);
  };

  if (editMode && selectedRowId === elementId) {
    return <InLineSelectBox arrayOfData={dropdownList} item={id} updateValues={updateValues} />;
  }
  if (field === null || field === "" || field === undefined) {
    return COMMON.hyphen;
  }
  return <>{field}</>;
};

EditCellDropDownForRole.propTypes = {
  dropdownList: PropTypes.any,
  editMode: PropTypes.any,
  elementId: PropTypes.any,
  field: PropTypes.string,
  id: PropTypes.any,
  selectedRowId: PropTypes.any,
  setter: PropTypes.func,
};

export default EditCellDropDownForRole;
