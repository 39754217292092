import { COMMON } from "constants/brm";
import ButtonAsLink from "components/modals/ButtonAsLinkStyled";

interface IAllocatedControlsImplguidanceNotesModalProps {
  implguidance: string;
  setModalIsOpen: (value: boolean) => void;
}

const AllocatedControlsImplguidanceNotesModal = ({
  implguidance,
  setModalIsOpen,
}: IAllocatedControlsImplguidanceNotesModalProps) => {
  return (
    <p id="AllocatedControlsImplguidanceModal">
      {implguidance ? (
        <ButtonAsLink
          type="button"
          onClick={() => {
            setModalIsOpen(true);
          }}
        >
          {implguidance}
        </ButtonAsLink>
      ) : (
        COMMON.hyphen
      )}
    </p>
  );
};

export default AllocatedControlsImplguidanceNotesModal;
