import * as React from "react";
import { RarDiagram } from "features/diagram-attack";
// import useEffectDebug from "hooks/useEffectDebug";
import type { IRarReportData } from "./useRarReport";
import S from "./RarReport.styles";

export const RarDiagramView = ({
  rarReportData,
  selectedContributor,
  highlighted,
  riskElement,
  handleHightlightNodes,
  contributorsIdList,
}: {
  rarReportData: IRarReportData;
  selectedContributor: any;
  highlighted: any[];
  riskElement: any;
  handleHightlightNodes: any;
  contributorsIdList: string[];
}) => {
  const rarData = React.useMemo(() => {
    return [
      {
        id: riskElement.id,
        name: riskElement.name,
        type: "Risk",
        rank: { value: riskElement?.score?.calcValue },
      },
    ];
  }, [riskElement?.id, riskElement.name, riskElement?.score?.calcValue]);

  // useEffectDebug(() => {
  //   console.log("settings targets...selectedContributor", selectedContributor);
  // }, [contributorsList, highlighted.length, selectedContributor]);

  const targets = React.useMemo(() => {
    // console.log("settings targets...selectedContributor", selectedContributor);
    // console.log("settings targets...highlighted", highlighted);
    // console.log("settings targets...contributorsList", contributorsList);
    return selectedContributor?.label === "ALL" || highlighted.length > 0
      ? contributorsIdList
      : [selectedContributor.value];
  }, [contributorsIdList, highlighted.length, selectedContributor?.label, selectedContributor.value]);

  return (
    <S.DiagramView>
      {rarReportData && rarReportData.threat.threats.length > 0 && rarReportData.vulnerability.length > 0 ? (
        <RarDiagram
          rarData={rarData}
          targets={targets}
          highlighted={highlighted}
          // onFocusOnTarget={handleFocusOnTarget}
          onHighlightNodes={handleHightlightNodes}
        />
      ) : (
        <S.MessageDisplay> All threats are mitigated </S.MessageDisplay>
      )}
    </S.DiagramView>
  );
};
