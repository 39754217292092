import { COMMON } from "constants/brm";

export const RISK_LEVELS = {
  veryLowValues: ["20", "22", "23", "24", "25", "21", "19"],
  lowValues: ["12", "13", "14", "15", "18", "16", "17", "11"],
  moderateValues: ["7", "8", "9", "10", "6"],
  highValues: ["3", "5", "4"],
  veryHighValues: ["1", "2"],
};

/**
 * Mapping is based on the Risk Matrix
 *    I1   I2   I3   I4  I5
 *
 * L5   20   12    7   3   1
 * L4   22   14    9   5   2
 * L3   23   15    10   6  4
 * L2   24   18    16  11  8
 * L1   25   21    19  17  13
 *
 * @param {number} riskLevel
 * @returns string
 */
export function riskLevelMapper(riskLevel: string) {
  if (RISK_LEVELS.veryHighValues.includes(riskLevel)) {
    return COMMON.veryHigh;
  }
  if (RISK_LEVELS.highValues.includes(riskLevel)) {
    return COMMON.high;
  }
  if (RISK_LEVELS.moderateValues.includes(riskLevel)) {
    return COMMON.moderate;
  }
  if (RISK_LEVELS.lowValues.includes(riskLevel)) {
    return COMMON.low;
  }
  if (RISK_LEVELS.veryLowValues.includes(riskLevel)) {
    return COMMON.veryLow;
  }
  return "";
}

/**
 * Mapping is based on the Risk Matrix
 *    I1   I2   I3   I4  I5
 *
 * L5   20   12    7   3   1
 * L4   22   14    9   5   2
 * L3   23   15    10   6  4
 * L2   24   18    16  11  8
 * L1   25   21    19  17  13
 *
 * @param {number} riskLevel
 * @returns number
 */
export function riskLevelSortingMapper(riskLevel: string) {
  if (RISK_LEVELS.veryHighValues.includes(riskLevel)) {
    return 5;
  }
  if (RISK_LEVELS.highValues.includes(riskLevel)) {
    return 4;
  }
  if (RISK_LEVELS.moderateValues.includes(riskLevel)) {
    return 3;
  }
  if (RISK_LEVELS.lowValues.includes(riskLevel)) {
    return 2;
  }
  if (RISK_LEVELS.veryLowValues.includes(riskLevel)) {
    return 1;
  }
  return 0;
}
