import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import errorUtils from "utils/error-utils";
import { nodeApi, activityApi } from "services/brm/system-service";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { BrmError, DetailTableType } from "features/brm";

const OutTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const columns = useMemo(() => [createColumnMappedNoEdit("name"), createColumnMappedNoEdit("category")], []);

  const [outData, setOutData] = useState([]);

  useEffect(() => {
    async function getData() {
      function getElementRequest() {
        switch (elementNameInUrl) {
          case DetailTableType.ACTIVITIES.key: {
            return activityApi.getActivityOutExchangeWithHttpInfo(selectedElement.id);
          }
          case DetailTableType.NODES.key: {
            return nodeApi.getNodeOutExchangeWithHttpInfo(selectedElement.id);
          }
          default: {
            break;
          }
        }
        throw new BrmError(`Unsupported element type:${elementNameInUrl}`);
      }
      try {
        const res = await getElementRequest();
        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setOutData(res.data);
        }
      } catch (err) {
        console.error("Error while loading out data ", err);
      }
    }
    getData();
  }, [elementNameInUrl, selectedElement]);

  return (
    <S.DetailsContainer id="OutTable_detailsPanel">
      <BrmDetailTable
        data={outData}
        columns={columns}
        customProps={{ id: "OutTable_detailsTable" }}
        showRowSelect={false}
        tableTitle={tableTitle}
        setSelectedElement={() => {}}
      />
    </S.DetailsContainer>
  );
};

OutTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  tableTitle: PropTypes.string,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
};

export default OutTable;
