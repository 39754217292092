/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

import { FaCheckCircle, FaCircle } from "react-icons/fa";
import * as S from "./StepIcon.styles";

interface IStepIconProps {
  icon: React.ReactNode;
  isCompleted?: boolean;
  isActive?: boolean;
}

export const StepIcon = ({ icon, isActive = false, isCompleted = false }: IStepIconProps) => {
  const iconProps = { $isActive: isActive, $isCompleted: isCompleted };

  if (typeof icon === "number" || typeof icon === "string") {
    if (isCompleted) {
      return (
        <S.IconStack>
          <S.StepSvg as={FaCircle} color="#fff" />
          <S.StepSvg as={FaCheckCircle} {...iconProps} />
        </S.IconStack>
      );
    }

    // console.log(iconProps);

    return (
      <S.StepSvg focusable="false" viewBox="0 0 24 24" color="inherit" aria-hidden="true" {...iconProps}>
        <circle cx="12" cy="12" r="12" />
        <S.StepIconText x="12" y="12" textAnchor="middle" dominantBaseline="central">
          {icon}
        </S.StepIconText>
      </S.StepSvg>
    );
  }
  return <>icon</>;
};
