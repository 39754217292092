import toast from "react-hot-toast";
import { useState, useMemo, useRef, useCallback } from "react";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable, RetainStringColumnFilter } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { TYPE } from "constants/brm";
import { SystemApi } from "features/brm";
import { useCanRename } from "features/system-model";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { queryClient } from "libs/react-query";

import {
  createColumnName,
  createColumnBooleanMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";

const PersonTable = ({ setSelectedRows }) => {
  const { canRename } = useCanRename();
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedCategory = useRef("");
  const updatedIsInternal = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const { data: persons, isError: isPersonsError } = SystemApi.usePersons({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });
  const { data: elementCats, isError: isElementCatsError } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });

  const { mutate: setPersonName } = SystemApi.useSetPersonName({
    options: {
      onSettled: () => {
        setSelectedInstance({
          id: selectedInstance.id,
          name: updatedName.current,
          type: selectedInstance.type,
        });
        updatedName.current = "";
      },
      onError: (err) => {
        toast.error(`Setting person name caused an error: ${err}`);
      },
    },
  });
  const { mutate: setPersonCategory } = SystemApi.useSetPersonCategory({
    options: {
      onSettled: () => {
        updatedCategory.current = "";
      },
      onError: (err) => {
        toast.error(`Setting person category caused an error: ${err}`);
      },
    },
  });
  const { mutate: setPersonIsInternal } = SystemApi.useSetPersonIsInternal({
    options: {
      onSettled: () => {
        updatedIsInternal.current = "";
      },
      onError: (err) => {
        toast.error(`Setting person internal caused an error: ${err}`);
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedName.current !== "") {
              if (!canRename({ updatedName, elementsList: persons })) return;
              setPersonName({ personId: selectedInstance.id, name: updatedName.current });
            }

            if (updatedIsInternal.current !== "") {
              setPersonIsInternal({ personId: selectedInstance.id, isInternal: updatedIsInternal.current });
            }

            if (updatedCategory.current !== "") {
              setPersonCategory({ personId: selectedInstance.id, category: updatedCategory.current });
            }
            queryClient.invalidateQueries(["person", selectedInstance.id]);
            queryClient.invalidateQueries(["persons", projectId]);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [
      selectedInstance.id,
      editMode,
      projectId,
      canRename,
      persons,
      setPersonName,
      setPersonIsInternal,
      setPersonCategory,
    ]
  );

  const checkNameUniqueness = useCallback(
    (newName) => {
      const trimedNamed = newName.trim();
      return isSystemEngineer && Array.isArray(persons)
        ? !persons.map((person) => person.name.toLowerCase()).includes(trimedNamed.toLowerCase())
        : false;
    },
    [isSystemEngineer, persons]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnName(
          selectedInstance.id,
          setSelectedInstance,
          editMode,
          updatedName,
          "person",
          checkNameUniqueness,
          {
            Filter: RetainStringColumnFilter,
          }
        ),
        createColumnCategoryMapped("category", selectedInstance.id, elementCats, editMode, updatedCategory),
        createColumnBooleanMapped("isInternal", selectedInstance.id, editMode, updatedIsInternal),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }

    return [
      createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "person"),
      createColumnCategoryMapped("category", selectedInstance.id, elementCats, false, updatedCategory),
      createColumnBooleanMapped("isInternal", selectedInstance.id, false, updatedIsInternal),
    ];
  }, [
    isSystemEngineer,
    selectedInstance.id,
    setSelectedInstance,
    elementCats,
    editMode,
    checkNameUniqueness,
    createButton,
  ]);

  if (isPersonsError || isElementCatsError) {
    return <ErrorBanner msg="Error while loading person data" />;
  }

  if (persons) {
    return (
      <CustomTable>
        <BrmMainTable
          data={persons}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "PersonTable_table" }}
          elementName={TYPE.person}
          showRowSelect={isSystemEngineer}
        />
      </CustomTable>
    );
  }

  return <Loading />;
};

PersonTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

export default PersonTable;
