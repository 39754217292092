import * as ReactQuery from "@tanstack/react-query";
import { queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import { ThreatApi } from "features/brm";

/**
 * Deletes attacker with the given uuid
 * @param {string} attackerId uuid of the attacker
 * @returns
 */
export const deleteAttacker = ({ attackerId }) => {
  return Brm.attackerApi.deleteAttacker(attackerId);
};

/**
 * Custom hook to delete an existing attacker
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteAttacker = ({ config = {} } = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(ThreatApi.attackerKeys.all);
    },
    ...config,
    mutationFn: deleteAttacker,
  });
};
