import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import handleApi from "services/brm/api-util";
import { userRoleState, projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { userRoles } from "services/brm/admin-service";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";
import { attackSurfaceApi } from "services/brm/access-service";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { useRoles } from "features/brm/hooks/useRoles";
import { useCanRename } from "features/system-model";
import { TYPE, COMMON } from "constants/brm";
import * as ACQueries from "brm/system-model/access/access-queries";
import { LoadingSpinner as Loading } from "components/elements";
import {
  createColumnName,
  createColumnCategoryMapped,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";

const AttackSurfaceTable = ({ setSelectedRows, refresh, setRefresh }) => {
  const { canRename } = useCanRename();
  const userRole = useRecoilValue(userRoleState);
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedCategory = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const sysEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
  const riskAnalystRole = userRoles.properties[userRoles.RISK_ANALYST].key;
  const projectId = useRecoilValue(projectIdState);

  const { data: attackSurfaceCategories } = ACQueries.useAttackSurfaceCategories(projectId);
  const { data: attackSurfaces, isError, refetch: refetchAttackSurfaces } = ACQueries.useAttackSurfaces(projectId);

  const { isThreatAnalyst } = useRoles();

  const createButton = useCallback(
    (cellProps) => {
      if (userRole === sysEngineerRole || userRole === riskAnalystRole) {
        return (
          <ButtonCell
            selectedRowId={selectedInstance.id}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedName.current !== "") {
                if (!canRename({ updatedName, elementsList: attackSurfaces })) return;
                await handleApi(
                  attackSurfaceApi.setAttackSurfaceNameWithHttpInfo(selectedInstance.id, {
                    body: updatedName.current,
                  })
                );
                setSelectedInstance({
                  id: selectedInstance.id,
                  name: updatedName.current,
                  type: selectedInstance.type,
                });
                updatedName.current = "";
              }

              if (updatedCategory.current !== "") {
                await handleApi(
                  attackSurfaceApi.setAttackSurfaceCategoryWithHttpInfo(selectedInstance.id, {
                    body: updatedCategory.current,
                  })
                );
                updatedCategory.current = "";
              }

              setRefresh(true);
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return COMMON.na;
    },

    [
      userRole,
      sysEngineerRole,
      riskAnalystRole,
      selectedInstance.id,
      selectedInstance.type,
      editMode,
      setRefresh,
      canRename,
      attackSurfaces,
      setSelectedInstance,
    ]
  );

  const columns = useMemo(() => {
    if (isThreatAnalyst) {
      return [
        createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "attackSurface"),
        createColumnCategoryMapped("category", selectedInstance.id, attackSurfaceCategories, editMode, updatedCategory),
      ];
    }
    return [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "attackSurface"),
      createColumnCategoryMapped("category", selectedInstance.id, attackSurfaceCategories, editMode, updatedCategory),
      createColumnActions(createButton, { disableFilters: true }),
    ];
  }, [editMode, setSelectedInstance, selectedInstance.id, attackSurfaceCategories, createButton, isThreatAnalyst]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      refetchAttackSurfaces();
    }
  }, [refresh, setRefresh, refetchAttackSurfaces]);

  if (isError) {
    return <div>Error while loading Attack Surface data</div>;
  }

  if (attackSurfaces) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={attackSurfaces}
            columns={columns}
            showRowSelect={!isThreatAnalyst}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "AttackSurfaceTable_table" }}
            elementName={TYPE.attackSurface}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

AttackSurfaceTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default AttackSurfaceTable;
