/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { components } from "react-select";
import * as S from "./FilterControl.styles";

export const FilterControl = (props) => {
  const { selectProps, children } = props;

  return (
    <components.Control {...props}>
      <S.ControlSpan title={selectProps.filterTitle}>{children}</S.ControlSpan>
    </components.Control>
  );
};
