import { RiskCategoryApi, RiskApi } from "@kdmanalytics/brm-system";
import BrmApiClient from "services/brm/BrmApiClient";

export const riskCategoryApi = new RiskCategoryApi(BrmApiClient.instance);
export const riskApi = new RiskApi(BrmApiClient.instance);

const createRiskCategory = async (projectId, createRiskCatDto) => {
  const opts = { riskCategoryCreateDto: createRiskCatDto };
  return riskCategoryApi.createRiskCategoryWithHttpInfo(projectId, opts);
};

const createRisk = async (projectId, createRiskDto) => {
  const opts = { riskCreateDto: createRiskDto };
  return riskApi.createRiskWithHttpInfo(projectId, opts);
};

const addRiskUe = async (riskId, ueId) => {
  const opts = { body: ueId };
  return riskApi.addRiskUeWithHttpInfo(riskId, opts);
};

const RiskService = {
  createRiskCategory,
  createRisk,
  addRiskUe,
};
export default RiskService;
