import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { assetApi, securityObjectiveApi, undesiredEventApi } from "services/brm";
import { useSetRecoilState } from "recoil";
import { undesiredEventListState } from "atoms/atoms-impact-calibration";
import { sortByName } from "utils/sorting";
import { riskUpdatedState } from "atoms/atoms-risk";
import { cloneDeep } from "lodash";
import { RiskApi, useRoles } from "features/brm";
import { ZERO } from "../constants";

export const useUndesiredEvents = (projectId, variantId, hideDisabledUes) => {
  const { isRiskAnalyst } = useRoles();
  const setUeList = useSetRecoilState(undesiredEventListState);
  const setRiskUpdated = useSetRecoilState(riskUpdatedState);
  return useQuery(
    RiskApi.undesiredEventKeys.impactCalibrationUEs(projectId, variantId, hideDisabledUes),
    () => undesiredEventApi.findUndesiredEvent(projectId, { variant: variantId }),
    {
      enabled: !!projectId && isRiskAnalyst,
      onSuccess: (data) => {
        if (hideDisabledUes) {
          // remove risks with score of 0
          const nonZeroUes = data?.filter((ue) => ue.impact.calibratedStringValue !== ZERO);
          setUeList(nonZeroUes?.sort(sortByName));
        } else {
          setUeList(data?.sort(sortByName));
        }
        setRiskUpdated(false);
      },
    }
  );
};

export const useGetSecurityObjectives = (projectId) => {
  const { isRiskAnalyst } = useRoles();
  return useQuery(["getSecurityObjectives", projectId], () => securityObjectiveApi.findSecurityObjective(projectId), {
    enabled: !!projectId && isRiskAnalyst,
  });
};

export const useGetAssets = (projectId) => {
  const { isRiskAnalyst } = useRoles();
  return useQuery(["getAssets", projectId], () => assetApi.findAsset(projectId), {
    enabled: !!projectId && isRiskAnalyst,
  });
};

export const useChangeImpactLevel = (projectId, variantId, hideDisabledUes) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ ueId, level }) => {
      undesiredEventApi.setUndesiredEventImpactWithHttpInfo(ueId, {
        calibrationValueDto: {
          variant: variantId,
          calibratedStringValue: level,
        },
      });
    },
    {
      onMutate: async ({ ueId, level }) => {
        await queryClient.cancelQueries(["getUndesiredEvents", projectId, variantId, hideDisabledUes]);
        const prevValues = queryClient.getQueryData(["getUndesiredEvents", projectId, variantId, hideDisabledUes]);

        queryClient.setQueryData(
          RiskApi.undesiredEventKeys.impactCalibrationUEs(projectId, variantId, hideDisabledUes),
          (old) => {
            if (Array.isArray(old) && old.length) {
              return old.map((data) => {
                if (ueId === data.id) {
                  const newImpact = cloneDeep(data.impact);
                  newImpact.calibratedStringValue = level;
                  return {
                    ...data,
                    impact: newImpact,
                  };
                }
                return data;
              });
            }
            return old;
          }
        );
        return prevValues;
      },
      onSuccess: () =>
        queryClient.setQueryData(
          RiskApi.undesiredEventKeys.impactCalibrationUEs(projectId, variantId, hideDisabledUes)
        ),
      onError: (prevValues) =>
        queryClient.setQueryData(
          RiskApi.undesiredEventKeys.impactCalibrationUEs(projectId, variantId, hideDisabledUes),
          prevValues
        ),
      onSettled: () => {
        queryClient.invalidateQueries(
          RiskApi.undesiredEventKeys.impactCalibrationUEs(projectId, variantId, hideDisabledUes)
        );
      },
    }
  );
};
