/**
 * Constants for classification, probably should use Classification enum instead of this class.
 * If you edit this constant be sure to update Classification enum as well.
 */
const CLASSIFICATION = {
  topSecret: {
    letter: "TS",
    name: "Top Secret",
    colour: "#FF6501",
    value: 4,
  },
  secret: {
    letter: "S",
    name: "Secret",
    colour: "#CC0001",
    value: 3,
  },
  fouo: {
    letter: "FOUO",
    name: "For official use only",
    colour: "#01009A",
    value: 2,
  },
  unclassified: {
    letter: "U",
    name: "Unclassified",
    colour: "#009900",
    value: 1,
  },
  notClassified: {
    letter: "-",
    name: "Not Classified",
    colour: "#969696",
    value: 0,
  },
};

export default CLASSIFICATION;
