import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./project-query-key-factory";

type ProjectProperty = "controlCatalog" | "baseline";

interface IProjectStatusProps {
  projectId: string | null;
  property: ProjectProperty;
  value: any;
}

/**
 * Upload the given file as the image for the CD
 *
 * @param param0 upload file data
 * @returns
 */
export const setProjectProperty = ({ projectId, property, value }: IProjectStatusProps) => {
  if (projectId === null) {
    return Promise.reject(new Error("Invalid ProjectId"));
  }
  switch (property) {
    case "controlCatalog":
      return Brm.projectApi.setProjectCatalog(projectId, { body: value });
    case "baseline":
      return Brm.projectApi.setProjectBaseline(projectId, { body: value });
    default:
      return Promise.reject(new Error("Unsupported property"));
  }
};

type MutationFunction = typeof setProjectProperty;
interface ISetStatusProps {
  options?: MutationOption<MutationFunction>;
}

/**
 * Upload the given formdata to to the upload service.
 *
 * @returns react-query Mutation.
 */
export const useSetProjectProperty = ({ options }: ISetStatusProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: () => {
      queryClient.invalidateQueries(KeyFactory.projectKeys.all);
    },
    ...options,
    mutationFn: setProjectProperty,
  });
};
