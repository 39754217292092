import styled from "styled-components/macro";

export const AppContainer = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fonts.defaultSize};
`;

export const LayoutContainer = styled.div`
  height: 100%;
  width: 100vw;
  outline: none;
  display: grid;
  grid-template-columns: var(--leftPanelWidth, 0px) minmax(0, 1fr) var(--rightPanelWidth, 0px);
  grid-template-rows: ${({ theme }) => theme.sizes.bannerHeight} ${({ theme }) => theme.header.height} auto ${({
      theme,
    }) => theme.footer.height};
  grid-template-areas:
    "left-panel banner right-panel"
    "left-panel top-navigation right-panel"
    "left-panel content right-panel"
    "left-panel footer right-panel";
`;

export const TopNavigation = styled.div`
  grid-area: top-navigation;
  position: fixed;
  height: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
`;

export const MainLayoutContainer = styled.div`
  grid-area: content / content / content / content;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  display: flex;
`;

export const MainLayoutContentContainer = styled.div`
  display: flex;

  & > :first-of-type {
    width: calc(
      100vw - var(--leftPanelWidth, 0px) - var(--leftSidebarWidth, 40px) - var(--rightPanelWidth, 0px) -
        var(--rightSidebarWidth, 40px)
    );
  }
`;

export const HelpLayoutContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;

export const DemoLayoutContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div`
  grid-area: footer;
  background: grey;
`;
