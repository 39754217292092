import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./knowledgebase-query-key-factory";

interface ICloneKb {
  kbId: string;
  orgId: string;
}

/**
 * Create a cloned version of selected kb
 *
 * @param {string} kbId - uuid of the selected Kb
 * @param {string} orgId - uuid of organization
 */
export const cloneKb = ({ kbId, orgId }: ICloneKb) => {
  return Brm.kbApi.cloneKB(kbId, orgId);
};

interface IUseCloneKb {
  options?: MutationOption<typeof cloneKb>;
}
/**
 * custom hook for Cloning of a KB .
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useCloneKb = ({ options }: IUseCloneKb = {}) => {
  return ReactQuery.useMutation({
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.knowledgebaseKeys.all);
    },
    mutationFn: cloneKb,
  });
};
