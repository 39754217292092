import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 *  Deletes a mission from the system
 *
 * @param {string} missionId - uuid of mission to delete
 */
export const deleteMission = ({ missionId }) => {
  return Brm.missionApi.deleteMission(missionId);
};

/**
 * Custom hook to delete an existing mission
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteMission = ({ config = {} } = {}) => {
  return ReactQuery.useMutation({
    ...config,
    mutationFn: deleteMission,
  });
};
