import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

export interface ISetNodeParent {
  nodeId: string;
  parentId: string | null;
}

/**
 * Sets the parent of a node
 *
 * @param {string} nodeId - uuid of the node
 * @param {string} parentId - uuid of new parent or null
 */
export const setNodeParent = ({ nodeId, parentId }: ISetNodeParent) => {
  return nodeId === null
    ? Promise.reject(new Error("Invalid nodeId id"))
    : Brm.nodeApi.setNodeParent(nodeId, { body: parentId });
};

export interface IUseSetNodeParent {
  options?: MutationOption<typeof setNodeParent>;
}

/**
 * Custom hook to change the parent of an existing node
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetNodeParent = ({ options }: IUseSetNodeParent = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.nodeKeys.all);
      queryClient.invalidateQueries(KeyFactory.nodeKeysGraphQl.getNodeParents);
      queryClient.invalidateQueries(KeyFactory.nodeKeysGraphQl.systemDiagramData);
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: setNodeParent,
  });
};
