import PropTypes from "prop-types";

import S from "./ContentLayout.styles";

const ContentLayout = ({ pageId, header, main, footer }) => {
  return (
    <S.Container id={pageId}>
      <S.ContentHeader>{header}</S.ContentHeader>
      <S.ContentMain>{main}</S.ContentMain>
      <S.ContentFooter>{footer}</S.ContentFooter>
    </S.Container>
  );
};

ContentLayout.propTypes = {
  pageId: PropTypes.string.isRequired,
  header: PropTypes.any,
  main: PropTypes.any.isRequired,
  footer: PropTypes.any,
};

export default ContentLayout;
