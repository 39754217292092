import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./variant-query-key-factory";

type VariantProperty = "baseline" | "catalog";

interface IVariantStatusProps {
  variantId: string | null;
  property: VariantProperty;
  value: any;
}

/**
 * Set
 *
 * @param param0 upload file data
 * @returns
 */
export const setVariantProperty = ({ variantId, property, value }: IVariantStatusProps) => {
  if (variantId === null) {
    return Promise.reject(new Error("Invalid VariantId"));
  }

  switch (property) {
    case "baseline":
      return Brm.variantApi.setVariantBaseline(variantId, { body: value });
    case "catalog":
      return Brm.variantApi.setVariantCatalog(variantId, { body: value });
    default:
      return Promise.reject(new Error(`Unsupported property: ${property}`));
  }
};

type MutationFunction = typeof setVariantProperty;
interface ISetStatusProps {
  options?: MutationOption<MutationFunction>;
}

/**
 * Set property on variant
 *
 * @returns react-query Mutation.
 */
export const useSetVariantProperty = ({ options }: ISetStatusProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: () => {
      queryClient.invalidateQueries(KeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: setVariantProperty,
  });
};
