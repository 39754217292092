import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import * as Brm from "services/brm";
import * as SapPersonKeyFactory from "features/brm/api/admin/sapPerson/sap-person-query-key-factory";
import * as SapBindingKeyFactory from "features/brm/api/admin/sapBinding/sap-binding-query-key-factory";
import * as SapProgramKeyFactory from "features/brm/api/admin/sapProgram/sap-program-query-key-factory";
import * as ProjectKeyFactory from "features/brm/api/admin/project/project-query-key-factory";

interface CreateSapUserBindingProps {
  sapUserBindingCreateDto: any;
}

/**
 * Create a sap user binding
 *
 * @returns
 */
export const createSapUserBinding = ({ sapUserBindingCreateDto }: CreateSapUserBindingProps) => {
  return sapUserBindingCreateDto === null
    ? Promise.reject(new Error("Invalid createbinding dto"))
    : Brm.sapUserBindingApi.createSapUserBinding({ sapUserBindingCreateDto });
};

type MutateFunction = typeof createSapUserBinding;
interface ICreateSapUserBindingProps {
  options?: MutationOption<MutateFunction>;
}

/**
 * Create a sap user binding
 *
 * @returns react-query Mutation.
 */
export const useCreateSapUserBinding = ({ options = {} }: ICreateSapUserBindingProps = {}) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation({
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ProjectKeyFactory.projectKeys.all });
      queryClient.invalidateQueries({ queryKey: SapProgramKeyFactory.sapProgramKeys.all });
      queryClient.invalidateQueries({ queryKey: SapPersonKeyFactory.sapPersonKeys.all });
      queryClient.invalidateQueries({ queryKey: SapBindingKeyFactory.sapBindingKeys.allUser });
    },
    mutationFn: createSapUserBinding,
  });
};
