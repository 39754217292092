import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./organization-query-key-factory";

type OrganizationProperty = "defaultControlCatalog" | "projectSetting";

interface IOrganizationStatusProps {
  organizationId: string | null;
  property: OrganizationProperty;
  value: any;
}

/**
 * Upload the given file as the image for the CD
 *
 * @param param0 upload file data
 * @returns
 */
export const setOrganizationProperty = ({ organizationId, property, value }: IOrganizationStatusProps) => {
  if (organizationId === null) {
    return Promise.reject(new Error("Invalid organizationId"));
  }
  switch (property) {
    case "defaultControlCatalog":
      return Brm.organizationApi.setOrganizationDefaultCatalog(organizationId, { body: value });
    case "projectSetting": {
      return Brm.projectSettingApi.setProjectSettingValue(value.id, { body: value.value });
    }
    default:
      return Promise.reject(new Error("Unsupported property"));
  }
};

type MutationFunction = typeof setOrganizationProperty;
interface ISetStatusProps {
  options?: MutationOption<MutationFunction>;
}

/**
 * Upload the given formdata to to the upload service.
 *
 * @returns react-query Mutation.
 */
export const useSetOrganizationProperty = ({ options }: ISetStatusProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: () => {
      queryClient.invalidateQueries(KeyFactory.organizationKeys.all);
    },
    ...options,
    mutationFn: setOrganizationProperty,
  });
};
