import AttackGraphNode from "./AttackGraphNode";

/**
 * Graph data structure
 *
 * mapping is  id -> attackgraph node;
 *
 * each node tracks it's own adjacency list;
 */
export default class AttackGraph {
  /**
   * Initialize the empty list of nodes
   *
   * @param {String} $edgeDirection directed or undirected
   */
  constructor($edgeDirection = AttackGraph.UNDIRECTED, idBinding = (i) => i) {
    this.$nodes = new Map();
    this.$edgeDirection = $edgeDirection;
    this.$idBinding = idBinding;
  }

  clear() {
    this.$nodes.clear();
  }

  hasNode(value = null) {
    if (value === null || value === undefined) {
      return false;
    }
    const id = this.$idBinding(value);
    return this.$nodes.has(id);
  }

  /**
   * Add a node to the graph.
   *
   * Runtime: O(1)
   * @param {any} value
   * @returns {AttackGraphNode} the new node or the existing one if it exists already.
   */
  addNode(value = null) {
    if (value === null || value === undefined) {
      return null;
    }
    const id = this.$idBinding(value);
    if (this.$nodes.has(id)) {
      // console.log("existing node:", value.name);
      return this.$nodes.get(id);
    }
    // console.log("Creating new node:", value.name);
    const node = new AttackGraphNode(value);
    this.$nodes.set(id, node);
    // console.log("this.$nodes:", this.$nodes);
    return node;
  }

  /**
   * Create an edge between the source and destination nodes.
   *
   * Runtime: O(1)
   * @param {any} source
   * @param {any} destination
   * @returns {[AttackGraphNode, AttackGraphNode]} source, destination node pair.
   */
  addEdge(source, destination) {
    if (source === null || source === undefined || destination === null || destination === undefined) {
      return null;
    }
    const sourceNode = this.addNode(source);
    const destinationNode = this.addNode(destination);
    sourceNode.addAdjacent(destinationNode);

    if (this.$edgeDirection === AttackGraph.UNDIRECTED) {
      destinationNode.addAdjacent(sourceNode);
    }
    return [sourceNode, destinationNode];
  }

  /**
   * Remove an edge between the source and destination nodes.
   *
   * Runtime: O(1)
   *
   * @param {any} source
   * @param {any} destination
   * @returns [AttackGraphNode, AttackGraphNode] source/destination node pair
   */
  removeEdge(source, destination) {
    if (source === null || source === undefined || destination === null || destination === undefined) {
      return [null, null];
    }

    const sourceId = this.$idBinding(source);
    const destinationId = this.$idBinding(destination);
    const sourceNode = this.$nodes.get(sourceId);
    const destinationNode = this.$nodes.get(destinationId);

    if (sourceNode && destinationNode) {
      sourceNode.removeAdjacent(destinationNode);
      if (this.$edgeDirection === AttackGraph.UNDIRECTED) {
        destinationNode.removeAdjacent(sourceNode);
      }
    }
    return [sourceNode, destinationNode];
  }

  /**
   * Remove a node from the graph.  When removing a node the edges connected to it are also removed.
   * @param {any} value
   * @returns true if removed false otherwise;
   */
  removeNode(value) {
    if (value === null || value === undefined) {
      return null;
    }
    const id = this.$idBinding(value);
    const current = this.$nodes.get(id);
    if (current) {
      this.$nodes.forEach((n) => n.removeAdjacent(current));
    }
    return this.$nodes.delete(id);
  }

  /**
   * Checks to see if the given source and destination are adjacent.
   *
   * @param {any} source
   * @param {any} destination
   * @returns {bool} true if the given nodes are adjacent
   */
  areAjacent(source, destination) {
    if (source === null || source === undefined || destination === null || destination === undefined) {
      return false;
    }
    const sourceId = this.$idBinding(source);
    const destinationId = this.$idBinding(destination);
    const sourceNode = this.$nodes.get(sourceId);
    const destinationNode = this.$nodes.get(destinationId);

    if (sourceNode && destinationNode) {
      return sourceNode.isAdjacent(destinationNode);
    }
    return false;
  }

  getAdjacent(value) {
    if (value === null || value === undefined) {
      return false;
    }
    const id = this.$idBinding(value);
    const node = this.$nodes.get(id);
    if (!node) {
      console.error(`Unable to located node with id: ${id}. Attack graph likely corrupted please refresh page`);
      return [];
    }
    return node.getAdjacents().map((n) => n.value);
  }

  // DEBUG ONLY
  // printGraph() {
  //   this.adjacencyList.forEach((value, key) => {
  //     console.log(`${key} ->  ${value.join(",")}`);
  //   });
  // }

  /**
   * Returns the AttackGraphNodes from this AttackGraph
   */
  get nodes() {
    return Array.from(this.$nodes.values());
  }

  /**
   * Returns the values from each node in this AttackGraph
   */
  get nodeValues() {
    return Array.from(this.$nodes.values()).map((n) => n.value);
  }

  getNode(value = null) {
    if (value === null || value === undefined) {
      return null;
    }
    const id = this.$idBinding(value);
    const node = this.$nodes.get(id);
    return node.value;
  }
}

AttackGraph.DIRECTED = "directed";
AttackGraph.UNDIRECTED = "undirected";
