import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { SystemApi } from "features/brm";

/**
 * Sets the IsInternal of an Datatype
 *
 * @param {string} dataTypeId - uuid of the dataType
 * @param {string} isInternal - new isInternal of the dataType
 */
export const setDataTypeIsInternal = ({ dataTypeId, isInternal }) => {
  return Brm.dataTypeApi.setDataTypeIsInternal(dataTypeId, { body: isInternal });
};

/**
 * Custom hook to change the IsInternal of an existing datatype
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetDataTypeIsInternal = ({ projectId, config = {} } = {}) => {
  const queryClient = ReactQuery.useQueryClient();

  return ReactQuery.useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(SystemApi.dataTypeKeys.project(projectId));
      queryClient.invalidateQueries(SystemApi.dataTypeKeys.dataType(variables.dataTypeId));
    },
    ...config,
    mutationFn: setDataTypeIsInternal,
  });
};
