import { useNavigate } from "react-router-dom";
import HomeIcon from "assets/icons/home-20x20.svg";
import IconLink from "components/elements/IconLink";

import { RoutePath } from "routes/route-paths";

export const Home = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate(RoutePath.Home);
  };

  return (
    <span title="Home">
      <IconLink id="Header_HomeLink" before={HomeIcon} beforeAlt="Home" onClick={handleHomeClick} />
    </span>
  );
};
