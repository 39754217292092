import PropTypes from "prop-types";
import LinkIcon from "assets/icons/link.svg";
import PrimaryButton from "components/elements/PrimaryStyledButton";
import ImgSized from "components/elements/ImgSized";

const LinkButton = ({ onClick }) => (
  <>
    <PrimaryButton md onClick={onClick}>
      <ImgSized src={LinkIcon} alt="add" />
    </PrimaryButton>
  </>
);

LinkButton.propTypes = {
  onClick: PropTypes.func,
};

export default LinkButton;
