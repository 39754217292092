import { useMutation, useQueryClient } from "@tanstack/react-query";
import { projectApi } from "services/brm";
import { AdminApi } from "features/brm";

// TODO: Move this hook to src\features\brm\hooks
const useProjectListed = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ projectId, isListed }) => {
      return projectApi.setProjectIsListed(projectId, {
        body: isListed,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(AdminApi.projectKeys.all);
      },
    }
  );
};

export default useProjectListed;
