import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./dataflow-query-key-factory";

/**
 * Returns a dataflow
 *
 * @param {string} dataFlowId - uuid of dataflow
 * @returns a dataflow
 */
export const getDataflow = ({ dataflowId }: { dataflowId: string }) => {
  return Brm.dataflowApi.getDataFlowByID(dataflowId);
};

interface IUseDataflow {
  dataflowId: string;
  options?: QueryOption<typeof getDataflow>;
}

/**
 * Return the DataflowDto .
 *
 * @param {string} dataflowId - uuid of dataflow
 * @param {object} options - react-query configuration object
 * @returns consumer for this exchange
 */
export const useDataflow = ({ dataflowId, options }: IUseDataflow) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.dataflowKeys.dataflow(dataflowId),
    queryFn: () => getDataflow({ dataflowId }),
  });
};
