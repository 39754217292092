import styled from "styled-components/macro";

const contentStyle = {
  Main: styled.main`
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* height: 100%; */
    background-color: ${({ theme }) => theme.colors.background};
  `,
  MainContentContainer: styled.div`
    /* height: 100%; */
    display: flex;
    flex-direction: column;
  `,
  ContentNavArea: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  CenterContainer: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};

export default contentStyle;
