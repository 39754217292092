import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./knowledgebase-query-key-factory";

/**
 * Returns list of kb's of an organization
 *
 * @param {string} orgId - uuid of organization
 * @returns list of kb
 */
export const getOrganizationKb = ({ orgId }: { orgId: string }) => {
  return Brm.kbApi.findKB({ organization: orgId });
};

interface IUseOrganizationKb {
  orgId: string;
  options?: QueryOption<typeof getOrganizationKb>;
}

/**
 * Returns list of Kb's of an organization .
 *
 * @param {string} orgId - uuid of the organization
 * @param {object} options - react-query configuration object
 * @returns kb list of organization
 */
export const useOrganizationKb = ({ orgId, options }: IUseOrganizationKb) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.knowledgebaseKeys.orgKb(orgId),
    queryFn: () => getOrganizationKb({ orgId }),
  });
};
