import { useQuery } from "@tanstack/react-query";
import * as BrmGql from "generated/graphql";
import { QueryOption } from "libs/react-query";
import { BrmError, DetailTableType } from "features/brm";

const getMissions = (
  elementType: string,
  nodeMissionsData: BrmGql.GetNodeMissionQuery | undefined,
  exchangeMissionsData: BrmGql.GetExchangeMissionsQuery | undefined,
  dataTypeMissionsData: BrmGql.GetDataTypeMissionsQuery | undefined,
  activityMissionsData: BrmGql.GetActivityMissionsQuery | undefined,
  personMissionsData: BrmGql.GetPersonMissionsQuery | undefined,
  capabilityMissionsData: BrmGql.GetCapabilityMissionsQuery | undefined,
  resourceMissionsData: BrmGql.GetResourceMissionsQuery | undefined
) => {
  const missionsMap: IMissionMap = {
    [DetailTableType.NODES.key]: () => nodeMissionsData?.node?.missions,
    [DetailTableType.EXCHANGES.key]: () => exchangeMissionsData?.exchange?.missions,
    [DetailTableType.DATATYPES.key]: () => dataTypeMissionsData?.dataType?.missions,
    [DetailTableType.ACTIVITIES.key]: () => activityMissionsData?.activity?.missions,
    [DetailTableType.PERSONS.key]: () => personMissionsData?.person?.missions,
    [DetailTableType.CAPABILITIES.key]: () => capabilityMissionsData?.capability?.missions,
    [DetailTableType.RESOURCES.key]: () => resourceMissionsData?.resource?.missions,
  };

  if (missionsMap[elementType]) {
    return missionsMap[elementType]() || [];
  }
  throw new BrmError(`Unsupported element type: ${elementType}`);
};
interface IMissionMap {
  [index: string]: any;
}

interface IElementMissionProps {
  elementId: string;
  elementName: string;
  projectId: string;
  variantId: string;
  options: QueryOption<typeof getMissions>;
}

/**
 * custom-hook to get all missions
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {string} variantId - uuid of the variant
 * @param {object} options - configuration for the react-query
 * @returns react-query for missions
 */
export const useElementMissions = ({
  elementId,
  elementName,
  projectId,
  variantId,
  options = {},
}: IElementMissionProps) => {
  const nodeMissionsQuery = BrmGql.useGetNodeMissionQuery(
    { project: projectId, variant: variantId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.NODES.key }
  );

  const exchangeMissionsQuery = BrmGql.useGetExchangeMissionsQuery(
    { project: projectId, variant: variantId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.EXCHANGES.key }
  );

  const dataTypeMissionsQuery = BrmGql.useGetDataTypeMissionsQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.DATATYPES.key }
  );

  const activityMissionsQuery = BrmGql.useGetActivityMissionsQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.ACTIVITIES.key }
  );

  const personMissionsQuery = BrmGql.useGetPersonMissionsQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.PERSON.key }
  );

  const capabilityMissionsQuery = BrmGql.useGetCapabilityMissionsQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.CAPABILITIES.key }
  );

  const resourceMissionsQuery = BrmGql.useGetResourceMissionsQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.RESOURCES.key }
  );

  const missionQuery = useQuery({
    ...options,
    queryKey: [
      "elementMissions",
      elementId,
      elementName,
      nodeMissionsQuery.data,
      exchangeMissionsQuery.data,
      dataTypeMissionsQuery.data,
      activityMissionsQuery.data,
      personMissionsQuery.data,
      capabilityMissionsQuery.data,
      resourceMissionsQuery.data,
    ],
    queryFn: () =>
      getMissions(
        elementName,
        nodeMissionsQuery.data,
        exchangeMissionsQuery.data,
        dataTypeMissionsQuery.data,
        activityMissionsQuery.data,
        personMissionsQuery.data,
        capabilityMissionsQuery.data,
        resourceMissionsQuery.data
      ),
  });

  if (nodeMissionsQuery.isError) {
    return nodeMissionsQuery;
  }
  if (exchangeMissionsQuery.isError) {
    return exchangeMissionsQuery;
  }
  if (dataTypeMissionsQuery.isError) {
    return dataTypeMissionsQuery;
  }
  if (activityMissionsQuery.isError) {
    return activityMissionsQuery;
  }
  if (personMissionsQuery.isError) {
    return personMissionsQuery;
  }
  if (capabilityMissionsQuery.isError) {
    return capabilityMissionsQuery;
  }
  if (resourceMissionsQuery.isError) {
    return resourceMissionsQuery;
  }

  return missionQuery;
};
