// React Query
import { useMutation } from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./global-control-query-key-factory";

interface IUploadControlCatalog {
  controlCatalogId: string;
  fileContents: any;
}

/**
 * imports control catalog
 *
 * @param {controlCatalogId} controlCatalogId - uuid of the control catalog
 * @param {object} filecontents - file contents of the uploaded file
 */
export const uploadControlCatalog = ({ controlCatalogId, fileContents }: IUploadControlCatalog) => {
  return Brm.controlCatalogApi.importControlCatalog(controlCatalogId, { body: fileContents });
};

interface IUseUploadControlCatalog {
  options?: MutationOption<typeof uploadControlCatalog>;
}

/**
 * Custom-hook to import  control catalog.
 *
 * @param {object} fileContents - the contents of the uploaded file
 * @param {object} options - hook configuration
 * @returns react-query for import control catalog
 */
export const useUploadControlCatalog = ({ options }: IUseUploadControlCatalog = {}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.controlCatalogKeys.all);
    },
    ...options,
    mutationFn: uploadControlCatalog,
  });
};
