import { NodeCreator } from "yfiles";

/**
 * Node creator for group nodes, delegates to the diagramBuilder
 */
export default class DiagramGroupCreator extends NodeCreator {
  /**
   * Creates a new instance of  {@link DiagramGroupCreator}
   * @param {!DiagramGraphBuilder} $diagramBuilder
   */
  constructor($diagramBuilder) {
    super();
    this.$diagramBuilder = $diagramBuilder;
  }

  /**
   * Creates a group node, delegates to diagramBuilder.$builderCreateGroupNode
   * @param {!IGraph} graph
   * @param {?INode} parent
   * @param {*} dataItem
   * @returns {*}
   */
  createNode(graph, parent, dataItem) {
    const labelData = this.$getLabelData(dataItem);
    const nodeObject = this.$getNodeObject(dataItem);
    const node = this.$diagramBuilder.$builderCreateGroupNode(graph, labelData, nodeObject);
    graph.setParent(node, parent);
    return node;
  }

  /**
   * Updates a group node, delegates to diagramBuidler.builderUpdateGroupNode
   * @param {!Graph} graph
   * @param {!Node} node
   * @param {?INode} parent
   * @param {*} dataItem
   */
  updateNode(graph, node, parent, dataItem) {
    const labelData = this.$getLabelData(dataItem);
    const nodeObject = this.$getNodeObject(dataItem);
    this.$diagramBuilder.$builderUpdateGroupNode(graph, node, labelData, nodeObject);
    if (graph.getParent(node) !== parent) {
      graph.setParent(node, parent);
    }
  }

  /**
   * Returns the business object.
   * @param {*} dataItem
   * @returns {*}
   */
  $getNodeObject(dataItem) {
    if (this.tagProvider) {
      return this.tagProvider(dataItem);
    }
    return dataItem;
  }

  /**
   * Return the label or null
   * @param {*} dataItem
   * @returns {*}
   */
  $getLabelData(dataItem) {
    return this.$diagramBuilder.groupLabelProvider ? this.$diagramBuilder.groupLabelProvider(dataItem) : null;
  }
}
