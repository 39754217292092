export const DIAGRAM_EVENT = {
  organicLayout: "organicLayout",
  circularLayout: "circularLayout",
  radialLayout: "radialLayout",
  hierarchicalLayout: "hierarchicalLayout",
  hierarchicalLayout_left_to_right: "hierarchicalLayout_left_to_right",
  orthogonalLayout: "orthogonalLayout",
  orthogonalLayout_left_to_right: "orthogonalLayout_left_to_right",
  zoomIn: "zoomIn",
  zoomOut: "zoomOut",
  zoomReset: "zoomReset",
  zoomToFit: "zoomToFit",
};

export const SYSTEM_DIAGRAM_EVENTS = {
  resetLayout: "resetLayout",
  expandSelectedNodes: "expandSelectedNodes",
  expandComponentNodes: "expandComponents",
  expandSubsystemNodes: "expandSubsystems",
  expandSegmentNodes: "expandSegments",
  expandAllNodes: "expandAll",
  collapseSelectedNodes: "collapseSelectedNodes",
  collapseComponentNodes: "collapseComponents",
  collapseSubsystemNodes: "collapseSubsystems",
  collapseSegmentNodes: "collapseSegments",
  collapseAllNodes: "collapseAll",
  toggleLabels: "toggleLabels",
  toggleControls: "toggleControls",
  toggleBugModel: "toggleBugModel",
  toggleBugBor: "toggleBugBor",
  toggleOverview: "toggleOverview",
  toggleOrphans: "toggleOrphans",
  itemNameChanged: "itemNameChanged",
  nodeParentChanged: "nodeParentChanged",
  nodeParentChangedById: "nodeParentChangedById",
  exchangeDatatypeChanged: "exchangeDatatypeChanged",
  exchangeNameDoubleClick: "exchangeNameDoubleClick",
};
