/* eslint-disable no-param-reassign */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import convertedPercent from "utils/percent-converter";

export const getAssets = async (projectId, variantId) => {
  const data = await Brm.assetApi.findAsset(projectId, { variant: variantId });

  const riskData = await Brm.riskApi.findRisk(projectId, { variant: variantId });
  // Find if risk with score of 1 exists
  const riskHasBeenRun = riskData.some((r) => r.rank?.calcValue === 1);

  if (riskHasBeenRun) {
    // use the zero values in distribution
    const updatedData = data.map((item) => {
      const percent = item.riskDistributionPercent * 100;
      item.riskDistributionPercent = convertedPercent(percent);

      return item;
    });
    return updatedData;
  }
  // set risk distribution values to N/A
  const updatedData = data.map((item) => {
    item.riskDistributionRank = "N/A";
    item.riskDistributionPercent = "N/A";

    return item;
  });
  return updatedData;
};

export const useAssets = (projectId, variantId, config = {}) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["assets", projectId, variantId],
    queryFn: () => getAssets(projectId, variantId),
  });
};
