import { useCallback, useState } from "react";

const useToggle = (initialState) => {
  const [value, setValue] = useState(initialState || false);
  const toggle = useCallback(() => {
    setValue((state) => !state);
  }, [setValue]);
  return [value, toggle];
};

export default useToggle;
