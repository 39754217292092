import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./sap-binding-query-key-factory";

interface IGetSapUserBindingProps {
  userId?: string | null;
}

/**
 * Gets the sap user objects
 */
export const getSapUserBindings = ({ userId }: IGetSapUserBindingProps) => {
  if (userId) {
    return Brm.sapUserBindingApi.findSapUserBinding({ user: userId });
  }
  return Brm.sapUserBindingApi.findSapUserBinding();
};

type FuncType = typeof getSapUserBindings;

interface ISapUserBindinProps {
  userId?: string | null;
  options?: QueryOption<FuncType>;
}

/**
 * Custom hook for getting the sap user binding
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useSapUserBindings = ({ userId, options = {} }: ISapUserBindinProps = {}) => {
  const key = userId ? KeyFactory.sapBindingKeys.user(userId) : KeyFactory.sapBindingKeys.allUser;

  return ReactQuery.useQuery({
    ...options,
    queryKey: key,
    queryFn: () => getSapUserBindings({ userId }),
  });
};
