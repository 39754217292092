import { IControlTypeData, IManualMitigationState, IVulnerabilityData } from "./types";

export const toSelectedVulnerabilities = (wizardState: IManualMitigationState) => {
  const initial: IVulnerabilityData[] = [];
  return Object.entries(wizardState.selectedVulnerabilities).reduce((acc, [key, value]) => {
    if (value) {
      return [...acc, wizardState.rarVulnerabilities[key as unknown as number]];
    }
    return acc;
  }, initial);
};

export const toVulnerabilities = (
  vulnerabilities: IVulnerabilityData[],
  vulnerabilitySelection: Record<string, boolean>
) => {
  const initial: IVulnerabilityData[] = [];
  return Object.entries(vulnerabilitySelection).reduce((acc, [key, value]) => {
    if (value) {
      return [...acc, vulnerabilities[key as unknown as number]];
    }
    return acc;
  }, initial);
};

export const toSelectedControlTypes = (wizardState: IManualMitigationState) => {
  const initial: IControlTypeData[] = [];
  return Object.entries(wizardState.selectedControlTypes).reduce((acc, [key, value]) => {
    if (value) {
      return [...acc, wizardState.controlTypes[key as unknown as number]];
    }
    return acc;
  }, initial);
};
