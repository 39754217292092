import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

/**
 * function making api call to get details of standard baseline using baseline id
 *
 * @param {string} baselineId - uuid of baseline
 * @returns array of object
 */
export const getStandardBaselineControlType = async ({ baselineId }: { baselineId: string }) => {
  return Brm.baselineApi.getStandardBaselineControltype(baselineId);
};

interface IUseGetStandardBaselineControlType {
  baselineId: string;
  options: QueryOption<typeof getStandardBaselineControlType>;
}

/**
 * React-query hook for getting Baseline details by id
 *
 * @param {string} baselineId - uuid of the baseline
 * @param {object} options- configuration for useStandardBaseline hook
 * @returns react-query for getBaseilne
 */
export const useGetStandardBaselineControlType = ({ baselineId, options = {} }: IUseGetStandardBaselineControlType) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.standardBaselineKeys.controlType(baselineId),
    queryFn: () => getStandardBaselineControlType({ baselineId }),
  });
};
