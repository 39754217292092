import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import BoundaryTable from "./tables/BoundaryTable";

function Boundary() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const boundaryHeader = <Title>Boundary Nodes</Title>;

  const boundaryMain = (
    <>
      <BoundaryTable
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );

  return <ContentLayout pageId="Boundary_page" header={boundaryHeader} main={boundaryMain} />;
}

export default Boundary;
