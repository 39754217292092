import * as ReactQuery from "@tanstack/react-query";
import { sortByName } from "utils/sorting";
import { QueryOption } from "libs/react-query";
import { getNodes } from "./getNodes";
import * as KeyFactory from "./node-query-key-factory";

/**
 * Returns a list of all possible parents within the given project.
 *
 * @param {string} projectId - uuid of a project
 * @returns a list of all possible parents
 */
export const getNodeParents = async ({ projectId }: { projectId: string }) => {
  const nodes = await getNodes({ projectId });
  const nonLeaf = nodes.filter((node: any) => node.structuralcat !== "Leaf");
  return [{ id: null, name: "-" }, ...nonLeaf.sort(sortByName)];
};

interface IUseNodeParentsProps {
  projectId: string;
  options?: QueryOption<typeof getNodeParents>;
}

/**
 * Custom hook to retreive a possible parents of a node. (non-leaves)
 *
 * @param {string} projectId - uuid of a project
 * @param {object} options - react-query configuration object
 * @returns react-query for getNodeParents
 */
export const useNodeParents = ({ projectId, options = {} }: IUseNodeParentsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.nodeKeys.parents(projectId),
    queryFn: () => getNodeParents({ projectId }),
  });
};
