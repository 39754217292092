import { HarmCategoryApi, UndesiredEventApi } from "@kdmanalytics/brm-system";
import BrmApiClient from "services/brm/BrmApiClient";

export const harmCategoryApi = new HarmCategoryApi(BrmApiClient.instance);
export const undesiredEventApi = new UndesiredEventApi(BrmApiClient.instance);

const createHarmCategory = async (projectId, createHarmCatDto) => {
  const opts = { harmCategoryCreateDto: createHarmCatDto };
  return harmCategoryApi.createHarmCategoryWithHttpInfo(projectId, opts);
};

const createUndesiredEvent = async (projectId, createUEDto) => {
  const opts = { undesiredEventCreateDto: createUEDto };
  return undesiredEventApi.createUndesiredEventWithHttpInfo(projectId, opts);
};

const getUndesiredEventByCategory = async (project, cat) => {
  const opts = { cat };
  return undesiredEventApi.findUndesiredEventWithHttpInfo(project, opts);
};

const addUndesiredEventSystemAsset = async (ueId, saId) => {
  const opts = { body: saId };
  return undesiredEventApi.addUndesiredEventSystemassetWithHttpInfo(ueId, opts);
};

const addUndesiredEventTrev = async (ueId, trevId) => {
  const opts = { body: trevId };
  return undesiredEventApi.addUndesiredEventTrevWithHttpInfo(ueId, opts);
};

const ImpactService = {
  createHarmCategory,
  createUndesiredEvent,
  getUndesiredEventByCategory,
  addUndesiredEventSystemAsset,
  addUndesiredEventTrev,
};
export default ImpactService;
