import styled from "styled-components";

const NavListIconStyled = styled.img`
  height: 16px;
  margin-right: 5px;
  margin-bottom: 5px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
    text-decoration: none;
  }
`;

export default NavListIconStyled;
