import * as React from "react";
import * as YFiles from "yfiles";
import * as ReactDOM from "react-dom";
import Tooltip from "../../Tooltip";

export const useTooltipFactory = () => {
  const createTooltipContent = React.useCallback((item: YFiles.IModelItem) => {
    const props = {
      item,
    };
    const tooltipContainer = document.createElement("div");
    const element = React.createElement(Tooltip, props);
    ReactDOM.render(element, tooltipContainer);
    return tooltipContainer as HTMLElement;
  }, []);
  return createTooltipContent;
};
