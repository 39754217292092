import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { useRoles } from "features/brm";

export const getAttacker = async ({ id }) => {
  let attackerToDisplay = {};
  const data = await Brm.attackerApi.getAttackerByID(id);
  attackerToDisplay = data;
  const category = await Brm.attackerApi.getAttackerCategory(id);
  const group = await Brm.attackerApi.getAttackerGroup(id);
  const person = await Brm.attackerApi.getAttackerPerson(id);
  const saType = await Brm.attackerApi.getAttackerSatype(id);
  attackerToDisplay.category = category.result;
  attackerToDisplay.group = group;
  attackerToDisplay.person = person;
  attackerToDisplay.satype = saType;
  return [attackerToDisplay];
};

/**
 * React-query hook for getting all details of  Attacker
 *
 * @param {string} projectId - uuid of the attacker
 * @returns react-query for getAttackers
 */
export const useAttacker = ({ id, setSelectedElement }) => {
  const { isRiskAnalyst, isThreatAnalyst } = useRoles();

  return ReactQuery.useQuery(["attacker", id], () => getAttacker({ id }), {
    enabled: !!id && (isRiskAnalyst || isThreatAnalyst),
    onSuccess: (data) => {
      setSelectedElement(data[0]);
    },
  });
};
