import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./exchange-query-key-factory";

interface IGetExchangesProps {
  projectId: string;
  variantId: string;
}

/**
 * Returns a list of ExchangeShortDto's for the given project
 *
 * @param {string} projectId - uuid of the project
 * @param {string} variantId - uuid of the variant
 * @returns list of ExchangeShortDto's
 */
export const getExchanges = ({ projectId, variantId }: IGetExchangesProps): Promise<any> => {
  return Brm.exchangeApi.findExchange(projectId, { variant: variantId });
};

interface IUseExchangesProps {
  projectId: string;
  variantId: string;
  options?: QueryOption<typeof getExchanges>;
}

/**
 * React-query hook for getting all exchanges
 *
 * @param {string} projectId - uuid of the project
 * @param {object} config - react-query configuration object
 * @returns react-query for getExchanges
 */
export const useExchanges = ({ projectId, variantId, options = {} }: IUseExchangesProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exchangeKeys.variant(projectId, variantId),
    queryFn: () => getExchanges({ projectId, variantId }),
  });
};
