import * as React from "react";
import { useMultiStepForm } from "hooks/useMultStepForm";
import { Stepper, Step, StepLabel } from "components/elements/Stepper";
import { AdminApi, MitigationApi, ProjectState } from "features/brm";
import { useQueryClient } from "@tanstack/react-query";
import { CreateProjectStepForm } from "./CreateProjectStepForm";
import * as S from "./CreateProjectForm.styles";
import { CreateProjectContext } from "./CreateProjectContext";
import { ProjectCreatedStep } from "./ProjectCreatedStep";
import { useCreateProject } from "./useCreateProject";

interface ICreateProjectFormProps {
  onClose: () => void;
  onConfigure: () => void;
}

const creationSteps = [
  {
    label: "Project Information",
    step: <CreateProjectStepForm />,
    optional: null,
  },
  {
    label: "Project Creation",
    step: <ProjectCreatedStep />,
    optional: null,
  },
];

export const CreateProjectForm = ({ onClose, onConfigure }: ICreateProjectFormProps) => {
  const queryClient = useQueryClient();
  const formSteps = creationSteps.map((s) => s.step);
  const { currentStep, isLastStep, currentStepIndex, prev, next } = useMultiStepForm(formSteps);
  const [projectCreationError, setProjectCreationError] = React.useState<string | undefined>();
  const [projectState, setProjectState] = React.useState<ProjectState | undefined>();
  const [sapPrograms, setSapPrograms] = React.useState<string[] | undefined>();

  const { createProject } = useCreateProject({
    onSuccess: (projState: ProjectState) => {
      setProjectState(projState);
      queryClient.invalidateQueries(AdminApi.projectKeys.all);
      queryClient.invalidateQueries(MitigationApi.variantKeys.all);
    },
    onError: (error) => {
      setProjectCreationError(error);
    },
  });

  const context = React.useMemo(() => {
    return {
      next,
      prev,
      close: onClose,
      onConfigure,
      createProject,
      projectCreationError,
      setProjectCreationError,
      setSapPrograms,
      projectState,
      sapPrograms,
    };
  }, [next, prev, onClose, onConfigure, createProject, projectCreationError, projectState, sapPrograms]);

  return (
    <S.Container>
      <S.VerticalStepContainer>
        <S.VerticalStepHeader>Project Creation</S.VerticalStepHeader>
        <Stepper activeStep={currentStepIndex}>
          {creationSteps.map((step) => (
            <Step key={step.label} isCompleted={isLastStep ? true : undefined}>
              <StepLabel optional={step.optional}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </S.VerticalStepContainer>
      <CreateProjectContext.Provider value={context}>
        <S.StepContainer>{currentStep}</S.StepContainer>
      </CreateProjectContext.Provider>
    </S.Container>
  );
};
