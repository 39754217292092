import * as Recoil from "recoil";
import { CONTEXT_SIDEBAR_CONTENT, contextSidebarContentState } from "features/brm";

import AddNodes from "../AddNodes";

interface IContextMap {
  [index: string]: JSX.Element;
}

export const ContextPanelContainer = () => {
  const contentType = Recoil.useRecoilValue(contextSidebarContentState);

  const drawerContent: IContextMap = {
    [CONTEXT_SIDEBAR_CONTENT.attackGraphAddNodes]: <AddNodes />,
  };
  const Content = drawerContent[contentType];
  return Content;
};
