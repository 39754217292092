import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./knowledgebase-query-key-factory";

/**
 * imports file for selected kb
 *
 * @param {string} kbId - uuid of the selected Kb
 * @param {object} file - imported file object
 */
export const importKb = ({ kbId, file }: { kbId: string; file: any }) => {
  return Brm.kbApi.importKB(kbId, { body: file });
};

interface IUseImportKb {
  options?: MutationOption<typeof importKb>;
}

/**
 * custom hook for Import of a KB .
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useImportKb = ({ options }: IUseImportKb = {}) => {
  return ReactQuery.useMutation({
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.knowledgebaseKeys.all);
    },
    mutationFn: importKb,
  });
};
