import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./exchange-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

interface ICreateExchange {
  projectId: string;
  exchangeCreateDto: any;
  existingEdge?: any;
}

/**
 *
 * @param {string} projectId uuid of project
 * @param {ExchangeCreateDto} exchangeCreateDto creation dto
 * @returns id of created exchange
 */
export const createExchange = ({ projectId, exchangeCreateDto }: ICreateExchange) => {
  return Brm.exchangeApi.createExchange(projectId, { exchangeCreateDto });
};

export interface IUseCreateExchange {
  options?: MutationOption<typeof createExchange>;
}

/**
 * Custom hook to create a new exchange.
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for useCreateExchange
 */
export const useCreateExchange = ({ options }: IUseCreateExchange = {}) => {
  return ReactQuery.useMutation({
    onSuccess: (data, { projectId }) => {
      queryClient.invalidateQueries(KeyFactory.exchangeKeys.project(projectId));
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.project(projectId));
    },
    ...options,
    mutationFn: createExchange,
  });
};
