import { keycloak } from "libs/keycloak";
import { queryClient } from "libs/react-query";
import { getErrorMessage } from "utils/error-message-utils";

/**
 * Simple wrappers around keycloak functions
 * probably should use keycloak directly.. but
 * that would require more refactoring than I'm
 * comfortable with at this time.
 */

const doLogout = async (options) => {
  queryClient.clear();
  await keycloak.logout(options);
};

const updateToken = () => {
  return keycloak.updateToken(5).catch((error) => {
    console.error("Keycloak", getErrorMessage(error));
    doLogout();
  });
};

/** Cannot return a promise because the generated getFetchParams needs the token value so we promise and loop ;) */
const getToken = () => {
  return keycloak.token;
};

const UserService = {
  doLogout,
  getToken,
  updateToken,
};

export default UserService;
