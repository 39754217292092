/**
 * Admin Help contents -  this is a key value pair mapping help videos to each page , where key is the "contentKey" which is unique to each page
 */

export const AdminHelpContents = {
  projects: {
    contents: [
      {
        role: "Admin",
        type: "Video",
        name: "Create and Configure a Project",
        filePath: "/help-assets/Admin/CreateAProject/CreateAProject_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "View a project information",
        filePath: "/help-assets/Admin/ViewProjectInformation/ViewProjectInformation_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Edit a project",
        filePath: "/help-assets/Admin/EditAProject/EditAProject_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Delete a project",
        filePath: "/help-assets/Admin/DeleteAProject/DeleteAProject_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "View project notes",
        filePath: "/help-assets/Admin/ViewProjectNotes/ViewProjectNotes_player.html",
      },
    ],
  },

  projectOverview: {
    contents: [
      {
        role: "Admin",
        type: "Video",
        name: "Add a user",
        filePath: "/help-assets/Admin/AddAUser/AddAUser_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Delete a user in Project Overview",
        filePath: "/help-assets/Admin/DeleteAUser/DeleteAUser_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Restore save point",
        filePath: "/help-assets/Admin/restoreSavePoint/restoreSavePoint_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Create save point for project by Admin",
        filePath: "/help-assets/Admin/createSavePoint/createSavePoint_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Backup to file",
        filePath: "/help-assets/Admin/backupToFile/backupToFile_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Restore from file",
        filePath: "/help-assets/Admin/restoreFromFile/restoreFromFile_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Add a readonly user",
        filePath: "/help-assets/Admin/AddAReadOnlyUser/AddAReadOnlyUser_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Delete a readonly user",
        filePath: "/help-assets/Admin/DeleteAReadOnlyUser/DeleteAReadOnlyUser_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Edit project from overview page",
        filePath: "/help-assets/Admin/EditProjectFromOverviewPage/EditProjectFromOverviewPage_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Filter and sort users list",
        filePath: "/help-assets/Admin/FilterAndSortUsersList/FilterAndSortUsersList_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Filter read only user list",
        filePath: "/help-assets/Admin/FilterReadOnlyUserList/FilterReadOnlyUserList_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Navigate to a user details page",
        filePath: "/help-assets/Admin/NavigateToAUserDetailsPage/NavigateToAUserDetailsPage_player.html",
      },
    ],
  },

  users: {
    contents: [
      {
        role: "Admin",
        type: "Video",
        name: "Create a user by Admin",
        filePath: "/help-assets/Admin/CreateAUser/CreateAUser_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Edit a user by Admin",
        filePath: "/help-assets/Admin/EditAUser/EditAUser_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Delete a user by Admin",
        filePath: "/help-assets/Admin/DeleteAUser_A/DeleteAUser_A_player.html",
      },
    ],
  },

  userOverview: {
    contents: [
      {
        role: "Admin",
        type: "Video",
        name: "Assign and remove roles to an existing user",
        filePath:
          "/help-assets/Admin/AssignAndRemoveRolesToAnExistingUser/AssignAndRemoveRolesToAnExistingUser_player.html",
      },
      {
        role: "Admin",
        type: "Video",
        name: "Reset password for user by Admin",
        filePath: "/help-assets/Admin/resetPassword/resetPassword_player.html",
      },
    ],
  },

  organizations: {
    contents: [
      {
        role: "Admin",
        type: "Video",
        name: "View an organization by Admin",
        filePath: "/help-assets/Admin/ViewOrganizations/ViewOrganizations_player.html",
      },
    ],
  },
};
