import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { LoadingSpinner, ErrorBanner } from "components/elements";

import {
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnNameDetails,
} from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";
import { SystemApi } from "features/brm";

interface IInDataProps {
  elementNameInUrl: string;
  selectedElement: {
    id: string;
  };
  tableTitle: string;
}

const InDataTable = ({ elementNameInUrl, selectedElement, tableTitle }: IInDataProps) => {
  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Datatype.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnBooleanNoEditMapped("isInternal"),
      createColumnBooleanNoEditMapped("isInput"),
      createColumnBooleanNoEditMapped("isOutput"),
      createColumnBooleanNoEditMapped("isStored"),
      createColumnBooleanNoEditMapped("isProcessed"),
    ],
    []
  );

  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);

  const { data: inData, isError: isInDataError } = SystemApi.useElementInData({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    variantId,
    options: { enabled: !!selectedElement?.id && !!elementNameInUrl },
  });

  if (isInDataError) {
    return <ErrorBanner msg="Error while loading InData" />;
  }

  if (inData) {
    return (
      <S.DetailsContainer id="InDataTable_detailsPanel">
        <BrmDetailTable
          data={inData}
          columns={columns}
          customProps={{ id: "InDataTable_detailsTable" }}
          showRowSelect={false}
          tableTitle={tableTitle}
        />
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

export default InDataTable;
