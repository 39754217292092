import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./activity-query-key-factory";

interface ISetActivityNameProps {
  activityId: string;
  name: string;
}

/**
 * Sets the name of an activity
 *
 * @param {string} activityId - uuid of the activity
 * @param {string} name - new name of the activity
 */
export const setActivityName = ({ activityId, name }: ISetActivityNameProps) => {
  return Brm.activityApi.setActivityName(activityId, { body: name });
};

export interface IUseSetActivityName {
  options?: MutationOption<typeof setActivityName>;
}

/**
 * Custom hook to change the name of an existing activity
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetActivityName = ({ options }: IUseSetActivityName) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.activitiesKeys.all);
    },
    ...options,
    mutationFn: setActivityName,
  });
};
