import styled from "styled-components";

export const HelpContent = styled.div`
  display: flex;
  flex: 1;
  margin: 10px;
  align-items: space-around;
`;

export const HelpTable = styled.div`
  display: flex;
  flex: 1 0 60%;
`;

export const HelpArticle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  flex: 1 0 40%;
  padding: 10px;
  text-align: justify;
  font-size: 25px;
`;
