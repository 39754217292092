import { useState, useEffect } from "react";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { userRoleState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { userRoles } from "services/brm/admin-service";
import { AddButton, DeleteButton, SystemApi } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";

import { TYPE, CRUD_EVENT } from "constants/brm";
import systemModals from "components/modals/Modals";

import ContentLayout from "brm/layout/Content/ContentLayout";
import CapabilityTable from "./tables/CapabilityTable";
import CapabilityAddForm from "./forms/CapabilityAddForm";
import Event from "./crudEvent";

function Capability() {
  const userRole = useRecoilValue(userRoleState);
  const sysEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const { mutateAsync: deleteCapability } = SystemApi.useDeleteCapability();

  const addCapability = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      await Promise.all(selectedRows.map((each) => deleteCapability({ capabilityId: each.id })));
      setDeleteModalIsOpen(false);
      setSelectedInstance({});
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteCapability, selectedRows, setSelectedInstance]);

  const capabilityHeader = (
    <Title id="Capability_title">
      Capabilities
      <Buttons id="Capability_buttons">
        <Event constant={CRUD_EVENT.capability} setRefresh={setRefresh} />
        {userRole && userRole === sysEngineerRole ? (
          <>
            {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
            <AddButton onClick={addCapability} />
          </>
        ) : (
          <br />
        )}
      </Buttons>
    </Title>
  );

  const capabilityMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "Capability_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.capability}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.capability
        )}

      {/* modal for adding instance */}
      {modalIsOpen &&
        systemModals.addModal(
          "Capability_page",
          modalIsOpen,
          setModalIsOpen,
          <CapabilityAddForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} />,
          TYPE.capability
        )}

      <CapabilityTable
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
        accessibleRole={sysEngineerRole}
      />
    </>
  );

  return <ContentLayout pageId="Capability_page" header={capabilityHeader} main={capabilityMain} />;
}

export default Capability;
