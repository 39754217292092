import { Wrapper } from "./HelpArticleFaq.styles";

export const HelpArticleFaq = ({ title, response }: { title: string; response?: string }) => {
  if (title && response) {
    return (
      <Wrapper>
        <b>{title}</b>
        <p>{response}</p>
      </Wrapper>
    );
  }
  return null;
};
