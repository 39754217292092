import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
// Components
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import SystemAssetTypeDetails from "brm/system-model/system-assets/details/SystemAssetTypeDetails";
import ButtonCell from "components/EditComponents/ButtonCell";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
// Services
import { SystemApi, useRoles } from "features/brm";
// State
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
// Styles
import Title from "components/elements/StyledTitle";

import {
  // createColumnMappedStringEdit,
  createColumnActions,
  createColumnCategoryMapped,
  createColumnMappedStringEditValidation,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { RoutePath } from "routes/route-paths";
import { TYPE } from "constants/brm";

function SystemAssetTypeFullDetails() {
  const { isSystemEngineer, isRiskAnalyst, isThreatAnalyst } = useRoles();
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const updatedCpe = useRef("");
  const updatedCategory = useRef("");
  const { data, error } = SystemApi.useSystemAssetsType({
    saTypeId: id,
    setSelectedElement,
    options: { enabled: isSystemEngineer || isRiskAnalyst || isThreatAnalyst },
  });
  const { data: categoryList, isError: isCategoryListError } = SystemApi.useSystemAssetCategories({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst || isThreatAnalyst) },
  });

  const { mutate: setSystemAssetTypeCpe } = SystemApi.useSetSystemAssetTypeCpe({ projectId });
  const { mutate: setSystemAssetTypeCategory } = SystemApi.useSetSystemAssetTypeCategory();

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedRowId}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (Array.isArray(data) && data.length > 0 && updatedCpe.current !== data[0].cpe) {
              setSystemAssetTypeCpe(
                {
                  saTypeId: selectedRowId,
                  cpeId: updatedCpe.current,
                },
                {
                  onSettled: () => {
                    updatedCpe.current = "";
                  },
                }
              );
            }

            if (updatedCategory.current !== "") {
              setSystemAssetTypeCategory(
                {
                  saTypeId: selectedRowId,
                  saTypeCat: updatedCategory.current,
                },
                {
                  onSettled: () => {
                    updatedCategory.current = "";
                  },
                }
              );
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [selectedRowId, editMode, data, setSystemAssetTypeCpe, setSystemAssetTypeCategory]
  );

  const columns = useMemo(
    () => [
      createColumnCategoryMapped("category", selectedRowId, categoryList, editMode, updatedCategory),
      createColumnMappedStringEditValidation("cpe", selectedRowId, editMode, updatedCpe, "systemAssetType"),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [createButton, editMode, selectedRowId, categoryList]
  );

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(RoutePath.SystemAssetTypes);
    }
  }, [projectId, navigate]);

  if (error || isCategoryListError) {
    return <ErrorBanner msg="Error while loading system asset type details" />;
  }

  if (data) {
    return (
      <>
        <Title id="SystemAssetTypeFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "SystemAssetTypeFullDetails_table" }}
              elementName={TYPE.systemAssetType}
            />
          </DetailsContainerSingleTable>
        ) : (
          <></>
        )}

        {/* This conditional ensures that the details doesn't render until the selectedElement is assigned a value */}
        {selectedElement.id && (
          <DetailsContainerParent showSummaryTable={showSummaryTable}>
            <DetailsContainer>
              <SystemAssetTypeDetails selectedElement={selectedElement} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
  }
  return <Loading />;
}

export default SystemAssetTypeFullDetails;
