import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";
import * as S from "./EditField.styles";
// Editable Input Field
const EditField = ({
  field,
  updateData,
  checkNameUniqueness = () => {
    return true;
  },
  validationMode,
}) => {
  const [value, setValue] = useState(field);
  const [isTextValid, setIsTextValid] = useState(true);
  const msg = useRef("");

  useEffect(() => {
    setValue(field !== null ? field : "");
  }, [field]);

  const onChange = (e) => {
    const inputString = e.target.value;
    const isNameUnique = checkNameUniqueness(inputString);

    if (validationMode === "systemAssetType" && inputString.match(/[$&+,;?@#|'<>^()%!]+/) && inputString.length > 0) {
      msg.current = "Invalid input";
      setIsTextValid(false);
    } else if (
      (validationMode === "default" && !inputString.match(/^[\w\-\s]+$/) && inputString.length > 0) ||
      !isNameUnique
    ) {
      // input string include something other than alphanumeric chars, dash or underscore; invalid
      msg.current =
        validationMode === "default"
          ? "Invalid character(s). Valid characters are alphanumeric, dash (-), or underscore (_)"
          : "Name already exists";
      setIsTextValid(false);
    } else if (
      (validationMode === "url" &&
        !inputString.match(
          /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
        ) &&
        inputString.length > 0) ||
      !isNameUnique
    ) {
      msg.current = "Invalid URL";
      setIsTextValid(false);
    } else {
      setIsTextValid(true);
    }
    setValue(inputString);
  };

  // only update when input is blurred
  const onBlur = (e) => {
    if (isTextValid) {
      updateData(e.target.value.trim());
    }
  };

  return (
    <>
      <S.InputTextField
        title={isTextValid ? "" : msg.current}
        valid={isTextValid}
        id="editTextField"
        type="text"
        value={value}
        placeholder={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
};

EditField.propTypes = {
  field: PropTypes.any,
  updateData: PropTypes.func,
  checkNameUniqueness: PropTypes.func,
  validationMode: PropTypes.string,
};

export default EditField;
