import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

interface ICreateSystemAsset {
  projectId: string;
  systemAssetCreateDto: any;
}

/**
 *
 * @param {string} projectId uuid of project
 * @param {SystemAssetCreateDto} systemAsset creation dto
 * @returns id of created node
 */
export const createSystemAsset = ({ projectId, systemAssetCreateDto }: ICreateSystemAsset) => {
  return Brm.systemAssetApi.createSystemAsset(projectId, { systemAssetCreateDto });
};

export interface IUseCreateSystemAsset {
  options?: MutationOption<typeof createSystemAsset>;
}

/**
 * Custom hook to create a new exchange.
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for useCreateSystemAsset
 */
export const useCreateSystemAsset = ({ options }: IUseCreateSystemAsset = {}) => {
  return ReactQuery.useMutation({
    onSuccess: (data, { projectId }) => {
      queryClient.invalidateQueries(KeyFactory.systemAssetKeys.project(projectId));
    },
    ...options,
    mutationFn: createSystemAsset,
  });
};
