import styled from "styled-components";

const StyledTitle = styled.h5`
  color: ${({ theme }) => theme.colors.title};
  /* height: 46px; */
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.7em;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  word-wrap: break-word;
  /* margin-bottom: 10px; */
`;

export default StyledTitle;
