import { useQuery } from "@tanstack/react-query";
import { controlFrameworkApi } from "services/brm";
import { SystemApi } from "features/brm";

const getData = async (acData) => {
  const simpleData = [];

  try {
    const frameworkData = await controlFrameworkApi.findControlFramework();
    const csf = frameworkData?.filter((e) => e.name === "CSF");
    if (csf.length > 0 && csf[0]?.id) {
      const result = await controlFrameworkApi.getControlFrameworkByID(csf[0].id);

      result.structure.levels.forEach((f) =>
        f.levels.forEach((c) =>
          c.levels.forEach((s) =>
            simpleData.push({
              function: `${f.level_value} (${f.level_abbreviation})`,
              funcdescription: f.level_note || "",
              category: `${c.level_value} (${c.level_abbreviation})`,
              description: c.level_note,
              subcategory: s.level_value,
              subdescription: s.level_note,
              control: s.controls,
            })
          )
        )
      );
    }
  } catch (err) {
    console.error("err in framework data : ", err);
  }

  const modifiedData = simpleData
    .map((e) => {
      const res = [];
      e.control?.forEach((element) => {
        const controls = acData?.filter((item) => item.controltype.name === element);
        if (controls && controls.length > 0) {
          res.push({ ...e, control: element, target: controls.length, objects: controls });
        } else res.push({ ...e, control: element, target: 0 });
      });
      return res;
    })
    .flat();
  return modifiedData || [];
};

const getCategories = (selectedElement, data) => {
  const all = [{ label: "All", value: "All" }];
  const array = data.filter((e) => e.function === selectedElement);
  const categories = array.reduce((result, item) => {
    if (result.indexOf(item.category) < 0) {
      result.push(item.category);
    }
    return result;
  }, []);
  const selectArray = all.concat(categories.map((each) => ({ label: each, value: each })));
  return selectArray;
};

const getSubCategories = (selectedElement, data) => {
  const all = [{ label: "All", value: "All" }];
  const array = data.filter((e) => e.category === selectedElement);
  const subcats = array.reduce((result, item) => {
    if (result.indexOf(item.subcategory) < 0) {
      result.push(item.subcategory);
    }
    return result;
  }, []);
  const selectArray = all.concat(subcats.map((each) => ({ label: each, value: each })));
  return selectArray;
};

export const useGetCsfCategories = (selectedFunction, data) => {
  return useQuery(["getCsfCategories", selectedFunction], () => getCategories(selectedFunction, data), {
    enabled: !!selectedFunction !== "All" && !!data,
  });
};

export const useGetCsfSubCategories = (selectedCategory, data) => {
  return useQuery(["getCsfSubCategories", selectedCategory], () => getSubCategories(selectedCategory, data), {
    enabled: !!selectedCategory !== "All" && !!data,
  });
};

export const useGetCsfData = (selectedElement) => {
  const { data: getACData } = SystemApi.useElementAllocatedControls({
    elementId: selectedElement.id,
    elementName: "variants",
    sctmId: selectedElement.sctm?.id,
    options: { enabled: !!selectedElement.id },
  });
  return useQuery(["getCsfData", getACData], () => getData(getACData), {
    enabled: !!selectedElement.id,
    cacheTime: 0,
  });
};
