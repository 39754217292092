export const attackKeys = {
  all: ["attack"] as const,
  element: (
    elementId: string | null,
    elementName: string | null,
    projectId: string | null,
    variantId: string | null,
    attackData: any | null,
    targetAttacksData: any | null
  ) =>
    [
      ...attackKeys.all,
      "elementAttacks",
      elementId,
      elementName,
      projectId,
      variantId,
      attackData,
      targetAttacksData,
    ] as const,
  attack: (attackId: string | null, variantId: string | null) => [...attackKeys.all, attackId, variantId] as const,
};
