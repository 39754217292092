import * as React from "react";
import Accordion from "react-bootstrap/Accordion";
import { userRoleState } from "atoms/atoms-admin";
import { contentKeyState } from "atoms";
import { useRecoilValue } from "recoil";
import { AccordionStyles } from "features/diagram-common";
import { Separator } from "components/elements/NavigationToolbar";
import faqList from "./faq.json";
import * as S from "./HelpFaq.styles";

interface IFaqList {
  Role: string;
  Page: string;
  Question: string;
  Answer: string;
}
interface IContentQuesAns {
  Question: string;
  Answer: string;
}

interface IFaq {
  [role: string]: {
    [page: string]: IContentQuesAns[];
  };
}

const createFaqObj = (list: IFaqList[] = []) => {
  let faqObj: IFaq | null = null;
  list.forEach((item) => {
    if (!faqObj) {
      faqObj = {};
    }
    if (!faqObj[item.Role]) {
      faqObj[item.Role] = {};
    }
    if (!faqObj[item.Role][item.Page]) {
      faqObj[item.Role][item.Page] = [];
    }
    faqObj[item.Role][item.Page].push({
      Question: item.Question,
      Answer: item.Answer,
    });
  });

  return faqObj;
};

const faqObj: null | IFaq = createFaqObj(faqList);

export const HelpFaq = () => {
  const userRoleVal = useRecoilValue(userRoleState);
  const contentKey = useRecoilValue(contentKeyState);
  const [contentsData, setContentsData] = React.useState<IContentQuesAns[]>([]);

  React.useEffect(() => {
    if (userRoleVal && contentKey) {
      setContentsData(() =>
        faqObj &&
        userRoleVal &&
        faqObj[userRoleVal] &&
        faqObj[userRoleVal][contentKey] &&
        Array.isArray(faqObj[userRoleVal][contentKey])
          ? faqObj[userRoleVal][contentKey]
          : []
      );
    } else {
      setContentsData([]);
    }
  }, [contentKey, userRoleVal]);

  return (
    <>
      <S.Separator>
        <Separator />
      </S.Separator>
      <S.Wrapper>
        <b>FAQ</b>
        <S.Text>
          A list of &quot;Frequently Asked Questions&quot; related to this page. Click chevron to view answers to the
          question.
        </S.Text>
      </S.Wrapper>
      <S.Container>
        <Accordion bsPrefix="help-faq">
          {contentsData &&
            contentsData.map((el: IContentQuesAns, index: number) => (
              // eslint-disable-next-line
              <Accordion.Item key={`${contentKey}_${userRoleVal}_${index}`} eventKey={index.toString()}>
                <AccordionStyles.Header>{el?.Question || ""}</AccordionStyles.Header>
                <AccordionStyles.MediumBody>{el?.Answer || ""}</AccordionStyles.MediumBody>
              </Accordion.Item>
            ))}
        </Accordion>
      </S.Container>
    </>
  );
};
