import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./save-point-query-key-factory";

/**
 * Returns a list of all save points of a given project
 *
 * @param {string} projectId - uuid of project
 * @returns list of all save points of a given project
 */
export const savePointHistoryProject = ({ projectId }: { projectId: string }) => {
  return Brm.savePointApi.savepointHistoryProjectIdGet(projectId);
};

export interface IUseSavePointHistoryProject {
  projectId: string;
  options?: QueryOption<typeof savePointHistoryProject>;
}

/**
 * Custom hook to list the save points of a project
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query mutation configuration
 * @returns react-query for savepointHistoryProjectIdGet
 */
export const useSavePointHistoryProject = ({ projectId, options = {} }: IUseSavePointHistoryProject) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.savePointKeys.history(projectId),
    queryFn: () => savePointHistoryProject({ projectId }),
  });
};
