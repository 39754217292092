import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { queryClient } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

interface ISetNameControlCatalog {
  ccId: string;
  standard: string;
}

/**
 * Sets the name of Control Catalog
 *
 * @param {string} ccId - ccID of the control catalog
 * @param {string} standard - standard of the control catalog
 */
export const setCCStandard = ({ ccId, standard }: ISetNameControlCatalog) => {
  return Brm.controlCatalogApi.setControlCatalogStandard(ccId, { body: standard });
};

/**
 * Custom hook to change the standard of an existing Control Catalog
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetCCStandard = ({ options = {} } = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.controlCatalogKeys.all);
    },
    ...options,
    mutationFn: setCCStandard,
  });
};
