/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { components } from "react-select";
import { Button } from "react-bootstrap";
import * as S from "./FilterMenu.styles";

export const FilterMenuList = (props) => {
  const { selectProps, children, options } = props;

  const handleSelectAll = () => {
    selectProps.onChange(options);
  };

  const handleDeselectAll = () => {
    selectProps.onChange([]);
  };

  return (
    <components.MenuList {...props}>
      <S.TitleContainer>{selectProps.filterTitle}</S.TitleContainer>
      <S.ButtonContainer>
        <Button size="sm" variant="outline-primary" onClick={handleSelectAll}>
          Select All
        </Button>
        <Button size="sm" variant="outline-primary" onClick={handleDeselectAll}>
          Deselect All
        </Button>
      </S.ButtonContainer>
      {children}
    </components.MenuList>
  );
};
