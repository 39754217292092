import { useState } from "react";
// Styles
import Title from "components/elements/StyledTitle";
import ContentLayout from "brm/layout/Content/ContentLayout";

// Local
import ThreatEventPatternTable from "./tables/ThreatEventPatternTable";

function ThreatEventPattern() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const trevPatHeader = <Title id="ThreatEventPattern_title">Threat Event Patterns</Title>;

  const trevPatMain = (
    <ThreatEventPatternTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
  );

  return <ContentLayout pageId="ThreatEventPattern_page" header={trevPatHeader} main={trevPatMain} />;
}

export default ThreatEventPattern;
