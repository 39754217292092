interface IDynamicSelectBoxCategoryProps {
  arrayOfData: Array<string>;
  name: string;
  item: string;
  defaultCategory?: string | null;
}

const DynamicSelectBoxCategory = ({ arrayOfData, name, item, defaultCategory }: IDynamicSelectBoxCategoryProps) => {
  const options: any = [];
  let defaultOption;
  let option;
  if (Array.isArray(arrayOfData) && arrayOfData.length) {
    // check if there is any data in the array first
    arrayOfData.forEach((data) => {
      option = (
        <option key={data} value={data}>
          {data}
        </option>
      );
      if (defaultCategory && data === defaultCategory) {
        defaultOption = option;
      } else {
        options.push(option);
      }
    });
  }
  return (
    <select name={name} id={item}>
      {defaultOption || (arrayOfData.length > 1 ? <option>Select {name}</option> : null)}
      {options}
    </select>
  );
};

export default DynamicSelectBoxCategory;
