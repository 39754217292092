const USER_ROLES = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  SYSTEM_ENGINEER: 3,
  THREAT_ANALYST: 4,
  RISK_ANALYST: 5,
  RMF_COORDINATOR: 6,
  CONTROLS_COORDINATOR: 7,
  SCA: 8,
  AUTH_OFFICER: 9,
  CYBERSECURITY_EXPERT: 10,
  TECH_ADMIN: 11,
  EXECUTIVE: 12,
  BUILD_ENGINEER: 13,
  BOR_PROJECT_COORDINATOR: 14,
  SW_DEVELOPER: 15,
  EVIDENCE_SERVER_COORDINATOR: 16,
  properties: {
    1: { key: "superAdmin", name: "Super Admin", rank: 1, inUse: true },
    2: { key: "admin", name: "Admin", rank: 2, inUse: true },
    3: { key: "systemsEngineer", name: "Systems Engineer", rank: 3, inUse: true },
    4: { key: "threatAnalyst", name: "Threat Analyst", rank: 5, inUse: true },
    5: { key: "riskAnalyst", name: "Risk Analyst", rank: 4, inUse: true },
    6: { key: "rmfCoordinator", name: "RMF Coordinator", rank: 6, inUse: false },
    7: { key: "controlCoordinator", name: "Control Coordinator", rank: 7, inUse: true },
    8: { key: "sca", name: "Security Control Assessor", rank: 8, inUse: false },
    9: { key: "ao", name: "Authorization Officer", rank: 9, inUse: false },
    10: { key: "cybersecurityExpert", name: "CyberSecurity Expert", rank: 10, inUse: true },
    11: { key: "techAdmin", name: "Tech Admin", rank: 11, inUse: false },
    12: { key: "executive", name: "Executive", rank: 12, inUse: false },
    13: { key: "BuildEngineer", name: "Build Engineer", rank: 13, inUse: false },
    14: { key: "BORProjectCoordinator", name: "BOR Project Coordinator", rank: 14, inUse: false },
    15: { key: "SWDeveloper", name: "Software Developer", rank: 15, inUse: false },
    16: { key: "EvidenceServerCoordinator", name: "Evidence Server Coordinator", rank: 16, inUse: false },
  },
};

export default USER_ROLES;
