import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * Returns a activity
 *
 * @param {string} activityId - uuid of activity
 * @returns a activity
 */
export const getActivity = ({ activityId }) => {
  return Brm.activityApi.getActivityByID(activityId);
};

/**
 * Return the ActivityDto .
 *
 * @param {string} activityId - uuid of activity
 * @param {object} config - react-query configuration object
 * @returns react-query-data for an activity
 */
export const useActivity = ({ activityId, config = {} }) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["activity", activityId],
    queryFn: () => getActivity({ activityId }),
  });
};
