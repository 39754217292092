import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Components
import systemModals from "components/modals/Modals";
import GenericUserDeleteConfirmation from "components/modals/GenericUserDeleteConfirmation";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { AddButton, DeleteButton, AdminApi } from "features/brm";
// State
import { userIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useSetRecoilState } from "recoil";
// Styles
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";

import ContentLayout from "brm/layout/Content/ContentLayout";
// Constants
import { TYPE } from "constants/brm";
// Local
import { useRoles } from "features/brm/hooks/useRoles";
import { RoutePath } from "routes/route-paths";
import { UserTable } from "../../components/tables/UserTable";

export const Users = () => {
  const userId = useRecoilValue(userIdState); // current userID
  const setSelectedInstance = useSetRecoilState<any>(selectedInstanceState);

  const { isAdmin } = useRoles();
  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { mutateAsync: deleteUser } = AdminApi.useDeleteUser();
  const addUser = () => {
    const path = RoutePath.AddUser;
    navigate(path);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      const nonSelfUsers = selectedRows.filter((e: any) => e.id !== userId);

      Promise.all(nonSelfUsers.map((each: any) => deleteUser({ userId: each.id })))
        .then(() => {
          setConfirmDelete(false);
          setSelectedRows([]);
          setDeleteModalIsOpen(false);
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteUser, selectedRows, setSelectedInstance, userId]);

  const userHeader = (
    <Title id="User_title">
      Users
      <Buttons id="User_buttons">
        {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
        <AddButton onClick={addUser} />
      </Buttons>
    </Title>
  );

  const userMain = (
    <>
      {isAdmin
        ? deleteModalIsOpen &&
          systemModals.deleteModal(
            "User_page",
            deleteModalIsOpen,
            setDeleteModalIsOpen,
            <GenericUserDeleteConfirmation
              elementName={TYPE.user}
              setDeleteModalIsOpen={setDeleteModalIsOpen}
              selectedRows={selectedRows}
              setConfirmDelete={setConfirmDelete}
            />,
            TYPE.user
          )
        : deleteModalIsOpen &&
          systemModals.deleteModal(
            "User_page",
            deleteModalIsOpen,
            setDeleteModalIsOpen,
            <GenericDeleteConfirmation
              elementName="User"
              setDeleteModalIsOpen={setDeleteModalIsOpen}
              selectedRows={selectedRows}
              setConfirmDelete={setConfirmDelete}
            />,
            TYPE.user
          )}

      <UserTable setSelectedRows={setSelectedRows} />
    </>
  );

  return <ContentLayout pageId="User_page" header={userHeader} main={userMain} />;
};
