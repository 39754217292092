import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as BrmGql from "generated/graphql";
import * as KeyFactory from "./threat-group-query-key-factory";

interface ICreateThreatGroup {
  catalog: string;
  threatGroupCreateDto: any;
}

export interface IUseCreateThreatGroup {
  options?: MutationOption<typeof createThreatGroup>;
}

/**
 *
 * @param {ThreatGroupCreateDto} threatGroupCreateDto creation dto
 * @returns id of created threat group
 */
export const createThreatGroup = ({ catalog, threatGroupCreateDto }: ICreateThreatGroup) => {
  return Brm.threatGroupApi.createThreatGroup(catalog, { threatGroupCreateDto });
};

/**
 * Custom hook to create a new exchange.
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for useCreateThreatGroup
 */
export const useCreateThreatGroup = ({ options }: IUseCreateThreatGroup = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.threatGroupKeys.all);
      queryClient.invalidateQueries(BrmGql.useGetThreatGroupsQuery.getKey());
    },
    ...options,
    mutationFn: createThreatGroup,
  });
};
