import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

/**
 * function making api call to get control type details using control type id
 *
 * @param {string} controlTypeId - uuid of control type id
 * @returns array of object
 */
export const getControlType = async ({ controlTypeId }: { controlTypeId: string }) => {
  let controlTypeToDisplay: any = {};
  const data = await Brm.controlTypeApi.getControlTypeByID(controlTypeId);
  controlTypeToDisplay = data;
  const family = await Brm.controlTypeApi.getControlTypeFamily(controlTypeId);
  const catalog = await Brm.controlTypeApi.getControlTypeCatalog(controlTypeId);
  controlTypeToDisplay.family = family;
  controlTypeToDisplay.catalog = catalog;

  return [controlTypeToDisplay];
};

interface IUseControlType {
  controlTypeId: string;
  options: QueryOption<typeof getControlType>;
}

/**
 * React-query hook for getting  Control type details by id
 *
 * @param {string} controlTypeId - uuid of the control Type
 * @param {object} options - optionsuration for useControlType hook
 * @returns react-query for getControlType
 */
export const useControlType = ({ controlTypeId, options = {} }: IUseControlType) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.controlTypeKeys.controlType(controlTypeId),
    queryFn: () => getControlType({ controlTypeId }),
  });
};
