import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./exchange-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

interface ISetExchangeDataProps {
  exchangeId: string | null;
  name: string;
}

/**
 * Sets the name of an exchange
 *
 * @param {string} id - uuid of the exchange
 * @param {string} name - new name for the exchange
 */
export const setExchangeName = ({ exchangeId, name }: ISetExchangeDataProps) => {
  return Brm.exchangeApi.setExchangeName(exchangeId, { body: name });
};

interface IUseSetExchangeNameProps {
  options?: MutationOption<typeof setExchangeName>;
}

/**
 * Custom hook to change the name of a existing exchange
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetExchangeName = ({ options }: IUseSetExchangeNameProps = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.exchangeKeys.all);
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: setExchangeName,
  });
};
