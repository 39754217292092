import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./threat-catalog-query-key-factory";

export const getThreatCatalog = ({ threatCatalogId }: { threatCatalogId: string }) => {
  return Brm.threatCatalogApi.getThreatCatalogByID(threatCatalogId);
};

interface IUseThreatCatalog {
  threatCatalogId: string;
  options?: QueryOption<typeof getThreatCatalog>;
}

/**
 * React-query hook for getting a Threat Catalogs
 *
 * @param
 * @returns react-query for threat catalog
 */
export const useThreatCatalog = ({ threatCatalogId, options }: IUseThreatCatalog) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatCatalogKeys.catalog(threatCatalogId),
    queryFn: () => getThreatCatalog({ threatCatalogId }),
  });
};
