import PropTypes from "prop-types";
import IconStyled from "components/elements/EditIconStyled";
import DownloadIcon from "assets/icons/download.svg";
import PrimaryStyledButton from "components/elements/PrimaryStyledButton";

export const DownloadButton = ({ onClick, buttonTitle = "Export" }) => (
  <span title={buttonTitle}>
    <PrimaryStyledButton id="DownloadButton_button" onClick={onClick}>
      <IconStyled id="DownloadButton_icon" src={DownloadIcon} alt="download" />
    </PrimaryStyledButton>
  </span>
);

DownloadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string,
};
