import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import errorUtils from "utils/error-utils";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import SelectBox from "components/forms/DynamicSelectBox";
import { variantApi } from "services/brm/component-service";
import { riskApi } from "services/brm/risk-service";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilState } from "recoil";
import { DetailTableType } from "features/brm";

const AddRiskForm = ({ setModalIsOpen, setRefresh, elementNameInUrl, selectedElement }) => {
  const [postError, setPostError] = useState("");
  const [list, setList] = useState([]);
  const [currentProjectId] = useRecoilState(projectIdState);

  useEffect(() => {
    async function getData() {
      try {
        const res = await riskApi.findRiskWithHttpInfo(currentProjectId);
        if (res?.data) {
          setList(res.data);
        }
      } catch (err) {
        console.error(err);
      }
    }
    if (currentProjectId) {
      getData();
    }
  }, [currentProjectId]);

  function getPutRequest(selectedId) {
    switch (elementNameInUrl) {
      case DetailTableType.VARIANTS.key: {
        return variantApi.addVariantRiskWithHttpInfo(selectedElement.id, selectedId);
      }
      default: {
        break;
      }
    }
    return undefined;
  }

  function validate(formData) {
    let error = true;
    if (formData.risks.value === "") {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isValid = validate(e.target.elements);

    if (isValid) {
      try {
        const res = await getPutRequest(e.target.elements.risks.value);
        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setModalIsOpen(false);
          setRefresh(true);
        }
      } catch (err) {
        setPostError("An error occured.");
        console.error("there was an error adding risk to element ", err);
      }
    } else {
      setPostError("*Please fill out all fields in the form prior to submitting.");
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit} action="">
        <FormStyled>
          <div className="form-style">
            <label>Select Risk:</label>
            <SelectBox arrayOfData={list} item="risks" />
          </div>
        </FormStyled>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
          <DialogButtonStyled type="submit">Add</DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};

AddRiskForm.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setModalIsOpen: PropTypes.func,
  setRefresh: PropTypes.func,
};

export default AddRiskForm;
