import PropTypes from "prop-types";
import { useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import * as S from "brm/styles/details-table.styles";
import useGetElementPivotPath from "./api/pivot-path-table-queries";
// Styles

const PivotPathTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const columns = useMemo(() => [createColumnMappedNoEdit("name")], []);

  const { data: getPivotPathData, isError: isGetPivotPathDataError } = useGetElementPivotPath(
    selectedElement.id,
    elementNameInUrl
  );

  if (isGetPivotPathDataError) {
    return <ErrorBanner msg="Error while loading loading path data" />;
  }

  if (getPivotPathData) {
    return (
      <S.DetailsContainer id="PivotPathTable_detailsPanel">
        <S.DetailsTableContainer>
          <BrmDetailTable
            data={getPivotPathData}
            columns={columns}
            customProps={{ id: "PivotPathTable_detailsTable" }}
            showRowSelect={false}
            tableTitle={tableTitle}
          />
        </S.DetailsTableContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

PivotPathTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  tableTitle: PropTypes.string,
};

export default PivotPathTable;
