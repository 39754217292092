import styled from "styled-components";

export const ButtonArea = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  padding: 6.4px 24px;
`;

export const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  color: #007bff;
  margin: 0px 3px;

  &:focus {
    outline: none;
  }
  &:hover {
    color: #007bff;
    text-decoration: underline;
  }
  &:disabled {
    color: grey;
    text-decoration: none;
  }
  .p {
    color: #007bff;
  }
`;
