import PropTypes from "prop-types";
import { useState } from "react";
import toast from "react-hot-toast";
import { queryClient } from "libs/react-query";

// State
import { useRecoilValue } from "recoil";
import { variantIdState } from "atoms/atoms-component";
import { impactOptionsState } from "atoms/atoms-impact-calibration";
import { projectIdState } from "atoms/atoms-admin";

// Components
import Select from "react-select";
import systemModals from "components/modals/Modals";

// Services
import * as BrmGql from "generated/graphql";
import { RiskApi } from "features/brm";
import { convertLevelForBackend } from "utils/calibration-converter";

// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { setCalibrationStyle } from "components/elements/ReactSelectCustomStyles";
import * as S from "../styles/modal-styles";

export const SetImpactLevelModal = ({ modalIsOpen, setModalIsOpen, selectedRows, hideDisabledUes }) => {
  const impactOptions = useRecoilValue(impactOptionsState);
  const [selectedImpactLevel, setSelectedImpactLeve] = useState("");
  const variantId = useRecoilValue(variantIdState);
  const projectId = useRecoilValue(projectIdState);
  const { mutate: setUndesiredEventImpact } = BrmGql.useSetUndesiredEventImpactMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(
        RiskApi.undesiredEventKeys.impactCalibrationUEs(projectId, variantId, hideDisabledUes)
      );
    },
    onError: () => {
      toast.error("Error occured while setting impact level", {
        duration: 4000,
      });
    },
  });

  async function setLevelClick() {
    const convertedLevel = convertLevelForBackend(selectedImpactLevel.label.toLowerCase());
    let nonUpdatedImpact = false;
    const selectRowsToModify = (row) => {
      if (row.impact.stringValue !== convertedLevel) {
        return true;
      }
      nonUpdatedImpact = true;
      return false;
    };
    const ueIds = selectedRows.filter(selectRowsToModify).map((each) => each.id);
    setUndesiredEventImpact(
      { ids: ueIds, project: projectId, variant: variantId, calibratedStringValue: convertedLevel },
      {
        onSuccess: () => {
          if (nonUpdatedImpact) {
            if (selectedRows.length > 1) {
              toast(
                "Some of the chosen UEs are already at the Impact Level selected, so those chosen Attacks will not be adjusted",
                {
                  duration: 6000,
                }
              );
            } else {
              toast("UE is already at the Impact Level selected, please select a different Impact Level", {
                duration: 5000,
              });
            }
          } else {
            toast.success("Impact Level set successfully", { duration: 4000 });
          }
          setModalIsOpen(false);
        },
        enabled: !!projectId && !!variantId && !!ueIds && !!convertedLevel,
      }
    );
  }

  const handleCancel = () => {
    setModalIsOpen(false);
  };

  const body = (
    <>
      <S.ShortBodyDiv>
        <p>Select the desired calibrated level:</p>
        <Select
          isMulti={false}
          options={impactOptions}
          defaultValue={selectedImpactLevel}
          onChange={(selectedItem) => setSelectedImpactLeve(selectedItem)}
          styles={setCalibrationStyle}
          classNamePrefix="impactLevelDropdown"
          id="SetImpactLevelModal_impactLevelDropdown"
        />
      </S.ShortBodyDiv>
      <DialogButtonDivStyled>
        <DialogButtonStyled id="SetImpactLevelModal_cancelButton" onClick={handleCancel}>
          Cancel
        </DialogButtonStyled>
        <DialogButtonStyled
          id="SetImpactLevelModal_confirmButton"
          onClick={() => setLevelClick()}
          disabled={selectedImpactLevel === ""}
        >
          Confirm
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </>
  );

  return (
    <div id="SetImpactLevelModal_modal">
      {systemModals.customModal(
        "SetImpactLevelModal_modal",
        modalIsOpen,
        setModalIsOpen,
        body,
        "Set Calibrated Impact Level"
      )}
    </div>
  );
};

SetImpactLevelModal.propTypes = {
  modalIsOpen: PropTypes.any,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
  setModalIsOpen: PropTypes.func,
  hideDisabledUes: PropTypes.bool,
};
