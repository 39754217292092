import { MdOutlineHelp } from "react-icons/md";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Separator } from "components/elements/NavigationToolbar";
import { HelpFaq } from "../HelpFaq";
import * as S from "./HelpHome.styles";

const docFilePath = "/help-assets/resources/documentation/Documentation.zip";
const sampleFilePath = "/help-assets/resources/samples/Samples.zip";

export const HelpHomeText = () => {
  const theme = useContext(ThemeContext);
  return (
    <>
      <div>
        <S.HomeHelptext>
          {`The ‘Quick Start’ page provides task related information to help you get up and running quickly in Blade
      RiskManager.`}
        </S.HomeHelptext>

        <S.HomeHelptext>
          <span>When working in Blade RiskManager selecting the Help</span>
          {MdOutlineHelp && (
            <MdOutlineHelp
              title="Help"
              size={22}
              width="calc(100% - 44px)"
              overflow="auto"
              fill={theme.colors.buttonPrimary}
              height={theme.sizes.standaloneIconHeight}
            />
          )}
          <span>
            {` icon, in the right navigation slides open a help panel that provides a context sensitive list of "How to"
        videos and ‘FAQs’ related to the current page`}
          </span>
        </S.HomeHelptext>
      </div>

      <S.SeparatorWrapper>
        <Separator />
      </S.SeparatorWrapper>

      <S.DocContainer>
        <S.DocHeading>Resources</S.DocHeading>
        <S.Subtask>
          <li>
            <a href={docFilePath} download>
              Documentation
            </a>
          </li>
          <li>
            <a href={sampleFilePath} download>
              Samples
            </a>
          </li>
        </S.Subtask>
      </S.DocContainer>

      <S.SeparatorWrapper>
        <Separator />
      </S.SeparatorWrapper>

      <HelpFaq />
    </>
  );
};
