import * as React from "react";
import * as Recoil from "recoil";
import VulnerabilityVariantSelect from "brm/risk-model/vulnerability/utils/VulnerabilityVariantSelect";
import systemModals from "components/modals/Modals";
import { DetailTableType, Levels, VariantState } from "features/brm";
// import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { MdInfoOutline } from "react-icons/md";

// Styles
import { riskUpdatedState /* , autoMitigateState */ } from "atoms/atoms-risk";
import { useElementVulnerabilities } from "brm/tables/details/api";
import { CheckBox } from "components/elements";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { usePerformAutoMitigate } from "features/risk";
import * as Vulstyle from "./modal.styles";
import { AddVariantForm } from "../../forms/AddVariantForm";
import { VulnerabilitySelectionTable } from "../RarManualMitigateForm/VulnerabilitySelectionTable";
import { IVulnerabilityData, VulnerabilitySelection } from "../RarManualMitigateForm/types";
import * as S from "./VulnerabilitiesModal.styles";
import { toVulnerabilities } from "../RarManualMitigateForm/selection-to-type";

interface IOptionProps {
  vulList: any[];
  handleChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

interface IVulnerabilitiesModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: (v: boolean) => void;
  vulnerabilities: IVulnerabilityData[];
  riskId: string;
  projectId: string;
  variantId: string;
}

const Options = ({ vulList, handleChange }: IOptionProps) => (
  <Vulstyle.List>
    {vulList.map((each: any) => (
      <div key={each.id}>
        <CheckBox label={each.name} onCheckboxChange={handleChange} />
        <br />
      </div>
    ))}
  </Vulstyle.List>
);

const VulnerabilitiesModal = ({
  modalIsOpen,
  setModalIsOpen,
  vulnerabilities,
  riskId,
  projectId,
  variantId,
}: IVulnerabilitiesModalProps) => {
  const setRiskUpdated = Recoil.useSetRecoilState(riskUpdatedState);
  const [displayModalIsOpen, setDisplayModalIsOpen] = React.useState(true);
  const [variantSelectModalIsOpen, setVariantSelectModalIsOpen] = React.useState(false);
  const [variantAddModalIsOpen, setVariantAddModalIsOpen] = React.useState(false);
  const [isShowVul, setIsShowVul] = React.useState(false);
  const [newVariant, setNewVariant] = React.useState();

  // const selectedVulnerabilityRef = React.useRef<VulnerabilitySelection[]>(
  //   vulnerabilities.map((e, i) => ({ value: e, rowId: i.toString() }))
  // );

  const [selectedRiskVulnerabilities, setSelectedRiskVulnerabilities] = React.useState<VulnerabilitySelection[]>([]);
  const [riskVulnerabilities, setRiskVulnerabilities] = React.useState<any>([]);
  const { data: allVulnerabilitiesData } = useElementVulnerabilities({
    elementId: riskId,
    projectId,
    variantId,
    elementName: DetailTableType.RISKS.key,
    config: { enabled: !!riskId },
  });
  const totalVulnerabilities = vulnerabilities.concat(riskVulnerabilities);
  const [rowSelection, setRowSelection] = React.useState({});

  React.useEffect(() => {
    if (allVulnerabilitiesData) {
      const list = allVulnerabilitiesData
        ?.filter((val: any) => !vulnerabilities.some((val2) => val.id === val2.id))
        // Filter out the very low vulnerabilities since they are not very useful
        .filter((val2: any) => val2.level.calcStringValue !== Levels.VERY_LOW.id)
        .map((e: any) => ({ ...e, isSelected: false }));
      setRiskVulnerabilities(list);
    }
  }, [allVulnerabilitiesData, vulnerabilities]);

  const selectAll = React.useCallback(() => {
    const vulSelection: Record<string, boolean> = {};
    vulnerabilities.forEach((v, index) => {
      vulSelection[index as unknown as string] = true;
    });
    setRowSelection(vulSelection);
  }, [vulnerabilities]);

  React.useEffect(() => {
    selectAll();
  }, [selectAll, vulnerabilities]);

  const autoMitigate = usePerformAutoMitigate({
    onSuccess: () => {
      setModalIsOpen(false);
      setRiskUpdated(true);
      // setAutoMigitatedState("Completed");
    },
    onError: () => {
      console.error("auto mitgates failed");
    },
  });

  const performAutoMitigate = async (varState: VariantState) => {
    setDisplayModalIsOpen(false);

    const vulIds = toVulnerabilities(vulnerabilities, rowSelection).map((v) => v.id) as string[];
    const vulRiskIds = selectedRiskVulnerabilities.map((v) => v.value.id) as string[];

    autoMitigate(varState, [...vulIds, ...vulRiskIds]);
  };

  const handleCancel = () => {
    setModalIsOpen(false);
  };

  const handleSelectClick = (isSelectAll: boolean) => {
    if (isSelectAll) {
      const vulSelection: Record<string, boolean> = {};
      vulnerabilities.forEach((v, index) => {
        vulSelection[index as unknown as string] = true;
      });
      setRowSelection(vulSelection);
      // selectedVulnerabilityRef.current = vulnerabilities.map((e, i) => ({ value: e, rowId: i.toString() }));
      setSelectedRiskVulnerabilities(
        riskVulnerabilities.map((e: any, i: number) => ({ value: e, rowId: i.toString() }))
      );
    } else {
      setRowSelection({});
      // selectedVulnerabilityRef.current = [];
      setSelectedRiskVulnerabilities([]);
    }
  };

  // rar vulnerabilities row handler
  // const handleRowSelection = (rowSelection: VulnerabilitySelection[]) => {
  //   selectedVulnerabilityRef.current = rowSelection;
  // };

  // other vulnerabilities checkbox handler
  const handleVulCheckBoxChange = (changeEvent: any) => {
    const { name } = changeEvent.target;
    const element = riskVulnerabilities.find((e: any) => name === e.name);
    const isChecked = selectedRiskVulnerabilities.some((sr) => sr.value.id === element.id);

    if (!isChecked) {
      setSelectedRiskVulnerabilities([...selectedRiskVulnerabilities, { rowId: "", value: element }]);
    } else {
      setSelectedRiskVulnerabilities(selectedRiskVulnerabilities.filter((s) => s.value.id !== element.id));
    }
  };

  // {
  //   /* <Options
  //   vulList={vulList}
  //   handleChange={handleRarVulCheckBoxChange} /* id="VulnerabilitiesModal_rarVul" */
  //   /> */
  // }

  const popover = (
    <S.ShadowPopover id="popover-basic">
      <Popover.Header as="h3"> Mitigation Hints</Popover.Header>
      <Popover.Body>
        <S.Title>Rank</S.Title>
        <S.List>
          <S.ListItem>
            The Global rank of the Vulnerability Conditions within this Variant of the Project, based on importance to
            the Risk score for the Contributors affected by this Risk.
          </S.ListItem>
        </S.List>

        <S.Title>Vulnerability Category</S.Title>
        <S.List>
          <S.ListItem>
            A Vulnerability Category is a classification associated with a Vulnerability Condition that more precisely
            indicates the affect of the vulnerability. The category name can be captured as an abbreviation. For
            example, “iaaa” is “Inadequate audit and accountability”.
          </S.ListItem>
        </S.List>
        <S.Title>Vulnerability Condition</S.Title>
        <S.List>
          <S.ListItem>
            A Vulnerability Condition allows exploiting this system in unauthorised and possibly even malicious ways.
          </S.ListItem>
        </S.List>
        <S.Title>Vulnerability Condition Level</S.Title>
        <S.List>
          <S.ListItem>
            The Vulnerability Condition Level is the impact range (very low, low, moderate, high, very high) of a
            Vulnerability Condition.
          </S.ListItem>
        </S.List>
      </Popover.Body>
    </S.ShadowPopover>
  );

  const body = (
    <S.Container id="VulnerabilitiesModal">
      {vulnerabilities.length > 0 ? (
        <div>
          <div>
            <div
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "5px" }}
            >
              {Object.keys(rowSelection).length > 0 ? (
                <p>
                  Are you sure you want to mitigate {Object.keys(rowSelection).length} vulnerabilites of the selected
                  contributor?
                </p>
              ) : (
                <p> No vulnerabilites selected to mitigate</p>
              )}
              <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                <Button title="Auto Mitigation Hints" size="sm" variant="link" style={{ margin: "3px" }}>
                  <MdInfoOutline size="24" />
                </Button>
              </OverlayTrigger>
            </div>
            <Vulstyle.DisplayVul id="VulnerabilitiesModal_vulnerabilities">
              <Vulstyle.VulListDiv>
                <Vulstyle.Title>RAR selected vulnerabilities</Vulstyle.Title>
                <VulnerabilitySelectionTable
                  vulnerabilities={vulnerabilities}
                  selectedVulnerabilities={rowSelection}
                  onRowSelect={setRowSelection}
                />
              </Vulstyle.VulListDiv>

              <Vulstyle.AdditionalVulSpan>
                <Vulstyle.Button
                  onClick={() => setIsShowVul(() => !isShowVul)}
                  id="VulnerabilitiesModal_additionalVulButton"
                >
                  <Vulstyle.Title>
                    Additional vulnerabilities
                    {isShowVul ? <Vulstyle.AdditonalVulChevronDown /> : <Vulstyle.AdditonalVulChevronRight />}
                  </Vulstyle.Title>
                </Vulstyle.Button>
              </Vulstyle.AdditionalVulSpan>

              {isShowVul && (
                <div>
                  {riskVulnerabilities.length > 0 ? (
                    <Options
                      vulList={riskVulnerabilities}
                      handleChange={handleVulCheckBoxChange}
                      // id="VulnerabilitiesModal_additionalVul"
                    />
                  ) : (
                    <p> No additional vulnerabilities </p>
                  )}
                </div>
              )}
            </Vulstyle.DisplayVul>
          </div>
          <DialogButtonDivStyled>
            <DialogButtonStyled id="VulnerabilitiesModal_cancelButton" onClick={handleCancel}>
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled
              disabled={Object.keys(rowSelection).length === totalVulnerabilities.length}
              onClick={() => handleSelectClick(true)}
              id="VulnerabilitiesModal_selectAll"
            >
              Select all
            </DialogButtonStyled>
            <DialogButtonStyled
              disabled={Object.keys(rowSelection).length === 0}
              onClick={() => handleSelectClick(false)}
              id="VulnerabilitiesModal_deselectAll"
            >
              Deselect all
            </DialogButtonStyled>
            <DialogButtonStyled
              id="VulnerabilitiesModal_confirmButton"
              onClick={() => setVariantSelectModalIsOpen(true)}
              disabled={Object.keys(rowSelection).length === 0}
            >
              Confirm
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </div>
      ) : (
        <>
          <p>All vulnerabilities are mitigated!</p>
          <DialogButtonDivStyled>
            <DialogButtonStyled id="VulnerabilitiesModal_cancelButton" onClick={handleCancel}>
              Cancel
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </>
      )}
    </S.Container>
  );

  return (
    <div id="VulnerabilitiesModal">
      {displayModalIsOpen &&
        systemModals.customModal(
          "VulnerabilitiesModal_modal",
          modalIsOpen,
          setModalIsOpen,
          body,
          "Auto Vulnerability Mitigation"
        )}

      {variantSelectModalIsOpen &&
        systemModals.customModal(
          "VulnerabilitiesModal_selectVariant",
          variantSelectModalIsOpen,
          setVariantSelectModalIsOpen,
          <VulnerabilityVariantSelect
            setModalIsOpen={setVariantAddModalIsOpen}
            setVariantSelectModalIsOpen={setVariantSelectModalIsOpen}
            onAutoMitigate={performAutoMitigate}
            newVariant={newVariant}
          />,
          "Select Variant"
        )}

      {variantAddModalIsOpen &&
        systemModals.addModal(
          "VulnerabilitiesModal_addVariant",
          variantAddModalIsOpen,
          setVariantAddModalIsOpen,
          <AddVariantForm setModalIsOpen={setVariantAddModalIsOpen} onNewVariant={setNewVariant} />,
          "Variant"
        )}
    </div>
  );
};

export default VulnerabilitiesModal;
