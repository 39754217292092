import { sortByLabel, sortByLabelAlphanumeric } from "utils/sorting";

function getReactSelectArray(array, alphanumeric = false) {
  const objects = [];
  if (array && array.length > 0) {
    array.forEach((obj) => {
      objects.push({
        value: obj.id,
        label: obj.name,
      });
    });

    if (alphanumeric) {
      return objects.sort(sortByLabelAlphanumeric);
    }

    return objects.sort(sortByLabel);
  }
  return objects;
}
export default getReactSelectArray;
