import * as React from "react";

import { AdminApi, ProjectState, useProject } from "features/brm";
import { IOption } from "types";
import { ProjectCreateDto } from "@kdmanalytics/brm-admin";
import { STATUS } from "constants/brm";
import { useSap } from "features/admin/hooks";
import toast from "react-hot-toast";
import { ICreateProjectInput } from "./types";
import { useAdminOrganization } from "./useAdminOranization";

interface IProjectUsers {
  users: IOption[] | undefined;
  readonlyUsers: IOption[] | undefined;
}

// Project creation status interval
const POLL_INTERVAL = 1000;

interface IUseCreateProjectProps {
  onSuccess: (projectState: ProjectState) => void;
  onError: (error: string) => void;
}

export const useCreateProject = ({ onSuccess, onError }: IUseCreateProjectProps) => {
  const [, { setCurrentProject }] = useProject();

  const { mutate: createProjectMutation } = AdminApi.useCreateProject();
  const { mutateAsync: addWorker } = AdminApi.useAddProjectWorker();
  const { mutateAsync: addReadonlUser } = AdminApi.useAddProjectReadOnlyUser();
  const { organizationId, organizationName } = useAdminOrganization();
  const [newProjState, setNewProjectState] = React.useState<ProjectState | null>(null);
  const [stop, setStop] = React.useState(false);
  const [projectUserMap] = React.useState(new Map<string, IProjectUsers>());
  // set the programs for this project
  const { mutateAsync: setSapPrograms } = AdminApi.useSetProjectSapPrograms();
  const [sapPrograms, setSapProgramIds] = React.useState<string[]>([]);

  // Is Sap enabled or not..
  const { isEnabled: isSapEnabled } = useSap({ isAdminMode: true });

  const createProject = React.useCallback(
    (projectInput: ICreateProjectInput) => {
      const params = {
        name: projectInput.name,
        note: projectInput.note || "",
        isListed: true,
      };

      createProjectMutation(
        {
          organizationId,
          projectCreateDto: ProjectCreateDto.constructFromObject(params),
        },
        {
          onSuccess: async (projectId) => {
            // Optimisticaly set the project state while we wait for the polling...
            const projState: ProjectState = {
              id: projectId,
              name: params.name,
              status: STATUS.inPreparation,
              isListed: params.isListed,
              organization: {
                id: organizationId,
                name: organizationName,
                status: STATUS.active,
              },
            };
            // optimistically create the new projectstate to set after project is created.
            setNewProjectState(projState);
            // keep track of the users until the project status says we can add them...
            projectUserMap.set(projectId, { users: projectInput.users, readonlyUsers: projectInput.readOnlyUsers });
            setSapProgramIds(projectInput.sapPrograms?.map((p) => p.value) || []);
          },
          onError: (error: Error) => {
            onError(error.message);
          },
        }
      );
    },
    [createProjectMutation, onError, organizationId, organizationName, projectUserMap]
  );

  const finalizeProject = React.useCallback(
    async (projectId: string) => {
      if (isSapEnabled && projectId) {
        if (Array.isArray(sapPrograms) && sapPrograms.length) {
          try {
            await setSapPrograms({ projectId, sapProgramIds: sapPrograms });
          } catch (error) {
            toast.error("Unable to add SAP programs to the project");
          }
        } else {
          // No Sap programs means we cannot add users so we can bail here.
          setCurrentProject(newProjState);
          setNewProjectState(null);
          return;
        }
      }

      const userMap = projectUserMap.get(projectId);
      if (userMap) {
        if (userMap.users) {
          try {
            await Promise.all(userMap.users.map((user: IOption) => addWorker({ projectId, userId: user.value })));
          } catch (error) {
            toast.error("Unable to add user to the project");
          }
          userMap.users = undefined;
        }
        if (userMap.readonlyUsers) {
          try {
            await Promise.all(
              userMap.readonlyUsers.map((user: IOption) => addReadonlUser({ projectId, userId: user.value }))
            );
          } catch (error) {
            toast.error("Unable to add readonly user to the project");
          }

          userMap.readonlyUsers = undefined;
        }
        projectUserMap.delete(projectId);
      }

      setCurrentProject(newProjState);
      setNewProjectState(null);
    },
    [
      addReadonlUser,
      addWorker,
      isSapEnabled,
      newProjState,
      projectUserMap,
      sapPrograms,
      setCurrentProject,
      setSapPrograms,
    ]
  );

  AdminApi.useProjectStatus({
    projectId: newProjState?.id || "",
    options: {
      enabled: newProjState !== null,
      refetchInterval: stop ? false : POLL_INTERVAL,
      onSuccess: (pollData) => {
        const pollStatus = pollData.result;
        if (pollStatus === STATUS.inPreparation || pollStatus === STATUS.active) {
          setStop(true);
          if (newProjState?.id) {
            finalizeProject(newProjState.id);
            onSuccess(newProjState);
          }
        }
      },
      onError: () => {
        setStop(true);
        onError("Creation Polling failed.");
      },
    },
  });

  return {
    createProject,
  };
};
