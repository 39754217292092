import * as ReactQuery from "@tanstack/react-query";
import { MutationOption /* , queryClient */ } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./sap-org-query-key-factory";
import * as SapProgramKeyFactory from "../sapProgram/sap-program-query-key-factory";

export interface IRemoveSapProgramProps {
  orgId: string;
  progId: string;
}

/**
 * Delete an sap org program
 *
 * @param param0 upload file data
 * @returns
 */
export const removeSapProgram = ({ orgId, progId }: IRemoveSapProgramProps) => {
  // console.log("remove orgId, progId", orgId, progId);
  return Brm.sapOrganizationApi.removeSapOrganizationSapprogram(orgId, progId);
};

type MutationFunction = typeof removeSapProgram;
export interface IUseRemoveSapProgramProps {
  options?: MutationOption<MutationFunction>;
}

/**
 * Custom hook to Delete an SapOrg program
 *
 * @returns react-query Mutation.
 */
export const useRemoveSapProgram = ({ options }: IUseRemoveSapProgramProps = {}) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation({
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: KeyFactory.sapOrganizationKeys.all });
      queryClient.invalidateQueries({ queryKey: SapProgramKeyFactory.sapProgramKeys.all });
    },
    mutationFn: removeSapProgram,
  });
};
