import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { capabilityApi } from "services/brm/system-service";
import { AddButton, BrmError, DeleteButton, DetailTableType } from "features/brm";
import systemModals from "components/modals/Modals";
import { BrmDetailTable } from "brm/tables/BrmTables";
import {
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnNameDetails,
} from "brm/tables/services/column/columnFactory";
import * as S from "brm/styles/details-table.styles";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { LoadingSpinner, ErrorBanner } from "components/elements";
import { RoutePath } from "routes/route-paths";
import PersonAddForm from "./forms/PersonAddForm";
import { useElementPersons } from "./api";
import multiSelectDelete from "./utils/DetailTablesUtils";

const PersonTable = ({ elementNameInUrl, selectedElement, showAdd, showDelete, setRefresh, tableTitle }) => {
  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Person.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnBooleanNoEditMapped("isInternal"),
    ],
    []
  );

  const [, setSelectedPerson] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);

  const { data: personData, isError: isPersonDataError } = useElementPersons({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    variantId,
    config: { enabled: !!selectedElement?.id && !!elementNameInUrl },
  });

  function getDeleteRequest(personId) {
    switch (elementNameInUrl) {
      case DetailTableType.CAPABILITIES.key: {
        return capabilityApi.removeCapabilityPersonWithHttpInfo(selectedElement.id, personId);
      }
      default: {
        break;
      }
    }
    throw new BrmError(`PersonTable:: Unsupported element type:${elementNameInUrl}`);
  }

  const addPerson = () => {
    setModalIsOpen(true);
  };

  if (isPersonDataError) {
    return <ErrorBanner msg="Error while loading person data" />;
  }

  if (personData) {
    return (
      <S.DetailsContainer id="PersonTable_detailsPanel">
        {/* modal for adding instance */}
        {modalIsOpen &&
          systemModals.addModal(
            "PersonTable_detailsPanel",
            modalIsOpen,
            setModalIsOpen,
            <PersonAddForm
              setModalIsOpen={setModalIsOpen}
              setRefresh={setRefresh}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
            />,
            "Person"
          )}
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={personData}
              columns={columns}
              setSelectedElement={setSelectedPerson}
              setSelectedRows={setSelectedRows}
              customProps={{ id: "PersonTable_detailsTable" }}
              showRowSelect={showDelete}
              tableTitle={tableTitle}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            {showDelete && (
              <DeleteButton md onClick={() => multiSelectDelete(selectedRows, getDeleteRequest, setRefresh)} />
            )}
            {showAdd && <AddButton md onClick={addPerson} />}
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

PersonTable.propTypes = {
  elementNameInUrl: PropTypes.any,

  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setRefresh: PropTypes.any,
  showAdd: PropTypes.any,
  showDelete: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default PersonTable;
