import styled from "styled-components/macro";

export const AccountHeaderRoot = styled.header`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  height: 56px;
  position: relative;
  background-color: #ffffff;
  color: #6b778c;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 4px;
    background: linear-gradient(
      180deg,
      rgba(9, 30, 66, 0.13) 0,
      rgba(9, 30, 66, 0.13) 1px,
      rgba(9, 30, 66, 0.08) 1px,
      rgba(9, 30, 66, 0) 4px
    );
  }
`;

export const PrimaryContainer = styled.nav`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding: ${({ theme }) => theme.sizes.defaultPadding};
`;
