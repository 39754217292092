import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import { CategorizedEntityObjectiveDetailsTable } from "features/detail-tables";
import PropTypes from "prop-types";
import UndesiredEventsTable from "brm/tables/details/UndesiredEventTable";
import RiskTable from "brm/tables/details/RiskTable";
import { DetailTableType } from "features/brm";
// Hooks
import { useRoles } from "features/brm/hooks/useRoles";

const SecurityObjectiveDetails = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.SECURITY_OBJECTIVES.key;
  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  return (
    <Tabs>
      <TabList>
        {(isRiskAnalyst || isSystemEngineer) && <Tab>Categorized Entities</Tab>}
        {isRiskAnalyst && (
          <>
            <Tab>Undesired Events</Tab>
            <Tab>Risks</Tab>
          </>
        )}
      </TabList>

      {(isRiskAnalyst || isSystemEngineer) && (
        <>
          <TabPanel>
            <CategorizedEntityObjectiveDetailsTable selectedElement={selectedElement} />
          </TabPanel>
        </>
      )}
      {isRiskAnalyst && (
        <>
          <TabPanel>
            <UndesiredEventsTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
          </TabPanel>
          <TabPanel>
            <RiskTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
          </TabPanel>
        </>
      )}
    </Tabs>
  );
};

SecurityObjectiveDetails.propTypes = {
  selectedElement: PropTypes.object.isRequired,
};

export default SecurityObjectiveDetails;
