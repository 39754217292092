import { useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { createColumnBooleanNoEditMapped, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { LoadingSpinner, ErrorBanner } from "components/elements";
import { RoutePath } from "routes/route-paths";
import { useElementMissions } from "./api";

interface IMissionProps {
  elementNameInUrl: string;
  selectedElement: {
    id: string;
  };
  tableTitle: string;
}

const MissionTable = ({ elementNameInUrl, selectedElement, tableTitle }: IMissionProps) => {
  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Mission.replace(":id", "")),
      createColumnBooleanNoEditMapped("isInternal"),
    ],
    []
  );
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);

  const { data: missionsData, isError: isMissionsDataError } = useElementMissions({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    variantId,
    options: { enabled: !!selectedElement?.id && !!elementNameInUrl },
  });

  if (isMissionsDataError) {
    return <ErrorBanner msg="Error while attempting to load missions data" />;
  }

  if (missionsData) {
    return (
      <S.DetailsContainer id="MemberTable_detailsPanel">
        <BrmDetailTable
          data={missionsData}
          columns={columns}
          customProps={{ id: "MemberTable_detailsTable" }}
          showRowSelect={false}
          tableTitle={tableTitle}
        />
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

export default MissionTable;
