import * as ReactQuery from "@tanstack/react-query";
import { MutationOption /* , queryClient */ } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./sap-org-query-key-factory";
import * as SapProgramKeyFactory from "../sapProgram/sap-program-query-key-factory";

export interface IAddSapProgramProps {
  orgId: string;
  progId: string;
}

/**
 * Add an sap org program
 *
 * @returns
 */
export const addSapProgram = ({ orgId, progId }: IAddSapProgramProps) => {
  // console.log("add orgId, progId", orgId, progId);
  return Brm.sapOrganizationApi.addSapOrganizationSapprogram(orgId, { body: progId });
};

type MutationFunction = typeof addSapProgram;
export interface IUseAddSapProgramProps {
  options?: MutationOption<MutationFunction>;
}

/**
 * Custom hook to Add an SapOrg program
 *
 * @returns react-query Mutation.
 */
export const useAddSapProgram = ({ options }: IUseAddSapProgramProps = {}) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation({
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: KeyFactory.sapOrganizationKeys.all });
      queryClient.invalidateQueries({ queryKey: SapProgramKeyFactory.sapProgramKeys.all });
    },
    mutationFn: addSapProgram,
  });
};
