import * as React from "react";

import { useParams, useSearchParams } from "react-router-dom";
import ButtonCell from "components/EditComponents/ButtonCell";
import { ElementFullDetails, AdminApi } from "features/brm";
import {
  createColumnMapped,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnAdmin,
  createColumnCount,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import { STATUS, TYPE } from "constants/brm";
import { useRoles } from "features/brm/hooks/useRoles";
import { InstallationDetailsTab } from "../../components/tabs/InstallationDetailsTab";

type InstallationOverviewParams = {
  id: string;
};

const toResult = (data: any) => {
  return data.result;
};

export const InstallationOverview = () => {
  const { id } = useParams<InstallationOverviewParams>();
  const [searchParams] = useSearchParams();
  const [editMode, setEditMode] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [statusList] = React.useState([{ id: STATUS.active, name: STATUS.active }]);
  const updatedName = React.useRef("");
  const updatedStatus = React.useRef("");
  const updatedAdmin = React.useRef("");
  const { isSuperAdmin } = useRoles();

  const { data: users } = AdminApi.useUsers({ options: { enabled: isSuperAdmin } });
  const { data: installation } = AdminApi.useInstallation({
    installationId: id || null,
    options: { enabled: isSuperAdmin },
  });

  const adminId = installation?.admin;
  const installationId = installation?.id;

  const { data: installationOrgCount } = AdminApi.useInstallationOrganizationCount({
    installationId,
    options: {
      enabled: !!installation && isSuperAdmin,
      select: toResult,
    },
  });
  const { data: installationProjectCount } = AdminApi.useInstallationProjectCount({
    installationId,
    options: {
      enabled: !!installation && isSuperAdmin,
      select: toResult,
    },
  });
  const { data: installationUserCount } = AdminApi.useInstallationUserCount({
    installationId,
    options: {
      enabled: !!installation && isSuperAdmin,
      select: toResult,
    },
  });
  const { mutate: setInstallationName } = AdminApi.useSetInstallationName();
  const { mutate: setInstallationStatus } = AdminApi.useSetInstallationStatus();
  const { mutate: setInstallationAdmin } = AdminApi.useSetInstallationAdmin();

  const createButton = React.useCallback(
    (cellProps) => (
      <ButtonCell
        selectedRowId={selectedRowId}
        elementId={cellProps.cell.row.original.id}
        handleConfirmEditClick={async () => {
          setEditMode(false);
          // send request to update name
          if (updatedName.current !== "") {
            setInstallationName({ installationId: selectedRowId, newName: updatedName.current });
            updatedName.current = "";
          }

          // send request to update status
          if (updatedStatus.current && updatedStatus.current !== "Select status") {
            setInstallationStatus({ installationId: selectedRowId, newStatus: updatedStatus.current });
            updatedStatus.current = "";
          }

          // send request to update admin
          if (updatedAdmin.current && updatedAdmin.current !== "Select admin") {
            setInstallationAdmin({ installationId: selectedRowId, newAdmin: updatedAdmin.current });
            updatedAdmin.current = "";
          }
        }}
        setEditMode={setEditMode}
        editMode={editMode}
      />
    ),
    [editMode, selectedRowId, setInstallationAdmin, setInstallationName, setInstallationStatus]
  );

  const columns = React.useMemo(
    () => [
      createColumnMapped("status", selectedRowId, statusList, editMode, updatedStatus),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnAdmin(selectedRowId, users, editMode, updatedAdmin),
      createColumnCount("Organization Count", "organizationCount"),
      createColumnCount("Project Count", "projectCount"),
      createColumnCount("User Count", "userCount"),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [editMode, statusList, selectedRowId, users, createButton]
  );

  const detailsComp = <InstallationDetailsTab tab={searchParams.get("tab")} />;

  if (
    users &&
    installation &&
    installationOrgCount &&
    !Number.isNaN(installationProjectCount) &&
    installationUserCount
  ) {
    const adminName = users.filter((u: any) => u.id === adminId)[0]?.username;

    const installationData: any[] = [
      {
        ...installation,
        admin: adminName || "",
        organizationCount: installationOrgCount,
        projectCount: installationProjectCount,
        userCount: installationUserCount,
      },
    ];

    return (
      <ElementFullDetails
        showBackLink
        data={installationData}
        columns={columns}
        selectedElement={installation}
        setSelectedElement={() => {}}
        setSelectedRowId={setSelectedRowId}
        detailsComp={detailsComp}
        compName={TYPE.installation}
      />
    );
  }

  return <p>Loading...</p>;
};
