import styled from "styled-components";

const TextFieldStyles = {
  Msg: styled.p`
    color: red;
    display: inline;
    padding-left: 5px;
  `,
  Input: styled.input`
    margin-top: 5px;
    width: 100%;
  `,
  Label: styled.label`
    width: 100%;
    margin-bottom: 0px;
  `,
};

export default TextFieldStyles;
