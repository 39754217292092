import * as React from "react";
import * as Recoil from "recoil";
import { useRoles } from "features/brm/hooks/useRoles";
import { useUserProjectPermission } from "features/brm/api/admin";
import { isCurrentProjectPrepState } from "../../../stores";
import { useProject } from "../../../hooks/useProject";
import { useNodes } from "../../system";

interface IUseCanPublishProps {
  onFailure?: (nodes: any, isInPrepState: boolean) => void | null;
}

/**
 * Custom hook for validating if a project can be published and either setting the publish button tooltip or showing a toast warning
 * @returns boolean.
 */
export function useCanPublish() {
  const { isSystemEngineer, userId } = useRoles();
  const [currentProject, { projectId }] = useProject();
  const isInPreparation = Recoil.useRecoilValue(isCurrentProjectPrepState);

  const projId = currentProject?.id || "";
  const hasProjectPermission = useUserProjectPermission({
    userId,
    projectId: projId,
    options: {
      select: (data: any) => data.hasProjectPermission,
    },
  });

  const { data: nodes } = useNodes({
    projectId,
    options: { enabled: isSystemEngineer && hasProjectPermission && !!projectId && isInPreparation },
  });

  const canPublish = React.useCallback(
    ({ onFailure = () => {} }: IUseCanPublishProps = {}) => {
      let response = false;

      if (nodes && nodes.length > 0 && isInPreparation) {
        response = true;
      } else {
        onFailure(nodes, isInPreparation);
      }

      return response;
    },
    [isInPreparation, nodes]
  );
  return { canPublish };
}
