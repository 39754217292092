import { useQuery } from "@tanstack/react-query";

import { controlFrameworkApi } from "services/brm";
import { SystemApi } from "features/brm";

const getData = async (acData) => {
  const sample = [];

  try {
    const frameworkData = await controlFrameworkApi.findControlFramework();
    const csa = frameworkData.filter((e) => e.name === "CSA");
    if (csa.length > 0 && csa[0]?.id) {
      const result = await controlFrameworkApi.getControlFrameworkByID(csa[0].id);

      result.structure.levels.forEach((f) =>
        f.levels.forEach((s) =>
          sample.push({
            function: f.level_value,
            subcategory: `${s.level_value} (${s.level_abbreviation})`,
            control: s.controls,
          })
        )
      );
    }
  } catch (err) {
    console.error("err in framework data : ", err);
  }

  const modifiedData = sample
    .map((e) => {
      const res = [];
      e.control?.forEach((element) => {
        const controls = acData?.filter((item) => item.controltype.name === element);
        if (controls && controls.length > 0) {
          res.push({ ...e, control: element, target: controls.length, objects: controls });
        } else res.push({ ...e, control: `${element}`, target: 0 });
      });
      return res;
    })
    .flat();
  return modifiedData || [];
};

export const useGetCsaSubCategories = (selectedFunction, data) => {
  const all = [{ value: "All", label: "All" }];
  if (data && data.length > 0) {
    const array = data.filter((e) => e.function === selectedFunction);
    const subcats = array.reduce((result, item) => {
      if (result.indexOf(item.subcategory) < 0) {
        result.push(item.subcategory);
      }
      return result;
    }, []);
    const selectArray = all.concat(subcats.map((each) => ({ label: each, value: each })));
    return selectArray;
  }

  return all;
};

export const useGetCsaData = (selectedElement) => {
  const { data: getACData } = SystemApi.useElementAllocatedControls({
    elementId: selectedElement.id,
    elementName: "variants",
    sctmId: selectedElement.sctm?.id,
    options: { enabled: !!selectedElement.id },
  });
  return useQuery(["getCsaData", getACData], () => getData(getACData), {
    enabled: !!selectedElement,
    cacheTime: 0,
  });
};
