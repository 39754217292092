import React from "react";
import Recoil from "recoil";
// import Recoil from "recoil";
// import { lookupCwesState, subcatCwesState } from "atoms/atoms-report";
import * as log from "loglevel";
import { getErrorMessage } from "utils/error-message-utils";
import { lookupCwesState, subcatCwesState } from "atoms/atoms-report";
import { useCsvFileRows } from "../useCsvFile";

interface ICwe {
  subcatId: string;
  cweName: string;
  sfpId: string;
  note: string;
}

interface ICweLookup {
  [key: string]: ICwe;
}

interface ISubcatSweLookup {
  [key: string]: string[];
}

export const useKbCwes = (csvUrl: string = "/kbs/KB_cwe.csv") => {
  const origCwes = Recoil.useRecoilValue(lookupCwesState);
  const origSubcatCwes = Recoil.useRecoilValue(subcatCwesState);

  const [lookupCwes, setLookupCwes] = React.useState<ICweLookup>();
  const [lookupSubcatCwes, setLookupSubcatCwes] = React.useState<ISubcatSweLookup>();

  const { data } = useCsvFileRows({ csvUrl });

  React.useEffect(() => {
    if (data) {
      try {
        const rows = data as string[][];
        const csvFileCwes: ICweLookup = {};
        const subcatCwesMap = new Map<string, Set<string>>();
        const localSubcatCwes: ISubcatSweLookup = {};

        rows.forEach((row: string[]) => {
          // make sure csv table has a mapped subcat
          const cweId = row[0];
          const subcatId = row[1];
          if (subcatId !== "" && subcatId !== undefined) {
            csvFileCwes[cweId] = {
              subcatId,
              cweName: row[2],
              sfpId: row[3],
              note: row[4],
            };

            let subcatCwesSet;
            if (subcatCwesMap.has(subcatId)) {
              subcatCwesSet = subcatCwesMap.get(subcatId);
            } else {
              subcatCwesSet = new Set<string>();
              subcatCwesMap.set(subcatId, subcatCwesSet);
            }

            if (subcatCwesSet) {
              subcatCwesSet.add(cweId);
            }
          }
        });
        setLookupCwes(csvFileCwes);

        subcatCwesMap.forEach((value, key) => {
          localSubcatCwes[key] = Array.from(value);
        });
        setLookupSubcatCwes(localSubcatCwes);
      } catch (err) {
        log.error(`Unable to load Subcat CWEs${getErrorMessage(err)}`);
        setLookupCwes({});
        setLookupSubcatCwes({});
      }
    }
  }, [data, origCwes, origSubcatCwes, setLookupCwes, setLookupSubcatCwes]);

  return { lookupCwes, lookupSubcatCwes };
};
