import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./capability-query-key-factory";
import * as NodeKeyFactory from "../node/node-query-key-factory";

interface IAddCapabilityNodeProps {
  capabilityId: string | null;
  nodeId: string | null;
}

/**
 * Add a node to capability
 *
 * @returns
 */
export const addCapabilityNode = ({ capabilityId, nodeId }: IAddCapabilityNodeProps) => {
  return capabilityId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.capabilityApi.addCapabilityNode(capabilityId, { body: nodeId });
};

interface IAddCapabilityNodeQueryProps {
  options?: MutationOption<typeof addCapabilityNode>;
}

/**
 * Custom hook to add a node to capability
 *
 * @returns react-query Mutation.
 */
export const useAddCapabilityNode = ({ options }: IAddCapabilityNodeQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.capabilityKeys.all);
      queryClient.invalidateQueries(NodeKeyFactory.nodeKeys.persons(variables.nodeId));
    },
    ...options,
    mutationFn: addCapabilityNode,
  });
};
