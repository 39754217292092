import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";

interface ISetControlTypeCost {
  controlTypeId: string;
  cost: string;
}
/**
 * Sets cost value for control type
 *
 * @param {string} controlTypeId - uuid of control type
 * @param {string} cost - user entered value for cost of the control type
 */
export const setControlTypeCost = ({ controlTypeId, cost }: ISetControlTypeCost) => {
  return Brm.controlTypeApi.setControlTypeCost(controlTypeId, { body: cost });
};

interface IUseSetControlTypeCost {
  options?: MutationOption<typeof setControlTypeCost>;
}

/**
 * Custom hook to change cost of a existing control type
 *
 * @param {object} options - configuration of react-query
 * @returns react-query ReactQuery.useMutation
 */
export const useSetControlTypeCost = ({ options }: IUseSetControlTypeCost = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: setControlTypeCost,
  });
};
