import { useState, useEffect } from "react";
import axios from "axios";
import backendUtils from "utils/backend-utils";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import Dropzone from "react-dropzone";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import * as S from "./AddSystemForm.styles";

interface IAddSystemFormProps {
  setModalIsOpen: (value: boolean) => void;
  setRefresh: (value: boolean) => void;
  refresh: boolean;
}

export const AddSystemForm = ({
  setModalIsOpen = () => {},
  setRefresh = () => {},
  refresh = false,
}: IAddSystemFormProps) => {
  const projectId = useRecoilValue(projectIdState);
  const [systemId, setSystemId] = useState("");
  const [postError] = useState("");

  useEffect(() => {
    async function getData() {
      await axios
        .get(`${backendUtils.getBackend()}systems?project=${projectId}`, {
          headers: backendUtils.getHeader(),
        } as any)
        .then((res) => {
          setSystemId(res.data.id);
        })
        .catch(async (error) => {
          console.error(error);
        });
    }
    getData();
  }, [projectId]);

  async function sendFileContents(contents: any) {
    const axiosConfig: any = { headers: backendUtils.getHeaderJSON() };

    // send file contents to backend
    await axios
      .post(`${backendUtils.getBackend()}systems/${systemId}/importInstant`, contents, axiosConfig)
      .then(() => {
        setRefresh(!refresh);
      })
      .catch(async (error) => {
        console.error(error);
      });
  }

  return (
    <div>
      <form action="">
        <FormStyled>
          <div className="form-style">
            <Dropzone
              accept=".json"
              onDrop={async ([file]) => {
                const reader = new FileReader();
                reader.onload = function onLoad(e: any) {
                  const contents = e.target.result;
                  sendFileContents(contents);
                };
                reader.readAsText(file);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div>
                    {/* <div className="dropzone-div" {...getRootProps()}> */}
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <S.Container {...getRootProps()}>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <input {...getInputProps()} />
                      <p>Click to select JSON file for upload.</p>
                      {/* </div> */}
                    </S.Container>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </FormStyled>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
          <DialogButtonStyled type="submit">Add</DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};
