import { useState, useMemo, useRef, useCallback } from "react";
import { CharacteristicCreateDto } from "@kdmanalytics/brm-system";
import { BrmDetailTable } from "brm/tables/BrmTables";
import ButtonCell from "components/EditComponents/ButtonCell";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import PropTypes from "prop-types";
import {
  createColumnMappedNoEdit,
  createColumnBooleanMapped,
  createColumnBooleanNoEditMapped,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
// Hooks
import { useRoles } from "features/brm/hooks/useRoles";
// Styles
import * as S from "brm/styles/details-table.styles";
import { DetailTableType, SystemApi } from "features/brm";

const CharacteristicTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const [selectedCharacteristic, setSelectedCharacteristic] = useState([]);
  const [, setSelectedRows] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const { isSystemEngineer } = useRoles();
  const updatedValue = useRef("");

  const { data: characteristicData, isError: isCharacteristicDataError } = SystemApi.useElementCharacteristic({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    options: {
      enabled: !!selectedElement.id,
      select: useCallback(
        (res) => {
          if (res && isSystemEngineer && elementNameInUrl === DetailTableType.EXCHANGES.key) {
            return res.map((characteristic, index) => {
              return { ...characteristic, value: characteristic.value === "yes", id: index };
            });
          }
          return res;
        },
        [elementNameInUrl, isSystemEngineer]
      ),
    },
  });

  const { mutate: setExchangeCharacteristic } = SystemApi.useAddExchangeCharacteristic({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    options: {
      enabled: !!selectedElement.id,
      onSettled: () => {
        updatedValue.current = "";
      },
    },
  });

  const createButton = useCallback(() => {
    return (
      <ButtonCell
        handleConfirmEditClick={async () => {
          if (updatedValue.current !== "") {
            const params = {
              tag: selectedCharacteristic.tag,
              value: updatedValue.current === "true" ? "yes" : "no",
            };
            const characteristicCreateDto = CharacteristicCreateDto.constructFromObject(params);
            if (elementNameInUrl === DetailTableType.EXCHANGES.key) {
              setExchangeCharacteristic({
                exchangeId: selectedElement.id,
                characteristicCreateDto,
              });
            }
          }
          setEditMode(false);
        }}
        setEditMode={setEditMode}
        editMode={editMode}
      />
    );
  }, [editMode, selectedCharacteristic.tag, elementNameInUrl, setExchangeCharacteristic, selectedElement.id]);

  const columns = useMemo(() => {
    if (isSystemEngineer && elementNameInUrl === DetailTableType.EXCHANGES.key) {
      return [
        createColumnMappedNoEdit("tag"),
        createColumnBooleanMapped("IsAttackable", selectedCharacteristic.id, editMode, updatedValue),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [createColumnMappedNoEdit("tag"), createColumnBooleanNoEditMapped("IsAttackable")];
  }, [createButton, editMode, elementNameInUrl, isSystemEngineer, selectedCharacteristic.id]);

  if (isCharacteristicDataError) {
    return <ErrorBanner msg="Error while loading Characteristic Data" />;
  }

  if (characteristicData) {
    return (
      <S.DetailsContainer id="CharacteristicTable_DetailsPanel">
        <S.DetailsTableContainer>
          <BrmDetailTable
            data={characteristicData}
            columns={columns}
            setSelectedElement={setSelectedCharacteristic}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "CharacteristicTable_detailsTable" }}
            showRowSelect={false}
            tableTitle={tableTitle}
          />
        </S.DetailsTableContainer>
      </S.DetailsContainer>
    );
  }
  return <Loading />;
};
CharacteristicTable.propTypes = {
  selectedElement: PropTypes.object.isRequired,
  elementNameInUrl: PropTypes.string.isRequired,
  tableTitle: PropTypes.string,
};

export default CharacteristicTable;
