import { atom, selector } from "recoil";
import {
  isActiveStatus,
  isInPrepStatus,
  isGeneratingStatus,
  isImportingStatus,
  isMitigatingStatus,
  isSuspendedStatus,
} from "utils/filter-utils";
import { ProjectState, VariantState } from "../types";

export const calculateRiskToastIdState = atom<string | null>({
  key: "calculateRiskToastIdState",
  default: null,
});

export const isEditingState = atom<boolean>({
  key: "isEditingState",
  default: false,
});

export const currentProjectState = atom<ProjectState | null>({
  key: "currentProjectState",
  default: null,
});

export const currentVariantState = atom<VariantState | null>({
  key: "currentVariantState",
  default: null,
});

export const isCurrentProjectActiveState = selector<boolean>({
  key: "isCurrentProjectActiveState",
  get: ({ get }) => {
    const currentProject = get(currentProjectState);
    if (currentProject === null || !currentProject.id) {
      return false;
    }
    return isActiveStatus(currentProject);
  },
});

export const isCurrentProjectPrepState = selector<boolean>({
  key: "isCurrentProjectPrepState",
  get: ({ get }) => {
    const currentProject = get(currentProjectState);
    if (currentProject === null || !currentProject.id) {
      return false;
    }
    return isInPrepStatus(currentProject);
  },
});

export const isCurrentProjectGeneratingState = selector<boolean>({
  key: "isCurrentProjectGeneratingState",
  get: ({ get }) => {
    const currentProject = get(currentProjectState);
    if (currentProject === null || !currentProject.id) {
      return false;
    }
    return isGeneratingStatus(currentProject);
  },
});

export const isCurrentProjectMitigatingState = selector<boolean>({
  key: "isCurrentProjectMitigatingState",
  get: ({ get }) => {
    const currentProject = get(currentProjectState);
    if (currentProject === null || !currentProject.id) {
      return false;
    }
    return isMitigatingStatus(currentProject);
  },
});

export const isCurrentProjectImportingState = selector<boolean>({
  key: "isCurrentProjectImportingState",
  get: ({ get }) => {
    const currentProject = get(currentProjectState);
    if (currentProject === null || !currentProject.id) {
      return false;
    }
    return isImportingStatus(currentProject);
  },
});

export const isCurrentProjectSuspendedState = selector<boolean>({
  key: "isCurrentProjectSuspendedState",
  get: ({ get }) => {
    const currentProject = get(currentProjectState);
    if (currentProject === null || !currentProject.id) {
      return false;
    }
    return isSuspendedStatus(currentProject);
  },
});
