import styled from "styled-components";
import styledMap from "styled-map";

const imgSizedStyles = {
  ImgSized: styled.img`
    height: ${styledMap`
        lg: 18px;
        md: 16px;
        sm: 12px;
        default: 16px;
    `};
  `,
};

export default imgSizedStyles;
