/* eslint-disable no-param-reassign */
import * as YFiles from "yfiles";
import * as React from "react";
import usePrevious from "hooks/usePrevious";

import { LayoutType } from "features/diagram-constants";
// import { AttackGraphBuilder } from "../../../services/graph-building";
// import useEffectDebug from "hooks/useEffectDebug";
import { useAttackDiagramLayout } from "./useAttackDiagramLayout";
import { useAttackGraphBuilder } from "./useAttackGraphBuilder";
import { IGraphData } from "../../../types";

export const useUpdateGraph = (
  graphComp: YFiles.GraphComponent,
  graphData: IGraphData,
  currentLayout: LayoutType,
  onGraphUpdated: () => void
) => {
  const diagramBuilder = useAttackGraphBuilder(graphComp, graphData);
  const prevGraphData = usePrevious(graphData);
  const isUpdating = React.useRef(false);
  const scheduledUpdate = React.useRef(Promise.resolve());
  const executeLayout = useAttackDiagramLayout(graphComp, currentLayout);

  const updateGraph = React.useCallback(
    async (gd) => {
      if (isUpdating.current || !graphComp) {
        return;
      }
      isUpdating.current = true;
      if (diagramBuilder) {
        const { nodes, edges } = gd;
        diagramBuilder.nodeData = nodes;
        diagramBuilder.edgeData = edges;
        diagramBuilder.updateGraph();
        await executeLayout();
        onGraphUpdated();
      }
      isUpdating.current = false;
    },
    [diagramBuilder, executeLayout, graphComp, onGraphUpdated]
  );

  // const doUpdate = React.useCallback(() => {
  //   if (!isUpdating.current) {
  //     scheduledUpdate.current = updateGraph(graphData);
  //   } else {
  //     // the graph is current still updating and running the layout animation, thus schedule an update
  //     scheduledUpdate.current = scheduledUpdate.current.then(() => updateGraph(graphData));
  //   }
  // }, [graphData, updateGraph]);

  /**
   * Schedule update graph when graphdata changes.
   */
  React.useEffect(() => {
    if (!graphData) {
      return;
    }

    const doUpdate = () => {
      // console.log("doUpdate");
      if (!isUpdating.current) {
        scheduledUpdate.current = updateGraph(graphData);
      } else {
        // the graph is current still updating and running the layout animation, thus schedule an update
        scheduledUpdate.current = scheduledUpdate.current.then(() => updateGraph(graphData));
      }
    };

    // first render don't layout just build the graph.
    if (diagramBuilder && prevGraphData === undefined) {
      const { nodes, edges } = graphData;
      diagramBuilder.nodeData = nodes;
      diagramBuilder.edgeData = edges;
      diagramBuilder.updateGraph();
    } else if (prevGraphData !== graphData) {
      const isCountsEqual =
        prevGraphData.nodes.length === graphData.nodes.length && prevGraphData.edges.length && graphData.edges.length;
      if (!isCountsEqual) {
        // this check ensures the data is actually changed before re-rendering
        doUpdate();
      }
    }
  }, [diagramBuilder, graphData, prevGraphData, updateGraph]);
};
