import * as React from "react";
import toast from "react-hot-toast";

// Components
import systemModals from "components/modals/Modals";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import ButtonCell from "components/EditComponents/ButtonCell";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { KnowledgebaseApi } from "features/brm";
// Constants
import { SEVERITY, CRITICALITY, TYPE, ATTACK_KIND } from "constants/brm";
// Styles
import * as S from "brm/styles/details-table.styles";
import { useKnowledgebase } from "features/knowledgebase";
import { BrmDetailTable } from "brm/tables/BrmTables";
import {
  createColumnActions,
  createColumnMapped,
  createColumnMappedNoEditRuleset,
} from "brm/tables/services/column/columnFactory";
import { InlineEditNumberCell } from "features/knowledgebase/components/tables/UndesiredEventRulesetTable/InlineEditNumberCell";
// import RuleAddForm from "../../forms/KbRuleAddForm";

const modifiedSeverity = SEVERITY.map((e) => ({ id: e.value, name: e.label }));
const modifiedCriticality = CRITICALITY.map((e) => ({ id: e.value, name: e.label }));

interface Rule {
  id: string;
  rfactor: number;
}

export const UndesiredEventRulesetTable = ({ kbId, selectedElement, setIsRulesetClicked, selectedRuleset }: any) => {
  const [selectedRule, setSelectedRule] = React.useState<Rule>();
  const [editMode, setEditMode] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const updatedSeverity = React.useRef("");
  const updatedCriticality = React.useRef("");
  const updatedRfactor = React.useRef<number>();

  const { data: rulesAndUEsData, isError: isRulesAndUEsDataError } = KnowledgebaseApi.useGetRules({ kbId });
  const { mutate: setRuleUpdate } = KnowledgebaseApi.useSetRules({ kbId });
  const { isGlobalKb, isReadOnlyKb } = useKnowledgebase({ kbId });

  const rulesData = React.useMemo(() => {
    if (rulesAndUEsData && rulesAndUEsData.rules?.length) {
      return rulesAndUEsData.rules?.filter((rule: any) => rule.atkkind === ATTACK_KIND.cyber || ATTACK_KIND.physical);
    }
    return [];
  }, [rulesAndUEsData]);

  const ueData = React.useMemo(() => {
    if (rulesAndUEsData && rulesAndUEsData.ue?.length) {
      return rulesAndUEsData.ue.map((ue: any, i: number) => ({ id: i, ...ue }));
    }
    return [];
  }, [rulesAndUEsData]);

  const createButton = React.useCallback(
    (cellProps) => {
      if (!isGlobalKb && !isReadOnlyKb) {
        return (
          <ButtonCell
            selectedRowId={selectedRule?.id}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={() => {
              setEditMode(false);

              const newUeData = ueData.map((r: any) => {
                if (r.id === selectedRule?.id) {
                  return {
                    ...r,
                    criticality: updatedCriticality.current ? updatedCriticality.current : r.criticality,
                    rfactor: updatedRfactor.current ? updatedRfactor.current : r.rfactor,
                    severity: updatedSeverity.current ? updatedSeverity.current : r.severity,
                  };
                }
                return r;
              });

              setRuleUpdate(
                { kbId, json: { ue: newUeData, rules: rulesData } },
                {
                  onSettled: () => {
                    updatedCriticality.current = "";
                    updatedSeverity.current = "";
                    updatedRfactor.current = undefined;
                  },
                }
              );
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }

      return null;
    },
    [isGlobalKb, isReadOnlyKb, selectedRule?.id, editMode, ueData, setRuleUpdate, kbId, rulesData]
  );

  const columns = React.useMemo(
    () => [
      createColumnMappedNoEditRuleset("targetcat"),
      createColumnMappedNoEditRuleset("objective"),
      createColumnMapped("severity", selectedRule?.id, modifiedSeverity, editMode, updatedSeverity),
      createColumnMapped("criticality", selectedRule?.id, modifiedCriticality, editMode, updatedCriticality),
      {
        Header: "RFactor",
        accessor: "rfactor",
        Cell: (cellProps: any) => {
          return (
            <InlineEditNumberCell
              isEditMode={editMode}
              // eslint-disable-next-line react/destructuring-assignment
              value={cellProps.cell.value || 1}
              onChange={(value) => {
                updatedRfactor.current = value;
              }}
            />
          );
        },
      },
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [createButton, editMode, selectedRule?.id]
  );

  React.useEffect(() => {
    function multiDeleteApiCalls() {
      const newJson = rulesData.filter((e: any) => !selectedRows.some((each: any) => e.id === each.id));
      setRuleUpdate(
        { kbId, json: { rules: newJson } },
        {
          onSettled: () => {
            setConfirmDelete(false);
            setDeleteModalIsOpen(false);
            setSelectedRows([]);
          },
          onSuccess: () => {
            toast.success("Rules updated successfully");
          },
          onError: () => {
            toast.error("Error occurred while deleting rules");
          },
        }
      );
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, kbId, rulesData, selectedElement.id, selectedRows, setRuleUpdate]);

  if (isRulesAndUEsDataError) {
    return <ErrorBanner msg="Error while loading rules" />;
  }

  if (ueData) {
    return (
      <>
        <S.RuleTitleContainer>
          <S.RuleTitle id="RuleTable_title">{selectedRuleset.name} Ruleset</S.RuleTitle>
          <S.DetailsTableButtonsContainer id="RuleTable_buttons">
            <S.RuleButton type="button" onClick={() => setIsRulesetClicked(false)} id="RuleTable_back">
              Back to rulesets
            </S.RuleButton>
          </S.DetailsTableButtonsContainer>
        </S.RuleTitleContainer>
        <S.DetailsContainer id="RuleTable_detailsPanel">
          {deleteModalIsOpen &&
            systemModals.deleteModal(
              "RuleTable_DetailsPanel",
              deleteModalIsOpen,
              setDeleteModalIsOpen,
              <GenericDeleteConfirmation
                elementName={TYPE.rule}
                setDeleteModalIsOpen={setDeleteModalIsOpen}
                selectedRows={selectedRows}
                setConfirmDelete={setConfirmDelete}
              />,
              TYPE.rule
            )}
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={ueData}
              columns={columns}
              setSelectedRows={(newSelectedRows) =>
                setSelectedRows(newSelectedRows.toString() !== selectedRows.toString() ? newSelectedRows : selectedRows)
              }
              setSelectedElement={setSelectedRule}
              customProps={{ id: "RuleTable_detailsTable" }}
              showRowSelect={false}
            />
          </S.DetailsTableContainer>
        </S.DetailsContainer>
      </>
    );
  }

  return <Loading />;
};
