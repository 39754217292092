import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "brm/risk-model/attack/api/";

interface IGetElementAllAttack {
  elementId: string;
  variantId: string;
}

/**
 * @param {string} elementId - uuid of target
 * @param {string} variantId - uuid of variant
 * @returns AttackShortDto
 */
export const getElementAllAttack = ({ elementId, variantId }: IGetElementAllAttack) => {
  return Brm.systemElementApi.getSystemElementAllAttack(elementId, { variant: variantId });
};

interface IUseElementAllAttack {
  elementId: string;
  variantId: string;
  options: QueryOption<typeof getElementAllAttack>;
}

/**
 * custom-hook to get all attacks of an element
 *
 * @param {string} elementId - uuid of target
 * @param {string} variantId - uuid of variant
 * @param {object} options - react-query configuration object
 * @returns react-query for attacks
 */
export const useElementAllAttack = ({ elementId, variantId, options }: IUseElementAllAttack) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.attackKeys.attack(elementId, variantId),
    queryFn: () => getElementAllAttack({ elementId, variantId }),
  });
};
