import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * sets group of the selected attacker
 * @param {string} id - uuid of the attacker
 * @param {string} group - uuid of threat group
 *
 */
export const setAttackerGroup = ({ id, group }) => {
  return Brm.attackerApi.setAttackerGroup(id, { body: group });
};

/**
 * Custom hook to change the group of a existing Attacker
 * @param {object} config hook configuration
 * @returns react-query ReactQuery.useMutation
 */
export const useSetAttackerGroup = ({ config = {} }) => {
  return ReactQuery.useMutation({
    ...config,
    mutationFn: setAttackerGroup,
  });
};
