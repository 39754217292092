/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import Form from "react-bootstrap/Form";
import { ActionButton } from "components/elements/ActionButton";
import { useForm, SubmitHandler } from "react-hook-form";
import { getErrorMessage } from "utils/error-message-utils";
import { ErrorSpan } from "components/elements";
import { ImportApi } from "features/brm";
import ReactModal from "react-modal";
import * as ModalStyles from "components/modals/Modal.styles";
import Cancel from "assets/icons/cancelModal.svg";

interface IImportFromFileModalProps {
  threatCatalogId: string;
  onHide: () => void;
  show: boolean;
}

interface IInputs {
  tcFile: FileList;
}

export const ImportThreatCatalogFileModal = ({ threatCatalogId, onHide, show }: IImportFromFileModalProps) => {
  const [importMessage, setImportMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [isBusySubmitting, setIsBusySubmitting] = React.useState(false);

  const onHideWrapper = () => {
    setIsError(false);
    setImportMessage("");
    onHide();
  };

  const {
    register,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<IInputs>({ mode: "onChange" });

  const handleClose = () => {
    reset();
    onHideWrapper();
  };

  const { mutate: importThreatCatalog } = ImportApi.useImportThreatCatalog({
    options: {
      onMutate: () => {
        setIsBusySubmitting(true);
        setImportMessage("Import in progress...");
      },
    },
  });

  const onSubmit: SubmitHandler<IInputs> = (data: { tcFile: Iterable<unknown> | ArrayLike<unknown> }) => {
    const fileArray = Array.from(data.tcFile);
    if (fileArray.length > 0) {
      importThreatCatalog(
        { threatCatalogId, file: fileArray[0] },
        {
          onSuccess: () => {
            reset();
            onHideWrapper();
            setImportMessage(`Imported Threat Catalog Successfully!`);
            setIsBusySubmitting(false);
          },
          onError: (error: Error) => {
            reset();
            setIsError(true);
            setIsBusySubmitting(false);
            if ("status" in error && "statusText" in error) {
              const err: any = error;
              setImportMessage(`Error while attempting to import Threat Catalog: ${err.status} : ${err.statusText}`);
            } else {
              setImportMessage(`Error while attempting to import Threat Catalog: ${getErrorMessage(error)}`);
            }
          },
        }
      );
    }
  };

  return (
    <ReactModal
      isOpen={show}
      onAfterClose={onHideWrapper}
      ariaHideApp={false}
      onRequestClose={() => handleClose()}
      style={ModalStyles.defaultModalStyles}
    >
      <ModalStyles.HeaderFlex>
        <ModalStyles.ModalTitle>Import a Threat Catalog</ModalStyles.ModalTitle>
        <ModalStyles.ButtonsDiv>
          <ModalStyles.Close onClick={() => handleClose()}>
            <ModalStyles.CloseIcon src={Cancel} />
          </ModalStyles.Close>
        </ModalStyles.ButtonsDiv>
      </ModalStyles.HeaderFlex>
      <ModalStyles.Body>
        <Form>
          <Form.Label>Select a Threat Catalog file to import</Form.Label>
          <Form.Group className="mb-3">
            <Form.Control accept=".csv,.json" type="file" {...register("tcFile", { required: true })} />
          </Form.Group>
        </Form>
        {isError ? <ErrorSpan>ERROR: {importMessage}</ErrorSpan> : importMessage}
      </ModalStyles.Body>
      <ModalStyles.Footer>
        <ActionButton onClick={handleClose}>Close</ActionButton>
        <ActionButton onClick={handleSubmit(onSubmit)} disabled={!isDirty || !isValid || isBusySubmitting}>
          Import
        </ActionButton>
      </ModalStyles.Footer>
    </ReactModal>
  );
};
