import * as React from "react";
import * as Recoil from "recoil";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// Components
import Toolbar, { Separator } from "components/elements/NavigationToolbar";
import { BasicButton as Button } from "components/elements";
import { ProjectExplorer } from "features/project-explorer";
import { RiskMatrix } from "features/risk-matrix";
import TitledPane from "components/elements/TitledPane";
import ResizeControl from "brm/layout/MainPage/ResizeControl";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "components/error";

// State
// import { projectIdState, projectNameState } from "atoms/atoms-admin";

// Images
import { ReactComponent as SystemDiagramIcon } from "assets/icons/studio91/sidebars-toolbars/system-diagram.svg";
import { ReactComponent as AttackGraphIcon } from "assets/icons/studio91/sidebars-toolbars/attack-graph.svg";
import { ReactComponent as ContextDiagramIcon } from "assets/icons/studio91/sidebars-toolbars/context-diagram.svg";
import { ReactComponent as ExplorerIcon } from "assets/icons/studio91/rm/explorer.svg";
import { ReactComponent as RiskMatrixIcon } from "assets/icons/risk-matrix.svg";
import { HiDocumentReport } from "react-icons/hi";
import { VscProject } from "react-icons/vsc";
// Services
import { ThemeContext } from "styled-components";

// Hooks
import { useRoles } from "features/brm/hooks/useRoles";

// Styles
import { RoutePath } from "routes/route-paths";
import {
  CONTEXT_SIDEBAR_CONTENT,
  contextSidebarContentState,
  currentProjectState,
  useProjectStatus,
} from "features/brm";
import { isViewableByRiskAnalyst } from "utils/filter-utils";
import * as S from "./LeftSidebar.styles";

const leftResizeControl = {
  location: "left",
  sidebarWidth: "--leftSidebarWidth",
  sidebarMinWidth: "--leftSidebarMinWidth",
  sidebarFlyoutWidth: "--leftSidebarFlyoutWidth",
};

const LeftSidebar = ({ show = false, toggleDrawerVisibility }) => {
  const { isReadable } = useProjectStatus();
  const { isRiskAnalyst, isControlsCoordinator, isSystemEngineer, isThreatAnalyst, isCyberSecurityExpert } = useRoles();
  const setContentType = Recoil.useSetRecoilState(contextSidebarContentState);

  const navigate = useNavigate();
  const theme = React.useContext(ThemeContext);
  const currentProject = Recoil.useRecoilValue(currentProjectState);

  const nodeRef = React.useRef(null);
  const [isDragging, setIsDragging] = React.useState(false);

  React.useEffect(() => {
    const root = document.documentElement;
    const rootStyle = getComputedStyle(root);
    let newWidth = "40px";
    if (!show) {
      newWidth = rootStyle.getPropertyValue("--navigationToolbarWidth");
    } else {
      newWidth = rootStyle.getPropertyValue(leftResizeControl.sidebarFlyoutWidth);
    }
    root.style.setProperty(leftResizeControl.sidebarWidth, newWidth);
  }, [show]);

  function hideDrawer() {
    if (show) {
      toggleDrawerVisibility();
    }
  }

  const onExplorerClicked = () => {
    toggleDrawerVisibility();
  };

  const onProjectsClicked = () => {
    navigate(RoutePath.Projects);
  };

  const onSystemDiagramClicked = () => {
    hideDrawer();
    setContentType(CONTEXT_SIDEBAR_CONTENT.systemDiagramProperties);
    navigate(RoutePath.SystemDiagram);
  };

  const onContextDiagramClicked = () => {
    hideDrawer();
    setContentType(CONTEXT_SIDEBAR_CONTENT.contextDiagramFiltering);
    navigate(RoutePath.ContextDiagram);
  };

  const onAttackGraphClicked = () => {
    hideDrawer();
    navigate(RoutePath.AttackDiagram);
    setContentType(CONTEXT_SIDEBAR_CONTENT.attackGraphAddNodes);
  };

  const onReportsClicked = () => {
    navigate(RoutePath.Reports);
  };

  const RiskMatrixPanel = (
    <TitledPane title="Risk Matrix" iconComp={RiskMatrixIcon} alt="Risk Matrix Icon">
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <RiskMatrix />
        </React.Suspense>
      </ErrorBoundary>
    </TitledPane>
  );

  const suffix =
    currentProject &&
    (isSystemEngineer || isThreatAnalyst || (isRiskAnalyst && isViewableByRiskAnalyst(currentProject)))
      ? `: ${currentProject.name}`
      : "";
  const Drawer = (
    <S.Drawer ref={nodeRef}>
      <S.ExplorerContainer>
        <TitledPane
          // height={isRiskAnalyst ? "67%" : "100%"}
          overflow="auto"
          title={`Explorer${suffix}`}
          iconComp={ExplorerIcon}
          alt="Explorer Icon"
        >
          <ProjectExplorer />
        </TitledPane>
      </S.ExplorerContainer>
      <S.MatrixContainer>{isRiskAnalyst ? RiskMatrixPanel : null}</S.MatrixContainer>
    </S.Drawer>
  );

  const {
    colors: { buttonPrimary: buttonFill },
    sizes: { standaloneIconWidth: iconWidth, standaloneIconWidth: iconHeight },
  } = theme;

  const handleResizeStart = () => {
    setIsDragging(true);
  };

  const handleResizeStop = () => {
    setIsDragging(false);
  };

  const handleDrag = (e) => {
    const root = document.documentElement;
    const rootStyle = getComputedStyle(root);
    const toolbarWidth = parseInt(rootStyle.getPropertyValue("--navigationToolbarWidth"), 10);
    const vw = Math.max(root.clientWidth || 0, window.innerWidth || 0);

    let newWidth = e.clientX;
    if (e.clientX > vw / 2) {
      newWidth = vw / 2;
    } else if (e.clientX < toolbarWidth) {
      newWidth = toolbarWidth;
    }
    root.style.setProperty(leftResizeControl.sidebarWidth, `${newWidth}px`);
  };

  React.useEffect(() => {
    if (!isDragging) {
      const root = document.documentElement;
      const rootStyle = getComputedStyle(root);
      const toolbarWidth = parseInt(rootStyle.getPropertyValue("--navigationToolbarWidth"), 10);
      const resizeThreshold = parseInt(rootStyle.getPropertyValue("--resizeSizeThreshold"), 10);
      const width = parseInt(rootStyle.getPropertyValue(leftResizeControl.sidebarWidth), 10);
      const minWidth = parseInt(rootStyle.getPropertyValue(leftResizeControl.sidebarMinWidth), 10);

      if (width < minWidth) {
        if (width > minWidth - resizeThreshold) {
          root.style.setProperty(leftResizeControl.sidebarWidth, `${minWidth}px`);
        } else {
          root.style.setProperty(leftResizeControl.sidebarWidth, `${toolbarWidth}px`);
        }
        root.style.setProperty(leftResizeControl.sidebarFlyoutWidth, `${minWidth}px`);
      } else {
        root.style.setProperty(leftResizeControl.sidebarFlyoutWidth, `${width}px`);
      }
    }
  }, [isDragging]);

  const contextDiagramButton = (
    <span title="Context Diagram">
      <Button
        onClick={onContextDiagramClicked}
        id="LeftSidebar_contextDiagramButton"
        disabled={!currentProject || !isReadable}
      >
        <ContextDiagramIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
      </Button>
    </span>
  );

  const systemDiagramButton = (
    <span title="System Diagram">
      <Button
        onClick={onSystemDiagramClicked}
        id="LeftSidebar_systemDiagramButton"
        disabled={!currentProject || !isReadable}
      >
        <SystemDiagramIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
      </Button>
    </span>
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
      <S.MainContainer>
        <S.TransitionContainer isDragging={isDragging}>
          <S.ContentContainer isDragging={isDragging}>
            <S.LeftSidebarContainer>
              <S.GlobalNavigation>
                <Toolbar orientation="vertical">
                  {!isCyberSecurityExpert && !isControlsCoordinator ? (
                    <span title="Projects">
                      <Button onClick={onProjectsClicked} id="LeftSidebar_projectsButton">
                        <VscProject fill={buttonFill} size={25} />
                      </Button>
                    </span>
                  ) : null}
                  <span title="Explorer">
                    <Button onClick={onExplorerClicked} id="LeftSidebar_explorerButton">
                      <ExplorerIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
                    </Button>
                  </span>
                  <Separator />
                  {isSystemEngineer && currentProject ? (
                    <>
                      {systemDiagramButton}
                      {contextDiagramButton}
                      <Separator />
                      <span title="Reports">
                        {/* TODO :: BRMCD - 4561 : Reports icon needs to be hooked up to any available role/context specific report */}
                        <Button onClick={onReportsClicked} id="LeftSidebar_reportButton">
                          <HiDocumentReport fill={buttonFill} size={29} />
                        </Button>
                      </span>
                    </>
                  ) : null}
                  {isRiskAnalyst && currentProject ? (
                    <>
                      <span title="Attack Diagram">
                        <Button
                          onClick={onAttackGraphClicked}
                          id="LeftSidebar_attackDiagramButton"
                          disabled={!isReadable}
                        >
                          <AttackGraphIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
                        </Button>
                      </span>
                      {systemDiagramButton}
                      {contextDiagramButton}
                      <Separator />
                      <span title="Reports">
                        {/* TODO :: BRMCD - 4561 : Reports icon needs to be hooked up to any available role/context specific report */}
                        <Button onClick={onReportsClicked} id="LeftSidebar_reportButton">
                          <HiDocumentReport fill={buttonFill} size={28} />
                        </Button>
                      </span>
                    </>
                  ) : null}
                </Toolbar>
              </S.GlobalNavigation>
              <S.DrawerContainer>{Drawer}</S.DrawerContainer>
            </S.LeftSidebarContainer>

            <ResizeControl
              location="left"
              onStart={handleResizeStart}
              onStop={handleResizeStop}
              onDrag={handleDrag}
              onDoubleClick={toggleDrawerVisibility}
              show={show}
              onChevronClick={onExplorerClicked}
            />
          </S.ContentContainer>
        </S.TransitionContainer>
      </S.MainContainer>
    </ErrorBoundary>
  );
};

LeftSidebar.propTypes = {
  show: PropTypes.bool,
  toggleDrawerVisibility: PropTypes.func,
};

export default LeftSidebar;
