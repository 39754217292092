import { BrmError, DetailTableType } from "features/brm";
import { useQuery } from "@tanstack/react-query";
import * as Brm from "services/brm";
// Services
import * as KeyFactory from "./element-query-key-factory";

interface IGetElementFindingsProps {
  elementId: string;
  elementName: string;
  elementCategory: string | null;
  nodeId: string | null;
  projectId: string;
  variantId: string;
  options: {};
}

interface IGetFindingsProps {
  elementId: string;
  elementName: string;
  elementCategory: string | null;
  nodeId: string | null;
  projectId: string;
}

interface IFindingsMap {
  [index: string]: (id?: string) => any;
}

async function getFindings({ elementId, elementName, projectId, elementCategory, nodeId }: IGetFindingsProps) {
  const findingsMap: IFindingsMap = {
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeFinding(id),
    [DetailTableType.VULNERABILITIES.key]: () =>
      Brm.findingsApi.findFinding(projectId, { category: elementCategory, performer: nodeId }),
    [DetailTableType.PROJECTS.key]: () => Brm.findingsApi.findFinding(projectId),
    [DetailTableType.RISKS.key]: (id) => Brm.riskApi.getRiskAllFinding(id),
  };

  if (findingsMap[elementName]) {
    const data = await findingsMap[elementName](elementId);
    return Array.isArray(data) ? data : [data];
  }

  throw new BrmError(`Unsupported element type: ${elementName}`);
}

export const useGetElementFindings = ({
  elementId,
  elementName,
  projectId,
  elementCategory,
  nodeId,
  variantId,
  options = {},
}: IGetElementFindingsProps) => {
  return useQuery({
    ...options,
    queryKey: KeyFactory.elementKeys.findings(elementId, elementName, projectId, variantId),
    queryFn: () => getFindings({ elementId, elementName, projectId, elementCategory, nodeId }),
  });
};
