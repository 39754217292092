import { CategorizedEntityApi, ClassificationCategoryApi, SecurityObjectiveApi } from "@kdmanalytics/brm-system";
import BrmApiClient from "services/brm/BrmApiClient";

export const categorizedEntityApi = new CategorizedEntityApi(BrmApiClient.instance);
export const classificationCategoryApi = new ClassificationCategoryApi(BrmApiClient.instance);
export const securityObjectiveApi = new SecurityObjectiveApi(BrmApiClient.instance);

const createCatEntity = async (categorizedEntityId, createCatEntityDto) => {
  const opts = { categorizedEntityCreateDto: createCatEntityDto };
  return categorizedEntityApi.createCategorizedEntityWithHttpInfo(categorizedEntityId, opts);
};

const getAllCategorizedEntity = async (projectId, classificationId, objectiveId) => {
  const opts = { classification: classificationId, objective: objectiveId };
  return categorizedEntityApi.findCategorizedEntityWithHttpInfo(projectId, opts);
};

const createClassificationCat = async (projectId, classificationCategoryDto) => {
  const opts = { classificationCategoryCreateDto: classificationCategoryDto };
  return classificationCategoryApi.createClassificationCategoryWithHttpInfo(projectId, opts);
};

const CategorizationService = {
  createCatEntity,
  getAllCategorizedEntity,
  createClassificationCat,
};
export default CategorizationService;
