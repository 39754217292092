import { useQuery } from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";
// import * as RiskApi from "brm/risk-model/risk/api";
import * as KeyFactory from "brm/risk-model/risk/api/risk-query-key-factory";
import { BrmError, DetailTableType, RiskApi } from "features/brm";

/**
 * Returns a list of risks of a given selected element
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @returns list of all risks
 */
export const getElementRisks = async ({ elementId, elementName, projectId, variantId, riskData }) => {
  const RisksMap = {
    [DetailTableType.MISSIONS.key]: (id, variant) => Brm.missionApi.getMissionAllRisk(id, { variant }),
    [DetailTableType.PERSONS.key]: (id, variant) => Brm.personApi.getPersonAllRisk(id, { variant }),
    [DetailTableType.CAPABILITIES.key]: (id, variant) => Brm.capabilityApi.getCapabilityAllRisk(id, { variant }),
    [DetailTableType.ACTIVITIES.key]: (id, variant) => Brm.activityApi.getActivityAllRisk(id, { variant }),
    [DetailTableType.DATATYPES.key]: (id, variant) => Brm.dataTypeApi.getDataTypeAllRisk(id, { variant }),
    [DetailTableType.DATAFLOWS.key]: (id, variant) => Brm.dataflowApi.getDataFlowAllRisk(id, { variant }),
    [DetailTableType.EXCHANGES.key]: (id, variant) => Brm.exchangeApi.getExchangeAllDependentRisk(id, { variant }),
    [DetailTableType.NODES.key]: (id, variant) => Brm.nodeApi.getNodeAllDependentRisk(id, { variant }),
    [DetailTableType.SYSTEM_ASSETS.key]: (id, variant) =>
      Brm.systemAssetApi.getSystemAssetAllDependentRisk(id, { variant }),
    [DetailTableType.SECURITY_OBJECTIVES.key]: (id, variant) =>
      Brm.securityObjectiveApi.getSecurityObjectiveAllRisk(id, { variant }),
    [DetailTableType.ASSETS.key]: (id) => Brm.assetApi.getAssetAllRisk(id),
    [DetailTableType.UNDESIRED_EVENTS.key]: (id, variant) =>
      Brm.undesiredEventApi.getUndesiredEventRisk(id, { variant }),
    [DetailTableType.RISK_CATEGORIES.key]: () => Brm.riskApi.findRisk(projectId),
    [DetailTableType.VULNERABILITIES.key]: (id, variant) =>
      Brm.vulnerabilityApi.getVulnerabilityAllRisk(id, { variant }),
    [DetailTableType.THREAT_EVENTS.key]: (id, variant) => Brm.threatEventApi.getThreatEventAllRisk(id, { variant }),
    [DetailTableType.SYSTEM.key]: (id, variant) => Brm.systemApi.getSystemRisk(id, { variant }),
    [DetailTableType.VARIANTS.key]: (id) => Brm.variantApi.getVariantRisk(id),
    [DetailTableType.ATTACKS.key]: (id, variant) => Brm.attackApi.getAttackAllRisk(id, { variant }),
    [DetailTableType.ATTACKERS.key]: (id, variant) => Brm.attackerApi.getAttackerAllRisk(id, { variant }),
    [DetailTableType.RISKS.key]: () => riskData,
    [DetailTableType.RESOURCES.key]: (id, variant) => Brm.resourceApi.getResourceAllRisk(id, { variant }),
  };

  if (RisksMap[elementName]) {
    const data = await RisksMap[elementName](elementId, variantId);
    return Array.isArray(data) ? data : [data];
  }

  throw new BrmError(`Unsupported element type :${elementName}`);
};

/**
 * custom-hook to retreive all risks
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {object} config - configuration for the react-query
 * @returns react-query for risks
 */
export const useElementRisks = ({ elementId, elementName, projectId, variantId, riskUpdated, options = {} }) => {
  const { data: riskData } = RiskApi.useRiskDetails({
    riskId: elementId,
    variantId,
    riskUpdated,
    options: { enabled: !!elementId && elementName === DetailTableType.RISKS.key },
  });

  return useQuery({
    ...options,
    queryKey: KeyFactory.risksKeys.element(elementId, elementName, projectId, variantId, riskData),
    queryFn: () => getElementRisks({ elementId, elementName, projectId, variantId, riskData }),
  });
};
