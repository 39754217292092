import PropTypes from "prop-types";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import ButtonCell from "components/EditComponents/ButtonCell";
import { DeleteButton, AddButton } from "features/brm";
import { ImpactStatementCreateDto } from "@kdmanalytics/brm-system";
import {
  createColumnActions,
  createColumnMapped,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";
// Services
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import * as S from "brm/styles/details-table.styles";
import { LoadingSpinner as Loading } from "components/elements";
import { useQueryClient } from "@tanstack/react-query";
import { LEVELS } from "constants/brm";
import { useRoles } from "features/brm/hooks/useRoles";
import { useDeleteCategorization, useElementCategorization, useSetCategorizedEntityCategorization } from "./api";
import CategorizationAddForm from "./forms/CategorizationAddForm";

const CategorizationTable = ({ elementNameInUrl, selectedElement, showAdd, showDelete }) => {
  const projectId = useRecoilValue(projectIdState);
  const queryClient = useQueryClient();
  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  const [selectedCategorization, setSelectedCategorization] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [detailsSelectedInstance, setDetailsSelectedInstance] = useState({});
  const updatedLevel = useRef("");

  const { data: categorizationData } = useElementCategorization({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    config: { enabled: !!selectedElement.id },
  });
  const { mutate: setSOLevel } = useSetCategorizedEntityCategorization({
    config: {
      onSettled: () => {
        updatedLevel.current = "";
        queryClient.invalidateQueries(["elementCategorization"]);
      },
    },
  });
  const { mutate: deleteCategorization } = useDeleteCategorization({
    config: {
      onSettled: () => {
        queryClient.invalidateQueries(["elementCategorization"]);
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={detailsSelectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            if (updatedLevel !== "" && updatedLevel !== "Select level") {
              const params = {
                objective: detailsSelectedInstance.name,
                level: updatedLevel.current,
              };

              const impactStatementCreateDto = ImpactStatementCreateDto.constructFromObject(params);
              setSOLevel({
                catEntityId: selectedElement.id,
                impactStatementCreateDto,
              });
            }
            setEditMode(false);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [detailsSelectedInstance.id, detailsSelectedInstance.name, editMode, selectedElement.id, setSOLevel]
  );

  const columns = useMemo(() => {
    if (isRiskAnalyst || isSystemEngineer) {
      return [
        createColumnMappedNoEdit("objective"),
        createColumnMapped("level", detailsSelectedInstance.id, LEVELS, editMode, updatedLevel),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnMappedNoEdit("objective"),
      createColumnMapped("level", detailsSelectedInstance.id, LEVELS, false, null),
    ];
  }, [createButton, detailsSelectedInstance.id, editMode, isRiskAnalyst, isSystemEngineer]);

  const handleDelete = () => {
    if (selectedRows.length > 0) {
      Promise.all(
        selectedRows.map((each) =>
          deleteCategorization({
            securityObjectiveId: each.objective.id,
            elementName: elementNameInUrl,
            elementId: selectedElement.id,
          })
        )
      );
    }
  };

  useEffect(() => {
    if (selectedCategorization) {
      setDetailsSelectedInstance({
        id: selectedCategorization.id,
        name: selectedCategorization?.objective?.name || "",
      });
    }
  }, [selectedCategorization]);

  if (categorizationData) {
    return (
      <S.DetailsContainer id="CategorizationTable_detailsPanel">
        {addModalIsOpen &&
          systemModals.addModal(
            "CategorizationTable_detailsPanel",
            addModalIsOpen,
            setAddModalIsOpen,
            <CategorizationAddForm
              setModalIsOpen={setAddModalIsOpen}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
            />,
            "Categorization"
          )}

        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={categorizationData}
              columns={columns}
              setSelectedRows={setSelectedRows}
              setSelectedElement={setSelectedCategorization}
              customProps={{ id: "CategorizationTable_detailsTable" }}
              showRowSelect={isRiskAnalyst || isSystemEngineer}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            {showDelete && selectedRows?.length > 0 && <DeleteButton md onClick={handleDelete} />}
            {showAdd && <AddButton md onClick={() => setAddModalIsOpen(true)} />}
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

CategorizationTable.propTypes = {
  elementNameInUrl: PropTypes.string,
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
  }),
  showAdd: PropTypes.bool,
  showDelete: PropTypes.bool,
};

export default CategorizationTable;
