import { useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import {
  createColumnMappedNoEdit,
  createColumnBooleanNoEditMapped,
  createColumnNameDetails,
} from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { LoadingSpinner, ErrorBanner } from "components/elements";
import { RoutePath } from "routes/route-paths";
import { SystemApi } from "features/brm";

interface IProducingActivityProps {
  selectedElement: {
    id: string;
  };
  tableTitle: string;
}

const ConsumerTable = ({ selectedElement, tableTitle }: IProducingActivityProps) => {
  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Node.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("structcat"),
      createColumnBooleanNoEditMapped("isInternal"),
    ],
    []
  );

  const { data: consumerData, isError: isConsumerError } = SystemApi.useExchangeConsumer({
    exchangeId: selectedElement.id,
  });

  if (isConsumerError) {
    return <ErrorBanner msg="Error while loading Consumer" />;
  }

  if (consumerData) {
    return (
      <S.DetailsContainer id="ConsumerTable_detailsPanel">
        <BrmDetailTable
          data={consumerData}
          columns={columns}
          customProps={{ id: "ConsumerTable_detailsPanel" }}
          showRowSelect={false}
          tableTitle={tableTitle}
        />
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

export default ConsumerTable;
