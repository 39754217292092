import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";

export const getRuleSet = ({ kbId }: { kbId: string }) => {
  return Brm.kbApi.getKBRuleset(kbId);
};

type FetcherFunction = typeof getRuleSet;
interface IUseGetRuleset {
  kbId: string;
  options?: QueryOption<FetcherFunction>;
}

export const useGetRuleset = ({ kbId, options }: IUseGetRuleset) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: ["getRulesets", kbId],
    queryFn: () => getRuleSet({ kbId }),
  });
};
