import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

export interface ISetNodeStoredData {
  nodeId: string;
  dataId: string | null;
}

/**
 * Sets the stored data of a node
 *
 * @param {string} nodeId - uuid of the node
 * @param {string} dataId - uuid of new data or null
 */
export const setNodeStoredData = ({ nodeId, dataId }: ISetNodeStoredData) => {
  return Brm.nodeApi.addNodeStoredData(nodeId, { body: dataId });
};

export interface IUseSetNodeStoredData {
  options?: MutationOption<typeof setNodeStoredData>;
}

/**
 * Custom hook to change the stored data of an  existing node
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetNodeStoredData = ({ options }: IUseSetNodeStoredData = {}) => {
  return ReactQuery.useMutation({
    onSuccess: (data, { nodeId }) => {
      queryClient.invalidateQueries(KeyFactory.nodeKeys.node(nodeId));
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: setNodeStoredData,
  });
};
