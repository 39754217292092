import { useState } from "react";
import PropTypes from "prop-types";
// State
import { useRecoilValue } from "recoil";
import { controlFamilyIdState } from "atoms/atoms-global-control";
// Utils
import { stringToBoolean } from "utils/boolean-checker";
import { strengthLevels } from "features/mitigation";
// Components
import SelectBoxCategory from "components/forms/DynamicSelectBoxCategory";
import SelectBox from "components/forms/DynamicSelectBox";
import BooleanDropdown from "components/forms/BooleanDropdown";
import TextField from "components/forms/TextField";
// Constants
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
// Services
import { ControlTypeCreateDto } from "@kdmanalytics/brm-knowledgebase";
import { GlobalControlApi } from "features/brm";

/**
 * Hard coded vulnerability categories as suggested by Nick
 * Since control type is global context and there is no access for projectId
 * inorder to get vul cats via rest req.
 */
const vulCats = [
  "iaaa",
  "iaco",
  "iasa",
  "ibpr",
  "icma",
  "idsc",
  "iiau",
  "iiie",
  "iilp",
  "impr",
  "ipma",
  "ippd",
  "irpr",
  "isas",
  "isba",
  "isfa",
  "isma",
  "ismo",
  "issa",
  "issp",
  "itop",
  "pdch",
  "umsh",
];

export const AddControlTypeForm = ({ setModalIsOpen }) => {
  const [postError, setPostError] = useState("");
  const controlFamilyId = useRecoilValue(controlFamilyIdState);
  const [isTextValid, setIsTextValid] = useState(true);
  const { mutate: createControlType } = GlobalControlApi.useCreateControlType();

  // validate that all fields in the form have been completed
  function validate(formData) {
    let error = true;
    if (
      formData.name.value === "" ||
      formData.isStandard.value === "" ||
      formData.vulCat.value === "" ||
      formData.vulCat.value === "Select" ||
      formData.assmt.value === "" ||
      formData.impl.value === "" ||
      formData.cost.value === "" ||
      formData.strength.value === "" ||
      formData.strength.value === "Select"
    ) {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value,
        strength: e.target.elements.strength.value,
        cost: e.target.elements.cost.value,
        implGuidance: e.target.elements.impl.value,
        assmtGuidance: e.target.elements.assmt.value,
        vulnerabilitycategory: e.target.elements.vulCat.value,
        isStandard: stringToBoolean(e.target.elements.isStandard.value),
      };
      const controlTypeCreateDto = ControlTypeCreateDto.constructFromObject(params);
      createControlType(
        { controlFamilyId, controlTypeCreateDto },
        {
          onSettled: () => {
            setModalIsOpen(false);
          },
        }
      );
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  return (
    <div>
      <form onSubmit={handleSubmit} action="">
        <FormStyled>
          <div className="form-style">
            <TextField
              label={{ id: "ControlTypeAddForm_Name", name: FORM_LABEL.nameMandatory }}
              input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
              setIsTextValid={setIsTextValid}
              isTextValid={(inputString) => {
                const inputStr = inputString.trim();
                return inputStr.match(/[$&+,:;?@#|'^*()%!-]+/) && inputStr.length > 0;
              }}
            />
            <TextField
              label={{ id: "ControlTypeAddForm_Note", name: FORM_LABEL.note }}
              input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
              setIsTextValid={setIsTextValid}
            />
            <label id="ControlTypeAddForm_Strength">{FORM_LABEL.strengthMandatory}</label>
            <SelectBox id="ControlTypeAddForm_strengthDropdown" arrayOfData={strengthLevels} item="strength" />
            <TextField
              label={{ id: "ControlTypeAddForm_Cost", name: FORM_LABEL.costMandatory }}
              input={{ name: "cost", placeholder: FORM_PLACEHOLDER.cost }}
              setIsTextValid={setIsTextValid}
            />
            <TextField
              label={{ id: "ControlTypeAddForm_ImplGuidance", name: FORM_LABEL.implGuidanceMandatory }}
              input={{ name: "impl", placeholder: FORM_PLACEHOLDER.impleGuidance }}
              setIsTextValid={setIsTextValid}
            />
            <TextField
              label={{ id: "ControlTypeAddForm_AssmtGuidance", name: FORM_LABEL.assmtGuidanceMandatory }}
              input={{ name: "assmt", placeholder: FORM_PLACEHOLDER.assmtGuidance }}
              setIsTextValid={setIsTextValid}
            />

            <label id="ControlTypeAddForm_VulnerabilityCategory">{FORM_LABEL.vulCategoryMandatory}</label>
            <SelectBoxCategory
              id="ControlTypeAddForm_vulnerabilityCategoryDropdown"
              arrayOfData={vulCats || []}
              item="vulCat"
            />

            <BooleanDropdown
              label={{ id: "ControlTypeAddForm_isStandard", name: "Standard:" }}
              select={{ id: "isStandard", name: "isStandard" }}
            />
          </div>
        </FormStyled>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
          <DialogButtonStyled type="submit">Add</DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};

AddControlTypeForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};
