import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as ElementKeyFactory from "brm/tables/details/api/element-query-key-factory";

interface IAddCapabilityNodeProps {
  dataflowId: string | null;
  exchangeId: string | null;
}

/**
 * Add an exchange to dataflow
 *
 * @returns
 */
export const addDataFlowExchange = ({ dataflowId, exchangeId }: IAddCapabilityNodeProps) => {
  return Brm.dataflowApi.addDataFlowExchange(dataflowId, { body: exchangeId });
};

interface IAddDataflowExchangeQueryProps {
  elementId: string;
  elementName: string;
  options?: MutationOption<typeof addDataFlowExchange>;
}

/**
 * Custom hook to add an exchange to dataflow
 *
 * @returns react-query Mutation.
 */
export const useAddDataflowExchange = ({ elementId, elementName, options = {} }: IAddDataflowExchangeQueryProps) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(
        ElementKeyFactory.elementKeys.exchanges(elementId, elementName, undefined, undefined)
      );
    },
    ...options,
    mutationFn: addDataFlowExchange,
  });
};
