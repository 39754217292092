import * as ReactQuery from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";

/**
 * Custom hook to retreive an asset.
 *
 * @param {string} assetId - uuid of a asset
 * @returns react-query for getAssetByID
 */
export const getAssetById = async (assetId) => {
  const asset = await Brm.assetApi.getAssetByID(assetId);
  asset.subject = await Brm.assetApi.getAssetSubject(assetId);
  asset.categorizedentity = await Brm.assetApi.getAssetCategorizedentity(assetId);

  return asset;
};

export const useGetAssetById = (assetId, config = {}) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["asset", assetId],
    queryFn: () => getAssetById(assetId),
  });
};
