import { BsFillShieldFill, BsFillBugFill } from "react-icons/bs";

import { LoadingSpinner } from "components/elements";
import { COMMON } from "constants/brm";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { MdInfoOutline } from "react-icons/md";
import Button from "react-bootstrap/Button";

import S from "./RarReport.styles";
import type { IRarReportData } from "./useRarReport";

const LEVEL_MAP = {
  [COMMON.veryHigh]: "VH",
  [COMMON.high]: "H",
  [COMMON.moderate]: "M",
  [COMMON.low]: "L",
  [COMMON.veryLow]: "VL",
};

export const RarReportView = ({
  isFetching,
  rarReportData,
  selectedContributor,
  showAdditionalContribs,
  contribsDisplayStructure,
}: {
  isFetching: boolean;
  rarReportData: IRarReportData;
  selectedContributor: any;
  showAdditionalContribs: any;
  contribsDisplayStructure: any;
}) => {
  // console.log(rarReportData);

  // const maxIconCount = Math.max(
  //   ...rarReportData.vulnerability.map((v) => {
  //     if (v.hasFindings && v.hasControls) {
  //       return 2;
  //     }
  //     if (v.hasFindings || v.hasControls) {
  //       return 1;
  //     }
  //     return 0;
  //   })
  // );

  const popover = (
    <S.ShadowPopover id="popover-basic">
      <Popover.Header as="h3">Vulnerability Legend</Popover.Header>
      <Popover.Body>
        <S.OrderedList>
          <S.ListItem style={{ paddingBottom: "10px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <S.Definition>Rank</S.Definition>
              <S.Dash>-</S.Dash>
              <div>
                The Global rank of the Vulnerability Conditions within this Variant of the Project, based on importance
                to the Risk score for the Contributors affected by this Risk
              </div>
            </div>
          </S.ListItem>
          <S.ListItem style={{ paddingBottom: "10px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <S.CondDefinition>Vulnerability Condition</S.CondDefinition>
              <S.Dash>-</S.Dash>
              <div>
                A Vulnerability Condition allows exploiting this system in unauthorised and possibly even malicious
                ways.
              </div>
            </div>
          </S.ListItem>
          <S.ListItem style={{ paddingBottom: "10px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <S.CondDefinition>Vulnerability Condition Level</S.CondDefinition>
              <S.Dash>-</S.Dash>
              <div>
                The Vulnerability Condition Level is the impact range (very low, low, moderate, high, very high) of a
                Vulnerability Condition.
              </div>
            </div>
          </S.ListItem>
          <div style={{ paddingLeft: "120px" }}>
            <S.ListItem>
              <S.Level>VL</S.Level>- Very Low
            </S.ListItem>
            <S.ListItem>
              <S.Level>L</S.Level>- Low
            </S.ListItem>
            <S.ListItem>
              <S.Level>M</S.Level>- Medium
            </S.ListItem>
            <S.ListItem>
              <S.Level>H</S.Level>- High
            </S.ListItem>
            <S.ListItem style={{ paddingBottom: "10px" }}>
              <S.Level>VH</S.Level>- Very High
            </S.ListItem>
          </div>
          <S.ListItem style={{ paddingBottom: "10px" }}>
            <S.Definition>
              <BsFillBugFill fill="#cd2027" />
            </S.Definition>
            - Vulnerability Condition has Known Findings
          </S.ListItem>
          <S.ListItem style={{ paddingBottom: "10px" }}>
            <S.Definition>
              <BsFillShieldFill fill="#55ad81" />
            </S.Definition>
            - Vulnerability Condition has Active Controls
          </S.ListItem>
        </S.OrderedList>
      </Popover.Body>
    </S.ShadowPopover>
  );

  // console.log("um what?", rarReportData.additional, rarReportData.recommended);

  return (
    <S.ReportContainer>
      <S.ReportViewWrapper>
        <S.UpdatingPopup isLoading={isFetching}>
          <S.UpdatingPopupContainer>
            <LoadingSpinner message="Updating RAR..." />
          </S.UpdatingPopupContainer>
        </S.UpdatingPopup>
      </S.ReportViewWrapper>
      <S.ReportView isLoading={isFetching}>
        <S.Layout>
          <S.SystemComponent>
            <S.ReportElementTitle>Contributor:</S.ReportElementTitle>{" "}
            {selectedContributor?.label === "ALL" ? "ALL" : `${rarReportData.component}`}
          </S.SystemComponent>

          <S.ControlRequirement>
            <S.ReportElementTitle>Control Requirement</S.ReportElementTitle>
          </S.ControlRequirement>

          <S.RiskNumber>
            <S.ReportElementTitle>Risk #</S.ReportElementTitle> {rarReportData.num}
          </S.RiskNumber>

          <S.RiskName>
            <S.ReportElementTitle>Risk Name:</S.ReportElementTitle> {rarReportData.statement}
          </S.RiskName>

          <S.RiskLevel level={rarReportData.level}>
            <S.ReportElementTitle>Initial Risk Level:</S.ReportElementTitle>
            <S.ReportElementContent>{rarReportData.level}</S.ReportElementContent>
          </S.RiskLevel>

          {/* Risk Analysis contributors */}
          <S.RiskContributors id="RarReport_RiskContributors">
            <S.ReportElementTitle>
              The risk analysis information below is calculated with a focus on contributor(s):
            </S.ReportElementTitle>
            <S.ReportElementContent>
              {selectedContributor?.label === "ALL"
                ? contribsDisplayStructure(rarReportData.contrib)
                : `${selectedContributor.label}`}
            </S.ReportElementContent>
          </S.RiskContributors>

          {/* Threats */}
          <S.Threats id="RarReport_Threats">
            <S.ReportElementTitle>Threats:</S.ReportElementTitle>
            <S.List>
              {rarReportData.threat?.threats.length > 0
                ? rarReportData.threat.threats?.map((each: any, key: string) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`${each.event}_${each.attacker}_${key}`}>
                      {each.event} by {each.attacker}
                    </li>
                  ))
                : "All threats are mitigated"}
            </S.List>
          </S.Threats>

          {/* Vulnerabilities */}
          <S.Vulnerabilities id="RarReport_Vulnerabilities">
            <S.ReportElementTitle>Vulnerabilities Sorted By Rank:</S.ReportElementTitle>
            <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
              <Button type="button" title="Vulnerability Legend" size="sm" variant="link">
                <MdInfoOutline size="16" />
              </Button>
            </OverlayTrigger>
            {rarReportData.vulnerability.length > 0 ? (
              <S.VulnerabilityContainer>
                {rarReportData.vulnerability?.reduce((acc, each) => {
                  return [
                    ...acc,
                    <S.MetaContainer key={`${each.id}bracket1`}>
                      <S.RankSpacer key={`${each.id}bracket2`}>{each.rank}</S.RankSpacer>
                      <S.IconSpacer key={`${each.id}bracket2.5`}>{`${LEVEL_MAP[each.level]}`}</S.IconSpacer>
                      <S.IconSpacer key={`${each.id}bracket3`}>
                        {each.hasFindings ? <BsFillBugFill fill="#cd2027" /> : <S.IconSpacer>-</S.IconSpacer>}
                      </S.IconSpacer>
                      <S.IconSpacer key={`${each.id}bracket`}>
                        {each.hasControls ? <BsFillShieldFill fill="#55ad81" /> : <S.IconSpacer>-</S.IconSpacer>}
                      </S.IconSpacer>
                    </S.MetaContainer>,
                    <div key={`${each.id}name`}>{each.name}</div>,

                    // <div key={`${each.id}bracket`} />,
                    // <div style={{ textAlign: "right", paddingRight: "10px" }} key={`${each.id}cat`}>
                    //   {`${LEVEL_MAP[each.level]}`}
                    //   {each.hasFindings ? (
                    //     <>
                    //       ,<BsFillBugFill fill="#cd2027" />
                    //     </>
                    //   ) : (
                    //     ""
                    //   )}
                    //   {each.hasControls ? (
                    //     <>
                    //       ,<BsFillShieldFill fill="#55ad81" />{" "}
                    //     </>
                    //   ) : (
                    //     " "
                    //   )}
                    // </div>,
                    // <div
                    //   style={{ cursor: "default" }}
                    //   title="This vulnerability has a real vulnerability (Vulnerability Finding) identified. Applying further controls may not fully mitigate this vulnerability condition"
                    //   key={`${each.id}findings`}
                    // >
                    //   {/* [F] */}
                    // </div>,
                    // <div
                    //   style={{ cursor: "default" }}
                    //   title="This vulnerability does not have any more controls in the baseline that can be applied and therefore you need to go to the control catalog to further mitigate"
                    //   key={`${each.id}baseline`}
                    // >
                    //   {/* [B] */}
                    // </div>,
                  ];
                }, [])}
              </S.VulnerabilityContainer>
            ) : (
              <>All vulnerabilities are mitigated</>
            )}
          </S.Vulnerabilities>

          {/* Risk,likelihood, Impact */}
          <S.Impact id="RarReport_Impact">
            <S.ImpactDisplay>
              <S.ImpactTitle>
                <S.ReportElementTitle>Risk: </S.ReportElementTitle>
              </S.ImpactTitle>
              {rarReportData.magnitude.initial.likelihood},{rarReportData.magnitude.initial.impact},
              {rarReportData.magnitude.initial.level}
            </S.ImpactDisplay>
            <S.ImpactDisplay>
              <S.ImpactTitle>
                <S.ReportElementTitle>Likelihood: </S.ReportElementTitle>
              </S.ImpactTitle>
              {rarReportData.magnitude.likelihood.means},{rarReportData.magnitude.likelihood.opportunity}
            </S.ImpactDisplay>
            <S.ImpactDisplay>
              <S.ImpactTitle>
                <S.ReportElementTitle>Impact: </S.ReportElementTitle>
              </S.ImpactTitle>
              {rarReportData.magnitude.impact.severity}, {rarReportData.magnitude.impact.criticality}
            </S.ImpactDisplay>
          </S.Impact>

          {/* Mitigation/CounterMeasures */}
          <S.Mitigation id="RarReport_Mitigation">
            <S.ReportElementContent>
              <S.ReportElementTitle>Mitigation/Countermeasures: </S.ReportElementTitle>
              {rarReportData.mitigations.join(", ") || "None"}
            </S.ReportElementContent>
          </S.Mitigation>

          {/* Residual Risk */}
          <S.ResidualRisk id="RarReport_ResidualRisk">
            <S.ReportElementTitle>Residual Risk: </S.ReportElementTitle>
            <span title={rarReportData.residual.explanation}>
              {rarReportData.residual.means},{rarReportData.residual.opportunity},{rarReportData.residual.likelihood},
              {rarReportData.residual.level}
            </span>
          </S.ResidualRisk>

          {/* Current Residual Risk */}
          <S.CurrentResidualRisk id="RarReport_CurrentResidualRisk" level={rarReportData.residual.level}>
            <S.ReportElementTitle>Current Residual Risk: </S.ReportElementTitle>
            {rarReportData.residual.level}
          </S.CurrentResidualRisk>

          {/* Additional Countermeasures */}
          <S.CounterMeasures id="RarReport_CounterMeasures">
            <S.ReportElementTitle>
              Additional countermeasures needed for Low/Very Low residual risk:
            </S.ReportElementTitle>
            <S.ReportElementContent>
              <em>
                <u>Have not been applied to any contributors:</u>{" "}
              </em>
              {rarReportData.recommended.join(", ") || "None"}
            </S.ReportElementContent>

            <S.ReportElementContent>
              <em>
                <u>Have not been applied to all applicable contributors:</u>{" "}
              </em>
              {rarReportData.additional?.join(", ") || "None"}
              {(!rarReportData.additional ||
                (Array.isArray(rarReportData.additional) && !rarReportData.additional.length)) &&
              (!rarReportData.recommended ||
                (Array.isArray(rarReportData.recommended) && !rarReportData.recommended.length)) &&
              rarReportData.threat?.threats.length > 0 ? (
                <>
                  <br />
                  <br />
                  <p>No countermeasures in current baseline left to further mitigate this risk.</p>
                  <p>
                    Use the &quot;User-Guided Mitigation&quot; to select applicable countermeasures from the Catalog.
                  </p>
                </>
              ) : (
                <></>
              )}
            </S.ReportElementContent>
          </S.CounterMeasures>

          {/* Additional Contributors */}
          <S.Contributors id="RarReport_Contributors">
            <S.ReportElementTitle>Additonal Contributors:</S.ReportElementTitle>
            {selectedContributor?.label === "ALL" ? "None" : showAdditionalContribs(rarReportData.contrib)}
          </S.Contributors>
        </S.Layout>
      </S.ReportView>
    </S.ReportContainer>
  );
};
