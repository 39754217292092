import { useState, useEffect } from "react";
import * as ReactQuery from "@tanstack/react-query";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { userRoleState, projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { userRoles } from "services/brm/admin-service";
import { AddButton, DeleteButton } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { TYPE, CRUD_EVENT } from "constants/brm";
import systemModals from "components/modals/Modals";

import ContentLayout from "brm/layout/Content/ContentLayout";

import MissionTable from "./tables/MissionTable";
import MissionAddForm from "./forms/MissionAddForm";
import Event from "./crudEvent";
import * as SysFactsApi from "./api";

function Mission() {
  const userRole = useRecoilValue(userRoleState);
  const projectId = useRecoilValue(projectIdState);
  const sysEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const queryClient = ReactQuery.useQueryClient();

  const deleteMission = SysFactsApi.useDeleteMission({
    config: {
      onSuccess: () => {
        setDeleteModalIsOpen(false);
        setSelectedInstance({});
        queryClient.invalidateQueries(["mission", projectId]);
      },
      onError: (error) => {
        console.error("Error when deleting mission:", error.message);
      },
    },
  });

  const addMission = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      Promise.all(selectedRows.map((each) => deleteMission.mutateAsync({ missionId: each.id })));
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteMission, selectedRows, setSelectedInstance]);

  const missionHeader = (
    <Title id="Mission_title">
      Missions
      <Buttons id="Mission_buttons">
        <Event constant={CRUD_EVENT.mission} setRefresh={setRefresh} />
        {userRole && userRole === sysEngineerRole ? (
          <>
            {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
            <AddButton onClick={addMission} />
          </>
        ) : (
          <br />
        )}
      </Buttons>
    </Title>
  );

  const missionMain = (
    <>
      {" "}
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "Mission_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.mission}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.mission
        )}
      {modalIsOpen &&
        systemModals.addModal(
          "Mission_page",
          modalIsOpen,
          setModalIsOpen,
          <MissionAddForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} />,
          TYPE.mission
        )}
      <MissionTable
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
        accessibleRole={sysEngineerRole}
      />
    </>
  );

  return <ContentLayout pageId="Mission_page" header={missionHeader} main={missionMain} />;
}

export default Mission;
