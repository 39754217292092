import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./vulnerability-query-key-factory";

interface IGetVulnerabilitiess {
  projectId: string;
  variantId: string;
  objectId: string;
}

/**
 * Fetches a list of vulnerabilities for the given object
 *
 * @param {string} projectId - uuid of project
 * @param {string} variantId - uuid of project variant
 * @param {string} objectId - uuid of the object to retreive vulnerabilities
 * @returns a list of vulnerabilities
 */
export const getVulnerabilities = ({ projectId, variantId, objectId = "" }: IGetVulnerabilitiess) => {
  const opts = objectId ? { variant: variantId, object: objectId } : { variant: variantId };
  return Brm.vulnerabilityApi.findVulnerability(projectId, opts);
};

interface IUseVulnerabilities {
  projectId: string;
  variantId: string;
  objectId: string;
  options?: QueryOption<typeof getVulnerabilities>;
}

/**
 * Custom-hook to retrieve all vulnerabilityes for a project.
 *
 * @param {string} projectId - uuid of project
 * @param {string} variantId - uuid of project variant
 * @param {object} options - react-query configuration object
 * @returns react-query for findNode
 */
export const useVulnerabilities = ({ projectId, variantId, objectId, options }: IUseVulnerabilities) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.vulnerabilityKeys.object(projectId, variantId, objectId),
    queryFn: () => getVulnerabilities({ projectId, variantId, objectId }),
  });
};
