import PropTypes from "prop-types";

const DynamicUsersSelectBox = ({ arrayOfData, item }) => {
  let options;
  if (arrayOfData && arrayOfData.length > 0) {
    // check if there is any data in the array first
    options = arrayOfData.map((data) => (
      <option key={data.id} value={data.id}>
        {data.username}
      </option>
    ));
  }

  return (
    <select name={item} id={item}>
      <option>Select {item}</option>
      {options}
    </select>
  );
};

DynamicUsersSelectBox.propTypes = {
  arrayOfData: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
    })
  ),
  item: PropTypes.any,
};

export default DynamicUsersSelectBox;
