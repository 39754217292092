import styled from "styled-components";
import styledMap from "styled-map";
import StyledButton from "components/elements/StyledButton";

const SecondaryStyledButton = styled(StyledButton)`
  height: ${({ theme }) => theme.sizes.defaultButton};

  border-color: ${(props) => props.theme.secondarybuttonbordercolor};
  color: ${(props) => props.theme.secondarybuttontextcolor};
  background-color: ${(props) => props.theme.secondarybuttonbackgroundcolor};
  font-size: ${styledMap`
        lg: 1.3rem;
        md: 1rem;
        sm: 0.7rem;
        default: 1rem;
    `};
  &:hover {
    opacity: 0.8;
    cursor: pointer;
    background-color: #ddd;
  }
  margin: 0 3px;
  &:disabled {
    background-color: #cccccc;
  }
`;

export default SecondaryStyledButton;
