import SecondaryStyledButton from "components/elements/SecondaryStyledButton";
import React from "react";
import { GrLicense } from "react-icons/gr";

interface ILicenseCheckButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const LicenseCheckButton = ({ onClick }: ILicenseCheckButtonProps) => (
  <span title="License status">
    <SecondaryStyledButton id="LicenseCheckButton_button" onClick={onClick}>
      <GrLicense fill="#000000" width="14px" height="14px" />
    </SecondaryStyledButton>
  </span>
);
