import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import systemModals from "components/modals/Modals";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { useRecoilState, useRecoilValue } from "recoil";
import { AddButton, BrmError, DeleteButton, DetailTableType, ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { riskApi } from "services/brm/risk-service";
import {
  createColumnMappedNoEdit,
  createColumnNameDetails,
  createColumnImpactMapped,
} from "brm/tables/services/column/columnFactory";
import { useModal } from "hooks";
import { useRoles } from "features/brm/hooks/useRoles";
import * as S from "brm/styles/details-table.styles";
import * as BrmGql from "generated/graphql";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";

import { RoutePath } from "routes/route-paths";
import { useElementUndesiredEvents } from "./api";
import UndesiredEventAddForm from "./forms/UndesiredEventAddForm";
import multiSelectDelete from "./utils/DetailTablesUtils";
// Styles

const UndesiredEventTable = ({
  elementNameInUrl,
  selectedElement,
  showAdd,
  showDelete = false,
  setRefresh,
  tableTitle,
}) => {
  const [projectId] = useRecoilState(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const [, setSelectedUe] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { isRiskAnalyst } = useRoles();

  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();
  const { data: ueData, isError: isUeDataError } = useElementUndesiredEvents({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    variantId,
    projectId,
    config: {
      enabled:
        !!selectedElement.id &&
        !!elementNameInUrl &&
        !!projectId &&
        elementNameInUrl !== DetailTableType.VULNERABILITIES.key &&
        elementNameInUrl !== DetailTableType.SYSTEM_ASSET_TYPES.key,
    },
  });
  const {
    data: vulnerabilityUEsData,
    isError: isVulnerabilityUEsDataError,
    error: vulnerabilityUEsDataError,
  } = BrmGql.useGetVulnerabilityUEsQuery(
    { project: projectId, id: selectedElement.id, variant: variantId },
    {
      enabled: !!selectedElement.id && elementNameInUrl === DetailTableType.VULNERABILITIES.key,
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: systemAssetTypeUEsData,
    isError: isSystemAssetTypeUEsDataError,
    error: systemAssetTypeUEsDataError,
  } = BrmGql.useGetSystemAssetTypeUEsQuery(
    { project: projectId, id: selectedElement.id, variant: variantId },
    {
      enabled: !!selectedElement.id && elementNameInUrl === DetailTableType.SYSTEM_ASSET_TYPES.key,
      refetchOnWindowFocus: false,
    }
  );

  const columns = useMemo(() => {
    if (isRiskAnalyst) {
      return [
        createColumnNameDetails(RoutePath.UndesiredEvent.replace(":id", "")),
        createColumnMappedNoEdit("category"),
        createColumnMappedNoEdit("objective"),
        createColumnImpactMapped("currentImpact"),
        createColumnMappedNoEdit("anobject"),
      ];
    }
    return [
      createColumnMappedNoEdit("name"),
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("objective"),
      createColumnImpactMapped("currentImpact"),
      createColumnMappedNoEdit("anobject"),
    ];
  }, [isRiskAnalyst]);

  function getDeleteRequest(ueId) {
    switch (elementNameInUrl) {
      case DetailTableType.RISKS.key: {
        return riskApi.removeRiskUeWithHttpInfo(selectedElement.id, ueId);
      }
      default: {
        break;
      }
    }
    throw new BrmError(`Unsupported element type:${elementNameInUrl}`);
  }

  if (isUeDataError) {
    return <ErrorBanner msg="Error while loading undesired events" />;
  }
  if (isVulnerabilityUEsDataError) {
    return <ErrorBanner msg={vulnerabilityUEsDataError?.message} />;
  }
  if (isSystemAssetTypeUEsDataError) {
    return <ErrorBanner msg={systemAssetTypeUEsDataError?.message} />;
  }

  let data = null;

  if (ueData) {
    try {
      // remove UE's with impact calibratedStringValue of zero
      ueData.forEach((ue) => {
        if (ue.impact.calibratedStringValue === "zero") {
          const index = ueData.indexOf(ue);
          ueData.splice(index, 1);
        }
      });
    } catch (err) {
      console.error("Error removing UE with impact calibratedStringValue value of zero: ", err);
    }
    data = ueData;
  }

  if (vulnerabilityUEsData || systemAssetTypeUEsData) {
    if (elementNameInUrl === DetailTableType.VULNERABILITIES.key) {
      data = vulnerabilityUEsData?.vulnerability.ues;
    } else if (elementNameInUrl === DetailTableType.SYSTEM_ASSET_TYPES.key) {
      data = systemAssetTypeUEsData?.systemAssetType.ues;
    }
  }

  const addUndesiredEvent = () => {
    setModalIsOpen(true);
  };

  if (data) {
    return (
      <S.DetailsContainer id="UndesiredEventTable_detailsPanel">
        {/* modal for adding instance */}
        {modalIsOpen &&
          systemModals.addModal(
            "UndesiredEventTable_detailsPanel",
            modalIsOpen,
            setModalIsOpen,
            <UndesiredEventAddForm
              setModalIsOpen={setModalIsOpen}
              setRefresh={setRefresh}
              elementNameInUrl={elementNameInUrl}
              selectedElement={selectedElement}
            />,
            "Undesired Event"
          )}
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedUe}
              setSelectedRows={setSelectedRows}
              customProps={{ id: "UndesiredEventTable_detailsTable" }}
              showRowSelect={showDelete}
              elementName={elementNameInUrl}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            {showDelete && (
              <DeleteButton md onClick={() => multiSelectDelete(selectedRows, getDeleteRequest, setRefresh)} />
            )}
            {showAdd && <AddButton md onClick={addUndesiredEvent} />}
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

UndesiredEventTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setRefresh: PropTypes.any,
  showAdd: PropTypes.any,
  showDelete: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default UndesiredEventTable;
