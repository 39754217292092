/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { components } from "react-select";
import { BsFillTriangleFill } from "react-icons/bs";
import { TYPE } from "constants/brm";

const FilterValueContainer = ({ children, ...rest }) => {
  let icon;
  if (rest.selectProps.type === TYPE.attack || rest.selectProps.type === TYPE.trev) {
    icon = (
      <BsFillTriangleFill size="18" color={rest.selectProps.filterColor} style={{ transform: "rotate(180deg)" }} />
    );
  } else if (rest.selectProps.type === TYPE.risk) {
    icon = <BsFillTriangleFill size="18" color={rest.selectProps.filterColor} />;
  } else {
    icon = (
      <svg id="svgelem" width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" rx="5" ry="5" width="16" height="16" style={{ fill: `${rest.selectProps.filterColor}` }} />
      </svg>
    );
  }

  return (
    <components.ValueContainer {...rest}>
      {icon}
      {children[1]}
    </components.ValueContainer>
  );
};

FilterValueContainer.propTypes = {
  getValue: PropTypes.func.isRequired,
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  options: PropTypes.array,
};

export default FilterValueContainer;
