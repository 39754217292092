export const attackKeys = {
  all: ["attack"] as const,
  element: (
    elementId: string | null,
    elementName: string | null,
    projectId: string | null,
    variantId: string | null,
    attackData: any | null
  ) => [...attackKeys.all, "elementAttacks", elementId, elementName, projectId, variantId, attackData] as const,
  attack: (attackId: string | null, variantId: string | null) => [...attackKeys.all, attackId, variantId] as const,
  attacks: (projectId: string, variantId: string) => [...attackKeys.all, "attacks", projectId, variantId] as const,
  attackTactics: (projectId: string) => [...attackKeys.all, projectId, "attackTactics"] as const,
  likelihoodCalibrationAttacks: (projectId: string, variantId: string) =>
    [...attackKeys.all, projectId, variantId, "getAttacks"] as const,
};
