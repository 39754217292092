import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

/**
 *
 * Gets catalog of project
 * @param {string} projectId - uuid of project
 */
export const getProjectCatalog = ({ projectId }: { projectId: string }) => {
  return Brm.projectApi.getProjectCatalog(projectId);
};

interface IProjectProps {
  projectId: string;
  options?: QueryOption<typeof getProjectCatalog>;
}

/**
 * Custom hook for getting catalog of project
 *
 * @param {string} projectId - uuid of user
 * @param {object} options - react-query configuration
 * @returns getprojectCatalog
 */
export const useProjectCatalog = ({ projectId, options }: IProjectProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.project(projectId),
    queryFn: () => getProjectCatalog({ projectId }),
  });
};
