import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./project-query-key-factory";
import * as UserKeyFactory from "../user/user-query-key-factory";

export interface IProjectKbProps {
  projectId: string | null;
  kb: string;
}

export const setProjectKb = ({ projectId, kb }: IProjectKbProps) => {
  return projectId === null
    ? Promise.reject(new Error("Invalid projectId id"))
    : Brm.projectApi.setProjectKb(projectId, { body: kb });
};

interface ISetProjectKbProps {
  options?: MutationOption<typeof setProjectKb>;
}

/**
 * Custom hook to set kb to a project.
 *
 * @returns react-query Mutation.
 */
export const useSetProjectKb = ({ options }: ISetProjectKbProps = {}) => {
  return ReactQuery.useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(KeyFactory.projectKeys.all);
      queryClient.invalidateQueries(KeyFactory.projectKeys.project(variables.projectId));
      queryClient.invalidateQueries(UserKeyFactory.userKeys.all);
    },
    ...options,
    mutationFn: setProjectKb,
  });
};
