import { useParams, useNavigate } from "react-router-dom";

// State
import { userIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";

// Constants
import { TYPE } from "constants/brm";

// Local
import { LoadingSpinner as Loading } from "components/elements";
import { UserDetailsTab } from "../../components/tabs/UserDetailsTab";
import { UserFullDetails } from "./UserFullDetails";
import { useUserOverview } from "./useUserOverview";

export const UserOverview = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const userId = useRecoilValue(userIdState);

  const { assignedRoles, userList, user, selectedElement, columns, setSelectedElement, setSelectedRowId } =
    useUserOverview({ userOverviewId: id, userId, navigate });

  if (assignedRoles && userList && user && selectedElement) {
    const detailsComp = <UserDetailsTab selectedElement={selectedElement} />;
    return (
      <UserFullDetails
        showBackLink
        data={userList}
        columns={columns}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        setSelectedRowId={setSelectedRowId}
        detailsComp={detailsComp}
        compName={TYPE.user}
      />
    );
  }
  return <Loading />;
};
