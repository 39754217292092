import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import errorUtils from "utils/error-utils";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import SystemService, { exchangeApi, busApi, linkApi, capabilityApi, dataflowApi } from "services/brm/system-service";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import getReactSelectArray from "utils/react-select-array";
import { FORM_LABEL } from "constants/brm";
import SelectAll from "components/forms/SelectAllReactSelect";
import { DetailTableType, SystemApi } from "features/brm";
import localforage from "localforage";
import getUniqueArray from "./helpers/GetUniqueArray";
// import customDetailStyle from "./styles/BrmReactSelectDetailsStyles";

const AddExchangeForm = ({ setModalIsOpen, setRefresh, elementNameInUrl, selectedElement }) => {
  const [postError, setPostError] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const projectId = useRecoilValue(projectIdState);

  const { mutateAsync: addDataflowExchange } = SystemApi.useAddDataflowExchange({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
  });

  const filterExisting = useCallback(async () => {
    let existingRelations = [];
    switch (elementNameInUrl) {
      case DetailTableType.BUSES.key: {
        const data = await busApi.getBusExchange(selectedElement.id);
        existingRelations = data;
        break;
      }
      case DetailTableType.LINKS.key: {
        const data = await linkApi.getLinkExchange(selectedElement.id);
        existingRelations = data;
        break;
      }
      case DetailTableType.CAPABILITIES.key: {
        const data = await capabilityApi.getCapabilityExchange(selectedElement.id);
        existingRelations = data;
        break;
      }
      case DetailTableType.DATAFLOWS.key: {
        const data = await dataflowApi.getDataFlowExchange(selectedElement.id);
        existingRelations = data;
        break;
      }
      default: {
        break;
      }
    }
    return existingRelations;
  }, [elementNameInUrl, selectedElement.id]);

  useEffect(() => {
    async function getData() {
      const existingRelations = await filterExisting();

      const res = await exchangeApi.findExchangeWithHttpInfo(projectId);
      if (res?.data) {
        const uniqueArray = getUniqueArray(res.data, existingRelations);
        setOptions(getReactSelectArray(uniqueArray));
      }
    }
    if (projectId) {
      getData();
    }
  }, [projectId, filterExisting]);

  const handleSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedOptions(items);
  };

  function getPutRequest(selectedId) {
    switch (elementNameInUrl) {
      case DetailTableType.BUSES.key: {
        return SystemService.addBusExchange(selectedElement.id, selectedId);
      }
      case DetailTableType.LINKS.key: {
        return SystemService.addLinkExchange(selectedElement.id, selectedId);
      }
      case DetailTableType.CAPABILITIES.key: {
        return SystemService.addCapabilityExchange(selectedElement.id, selectedId);
      }
      case DetailTableType.DATAFLOWS.key: {
        return addDataflowExchange({ dataflowId: selectedElement.id, exchangeId: selectedId });
      }
      default: {
        break;
      }
    }
    return undefined;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (selectedOptions.length > 0) {
      try {
        selectedOptions.forEach(async (item) => {
          const res = await getPutRequest(item.value);
          if (res?.response) {
            errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          }
        });
        localforage.removeItem(`sd_layout_${projectId}`);
      } catch (err) {
        console.error("Error occurred on put request: ", err);
      }
      setModalIsOpen(false);
      if (setRefresh) {
        setRefresh(true);
      }
    } else {
      setPostError("*Please fill out all fields in the form prior to submitting.");
    }
  }

  return (
    <div id="AddExchangeForm">
      <form onSubmit={handleSubmit} action="" id="AddExchangeForm_form">
        <FormStyled>
          <div className="form-style" id="AddExchangeForm_formContent">
            <label id="AddExchangeForm_label">{FORM_LABEL.exchanges}</label>
            <SelectAll
              // styles={customDetailStyle}
              isMulti
              options={options}
              defaultValue={selectedOptions}
              value={selectedOptions}
              onChange={handleSelectChange}
              allowSelectAll
              allOption={{
                label: "Select all",
                value: "*",
              }}
              elementId="AddExchangeForm_exchange"
            />
          </div>
        </FormStyled>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="AddExchangeForm_cancelButton">
            Cancel
          </DialogButtonStyled>
          <DialogButtonStyled type="submit" id="AddExchangeForm_addButton">
            Add
          </DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};

AddExchangeForm.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setModalIsOpen: PropTypes.func,
  setRefresh: PropTypes.func,
};

export default AddExchangeForm;
