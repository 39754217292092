import * as React from "react";
import { ActionButton } from "components/elements";
import * as S from "./ExportArryForm.styles";
import { useExportAar } from "../../../hooks";

interface IExportAarForm {
  onClose: () => void;
}

export const ExportAarForm = ({ onClose }: IExportAarForm) => {
  const [msg, setMessage] = React.useState("Generating AAR Document...");

  const { exportAarFile } = useExportAar({
    onStatusError: (msg2) => {
      setMessage(msg2);
    },
    onSuccess: () => {
      onClose();
    },
  });

  React.useEffect(() => {
    exportAarFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      {msg}
      <S.ButtonContainer>
        <ActionButton type="button" onClick={onClose}>
          Cancel
        </ActionButton>
      </S.ButtonContainer>
    </S.Container>
  );
};
