import styled from "styled-components";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SettingLabel = styled.label`
  display: inline-block;
  width: 300px;
`;

export const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 250px 200px;
  margin-bottom: 5px;
  align-items: center;
`;

export const Title = styled.h6`
  padding: 5px 0px;
`;

export const Definition = styled.div`
  padding: 5px 10px 5px;
`;
