import { useContext } from "react";
import * as Recoil from "recoil";

// Images
import { ReactComponent as InfoIcon } from "assets/icons/studio91/sidebars-toolbars/information.svg";
import { ReactComponent as FilterIcon } from "assets/icons/studio91/sidebars-toolbars/filter.svg";

import { ThemeContext } from "styled-components";
// Components
import { BasicButton as Button } from "components/elements";
import { CONTEXT_SIDEBAR_CONTENT, contextSidebarContentState, DrawerContentType } from "features/brm";

// Constants

export const RightSidebarContextDiagramButtons = ({ onClick }: { onClick: (type: DrawerContentType) => void }) => {
  const theme = useContext(ThemeContext);
  const setContentType = Recoil.useSetRecoilState(contextSidebarContentState);

  const {
    colors: { buttonPrimary: buttonFill },
    sizes: { standaloneIconWidth: iconWidth, standaloneIconWidth: iconHeight },
  } = theme;

  const handleButtonClick = (contentType: DrawerContentType) => {
    onClick(contentType);
    setContentType(contentType);
  };

  return (
    <>
      <Button onClick={() => handleButtonClick(CONTEXT_SIDEBAR_CONTENT.contextDiagramInfo)}>
        <span title="Information">
          <InfoIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
        </span>
      </Button>
      <Button onClick={() => handleButtonClick(CONTEXT_SIDEBAR_CONTENT.contextDiagramFiltering)}>
        <span title="Filtering">
          <FilterIcon fill={buttonFill} width={iconWidth} height={iconHeight} />
        </span>
      </Button>
    </>
  );
};
