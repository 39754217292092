/* eslint-disable no-param-reassign */
import * as YFiles from "yfiles";
import * as React from "react";

const configureFiltering = (
  fullGraph: YFiles.IGraph,
  nodePredicate: (node: YFiles.INode) => boolean,
  edgePredicate: (edge: YFiles.IEdge) => boolean
): YFiles.FilteredGraphWrapper => {
  const filteredGraph = new YFiles.FilteredGraphWrapper(fullGraph, nodePredicate, edgePredicate);
  return filteredGraph;
};

export const useGraphFiltering = (
  graphComp: YFiles.GraphComponent,
  nodePredicate: (node: YFiles.INode) => boolean,
  edgePredicate: (edge: YFiles.IEdge) => boolean
) => {
  const [masterGraph] = React.useState<YFiles.IGraph>(() => graphComp.graph);
  const [filteredGraph] = React.useState(() => configureFiltering(graphComp.graph, nodePredicate, edgePredicate));

  React.useEffect(() => {
    graphComp.graph = filteredGraph;
  }, [filteredGraph, graphComp]);

  return [masterGraph, filteredGraph] as const;
};
