import {
  fromRiskMatrixState,
  isRiskMatrixCellClickedState,
  riskFromNavigateState,
  riskUpdatedState,
} from "atoms/atoms-risk";
import { useQuery } from "@tanstack/react-query";
import filterRisks from "brm/risk-model/risk/utils/RiskFilter";
import { useRecoilState, useRecoilValue } from "recoil";
import { riskApi } from "services/brm";
import { sortByRiskRank } from "utils/sorting";
import { variantIdState } from "atoms/atoms-component";
import { useRoles } from "features/brm";

const getNonZeroRisks = async (projectId, variantId, fromRiskMatrix, setFromRiskMatrix) => {
  const data = await riskApi.findRisk(projectId, { variant: variantId });
  // remove risks with score of 0
  const nonZeroRisks = data.filter((r) => r.score.value !== 0);
  let result = [];
  if (fromRiskMatrix.navFromRiskMatrix) {
    const filteredArray = filterRisks(nonZeroRisks, fromRiskMatrix.filter);
    setFromRiskMatrix({ navFromRiskMatrix: false, filter: null });
    result = filteredArray.sort(sortByRiskRank);
  } else result = nonZeroRisks.sort(sortByRiskRank);
  return result;
};

export const useNonZeroRisks = (projectId) => {
  const { isRiskAnalyst } = useRoles();
  const variantId = useRecoilValue(variantIdState);
  const [fromRiskMatrix, setFromRiskMatrix] = useRecoilState(fromRiskMatrixState);
  const [riskUpdated, setRiskUpdated] = useRecoilState(riskUpdatedState);
  const [riskFromNavigate, setRiskFromNavigate] = useRecoilState(riskFromNavigateState);
  const isRiskMatrixCellClicked = useRecoilValue(isRiskMatrixCellClickedState);

  return useQuery(
    ["risks", projectId, variantId, isRiskMatrixCellClicked, riskFromNavigate],
    () => getNonZeroRisks(projectId, variantId, fromRiskMatrix, setFromRiskMatrix),
    {
      enabled: !!projectId && isRiskAnalyst,
      cacheTime: 0,
      onSuccess: () => {
        if (riskUpdated) {
          setRiskUpdated(false);
        }
        if (riskFromNavigate) {
          setRiskFromNavigate(false);
        }
      },
    }
  );
};
