import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as BrmGql from "generated/graphql";
import * as KeyFactory from "./threat-group-query-key-factory";

export interface ISetThreatGroupName {
  threatGroupId: string;
  name: string;
}

/**
 * Sets  name property for threat group
 *
 * @param {string} threatGroupId - uuid of the threat group
 * @param {string} name - threat group name
 */
export const setThreatGroupName = ({ threatGroupId, name }: ISetThreatGroupName) => {
  return Brm.threatGroupApi.setThreatGroupName(threatGroupId, { body: name });
};

export interface IUseSetThreatGroupName {
  options?: MutationOption<typeof setThreatGroupName>;
}

/**
 * Custom hook to change the name property of an existing threat group
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetThreatGroupName = ({ options }: IUseSetThreatGroupName = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.threatGroupKeys.all);
      queryClient.invalidateQueries(BrmGql.useGetThreatGroupsQuery.getKey());
    },
    ...options,
    mutationFn: setThreatGroupName,
  });
};
