import { useState } from "react";
// Components
import SelectBoxCategory from "components/forms/DynamicSelectBoxCategory";
import SelectBox from "components/forms/DynamicSelectBox";
import BooleanDropdown from "components/forms/BooleanDropdown";
import TextField from "components/forms/TextField";
// Constants
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
// Services
import { ThreatGroupCreateDto } from "@kdmanalytics/brm-knowledgebase";
import PropTypes from "prop-types";
import { LoadingSpinner as Loading } from "components/elements";
import { ThreatApi, AdminApi } from "features/brm";

const AddThreatGroupForm = ({ setModalIsOpen }) => {
  const { data: selfData } = AdminApi.useSelf({ options: {} });
  const [postError, setPostError] = useState("");
  const [isTextValid, setIsTextValid] = useState(true);
  const { data: threatTiers } = ThreatApi.useThreatTiers();
  const { data: threatCatalogs } = ThreatApi.useThreatCatalogs({
    options: {
      enbaled: !!selfData,
      select: (data) => data.filter((tc) => tc.organization.id === selfData.organization),
    },
  });
  const createThreatGroup = ThreatApi.useCreateThreatGroup();

  // validate that all fields in the form have been completed
  function validate(formData) {
    let error = true;
    if (
      formData.name.value === "" ||
      formData.sourceURL.value === "" ||
      formData.isNationState.value === "" ||
      formData.tier.value === "Select tier" ||
      formData.catalog.value === "Select catalog" ||
      formData.catalog.value === ""
    ) {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isValid = validate(e.target.elements);

    if (!isValid) {
      if (e.target.elements.catalog.value === "") {
        return setPostError(
          "No available catalogs to add this threat group to. Please create a threat catalog for your organization."
        );
      }
    }

    if (isValid && isTextValid) {
      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value || "",
        sourceURL: e.target.elements.sourceURL.value,
        isNationState: e.target.elements.isNationState.value,
        tier: e.target.elements.tier.value,
        // OPTIONAL
        // "ttp": e.target.elements.ttp.value
      };

      const threatGroupCreateDto = ThreatGroupCreateDto.constructFromObject(params);
      createThreatGroup.mutate(
        { catalog: e.target.elements.catalog.value, threatGroupCreateDto },
        {
          onSettled: () => {
            setModalIsOpen(false);
          },
        }
      );
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  if (threatCatalogs && threatTiers) {
    return (
      <div>
        <form onSubmit={handleSubmit} action="">
          <FormStyled>
            <div className="form-style">
              <TextField
                label={{ id: "ThreatGroupAddForm_Name", name: FORM_LABEL.nameMandatory }}
                input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "ThreatGroupAddForm_Note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "ThreatGroupAddForm_SourceURL", name: FORM_LABEL.sourceUrlMandatory }}
                input={{ name: "sourceURL", placeholder: FORM_PLACEHOLDER.sourceURL }}
                setIsTextValid={setIsTextValid}
              />

              <BooleanDropdown
                label={{ id: "ThreatGroupAddForm_isNationState", name: FORM_LABEL.nationStateMandatory }}
                select={{ id: "isNationState", name: "isNationState" }}
              />

              <label id="ThreatGroupAddForm_Tier">{FORM_LABEL.tierMandatory}</label>
              <SelectBoxCategory id="ThreatGroupAddForm_tierDropdown" arrayOfData={threatTiers} item="tier" />
              <label id="ThreatGroupAddForm_Catalog">{FORM_LABEL.catalogMandatory}</label>
              <SelectBox id="ThreatGroupAddForm_catalogDropdown" arrayOfData={threatCatalogs} item="catalog" />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
            <DialogButtonStyled type="submit">Add</DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }

  return <Loading />;
};

AddThreatGroupForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};

export default AddThreatGroupForm;
