import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import { DetailTableType } from "../../../types/DetailTableType";
import * as KeyFactory from "./node-query-key-factory";

export interface IAddElementNodesProps {
  elementId: string;
  elementName: string;
  nodeId: string;
}

interface InodeMap {
  [index: string]: (id?: string) => any;
}

/**
 * Sets node for an element
 *
 * @param {string} elementId - uuid of the element
 * @param {string} elementName - name of the element
 *  @param {string} nodeId - uuid of the node
 */
export const addElementNodes = ({ elementId, elementName, nodeId }: IAddElementNodesProps) => {
  const nodeMap: InodeMap = {
    [DetailTableType.PERSONS.key]: (id) => Brm.personApi.addPersonNode(id, { body: nodeId }),
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.addCapabilityNode(id, { body: nodeId }),
    [DetailTableType.RESOURCES.key]: (id) => Brm.resourceApi.addResourceNode(id, { body: nodeId }),
  };

  if (nodeMap[elementName]) {
    return nodeMap[elementName](elementId);
  }

  return [];
};

export interface IUseAddElementNodes {
  options?: MutationOption<typeof addElementNodes>;
}

/**
 * Custom hook to add node to an element
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useAddElementNodes = ({ options = {} }: IUseAddElementNodes = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.nodeKeys.all);
    },
    ...options,
    mutationFn: addElementNodes,
  });
};
