import PropTypes from "prop-types";

// Styles
import S from "./ProjectExplorer.styles";

const MenuEntry = ({ text }) => {
  return <S.MenuEntry>{text}</S.MenuEntry>;
};

MenuEntry.propTypes = {
  text: PropTypes.string.isRequired,
};

export default MenuEntry;
