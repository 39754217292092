import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./license-query-key-factory";

/**
 * gets current state of the license
 *
 */
export const getLicenseState = (): Promise<any> => {
  return Brm.licensingApi.licenseState();
};

interface IUseLicenseState {
  options?: QueryOption<typeof getLicenseState>;
}

/**
 * Custom-hook to get current state of the license
 *
 * @param {object} options - react-query configuration object
 * @returns react-query for getLicenseState
 */
export const useLicenseState = ({ options }: IUseLicenseState = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.licenseKeys.state(),
    queryFn: () => getLicenseState(),
  });
};
