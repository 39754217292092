import { useState, useEffect } from "react";
import * as ReactQuery from "@tanstack/react-query";
// Components
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { AddButton, DeleteButton, SystemApi } from "features/brm";
import systemModals from "components/modals/Modals";

import ContentLayout from "brm/layout/Content/ContentLayout";
// Styles
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
// State
import { projectIdState, projectNameState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
// Services
import { userRoles } from "services/brm/admin-service";
// Constants
import { TYPE, CRUD_EVENT } from "constants/brm";
// Local
import { DownloadButton } from "components/elements";
import { dloadJSON } from "utils/report-utils";
import { variantIdState } from "atoms/atoms-component";
import { useRoles } from "features/brm/hooks/useRoles";
import NodeTable from "./tables/NodeTable";
import NodeAddForm from "./forms/NodeAddForm";
import Event from "./crudEvent";

const sysEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;

function Node() {
  const { isRiskAnalyst, isSystemEngineer } = useRoles();

  const projectName = useRecoilValue(projectNameState);
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const queryClient = ReactQuery.useQueryClient();
  const { data: nodeData } = SystemApi.useNodes({
    projectId,
    variantId,
    options: { enabled: isSystemEngineer || isRiskAnalyst },
  });
  const deleteNode = SystemApi.useDeleteNode({
    options: {
      onSuccess: () => {
        setDeleteModalIsOpen(false);
        setSelectedInstance({});
      },
      onError: (error) => {
        console.error("err in delete Promise.all : ", error.message);
      },
    },
  });

  const addNode = () => {
    setModalIsOpen(true);
  };

  function handleDownloadClick() {
    const fileName = `${projectName}_(BOR)_Targets_Guidance.json`;
    const jsonData = nodeData ? nodeData.map((e) => ({ targetName: e.name })) : [];
    dloadJSON(jsonData, fileName);
  }

  useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      await Promise.all(selectedRows.map((each) => deleteNode.mutateAsync({ nodeId: each.id })));
      queryClient.invalidateQueries(SystemApi.nodeKeys.all);
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteNode, queryClient, selectedRows, setSelectedInstance]);

  const nodeHeader = (
    <Title id="Nodes_title">
      Nodes
      <Buttons id="Nodes_Buttons">
        <Event constant={CRUD_EVENT.node} setRefresh={setRefresh} />

        {isSystemEngineer ? (
          <>
            {selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
            <AddButton onClick={addNode} />
          </>
        ) : (
          <br />
        )}
        {isRiskAnalyst && (
          <DownloadButton
            onClick={() => handleDownloadClick()}
            buttonTitle="Download Software Vulnerability (BOR) Target Guidance"
          />
        )}
      </Buttons>
    </Title>
  );

  const nodeMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "Node_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.node}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
            // api={multiDeleteApiCalls()}
          />,
          TYPE.node
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "Node_page",
          modalIsOpen,
          setModalIsOpen,
          <NodeAddForm setModalIsOpen={setModalIsOpen} />,
          TYPE.node
        )}

      <NodeTable
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        refresh={refresh}
        setRefresh={setRefresh}
        accessibleRole={sysEngineerRole}
        // setEnableMessage={setEnableMessage}
      />
    </>
  );
  return <ContentLayout pageId="Node_page" header={nodeHeader} main={nodeMain} />;
}

export default Node;
