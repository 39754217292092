import { COMMON } from "constants/brm";
import { BrmError, DetailTableType } from "features/brm";
import { useQuery } from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";

/**
 * returns assets
 *
 * @param {string} elementId - uuid of any selected element
 * @param {string} elementName - type/category of selected element
 * @param {string} projectId - uuid of project
 * @param {string} variantId - uuid of the variant
 *
 */
export const getElementAssets = async ({ elementId, elementName, projectId }) => {
  const assetsMap = {
    [DetailTableType.PERSONS.key]: (id) => Brm.personApi.getPersonAsset(id),
    [DetailTableType.MISSIONS.key]: (id) => Brm.missionApi.getMissionAsset(id),
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.getCapabilityAsset(id),
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityAsset(id),
    [DetailTableType.DATATYPES.key]: (id) => Brm.dataTypeApi.getDataTypeAsset(id),
    [DetailTableType.DATAFLOWS.key]: (id) => Brm.dataflowApi.getDataFlowAsset(id),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeAsset(id),
    [DetailTableType.CATEGORIZED_ENTITIES.key]: (id) => Brm.categorizedEntityApi.getCategorizedEntityAsset(id),
    [DetailTableType.ASSETS.key]: (id) => Brm.assetApi.findAssetWithHttpInfo(projectId, id),
    [DetailTableType.THREAT_EVENTS.key]: (id) => Brm.threatEventApi.getThreatEventAllAsset(id),
    [DetailTableType.ATTACKS.key]: (id) => Brm.attackApi.getAttackAllAsset(id),
    [DetailTableType.ATTACKERS.key]: (id) => Brm.attackerApi.getAttackerAllAsset(id),
    [DetailTableType.UNDESIRED_EVENTS.key]: (id) => Brm.undesiredEventApi.getUndesiredEventAsset(id),
    [DetailTableType.RISKS.key]: (id) => Brm.riskApi.getRiskAsset(id),
    [DetailTableType.RESOURCES.key]: (id) => Brm.resourceApi.getResourceAsset(id),
  };

  if (assetsMap[elementName]) {
    const data = await assetsMap[elementName](elementId);

    if (!Array.isArray(data)) {
      return data?.id === COMMON.nullUuid ? [] : [data];
    }
    return data;
  }

  throw new BrmError(`Unsupported element type: ${elementName}`);
};

/**
 * custom-hook to retreive all assets of any selected element
 *
 * @param {string} elementId - uuid of any selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of project
 * @param {string} variantId - uuid of variant
 * @param {object} config - configuration of react-query
 * @returns react-query for getting assets
 */
export const useElementAssets = ({ elementId, elementName, projectId, config = {} }) => {
  return useQuery({
    ...config,
    queryKey: ["elementAssets", elementId, elementName, projectId],
    queryFn: () => getElementAssets({ elementId, elementName, projectId }),
  });
};
