/**
 * System Enggineer Help contents -  this is a key value pair mapping help videos to each page , where key is the "contentKey" which is unique to each page
 */

export const SystemEngHelpContents = {
  metadata: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add metadata",
        filePath: "/help-assets/SE/AddMetadata/AddMetadata_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Update metadata by SE",
        filePath: "/help-assets/SE/updateMetadata/updateMetadata_player.html",
      },
    ],
  },

  projectOverview: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Publish an updated system model",
        filePath: "/help-assets/SE/publishAnUpdatedSystemModel/publishAnUpdatedSystemModel_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Import project system model",
        filePath: "/help-assets/SE/ImportProjectSystemModel/ImportProjectSystemModel_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Review system model analysis reports",
        filePath: "/help-assets/SE/ReviewSystemModelAnalysisReport/ReviewSystemModelAnalysisReport_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Configuring UAF and Sysml model",
        filePath: "/help-assets/SE/configuringUafAndSysmlModel/configuringUafAndSysmlModel_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Import a project MBSE system model",
        filePath: "/help-assets/SE/ImportAProjectMBSESystemModel/ImportAProjectMBSESystemModel_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Create save point for project by SE",
        filePath: "/help-assets/SE/createSavePoint/createSavePoint_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Export to AAR document",
        filePath: "/help-assets/SE/exportToAar/exportToAar_player.html",
      },
    ],
  },
  systemsDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Export fact analysis inventory report",
        filePath: "/help-assets/SE/ExportFactAnalysisInventoryReport/ExportFactAnalysisInventoryReport_player.html",
      },
    ],
  },

  systemAssets: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add system asset",
        filePath: "/help-assets/SE/addSytemAsset_SE/addSytemAsset_SE_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit system asset from list",
        filePath: "/help-assets/SE/EditSystemAssetFromList/EditSystemAssetFromList_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Export system asset list",
        filePath: "/help-assets/SE/exportSystemAssetList/exportSystemAssetList_player.html",
      },
    ],
  },
  systemAssetFullDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit system asset from details",
        filePath: "/help-assets/SE/EditSystemAssetFromDetails/EditSystemAssetFromDetails_player.html",
      },
    ],
  },

  systemAssetTypes: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit system asset type from list",
        filePath: "/help-assets/SE/EditSystemAssetTypefromListView/EditSystemAssetTypefromListView_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add System Asset Types by SE",
        filePath: "/help-assets/SE/addSystemAssetTypes/addSystemAssetTypes_player.html",
      },
    ],
  },

  systemAssetTypeFullDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit system asset type from details",
        filePath: "/help-assets/SE/EditSystemAssetTypeFromDetails_SE/EditSystemAssetTypeFromDetails_SE_player.html",
      },
    ],
  },

  entryPoints: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit entry point",
        filePath: "/help-assets/SE/EditEntryPoints/EditEntryPoints_player.html",
      },
    ],
  },

  entryPointsDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit entry point from details",
        filePath: "/help-assets/SE/EditEntryPointFromDetails/EditEntryPointFromDetails_player.html",
      },
    ],
  },

  accessPoints: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit access point",
        filePath: "/help-assets/SE/EditAccessPoint/EditAccessPoint_player.html",
      },
    ],
  },
  accessPointsDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit access point from details",
        filePath: "/help-assets/SE/EditAccessPointFromDetails/EditAccessPointFromDetails_player.html",
      },
    ],
  },
  nodes: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add node to a project system model",
        filePath: "/help-assets/SE/AddANodeToAProjectSystemModel/AddANodeToAProjectSystemModel_player.html",
      },
    ],
  },

  nodeFullDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "View node details by SE",
        filePath: "/help-assets/SE/ViewANodesDetails/ViewANodesDetails_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit node from details",
        filePath: "/help-assets/SE/EditNodeFromDetails/EditNodeFromDetails_player.html",
      },
    ],
  },
  datatypes: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit datatype",
        filePath: "/help-assets/SE/EditDataTypes/EditDataTypes_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add datatype",
        filePath: "/help-assets/SE/addDatatype/addDatatype_player.html",
      },
    ],
  },
  datatypeFullDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit datatype from details",
        filePath: "/help-assets/SE/EditDatatypeFromDetails/EditDatatypeFromDetails_player.html",
      },
    ],
  },

  capabilities: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add capability to project system model",
        filePath: "/help-assets/SE/AddACapabilityToProjectSystemModel/AddACapabilityToProjectSystemModel_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit capability from list ",
        filePath: "/help-assets/SE/EditCapabilityFromList/EditCapabilityFromList_player.html",
      },
    ],
  },

  dataflows: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add dataflow from list",
        filePath: "/help-assets/SE/AddADataflowtoProjectSystemModel/AddADataflowtoProjectSystemModel_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit dataflow from list",
        filePath: "/help-assets/SE/EditDataflowFromList/EditDataflowFromList_player.html",
      },
    ],
  },

  dataflowFullDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit dataflow from details",
        filePath: "/help-assets/SE/EditDataflowFromDetails/EditDataflowFromDetails_player.html",
      },
    ],
  },

  persons: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add person to project system model",
        filePath: "/help-assets/SE/AddAPersonToProjectSystemModel/AddAPersonToProjectSystemModel_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit person from list",
        filePath: "/help-assets/SE/EditPersonFromList/EditPersonFromList_player.html",
      },
    ],
  },

  personFullDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit person from details",
        filePath: "/help-assets/SE/EditPersonFromDetails/EditPersonFromDetails_player.html",
      },
    ],
  },

  resources: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add resource to project system model",
        filePath: "/help-assets/SE/AddAResourceToProjectSystemModel/AddAResourceToProjectSystemModel_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit resource from list",
        filePath: "/help-assets/SE/EditResourceFromList/EditResourceFromList_player.html",
      },
    ],
  },

  resourceFullDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit resource from details",
        filePath: "/help-assets/SE/EditResourceFromDetails/EditResourceFromDetails_player.html",
      },
    ],
  },

  activities: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add activity to project system model",
        filePath: "/help-assets/SE/AddAnActivityToProjectSystemModel/AddAnActivityToProjectSystemModel_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit activity",
        filePath: "/help-assets/SE/EditActivity/EditActivity_player.html",
      },
    ],
  },

  activityFullDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit activity from details",
        filePath: "/help-assets/SE/EditActivityFromDetails/EditActivityFromDetails_player.html",
      },
    ],
  },

  missions: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add mission to project system model",
        filePath: "/help-assets/SE/AddAMissionToProjectSystemModel/AddAMissionToProjectSystemModel_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit a mission",
        filePath: "/help-assets/SE/editAMission/editAMission_player.html",
      },
    ],
  },

  missionFullDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit mission from details",
        filePath: "/help-assets/SE/EditMissionFromDetails/EditMissionFromDetails_player.html",
      },
    ],
  },

  exchanges: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add exchange to project system model",
        filePath: "/help-assets/SE/AddAnExchangeToAProjectSystemModel/AddAnExchangeToAProjectSystemModel_player.html",
      },
    ],
  },

  exchangeFullDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "View an exchanges details",
        filePath: "/help-assets/SE/ViewAnExchangesDetails/ViewAnExchangesDetails_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit exchange from details",
        filePath: "/help-assets/SE/EditExchangeFromDetails/EditExchangeFromDetails_player.html",
      },
    ],
  },
  attackSurfaces: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add attack surface",
        filePath: "/help-assets/SE/AddAttackSurfacev2/AddAttackSurfacev2_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit attack surface",
        filePath: "/help-assets/SE/EditAttackSurface/EditAttackSurface_player.html",
      },
    ],
  },

  attackSurfacesDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit attack surface from details",
        filePath: "/help-assets/SE/EditAttackSurfaceFromDetails/EditAttackSurfaceFromDetails_player.html",
      },
    ],
  },

  attackVectors: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit attack vector",
        filePath: "/help-assets/SE/EditAttackVector/EditAttackVector_player.html",
      },
    ],
  },

  attackVectorsDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit attack vector from details",
        filePath: "/help-assets/SE/EditAttackVectorFromDetails/EditAttackVectorFromDetails_player.html",
      },
    ],
  },

  capabilityFullDetails: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit capability from details",
        filePath: "/help-assets/SE/EditCapabilityFromDetails/EditCapabilityFromDetails_player.html",
      },
    ],
  },

  categorizedEntitiesOverview: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "View categorized entity details",
        filePath: "/help-assets/SE/ViewCategorizedEntityDetails/ViewCategorizedEntityDetails_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add categorization to categorized entity",
        filePath:
          "/help-assets/SE/AddCategorizationToCategorizedEntity/AddCategorizationToCategorizedEntity_player.html",
      },

      {
        role: "Systems Engineer",
        type: "Video",
        name: "Delete categorization from categorized entity",
        filePath:
          "/help-assets/SE/DeleteCategorizationFromCategorizedEntity/DeleteCategorizationFromCategorizedEntity_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit categorized entity from details",
        filePath: "/help-assets/SE/EditCategorizedEntityFromDetails/EditCategorizedEntityFromDetails_player.html",
      },
    ],
  },

  categorizedEntities: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add categorized entity",
        filePath: "/help-assets/SE/AddCategorizedEntity/AddCategorizedEntity_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit categorized entity from list",
        filePath: "/help-assets/SE/EditCategorizedEntityFromList/EditCategorizedEntityFromList_player.html",
      },
    ],
  },

  securityObjectivesOverview: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "View security objective details ",
        filePath: "/help-assets/SE/ViewSecurityObjectiveDetails_SE/ViewSecurityObjectiveDetails_SE_player.html",
      },
    ],
  },

  systemDiagram: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Manually create system diagram",
        filePath: "/help-assets/SE/manuallyCreateSystemDiagram/manuallyCreateSystemDiagram_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "System diagram toolbar buttons",
        filePath: "/help-assets/SE/systemDiagramToolbarButtons/systemDiagramToolbarButtons_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "View projects in system diagram",
        filePath: "/help-assets/SE/ViewAProjectsSystemDiagram/ViewAProjectsSystemDiagram_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add person from system diagram",
        filePath: "/help-assets/SE/AddAPersonFromSystemDiagram/AddAPersonFromSystemDiagram_player.html",
      },

      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add dataflow from system diagram",
        filePath: "/help-assets/SE/AddDataflowFromSystemDiagram/AddDataflowFromSystemDiagram_player.html",
      },

      {
        role: "Systems Engineer",
        type: "Video",
        name: "View dataflows",
        filePath: "/help-assets/SE/ViewDataflows/ViewDataflows_player.html",
      },

      {
        role: "Systems Engineer",
        type: "Video",
        name: "View datatypes",
        filePath: "/help-assets/SE/ViewDatatypes/ViewDatatypes_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "View node properties",
        filePath: "/help-assets/SE/ViewNodeProperties/ViewNodeProperties_player.html",
      },

      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add exchange between nodes",
        filePath: "/help-assets/SE/AddExchangebetweennodesv2/AddExchangebetweennodesv2_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "View node details in system diagram",
        filePath: "/help-assets/SE/ViewNodeDetailsInSD/ViewNodeDetailsInSD_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Create new node",
        filePath: "/help-assets/SE/CreateANewNode/CreateANewNode_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Edit exchange datatype in properties panel",
        filePath:
          "/help-assets/SE/EditExchangeDatatypeInPropertiesPanel/EditExchangeDatatypeInPropertiesPanel_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Map icons nodes initial import ",
        filePath: "/help-assets/SE/MapIconsNodesInitialImportSD/MapIconsNodesInitialImportSD_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Update datatype classification from system diagram ",
        filePath:
          "/help-assets/SE/UpdateDatatypeClassificationFromSystemDiagram/UpdateDatatypeClassificationFromSystemDiagram_player.html",
      },
    ],
  },

  contextDiagram: {
    contents: [
      {
        role: "Systems Engineer",
        type: "Video",
        name: "View context diagram by SE",
        filePath: "/help-assets/SE/viewContextDiagram/viewContextDiagram_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Add image to context diagram",
        filePath: "/help-assets/SE/AddImageToContextDiagram/addImageToContextDiagram_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Filter context diagram by SE",
        filePath: "/help-assets/SE/filteringContextDiagram/filteringContextDiagram_player.html",
      },
      {
        role: "Systems Engineer",
        type: "Video",
        name: "Highlight entry points related to external access points by SE",
        filePath:
          "/help-assets/SE/highlightEntryPointsRelatedToExternalAccessPoints/highlightEntryPointsRelatedToExternalAccessPoints_player.html",
      },
    ],
  },
};
