import { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "libs/react-query";
import toast from "react-hot-toast";

// State
import { projectIdState } from "atoms/atoms-admin";
import {
  likelihoodOptionsState,
  nodeSelectedFiltersState,
  exchangeSelectedFiltersState,
  attackerSelectedFiltersState,
  trevPatternSelectedFiltersState,
  tacticsSelectedFiltersState,
  likelihoodSelectedFiltersState,
  calibratedLikelihoodSelectedFiltersState,
  filteredAttackLikelihoodCalibrationState,
  attackListState,
} from "atoms/atoms-likelihood-calibration";
// import { riskUpdatedState } from "atoms/atoms-risk";
import { variantIdState } from "atoms/atoms-component";
import { useRecoilState, useRecoilValue } from "recoil";
// Components
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { BrmMainTable } from "brm/tables/BrmTables";
import Select from "react-select";
// Services
import * as BrmGql from "generated/graphql";
import { SystemApi, RiskApi, useRoles } from "features/brm";
import { RoutePath } from "routes/route-paths";
// import getReactSelectArray from "utils/react-select-array";
// Constants
import { COMMON, TYPE } from "constants/brm";
import { createColumnLikelihoodMapped, createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
// Styles
import FilterIcon from "assets/icons/filter.svg";
import NavListIconStyled from "components/elements/NavListIconStyled";
import CustomTable from "components/elements/CustomTableDivStyled";
import { calibrationStyle } from "components/elements/ReactSelectCustomStyles";
import Title from "components/elements/StyledTitle";
import { createOptionsFromSrc, createOptionsFromProps } from "utils/react-select-utils";
import { sortByLabel, sortByName } from "utils/sorting";
import { ClearLikelihoodLevelModal } from "../../components/modals/ClearLikelihoodLevelModal";
import { SetLikelihoodLevelModal } from "../../components/modals/SetLikelihoodLevelModal";
import S from "../styles/calibration.styles";
import {
  useAttackers,
  useAttackTactics,
  // useGetAttacks,
  useAttacks,
  useTrevPattern,
} from "../../api/likelihood-calibration-queries";

import { LEVEL } from "../../constants";

// Likelihood level adjust through means and opportunity
export const LikelihoodCalibration = () => {
  const { isRiskAnalyst } = useRoles();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const [, setAttackList] = useRecoilState(attackListState);
  const filteredAttackList = useRecoilValue(filteredAttackLikelihoodCalibrationState);
  const likelihoodOptions = useRecoilValue(likelihoodOptionsState);
  const [selectedTrevs, setSelectedTrevs] = useRecoilState(trevPatternSelectedFiltersState);
  const [selectedAttackTactics, setSelectedAttackTactics] = useRecoilState(tacticsSelectedFiltersState);
  const [selectedAttackers, setSelectedAttackers] = useRecoilState(attackerSelectedFiltersState);
  const [selectedNodes, setSelectedNodes] = useRecoilState(nodeSelectedFiltersState);
  const [selectedExchanges, setSelectedExchanges] = useRecoilState(exchangeSelectedFiltersState);
  const [selectedLikelihoods, setSelectedLikelihoods] = useRecoilState(likelihoodSelectedFiltersState);
  const [selectedCalibratedLikelihoods, setSelectedCalibratedLikelihoods] = useRecoilState(
    calibratedLikelihoodSelectedFiltersState
  );

  const [selectedRows, setSelectedRows] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [clearModalIsOpen, setClearModalIsOpen] = useState(false);

  const numOfActiveFilters = useMemo(() => {
    let total = 0;

    const filters = [
      selectedTrevs.length,
      selectedAttackTactics.length,
      selectedAttackers.length,
      selectedNodes.length,
      selectedExchanges.length,
      selectedLikelihoods.length,
      selectedCalibratedLikelihoods,
    ];

    filters.forEach((f) => {
      if (f > 0) {
        total += 1;
      }
    });
    return total;
  }, [
    selectedTrevs,
    selectedAttackTactics,
    selectedAttackers,
    selectedNodes,
    selectedExchanges,
    selectedLikelihoods,
    selectedCalibratedLikelihoods,
  ]);

  const { data: attacksList, isError: isAttacksListError } = useAttacks(projectId, variantId, {
    enabled: !!projectId && isRiskAnalyst,
    onSuccess: (data) => {
      setAttackList(data.sort(sortByName));
    },
  });

  const { data: nodesListOptions, isError: isNodesListError } = SystemApi.useNodesWithRisk({
    projectId,
    variantId,
    options: {
      enabled: showFilters && isRiskAnalyst,
      select: useCallback(
        (data) => createOptionsFromProps({ src: data, value: "id", label: "name" }).sort(sortByLabel),
        []
      ),
    },
  });

  const { data: exchangeListOptions, isError: isExchangeListError } = SystemApi.useExchangesWithRisk({
    projectId,
    variantId,
    options: {
      enabled: showFilters && isRiskAnalyst,
      select: useCallback(
        (data) => createOptionsFromProps({ src: data, value: "id", label: "name" }).sort(sortByLabel),
        []
      ),
    },
  });

  // const { data: trevPatternList, isError: isTrevPatternListError } = useTrevPattern(projectId);
  const { data: trevPatternOptions, isError: isTrevPatternListError } = useTrevPattern(projectId, {
    enabled: !!projectId && showFilters && isRiskAnalyst,
    select: useCallback((data) => createOptionsFromSrc({ src: data }), []),
  });

  // const { data: attackTactic, isError: isAtatckTacticError } = useAttacktactics(projectId);
  const { data: attackTacticOptions, isError: isAtatckTacticError } = useAttackTactics(projectId, {
    enabled: !!projectId && showFilters && isRiskAnalyst,
    select: useCallback((data) => createOptionsFromSrc({ src: data }), []),
  });

  // const { data: attackersList, isError: isAttackersListError } = useAttackers(projectId);
  const { data: attackersListOptions, isError: isAttackersListError } = useAttackers(projectId, {
    enabled: !!projectId && showFilters && isRiskAnalyst,
    select: useCallback(
      (data) => createOptionsFromProps({ src: data, value: "id", label: "name" }).sort(sortByLabel),
      []
    ),
  });

  const { mutate: setAttackLikelihood } = BrmGql.useSetAttackLikelihoodMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(RiskApi.attackKeys.likelihoodCalibrationAttacks(projectId, variantId));
    },
    onError: () => {
      toast.error("Error occured while setting likelihood level", {
        duration: 4000,
      });
    },
  });

  const columns = useMemo(
    () => [
      createColumnMappedNoEdit("attack"),
      createColumnMappedNoEdit("systemasset"),
      createColumnLikelihoodMapped("calclikelihood"),
      createColumnLikelihoodMapped("calibratedlikelihood"),
    ],
    []
  );

  // Switch pages if variant is null
  useEffect(() => {
    if (variantId === COMMON.nullUuid) {
      navigate(RoutePath.Projects);
    }
  }, [variantId, navigate]);

  const handleAttackersSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedAttackers(items);
  };
  const handleTacticSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedAttackTactics(items);
  };
  const handleTrevSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedTrevs(items);
  };
  const handleNodeSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedNodes(items);
  };
  const handleExchangeSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedExchanges(items);
  };

  const handleLikelihoodSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedLikelihoods(items);
  };

  const handleCalibratedLikelihoodSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedCalibratedLikelihoods(items);
  };

  const setLevel = () => {
    setModalIsOpen(true);
  };

  const clearLevel = () => {
    setClearModalIsOpen(true);
  };

  const modifyLevel = (levelShift) => {
    const attackIds = selectedRows.map((each) => each.id);
    setAttackLikelihood(
      { ids: attackIds, project: projectId, variant: variantId, calibratedStringValue: levelShift },
      {
        onSuccess: () => {
          toast.success("Likelihood Level set successfully", { duration: 4000 });
        },
        enabled: !!projectId && !!variantId && !!attackIds,
      }
    );
  };

  // useEffect(() => {
  //   console.log("attacksList", attacksList);
  // }, [attacksList]);

  // useEffect(() => {
  //   console.log("filteredAttackList", filteredAttackList);
  // }, [filteredAttackList]);

  if (
    isAtatckTacticError ||
    isAttackersListError ||
    isAttacksListError ||
    isExchangeListError ||
    isNodesListError ||
    isTrevPatternListError
  ) {
    return <ErrorBanner msg="Error while loading Attacks Data" />;
  }

  const isSelectedRowsEmpty = selectedRows.length === 0;

  return (
    <>
      <Title>Likelihood Calibration</Title>
      {modalIsOpen && (
        <SetLikelihoodLevelModal
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          selectedRows={selectedRows}
        />
      )}
      {clearModalIsOpen && (
        <ClearLikelihoodLevelModal
          modalIsOpen={clearModalIsOpen}
          setModalIsOpen={setClearModalIsOpen}
          selectedRows={selectedRows}
        />
      )}
      <S.FilterSection>
        {/* Buttons */}
        <S.CalibrateButtons
          id="LikelihoodCalibration_increaseLevelButton"
          onClick={() => modifyLevel(LEVEL.increase)}
          disabled={isSelectedRowsEmpty}
        >
          Increase Level
        </S.CalibrateButtons>
        <S.CalibrateButtons
          id="LikelihoodCalibration_decreaseLevelButton"
          onClick={() => modifyLevel(LEVEL.decrease)}
          disabled={isSelectedRowsEmpty}
        >
          Decrease Level
        </S.CalibrateButtons>
        <S.CalibrateButtons id="LikelihoodCalibration_setLevelButton" onClick={setLevel} disabled={isSelectedRowsEmpty}>
          Set Level
        </S.CalibrateButtons>
        <S.CalibrateButtons
          id="LikelihoodCalibration_clearCalibrationButton"
          onClick={clearLevel}
          disabled={isSelectedRowsEmpty}
        >
          Clear Calibration
        </S.CalibrateButtons>

        {/* Filters */}
        <S.ShowFilterButton id="LikelihoodCalibration_filterButton" onClick={() => setShowFilters(!showFilters)}>
          <NavListIconStyled src={FilterIcon} alt="Filter" />
          Filters {numOfActiveFilters > 0 ? `(${numOfActiveFilters})` : ""}
        </S.ShowFilterButton>

        <br />
        {showFilters ? (
          <>
            {/* Threat Event Pattern */}
            <S.SelectDiv>
              <S.SelectLabel>
                Threat Event Patterns
                <Select
                  isMulti
                  value={selectedTrevs}
                  options={trevPatternOptions}
                  defaultValue={selectedTrevs}
                  onChange={handleTrevSelectChange}
                  id="LikelihoodCalibration_trevDropdown"
                  classNamePrefix="trevDropdown"
                  styles={calibrationStyle}
                />
              </S.SelectLabel>
            </S.SelectDiv>
            {/* Attack Tactic */}
            <S.SelectDiv>
              <S.SelectLabel>
                Attack Tactic
                <Select
                  isMulti
                  options={attackTacticOptions}
                  value={selectedAttackTactics}
                  defaultValue={selectedAttackTactics}
                  onChange={handleTacticSelectChange}
                  id="LikelihoodCalibration_attackTacticDropdown"
                  classNamePrefix="attackTacticDropdown"
                  styles={calibrationStyle}
                />
              </S.SelectLabel>
            </S.SelectDiv>
            {/* Attackers */}
            <S.SelectDiv>
              <S.SelectLabel>
                Attackers
                <Select
                  isMulti
                  options={attackersListOptions}
                  value={selectedAttackers}
                  defaultValue={selectedAttackers}
                  onChange={handleAttackersSelectChange}
                  id="LikelihoodCalibration_attackerDropdown"
                  classNamePrefix="attackerDropdown"
                  styles={calibrationStyle}
                />
              </S.SelectLabel>
            </S.SelectDiv>

            {/* Nodes */}
            <S.SelectDiv>
              <S.SelectLabel>
                Node
                <Select
                  isMulti
                  options={nodesListOptions}
                  value={selectedNodes}
                  defaultValue={selectedNodes}
                  onChange={handleNodeSelectChange}
                  id="LikelihoodCalibration_nodeDropdown"
                  classNamePrefix="nodeDropdown"
                  styles={calibrationStyle}
                />
              </S.SelectLabel>
            </S.SelectDiv>
            {/* Exchanges */}
            <S.SelectDiv>
              <S.SelectLabel>
                Exchanges
                <Select
                  isMulti
                  options={exchangeListOptions}
                  value={selectedExchanges}
                  defaultValue={selectedExchanges}
                  onChange={handleExchangeSelectChange}
                  id="LikelihoodCalibration_exchangeDropdown"
                  classNamePrefix="exchangeDropdown"
                  styles={calibrationStyle}
                />
              </S.SelectLabel>
            </S.SelectDiv>
            {/* Likelihood */}
            <S.SelectDiv>
              <S.SelectLabel>
                Likelihood
                <Select
                  isMulti
                  options={likelihoodOptions}
                  value={selectedLikelihoods}
                  defaultValue={selectedLikelihoods}
                  onChange={handleLikelihoodSelectChange}
                  id="LikelihoodCalibration_likelihoodDropdown"
                  classNamePrefix="likelihoodDropdown"
                  styles={calibrationStyle}
                />
              </S.SelectLabel>
            </S.SelectDiv>

            {/* Calibrated Likelihood */}
            <S.SelectDiv>
              <S.SelectLabel>
                Calibrated Likelihood
                <Select
                  isMulti
                  options={likelihoodOptions}
                  value={selectedCalibratedLikelihoods}
                  defaultValue={selectedCalibratedLikelihoods}
                  onChange={handleCalibratedLikelihoodSelectChange}
                  id="LikelihoodCalibration_calibratedLikelihoodDropdown"
                  classNamePrefix="calibratedLikelihoodDropdown"
                  styles={calibrationStyle}
                />
              </S.SelectLabel>
            </S.SelectDiv>
          </>
        ) : (
          <></>
        )}
      </S.FilterSection>
      {attacksList && attacksList[0]?.likelihood?.calcStringValue === null && (
        <S.Note>
          <em>
            <b>Note</b>
          </em>{" "}
          : You must click <b>Calculate Risks</b> button prior to making any adjustments
        </S.Note>
      )}

      {attacksList && filteredAttackList ? (
        <CustomTable>
          <BrmMainTable
            data={filteredAttackList}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "LikelihoodCalibration_table" }}
            elementName={TYPE.attack}
            dataLength={attacksList.length}
            showRowSelect={attacksList[0]?.likelihood?.calcStringValue !== null}
          />
        </CustomTable>
      ) : (
        <Loading />
      )}
    </>
  );
};
