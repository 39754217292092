import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { COMMON } from "constants/brm";
import * as KeyFactory from "./exchange-query-key-factory";

/**
 * Retrieve datatype for an exchange
 *
 * @param {string} exchangeId - uuid of exchange
 * @returns list of all persons within the given exchange
 */
export const getExchangeConsumingActivity = async ({ exchangeId }: { exchangeId: string | null }) => {
  const data = await Brm.exchangeApi.getExchangeActConsumer(exchangeId);
  if (!Array.isArray(data)) {
    return data?.id === COMMON.nullUuid ? [] : [data];
  }
  return data || [];
};

interface IExchangeDataProps {
  exchangeId: string | null;
  options?: QueryOption<typeof getExchangeConsumingActivity>;
}

/**
 * Custom-hook to retrieve consuming activity for an exchange.
 *
 * @param {string} exchangeId - uuid of exchange
 * @param {object} options - react-query configuration object
 * @returns react-query for consumingactivity
 */
export const useExchangeConsumingActivity = ({ exchangeId, options = {} }: IExchangeDataProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exchangeKeys.actConsumer(exchangeId),
    queryFn: () => getExchangeConsumingActivity({ exchangeId }),
  });
};
