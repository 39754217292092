import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { ErrorBanner, LoadingSpinner } from "components/elements";
// Components
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import ButtonCell from "components/EditComponents/ButtonCell";
import EntryPointDetails from "brm/system-model/access/details/EntryPointDetails";
// Services
import { attackSurfaceApi } from "services/brm/access-service";
import { SystemApi } from "features/brm";
// Styles
import Title from "components/elements/StyledTitle";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
// State
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
// Constants
import { COMMON, TYPE } from "constants/brm";
// Hooks
import useRedirectDetailsPage from "hooks/useRedirectDetailsPage";
import { useRoles } from "features/brm/hooks/useRoles";
// Table Columns
import {
  createColumnMapped,
  createColumnCategoryMapped,
  createColumnMappedNoEdit,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
// Queries
import * as ACQueries from "brm/system-model/access/access-queries";
import { exchangeApi, nodeApi } from "services/brm";
import { queryClient } from "libs/react-query";
import { RoutePath } from "routes/route-paths";

function EntryPointFullDetails() {
  const { id } = useParams();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState<any>({});
  const [epData, setEpData] = useState<any>([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  const updatedCategory = useRef("");
  const updatedSurface = useRef("");
  let content = null;

  const {
    data: entryPoint,
    isLoading,
    isError: entryPointError,
  } = SystemApi.useGetEntryPointById({ entryPointId: id, options: { enabled: !!id } });
  const { data: attackSurfaces, isError: attackSurfacesError } = SystemApi.useAttackSurfaces({
    projectId,
    options: { enabled: !!projectId },
  });
  const { data: entryPointCategories } = ACQueries.useEntryPointCategories(projectId);

  const { mutate: setEntryPointCategory } = SystemApi.useSetEntryPointCategory({
    projectId,
    options: {
      onSettled: () => {
        updatedCategory.current = "";
      },
      onSuccess: () => {
        queryClient.invalidateQueries(SystemApi.entryPointKeys.all);
      },
    },
  });

  const { mutate: setEntryPointSurface } = SystemApi.useSetEntryPointSurface({
    projectId,
    options: {
      onSettled: () => {
        updatedSurface.current = "";
      },
      onSuccess: () => {
        queryClient.invalidateQueries(SystemApi.entryPointKeys.all);
      },
    },
  });

  const createButton = useCallback(
    (cellProps: any) => {
      if (isSystemEngineer || isRiskAnalyst) {
        return (
          <ButtonCell
            selectedRowId={selectedRowId}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedCategory.current !== "") {
                setEntryPointCategory({ entryPointId: selectedRowId, category: updatedCategory.current });
              }

              if (updatedSurface.current !== "") {
                setEntryPointSurface({ entryPointId: selectedRowId, surface: updatedSurface.current });
              }
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return COMMON.na;
    },

    [isSystemEngineer, isRiskAnalyst, selectedRowId, editMode, setEntryPointCategory, setEntryPointSurface]
  );

  const columns = useMemo(
    () => [
      createColumnCategoryMapped("category", selectedRowId, entryPointCategories, editMode, updatedCategory),
      createColumnMappedNoEdit("node"),
      createColumnMappedNoEdit("exchange"),
      createColumnMapped("surface", selectedRowId, attackSurfaces, editMode, updatedSurface),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [editMode, selectedRowId, entryPointCategories, attackSurfaces, createButton]
  );

  useRedirectDetailsPage(projectId, localProjectId, RoutePath.EntryPoints);

  useEffect(() => {
    const getData = async () => {
      const dataToDisplay = { ...entryPoint };
      if (entryPoint.node) {
        dataToDisplay.node = await nodeApi.getNodeByID(entryPoint.node);
      }
      if (entryPoint.exchange) {
        dataToDisplay.exchange = await exchangeApi.getExchangeByID(entryPoint.exchange);
      }
      if (entryPoint.surface) {
        dataToDisplay.surface = await attackSurfaceApi.getAttackSurfaceByID(entryPoint.surface);
      }
      setSelectedElement(dataToDisplay);
      setEpData([dataToDisplay]);
    };
    if (entryPoint) {
      getData();
    }
  }, [entryPoint]);

  if (entryPointError) {
    return <ErrorBanner msg="Error while loading Entry Point data." />;
  }

  if (attackSurfacesError) {
    return <ErrorBanner msg="Error while loading Attack Surfaces data." />;
  }

  if (isLoading) {
    content = <LoadingSpinner />;
  }

  if (entryPoint && epData && selectedElement) {
    content = (
      <>
        <Title id="EntryPointFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={epData}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "EntryPointFullDetails_table" }}
              elementName={TYPE.entryPoint}
            />
          </DetailsContainerSingleTable>
        ) : (
          <></>
        )}

        {selectedElement.id && (
          <DetailsContainerParent>
            <DetailsContainer id="EntryPointFullDetails_detailsPanel">
              <EntryPointDetails selectedElement={selectedElement} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
  }

  return content;
}

export default EntryPointFullDetails;
