import { COMMON } from "constants/brm";
import { RISK_LEVELS } from "features/risk-model";
import { Select } from "./styles/tables.styles";

const SelectRiskColumnFilter = ({ column: { setFilter } }: any) => (
  <Select
    id="custom-select"
    onChange={(e) => {
      if (e.target.value === "all") e.target.value = "";
      const values = e.target.value === "" ? [] : e.target.value.split(",") || undefined;
      setFilter(values);
    }}
  >
    <option value="">All</option>
    <option value={RISK_LEVELS.veryLowValues}>{COMMON.veryLow}</option>
    <option value={RISK_LEVELS.lowValues}>{COMMON.low}</option>
    <option value={RISK_LEVELS.moderateValues}>{COMMON.moderate}</option>
    <option value={RISK_LEVELS.highValues}>{COMMON.high}</option>
    <option value={RISK_LEVELS.veryHighValues}>{COMMON.veryHigh}</option>
  </Select>
);

export default SelectRiskColumnFilter;
