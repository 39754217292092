import { useState, useMemo, useRef, useCallback } from "react";
import toast from "react-hot-toast";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable, RetainStringColumnFilter } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { TYPE } from "constants/brm";
import { SystemApi } from "features/brm";
import { useCanRename } from "features/system-model";
import * as SysFactsApi from "brm/system-model/system-facts/api";

import {
  createColumnName,
  createColumnBooleanMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";

const DatatypeTable = ({ setSelectedRows }) => {
  const { canRename } = useCanRename();
  const projectId = useRecoilValue(projectIdState);
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedCategory = useRef("");
  const updatedIsInternal = useRef("");
  const updatedIsInput = useRef("");
  const updatedIsOutput = useRef("");
  const updatedIsStored = useRef("");
  const updatedIsProcessed = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const { data: elementCats, isError: isElementCatsError } = SystemApi.useElementCategories({
    projectId,
    options: {
      enabled: isSystemEngineer || isRiskAnalyst,
    },
  });
  const { data: datatypes, isError: isDatatypesError } = SystemApi.useDataTypes({
    projectId,
    options: {
      enabled: isSystemEngineer || isRiskAnalyst,
    },
  });

  const { mutate: setDataTypeName } = SysFactsApi.useSetDataTypeName({
    projectId,
    config: {
      onSettled: () => {
        setSelectedInstance({
          id: selectedInstance.id,
          name: updatedName.current,
          type: selectedInstance.type,
        });
        updatedName.current = "";
      },
      onError: (err) => {
        toast.error(`Setting datatype name caused an error: ${err}`);
      },
    },
  });
  const { mutate: setDataTypeCategory } = SysFactsApi.useSetDataTypeCategory({
    projectId,
    config: {
      onSettled: () => {
        updatedCategory.current = "";
      },
      onError: (err) => {
        toast.error(`Setting datatype category caused an error: ${err}`);
      },
    },
  });
  const { mutate: setDataTypeIsInternal } = SysFactsApi.useSetDataTypeIsInternal({
    projectId,
    config: {
      onSettled: () => {
        updatedIsInternal.current = "";
      },
      onError: (err) => {
        toast.error(`Setting datatype internal caused an error: ${err}`);
      },
    },
  });
  const { mutate: setDataTypeIsInput } = SysFactsApi.useSetDataTypeIsInput({
    projectId,
    config: {
      onSettled: () => {
        updatedIsInput.current = "";
      },
      onError: (err) => {
        toast.error(`Setting datatype input caused an error: ${err}`);
      },
    },
  });
  const { mutate: setDataTypeIsOutput } = SysFactsApi.useSetDataTypeIsOutput({
    projectId,
    config: {
      onSettled: () => {
        updatedIsOutput.current = "";
      },
      onError: (err) => {
        toast.error(`Setting datatype output caused an error: ${err}`);
      },
    },
  });
  const { mutate: setDataTypeIsStored } = SysFactsApi.useSetDataTypeIsStored({
    projectId,
    config: {
      onSettled: () => {
        updatedIsStored.current = "";
      },
      onError: (err) => {
        toast.error(`Setting datatype stored caused an error: ${err}`);
      },
    },
  });
  const { mutate: setDataTypeIsProcessed } = SysFactsApi.useSetDataTypeIsProcessed({
    projectId,
    config: {
      onSettled: () => {
        updatedIsProcessed.current = "";
      },
      onError: (err) => {
        toast.error(`Setting datatype processed caused an error: ${err}`);
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedName.current !== "") {
              if (!canRename({ updatedName, elementsList: datatypes })) return;
              setDataTypeName({ dataTypeId: selectedInstance.id, name: updatedName.current });
            }

            if (updatedIsInternal.current !== "") {
              setDataTypeIsInternal({ dataTypeId: selectedInstance.id, isInternal: updatedIsInternal.current });
            }

            if (updatedIsInput.current !== "") {
              setDataTypeIsInput({ dataTypeId: selectedInstance.id, isInput: updatedIsInput.current });
            }

            if (updatedIsOutput.current !== "") {
              setDataTypeIsOutput({ dataTypeId: selectedInstance.id, isOutput: updatedIsOutput.current });
            }

            if (updatedIsStored.current !== "") {
              setDataTypeIsStored({ dataTypeId: selectedInstance.id, isStored: updatedIsStored.current });
            }

            if (updatedIsProcessed.current !== "") {
              setDataTypeIsProcessed({ dataTypeId: selectedInstance.id, isProcessed: updatedIsProcessed.current });
            }

            if (updatedCategory.current !== "") {
              setDataTypeCategory({ dataTypeId: selectedInstance.id, category: updatedCategory.current });
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [
      selectedInstance.id,
      editMode,
      canRename,
      datatypes,
      setDataTypeName,
      setDataTypeIsInternal,
      setDataTypeIsInput,
      setDataTypeIsOutput,
      setDataTypeIsStored,
      setDataTypeIsProcessed,
      setDataTypeCategory,
    ]
  );

  const checkNameUniqueness = useCallback(
    (newName) => {
      const trimedNamed = newName.trim();
      return isSystemEngineer && Array.isArray(datatypes)
        ? !datatypes.map((datatype) => datatype.name.toLowerCase()).includes(trimedNamed.toLowerCase())
        : false;
    },
    [isSystemEngineer, datatypes]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnName(
          selectedInstance.id,
          setSelectedInstance,
          editMode,
          updatedName,
          "datatype",
          checkNameUniqueness,
          {
            Filter: RetainStringColumnFilter,
          }
        ),
        createColumnCategoryMapped("category", selectedInstance.id, elementCats, editMode, updatedCategory),
        createColumnBooleanMapped("isInternal", selectedInstance.id, editMode, updatedIsInternal),
        createColumnBooleanMapped("isInput", selectedInstance.id, editMode, updatedIsInput),
        createColumnBooleanMapped("isOutput", selectedInstance.id, editMode, updatedIsOutput),
        createColumnBooleanMapped("isStored", selectedInstance.id, editMode, updatedIsStored),
        createColumnBooleanMapped("isProcessed", selectedInstance.id, editMode, updatedIsProcessed),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "datatype"),
      createColumnCategoryMapped("category", selectedInstance.id, elementCats, false, updatedCategory),
      createColumnBooleanMapped("isInternal", selectedInstance.id, false, updatedIsInternal),
      createColumnBooleanMapped("isInput", selectedInstance.id, false, updatedIsInput),
      createColumnBooleanMapped("isOutput", selectedInstance.id, false, updatedIsOutput),
      createColumnBooleanMapped("isStored", selectedInstance.id, false, updatedIsStored),
      createColumnBooleanMapped("isProcessed", selectedInstance.id, false, updatedIsProcessed),
    ];
  }, [
    isSystemEngineer,
    selectedInstance.id,
    setSelectedInstance,
    elementCats,
    editMode,
    checkNameUniqueness,
    createButton,
  ]);

  if (isElementCatsError || isDatatypesError) {
    return <ErrorBanner msg="Error while loading Datatypes data." />;
  }

  if (datatypes && elementCats) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={datatypes}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "DataType_table" }}
            elementName={TYPE.datatype}
            showRowSelect={isSystemEngineer}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

DatatypeTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

export default DatatypeTable;
