import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

export const getAttackerCategories = ({ projectId }) => {
  return Brm.attackerCategoryApi.findAttackerCategory(projectId);
};

/**
 * React-query hook for getting all Attacker categories
 *
 * @param {string} projectId - uuid of the project
 * @returns react-query for getAttackerCategories
 */
export const useAttackerCategories = ({ projectId, config = {} }) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["attackerCategories", projectId],
    queryFn: () => getAttackerCategories({ projectId }),
  });
};
