import * as S from "./StepConnector.styles";
import { useStepperContext } from "./StepperContext";

export const StepConnector = () => {
  const { orientation } = useStepperContext();

  return (
    <S.StepConnectorContainer orientation={orientation}>
      <S.StepConnectorLine orientation={orientation} />
    </S.StepConnectorContainer>
  );
};
