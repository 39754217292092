import styled from "styled-components";

const FormStyled = styled.div`
  /* font: Arial, Helvetica, sans-serif; */
  max-width: 680px;
  margin: 10px auto;
  padding: 10px;
  background: ${(props) => props.theme.formbackground};
  border-radius: 5px;
  /* border-bottom: 1px solid #ccc; */
  /* border-radius: 5px; */
  .form-style input[type="text"],
  .form-style input[type="text"] {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  ,
  .form-style textarea,
  .form-style select {
    transition: all 0.3s ease-in-out;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    /* padding: 3%; */
    /* background: white; */
    /* font: 95% Arial, Helvetica, sans-serif; */
  }
  .form-style textarea:focus,
  .form-style select:focus {
    box-shadow: 0 0 5px #2684ff;
    /* padding: 3%; */
    border: 1px solid #2684ff;
  }
`;

export default FormStyled;
