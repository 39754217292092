import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./threat-group-query-key-factory";

/**
 * Returns threat group long dto
 *
 * @param {string} threatGroupId - uuid of the threat group
 */
export const getThreatGroup = ({ threatGroupId }: { threatGroupId: string }) => {
  return Brm.threatGroupApi.getThreatGroupByID(threatGroupId);
};

interface IUseThreatGroup {
  threatGroupId: string;
  options?: QueryOption<typeof getThreatGroup>;
}

/**
 * React-query hook for getting all details of threat group
 *
 * @param {string} threatGroupId - uuid of the threat group
 * @returns react-query for getThreatGroup
 */
export const useThreatGroup = ({ threatGroupId, options = {} }: IUseThreatGroup) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatGroupKeys.group(threatGroupId),
    queryFn: () => getThreatGroup({ threatGroupId }),
  });
};
