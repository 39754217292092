import styled from "styled-components";
import Card from "react-bootstrap/Card";

export const Container = styled.div`
  line-height: 0.75;
`;

export const StyledCardBody = styled(Card.Body)`
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledCard = styled(Card)`
  background-color: rgb(244, 245, 247);
`;
