import * as Recoil from "recoil";
import { userIdState, projectIdState, userRoleState } from "atoms";
import useCheckUserPermission from "hooks/useCheckUserPermission";

export const useUserCheckPermissions = (contentKey: string) => {
  const userId = Recoil.useRecoilValue(userIdState);
  const projectId = Recoil.useRecoilValue(projectIdState);
  const userRole = Recoil.useRecoilValue(userRoleState);
  useCheckUserPermission(userId, projectId, userRole, contentKey);
};
