import { useState } from "react";
// Styles
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

// Local
import RiskCategoryTable from "./tables/RiskCategoryTable";

function RiskCategory() {
  const [, setSelectedRows] = useState([]);

  const riskCatHeader = <Title id="RiskCategory_title">Risk Categories</Title>;

  const riskCatMain = <RiskCategoryTable setSelectedRows={setSelectedRows} />;

  return <ContentLayout pageId="RiskCategory_page" header={riskCatHeader} main={riskCatMain} />;
}

export default RiskCategory;
