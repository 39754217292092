import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./knowledgebase-query-key-factory";

/**
 * Returns all kb
 *
 * @returns a kb
 */
export const getAllKbs = () => {
  return Brm.kbApi.findKB();
};

interface IUseAllKb {
  options?: QueryOption<typeof getAllKbs>;
}
/**
 * Returns all Kb's .
 *
 * @param {object} options - react-query configuration object
 * @returns kb list
 */
export const useAllKbs = ({ options }: IUseAllKb = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.knowledgebaseKeys.all,
    queryFn: () => getAllKbs(),
  });
};
