import styled from "styled-components";

export const PropertiesContainer = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PropertyContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PropertyName = styled.span`
  width: 200px;
  font-weight: 600;
`;

export const PropertyValue = styled.span``;
