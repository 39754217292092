import { useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";

// Constants
import { STATUS } from "constants/brm";

// State
import { useRecoilState } from "recoil";
import { riskFromNavigateState } from "atoms/atoms-risk";
import { RoutePath } from "routes/route-paths";

// Styles
import "./Accordion.css";
import { useStatusChevron } from "features/brm";
import S from "./ProjectExplorer.styles";

// Hooks
import { HighlightElementContext } from "./HighlightElementContext";

const Accordion = ({ title, content2 }) => {
  const highlightedElement = useContext(HighlightElementContext);
  const [active, setActive] = useState("");
  const [height, setHeight] = useState("0px");
  const navigate = useNavigate();
  const location = useLocation();
  const content = useRef(null);
  const [, setRiskFromNavigate] = useRecoilState(riskFromNavigateState);
  const folderIcon = useStatusChevron(active);

  // const selectedElement = useRef("");

  /* useEffect(() => {
    var cells = document.getElementsByClassName("subMenuDiv");

    for (var i = 0; i < cells.length; i++) {
      cells[i].style.backgroundColor = "white";
    }

    var selected = document.getElementById(selectedElement.current);
    if (selected) {
      selected.style.backgroundColor = "#005CA6";
    }
  }, [selectedElement]); */

  useEffect(() => {
    highlightedElement.setId(location.pathname);
  }, [highlightedElement, location]);

  const toggleAccordion = () => {
    setActive(active === "" ? STATUS.active : "");
    setHeight(active === STATUS.active ? "0px" : `${content.current.scrollHeight}px`);
  };

  const handleClick = (e) => {
    highlightedElement.setId(e.target.id);

    if (e.target.id === RoutePath.Risks) {
      // notify risk list page that navigation was from Navigtor to clear any filtering from risk matrix
      setRiskFromNavigate(true);
    }
    navigate(e.target.id);
  };

  useEffect(() => {}, [active]);

  return (
    <div className="accordion__section">
      <S.PackageLink id={title} className={`accordion ${active}`} onClick={toggleAccordion}>
        {folderIcon}
        {title}
      </S.PackageLink>
      <div ref={content} style={{ maxHeight: `${height}` }} className="accordion__content">
        <div className="accordion__text">
          {content2.map((value) => (
            <S.SubMenu
              className={highlightedElement.id === value.path && "highlighted__element"}
              id={value.path}
              key={value.path}
              onClick={handleClick}
            >
              {value.name}
            </S.SubMenu>
          ))}
        </div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  content2: PropTypes.array.isRequired,
};

export default Accordion;
