import * as React from "react";

import toast from "react-hot-toast";
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { AddButton, DeleteButton, CloneButton, useProject, AdminApi, ThreatApi } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import { COMMON, TYPE } from "constants/brm";

import ContentLayout from "brm/layout/Content/ContentLayout";
import { AttackerCreateDto } from "@kdmanalytics/brm-system";
import { queryClient } from "libs/react-query";
import ThreatGroupAddForm from "./forms/ThreatGroupAddForm";
import ThreatGroupTable from "./tables/ThreatGroupTable";

function ThreatGroup() {
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const [currentProject] = useProject();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [, setRefresh] = React.useState(true);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  let cloneThreaGroupToastId;

  const { data: selfData } = AdminApi.useSelf({ options: {} });

  const { mutate: deleteThreatGroup } = ThreatApi.useDeleteThreatGroup();
  const { mutate: addAttacker } = ThreatApi.useCreateAttacker({
    options: {
      onError: () => {
        toast.error(<span id="threatGroupToast">Cloning error</span>, {
          id: cloneThreaGroupToastId,
          duration: 4000,
        });
      },
    },
  });
  const projectId = currentProject?.id || "";

  React.useEffect(() => {
    async function multiDeleteApiCalls() {
      Promise.all(selectedRows.map((each) => deleteThreatGroup({ threatGroupId: each.id })))
        .then(() => {
          setConfirmDelete(false);
          setDeleteModalIsOpen(false);
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteThreatGroup, selectedRows, setSelectedInstance]);

  const addThreatGroup = () => {
    setModalIsOpen(true);
  };

  const handleClone = async () => {
    cloneThreaGroupToastId = toast.loading(<span id="threatGroupToast">Creating attackers...</span>);
    selectedRows.forEach((each) => {
      const params = {
        // MANDATORY
        name: each.name,
        note: "created by cloning threat group",
        category: "External",
        availableMeans: "M3",
        availableOpportunity: "O3",
        likelihood: "Moderate",
        group: each.id,
        // https://kdmanalytics.atlassian.net/browse/BRMCD-6399
        // person: COMMON.nullUuid,
        // satype: COMMON.nullUuid,
      };
      const attackerCreateDto = AttackerCreateDto.constructFromObject(params);
      addAttacker({ projectId, attackerCreateDto });
    });
    queryClient.invalidateQueries(["getThreatGroup"]);
    toast.success(
      <span id="threatGroupToast">
        Cloning completed. Attacker created from the selected Threat Group(s) for the project.
      </span>,
      {
        id: cloneThreaGroupToastId,
        duration: 4000,
      }
    );
  };

  const isDeleteable = React.useMemo(() => {
    if (selfData) {
      return selectedRows.every(
        (row) => row.catalog.id !== COMMON.defaultUuid && row.catalog.organization.id === selfData.organization
      );
    }
    return false;
  }, [selfData, selectedRows]);

  // const hideCheckBoxCondition = React.useCallback((row) => row.original.organization.id !== COMMON.defaultUuid, []);

  const header = (
    <Title id="ThreatGroup_title">
      Threat Groups
      <Buttons id="ThreatGroup_buttons">
        {selectedRows && selectedRows.length > 0 && (
          <>
            <CloneButton onClick={handleClone} disabled={currentProject === null} />
            {isDeleteable ? <DeleteButton onClick={() => setDeleteModalIsOpen(true)} /> : null}
          </>
        )}

        <AddButton onClick={addThreatGroup} />
      </Buttons>
    </Title>
  );

  const main = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "ThreatGroup_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.threatGroup}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.threatGroup
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "ThreatGroup_page",
          modalIsOpen,
          setModalIsOpen,
          <ThreatGroupAddForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} />,
          TYPE.threatGroup
        )}

      <ThreatGroupTable setSelectedRows={setSelectedRows} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="ThreatGroup_page" header={header} main={main} />;
}

export default ThreatGroup;
