import S from "./Toolbar.styles";

interface ISeparator {
  width?: string;
  margin?: string;
  padding?: string;
}
export const Separator = ({ padding = "", width = "", margin = "" }: ISeparator) => {
  return (
    <>
      <S.SeparatorDiv padding={padding} width={width} margin={margin} />
    </>
  );
};
