/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { components } from "react-select";
import * as S from "./FilterOption.styles";

const FilterOption = ({ label, isSelected, data, ...rest }) => {
  return (
    <components.Option {...rest}>
      <S.Container>
        {data.disabled ? null : <S.Input type="checkbox" checked={isSelected} onChange={() => null} />}
        <S.Label disabled={data.disabled}>{label}</S.Label>
      </S.Container>
    </components.Option>
  );
};

FilterOption.propTypes = {
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    disabled: PropTypes.bool,
  }),
};

export default FilterOption;
