import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as BrmGql from "generated/graphql";
import * as KeyFactory from "./threat-group-query-key-factory";

export interface ISetThreatGroupSourceURL {
  threatGroupId: string;
  url: string;
}

/**
 * Sets sourceURL property for threat group
 *
 * @param {string} threatGroupId - uuid of the threat group
 * @param {string} url - source url
 */
export const setThreatGroupSourceURL = ({ threatGroupId, url }: ISetThreatGroupSourceURL) => {
  return Brm.threatGroupApi.setThreatGroupSourceURL(threatGroupId, { body: url });
};

export interface IUseSetThreatGroupSourceUrl {
  options?: MutationOption<typeof setThreatGroupSourceURL>;
}

/**
 * Custom hook to change the source url property of an existing threat group
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetThreatGroupSourceUrl = ({ options }: IUseSetThreatGroupSourceUrl = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.threatGroupKeys.all);
      queryClient.invalidateQueries(BrmGql.useGetThreatGroupsQuery.getKey());
    },
    ...options,
    mutationFn: setThreatGroupSourceURL,
  });
};
