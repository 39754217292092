import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./sap-program-query-key-factory";

interface ISapProgramsProps {
  queryParam?: Record<string, string>;
}

/**
 * Gets the sap programs
 */
export const getSapPrograms = ({ queryParam }: ISapProgramsProps) => {
  return Brm.sapProgramApi.findSapProgram(queryParam);
};

type FunctionType = typeof getSapPrograms;
interface IProgramsProps {
  queryParam?: Record<string, string>;
  options?: QueryOption<FunctionType>;
}

/**
 * Custom hook for getting all sap Programs
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useGetSapPrograms = ({ queryParam, options }: IProgramsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: queryParam ? KeyFactory.sapProgramKeys.query(queryParam) : KeyFactory.sapProgramKeys.all,
    queryFn: () => getSapPrograms({ queryParam }),
  });
};
