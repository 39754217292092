export const ContentKey = {
  reports: "reports",
  threatCatalogs: "threatCatalogs",
  threatCatalog: "threatCatalogsDetails",
  threatTiers: "threatTiers",
  threatTier: "threatTiersDetails",
  threatGroups: "threatGroups",
  threatGroupDetails: "threatGroupDetails",
  ttps: "ttps",
  ttpDetails: "ttpDetails",
  attackMechanisms: "attackMechanisms",
  attackMechanism: "attackMechanismFullDetails",
  attackTactics: "attackTactics",
  attackTactic: "attackTacticFullDetails",
  attackKinds: "attackKinds",
  attackKind: "attackKindFullDetails",
  attacks: "attacks",
  attack: "attackFullDetails",
  attackerCategories: "attackerCategories",
  attackers: "attackers",
  attacker: "attackersDetails",
  structuralCategories: "structuralCategories",
  elementCategories: "elementCategories",
  nodes: "nodes",
  node: "nodeFullDetails",
  links: "links",
  link: "linkFullDetails",
  groups: "groups",
  channels: "channels",
  exchanges: "exchanges",
  exchange: "exchangeFullDetails",
  datatypes: "datatypes",
  datatype: "datatypeFullDetails",
  persons: "persons",
  person: "personFullDetails",
  dataflows: "dataflows",
  dataflow: "dataflowFullDetails",
  capabilities: "capabilities",
  capability: "capabilityFullDetails",
  activities: "activities",
  activity: "activityFullDetails",
  missions: "missions",
  mission: "missionFullDetails",
  resources: "resources",
  resource: "resourceFullDetails",
  entryPoints: "entryPoints",
  entryPoint: "entryPointsDetails",
  entryPointCategories: "entryPointCategories",
  accessPoints: "accessPoints",
  accessPoint: "accessPointsDetails",
  accessPointCategories: "accessPointCategories",
  attackSurfaces: "attackSurfaces",
  attackSurface: "attackSurfacesDetails",
  attackSurfaceCategories: "attackSurfaceCategories",
  attackVectors: "attackVectors",
  attackVector: "attackVectorsDetails",
  attackVectorCategories: "attackVectorCategories",
  boundaries: "boundaries",
  boundaryActivities: "boundariesActivities",
  classifiedCategories: "classifiedCategories",
  securityObjectives: "securityObjectives",
  securityObjectivesOverview: "securityObjectivesOverview",
  categorizedEntities: "categorizedEntities",
  categorizedEntitiesOverview: "categorizedEntitiesOverview",
  systemAssets: "systemAssets",
  systemAsset: "systemAssetFullDetails",
  systemAssetCategories: "systemAssetCategories",
  systemAssetTypes: "systemAssetTypes",
  systemAssetType: "systemAssetTypeFullDetails",
  threatEventPatterns: "threatEventPatterns",
  threatEventPattern: "threatEventPatternFullDetails",
  threatEventCategories: "threatEventCategories",
  threatEvents: "threatEvents",
  threatEvent: "threatEventFullDetails",
  riskCategories: "riskCategories",
  riskCategory: "riskCategoryFullDetails",
  risks: "risks",
  risk: "riskFullDetails",
  harms: "harms",
  harmCategory: "harmCategoryFullDetails",
  undesiredEvents: "undesiredEvents",
  undesiredEvent: "undesiredEventFullDetails",
  assetCategories: "assetCategories",
  assetCategory: "assetCategoryFullDetails",
  assets: "assets",
  asset: "assetFullDetails",
  vulnerabilities: "vulnerabilities",
  vulnerability: "vulnerabilityFullDetails",
  vulnerabilityCategories: "vulnerabilityCategories",
  softwareVulnerabilities: "softwareVulnerabilities",
  sctms: "sctms",
  sctm: "sctmsDetails",
  allocatedControls: "allocatedControls",
  allocatedControl: "allocatedControlsDetails",
  controlCatalogs: "controlCatalogs",
  controlCatalog: "controlCatalogsDetails",
  controlFamilies: "controlFamily",
  controlFamily: "controlFamilyFullDetails",
  controlTypes: "controlType",
  controlType: "controlTypeFullDetails",
  standardBaselines: "standardBaselines",
  standardBaseline: "standardBaselineFullDetails",
  projectCatalogConfig: "projectCatalogConfig",
  knowledgebases: "knowledgebases",
  kbOrganizations: "kbOrganizations",
  knowledgebase: "knowledgebasesDetails",
  kbProjects: "kbProjects",
  metadata: "metadata",
  systems: "systems",
  system: "systemsDetails",
  variants: "variants",
  variant: "variantsDetails",
  projects: "projects",
  projectOverview: "projectOverview",
  impactCalibration: "impactCalibration",
  likelihoodCalibration: "likelihoodCalibration",
  organizations: "organizations",
  organizationOverview: "organizationOverview",
  users: "users",
  userOverview: "userOverview",
  installations: "installation",
  installationOverview: "installationOverview",
  addUsers: "addUsers",
  projectOverviewImporter: "projectOverviewImporter",
  home: "home",
  licenseError: "licenseError",
  logout: "logout",
  empty: "empty",
} as const;

export type ContentKeyValues = typeof ContentKey[keyof typeof ContentKey];
