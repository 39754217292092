/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import * as S from "./StepLabel.styles";
import { StepIcon } from "./StepIcon";
import { useStepContext } from "./StepContext";

interface IStepIconProps {
  icon: React.ReactNode;
  isCompleted?: boolean;
  isActive?: boolean;
}

interface IStepLabelProps {
  icon?: React.ReactNode;
  children?: React.ReactNode;
  StepIconComponentType?: React.ComponentType<IStepIconProps>;
  optional?: React.ReactNode;
}

export const StepLabel = ({
  children,
  icon: iconProp,
  optional = null,
  StepIconComponentType = StepIcon,
}: IStepLabelProps) => {
  const { icon: iconContext, isActive, isCompleted } = useStepContext();

  const icon = iconProp || iconContext;

  let StepIconComponent = StepIconComponentType;
  if (icon && !StepIconComponent) {
    StepIconComponent = StepIcon;
  }

  const labelProps = { isActive, isCompleted };

  return (
    <S.Container>
      <S.StepLabelIconContainer>
        <StepIconComponent icon={icon} {...labelProps} />
      </S.StepLabelIconContainer>
      <S.StepLabelLabelContainer {...labelProps}>
        <S.StepLabelLabel>{children}</S.StepLabelLabel>
        {optional}
      </S.StepLabelLabelContainer>
    </S.Container>
  );
};
