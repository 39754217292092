import styled, { css } from "styled-components/macro";
import { ChevronRight, ChevronLeft } from "@styled-icons/heroicons-solid";

interface IResizeProps {
  location: string;
}

export const ResizeControlContainer = styled.div<IResizeProps>`
  position: absolute;
  ${(props) =>
    props.location === "left" &&
    css`
      left: 100%;
    `}
  ${(props) =>
    props.location === "right" &&
    css`
      left: 0;
    `}
  top: 0;
  bottom: 0;
  /* background-color: #00ff00; */
`;

export const ResizeControlShadow = styled.div`
  bottom: 0;
  top: 0;
  position: absolute;
  left: -1px;
  width: 3px;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.2) 0px,
    rgba(0, 0, 0, 0.2) 1px,
    rgba(0, 0, 0, 0.1) 1px,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const ResizeControlGrabArea = styled.div<IResizeProps>`
  cursor: ew-resize;
  height: 100%;
  width: ${({ theme }) => theme.sizes.dragBarWidth};

  ${(props) =>
    props.location === "left" &&
    css`
      left: ${({ theme }) => `-${theme.sizes.dragBarWidth}`};
    `}

  ${(props) =>
    props.location === "right" &&
    css`
      right: ${({ theme }) => `-${theme.sizes.dragBarWidth}`};
    `} 

  padding: 0;
  border: 0;
  position: absolute;
  background: ${({ theme }) => theme.dragBars.backgroundColor};
  justify-content: center;
  align-content: center;
  display: flex;
`;

export const NavigationChevronRight = styled(ChevronRight)`
  color: ${({ theme }) => theme.colors.buttonPrimary};
  width: ${({ theme }) => theme.sizes.chevronWidth};
  height: ${({ theme }) => theme.sizes.chevronHeight};
  margin-top: -5px;
  margin-left: -4px;
`;

export const NavigationChevronLeft = styled(ChevronLeft)`
  color: ${({ theme }) => theme.colors.buttonPrimary};
  width: ${({ theme }) => theme.sizes.chevronWidth};
  height: ${({ theme }) => theme.sizes.chevronHeight};
  margin-top: -5px;
  margin-left: -6px;
`;

export const ExpandButton = styled.button`
  z-index: 100;
  position: absolute;
  background-color: #ffffff;
  top: 10px;
  width: 20px;
  height: 20px;

  border: 0px;
  border-radius: 50%;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 4px 1px;
`;
