import * as React from "react";

import {
  ROLE_SUPER_ADMIN,
  ROLE_ADMIN,
  ROLE_SYSTEM_ENGINEER,
  ROLE_RISK_ANALYST,
  ROLE_CONTROLS_COORDINATOR,
  ROLE_CYBERSECURITY_EXPERT,
  ROLE_THREAT_ANALYST,
} from "services/user";

import {
  AdminHelpContents,
  CntrlCoordinatorHelpContents,
  CyberSecurityHelpContents,
  RiskAnalystHelpContents,
  SuperAdminHelpContents,
  SystemEngHelpContents,
  ThreatAnalystHelpContents,
} from "features/help/constants";
import { IUseHelpContent, IContentObj } from "features/help/types";

interface Contents {
  contents: IContentObj[];
}

const superAdminContents: { [key: string]: Contents } = SuperAdminHelpContents;
const adminContents: { [key: string]: Contents } = AdminHelpContents;
const cntrlCoordinatorContents: { [key: string]: Contents } = CntrlCoordinatorHelpContents;
const cseContents: { [key: string]: Contents } = CyberSecurityHelpContents;
const riskAnalystContents: { [key: string]: Contents } = RiskAnalystHelpContents;
const systemEngContents: { [key: string]: Contents } = SystemEngHelpContents;
const threatAnalystContents: { [key: string]: Contents } = ThreatAnalystHelpContents;

const getAllVideoContents = () => {
  const superAdminMixedContents = Object.values(superAdminContents)
    .map((contentObj) => contentObj.contents)
    .flat();

  const adminMixedContents = Object.values(adminContents)
    .map((contentObj) => contentObj.contents)
    .flat();

  const cntrlCoordinatorMixedContents = Object.values(cntrlCoordinatorContents)
    .map((contentObj) => contentObj.contents)
    .flat();

  const cseMixedContents = Object.values(cseContents)
    .map((contentObj) => contentObj.contents)
    .flat();

  const riskAnalystMixedContents = Object.values(riskAnalystContents)
    .map((contentObj) => contentObj.contents)
    .flat();

  const systemEngMixedContents = Object.values(systemEngContents)
    .map((contentObj) => contentObj.contents)
    .flat();

  const threatAnalystMixedContents = Object.values(threatAnalystContents)
    .map((contentObj) => contentObj.contents)
    .flat();

  return superAdminMixedContents.concat(
    adminMixedContents,
    cntrlCoordinatorMixedContents,
    cseMixedContents,
    riskAnalystMixedContents,
    systemEngMixedContents,
    threatAnalystMixedContents
  );
};

const getContextVideosByRole = (contexts: string[], roleContents: { [key: string]: Contents }) => {
  return contexts.map((contentKey: string) => roleContents[contentKey]?.contents || []).flat();
};

const getAllContextsVideosByRole = (roleContents: { [key: string]: Contents }) => {
  return Object.values(roleContents)
    .map((contentObj) => contentObj.contents)
    .flat();
};

export const useHelpCenterVideoContent = ({ userRoles, contexts }: IUseHelpContent) => {
  const [contentsData, setContentsData] = React.useState<IContentObj[]>([]);

  const allVideoContentsData = React.useMemo(() => getAllVideoContents(), []);

  const roleAndContextContent = React.useMemo(() => {
    const contextVideosByRole: { [key: string]: IContentObj[] } = {
      [ROLE_SUPER_ADMIN]: getContextVideosByRole(contexts, superAdminContents),
      [ROLE_ADMIN]: getContextVideosByRole(contexts, adminContents),
      [ROLE_SYSTEM_ENGINEER]: getContextVideosByRole(contexts, systemEngContents),
      [ROLE_THREAT_ANALYST]: getContextVideosByRole(contexts, threatAnalystContents),
      [ROLE_RISK_ANALYST]: getContextVideosByRole(contexts, riskAnalystContents),
      [ROLE_CONTROLS_COORDINATOR]: getContextVideosByRole(contexts, cntrlCoordinatorContents),
      [ROLE_CYBERSECURITY_EXPERT]: getContextVideosByRole(contexts, cseContents),
    };

    const allContextsVideosByRole: { [key: string]: IContentObj[] } = {
      [ROLE_SUPER_ADMIN]: getAllContextsVideosByRole(superAdminContents),
      [ROLE_ADMIN]: getAllContextsVideosByRole(adminContents),
      [ROLE_SYSTEM_ENGINEER]: getAllContextsVideosByRole(systemEngContents),
      [ROLE_THREAT_ANALYST]: getAllContextsVideosByRole(threatAnalystContents),
      [ROLE_RISK_ANALYST]: getAllContextsVideosByRole(riskAnalystContents),
      [ROLE_CONTROLS_COORDINATOR]: getAllContextsVideosByRole(cntrlCoordinatorContents),
      [ROLE_CYBERSECURITY_EXPERT]: getAllContextsVideosByRole(cseContents),
    };

    if (userRoles.length && contexts.length) {
      return userRoles.map((role) => contextVideosByRole[role] || []).flat();
    }
    if (userRoles.length && !contexts.length) {
      return userRoles.map((role) => allContextsVideosByRole[role] || []).flat();
    }
    if (!userRoles.length && contexts.length) {
      return contexts
        .map((contentKey) => {
          const superAdminContent = superAdminContents[contentKey]?.contents || [];
          const adminContent = adminContents[contentKey]?.contents || [];
          const systemEngContent = systemEngContents[contentKey]?.contents || [];
          const threatAnalystContent = threatAnalystContents[contentKey]?.contents || [];
          const riskAnalystContent = riskAnalystContents[contentKey]?.contents || [];
          const cntrlCoordinatorContent = cntrlCoordinatorContents[contentKey]?.contents || [];
          const cseContent = cseContents[contentKey]?.contents || [];

          return superAdminContent.concat(
            adminContent,
            cntrlCoordinatorContent,
            cseContent,
            riskAnalystContent,
            systemEngContent,
            threatAnalystContent
          );
        })
        .flat();
    }
    return null;
  }, [userRoles, contexts]);

  React.useEffect(() => {
    if (roleAndContextContent) {
      setContentsData(roleAndContextContent);
    } else {
      setContentsData(allVideoContentsData);
    }
  }, [allVideoContentsData, contexts, roleAndContextContent, userRoles]);

  return contentsData;
};
