import { useRecoilValue } from "recoil";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { attackKindApi } from "services/brm/attack-service";
import errorUtils from "utils/error-utils";
import {
  DetailsContainer,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import Title from "components/elements/StyledTitle";
import { projectIdState } from "atoms/atoms-admin";
import { RoutePath } from "routes/route-paths";
import AttackKindDetails from "../details/AttackKindDetails";

const listUrl = RoutePath.AttackKinds;

function AttackKindFullDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(listUrl);
    }
  }, [projectId, navigate]);

  useEffect(() => {
    async function getData() {
      setRefresh(false);

      try {
        const res = await attackKindApi.getAttackKindByIDWithHttpInfo(id);

        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setSelectedElement(res.data);
        }
      } catch (err) {
        console.error("Error in getAttackKindByIDWithHttpInfo: ", err);
      }
    }
    if (refresh) {
      getData();
    }
  }, [refresh, id, setRefresh]);

  return (
    <>
      <Title id="AttackKindFullDetails_title">{selectedElement.name}</Title>

      <DetailsContainerParent showSummaryTable={false}>
        <DetailsContainer>
          {selectedElement.id && <AttackKindDetails selectedElement={selectedElement} />}
        </DetailsContainer>
      </DetailsContainerParent>
    </>
  );
}

export default AttackKindFullDetails;
