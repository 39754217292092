import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import errorUtils from "utils/error-utils";
import { nodeApi } from "services/brm/system-service";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { BrmError, DetailTableType } from "features/brm";

const MemberTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const columns = useMemo(() => [createColumnMappedNoEdit("groups"), createColumnMappedNoEdit("members")], []);

  const [memberData, setMemberData] = useState([]);

  useEffect(() => {
    async function getData() {
      function getElementRequest() {
        switch (elementNameInUrl) {
          case DetailTableType.NODES.key: {
            return nodeApi.getNodeMemberWithHttpInfo(selectedElement.id);
          }
          default: {
            break;
          }
        }
        throw new BrmError(`Unsupported element type:${elementNameInUrl}`);
      }

      try {
        const res = await getElementRequest();
        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setMemberData(res.data);
        }
      } catch (err) {
        console.error("Error while loading members ", err);
      }
    }
    getData();
  }, [elementNameInUrl, selectedElement]);

  return (
    <S.DetailsContainer id="MemberTable_detailsPanel">
      <BrmDetailTable
        data={memberData}
        columns={columns}
        customProps={{ id: "MemberTable_detailsTable" }}
        showRowSelect={false}
        tableTitle={tableTitle}
      />
    </S.DetailsContainer>
  );
};

MemberTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  tableTitle: PropTypes.string,
};

export default MemberTable;
