import { useMemo } from "react";
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { TYPE } from "constants/brm";

import { createColumnCategoryName } from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { RiskApi, useRoles } from "features/brm";

const RiskCategoryTable = ({ setSelectedRows }) => {
  const { isRiskAnalyst } = useRoles();

  const projectId = useRecoilValue(projectIdState);
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data: getRiskCatData, isError: isGetRiskCatDataError } = RiskApi.useRiskCategories({
    projectId,
    options: { enabled: isRiskAnalyst },
  });

  const columns = useMemo(
    () => [createColumnCategoryName(selectedInstance.id, setSelectedInstance, "riskCategory")],
    [selectedInstance.id, setSelectedInstance]
  );

  if (isGetRiskCatDataError) {
    return <ErrorBanner msg="Error while loading Risk categories" />;
  }

  if (getRiskCatData) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={getRiskCatData}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "RiskCategoryTable_table" }}
            showRowSelect={false}
            elementName={TYPE.riskCat}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};

RiskCategoryTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

export default RiskCategoryTable;
