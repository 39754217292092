import { useState, useEffect } from "react";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import { attackSurfaceApi } from "services/brm/access-service";
import { AddButton, DeleteButton } from "features/brm";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { TYPE } from "constants/brm";
import systemModals from "components/modals/Modals";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { useRoles } from "features/brm/hooks/useRoles";
import AttackSurfaceTable from "./tables/AttackSurfaceTable";
import AttackSurfaceAddForm from "./forms/AttackSurfaceAddForm";

function AttackSurface() {
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);

  useEffect(() => {
    async function multiDeleteApiCalls() {
      Promise.all(selectedRows.map((each) => attackSurfaceApi.deleteAttackSurfaceWithHttpInfo(each.id)))
        .then(() => {
          setConfirmDelete(false);
          setDeleteModalIsOpen(false);
          setRefresh(true);
          setSelectedInstance({});
        })
        .catch((err) => {
          console.error("err in delete Promise.all : ", err.message);
        });
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, selectedRows, setSelectedInstance]);

  const addAttackSurface = () => {
    setModalIsOpen(true);
  };

  const attackSurfaceHeader = (
    <Title id="AttackSurface_title">
      Attack Surfaces
      <Buttons id="AttackSurface_buttons">
        {isRiskAnalyst || isSystemEngineer ? (
          <>
            {selectedRows?.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
            <AddButton onClick={addAttackSurface} />
          </>
        ) : (
          <br />
        )}
      </Buttons>
    </Title>
  );

  const attackSurfaceMain = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "AttackSurface_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.attackSurface}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            refresh={refresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.attackSurface
        )}
      {modalIsOpen &&
        systemModals.addModal(
          "AttackSurface_page",
          modalIsOpen,
          setModalIsOpen,
          <AttackSurfaceAddForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} refresh={refresh} />,
          TYPE.attackSurface
        )}
      <AttackSurfaceTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="AttackSurface_page" header={attackSurfaceHeader} main={attackSurfaceMain} />;
}

export default AttackSurface;
