import { useState, useMemo, useRef, useCallback } from "react";
import toast from "react-hot-toast";
import * as BrmGql from "generated/graphql";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue } from "recoil";
import { TYPE } from "constants/brm";
import {
  createColumnNameNoNav,
  createColumnModifiedAt,
  createColumnCreatedAt,
  createColumnMapped,
  createColumnMappedNoEdit,
  createColumnActions,
  createColumnBooleanMapped,
} from "brm/tables/services/column/columnFactory";
import { AdminApi, KnowledgebaseApi } from "features/brm";
import { LoadingSpinner as Loading } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";
import { getCyberSecurityExpertFilteredProjects } from "utils/filter-utils";
import { userIdState } from "atoms";
import { queryClient } from "libs/react-query";

const modesList = [
  {
    id: "default",
    name: "default",
  },
  {
    id: "aggressive",
    name: "aggressive",
  },
];

export const KnowledgebaseProjectConfigurationTable = ({ setSelectedRows, setRefresh }) => {
  const [editMode, setEditMode] = useState(false);
  const updatedKb = useRef("");
  const updatedMode = useRef("");
  const updatePhysicalMode = useRef("");
  const selectedInstance = useRecoilValue(selectedInstanceState);
  const { isCyberSecurityExpert } = useRoles();

  const userId = useRecoilValue(userIdState);

  const { data: knowledgebaseList, isError: isKnowledgebaseListError } = KnowledgebaseApi.useAllKbs({
    options: { enabled: isCyberSecurityExpert },
  });
  const { data: projectsKB, isError: isProjectsKBError } = BrmGql.useGetKbProjectConfigurationQuery(
    { userId },
    {
      select: useCallback(
        (data) =>
          data.projectFindAllByUserWithKb.filter(
            (project) => isCyberSecurityExpert && getCyberSecurityExpertFilteredProjects(project)
          ),
        [isCyberSecurityExpert]
      ),
      enabled: !!userId && isCyberSecurityExpert,
    }
  );

  const { mutate: setProjectKb } = AdminApi.useSetProjectKb();
  const { mutate: setProjectSetting } = AdminApi.useSetProjectSetting({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(BrmGql.useGetKbProjectConfigurationQuery.getKey({ userId }));
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedKb.current !== "") {
              setProjectKb(
                { projectId: selectedInstance.id, kb: updatedKb.current },
                {
                  onSuccess: () => {
                    updatedKb.current = "";
                    queryClient.invalidateQueries(BrmGql.useGetKbProjectConfigurationQuery.getKey({ userId }));
                    queryClient.invalidateQueries(KnowledgebaseApi.knowledgebaseKeys.all);
                  },
                  onError: () => {
                    toast.error("Error occured while setting Kb to project");
                  },
                }
              );
            }

            if (updatedMode.current !== "") {
              setProjectSetting(
                {
                  projectId: selectedInstance.id,
                  setting: "vulnerabilityComputationMode",
                  value: updatedMode.current,
                },
                {
                  onSuccess: () => {
                    updatedMode.current = "";
                  },
                  onError: () => {
                    toast.error("Error occured while setting vulnerbility Computation");
                  },
                }
              );
            }

            if (updatePhysicalMode.current !== "") {
              // physical mode needs yes or no.. so we convert
              const value = updatePhysicalMode.current === "true" ? "yes" : "no";
              setProjectSetting(
                {
                  projectId: selectedInstance.id,
                  setting: "physicalMode",
                  value,
                },
                {
                  onSuccess: () => {
                    updatePhysicalMode.current = "";
                  },
                  onError: () => {
                    toast.error("Error occured while setting physical mode");
                  },
                }
              );
            }

            setRefresh(true);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [selectedInstance.id, editMode, setRefresh, setProjectKb, userId, setProjectSetting]
  );

  const columns = useMemo(
    () => [
      createColumnNameNoNav(selectedInstance.id, false, null),
      createColumnMappedNoEdit("org"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnMapped("vulnerabilityComputation", selectedInstance.id, modesList, false, updatedMode),
      createColumnBooleanMapped("physicalMode", selectedInstance.id, false, updatePhysicalMode),
      createColumnMapped("kb", selectedInstance.id, knowledgebaseList, editMode, updatedKb),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [editMode, selectedInstance.id, knowledgebaseList, createButton]
  );

  if (isKnowledgebaseListError || isProjectsKBError) {
    toast.error(<span>Error while loading projects</span>, {
      duration: 4000,
    });
  }

  if (projectsKB) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={projectsKB}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "KBProjectTable_table" }}
            elementName={TYPE.project}
            showRowSelect={false}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

KnowledgebaseProjectConfigurationTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

// export default KBProjectTable;
