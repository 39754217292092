import { atom, selector } from "recoil";
import { convertToLevel } from "utils/calibration-converter";

export const impactOptionsState = atom({
  key: "impactOptionsState",
  default: [
    { value: "I5", label: "catastrophic" },
    { value: "I4", label: "major" },
    { value: "I3", label: "moderate" },
    { value: "I2", label: "minor" },
    { value: "I1", label: "negligible" },
  ],
});

// export const severityOptionsState = atom({
//   key: "severityOptionsState",
//   default: [
//     { value: "S5", label: "Very High" },
//     { value: "S4", label: "Major" },
//     { value: "S3", label: "Moderate" },
//     { value: "S2", label: "Minor" },
//     { value: "S1", label: "Negligible" },
//   ],
// });

// export const criticalityOptionsState = atom({
//   key: "criticalityOptionsState",
//   default: [
//     { value: "C5", label: "Total Mission Failure" },
//     { value: "C4", label: "Significant/Unacceptable Mission Degradation" },
//     { value: "C3", label: "Partial Mission Degradation" },
//     { value: "C2", label: "Negligible Mission Degradation" },
//     { value: "C1", label: "No Mission Degradation" },
//   ],
// });

export const undesiredEventListState = atom({
  key: "undesiredEventListState",
  default: [],
});

export const objectiveSelectedFiltersState = atom({
  key: "objectiveSelectedFiltersState",
  default: [],
});

export const assetSelectedFiltersState = atom({
  key: "assetSelectedFiltersState",
  default: [],
});

export const impactSelectedFiltersState = atom({
  key: "impactSelectedFiltersState",
  default: [],
});

export const calibratedImpactSelectedFiltersState = atom({
  key: "calibratedImpactSelectedFiltersState",
  default: [],
});

/**
 * User Filtered UE in Impact Calibration Editor
 */
export const filteredUEImpactCalibrationState = selector({
  key: "filteredUEImpactCalibrationState",
  get: ({ get }) => {
    const objectiveFilter = get(objectiveSelectedFiltersState);
    const assetFilter = get(assetSelectedFiltersState);
    const impactFilter = get(impactSelectedFiltersState);
    const calibratedImpactFilter = get(calibratedImpactSelectedFiltersState);
    let uefilteredList = get(undesiredEventListState);

    if (objectiveFilter.length !== 0) {
      const items = [];
      objectiveFilter.forEach((item) => {
        items.push(item.value);
      });
      const collectionSet = new Set(items);
      uefilteredList = uefilteredList.filter((ue) => collectionSet.has(ue.objective.id));
    }

    if (assetFilter.length !== 0) {
      const items = [];
      assetFilter.forEach((item) => {
        items.push(item.value);
      });
      const collectionSet = new Set(items);
      uefilteredList = uefilteredList.filter((ue) => collectionSet.has(ue.asset.id));
    }

    if (impactFilter.length !== 0) {
      const items = [];
      impactFilter.forEach((item) => {
        items.push(item.label);
      });
      const collectionSet = new Set(items);
      uefilteredList = uefilteredList.filter((ue) =>
        collectionSet.has(convertToLevel(ue.impact.calcStringValue, "impact"))
      );
    }

    if (calibratedImpactFilter.length !== 0) {
      const items = [];
      calibratedImpactFilter.forEach((item) => {
        items.push(item.label);
      });
      const collectionSet = new Set(items);
      uefilteredList = uefilteredList.filter((ue) =>
        collectionSet.has(convertToLevel(ue.impact.calibratedStringValue, "impact"))
      );
    }

    return uefilteredList;
  },
});
