import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./risk-query-key-factory";

interface IGetRiskDetails {
  riskId: string;
  variantId: string;
}

export const getRiskDetails = async ({ riskId, variantId }: IGetRiskDetails) => {
  const risk = await Brm.riskApi.getRiskByID(riskId, {
    variant: variantId,
  });
  risk.asset = await Brm.riskApi.getRiskAsset(riskId);
  risk.objective = await Brm.riskApi.getRiskObjective(riskId);
  return risk;
};

interface IUseRiskDetails {
  riskId: string;
  variantId: string;
  options?: QueryOption<typeof getRiskDetails>;
}

export const useRiskDetails = ({ riskId, variantId, options }: IUseRiskDetails) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.riskKeys.details(riskId, variantId),
    queryFn: () => getRiskDetails({ riskId, variantId }),
  });
};
