import { useState, useEffect, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import errorUtils from "utils/error-utils";
import { userApi } from "services/brm/admin-service";

import {
  createColumnMappedNoEdit,
  createColumnUsernameDetails,
  createColumnCreatedAt,
  createColumnModifiedAt,
} from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";

export const InstallationUserDetailsTable = () => {
  const [, setSelectedUser] = useState([]);
  const [userData, setUserData] = useState([]);

  const columns = useMemo(
    () => [
      createColumnUsernameDetails(RoutePath.User.replace(":id", "")),
      createColumnMappedNoEdit("firstname"),
      createColumnMappedNoEdit("lastname"),
      createColumnMappedNoEdit("org"),
      createColumnMappedNoEdit("status"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
    ],
    []
  );

  useEffect(() => {
    async function getData() {
      try {
        const res = await userApi.findUserWithHttpInfo();
        errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
        setUserData(res.data);
      } catch (error) {
        console.error("Details: Installation User GET: ", error);
      }
    }
    getData();
  }, []);

  return (
    <S.DetailsContainer id="InstallationUserTable_detailsPanel">
      <BrmDetailTable
        data={userData}
        columns={columns}
        setSelectedElement={setSelectedUser}
        setSelectedRows={() => {}}
        customProps={{ id: "InstallationUserTable_detailsTable" }}
        showRowSelect={false}
      />
    </S.DetailsContainer>
  );
};
