import { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import errorUtils from "utils/error-utils";
import Title from "components/elements/StyledTitle";
import { systemApi } from "services/brm/component-service";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import GenericModal from "components/modals/GenericModal";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import useRedirectDetailsPage from "hooks/useRedirectDetailsPage";
import { TYPE } from "constants/brm";

import { createColumnMappedNoEdit, createColumnBooleanNoEditMapped } from "brm/tables/services/column/columnFactory";

import { RoutePath } from "routes/route-paths";
import { SystemDetailsTab } from "../../components/tabs/SystemDetailsTab";

export function SystemOverview() {
  const { id } = useParams();

  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [, setSelectedRowId] = useState("");

  const [reImportModalIsOpen, setReImportModalIsOpen] = useState(false);
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const listUrl = RoutePath.Systems;
  const [showSummaryTable, setShowSummaryTable] = useState(false);

  const columns = useMemo(
    () => [
      createColumnMappedNoEdit("confidentialityLevel"),
      createColumnMappedNoEdit("integrityLevel"),
      createColumnMappedNoEdit("availabilityLevel"),
      createColumnMappedNoEdit("defaultVariant"),
      createColumnMappedNoEdit("compliance"),
      createColumnBooleanNoEditMapped("isImported"),
      createColumnMappedNoEdit("metadata"),
      createColumnMappedNoEdit("categorizedentity"),
    ],
    []
  );

  useRedirectDetailsPage(projectId, localProjectId, listUrl);

  const systemReimport = async () => {
    try {
      const { response } = await systemApi.reimportSystemWithHttpInfo(selectedElement.id);
      errorUtils.checkErrorStatus(response.status, response.statusText);
      setReImportModalIsOpen(false);
    } catch (err) {
      console.error("error during re-import : ", err);
    }
  };

  function showReImportBody() {
    return (
      <div>
        <p> Are you sure you want to perform re-import to the system ?</p>
        <DialogButtonDivStyled id="ProjectAddForm_buttons">
          <DialogButtonStyled onClick={() => setReImportModalIsOpen(false)}> Cancel </DialogButtonStyled>
          <DialogButtonStyled onClick={systemReimport}> Confirm </DialogButtonStyled>
        </DialogButtonDivStyled>
      </div>
    );
  }

  useEffect(() => {
    async function getData() {
      setRefresh(false);
      let systemToDisplay = {};

      try {
        const { data: sysData, response } = await systemApi.getSystemByIDWithHttpInfo(id);

        if (sysData) {
          if (response.status !== 200 && response.status !== 201) {
            errorUtils.checkErrorStatus(response.status, response.statusText);
          }
          setSelectedElement(sysData);
          systemToDisplay = sysData;
          setData([systemToDisplay]);
        }
      } catch (err) {
        console.error("error in gettign the System full details : ", err);
      }
    }

    if (refresh && id) {
      getData();
    }
  }, [refresh, id]);

  return (
    <>
      <Title>
        {selectedElement.name}
        <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
      </Title>

      {showSummaryTable ? (
        <DetailsContainerSingleTable>
          <BrmSingleElementTable
            data={data}
            columns={columns}
            setSelectedElement={setSelectedElement}
            setSelectedRowId={setSelectedRowId}
            customProps={{ id: "SystemFullDetails_table" }}
            elementName={TYPE.system}
          />
        </DetailsContainerSingleTable>
      ) : (
        <></>
      )}

      {reImportModalIsOpen && (
        <GenericModal
          elementId="SystemFullDetails_panel"
          modalIsOpen={reImportModalIsOpen}
          setModalIsOpen={setReImportModalIsOpen}
          headerText="Re Import Alert !"
          bodyText={showReImportBody()}
        />
      )}

      {selectedElement.id && (
        <DetailsContainerParent showSummaryTable={showSummaryTable}>
          <DetailsContainer>
            <SystemDetailsTab selectedElement={selectedElement} setRefresh={setRefresh} refresh={refresh} />
          </DetailsContainer>
        </DetailsContainerParent>
      )}
    </>
  );
}
