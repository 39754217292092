import * as React from "react";

import { ExportApi, ExportContext, useProject } from "features/brm";
import { useDownloadFile, useGetExportStatus } from "../../api";
import { EXPORT_STATUS } from "../../constants";
import { AAR_EXPORT_FORMATS } from "../../types";

const filePrefix = "AAR";

interface IUseExportAarProps {
  onStatusError: (msg: string) => void;
  onSuccess: () => void;
}

export const useExportAar = ({ onStatusError, onSuccess }: IUseExportAarProps) => {
  const [responseFromExport, setResponseFromExport] = React.useState<any>();
  const [currentProject] = useProject();
  const [exportStatus, setExportStatus] = React.useState("");
  const [selectedFormat] = React.useState(AAR_EXPORT_FORMATS.Docx);

  const { mutate: fileExport } = ExportApi.useExportFile({
    onSuccess: (data: any) => {
      setResponseFromExport(data);
    },
  });

  const { refetch: fetchDownload } = useDownloadFile(
    currentProject?.id,
    `${currentProject?.name}_${filePrefix}`,
    responseFromExport?.id,
    exportStatus,
    selectedFormat,
    () => {
      onSuccess();
    },
    ExportContext.system
  );

  const { refetch: refetchStatus } = useGetExportStatus(
    currentProject?.id,
    responseFromExport?.id,
    setExportStatus,
    exportStatus,
    // TODO implement these...
    onStatusError,
    () => {},
    ExportContext.system
  );

  React.useEffect(() => {
    if (responseFromExport) {
      if (exportStatus && exportStatus === EXPORT_STATUS.pass) {
        fetchDownload();
      } else {
        refetchStatus();
      }
    }
  }, [exportStatus, fetchDownload, refetchStatus, responseFromExport]);

  const exportAarFile = React.useCallback(() => {
    const opts = {
      target: selectedFormat,
    };

    if (currentProject?.id) {
      fileExport(
        { projectId: currentProject.id, body: opts, context: ExportContext.system },
        {
          onError: () => {
            // TODO implement these...
            // if (error.status === LICENSE_VALIDATION_ERROR_STATUS) {
            //   setLicenseErrorIsOpen(true);
            //   setDisableExport(false);
            // }
          },
        }
      );
    }
  }, [currentProject?.id, fileExport, selectedFormat]);

  return { exportAarFile };
};
