import PropTypes from "prop-types";
import { useState, useMemo } from "react";
// Utils
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
// State
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { useRecoilValue } from "recoil";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
import { useModal } from "hooks";
import { useRoles } from "features/brm/hooks/useRoles";
// Constants
import { COMMON } from "constants/brm";
// Styles
import * as S from "brm/styles/details-table.styles";
// Local
import { LoadingSpinner, ErrorBanner, CenterText } from "components/elements";
import * as BrmGql from "generated/graphql";
import { DetailTableType, ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { RoutePath } from "routes/route-paths";
import { useElementAssets } from "./api";

const AssetTable = ({ elementNameInUrl, selectedElement, tableTitle }) => {
  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const { isRiskAnalyst } = useRoles();
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  const columns = useMemo(() => {
    if (isRiskAnalyst) {
      return [createColumnNameDetails(RoutePath.Asset.replace(":id", "")), createColumnMappedNoEdit("category")];
    }
    return [createColumnMappedNoEdit("name"), createColumnMappedNoEdit("category")];
  }, [isRiskAnalyst]);

  const [, setSelectedAsset] = useState("");

  const {
    data: assets,
    isError: isAssetsError,
    error: assetError,
  } = useElementAssets({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId,
    config: {
      enabled:
        !!selectedElement?.id &&
        !!elementNameInUrl &&
        elementNameInUrl !== DetailTableType.SYSTEM_ASSET_TYPES.key &&
        elementNameInUrl !== DetailTableType.VULNERABILITIES.key,
    },
  });
  const {
    data: vulnerabilityAssetsData,
    isError: isVulnerabilityAssetsDataError,
    error: vulnerabilityAssetsDataError,
  } = BrmGql.useGetVulnerabilityAssetQuery(
    { project: projectId, id: selectedElement.id, variant: variantId },
    {
      enabled: !!selectedElement.id && elementNameInUrl === DetailTableType.VULNERABILITIES.key,
      refetchOnWindowFocus: false,
    }
  );

  const data = useMemo(() => {
    let assetData = null;
    if (assets || vulnerabilityAssetsData) {
      if (elementNameInUrl === DetailTableType.VULNERABILITIES.key) {
        const res = vulnerabilityAssetsData?.vulnerability.asset.map((el) => {
          return { ...el, category: el.category.name };
        });
        assetData = res || [];
      } else if (Array.isArray(assets)) {
        assetData = assets;
      } else {
        assetData = assets.id === COMMON.nullUuid ? [] : [assets];
      }
    }
    return assetData;
  }, [assets, elementNameInUrl, vulnerabilityAssetsData]);

  if (isAssetsError) {
    return <ErrorBanner msg={assetError?.statusText} />;
  }

  if (isVulnerabilityAssetsDataError) {
    return <ErrorBanner msg={vulnerabilityAssetsDataError?.message} />;
  }

  if (elementNameInUrl === DetailTableType.SYSTEM_ASSET_TYPES.key) {
    return <CenterText msg="Assets are not applicable" />;
  }

  if (data) {
    return (
      <S.DetailsContainer id="AssetTable_detailsPanel">
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedAsset}
              customProps={{ id: "AssetTable_detailsTable" }}
              showRowSelect={false}
              elementName={elementNameInUrl}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

AssetTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  tableTitle: PropTypes.string,
};

export default AssetTable;
