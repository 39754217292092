import * as BrmGql from "generated/graphql";

export const activitiesKeys = {
  all: ["activities"] as const,
  activities: (projectId: string | null) => [...activitiesKeys.all, projectId],
  details: (
    elementId: string | null,
    elementName: string | null,
    dataTypeActivitiesData: BrmGql.GetDataTypeActivitiesQuery | undefined,
    missionActivitiesData: BrmGql.GetMissionActivitiesQuery | undefined
  ) =>
    [
      ...activitiesKeys.all,
      "elementActivities",
      elementId,
      elementName,
      dataTypeActivitiesData,
      missionActivitiesData,
    ] as const,
  person: (activityId: string) => [...activitiesKeys.all, activityId, "person"] as const,
  resources: (activityId: string) => [...activitiesKeys.all, activityId, "resource"] as const,
  capability: (activityId: string) => [...activitiesKeys.all, activityId, "capability"] as const,
};
