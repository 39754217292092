import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./threat-catalog-query-key-factory";

/**
 * Returns name of the threat catalog
 *
 * @param {string} threatCatalogId - uuid of the threat catalog
 *
 */
export const getThreatCatalogName = ({ threatCatalogId }: { threatCatalogId: string }) => {
  return Brm.threatCatalogApi.getThreatCatalogName(threatCatalogId);
};

interface IUseThreatCatalogName {
  threatCatalogId: string;
  options?: QueryOption<typeof getThreatCatalogName>;
}

/**
 * Custom hook to get name of threat catalog.
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for getThreatCatalogName
 */
export const useThreatCatalogName = ({ threatCatalogId, options = {} }: IUseThreatCatalogName) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.threatCatalogKeys.name(threatCatalogId),
    queryFn: () => getThreatCatalogName({ threatCatalogId }),
  });
};
