export const STATUS = {
  active: "Active",
  deleted: "Deleted",
  archived: "Archived",
  sandbox: "SANDBOX",
  busy: "Busy",
  suspended: "Suspended",
  inPreparation: "InPreparation",
  importing: "Importing",
  generating: "Generating",
  mitigating: "Mitigating",
} as const;
