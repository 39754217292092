import toast from "react-hot-toast";
import { useState } from "react";
import PropTypes from "prop-types";

// Components
import SelectBoxCategory from "components/forms/DynamicSelectBoxCategory";
import TextField from "components/forms/TextField";
// Constants
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
// State
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
// Services
import { SystemAssetTypeCreateDto } from "@kdmanalytics/brm-system";
import { SystemApi } from "features/brm";
import { validateNameUniqueness } from "features/system-model";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";

const AddSystemAssetTypeForm = ({ setModalIsOpen }) => {
  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = useState("");
  const [isTextValid, setIsTextValid] = useState(true);
  const createSystemAssetType = SystemApi.useCreateSystemAssetType();
  const { data: saTypesList, isError: isSaTypesError } = SystemApi.useSystemAssetsTypes({
    projectId,
    options: { enabled: !!projectId },
  });
  const { data: categories, isError: isCategoriesError } = SystemApi.useSystemAssetCategories({
    projectId,
    options: { enabled: !!projectId },
  });

  function validate(formData) {
    let error = true;
    if (formData.name.value === "" || formData.category.value === "Select category") {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isNameValid = validateNameUniqueness(saTypesList, e.target.elements.name.value);
    if (!isNameValid) {
      setPostError(FORM_ERROR.duplicateSaTypeName);
      return null;
    }

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value || "",
        category: e.target.elements.category.value,
        cpe: e.target.elements.cpe.value,
      };

      const systemAssetTypeCreateDto = SystemAssetTypeCreateDto.constructFromObject(params);
      createSystemAssetType.mutate(
        { projectId, systemAssetTypeCreateDto },
        {
          onSettled: () => {
            setModalIsOpen(false);
          },
          onError: (err) => {
            toast.error(`Creating system asset type object caused an error: ${err}`);
          },
        }
      );
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  if (isSaTypesError || isCategoriesError) {
    return <ErrorBanner msg="Error while loading related data for add system asset type" />;
  }

  if (categories) {
    return (
      <div>
        <form onSubmit={handleSubmit} action="">
          <FormStyled>
            <div className="form-style">
              <TextField
                label={{ id: "AddSystemAssetTypeForm_name", name: FORM_LABEL.nameMandatory }}
                input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
                isTextValid={(inputString) => {
                  const inputStr = inputString.trim();
                  return Boolean(inputStr.match(/[$&+,;?@#|'<>^*()%!]+/)) && inputStr.length > 0;
                }}
              />
              <TextField
                label={{ id: "AddSystemAssetTypeForm_note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />

              <label id="AddSystemAssetTypeForm_systemAssetCategory">{FORM_LABEL.systemAssetCategoryMandatory}</label>
              <SelectBoxCategory
                arrayOfData={categories}
                name="category"
                item="AddSystemAssetTypeForm_categoryDropdown"
              />
              <label id="AddSystemAssetTypeForm_cpe">{FORM_LABEL.cpeOptional}</label>
              <input id="cpe" className="inputField" type="text" name="cpe" placeholder="CPE Code" />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
            <DialogButtonStyled type="submit">Add</DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }

  return <Loading />;
};

AddSystemAssetTypeForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};

export default AddSystemAssetTypeForm;
