import { atom } from "recoil";

// NOTE:
// Atoms or Selectors can represent either primitives, arrays, or complex objects.
// But, subscriptions are only handled at an atom/selector granularity.
// We've found it a best-practice to keep atom values rather simple.

/**
 * Logged in user id
 */
export const userIdState = atom<string | null>({
  key: "userId",
  default: null,
});

/**
 * Logged in users, current role
 */
export const userRoleState = atom<string | null>({
  key: "userRole",
  default: null,
});

/**
 * Logged in users, assigned roles, stored as role keys
 */
export const userAssignedRolesState = atom({
  key: "userAssignedRoles",
  default: [],
});

/**
 * The active project id
 */
export const projectIdState = atom<string>({
  key: "projectId",
  default: "",
});

export const projectNameState = atom<string | null>({
  key: "projectNameState", // unique ID (with respect to other atoms/selectors)
  default: null,
});

export const levelsEnumState = atom({
  key: "levelsEnumState",
  default: [
    { id: "very high", name: "near certainty" },
    { id: "high", name: "probable" },
    { id: "moderate", name: "occasional" },
    { id: "low", name: "remote" },
    { id: "very low", name: "improbable" },
  ],
});

export const isReadOnlyState = atom({
  key: "readOnlyState",
  default: false,
});

export const isProjectBusyState = atom({
  key: "isProjectBusyState",
  default: false,
});

export const projectStatusState = atom<string | null>({
  key: "projectStatusState",
  default: null,
});

export const isPublishingState = atom<boolean>({
  key: "isPublishingState",
  default: false,
});

// export const isSapEnabled = atom<boolean>({
//   key: "isSapEnabled",
//   default: false,
// });
