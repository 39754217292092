import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-settings-query-key-factory";

/**
 * Gets the GlobalSettings objects
 */
export const getGlobalSetting = ({ name }: { name: string }) => {
  return Brm.globalSettingApi.findGlobalSetting({ name });
};

type FuncType = typeof getGlobalSetting;

interface ISettingsProps {
  name: string;
  options?: QueryOption<FuncType>;
}

/**
 * Custom hook for getting the GlobalSettings objects
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useGlobalSetting = ({ name, options = {} }: ISettingsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.globalSettingKeys.setting(name),
    queryFn: () => getGlobalSetting({ name }),
    meta: {
      errorMessage: `Failed to fetch global setting: ${name}`,
    },
  });
};
