/* eslint-disable react/jsx-props-no-spreading */
// import Select as ReactSelect, { Props, GroupBase } from "react-select";
import * as React from "react";
import * as ReactSelect from "react-select";
import { IOption } from "types";

const WrappedSelect = ReactSelect.default;

export const selectListStyle = {
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: "150px",
    overflowY: "auto",
  }),
  dropdownIndicator: () => ({
    padding: "8px",
    color: "#005ca6",
  }),
  control: (base: any) => ({
    ...base,
    borderRadius: "5px",
  }),
};

// export const Select = <
//   Option,
//   IsMulti extends boolean = false,
//   Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
// >(
//   props: ReactSelect.Props<Option, IsMulti, Group>
// ) => {
//   return <WrappedSelect {...props} styles={selectListStyle} theme={(theme) => ({ ...theme, borderRadius: 0 })} />;
// };

const selectAllOption: IOption = { value: "*", label: "Select All" };
const isSelectAllOption = (o: any) => o.value === selectAllOption.value;

type RefCallback = (instance: any) => void;

export const Select = React.forwardRef<RefCallback, any>((props, ref) => {
  return (
    <WrappedSelect ref={ref} {...props} styles={selectListStyle} theme={(theme) => ({ ...theme, borderRadius: 0 })} />
  );
});
Select.displayName = "Select";

// /** **************************************** WORKING but select all visible *************************************************** */

// export const MultiSelect = <
//   Option,
//   IsMulti extends boolean = false,
//   Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
// >({
//   options,
//   onChange,
//   ...rest
// }: ReactSelect.Props<Option, IsMulti, Group>) => {
//   let allOptions: any;
//   if (Array.isArray(options)) {
//     allOptions = [selectAllOption, ...options];
//   }

//   const handleOnChange = (
//     option: ReactSelect.OnChangeValue<Option, IsMulti>,
//     actionMeta: ReactSelect.ActionMeta<Option>
//   ) => {
//     if (onChange === undefined) {
//       return;
//     }
//     if (option !== null && Array.isArray(option) && option.length && option.some(isSelectAllOption)) {
//       const opt = allOptions as unknown as ReactSelect.OnChangeValue<Option, IsMulti>;
//       onChange(opt, actionMeta);
//     } else {
//       onChange(option, actionMeta);
//     }
//   };

//   return (
//     <WrappedSelect
//       options={allOptions}
//       isMulti={true as IsMulti}
//       closeMenuOnSelect={false}
//       onChange={handleOnChange}
//       {...rest}
//     />
//   );
// };

const MultiSelectInner = <
  Option,
  IsMulti extends boolean = true,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
>(
  { options, onChange, defaultValue, ...rest }: ReactSelect.Props<Option, IsMulti, Group>,
  ref: React.ForwardedRef<RefCallback>
) => {
  const [isSelectAllVisible, setIsSelectAllVisible] = React.useState(true);

  const allOptions = React.useMemo(() => {
    if (isSelectAllVisible && Array.isArray(options) && options.length) {
      return [selectAllOption, ...options] as ReactSelect.OptionsOrGroups<Option, Group>;
    }
    return options;
  }, [options, isSelectAllVisible]);

  const [selectedValues, setSelectedValues] = React.useState<any>(defaultValue);

  const handleOnChange = (
    selectedOptions: ReactSelect.OnChangeValue<Option, IsMulti>,
    actionMeta: ReactSelect.ActionMeta<Option>
  ) => {
    if (Array.isArray(selectedOptions) && Array.isArray(options)) {
      const isSelectAllOptionSelected = selectedOptions.some(isSelectAllOption);
      if (isSelectAllOptionSelected || selectedOptions.length === options.length) {
        setIsSelectAllVisible(false);
      } else if (selectedOptions.length !== options.length) {
        setIsSelectAllVisible(true);
      }
      const opt = isSelectAllOptionSelected ? options : selectedOptions;
      setSelectedValues(opt);
      if (onChange) {
        onChange(opt as unknown as ReactSelect.OnChangeValue<Option, IsMulti>, actionMeta);
      }
    }
  };
  return (
    <Select
      ref={ref}
      isMulti={true as IsMulti}
      options={allOptions}
      {...rest}
      value={selectedValues}
      defaultValue={selectedValues}
      styles={selectListStyle}
      onChange={handleOnChange}
    />
  );
};

export const MultiSelect = React.forwardRef(MultiSelectInner);
//  as <
//   Option,
//   IsMulti extends boolean,
//   Group extends ReactSelect.GroupBase<Option>
// >(
//   props: ReactSelect.Props<Option, IsMulti, Group> & { ref?: React.ForwardedRef<HTMLElement> }
// ) => ReturnType<typeof MultiSelect2Inner>;

// export const MultiSelect = <
//   Option,
//   IsMulti extends boolean = true,
//   Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
// >({
//   options,
//   onChange,
//   defaultValue,
//   ...rest
// }: ReactSelect.Props<Option, IsMulti, Group>) => {
//   const [isSelectAllVisible, setIsSelectAllVisible] = React.useState(true);

//   const allOptions = React.useMemo(() => {
//     if (isSelectAllVisible && Array.isArray(options)) {
//       return [selectAllOption, ...options] as ReactSelect.OptionsOrGroups<Option, Group>;
//     }
//     return options;
//   }, [options, isSelectAllVisible]);

//   const [selectedValues, setSelectedValues] = React.useState<any>(defaultValue);

//   const handleOnChange = (
//     selectedOptions: ReactSelect.OnChangeValue<Option, IsMulti>,
//     actionMeta: ReactSelect.ActionMeta<Option>
//   ) => {
//     if (Array.isArray(selectedOptions) && Array.isArray(options)) {
//       const isSelectAllOptionSelected = selectedOptions.some(isSelectAllOption);
//       if (isSelectAllOptionSelected || selectedOptions.length === options.length) {
//         setIsSelectAllVisible(false);
//       } else if (selectedOptions.length !== options.length) {
//         setIsSelectAllVisible(true);
//       }
//       const opt = isSelectAllOptionSelected ? options : selectedOptions;
//       setSelectedValues(opt);
//       if (onChange) {
//         onChange(opt as unknown as ReactSelect.OnChangeValue<Option, IsMulti>, actionMeta);
//       }
//     }
//   };
//   return (
//     <WrappedSelect
//       isMulti={true as IsMulti}
//       options={allOptions}
//       {...rest}
//       value={selectedValues}
//       defaultValue={selectedValues}
//       styles={selectListStyle}
//       onChange={handleOnChange}
//     />
//   );
// };
