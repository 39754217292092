import * as React from "react";
import { ProjectState } from "features/brm";
import { ICreateProjectInput } from "./types";

interface ICreateProjectContext {
  next: () => void;
  prev: () => void;
  close: () => void;
  onConfigure: () => void;
  // onError: (error: string) => void;
  createProject: (data: ICreateProjectInput) => void;
  projectCreationError: any;
  setProjectCreationError: (value: any) => void;
  projectState: ProjectState | undefined;
}

export const CreateProjectContext = React.createContext<ICreateProjectContext | null>(null);

export const useCreateProjectContext = () => {
  const context = React.useContext(CreateProjectContext);

  if (!context) {
    throw new Error("useCreateProjectContext has to be used within <CreateProjectContext.Provider>");
  }
  return context;
};
