export const RECORD_KEY = {
  duration: "Duration (days)",
  expiryDate: "Expiry date",
  hostId: "Host ID",
  issueDate: "Issue date",
  sessionsMax: "Max sessions",
  userName: "User name",
  userSessionsMax: "Max sessions per use",
};

export const EXPORT_FILE = {
  format: "JSON",
  name: "BORROW-LICENSE",
};
