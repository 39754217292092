import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./capability-query-key-factory";

/**
 * Returns a list of capabilities
 *
 * @param {string} projectId - uuid of project
 * @returns a capability
 */
export const getCapabilities = ({ projectId }: { projectId: string }) => {
  return Brm.capabilityApi.findCapability(projectId);
};

interface IUseCapabilities {
  projectId: string;
  options: QueryOption<typeof getCapabilities>;
}

/**
 * Return a list of capabilities.
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query configuration object
 * @returns react-query-data object for project capabilities
 */
export const useCapabilities = ({ projectId, options }: IUseCapabilities) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.capabilityKeys.project(projectId),
    queryFn: () => getCapabilities({ projectId }),
  });
};
