import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

export interface ISetNodeGroup {
  nodeId: string;
  groupId: string | null;
}

/**
 * Sets the group of a node
 *
 * @param {string} nodeId - uuid of the node
 * @param {string} groupId - uuid of group
 */
export const setNodeGroup = ({ nodeId, groupId }: ISetNodeGroup) => {
  return Brm.nodeApi.setNodeParent(nodeId, { body: groupId });
};

export interface IUseSetNodeGroup {
  options?: MutationOption<typeof setNodeGroup>;
}

/**
 * Custom hook to change the group of an  existing node
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetNodeGroup = ({ options }: IUseSetNodeGroup = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.nodeKeys.all);
      queryClient.invalidateQueries(KeyFactory.nodeKeysGraphQl.getNodeParents);
      queryClient.invalidateQueries(KeyFactory.nodeKeysGraphQl.systemDiagramData);
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: setNodeGroup,
  });
};
