import styled from "styled-components";

interface ISeparator {
  width: string;
  margin: string;
  padding: string;
}

const toolbarStyles = {
  Vertical: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 10;
    width: ${({ theme }) => theme.mainToolbars.width};
    background: #fafbfc;
    padding: ${({ theme }) => theme.mainToolbars.padding};
    align-items: center;
    gap: 15px;

    & button:enabled {
      svg:hover {
        fill: ${({ theme }) => theme.colors.buttonHover};
      }
    }

    border-right: 2px solid #ebecf0;
    border-left: 2px solid #ebecf0;
    padding-top: 45px;
  `,
  Horizontal: styled.div`
    display: flex;
    width: 300px;
    padding: 6px 8px;
  `,
  SeparatorDiv: styled.div<ISeparator>`
    width: ${(props) => props?.width || "100%"};
    padding: ${(props) => props?.padding || "3px"};
    border-bottom: 2px solid ${({ theme }) => theme.colors.highContrastBackground};
    margin: ${(props) => props?.margin};
  `,
};

export default toolbarStyles;
