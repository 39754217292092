import { useState } from "react";
// Styles
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

// Local
import AccessPointTable from "./tables/AccessPointTable";

function AccessPoint() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const accessPointHeader = <Title id="AccessPoint_title">Access Points</Title>;

  const accessPointMain = (
    <>
      <AccessPointTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="AccessPoint_page" header={accessPointHeader} main={accessPointMain} />;
}

export default AccessPoint;
