/* eslint-disable no-param-reassign */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./impact-query-key-factory";

export const getUndesiredEvent = async ({ ueId, variantId }: { ueId: string; variantId: string }) => {
  const ue = await Brm.undesiredEventApi.getUndesiredEventByID(ueId, { variant: variantId });
  ue.asset = await Brm.undesiredEventApi.getUndesiredEventAsset(ueId);
  ue.objective = await Brm.undesiredEventApi.getUndesiredEventObjective(ueId);
  ue.object = await Brm.undesiredEventApi.getUndesiredEventObject(ueId);

  return ue;
};

interface IUseGetUndesiredEvent {
  ueId: string;
  variantId: string;
  options?: QueryOption<typeof getUndesiredEvent>;
}

/**
 * Custom hook to retreive an undesired event.
 *
 * @param {string} ueId - uuid of a undesired event
 * @param {string} variantId - uuid of the current variant
 * @param {Object} options - configuration object
 * @returns react-query for getUndesiredEvent
 */
export const useUndesiredEvent = ({ ueId, variantId, options }: IUseGetUndesiredEvent) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.undesiredEventKeys.undesiredEvent(ueId, variantId),
    queryFn: () => getUndesiredEvent({ ueId, variantId }),
  });
};
