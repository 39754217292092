import { useState, useEffect } from "react";
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import { useRoles } from "features/brm/hooks/useRoles";
import Title from "components/elements/StyledTitle";
import { TYPE } from "constants/brm";
import { AddButton, DeleteButton } from "features/brm";
// Styles
import Buttons from "components/elements/TableButtonsDivStyled";
import ContentLayout from "brm/layout/Content/ContentLayout";

import AttackerTable from "./tables/AttackerTable";
import AttackerAddForm from "./forms/AttackerAddForm";
import { useDeleteAttacker } from "./api";

function Attacker() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { isRiskAnalyst } = useRoles();
  const { mutate: deleteAttacker } = useDeleteAttacker();
  useEffect(() => {
    async function multiDeleteApiCalls() {
      await Promise.all(selectedRows.map((each) => deleteAttacker({ attackerId: each.id })));
      setConfirmDelete(false);
      setSelectedRows([]);
      setDeleteModalIsOpen(false);
      setSelectedInstance({});
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, deleteAttacker, selectedRows, setSelectedInstance]);

  const header = (
    <Title id="Attacker_title">
      Attackers
      <Buttons id="Attacker_buttons">
        {selectedRows && selectedRows.length > 0 && <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />}
        {!isRiskAnalyst && <AddButton onClick={() => setModalIsOpen(true)} />}
      </Buttons>
    </Title>
  );

  const main = (
    <>
      {deleteModalIsOpen &&
        systemModals.deleteModal(
          "Attacker_page",
          deleteModalIsOpen,
          setDeleteModalIsOpen,
          <GenericDeleteConfirmation
            elementName={TYPE.attacker}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            selectedRows={selectedRows}
            setRefresh={setRefresh}
            setConfirmDelete={setConfirmDelete}
          />,
          TYPE.attacker
        )}

      {modalIsOpen &&
        systemModals.addModal(
          "Attacker_page",
          modalIsOpen,
          setModalIsOpen,
          <AttackerAddForm setModalIsOpen={setModalIsOpen} setRefresh={setRefresh} />,
          TYPE.attacker
        )}

      <AttackerTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="Attacker_page" header={header} main={main} />;
}

export default Attacker;
