import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./installation-query-key-factory";

interface IInstallationStatusProps {
  installationId: string | null;
  newAdmin: string;
}

/**
 * Upload the given file as the image for the CD
 *
 * @param param0 upload file data
 * @returns
 */
export const setInstallationAdmin = ({ installationId, newAdmin }: IInstallationStatusProps) => {
  return installationId === null
    ? Promise.reject(new Error("Invalid installationId id"))
    : Brm.installationApi.setInstallationAdmin(installationId, { body: newAdmin });
};

interface ISetNameProps {
  options?: MutationOption<typeof setInstallationAdmin>;
}

/**
 * Set the admin for the an installation.
 *
 * @returns react-query Mutation.
 */
export const useSetInstallationAdmin = ({ options }: ISetNameProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, err, variables) => {
      queryClient.invalidateQueries(KeyFactory.installationKeys.all);
      queryClient.invalidateQueries(KeyFactory.installationKeys.installation(variables.installationId));
    },
    ...options,
    mutationFn: setInstallationAdmin,
  });
};
