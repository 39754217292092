import { useEffect, useState, useMemo, useRef, useCallback } from "react";

import errorUtils from "utils/error-utils";
import * as Brm from "services/brm";
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";
import { systemApi } from "services/brm/component-service";
import PropTypes from "prop-types";
import handleApi from "services/brm/api-util";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";

import {
  createColumnName,
  createColumnActions,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm/hooks/useRoles";

export const SystemTable = ({ setSelectedRows }) => {
  const [refresh, setRefresh] = useState(true);
  const { isRiskAnalyst, isSystemEngineer, isThreatAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const [systemListData, setSystemListData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedName.current !== "") {
              await handleApi(
                systemApi.setSystemNameWithHttpInfo(selectedInstance.id, {
                  body: updatedName.current,
                })
              );
              setSelectedInstance({
                id: selectedInstance.id,
                name: updatedName.current,
                type: selectedInstance.type,
              });
              updatedName.current = "";
            }

            setRefresh(true);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [editMode, setRefresh, selectedInstance.id, selectedInstance.type, setSelectedInstance]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "system"),
        createColumnMappedNoEdit("catalog"),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    if (isRiskAnalyst || isThreatAnalyst) {
      return [
        createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "system"),
        createColumnMappedNoEdit("catalog"),
      ];
    }
    return [createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "system")];
  }, [
    isSystemEngineer,
    isRiskAnalyst,
    isThreatAnalyst,
    selectedInstance.id,
    setSelectedInstance,
    editMode,
    createButton,
  ]);

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    async function getData() {
      setRefresh(false);
      try {
        const [catalog, systemData] = await Promise.all([
          Brm.projectApi.getProjectCatalog(projectId),
          systemApi.findSystemWithHttpInfo(projectId),
        ]);
        if (systemData?.data) {
          errorUtils.checkErrorStatus(systemData.response.status, systemData.response.statusText);
          const systemListdata = [{ ...systemData?.data, catalog }];
          setSystemListData(systemListdata);
        }
      } catch (err) {
        console.error("err from get all system : ", err);
      }
    }
    if (refresh) {
      getData();
    }
  }, [refresh, projectId]);

  return (
    <>
      <CustomTable>
        <BrmMainTable
          data={systemListData}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "SystemTable_table" }}
          showRowSelect={false}
          elementName={TYPE.system}
        />
      </CustomTable>
    </>
  );
};

SystemTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};
