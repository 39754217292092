import * as React from "react";

import errorUtils from "utils/error-utils";
import SelectBox from "components/forms/DynamicSelectBoxCategory";
import SelectBoxById from "components/forms/DynamicSelectBox";
import { stringToBoolean } from "utils/boolean-checker";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import SystemService, { activityApi } from "services/brm/system-service";
import { ActivityCreateDto } from "@kdmanalytics/brm-system";
import PropTypes from "prop-types";
import Select from "react-select";
import handleApi from "services/brm/api-util";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import BooleanDropdown from "components/forms/BooleanDropdown";
import { sortByName, sortUpperCase } from "utils/sorting";
import TextField from "components/forms/TextField";
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
import { useQueryClient } from "@tanstack/react-query";
import { LoadingSpinner as Loading } from "components/elements";
import { SystemApi } from "features/brm";
import { validateNameUniqueness } from "features/system-model";

const SELECT_CATEGORY = "Select Category";
const SELECT_NODE = "Select Node";

const AddActivityForm = ({ setModalIsOpen }) => {
  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = React.useState("");
  const [selectedNext, setSelectedNext] = React.useState([]);
  const [selectedStoredData, setSelectedStoredData] = React.useState([]);
  const [isTextValid, setIsTextValid] = React.useState(true);

  const queryClient = useQueryClient();
  const { data: elementCategories } = SystemApi.useElementCategories({
    projectId,
    options: {
      select: React.useCallback((data) => data.sort(sortUpperCase), []),
    },
  });

  const { data: nodes } = SystemApi.useNodes({
    projectId,
    options: {
      select: React.useCallback((data) => data.sort(sortByName), []),
    },
  });

  const { data: datatypes } = SystemApi.useDataTypes({
    projectId,
    options: {
      select: React.useCallback((data) => data.map((d) => ({ value: d.id, label: d.name })), []),
    },
  });

  const { data: activities } = SystemApi.useActivities({
    projectId,
    options: {
      select: React.useCallback((data) => data.map((d) => ({ value: d.id, label: d.name, name: d.name })), []),
    },
  });

  // validate that all fields in the form have been completed
  function validate(formData) {
    let error = true;
    if (
      formData.name.value === "" ||
      formData.category.value === SELECT_CATEGORY ||
      formData.isInternal.value === null ||
      formData.isInitial.value === null ||
      formData.node.value === SELECT_NODE ||
      formData.isFinal.value === null
    ) {
      error = false;
    }
    return error;
  }

  const handleStoredDataSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedStoredData(items);
  };

  const handleNextSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedNext(items);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    const isNameValid = validateNameUniqueness(activities, e.target.elements.name.value);
    if (!isNameValid) {
      setPostError(FORM_ERROR.duplicateActivityeName);
      return null;
    }

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value || "",
        category: e.target.elements.category.value,
        isInternal: stringToBoolean(e.target.elements.isInternal.value),
        isInitial: stringToBoolean(e.target.elements.isInitial.value),
        isFinal: stringToBoolean(e.target.elements.isFinal.value),
        node: e.target.elements.node.value,
      };

      const dto = ActivityCreateDto.constructFromObject(params);
      try {
        const res = await SystemService.createActivity(projectId, dto);

        if (selectedNext.length > 0) {
          selectedNext.forEach(async (item) => {
            await handleApi(
              activityApi.addActivityNextWithHttpInfo(res.data, {
                body: item.value,
              })
            );
          });
        }

        if (selectedStoredData.length > 0) {
          selectedStoredData.forEach(async (item) => {
            await handleApi(
              activityApi.addActivityStoredDataWithHttpInfo(res.data, {
                body: item.value,
              })
            );
          });
        }

        errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
        setModalIsOpen(false);
        // setRefresh(true);
        queryClient.invalidateQueries(["activities", projectId]);
      } catch (error) {
        console.error(error);
      }
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  if (elementCategories && nodes && datatypes && activities) {
    return (
      <div id="ActivityAddForm" style={{ maxHeight: "95vh", overflow: "auto" }}>
        <form onSubmit={handleSubmit} action="" id="ActivityAddForm_form">
          <FormStyled>
            <div className="form-style">
              <TextField
                label={{ id: "ActivityAddForm_Name", name: FORM_LABEL.nameMandatory }}
                input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "ActivityAddForm_Note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />
              <BooleanDropdown
                label={{ id: "ActivityAddForm_isInternal", name: FORM_LABEL.internalMandatory }}
                select={{ id: "isInternal", name: "isInternal" }}
              />
              <label id="ActivityAddForm_Category">{FORM_LABEL.categoryMandatory}</label>
              <SelectBox
                id="ActivityAddForm_categoryDropdown"
                arrayOfData={elementCategories}
                name="Category"
                item="category"
              />
              <label id="ActivityAddForm_Node">{FORM_LABEL.nodeMandatory}</label>
              <SelectBoxById id="ActivityAddForm_nodeDropdown" arrayOfData={nodes} name="Node" item="node" />

              <BooleanDropdown
                label={{ id: "ActivityAddForm_isInitial", name: FORM_LABEL.initialMandatory }}
                select={{ id: "isInitial", name: "isInitial" }}
              />

              <BooleanDropdown
                label={{ id: "ActivityAddForm_isFinal", name: FORM_LABEL.finalMandatory }}
                select={{ id: "isFinal", name: "isFinal" }}
              />

              <label id="ActivityAddForm_StoredData">{FORM_LABEL.storedDataOptional}</label>
              <Select
                isMulti
                options={datatypes}
                defaultValue={selectedStoredData}
                onChange={handleStoredDataSelectChange}
                id="ActivityAddForm_dataDropdown"
                classNamePrefix="dataDropdown"
              />
              <br />
              <label id="ActivityAddForm_Next">{FORM_LABEL.nextOptional}</label>
              <Select
                isMulti
                options={activities}
                defaultValue={selectedNext}
                onChange={handleNextSelectChange}
                id="ActivityAddForm_nextDropdown"
                classNamePrefix="nextDropdown"
              />
            </div>
          </FormStyled>
          <FormStyledError id="ActivityAddForm_error">{postError}</FormStyledError>
          <DialogButtonDivStyled id="ActivityAddForm_buttons">
            <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="ActivityAddForm_cancelButton">
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled type="submit" id="ActivityAddForm_addButton">
              Add
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }
  return <Loading />;
};

AddActivityForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};

export default AddActivityForm;
