import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";

export interface IImportKnowledgebase {
  kbId: string;
  file: any;
}

/**
 * Start processing an already uploaded submission
 *
 * @param {string} kbId - uuid of the kbId
 * @param {any} file - import context
 * @returns
 */
export const importKnowledgebase = async ({ kbId, file }: IImportKnowledgebase) => {
  return Brm.knowledgebaseApi.kbImport(kbId, { file });
};

interface IUseKnowledgebase {
  options?: MutationOption<typeof importKnowledgebase>;
}

/**
 * Custom hook to start processing an already uploaded submission
 *
 * @param {object} options - react-query options
 */
export const useImportKnowledgebase = ({ options }: IUseKnowledgebase = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: importKnowledgebase,
  });
};
