import * as React from "react";
import { useSearchParams } from "react-router-dom";

import ContentLayout from "brm/layout/Content/ContentLayout";

import { UrlSearchParams } from "features/brm";
import { IContentObj } from "features/help/types";

// Local
import { HelpFilters, HelpTable, HelpArticle } from "features/help/components";
import * as S from "./Help.styles";

export const Help = () => {
  const [searchParams] = useSearchParams();
  const userRole = searchParams.get(UrlSearchParams.userRole);
  const contentKey = searchParams.get(UrlSearchParams.contentKey);
  const [, setSelectedRows] = React.useState([]);
  const [articleType, setArticleType] = React.useState("");
  const [articleName, setArticleName] = React.useState("");
  const [articleVideoPath, setArticleVideoPath] = React.useState<string | undefined>("");
  const [articleFaqResponse, setArticleFaqResponse] = React.useState<string | undefined>("");

  const [userRoles, setUserRoles] = React.useState<string[]>(() => {
    return userRole ? [userRole] : [];
  });

  const [contexts, setContexts] = React.useState<string[]>(() => {
    return contentKey ? [contentKey] : [];
  });

  const handleRoleChange = (roles: Readonly<any[]>) => {
    if (Array.isArray(roles)) {
      setUserRoles(roles.map((r) => r.value));
    }
  };

  const handleContextChange = (context: Readonly<any[]>) => {
    if (Array.isArray(context)) {
      setContexts(context.map((c) => c.value));
    }
  };

  const handleArticleOnClick = React.useCallback(({ type, name, filePath, response }: IContentObj) => {
    setArticleType(type);
    setArticleName(name);
    setArticleVideoPath(filePath);
    setArticleFaqResponse(response);
  }, []);

  const helpMain = (
    <>
      <HelpFilters
        rolesDisplayed={userRoles}
        onRoleChange={handleRoleChange}
        userRole={userRole || ""}
        onContextChange={handleContextChange}
        contentKey={contentKey || ""}
      />
      <S.HelpContent>
        <S.HelpTable>
          <HelpTable
            setSelectedRows={setSelectedRows}
            handleArticleOnClick={handleArticleOnClick}
            contexts={contexts}
            userRoles={userRoles}
          />
        </S.HelpTable>
        <S.HelpArticle>
          <HelpArticle
            type={articleType}
            name={articleName}
            filePath={articleVideoPath}
            response={articleFaqResponse}
          />
        </S.HelpArticle>
      </S.HelpContent>
    </>
  );
  return <ContentLayout pageId="Help_page" main={helpMain} />;
};
