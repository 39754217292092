import * as Recoil from "recoil";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import ControlTypeTable from "brm/tables/details/ControlTypeTable";
import PropTypes from "prop-types";
import { ImporterPages } from "features/importer";
import { ImportContext } from "features/brm";
import { projectIdState } from "atoms/atoms-admin";
import { useControlCatalog } from "../../../hooks";

export const StandardBaselineTab = ({ selectedElement, elementNameInUrl }) => {
  const projectId = Recoil.useRecoilValue(projectIdState);

  const { isReadOnlyCatalog } = useControlCatalog();

  return (
    <Tabs>
      <TabList>
        <Tab>Control Types</Tab>
        <Tab>Importer Details</Tab>
      </TabList>

      <TabPanel>
        <ControlTypeTable
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          showAdd={!isReadOnlyCatalog}
          showDelete={!isReadOnlyCatalog}
        />
      </TabPanel>
      <TabPanel>
        {/* "No data found..." displayed until the API services useSubmissionStatus (BRMCD-6266) and getImportHistory (BRMCD-7119) are fixed. */}
        <ImporterPages projectId={projectId} context={ImportContext.baseline} />
      </TabPanel>
    </Tabs>
  );
};

StandardBaselineTab.propTypes = {
  selectedElement: PropTypes.object.isRequired,
  elementNameInUrl: PropTypes.string,
};
