import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./user-query-key-factory";

interface IUserFirstnameProps {
  userId: string | null;
  newFirstname: string;
}

/**
 * Set the users firstname
 *
 * @param param0 upload file data
 * @returns
 */
export const setUserFirstname = ({ userId, newFirstname }: IUserFirstnameProps) => {
  return userId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.userApi.setUserFirstname(userId, { body: newFirstname });
};

interface ISetNameProps {
  options?: MutationOption<typeof setUserFirstname>;
}

/**
 * mutate the user firstname.
 *
 * @returns react-query Mutation.
 */
export const useSetUserFirstname = ({ options }: ISetNameProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.userKeys.user(variables.userId));
      queryClient.invalidateQueries(KeyFactory.userKeys.all);
    },
    ...options,
    mutationFn: setUserFirstname,
  });
};
