import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./knowledgebase-query-key-factory";

/**
 * delete exisitng kb
 *
 * @param {string} kbId - uuid of exisitng kb
 *
 */
export const deleteKb = async ({ kbId }: { kbId: string }) => {
  return Brm.kbApi.deleteKB(kbId);
};

interface IUseDeleteKb {
  options?: MutationOption<typeof deleteKb>;
}

/**
 * custom hook to delete an existing kb .
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteKb = ({ options }: IUseDeleteKb = {}) => {
  return ReactQuery.useMutation({
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.knowledgebaseKeys.all);
    },
    mutationFn: deleteKb,
  });
};
