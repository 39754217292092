import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./data-type-query-key-factory";
/**
 * Gets the dataTypes
 */
export const getDataTypes = async ({ projectId }: { projectId: string | null }) => {
  return projectId === null
    ? Promise.reject(new Error("Invalid projectId id"))
    : Brm.dataTypeApi.findDataType(projectId);
};

interface IDataTypesProps {
  projectId: string | null;
  options?: QueryOption<typeof getDataTypes>;
}

/**
 * Custom hook for getting all dataTypes for a project
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useDataTypes = ({ projectId, options = {} }: IDataTypesProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.dataTypeKeys.project(projectId),
    queryFn: () => getDataTypes({ projectId }),
  });
};
