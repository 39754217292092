import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./resource-query-key-factory";

interface ICreateResourceProps {
  projectId: string;
  resourceCreateDto: any;
}

/**
 * Creates a resource.
 * @param {string} projectId uuid of the project
 * @param {ResourceCreateDto} resourceCreateDto a ResourceCreateDto object
 * @returns id of created resource
 */
export const createResource = ({ projectId, resourceCreateDto }: ICreateResourceProps) => {
  return Brm.resourceApi.createResource(projectId, { resourceCreateDto });
};

export interface IUseCreateResource {
  options?: MutationOption<typeof createResource>;
}

/**
 * Custom hook to create a new resource.
 *
 * @param {object} options - react-query configuration object
 * @returns react-query for getNodeByID
 */
export const useCreateResource = ({ options }: IUseCreateResource = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.resourceKeys.all);
    },
    ...options,
    mutationFn: createResource,
  });
};
