import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./categorized-entity-query-key-factory";

export interface ISetCategorizedEntityClassificationProps {
  categorizedEntityId: string | null;
  classificationId: string;
}

/**
 * Set the classification for an categorized entity.
 *
 * @param param0 entity and classification
 * @returns
 */
export const setCategorizedEntityClassification = ({
  categorizedEntityId,
  classificationId,
}: ISetCategorizedEntityClassificationProps) => {
  return categorizedEntityId === null
    ? Promise.reject(new Error("Invalid categorizedEntityId id"))
    : Brm.categorizedEntityApi.setCategorizedEntityClassification(categorizedEntityId, { body: classificationId });
};

interface ISetNameProps {
  options?: MutationOption<typeof setCategorizedEntityClassification>;
}

/**
 * Custom Hook to Set the classification for an categorized entity.
 *
 * @returns react-query Mutation.
 */
export const useSetCategorizedEntityClassification = ({ options }: ISetNameProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, err, variables) => {
      queryClient.invalidateQueries(KeyFactory.categorizedEntityKeys.categorizedEntity(variables.categorizedEntityId));
    },
    ...options,
    mutationFn: setCategorizedEntityClassification,
  });
};
