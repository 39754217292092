import * as React from "react";

// Styles
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";

// Components
import TextField from "components/forms/TextField";

// Constants
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";

// Local
import { IBorrowRequestProps } from "../../../types";

interface IExportAuditWizard {
  onBorrowRequest: IBorrowRequestProps;
  toggle: (value: boolean) => void;
}

export const BorrowRequestWizard = ({ onBorrowRequest, toggle }: IExportAuditWizard) => {
  const [isTextValid, setIsTextValid] = React.useState(true);
  const [postError, setPostError] = React.useState("");
  const [disableRequest, setDisableRequest] = React.useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const { elements } = e.target;
    if (postError) {
      setPostError("");
    }
    if (isTextValid && elements?.hostId?.value && elements?.duration?.value) {
      setDisableRequest(true);
      const params = {
        hostId: elements.hostId.value,
        duration: elements.duration.value,
      };
      onBorrowRequest(params);
      toggle(false);
    } else setPostError(FORM_ERROR.missingFields);
  };

  return (
    <div id="BorrowReqWizard">
      <form onSubmit={handleSubmit} action="" id="BorrowReqForm_form">
        <FormStyled>
          <div className="form-style" id="BorrowReqForm_formContent">
            <TextField
              label={{ id: "BorrowReqForm_Name", name: FORM_LABEL.borrowHostIdMandatory }}
              input={{ name: "hostId", placeholder: FORM_PLACEHOLDER.borrowHostId, disabled: disableRequest }}
              setIsTextValid={setIsTextValid}
            />
            <TextField
              label={{ id: "BorrowReqForm_Note", name: FORM_LABEL.borrowDurationMandatory }}
              input={{ name: "duration", placeholder: FORM_PLACEHOLDER.borrowDuration, disabled: disableRequest }}
              setIsTextValid={setIsTextValid}
            />
          </div>
        </FormStyled>
        <FormStyledError id="BorrowReqForm_Error">{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => toggle(false)}>Cancel</DialogButtonStyled>
          <DialogButtonStyled type="submit" id="Wizard_export" disabled={disableRequest}>
            {disableRequest ? "Generating..." : "Generate"}
          </DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};
