import { useMemo } from "react";
import { projectIdState } from "atoms/atoms-admin";
import { variantIdState } from "atoms/atoms-component";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";
import * as TrevApi from "brm/risk-model/trev/api";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import {
  createColumnName,
  createColumnMapped,
  createColumnLikelihoodMapped,
} from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm";

const ThreatEventTable = ({ setSelectedRows, setRefresh, setExportTableData }) => {
  const { isRiskAnalyst } = useRoles();

  const projectId = useRecoilValue(projectIdState);
  const variantId = useRecoilValue(variantIdState);
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data: trevs, isError } = TrevApi.useThreatEvents(projectId, variantId, {
    enabled: !!projectId && isRiskAnalyst,
  });

  const columns = useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, false, null, "threatEvent"),
      createColumnMapped("category", selectedInstance.id, null, false, null),
      createColumnMapped("pattern", selectedInstance.id, null, false, null),
      createColumnMapped("subject", selectedInstance.id, null, false, null),
      createColumnMapped("target", selectedInstance.id, null, false, null),
      createColumnLikelihoodMapped("likelihood"),
      createColumnLikelihoodMapped("mitigatedlikelihood"),
    ],
    [setSelectedInstance, selectedInstance.id]
  );

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  if (isError) {
    return <ErrorBanner msg="Error while loading Threat Event data" />;
  }

  if (trevs) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={trevs}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "ThreatEventTable_table" }}
            showRowSelect={false}
            elementName={TYPE.trev}
            setExportTableData={setExportTableData}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

ThreatEventTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  setExportTableData: PropTypes.func,
};

export default ThreatEventTable;
