// React
import * as React from "react";
import ReactDOM from "react-dom";
// import { setUseWhatChange } from "@simbathesailor/use-what-changed";

// utils
// import { initLogLevel } from "libs/loglevel";

// Styles
import "index.css";

// Locals
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloak, keycloakInitOptions } from "libs/keycloak";
import { onKeycloakEvent } from "services/keycloak";
import { CenteredSpinner } from "components/elements";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// setUseWhatChange(process.env.NODE_ENV === "development");

// initLogLevel();

ReactDOM.render(
  <React.StrictMode>
    {/* This provider must contain the app to ensure keycloak user information is loaded before recoil is intialized */}
    <ReactKeycloakProvider
      initOptions={keycloakInitOptions}
      authClient={keycloak}
      onEvent={onKeycloakEvent}
      autoRefreshToken={false}
      LoadingComponent={<CenteredSpinner text="Loading BRM..." />}
      // isLoadingCheck={() => true}
    >
      <App />
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
