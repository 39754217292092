import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";
import * as KeyFactory from "./capability-query-key-factory";

interface ISetCapabilbityNameProps {
  capabilityId: string;
  name: string;
}

/**
 * Sets the name of a capability
 *
 * @param {string} capabilityId - uuid of the capability
 * @param {string} name - new name of the capability
 */
export const setCapabilityName = ({ capabilityId, name }: ISetCapabilbityNameProps) => {
  return Brm.capabilityApi.setCapabilityName(capabilityId, { body: name });
};

export interface IUseSetCapabilityName {
  projectId: string;
  options?: MutationOption<typeof setCapabilityName>;
}

/**
 * Custom hook to change the name of an existing capability
 *
 * @param {string} projectId - uuid of the project
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetCapabilityName = ({ projectId, options = {} }: IUseSetCapabilityName) => {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation({
    ...options,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(KeyFactory.capabilityKeys.capability(variables.capabilityId));
      queryClient.invalidateQueries(KeyFactory.capabilityKeys.project(projectId));
    },
    mutationFn: setCapabilityName,
  });
};
