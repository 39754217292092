import * as React from "react";
import { useState } from "react";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import Form from "react-bootstrap/Form";
import * as S from "../styles/wizard.styles";
import * as DS from "./Dodaf.styles";

const ImportOption = {
  ViewType: "view-type",
  Config: "brm-config",
};

export const Dodaf = ({ options, handleSubmit, onCancel }: any) => {
  const [selectedValues, setSelectedValues] = useState(
    options[ImportOption.ViewType] ? options[ImportOption.ViewType].defaults[0] : ""
  );
  const [selectedConfigValues, setSelectedConfigValues] = useState<string[]>(options[ImportOption.Config]?.defaults);
  const [isSysMl, setIsSysMl] = useState(false);
  // const [isSysView, setIsSysView] = useState(false);

  function handleSelection(e: React.ChangeEvent<HTMLInputElement>) {
    setSelectedValues(e.target.value);
    // setIsSysView(e.target.value === "System");
  }

  const handleSubmission = () => {
    const opt = options[ImportOption.ViewType];
    opt.selection = [selectedValues];
    if (options[ImportOption.Config]) {
      const conf = options[ImportOption.Config];
      conf.selection = selectedConfigValues;
    }
    handleSubmit(options);
  };

  const handleConfigSelection = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValues = selectedConfigValues.map((v, i) => {
      if (i === index) {
        return event.target.checked ? "true" : "false";
      }
      return v;
    });
    setSelectedConfigValues(newValues);
  };

  return (
    <S.DodafWizard id="Dodaf">
      <DS.WizardTitle data-testid>DODAF WIZARD</DS.WizardTitle>
      <DS.SysmlFieldset>
        <DS.SysmlLegend>Importing SysML</DS.SysmlLegend>
        <Form.Check
          type="radio"
          label="Yes"
          checked={isSysMl}
          onChange={() => {
            setIsSysMl(true);
            setSelectedValues("");
            // setIsSysView(true);
          }}
        />
        <Form.Check
          type="radio"
          label="No"
          checked={!isSysMl}
          onChange={() => {
            setIsSysMl(false);
            setSelectedValues("Operational");
            // setIsSysView(false);
          }}
        />
      </DS.SysmlFieldset>
      <DS.SysmlFieldset>
        <DS.SysmlLegend>View Types</DS.SysmlLegend>
        {Object.entries(options) &&
          options[ImportOption.ViewType]?.values.map((each: string) => (
            <Form.Check
              key={each}
              type="radio"
              label={each}
              value={each}
              checked={each === selectedValues && !isSysMl}
              onChange={(e) => handleSelection(e)}
              disabled={isSysMl}
            />
          ))}
      </DS.SysmlFieldset>
      {options[ImportOption.Config] ? (
        <>
          <DS.SysmlFieldset>
            <DS.SysmlLegend>Configurations</DS.SysmlLegend>
            <DS.ConfigurationContainer>
              {Object.entries(options) &&
                options[ImportOption.Config].values.map((each: string, index: number) => (
                  <Form.Check
                    key={each}
                    type="checkbox"
                    label={each}
                    value={each}
                    checked={selectedConfigValues[index] === "true"}
                    onChange={(event) => handleConfigSelection(event, index)}
                  />
                ))}
            </DS.ConfigurationContainer>
          </DS.SysmlFieldset>
        </>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginLeft: "10px" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
            <AiOutlineExclamationCircle size="40px" fill="#6c757d" />
            <div>
              BRM determines the initial/optimal system configuration of your MBSE model. You can override the automated
              selection of the system configuration on subsequent imports of your MBSE model.
            </div>
          </div>
        </div>
      )}

      <DialogButtonDivStyled>
        <DialogButtonStyled onClick={onCancel} id="Dodaf_submit">
          Cancel
        </DialogButtonStyled>
        <DialogButtonStyled onClick={handleSubmission} id="Dodaf_submit">
          Submit
        </DialogButtonStyled>
      </DialogButtonDivStyled>
    </S.DodafWizard>
  );
};
