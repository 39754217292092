import * as YFiles from "yfiles";
import * as React from "react";
import useToggle from "hooks/useToggle";

export const useLabelVisibility = (graph: YFiles.IGraph) => {
  const [isLabelsVisible, toggleLabelsVisible] = useToggle(true);

  /**
   * Update visibility of labels on the graph.
   */
  React.useEffect(() => {
    if (isLabelsVisible) {
      // If the default label already exists don't add it again...
      graph.nodes.forEach((n: YFiles.INode) => {
        if (n.labels.size === 0) {
          graph.addLabel(n, n.tag.name);
        } else if (n.labels.size > 0) {
          // assume we have only a single label...
          const label = n.labels.get(0);
          if (label.text !== n.tag.name) {
            graph.addLabel(n, n.tag.name);
          }
        }
      });
    } else {
      const labelsToRemove = graph.labels.toList();
      labelsToRemove.forEach((l) => graph.remove(l));
    }
  }, [graph, isLabelsVisible]);

  return [isLabelsVisible, toggleLabelsVisible];
};
