import * as React from "react";
import * as Recoil from "recoil";
import toast from "react-hot-toast";
import { queryClient } from "libs/react-query";
import * as BrmGql from "generated/graphql";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { selectedInstanceState } from "atoms";
import { selectedControlCatalogIdState } from "atoms/atoms-global-control";
import { TYPE } from "constants/brm";
import {
  createColumnNameNoNav,
  createColumnModifiedAt,
  createColumnCreatedAt,
  createColumnMapped,
  createColumnMappedNoEdit,
  createColumnActions,
  createColumnBaselineMapped,
} from "brm/tables/services/column/columnFactory";
import { AdminApi, GlobalControlApi } from "features/brm";
import { useRoles } from "features/brm/hooks/useRoles";
import { getControlsCoordinatorFilteredProjects } from "utils/filter-utils";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";

export const ProjectCatalogConfigTable = ({ setSelectedRows }) => {
  // Global State
  const selectedInstance = Recoil.useRecoilValue(selectedInstanceState);
  const setSelectedControlCatalogId = Recoil.useSetRecoilState(selectedControlCatalogIdState);
  // Local State
  const [editMode, setEditMode] = React.useState(false);
  const updatedCatalog = React.useRef("");
  const updatedBaseline = React.useRef("");

  const { isControlsCoordinator } = useRoles();

  // Queries
  const { data: selfData, isError: isSelfDataError } = AdminApi.useSelf({ options: {} });
  const { data: cntrlcatalogs, isError: isCntrlCatalogsError } = GlobalControlApi.useControlCatalogs({
    options: { enabled: isControlsCoordinator },
  });
  const { data: catalogProjData, isError: isCatalogProjDataError } = BrmGql.useGetProjectCatalogConfigurationQuery(
    { userId: selfData?.id },
    {
      select: React.useCallback(
        (data) =>
          data.projectFindAllByUser.filter(
            (project) => isControlsCoordinator && getControlsCoordinatorFilteredProjects(project)
          ),
        [isControlsCoordinator]
      ),
      enabled: !!selfData?.id,
    }
  );

  const { mutateAsync: setProjCatalog } = AdminApi.useSetProjectCatalog({
    options: {
      onSuccess: () => {
        updatedCatalog.current = "";
        queryClient.invalidateQueries(AdminApi.userKeys.project(selfData.id));
        queryClient.invalidateQueries(AdminApi.projectKeys.projectCatalogConf);
      },
      onError: () => {
        toast.error(<span>Error setting catalog to project</span>, {
          duration: 4000,
        });
      },
    },
  });
  const { mutate: setProjBaseline } = AdminApi.useSetProjectBaseline({
    options: {
      onSuccess: () => {
        updatedBaseline.current = "";
        queryClient.invalidateQueries(AdminApi.userKeys.project(selfData.id));
        queryClient.invalidateQueries(AdminApi.projectKeys.projectCatalogConf);
      },
      onError: () => {
        toast.error(<span>Error setting baseline to project</span>, {
          duration: 4000,
        });
      },
    },
  });

  const createButton = React.useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedCatalog.current !== "" && updatedCatalog.current !== "Select cntrlCatalog") {
              await setProjCatalog({ projectId: selectedInstance.id, catalogId: updatedCatalog.current });
            }

            if (updatedBaseline.current !== "" && updatedBaseline.current !== "Select baseline") {
              setProjBaseline({ projectId: selectedInstance.id, baselineId: updatedBaseline.current });
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [editMode, selectedInstance.id, setProjBaseline, setProjCatalog]
  );

  const setNewCntrlCatalogId = React.useCallback(
    (newCntrlCatalogId) => {
      setSelectedControlCatalogId(newCntrlCatalogId);
    },
    [setSelectedControlCatalogId]
  );

  const columns = React.useMemo(
    () => [
      createColumnNameNoNav(selectedInstance.id, false, null),
      createColumnMappedNoEdit("org"),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnMapped(
        "cntrlCatalog",
        selectedInstance.id,
        cntrlcatalogs,
        editMode,
        updatedCatalog,
        setNewCntrlCatalogId
      ),
      createColumnBaselineMapped("standardBaseline", selectedInstance.id, editMode, updatedBaseline),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [selectedInstance.id, cntrlcatalogs, editMode, setNewCntrlCatalogId, createButton]
  );

  if (isSelfDataError) {
    return <ErrorBanner msg="Error while loading user data" />;
  }

  if (isCntrlCatalogsError) {
    return <ErrorBanner msg="Error while loading control catalogs" />;
  }

  if (isCatalogProjDataError) {
    return <ErrorBanner msg="Error occured while loading projects with catalog" />;
  }

  if (catalogProjData) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={catalogProjData}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "KBProjectTable_table" }}
            elementName={TYPE.project}
            showRowSelect={false}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

ProjectCatalogConfigTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};
