// import { attackListState } from "atoms/atoms-likelihood-calibration";
// import { riskUpdatedState } from "atoms/atoms-risk";
// import { sortByName } from "utils/sorting";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// import { useSetRecoilState } from "recoil";
import { attackApi, attackerApi, attackTacticApi, threatEventPatternApi } from "services/brm";
import { RiskApi } from "features/brm";
import { cloneDeep } from "lodash";

export const useTrevPattern = (projectId, options) => {
  return useQuery({
    ...options,
    queryKey: ["threatEventPattern", projectId],
    queryFn: () => threatEventPatternApi.findThreatEventPattern(projectId),
  });
};

export const useAttackTactics = (projectId, options) => {
  return useQuery({
    ...options,
    queryKey: ["attackTactics", projectId],
    queryFn: () => attackTacticApi.findAttackTactic(projectId),
  });
};

export const useAttackers = (projectId, options) => {
  return useQuery({
    ...options,
    queryKey: ["attacker", projectId],
    queryFn: () => attackerApi.findAttacker(projectId),
  });
};

export const useAttacks = (projectId, variantId, options) => {
  return useQuery({
    ...options,
    queryKey: RiskApi.attackKeys.likelihoodCalibrationAttacks(projectId, variantId),
    queryFn: () => attackApi.findAttack(projectId, { variant: variantId }),
  });
};

// export const useGetAttacks = (projectId, variantId, riskUpdated) => {
//   const setAttackList = useSetRecoilState(attackListState);
//   const setRiskUpdated = useSetRecoilState(riskUpdatedState);
//   return useQuery(
//     RiskApi.attackKeys.likelihoodCalibrationAttacks(projectId, variantId, riskUpdated),
//     () => attackApi.findAttack(projectId, { variant: variantId }),
//     {
//       enabled: !!projectId,
//       onSuccess: (data) => {
//         setAttackList(data.sort(sortByName));
//         setRiskUpdated(false);
//       },
//     }
//   );
// };

export const useChangeLikelihoodLevel = (projectId, variantId) => {
  const queryKey = RiskApi.attackKeys.likelihoodCalibrationAttacks(projectId, variantId);
  const queryClient = useQueryClient();
  return useMutation(
    ({ attackId, level }) => {
      attackApi.setAttackLikelihoodWithHttpInfo(attackId, {
        calibrationValueDto: {
          variant: variantId,
          calibratedStringValue: level,
        },
      });
    },
    {
      onMutate: async ({ attackId, level }) => {
        await queryClient.cancelQueries(queryKey);
        const prevValues = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, (old) =>
          old.map((data) => {
            if (attackId === data.id) {
              const newLikelihood = cloneDeep(data.likelihood);
              newLikelihood.calibratedStringValue = level;
              return {
                ...data,
                likelihood: newLikelihood,
              };
            }
            return data;
          })
        );
        return prevValues;
      },
      onError: (prevValues) => queryClient.setQueryData(queryKey, prevValues),
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );
};
