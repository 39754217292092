import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./organization-query-key-factory";

interface IOrganizationStatusProps {
  organizationId: string | null;
  newStatus: string;
}

/**
 * Upload the given file as the image for the CD
 *
 * @param param0 upload file data
 * @returns
 */
export const setOrganizationStatus = ({ organizationId, newStatus }: IOrganizationStatusProps) => {
  return organizationId === null
    ? Promise.reject(new Error("Invalid organizationId id"))
    : Brm.organizationApi.setOrganizationStatus(organizationId, { body: newStatus });
};

interface ISetStatusProps {
  options?: MutationOption<typeof setOrganizationStatus>;
}

/**
 * Upload the given formdata to to the upload service.
 *
 * @returns react-query Mutation.
 */
export const useSetOrganizationStatus = ({ options }: ISetStatusProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.organizationKeys.all);
      queryClient.invalidateQueries(KeyFactory.organizationKeys.org(variables.organizationId));
    },
    ...options,
    mutationFn: setOrganizationStatus,
  });
};
