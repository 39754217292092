import { VulnerabilityCategoryApi, VulnerabilityApi } from "@kdmanalytics/brm-system";
import BrmApiClient from "services/brm/BrmApiClient";

export const vulnerabilityCategoryApi = new VulnerabilityCategoryApi(BrmApiClient.instance);
export const vulnerabilityApi = new VulnerabilityApi(BrmApiClient.instance);

const createVulnerabilityCategory = async (projectId, createVulCatDto) => {
  const opts = { vulnerabilityCategoryCreateDto: createVulCatDto };
  return vulnerabilityCategoryApi.createVulnerabilityCategoryWithHttpInfo(projectId, opts);
};

const createVulnerability = async (projectId, createVulDto) => {
  const opts = { vulnerabilityCreateDto: createVulDto };
  return vulnerabilityApi.createVulnerabilityWithHttpInfo(projectId, opts);
};

const addVulnerabilityTrev = async (vulId, trevId) => {
  const opts = { body: trevId };
  return vulnerabilityApi.addVulnerabilityTrevWithHttpInfo(vulId, opts);
};

const VulnerabilityService = {
  createVulnerabilityCategory,
  createVulnerability,
  addVulnerabilityTrev,
};

export default VulnerabilityService;
