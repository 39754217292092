import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./structural-category-query-key-factory";

interface IGetStructrualCategories {
  projectId: string;
}

/**
 * Fetches a list of structural categories for the project
 *
 * @param {string} projectId - uuid of a project
 * @returns a list of structural categories
 */
export const getStructuralCategories = ({ projectId }: IGetStructrualCategories) => {
  return Brm.structCatApi.findStructuralCategory(projectId);
};

interface IUseStructuralCategoriesProps {
  projectId: string;
  options?: QueryOption<typeof getStructuralCategories>;
}

/**
 * Returns a list of Structural Categories
 *
 * @param {string} projectId - uuid of a project
 * @returns a list of struct categories
 */
export const useStructuralCategories = ({ projectId, options = {} }: IUseStructuralCategoriesProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.structuralCategoryKeys.project(projectId),
    queryFn: () => getStructuralCategories({ projectId }),
  });
};
