import styled, { css } from "styled-components/macro";
import { IStepperStyleProps } from "./types";

export const StepConnectorContainer = styled.div<IStepperStyleProps>`
  flex: 1 1 auto;
  /* flex-direction: ${({ orientation }) => (orientation === "vertical" ? "column" : "row")}; */

  ${({ orientation }) =>
    orientation === "vertical" &&
    css`
      margin-left: 12px;
    `}
`;

export const StepConnectorLine = styled.span<IStepperStyleProps>`
  display: block;
  border-color: #fff;

  ${({ orientation }) =>
    orientation === "horizontal" &&
    css`
      border-top-style: solid;
      border-top-width: 1px;
    `}

  ${({ orientation }) =>
    orientation === "vertical" &&
    css`
      border-left-style: solid;
      border-left-width: 2px;
      min-height: 24px;
    `}
`;
