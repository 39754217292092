import * as ReactQuery from "@tanstack/react-query";
import { queryClient } from "libs/react-query";
import { getNote, setNote as setBrmNote } from "services/note";
import { NotePane } from "./NotePane";

import { INoteProps } from "./types";

export const Note = ({ type, name, id }: INoteProps) => {
  const noteQuery = ReactQuery.useQuery(["note", id], () => getNote(type, id), { staleTime: 5000 });

  const setNote = (txt: string) => {
    // TODO useMutation
    setBrmNote(type, id, txt);
    // invalidate note for for this id
    queryClient.invalidateQueries(["note", id]);
  };

  if (noteQuery.isError) {
    return <div>Error while fetching notes</div>;
  }
  if (noteQuery.isLoading) {
    return <div>Loading...</div>;
  }
  const noteName = name || "UNKNOWN";

  return <NotePane note={noteQuery.data?.result} setNote={setNote} type={type} name={noteName} />;
};
