import * as React from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
import { controlFamilyApi } from "services/brm/global-control-service";
import { sortByLabel, sortByLabelAlphanumeric } from "utils/sorting";
import SelectAll from "components/forms/SelectAllReactSelect";
import { FORM_LABEL } from "constants/brm";
import { LoadingSpinner as Loading } from "components/elements";
import { IAllocatedControl, ISctm, IOption } from "./types";
import { useAddAllocatedControlsForm } from "./useAddAllocatedControlsForm";
import { getReactSelectArray } from "./utils";
import { menuListStyle, menuListAndContainerStyle, AddAllocatedControlPanel } from "./AddAllocatedControlForm.styles";

const NOUNS = {
  allocatedcontrol: "allocatedcontrol",
  sctm: "SCTM",
};

const allOption = {
  label: "Select all",
  value: "*",
};

interface IAddAllocatedControlForm {
  setModalIsOpen: (v: boolean) => void;
  sctmName: string;
  importSctm: ({ id, body }: { id: string; body: string }) => void;
  vulnerabilityCategories?: string[];
  nodes?: string[];
  exchanges?: string[];
}

export const AddAllocatedControlForm = ({
  setModalIsOpen,
  sctmName,
  importSctm,
  vulnerabilityCategories = [],
  nodes = [],
  exchanges = [],
}: IAddAllocatedControlForm) => {
  const {
    selectedVulnerabilityCategories,
    selectedNodes,
    selectedExchanges,
    selectedFamily,
    selectedControlTypes,
    activeControlTypeOptions,
    isError,
    sctmBaseline,
    sctmId,
    nodeslist,
    exchangeList,
    cntrlCatalogFamilies,
    controlTypesSource,
    vulnerabilityCategoryOptions,
    controlTypesOptions,
    catalog,
    defaultVulnerabilityCategoryOptions,
    postError,
    setSelectedVulnerabilityCategories,
    setSelectedControlTypes,
    setSelectedFamily,
    setSelectedNodes,
    setSelectedExchanges,
    setControlTypesSource,
    setControlTypesOptions,
    setPostError,
    resetControlTypes,
  } = useAddAllocatedControlsForm({ nodes, exchanges, vulnerabilityCategories });

  if (isError) {
    toast.error("Error occured while fetching project catalog list");
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    let allocatedcontrol = [];
    if (selectedControlTypes.length > 0) {
      if (selectedExchanges.length > 0 || selectedNodes.length > 0) {
        allocatedcontrol = await Promise.all(
          selectedControlTypes.map((selectedCT: IOption) => {
            let nodeObj: IAllocatedControl[] = [];
            let exchangeObj: IAllocatedControl[] = [];
            if (selectedNodes.length > 0) {
              nodeObj = selectedNodes.map((node: IOption): IAllocatedControl => {
                return {
                  noun: NOUNS.allocatedcontrol,
                  name: `${selectedCT.label}@${node.label}`,
                  id: `${selectedCT.label}`,
                  object: `${node.label}`,
                  controltype: `${selectedCT.label}`,
                  isCompliant: false,
                  isImported: false,
                };
              });
            }

            if (selectedExchanges.length > 0) {
              exchangeObj = selectedExchanges.map((exchange: IOption): IAllocatedControl => {
                return {
                  noun: NOUNS.allocatedcontrol,
                  name: `${selectedCT.label}@${exchange.label}`,
                  id: `${selectedCT.label}`,
                  object: `${exchange.label}`,
                  controltype: `${selectedCT.label}`,
                  isCompliant: false,
                  isImported: false,
                };
              });
            }

            return [...nodeObj, ...exchangeObj];
          })
        );
        const finalControls = [...allocatedcontrol.flat()];

        const schema: ISctm = {
          noun: NOUNS.sctm,
          name: sctmName,
          allocatedcontrol: finalControls,
          baseline: sctmBaseline.name,
          note: "",
          isImported: false,
          autoallocate: false,
        };

        try {
          // console.log("opts", schema);
          const opts = JSON.stringify(schema);
          // console.log("opts", opts);
          if (sctmId) {
            importSctm({ id: sctmId, body: opts });
          }
          setModalIsOpen(false);
        } catch (err) {
          console.error("error in creating sctm import", err);
        }
      } else {
        setPostError("*Minimum one Node/Exchange is required to add controls");
      }
    } else {
      setPostError("No Control Types have been selected.");
    }
  }

  const handleVulnerabilityCategoryChange = (selectedCategories: any) => {
    // Clear the vulnerability category
    if (Array.isArray(selectedCategories) && selectedCategories.length === 0) {
      setSelectedVulnerabilityCategories([]);
      resetControlTypes();
      return;
    }

    setSelectedVulnerabilityCategories(selectedCategories);
    setSelectedFamily([]);
    const types = activeControlTypeOptions.filter((type: any) =>
      selectedCategories.some((cat: any) => cat.value === type.vulnerabilitycategory)
    );

    setSelectedControlTypes([]);
    setControlTypesOptions(types.sort(sortByLabel));
  };

  const handleNodeSelectChange = (selectedItems: IOption[] | IOption | null) => {
    const options = Array.isArray(selectedItems) ? selectedItems : [];
    setSelectedNodes(options);
  };

  const handleExchangeSelectChange = (selectedItems: IOption[] | null) => {
    const options = Array.isArray(selectedItems) ? selectedItems : [];
    setSelectedExchanges(options);
  };

  const handleControlTypeSelectChange = (selectedItems: IOption[] | null) => {
    const options = Array.isArray(selectedItems) ? selectedItems : [];
    setSelectedControlTypes(options);
  };

  const handleControlSourceChange = (e: any) => {
    setControlTypesSource(e.target.value);
  };

  const handleControlFamilySelectChange = React.useCallback(
    async (selectedItem: any) => {
      if (selectedItem) {
        setSelectedFamily(selectedItem);
        /// get control types by family
        try {
          const resControls = await controlFamilyApi.getControlFamilyControl(selectedItem.value);
          setSelectedVulnerabilityCategories([]);
          setSelectedControlTypes([]);
          setControlTypesOptions(
            activeControlTypeOptions.filter((ct: any) => resControls.some((rc: any) => rc.id === ct.value))
          );
        } catch (err) {
          console.error(err);
        }
      } else {
        setSelectedFamily([]);
        resetControlTypes();
      }
    },
    [
      activeControlTypeOptions,
      resetControlTypes,
      setControlTypesOptions,
      setSelectedControlTypes,
      setSelectedFamily,
      setSelectedVulnerabilityCategories,
    ]
  );

  if (
    nodeslist &&
    exchangeList &&
    cntrlCatalogFamilies &&
    sctmBaseline &&
    vulnerabilityCategoryOptions &&
    controlTypesOptions
  ) {
    return (
      <AddAllocatedControlPanel>
        <FormStyled>
          <form onSubmit={handleSubmit} action="">
            <div className="form-style">
              <strong>Currently selected control type source</strong>:
              <div style={{ marginLeft: "5px", padding: "5px" }}>
                <Form.Check
                  type="radio"
                  value="catalog"
                  label={`Catalog: ${catalog.name}`}
                  id="catalog-controltypes"
                  checked={controlTypesSource === "catalog"}
                  onChange={handleControlSourceChange}
                />
                <Form.Check
                  type="radio"
                  value="baseline"
                  label={`Baseline: ${(sctmBaseline as any).name}`}
                  id="base-controltypes"
                  checked={controlTypesSource === "baseline"}
                  onChange={handleControlSourceChange}
                />
              </div>
              <div>Scope Control Types using Vulnerability Categories or Control Family</div>
              <div style={{ border: "1px solid #CCC", padding: "8px", borderRadius: "5px" }}>
                <strong>
                  <label data-testid="AllocatedControlAddForm_vulnerabilityCategory">{FORM_LABEL.vulCategory}</label>
                </strong>
                <SelectAll
                  isMulti
                  options={vulnerabilityCategoryOptions}
                  defaultValue={defaultVulnerabilityCategoryOptions}
                  value={selectedVulnerabilityCategories}
                  onChange={handleVulnerabilityCategoryChange}
                  styles={menuListAndContainerStyle}
                  allowSelectAll
                  allOption={allOption}
                  elementId="AllocatedControlAddForm_vulnerabilityCategory"
                />
                <div style={{ padding: "8px" }}>Or</div>
                <strong>
                  <label id="AllocatedControlAddForm_controlFamilyLabel">{FORM_LABEL.controlFamily}</label>
                </strong>

                <Select
                  isMulti={false}
                  options={getReactSelectArray(cntrlCatalogFamilies)}
                  defaultValue={selectedFamily}
                  value={selectedFamily}
                  onChange={handleControlFamilySelectChange}
                  id="AllocatedControlAddForm_controlFamilyDropdown"
                  styles={menuListStyle}
                  isClearable
                />
              </div>
              <br />
              <strong>
                <label id="AllocatedControlAddForm_controlTypeLabel">{FORM_LABEL.controlTypes}</label>
              </strong>
              <SelectAll
                isMulti
                options={controlTypesOptions.sort(sortByLabelAlphanumeric)}
                defaultValue={selectedControlTypes}
                value={selectedControlTypes}
                onChange={handleControlTypeSelectChange}
                styles={menuListAndContainerStyle}
                allowSelectAll
                allOption={allOption}
                elementId="AllocatedControlAddForm_controlType"
              />
              <br />
              <strong>
                <label id="AllocatedControlAddForm_nodeLabel">{FORM_LABEL.nodes}</label>
              </strong>
              <SelectAll
                isMulti
                options={getReactSelectArray(nodeslist)}
                defaultValue={selectedNodes}
                value={selectedNodes}
                onChange={handleNodeSelectChange}
                styles={menuListAndContainerStyle}
                allowSelectAll
                allOption={allOption}
                elementId="AllocatedControlAddForm_nodes"
              />
              <br />
              <strong>
                <label id="AllocatedControlAddForm_linkLabel">{FORM_LABEL.exchanges}</label>
              </strong>
              <SelectAll
                isMulti
                options={getReactSelectArray(exchangeList)}
                defaultValue={selectedExchanges}
                value={selectedExchanges}
                onChange={handleExchangeSelectChange}
                styles={menuListAndContainerStyle}
                allowSelectAll
                allOption={allOption}
                elementId="AllocatedControlAddForm_Exchanges"
              />
              <br />
            </div>
            <FormStyledError>{postError}</FormStyledError>

            <DialogButtonDivStyled>
              {(sctmBaseline as any).name ? (
                <>
                  <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="AllocatedControlAddForm_cancelButton">
                    Cancel
                  </DialogButtonStyled>
                  <DialogButtonStyled
                    type="submit"
                    id="AllocatedControlAddForm_addButton"
                    disabled={!((selectedNodes.length || selectedExchanges.length) && selectedControlTypes.length)}
                  >
                    Add Controls
                  </DialogButtonStyled>
                </>
              ) : (
                <></>
              )}
            </DialogButtonDivStyled>
          </form>
        </FormStyled>
      </AddAllocatedControlPanel>
    );
  }

  return <Loading />;
};
