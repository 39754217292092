/* eslint-disable react/jsx-props-no-spreading */
import * as ReactSelect from "react-select";

const DefaultSelect = ReactSelect.default;

export const Select = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>
>(
  props: ReactSelect.Props<Option, IsMulti, Group>
) => {
  return <DefaultSelect {...props} />;
};
