import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./import-query-key-factory";
import { ImportContext, ImportContextValues } from "../../constants";

interface IGetImportStatus {
  projectId: string;
  context: ImportContextValues;
  assetId: string;
}

interface ImporterFunctionMap {
  [key: string]: any;
}

export const downloadAsset = async ({ projectId, context, assetId }: IGetImportStatus) => {
  // console.log({ projectId, context, assetId });
  const exporter: ImporterFunctionMap = {
    [ImportContext.bor]: () => Brm.borImportApi.importAssetDownloadBOR(projectId, assetId),
    [ImportContext.savepoint]: () => Brm.savePointImportApi.importAssetDownloadSavepoint(projectId, assetId),
    [ImportContext.sctm]: () => Brm.sctmImportApi.importAssetDownloadSctm(projectId, assetId),
    [ImportContext.tra]: () => Brm.traImportApi.importAssetDownloadTra(projectId, assetId),
    [ImportContext.system]: () => Brm.systemImportApi.importAssetDownloadSystem(projectId, assetId),
  };

  if (!Object.keys(exporter).some((k) => k === context)) {
    throw new Error(`Unsupported export context: ${context}`);
  }

  return exporter[context]();
};

export interface IUseDownloadAsset {
  projectId: string;
  context: ImportContextValues;
  assetId: string;
  options?: QueryOption<typeof downloadAsset>;
}

export const useDownloadAsset = ({ projectId, assetId, context, options }: IUseDownloadAsset) => {
  // When context = ImportContext.baseline => projectId = baselineId.
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.importKeys.downloadAsset(projectId, context, assetId),
    queryFn: () => downloadAsset({ projectId, context, assetId }),
  });
};
