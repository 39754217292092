import * as React from "react";
import PropTypes from "prop-types";

// Utils
import * as FilterUtil from "utils/filter-utils";
import * as ReactSelectUtil from "utils/react-select-utils";

// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
// Services
import { OrganizationCreateDto } from "@kdmanalytics/brm-admin";
// Components
import SelectAll from "components/forms/SelectAllReactSelect";
import TextField from "components/forms/TextField";
// Hooks
import { useRoles } from "features/brm/hooks/useRoles";
// Constants
import { STATUS, FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";
import { AdminApi } from "features/brm";
import { LoadingSpinner as Loading } from "components/elements";

export const AddOrganizationForm = ({ setModalIsOpen }) => {
  const { isSuperAdmin } = useRoles();
  const [postError, setPostError] = React.useState("");
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [isTextValid, setIsTextValid] = React.useState(true);

  const { data: userOptions } = AdminApi.useUsers({
    options: {
      select: React.useCallback((data) => {
        const notDeletedData = data.filter(FilterUtil.isNotDeletedStatus);
        return ReactSelectUtil.createOptionsFromProps({ src: notDeletedData, value: "id", label: "username" });
      }, []),
    },
  });
  const { data: organizations } = AdminApi.useOrganizations({ options: { enabled: isSuperAdmin } });
  const { mutateAsync: createOrganization } = AdminApi.useCreateOrganization();
  const { mutateAsync: addOrganizationAttachedUser } = AdminApi.useAddOrganizationAttachedUser();

  const handleUserSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedUsers(items);
  };

  // validate that all fields in the form have been completed
  const validate = (formData) => {
    let error = true;
    if (formData.name.value === "") {
      error = false;
    }
    return error;
  };

  const validateName = (name) => {
    return !organizations.some((org) => org.name === name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validate(e.target.elements);
    const isValidName = validateName(e.target.elements.name.value);

    if (isValid && isValidName && isTextValid) {
      // the new organization from user - form entry
      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value || "",
        status: STATUS.active,
      };

      // initBrmClient(BrmApiClient.instance);
      const dto = OrganizationCreateDto.constructFromObject(params);
      const id = await createOrganization({ organizationCreateDto: dto });

      await Promise.all(
        selectedUsers.map((su) => {
          return addOrganizationAttachedUser({ organizationId: id, userId: su.value });
        })
      );
      setModalIsOpen(false);
    } else {
      if (!isValidName) return setPostError(FORM_ERROR.uniqueName);
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  };

  if (userOptions) {
    return (
      <div id="OrganizationAddForm_Panel">
        <form onSubmit={handleSubmit} action="" id="OrganizationAddForm_form">
          <FormStyled>
            <div className="form-style">
              <TextField
                label={{
                  id: "OrganizationAddForm_Name",
                  name: FORM_LABEL.nameMandatory,
                  htmlFor: "OrganizationAddForm_Name_input",
                }}
                input={{ id: "OrganizationAddForm_Name_input", name: "name", placeholder: FORM_PLACEHOLDER.name }}
                setIsTextValid={setIsTextValid}
              />
              <TextField
                label={{ id: "OrganizationAddForm_Note", name: FORM_LABEL.note }}
                input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                setIsTextValid={setIsTextValid}
              />

              <label>{FORM_LABEL.attachedUsersOptional}</label>
              <SelectAll
                isMulti
                options={userOptions}
                defaultValue={selectedUsers}
                value={selectedUsers}
                onChange={handleUserSelectChange}
                allowSelectAll
                allOption={{
                  label: "Select all",
                  value: "*",
                }}
                elementId="OrganizationAddForm_attachedUser"
              />
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled id="OrganizationAddForm_buttons">
            <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="OrganizationAddForm_cancelButton">
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled type="submit" id="OrganizationAddForm_addButton">
              Add
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }
  return <Loading />;
};

AddOrganizationForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};
