import * as React from "react";
import * as YFiles from "yfiles";

export const useGraphComponentContainer = (graphComponent: YFiles.GraphComponent, zoomToHideInitialLayout = false) => {
  const graphContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (graphComponent) {
      if (graphContainerRef.current!.hasChildNodes()) {
        // Replace if existing ... happens in dev dev
        graphContainerRef.current!.replaceChild(graphComponent.div, graphContainerRef.current!.firstChild as Node);
      } else {
        // otherwise just append
        graphContainerRef.current!.appendChild(graphComponent.div);
      }
      if (zoomToHideInitialLayout) {
        graphComponent.zoomTo(new YFiles.Rect(1000, 1000, 120, 75));
      }
    }
  }, [graphComponent, zoomToHideInitialLayout]);

  return graphContainerRef;
};
