import * as React from "react";
import * as S from "./Step.styles";
import { StepConnector } from "./StepConnector";
import { useStepperContext } from "./StepperContext";
import { StepContext } from "./StepContext";

interface IStepProps {
  children?: React.ReactNode;
  index?: number;
  isLast?: boolean;
  isActive?: boolean;
  isCompleted?: boolean;
  isDisabled?: boolean;
}

export const Step = ({
  children,
  index = 0,
  isLast = false,
  isActive: isActiveProp,
  isCompleted: isCompletedProp,
  isDisabled: isDisabledProp,
}: IStepProps) => {
  const { orientation, activeStep } = useStepperContext();

  let isActive = isActiveProp ?? false;
  let isCompleted = isCompletedProp ?? false;
  let isDisabled = isDisabledProp ?? false;

  if (activeStep === index) {
    isActive = isActiveProp !== undefined ? isActiveProp : true;
  } else if (activeStep && activeStep > index) {
    isCompleted = isCompletedProp !== undefined ? isCompletedProp : true;
  } else if (activeStep && activeStep < index) {
    isDisabled = isDisabledProp !== undefined ? isDisabledProp : true;
  }

  // console.log("index", index);
  // console.log("activeStep", activeStep);
  // console.log("isCompleted", isCompleted);

  const contextValue = React.useMemo(
    () => ({ index, icon: index + 1, isLast, isActive, isCompleted, isDisabled }),
    [index, isActive, isCompleted, isDisabled, isLast]
  );

  return (
    <StepContext.Provider value={contextValue}>
      {index !== 0 ? <StepConnector /> : null}
      <S.StepContainer orientation={orientation}>{children}</S.StepContainer>
    </StepContext.Provider>
  );
};
