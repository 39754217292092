import { useRecoilValue } from "recoil";

// Components
import { useRoles } from "features/brm/hooks/useRoles";
import { contentKeyState } from "atoms";
import { currentProjectState } from "../../stores";

export const useShowProjectSysAssetFDText = () => {
  const currentProject = useRecoilValue(currentProjectState);
  const { isRiskAnalyst } = useRoles();
  const contentKey = useRecoilValue(contentKeyState);
  const isSysAssetFD = contentKey === "systemAssetFullDetails";

  return isRiskAnalyst && !currentProject?.id && isSysAssetFD;
};
