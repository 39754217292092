export const undesiredEventKeys = {
  all: ["undesiredEvent"] as const,
  undesiredEvent: (undesiredEventId: string, variantId: string) =>
    [...undesiredEventKeys.all, "undesiredEvent", undesiredEventId, variantId] as const,
  project: (projectId: string, variantId: string) =>
    [...undesiredEventKeys.all, "project", projectId, variantId] as const,
  impactCalibrationUEs: (projectId: string, variantId: string, hideDisabledUes: boolean) =>
    [...undesiredEventKeys.all, "impactCalibrationUEs", projectId, variantId, hideDisabledUes] as const,
  element: (
    elementId: string | null,
    elementName: string | null,
    variantId: string | null,
    projectId: string | null,
    undesiredEventData: any
  ) =>
    [...undesiredEventKeys.all, "element", elementId, elementName, variantId, projectId, undesiredEventData] as const,
};

export const harmKeys = {
  all: ["harm"] as const,
  categories: (projectId: string) => [...harmKeys.all, "categories", projectId] as const,
};
