import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./user-query-key-factory";

interface IUserRoleProps {
  userId: string | null;
  roleId: string;
}

/**
 * Add user assigned role
 *
 * @returns
 */
export const addUserAssignedRole = ({ userId, roleId }: IUserRoleProps) => {
  return userId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.userApi.addUserAssignedRole(userId, { body: roleId });
};

interface IAddUserAssignedRoleProps {
  options?: MutationOption<typeof addUserAssignedRole>;
}

/**
 * Custom Hook to add assigend role.
 *
 * @returns react-query Mutation.
 */
export const useAddUserAssignedRole = ({ options }: IAddUserAssignedRoleProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.userKeys.user(variables.userId));
      queryClient.invalidateQueries(KeyFactory.userKeys.all);
    },
    ...options,
    mutationFn: addUserAssignedRole,
  });
};
