import * as React from "react";
import * as Recoil from "recoil";

import {
  nodeSelectedFiltersState,
  exchangeSelectedFiltersState,
  attackerSelectedFiltersState,
  trevPatternSelectedFiltersState,
  tacticsSelectedFiltersState,
  likelihoodSelectedFiltersState,
  calibratedLikelihoodSelectedFiltersState,
} from "atoms/atoms-likelihood-calibration";
import { useVariant } from "features/brm/hooks/useVariant";
import { useProject } from "features/brm/hooks/useProject";

export const useResetLikelihoodFilters = () => {
  const { variantId } = useVariant();
  const [, { projectId }] = useProject();

  const setSelectedTrevs = Recoil.useSetRecoilState(trevPatternSelectedFiltersState);
  const setSelectedAttackTactics = Recoil.useSetRecoilState(tacticsSelectedFiltersState);
  const setSelectedAttackers = Recoil.useSetRecoilState(attackerSelectedFiltersState);
  const setSelectedNodes = Recoil.useSetRecoilState(nodeSelectedFiltersState);
  const setSelectedExchanges = Recoil.useSetRecoilState(exchangeSelectedFiltersState);
  const setSelectedLikelihoods = Recoil.useSetRecoilState(likelihoodSelectedFiltersState);
  const setSelectedCalibratedLikelihoods = Recoil.useSetRecoilState(calibratedLikelihoodSelectedFiltersState);

  React.useEffect(() => {
    const resetFilters = () => {
      setSelectedTrevs([]);
      setSelectedAttackTactics([]);
      setSelectedAttackers([]);
      setSelectedNodes([]);
      setSelectedExchanges([]);
      setSelectedLikelihoods([]);
      setSelectedCalibratedLikelihoods([]);
    };
    if (projectId || variantId) {
      resetFilters();
    }
  }, [
    projectId,
    setSelectedAttackTactics,
    setSelectedAttackers,
    setSelectedCalibratedLikelihoods,
    setSelectedExchanges,
    setSelectedLikelihoods,
    setSelectedNodes,
    setSelectedTrevs,
    variantId,
  ]);
};
// export const test = () => {};
