/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from "prop-types";
import { useMemo, useEffect, useRef, forwardRef, useCallback } from "react";
import { useTable, useSortBy, useFilters, usePagination, useRowSelect } from "react-table";
import { useRecoilState } from "recoil";
import userRoles from "services/brm/user-roles";
// Atoms
import { pageSizeState } from "atoms";
// Local
import { useRoles } from "features/brm/hooks/useRoles";
import { BrmDetailTableSimple } from "./BrmDetailTableSimple";
import { BrmDetailTableDefault } from "./BrmDetailTableDefault";
import { DefaultColumnFilter } from "./BrmTableFilter";

const SUPERADMIN = userRoles.properties[userRoles.SUPER_ADMIN].key;
const ADMIN = userRoles.properties[userRoles.ADMIN].key;

const BrmDetailTable = ({
  data,
  columns,
  setSelectedElement,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  setSelectedRows = (value) => {},
  customProps = { id: "noIdTable_table" },
  showRowSelect = true,
  showPagination = true,
  showRowCount = true,
  showHeaderFilters = true,
  simple = false,
  elementName,
  tableTitle = "",
  tableTitleForExport = "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  setExportTableData = (value) => {},
}) => {
  const { isSuperAdmin, isAdmin } = useRoles();
  const [initialPageSize, setInitialPageSize] = useRecoilState(pageSizeState);

  // This is the initial sorting state, can add more id's for additional columns to sort by
  const initialState = {
    sortBy: [{ id: "username" }, { id: "rank" }, { id: "rank.value" }, { id: "name" }],
    pageIndex: 0,
    pageSize: initialPageSize,
  };

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    []
  );

  const makeHeader = ({ getToggleAllRowsSelectedProps }) => (
    <div>
      <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    </div>
  );

  const makeCell = useCallback(
    ({ row }) => {
      const myRow = row;
      if (
        (myRow.original.id === SUPERADMIN && isSuperAdmin) ||
        (isAdmin && (myRow.original.id === SUPERADMIN || myRow.original.id === ADMIN))
      ) {
        myRow.isSelected = false;
        return null;
      }

      return (
        <div>
          <IndeterminateCheckbox {...myRow.getToggleRowSelectedProps()} />
        </div>
      );
    },
    [isAdmin, isSuperAdmin]
  );

  const showSelectBox = useCallback(
    (hooks) => {
      if (showRowSelect) {
        hooks.visibleColumns.push((cols) => {
          return [
            // Let's make a column for selection
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: makeHeader,
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: makeCell,
            },
            ...cols,
          ];
        });
      }
    },
    [makeCell, showRowSelect]
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  });
  IndeterminateCheckbox.displayName = "IndeterminateCheckbox";

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    preFilteredRows,
    // rows, -> we change 'rows' to 'page'
    rows,
    page,
    prepareRow,
    // below new props related to 'usePagination' hook
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    visibleColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState, defaultColumn, filterTypes },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    showSelectBox
  );

  useEffect(() => {
    if (rows.length) {
      const exportTableData = {
        originalName: `${elementName || ""}_${tableTitleForExport || tableTitle}`,
        rawHeaders: headerGroups,
        rawRows: rows,
        rawPage: page,
        unfilteredRows: preFilteredRows,
      };
      setExportTableData(exportTableData);
    }
  }, [page, rows, preFilteredRows, headerGroups, elementName, tableTitle, setExportTableData, tableTitleForExport]);

  if (simple) {
    return (
      <BrmDetailTableSimple
        customProps={customProps}
        prepareRow={prepareRow}
        headerGroups={headerGroups}
        selectedFlatRows={selectedFlatRows}
        setSelectedElement={setSelectedElement}
        setSelectedRows={setSelectedRows}
        showHeaderFilters={showHeaderFilters}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        page={page}
        visibleColumns={visibleColumns}
        elementName={elementName}
      />
    );
  }
  return (
    <BrmDetailTableDefault
      customProps={customProps}
      prepareRow={prepareRow}
      headerGroups={headerGroups}
      selectedFlatRows={selectedFlatRows}
      setSelectedElement={setSelectedElement}
      setSelectedRows={setSelectedRows}
      showHeaderFilters={showHeaderFilters}
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      page={page}
      visibleColumns={visibleColumns}
      showRowCount={showRowCount}
      showTableTitle={tableTitle}
      rows={rows}
      data={data}
      showPagination={showPagination}
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
      pageOptions={pageOptions}
      pageCount={pageCount}
      gotoPage={gotoPage}
      nextPage={nextPage}
      previousPage={previousPage}
      setPageSize={setPageSize}
      setInitialPageSize={setInitialPageSize}
      pageIndex={pageIndex}
      pageSize={pageSize}
      elementName={elementName}
    />
  );
};

BrmDetailTable.propTypes = {
  columns: PropTypes.any,
  customProps: PropTypes.shape({
    id: PropTypes.string,
  }),
  data: PropTypes.array,
  setSelectedElement: PropTypes.func,
  setSelectedRows: PropTypes.func,
  showRowSelect: PropTypes.bool,
  showPagination: PropTypes.bool,
  showHeaderFilters: PropTypes.bool,
  showRowCount: PropTypes.bool,
  indeterminate: PropTypes.any,
  row: PropTypes.any,
  getToggleAllRowsSelectedProps: PropTypes.func,
  simple: PropTypes.bool,
  elementName: PropTypes.string,
  tableTitle: PropTypes.string,
  tableTitleForExport: PropTypes.string,
  setExportTableData: PropTypes.func,
};

export default BrmDetailTable;
