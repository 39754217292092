/* eslint-disable import/prefer-default-export */
import * as ReactQuery from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";

/**
 * Custom hook to retrieve a threat event.
 *
 * @param {string} trevId - uuid of a threat event
 * @returns react-query for getThreatEventByID
 */
const getThreatEventById = async (trevId, variantId) => {
  const trev = await Brm.threatEventApi.getThreatEventByID(trevId, {
    variant: variantId,
  });
  trev.target = await Brm.threatEventApi.getThreatEventTarget(trevId);
  trev.subject = await Brm.threatEventApi.getThreatEventSubject(trevId);

  return trev;
};

export const useGetThreatEventById = (trevId, variantId, config = {}) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["threatEvent", trevId, variantId],
    queryFn: () => getThreatEventById(trevId, variantId),
  });
};
