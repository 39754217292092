import * as React from "react";
import { ActionButton, LoadingSpinner } from "components/elements";
import { AdminApi, useRoles } from "features/brm";
import * as CommonStyle from "./styles";
import { useCreateProjectContext } from "./CreateProjectContext";

export const ProjectCreatedStep = () => {
  const { userId } = useRoles();
  const { close, projectState, projectCreationError, onConfigure } = useCreateProjectContext();
  let content = null;

  const projId = projectState?.id || "";

  const { data: workers } = AdminApi.useProjectWorkers({ projectId: projId, options: { enabled: !!projectState } });

  const isOnProject = React.useMemo(() => {
    if (workers) {
      return workers.some((w: any) => w.id === userId);
    }

    return false;
  }, [userId, workers]);

  if (projectCreationError) {
    content = `Bad things have happened: ${projectCreationError}`;
  }

  if (projectState && isOnProject) {
    content = (
      <div>
        <h6>The {projectState.name} project was created successfully!</h6>
        <br />
        You now have the choice of configuring this project via the project configuration wizard or closing this
        dialogue if you need a more customized project.
        <br />
        <br />
        <h6>
          <strong>Custom Configuration</strong>
        </h6>{" "}
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          If you choose to customize your project configuration ensure that the Threat and Security Environment for this
          project is configured prior to importing the System Model, otherwise default configurations will be used.
          <br />
          To configure the Threat and Security Environment, refer to the following roles and tasks:
        </div>
        <div style={{ marginLeft: "20px", marginTop: "5px" }}>
          <ul>
            <li>Cybersecurity Expert - Configure Knowledgebase</li>
            <li>Control Coordinator - Configure Control Catalog and Baseline</li>
            <li>Threat Analyst - Configure Threat Catalog and Threat Groups</li>
          </ul>
        </div>
      </div>
    );
  } else if (projectState && !isOnProject) {
    content = (
      <div>
        <h6>The {projectState.name} project was created successfully!</h6>
        <br />
        <h6>
          <strong>Custom Configuration</strong>
        </h6>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          Inform the users of this project that in order to customize your project configuration ensure that the Threat
          and Security Environment for this project is configured prior to importing the System Model, otherwise default
          configurations will be used.
          <br />
          To configure the Threat and Security Environment, refer to the following roles and tasks:
        </div>
        <div style={{ marginLeft: "20px", marginTop: "5px" }}>
          <ul>
            <li>Cybersecurity Expert - Configure Knowledgebase</li>
            <li>Control Coordinator - Configure Control Catalog and Baseline</li>
            <li>Threat Analyst - Configure Threat Catalog and Threat Groups</li>
          </ul>
        </div>
      </div>
    );
  } else {
    content = <LoadingSpinner message="Creating Project...." />;
  }

  return (
    <CommonStyle.StepDiv>
      <div>
        <h5 style={{ marginTop: "10px", marginBottom: "10px", fontWeight: 600 }}>Project Creation</h5>
        {content}
      </div>
      <CommonStyle.ButtonContainer>
        <ActionButton onClick={close}>Close</ActionButton>
        {isOnProject ? (
          <ActionButton onClick={onConfigure} disabled={!projectState}>
            Configure
          </ActionButton>
        ) : null}
      </CommonStyle.ButtonContainer>
    </CommonStyle.StepDiv>
  );
};
