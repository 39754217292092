import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

  html {
    margin:0;
    padding:0;
    font-size: 14px;
  }

  html, body, p, div, h1, h2, h3, h4, h5, h6, img, pre, form, fieldset, ol, ul{
    margin:0;
    padding:0;
  }   

  :root {
    --navigationToolbarWidth: 45px;
    --resizeSizeThreshold: 120px;

    --leftPanelWidth: 0px;
    --rightPanelWidth: 0px;

    --leftSidebarWidth: 300px;
    --leftSidebarMinWidth: 300px;
    --leftSidebarFlyoutWidth: 300px;

    --rightSidebarWidth: 350px;
    --rightSidebarMinWidth: 350px;    
    --rightSidebarFlyoutWidth: 300px;
  } 
`;

export default GlobalStyles;
