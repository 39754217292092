import styled from "styled-components/macro";

const logoStyles = {
  Logo: styled.img`
    width: ${({ theme }) => theme.sizes.logoWidth};
    height: auto;
    float: left;
  `,
};
export default logoStyles;
