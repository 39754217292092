import { useProject } from "features/brm/hooks";
import * as React from "react";
import Select, { SingleValue } from "react-select";
import { createOptionsFromProps } from "utils/react-select-utils";
import Form from "react-bootstrap/Form";
import * as MitigationApi from "../../../api/mitigation";
import * as S from "./VariantSelectionForm.styles";
import { AddButton } from "../../elements/AddButton";

interface Option {
  readonly label: string;
  readonly value: string;
}

interface IVariantSelectionFormProps {
  onChange: (v: Option) => void;
  showCreationButton?: boolean;
}

export const VariantSelectionForm = ({ onChange, showCreationButton = false }: IVariantSelectionFormProps) => {
  const [currentProject] = useProject();
  const [value, setValue] = React.useState<Option | null>();
  // const [showCreationForm, setShowCreationForm] = React.useState(false);

  const { data: variantOptions } = MitigationApi.useVariants({
    projectId: currentProject?.id || "",
    options: {
      select: (data) => createOptionsFromProps({ src: data, value: "id", label: "name" }),
    },
  });

  const handleClick = () => {
    // setShowCreationForm(true);
  };

  const handleChange = (selectedItem: SingleValue<Option>) => {
    setValue(value);
    onChange(selectedItem as Option);
  };

  return (
    <S.Container>
      <S.LabelButtonContainer>
        <S.LabelContainer>
          <Form.Label>
            <strong>Select Variant</strong>
          </Form.Label>
        </S.LabelContainer>

        {showCreationButton ? <AddButton onClick={handleClick} tooltip="Create New Variant" /> : null}
        {/* showCreationButton ?  <ActionButton onClick={handleClick}>Create New Variant</ActionButton>: null */}
      </S.LabelButtonContainer>
      <Select isClearable onChange={handleChange} options={variantOptions} value={value} />
    </S.Container>
  );
};
