import { useEffect, useMemo, useState } from "react";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";
import { LoadingSpinner } from "components/elements";
import * as AttackApi from "brm/risk-model/attack/api";
import { createColumnName } from "brm/tables/services/column/columnFactory";
import { useRoles } from "features/brm";

const AttackMechanismTable = ({ setSelectedRows, setRefresh }) => {
  const { isRiskAnalyst } = useRoles();

  const projectId = useRecoilValue(projectIdState);
  const [modData, setModData] = useState([]);
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const { data: attackMechanisms, isError } = AttackApi.useAttackMechanisms(projectId, {
    enabled: !!projectId && isRiskAnalyst,
  });

  const columns = useMemo(
    () => [createColumnName(selectedInstance.id, setSelectedInstance, false, null, null)],
    [selectedInstance.id, setSelectedInstance]
  );

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    if (attackMechanisms) {
      setModData(attackMechanisms.map((e) => ({ name: e, id: e, type: "Attack Mechanism" })));
    }
  }, [attackMechanisms]);

  if (isError) {
    return <div>Error While loading {TYPE.attackMechanism} data</div>;
  }

  if (attackMechanisms && modData) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={modData}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "AttackMechanismTable_table" }}
            showRowSelect={false}
            elementName={TYPE.attackMechanism}
          />
        </CustomTable>
      </>
    );
  }
  return <LoadingSpinner />;
};

AttackMechanismTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default AttackMechanismTable;
