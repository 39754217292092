import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import ButtonCell from "components/EditComponents/ButtonCell";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import { ImpactStatementCreateDto } from "@kdmanalytics/brm-system";
import { DeleteButton, AddButton, SystemApi } from "features/brm";
import systemModals from "components/modals/Modals";
import { useQueryClient } from "@tanstack/react-query";

import {
  createColumnActions,
  createColumnMapped,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";
import * as S from "brm/styles/details-table.styles";
import { LEVELS } from "constants/brm";
import { useRoles } from "features/brm/hooks/useRoles";
import { useDeleteCategorization } from "./api";
import CategorizationAddForm from "./forms/CategorizationAddForm";

interface ICategorizedEntityTableProps {
  elementNameInUrl: string;
  selectedElement: any;
  tableTitle?: string;
  showAdd?: boolean;
  showDelete?: boolean;
}

interface ISelectedCategorization {
  id: string;
  objective: any;
}

interface IDetailsSelectedInstance {
  id: string;
  name: string;
}

const CategorizedEntityTable = ({
  elementNameInUrl,
  selectedElement,
  tableTitle,
  showAdd = true,
  showDelete = true,
}: ICategorizedEntityTableProps) => {
  const queryClient = useQueryClient();

  const [selectedCategorization, setSelectedCategorization] = useState<ISelectedCategorization>({
    id: "",
    objective: {},
  });
  const [detailsSelectedInstance, setDetailsSelectedInstance] = useState<IDetailsSelectedInstance>({
    id: "",
    name: "",
  });
  const [editMode, setEditMode] = useState(false);
  const updatedLevel = useRef("");
  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const { data: categorizedEntityData, isError: isCategorizedEntityDataError } = SystemApi.useElementCategorizedEntity({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    options: {
      enabled: !!selectedElement.id,
    },
  });

  const { mutate: setSOLevel } = SystemApi.useSetCategorizedEntityCategorization({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    options: {
      onSettled: () => {
        updatedLevel.current = "";
      },
    },
  });

  const { mutate: deleteCategorization } = useDeleteCategorization({
    config: {
      onSettled: () => {
        queryClient.invalidateQueries();
      },
    },
  });

  const handleDelete = async () => {
    if (selectedRows.length > 0) {
      await Promise.all(
        selectedRows.map((each: any) => {
          return deleteCategorization({
            securityObjectiveId: each.objective.id,
            elementName: elementNameInUrl,
            elementId: categorizedEntityData[0].id,
          });
        })
      );
    }
  };

  const createButton = useCallback(
    (cellProps: { cell: { row: { original: { id: any } } } }) => {
      return (
        <ButtonCell
          selectedRowId={detailsSelectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            if (updatedLevel.current !== "" && updatedLevel.current !== "Select level") {
              const params = {
                objective: detailsSelectedInstance.name,
                level: updatedLevel.current,
              };

              const impactStatementCreateDto = ImpactStatementCreateDto.constructFromObject(params);
              setSOLevel({
                catEntityId: categorizedEntityData[0].id,
                impactStatementCreateDto,
              });
            }
            setEditMode(false);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [categorizedEntityData, detailsSelectedInstance, editMode, setSOLevel]
  );

  const columns = useMemo(() => {
    if (isRiskAnalyst || isSystemEngineer) {
      return [
        createColumnMappedNoEdit("objective"),
        createColumnMapped("level", detailsSelectedInstance.id, LEVELS, editMode, updatedLevel),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnMappedNoEdit("objective"),
      createColumnMapped("level", detailsSelectedInstance.id, LEVELS, false, null),
    ];
  }, [createButton, detailsSelectedInstance.id, editMode, isRiskAnalyst, isSystemEngineer]);

  useEffect(() => {
    if (selectedCategorization) {
      setDetailsSelectedInstance({
        id: selectedCategorization.id,
        name: selectedCategorization?.objective?.name || "",
      });
    }
  }, [selectedCategorization]);

  if (isCategorizedEntityDataError) {
    return <ErrorBanner msg="Error while loading Categorized Entity Data" />;
  }

  if (Array.isArray(categorizedEntityData)) {
    const categorizationData = categorizedEntityData.length ? categorizedEntityData[0]?.categorization : [];
    return (
      <S.DetailsContainer id="CategorizationTable_detailsPanel">
        {addModalIsOpen &&
          systemModals.addModal(
            "CategorizationTable_detailsPanel",
            addModalIsOpen,
            setAddModalIsOpen,
            <CategorizationAddForm setModalIsOpen={setAddModalIsOpen} selectedElement={selectedElement} />,
            "Categorization"
          )}
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={categorizationData}
              columns={columns}
              customProps={{ id: "CategorizedEntityTable_detailsTable" }}
              showRowSelect={showDelete}
              tableTitle={tableTitle}
              setSelectedRows={setSelectedRows}
              setSelectedElement={setSelectedCategorization}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            {showDelete && selectedRows?.length > 0 && <DeleteButton onClick={handleDelete} />}
            {showAdd && <AddButton onClick={() => setAddModalIsOpen(true)} />}
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }
  return <Loading />;
};

export default CategorizedEntityTable;
