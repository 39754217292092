import { useState, useEffect, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import errorUtils from "utils/error-utils";
import { projectApi } from "services/brm/admin-service";

import {
  createColumnNameDetails,
  createColumnCreatedAt,
  createColumnModifiedAt,
  createColumnMappedNoEdit,
  createColumnBooleanNoEditMapped,
} from "brm/tables/services/column/columnFactory";
// Styles
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";

export const InstallationProjectDetailsTable = () => {
  const [, setSelectedProject] = useState([]);
  const [projectData, setProjectData] = useState([]);

  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Project.replace(":id", "")),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
      createColumnMappedNoEdit("status"),
      createColumnMappedNoEdit("org"),
      createColumnBooleanNoEditMapped("isListed"),
      createColumnBooleanNoEditMapped("isShared"),
    ],
    []
  );

  useEffect(() => {
    async function getData() {
      projectApi
        .findProjectWithHttpInfo()
        .then((res) => {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setProjectData(res.data);
        })
        .catch(async (error) => {
          console.error("Details: Installation Project GET: ", error);
        });
    }
    getData();
  }, []);

  return (
    <S.DetailsContainer id="InstallationProjectTable_detailsPanel">
      <BrmDetailTable
        data={projectData}
        columns={columns}
        setSelectedElement={setSelectedProject}
        setSelectedRows={() => {}}
        customProps={{ id: "InstallationProjectTable_detailsTable" }}
        showRowSelect={false}
      />
    </S.DetailsContainer>
  );
};
