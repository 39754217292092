import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import * as S from "./RiskFilters.styles";

interface IRiskFilterButton {
  isFilterVisible: boolean;
  setIsFilterVisible: (value: boolean) => void;
}

export const RiskFilterButton = ({ isFilterVisible, setIsFilterVisible }: IRiskFilterButton) => {
  return (
    <S.ShowFilterButton onClick={() => setIsFilterVisible(!isFilterVisible)}>
      <FilterIcon />
      Filters
    </S.ShowFilterButton>
  );
};
