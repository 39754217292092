import styled from "styled-components/macro";

interface ISvgProps {
  $isActive?: boolean;
  $isCompleted?: boolean;
}

export const StepSvg = styled.svg<ISvgProps>`
  width: 24px;
  height: 24px;
  display: inline-block;
  fill: currentColor;
  transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: ${({ theme, $isActive, $isCompleted }) => {
    if ($isActive || $isCompleted) {
      return theme.colors.primaryButton;
    }
    return theme.colors.disabledMedium;
  }};
  font-size: 1.5rem;
`;

export const StepIconText = styled.text`
  color: #fff;
  font-size: 14px;
`;
export const IconStack = styled.span`
  display: grid;
  svg {
    grid-area: 1 / 1;
  }
`;
