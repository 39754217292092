import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./exchange-query-key-factory";

interface IGetExchangeProps {
  exchangeId: string;
}

/**
 * Returns an ExchangeDto corresponding to the given id
 *
 * @param {string} exchangeId uuid of the exchange
 * @returns ExchangeDto
 */
export const getExchange = ({ exchangeId }: IGetExchangeProps) => {
  return Brm.exchangeApi.getExchangeByID(exchangeId);
};

interface IUseExchangeProps {
  exchangeId: string;
  options?: QueryOption<typeof getExchange>;
}

/**
 * React-query mutation hook for retrieving an exchange
 *
 * @param {string} exchangeId - uuid of the exchange
 * @param {object} options - react-query configuration object
 * @returns react-query mutation
 */
export const useExchange = ({ exchangeId, options = {} }: IUseExchangeProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exchangeKeys.exchange(exchangeId),
    queryFn: () => getExchange({ exchangeId }),
  });
};
