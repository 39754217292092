/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";
import {
  Column,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  Table,
  useReactTable,
  RowSelectionState,
  SortingState,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
} from "@tanstack/react-table";
import { FaSortDown, FaSortUp, FaSort } from "react-icons/fa";
import * as S from "./SelectTable.styles";

interface ISelectionTableProp {
  data: any;
  columns: any;
  rowSelection: any;
  setRowCount: (value: number) => void;
  minHeight?: string;
  onRowSelectionChange?: (rowSelection: RowSelectionState) => void;
  renderFilter?: ({ column, table }: { column: Column<any, any>; table: Table<any> }) => React.ReactNode;
}

export const SelectionTable = ({
  data,
  columns,
  rowSelection,
  minHeight,
  onRowSelectionChange,
  renderFilter,
  setRowCount,
}: ISelectionTableProp) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      sorting,
    },
    enableRowSelection: true,
    onRowSelectionChange: (updaterOrValue) => {
      if (!onRowSelectionChange) {
        return;
      }
      if (typeof updaterOrValue === "function") {
        onRowSelectionChange(updaterOrValue(rowSelection));
      } else {
        onRowSelectionChange(updaterOrValue);
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const rowCount = table.getFilteredRowModel().rows.length;

  React.useEffect(() => {
    setRowCount(rowCount);
  }, [rowCount, setRowCount, table]);

  const getSortIcon = (column: Column<any, any>) => {
    return (
      {
        asc: <FaSortUp />,
        desc: <FaSortDown />,
      }[column.getIsSorted() as string] ?? <FaSort />
    );
  };

  return (
    <S.RootContainer>
      <S.TableContainer>
        <S.Table striped bordered hover $minHeight={minHeight}>
          <S.THead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                              onClick: header.column.getToggleSortingHandler(),
                              style: { display: "flex", flexDirection: "row" },
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {header.column.getCanSort() ? getSortIcon(header.column) : null}
                          </div>
                          <div>
                            {header.column.getCanFilter() ? (
                              <div>{renderFilter ? renderFilter({ column: header.column, table }) : null}</div>
                            ) : null}
                          </div>
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </S.THead>
          <S.TBody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </S.TBody>
        </S.Table>
      </S.TableContainer>
    </S.RootContainer>
  );
};
