function filterRisks(riskData, liklihoodImpact) {
  let filterByRiskValue = 0;

  // can be 1 - 25
  switch (liklihoodImpact) {
    case "L5_I5": // row 1/col 5
      filterByRiskValue = 1;
      break;
    case "L5_I4": // row 1/col 4
      filterByRiskValue = 3;
      break;
    case "L5_I3": // row 1/col 3
      filterByRiskValue = 7;
      break;
    case "L5_I2": // row 1/col 2
      filterByRiskValue = 15;
      break;
    case "L5_I1": // row 1/col 1
      filterByRiskValue = 20;
      break;

    case "L4_I5": // row 2/col 5
      filterByRiskValue = 2;
      break;
    case "L4_I4": // row 2/col 4
      filterByRiskValue = 5;
      break;
    case "L4_I3": // row 2/col 3
      filterByRiskValue = 9;
      break;
    case "L4_I2": // row 2/col 2
      filterByRiskValue = 16;
      break;
    case "L4_I1": // row 2/col 1
      filterByRiskValue = 22;
      break;

    case "L3_I5": // row 3/col 5
      filterByRiskValue = 4;
      break;
    case "L3_I4": // row 3/col 4
      filterByRiskValue = 6;
      break;
    case "L3_I3": // row 3/col 3
      filterByRiskValue = 10;
      break;
    case "L3_I2": // row 3/col 2
      filterByRiskValue = 17;
      break;
    case "L3_I1": // row 3/col 1
      filterByRiskValue = 23;
      break;

    case "L2_I5": // row 4/col 5
      filterByRiskValue = 8;
      break;
    case "L2_I4": // row 4/col 4
      filterByRiskValue = 11;
      break;
    case "L2_I3": // row 4/col 3
      filterByRiskValue = 13;
      break;
    case "L2_I2": // row 4/col 2
      filterByRiskValue = 18;
      break;
    case "L2_I1": // row 4/col 1
      filterByRiskValue = 24;
      break;

    case "L1_I5": // row 5/col 5
      filterByRiskValue = 12;
      break;
    case "L1_I4": // row 5/col 4
      filterByRiskValue = 14;
      break;
    case "L1_I3": // row 5/col 3
      filterByRiskValue = 19;
      break;
    case "L1_I2": // row 5/col 2
      filterByRiskValue = 21;
      break;
    case "L1_I1": // row 5/col 1
      filterByRiskValue = 25;
      break;
    default:
      break;
  }

  const filteredArray = [];

  riskData.forEach((risk) => {
    const riskLevel = parseInt(risk.mitigatedRiskLevel, 10);
    if (riskLevel === filterByRiskValue) {
      filteredArray.push(risk);
    }
  });

  return filteredArray;
}

export default filterRisks;
