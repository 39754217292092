import styled from "styled-components/macro";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Label = styled.label`
  font-weight: 600;
`;

export const AutoLogoutWarning = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const AdminInfo = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  display: grid;
  gap: 1rem 0.5rem;
  justify-content: flex-start;
  grid-template-columns: auto minmax(auto, 400px);
`;
