import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./person-query-key-factory";
import * as ActivityKeyFactory from "../activity/activity-query-key-factory";

interface IAddPersonActivityProps {
  personId: string | null;
  activityId: string;
}

/**
 * Add an activity to person
 *
 * @returns
 */
export const addPersonActivity = ({ personId, activityId }: IAddPersonActivityProps) => {
  return personId === null
    ? Promise.reject(new Error("Invalid userId id"))
    : Brm.personApi.addPersonActivity(personId, { body: activityId });
};

interface IAddPersonActivityQueryProps {
  options?: MutationOption<typeof addPersonActivity>;
}

/**
 * Custom hook to add an activity to person
 *
 * @returns react-query Mutation.
 */
export const useAddPersonActivity = ({ options }: IAddPersonActivityQueryProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(KeyFactory.personKeys.all);
      queryClient.invalidateQueries(ActivityKeyFactory.activitiesKeys.person(variables.activityId));
    },
    ...options,
    mutationFn: addPersonActivity,
  });
};
