import styled from "styled-components";

export const AddProjectPanel = styled.div`
  max-height: 90vh;
  overflow-y: auto;
`;

export const InstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10px;
  margin-right: 12px;
`;

export const InstructionsWrapper2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const InstructionsText = styled.div`
  text-align: justify;
`;
