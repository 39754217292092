import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
// import { AccessPointShortDto } from "@kdmanalytics/brm-system";
import { accessPointKeys } from "./access-query-key-factory";

/**
 * Returns a list of all access points within a given project
 *
 * @param {string} projectId - uuid of project
 * @returns list of all access points within the given project
 */
export const getAccessPoints = ({ projectId }: { projectId: string }): Promise<any> => {
  return Brm.accessPointApi.findAccessPoint(projectId);
};

interface IUseAccessPoints {
  projectId: string;
  options?: QueryOption<typeof getAccessPoints>;
}

/**
 * Custom-hook to retrieve all access points for a project.
 *
 * @param {string} projectId - uuid of project
 * @param {object} config - react-query configuration object
 * @returns react-query for findAccesspoints
 */
export const useAccessPoints = ({ projectId, options = {} }: IUseAccessPoints) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: accessPointKeys.project(projectId),
    queryFn: () => getAccessPoints({ projectId }),
  });
};
