import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./installation-query-key-factory";

interface IInstallationStatusProps {
  installationId: string | null;
  newStatus: string;
}

/**
 * Set installation status
 *
 * @param param0 upload file data
 * @returns
 */
export const setInstallationStatus = ({ installationId, newStatus }: IInstallationStatusProps) => {
  return installationId === null
    ? Promise.reject(new Error("Invalid installationId id"))
    : Brm.installationApi.setInstallationStatus(installationId, { body: newStatus });
};

interface ISetNameProps {
  options?: MutationOption<typeof setInstallationStatus>;
}

/**
 * Hook to Set installation status
 *
 * @returns react-query Mutation.
 */
export const useSetInstallationStatus = ({ options }: ISetNameProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, err, variables) => {
      queryClient.invalidateQueries(KeyFactory.installationKeys.all);
      queryClient.invalidateQueries(KeyFactory.installationKeys.installation(variables.installationId));
    },
    ...options,
    mutationFn: setInstallationStatus,
  });
};
