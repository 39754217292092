/* eslint-disable import/prefer-default-export */
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

export const useAttacks = (projectId, variantId, riskUpdated, config = {}) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["attack", projectId, variantId, riskUpdated],
    queryFn: () => Brm.attackApi.findAttack(projectId, { variant: variantId }),
  });
};
