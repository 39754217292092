import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";
// Local
import ThreatEventCategoryTable from "./tables/ThreatEventTableCategory";

function ThreatEventCategory() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const trevCatHeader = <Title id="ThreatEventCategory_title">Threat Event Categories</Title>;

  const trevCatMain = (
    <ThreatEventCategoryTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
  );

  return <ContentLayout pageId="ThreatEventCategory_page" header={trevCatHeader} main={trevCatMain} />;
}

export default ThreatEventCategory;
