import { useState } from "react";
import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import ThreatTierTable from "./tables/ThreatTierTable";

function ThreatTier() {
  const [, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const header = <Title id="ThreatTier_title">Threat Tiers</Title>;

  const main = (
    <>
      <ThreatTierTable setSelectedRows={setSelectedRows} refresh={refresh} setRefresh={setRefresh} />
    </>
  );

  return <ContentLayout pageId="ThreatTier_page" header={header} main={main} />;
}

export default ThreatTier;
