import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./dataflow-query-key-factory";

/**
 * Creates a dataflow
 *
 * @param {string} dataflowId - uuid of the dataflow
 * @param {string} dataflowCreateDto - new name of the dataflow
 */
export const createDataflow = ({ projectId, dataflowCreateDto }: { projectId: string; dataflowCreateDto: any }) => {
  return Brm.dataflowApi.createDataFlow(projectId, { dataFlowCreateDto: dataflowCreateDto });
};

interface IUseCreateDataflow {
  options?: MutationOption<typeof createDataflow>;
}

/**
 * Custom hook to create a dataflow
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useCreateDataflow = ({ options }: IUseCreateDataflow = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.dataflowKeys.all);
    },
    ...options,
    mutationFn: createDataflow,
  });
};
