import * as React from "react";
import * as Recoil from "recoil";
import { useQueryClient } from "@tanstack/react-query";
import systemModals from "components/modals/Modals";
import GenericDeleteConfirmation from "components/modals/GenericDelete";
import { DeleteButton, AddButton } from "features/brm";
import { selectedInstanceState } from "atoms/atoms-content";
import Title from "components/elements/StyledTitle";
import Buttons from "components/elements/TableButtonsDivStyled";
import { baselineApi } from "services/brm/global-control-service";
import { TYPE } from "constants/brm";
import { controlCatalogIdState } from "atoms/atoms-global-control";

import ContentLayout from "brm/layout/Content/ContentLayout";
import { LoadingSpinner } from "components/elements";
import * as KeyFactory from "../../../brm/api/global-control/global-control-query-key-factory";

import { StandardBaselineTable, AddStandardBaselineForm } from "../../components";
import { useControlCatalog } from "../../hooks";

export function StandardBaselines() {
  const [controlCatalogId] = Recoil.useRecoilState(controlCatalogIdState);
  const setSelectedInstance = Recoil.useSetRecoilState(selectedInstanceState);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const queryClient = useQueryClient();
  const { controlCatalog, isReadOnlyCatalog } = useControlCatalog();

  const addStandardBaseline = () => {
    setModalIsOpen(true);
  };

  React.useEffect(() => {
    async function multiDeleteApiCalls() {
      setConfirmDelete(false);
      await Promise.all(selectedRows.map((each) => baselineApi.deleteStandardBaselineWithHttpInfo(each.id)));
      queryClient.invalidateQueries(KeyFactory.standardBaselineKeys.all);
      setDeleteModalIsOpen(false);
      setSelectedInstance({});
    }
    if (confirmDelete) {
      multiDeleteApiCalls();
    }
  }, [confirmDelete, queryClient, selectedRows, setSelectedInstance]);

  if (!controlCatalogId) {
    return <div> Select a control catalog to continue</div>;
  }

  if (controlCatalog) {
    const header = (
      <Title>
        Baselines
        <Buttons>
          {!isReadOnlyCatalog && selectedRows.length > 0 ? (
            <DeleteButton onClick={() => setDeleteModalIsOpen(true)} />
          ) : null}
          {isReadOnlyCatalog ? null : <AddButton onClick={addStandardBaseline} />}
        </Buttons>
      </Title>
    );

    const main = (
      <>
        {deleteModalIsOpen &&
          systemModals.deleteModal(
            "StandardBaseline_page",
            deleteModalIsOpen,
            setDeleteModalIsOpen,
            <GenericDeleteConfirmation
              elementName={TYPE.standardBaseline}
              setDeleteModalIsOpen={setDeleteModalIsOpen}
              selectedRows={selectedRows}
              setConfirmDelete={setConfirmDelete}
            />,
            TYPE.standardBaseline
          )}

        {modalIsOpen &&
          systemModals.addModal(
            "StandardBaseline_page",
            modalIsOpen,
            setModalIsOpen,
            <AddStandardBaselineForm setModalIsOpen={setModalIsOpen} />,
            TYPE.standardBaseline
          )}

        <StandardBaselineTable setSelectedRows={setSelectedRows} />
      </>
    );

    return <ContentLayout pageId="StandardBaseline_page" header={header} main={main} />;
  }
  return <LoadingSpinner />;
}
