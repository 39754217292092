import styled from "styled-components";

export const Img = styled.img`
  width: 49.5%;
  margin: 60px 0 auto;
  @media screen and (min-width: 1025px) {
    width: 33%;
    height: auto;
  }
`;
