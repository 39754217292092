import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { MutationOption } from "libs/react-query";

interface IExportControlCatalog {
  controlCatalogId: string;
  format: string;
}

/**
 * Export a control catalog.
 *
 * @param {string} controlCatalogId - control catalog id
 * @param {format} format - format of the export
 */
export const exportControlCatalog = async ({ controlCatalogId, format }: IExportControlCatalog) => {
  return Brm.controlCatalogIoApi.controlCatalogExport(controlCatalogId, format);
};

export interface IUseExportControlCatalog {
  options?: MutationOption<typeof exportControlCatalog>;
}

/**
 * Custom hook to export a control catalog.
 *
 * @param {string} controlCatalogId - control catalog id
 * @param {format} format - format of the export
 * @param {object} options - react-query options
 */
export const useExportControlCatalog = ({ options }: IUseExportControlCatalog = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: exportControlCatalog,
  });
};
