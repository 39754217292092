import { Col, Row } from "react-bootstrap";
import { COMMON } from "constants/brm";

interface IModalElementProps {
  label: string;
  value?: string | number;
}

export const ModalRow = ({ label, value }: IModalElementProps) => {
  return (
    <Row>
      <Col>{`${label} :`}</Col>
      <Col> {value || COMMON.hyphen}</Col>
    </Row>
  );
};
