import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./dataflow-query-key-factory";

/**
 * Sets the category of an Dataflow
 *
 * @param {string} dataflowId - uuid of the dataflow
 * @param {string} category - category of the dataflow
 */
export const setDataflowCategory = ({ dataflowId, category }: { dataflowId: string; category: string }) => {
  return Brm.dataflowApi.setDataFlowCategory(dataflowId, { body: category });
};

interface IUseSetDataflowCategory {
  options?: MutationOption<typeof setDataflowCategory>;
}

/**
 * Custom hook to change the category of an existing dataflow
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetDataflowCategory = ({ options }: IUseSetDataflowCategory) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.dataflowKeys.all);
    },
    ...options,
    mutationFn: setDataflowCategory,
  });
};
