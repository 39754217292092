import { LoadingSpinner } from "components/elements/LoadingSpinner";
import * as S from "./PageSpinner.styles";

interface ISpinnerProps {
  text?: string;
}

export const PageSpinner = ({ text }: ISpinnerProps) => {
  return (
    <S.Container>
      <LoadingSpinner message={text} />
    </S.Container>
  );
};
