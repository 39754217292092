// import DiagramItemType from "../../../types/DiagramItemType";
import * as YFiles from "yfiles";
import { TYPE } from "constants/brm";
import * as S from "./Tooltip.styles";

interface TooltipProps {
  item: YFiles.IModelItem;
}

// eslint-disable-next-line import/prefer-default-export
export const Tooltip = ({ item }: TooltipProps) => {
  let text = "";
  if (YFiles.IEdge.isInstance(item) || YFiles.INode.isInstance(item)) {
    if (process.env.NODE_ENV === "development") {
      if (item.tag.type === TYPE.risk) {
        text = `${item.tag.id} (${item.tag.rank}) ${item.tag.name}`;
      } else {
        text = `${item.tag.id} ${item.tag.name}`;
      }
    } else if (item.tag.type === TYPE.risk) {
      text = `(${item.tag.rank}) ${item.tag.name}`;
    } else {
      text = `${item.tag.name}`;
    }
  }

  return (
    <S.TooltipContainer>
      <S.TooltipValue>{text}</S.TooltipValue>
    </S.TooltipContainer>
  );
};
