import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  background: #f7f7f7;
  margin: 0px;
  height: 600px;
  width: 800px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  border-top: 1px solid #ccc;
  padding: 5px 0px 5px 0px;
  margin-right: 5px;
  margin-left: 5px;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
`;

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const VerticalStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #0065b8;
  width: 350px;
  padding: 10px 5px 10px 15px;
`;

export const VerticalStepHeader = styled.h5`
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
`;

export const Optional = styled.span`
  color: inherit;
`;
