/**
 * Create a react-select options array using the given src array and selectd properties
 */
export const createOptionsFromProps = ({ src, value, label }: { src: any[]; value: string; label: string }) => {
  return src.map((v) => {
    return {
      value: v[value],
      label: v[label],
    };
  });
};

/**
 * Create a react-select options array using the given values in the array as both value and label
 */
export const createOptionsFromSrc = ({ src }: { src: any[] }) => {
  return src.map((v) => {
    return {
      value: v,
      label: v,
    };
  });
};

/**
 * Create a react-select option using the given src and selectd properties
 *
 * @param src object
 * @param value  prop name to use for value
 * @param label  prop name ot use for label
 * @returns react-select option object
 */
export const toOptionFromProps = (src: any, value: string, label: string) => {
  return {
    value: src[value],
    label: src[label],
  };
};

/**
 * Create a react-select option using the given value as both value and label
 * @param src object
 * @returns react-select option object
 */
export const toOptionSrc = (src: any) => {
  return {
    value: src,
    label: src,
  };
};
