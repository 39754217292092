import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

/**
 *
 * Gets project access level
 * @param {string} projectId - uuid of project
 */
export const getProjectAccess = ({ projectId }: { projectId: string }) => {
  return Brm.projectApi.getProjectAccess(projectId);
};

interface IProjectProps {
  projectId: string;
  options?: QueryOption<typeof getProjectAccess>;
}

/**
 * Custom hook for project access level
 *
 * @param {string} projectId - uuid of user
 * @param {object} options - react-query configuration
 * @returns getprojectCatalog
 */
export const useProjectAccess = ({ projectId, options }: IProjectProps) => {
  return ReactQuery.useQuery({
    select: (data) => data.result,
    ...options,
    queryKey: KeyFactory.projectKeys.access(projectId),
    queryFn: () => getProjectAccess({ projectId }),
  });
};
