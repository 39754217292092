import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./security-objective-query-key-factory";
/**
 * Returns a list of all securityObjectives within a given project
 *
 * @param {string} projectId - uuid of project
 * @returns list of all securityObjectives within the given project
 */
export const getSecurityObjectives = ({ projectId }: { projectId: string | null }) => {
  return projectId === null
    ? Promise.reject(new Error("Invalid projectId"))
    : Brm.securityObjectiveApi.findSecurityObjective(projectId);
};

interface ISecurityObjectivesProps {
  projectId: string | null;
  options?: QueryOption<typeof getSecurityObjectives>;
}

/**
 * Custom-hook to retrieve all securityObjectives for a project.
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query configuration object
 * @returns react-query for findNode
 */
export const useSecurityObjectives = ({ projectId, options = {} }: ISecurityObjectivesProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.securityObjectiveKeys.project(projectId),
    queryFn: () => getSecurityObjectives({ projectId }),
  });
};
