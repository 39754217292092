import ReactModal from "react-modal";
import * as React from "react";
import Cancel from "assets/icons/cancelModal.svg";
import { ActionButton } from "components/elements";
import { useVariant, useProject } from "features/brm/hooks";
import { VariantSelectionForm } from "../../forms/VariantSelectionForm";
import * as S from "./VariantSelectionModal.styles";
import * as MitigationApi from "../../../api/mitigation";

interface Option {
  readonly label: string;
  readonly value: string;
}

interface IVariantSelectionModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onClosing: () => void;
}

export const VariantSelectionModal = ({ isVisible, onCancel, onClosing }: IVariantSelectionModalProps) => {
  const [selectedVariant, setSelectedVariant] = React.useState<Option | null>();
  const [currentProject] = useProject();

  const {
    handlers: { setCurrentVariant },
  } = useVariant();

  const { data: variants } = MitigationApi.useVariants({
    projectId: currentProject?.id || "",
  });

  const handleClose = () => {
    onClosing();
  };

  const handleChange = (selectedItem: Option) => {
    setSelectedVariant(selectedItem);
  };

  const handleSelectVariant = () => {
    const variant = variants.find((v: any) => v.id === selectedVariant?.value);
    const varState = {
      id: variant.id,
      name: variant.name,
      isRecalcNeeded: variant.isRecalcNeeded,
    };
    setCurrentVariant(varState);
    handleClose();
  };

  return (
    <ReactModal
      isOpen={isVisible}
      onAfterClose={handleClose}
      ariaHideApp={false}
      onRequestClose={handleClose}
      style={S.rootModalStyle}
    >
      <S.Header>
        <S.ModalTitle>Select Variant</S.ModalTitle>
        <S.HeaderButtonsDiv>
          <S.Close onClick={handleClose}>
            <S.CloseIcon src={Cancel} />
          </S.Close>
        </S.HeaderButtonsDiv>
      </S.Header>
      <S.Body>
        <VariantSelectionForm onChange={handleChange} />
      </S.Body>
      <S.Footer>
        <S.ButtonContainer>
          <ActionButton onClick={onCancel}>Cancel</ActionButton>
          <ActionButton onClick={handleSelectVariant} disabled={!selectedVariant}>
            Select Variant
          </ActionButton>
        </S.ButtonContainer>
      </S.Footer>
    </ReactModal>
  );
};
