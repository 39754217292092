import PropTypes from "prop-types";

function DynamicLevelSelectBox({ arrayOfData, item }) {
  let options;
  if (Array.isArray(arrayOfData) && arrayOfData.length) {
    // check if there is any data in the array first
    options = arrayOfData.map((data) => (
      <option key={data.id} value={JSON.stringify(data.name)}>
        {data.name.level} - {data.name.letter}
      </option>
    ));
  }
  return (
    <select name={item} id={item}>
      <option>Select {item}</option>
      {options}
    </select>
  );
}

DynamicLevelSelectBox.propTypes = {
  arrayOfData: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    })
  ),
  item: PropTypes.any,
};

export default DynamicLevelSelectBox;
