import * as React from "react";
import { useNavigate } from "react-router-dom";
import errorUtils from "utils/error-utils";
import { isNotDeletedStatus } from "utils/filter-utils";

import { useRoles } from "features/brm/hooks/useRoles";
import { AdminApi } from "features/brm";
import toast from "react-hot-toast";

import SelectBox from "components/forms/DynamicSelectBoxRoles";
import { userIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import FormStyledError from "components/forms/FormStyledError";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import Title from "components/elements/StyledTitle";
import SelectBoxForOrg from "components/forms/DynamicSelectBoxOrgs";
import { userRoles, userApi } from "services/brm/admin-service";
import { UserCreateDto } from "@kdmanalytics/brm-admin";
import { STATUS } from "constants/brm";
import { RoutePath } from "routes/route-paths";
import * as S from "./AddUserForm.styles";

const unusedRoles = [
  userRoles.properties[userRoles.EVIDENCE_SERVER_COORDINATOR],
  userRoles.properties[userRoles.SW_DEVELOPER],
  userRoles.properties[userRoles.BOR_PROJECT_COORDINATOR],
  userRoles.properties[userRoles.TECH_ADMIN],
  userRoles.properties[userRoles.BUILD_ENGINEER],
  userRoles.properties[userRoles.AUTH_OFFICER],
  userRoles.properties[userRoles.RMF_COORDINATOR],
  userRoles.properties[userRoles.EXECUTIVE],
  userRoles.properties[userRoles.SCA],
];
const SUPERADMIN = userRoles.properties[userRoles.SUPER_ADMIN].key;
const ADMIN = userRoles.properties[userRoles.ADMIN].key;

const MIN_PASSWORD_LENGTH = 16;

const userRolesList = Object.values(userRoles.properties);

export const AddUserForm = () => {
  const navigate = useNavigate();
  const userId = useRecoilValue(userIdState);
  const { isAdmin, isSuperAdmin } = useRoles();

  const { data: allUsersNames } = AdminApi.useUsers({
    options: {
      enabled: isSuperAdmin,
      select: React.useCallback(
        (data) => data.filter((user) => user.status !== STATUS.deleted).map((user) => user.username.toLowerCase()),
        []
      ),
    },
  }); // Get list of all users irrespective of organization

  const { data: userOrg, isError } = AdminApi.useUserPrimaryOrganization({ userId });

  const { data: organizations, isError: isSaError } = AdminApi.useOrganizations({ options: { enabled: isSuperAdmin } });

  const [defaultRolelist, setDefaultRoleList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [missingFirstName, setMissingFirstName] = React.useState(false);
  const [missingLastName, setMissingLastName] = React.useState(false);
  const [missingUserName, setMissingUserName] = React.useState(false);
  const [repeatedUserName, setRepeatedUserName] = React.useState(false);
  const [missingEmail, setMissingEmail] = React.useState(false);
  const [missingDefaultRole, setMissingDefaultRole] = React.useState(false);
  const [missingOrganization, setMissingOrganization] = React.useState(false);
  const [missingPassword, setMissingPassword] = React.useState(false);
  const [missingPassword2, setMissingPassword2] = React.useState(false);
  const [postError, setPostError] = React.useState("");

  if (isError) {
    toast.error("Error occured while fetching organization list");
  }

  if (isSaError) {
    toast.error("Error occured while fetching super admin organization list");
  }

  const userOrganization = React.useMemo(() => {
    let userOrgs = [];
    if (isSuperAdmin) {
      userOrgs = organizations && Array.isArray(organizations) ? organizations : [];
    } else {
      let userOrgArray = [];
      if (Array.isArray(userOrg)) {
        userOrgArray = userOrg;
      } else {
        userOrgArray = [userOrg];
      }
      userOrgs = userOrg && userOrg.id ? userOrgArray : [];
    }
    return userOrgs;
  }, [isSuperAdmin, organizations, userOrg]);

  const valuesToDefaultRole = (role, e) => {
    const array = [...filteredList];
    for (let i = 0; i < filteredList.length; i += 1) {
      if (filteredList[i].key === role) {
        array[i].checked = e?.target.checked || false;
        break;
      }
    }
    setFilteredList(array);
  };

  function validationCheck(formData) {
    const username = formData.username.value;
    const password1 = formData.password.value;
    const password2 = formData.password2.value;
    const regex = /^[A-Za-z0-9 ]+$/;
    const regexForEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    // const regexForPassword = /^[A-Z0-9a-z]+$/;
    const regexForPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{16,}$/;

    if (formData.firstname.value === "" || !regex.test(formData.firstname.value)) {
      setPostError("* Enter a valid First Name *");
      setMissingFirstName(true);
      return false;
    }
    if (formData.lastname.value === "" || !regex.test(formData.lastname.value)) {
      setPostError("* Enter a valid Last Name *");
      setMissingLastName(true);
      return false;
    }
    if (username === "") {
      setPostError("* Enter Username *");
      setMissingUserName(true);
      return false;
    }
    if (allUsersNames && allUsersNames.includes(username.toLowerCase())) {
      setPostError(`* Username ${username} already exists please select a new username *`);
      setRepeatedUserName(true);
      return false;
    }
    if (formData.email.value === "" || !regexForEmail.test(formData.email.value)) {
      setPostError("* Enter a valid Email *");
      setMissingEmail(true);
      return false;
    }

    if (password1.length < MIN_PASSWORD_LENGTH) {
      setPostError(`*Password should be ${MIN_PASSWORD_LENGTH} characters long*`);
      setMissingPassword(true);
      return false;
    }

    if (!regexForPassword.test(password1)) {
      setPostError("*Password should be mix of uppercase, lowercase letters, special characters and numbers *");
      setMissingPassword(true);
      return false;
    }

    if (password1 === formData.username.value) {
      setPostError("* Password should not be same as username *");
      setMissingPassword(true);
      return false;
    }

    if (password1 === formData.email.value) {
      setPostError("* Password should not be same as email *");
      setMissingPassword(true);
      return false;
    }

    if (password1 === "" || password2 === "") {
      setPostError("* Enter Password *");
      setMissingPassword(true);
      return false;
    }

    if (password1 !== password2) {
      setPostError("* Passwords do not match, try again *");
      setMissingPassword2(true);
      return false;
    }
    if (formData.role.value === "" || formData.role.value === "Select role") {
      setPostError("* Please select a default role *");
      setMissingDefaultRole(true);
      return false;
    }
    if (formData.org?.value === "" || formData.org?.value === "Select org") {
      setPostError("* Please select an organization *");
      setMissingOrganization(true);
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = [];
    let isValid = true;
    setPostError("");

    isValid = validationCheck(e.target.elements);
    const form = document.getElementById("UserAddPage_form");
    const input = form.getElementsByTagName("input");

    if (isValid) {
      if (input.length !== 0) {
        for (let i = 0; i < input.length; i += 1) {
          if (input[i].type === "checkbox" && input[i].checked) {
            if (!values.includes(input[i].checked)) {
              values.push(input[i].value);
            }
          }
        }
      } else {
        setPostError("select at least one role! ");
      }

      if (values.length !== 0) {
        const params = {
          firstname: e.target.elements.firstname.value,
          lastname: e.target.elements.lastname.value,
          username: e.target.elements.username.value,
          /* note is mandatory but if nothing entered.. an empty string is passed */
          note: e.target.elements.note.value || "",
          status: STATUS.active,
          email: e.target.elements.email.value,
          password: e.target.elements.password.value,
          assignedRole: values,
          isTemporaryPassword: true,
          isEmailVerificationNeeded: true,
        };
        // mandatory
        const role = e.target.elements.role.value;

        const dto = UserCreateDto.constructFromObject(params);
        const opts = { userCreateDto: dto };

        try {
          const { data, response } = await userApi.createUserWithHttpInfo(
            isAdmin ? userOrganization?.[0]?.id : e.target.elements.org?.value,
            opts
          );
          errorUtils.checkErrorStatus(response.status, response.statusText);
          if (role !== "Select role") {
            try {
              const responseForRole = await userApi.setUserRoleWithHttpInfo(data, {
                body: role,
              });
              errorUtils.checkErrorStatus(responseForRole.response.status, responseForRole.response.statusText);
              navigate(RoutePath.Users);
            } catch (err) {
              console.error("err in setting default role to created user : ", err);
            }
          } else {
            navigate(RoutePath.Users);
          }
        } catch (err) {
          setPostError("Incorrect creadentials for creating the user");
          console.error(" err in creating the user : ", err);
        }
      } else {
        setPostError("Select at least one Role!");
      }
    }
  };

  const onInputChange = () => {
    setMissingEmail(false);
    setMissingFirstName(false);
    setMissingLastName(false);
    setMissingUserName(false);
    setRepeatedUserName(false);
    setMissingDefaultRole(false);
    setMissingOrganization(false);
    setMissingPassword(false);
    setMissingPassword2(false);
  };

  React.useEffect(() => {
    if (isAdmin) {
      /* FILTER ASSIGNED ROLES LIST */
      const result = userRolesList
        .filter((i) => !unusedRoles.some((j) => j.key === i.key))
        .map((i) => {
          return {
            ...i,
            checked: i.key !== ADMIN || false,
          };
        });
      const removeSA = result.filter((e) => e.key !== userRoles.properties[userRoles.SUPER_ADMIN].key);
      setFilteredList(removeSA);
    } else {
      /* FILTER ASSIGNED ROLES LIST */
      const result = userRolesList
        .filter((i) => !unusedRoles.some((j) => j.key === i.key))
        .map((i) => {
          const check = !(i.key === SUPERADMIN || i.key === ADMIN);
          return {
            ...i,
            checked: check,
          };
        });
      setFilteredList(result);
    }
  }, [isAdmin]);

  React.useEffect(() => {
    if (filteredList.length) {
      const array = filteredList
        .filter((list) => list.checked)
        .map((list) => ({ id: list.key, name: list.name, role: list.key }));
      setDefaultRoleList(array);
    }
  }, [filteredList]);

  return (
    <S.AddUserPanel id="UserAddPage_panel">
      <Title id="UserAddPage_Title">Create User</Title>
      <S.Form id="UserAddPage_form" className="userForm" onSubmit={handleSubmit} action="">
        <div className="row" id="UserAddPage_formContent">
          <div className="column" id="UserAddPage_formInputFields">
            <div className="input_group">
              <S.InputLabel className="firstName" id="UserAddPage_FirstName">
                First Name*:
              </S.InputLabel>
              <div
                style={{
                  border: missingFirstName ? "2px solid red" : "none",
                }}
              >
                <S.TextInput
                  onChange={onInputChange}
                  id="firstname"
                  className="inputField"
                  type="text"
                  name="firstname"
                  placeholder="First Name"
                />
              </div>
              <span />
            </div>

            <div className="input_group">
              <S.InputLabel id="UserAddPage_LastName">Last Name*:</S.InputLabel>
              <div
                style={{
                  border: missingLastName ? "2px solid red" : "none",
                }}
              >
                <S.TextInput
                  onChange={onInputChange}
                  className="inputField"
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Last Name"
                />
              </div>
            </div>

            <div className="input_group">
              <S.InputLabel id="UserAddPage_UserName">Username*:</S.InputLabel>
              <div
                style={{
                  border: missingUserName || repeatedUserName ? "2px solid red" : "none",
                }}
              >
                <S.TextInput
                  onChange={onInputChange}
                  className="inputField"
                  type="text"
                  name="username"
                  id="username"
                  placeholder="Username"
                />
              </div>
            </div>
            <div className="input_group">
              <S.InputLabel id="UserAddPage_Note">Note:</S.InputLabel>
              <S.TextArea
                className="inputField"
                type="textbox"
                name="note"
                placeholder="Note"
                rows="3"
                cols="25"
                id="note"
              />
            </div>

            <div className="input_group">
              <S.InputLabel id="UserAddPage_Email">Email*:</S.InputLabel>
              <div
                style={{
                  border: missingEmail ? "2px solid red" : "none",
                }}
              >
                <S.TextInput
                  onChange={onInputChange}
                  id="email"
                  className="inputField"
                  type="text"
                  name="email"
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="input_group">
              <S.InputLabel id="UserAddPage_CreatePassword">Create Password*:</S.InputLabel>
              <div
                style={{
                  border: missingPassword ? "2px solid red" : "none",
                }}
              >
                <S.TextInput
                  onChange={onInputChange}
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Password"
                />
              </div>
              <br />
            </div>
            <div className="input_group">
              <S.InputLabel id="UserAddPage_RetypePassword">Retype Password*:</S.InputLabel>
              <div
                style={{
                  border: missingPassword2 ? "2px solid red" : "none",
                }}
              >
                <S.TextInput
                  onChange={onInputChange}
                  id="retypePassword"
                  type="password"
                  name="password2"
                  placeholder="Re-type password"
                />
              </div>
            </div>
          </div>

          <div className="column space-between">
            <div>
              <div className="assigned_roles" id="UserAddPage_assignedRolesSection">
                <S.InputLabel id="UserAddPage_assignedRolesTitle">Select Assigned Roles*:</S.InputLabel>
                <div id="UserAddPage_assignedRolesList">
                  {filteredList &&
                    filteredList.map((item) => (
                      <div key={item.key} className="checkbox_group">
                        <S.RoleName id={item.name} key={item.key}>
                          <S.CheckBox
                            type="checkbox"
                            value={item.key}
                            name="assignedRole"
                            onChange={(e) => valuesToDefaultRole(item.key, e)}
                            checked={item.checked}
                          />
                          {item.name}
                        </S.RoleName>
                        <br />
                      </div>
                    ))}
                </div>
              </div>

              <div className="roles">
                <S.InputLabel id="UserAddPage_roles">Default Role*:</S.InputLabel>
                <div
                  style={{
                    border: missingDefaultRole ? "2px solid red" : "none",
                  }}
                >
                  <SelectBox id="defaultRoles" arrayOfData={defaultRolelist} item="role" />
                </div>
              </div>
              <br />
              {isSuperAdmin && (
                <div className="Organization">
                  <S.InputLabel id="UserAddPage_Organization">Select Organization*:</S.InputLabel>
                  <div
                    style={{
                      border: missingOrganization ? "2px solid red" : "none",
                    }}
                  >
                    <SelectBoxForOrg
                      id="Organization"
                      arrayOfData={userOrganization?.filter(isNotDeletedStatus)}
                      item="org"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="passwordPolicyWrapper">
              <ul>
                <li>
                  Password must be at least {MIN_PASSWORD_LENGTH} characters long, contain uppercase, lowercase letters,
                  special characters and numbers.
                </li>
                <li>The password cannot be the same as the username / email address</li>
              </ul>
            </div>
          </div>
        </div>
        <FormStyledError id="UserAddPage_Error">{postError}</FormStyledError>
        <S.ButtonsArea id="UserAddPage_buttons">
          <DialogButtonStyled type="button" onClick={() => navigate(-1)}>
            Cancel
          </DialogButtonStyled>
          <DialogButtonStyled type="submit" id="UserAddPage_addButton" disabled={isError || isSaError}>
            Add
          </DialogButtonStyled>
          <DialogButtonStyled type="reset" value="Reset">
            Reset
          </DialogButtonStyled>
        </S.ButtonsArea>
      </S.Form>
    </S.AddUserPanel>
  );
};
