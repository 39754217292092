import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./variant-query-key-factory";

interface IGetVariants {
  projectId: string;
}

/**
 *
 * @param {string} projectId - uuid of the project
 * @returns list of variants
 */
export const getVariants = async ({ projectId }: IGetVariants): Promise<any> => {
  if (projectId === "") {
    return Promise.reject(new Error("Invalid projectId"));
  }
  return Brm.variantApi.findVariant(projectId);
};

interface IUseVariants {
  projectId: string;
  options?: QueryOption<typeof getVariants>;
}

/**
 * React-query hook for getting all variants
 *
 * @param {string} projectId - uuid of the project
 * @param {object} config - react-query configuration object
 * @returns react-query for getVariants
 */
export const useVariants = ({ projectId, options = {} }: IUseVariants) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.variantsKeys.project(projectId),
    queryFn: () => getVariants({ projectId }),
  });
};
