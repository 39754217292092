import styled from "styled-components/macro";

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors.title};
  /* height: 46px; */
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.7em;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
`;

export const FullDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const DetailsContainerSingleTable = styled.div`
  padding: 10px;
  padding-bottom: 20px;
`;

export const ShowSummaryTableButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

export const DetailsContainerParent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PasswordResetDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 10px 5px 10px;
  /* width: 100%;
  height: inherit; */
  background-color: ${({ theme }) => theme.colors.background};
  margin: 0px;

  .react-tabs {
    display: flex;
    flex-direction: column;
    flex: 1;

    /* height: 100%; */

    .react-tabs__tab-panel--selected {
      display: flex;
      flex-direction: column;
      flex: 1;
      /* height: 100%; */
    }
  }
`;
