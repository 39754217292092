import { riskUpdatedState } from "atoms/atoms-risk";
import { sortByRiskRank } from "utils/sorting";
import { useQuery } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
// Services
import { busApi, linkApi, exchangeApi, nodeApi } from "services/brm/system-service";
import { DetailTableType } from "features/brm";

async function getDepRisks(elementId, elementName, variantId) {
  let result = [];
  switch (elementName) {
    case DetailTableType.BUSES.key: {
      result = await busApi.getBusAllDependentRisk(elementId, { variant: variantId });
      break;
    }
    case DetailTableType.NODES.key: {
      return nodeApi.getNodeAllDependentRisk(elementId, { variant: variantId });
    }
    case DetailTableType.LINKS.key: {
      result = await linkApi.getLinkAllDependentRisk(elementId, { variant: variantId });
      break;
    }
    case DetailTableType.EXCHANGES.key: {
      result = await exchangeApi.getExchangeAllDependentRisk(elementId, { variant: variantId });
      break;
    }
    default: {
      break;
    }
  }

  if (Array.isArray(result)) {
    try {
      // remove risks with score of 0
      result.forEach((risk) => {
        if (risk.mitigatedScore.value === 0) {
          const index = result.indexOf(risk);
          result.splice(index, 1);
        }
      });
    } catch (err) {
      console.error("Error in removing risks with mitigatedScore of 0: ", err);
    }
    return result.sort(sortByRiskRank);
  }
  return [result].sort(sortByRiskRank);
}

const useGetElementDependentRisk = (elementId, elementName, variantId) => {
  const riskUpdated = useRecoilValue(riskUpdatedState);
  return useQuery(
    ["getElementDependentRisk", elementId, elementName, riskUpdated, variantId],
    () => getDepRisks(elementId, elementName, variantId),
    {
      enabled: !!elementId,
      cacheTime: 0,
    }
  );
};

export default useGetElementDependentRisk;
