import styled from "styled-components";

const CalibrationStyles = {
  CalibrateButtons: styled.button`
    float: right;
    background-color: ${({ theme }) => theme.primarybuttonbackgroundcolor};
    border-radius: 3px;
    color: white;
    font-family: ${({ theme }) => theme.fonts.primary};
    border: 1px black;
    padding: 5px;
    margin: 0px 8px;
    opacity: ${(prop) => (prop.disabled ? 0.3 : 1)};
    &:hover {
      opacity: ${(prop) => (prop.disabled ? 0.3 : 0.8)};
      cursor: ${(prop) => (prop.disabled ? "auto" : "pointer")};
    }
  `,
  FilterSection: styled.div`
    width: 100%;
    padding: ${({ theme }) => theme.sizes.defaultPadding};
    margin-bottom: 10px;
    background: ${({ theme }) => theme.colors.lowContrastBackground};
    position: relative;
    height: auto;
  `,
  ShowFilterButton: styled.button`
    margin: 5px;
    background: none;
    color: inherit;
    border: none;
    padding-top: 10px;
    font-family: ${({ theme }) => theme.fonts.primary};
    cursor: pointer;
    :focus {
      outline: none;
    }
    :hover {
      opacity: 0.5;
    }
  `,
  SelectDiv: styled.div`
    margin-left: 10px;
    margin-right: 10px;
    width: 23%;
    display: inline-block;
  `,
  SelectLabel: styled.label`
    margin-top: 10px;
    width: 100%;
    display: inline-block;
    font-size: 12px;
  `,
  HideLabel: styled.label`
    padding-left: 10px;
    font-family: ${({ theme }) => theme.fonts.primary};
  `,
  BodyDiv: styled.div`
    height: 120px;
  `,
  Note: styled.span`
    margin-left: 5px;
    padding-bottom: 10px;
  `,
};

export default CalibrationStyles;
