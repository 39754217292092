import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import errorUtils from "utils/error-utils";

import { activityApi } from "services/brm/system-service";
import { DeleteButton, AddButton, DetailTableType, BrmError } from "features/brm";
import systemModals from "components/modals/Modals";
import { BrmDetailTable } from "brm/tables/BrmTables";
import {
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnNameDetails,
} from "brm/tables/services/column/columnFactory";
import * as S from "brm/styles/details-table.styles";
import { RoutePath } from "routes/route-paths";
import multiSelectDelete from "./utils/DetailTablesUtils";

import NextAddForm from "./forms/NextAddForm";
// Styles
const PrevTable = ({ elementNameInUrl, selectedElement, showAdd, showDelete, setRefresh, refresh, tableTitle }) => {
  const columns = useMemo(
    () => [
      createColumnNameDetails(RoutePath.Activity.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnBooleanNoEditMapped("isInternal"),
      createColumnBooleanNoEditMapped("isBoundary"),
      createColumnBooleanNoEditMapped("isInitial"),
      createColumnBooleanNoEditMapped("isFinal"),
      createColumnMappedNoEdit("node"),
    ],
    []
  );

  const [nextData, setNextData] = useState([]);
  const [, setSelectedNext] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  function getDeleteRequest(activityId) {
    switch (elementNameInUrl) {
      case DetailTableType.ACTIVITIES.key: {
        return activityApi.removeActivityNextWithHttpInfo(selectedElement.id, activityId);
      }
      default: {
        break;
      }
    }
    throw new BrmError(`Unsupported element type:${elementNameInUrl}`);
  }

  useEffect(() => {
    async function getData() {
      function getElementRequest() {
        switch (elementNameInUrl) {
          case DetailTableType.ACTIVITIES.key: {
            return activityApi.getActivityNextWithHttpInfo(selectedElement.id);
          }
          default: {
            break;
          }
        }
        throw new BrmError(`Unsupported element type:${elementNameInUrl}`);
      }

      try {
        const res = await getElementRequest();
        if (res?.data) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
          setNextData(res.data);
        }
      } catch (err) {
        console.error("Error while loading next activity ", err);
      }
    }
    getData();
  }, [refresh, elementNameInUrl, selectedElement]);

  const addNext = () => {
    setModalIsOpen(true);
  };

  return (
    <S.DetailsContainer id="PrevTable_detailsPanel">
      {modalIsOpen &&
        systemModals.addModal(
          "PrevTable_detailsPanel",
          modalIsOpen,
          setModalIsOpen,
          <NextAddForm
            setModalIsOpen={setModalIsOpen}
            setRefresh={setRefresh}
            elementNameInUrl={elementNameInUrl}
            selectedElement={selectedElement}
          />,
          "Previous"
        )}
      <S.ActionContainer>
        <S.DetailsTableContainer>
          <BrmDetailTable
            data={nextData}
            columns={columns}
            setSelectedElement={setSelectedNext}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "PrevTable_detailsTable" }}
            showRowSelect={showDelete}
            tableTitle={tableTitle}
          />
        </S.DetailsTableContainer>
        <S.DetailsTableButtonsContainer>
          {showDelete && (
            <DeleteButton md onClick={() => multiSelectDelete(selectedRows, getDeleteRequest, setRefresh)} />
          )}
          {showAdd && <AddButton md onClick={addNext} />}
        </S.DetailsTableButtonsContainer>
      </S.ActionContainer>
    </S.DetailsContainer>
  );
};

PrevTable.propTypes = {
  elementNameInUrl: PropTypes.any,
  refresh: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setRefresh: PropTypes.any,
  showAdd: PropTypes.any,
  showDelete: PropTypes.any,
  tableTitle: PropTypes.string,
};

export default PrevTable;
