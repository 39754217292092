import { useState, useMemo } from "react";
import { BrmDetailTable } from "brm/tables/BrmTables";
import {
  createColumnBooleanNoEditMapped,
  createColumnMappedNoEdit,
  createColumnNameDetails,
} from "brm/tables/services/column/columnFactory";

import { ExportButton } from "features/brm";
import { ExportTableModal, useExportTable } from "features/exporter";
import { useModal } from "hooks";

// Styles
import * as S from "brm/styles/details-table.styles";
import { LoadingSpinner, ErrorBanner } from "components/elements";
import { useElementTopics } from "./api";

interface ITopicProps {
  elementNameInUrl: string;
  selectedElement: {
    id: string;
  };

  tableTitle: string;
}

const TopicTable = ({ elementNameInUrl, selectedElement, tableTitle }: ITopicProps) => {
  const [, setSelectedSystemObject] = useState([]);
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  const { data: topicData, isError: isTopicError } = useElementTopics({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    config: {
      enabled: !!selectedElement.id,
    },
  });

  const columns = useMemo(
    () => [
      createColumnNameDetails(""),
      createColumnMappedNoEdit("noun"),
      createColumnBooleanNoEditMapped("isInternal"),
      createColumnBooleanNoEditMapped("isBoundary"),
    ],
    []
  );

  if (isTopicError) {
    return <ErrorBanner msg="Error while loading topic data" />;
  }

  if (topicData) {
    return (
      <S.DetailsContainer id="TopicTable_detailsPanel">
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={Array.isArray(topicData) ? topicData : [topicData]}
              columns={columns}
              setSelectedElement={setSelectedSystemObject}
              customProps={{ id: "TopicTable_detailsTable" }}
              showRowSelect={false}
              elementName={elementNameInUrl}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailsTableButtonsContainer>
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailsTableButtonsContainer>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

export default TopicTable;
