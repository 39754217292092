/* eslint-disable no-param-reassign */
import * as Recoil from "recoil";
import { useEffect, useMemo, useState } from "react";
// State
import { selectedInstanceState } from "atoms/atoms-content";
import { variantIdState } from "atoms/atoms-component";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";
import convertedPercent from "utils/percent-converter";
import * as BrmGql from "generated/graphql";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { currentProjectState, RiskApi, isCurrentProjectActiveState, useRoles } from "features/brm";
import { queryClient } from "libs/react-query";
import { createColumnNameNoNav, createColumnMappedNoEdit } from "brm/tables/services/column/columnFactory";

const AssetCategoryTable = ({ setSelectedRows, refresh, setRefresh }) => {
  const { isRiskAnalyst } = useRoles();
  const currentProject = Recoil.useRecoilValue(currentProjectState);
  const isCurrentProjectActive = Recoil.useRecoilValue(isCurrentProjectActiveState);

  const variantId = Recoil.useRecoilValue(variantIdState);
  const [selectedInstance] = Recoil.useRecoilState(selectedInstanceState);

  const projectId = currentProject?.id;
  const { data: riskHasBeenRun } = RiskApi.useRiskCalculated({
    projectId,
    variantId,
    options: {
      enabled: isCurrentProjectActive && isRiskAnalyst,
    },
  });

  const { data: assetCategories, isError: isAssetCategoriesError } = BrmGql.useAssetCatsQuery(
    { project: projectId, variant: variantId },
    {
      refetchOnWindowFocus: false,
      enabled: isCurrentProjectActive && isRiskAnalyst,
    }
  );
  const [assetCategoriestoDisplay, setAssetCategoriestoDisplay] = useState(null);

  useEffect(() => {
    if (isCurrentProjectActive && assetCategories) {
      const assetCatsWithRisk = assetCategories.assetcats.map((assetCat) => {
        const updatedAssetCat = { name: assetCat.name };
        if (riskHasBeenRun) {
          const percent = assetCat.riskDistribution.percent * 100;
          const { rank } = assetCat.riskDistribution;

          updatedAssetCat.riskDistributionPercent = convertedPercent(percent);
          updatedAssetCat.riskDistributionRank = rank;
        } else {
          // set risk distribution values to N/A
          updatedAssetCat.riskDistributionPercent = "N/A";
          updatedAssetCat.riskDistributionRank = "N/A";
        }
        return updatedAssetCat;
      });

      setAssetCategoriestoDisplay(assetCatsWithRisk);
    }
  }, [assetCategories, riskHasBeenRun, isCurrentProjectActive]);

  const columns = useMemo(
    () => [
      createColumnNameNoNav(selectedInstance.id, false, null),
      createColumnMappedNoEdit("riskDistributionRank"),
      createColumnMappedNoEdit("riskDistributionPercent"),
    ],
    [selectedInstance.id]
  );

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      queryClient.invalidateQueries(["assetCats", projectId, variantId]);
    }
  }, [refresh, projectId, variantId, setRefresh]);

  if (isAssetCategoriesError) {
    return <ErrorBanner msg="Error loading Asset Category data." />;
  }

  if (assetCategoriestoDisplay) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={assetCategoriestoDisplay}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "AssetCategoryTable_table" }}
            showRowSelect={false}
            elementName={TYPE.assetCat}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

AssetCategoryTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default AssetCategoryTable;
