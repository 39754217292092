import { useState, useMemo } from "react";
// Components
import { BrmDetailTable } from "brm/tables/BrmTables";
// Utils
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
// Hooks
import { useRoles } from "features/brm/hooks/useRoles";
// Styles
import * as S from "brm/styles/details-table.styles";
import { DetailTableType, SystemApi } from "features/brm";
import { CenterText, ErrorBanner, LoadingSpinner } from "components/elements";
import { RoutePath } from "routes/route-paths";
import { useRecoilValue } from "recoil";
import { projectIdState } from "atoms";

interface IAttackVectorProps {
  elementNameInUrl: string;
  selectedElement: {
    id: string;
  };
  tableTitle: string;
}

const AttackVectorTable = ({ elementNameInUrl, selectedElement, tableTitle }: IAttackVectorProps) => {
  const { isThreatAnalyst } = useRoles();
  const projectId = useRecoilValue(projectIdState);
  const columns = useMemo(() => {
    if (isThreatAnalyst) {
      return [createColumnMappedNoEdit("name"), createColumnMappedNoEdit("category"), createColumnMappedNoEdit("node")];
    }
    return [
      createColumnNameDetails(RoutePath.AttackVector.replace(":id", "")),
      createColumnMappedNoEdit("category"),
      createColumnMappedNoEdit("node"),
    ];
  }, [isThreatAnalyst]);

  const [, setSelectedAttackVector] = useState([]);

  const { data: elementAttackVectorData, isError: isElementAttackVectorDataError } = SystemApi.useElementAttackVectors({
    elementId: selectedElement?.id,
    elementName: elementNameInUrl,
    projectId,
    options: {
      enabled:
        !!selectedElement.id &&
        elementNameInUrl !== DetailTableType.DATATYPES.key &&
        elementNameInUrl !== DetailTableType.MISSIONS.key &&
        elementNameInUrl !== DetailTableType.CAPABILITIES.key &&
        elementNameInUrl !== DetailTableType.DATAFLOWS.key &&
        elementNameInUrl !== DetailTableType.PERSONS.key &&
        elementNameInUrl !== DetailTableType.RESOURCES.key,
    },
  });

  if (
    elementNameInUrl === DetailTableType.DATATYPES.key ||
    elementNameInUrl === DetailTableType.MISSIONS.key ||
    elementNameInUrl === DetailTableType.CAPABILITIES.key ||
    elementNameInUrl === DetailTableType.DATAFLOWS.key ||
    elementNameInUrl === DetailTableType.PERSONS.key ||
    elementNameInUrl === DetailTableType.RESOURCES.key
  ) {
    return <CenterText msg="Attack Vectors are not applicable" />;
  }

  if (isElementAttackVectorDataError) {
    return <ErrorBanner msg="Error while loading attack vectors" />;
  }

  if (elementAttackVectorData) {
    return (
      <S.DetailsContainer id="AttackVectorTable_detailsPanel">
        <BrmDetailTable
          data={elementAttackVectorData}
          columns={columns}
          setSelectedElement={setSelectedAttackVector}
          customProps={{ id: "AttackVectorTable_detailsTable" }}
          showRowSelect={false}
          tableTitle={tableTitle}
        />
      </S.DetailsContainer>
    );
  }

  return <LoadingSpinner />;
};

export default AttackVectorTable;
