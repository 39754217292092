import * as Brm from "services/brm";
import * as ReactQuery from "@tanstack/react-query";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./exchange-query-key-factory";

interface IGetControlsForExchange {
  exchangeId: string;
  variantId: string;
}

/**
 * Returns a list of controls applied to the exchange
 *
 * @param {string} exchangeId - uuid of the exchange
 * @param {string} variantId - uuid of the variant
 * @param {object} config - react-query configuration object
 * @returns react-query mutation
 */
export const getControlsForExchange = ({ exchangeId, variantId }: IGetControlsForExchange) => {
  return Brm.exchangeApi.getExchangeAllControl(exchangeId, { variant: variantId });
};

interface IUseControlsForExchange {
  exchangeId: string;
  variantId: string;
  options?: QueryOption<typeof getControlsForExchange>;
}

/**
 * React-query custom hook for retrieving controls for an exchange
 *
 * @param {string} exchangeId - uuid of the exchange
 * @param {string} variantId - uuid of the variant
 * @param {object} options - react-query configuration object
 * @returns react-query mutation
 */
export const useControlsForExchange = ({ exchangeId, variantId, options }: IUseControlsForExchange) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.exchangeKeys.controls(exchangeId, variantId),
    queryFn: () => getControlsForExchange({ exchangeId, variantId }),
  });
};
