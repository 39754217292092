import { BrmError, DetailTableType, useRoles } from "features/brm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// Services
import { attackApi } from "services/brm/attack-service";
import { baselineApi, controlFamilyApi } from "services/brm/global-control-service";

async function getControlTypes(elementId, elementName) {
  switch (elementName) {
    case DetailTableType.CONTROL_FAMILIES.key: {
      const data = await controlFamilyApi.getControlFamilyControl(elementId);
      return data;
    }
    case DetailTableType.STANDARD_BASELINE.key: {
      const data = await baselineApi.getStandardBaselineControltype(elementId);
      return data;
    }
    case DetailTableType.ATTACKS.key: {
      const data = await attackApi.getAttackAllControltype(elementId);
      return data;
    }
    default: {
      break;
    }
  }
  throw new BrmError(`Unsupported element type: ${elementName}`);
}

const deleteControlType = async (elementId, elementName, controlId) => {
  switch (elementName) {
    case DetailTableType.STANDARD_BASELINE.key: {
      const data = await baselineApi.removeStandardBaselineControltype(elementId, controlId);
      return data;
    }
    default:
      break;
  }
  throw new BrmError(`Unsupported element type: ${elementName}`);
};

export const useGetElementControlTypes = (elementId, elementName) => {
  const { isControlsCoordinator } = useRoles();
  return useQuery(["getElementControlTypes", elementId, elementName], () => getControlTypes(elementId, elementName), {
    enabled: !!elementId && !!elementName && isControlsCoordinator,
    cacheTime: 0,
  });
};

export const useDeleteElementControlTypes = (elementId, elementName) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ controlId }) => {
      return deleteControlType(elementId, elementName, controlId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getElementControlTypes", elementId, elementName]);
      },
    }
  );
};
