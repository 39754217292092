import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./person-query-key-factory";

interface ISetPersonName {
  personId: string;
  name: string;
}

/**
 * Sets the name of a person
 *
 * @param {string} personId - uuid of the person
 * @param {string} name - name of the person
 */
export const setPersonName = ({ personId, name }: ISetPersonName) => {
  return Brm.personApi.setPersonName(personId, { body: name });
};

interface IUseSetPersonName {
  options?: MutationOption<typeof setPersonName>;
}
/**
 * Custom hook to change the name of an existing person
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetPersonName = ({ options }: IUseSetPersonName = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.personKeys.all);
    },
    ...options,
    mutationFn: setPersonName,
  });
};
