import { COMMON } from "constants/brm";
import { useQuery } from "@tanstack/react-query";
// Services
import { sctmApi } from "services/brm/mitigation-service";
import { useRoles } from "features/brm";

export const useSctm = (projectId, variantId) => {
  return useQuery(["getSctm", projectId, variantId], () => sctmApi.findSCTM(projectId, { variant: variantId }), {
    enabled: !!variantId && !!(variantId !== COMMON.nullUuid),
    cacheTime: 0,
  });
};

export const useSctmImportStatus = (sctmId) => {
  return useQuery(["getSctmIsImported", sctmId], () => sctmApi.getSCTMIsImported(sctmId), {
    enabled: !!sctmId,
    cacheTime: 0,
  });
};

export const useSctmName = (sctmId) => {
  const { isRiskAnalyst } = useRoles();
  return useQuery({
    queryKey: ["getSctmName", sctmId],
    queryFn: () => sctmApi.getSCTMName(sctmId),
    enabled: !!sctmId && isRiskAnalyst,
    cacheTime: 0,
  });
};
