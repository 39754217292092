import * as React from "react";
import * as Recoil from "recoil";
import { getErrorMessage } from "utils/error-message-utils";
import * as SystemApi from "../../api/system";
import { useProjectStatus } from "../useProjectStatus";
import { systemModelStructureDirtyState } from "../../stores";

export interface IMoveOptions {
  onSuccess?: () => void;
  onError?: (err: Error) => void;
  markSystemModelDirty?: boolean;
}

export interface IMoveNode {
  nodeId: string;
  parentId: string | null;
  isInternal: boolean;
}

export const useMoveNode = () => {
  const setSystemModelStructureDirty = Recoil.useSetRecoilState(systemModelStructureDirtyState);
  const { mutateAsync: setNodeParent } = SystemApi.useSetNodeParent();
  const { mutateAsync: setNodeIsInternal } = SystemApi.useSetNodeIsInternal();
  const { setIsEditing } = useProjectStatus();

  const moveNode = React.useCallback(
    async (
      { nodeId, parentId, isInternal }: IMoveNode,
      { onSuccess, onError, markSystemModelDirty = false }: IMoveOptions = {}
    ) => {
      setIsEditing(true);
      try {
        await setNodeParent({ nodeId, parentId });
        await setNodeIsInternal({ nodeId, isInternal });
        if (markSystemModelDirty) {
          setSystemModelStructureDirty(true);
        }
        if (onSuccess !== undefined) {
          onSuccess();
        }
      } catch (err) {
        console.error(getErrorMessage(err));
        // setError(err);
        if (onError !== undefined) {
          onError(err as Error);
        }
      }
      setIsEditing(false);
    },
    [setIsEditing, setNodeIsInternal, setNodeParent, setSystemModelStructureDirty]
  );

  return {
    moveNode,
  };
};
