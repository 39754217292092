import { useState, useMemo, useRef } from "react";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { BrmDetailTable } from "brm/tables/BrmTables";

import PropTypes from "prop-types";

import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import { TYPE } from "constants/brm";
import {
  createColumnMappedStringEdit,
  createColumnModifiedAt,
  createColumnCreatedAt,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";
import styled from "styled-components";
import * as S from "brm/styles/details-table.styles";
import { useGetRuleset } from "../../../api";

export const RulesetsTable = ({ kbId, setIsRulesetClicked, setSelectedRuleset }) => {
  const [, setSelectedRows] = useState([]);
  const updatedVersion = useRef("");
  const updatedResource = useRef("");
  const [selectedInstance] = useRecoilState(selectedInstanceState);

  const { data: getRuleSetData, isError: isgetRuleSetDataError } = useGetRuleset(kbId);

  const columns = useMemo(() => {
    const makeNameCell = ({ row }) => {
      /**
       * Activated Attack Ruleset as link since this is the only one supported
       * by deep rules engines and has editing capability
       */
      if (row.original.name === "Attack") {
        return (
          <Button
            id="RulesetTable_attack"
            type="button"
            onClick={() => {
              setSelectedRuleset(row.original);
              setIsRulesetClicked(true);
            }}
          >
            {row.original.name}
          </Button>
        );
      }
      if (row.original.name === "UE") {
        return (
          <Button
            id="RulesetTable_ue"
            type="button"
            onClick={() => {
              setSelectedRuleset(row.original);
              setIsRulesetClicked(true);
            }}
          >
            {row.original.name}
          </Button>
        );
      }

      return <span title={row.original.name}>{row.original.name}</span>;
    };

    return [
      {
        Header: "Name",
        accessor: "name",
        Cell: makeNameCell,
      },
      createColumnMappedStringEdit("version", selectedInstance.id, false, updatedVersion),
      createColumnMappedNoEdit("kb"),
      createColumnMappedStringEdit("resource", selectedInstance.id, false, updatedResource),
      createColumnCreatedAt(),
      createColumnModifiedAt(),
    ];
  }, [selectedInstance.id, setSelectedRuleset, setIsRulesetClicked]);

  if (isgetRuleSetDataError) {
    return <ErrorBanner msg="Error in getting ruleset data" />;
  }

  if (getRuleSetData) {
    return (
      <S.DetailsContainer id="RulesetTable_detailsPanel">
        <S.DetailsTableContainer>
          <BrmDetailTable
            data={getRuleSetData}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "RulesetTable_table" }}
            elementName={TYPE.ruleset}
            showRowSelect={false}
          />
        </S.DetailsTableContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};

const Button = styled.button`
  background: none;
  padding: 0px;
  outline: none;
  border: none;
  color: blue;
  width: 25px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: blue;
    text-decoration: underline;
  }
  .p {
    color: blue;
  }
`;

RulesetsTable.propTypes = {
  kbId: PropTypes.string,
  setIsRulesetClicked: PropTypes.func,
  setSelectedRuleset: PropTypes.func,
};
