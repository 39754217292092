import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./capability-query-key-factory";

/**
 * Returns a capability
 *
 * @param {string} dataFlowId - uuid of capability
 * @returns a capability
 */
export const getCapability = ({ capabilityId }: { capabilityId: string }) => {
  return Brm.capabilityApi.getCapabilityByID(capabilityId);
};

interface IUseCapability {
  capabilityId: string;
  options: QueryOption<typeof getCapability>;
}

/**
 * Return the CapabilityDto .
 *
 * @param {string} capabilityId - uuid of capability
 * @param {object} options - react-query configuration object
 * @returns dataflow
 */
export const useCapability = ({ capabilityId, options }: IUseCapability) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.capabilityKeys.capability(capabilityId),
    queryFn: () => getCapability({ capabilityId }),
  });
};
