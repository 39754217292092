export const exportKeys = {
  all: ["export"] as const,
  project: (projectId: string | null) => [...exportKeys.all, projectId] as const,
  // context: (projectId: string | null, context: any) => [...importKeys.project(projectId), context] as const,
  // submissionStatus: (projectId: string, context: string, runId: string) =>
  //   [...importKeys.context(projectId, context), runId] as const,
  status: (projectId: string, context: string, runId: string) =>
    [...exportKeys.all, "status", context, projectId, runId] as const,
  knowledgebase: (kbId: string, format: string) => [...exportKeys.all, "knowledgebase", kbId, format] as const,
  controlCatalog: (controlCatalogId: string, format: string) =>
    [...exportKeys.all, "controlCatalog", controlCatalogId, format] as const,
  downloadAsset: (projectId: string, context: string, assetId: string) =>
    [...exportKeys.all, "downloadAsset", context, projectId, assetId] as const,
};
