import { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";

import errorUtils from "utils/error-utils";
import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import { boundaryApi } from "services/brm/access-service";
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import useRefreshTableList from "hooks/useRefreshTableList";
import { TYPE } from "constants/brm";

const BoundaryActivitiesTable = ({ setSelectedRows, refresh, setRefresh }) => {
  const [boundaryData, setBoundaryData] = useState([]);
  const projectId = useRecoilValue(projectIdState);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
      },
    ],
    []
  );

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    async function getData() {
      setRefresh(false);

      try {
        const { data, response } = await boundaryApi.findBoundaryActivitiesWithHttpInfo(projectId);

        if (response.status !== 200 && response.status !== 201) {
          errorUtils.checkErrorStatus(response.status, response.statusText);
        }
        setBoundaryData(data);
      } catch (error) {
        console.error("Boundary GET: ", error);
      }
    }
    if (refresh) {
      getData();
    }
  }, [refresh, projectId, setRefresh, setBoundaryData]);

  return (
    <>
      <CustomTable>
        <BrmMainTable
          data={boundaryData}
          columns={columns}
          setSelectedRows={setSelectedRows}
          customProps={{ id: "BoundaryActivitiesTable_table" }}
          elementName={TYPE.activity}
          showRowSelect={false}
        />
      </CustomTable>
    </>
  );
};

BoundaryActivitiesTable.propTypes = {
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func,
  setSelectedRows: PropTypes.func.isRequired,
};

export default BoundaryActivitiesTable;
