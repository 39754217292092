import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";

/**
 * Returns an activity node
 *
 * @param {string} activityId - uuid of activity
 * @returns an node for this activity
 */
export const getActivityNode = ({ activityId }) => {
  return Brm.activityApi.getActivityNode(activityId);
};

/**
 * Return the NodeShortDto .
 *
 * @param {string} activityId - uuid of activity
 * @param {object} config - react-query configuration object
 * @returns node for this activity
 */
export const useActivityNode = ({ activityId, config = {} }) => {
  return ReactQuery.useQuery({
    ...config,
    queryKey: ["activityNode", activityId],
    queryFn: () => getActivityNode({ activityId }),
  });
};
