import * as ReactQuery from "@tanstack/react-query";
// import { useMutation } from "react-query";
import { queryClient } from "libs/react-query";
import toast from "react-hot-toast";
import { IMPORT_STATUS } from "constants/brm";
// import { genericImportApi } from "services/brm/importer-service";
import { ImportApi, ImportContext } from "features/brm";
import * as Brm from "services/brm";

// const upload = async (projectId, selectedFiles, context) => {
//   const data = await genericImportApi.importAssetUpload(context, projectId, { file: selectedFiles });
//   return data;
// };

// const submission = async (projectId, result, context) => {
//   const data = await genericImportApi.importSubmission(context, projectId, {
//     importSubmission: result,
//   });
//   return data;
// };

export const useWizardImportSubmission = () => {
  return ReactQuery.useMutation(
    ({ projectId, result, context }) => ImportApi.importSubmission({ projectId, result, context }),
    {
      onSuccess: (data, variable) => {
        if (variable?.context === ImportContext.tra) {
          toast.success("TRA template(s) successfully submitted");
        }
        if (variable?.context === ImportContext.baseline && data.status === IMPORT_STATUS.pass) {
          // When context = ImportContext.baseline => projectId = baselineId.
          const elementName = variable?.result?.options["baseline-name"]?.elementNameInUrl;
          // queryClient.invalidateQueries(AdminApi.userKeys.all);
          queryClient.invalidateQueries(["getElementControlTypes", variable.projectId, elementName]);
          toast.success("Baseline controls submitted successfully");
        }
      },
    }
  );
};

export const useWizardUpload = (
  setResponseFromUpload,
  setShowUploadProgress,
  setImporterType,
  onProgress = undefined
) => {
  // const foo = React.useCallback((event) => {
  //   console.log("Percentage", event.percent);
  // }, []);

  return ReactQuery.useMutation(
    ({ projectId, selectedFiles, context }) => ImportApi.uploadAsset({ projectId, context, files: selectedFiles }),
    {
      onMutate: () => {
        if (onProgress) {
          Brm.systemImportApi.apiClient.addProgressListener(onProgress);
        }
      },
      onSuccess: (data) => {
        setResponseFromUpload(data);
        setShowUploadProgress(false);
        setImporterType(data.type);
      },
      onSettled: () => {
        if (onProgress) {
          Brm.systemImportApi.apiClient.removeProgressListener(onProgress);
        }
      },
    }
  );
};
