import { useQuery } from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";
import { BrmError, RiskApi, DetailTableType } from "features/brm";

/**
 * Returns a list of undesired events of a given selected element
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @returns list of all undesired events
 */
export const getElementUndesiredEvents = async ({ elementId, elementName, projectId, variantId, ueData }) => {
  const ueMap = {
    [DetailTableType.MISSIONS.key]: (id) => Brm.missionApi.getMissionAllUe(id, { variant: variantId }),
    [DetailTableType.PERSON.key]: (id) => Brm.personApi.getPersonAllUe(id, { variant: variantId }),
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.getCapabilityAllUe(id, { variant: variantId }),
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityAllUe(id, { variant: variantId }),
    [DetailTableType.DATATYPES.key]: (id) => Brm.dataTypeApi.getDataTypeAllUe(id, { variant: variantId }),
    [DetailTableType.DATAFLOWS.key]: (id) => Brm.dataflowApi.getDataFlowAllUe(id, { variant: variantId }),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeAllUe(id, { variant: variantId }),
    [DetailTableType.SYSTEM_ASSETS.key]: (id) =>
      Brm.systemAssetApi.getSystemAssetAllDependentUe(id, { variant: variantId }),
    [DetailTableType.SECURITY_OBJECTIVES.key]: (id) =>
      Brm.securityObjectiveApi.getSecurityObjectiveAllUe(id, { variant: variantId }),
    [DetailTableType.ASSETS.key]: (id) => Brm.assetApi.getAssetAllUe(id, { variant: variantId }),
    [DetailTableType.HARM_CATEGORIES.key]: (id) => Brm.undesiredEventApi.findUndesiredEvent(projectId, { id }),
    [DetailTableType.RISKS.key]: (id) => Brm.riskApi.getRiskUe(id, { variant: variantId }),
    [DetailTableType.THREAT_EVENTS.key]: (id) => Brm.threatEventApi.getThreatEventAllUe(id, { variant: variantId }),
    [DetailTableType.ATTACKS.key]: (id) => Brm.attackApi.getAttackAllUe(id, { variant: variantId }),
    [DetailTableType.ATTACKERS.key]: (id) => Brm.attackerApi.getAttackerAllUe(id, { variant: variantId }),
    [DetailTableType.UNDESIRED_EVENTS.key]: () => ueData,
    [DetailTableType.RESOURCES.key]: (id) => Brm.resourceApi.getResourceAllUe(id, { variant: variantId }),
  };

  if (ueMap[elementName]) {
    const data = await ueMap[elementName](elementId);
    return Array.isArray(data) ? data : [data];
  }

  throw new BrmError(`Unsupported element type ${elementName}`);
};

/**
 * custom-hook to retreive all undesired events
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {string} variantId - uuid of the variant
 * @param {object} config - configuration for the react-query
 * @returns react-query for undesired events
 */
export const useElementUndesiredEvents = ({ elementId, elementName, projectId, variantId, config = {} }) => {
  const undesiredEventQuery = RiskApi.useUndesiredEvent({
    ueId: elementId,
    variantId,
    options: {
      enabled: !!elementId && !!projectId && elementName === DetailTableType.UNDESIRED_EVENTS.key,
    },
  });

  const elementUndesiredEventsQuery = useQuery({
    ...config,
    queryKey: RiskApi.undesiredEventKeys.element(
      elementId,
      elementName,
      projectId,
      variantId,
      undesiredEventQuery.data
    ),
    queryFn: () =>
      getElementUndesiredEvents({ elementId, elementName, projectId, variantId, ueData: undesiredEventQuery.data }),
  });

  if (undesiredEventQuery.isError) {
    return undesiredEventQuery;
  }

  return elementUndesiredEventsQuery;
};
