import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./knowledgebase-query-key-factory";

/**
 * Returns a kb
 *
 * @returns a kb
 */
export const getKb = ({ kbId }: { kbId: string }) => {
  return Brm.kbApi.getKBByID(kbId);
};

interface IUseKb {
  kbId: string;
  options?: QueryOption<typeof getKb>;
}
/**
 * Returns list of Kb's .
 *
 * @param {object} options - react-query configuration object
 * @returns kb list
 */
export const useKb = ({ kbId, options }: IUseKb) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.knowledgebaseKeys.kb(kbId),
    queryFn: () => getKb({ kbId }),
  });
};
