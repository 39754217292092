import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

interface ICreateBaseline {
  controlCatalogId: string;
  standardBaselineCreateDto: any;
}

/**
 * Function to create new Standard Baseline
 *
 * @param {ControlTypeCreateDto} standardBaselineCreateDto creation dto
 * @returns id of created baseline
 */
export const createBaseline = ({ controlCatalogId, standardBaselineCreateDto }: ICreateBaseline) => {
  return Brm.baselineApi.createStandardBaseline(controlCatalogId, { standardBaselineCreateDto });
};

interface IUseCreateBaseline {
  options?: MutationOption<typeof createBaseline>;
}

/**
 * Custom hook to create a new Baseline.
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for useCreateBaseline
 */
export const useCreateBaseline = ({ options }: IUseCreateBaseline = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.standardBaselineKeys.all);
    },
    ...options,
    mutationFn: createBaseline,
  });
};
