import styled from "styled-components/macro";

const tablePaginationSmallStyles = {
  PaginationButton: styled.button`
    font-family: ${({ theme }) => theme.fonts.primary};
    padding: 0px;
    background-color: ${({ theme }) => theme.tableheaderbackgroundcolor};
    color: ${({ theme }) => theme.tableheadercolor};
    border-radius: ${({ theme }) => theme.tables.pagination.small.borderRadius};
    border-color: ${({ theme }) => theme.tableheaderbackgroundcolor};
    margin: ${({ theme }) => theme.tables.pagination.margin};

    :disabled {
      opacity: 0.3;
    }
  `,
  PaginationDiv: styled.span`
    font-size: ${({ theme }) => theme.tables.pagination.small.fontSize};
    max-width: 300px;
    margin: 5px;
    float: right;
  `,
  PaginationText: styled.p`
    font-family: ${({ theme }) => theme.fonts.primary};
    margin: ${({ theme }) => theme.tables.pagination.margin};
    display: inline;
  `,
  PaginationSelect: styled.select`
    font-family: ${({ theme }) => theme.fonts.primary};
    margin: ${({ theme }) => theme.tables.pagination.margin};
    display: inline;
    border-radius: ${({ theme }) => theme.tables.pagination.small.borderRadius};
    height: 22px;
  `,
  PaginationInput: styled.input`
    font-family: ${({ theme }) => theme.fonts.primary};
    margin: ${({ theme }) => theme.tables.pagination.margin};
    display: inline;
    border-radius: ${({ theme }) => theme.tables.pagination.small.borderRadius};
    height: 22px;
    width: 40px;
  `,
};

export default tablePaginationSmallStyles;
