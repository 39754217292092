import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

interface ISetProjectCatalog {
  projectId: string;
  catalogId: string;
}

/**
 * Sets catalog for a project
 *
 * @param {string} projectId - uuid of project
 * @param {string} catalogId - uuid of cntrl catalog
 */
export const setProjectCatalog = ({ projectId, catalogId }: ISetProjectCatalog) => {
  return Brm.projectApi.setProjectCatalog(projectId, { body: catalogId });
};

interface IUseSetProjectCatalog {
  options?: MutationOption<typeof setProjectCatalog>;
}

/**
 * Custom hook to change catalog for a project
 *
 * @param {object} options - configuration of react-query
 * @returns react-query ReactQuery.useMutation
 */
export const useSetProjectCatalog = ({ options }: IUseSetProjectCatalog = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.projectKeys.all);
    },
    ...options,
    mutationFn: setProjectCatalog,
  });
};
