import * as React from "react";
// import { useState, useReducer, useMemo } from "react";
import PropTypes from "prop-types";
// Components
import Select from "react-select";
import toast from "react-hot-toast";
// import SelectBox from "components/forms/DynamicSelectBox";
// import SelectBoxCategory from "components/forms/DynamicSelectBoxCategory";
import TextField from "components/forms/TextField";
// Constants
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR, MEANS, OPPORTUNITIES, COMMON } from "constants/brm";
// styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
// Services
import { AttackerCreateDto } from "@kdmanalytics/brm-system";

import { SystemApi, ThreatApi, useProject } from "features/brm";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";
import { LIKELIHOOD_MAP } from "brm/threat-model/constants/likelihood-mapping";

import * as S from "./AttackAddForm.styles";

const defaultMeans = "M3";
const defaultOpportunity = "O3";

const LIKELIHOOD = [COMMON.veryHigh, COMMON.high, COMMON.moderate, COMMON.low, COMMON.veryLow];

const attackerTypeReducer = (state, action) => {
  switch (action.type) {
    case "updateGroup": {
      return {
        group: false,
        person: true,
        systemAssetType: true,
        isCategoryEnabled: true,
      };
    }
    case "updatePerson": {
      return {
        group: true,
        person: false,
        systemAssetType: true,
        isCategoryEnabled: true,
      };
    }
    case "updateSAType": {
      return {
        group: true,
        person: true,
        systemAssetType: false,
        isCategoryEnabled: true,
      };
    }
    case "reset": {
      return {
        group: false,
        person: false,
        systemAssetType: false,
        isCategoryEnabled: true,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const initState = {
  group: false,
  person: false,
  systemAssetType: false,
  isCategoryEnabled: true,
};

function nameCompare(a, b) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
}

const AddAttackerForm = ({ setModalIsOpen }) => {
  const [, { projectId }] = useProject();
  const [postError, setPostError] = React.useState("");
  // const [selectedMeans, setSelectedMeans] = React.useState({});
  // const [selectedOpportunity, setSelectedOpportunity] = React.useState({});
  // const [likelihoodLevels, setLikelihoodLevels] = useState([]);
  const [isTextValid, setIsTextValid] = React.useState(true);
  const [values, dispatch] = React.useReducer(attackerTypeReducer, initState);

  const [catalog, setCatalog] = React.useState();
  const [threatGroup, setThreatGroup] = React.useState();
  const [person, setPerson] = React.useState();
  const [systemAssetType, setSystemAssetType] = React.useState();
  // const [name, setName] = React.useState();
  // const [note, setNote] = React.useState();
  const [means, setMeans] = React.useState(defaultMeans);
  const [opportunity, setOpportunity] = React.useState(defaultOpportunity);
  const [category, setCategory] = React.useState();

  // const { data: nodes, isError: isNodesError } = SystemApi.useNodes({ projectId, config: { enabled: !!projectId } });
  // const { data: exchanges, isError: isExchangesError } = SystemApi.useExchanges({
  //   projectId,
  //   options: { enabled: !!projectId },
  // });
  const { data: attackerCategoryOptions, isError: isAttackerCategoriesError } = ThreatApi.useAttackerCategories({
    projectId,
    options: { enabled: !!projectId, select: (data) => [...data].sort().map((ac) => ({ label: ac, value: ac })) },
  });

  // console.log(attackerCategories);

  const { data: persons, isError: isPersonsError } = SystemApi.usePersons({
    projectId,
    options: {
      enabled: !!projectId,
      select: (data) => data.sort(nameCompare).map((p) => ({ label: p.name, value: p.id })),
    },
  });

  const { data: threatGroups, isError: isThreatGroupError } = ThreatApi.useThreatGroups();
  // console.log("threatGroups", threatGroups);

  const { data: systemAssetTypes, isError: isSystemAssetTypesError } = SystemApi.useSystemAssetsTypes({
    projectId,
    options: {
      enabled: !!projectId,
    },
  });

  const { data: systemAssetTypesOptions } = SystemApi.useSystemAssetsTypes({
    projectId,
    options: {
      enabled: !!projectId,
      select: (data) => [...data].sort(nameCompare).map((sa) => ({ label: sa.name, value: sa.id })),
    },
  });

  const { mutateAsync: addAttacker } = ThreatApi.useCreateAttacker();

  const likelihoodLevels = LIKELIHOOD.map((e) => ({ label: e, value: e }));

  // useEffect(() => {
  //   if (nodes && exchanges) {
  //     setLikelihoodLevels(LIKELIHOOD.map((e) => ({ id: e, name: e })));
  //   }
  // }, [nodes, exchanges]);

  const handleMeansSelectChange = (selectedItems) => {
    // setSelectedMeans(selectedItems);
    setMeans(selectedItems?.value);
  };

  const handleOpportunitySelectChange = (selectedItems) => {
    // setSelectedOpportunity(selectedItems);
    setOpportunity(selectedItems?.value);
  };

  const handleCategoryChange = (selectedCategory) => {
    setCategory(selectedCategory?.value);
  };

  const handleGroupChange = (e) => {
    if (e == null) {
      dispatch({ type: "reset" });
      setCategory("");
    } else {
      dispatch({ type: "updateGroup" });
      setCategory("External");
    }
    setThreatGroup(e?.value);

    // console.log(e);

    // const value = e?.target?.value || "";
    // if (value.toLowerCase() === "select") {
    //   dispatch({ type: "reset" });
    // } else {
    // }
  };

  const handleCatalogChange = (selectedCatalog) => {
    if (selectedCatalog?.value === null) {
      handleGroupChange(null);
    }
    setCatalog(selectedCatalog?.value);
  };

  const handlePersonChange = (e) => {
    if (e == null) {
      dispatch({ type: "reset" });
      setPerson("");
    } else {
      dispatch({ type: "updatePerson" });
      setCategory("");
      setPerson(e.value);
    }

    // const value = e?.target?.value || "";
    // if (value.toLowerCase() === "select") {
    //   dispatch({ type: "reset" });
    // } else {
    //   dispatch({ type: "updatePerson" });
    // }
  };

  const handleSystemAssetTypeChange = (e) => {
    if (e == null) {
      dispatch({ type: "reset" });
    } else {
      dispatch({ type: "updateSAType" });
      setSystemAssetType(e.value);
      const sat = systemAssetTypes.find((sa) => sa.id === e.value);
      setCategory(sat.category);
    }

    // const value = e?.target?.value || "";
    // if (value.toLowerCase() === "select") {
    //   dispatch({ type: "reset" });
    // } else {
    //   dispatch({ type: "updateSAType" });
    // }
  };

  // validate that all fields in the form have been completed
  function validate(formData) {
    let error = true;
    // To Do: Check the strings returned and see if they can be replaced by FORM_LABEL.placeholder
    if (formData.name.value === "" || category === "" || category === undefined) {
      error = false;
    }

    if (means === "" || opportunity === "") {
      error = false;
    }

    // if (selectedMeans?.value === "" || !selectedOpportunity?.value === "") {
    //   error = false;
    // }

    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isValid = validate(e.target.elements);

    const params = {
      name: e.target.elements.name.value,
      note: e.target.elements.note.value || "",
      category,
      availableMeans: means,
      availableOpportunity: opportunity,
      likelihood: LIKELIHOOD_MAP.get(opportunity).get(means),
      group: threatGroup === "" ? null : threatGroup,
      person: person === "" ? null : person,
      satype: systemAssetType === "" ? null : systemAssetType,
    };

    // // const group = e.target.elements.group.value;
    // // const person = e.target.elements.person.value;
    // // const systemAssetType = e.target.elements.systemassettype.value;
    // // const likeliHood = e.target.elements.likelihood.value;

    if (isValid && isTextValid) {
      //   const params = {
      //     // MANDATORY
      //     name: e.target.elements.name.value,
      //     note: e.target.elements.note.value || "",
      //     category: e.target.elements.category.value,
      //     availableMeans: selectedMeans.value,
      //     availableOpportunity: selectedOpportunity.value,
      //     likelihood: group.toLowerCase() === "select" ? "" : likeliHood,
      //     group: group.toLowerCase() === "select" ? null : group,
      //     person: person.toLowerCase() === "select" ? null : person,
      //     satype: systemAssetType.toLowerCase() === "select" ? null : systemAssetType,
      //   };

      // if (isValid && isTextValid) {
      const attackerCreateDto = AttackerCreateDto.constructFromObject(params);

      try {
        await addAttacker({ projectId, attackerCreateDto });
        setModalIsOpen(false);
      } catch {
        toast.error(<p>Error adding attacker</p>, {});
      }
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  const threatCatalogSet = React.useMemo(() => {
    if (threatGroups) {
      return new Set(threatGroups.map((tg) => tg.catalog.name));
    }
    return new Set();
  }, [threatGroups]);

  const threatGroupOptions = React.useMemo(() => {
    if (threatGroups) {
      if (catalog) {
        return threatGroups
          .filter((tg) => tg.catalog.name === catalog)
          .sort(nameCompare)
          .map((v) => ({ label: v.name, value: v.id }));
      }
    }
    //   // get list of all unique threat catalogs from the threatgroups
    //   const catalogMap = new Map();
    //   threatCatalogSet.forEach((tc) => catalogMap.set(tc, []));
    //   threatGroups.forEach((tg) => catalogMap.get(tg.catalog.name).push(tg));

    //   const catalogOptions = Array.from(catalogMap).map(([key, value]) => ({
    //     label: key,
    //     options: value.sort(nameCompare).map((v) => ({ label: v.name, value: v.id })),
    //   }));
    //   return catalogOptions;
    // }
    return [];
  }, [catalog, threatGroups]);

  const categoryOptions = React.useMemo(() => {
    if (!values.group && !values.person && !values.systemAssetType) {
      return attackerCategoryOptions.filter((aco) => {
        return !(
          aco.label === "HardwareSupplier" ||
          aco.label === "CustomSWSupplier" ||
          aco.label === "NetworkSupplier" ||
          aco.label === "MediaSupplier" ||
          aco.label === "OperatingSystemSupplier" ||
          aco.label === "Supplier"
        );
      });
    }

    if (!values.group) {
      return [{ label: "External", value: "External" }];
    }
    if (!values.person) {
      return [
        { label: "Careless", value: "Careless" },
        { label: "Clueless", value: "Clueless" },
        { label: "Malicious", value: "Malicious" },
      ];
    }
    if (!values.systemAssetType) {
      const assetTypeName = systemAssetTypesOptions.find((o) => o.value === systemAssetType).label;
      if (assetTypeName === "Hardware") {
        return [attackerCategoryOptions.find((ac) => ac.label === "HardwareSupplier")];
      }
      if (assetTypeName === "Custom Software") {
        return [attackerCategoryOptions.find((ac) => ac.label === "CustomSWSupplier")];
      }
      if (assetTypeName === "Network") {
        return [attackerCategoryOptions.find((ac) => ac.label === "NetworkSupplier")];
      }
      if (assetTypeName === "Media") {
        return [attackerCategoryOptions.find((ac) => ac.label === "MediaSupplier")];
      }
      if (assetTypeName === "Operating System") {
        return [attackerCategoryOptions.find((ac) => ac.label === "OperatingSystemSupplier")];
      }
      return [attackerCategoryOptions.find((ac) => ac.label === "Supplier")];

      // const sat = attackerCategoryOptions.find((sa) => sa.id === systemAssetType);

      // return [{ label: sat.category, value: sat.category }];
      // return attackerCategoryOptions;
    }
    // return attackerCategoryOptions.filter((aco) => {
    //   return !(
    //     aco.label === "HardwareSupplier" ||
    //     aco.label === "CustomSWSupplier" ||
    //     aco.label === "NetworkSupplier" ||
    //     aco.label === "MediaSupplier" ||
    //     aco.label === "OperatingSystemSupplier" ||
    //     aco.label === "Supplier"
    //   );
    // });

    return [];
  }, [
    attackerCategoryOptions,
    systemAssetType,
    systemAssetTypesOptions,
    values.group,
    values.person,
    values.systemAssetType,
  ]);

  React.useEffect(() => {
    if (Array.isArray(categoryOptions) && categoryOptions.length === 1) {
      setCategory(categoryOptions[0].value);
    }
  }, [categoryOptions]);

  if (
    isAttackerCategoriesError ||
    // isExchangesError ||
    // isNodesError ||
    isPersonsError ||
    isSystemAssetTypesError ||
    isThreatGroupError
  ) {
    return <ErrorBanner />;
  }

  if (
    attackerCategoryOptions &&
    threatGroups &&
    threatCatalogSet &&
    persons &&
    systemAssetTypesOptions &&
    likelihoodLevels &&
    threatGroupOptions
  ) {
    return (
      <div>
        <form onSubmit={handleSubmit} action="" style={{ paddingTop: "10px" }}>
          <FormStyled>
            <div className="form-style">
              <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                {/* OPTIONAL */}
                {/* To Do: The following fields should be turned into optional. Optional-labels already exist. */}
                <S.FormGroup>
                  <label>{FORM_LABEL.threatCatalog}</label>
                  {/* <SelectBox arrayOfData={threatGroups} item="group" onChange={handleGroupChange} disabled={values.group} /> */}
                  <Select
                    options={Array.from(threatCatalogSet).map((c) => ({ label: c, value: c }))}
                    onChange={handleCatalogChange}
                    isDisabled={values.group}
                    isClearable
                  />
                </S.FormGroup>
                <S.FormGroup>
                  <label>{FORM_LABEL.threatGroupOptional}</label>
                  {/* <SelectBox arrayOfData={threatGroups} item="group" onChange={handleGroupChange} disabled={values.group} /> */}
                  <Select
                    options={threatGroupOptions}
                    onChange={handleGroupChange}
                    value={threatGroupOptions.find((tg) => tg.value === threatGroup) || null}
                    isDisabled={values.group || catalog === undefined}
                    isClearable
                  />
                </S.FormGroup>
                <S.FormGroup>
                  <label>{FORM_LABEL.personOptional}</label>
                  {/* <SelectBox
                arrayOfData={persons}
                item="person"
                isOptional
                onChange={handlePersonChange}
                disabled={values.person}
              /> */}
                  <Select options={persons} onChange={handlePersonChange} isDisabled={values.person} isClearable />
                </S.FormGroup>
                <S.FormGroup>
                  <label>{FORM_LABEL.systemAssetTypeOptional}</label>
                  {/* <SelectBox
                arrayOfData={systemAssetTypes}
                item="systemassettype"
                onChange={handleSystemAssetTypeChange}
                disabled={values.systemAssetType}
              /> */}
                  <Select
                    options={systemAssetTypesOptions}
                    onChange={handleSystemAssetTypeChange}
                    isDisabled={values.systemAssetType}
                    isClearable
                  />
                </S.FormGroup>
                <hr />

                {/* MANDATORY */}
                <TextField
                  label={{ id: "AttackerAddForm_name", name: FORM_LABEL.nameMandatory }}
                  input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
                  setIsTextValid={setIsTextValid}
                />
                <TextField
                  label={{ id: "AttackerAddForm_note", name: FORM_LABEL.note }}
                  input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
                  setIsTextValid={setIsTextValid}
                />
                <S.FormGroup>
                  <label>{FORM_LABEL.categoryMandatory}</label>
                  {/* // <SelectBoxCategory arrayOfData={attackerCategories} item="category" /> */}
                  <Select
                    options={categoryOptions}
                    value={category ? categoryOptions.find((ac) => ac.value === category) : ""}
                    onChange={handleCategoryChange}
                    isDisabled={!values.isCategoryEnabled}
                  />
                </S.FormGroup>
                <S.FormGroup>
                  <label>{FORM_LABEL.availableMeansOptional}</label>
                  <Select
                    options={MEANS}
                    // value={means !== "" ? MEANS.find((o) => o.value === means) : MEANS.find((o) => o.value === "M3")}
                    defaultValue={
                      means !== "" ? MEANS.find((o) => o.value === means) : MEANS.find((o) => o.value === defaultMeans)
                    }
                    onChange={handleMeansSelectChange}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                  />
                </S.FormGroup>
                <S.FormGroup>
                  <label>{FORM_LABEL.availableOpportunityOptional}</label>
                  <Select
                    options={OPPORTUNITIES}
                    defaultValue={
                      opportunity !== ""
                        ? OPPORTUNITIES.find((o) => o.value === opportunity)
                        : OPPORTUNITIES.find((o) => o.value === defaultOpportunity)
                    }
                    onChange={handleOpportunitySelectChange}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                  />
                </S.FormGroup>
                <S.FormGroup>
                  <label>{FORM_LABEL.likelihoodOptional}</label>
                  <Select
                    options={likelihoodLevels}
                    value={likelihoodLevels.find((l) => l.value === LIKELIHOOD_MAP.get(opportunity).get(means))}
                    item="likelihood"
                    isDisabled
                    // menuPlacement="top"
                    // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    // menuPortalTarget={document.body}
                  />
                </S.FormGroup>
              </div>
            </div>
          </FormStyled>
          <FormStyledError>{postError}</FormStyledError>
          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
            <DialogButtonStyled type="submit">Add</DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }

  return <Loading />;
};

AddAttackerForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};

export default AddAttackerForm;
