import styled from "styled-components";

export const SelectDivRole = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  width: 40%;
  display: inline-block;
`;

export const SelectDivContext = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  width: 60%;
  display: inline-block;
`;

export const SelectLabel = styled.label`
  margin-top: 10px;
  width: 100%;
  display: inline-block;
  font-size: 12px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;

export const RowFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;
