import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./organization-query-key-factory";

/**
 * Gets the organization user
 */
export const getOrganizationUser = ({ organizationId }: { organizationId: string | null }) => {
  return organizationId === null
    ? Promise.reject(new Error("Invalid organizationId id"))
    : Brm.organizationApi.getOrganizationUser(organizationId);
};

interface IOrganizationsProps {
  organizationId: string | null;
  options?: QueryOption<typeof getOrganizationUser>;
}

/**
 * Custom hook for getting organization user
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useOrganizationUser = ({ organizationId, options = {} }: IOrganizationsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.organizationKeys.user(organizationId),
    queryFn: () => getOrganizationUser({ organizationId }),
  });
};
