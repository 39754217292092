import * as React from "react";
// import * as Recoil from "recoil";
// import { autoMitigateState } from "atoms/atoms-risk";

import { useProject, useVariant, RiskApi, useRoles } from "features/brm";
// import { riskUpdatedState } from "atoms/atoms-risk";
import {
  createColumnMapped,
  createColumnMappedNoEdit,
  createColumnImpactMapped,
  createColumnLikelihoodMapped,
} from "brm/tables/services/column/columnFactory";
// import * as RiskApi from "brm/risk-model/risk/api";
import { usePrevious } from "hooks";
import { RoutePath } from "routes/route-paths";
import riskLevelMapper from "../utils/RiskLevelMapper";

interface INamedElement {
  id: string;
  name: string;
}

export const useRiskFullDetails = ({ riskId, navigate }: { riskId: string; navigate: (v: string) => void }) => {
  const { isRiskAnalyst } = useRoles();
  const [currentProject, { isActive }] = useProject();
  const { currentVariant, variantId } = useVariant();
  // const autoMigitatedState = Recoil.useRecoilValue(autoMitigateState);

  const prevProject = usePrevious(currentProject);

  const [selectedElement, setSelectedElement] = React.useState<Partial<INamedElement>>({});
  const [riskData, setRiskData] = React.useState<any[]>([]);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [showSummaryTable, setShowSummaryTable] = React.useState(true);

  const { data: riskById, isError } = RiskApi.useRiskDetails({
    riskId,
    variantId,
    options: {
      enabled: !!riskId && currentVariant !== null && isActive && isRiskAnalyst,
      keepPreviousData: true,
      // &&        (autoMigitatedState === "Completed" || autoMigitatedState === "None"),
    },
  });

  const columns = React.useMemo(() => {
    const makePercentCell = (cellProps: any) => {
      if (cellProps.cell.row.original.percent.value) {
        // Percent is coming back as a value between 0-1 --> therefore multiply by 100
        const percent = cellProps.cell.row.original.percent.value * 100;
        if (percent > 0.2) {
          return percent.toFixed(1);
        }
        return <>0.001</>;
      }
      return <>-</>;
    };

    const makeScoreCell = (cellProps: any) => {
      if (cellProps.cell.row.original.score.value) {
        return cellProps.cell.row.original.score.value.toFixed(1);
      }
      return <>-</>;
    };

    const makeMitScoreCell = (cellProps: any) => {
      if (cellProps.cell.row.original.mitigatedScore.value) {
        return cellProps.cell.row.original.mitigatedScore.value.toFixed(1);
      }
      if (cellProps.cell.row.original.mitigatedScore.value === 0) {
        return "0";
      }
      return <>-</>;
    };

    return [
      createColumnMappedNoEdit("rank"),
      createColumnMapped("category", selectedRowId, null, false, null),
      createColumnMappedNoEdit("objective"),
      {
        Header: "Percent",
        accessor: "percent.value",
        Cell: makePercentCell,
      },
      {
        Header: "Score",
        accessor: "score.value",
        Cell: makeScoreCell,
      },
      {
        Header: "Mitigated Score",
        accessor: "mitigatedScore.value",
        Cell: makeMitScoreCell,
      },
      createColumnLikelihoodMapped("likelihood"),
      createColumnLikelihoodMapped("mitigatedlikelihood"),
      createColumnImpactMapped("impact"),
      createColumnImpactMapped("mitigatedimpact"),
      {
        Header: "Risk Level",
        accessor: "riskLevel",
        Cell: (cell: any) => riskLevelMapper(cell.value),
      },
      {
        Header: "Mitigated Risk Level",
        accessor: "mitigatedRiskLevel",
        Cell: (cell: any) => riskLevelMapper(cell.value),
      },
    ];
  }, [selectedRowId]);

  /**
   * Switch projects, navigate to list of risks
   */
  React.useEffect(() => {
    if (currentProject && prevProject && currentProject.id !== prevProject.id) {
      navigate(RoutePath.Risks);
    }
  }, [currentProject, navigate, prevProject]);

  React.useEffect(() => {
    if (riskById) {
      setSelectedElement(riskById);
      setRiskData([riskById]);
    }
  }, [riskById]);

  return {
    columns,
    riskData,
    isRiskError: isError,
    showSummaryTable,
    setShowSummaryTable,
    selectedElement,
    setSelectedElement,
    setSelectedRowId,
  };
};
