import { useQuery } from "@tanstack/react-query";
import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";
import * as KeyFactory from "./element-query-key-factory";
import { BrmError } from "../../../errors/BrmError";
import { DetailTableType } from "../../../types/DetailTableType";

/**
 * custom-hook to get all activities
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {object} config - configuration for the react-query
 */

interface IElementActivitiesProps {
  elementId: string;
  elementName: string;
  projectId: string;
  config: {};
}

interface IActivitiesMap {
  [index: string]: (id?: string) => any;
}

export const getElementActivities = (
  elementId: string,
  elementType: string,
  dataTypeActivitiesQueryData: BrmGql.GetDataTypeActivitiesQuery | undefined,
  missionActivitiesQueryData: BrmGql.GetMissionActivitiesQuery | undefined
) => {
  const activitiesMap: IActivitiesMap = {
    [DetailTableType.DATATYPES.key]: () => dataTypeActivitiesQueryData?.dataType?.activities,
    [DetailTableType.MISSIONS.key]: () => missionActivitiesQueryData?.mission?.activities,
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.getCapabilityActivity(id),
    [DetailTableType.RESOURCES.key]: (id) => Brm.resourceApi.getResourceActivity(id),
    [DetailTableType.PERSONS.key]: (id) => Brm.personApi.getPersonActivity(id),
    [DetailTableType.DATAFLOWS.key]: (id) => Brm.dataflowApi.getDataFlowActivity(id),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeActivity(id),
    [DetailTableType.ATTACK_SURFACES.key]: (id) => Brm.attackSurfaceApi.getAttackSurfaceAllActivity(id),
    [DetailTableType.ATTACK_SURFACE_CATEGORIES.key]: (id) =>
      Brm.attackSurfaceCatApi.getAttackSurfaceCategoryAllActivity(id),
    [DetailTableType.ENTRY_POINTS.key]: (id) => Brm.entryPointApi.getEntryPointActivity(id),
    [DetailTableType.ENTRY_POINT_CATEGORIES.key]: (id) => Brm.entryPointCatApi.getEntryPointCategoryAllActivity(id),
    [DetailTableType.ACCESS_POINTS.key]: (id) => Brm.accessPointApi.getAccessPointActivity(id),
    [DetailTableType.ACCESS_POINT_CATEGORIES.key]: (id) => Brm.accessPointCatApi.getAccessPointCategoryAllActivity(id),
    [DetailTableType.ATTACK_VECTORS.key]: (id) => Brm.attackVectorApi.getAttackVectorActivity(id),
    [DetailTableType.ATTACK_VECTOR_CATEGORIES.key]: (id) =>
      Brm.attackVectorCatApi.getAttackVectorCategoryAllActivity(id),
  };

  if (activitiesMap[elementType]) {
    return activitiesMap[elementType](elementId) || [];
  }

  throw new BrmError(`Unsupported element type: ${elementType}`);
};

export const useElementActivities = ({ elementId, elementName, projectId, config = {} }: IElementActivitiesProps) => {
  const dataTypeActivitiesQuery = BrmGql.useGetDataTypeActivitiesQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.DATATYPES.key }
  );

  const missionActivitiesQuery = BrmGql.useGetMissionActivitiesQuery(
    { project: projectId, id: elementId },
    { enabled: !!elementId && !!projectId && elementName === DetailTableType.MISSIONS.key }
  );

  const elementActivitiesQuery = useQuery({
    ...config,
    queryKey: KeyFactory.activityKeys.element(
      elementId,
      elementName,
      dataTypeActivitiesQuery.data,
      missionActivitiesQuery.data
    ),
    queryFn: () =>
      getElementActivities(elementId, elementName, dataTypeActivitiesQuery.data, missionActivitiesQuery.data),
  });

  if (dataTypeActivitiesQuery.isError) {
    return dataTypeActivitiesQuery;
  }

  if (missionActivitiesQuery.isError) {
    return missionActivitiesQuery;
  }

  return elementActivitiesQuery;
};
