import { COMMON } from "constants/brm";
import styled from "styled-components/macro";
import ReactSelect from "react-select";
import { Popover } from "react-bootstrap";

interface ILevel {
  level: string;
}
interface ILoading {
  isLoading: boolean;
}
const RarReportStyles = {
  Layout: styled.div`
    font-family: ${({ theme }) => theme.fonts.primary};
    display: grid;
    grid-template-columns: 1fr 1fr 10% 1.5fr 0.8fr;
    border: 1px solid black;
    grid-gap: 1px;
  `,
  DiagramView: styled.div`
    text-align: center;
    width: 100%;
    height: 100%;
  `,

  RarPanel: styled.div`
    min-height: 0;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 5px;
    flex: 1;
  `,
  ReportViewWrapper: styled.div`
    position: relative;
  `,
  UpdatingPopup: styled.div<ILoading>`
    opacity: ${({ isLoading }) => (isLoading ? "100" : "0")};
    position: absolute;
    margin: auto;
    left: 50%;
    top: 50%;
    padding: 1.5em;

    display: inline-block;
    z-index: 2;
    transition: opacity 500ms ease-in-out;
    transform: translateX(-50%) translateY(50%);
    pointer-events: none;
    background: white;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  `,
  UpdatingPopupContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
  `,

  ReportView: styled.div<ILoading>`
    flex: 1;
    /* height: 60vh; */
    overflow-y: auto;
    width: 100%;
    min-width: 50%;
    opacity: ${({ isLoading }) => (isLoading ? "30%" : "100%")};
    transition: opacity 250ms ease-in-out;
  `,

  RiskLevel: styled.div<ILevel>`
    grid-column: 5/ 6;
    grid-row: 1 / 2;
    border: 1px solid black;
    padding: 5px;
    background-color: ${(props) => {
      if (props.level === COMMON.low) {
        return `#7ED143`;
      }
      if (props.level === COMMON.moderate) {
        return `#FDFD96`;
      }
      if (props.level === COMMON.high) {
        return `#F8BA27`;
      }
      if (props.level === COMMON.veryHigh) {
        return `#FF473D`;
      }
      if (props.level === COMMON.veryLow) {
        return `#1EB147`;
      }
      return `white`;
    }};
  `,

  ReportElement: styled.div``,
  ReportElementTitle: styled.strong``,
  ReportElementContent: styled.p``,
  ReportNodeSelection: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  `,
  SystemComponent: styled.div`
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    border: 1px solid black;
    padding: 5px;
  `,

  ControlRequirement: styled.div`
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    border: 1px solid black;
    padding: 5px;
  `,
  RiskNumber: styled.div`
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    border: 1px solid black;
    padding: 5px;
  `,
  RiskName: styled.div`
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    border: 1px solid black;
    padding: 5px;
  `,
  RiskContributors: styled.div`
    grid-column: 1 / 6;
    grid-row: 2 / 3;
    border: 1px solid black;
    padding: 5px;
  `,
  Threats: styled.div`
    grid-column: 1 / 6;
    grid-row: 3 / 4;
    border: 1px solid black;
    padding: 5px;
  `,
  Vulnerabilities: styled.div`
    grid-column: 1 / 6;
    grid-row: 4 / 5;
    border: 1px solid black;
    padding: 5px;
  `,
  Impact: styled.div`
    grid-column: 1 / 6;
    grid-row: 5 / 6;
    border: 1px solid black;
    padding: 5px;
  `,

  ImpactDisplay: styled.div`
    display: flex;
    flex-direction: row;

    .col1 {
      width: 100px;
    }
  `,
  ImpactTitle: styled.div`
    width: 100px;
  `,
  Mitigation: styled.div`
    grid-column: 1 / 6;
    grid-row: 6 / 7;
    border: 1px solid black;
    padding: 5px;
  `,
  ResidualRisk: styled.div`
    grid-column: 1 / 5;
    grid-row: 7 / 8;
    border: 1px solid black;
    padding: 5px;
  `,
  CurrentResidualRisk: styled.div<ILevel>`
    grid-column: 5 / 6;
    grid-row: 7 / 8;
    border: 1px solid black;
    padding: 5px;
    background-color: ${(props) => {
      if (props.level === COMMON.low) {
        return `#7ED143`;
      }
      if (props.level === COMMON.moderate) {
        return `#FDFD96`;
      }
      if (props.level === COMMON.high) {
        return `#F8BA27`;
      }
      if (props.level === COMMON.veryHigh) {
        return `#FF473D`;
      }
      if (props.level === COMMON.veryLow) {
        return `#1EB147`;
      }
      return `white`;
    }};
  `,
  CounterMeasures: styled.div`
    grid-column: 1 / 6;
    grid-row: 8 / 9;
    border: 1px solid black;
    padding: 5px;
  `,
  Contributors: styled.div`
    grid-column: 1 / 6;
    grid-column-end: 6;
    grid-row: 9 / 10;
    border: 1px solid black;
    padding: 5px;
  `,

  ReportButtons: styled.button`
    background-color: ${({ theme }) => theme.primarybuttonbackgroundcolor};
    border-radius: 3px;
    color: white;
    font-family: ${({ theme }) => theme.fonts.primary};
    border: 1px black;
    padding: 5px;
    margin: 0px 8px;
    opacity: ${(prop) => (prop.disabled ? 0.3 : 1)};
    &:hover {
      opacity: ${(prop) => (prop.disabled ? 0.3 : 0.8)};
      cursor: ${(prop) => (prop.disabled ? "auto" : "pointer")};
    }
  `,
  List: styled.ul`
    margin-left: 5px;
  `,

  ModalBody: styled.div`
    height: auto;
    max-height: 75vh;
    overflow: auto;
  `,
  Dropdown: styled.div`
    margin-left: 10px;
  `,
  NodeSelect: styled(ReactSelect)`
    & .react-select__control {
      min-height: 30px;
      max-height: 30px;
      width: auto;
      min-width: 250px;
      max-width: 350px;
    }
    & .react-select__single-value {
      margin: 0;
      width: auto;
      height: auto;
      color: black;
      padding-bottom: 9px;
    }
    & .react-select__indicator {
      color: black;
    }
    & .react-select__dropdown-indicator {
      padding: 0px 2px 5px 2px;
    }
    & .react-select__indicator-separator {
      margin-bottom: 10px;
      margin-top: 4px;
    }
    & .react-select__value-container {
      padding-top: 4px;
    }

    /*    & .react-select__menu {
      width: 250px;
      z-index: 99;
    }

    & .react-select__option {
      padding: 5;
      width: 100%;
      height: auto;
      font-size: 12px;
      font-family: ${({ theme }) => theme.fonts.primary};
      color: black;
    }
    & .react-select__single-value {
      margin: 0;
      width: auto;
      height: auto;
      font-size: 12px;
      color: black;
      padding-bottom: 10px;
    }
    & .react-select__value-container {
      padding-top: 0px;
      width: 250px;
    }

    & .react-select__control--is-focused {
      min-height: 27px;
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
    & .react-select__indicator {
      color: black;
    }
    & .react-select__indicator-separator {
      margin-bottom: 10px;
      margin-top: 4px;
    } */
  `,

  MessageDisplay: styled.h4`
    font-family: ${({ theme }) => theme.fonts.primary};
    padding-top: 14rem;
  `,
  CenterContainer: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  TableButtonsDiv: styled.div`
    float: right;
    padding-top: 0px;
    margin-left: 10px;
  `,
  ReportContainer: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  `,
  ReportToolbar: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  VulnerabilityContainer: styled.div`
    padding-left: 0px;
    display: grid;
    grid-template-columns: 100px auto;
  `,
  MetaContainer: styled.div`
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
  `,
  OrderedList: styled.ul`
    padding-left: 0px;
    list-style-type: none;
  `,
  ListItem: styled.li`
    padding-left: 5px;
  `,
  IconSpacer: styled.div`
    display: flex;
    justify-content: space-evenly;
    min-width: 20px;
  `,
  RankSpacer: styled.div`
    display: flex;
    justify-content: right;
    min-width: 30px;
    padding-right: 5px;
  `,
  Level: styled.div`
    display: inline-block;
    min-width: 25px;
    font-weight: 600;
  `,
  Definition: styled.div`
    display: inline-block;
    min-width: 95px;
    font-weight: 600;
  `,
  CondDefinition: styled.div`
    display: inline-block;
    min-width: 95px;
    font-weight: 600;
  `,
  Dash: styled.div`
    padding: 0 5px;
  `,
  ShadowPopover: styled(Popover)`
    box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
    border: none;
    font-size: 1rem;
    max-width: 550px;
  `,
};

export default RarReportStyles;
