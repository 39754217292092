import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

interface ICloneControlCatalog {
  ccId: any;
}
/**
 * Create a colned version of selected ControlCatalog *
 * @param {string} ccId - uuid of the selected control catalog
 */

export const cloneControlCatalog = ({ ccId }: ICloneControlCatalog) => {
  return Brm.controlCatalogApi.cloneControlCatalog(ccId);
};

interface IUseCloneControlCatalog {
  options?: MutationOption<typeof cloneControlCatalog>;
}
/**
 * custom hook for Cloning a control catalog .
 * @param {object} options - react-query mutation configuration
 * @returns react-query ReactQuery.useMutation
 */

export const useCloneControlCatalog = ({ options }: IUseCloneControlCatalog = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.controlCatalogKeys.all);
    },
    ...options,
    mutationFn: cloneControlCatalog,
  });
};
