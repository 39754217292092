import { Link } from "react-router-dom";
import styled from "styled-components";

export const CleanLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
    color: purple;
  }
`;
