import styled from "styled-components";

export const FilterSection = styled.div`
  width: 100%;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.colors.lowContrastBackground};
  position: relative;
  height: auto;
`;

export const ShowFilterButton = styled.button`
  margin: 5px;
  background: none;
  color: inherit;
  border: none;
  padding-top: 10px;
  font-family: ${({ theme }) => theme.fonts.primary};
  cursor: pointer;
  :focus {
    outline: none;
  }
  :hover {
    opacity: 0.5;
  }
`;

export const SelectDiv = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  width: 23%;
  display: inline-block;
`;

export const SelectLabel = styled.label`
  margin-top: 10px;
  width: 100%;
  display: inline-block;
  font-size: 12px;
`;

export const HideLabel = styled.label`
  padding-left: 10px;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const BodyDiv = styled.div`
  height: 120px;
`;

export const FunctionCell = styled.p`
  font-weight: 500;
  color: ${(props) => {
    if (props.name === "Identify (ID)") {
      return `blue`;
    }
    if (props.name === "Protect (PR)") {
      return `purple`;
    }
    if (props.name === "Detect (DE)") {
      return `orange`;
    }
    if (props.name === "Respond (RS)") {
      return `red`;
    }
    if (props.name === "Recover (RC)") {
      return `green`;
    }
    return `black`;
  }};
`;

export const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  color: blue;
  width: 25px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: blue;
    text-decoration: underline;
  }
  .p {
    color: blue;
  }
`;
