import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import errorUtils from "utils/error-utils";
import Title from "components/elements/StyledTitle";
import { threatCatalogApi } from "services/brm/global-threat-service";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
import {
  createColumnBooleanNoEditMapped,
  createColumnModifiedAt,
  createColumnImportedAt,
} from "brm/tables/services/column/columnFactory";
import { TYPE } from "constants/brm";
import ThreatCatalogDetails from "../details/ThreatCatalogDetails";

function ThreatCatalogFullDetails() {
  const { id } = useParams();
  const [selectedElement, setSelectedElement] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [, setSelectedRowId] = useState("");
  const [showSummaryTable, setShowSummaryTable] = useState(true);

  const columns = useMemo(
    () => [createColumnImportedAt(), createColumnModifiedAt(), createColumnBooleanNoEditMapped("isImported")],
    []
  );

  useEffect(() => {
    async function getData() {
      setRefresh(false);
      try {
        const res = await threatCatalogApi.getThreatCatalogByIDWithHttpInfo(id);
        if (res.response.status !== 200 || res.response.status !== 201) {
          errorUtils.checkErrorStatus(res.response.status, res.response.statusText);
        }
        setSelectedElement(res.data);
        setData([res.data]);
      } catch (err) {
        console.error(err);
      }
    }
    if (refresh) {
      getData();
    }
  }, [refresh, id]);

  return (
    <>
      <Title id="ThreatCatalogFullDetails_title">
        {selectedElement.name}
        <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
      </Title>

      {showSummaryTable ? (
        <DetailsContainerSingleTable>
          <BrmSingleElementTable
            data={data}
            columns={columns}
            setSelectedElement={setSelectedElement}
            setSelectedRowId={setSelectedRowId}
            customProps={{ id: "ThreatCatalogFullDetails_table" }}
            elementName={TYPE.threatCatalog}
          />
        </DetailsContainerSingleTable>
      ) : (
        <></>
      )}

      {selectedElement.id && (
        <DetailsContainerParent showSummaryTable={showSummaryTable}>
          <DetailsContainer>
            <ThreatCatalogDetails selectedElement={selectedElement} setRefresh={setRefresh} refresh={refresh} />
          </DetailsContainer>
        </DetailsContainerParent>
      )}
    </>
  );
}

export default ThreatCatalogFullDetails;
