/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { ErrorSpan } from "components/elements";
import { useConfigureProjectContext } from "./ConfigureProjectContext";
import { StandardStepButtons } from "./StandardStepButtons";
import * as CommonStyle from "./styles";
import { FileList } from "./FileList";

interface ISecurityArchitecture {
  files?: FileList;
}

export const VulnerabilityFindingsStepForm = () => {
  const findingFileRef = React.useRef<HTMLInputElement>();
  const { next, configureData, setConfigureData } = useConfigureProjectContext();
  const {
    handleSubmit,
    // register,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<ISecurityArchitecture>({ mode: "onChange", defaultValues: { files: configureData.findingsFiles } });
  const files = watch("files");

  const setConfigureDataHook = (data: ISecurityArchitecture) => {
    const findingsFiles = data.files?.length ? data.files : undefined;
    setConfigureData({ ...configureData, findingsFiles });
  };

  const onSubmit = (data: ISecurityArchitecture) => {
    setConfigureDataHook(data);
    next();
  };

  const removeSelectedFile = (file: File) => {
    if (!files) {
      return;
    }
    const newList = [...files].filter((f) => f.name !== file.name);
    if (newList.length) {
      const list = new DataTransfer();
      newList.forEach((newFile) => list.items.add(newFile));
      setValue("files", list.files);
    } else {
      setValue("files", undefined);
      if (findingFileRef.current) {
        findingFileRef.current.value = "";
      }
    }
  };

  const fileArray = files ? [...files] : [];
  let fileListLabel = `Currently Selected Vulnerability Findings Files:`;
  if (fileArray.length) {
    fileListLabel += `(${fileArray.length})`;
  }

  return (
    <CommonStyle.StepForm onSubmit={handleSubmit(onSubmit)} id="selectFinding">
      <fieldset>
        <legend>Vulnerability Findings</legend>
        <Form.Group className="mb-3" controlId="files">
          <CommonStyle.UploadLabelContainer>
            <span>Select Vulnerability Findings files for upload</span>
            <CommonStyle.UploadLabel htmlFor="files">Choose files</CommonStyle.UploadLabel>
          </CommonStyle.UploadLabelContainer>

          <Controller
            name="files"
            control={control}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ field: { onChange, value, ref, ...rest } }) => (
              <Form.Control
                style={{ display: "none" }}
                type="file"
                accept=".zip"
                multiple
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event?.target?.files !== null && event?.target?.files.length > 0) {
                    onChange(event.target.files);
                    handleSubmit(onSubmit);
                  }
                }}
                ref={(e: HTMLInputElement) => {
                  ref(e);
                  findingFileRef.current = e;
                }}
                {...rest}
              />
            )}
          />
          {errors.files && errors.files.type === "validate" && <ErrorSpan>Unsupported file type</ErrorSpan>}
        </Form.Group>
        <FileList fileArray={fileArray} onRemoveClicked={removeSelectedFile} label={fileListLabel} />
      </fieldset>
      <StandardStepButtons isBackVisible onBack={handleSubmit(setConfigureDataHook)} />
    </CommonStyle.StepForm>
  );
};
