import { useState, useMemo, useRef, useCallback } from "react";
import toast from "react-hot-toast";

import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable, RetainStringColumnFilter } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { TYPE } from "constants/brm";

import {
  createColumnName,
  createColumnBooleanMapped,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { useRoles } from "features/brm/hooks/useRoles";
import { useCanRename } from "features/system-model";
import { SystemApi } from "features/brm";

const CapabilityTable = ({ setSelectedRows }) => {
  const { canRename } = useCanRename();
  const projectId = useRecoilValue(projectIdState);
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedIsInternal = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const { data: capabilities, isError: isCapabilitiesError } = SystemApi.useCapabilities({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });
  const { mutate: setCapabilityName } = SystemApi.useSetCapabilityName({
    projectId,
    options: {
      onSettled: () => {
        setSelectedInstance({
          id: selectedInstance.id,
          name: updatedName.current,
          type: selectedInstance.type,
        });
        updatedName.current = "";
      },
      onError: () => {
        toast.error(<span id="capabilityError">An error occurred updating the capability name</span>, {
          duration: 4000,
        });
      },
    },
  });
  const { mutate: setCapabilityIsInternal } = SystemApi.useSetCapabilityIsInternal({
    projectId,
    options: {
      onSettled: () => {
        updatedIsInternal.current = "";
      },
      onError: () => {
        toast.error(<span id="capabilityError">An error occurred updating the isInternal field</span>, {
          duration: 4000,
        });
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedName.current !== "") {
              if (!canRename({ updatedName, elementsList: capabilities })) return;
              setCapabilityName({ capabilityId: selectedInstance.id, name: updatedName.current });
            }

            if (updatedIsInternal.current !== "") {
              setCapabilityIsInternal({ capabilityId: selectedInstance.id, isInternal: updatedIsInternal.current });
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },

    [selectedInstance.id, editMode, canRename, capabilities, setCapabilityName, setCapabilityIsInternal]
  );

  const checkNameUniqueness = useCallback(
    (newName) => {
      const trimedNamed = newName.trim();
      return isSystemEngineer && Array.isArray(capabilities)
        ? !capabilities.map((capability) => capability.name.toLowerCase()).includes(trimedNamed.toLowerCase())
        : false;
    },
    [isSystemEngineer, capabilities]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnName(
          selectedInstance.id,
          setSelectedInstance,
          editMode,
          updatedName,
          "capability",
          checkNameUniqueness,
          {
            Filter: RetainStringColumnFilter,
          }
        ),
        createColumnBooleanMapped("isInternal", selectedInstance.id, editMode, updatedIsInternal),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "capability"),
      createColumnBooleanMapped("isInternal", selectedInstance.id, false, updatedIsInternal),
    ];
  }, [isSystemEngineer, selectedInstance.id, setSelectedInstance, editMode, checkNameUniqueness, createButton]);

  if (isCapabilitiesError) {
    return <ErrorBanner msg="Error while loading Capability data." />;
  }

  if (capabilities) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={capabilities}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "Capability_table" }}
            elementName={TYPE.capability}
            showRowSelect={isSystemEngineer}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

CapabilityTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

export default CapabilityTable;
