import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./global-control-query-key-factory";

/**
 * Get control types of cntrl catalog
 *
 * @param {string} catalogId - uuid of the control catalog
 */
export const getCatalogControlTypes = ({ catalogId }: { catalogId: string }) => {
  return Brm.controlCatalogApi.getControlCatalogControltype(catalogId);
};

type FetcherFunction = typeof getCatalogControlTypes;
interface ICatalogControlTypes {
  catalogId: string;
  options?: QueryOption<FetcherFunction>;
}

/**
 * React-query hook for getting ControlTypes from a control catalog
 *
 * @param {string} catalogId - uuid of the control Catalog
 * @param {object} options - configuration for useControlCatalog hook
 * @returns react-query for getCatalogControlTypes
 */
export const useCatalogControlTypes = ({ catalogId, options = {} }: ICatalogControlTypes) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.catalogBaselineKeys.catalog(catalogId),
    queryFn: () => getCatalogControlTypes({ catalogId }),
  });
};
