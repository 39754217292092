import * as Recoil from "recoil";
import Avatar from "react-avatar";
import { userIdState } from "atoms/atoms-admin";
import { AdminApi } from "features/brm";

interface AvatarOptions {
  size: string;
}

export const UserAvatar = ({ size }: AvatarOptions) => {
  const userId = Recoil.useRecoilValue(userIdState);
  const { data: user, isError } = AdminApi.useUser({ userId });

  const name = user ? `${user.firstname} ${user.lastname}` : "";

  if (isError) {
    return null;
  }

  return <Avatar name={name} size={size} round />;
};
