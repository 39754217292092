import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
// import ClassificationTable from "components/DetailTables/CategorizedEntityClassificationTable";
import AssetTable from "brm/tables/details/AssetTable";
import CategorizationTable from "brm/tables/details/CategorizationTable";
import PropTypes from "prop-types";
import { useRoles } from "features/brm/hooks/useRoles";
import { DetailTableType } from "features/brm";

const CategorizedEntityDetails = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.CATEGORIZED_ENTITIES.key;
  const { isRiskAnalyst, isSystemEngineer } = useRoles();

  return (
    <Tabs>
      <TabList>
        <Tab>Assets</Tab>
        <Tab>Categorization</Tab>
      </TabList>

      <TabPanel>
        <AssetTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
      <TabPanel>
        <CategorizationTable
          elementNameInUrl={elementNameInUrl}
          selectedElement={selectedElement}
          showAdd={isRiskAnalyst || isSystemEngineer}
          showDelete={isRiskAnalyst || isSystemEngineer}
        />
      </TabPanel>
    </Tabs>
  );
};

CategorizedEntityDetails.propTypes = {
  selectedElement: PropTypes.object.isRequired,
};

export default CategorizedEntityDetails;
