export const attackDiagramKeys = {
  all: ["attackDiagram"] as const,
  assetsByCategory: (projectId: string, variantId: string) =>
    [...attackDiagramKeys.all, "assetsByCategory", projectId, variantId] as const,
  undesiredEventsByCategory: (projectId: string, variantId: string) =>
    [...attackDiagramKeys.all, "undesiredEventsByCategory", projectId, variantId] as const,
  threatEventsByCategory: (projectId: string, variantId: string) =>
    [...attackDiagramKeys.all, "threatEventsByCategory", projectId, variantId] as const,
  attacksByTactic: (projectId: string, variantId: string) =>
    [...attackDiagramKeys.all, "attacksByTactic", projectId, variantId] as const,
  risksByCategory: (projectId: string, variantId: string) =>
    [...attackDiagramKeys.all, "risksByCategory", projectId, variantId] as const,
  targetsAsNodeData: (projectId: string, variantId: string) =>
    [...attackDiagramKeys.all, "targetsAsNodeData", projectId, variantId] as const,
};
