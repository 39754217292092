import * as React from "react";
import { useSap } from "features/admin/hooks";

import { Button } from "react-bootstrap";
import Modals from "components/modals/Modals";
import { AdminApi } from "features/brm";
import * as S from "./InstallationSapPanel.styled";

export const InstallationSapPanel = () => {
  const { lastSapUpdate, sapRecordCount } = useSap();
  const [isModalVisible, setModalVisible] = React.useState(false);

  const { mutate: resetSap } = AdminApi.useResetSapOrganization();

  const handleConfirmation = () => {
    setModalVisible(false);
    resetSap({});
  };

  return (
    <>
      {isModalVisible
        ? Modals.confirmModal(
            "reset sap",
            isModalVisible,
            setModalVisible,
            <div>Resetting SAP data will cause your current SAP bindings to be lost. Proceed with reset?</div>,
            "Reset SAP",
            handleConfirmation
          )
        : null}
      <S.PropertiesContainer>
        <S.PropertyContainer>
          <S.PropertyName>Last Updated:</S.PropertyName>
          <S.PropertyValue>{lastSapUpdate}</S.PropertyValue>
        </S.PropertyContainer>
        <S.PropertyContainer>
          <S.PropertyName>Size:</S.PropertyName>
          <S.PropertyValue>{sapRecordCount}</S.PropertyValue>
        </S.PropertyContainer>
        <S.PropertyContainer>
          <S.PropertyName>Reset SAP:</S.PropertyName>
          <S.PropertyValue>
            <Button onClick={() => setModalVisible(true)}>Reset SAP</Button>
          </S.PropertyValue>
        </S.PropertyContainer>
      </S.PropertiesContainer>
    </>
  );
};
