import PropTypes from "prop-types";
import * as React from "react";

// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
// Services
import { VariantCreateDto } from "@kdmanalytics/brm-component";
// State
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
// Components
import TextField from "components/forms/TextField";
// Constants
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR, UN_MITIGATED_VARIANT } from "constants/brm";
import { MitigationApi } from "features/brm";
import { ErrorSpan } from "components/elements";
import { AiOutlineExclamationCircle } from "react-icons/ai";

export const AddVariantForm = ({ setModalIsOpen, onNewVariant }) => {
  const projectId = useRecoilValue(projectIdState);
  const [postError, setPostError] = React.useState("");
  const [isTextValid, setIsTextValid] = React.useState(true);

  const { mutate: createVariant } = MitigationApi.useCreateVariant();
  const { data: existingVariants, isError: isVariantError } = MitigationApi.useVariants({ projectId });

  // validate that all fields in the form have been completed
  function validate(formData) {
    let error = true;
    if (formData.name.value === "") {
      error = false;
    }
    return error;
  }

  // validate variant name is unique
  function validateName(formData) {
    let error = true;

    existingVariants.forEach((variant) => {
      if (variant.name === formData.name.value) {
        error = false;
        setPostError(FORM_ERROR.uniqueName);
      }
      if (formData.name.value === UN_MITIGATED_VARIANT) {
        error = false;
        setPostError(FORM_ERROR.preDefinedName);
      }
    });

    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const isValid = validate(e.target.elements);
    const isValidName = validateName(e.target.elements);

    if (isValid && isTextValid) {
      if (isValidName) {
        const params = {
          name: e.target.elements.name.value,
          isCompliance: false,
          note: e.target.elements.note.value || "",
        };
        const variantCreateDto = VariantCreateDto.constructFromObject(params);
        createVariant(
          { projectId, variantCreateDto },
          {
            onSettled: (data, error, variables) => {
              setModalIsOpen(false);
              onNewVariant({ id: data, name: variables.variantCreateDto.name, isRecalcNeeded: false });
            },
          }
        );
      }
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  if (isVariantError) {
    return <ErrorSpan> Unable to load existing variants</ErrorSpan>;
  }

  return (
    <div>
      <FormStyled>
        <form onSubmit={handleSubmit} action="">
          <div className="form-style">
            <TextField
              label={{ id: "VariantAddForm_name", name: FORM_LABEL.nameMandatory }}
              input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
              setIsTextValid={setIsTextValid}
            />
            <TextField
              label={{ id: "VariantAddForm_note", name: FORM_LABEL.note }}
              input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
              setIsTextValid={setIsTextValid}
            />
          </div>
          <FormStyledError>{postError}</FormStyledError>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginLeft: "10px" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
              <AiOutlineExclamationCircle size="80px" fill="#6c757d" />
              <div>
                Ensure your project is configured to use the relevant Control Catalog and Baseline, otherwise the
                default Catalog and Baseline will be applied. Configuration is done via the following role and task:
                <div style={{ marginLeft: "20px", marginTop: "5px", marginBottom: "5px" }}>
                  <ul>
                    <li>Control Coordinator - Configure Control Catalog and Baseline</li>
                  </ul>
                </div>
                Baseline can be also changed locally, at the variant level, by the Risk Analyst.
              </div>
            </div>
          </div>

          <DialogButtonDivStyled>
            <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
            <DialogButtonStyled type="submit">Add</DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </FormStyled>
    </div>
  );
};

AddVariantForm.propTypes = {
  setModalIsOpen: PropTypes.func,
  onNewVariant: PropTypes.func,
};
