import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./user-query-key-factory";

/**
 *
 * Gets assigned roles of a user
 * @param {string} userId - uuid of the user
 */
export const getUserAssignedRoles = async ({ userId }: { userId: string | null }) => {
  return userId === null ? Promise.reject(new Error("Invalid id")) : Brm.userApi.getUserAssignedRole(userId);
};

interface IGetUserAssignedRoleProps {
  userId: string | null;
  options?: QueryOption<typeof getUserAssignedRoles>;
}

/**
 * Custom hook for getting assigned roles of a user
 *
 * @param {string} userId - uuid of user
 * @param {object} config - react-query configuration
 * @returns
 */
export const useUserAssignedRoles = ({ userId, options = {} }: IGetUserAssignedRoleProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.userKeys.assignedRole(userId),
    queryFn: () => getUserAssignedRoles({ userId }),
  });
};
