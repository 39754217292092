import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./variant-query-key-factory";

interface ISetVariantNameProps {
  variantId: string | null;
  name: string;
}

/**
 * Sets the name of a variant
 *
 * @param {string} id - uuid of the variant
 * @param {string} name - new name for the variant
 */
export const setVariantName = ({ variantId, name }: ISetVariantNameProps) => {
  return Brm.variantApi.setVariantName(variantId, { body: name });
};

interface IUseSetVariantNameProps {
  options?: MutationOption<typeof setVariantName>;
}

/**
 * Custom hook to change the name of a existing variant
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetVariantName = ({ options }: IUseSetVariantNameProps = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => queryClient.invalidateQueries(KeyFactory.variantsKeys.all),
    ...options,
    mutationFn: setVariantName,
  });
};
