import * as React from "react";

import PropTypes from "prop-types";
import Select from "react-select";
import { ThemeContext } from "styled-components";
import { keyToName } from "services/user";
import { sortByRanks } from "utils/sorting";
import { getCustomStyles } from "./role-selector.styles";

const Dropdown = ({ currentUserRole, onRoleChange, allocatedRoles, onRoleRefresh }) => {
  const theme = React.useContext(ThemeContext);
  const [roleList, setRoleList] = React.useState([]);
  const [defaultRole, setDefaultRole] = React.useState("");

  const onChangeHandler = (selectedItems) => {
    const item = selectedItems || {};
    onRoleChange(item.value);
  };

  React.useEffect(() => {
    const roles = [];
    allocatedRoles.map((item) => roles.push({ value: item, label: keyToName[item] }));
    const sortedRoles = roles.sort(sortByRanks);
    setRoleList(sortedRoles); // Sort the role list by ranks always

    roles.forEach((role) => {
      if (role.value === currentUserRole) {
        setDefaultRole(role);
      }
    });
  }, [setRoleList, allocatedRoles, currentUserRole]);

  return (
    <span title="Role Selection">
      <Select
        isSearchable={false}
        menuPortalTarget={document.body}
        id="Dropdown_roleSelect"
        classNamePrefix="roleSelect"
        isMulti={false}
        options={roleList}
        value={currentUserRole ? defaultRole : ""}
        onChange={onChangeHandler}
        placeholder="Select Role..."
        styles={getCustomStyles(theme)}
        onBlur={() => {
          onRoleRefresh();
        }}
        onFocus={() => {
          onRoleRefresh();
        }}
      />
    </span>
  );
};

Dropdown.propTypes = {
  currentUserRole: PropTypes.string.isRequired,
  onRoleChange: PropTypes.func.isRequired,
  allocatedRoles: PropTypes.array.isRequired,
  onRoleRefresh: PropTypes.func,
};

export default Dropdown;
