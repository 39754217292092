import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import * as Brm from "services/brm";

interface IExportSystemRisk {
  systemId: string;
}

const exportSystemRisk = ({ systemId }: IExportSystemRisk) => {
  return Brm.systemApi.exportRiskSystem(systemId);
};

interface IUseExportSystemRisk {
  options?: MutationOption<typeof exportSystemRisk>;
}

export const useExportSystemRisk = ({ options }: IUseExportSystemRisk = {}) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: exportSystemRisk,
  });
};
