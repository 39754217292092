import PropTypes from "prop-types";
import * as Recoil from "recoil";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "components/error";
import { PageSpinner } from "components/elements";

// State
// import { useRecoilValue, useSetRecoilState } from "recoil";
import { rightSidebarOpenState, contentTypeState, userIdState, projectIdState, userRoleState } from "atoms";

// Styles
import {
  Breadcrumb,
  contextSidebarContentState,
  PrevNextButton as PrevNext,
  CONTEXT_SIDEBAR_CONTENT,
  // isCurrentProjectActiveState,
  isCurrentProjectGeneratingState,
} from "features/brm";

// Hooks
import useCheckUserPermission from "hooks/useCheckUserPermission";

// import { useRoles } from "hooks";
import { useRoles } from "features/brm/hooks/useRoles";
import S from "./Content.styles";
// Local
import contentMap from "./contentMap";

const Content = ({ contentKey }) => {
  const { isSuperAdmin, isAdmin } = useRoles();
  const userId = Recoil.useRecoilValue(userIdState);
  const projectId = Recoil.useRecoilValue(projectIdState);
  const userRole = Recoil.useRecoilValue(userRoleState);
  const setContentType = Recoil.useSetRecoilState(contentTypeState);
  const setSideContentType = Recoil.useSetRecoilState(contextSidebarContentState);
  const setDrawerVisible = Recoil.useSetRecoilState(rightSidebarOpenState);
  const isCurrentProjectGenerating = Recoil.useRecoilValue(isCurrentProjectGeneratingState);

  const { Content: ContentComp, tableId, type, hideBreadCrum, hidePrevNextBtn } = contentMap[contentKey];

  useCheckUserPermission(userId, projectId, userRole, contentKey);

  useEffect(() => {
    setContentType(type);
    setSideContentType(CONTEXT_SIDEBAR_CONTENT.notes);
    setDrawerVisible(false);
  }, [setContentType, setDrawerVisible, setSideContentType, type]);

  const regularContents = (
    <>
      <S.ContentNavArea>
        {!hideBreadCrum && <Breadcrumb contentKey={contentKey} />}
        {!hidePrevNextBtn && <PrevNext />}
      </S.ContentNavArea>
      <ContentComp className={tableId} navroute="" setNavroute={() => {}} userRole={userRole} />
    </>
  );

  const generatingContents = (
    <S.CenterContainer>
      <PageSpinner text="Please wait while calculating risk" />
    </S.CenterContainer>
  );

  const isAdminAndProjectContent = (isAdmin || isSuperAdmin) && contentKey === "projects";

  let cnt = regularContents;
  if (isAdminAndProjectContent) {
    cnt = regularContents;
  } else if (isCurrentProjectGenerating) {
    cnt = generatingContents;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
      <S.Main>{cnt}</S.Main>
    </ErrorBoundary>
  );
};

Content.propTypes = {
  contentKey: PropTypes.any,
};

export default Content;
