import * as React from "react";

interface IInlineEditNumberCellProps {
  isEditMode: boolean;
  value: number;
  onChange: (value: number) => void;
}

export const InlineEditNumberCell = ({
  isEditMode,
  value: providedValue,
  onChange: providedOnChange,
}: IInlineEditNumberCellProps) => {
  const [value, setValue] = React.useState(providedValue);

  if (isEditMode) {
    return (
      <input
        type="number"
        min="0.1"
        max="1.0"
        step="0.1"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const floatValue = Math.min(Math.max(parseFloat(event.target.value), 0.1), 1.0);
          setValue(floatValue);
          providedOnChange(floatValue);
        }}
        onKeyDown={(e) => {
          if (!(e.key === "ArrowUp" || e.key === "ArrowDown")) {
            e.preventDefault();
          }
        }}
      />
    );
  }
  return <>{value}</>;
};
