import { endpointUrl, getFetchParams } from "services/api/graphql/graphql-config";
import { RequestTooLargeError } from "services/errors";
// import * as Log from "loglevel";

// const log = Log.getLogger("ProjectConfigureLogger");

export function fetcher<TData, TVariables>(
  query: string,
  variables?: TVariables /* , options?: RequestInit["headers"] */
) {
  return async (): Promise<TData> => {
    const params = await getFetchParams();
    const res = await fetch(endpointUrl as string, {
      method: "POST",
      ...params,
      body: JSON.stringify({ query, variables }),
    });

    if (!res.ok) {
      if (res.status === 413) {
        throw new RequestTooLargeError(res.statusText);
      } else {
        const message = `An error has occured: ${res.status}:${res.statusText} `;
        throw new Error(message);
      }
    }

    const json = await res.json();
    if (json.errors) {
      const { message } = json.errors[0];

      // log.debug("endpoint", endpointUrl);
      // log.debug("params", params);

      throw new Error(message);
    }
    return json.data;
  };
}
