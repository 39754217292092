import { useQuery } from "@tanstack/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "../access/access-query-key-factory";
import { DetailTableType } from "../../../types/DetailTableType";
import { IElementProps, IElementMap } from "./types/elementInterface";
import { responseAsArray } from "./utils/responseAsArray";

/**
 * custom-hook to get all attack surfaces of an element
 *
 * @param {string} elementId - uuid of the selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of the project
 * @param {object} config - configuration for the react-query
 */

export const getElementAttackSurfaces = async (elementId: string, elementType: string) => {
  const attackSurfaceMap: IElementMap = {
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeAllSurface(id),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeAllSurface(id),
    [DetailTableType.ATTACKERS.key]: (id) => Brm.attackerApi.getAttackerAllSurface(id),
    [DetailTableType.ATTACK_VECTORS.key]: (id) => Brm.attackVectorApi.getAttackVectorAllSurface(id),
    [DetailTableType.ACCESS_POINTS.key]: (id) => Brm.accessPointApi.getAccessPointSurface(id),
    [DetailTableType.ENTRY_POINTS.key]: (id) => Brm.entryPointApi.getEntryPointSurface(id),
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityAllSurface(id),
  };

  return responseAsArray({ map: attackSurfaceMap, elementId, elementType });
};

export const useElementAttackSurface = ({ elementId, elementName, projectId, options = {} }: IElementProps) => {
  const elementAttackSurfaceQuery = useQuery({
    ...options,
    queryKey: KeyFactory.attackSurfaceKeys.details(elementId, elementName, projectId),
    queryFn: () => getElementAttackSurfaces(elementId, elementName),
  });

  return elementAttackSurfaceQuery;
};
