const defaultTheme = {
  fonts: {
    primary: '"Segoe UI", "Nunito Sans", "Roboto", "Helvetica", "Arial", "sans-serif"',
    secondary: '"Segoe UI", "Nunito Sans", "Roboto", "Helvetica", "Arial", "sans-serif"',
    small: "10px",
    medium: "12px",
    defaultSize: "14px", // deprecated
    default: "14px",
    large: "16px",
    titleSize: "18px",
  },

  colors: {
    primary: "#282828",
    primaryButton: "#005CA6",
    background: "#ffffff",
    lowContrastBackground: "#fafbfc",
    mediumLowConstrastBackground: "#f0f0f0",
    mediumContrastBackground: "#d3d3d3",
    mediumHighContrastBackground: "#C0C0C0",
    highContrastBackground: "#818181",
    veryHighContrastBackground: "#5c5c5c",
    buttonHover: "#005CA6",
    buttonPrimary: "#737373",
    disabled: "#8a8a8a",
    disabledMedium: "#7A7A7A",
    disabledLight: "#d3d3d3",
    success: "#d4edda",
    successText: "#659671",
    error: "#f8d7da",
    errorText: "#dc3545",
    title: "#000000",
  },

  // Common Sizes
  sizes: {
    defaultButton: "32px",
    leftPanelWidth: "0px",
    rightPanelWidth: "0px",
    bannerHeight: "0px",
    logoWidth: "164px",
    standaloneIconWidth: "20px",
    standaloneIconHeight: "20px",
    boxedIconWidth: "16px",
    boxedIconHeight: "16px",
    // boxedIconContainerHeight: "28px", // defined by studio91 but not currently used
    // boxedIconContainerWidth: "28px", // defined by studio91 but not currently used
    dragBarWidth: "3px",
    defaultPadding: "15px",
    chevronWidth: "18px",
    chevronHeight: "18px",
  },

  tables: {
    headers: {
      color: "#ffffff",
      background: "#005ca6",
      borderRight: "1px solid #ffffff",
      textAlign: "left",
      borderRadius: "5px",
    },
    data: {
      margin: "auto",
      padding: "6px",
      textAlign: "left",
      color: "#000000",
      border: "solid 1px #F1F1F1",
      cellHeight: "44px",
    },
    pagination: {
      buttonPadding: "3px",
      margin: "0px 3px",
      small: {
        fontSize: "12px",
        borderRadius: "4px",
      },
    },
  },

  footer: {
    height: "22px",
    backgroundColor: "#818181",
    textColor: "black",
    font: "12px Segoe UI",
  },

  header: {
    height: "59px",
    padding: "0 10px 0 0px",
    textColor: "#737373",
  },

  mainToolbars: {
    width: "43px",
    padding: "3px",
  },

  dragBars: {
    width: "3px",
    backgroundColor: "#ebecf0",
  },

  attackGraph: {
    palette: {
      iconHeight: "12px",
      iconWidth: "12px",
    },
  },

  // buttons
  primarybuttonbackgroundcolor: "#005CA6",
  primarybuttonbordercolor: "#005CA6",
  primarybuttontextcolor: "white",
  secondarybuttonbackgroundcolor: "#ffffff",
  secondarybuttontextcolor: "black",
  secondarybuttonbordercolor: "#D7D7D7",
  cancelbuttonbackgroundcolor: "#FF0000",

  // dropdown menu
  dropdownselectcolor: "black",

  // forms
  formdialogbuttoncolor: "white",
  formdialogbuttonbackgroundcolor: "#005ca6",
  formbackground: "#F7F7F7",
  formstyleinputselecttextareacolor: "#555",
  formstyleinputselecttextareabackground: "#fff",

  // nav
  navheaderbackgroundcolor: "#e5e5e5",
  navheaderoutlinecolor: "#bebebe",

  navheadericoncolor: "#a0a0a0",
  navheaderitemtextcolor: "#5c5c5c",

  // sidebar navigator
  sidebarnavtextcolor: "#000000",
  sidebarnavexpanderdivcolor: "#cccccc",

  // grid container
  gridcontainerbackgroundcolor: "#cccccc",

  // custom checkboxes/checkmarks
  checkmarkcolor: "white",
  checkboxbackgroundcolor: "rgb(44, 117, 159)",
  checkboxhoverbackgroundcolor: "#ccc",

  // tables
  tableborder: "solid 1px gray",
  tableheaderbackgroundcolor: "rgb(244, 245, 247)",
  // tableheaderbackgroundcolor: "#266faa",
  tableheaderborderright: " 1px solid #ffffff",
  tableheadercolor: "black",
  tableheadertextalign: "left",

  tabledatamargin: "auto",
  tabledatapadding: "1px",
  tabledatatextalign: "left",
  tabledatacolor: "#000000",
  tabledataborder: "solid 1px #F1F1F1",
  tabledatacellheight: "37px",

  // pagination
  paginationbuttonpadding: "3px",
};

export default defaultTheme;
