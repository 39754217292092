import { useState } from "react";

import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import VulnerabilityCategoryTable from "./tables/VulnerabilityCategoryTable";

function VulnerabilityCategory() {
  const [, setSelectedRows] = useState([]);

  const vulnerabilityCatHeader = <Title id="VulnerabilityCategory_title">Vulnerability Categories</Title>;

  const vulnerabilityCatMain = <VulnerabilityCategoryTable setSelectedRows={setSelectedRows} />;

  return (
    <ContentLayout pageId="VulnerabilityCategory_page" header={vulnerabilityCatHeader} main={vulnerabilityCatMain} />
  );
}

export default VulnerabilityCategory;
