import * as BrmGql from "generated/graphql";

export const capabilityKeys = {
  all: ["capabilities"] as const,
  capability: (capabilityId: string) => [...capabilityKeys.all, capabilityId] as const,
  project: (projectId: string) => [...capabilityKeys.all, projectId] as const,
  element: (
    elementId: string | null,
    elementName: string | null,
    projectId: string | null,
    variantId: string | null,
    exchangeCapabilitiesData: BrmGql.GetExchangeCapabilitiesQuery | undefined,
    dataTypeCapabilitiesData: BrmGql.GetDataTypeCapabilitiesQuery | undefined,
    personCapabilitiesData: BrmGql.GetPersonCapabilitiesQuery | undefined,
    resourceCapabilitiesData: BrmGql.GetResourceCapabilitiesQuery | undefined
  ) =>
    [
      ...capabilityKeys.all,
      "elementCapabilities",
      elementId,
      elementName,
      projectId,
      variantId,
      exchangeCapabilitiesData,
      dataTypeCapabilitiesData,
      personCapabilitiesData,
      resourceCapabilitiesData,
    ] as const,
};
