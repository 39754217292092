import PropTypes from "prop-types";
import * as React from "react";
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import { controlCatalogIdState } from "atoms/atoms-global-control";
import { useRecoilValue } from "recoil";
import { LoadingSpinner } from "components/elements";
import { GlobalControlApi } from "features/brm";
import { FORM_LABEL } from "constants/brm";
import getReactSelectArray from "utils/react-select-array";
import SelectAll from "components/forms/SelectAllReactSelect";
import Select from "react-select";
import { useAddControlTypes } from "./form-queries";

const ControlTypeAddForm = ({ setModalIsOpen, elementNameInUrl, selectedElement }) => {
  const [selectedControlTypes, setSelectedControlTypes] = React.useState([]);
  const [selectedFamily, setSelectedFamily] = React.useState();
  const controlCatalogId = useRecoilValue(controlCatalogIdState);
  const { mutate: addCT } = useAddControlTypes(selectedElement?.id, elementNameInUrl);

  const { data: familyOptions } = GlobalControlApi.useControlFamilies({
    controlCatalogId,
    options: {
      enabled: controlCatalogId !== "",
      select: (data) => getReactSelectArray(data, true),
    },
  });

  const { data: baselineControlTypes } = GlobalControlApi.useGetStandardBaselineControlType({
    baselineId: selectedElement.id,
  });

  const famId = selectedFamily?.value || "";
  const { data: familyControlTypesOptions } = GlobalControlApi.useGetControlFamilyControls({
    controlFamilyId: famId,
    options: {
      enabled: selectedFamily !== undefined,
      select: (data) => getReactSelectArray(data, true),
    },
  });

  const typeOptions = React.useMemo(() => {
    if (familyControlTypesOptions && baselineControlTypes) {
      return familyControlTypesOptions.filter((o) => !baselineControlTypes.find((b) => o.value === b.id));
    }
    return [];
  }, [baselineControlTypes, familyControlTypesOptions]);

  async function handleSubmit(e) {
    e.preventDefault();

    await Promise.all(
      selectedControlTypes.map((each) => {
        return addCT({ controlId: each.value });
      })
    );
    setModalIsOpen(false);
  }

  const handleControlTypeSelectChange = (selectedItems) => {
    const items = selectedItems || [];
    setSelectedControlTypes(items);
  };

  const handleControlFamilySelectChange = async (selectedItems) => {
    const items = selectedItems || {};
    setSelectedFamily(items);
  };

  if (familyOptions) {
    return (
      <div id="ControlTypeAddForm">
        <form onSubmit={handleSubmit} action="" id="ControlTypeAddForm_form">
          <FormStyled>
            <div className="form-style" id="ControlTypeAddForm_formContent">
              <label>{FORM_LABEL.controlFamily}</label>
              <Select
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                isMulti={false}
                options={familyOptions}
                // defaultValue={selectedFamily}
                onChange={handleControlFamilySelectChange}
                id="ControlTypeAddForm_controlFamily_dropdown"
                classNamePrefix="ControlTypeAddForm_controlFamily_dropdownList"
              />

              <label>{FORM_LABEL.controlTypes}</label>
              <SelectAll
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                isMulti
                options={typeOptions ?? []}
                defaultValue={selectedControlTypes}
                value={selectedControlTypes}
                onChange={handleControlTypeSelectChange}
                allowSelectAll
                allOption={{
                  label: "Select all",
                  value: "*",
                }}
                isDisabled={selectedFamily === undefined || (Array.isArray(typeOptions) && typeOptions.length === 0)}
                elementId="ControlTypeAddForm_controlType"
              />
            </div>
          </FormStyled>
          <DialogButtonDivStyled>
            <DialogButtonStyled
              onClick={() => {
                setModalIsOpen(false);
              }}
              id="ControlTypeAddForm_cancelButton"
            >
              Cancel
            </DialogButtonStyled>
            <DialogButtonStyled type="submit" id="ControlTypeAddForm_addButton">
              Add
            </DialogButtonStyled>
          </DialogButtonDivStyled>
        </form>
      </div>
    );
  }

  return <LoadingSpinner />;
};

ControlTypeAddForm.propTypes = {
  elementNameInUrl: PropTypes.any,
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setModalIsOpen: PropTypes.func,
};

export default ControlTypeAddForm;
