import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";

interface ISetControlTypeVulCat {
  controlTypeId: string;
  vulCat: any;
}

/**
 * Sets vulnerability categoru value for control type
 *
 * @param {string} cntrlTypeId - uuid of control type
 * @param {string} cost - user entered value for cost of the control type
 */
export const setControlTypeVulCat = ({ controlTypeId, vulCat }: ISetControlTypeVulCat) => {
  return Brm.controlTypeApi.setControlTypeVulnerabilitycategory(controlTypeId, { body: vulCat });
};

interface IUseSetControlTypeVulCategory {
  options?: MutationOption<typeof setControlTypeVulCat>;
}

/**
 * Custom hook to change Vul Cat of a existing control type
 *
 * @param {object} options - configuration of react-query
 * @returns react-query ReactQuery.useMutation
 */
export const useSetControlTypeVulCategory = ({ options }: IUseSetControlTypeVulCategory) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: setControlTypeVulCat,
  });
};
