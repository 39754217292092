import * as React from "react";
import { FaChevronDown } from "react-icons/fa";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import {
  chevronStyle,
  Dropdown,
  Button,
  MenuItem,
  Container,
  DisabledContainer,
  MenuItemDisabled,
} from "./DynamicSubTabSelector.styles";

interface ITableType {
  name: string;
  key: string;
  isDisabled: boolean;
}

interface IDynamicSubTabSelectorProps {
  title: string;
  menu: ITableType[];
  setSelectedContext: (value: string) => void;
  curr: string;
}

const DynamicSubTabSelector = ({ title, menu, setSelectedContext, curr }: IDynamicSubTabSelectorProps) => {
  const [toggle, setToggle] = React.useState(false);
  const containerRef = React.useRef(null);
  useOnClickOutside(containerRef, () => setToggle(false));

  if (Array.isArray(menu) && menu?.length > 0) {
    return (
      <div id={`tabSelector_${title}`} ref={containerRef}>
        <span id="tabSelector_title">{title}</span>
        <Button
          id="tabSelector_dropdown"
          type="button"
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          <FaChevronDown fill="#000000" style={chevronStyle} />
        </Button>

        {toggle && title === curr && (
          <Dropdown id="tabSelector_menu">
            {menu.map((e) => {
              return e.isDisabled ? (
                <DisabledContainer id={`tabSelector_disabled_${e.name}`} key={e.name} onClick={() => {}}>
                  <MenuItemDisabled id={`${e.name}_disabled`}>{e.name}</MenuItemDisabled>
                </DisabledContainer>
              ) : (
                <Container
                  id={`tabSelector_${e.name}`}
                  key={e.name}
                  onClick={() => {
                    setSelectedContext(e.key);
                    setToggle(false);
                  }}
                >
                  <MenuItem id={`${e.name}`}>{e.name}</MenuItem>
                </Container>
              );
            })}
          </Dropdown>
        )}
      </div>
    );
  }

  return null;
};

export default DynamicSubTabSelector;
