import PropTypes from "prop-types";
import { keyToName } from "services/user";

function InLineRoleSelect({ arrayOfData, item, updateValues }) {
  let options;
  if (Array.isArray(arrayOfData) && arrayOfData.length) {
    // check if there is any data in the array first
    options = arrayOfData.map((data) => (
      <option key={data} value={data}>
        {keyToName[data]}
      </option>
    ));
  }

  function handleFieldChange(e) {
    updateValues(e.target.value);
  }

  return (
    <select name={item} id={item} onBlur={handleFieldChange}>
      <option>Select {item}</option>
      {options}
    </select>
  );
}

InLineRoleSelect.propTypes = {
  arrayOfData: PropTypes.arrayOf(PropTypes.string),
  item: PropTypes.any,
  updateValues: PropTypes.func,
};

export default InLineRoleSelect;
