/*
This page is the initial landing page when the user logs in. Here we are redirecting
 the user to a specific route according to his role as its starting point.
 
 LATER : this page can be acting as a common dashboard without any rerouting as implemented
 for now!
*/
import * as React from "react";
import * as Recoil from "recoil";

import DOMPurify from "dompurify";
import { rightSidebarOpenState } from "atoms";
import { userRoleState } from "atoms/atoms-admin";
import { currentVariantState, contextSidebarContentState, useShowProjectText } from "features/brm";

// Services
import {
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
  ROLE_RISK_ANALYST,
  ROLE_THREAT_ANALYST,
  ROLE_SYSTEM_ENGINEER,
  ROLE_CYBERSECURITY_EXPERT,
  ROLE_CONTROLS_COORDINATOR,
} from "services/user";

import { PageSpinner } from "components/elements";
import modals from "components/modals/Modals";
import HtmlHelpViewer from "components/elements/HtmlHelpViewer";
import { Separator } from "components/elements/NavigationToolbar";

import { useRoles } from "features/brm/hooks/useRoles";
import { HelpVideosList } from "features/help";

// import { useShowProjectText } from "hooks/useShowProjectText";

import { userDescriptions, userTasks, roleSubHeading } from "./userConstants";
import { ImgComponent } from "../ImgComponent";
import ccImage from "../../assets/homepage/cc.png";
import adminImage from "../../assets/homepage/admin.png";
import saImage from "../../assets/homepage/sa.png";
import raImage from "../../assets/homepage/ra.png";
import cseImage from "../../assets/homepage/cse.png";
import taImage from "../../assets/homepage/ta.png";
import seImage from "../../assets/homepage/se.png";

import S from "./LandingPage.styles";
import { useRefCallback } from "./useRefCallback";

const getImageMap = {
  [ROLE_ADMIN]: adminImage,
  [ROLE_SUPER_ADMIN]: saImage,
  [ROLE_RISK_ANALYST]: raImage,
  [ROLE_CONTROLS_COORDINATOR]: ccImage,
  [ROLE_CYBERSECURITY_EXPERT]: cseImage,
  [ROLE_THREAT_ANALYST]: taImage,
  [ROLE_SYSTEM_ENGINEER]: seImage,
};

const quickStartMap = {
  [ROLE_ADMIN]: "Administrating",
  [ROLE_SUPER_ADMIN]: "Installing & Configuring ",
  [ROLE_RISK_ANALYST]: "Analyzing Risk",
  [ROLE_CONTROLS_COORDINATOR]: "Configuring Controls",
  [ROLE_CYBERSECURITY_EXPERT]: "CyberSecurity Customization",
  [ROLE_THREAT_ANALYST]: "Configuring Threat Model",
  [ROLE_SYSTEM_ENGINEER]: "System Engineering",
};

export const LandingPage = () => {
  const currentVariant = Recoil.useRecoilValue(currentVariantState);
  const userRoleVal: string | null = Recoil.useRecoilValue(userRoleState);
  const setSideContentType = Recoil.useSetRecoilState(contextSidebarContentState);
  const setDrawerVisible = Recoil.useSetRecoilState(rightSidebarOpenState);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [path, setPath] = React.useState("");
  const [title, setTitle] = React.useState("");

  const { isRiskAnalyst } = useRoles();
  const { setRef } = useRefCallback({ setDrawerVisible, setSideContentType });

  const subHeading = userRoleVal && roleSubHeading[userRoleVal] ? roleSubHeading[userRoleVal] : "";
  const descriptions = userRoleVal && userDescriptions[userRoleVal] ? userDescriptions[userRoleVal] : [];
  const tasks = userRoleVal && userTasks[userRoleVal] ? userTasks[userRoleVal] : [];
  const src = userRoleVal ? getImageMap[userRoleVal as unknown as keyof typeof getImageMap] : "";

  const canDisableLinks = useShowProjectText();
  const canEnableVariantLinks = currentVariant !== null && currentVariant?.name !== "Initial" && isRiskAnalyst;

  const handleBtnOnClick = (openModal: boolean, filePath: string, name: string) => {
    setIsModalOpen(true);
    setPath(filePath);
    setTitle(name);
  };

  if (!userRoleVal) {
    return (
      <S.CenterContainer>
        <PageSpinner text="Loading....." />
      </S.CenterContainer>
    );
  }

  return (
    <S.PageWrapper>
      <S.UserRole>{`Quick Start - ${
        userRoleVal ? quickStartMap[userRoleVal as unknown as keyof typeof quickStartMap] : ""
      }`}</S.UserRole>
      {subHeading && <S.RoleSubHeading>{subHeading}</S.RoleSubHeading>}
      <Separator width="90%" margin="0 5%" />
      <S.Flex>
        <S.LeftSection>
          <S.SectionHeading>
            <q>Where to go</q> to perform key tasks
          </S.SectionHeading>
          <S.Maintasks>
            {descriptions.map((description) => {
              return (
                <div key={description?.heading || ""}>
                  {description?.heading && <S.Heading>{description?.heading || ""}</S.Heading>}
                  <S.Subtext ref={setRef}>
                    {description.list.map((lst, index) => {
                      let disabledClass = lst?.text && canDisableLinks ? "disabledlink" : "";
                      let formatedtext = lst?.text?.replace(/{placeholder}/gm, disabledClass);

                      if (!canEnableVariantLinks) {
                        disabledClass = !canEnableVariantLinks ? "disabledlink" : "";
                        formatedtext = formatedtext.replace(/{variant}/gm, disabledClass);
                      }
                      const sanatizedHtml = DOMPurify.sanitize(formatedtext);
                      /* eslint-disable react/no-danger */
                      return <li key={`${lst?.id || index}`} dangerouslySetInnerHTML={{ __html: sanatizedHtml }} />;
                    })}
                  </S.Subtext>
                </div>
              );
            })}
          </S.Maintasks>
        </S.LeftSection>
        <ImgComponent src={src} />

        <S.RightSection>
          <S.SectionHeading>
            <q>How to</q> videos
          </S.SectionHeading>
          <S.Maintasks className="list">
            {tasks.map((task) => {
              return (
                <div key={task?.heading || ""}>
                  <S.Taskheading>{task?.heading || ""}</S.Taskheading>

                  <HelpVideosList
                    handleBtnOnClick={handleBtnOnClick}
                    contentsData={task.contentsData || null}
                    className="contentList"
                  />
                </div>
              );
            })}
          </S.Maintasks>
        </S.RightSection>
      </S.Flex>
      {isModalOpen &&
        modals.customModal("HelpButton_page", isModalOpen, setIsModalOpen, <HtmlHelpViewer fileUrl={path} />, title)}
    </S.PageWrapper>
  );
};
