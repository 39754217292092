import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./installation-query-key-factory";

type InstallationProperty = "projectSetting";

interface IInstallationStatusProps {
  installationId: string | null;
  property: InstallationProperty;
  value: any;
}

/**
 * Upload the given file as the image for the CD
 *
 * @param param0 upload file data
 * @returns
 */
export const setInstallationProperty = ({ installationId, property, value }: IInstallationStatusProps) => {
  if (installationId === null) {
    return Promise.reject(new Error("Invalid installationId"));
  }
  switch (property) {
    case "projectSetting": {
      return Brm.projectSettingApi.setProjectSettingValue(value.id, { body: value.value });
    }
    default:
      return Promise.reject(new Error("Unsupported property"));
  }
};

type MutationFunction = typeof setInstallationProperty;
interface ISetStatusProps {
  options?: MutationOption<MutationFunction>;
}

/**
 * Upload the given formdata to to the upload service.
 *
 * @returns react-query Mutation.
 */
export const useSetInstallationProperty = ({ options }: ISetStatusProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: () => {
      queryClient.invalidateQueries(KeyFactory.installationKeys.all);
    },
    ...options,
    mutationFn: setInstallationProperty,
  });
};
