export const systemAssetKeys = {
  all: ["systemAssets"] as const,
  detail: (systemAssetId: string) => [...systemAssetKeys.all, systemAssetId] as const,
  project: (projectId: string) => [...systemAssetKeys.all, projectId] as const,
  element: (elementId: string | null, elementName: string | null, targetSystemAssetsData: any) =>
    [...systemAssetKeys.all, "elementSystemAssets", elementId, elementName, targetSystemAssetsData] as const,
};

export const systemAssetTypeKeys = {
  all: ["systemAssetType"] as const,
  detail: (systemAssetTypeId: string) => [...systemAssetTypeKeys.all, systemAssetTypeId] as const,
  project: (projectId: string) => [...systemAssetTypeKeys.all, projectId] as const,
};

export const systemAssetCategoryKeys = {
  all: ["systemAssetCategory"] as const,
  project: (projectId: string) => [...systemAssetCategoryKeys.all, projectId] as const,
};
