import * as React from "react";

export const useIsFirstRender = () => {
  const isFirst = React.useRef(true);

  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }
  return isFirst.current;
};
