/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { RiskFilterButton } from "./RiskFilterButton";
import { RiskFilterOptions } from "./RiskFilterOptions";
import * as S from "./RiskFilters.styles";

interface IRiskFilters {
  levels: string[];
  levelValue: string[];
  onLevelChange: () => void;
  percentageValue: number;
  onPercentageChange: () => void;
  maxPercent: number;
}

export const RiskFilters = ({ ...rest }: IRiskFilters) => {
  const [isFilterVisible, setIsFilterVisible] = React.useState(false);

  return (
    <S.RowFilterContainer>
      <RiskFilterButton isFilterVisible={isFilterVisible} setIsFilterVisible={setIsFilterVisible} />
      {isFilterVisible ? <RiskFilterOptions {...rest} /> : null}
    </S.RowFilterContainer>
  );
};
