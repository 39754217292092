export const SIZES = ["Bytes", "KB", "MB", "GB", "TB"];

export const IMPORT_AS = {
  system: "Import as System",
  target: "Import as Targets",
};

export const IMPORT_STATUS = {
  pass: "PASS",
  fail: "FAIL",
  pending: "PENDING",
};
