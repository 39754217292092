import * as React from "react";
import { COMMON } from "constants/brm";

interface IBooleanDropdownProps {
  label: { id: string; name: string };
  select: { id: string; name: string };
  defaultValue?: boolean;
  onChange?: (v: React.ChangeEvent<HTMLSelectElement>) => void;
}

const BooleanDropdown = ({ label, select, onChange, defaultValue }: IBooleanDropdownProps) => {
  return (
    <>
      <label id={label.id}>{label.name}</label>
      <select name={select.name} id={select.id} data-testid="select" onChange={onChange || undefined}>
        <option data-testid="select-option" value="true" selected={defaultValue !== undefined && defaultValue}>
          {COMMON.yes}
        </option>
        <option data-testid="select-option" value="false" selected={defaultValue !== undefined && !defaultValue}>
          {COMMON.no}
        </option>
      </select>
    </>
  );
};

export default BooleanDropdown;
