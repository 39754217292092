import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { STATUS } from "constants/brm";
import * as KeyFactory from "./project-query-key-factory";
import { useUserProjects } from "../user";

/**
 *
 * Gets project with cntrl catalogs
 * @param {string} projectId - uuid of project
 */
export const getProjectsWithCntrlCatalog = async ({ projects }: { projects: any }) => {
  // To remove this, we could request that the backend provide KB in projectShortDto
  // This would make the KB project table rendering much faster

  if (projects && projects.length > 0) {
    const catalogs = await Promise.all(
      projects
        .filter((e: any) => e.status === STATUS.active)
        .map(async (proj: { id: string; control: {}; baseline: {} }) => {
          const project = proj;
          project.control = await Brm.projectApi.getProjectCatalog(proj.id);
          project.baseline = await Brm.projectApi.getProjectBaseline(proj.id);
          return project;
        })
    );

    return catalogs;
  }

  return [];
};

interface IProjectsWithCntrlCatalog {
  userId: string | null;
  options?: QueryOption<typeof getProjectsWithCntrlCatalog>;
}

/**
 * Custom hook for getting projects with catalog
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query options
 * @returns getprojectsWithCntrlCatalog
 */
export const useProjectsWithCntrlCatalog = ({ userId, options = {} }: IProjectsWithCntrlCatalog) => {
  const userProjectsQuery = useUserProjects({ userId, options: { enabled: !!userId } });

  const projectsWithCntrlCatalogQuery = ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.cntrlCatalog(userProjectsQuery),
    queryFn: () => getProjectsWithCntrlCatalog({ projects: userProjectsQuery.data }),
  });

  if (userProjectsQuery.isError) {
    return userProjectsQuery;
  }

  return projectsWithCntrlCatalogQuery;
};
