import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";
import * as VariantKeyFactory from "../../mitigation/variant/variant-query-key-factory";

export interface ISetNodeCategory {
  nodeId: string;
  category: string;
}

/**
 * Sets the Category of a node
 *
 * @param {string} nodeId - uuid of the node
 * @param {string} category - category value of the node
 */
export const setNodeCategory = ({ nodeId, category }: ISetNodeCategory) => {
  return Brm.nodeApi.setNodeCategory(nodeId, { body: category });
};

export interface IUseSetNodeCategory {
  options?: MutationOption<typeof setNodeCategory>;
}

/**
 * Custom hook to change category value of an existing node
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetNodeCategory = ({ options }: IUseSetNodeCategory = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.nodeKeys.all);
      queryClient.invalidateQueries(VariantKeyFactory.variantsKeys.all);
    },
    ...options,
    mutationFn: setNodeCategory,
  });
};
