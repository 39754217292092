import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { kbApi, organizationApi } from "services/brm";

const getKbFullDetails = async (kbId) => {
  let kbToDisplay = {};
  const kbData = await kbApi.getKBByID(kbId);
  kbToDisplay = { ...kbData };
  const orgNameData = await organizationApi.getOrganizationName(kbData.organization);
  kbToDisplay.organizationId = kbData.organization;
  kbToDisplay.organization = orgNameData.result;
  return kbToDisplay;
};

export const useKbById = (kbId, setSelectedElement) => {
  return useQuery(["getKbById", kbId], () => getKbFullDetails(kbId), {
    enabled: !!kbId,
    cacheTime: 0,
    onSuccess: (data) => {
      setSelectedElement(data);
    },
  });
};

export const useUpdateKBTTL = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ kbId, kbTtl }) =>
      kbApi.setKBTimeToLiveWithHttpInfo(kbId, {
        body: kbTtl,
      }),
    {
      onMutate: async ({ kbId, kbTtl }) => {
        await queryClient.cancelQueries("getKbById");
        const previousValues = queryClient.getQueryData("getKbById");

        queryClient.setQueryData("getKbById", (old) =>
          old.map((data) => {
            if (kbId === data.id) {
              return {
                ...data,
                ttl: kbTtl,
              };
            }
            return data;
          })
        );
        return previousValues;
      },
      onError: (previousValues) => queryClient.setQueryData("getKbById", previousValues),
      onSuccess: () => {
        queryClient.invalidateQueries(["getKbById"]);
      },
    }
  );
};
