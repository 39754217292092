import Title from "components/elements/StyledTitle";

import ContentLayout from "brm/layout/Content/ContentLayout";

import ElementCategoryTable from "./tables/ElementCategoryTable";

function ElementCategory() {
  const elementCatHeader = <Title id="ElementCategory_title">Element Categories</Title>;

  const elementCatMain = <ElementCategoryTable setSelectedRows={() => {}} />;
  return <ContentLayout pageId="ElementCategory_page" header={elementCatHeader} main={elementCatMain} />;
}

export default ElementCategory;
