import { ItemEventArgs } from "yfiles";

export default class DiagramBuilderItemEventArgs extends ItemEventArgs {
  /**
   * Creates a new instance of the {@link DiagramGraphBuilderItemEventArgs} class with the given graph, item, and business object.
   * @param {!IGraph} graph The graph that can be used to modify <code>item</code>.
   * @param {!*} item The item created from <code>itemData</code>.
   * @param {!*} businessObject The object <code>item</code> was created from.
   */
  constructor(graph, item, businessObject) {
    super(item);
    // Gets the graph that can be used to modify the {@link ItemEventArgs#item}.
    this.graph = graph;
    // Gets the object the {@link ItemEventArgs#item} has been created from.
    this.businessObject = businessObject;
  }
}
