import styled from "styled-components/macro";

const ToolbarContainer = styled.div`
  text-align: left;
  background-color: #fafbfc;

  & > * {
    vertical-align: middle;
  }

  & button {
    line-height: normal;
    outline: none;
    border: none;
    height: 32px;
  }

  /* & button:hover,
  & > label:hover {
    background-color: #dedede;
  } */

  & button:active,
  & > label:active {
    background-color: #b2b2b2;
  }

  & button:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: transparent;
  }
  /*
  & button {
    line-height: normal;
    height: 24px;
  }

  & button, .foo > label {
    display: inline-block;
    outline: none;
    border: none;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: transparent;
    height: 32px;
    width: 32px;
    line-height: 32px;
    box-sizing: border-box;
    padding: 0;
    cursor: pointer;
    color: #000000;
  }

  & button:hover,
  & > label:hover {
    background-color: #dedede;
  }

  & button:active,
  & > label:active {
    background-color: #b2b2b2;
  }

  & button:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: transparent;
  } */
`;

export default ToolbarContainer;
