// import { Dropdown } from "react-bootstrap";
import * as S from "./ContextMenu.styles";
import { ContextMenuProps } from "./types";

export const ContextMenu = (p: ContextMenuProps) => {
  const { show, items, hideMenu } = p;

  const runAction = (action: () => void): void => {
    action();
    // close the contextmenu afterwards
    hideMenu();
  };

  let contextMenuItems: JSX.Element[] = [];
  if (show) {
    contextMenuItems = items.map((item, i) => {
      if (item.title === "separator") {
        // eslint-disable-next-line react/no-array-index-key
        return <S.ContextDivider key={i} />;
      }

      return (
        // eslint-disable-next-line react/no-array-index-key
        <S.ContextItem onClick={() => runAction(item.action)} key={i}>
          {item.Icon} {item.title}
        </S.ContextItem>
      );
    });
  }

  return (
    // ? I don't know why I have to pass the props into this component... something isn't setup right
    // ? with styled-components and typescript..
    // eslint-disable-next-line react/jsx-props-no-spreading
    <S.ContextMenuContainer {...p} /* x={x} y={y} items={items} show={show} hideMenu={hideMenu} */>
      {/* <Dropdown.Menu show>{contextMenuItems}</Dropdown.Menu> */}
      {contextMenuItems}
    </S.ContextMenuContainer>
  );
};
