export type ShortForm = "TS" | "S" | "FOUO" | "U" | "-";
type Names = "Top Secret" | "Secret" | "For official use only" | "Unclassified" | "Not Classified";

/**
 * Enum for classification,
 */
export class Classification {
  static values: Classification[] = [];

  static TOP_SECRET = new Classification("TS", "Top Secret", "#FF6501", 4);

  static SECRET = new Classification("S", "Secret", "#CC0001", 3);

  static FOUO = new Classification("FOUO", "For official use only", "#01009A", 2);

  static UNCLASSIFIED = new Classification("U", "Unclassified", "#009900", 1);

  static NOT_CLASSIFIED = new Classification("-", "Not Classified", "#969696", 0);

  static fromShortForm(letter: ShortForm) {
    if (letter === null) {
      return Classification.NOT_CLASSIFIED;
    }
    const result = this.values.find((c) => c.letter === letter);
    if (result) {
      return result;
    }
    throw new RangeError(`No instance of ${this.constructor.name} exists with the short form ${letter}.`);
  }

  static fromName(name: Names) {
    if (name === null) {
      return Classification.NOT_CLASSIFIED;
    }
    const result = this.values.find((c) => c.name === name);
    if (result) {
      return result;
    }
    throw new RangeError(`No instance of ${this.constructor.name} exists with the name ${name}.`);
  }

  static fromValue(value: number) {
    const result = this.values.find((c) => c.value === value);
    if (result) {
      return result;
    }
    throw new RangeError(`No instance of ${this.constructor.name} exists with the value ${value}.`);
  }

  letter: ShortForm;

  name: string;

  color: string;

  value: number;

  constructor(letter: ShortForm, name: Names, color: string, value: number) {
    this.letter = letter;
    this.name = name;
    this.color = color;
    this.value = value;
    Classification.values.push(this);
  }
}
