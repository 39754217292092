import * as ReactQuery from "@tanstack/react-query";
import { MutationOption, queryClient } from "libs/react-query";
import * as Brm from "services/brm";
import * as KeyFactory from "./organization-query-key-factory";

interface IOrganizationNameProps {
  organizationId: string | null;
  newName: string;
}

/**
 * Upload the given file as the image for the CD
 *
 * @param param0 upload file data
 * @returns
 */
export const setOrganizationName = ({ organizationId, newName }: IOrganizationNameProps) => {
  return organizationId === null
    ? Promise.reject(new Error("Invalid organizationId id"))
    : Brm.organizationApi.setOrganizationName(organizationId, { body: newName });
};

interface ISetNameProps {
  options?: MutationOption<typeof setOrganizationName>;
}

/**
 * Upload the given formdata to to the upload service.
 *
 * @returns react-query Mutation.
 */
export const useSetOrganizationName = ({ options }: ISetNameProps = {}) => {
  return ReactQuery.useMutation({
    onSettled: (data, err, variables) => {
      queryClient.invalidateQueries(KeyFactory.organizationKeys.all);
      queryClient.invalidateQueries(KeyFactory.organizationKeys.org(variables.organizationId));
    },
    ...options,
    mutationFn: setOrganizationName,
  });
};
