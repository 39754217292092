/**
 * react-select + allOption
 *
 * author: alex.escalante@gmail.com, @alex_escalante
 *
 * Gives users of the fine component react-select the ability to select
 * all options with a single click.
 *
 * We're basically wrapping react-select but using a special option,
 * the "allOption". This option is exclusive of any other option
 * and it never hits the user's onChange() handler because it gets
 * replaced with the whole options list.
 *
 * There are many ways to implement this feature, but this one doesn't
 * affect the rest of your app, and it doesn't take any UI space.
 */

import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { menuListStyle } from "components/elements/ReactSelectCustomStyles";
// specify props.allowSelectAll = true to enable
const Select = ({ allowSelectAll, allOption, options, onChange, elementId, ...rest }) => {
  if (allowSelectAll) {
    return (
      <ReactSelect
        options={[allOption, ...options]}
        onChange={(selected) => {
          if (selected !== null && selected.length > 0 && selected[selected.length - 1].value === allOption.value) {
            return onChange(options);
          }
          return onChange(selected);
        }}
        id={`${elementId}_dropdown`}
        classNamePrefix={`${elementId}_dropdownList`}
        styles={menuListStyle}
        menuPortalTarget={document.body}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ReactSelect {...rest} />;
};

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  elementId: PropTypes.string,
};

Select.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default Select;
