import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./element-query-key-factory";
import { DetailTableType } from "../../../types/DetailTableType";

export interface IDeleteElementActivitiesProps {
  elementId: string;
  elementName: string;
  activityId: string;
}

interface IactivityMap {
  [index: string]: (id?: string) => any;
}

/**
 * Sets the parent of a node
 *
 * @param {string} nodeId - uuid of the node
 * @param {string} parentId - uuid of new parent or null
 */
export const deleteElementActivities = ({ elementId, elementName, activityId }: IDeleteElementActivitiesProps) => {
  const activityMap: IactivityMap = {
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.removeCapabilityActivity(id, activityId),
    [DetailTableType.PERSONS.key]: (id) => Brm.personApi.removePersonActivity(id, activityId),
    [DetailTableType.DATAFLOWS.key]: (id) => Brm.dataflowApi.removeDataflowActivity(id, activityId),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.removeNodeActivity(id, activityId),
    [DetailTableType.ACCESS_POINTS.key]: (id) => Brm.accessPointApi.removeAccessPointActivity(id, activityId),
    [DetailTableType.ATTACK_VECTORS.key]: (id) => Brm.attackVectorApi.removeAttackVectorActivity(id, activityId),
    [DetailTableType.ENTRY_POINTS.key]: (id) => Brm.entryPointApi.removeEntryPointActivity(id, activityId),
    [DetailTableType.RESOURCES.key]: (id) => Brm.resourceApi.removeResourceActivity(id, activityId),
  };

  if (activityMap[elementName]) {
    return activityMap[elementName](elementId);
  }

  return [];
};

export interface IUseDeleteElementActivities {
  options?: MutationOption<typeof deleteElementActivities>;
}

/**
 * Custom hook to delete activity from an element
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useDeleteElementActivities = ({ options = {} }: IUseDeleteElementActivities = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.activityKeys.all);
    },
    ...options,
    mutationFn: deleteElementActivities,
  });
};
