import { GlobalControlApi } from "features/brm";

interface IUseMergeBaselineApi {
  catalogId: string;
  currentBaselineId: string;
}

export const useMergeBaselineFormApi = ({ catalogId, currentBaselineId }: IUseMergeBaselineApi) => {
  const projectCatalogId = catalogId || "";
  const { data: catalogBaselineOptions } = GlobalControlApi.useCatalogBaselines({
    catalogId: projectCatalogId,
    options: {
      enabled: projectCatalogId !== "",
      select: (data) =>
        data.filter((b: any) => b.id !== currentBaselineId).map((d: any) => ({ value: d.id, label: d.name })),
    },
  });

  return { catalogBaselineOptions };
};
