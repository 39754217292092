import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./impact-query-key-factory";

interface IGetHarmCategories {
  projectId: string;
}

export const getHarmCategories = ({ projectId }: IGetHarmCategories) => {
  return Brm.harmCategoryApi.findHarmCategory(projectId);
};

interface IUseHarms {
  projectId: string;
  options?: QueryOption<typeof getHarmCategories>;
}

export const useHarmCategories = ({ projectId, options }: IUseHarms) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.harmKeys.categories(projectId),
    queryFn: () => getHarmCategories({ projectId }),
  });
};
