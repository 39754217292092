import { useQuery } from "@tanstack/react-query";
// Services
import { nodeApi, busApi, linkApi, exchangeApi } from "services/brm/system-service";
import { systemAssetApi } from "services/brm";
import { BrmError, DetailTableType } from "features/brm";

async function getDepAssets(elementId, elementName) {
  switch (elementName) {
    case DetailTableType.BUSES.key: {
      return busApi.getBusAllDependentAsset(elementId);
    }
    case DetailTableType.LINKS.key: {
      return linkApi.getLinkAllDependentAsset(elementId);
    }
    case DetailTableType.NODES.key: {
      return nodeApi.getNodeAllDependentAsset(elementId);
    }
    case DetailTableType.EXCHANGES.key: {
      return exchangeApi.getExchangeAllDependentAsset(elementId);
    }
    case DetailTableType.SYSTEM_ASSETS.key: {
      return systemAssetApi.getSystemAssetAllDependentAsset(elementId);
    }
    default: {
      break;
    }
  }
  throw new BrmError(`Unsupported element type: ${elementName}`);
}

const useGetElementDependentAsset = (elementId, elementName) => {
  return useQuery(["getElementDependentAsset", elementId, elementName], () => getDepAssets(elementId, elementName), {
    enabled: !!elementId,
    cacheTime: 0,
  });
};

export default useGetElementDependentAsset;
