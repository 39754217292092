import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./dataflow-query-key-factory";

/**
 * Returns a list of dataflows
 *
 * @param {string} projectId - uuid of project
 * @returns a dataflow
 */
export const getDataflows = ({ projectId }: { projectId: string }) => {
  return Brm.dataflowApi.findDataFlow(projectId);
};

interface IUseDataflows {
  projectId: string;
  options?: QueryOption<typeof getDataflows>;
}

/**
 * Return a list of dataflows.
 *
 * @param {string} projectId - uuid of project
 * @param {object} config - react-query configuration object
 * @returns react-query-data object for dataflows
 */
export const useDataflows = ({ projectId, options }: IUseDataflows) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.dataflowKeys.project(projectId),
    queryFn: () => getDataflows({ projectId }),
  });
};
