import { BrmError } from "features/brm";
import { queryClient } from "libs/react-query";

export default async function multiSelectDelete(selectedRows, getDeleteRequest, queryKey) {
  if (selectedRows.length > 0) {
    try {
      await Promise.all(
        selectedRows.map((row) => {
          return getDeleteRequest(row.id || row.tag);
        })
      );
      queryClient.invalidateQueries(queryKey);
    } catch (err) {
      throw new BrmError(`Error occured while deleting ${err}`);
    }
  }
}
