import * as React from "react";
import Select from "react-select";
import { calibrationStyle } from "components/elements/ReactSelectCustomStyles";
import Form from "react-bootstrap/Form";
import * as S from "./RiskFilters.styles";

interface IRiskFilterOptions {
  levels: string[];
  levelValue: string[];
  onLevelChange: () => void;
  percentageValue: number;
  onPercentageChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  maxPercent: number;
}

export const RiskFilterOptions = ({
  levels,
  levelValue,
  onLevelChange,
  percentageValue,
  onPercentageChange,
  maxPercent,
}: IRiskFilterOptions) => {
  const levelOptions = levels.map((l) => ({ label: l, value: l })) as any;
  // const [percentage, setPercentage] = React.useState(0);

  return (
    <S.RowContainer>
      <S.SelectDiv>
        <S.SelectLabel>
          Risk Level
          <Select
            isMulti
            options={levelOptions}
            defaultValue={levelValue}
            value={levelValue}
            onChange={onLevelChange}
            id="riskLevel"
            styles={calibrationStyle}
            placeholder="Select Risk Level"
          />
        </S.SelectLabel>
      </S.SelectDiv>
      {/* <S.SelectDiv> */}
      <div>
        <S.SelectLabel htmlFor="percentage">
          Risk Percentage Threshold
          {/* <Select
            options={[]}
            defaultValue={[]}
            onChange={onPercentageChange}
            id="percentage"
            styles={calibrationStyle}
          /> */}
          {/* <input id="percentage" type="number" min="0" max="100" onChange={onPercentageChange} />
        <input id="percentage" type="number" min="0" max="100" onChange={onPercentageChange} /> */}
          <S.RangeContainer>
            <span>0</span>
            <Form.Range
              min="0"
              max={maxPercent}
              id="percentage"
              // defaultValue={percentage}
              value={percentageValue || 0}
              step="0.25"
              onChange={(e) => {
                // setPercentage(parseFloat(e.target.value));
                onPercentageChange(e);
              }}
            />
            <span>{maxPercent}</span>
            <div style={{ maxWidth: "80px" }}>
              {/* <Form.Control value={`${percentage} %`} disabled /> */}
              <Form.Control value={`${percentageValue || 0} %`} disabled />
            </div>
          </S.RangeContainer>
        </S.SelectLabel>
      </div>
      {/* </S.SelectDiv> */}
    </S.RowContainer>
  );
};
