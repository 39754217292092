import PropTypes from "prop-types";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "css/brm-react-tabs.css";
import ThreatGroupTable from "brm/tables/details/ThreatGroupTable";
import { DetailTableType } from "features/brm";

const ThreatTierDetails = ({ selectedElement }) => {
  const elementNameInUrl = DetailTableType.THREAT_TIERS.key;

  return (
    <Tabs>
      <TabList>
        <Tab>Threat Groups</Tab>
      </TabList>

      <TabPanel>
        <ThreatGroupTable elementNameInUrl={elementNameInUrl} selectedElement={selectedElement} />
      </TabPanel>
    </Tabs>
  );
};

ThreatTierDetails.propTypes = {
  selectedElement: PropTypes.any,
};

export default ThreatTierDetails;
