// import * as Recoil from "recoil";
import * as React from "react";
import PropTypes from "prop-types";
// import * as AdminAtom from "atoms/atoms-admin";
import { TYPE } from "constants/brm";
import { RiskApi, SystemApi, useVariant, useProject, useRoles } from "features/brm";
import { FilterSelect } from "../FilterSelect";
import * as AgQuery from "../../api";
import * as AgConstants from "../../constants";

// import { FaEyeSlash } from "react-icons/fa";
// import { ThemeContext } from "styled-components";
// import { ToolbarButton } from "brm/diagrams/Toolbar";
import * as S from "./FilterToolbar.styles";

const allOption = {
  label: "Select all",
  value: "*",
};
const deselectAllOption = {
  label: "Delect all",
  value: "-",
};

const FilterToolbar = ({ nodeFilterState, onNodeFilterChange /* , onToggleOrphans, showOrphans */ }) => {
  const { isRiskAnalyst } = useRoles();
  const [currentProject, { isActive }] = useProject();
  const { currentVariant } = useVariant();
  const [selectedAssetCats, setSelectedAssetCats] = React.useState([]);
  const [selectedAssets, setSelectedAssets] = React.useState([]);
  const [selectedRiskCats, setSelectedRiskCats] = React.useState([]);
  const [selectedAttackTactics, setSelectedAttackTactics] = React.useState([]);
  const [selectedTrevCats, setSelectedTrevCats] = React.useState([]);
  const [selectedHarmCats, setSelectedHarmCats] = React.useState([]);
  // const [showOrphans, setShowOrphans] = React.useState(true);

  const variantId = currentVariant?.id || "";
  const projectId = currentProject?.id || "";

  const { data: assetCategoryOptions } = SystemApi.useAssetCategories({
    projectId,
    options: {
      select: React.useCallback((data) => data.map((ac) => ({ value: ac, label: ac })), []),
      enabled: isActive && isRiskAnalyst,
    },
  });

  const { data: riskCategoryOptions } = RiskApi.useRiskCategories({
    projectId,
    options: {
      select: React.useCallback((data) => data.map((riskCat) => ({ value: riskCat, label: riskCat })), []),
      enabled: isActive && isRiskAnalyst,
    },
  });

  const { data: attackTacticOptions } = RiskApi.useAttackTactics({
    projectId,
    options: {
      select: React.useCallback(
        (data) => data.map((attackTactic) => ({ value: attackTactic, label: attackTactic })),
        []
      ),
      enabled: isActive && isRiskAnalyst,
    },
  });

  // const { data: threatEventCategoryOptions } = AgQuery.useTrevCategoriesSelect(projectId);
  const { data: threatEventCategoryOptions } = RiskApi.useThreatEventCategories({
    projectId,
    options: {
      select: React.useCallback((data) => data.map((trevCat) => ({ value: trevCat, label: trevCat })), []),
      enabled: isActive && isRiskAnalyst,
    },
  });

  const { data: harmCategoryOptions } = RiskApi.useHarmCategories({
    projectId,
    options: {
      select: React.useCallback((data) => data.map((harmCat) => ({ value: harmCat, label: harmCat })), []),
      enabled: isActive && isRiskAnalyst,
    },
  });

  // const { data: assetOptions } = AgQuery.useAssetsByCategorySelect(projectId);
  const { data: assetOptions } = AgQuery.useAssetsByCategory(projectId, variantId, {
    select: React.useCallback((data) => data.map((as) => ({ value: as.title, label: as.title })), []),
    enabled: isActive && isRiskAnalyst,
  });

  const targetOptions = React.useMemo(() => {
    return [
      { value: TYPE.node, label: "Nodes" },
      { value: TYPE.exchange, label: "Exchanges" },
    ];
  }, []);
  const [selectedTargets, setSelectedTargets] = React.useState(targetOptions);

  // const theme = React.useContext(ThemeContext);

  // const {
  //   colors: { buttonPrimary: buttonFill },
  //   // TODO the standard sizes from the theme look terrible... might have to revisit for now override with smaller icons
  //   // sizes: { standaloneIconWidth: iconWidth, standaloneIconWidth: iconHeight },
  // } = theme;
  // const iconWidth = "16px";
  // const iconHeight = "16px";
  // const toggleOrphansIcon = <FaEyeSlash fill={buttonFill} width={iconWidth} height={iconHeight} />;

  /**
   *  Filters the given options using the list of given types
   *
   * @param {Array} types an array of type names
   * @param {*} options an array of options
   * @returns array of options that matches the list of types
   */
  const initOptionState = (types, options) => {
    return options.filter((opt) => types.find((t) => t === opt.value));
  };

  React.useEffect(() => {
    if (
      assetCategoryOptions &&
      riskCategoryOptions &&
      threatEventCategoryOptions &&
      harmCategoryOptions &&
      assetOptions
    ) {
      setSelectedAssetCats(initOptionState(nodeFilterState.assetCatTypes, assetCategoryOptions));
      setSelectedRiskCats(initOptionState(nodeFilterState.riskCatTypes, riskCategoryOptions));
      setSelectedTrevCats(initOptionState(nodeFilterState.trevCatTypes, threatEventCategoryOptions));
      setSelectedHarmCats(initOptionState(nodeFilterState.harmCatTypes, harmCategoryOptions));
      setSelectedAssets(initOptionState(nodeFilterState.assetTypes, assetOptions));
      setSelectedAttackTactics(initOptionState(nodeFilterState.attackTacticTypes, attackTacticOptions));
      setSelectedTargets(initOptionState(nodeFilterState.targetTypes, targetOptions));
    }
  }, [
    assetCategoryOptions,
    riskCategoryOptions,
    threatEventCategoryOptions,
    harmCategoryOptions,
    assetOptions,
    nodeFilterState.assetCatTypes,
    nodeFilterState.riskCatTypes,
    nodeFilterState.trevCatTypes,
    nodeFilterState.harmCatTypes,
    nodeFilterState.assetTypes,
    nodeFilterState.attackTacticTypes,
    attackTacticOptions,
    nodeFilterState.targetTypes,
    targetOptions,
  ]);

  function handleFilterUpdate(selected, options, setSelected) {
    let updatedOptionsSelected = [];
    if (selected !== null && selected.length > 0 && selected[selected.length - 1].value === allOption.value) {
      const allSelected = [allOption, ...options];
      setSelected(allSelected);
      updatedOptionsSelected = options.map((item) => item.value);
    } else if (
      selected !== null &&
      selected.length > 0 &&
      selected[selected.length - 1].value === deselectAllOption.value
    ) {
      const allDeselected = [deselectAllOption];
      setSelected(allDeselected);
    } else {
      let updatedSelection = selected;
      if (selected.length <= options.length && selected.some((item) => item.value === allOption.value)) {
        // Remove all option selection if not all individual options have been checked
        updatedSelection = selected.filter((item) => item.value !== allOption.value);
      }
      if (selected.some((item) => item.value === deselectAllOption.value)) {
        // Remove deslect all option selection, if exists
        updatedSelection = updatedSelection.filter((item) => item.value !== deselectAllOption.value);
      }
      setSelected(updatedSelection);
      updatedOptionsSelected = selected.map((item) => item.value);
    }
    return updatedOptionsSelected;
  }

  /*
   * Filter change handler, which updated the node predicate reference and
   * the node predicate state with each of the latest reference values
   */
  function onChangeHandler(selected, type) {
    const newFilter = nodeFilterState.clone();
    switch (type) {
      case TYPE.assetCat:
        newFilter.assetCatTypes = handleFilterUpdate(selected, assetCategoryOptions, setSelectedAssetCats);
        break;
      case TYPE.asset:
        newFilter.assetTypes = handleFilterUpdate(selected, assetOptions, setSelectedAssets);
        break;
      case TYPE.risk:
        newFilter.riskCatTypes = handleFilterUpdate(selected, riskCategoryOptions, setSelectedRiskCats);
        break;
      case TYPE.node:
        newFilter.targetTypes = handleFilterUpdate(selected, targetOptions, setSelectedTargets);
        break;
      case TYPE.trev:
        newFilter.trevCatTypes = handleFilterUpdate(selected, threatEventCategoryOptions, setSelectedTrevCats);
        break;
      case TYPE.ue:
        newFilter.harmCatTypes = handleFilterUpdate(selected, harmCategoryOptions, setSelectedHarmCats);
        break;
      case TYPE.attack:
        newFilter.attackTacticTypes = handleFilterUpdate(selected, attackTacticOptions, setSelectedAttackTactics);
        break;
      default:
        throw new Error("Unknown type: ", type);
    }
    onNodeFilterChange(newFilter);
  }

  /*
   * Filter change handler, which updated the node predicate reference and
   * the node predicate state with each of the latest reference values
   */
  // const onOrhphanChangeHandler = (e) => {
  //   // setShowOrphans(!showOrphans);
  //   onToggleOrphans(!showOrphans);
  //   const newFilter = nodeFilterState.clone();
  //   newFilter.orphanCheck = !e.target.checked;
  //   onNodeFilterChange(newFilter);
  // };

  if (
    assetCategoryOptions &&
    riskCategoryOptions &&
    attackTacticOptions &&
    threatEventCategoryOptions &&
    assetOptions &&
    harmCategoryOptions
  ) {
    return (
      <S.Container>
        <FilterSelect
          filterTitle="Asset Categories"
          options={assetCategoryOptions}
          color={AgConstants.NODE_TYPE_STYLE_MAP[TYPE.assetCat].color}
          defaultValue={selectedAssetCats}
          onChange={(selected) => onChangeHandler(selected, TYPE.assetCat)}
          value={selectedAssetCats}
          type={TYPE.assetCat}
        />
        <FilterSelect
          filterTitle="Assets"
          options={assetOptions}
          color={AgConstants.NODE_TYPE_STYLE_MAP[TYPE.asset].color}
          defaultValue={selectedAssets}
          onChange={(selected) => onChangeHandler(selected, TYPE.asset)}
          value={selectedAssets}
          type={TYPE.asset}
        />
        <FilterSelect
          filterTitle="Risks"
          options={riskCategoryOptions}
          color={AgConstants.NODE_TYPE_STYLE_MAP[TYPE.risk].color}
          defaultValue={selectedRiskCats}
          onChange={(selected) => onChangeHandler(selected, TYPE.risk)}
          value={selectedRiskCats}
          type={TYPE.risk}
        />
        <FilterSelect
          filterTitle="Undesired Events"
          options={harmCategoryOptions}
          color={AgConstants.NODE_TYPE_STYLE_MAP[TYPE.ue].color}
          defaultValue={selectedHarmCats}
          onChange={(selected) => onChangeHandler(selected, TYPE.ue)}
          value={selectedHarmCats}
          type={TYPE.ue}
        />
        <FilterSelect
          filterTitle="Targets"
          options={targetOptions}
          color={AgConstants.NODE_TYPE_STYLE_MAP[TYPE.node].color}
          defaultValue={selectedTargets}
          onChange={(selected) => onChangeHandler(selected, TYPE.node)}
          value={selectedTargets}
          type={TYPE.node}
        />
        <FilterSelect
          filterTitle="Threat Events"
          options={threatEventCategoryOptions}
          color={AgConstants.NODE_TYPE_STYLE_MAP[TYPE.trev].color}
          defaultValue={selectedTrevCats}
          onChange={(selected) => onChangeHandler(selected, TYPE.trev)}
          value={selectedTrevCats}
          type={TYPE.trev}
        />
        <FilterSelect
          filterTitle="Attack Tactics"
          options={attackTacticOptions}
          color={AgConstants.NODE_TYPE_STYLE_MAP[TYPE.attack].color}
          onChange={(selected) => onChangeHandler(selected, TYPE.attack)}
          value={selectedAttackTactics}
          type={TYPE.attack}
        />
        {/* <ToolbarButton
          icon={toggleOrphansIcon}
          tooltip={showOrphans ? "Hide Orphans" : "Show Orphans"}
          onClick={onOrhphanChangeHandler}
          toggled={!showOrphans}
        /> */}
        {/* 
        <S.FilterLabel>
          Orphans:
          <input type="checkbox" onChange={onOrhphanChangeHandler} />
        </S.FilterLabel> */}
      </S.Container>
    );
  }
  return null;
};

FilterToolbar.propTypes = {
  nodeFilterState: PropTypes.shape({
    assetCatTypes: PropTypes.arrayOf(PropTypes.string),
    assetTypes: PropTypes.arrayOf(PropTypes.string),
    riskCatTypes: PropTypes.arrayOf(PropTypes.string),
    targetTypes: PropTypes.arrayOf(PropTypes.string),
    trevCatTypes: PropTypes.arrayOf(PropTypes.string),
    harmCatTypes: PropTypes.arrayOf(PropTypes.string),
    attackTacticTypes: PropTypes.arrayOf(PropTypes.string),
    oraphanCheck: PropTypes.bool,
    clone: PropTypes.func,
  }).isRequired,
  onNodeFilterChange: PropTypes.func.isRequired,
  // onToggleOrphans: PropTypes.func.isRequired,
  // showOrphans: PropTypes.bool,
};

export default FilterToolbar;
