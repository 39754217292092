import { useQuery } from "@tanstack/react-query";
// Services
import * as Brm from "services/brm";
import { trevKeys } from "brm/risk-model/trev/api";
import { BrmError, DetailTableType } from "features/brm";

/**
 * returns list of trevs
 *
 * @param {string} elementId - uuid of the selected Element
 * @param {string} elementName - type/category of the selected element
 * @returns list of all trevs
 */
export const getElementTrevs = async ({ elementId, elementName }) => {
  const trevMap = {
    [DetailTableType.MISSIONS.key]: (id) => Brm.missionApi.getMissionAllTrev(id),
    [DetailTableType.PERSONS.key]: (id) => Brm.personApi.getPersonAllTrev(id),
    [DetailTableType.CAPABILITIES.key]: (id) => Brm.capabilityApi.getCapabilityAllTrev(id),
    [DetailTableType.ACTIVITIES.key]: (id) => Brm.activityApi.getActivityAllTrev(id),
    [DetailTableType.DATATYPES.key]: (id) => Brm.dataTypeApi.getDataTypeAllTrev(id),
    [DetailTableType.DATAFLOWS.key]: (id) => Brm.dataflowApi.getDataFlowAllTrev(id),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeAllTrev(id),
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeAllTrev(id),
    [DetailTableType.SYSTEM_ASSETS.key]: (id) => Brm.systemAssetApi.getSystemAssetAllTrev(id),
    [DetailTableType.ASSETS.key]: (id) => Brm.assetApi.getAssetAllTrev(id),
    [DetailTableType.UNDESIRED_EVENTS.key]: (id) => Brm.undesiredEventApi.getUndesiredEventTrev(id),
    [DetailTableType.VULNERABILITIES.key]: (id) => Brm.vulnerabilityApi.getVulnerabilityTrev(id),
    [DetailTableType.ATTACKERS.key]: (id) => Brm.attackerApi.getAttackerAllTrev(id),
    [DetailTableType.ATTACKS.key]: (id) => Brm.attackApi.getAttackTrev(id),
    [DetailTableType.RESOURCES.key]: (id) => Brm.resourceApi.getResourceAllTrev(id),
  };

  if (trevMap[elementName]) {
    const data = await trevMap[elementName](elementId);
    return Array.isArray(data) ? data : [data];
  }

  throw new BrmError(`Unsupported element type :${elementName}`);
};

/**
 * custom-hook to retreive all trevs of any selected element
 *
 * @param {string} elementId - uuid of any selected element
 * @param {string} elementName - type/category of the selected element
 * @param {object} config - configuration of react-query
 * @returns react-query for getting trevs
 */
export const useElementTrevs = ({ elementId, elementName, config = {} }) => {
  return useQuery({
    ...config,
    queryKey: trevKeys.details(elementId, elementName),
    queryFn: () => getElementTrevs({ elementId, elementName }),
  });
};
