import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  background: #f7f7f7;
  margin: 1rem;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  border-top: 1px solid #ccc;
  padding-top: 5px;
`;

export const RadioContainer = styled.div`
  padding: 5px;
  margin-left: 20px;
`;

export const ExportFormatLabel = styled.div`
  font-weight: 550;
`;
