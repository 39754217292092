import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { SystemApi } from "features/brm";

/**
 * Sets the IsStored of an Datatype
 *
 * @param {string} dataTypeId - uuid of the dataType
 * @param {string} isStored - new isStored of the dataType
 */
export const setDataTypeIsStored = ({ dataTypeId, isStored }) => {
  return Brm.dataTypeApi.setDataTypeIsStored(dataTypeId, { body: isStored });
};

/**
 * Custom hook to change the IsStored of an existing datatype
 *
 * @param {object} config - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetDataTypeIsStored = ({ projectId, config = {} } = {}) => {
  const queryClient = ReactQuery.useQueryClient();

  return ReactQuery.useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(SystemApi.dataTypeKeys.project(projectId));
      queryClient.invalidateQueries(SystemApi.dataTypeKeys.dataType(variables.dataTypeId));
    },
    ...config,
    mutationFn: setDataTypeIsStored,
  });
};
