import styled from "styled-components/macro";

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const CenterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainGraphContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
`;

export const DetailsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const SideBarContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: row;
`;

export const GraphContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 1 1;
  flex-flow: column wrap;
`;

export const ToolbarContainer = styled.div`
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  top: 60px;
  left: 0;
  right: 0;
  height: 32px;
  line-height: 32px;
  padding: 0 0px;
  box-sizing: border-box;
  user-select: none;
  background-color: #e5e5e5;

  @media screen and (max-height: 500px) {
    top: 30px;
    height: 30px;
    line-height: 30px;
  }
`;
export const Drawer = styled.aside`
  height: 100%;
  width: 325px;
  float: right;
  z-index: 1;
  background: #e5e5e5;
  overflow-x: hidden; /* Disable horizontal scroll */
  top: 0;
  left: auto;
  right: 0;
`;
export const ComponentContainer = styled.div`
  height: 100%;
  flex: 1 1;

  .yfiles-canvascomponent {
    height: 100%;
    flex: 1 1;
  }
`;
