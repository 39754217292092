import * as ReactQuery from "@tanstack/react-query";
import { MutationOption } from "libs/react-query";
import * as Brm from "services/brm";
import * as SapOrgKeyFactory from "features/brm/api/admin/sapOrganization/sap-org-query-key-factory";
import * as SapBindingKeyFactory from "features/brm/api/admin/sapBinding/sap-binding-query-key-factory";

interface CreateSapOrgBindingProps {
  sapOrgBindingCreateDto: any;
}

/**
 * Create a sap org binding
 *
 * @returns
 */
export const createSapOrgBinding = ({ sapOrgBindingCreateDto }: CreateSapOrgBindingProps) => {
  return sapOrgBindingCreateDto === null
    ? Promise.reject(new Error("Invalid createbinding dto"))
    : Brm.sapOrgBindingApi.createSapOrgBinding({ sapOrgBindingCreateDto });
};

type MutateFunction = typeof createSapOrgBinding;
interface ICreateSapOrgBindingProps {
  options?: MutationOption<MutateFunction>;
}

/**
 * Create a sap org binding
 *
 * @returns react-query Mutation.
 */
export const useCreateSapOrgBinding = ({ options = {} }: ICreateSapOrgBindingProps = {}) => {
  const queryClient = ReactQuery.useQueryClient();

  return ReactQuery.useMutation({
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: SapOrgKeyFactory.sapOrganizationKeys.all });
      queryClient.invalidateQueries({ queryKey: SapBindingKeyFactory.sapBindingKeys.allOrg });
    },
    mutationFn: createSapOrgBinding,
  });
};
