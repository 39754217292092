import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./person-query-key-factory";

interface ISetPersonCategory {
  personId: string;
  category: string;
}

/**
 * Sets the category of a person
 *
 * @param {string} personId - uuid of the person
 * @param {string} category - category of the person
 */
export const setPersonCategory = ({ personId, category }: ISetPersonCategory) => {
  return Brm.personApi.setPersonCategory(personId, { body: category });
};

interface IUseSetPersonCategory {
  options?: MutationOption<typeof setPersonCategory>;
}

/**
 * Custom hook to change the category of an existing person
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetPersonCategory = ({ options }: IUseSetPersonCategory = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.personKeys.all);
    },
    ...options,
    mutationFn: setPersonCategory,
  });
};
