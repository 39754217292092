import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./user-query-key-factory";

/**
 * Gets role of a user
 *
 * @param {string} userId - uuid of the user
 */
export const getUserRole = ({ userId }: { userId: string | null }) => {
  return Brm.userApi.getUserRole(userId);
};

interface IGetUserRoleProps {
  userId: string | null;
  options?: QueryOption<typeof getUserRole>;
}

/**
 * Custom hook for getting a users role
 *
 * @param {string} userId - uuid of user
 * @param {object} options - react-query configuration
 * @returns
 */
export const useUserRole = ({ userId, options = {} }: IGetUserRoleProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.userKeys.role(userId),
    queryFn: () => getUserRole({ userId }),
  });
};
