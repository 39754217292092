import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  background: #f7f7f7;
  margin: 1rem;
  height: 650px;
  width: 800px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  border-top: 1px solid #ccc;
  padding-top: 5px;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 95%;
  border: 1px;
`;

export const ControlTypeStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px;
`;
