import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
// Components
import { BrmSingleElementTable } from "brm/tables/BrmTables";
import AttackersDetails from "brm/threat-model/details/AttackerDetails";
import {
  DetailsContainer,
  DetailsContainerSingleTable,
  DetailsContainerParent,
} from "features/brm/components/elements/FullDetail/ElementFullDetails.styles";
import ShowHideButton from "components/elements/ShowHideButton";
// Styles
import Title from "components/elements/StyledTitle";
// Utils
import {
  createColumnActions,
  createColumnCategoryMapped,
  createColumnMapped,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";
// Services
import * as AttackerApi from "brm/threat-model/api";

// import { SystemApi, ThreatApi } from "features/brm";
// State
import { projectIdState } from "atoms/atoms-admin";
import { useRecoilValue } from "recoil";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import ButtonCell from "components/EditComponents/ButtonCell";
import { useQueryClient } from "@tanstack/react-query";
import { /* COMMON, */ TYPE } from "constants/brm";
// Hooks
// import { useRoles } from "hooks/useRoles";
import { RoutePath } from "routes/route-paths";
import { LIKELIHOOD_MAP } from "brm/threat-model/constants/likelihood-mapping";

const MEANS = ["M1", "M2", "M3", "M4", "M5"];
const OPPORTUNITIES = ["O1", "O2", "O3", "O4", "O5"];
// const LIKELIHOOD = [COMMON.veryHigh, COMMON.high, COMMON.moderate, COMMON.low, COMMON.veryLow];
const listUrl = RoutePath.Attackers;

function AttackerFullDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const projectId = useRecoilValue(projectIdState);
  const localProjectId = useRef(projectId);
  const [selectedElement, setSelectedElement] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [selectedRowId, setSelectedRowId] = useState("");

  const { data, error } = AttackerApi.useAttacker({ id, setSelectedElement });

  const updatedMeans = useRef("");
  const updatedOpportunity = useRef("");
  const updatedCategory = useRef("");
  const updatedLikelihood = useRef("");
  const updatedGroup = useRef("");
  const updatedPerson = useRef("");
  const updatedSaType = useRef("");
  const [showSummaryTable, setShowSummaryTable] = useState(true);

  useEffect(() => {
    if (Array.isArray(data)) {
      // updatedOpportunity.current = data[0].availableOpportunity;
      // updatedMeans.current = data[0].availableMeans;
    }
  }, [data]);

  const { mutate: setAttackerCategory } = AttackerApi.useSetAttackerCategory({
    config: {
      onMutate: async ({ category }) => {
        await queryClient.cancelQueries(["attacker", id]);
        const previousValue = queryClient.getQueryData(["attacker", id]);

        queryClient.setQueryData(["attacker", id], (oldData) =>
          oldData.map((attacker) => {
            if (attacker.id === id) {
              return {
                ...attacker,
                category,
              };
            }
            return attacker;
          })
        );

        return previousValue;
      },
      onError: (previousValue) => {
        queryClient.setQueryData(["attacker", id], previousValue);
      },
      onSettled: () => {
        updatedCategory.current = "";
        queryClient.invalidateQueries(["attacker", id]);
      },
    },
  });
  const { mutate: setAttackerMeans } = AttackerApi.useSetAttackerMeans({
    config: {
      onMutate: async ({ means }) => {
        await queryClient.cancelQueries(["attacker", id]);
        const previousValue = queryClient.getQueryData(["attacker", id]);

        queryClient.setQueryData(["attacker", id], (oldData) =>
          oldData.map((attacker) => {
            if (attacker.id === id) {
              return {
                ...attacker,
                availableMeans: means,
              };
            }
            return attacker;
          })
        );

        return previousValue;
      },
      onError: (previousValue) => {
        queryClient.setQueryData(["attacker", id], previousValue);
      },
      onSettled: () => {
        updatedMeans.current = "";
        queryClient.invalidateQueries(["attacker", id]);
      },
    },
  });
  const { mutate: setAttackerOpportunity } = AttackerApi.useSetAttackerOpportunity({
    config: {
      onMutate: async ({ opportunity }) => {
        await queryClient.cancelQueries(["attacker", id]);
        const previousValue = queryClient.getQueryData(["attacker", id]);

        queryClient.setQueryData(["attacker", id], (oldData) =>
          oldData.map((attacker) => {
            if (attacker.id === id) {
              return {
                ...attacker,
                availableOpportunity: opportunity,
              };
            }
            return attacker;
          })
        );

        return previousValue;
      },
      onError: (previousValue) => {
        queryClient.setQueryData(["attacker", id], previousValue);
      },
      onSettled: () => {
        updatedOpportunity.current = "";
        queryClient.invalidateQueries(["attacker", id]);
      },
    },
  });
  const { mutate: setAttackerLikelihood } = AttackerApi.useSetAttackerLikelihood({
    config: {
      onMutate: async ({ likelihood }) => {
        await queryClient.cancelQueries(["attacker", id]);
        const previousValue = queryClient.getQueryData(["attacker", id]);

        queryClient.setQueryData(["attacker", id], (oldData) =>
          oldData.map((attacker) => {
            if (attacker.id === id) {
              return {
                ...attacker,
                likelihood,
              };
            }
            return attacker;
          })
        );

        return previousValue;
      },
      onError: (previousValue) => {
        queryClient.setQueryData(["attacker", id], previousValue);
      },
      onSettled: () => {
        updatedLikelihood.current = "";
        queryClient.invalidateQueries(["attacker", id]);
      },
    },
  });
  const { mutate: setAttackerGroup } = AttackerApi.useSetAttackerGroup({
    config: {
      onMutate: async ({ group }) => {
        await queryClient.cancelQueries(["attacker", id]);
        const previousValue = queryClient.getQueryData(["attacker", id]);

        queryClient.setQueryData(["attacker", id], (oldData) =>
          oldData.map((attacker) => {
            if (attacker.id === id) {
              return {
                ...attacker,
                group,
              };
            }
            return attacker;
          })
        );

        return previousValue;
      },
      onError: (previousValue) => {
        queryClient.setQueryData(["attacker", id], previousValue);
      },
      onSettled: () => {
        updatedGroup.current = "";
        queryClient.invalidateQueries(["attacker", id]);
      },
    },
  });
  const { mutate: setAttackerPerson } = AttackerApi.useSetAttackerPerson({
    config: {
      onMutate: async ({ person }) => {
        await queryClient.cancelQueries(["attacker", id]);
        const previousValue = queryClient.getQueryData(["attacker", id]);

        queryClient.setQueryData(["attacker", id], (oldData) =>
          oldData.map((attacker) => {
            if (attacker.id === id) {
              return {
                ...attacker,
                person,
              };
            }
            return attacker;
          })
        );

        return previousValue;
      },
      onError: (previousValue) => {
        queryClient.setQueryData(["attacker", id], previousValue);
      },
      onSettled: () => {
        updatedPerson.current = "";
        queryClient.invalidateQueries(["attacker", id]);
      },
    },
  });
  const { mutate: setAttackerSaType } = AttackerApi.useSetAttackerSaType({
    config: {
      onMutate: async ({ saType }) => {
        await queryClient.cancelQueries(["attacker", id]);
        const previousValue = queryClient.getQueryData(["attacker", id]);

        queryClient.setQueryData(["attacker", id], (oldData) =>
          oldData.map((attacker) => {
            if (attacker.id === id) {
              return {
                ...attacker,
                satype: saType,
              };
            }
            return attacker;
          })
        );

        return previousValue;
      },
      onError: (previousValue) => {
        queryClient.setQueryData(["attacker", id], previousValue);
      },
      onSettled: () => {
        updatedSaType.current = "";
        queryClient.invalidateQueries(["attacker", id]);
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      const attacker = cellProps.cell.row.original;
      return (
        <ButtonCell
          selectedRowId={id}
          elementId={attacker.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);
            if (updatedCategory.current !== "" && !!id) {
              setAttackerCategory({ id, category: updatedCategory.current });
            }
            if (updatedMeans.current && updatedMeans.current !== "" && !!id) {
              setAttackerMeans({ id, means: updatedMeans.current });
              setAttackerLikelihood({
                id,
                likelihood: LIKELIHOOD_MAP.get(
                  updatedOpportunity.current === "" ? attacker.availableOpportunity : updatedOpportunity.current
                ).get(updatedMeans.current),
              });
            }
            if (updatedOpportunity.current && updatedOpportunity.current !== "" && !!id) {
              setAttackerOpportunity({ id, opportunity: updatedOpportunity.current });
              setAttackerLikelihood({
                id,
                likelihood: LIKELIHOOD_MAP.get(updatedOpportunity.current).get(
                  updatedMeans.current === "" ? attacker.availableMeans : updatedMeans.current
                ),
              });
            }
            if (updatedLikelihood.current !== "" && !!id) {
              setAttackerLikelihood({ id, likelihood: updatedLikelihood.current });
            }
            if (updatedGroup.current !== "" && !!id) {
              setAttackerGroup({ id, group: updatedGroup.current });
              setAttackerPerson({ id, person: "" });
              setAttackerSaType({ id, saType: "" });
            }
            if (updatedPerson.current !== "" && !!id) {
              setAttackerPerson({ id, person: updatedPerson.current });
              setAttackerGroup({ id, group: "" });
              setAttackerSaType({ id, saType: "" });
            }
            if (updatedSaType.current !== "" && !!id) {
              setAttackerSaType({ id, saType: updatedSaType.current });
              setAttackerGroup({ id, group: "" });
              setAttackerPerson({ id, person: "" });
            }
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [
      editMode,
      id,
      setAttackerCategory,
      setAttackerGroup,
      setAttackerLikelihood,
      setAttackerMeans,
      setAttackerOpportunity,
      setAttackerPerson,
      setAttackerSaType,
    ]
  );

  const columns = useMemo(() => {
    // if (isRiskAnalyst) {
    //   return [
    //     createColumnCategoryMapped("category", selectedRowId, attackerCategoryList, editMode, updatedCategory),
    //     createColumnCategoryMapped("availableMeans", selectedRowId, MEANS, editMode, updatedMeans),
    //     createColumnCategoryMapped("availableOpportunity", selectedRowId, OPPORTUNITIES, editMode, updatedOpportunity),
    //     createColumnCategoryMapped("attackerLikelihood", selectedRowId, LIKELIHOOD, editMode, updatedLikelihood),
    //     createColumnMappedNoEdit("group"),
    //     createColumnMappedNoEdit("person"),
    //     createColumnMapped("satype", selectedRowId, saTypeList, editMode, updatedSaType),
    //   ];
    // }
    return [
      createColumnMappedNoEdit("category"),
      createColumnCategoryMapped("availableMeans", selectedRowId, MEANS, editMode, updatedMeans),
      createColumnCategoryMapped("availableOpportunity", selectedRowId, OPPORTUNITIES, editMode, updatedOpportunity),
      createColumnMapped("attackerLikelihood"),
      createColumnMappedNoEdit("group"),
      createColumnMappedNoEdit("person"),
      createColumnMappedNoEdit("satype"),
      createColumnActions(createButton, { disableFilters: true }),
    ];
  }, [selectedRowId, editMode, createButton]);

  useEffect(() => {
    if (projectId !== localProjectId.current) {
      navigate(listUrl);
    }
  }, [projectId, navigate]);

  if (error) {
    return <ErrorBanner msg="Error while loading details of attacker" />;
  }
  if (data) {
    return (
      <>
        <Title id="AttackerFullDetails_title">
          {selectedElement.name}
          <ShowHideButton setShowSummaryTable={setShowSummaryTable} showSummaryTable={showSummaryTable} />
        </Title>

        {showSummaryTable ? (
          <DetailsContainerSingleTable>
            <BrmSingleElementTable
              data={data}
              columns={columns}
              setSelectedElement={setSelectedElement}
              setSelectedRowId={setSelectedRowId}
              customProps={{ id: "AttackerFullDetails_table" }}
              elementName={TYPE.attacker}
            />
          </DetailsContainerSingleTable>
        ) : (
          <></>
        )}

        {selectedElement.id && (
          <DetailsContainerParent showSummaryTable={showSummaryTable}>
            <DetailsContainer>
              <AttackersDetails selectedElement={selectedElement} setRefresh={setRefresh} refresh={refresh} />
            </DetailsContainer>
          </DetailsContainerParent>
        )}
      </>
    );
  }

  return <Loading />;
}

export default AttackerFullDetails;
