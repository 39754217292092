import styled from "styled-components/macro";

const iconLinkStyle = {
  Link: styled.a`
    display: flex;
    color: ${({ theme }) => theme.header.textColor};
    font-size: ${({ theme }) => theme.fonts.defaultSize};
    font-family: ${({ theme }) => theme.fonts.primary};
    text-decoration: none;
    &:visited {
      color: ${({ theme }) => theme.header.textColor};
    }
    &:hover {
      opacity: 0.7;
      cursor: pointer;
      color: ${({ theme }) => theme.header.textColor};
      text-decoration: none;

      ::before {
        width: 100%;
      }
    }
  `,
  Image: styled.img`
    height: 18px;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
      text-decoration: none;
    }
  `,
};

export default iconLinkStyle;
