import * as Recoil from "recoil";
import * as log from "loglevel";
import { userIdState } from "atoms/atoms-admin";
import { AdminApi } from "features/brm";

import { UserAvatar } from "../UserAvatar";
import * as S from "./UserInfoAvatar.styles";

export const UserInfoAvatar = () => {
  const userId = Recoil.useRecoilValue(userIdState);
  const { data: user, isError } = AdminApi.useUser({ userId });

  const name = user ? `${user.firstname} ${user.lastname}` : "";

  if (isError) {
    log.error(`Unable to load user information for userid: ${userId}`);
    return null;
  }

  if (user) {
    return (
      <S.Root>
        <S.AvatarInfoContainer>
          <S.AvatarAndNameContainer>
            <S.AvatarContainer>
              <UserAvatar size="28" />
            </S.AvatarContainer>
            <S.NameAndEmailContainer>
              <S.NameContainer>{name}</S.NameContainer>
              <S.EmailContainer>{user.email}</S.EmailContainer>
            </S.NameAndEmailContainer>
          </S.AvatarAndNameContainer>
        </S.AvatarInfoContainer>
      </S.Root>
    );
  }

  return null;
};
