export const organizationKeys = {
  all: ["organization"] as const,
  org: (organizationId: string | null) => [...organizationKeys.all, organizationId] as const,
  user: (organizationId: string | null) => [...organizationKeys.org(organizationId), "user"] as const,
  settings: (organizationId: string | null) => [...organizationKeys.org(organizationId), "settings"] as const,
  project: (organizationId: string | null) => [...organizationKeys.org(organizationId), "project"] as const,
  attachedUser: (organizationId: string | null) => [...organizationKeys.org(organizationId), "attached"] as const,
  property: (organizationId: string | null, property: string | null) =>
    [...organizationKeys.org(organizationId), "property", property] as const,
};
