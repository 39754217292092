import PropTypes from "prop-types";
import { useState } from "react";
import toast from "react-hot-toast";
// Components
import SelectAll from "components/forms/SelectAllReactSelect";
import FormStyledError from "components/forms/FormStyledError";
// Services
import { sortByLabel } from "utils/sorting";
import { userRoles } from "services/brm/admin-service";
import { isSuperAdmin } from "services/user";
import { AdminApi } from "features/brm";
// styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";

const rolesList = [
  userRoles.properties[userRoles.SUPER_ADMIN],
  userRoles.properties[userRoles.ADMIN],
  userRoles.properties[userRoles.SYSTEM_ENGINEER],
  userRoles.properties[userRoles.THREAT_ANALYST],
  userRoles.properties[userRoles.RISK_ANALYST],
  userRoles.properties[userRoles.CONTROLS_COORDINATOR],
  userRoles.properties[userRoles.CYBERSECURITY_EXPERT],
];

const rolesListForAdmin = [
  userRoles.properties[userRoles.ADMIN],
  userRoles.properties[userRoles.SYSTEM_ENGINEER],
  userRoles.properties[userRoles.THREAT_ANALYST],
  userRoles.properties[userRoles.RISK_ANALYST],
  userRoles.properties[userRoles.CONTROLS_COORDINATOR],
  userRoles.properties[userRoles.CYBERSECURITY_EXPERT],
];

export const AddAssignedRoleForm = ({
  setModalIsOpen,
  selectedElement,
  userRole,
  assignedRoleData,
  setSelectedAssignedRole,
}) => {
  // Dropdowns for forms
  const [selectedRole, setSelectedRole] = useState([]);
  const [postError, setPostError] = useState("");
  const originalData = new Set(assignedRoleData.map(({ name }) => name));
  const { mutateAsync: setUserAssignedRole, isError: isSetUserAssignedRoleError } = AdminApi.useSetUserAssignedRole();

  const getReactSelectArray = (array) => {
    const filteredArray = array.filter(({ name }) => !originalData.has(name));
    return filteredArray.map((obj) => ({ value: obj.key, label: obj.name })).sort(sortByLabel);
  };

  const handleRoleSelectChange = (selectedItems = []) => {
    const items = selectedItems;
    setSelectedRole(items);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (selectedRole) {
      try {
        await setUserAssignedRole({ userId: selectedElement.id, assignedRoleData, selectedRole });
        setModalIsOpen(false);
        setSelectedAssignedRole("");
      } catch {
        toast.error(<p>Error assigning roles</p>, {});
      }
    } else {
      setPostError("*Please select a role prior to submitting.");
    }
  }

  const showSelect = (array) => {
    return (
      <SelectAll
        isMulti
        options={getReactSelectArray(array)}
        defaultValue={selectedRole}
        value={selectedRole}
        onChange={handleRoleSelectChange}
        allowSelectAll
        allOption={{
          label: "Select all",
          value: "*",
        }}
        elementId="AddAssignedRoleForm_roles"
      />
    );
  };

  if (isSetUserAssignedRoleError) {
    setPostError("Error occured while updating user role");
  }

  return (
    <div id="AddAssignedRoleForm" style={{ padding: "10px" }}>
      <form onSubmit={handleSubmit} action="" id="AddAssignedRoleForm_form">
        <div className="form-style">
          <label id="AddAssignedRoleForm_role">Select Role :</label>
          {isSuperAdmin(userRole) ? showSelect(rolesList) : showSelect(rolesListForAdmin)}
        </div>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled id="AddAssignedRoleForm_buttons">
          <DialogButtonStyled onClick={() => setModalIsOpen(false)} id="AddAssignedRoleForm_cancelButton">
            Cancel
          </DialogButtonStyled>
          <DialogButtonStyled type="submit" id="AddAssignedRoleForm_addButton">
            Add
          </DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};

AddAssignedRoleForm.propTypes = {
  assignedRoleData: PropTypes.arrayOf(PropTypes.any),
  selectedElement: PropTypes.shape({
    id: PropTypes.any,
  }),
  setModalIsOpen: PropTypes.func,
  userRole: PropTypes.string.isRequired,
  setSelectedAssignedRole: PropTypes.func,
};
