import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./knowledgebase-query-key-factory";

/**
 * Gets the organization's projects
 */
export const getKnowledgebaseProjects = ({ kbId }: { kbId: string | null }) => {
  return kbId === null ? Promise.reject(new Error("Invalid knowledgebase id")) : Brm.kbApi.getKBAllProject(kbId);
};

interface IKnowledgebaseOrganizations {
  kbId: string | null;
  options?: QueryOption<typeof getKnowledgebaseProjects>;
}

/**
 * Custom hook for getting knowledgebase projects
 *
 * @param {object} options - react-query options
 * @returns
 */
export const useKnowledgebaseProjects = ({ kbId, options = {} }: IKnowledgebaseOrganizations) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.knowledgebaseKeys.kb(kbId),
    queryFn: () => getKnowledgebaseProjects({ kbId }),
  });
};
