import { RiskShortDto } from "@kdmanalytics/brm-system";
import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./risk-query-key-factory";

interface IGetRiskCalculated {
  projectId: string;
  variantId: string;
}

export async function getRiskCalculated({ projectId, variantId }: IGetRiskCalculated) {
  if (projectId) {
    const riskData = await queryClient.fetchQuery(KeyFactory.riskKeys.project(projectId, variantId), () =>
      Brm.riskApi.findRisk(projectId, { variant: variantId })
    );
    // const riskData = await Brm.riskApi.findRisk(projectId, { variant: variantId });
    const riskHasBeenRun = await riskData.some((r: RiskShortDto) => r.rank?.calcValue === 1);
    return riskHasBeenRun;
  }
  return false;
}

interface IUseRiskCalculated {
  projectId: string;
  variantId: string;
  options?: QueryOption<typeof getRiskCalculated>;
}

export const useRiskCalculated = ({ projectId, variantId, options }: IUseRiskCalculated) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.riskKeys.calculated(projectId, variantId),
    queryFn: () => getRiskCalculated({ projectId, variantId }),
  });
};
