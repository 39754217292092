import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

export interface ISetSystemAssetCategory {
  saId: string;
  saCat: string;
}

export const setSystemAssetCategory = ({ saId, saCat }: ISetSystemAssetCategory) => {
  return Brm.systemAssetApi.setSystemAssetCategory(saId, { body: saCat });
};

export interface IUseSetSystemAssetCategory {
  options?: MutationOption<typeof setSystemAssetCategory>;
}

/**
 * Custom hook to change the category of a existing system asset
 * @returns react-query ReactQuery.useMutation
 */
// eslint-disable-next-line import/prefer-default-export
export const useSetSystemAssetCategory = ({ options }: IUseSetSystemAssetCategory = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.systemAssetKeys.all);
    },
    ...options,
    mutationFn: setSystemAssetCategory,
  });
};
