import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import * as S from "./ErrorBanner.styles";

export const ErrorBanner = ({ msg = "" }: { msg: string }) => {
  return (
    <div>
      <Alert variant="danger">
        <Alert.Heading>Something went wrong!</Alert.Heading>
      </Alert>
      <S.Contents>
        <p>Please report the following error to KDM Analytics:</p>
        <S.ErrorHeader>Error:</S.ErrorHeader>
        <S.MessageContainer>
          <p>{msg}</p>
        </S.MessageContainer>
        <p>You can attempt to recover from this error by clicking the reload button below.</p>
        <hr />
        <S.ButtonContainer>
          <Button size="sm" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </S.ButtonContainer>
      </S.Contents>
    </div>
  );
};
