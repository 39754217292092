import { MouseEvent } from "react";
import { MdSettingsBackupRestore } from "react-icons/md";
import { InlineButton } from "features/brm";
import * as S from "./RestoreSavePointButton.styles";

interface ISavePointButtonProps {
  onClick: (event: MouseEvent) => void;
  disableSavePoint: boolean;
}

export const RestoreSavePointButton = ({ onClick, disableSavePoint = false }: ISavePointButtonProps) => {
  return (
    <InlineButton
      title={!disableSavePoint ? "Restore Save Point" : "Restoring Save Point"}
      id="RestoreSavePointButton_button"
      onClick={onClick}
      disabled={disableSavePoint}
    >
      <S.InLineIcon>
        <MdSettingsBackupRestore size="16" />
      </S.InLineIcon>
    </InlineButton>
  );
};
