import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./node-query-key-factory";
/**
 * Returns the persons for a node
 * @param {string} nodeId - uuid of the node
 * @returns persons of the given node
 */
export const getNodePersons = ({ nodeId }: { nodeId: string | null }) => {
  return nodeId === null ? Promise.reject(new Error("Invalid nodeId")) : Brm.nodeApi.getNodePerson(nodeId);
};

interface INodePersonsProps {
  nodeId: string | null;
  options?: QueryOption<typeof getNodePersons>;
}

/**
 * Custom hook to retreive a persons for a node
 *
 * @param {string} nodeId - uuid of a node
 * @param {object} options - react-query configuration object
 * @returns react-query for getNodeParent
 */
export const useNodePersons = ({ nodeId, options = {} }: INodePersonsProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.nodeKeys.persons(nodeId),
    queryFn: () => getNodePersons({ nodeId }),
  });
};
