export const AUDIT_FORMAT = {
  json: "JSON",
  csv: "CSV",
};

export const AUDIT_TYPE = {
  session: "SESSION",
  activeSessions: "ACTIVE_SESSION",
  license: "LICENSE",
  system: "SYSTEM",
  admin: "ADMIN",
};
