import * as React from "react";
import * as Log from "loglevel";

import {
  AdminApi,
  GlobalControlApi,
  ImportApi,
  useProject,
  ImportContext,
  ImportContextValues,
  useVariant,
  MitigationApi,
} from "features/brm";
import { COMMON } from "constants/brm";
import { StandardBaselineCreateDto } from "@kdmanalytics/brm-knowledgebase";
import * as BrmGql from "generated/graphql";
import { getErrorMessage } from "utils/error-message-utils";
import { isBrmApiError, isError } from "utils/type-guard";
import { useQueryClient } from "@tanstack/react-query";
import { IStepInfo, IConfigureData, StepStatus, ConfigureStep } from "./types";
import { useAdminOrganization } from "./useAdminOranization";
import { useSubmissionStatusQuery } from "./useSubmissionStatusQuery";
import { errorMessageLookup } from "./error-lookup";

const log = Log.getLogger("ProjectConfigureLogger");

interface IBaselineInfo {
  baselineId: string;
  baselineName: string;
  catalogId: string;
}

const ImportOption = {
  ViewType: "view-type",
  Config: "brm-config",
};

interface IUserConfigureProject {
  onStepUpdate: (updatedStep: IStepInfo) => void;
  onComplete: () => void;
  onError: () => void;
  onCancelled: () => void;
  isCancelled?: boolean;
}

// interface IControlCatalog {
//   id: string;
//   name: string;
// }

// const isBrmApiError = (err: any): err is IBrmApiError => {
//   return err && "status" in err && "body" in err && "response" in err && "statusText" in err;
// };

function assertIsDefined<T>(value: T): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(`${value} is not defined`);
  }
}

const POLL_INTERVAL = 1000;

type ImportJobStatus =
  | "empty"
  | "in progress"
  | "uploading"
  | "importing"
  | "complete"
  | "nextStep"
  | "skipped"
  | "error";
type ImportJobState = { status: ImportJobStatus; jobId?: string; step?: IStepInfo; error?: Error };
type ImportJobAction =
  | { type: "empty" }
  | { type: "start" }
  | { type: "uploading"; step: IStepInfo }
  | { type: "importing"; step: IStepInfo; jobId: string }
  | { type: "finish" }
  | { type: "nextStep" }
  | { type: "skipped" }
  | { type: "failure"; error: Error };

const importJobReducer = (state: ImportJobState, action: ImportJobAction): ImportJobState => {
  switch (action.type) {
    case "empty": {
      return { status: "empty" };
    }
    case "start": {
      return { status: "in progress" };
    }
    case "uploading": {
      return { status: "uploading", step: action.step };
    }
    case "importing": {
      return { status: "importing", step: action.step, jobId: action.jobId };
    }
    case "finish": {
      return { status: "complete" };
    }
    case "nextStep": {
      return { status: "nextStep" };
    }
    case "skipped": {
      return { status: "skipped" };
    }
    case "failure": {
      return { status: "error", error: action.error };
    }
    default: {
      return state;
    }
  }
};

type RiskJobState = { status: string; jobId?: string; step?: IStepInfo; error?: Error };
type RiskJobAction =
  | { type: "empty" }
  | { type: "start"; step: IStepInfo }
  | { type: "calculate"; step: IStepInfo; jobId: string }
  | { type: "finish" }
  | { type: "nextStep" }
  | { type: "failure"; error: Error };

const riskJobReducer = (state: RiskJobState, action: RiskJobAction): RiskJobState => {
  switch (action.type) {
    case "empty": {
      return { status: "empty" };
    }
    case "start": {
      return { status: "in progress" };
    }
    case "calculate": {
      return { status: "calculating", step: action.step, jobId: action.jobId };
    }
    case "finish": {
      return { status: "complete" };
    }
    case "nextStep": {
      return { status: "nextStep" };
    }
    case "failure":
    default: {
      return state;
    }
  }
};

type ConfigureJobState = { status: string; jobId?: string; step?: IStepInfo; error?: Error };
type ConfigureJobAction =
  | { type: "empty" }
  | { type: "start" }
  | { type: "finish" }
  | { type: "nextStep" }
  | { type: "failure"; error: Error };

const configureJobReducer = (state: ConfigureJobState, action: ConfigureJobAction): ConfigureJobState => {
  switch (action.type) {
    case "empty": {
      return { status: "empty" };
    }
    case "start": {
      return { status: "in progress" };
    }
    case "finish": {
      return { status: "complete" };
    }
    case "nextStep": {
      return { status: "nextStep" };
    }
    case "failure":
    default: {
      return state;
    }
  }
};

export const useConfigureProject = ({
  onStepUpdate,
  onComplete,
  onError,
  onCancelled,
  isCancelled = false,
}: IUserConfigureProject) => {
  const queryClient = useQueryClient();
  const { organizationId } = useAdminOrganization();
  const [projectState, { projectId }] = useProject();
  const { variants } = useVariant();

  const [isConfigureStarted, setIsConfigureStarted] = React.useState(false);
  const [configureInputData, setConfigureInputData] = React.useState<IConfigureData>();
  const [stepMap, setStepMap] = React.useState<Map<ConfigureStep, IStepInfo>>();
  const [baselineInfo, setBaselineInfo] = React.useState<IBaselineInfo>();

  const [sctmImportState, sctmDispatch] = React.useReducer(importJobReducer, { status: "empty" });
  const [modelImportState, modelDispatch] = React.useReducer(importJobReducer, { status: "empty" });
  const [baselineConfigureState, baselineConfigureDispatch] = React.useReducer(configureJobReducer, {
    status: "empty",
  });
  const [baselineAssignmentState, baselineAssignmentDispatch] = React.useReducer(configureJobReducer, {
    status: "empty",
  });
  const [baselineImportState, baselineImportDispatch] = React.useReducer(importJobReducer, { status: "empty" });
  const [findingsImportState, findingsDispatch] = React.useReducer(importJobReducer, { status: "empty" });
  const [initialRiskJobState, initialRiskJobDispatch] = React.useReducer(riskJobReducer, { status: "empty" });
  const [variantRiskJobState, variantRiskJobDispatch] = React.useReducer(riskJobReducer, { status: "empty" });

  const { data: orgDefaultCatalog } = AdminApi.useGetOrganizationProperty({
    organizationId,
    property: "defaultControlCatalog",
    options: { enabled: !!organizationId },
  });

  // we can assume there is at least one variant in addition to the initial(system) variant
  const defaultVariantId = React.useMemo(() => {
    if (Array.isArray(variants) && variants.length) {
      return variants.length > 1 ? variants[1].id : variants[0].id;
    }
    return undefined;
  }, [variants]);

  const { data: sctmData } = MitigationApi.useVariantSctm({
    variantId: defaultVariantId || "",
    options: {
      enabled: defaultVariantId !== undefined,
    },
  });
  const { data: projectCatalog } = AdminApi.useGetProjectProperty({
    projectId,
    property: "catalog",
    options: { enabled: projectState !== null },
  });

  const { data: projectBaseline } = AdminApi.useGetProjectProperty({
    projectId,
    property: "baseline",
    options: { enabled: projectState !== null },
  });

  const { mutateAsync: setProjectKb } = AdminApi.useSetProjectKb();
  const { mutateAsync: cloneControlCatalog } = GlobalControlApi.useCloneControlCatalog();
  const { mutateAsync: createBaseline } = GlobalControlApi.useCreateBaseline();
  const { mutateAsync: uploadAsset } = ImportApi.useUploadAsset();
  const { mutateAsync: importSubmission } = ImportApi.useImportSubmission();
  const { mutateAsync: setOrganizationProperty } = AdminApi.useSetOrganizationProperty();
  const { mutateAsync: setProjectProperty } = AdminApi.useSetProjectProperty();
  const { mutateAsync: setProjectSetting } = AdminApi.useSetProjectSetting();
  const { mutateAsync: setVariantProperty } = MitigationApi.useSetVariantProperty();
  const { mutateAsync: calculateRisk } = BrmGql.useCalcRiskMutation({ retry: 3 });
  const { mutateAsync: mergeBaseline } = GlobalControlApi.useMergeStandardBaseline();

  const { mutate: cancelImport } = ImportApi.useCancelImport();

  BrmGql.useCalcRiskStatusQuery(
    { run: initialRiskJobState.jobId || "" },
    {
      onSuccess: (pollData) => {
        if (pollData.calcRiskStatus === BrmGql.CompletionStatus.Completed) {
          log.debug("Risk Calculation Complete");
          initialRiskJobDispatch({ type: "finish" });
          onStepUpdate({ ...initialRiskJobState.step!, status: StepStatus.Complete });
          queryClient.invalidateQueries(MitigationApi.variantsKeys.all);
        } else if (pollData.calcRiskStatus === BrmGql.CompletionStatus.Failed) {
          log.error("Risk Calculation Status Query Failed");
          initialRiskJobDispatch({ type: "failure", error: new Error("Bad things happened with intial risk") });
          onStepUpdate({ ...initialRiskJobState.step!, status: StepStatus.Error });
        }
      },
      onError: (error) => {
        log.error("Risk Calculation Failed");
        initialRiskJobDispatch({
          type: "failure",
          error: new Error(`Bad things happened with intial risk${getErrorMessage(error)}`),
        });
        onStepUpdate({ ...initialRiskJobState.step!, status: StepStatus.Error });
        onError();
      },
      enabled: !isCancelled && !!initialRiskJobState.jobId,
      refetchInterval: initialRiskJobState.jobId === undefined ? false : POLL_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );

  BrmGql.useCalcRiskStatusQuery(
    { run: variantRiskJobState.jobId || "" },
    {
      onSuccess: (pollData) => {
        if (pollData.calcRiskStatus === BrmGql.CompletionStatus.Completed) {
          log.debug("Risk Calculation Complete");
          variantRiskJobDispatch({ type: "finish" });
          onStepUpdate({ ...variantRiskJobState.step!, status: StepStatus.Complete });
          queryClient.invalidateQueries(MitigationApi.variantsKeys.all);
        } else if (pollData.calcRiskStatus === BrmGql.CompletionStatus.Failed) {
          log.error("Risk Calculation Status Query Failed");
          variantRiskJobDispatch({ type: "failure", error: new Error("Bad things happened with variant risk") });
          onStepUpdate({ ...variantRiskJobState.step!, status: StepStatus.Error });
        }
      },
      onError: (error) => {
        log.error("Risk Calculation Failed");
        variantRiskJobDispatch({
          type: "failure",
          error: new Error(`Bad things happened with variant risk${getErrorMessage(error)}`),
        });
        onStepUpdate({ ...variantRiskJobState.step!, status: StepStatus.Error });
        onError();
      },
      enabled: !isCancelled && !!variantRiskJobState.jobId,
      refetchInterval: variantRiskJobState.jobId === undefined ? false : POLL_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );

  useSubmissionStatusQuery({
    context: ImportContext.system,
    state: modelImportState,
    dispatch: modelDispatch,
    onStepUpdate,
    onError,
    isCancelled,
  });

  useSubmissionStatusQuery({
    context: ImportContext.sctm,
    state: sctmImportState,
    dispatch: sctmDispatch,
    onStepUpdate,
    onError,
    isCancelled,
  });

  useSubmissionStatusQuery({
    context: ImportContext.baseline,
    state: baselineImportState,
    dispatch: baselineImportDispatch,
    onStepUpdate,
    onError,
    isCancelled,
  });

  useSubmissionStatusQuery({
    context: ImportContext.bor,
    state: findingsImportState,
    dispatch: findingsDispatch,
    onStepUpdate,
    onError,
    isCancelled,
  });

  React.useEffect(() => {
    if (isCancelled) {
      let jobId;
      if (sctmImportState.jobId) {
        jobId = sctmImportState.jobId;
        sctmDispatch({ type: "finish" });
      } else if (modelImportState.jobId) {
        jobId = modelImportState.jobId;
        modelDispatch({ type: "finish" });
      } else if (baselineImportState.jobId) {
        jobId = baselineImportState.jobId;
        baselineImportDispatch({ type: "finish" });
      } else if (findingsImportState.jobId) {
        jobId = findingsImportState.jobId;
        findingsDispatch({ type: "finish" });
      } else if (initialRiskJobState.jobId || initialRiskJobState.status === "in progress") {
        initialRiskJobDispatch({ type: "finish" });
        onCancelled();
      } else if (variantRiskJobState.jobId || variantRiskJobState.status === "in progress") {
        variantRiskJobDispatch({ type: "finish" });
        onCancelled();
      }

      if (jobId) {
        // console.log("canceling!", jobId);
        cancelImport(
          { projectId, runId: jobId },
          {
            onSuccess: () => {
              // console.log("cancelled!");
              onCancelled();
            },
          }
        );
      }
    }
  }, [
    projectId,
    sctmImportState,
    modelImportState,
    baselineImportState,
    findingsImportState,
    cancelImport,
    isCancelled,
    onCancelled,
    initialRiskJobState,
    variantRiskJobState,
  ]);

  const getStep = React.useCallback(
    (stepId: ConfigureStep) => {
      const step = stepMap?.get(stepId);
      assertIsDefined(step);
      return step;
    },
    [stepMap]
  );

  const updateStep = React.useCallback(
    (stepId: ConfigureStep, status: StepStatus) => {
      const step = getStep(stepId);
      onStepUpdate({ ...step, status });
    },
    [getStep, onStepUpdate]
  );

  const updateStepOnError = React.useCallback(
    (stepId: ConfigureStep, error: unknown) => {
      const step = getStep(stepId);
      onStepUpdate({ ...step, status: StepStatus.Error, error });
      onError();
    },
    [getStep, onError, onStepUpdate]
  );

  // Does no catching of errors....caller beware
  const doStep = React.useCallback(
    async (step: IStepInfo | undefined, stepPromise: Promise<any>) => {
      assertIsDefined(step);
      if (step.status === StepStatus.Skipped) {
        return Promise.resolve(undefined);
      }
      onStepUpdate({ ...step, status: StepStatus.InProgress });
      const value = await stepPromise;
      onStepUpdate({ ...step, status: StepStatus.Complete });
      return Promise.resolve(value);
    },
    [onStepUpdate]
  );

  const setProjectKnowledgebaseStep = React.useCallback(
    async (kbId: string | undefined, step: IStepInfo | undefined) => {
      log.debug("[STEP] Setting project knowledge base: start");

      assertIsDefined(kbId);
      await doStep(step, setProjectKb({ projectId, kb: kbId }));
      log.debug("[STEP] Setting project knowledge base: complete");
    },
    [doStep, projectId, setProjectKb]
  );

  const cloneControlCatalogStep = React.useCallback(
    async (defaultControlCatalogId: string, step: IStepInfo | undefined): Promise<string | undefined> => {
      log.debug("[STEP] Clone Control Catalog Step: start");
      assertIsDefined(defaultControlCatalogId);
      const result = await doStep(step, cloneControlCatalog({ ccId: defaultControlCatalogId }));
      if (result) {
        const newCatalogId = result.result;
        log.debug("[STEP] Clone Control Catalog Step: complete");
        return newCatalogId;
      }
      return undefined;
    },
    [cloneControlCatalog, doStep]
  );

  const createBaselineInCatalogStep = React.useCallback(
    async (controlCatalogId: string | undefined, step: IStepInfo | undefined, name = "Custom Baseline") => {
      log.debug("[STEP] Create Baseline in Catalog Step: start");
      assertIsDefined(controlCatalogId);

      const params = {
        name,
        note: "Generated by project creation and configuration wizard",
      };
      const standardBaselineCreateDto = StandardBaselineCreateDto.constructFromObject(params);
      const newBaselineId = await doStep(step, createBaseline({ controlCatalogId, standardBaselineCreateDto }));
      log.debug("[STEP] Create Baseline in Catalog Step: complete");
      return { name: params.name, id: newBaselineId };
    },
    [createBaseline, doStep]
  );

  const setProjectCatalogStep = React.useCallback(
    async (controlCatalogId: string | undefined, step: IStepInfo | undefined) => {
      log.debug("[STEP] setProjectCatalogStep started");

      assertIsDefined(controlCatalogId);
      // console.log("setting project controlCatalog property1");
      await doStep(step, setProjectProperty({ projectId, property: "controlCatalog", value: controlCatalogId }));
      log.debug("[STEP] setProjectCatalogStep completed");
    },
    [doStep, projectId, setProjectProperty]
  );

  const setVariantCatalogStep = React.useCallback(
    async (controlCatalogId: string | undefined, step: IStepInfo | undefined) => {
      assertIsDefined(controlCatalogId);
      // console.log("setting variant catalog property1");
      await doStep(
        step,
        setVariantProperty({ variantId: defaultVariantId || "", property: "catalog", value: controlCatalogId })
      );
    },
    [defaultVariantId, doStep, setVariantProperty]
  );

  const doImport = React.useCallback(
    async ({
      dispatch,
      context,
      files,
      step,
      baselineData,
    }: {
      dispatch: (action: any) => void;
      context: ImportContextValues;
      files: File[] | undefined;
      step: IStepInfo | undefined;
      baselineData?: IBaselineInfo;
    }) => {
      assertIsDefined(step);
      // console.log("doImport step", step);
      if (!files || step.status === StepStatus.Skipped) {
        // console.log("skipping import");
        dispatch({ type: "skipped" });
        return;
      }
      dispatch({ type: "start" });
      onStepUpdate({ ...step, status: StepStatus.InProgress });
      try {
        if (!projectId) {
          throw new Error("projectId is undefined");
        }
        dispatch({ type: "uploading", step });
        await uploadAsset(
          { projectId, context, files },
          {
            onSuccess: (uploadData) => {
              assertIsDefined(configureInputData);

              const { isMdxml, viewType } = configureInputData;
              if (context === ImportContext.baseline) {
                assertIsDefined(baselineData);
                uploadData.options["baseline-name"].selection = [baselineData.baselineName];
                uploadData.options["baseline-name"].elementNameInUrl = "standardBaseline";
                // ACK! Abuse of baseline-id with the projectid
                uploadData.options["baseline-id"].selection = [COMMON.nullUuid];
                uploadData.options["catalog-id"].selection = [baselineData.catalogId];

                // console.log("uploadData", uploadData);
              }
              if (context === ImportContext.system) {
                if (isMdxml) {
                  uploadData.options[ImportOption.ViewType].selection = [viewType];
                }
              } else if (context === ImportContext.sctm) {
                log.debug(uploadData);
                // Reasonable defaults....
                // const variantId = variants.length ? variants[0].id : undefined;
                const { name, baseline } = sctmData;
                uploadData.options["control-column"].selection = [...uploadData.options["control-column"].values];
                uploadData.options["convert-effective"].selection = [
                  ...uploadData.options["convert-effective"].defaults,
                ];
                uploadData.options["header-row"].selection = [...uploadData.options["header-row"].defaults];
                uploadData.options["auto-allocate"].selection = [false];
                uploadData.options["exclude-non-compliant"].selection = [
                  configureInputData.excludeNonCompliantControls,
                ];
                uploadData.options["sctm-variant"].selection = [defaultVariantId];
                uploadData.options["sctm-name"].selection = [name];
                uploadData.options["sctm-baseline"].selection = [baseline.name];
                uploadData.options["import-scope"].selection = ["Import as Targets"];
                // uploadData.options.systems.selection = [...uploadData.options.systems.defaults];
                uploadData.options.nodes.selection = [...uploadData.options.nodes.values];
                uploadData.options.exchanges.selection = [...uploadData.options.exchanges.values];
                uploadData.options.worksheet.selection = [...uploadData.options.worksheet.defaults];
              }

              importSubmission(
                { projectId, result: uploadData, context },
                {
                  onSuccess: (importData) => {
                    dispatch({ type: "importing", step, jobId: importData.id });
                  },
                  onError: (error) => {
                    log.debug("import submission error", error);
                    const err = new Error(error.message);
                    dispatch({ type: "failure", err });
                    updateStepOnError(step.id, err);
                    // onError();
                  },
                }
              );
            },
            onError: (error) => {
              if (isBrmApiError(error)) {
                let err;
                if (error.status === 400 && error.body !== undefined) {
                  err = new Error(`Upload Failed. ${(error.body as any).message}}`);
                } else {
                  err = new Error(`Upload Failed. ${errorMessageLookup(context)}`);
                }
                onStepUpdate({ ...step, status: StepStatus.Error, error: err });
              }
              onError();
            },
          }
        );
      } catch (error) {
        log.debug(`error: ${error}`);

        if (isBrmApiError(error)) {
          log.debug("isBrmError");
          let err;
          if (error.status === 400 && error.body !== undefined) {
            err = new Error(`Upload Failed. ${(error.body as any).message}}`);
          } else {
            err = new Error(`Upload Failed. ${errorMessageLookup(context)}`);
          }
          onStepUpdate({ ...step, status: StepStatus.Error, error: err });
          dispatch({ type: "failure", err });
          onError();
        } else {
          log.debug("is not BrmError");
          throw new Error(getErrorMessage(error));
        }
      }
    },
    [
      configureInputData,
      defaultVariantId,
      importSubmission,
      onError,
      onStepUpdate,
      projectId,
      sctmData,
      updateStepOnError,
      uploadAsset,
    ]
  );

  const doRisk = React.useCallback(
    async (dispatch: (v: any) => void, step: IStepInfo | undefined, variantId: string | undefined = undefined) => {
      assertIsDefined(step);
      assertIsDefined(dispatch);
      if (step.status === StepStatus.Skipped) {
        // do something
      }
      onStepUpdate({ ...step, status: StepStatus.InProgress });
      try {
        dispatch({ type: "start", step });
        await calculateRisk(
          { project: projectId, variant: variantId },
          {
            onSuccess: (data) => {
              dispatch({ type: "calculate", step, jobId: data.calcRisk || "" });
            },
            onError: (error) => {
              dispatch({ type: "failure", error: new Error(getErrorMessage(error)) });
            },
          }
        );
      } catch (error) {
        log.debug("Is this the error?", error);
        if (isBrmApiError(error)) {
          const err = new Error(`Error while calculating risk ${error.statusText}`);
          updateStepOnError(step.id, err);
          dispatch({ type: "failure", error: err });
        } else if (isError(error)) {
          const err = new Error(`Error while calculating risk ${error.message}`);
          updateStepOnError(step.id, err);
          dispatch({ type: "failure", error: err });
        } else {
          throw new Error(getErrorMessage(error));
        }
      }
    },
    [calculateRisk, onStepUpdate, projectId, updateStepOnError]
  );

  const importBaseline = React.useCallback(
    async (baselineFile: File | undefined, step: IStepInfo | undefined, baselineData: IBaselineInfo) => {
      assertIsDefined(step);
      if (!baselineFile || step?.status === StepStatus.Skipped) {
        baselineImportDispatch({ type: "skipped" });
        return;
      }
      // console.log("about to do import", baselineFile);
      doImport({
        dispatch: baselineImportDispatch,
        context: ImportContext.baseline,
        files: [baselineFile],
        step,
        baselineData,
      });
    },
    [doImport]
  );

  const importSystemModel = React.useCallback(
    async (modelFiles: FileList | undefined, step: IStepInfo | undefined) => {
      log.debug("[STEP] Import System Model : start");
      if (!modelFiles || step?.status === StepStatus.Skipped) {
        modelDispatch({ type: "skipped" });
        log.debug("[STEP] Import System Model : skipped");
        return;
      }
      doImport({ dispatch: modelDispatch, context: ImportContext.system, files: [...modelFiles], step });
      log.debug("[STEP] Import System Model : Uploaded and Submitted");
    },
    [doImport]
  );

  const calculateInitialRisk = React.useCallback(
    async (step: IStepInfo | undefined) => {
      log.debug("[STEP] Calculate initial Risk : start");
      doRisk(initialRiskJobDispatch, step);
      log.debug("[STEP] Calculate initial Risk : complete");
    },
    [doRisk]
  );

  const importSctm = React.useCallback(
    async (sctmFile: File | undefined, step: IStepInfo | undefined) => {
      log.debug("[STEP] Importing SCTM : start");
      assertIsDefined(sctmFile);
      doImport({ dispatch: sctmDispatch, context: ImportContext.sctm, files: [sctmFile], step });
      log.debug("[STEP] Importing SCTM : Uploaded and Submitted");
    },
    [doImport]
  );

  const importFindings = React.useCallback(
    async (findingsFiles: FileList | undefined, step: IStepInfo | undefined) => {
      log.debug("[STEP] Importing Findings : start");
      assertIsDefined(findingsFiles);
      doImport({ dispatch: findingsDispatch, context: ImportContext.bor, files: [...findingsFiles], step });
      log.debug("[STEP] Importing Findings : Uploaded and Submitted");
    },
    [doImport]
  );

  const calculateVariantRisk = React.useCallback(
    async (step: IStepInfo | undefined) => {
      assertIsDefined(step);
      doRisk(variantRiskJobDispatch, step, defaultVariantId);
    },
    [doRisk, defaultVariantId]
  );

  const configureProject = (inputData: IConfigureData, steps: Map<ConfigureStep, IStepInfo>) => {
    log.debug("Starting to configure project");
    setConfigureInputData(inputData);
    setStepMap(steps);

    // set project settings before we do anything...
    setProjectSetting({
      projectId,
      setting: "compliantControlMode",
      value: inputData.excludeNonCompliantControls ? "exclude" : "include",
    });
    setProjectSetting({
      projectId,
      setting: "baselineMode",
      value: inputData.baselineMode || "floor",
    });

    setIsConfigureStarted(true);
  };

  React.useEffect(() => {
    const doConfigure = async () => {
      if (
        isConfigureStarted &&
        configureInputData &&
        orgDefaultCatalog &&
        stepMap &&
        projectBaseline &&
        projectCatalog
      ) {
        setIsConfigureStarted(false);

        const kbId = configureInputData.knowledgebase?.value;
        if (kbId) {
          const setProjectKbStep = getStep(ConfigureStep.SetProjectKnowledgebase);
          try {
            await setProjectKnowledgebaseStep(kbId, setProjectKbStep);
          } catch (error) {
            if (isBrmApiError(error)) {
              updateStepOnError(setProjectKbStep.id, new Error("Unable to set Project Knowledgebase"));
            } else {
              throw new Error(getErrorMessage(error));
            }
          }
        }
        log.debug("[STEP] Setting project knowledge base complete");
        baselineConfigureDispatch({ type: "start" });
        const hasSctmFileAndIncludesBaseline =
          configureInputData.sctmFile && configureInputData.isBaselineIncludedInSctm;
        const isReadOnlyCatalog = orgDefaultCatalog.id === COMMON.defaultUuid;
        let activeCatalogId = orgDefaultCatalog.id;

        if (isReadOnlyCatalog) {
          log.debug("[STEP] Catalog is readonly. Cloning catalog: ", activeCatalogId);
          try {
            const newCatalogId = await cloneControlCatalogStep(
              orgDefaultCatalog.id,
              stepMap.get(ConfigureStep.CloneControlCatalog)
            );
            log.debug("[STEP] Catalog clone successfully.  New Catalog Id: ", newCatalogId);

            await setOrganizationProperty({ organizationId, property: "defaultControlCatalog", value: newCatalogId });
            assertIsDefined(newCatalogId);
            activeCatalogId = newCatalogId;
          } catch (error) {
            if (isBrmApiError(error)) {
              const err = new Error(`Unable to set catalog:${error.statusText}`);
              baselineConfigureDispatch({ type: "failure", error: err });
            } else {
              throw new Error(getErrorMessage(error));
            }
          }
          await setProjectCatalogStep(activeCatalogId, stepMap.get(ConfigureStep.SetProjectCatalog));
          await setVariantCatalogStep(activeCatalogId, stepMap.get(ConfigureStep.SetVariantCatalog));
        } else {
          log.debug("[STEP] Catalog is not readonly");

          updateStep(ConfigureStep.CloneControlCatalog, StepStatus.NotApplicable);

          // user had chosen use defaults...
          // if (!hasSctmFileAndIncludesBaseline && !configureInputData.baseline) {
          updateStep(ConfigureStep.SetProjectCatalog, StepStatus.NotApplicable);
          updateStep(ConfigureStep.SetVariantCatalog, StepStatus.NotApplicable);
          // }
        }
        // let activeBaseline;

        if (hasSctmFileAndIncludesBaseline) {
          log.debug("[STEP] has sctm file and includes baseline");
          assertIsDefined(configureInputData.sctmFile);
          const file = configureInputData.sctmFile.item(0);
          assertIsDefined(file);

          try {
            const nameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
            let baselineName = `${nameWithoutExtension} - ${projectState?.name}_${Date.now()}`;
            // replace all brackets with underscores it messes up the backend and creates multiple baselines....
            baselineName = baselineName.replace(/\[|\]|\{|\}|\(|\)/g, "_");
            const { id: baselineId } = await createBaselineInCatalogStep(
              activeCatalogId,
              stepMap.get(ConfigureStep.CreateBaseline),
              baselineName
            );

            setBaselineInfo({
              baselineId,
              catalogId: activeCatalogId,
              baselineName,
            });
            // activeBaseline = baselineId;
          } catch (error) {
            if (isBrmApiError(error)) {
              // 404 is returned when the baseline with the given name already exists...
              if (error.status === 404) {
                setBaselineInfo({
                  baselineId: projectBaseline,
                  catalogId: activeCatalogId,
                  baselineName: projectBaseline,
                });
                // activeBaseline = projectBaseline;
                updateStep(ConfigureStep.CreateBaseline, StepStatus.Complete);
              } else {
                updateStepOnError(
                  ConfigureStep.CreateBaseline,
                  new Error("Unable to create a baseline using SCTM file")
                );
              }
            } else {
              throw new Error(getErrorMessage(error));
            }
          }
        } else {
          log.debug("[STEP] has no sctmfile");
          updateStep(ConfigureStep.CreateBaseline, StepStatus.NotApplicable);
          updateStep(ConfigureStep.SetProjectCatalog, StepStatus.NotApplicable);
          updateStep(ConfigureStep.SetVariantCatalog, StepStatus.NotApplicable);
          // console.log("here", configureInputData);

          if (configureInputData.baseline && configureInputData.catalog && configureInputData.baselineName) {
            setBaselineInfo({
              baselineId: configureInputData.baseline,
              catalogId: configureInputData.catalog,
              baselineName: configureInputData.baselineName,
            });
            // activeBaseline = configureInputData.baseline;
          }
        }

        baselineConfigureDispatch({ type: "finish" });
        log.debug("[STEP] baseline dispatch finish");
      }
    };
    doConfigure();
  }, [
    configureInputData,
    isConfigureStarted,
    setProjectKnowledgebaseStep,
    stepMap,
    orgDefaultCatalog,
    cloneControlCatalogStep,
    createBaselineInCatalogStep,
    setOrganizationProperty,
    organizationId,
    setProjectProperty,
    projectId,
    setProjectCatalogStep,
    onStepUpdate,
    importBaseline,
    projectBaseline,
    projectCatalog,
    updateStep,
    updateStepOnError,
    setVariantCatalogStep,
    getStep,
    projectState?.name,
  ]);

  React.useEffect(() => {
    const doConfigure = async () => {
      if (baselineConfigureState.status === "complete" && configureInputData && stepMap) {
        log.debug("[STEP] baselineConfigureState.status === complete");

        // console.log("baseline configure complete", baselineInfo, configureInputData.isBaselineIncludedInSctm);
        // const importBaselineStep = stepMap.get(ConfigureStep.ImportBaseline);

        if (configureInputData.isBaselineIncludedInSctm && baselineInfo) {
          // console.log("baseline importing....", stepMap.get(ConfigureStep.ImportBaseline));
          assertIsDefined(configureInputData.sctmFile);
          const file = configureInputData.sctmFile.item(0);
          assertIsDefined(file);
          baselineConfigureDispatch({ type: "nextStep" });
          try {
            await importBaseline(file, stepMap.get(ConfigureStep.ImportBaseline), baselineInfo);

            // if (configureInputData.baselineMode === "floor" && configureInputData.ceilingBaseline) {
            //   try {
            //     const bl = ceilingBaselineOption.find(
            //       (b: IOption) => b.label === configureInputData.ceilingBaseline?.label
            //     );
            //     await mergeBaseline({ id: baselineInfo.baselineId, baseline: bl.value });
            //   } catch (error) {
            //     if (isBrmApiError(error)) {
            //       if (error.status === 404) {
            //         updateStepOnError(ConfigureStep.CreateBaseline, new Error("Merge Api not available"));
            //       } else {
            //         updateStepOnError(ConfigureStep.CreateBaseline, new Error("Unable to merge ceiling baseline"));
            //       }
            //     }
            //   }
            // }

            // console.log("projectBaseline.id", projectBaseline.id);

            // if (configureInputData.baselineMode === "floor" && configureInputData.ceilingBaseline) {
            //   try {
            //     await mergeBaseline({ id: projectBaseline.id, baseline: configureInputData.ceilingBaseline.value });
            //   } catch (error) {
            //     if (isBrmApiError(error)) {
            //       if (error.status === 404) {
            //         updateStepOnError(ConfigureStep.CreateBaseline, new Error("Merge Api not available"));
            //       } else {
            //         updateStepOnError(ConfigureStep.CreateBaseline, new Error("Unable to merge ceiling baseline"));
            //       }
            //     }
            //   }
            // }
          } catch (error) {
            if (isBrmApiError(error)) {
              const err = new Error(`Unable to import baseline ${error.statusText}`);
              baselineImportDispatch({ type: "failure", error: err });
            } else {
              throw new Error(getErrorMessage(error));
            }
          }
        } else if (!configureInputData.isBaselineIncludedInSctm) {
          log.debug("[STEP] skipping baseline import");
          baselineConfigureDispatch({ type: "nextStep" });
          // console.log("skipping baseline import...");
          updateStep(ConfigureStep.ImportBaseline, StepStatus.NotApplicable);
          baselineImportDispatch({ type: "skipped" });
        }
      }
    };
    // console.log("baselineConfigureState", baselineConfigureState);
    doConfigure();
  }, [
    configureInputData,
    stepMap,
    baselineConfigureState,
    baselineInfo,
    importBaseline,
    projectId,
    onStepUpdate,
    updateStep,
    mergeBaseline,
    projectBaseline,
    updateStepOnError,
  ]);

  React.useEffect(() => {
    const doConfigure = async () => {
      // console.log(`baseline import step0:`, baselineImportState);
      log.debug(
        "[STEP]baseline import state",
        JSON.stringify(baselineImportState),
        JSON.stringify(configureInputData),
        defaultVariantId,
        projectId
      );

      if (baselineImportState.status === "error" && configureInputData && stepMap) {
        updateStep(ConfigureStep.SetProjectBaseline, StepStatus.NotApplicable);
        updateStep(ConfigureStep.SetVariantBaseline, StepStatus.NotApplicable);
        baselineImportDispatch({ type: "nextStep" });
        baselineAssignmentDispatch({ type: "finish" });
      } else if (
        (baselineImportState.status === "complete" || baselineImportState.status === "skipped") &&
        configureInputData &&
        stepMap &&
        defaultVariantId
      ) {
        log.debug("[STEP] setting baseline....", projectId);

        // console.log(`setting baseline....`, baselineInfo, configureInputData);
        baselineImportDispatch({ type: "nextStep" });
        baselineAssignmentDispatch({ type: "start" });

        if (configureInputData.baselineMode === "floor" && configureInputData.ceilingBaseline && baselineInfo) {
          try {
            // const bl = ceilingBaselineOption.find(
            //   (b: IOption) => b.label === configureInputData.ceilingBaseline?.label
            // );
            // console.log("ceilingBaseline:", configureInputData.ceilingBaseline);
            // console.log("baselineId:", baselineInfo.baselineId);
            // console.log("baseline:", configureInputData.ceilingBaseline.value);
            // console.log("baseline:", configureInputData.ceilingBaseline.label);
            await mergeBaseline({ id: baselineInfo.baselineId, baseline: configureInputData.ceilingBaseline.value });
          } catch (error) {
            if (isBrmApiError(error)) {
              // these errors cause the project to be unset, so no use continuing any further since
              // everything would fail without the project
              if (error.status === 404) {
                updateStepOnError(ConfigureStep.CreateBaseline, new Error("Merge Api not available"));
              } else {
                updateStepOnError(ConfigureStep.CreateBaseline, new Error("Unable to merge ceiling baseline"));
              }
              const err = new Error(`Unable to merge baselines`);
              baselineAssignmentDispatch({ type: "failure", error: err });
              return;
            }
          }
        }

        // User has specified a sctm file or they have choosen a baseline
        if (configureInputData.sctmFile || configureInputData.baseline) {
          const theBaselineId =
            configureInputData.isBaselineIncludedInSctm && baselineInfo
              ? baselineInfo.baselineId
              : configureInputData.baseline;
          // const theBaselineId = baselineInfo.baselineId;
          // console.log("Baseline", (configureInputData.sctmFile, configureInputData.baseline));

          try {
            if (configureInputData.catalog) {
              await setProjectProperty({ projectId, property: "controlCatalog", value: configureInputData.catalog });
            }
            await doStep(
              stepMap.get(ConfigureStep.SetProjectBaseline),
              setProjectProperty({ projectId, property: "baseline", value: theBaselineId })
            );

            if (configureInputData.catalog) {
              await setVariantProperty({
                variantId: defaultVariantId,
                property: "catalog",
                value: configureInputData.catalog,
              });
            }
            await doStep(
              stepMap.get(ConfigureStep.SetVariantBaseline),
              setVariantProperty({ variantId: defaultVariantId, property: "baseline", value: theBaselineId })
            );

            baselineAssignmentDispatch({ type: "finish" });
          } catch (error) {
            if (isBrmApiError(error)) {
              const err = new Error(`Unable to set baseline${error.statusText}`);
              baselineAssignmentDispatch({ type: "failure", error: err });
            } else {
              throw new Error(getErrorMessage(error));
            }
          }
        } else {
          updateStep(ConfigureStep.SetProjectBaseline, StepStatus.NotApplicable);
          updateStep(ConfigureStep.SetVariantBaseline, StepStatus.NotApplicable);
          baselineAssignmentDispatch({ type: "finish" });
        }
      }
    };
    doConfigure();
  }, [
    baselineImportState,
    baselineInfo,
    configureInputData,
    defaultVariantId,
    doStep,
    mergeBaseline,
    onStepUpdate,
    projectId,
    setProjectProperty,
    setVariantProperty,
    stepMap,
    updateStep,
    updateStepOnError,
  ]);

  React.useEffect(() => {
    const doConfigure = async () => {
      if (baselineAssignmentState.status === "complete" && configureInputData && stepMap) {
        baselineAssignmentDispatch({ type: "nextStep" });
        // console.log("baseline assignment complete");

        if (configureInputData.modelFiles) {
          try {
            await importSystemModel(configureInputData.modelFiles, stepMap.get(ConfigureStep.ImportModel));
          } catch (error) {
            if (isBrmApiError(error)) {
              const err = new Error(`Unable to import System Model${error.statusText}`);
              modelDispatch({ type: "failure", error: err });
            } else {
              throw new Error(getErrorMessage(error));
            }
          }
        } else {
          updateStep(ConfigureStep.ImportModel, StepStatus.NotApplicable);
          findingsDispatch({ type: "finish" });
        }
      }
    };
    doConfigure();
  }, [
    configureInputData,
    importSystemModel,
    stepMap,
    baselineConfigureState.status,
    baselineAssignmentState.status,
    updateStep,
  ]);

  React.useEffect(() => {
    const doConfigure = async () => {
      if (modelImportState.status === "complete" && configureInputData && stepMap && sctmData) {
        modelDispatch({ type: "nextStep" });
        // console.log("import initial risk complete");

        if (configureInputData?.sctmFile) {
          const file = configureInputData.sctmFile.item(0);
          assertIsDefined(file);
          try {
            await importSctm(file, stepMap.get(ConfigureStep.ImportSctm));
          } catch (error) {
            if (isBrmApiError(error)) {
              const err = new Error(`Unable to import SCTM${error.statusText}`);
              sctmDispatch({ type: "failure", error: err });
            } else {
              throw new Error(getErrorMessage(error));
            }
          }
        } else {
          updateStep(ConfigureStep.ImportSctm, StepStatus.NotApplicable);
          sctmDispatch({ type: "finish" });
        }
      }
    };
    // console.log("risk done useEffect", initialRiskJobState);

    doConfigure();
  }, [configureInputData, importSctm, modelImportState.status, sctmData, stepMap, updateStep]);

  React.useEffect(() => {
    const doConfigure = async () => {
      if (
        (sctmImportState.status === "complete" || sctmImportState.status === "error") &&
        configureInputData &&
        stepMap &&
        !isCancelled
      ) {
        sctmDispatch({ type: "nextStep" });
        // console.log("sctm import complete");

        if (configureInputData.findingsFiles) {
          try {
            await importFindings(configureInputData.findingsFiles, stepMap.get(ConfigureStep.ImportFindings));
          } catch (error) {
            if (isBrmApiError(error)) {
              const err = new Error(`Unable to import findings: ${error.statusText}`);
              findingsDispatch({ type: "failure", error: err });
            } else {
              throw new Error(getErrorMessage(error));
            }
          }
        } else {
          updateStep(ConfigureStep.ImportFindings, StepStatus.NotApplicable);
          findingsDispatch({ type: "finish" });
        }
      }
    };
    doConfigure();
  }, [configureInputData, importFindings, stepMap, sctmImportState.status, updateStep, isCancelled]);

  React.useEffect(() => {
    const doConfigure = async () => {
      if (
        (findingsImportState.status === "complete" || findingsImportState.status === "error") &&
        configureInputData &&
        stepMap &&
        !isCancelled
      ) {
        findingsDispatch({ type: "nextStep" });
        // console.log("about to calculate risk", findingsImportState);
        try {
          await calculateInitialRisk(stepMap.get(ConfigureStep.CalculateInitialRisk));
        } catch (error) {
          if (isBrmApiError(error)) {
            const err = new Error(`Unable to calculate initial risk: ${error.statusText}`);
            initialRiskJobDispatch({ type: "failure", error: err });
          } else {
            throw new Error(getErrorMessage(error));
          }
        }
      }
    };
    doConfigure();
  }, [calculateInitialRisk, configureInputData, findingsImportState, isCancelled, stepMap]);

  React.useEffect(() => {
    const doConfigure = async () => {
      if (initialRiskJobState.status === "complete" && configureInputData && stepMap && !isCancelled) {
        initialRiskJobDispatch({ type: "nextStep" });
        try {
          await calculateVariantRisk(stepMap.get(ConfigureStep.CalculateVariantRisk));
        } catch (error) {
          if (isBrmApiError(error)) {
            const err = new Error(`Unable to calculate variant risk: ${error.statusText}`);
            variantRiskJobDispatch({ type: "failure", error: err });
          }
        }
        onComplete();
      }
    };
    doConfigure();
  }, [calculateVariantRisk, configureInputData, initialRiskJobState.status, isCancelled, onComplete, stepMap]);

  return {
    configureProject,
  };
};
