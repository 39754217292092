import * as React from "react";
import { ImportApi, ImportContext, useVariant } from "features/brm";
import { COMMON, IMPORT_STATUS } from "constants/brm";
import { getErrorMessage } from "utils/error-message-utils";
import { queryClient } from "libs/react-query";

const POLL_INTERVAL = 1000;

export const useRestoreFromFile = ({
  projectId,
  onStart,
  onSuccess,
  onFailure,
  onError,
}: {
  projectId: string;
  onStart: () => void;
  onSuccess: (results: any) => void;
  onFailure: (results: any) => void;
  onError: (err: Error) => void;
}) => {
  const { mutateAsync: uploadRestoreFile } = ImportApi.useUploadAsset();
  const { mutate: submitRestoreFile } = ImportApi.useImportSubmission();
  const [runId, setRunId] = React.useState(COMMON.nullUuid);
  const [stop, setStop] = React.useState(true);
  const {
    handlers: { refreshVariants, setCurrentVariant, getDefaultVariant },
  } = useVariant();

  /* const { data: submissionStatus } = */ ImportApi.useSubmissionStatus({
    projectId,
    context: ImportContext.savepoint,
    runId,
    options: {
      enabled: runId !== COMMON.nullUuid,
      refetchInterval: stop ? false : POLL_INTERVAL,
      onSuccess: (pollData) => {
        if (pollData.status === IMPORT_STATUS.pass) {
          setStop(true);
          onSuccess(pollData);
          setRunId(COMMON.nullUuid);
          refreshVariants();
          setCurrentVariant(getDefaultVariant() || null);
        }
        if (pollData.status === IMPORT_STATUS.fail) {
          onFailure(pollData);
          setStop(true);
          setRunId(COMMON.nullUuid);
        }
      },
      onError: (err) => {
        onError(err);
        setStop(true);
        setRunId(COMMON.nullUuid);
      },
    },
  });

  // React.useEffect(() => {
  //   console.log("submissionStatus", submissionStatus);
  // }, [submissionStatus]);

  const restoreProjectFromFile = React.useCallback(
    async (restoreFile: File) => {
      const context = ImportContext.savepoint;
      try {
        onStart();
        const result = await uploadRestoreFile({ context, projectId, files: [restoreFile] });
        await queryClient.invalidateQueries();
        // we'll attempt to ignore the return runid and required polling..
        submitRestoreFile(
          { context, projectId, result },
          {
            onSuccess: (data: any) => {
              setRunId(data.id);
              setStop(false);
            },
            onError: (error) => {
              onError(error);
              setStop(true);
            },
          }
        );
      } catch (err) {
        console.error("Unable to upload restore file", getErrorMessage(err));
      }
    },
    [onError, onStart, projectId, submitRestoreFile, uploadRestoreFile]
  );

  return { restoreProjectFromFile };
};
