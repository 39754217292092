import { useState, useMemo, useRef, useCallback } from "react";
import toast from "react-hot-toast";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
import { projectIdState } from "atoms/atoms-admin";
import CustomTable from "components/elements/CustomTableDivStyled";
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable, RetainStringColumnFilter } from "brm/tables/BrmTables";
import PropTypes from "prop-types";
import { TYPE } from "constants/brm";
import {
  createColumnName,
  createColumnBooleanNoEditMapped,
  createColumnBooleanMapped,
  createColumnActions,
  createColumnCategoryMapped,
} from "brm/tables/services/column/columnFactory";
import { ErrorBanner, LoadingSpinner as Loading } from "components/elements";
import { useQueryClient } from "@tanstack/react-query";
import { useRoles } from "features/brm/hooks/useRoles";
import { SystemApi } from "features/brm";
import { useCanRename } from "features/system-model";

const DataflowTable = ({ setSelectedRows }) => {
  const { canRename } = useCanRename();
  const projectId = useRecoilValue(projectIdState);
  const { isSystemEngineer, isRiskAnalyst } = useRoles();
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedCategory = useRef("");
  const updatedIsInternal = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const queryClient = useQueryClient();
  const { data: elementCats, isError: isElementCatsError } = SystemApi.useElementCategories({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });
  const { data: dataflows, isError: isDataflowsError } = SystemApi.useDataflows({
    projectId,
    options: { enabled: !!projectId && (isSystemEngineer || isRiskAnalyst) },
  });

  const { mutate: setDataflowName } = SystemApi.useSetDataflowName({
    options: {
      onSettled: () => {
        setSelectedInstance({
          id: selectedInstance.id,
          name: updatedName.current,
          type: selectedInstance.type,
        });
        updatedName.current = "";
      },
      onError: (err) => {
        toast.error(`Setting dataflow name caused an error: ${err}`);
      },
    },
  });
  const { mutate: setDataflowIsInternal } = SystemApi.useSetDataflowIsInternal({
    options: {
      onSettled: () => {
        updatedIsInternal.current = "";
      },
      onError: (err) => {
        toast.error(`Setting dataflow internal caused an error: ${err}`);
      },
    },
  });
  const { mutate: setDataflowCategory } = SystemApi.useSetDataflowCategory({
    options: {
      onSettled: () => {
        updatedCategory.current = "";
      },
      onError: (err) => {
        toast.error(`Setting dataflow category caused an error: ${err}`);
      },
    },
  });

  const createButton = useCallback(
    (cellProps) => {
      return (
        <ButtonCell
          selectedRowId={selectedInstance.id}
          elementId={cellProps.cell.row.original.id}
          handleConfirmEditClick={async () => {
            setEditMode(false);

            if (updatedName.current !== "") {
              if (!canRename({ updatedName, elementsList: dataflows })) return;
              setDataflowName({ dataflowId: selectedInstance.id, name: updatedName.current });
            }

            if (updatedCategory.current !== "") {
              setDataflowCategory({ dataflowId: selectedInstance.id, category: updatedCategory.current });
            }

            if (updatedIsInternal.current !== "") {
              setDataflowIsInternal({ dataflowId: selectedInstance.id, isInternal: updatedIsInternal.current });
            }
            queryClient.invalidateQueries(["dataflow", selectedInstance.id]);
            queryClient.invalidateQueries(["dataflows", projectId]);
          }}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      );
    },
    [
      selectedInstance.id,
      editMode,
      queryClient,
      projectId,
      canRename,
      dataflows,
      setDataflowName,
      setDataflowCategory,
      setDataflowIsInternal,
    ]
  );

  const checkNameUniqueness = useCallback(
    (newName) => {
      const trimedNamed = newName.trim();
      return isSystemEngineer && Array.isArray(dataflows)
        ? !dataflows.map((dataflow) => dataflow.name.toLowerCase()).includes(trimedNamed.toLowerCase())
        : false;
    },
    [isSystemEngineer, dataflows]
  );

  const columns = useMemo(() => {
    if (isSystemEngineer) {
      return [
        createColumnName(
          selectedInstance.id,
          setSelectedInstance,
          editMode,
          updatedName,
          "dataflow",
          checkNameUniqueness,
          {
            Filter: RetainStringColumnFilter,
          }
        ),
        createColumnCategoryMapped("category", selectedInstance.id, elementCats, editMode, updatedCategory),
        createColumnBooleanMapped("isInternal", selectedInstance.id, editMode, updatedIsInternal),
        createColumnBooleanNoEditMapped("isBoundary"),
        createColumnActions(createButton, { disableFilters: true }),
      ];
    }
    return [
      createColumnName(selectedInstance.id, setSelectedInstance, false, updatedName, "dataflow"),
      createColumnCategoryMapped("category", selectedInstance.id, elementCats, false, updatedCategory),
      createColumnBooleanMapped("isInternal", selectedInstance.id, false, updatedIsInternal),
      createColumnBooleanNoEditMapped("isBoundary"),
    ];
  }, [
    isSystemEngineer,
    selectedInstance.id,
    setSelectedInstance,
    elementCats,
    editMode,
    checkNameUniqueness,
    createButton,
  ]);

  // Refresh the table list if project selection changes
  // useRefreshTableList(projectId, setRefresh);

  if (isElementCatsError || isDataflowsError) {
    return <ErrorBanner msg="Error while loading Dataflow data." />;
  }

  if (elementCats && dataflows) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={dataflows}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "DataFlow_table" }}
            elementName={TYPE.dataflow}
            showRowSelect={isSystemEngineer}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

DataflowTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
};

export default DataflowTable;
