import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption } from "libs/react-query";

interface IExportAuditEvents {
  format: string;
  type: string;
}

/**
 * Export Audit event records
 * @param {format} format - format of the exported file
 * @param {type} type - type of the exported file
 */
export const exportAuditLog = ({ format, type }: IExportAuditEvents) => {
  return Brm.auditApi.auditRecords(format, type);
};

interface IUseExportAuditEventsProps {
  options?: MutationOption<typeof exportAuditLog>;
}

/**
 * Custom hook for downloading audit events report
 * @param {object} options - react-query options
 * @returns react-query for export audit events
 */
export const useExportAuditLogEvents = ({ options = {} }: IUseExportAuditEventsProps) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: exportAuditLog,
  });
};
