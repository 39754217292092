import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import { STATUS } from "constants/brm";
import * as KeyFactory from "./project-query-key-factory";
import { useUserProjects } from "../user";

/**
 *
 * Gets status of project
 * @param {string} projectId - uuid of project
 */
export const getProjectsWithKb = async ({ projects }: { projects: any }) => {
  // To remove this, we could request that the backend provide KB in projectShortDto
  // This would make the KB project table rendering much faster

  if (projects && projects.length > 0) {
    const kbs = await Promise.all(
      projects
        ?.filter((e: any) => e.status === STATUS.active)
        .map((proj: any) =>
          Brm.projectApi.getProjectKb(proj.id).then((d: any) => {
            const project = proj;
            project.kb = d;
            return project;
          })
        )
    );

    return kbs;
  }

  return [];
};

interface IProjectsWithKbProps {
  userId: string | null;
  projectId: string | null;
  options?: QueryOption<typeof getProjectsWithKb>;
}

/**
 * Custom hook for getting status of project
 *
 * @param {string} userId - uuid of user
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query options
 * @returns getprojectCatalog
 */
export const useProjectsWithKb = ({ userId, projectId, options = {} }: IProjectsWithKbProps) => {
  const userProjectsQuery = useUserProjects({ userId, options: { enabled: !!userId } });

  const projectsWithKbQuery = ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.kb(projectId, userProjectsQuery),
    queryFn: () => getProjectsWithKb({ projects: userProjectsQuery.data }),
  });

  if (userProjectsQuery.isError) {
    return userProjectsQuery;
  }

  return projectsWithKbQuery;
};
