import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

interface ISetProjectBaseline {
  projectId: string;
  baselineId: string;
}

/**
 * Sets baseline for a project
 *
 * @param {string} projectId - uuid of project
 * @param {string} baselineId - uuid of the baseline
 */
export const setProjectBaseline = ({ projectId, baselineId }: ISetProjectBaseline) => {
  return Brm.projectApi.setProjectBaseline(projectId, { body: baselineId });
};

interface IUseSetProjectBaseline {
  options?: MutationOption<typeof setProjectBaseline>;
}

/**
 * Custom hook to change baseline for a project
 *
 * @param {object} options - configuration of react-query
 * @returns react-query ReactQuery.useMutation
 */
export const useSetProjectBaseline = ({ options }: IUseSetProjectBaseline = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.projectKeys.all);
    },
    ...options,
    mutationFn: setProjectBaseline,
  });
};
