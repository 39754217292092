import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

interface IDeleteSystemAsset {
  systemAssetId: string;
}

/**
 * Delete system asset
 *
 * @param {string} systemAssetId uuid of systemAsset
 */
export const deleteSystemAsset = ({ systemAssetId }: IDeleteSystemAsset) => {
  return Brm.systemAssetApi.deleteSystemAsset(systemAssetId);
};

export interface IUseDeleteSystemAsset {
  options?: MutationOption<typeof deleteSystemAsset>;
}

/**
 * Custom hook to delete a system asset
 *
 * @param {object} options - react-query mutation configuration
 * @returns react-query for useDeleteSystemAsset
 */
export const useDeleteSystemAsset = ({ options }: IUseDeleteSystemAsset = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.systemAssetKeys.all);
    },
    ...options,
    mutationFn: deleteSystemAsset,
  });
};
