const userContentPermissions = {
  systemDiagram: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  contextDiagram: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackGraph: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  threatCatalogs: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: true,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  threatCatalogsDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: true,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  threatTiers: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: true,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  threatTiersDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: true,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  threatGroups: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: true,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  threatGroupDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: true,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  ttps: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: true,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  ttpDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: true,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackMechanisms: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackMechanismFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackTactics: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackTacticFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackKinds: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackKindFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attacks: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackerCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackerCategoriesDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackers: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackersDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  structuralCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  structuralCategoryFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  elementCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  elementCategoryFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  nodes: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  nodeFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  busFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  links: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  linkFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  groups: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  channels: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  exchanges: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  exchangeFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  datatypes: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  datatypeFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  persons: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  personFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  resources: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  resourceFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  dataflows: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  dataflowFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  capabilities: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  capabilityFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  activities: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  activityFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  missions: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  missionFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  entryPoints: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  entryPointsDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  entryPointCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  entryPointCategoriesDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  accessPointCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  accessPointCategoriesDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  accessPoints: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  accessPointsDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackSurfaces: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackSurfacesDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackSurfaceCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackSurfaceCategoriesDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackVectorCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackVectorCategoriesDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackVectors: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  attackVectorsDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  boundaries: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  boundariesActivities: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  classifiedCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  classifiedCategoriesOverview: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  securityObjectives: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  securityObjectivesOverview: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  categorizedEntities: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  categorizedEntitiesOverview: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  systemAssets: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  systemAssetFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  systemAssetCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  systemAssetCategoryFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  systemAssetTypes: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  systemAssetTypeFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  threatEventPatterns: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  threatEventPatternFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  threatEventCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  threatEventCategoryFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  threatEvents: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  threatEventFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  riskCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  riskCategoryFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  risks: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  riskFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  harms: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  harmCategoryFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  undesiredEvents: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  undesiredEventFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  assetCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  assetCategoryFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  assets: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  assetFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  vulnerabilities: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  vulnerabilityFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  vulnerabilityCategories: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  vulnerabilityCategoryFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },

  softwareVulnerabilities: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  sctms: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  sctmsDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  allocatedControls: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  allocatedControlsDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  controlCatalogs: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: true,
    cybersecurityExpert: false,
  },
  controlCatalogsDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: true,
    cybersecurityExpert: false,
  },
  controlFamily: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: true,
    cybersecurityExpert: false,
  },
  controlFamilyFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: true,
    cybersecurityExpert: false,
  },
  controlType: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: true,
    cybersecurityExpert: false,
  },
  controlTypeFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: true,
    cybersecurityExpert: false,
  },
  standardBaselines: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: true,
    cybersecurityExpert: false,
  },
  standardBaselineFullDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: true,
    cybersecurityExpert: false,
  },
  projectCatalogConfig: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: true,
    cybersecurityExpert: false,
  },
  knowledgebases: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: true,
  },
  kbOrganizations: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: true,
  },
  knowledgebasesDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: true,
  },
  kbProjects: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: true,
  },
  metadata: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  modules: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  modulesDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  systems: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  systemsDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  variants: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  variantsDetails: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  projects: {
    superAdmin: true,
    admin: true,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  projectOverview: {
    superAdmin: true,
    admin: true,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  impactCalibration: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  likelihoodCalibration: {
    superAdmin: false,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  organizations: {
    superAdmin: true,
    admin: true,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  organizationOverview: {
    superAdmin: true,
    admin: true,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  installation: {
    superAdmin: true,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  installationOverview: {
    superAdmin: true,
    admin: false,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  users: {
    superAdmin: true,
    admin: true,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  userOverview: {
    superAdmin: true,
    admin: true,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  projectOverviewImporter: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  addUsers: {
    superAdmin: true,
    admin: true,
    systemsEngineer: false,
    threatAnalyst: false,
    riskAnalyst: false,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  home: {
    superAdmin: true,
    admin: true,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: true,
    cybersecurityExpert: true,
  },
  help: {
    superAdmin: true,
    admin: true,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: true,
    cybersecurityExpert: true,
  },
  reports: {
    superAdmin: false,
    admin: false,
    systemsEngineer: true,
    threatAnalyst: false,
    riskAnalyst: true,
    controlCoordinator: false,
    cybersecurityExpert: false,
  },
  licenseError: {
    superAdmin: true,
    admin: true,
    systemsEngineer: true,
    threatAnalyst: true,
    riskAnalyst: true,
    controlCoordinator: true,
    cybersecurityExpert: true,
  },
};

export default userContentPermissions;
