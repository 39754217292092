export default function getUniqueArray(allData, existingData) {
  const uniqueArrayData = allData.filter((item) => {
    let unique = true;
    existingData.forEach((existing) => {
      if (existing.id === item.id) {
        unique = false;
      }
    });
    return unique;
  });
  return uniqueArrayData;
}
