import BrmLogoPng from "assets/icons/BladeRiskManagerLogo.png";
import { ActionButton } from "components/elements/ActionButton";
import { useNavigate } from "react-router-dom";
// Local
import BrmLogo from "../Logo";
import * as S from "./AccountHeader.styles";

export const AccountHeader = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  return (
    <S.AccountHeaderRoot>
      <S.PrimaryContainer>
        <BrmLogo src={BrmLogoPng} alt="BRM Logo" id="Navbar_logo" />
      </S.PrimaryContainer>
      <ActionButton onClick={handleOnClick}>Back to BRM</ActionButton>
    </S.AccountHeaderRoot>
  );
};
