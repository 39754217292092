import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./sap-org-query-key-factory";

/**
 * Gets the sap organization bound to the given organization id
 */
export const getSapOrganization = ({ organizationId }: { organizationId: string }) => {
  return Brm.sapOrganizationApi.getSapOrganizationByID(organizationId);
};

type FunctionType = typeof getSapOrganization;
export interface IOrganizationProps {
  organizationId: string;
  options?: QueryOption<FunctionType>;
}

/**
 * Custom hook for getting all sap organizations
 *
 * @param {object} options - react-query configuration
 * @returns
 */
export const useGetSapOrganization = ({ organizationId, options = {} }: IOrganizationProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.sapOrganizationKeys.find(organizationId),
    queryFn: () => getSapOrganization({ organizationId }),
  });
};
