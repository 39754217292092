import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
// Helpers
import handleApi from "services/brm/api-util";
// State
import { userRoleState, projectIdState } from "atoms/atoms-admin";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilValue, useRecoilState } from "recoil";
// Services
import { userRoles } from "services/brm/admin-service";
import { attackVectorApi } from "services/brm/access-service";
// Styles
import CustomTable from "components/elements/CustomTableDivStyled";
// Components
import ButtonCell from "components/EditComponents/ButtonCell";
import { BrmMainTable } from "brm/tables/BrmTables";
// Hooks
import useRefreshTableList from "hooks/useRefreshTableList";
// Constants
import { TYPE, COMMON } from "constants/brm";
// Table Columns
import {
  createColumnName,
  createColumnCategoryMapped,
  createColumnMappedNoEdit,
  createColumnActions,
} from "brm/tables/services/column/columnFactory";
import * as ACQueries from "brm/system-model/access/access-queries";
import { LoadingSpinner as Loading } from "components/elements";

const AttackVectorTable = ({ setSelectedRows, refresh, setRefresh }) => {
  const userRole = useRecoilValue(userRoleState);
  const [editMode, setEditMode] = useState(false);
  const updatedName = useRef("");
  const updatedCategory = useRef("");
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);

  const sysEngineerRole = userRoles.properties[userRoles.SYSTEM_ENGINEER].key;
  const riskAnalystRole = userRoles.properties[userRoles.RISK_ANALYST].key;
  const projectId = useRecoilValue(projectIdState);

  const { data: attackVectorCategories } = ACQueries.useAttackVectorCategories(projectId);
  const { data: attackVectors, isError, refetch: refetchAttackVectors } = ACQueries.useAttackVectors(projectId);

  const createButton = useCallback(
    (cellProps) => {
      if (userRole === sysEngineerRole || userRole === riskAnalystRole) {
        return (
          <ButtonCell
            selectedRowId={selectedInstance.id}
            elementId={cellProps.cell.row.original.id}
            handleConfirmEditClick={async () => {
              setEditMode(false);

              if (updatedName.current !== "") {
                await handleApi(
                  attackVectorApi.setAttackVectorNameWithHttpInfo(selectedInstance.id, {
                    body: updatedName.current,
                  })
                );
                setSelectedInstance({
                  id: selectedInstance.id,
                  name: updatedName.current,
                  type: selectedInstance.type,
                });
                updatedName.current = "";
              }

              if (updatedCategory.current !== "") {
                await handleApi(
                  attackVectorApi.setAttackVectorCategoryWithHttpInfo(selectedInstance.id, {
                    body: updatedCategory.current,
                  })
                );
                updatedCategory.current = "";
              }

              setRefresh(true);
            }}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        );
      }
      return COMMON.na;
    },
    [
      sysEngineerRole,
      riskAnalystRole,
      editMode,
      setRefresh,
      selectedInstance.id,
      userRole,
      selectedInstance.type,
      setSelectedInstance,
    ]
  );

  const columns = useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "attackVector"),
      createColumnCategoryMapped("category", selectedInstance.id, attackVectorCategories, editMode, updatedCategory),
      createColumnMappedNoEdit("node"),
      createColumnMappedNoEdit("exchange"),
      createColumnActions(createButton, { disableFilters: true }),
    ],
    [editMode, setSelectedInstance, selectedInstance.id, attackVectorCategories, createButton]
  );

  // Refresh the table list if project selection changes
  useRefreshTableList(projectId, setRefresh);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      refetchAttackVectors();
    }
  }, [refresh, setRefresh, refetchAttackVectors]);

  if (isError) {
    return <div>Error while loading Attack Vector data</div>;
  }

  if (attackVectors) {
    return (
      <>
        <CustomTable>
          <BrmMainTable
            data={attackVectors}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "AttackVectorTable_table" }}
            elementName={TYPE.attackVector}
            showRowSelect={false}
          />
        </CustomTable>
      </>
    );
  }
  return <Loading />;
};

AttackVectorTable.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default AttackVectorTable;
