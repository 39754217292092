import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./system-asset-query-key-factory";

interface IGetSystemAssetsType {
  saTypeId: string;
}

export const getSystemAssetsType = async ({ saTypeId }: IGetSystemAssetsType) => {
  const data = await Brm.systemAssetTypeApi.getSystemAssetTypeByID(saTypeId);
  return [data];
};

interface IUseSystemAssetsType {
  saTypeId: string;
  setSelectedElement: (element: any) => void;
  options?: QueryOption<typeof getSystemAssetsType>;
}

/**
 * React-query hook for getting  System Assets type by id
 *
 * @param {string} saTypeId - uuid of System Assets type
 * @returns react-query for getSystemAssetsType
 */
export const useSystemAssetsType = ({ saTypeId, setSelectedElement, options }: IUseSystemAssetsType) => {
  return ReactQuery.useQuery({
    enabled: !!saTypeId,
    onSuccess: (data) => {
      setSelectedElement(data[0]);
    },
    ...options,
    queryKey: KeyFactory.systemAssetTypeKeys.detail(saTypeId),
    queryFn: () => getSystemAssetsType({ saTypeId }),
  });
};
