import styled from "styled-components/macro";

import BTable from "react-bootstrap/Table";

export const RootContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const TableContainer = styled.div`
  /* height: 400px; */
  overflow-y: auto;
  /* display: flex; */
  /* height: 400px;
  overflow: auto; */
  /* overflow-y: scroll; */
`;

interface ITable {
  $minHeight: string | undefined;
}

export const Table = styled(BTable)<ITable>`
  min-height: ${({ $minHeight }) => $minHeight || undefined};
  /* width: 100%;
  display: block;
  height: 100%;
  overflow-y: scroll; */
  /* height: 100%;
  
  position: relative; */
  /* position: sticky;
  top: 0;
  width: 100%; */
`;

export const THead = styled.thead`
  /* display: block; */
`;

export const TableHeaderCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* display: block; */
`;

export const TBody = styled.tbody`
  /* display: block; */
`;

export const TR = styled.tbody`
  /* display: block; */
`;

export const FilterStateContainer = styled.div``;
