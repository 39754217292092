const CRUD_EVENT = {
  systemModel: "systemModelCrud",
  node: "nodeCrud",
  person: "personCrud",
  activity: "activityCrud",
  bus: "busCrud",
  capability: "capabilityCrud",
  dataFlow: "dataflowCrud",
  datatype: "datatypeCrud",
  exchange: "exchangeCrud",
  link: "linkCrud",
  mission: "missionCrud",
};

export default CRUD_EVENT;
