import styled from "styled-components/macro";

export const Button = styled.button`
  display: inline-block;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: center;
  user-select: none;
  margin-top: -2px;
  background-color: ${(props) => (props.toggled ? "#c0c0c0" : "transparent")};

  & :hover {
    background-color: ${(props) => (props.toggled ? "#b2b2b2" : "#dedede")};
  }
`;

export const TextButton = styled(Button)`
  padding: 0.25em 0.5em;
  background-color: transparent;
`;

export const IconButton = styled(Button)`
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 32px;

  svg:hover {
    fill: ${({ theme }) => theme.colors.buttonHover};
  }
`;

export const TextAndIconButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  padding: ${(props) => (props.iconPos === "right" ? "0em .25em 0em .25em" : "0em .25em 0em .25em")};
  svg:hover {
    fill: #005ca6;
  }
`;

export const IconSpan = styled.span`
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 32px;
  padding: 0em 1em;
  width: 1em;
`;
