import { useState } from "react";
// State
import { controlCatalogIdState } from "atoms/atoms-global-control";
import { useRecoilValue } from "recoil";
// Styles
import DialogButtonDivStyled from "components/elements/DialogButtonDivStyled";
import DialogButtonStyled from "components/elements/DialogButtonStyled";
import FormStyled from "components/forms/FormStyled";
import FormStyledError from "components/forms/FormStyledError";
// Services
import { StandardBaselineCreateDto } from "@kdmanalytics/brm-knowledgebase";
import { GlobalControlApi } from "features/brm";
import PropTypes from "prop-types";
// Components
import TextField from "components/forms/TextField";
// Constants
import { FORM_PLACEHOLDER, FORM_LABEL, FORM_ERROR } from "constants/brm";

export const AddStandardBaselineForm = ({ setModalIsOpen }) => {
  const [postError, setPostError] = useState("");
  const controlCatalogId = useRecoilValue(controlCatalogIdState);
  const [isTextValid, setIsTextValid] = useState(true);
  const { mutate: createBaseline } = GlobalControlApi.useCreateBaseline();

  // validate that all fields in the form have been completed
  function validate(formData) {
    let error = true;
    if (formData.name.value === "") {
      error = false;
    }
    return error;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const isValid = validate(e.target.elements);

    if (isValid && isTextValid) {
      // the new instance from user - form entry
      const params = {
        name: e.target.elements.name.value,
        note: e.target.elements.note.value,
      };
      const standardBaselineCreateDto = StandardBaselineCreateDto.constructFromObject(params);
      createBaseline(
        { controlCatalogId, standardBaselineCreateDto },
        {
          onSettled: () => {
            setModalIsOpen(false);
          },
        }
      );
    } else {
      return isTextValid ? setPostError(FORM_ERROR.missingFields) : setPostError(FORM_ERROR.invalidCharacters);
    }
    return null;
  }

  return (
    <div>
      <form onSubmit={handleSubmit} action="">
        <FormStyled>
          <div className="form-style">
            <TextField
              label={{ id: "StandardBaselineAddForm_name", name: FORM_LABEL.nameMandatory }}
              input={{ name: "name", placeholder: FORM_PLACEHOLDER.name }}
              setIsTextValid={setIsTextValid}
            />
            <TextField
              label={{ id: "StandardBaselineAddForm_note", name: FORM_LABEL.note }}
              input={{ name: "note", placeholder: FORM_PLACEHOLDER.note }}
              setIsTextValid={setIsTextValid}
            />
          </div>
        </FormStyled>
        <FormStyledError>{postError}</FormStyledError>
        <DialogButtonDivStyled>
          <DialogButtonStyled onClick={() => setModalIsOpen(false)}>Cancel</DialogButtonStyled>
          <DialogButtonStyled type="submit">Add</DialogButtonStyled>
        </DialogButtonDivStyled>
      </form>
    </div>
  );
};

AddStandardBaselineForm.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
};
