import { ProjectSettingsView } from "../ProjectSettingsView";
import * as PS from "../styles/project-settings-styles";
import { useProjectConfigurationPanelApi } from "./useProjectConfigurationPanelApi";

interface IProjectSettingsPanel {
  projectId: string;
  isReadOnly?: boolean;
}

export const ProjectConfigurationPanel = ({ projectId, isReadOnly = true }: IProjectSettingsPanel) => {
  const { projectSettings, setProjectSetting } = useProjectConfigurationPanelApi({ projectId });

  return (
    <>
      <PS.SettingsHeading>Project Configuration</PS.SettingsHeading>
      <ProjectSettingsView
        projectSettings={projectSettings}
        isReadOnly={isReadOnly}
        onSettingChange={setProjectSetting}
      />
    </>
  );
};
