import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./knowledgebase-query-key-factory";

/**
 * Sets the name of Kb
 *
 * @param {string} kbId - uuid of the Kb
 * @param {string} name - name of the kb
 */
export const setKbName = ({ kbId, name }: { kbId: string; name: string }) => {
  return Brm.kbApi.setKBName(kbId, { body: name });
};

interface IUseSetKbName {
  options?: MutationOption<typeof setKbName>;
}

/**
 * Custom hook to change the name of an existing Kb
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetKbName = ({ options }: IUseSetKbName = {}) => {
  return ReactQuery.useMutation({
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.knowledgebaseKeys.all);
    },
    mutationFn: setKbName,
  });
};
