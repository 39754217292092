import * as React from "react";
import { type Orientation } from "./types";
import * as S from "./Stepper.styles";
import { StepperContext } from "./StepperContext";

interface IStepperProps {
  children?: React.ReactNode;
  orientation?: Orientation;
  activeStep: number;
}

interface IStepProps {
  index: number;
  isLast?: boolean;
}

export const Stepper = ({ children, orientation = "vertical", activeStep = 0 }: IStepperProps) => {
  const contextValue = React.useMemo(() => ({ orientation, activeStep }), [orientation, activeStep]);

  const childrenArray = React.Children.toArray(children);
  const steps = childrenArray.map((step, index) => {
    const newStep = step as React.ReactElement<React.PropsWithChildren<IStepProps>>;
    return React.cloneElement(newStep, { index, isLast: index === childrenArray.length - 1 });
  });

  return (
    <StepperContext.Provider value={contextValue}>
      <S.Container orientation={orientation}>{steps}</S.Container>
    </StepperContext.Provider>
  );
};
