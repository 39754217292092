// import { IMPORT_CONTEXT } from "constants/brm";
import { useQuery } from "@tanstack/react-query";
// import { genericImportApi } from "services/brm/importer-service";
import { ImportApi, ImportContext } from "features/brm";

export const useSctmImportAssets = (projectId, runId, setFilesToDownload, setDownloadModalIsOpen) => {
  return useQuery(
    // ["systemImportAssets", projectId, runId],
    // ["systemImportAssets", projectId, runId],
    ImportApi.importKeys.assets(projectId, ImportContext.sctm, runId),
    // () => genericImportApi.importAssets(ImportContext.sctm, projectId, runId),
    () => ImportApi.getImportAssets({ context: ImportContext.sctm, projectId, runId }),
    {
      enabled: false,
      onSuccess: (data) => {
        setFilesToDownload(data);
        setDownloadModalIsOpen(true);
      },
    }
  );
};

export const useBorImportAssets = (projectId, runId, setFilesToDownload, setDownloadModalIsOpen) => {
  return useQuery(
    // ["systemImportAssets", projectId, runId],
    // ["systemImportAssets", projectId, runId],
    ImportApi.importKeys.assets(projectId, ImportContext.bor, runId),
    // () => genericImportApi.importAssets(ImportContext.sctm, projectId, runId),
    () => ImportApi.getImportAssets({ context: ImportContext.bor, projectId, runId }),
    {
      enabled: false,
      onSuccess: (data) => {
        setFilesToDownload(data);
        setDownloadModalIsOpen(true);
      },
    }
  );
};

export const useSystemImportAssets = (projectId, runId, setFilesToDownload, setDownloadModalIsOpen) => {
  return useQuery(
    // ["systemImportAssets", projectId, runId],
    ImportApi.importKeys.assets(projectId, ImportContext.system, runId),
    // () => genericImportApi.importAssets(ImportContext.system, projectId, runId),
    () => ImportApi.getImportAssets({ context: ImportContext.system, projectId, runId }),
    {
      enabled: false,
      onSuccess: (data) => {
        setFilesToDownload(data);
        setDownloadModalIsOpen(true);
      },
    }
  );
};
