import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./license-query-key-factory";

interface ICreateBorrowFile {
  hostId: string;
  duration: string;
}

/**
 * Create a license borrow
 * @param {string} hostId - host ID to borrow the license
 * @param {number} duration - number of days of the borrowing
 */
export const createBorrowFile = ({ hostId, duration }: ICreateBorrowFile) => {
  return Brm.licensingApi.createBorrowFile(hostId, { days: duration });
};

interface IUseBorrowRequestProps {
  options?: MutationOption<typeof createBorrowFile>;
}

/**
 * Custom hook for requesting a license borrowing
 * @param {object} options - react-query options
 * @returns react-query for generating a license borrow
 */
export const useCreateBorrowFile = ({ options = {} }: IUseBorrowRequestProps) => {
  return ReactQuery.useMutation({
    ...options,
    mutationFn: createBorrowFile,
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.licenseKeys.all);
    },
  });
};
