import { useRoles } from "features/brm/hooks/useRoles";
import { useStructuralCategories } from "../structuralCategory";
/**
 * Custom hook that wraps the useStructuralCategories hook. Returns a list of Structural Categories.
 *
 * @param {string} projectId - uuid of a project
 * @returns a selected list of struct categories
 */
export const useGetNodeStructuralCategories = ({ projectId }: { projectId: string }) => {
  const { isRiskAnalyst, isSystemEngineer } = useRoles();
  const STRUCTURAL_CATEGORIES_TO_HIDE = ["Enclave", "Facility", "Organization", "Subnet", "Group"] as const;
  return useStructuralCategories({
    projectId,
    options: {
      enabled: !!projectId && (isRiskAnalyst || isSystemEngineer),
      select: (data) =>
        data.filter((structuralCtg: any) => !STRUCTURAL_CATEGORIES_TO_HIDE.includes(structuralCtg)).sort(),
    },
  });
};
