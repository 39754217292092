import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./mitigation-query-key-factory";

/**
 * Returns baseline for the given SCTM
 *
 * @param {string} sctmId - uuid of the SCTM
 */
export const getSctmBaseline = ({ sctmId }: { sctmId: string }) => {
  return Brm.sctmApi.getSCTMBaseline(sctmId);
};

interface IUseSctmBaseline {
  sctmId: string;
  options?: QueryOption<typeof getSctmBaseline>;
}

/**
 * React-query hook for getting baseline
 *
 * @param {string} sctmId - uuid of sctm
 * @param {object} config - react-query configuration object
 * @returns react-query for getSctmAllocatedControls
 */
export const useSctmBaseline = ({ sctmId, options }: IUseSctmBaseline) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.sctmKeys.baseline(sctmId),
    queryFn: () => getSctmBaseline({ sctmId }),
  });
};
