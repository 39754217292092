import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { MutationOption, queryClient } from "libs/react-query";
import * as KeyFactory from "./sctm-query-key-factory";

export interface ISetSctmName {
  sctmId: string;
  name: string;
}

/**
 * Sets the sctm name property
 *
 * @param {string} sctmId - uuid of the resource
 * @param {string} name - value
 */
export const setSctmName = ({ sctmId, name }: ISetSctmName) => {
  return Brm.sctmApi.setSCTMName(sctmId, { body: name });
};

type MutateFunction = typeof setSctmName;
export interface IUseSetSctmProperty {
  options?: MutationOption<MutateFunction>;
}

/**
 * Custom hook to change the name of sctm
 *
 * @param {object} options - react-query configuration object
 * @returns react-query ReactQuery.useMutation
 */
export const useSetSctmName = ({ options = {} }: IUseSetSctmProperty = {}) => {
  return ReactQuery.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KeyFactory.sctmKeys.all);
    },
    ...options,
    mutationFn: setSctmName,
  });
};
