import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./project-query-key-factory";

/**
 * Gets a projects readonly users
 * @param {string} projectId - uuid of project
 */
export const getProjectReadOnlyUsers = ({ projectId }: { projectId: string | null }) => {
  return projectId === null ? Promise.reject(new Error("Invalid id")) : Brm.projectApi.getProjectReadonly(projectId);
};

interface IProjectStatusProps {
  projectId: string | null;
  options?: QueryOption<typeof getProjectReadOnlyUsers>;
}

/**
 * Custom hook for getting readonly users a project
 *
 * @param {string} projectId - uuid of project
 * @param {object} options - react-query options
 * @returns getprojectCatalog
 */
export const useProjectReadOnlyUsers = ({ projectId, options = {} }: IProjectStatusProps) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.projectKeys.readOnlyUsers(projectId),
    queryFn: () => getProjectReadOnlyUsers({ projectId }),
  });
};
